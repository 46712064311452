import { observer } from 'mobx-react';

import FormInput from '../../../app/shared/components/FormInput';
import { PropertyList } from '../../../app/shared/components/PropertyList/PropertyList';
import { TextareaFieldWithLabel } from '../../../app/shared/components/Textarea/Textarea';
import { TextInput } from '../../../app/shared/Form';
import { OrderSummaryTranslation } from '../../localization/SiteTranslation';
import { CartState } from '../CartState';
import css from './OrderSummary.css';

interface OrderSummaryProps {
  cartList: Array<CartState>;
  commentInput: TextInput;
  translation: OrderSummaryTranslation;
}

export const OrderSummary = observer(({ cartList, commentInput, translation }: OrderSummaryProps) => {
  return (
    <>
      {cartList.map((cart, key) => {
        const readyForShippingValue = translation.readyForShippingFromFactoryValue.interpolate([
          ['minDays', cart?.preparingTimeInWorkingDays?.minDays?.toString()],
          ['maxDays', cart?.preparingTimeInWorkingDays?.maxDays?.toString()],
        ]);
        const cartItem = [
          { label: translation.brandLabel, value: cart.cartItems[0].item.baseModel.brandName },
          { label: translation.noItemsLabel, value: cart.numberOfItems },
          { label: translation.readyForShippingFromFactoryLabel, value: readyForShippingValue },
        ];

        return (
          <div key={key} className={css.OrderSummary}>
            <PropertyList
              className={css.propertyList}
              items={cartItem}
              propertyClassNames={{
                labelClassName: css.propertyLabel,
                valueClassName: css.propertyValue,
              }}
            />
            <FormInput
              label={translation.customReferenceLabel}
              input={cart.customReference}
              placeholder={translation.customReferencePlaceholder}
              autoFocus
              showValidationHints
            />
            <FormInput
              label={translation.customNameLabel}
              input={cart.customName}
              placeholder={translation.customNamePlaceholder}
              showValidationHints
            />
            <TextareaFieldWithLabel
              className={css.commentTextarea}
              label={translation.commentLabel}
              placeholder={translation.commentPlaceholder}
              input={commentInput}
              showValidationHints
            />
          </div>
        );
      })}
    </>
  );
});
