import { observer } from 'mobx-react';
import { Component } from 'react';

import ActionButton from '../shared/components/ActionButton';
import FormInput from '../shared/components/FormInput';
import Icon from '../shared/components/Icon/Icon';
import HeaderLevel3 from '../shared/components/Typography/HeaderLevel3';
import css from './ConfigurationSearchPage.css';
import { ConfigurationSearchPageState } from './ConfigurationSearchPageState';

@observer
class ConfigurationSearchPage extends Component<{ state: ConfigurationSearchPageState }, {}> {
  render() {
    const { state } = this.props;

    return (
      <div className={css.ConfigurationSearchPage}>
        <Icon name="barcode" className={css.barIcon} />
        <HeaderLevel3 className={css.header}>{state.translation.title}</HeaderLevel3>
        <p className={css.description}>{state.translation.description}</p>
        <div className={css.enterCode}>
          <div className={css.inputContainer}>
            <FormInput
              id="configuratorCode"
              input={state.configurationFinder.searchBar}
              onKeyPress={(p) => p.key === 'Enter' && state.configurationFinder.searchCommand.invoke()}
              placeholder={state.translation.codeInputPlaceholder}
              inputClassName={css.input}
              autoFocus
            />
          </div>
          <ActionButton command={state.configurationFinder.searchCommand} className={css.button}>
            <Icon className={css.icon} name="search" />
          </ActionButton>
        </div>
        {state.configurationFinder.errorMessage && (
          <div className={css.error}>{state.configurationFinder.errorMessage}</div>
        )}
      </div>
    );
  }
}

export default ConfigurationSearchPage;
