export const availableShots = ['front', 'left', 'back'];

export enum VisualizationAspectRatio {
  Default = 'Default',
  Wide = 'Wide',
}

export enum VisualizationMode {
  Default = 'Default',
  Full3d = '3d',
  Hybrid = 'Hybrid',
}
