import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import List from '../List/List';
import css from './NavigationBullets.css';

interface NavigationBulletsProps<T> {
  className?: string;
  items: Array<T>;
  handleOnBulletClick: (item: T) => void;
  activeShot: T;
}

@observer
class NavigationBullets<T> extends Component<NavigationBulletsProps<T>, {}> {
  constructor(props: NavigationBulletsProps<T>) {
    super(props);

    this.renderBullet = this.renderBullet.bind(this);
  }

  renderBullet(item: T) {
    const bulletClasses = classNames(css.bullet, item === this.props.activeShot && css.active);
    return (
      <button className={css.button} onClick={() => this.props.handleOnBulletClick(item)}>
        <span className={bulletClasses} />
      </button>
    );
  }

  render() {
    return (
      <List
        className={classNames(css.NavigationBullets, this.props.className)}
        items={this.props.items}
        itemRenderer={this.renderBullet}
      />
    );
  }
}

export default NavigationBullets;
