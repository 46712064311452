import { action, computed, observable } from 'mobx';

import { IApiClient } from '../data/client';
import { GenerateConfigurationPdfCommand, ShoppingContext } from '../data/model';
import { Analytics } from '../shared/analytics/Analytics';
import { IShareConfiguratorUrlProvider } from '../shared/BaseShareConfiguratorUrlProvider';
import { AsyncCommand } from '../shared/common';
import { IImagePreloader } from '../shared/ImagePreloader';
import { INavigationService } from '../shared/NavigationService';
import { IPdfUrlProvider } from '../shared/PdfUrlProvider';
import { ZipBuilder } from '../shared/ZipBuilder';
import IConfigurationPageQuery from './IConfigurationPageQuery';
import { FinalVisualizationBuilder } from './VisualizationBuilder';

const shots = ['front', 'left', 'back']; // TODO: get list of available shots form the server

export interface ConfigurationShareContext {
  productNumber: string;
  visualizationMode: string;
  query: IConfigurationPageQuery;
}

export class ConfigurationShareState {
  @observable public code: string = '';

  public downloadAsPdfCommand: AsyncCommand;
  public downloadZipCommand: AsyncCommand;

  private zipBuilder: ZipBuilder;

  private shoppingContext: ShoppingContext;

  @computed
  get pdfName() {
    return `Flokk-chair-${this.code}.pdf`;
  }

  @computed
  get zipName() {
    return `Flokk-chair-${this.code}.zip`;
  }

  @action
  public setCode(code: string) {
    this.code = code;
  }

  constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly imagePreloader: IImagePreloader,
    private readonly pdfUrlProvider: IPdfUrlProvider,
    private readonly shareConfiguratorUrlProvider: IShareConfiguratorUrlProvider,
    private readonly getContextCallback: () => ConfigurationShareContext,
  ) {
    this.downloadAsPdfCommand = new AsyncCommand(() => this.downloadAsPdf());
    this.downloadZipCommand = new AsyncCommand(() => this.downloadAsZip());
    this.zipBuilder = new ZipBuilder(this.client, this.navigation);
  }

  public async onLoad(shoppingContext: ShoppingContext) {
    this.shoppingContext = shoppingContext;
    await this.pdfUrlProvider.initialize(shoppingContext);
    await this.shareConfiguratorUrlProvider.initialize(shoppingContext);
  }

  @action
  async downloadAsPdf() {
    // Ensure image is generated to make sure that it is rendered in pdf
    const imageUrl = this.imageUrlForPdf;
    await this.imagePreloader.load([imageUrl]);

    const context = this.getContextCallback();
    const pdfGenerationUrl = this.pdfUrlProvider.getPdfUrl(context.productNumber, context.query);
    const command = new GenerateConfigurationPdfCommand({
      configurationCode: this.code,
      pdfGenerationUrl,
      shoppingContext: this.shoppingContext,
    });
    const response = await this.client.send(command);

    this.navigation.redirect(response.fileUrl);
    ConfigurationShareState.trackDownload('downloadConfigurationAsPdfClick', response.fileUrl);
  }

  @action
  async downloadAsZip() {
    const context = this.getContextCallback();
    const zipUrl = await this.zipBuilder.downloadZipConfigurationImages(
      this.code,
      {
        shots: shots,
        outputFilename: this.zipName,
      },
      context.visualizationMode,
    );

    ConfigurationShareState.trackDownload('downloadVisualizationsClick', zipUrl);
  }

  public get imageUrlForPdf() {
    return FinalVisualizationBuilder.buildUrl(this.code, shots[0], 'jpg', 1000, 1192);
  }

  @computed
  get absoluteShareUrl() {
    const context = this.getContextCallback();
    return this.shareConfiguratorUrlProvider.getUrl(this.code, context.query.quantity);
  }

  private static trackDownload(actionName: string, url: string) {
    Analytics.trackDownload({ name: actionName, url });
  }
}
