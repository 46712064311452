import * as React from 'react';

import { Navigation } from '../NavigationService';
import { RelativeUrl } from '../Url';

// tslint:disable-next-line: no-any
export interface LinkProps extends React.AnchorHTMLAttributes<any> {
  preventDefault?: boolean;
  to?: RelativeUrl;
}

export default class Link extends React.Component<LinkProps, {}> {
  static defaultProps = {
    preventDefault: true,
  };

  constructor(props: LinkProps) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick(e: React.MouseEvent, url: RelativeUrl) {
    const { onClick, preventDefault, to } = this.props;

    if (to) {
      if (preventDefault) {
        e.preventDefault();
      }
      Navigation.instance.navigateTo(url);
    }

    if (onClick) {
      if (preventDefault) {
        e.preventDefault();
      }
      onClick(e);
    }
  }

  render() {
    const { onClick, href, to, className, preventDefault, ...linkProps } = this.props;
    const url = to ? RelativeUrl.parse(to.toString()) : new RelativeUrl();
    return (
      <a
        onClick={(e) => {
          this.handleOnClick(e, url);
        }}
        href={to ? url.toString() : href}
        className={className}
        {...linkProps}
      />
    );
  }
}
