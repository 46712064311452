import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import { ModalState } from '../../../ModalState';
import { CustomModal } from '../CustomModal/CustomModal';
import { ModalDefaultApplyButtonProps, ModalDefaultCancelButtonProps } from '../CustomModal/CustomModalDefaultElements';

interface ConfirmationModalProps {
  modal: ModalState;

  title: ReactNode;
  titleClassName?: string;

  children: ReactNode;
  contentClassName?: string;

  applyButton: ModalDefaultApplyButtonProps;
  cancelButton?: ModalDefaultCancelButtonProps;
}

export const ConfirmationModal = observer((props: ConfirmationModalProps) => {
  const { modal, title, children, applyButton, cancelButton, titleClassName, contentClassName } = props;
  return (
    <CustomModal
      modal={modal}
      defaultTitle={{
        title: title,
        className: titleClassName,
      }}
      defaultContent={{
        content: children,
        className: contentClassName,
      }}
      defaultActionButtons={{
        applyButton: applyButton,
        cancelButton: cancelButton,
      }}
    />
  );
});
