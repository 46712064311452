import classNames from 'classnames';
import { Children, forwardRef, ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';

// tslint:disable:ordered-imports
import '../../../../styles/vendors/slick.css';
import css from './Carousel.css';

export interface CarouselProps extends Settings {
  centered?: boolean;
  children?: ReactNode;
}

const defaultSettings: Settings = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  dotsClass: css.dots,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 1000,
};

const Carousel = forwardRef<Slider, CarouselProps>(({ centered, children, className, ...settings }, ref) => {
  const sliderSettings = { ...defaultSettings, ...settings };

  /*
   * The infinite option needs to be disabled if a number of slides is less than a value of the slidesToShow option.
   * It's a temporary fix for an issue in the react-slick library:
   * https://github.com/akiran/react-slick/issues/1171
   * */
  const infinite = !!sliderSettings.infinite && Children.count(children) >= sliderSettings.slidesToShow;

  return (
    <Slider
      ref={ref}
      className={classNames(css.Carousel, { [css.centered]: centered }, className)}
      {...sliderSettings}
      infinite={infinite}
    >
      {children}
    </Slider>
  );
});

export default Carousel;
