import classNames from 'classnames';
import { ReactNode } from 'react';

import capitalizeFirstLetter from '../capitalizeFirstLetter';
import Icon from '../components/Icon/Icon';
import css from './Alert.css';

export type AlertSize = 'medium' | 'large';
export type AlertType = 'danger';

interface AlertProps {
  type?: AlertType;
  size?: AlertSize;
  children: ReactNode;
  className?: string;
}

export const Alert = ({ children, type = 'danger', size = 'medium', className }: AlertProps) => {
  const typeClassName = `type${capitalizeFirstLetter(type)}` as keyof typeof css;
  const styleClassName = `size${capitalizeFirstLetter(size)}` as keyof typeof css;
  return (
    <div className={classNames(css.Alert, css[typeClassName], css[styleClassName], className)}>
      <Icon name="danger" className={css.icon} />
      <div className={css.message}>{children}</div>
    </div>
  );
};
