import { IApiClient } from '../../../data/client';
import { AddressFormTranslation } from '../../../localization/SiteTranslation';
import CountryCode from '../../../shared/CountryCode';
import DefaultDynamicInputs from './DynamicInputs/DefaultDynamicInputs';
import DefaultDynamicInputsState from './DynamicInputs/DefaultDynamicInputsState';
import DynamicInputsCH from './DynamicInputs/DynamicInputsCH';
import DynamicInputsDE from './DynamicInputs/DynamicInputsDE';
import DynamicInputsDK from './DynamicInputs/DynamicInputsDK';
import DynamicInputsFR from './DynamicInputs/DynamicInputsFR';
import DynamicInputsNO from './DynamicInputs/DynamicInputsNO';
import DynamicInputsSE from './DynamicInputs/DynamicInputsSE';
import { DynamicInputsDefinition } from './DynamicInputsFactory';

type DynamicRegisterDelegate = (translation: AddressFormTranslation, apiClient: IApiClient) => DynamicInputsDefinition;

export const defaultInputDefinition: DynamicRegisterDelegate = (translation: AddressFormTranslation, client) => {
  return {
    state: new DefaultDynamicInputsState(translation, client),
    component: DefaultDynamicInputs,
  };
};

export const dynamicInputsDefinitions = new Map<string, DynamicRegisterDelegate>([
  [
    CountryCode.Denmark,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.Denmark),
        component: DynamicInputsDK,
      };
    },
  ],
  [
    CountryCode.Norway,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.Norway),
        component: DynamicInputsNO,
      };
    },
  ],
  [
    CountryCode.Sweden,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.Sweden),
        component: DynamicInputsSE,
      };
    },
  ],
  [
    CountryCode.Germany,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.Germany),
        component: DynamicInputsDE,
      };
    },
  ],
  [
    CountryCode.Switzerland,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.Switzerland),
        component: DynamicInputsCH,
      };
    },
  ],
  [
    CountryCode.France,
    (translation, client) => {
      return {
        state: new DefaultDynamicInputsState(translation, client, CountryCode.France),
        component: DynamicInputsFR,
      };
    },
  ],
]);
