import { Component } from 'react';

import { TagsTranslations } from '../../localization/SiteTranslation';
import Tags from '../../shared/components/Tags/Tags';
import { FiltersSelectionState } from '../CatalogFiltersState';

interface SelectedFiltersProps {
  className?: string;
  selection: FiltersSelectionState;
  translation: TagsTranslations;
}

export default class SelectedFilters extends Component<SelectedFiltersProps, {}> {
  render() {
    const { selection, className } = this.props;

    return (
      <div className={className}>
        <Tags
          items={selection.properties}
          onItemClick={(item) => selection.deselect(item)}
          clear={() => selection.clear()}
          translation={this.props.translation}
        />
      </div>
    );
  }
}
