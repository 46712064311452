import classNames from 'classnames';
import * as React from 'react';

import Icon from '../../../../../shared/components/Icon/Icon';
import css from './LinkListAccordionHeader.css';

interface LinkListAccordionHeaderProps {
  children: React.ReactChild;
  isExpanded: boolean;
}

const LinkListAccordionHeader = ({ children, isExpanded }: LinkListAccordionHeaderProps) => {
  return (
    <h2 className={classNames(css.container, { [css.active]: isExpanded })}>
      {children}
      <Icon className={css.icon} name={isExpanded ? 'angleUp' : 'angleDown'} />
    </h2>
  );
};

export default LinkListAccordionHeader;
