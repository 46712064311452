import moment from 'moment';

export function formatISODateStringToDottedDate(date: string) {
  if (!date) {
    return '';
  }

  const orderDate = moment(date);
  return orderDate.format('DD.MM.YYYY');
}
