import classNames from 'classnames';
import * as React from 'react';

import { ImageData } from '../../../data/model';
import Icon from '../Icon/Icon';
import PreloadedImage from '../PreloadedImage';
import css from './SelectableRoundImage.css';

interface SelectableRoundImageProps {
  isSelected?: boolean;
  image?: ImageData;
  className?: string;
  classNameInner?: string;
  onClick?: () => void;
  id?: string;
  width?: number;
  height?: number;
  iconClassName?: string;
  customElement?: React.ReactNode;
}

class SelectableRoundImage extends React.Component<SelectableRoundImageProps, {}> {
  render() {
    const { customElement, isSelected, image, className, classNameInner, onClick, id, width, height, iconClassName } =
      this.props;

    const classes = classNames(css.SelectableRoundImage, isSelected && css.isSelected, className);

    return (
      <div className={classes} onClick={onClick}>
        <div className={classNames(css.containerImage, classNameInner)} id={id}>
          {customElement ?? (
            <PreloadedImage
              className={css.image}
              src={image?.url}
              alt={image?.name}
              width={width ? width : 100}
              height={height ? height : 100}
            />
          )}
          {isSelected && <Icon name="check" className={classNames(css.icon, iconClassName)} />}
        </div>
      </div>
    );
  }
}

export default SelectableRoundImage;
