import classNames from 'classnames';
import { observer } from 'mobx-react';

import NavigationArrows from '../../../shared/components/NavigationArrows/NavigationArrows';
import { VisualizationShotsState } from '../../../shared/VisualizationShotsState';

interface ChairSliderNavigationProps {
  shots: VisualizationShotsState;
  className?: string;
  prevClassName?: string;
  nextClassName?: string;
  onClick?: () => void;
}

const ChairSliderNavigation = ({
  shots,
  className,
  nextClassName,
  prevClassName,
  onClick,
}: ChairSliderNavigationProps) => {
  const handleButtonClick = (callback: () => void) => {
    if (onClick) {
      onClick();
    }

    callback();
  };

  const handleButtonPrevClick = () => handleButtonClick(shots.prevSlide);

  const handleButtonNextClick = () => handleButtonClick(shots.nextSlide);

  return (
    <NavigationArrows
      prevIconClassName={classNames(className, prevClassName)}
      nextIconClassName={classNames(className, nextClassName)}
      handleOnClickNext={handleButtonNextClick}
      handleOnClickPrev={handleButtonPrevClick}
    />
  );
};

export default observer(ChairSliderNavigation);
