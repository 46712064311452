import { action, computed, makeObservable, observable } from 'mobx';

import { ConfiguratorState } from '../../app/configurator/ConfiguratorState';
import { IApiClient } from '../../app/data/client';
import {
  AddProductToDealerCartCommand,
  AddProductToFavoritesCommand,
  ShoppingCartItemData,
  UpdateProductConfigurationInDealerCartCommand,
} from '../../app/data/model';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import { Analytics } from '../../app/shared/analytics/Analytics';
import { AsyncCommand } from '../../app/shared/common';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { ModalState } from '../../app/shared/ModalState';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import { StoreState } from '../../app/StoreState';
import { FavoritesUpdatedEvent } from '../cart/events/FavoritesUpdatedEvent';
import { ShoppingCartUpdatedEvent } from '../cart/events/ShoppingCartUpdatedEvent';
import { AddedToCartModalTranslation, ConfigurationActionsTranslation } from '../localization/SiteTranslation';
import RouteDefinitions from '../routing/RouteDefinitions';

export type CartActionType = 'Add' | 'Update';

export class AddedToCartModalState extends ModalState {
  @observable.ref
  public recentlyAddedItem?: ShoppingCartItemData;

  @observable
  public recentlyAddedQuantity?: number;

  constructor(private navigation: INavigationService, public translation: AddedToCartModalTranslation) {
    super();
    makeObservable(this);
  }

  @action.bound
  public setRecentlyAddedItem(item: ShoppingCartItemData, addedQuantity: number) {
    this.recentlyAddedItem = item;
    this.recentlyAddedQuantity = addedQuantity;
  }

  public goToCart = async () => {
    this.close();
    this.navigation.navigateTo(StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.cartRoute)));
  };
}

export class ConfiguratorActionsState {
  @observable.ref
  public recentlyAddedItemModal: AddedToCartModalState;

  public addToCartCommand: AsyncCommand;
  public addToFavoritesCommand: AsyncCommand;
  public updateCartItemCommand: AsyncCommand;

  constructor(
    public readonly configurator: ConfiguratorState,
    public readonly store: StoreState,
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly eventAggregator: IEventAggregator,
    public readonly translation: ConfigurationActionsTranslation,
  ) {
    makeObservable(this);
    this.recentlyAddedItemModal = new AddedToCartModalState(this.navigation, this.translation.addedToCartModal);

    this.addToCartCommand = new AsyncCommand(this.addProductToDealerCart);
    this.addToFavoritesCommand = new AsyncCommand(this.addProductToFavorites);
    this.updateCartItemCommand = new AsyncCommand(this.updateProductInDealerCart);
  }

  @computed
  public get cartActionType(): CartActionType {
    return this.configurator.shoppingCartItemId ? 'Update' : 'Add';
  }

  @computed get isProductInFavorites(): boolean {
    return this.store.favoriteConfigurationCodes.includes(this.configurator.coreState.code);
  }

  private addProductToDealerCart = async () => {
    const command = new AddProductToDealerCartCommand({
      shoppingContext: this.store.shoppingContext,
      productConfiguration: this.configurator.getProductConfiguration(false),
    });

    const response = await this.client.send(command);

    const priceToTrack = response.recentItem.salePriceWithTax.amount;
    Analytics.trackAddToCart(this.configurator.buildAddToCartEvent(priceToTrack));

    this.openRecentItemModal(response.recentItem, response.quantityAdded);
    this.eventAggregator.publish(new ShoppingCartUpdatedEvent(response.dealerCarts.cartsSummary));
  };

  private addProductToFavorites = async () => {
    const command = new AddProductToFavoritesCommand({
      shoppingContext: this.store.shoppingContext,
      productConfiguration: this.configurator.getProductConfiguration(true),
    });

    const response = await this.client.send(command);
    this.eventAggregator.publish(FavoritesUpdatedEvent.fromFavoritesSummary(response.favoritesSummary));
  };

  private updateProductInDealerCart = async () => {
    const command = new UpdateProductConfigurationInDealerCartCommand({
      lineItemId: this.configurator.shoppingCartItemId,
      productConfiguration: this.configurator.getProductConfiguration(false),
      shoppingContext: this.store.shoppingContext,
    });

    const response = await this.client.send(command);
    this.openRecentItemModal(response.updatedItem, response.updatedItem.quantity);
    this.eventAggregator.publish(new ShoppingCartUpdatedEvent(response.dealerCarts.cartsSummary));
  };

  @action.bound
  private openRecentItemModal(lastItem: ShoppingCartItemData, lastItemAddedQuantity: number) {
    this.recentlyAddedItemModal.setRecentlyAddedItem(lastItem, lastItemAddedQuantity);
    this.recentlyAddedItemModal.open();
  }
}
