import classNames from 'classnames';
import * as React from 'react';

import Link, { LinkProps } from '../../../../app/shared/components/Link';
import { RelativeUrl } from '../../../../app/shared/Url';
import css from './SideMenuItem.css';
import { ISideMenuItemState } from './SideMenuItemState';

interface SideMenuItemProps extends Omit<ISideMenuItemState, 'setIsActive'> {
  onClick: (url?: RelativeUrl | string) => void;
}

export default class SideMenuItem extends React.Component<SideMenuItemProps> {
  constructor(props: SideMenuItemProps) {
    super(props);
  }

  render() {
    const { isActive, url, name, onClick } = this.props;
    const linkProps: LinkProps = {
      className: classNames(css.SideMenuItem, isActive && css.active),
      onClick: () => onClick(url),
    };

    if (url instanceof RelativeUrl) {
      linkProps.to = url;
    } else {
      linkProps.href = url;
      linkProps.target = '_blank';
    }

    return <Link {...linkProps}>{name}</Link>;
  }
}

export const SideMenuButton = ({ children, className, ...rest }: React.ComponentProps<'button'>) => (
  <button className={classNames(css.SideMenuItem, css.SideMenuButton, className)} {...rest}>
    {children}
  </button>
);
