import { ArcRotateCameraPointersInput } from '@babylonjs/core';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Scene } from '@babylonjs/core/scene';

class OrthographicCamera extends ArcRotateCamera {
  private readonly activeTouchPoints: PointerEvent[] = [];
  private readonly minZoomLevel = 0.1;

  private touchPointsDistanceCache = -1;
  private zoomLevel = 1;

  private get sceneElement() {
    return this.getEngine().getInputElement();
  }

  public constructor(name: string, radius: number, scene: Scene) {
    super(name, 0, 0, radius, Vector3.Zero(), scene);

    this.mode = Camera.ORTHOGRAPHIC_CAMERA;

    this.disableRotation();
    this.setZooming();

    this.positionPlaneOnScene();
  }

  public positionPlaneOnScene() {
    const engine = this.getEngine();
    const sceneSizeRatio = engine.getRenderHeight() / engine.getRenderWidth();

    this.orthoTop = this.radius * sceneSizeRatio * this.zoomLevel;
    this.orthoRight = this.radius * this.zoomLevel;
    this.orthoBottom = -this.radius * sceneSizeRatio * this.zoomLevel;
    this.orthoLeft = -this.radius * this.zoomLevel;
  }

  private disableRotation() {
    this.upperAlphaLimit = Math.PI;
    this.lowerAlphaLimit = Math.PI;
    this.upperBetaLimit = 0;
    this.lowerBetaLimit = 0;
  }

  private setZooming() {
    this.handleZoomOnMouseWheelEvent();
    this.handleZoomOnPinch();
  }

  private handleZoomOnMouseWheelEvent() {
    this.inputs.attached.mousewheel.attachControl = () => {
      this.sceneElement.addEventListener('wheel', this.handleMouseWheel);
    };
  }

  private handleZoomOnPinch() {
    (this.inputs.attached.pointers as ArcRotateCameraPointersInput).multiTouchPanAndZoom = false;

    const element = this.sceneElement;

    element.addEventListener('pointerdown', this.handleTouchStart);
    element.addEventListener('pointermove', this.handleTouchMove);
    element.addEventListener('pointerup', this.handleTouchEnd);
    element.addEventListener('pointercancel', this.handleTouchEnd);
    element.addEventListener('pointerout', this.handleTouchEnd);
    element.addEventListener('pointerleave', this.handleTouchEnd);
  }

  private handleMouseWheel = (event: WheelEvent) => {
    event.preventDefault();

    const zoomPrecision = 2000;
    const { deltaY } = event;
    const nextZoomLevel = this.zoomLevel + deltaY / zoomPrecision;

    if (nextZoomLevel <= this.minZoomLevel) {
      return;
    }

    this.zoomLevel = nextZoomLevel;
    this.positionPlaneOnScene();
  };

  private handleTouchStart = (event: PointerEvent) => {
    this.activeTouchPoints.push(event);
  };

  private handleTouchMove = (event: PointerEvent) => {
    const zoomPrecision = 5000;
    const index = this.activeTouchPoints.findIndex((x) => x.pointerId === event.pointerId);

    if (index !== -1) {
      this.activeTouchPoints[index] = event;
    }

    if (this.activeTouchPoints.length !== 2) {
      return;
    }

    const currentTouchPointsDistance = Math.abs(this.activeTouchPoints[0].clientX - this.activeTouchPoints[1].clientX);

    if (this.touchPointsDistanceCache > 0) {
      if (currentTouchPointsDistance > this.touchPointsDistanceCache) {
        this.setZoomLevel(this.zoomLevel - currentTouchPointsDistance / zoomPrecision);
      }
      if (currentTouchPointsDistance < this.touchPointsDistanceCache) {
        this.setZoomLevel(this.zoomLevel + currentTouchPointsDistance / zoomPrecision);
      }
      this.positionPlaneOnScene();
    }

    this.touchPointsDistanceCache = currentTouchPointsDistance;
  };

  private handleTouchEnd = (event: PointerEvent) => {
    this.removeTouchEventFromCache(event);

    if (this.activeTouchPoints.length < 2) {
      this.touchPointsDistanceCache = -1;
    }
  };

  private setZoomLevel(value: number) {
    if (value <= this.minZoomLevel) {
      return;
    }

    this.zoomLevel = value;
  }

  private removeTouchEventFromCache(event: PointerEvent) {
    const index = this.activeTouchPoints.findIndex((x) => x.pointerId === event.pointerId);

    if (index === -1) {
      return;
    }

    this.activeTouchPoints.splice(index, 1);
  }
}

export default OrthographicCamera;
