import { isBrowser } from '../shared/common';
import Event from '../shared/Event';

export enum Breakpoint {
  Unknown = 'unknown',
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export enum Orientation {
  Unknown = 'unknown',
  Portrait = 'portrait',
  Landscape = 'landscape',
}

export interface Screen {
  breakpoint: Breakpoint;
  orientation: Orientation;
}

export class ScreenObserver {
  public static current: Screen = { breakpoint: Breakpoint.Unknown, orientation: Orientation.Unknown };
  public static onScreenChange: Event<Screen> = new Event<Screen>();
}

interface BreakpointDefinition {
  breakpoint: Breakpoint;
  minWidth: number;
}

const onBreakpointMatch = (breakpoint: Breakpoint) => {
  ScreenObserver.current = { breakpoint: breakpoint, orientation: ScreenObserver.current.orientation };
  ScreenObserver.onScreenChange.raise(ScreenObserver.current);
};

const onBreakpointQueryChange = (mediaQuery: MediaQueryListEvent, definition: BreakpointDefinition) => {
  if (mediaQuery.matches) {
    onBreakpointMatch(definition.breakpoint);
  }
};

const onOrientationMatch = (orientation: Orientation) => {
  ScreenObserver.current = { breakpoint: ScreenObserver.current.breakpoint, orientation: orientation };
  ScreenObserver.onScreenChange.raise(ScreenObserver.current);
};

const onOrientationQueryChange = (mediaQuery: MediaQueryListEvent, orientation: Orientation) => {
  if (mediaQuery.matches) {
    onOrientationMatch(orientation);
  }
};

// tslint:disable-next-line:no-any
const addEventListener = (mediaQuery: MediaQueryList, listener: (x: MediaQueryListEvent) => any): void => {
  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', listener);
  } else {
    // Safari doesn't support addEventListener
    mediaQuery.addListener(listener);
  }
};

const registerBreakpoints = (definitions: BreakpointDefinition[]) => {
  definitions.forEach((definition, index) => {
    let query = `(min-width: ${definition.minWidth}px)`;

    if (index < definitions.length - 1) {
      const nextDefinition = definitions[index + 1];
      query += ` and (max-width: ${nextDefinition.minWidth - 1}px)`;
    }

    const mediaQuery = window.matchMedia(query);

    if (mediaQuery.matches) {
      onBreakpointMatch(definition.breakpoint);
    }

    addEventListener(mediaQuery, (x) => onBreakpointQueryChange(x, definition));
  });
};

const registerOrientations = (orientations: Orientation[]) => {
  orientations.forEach((orientation) => {
    const query = `(orientation: ${orientation})`;
    const mediaQuery = window.matchMedia(query);

    if (mediaQuery.matches) {
      onOrientationMatch(orientation);
    }

    addEventListener(mediaQuery, (x) => onOrientationQueryChange(x, orientation));
  });
};

if (isBrowser()) {
  registerBreakpoints([
    { breakpoint: Breakpoint.Phone, minWidth: 0 },
    { breakpoint: Breakpoint.Tablet, minWidth: 768 },
    { breakpoint: Breakpoint.Desktop, minWidth: 1200 },
  ]);

  registerOrientations([Orientation.Portrait, Orientation.Landscape]);
}
