import classNames from 'classnames';

import css from './FullPageLoader.css';
import ThreeDotsLoader from './ThreeDotsLoader';

interface FullPageLoaderProps {
  className?: string;
}

const FullPageLoader = ({ className }: FullPageLoaderProps) => {
  return (
    <div className={classNames(css.FullPageLoader, className)}>
      <div className={css.loader}>
        <ThreeDotsLoader className={css.threeDots} size="large" />
      </div>
    </div>
  );
};

export default FullPageLoader;
