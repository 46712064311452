import { nameOf } from '../../../../../app/shared/common';
import { QueryParser } from '../../../../../app/shared/QueryParser';

export interface IProductVariationsPageQuery {
  code?: string;
}

const CODE: string = nameOf<IProductVariationsPageQuery>('code');

export default class ProductVariationsQueryParser extends QueryParser {
  static toModel(query: Map<string, string>): IProductVariationsPageQuery {
    const code = QueryParser.getValue(query, CODE);

    return {
      code: code,
    };
  }

  static toQuery(queryModel: IProductVariationsPageQuery) {
    const query = new Map<string, string>();
    QueryParser.setValue(query, CODE, queryModel.code);

    return query;
  }
}
