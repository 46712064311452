import { OptionDescriptionModalTranslation, RequestQuoteContentTranslation, SiteTranslation } from './SiteTranslation';

// tslint:disable:max-line-length
const requestQuoteDefaultTranslation: RequestQuoteContentTranslation = {
  confirmation: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Page de confirmation',
    buttonLink: 'Continuer vos achats',
    title: 'Merci! Votre devis a été envoyé.',
    content: 'Vous serez contacté très rapidement.',
  },
  goBackLink: 'Revenir',
  title: 'Demander un devis',
  stepsTitle: 'Comment va être traitée votre demande?',
  steps: [
    'Votre demande va être transmise à Flokk France',
    'Vous serez contacté par Flokk France',
    'Une solution adaptée à vos besoins vous sera proposée',
  ],
};

const requestQuoteProcessedByDealerTranslation: RequestQuoteContentTranslation = {
  ...requestQuoteDefaultTranslation,
  confirmation: {
    ...requestQuoteDefaultTranslation.confirmation,
    content: 'Vous serez contacté très rapidement.',
  },
  steps: [
    'L’équipe de vente locale préparera une offre personnelle pour vous.',
    'Notre sympathique équipe de vente transmettra votre demande à un partenaire Flokk certifié qui se chargera du traitement pour nous et répondra à toutes vos questions de manière professionnelle.',
    'Le partenaire certifié vous contactera dans les 5 jours ouvrables avec une offre et des informations sur la livraison et le paiement. Le revendeur certifié vous contactera dès que possible pour finaliser votre commande, organiser le paiement et les détails de livraison.',
  ],
};

const optionDescriptionModal: OptionDescriptionModalTranslation = {
  closeButton:
    'Fermer',
};

const translation: SiteTranslation = {
  layout: {
    topBar: {
      title: 'Flokk',
      chooseChairLink: 'Choisir le siège',
      aboutUsLink: 'A propos de nous',
      inputSearchPlaceholder: 'Chercher',
      languages: {
        chDe: 'Allemand (Suisse)',
        chFr: 'Français (Suisse)',
        deDe: 'Allemand',
        dkDk: 'Danois',
        enUs: 'Anglais (US)',
        frFr: 'Français',
        nlNl: 'Néerlandais',
        nbNo: 'Norvégien',
        enGB: 'Anglais (GB)',
        svSe: 'Suédois',
        plPl: 'Polonais',
      },
    },
    footerPanel: {
      emailLabel: 'e-mail',
      phoneLabel: 'téléphone',
      countactUsLink: 'Contactez la filiale locale',
      followUsLabel: 'Suivez-nous',
      copyright: 'Droits d’auteur',
      allRightReserved: 'Tous droits réservés',
    },
    titleFormat: '{title} - Flokk - Configurez votre siège',
  },
  requestQuotePage: {
    errorMessage: 'Une erreur s’est produite lors du chargement du formulaire. Veuillez contacter l’assistance.',
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Demander un devis',
    defaultContent: requestQuoteDefaultTranslation,
    contentWhenProcessedByDealer: requestQuoteProcessedByDealerTranslation,
  },
  emailMyConfigurationPage: {
    errorMessage:
      'Une erreur est survenue pendant le chargement du formulaire HubSpot. Veuillez contacter le service d’assistance',
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'M’envoyer la configuration par e-mail',
    confirmation: {
      pageDescriptionFormat: 'Description',
      pageTitleFormat: 'M’envoyer la configuration par e-mail',
      buttonLink: 'Envoyer à mon adresse électronique',
      title: 'Merci ! Votre e-mail est en route.',
      content: 'Veuillez consulter votre boîte de réception.',
    },
    goBackLink: 'Retour au récapitulatif',
    title: 'Envoyer ma configuration par e-mail',
  },
  homePage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Page d’accueil',
    articleList: {
      readMore: 'En savoir plus',
    },
    productList: {
      fromPriceLabel: 'À partir de  {price} TTC',
      productThumbnail: {
        productVariationList: {
          title: 'Variations'
        }
      }
    },
  },
  configurationSearchPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Rechercher une configuration',
    title: 'Entrer le code de configuration',
    description: ' et vous serez dirigé vers le configurateur',
    codeInputPlaceholder: 'Taper le code ici et appuyer sur entrer',
    configurationFinder: {
      codeWithIncorrectLengthFormat: 'La valeur devrait avoir des caractères exacts {expectedLength}.',
      codeInvalid: 'Le code fourni {invalidCode} est invalide! Assurez-vous qu’il soit entré correctement.',
      codeNotFound: 'Impossible de trouver la configuration du produit par code {notFoundCode}',
    },
  },
  configurationDetailsPage: {
    pageTitleFormat:
      '*{code} - Configuration details',
    pageDescriptionFormat:
      '*{productName} {options} {accessories}',
    title:
      '*Your selected configuration',
    visualizationNotAvailableText:
      '*Visualization not available',
  },
  confirmationPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Confirmation de commande',
    title: 'Félicitations!',
    errorTitle: "Une erreur s'est produite",
    paymentConfirmed: 'Votre paiement a été confirmé. Nous espérons que votre achat sur notre site s’est bien passé.',
    checkYourEmail:
      "Pour plus d'informations sur votre commande et la livraison, veuillez consulter votre boîte de réception.",
    errorContent: 'Une erreur est survenue. Veuillez contacter le service d’assistance.',
    orderSection: {
      title: 'Votre commande',
      orderNumber: 'Numéro de commande',
    },
    summarySection: {
      title: 'Récapitulatif',
      taxText: '+ TVA',
      totalToPayText: 'total',
      contact: {
        contactAndBilling: 'Coordonnées et informations de facturation',
        deliveryAddress: 'Adresse de livraison',
      },
    },
    designerSectionTitle: 'Savez-vous qui a créé votre siège ?',
    customerStorySectionTitle: 'Savez-vous bien vous asseoir ?',
    readMore: 'EN SAVOIR PLUS',
    contactUsSection: {
      text: 'Si vous avez des questions, contactez notre',
      linkText: 'service client',
    },
  },
  checkoutPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Paiement',
    title: 'Vérifier',
    goBackLink: 'Revenir',
    progress: {
      title: 'Paiement',
      deliveryStep: 'Livraison',
      paymentStep: 'Paiement',
      confirmationStep: 'Confirmation',
    },
    goToPaymentButton: 'Confirmer et payer avec',
    paymentMethodLabel: 'Comment souhaitez-vous payer ?',
    orderAndPayButton: 'Commander et payer',
    submitButton: 'Envoyer',
    paymentPoweredByText: 'Nous utilisons une solution de paiement proposée par',
    addressForm: {
      city: {
        name: 'Ville',
        placeholder: 'Entrer la ville',
        fieldRequired: 'La ville est requise',
      },
      country: {
        name: 'Pays',
        placeholder: 'Entrer le pays ',
        fieldRequired: 'Le pays est requis',
      },
      street: {
        name: 'Rue',
        placeholder: 'Entrer la rue',
        fieldRequired: 'La rue est requise',
      },
      streetNumber: {
        name: 'Numéro de rue',
        placeholder: 'Entrer le numéro de rue',
        fieldRequired: 'Le numéro de rue est requis',
      },
      zipCode: {
        name: 'Code postale',
        fieldRequired: 'Le code postal est requis',
        wrongFormat: 'La valeur entrée n’est pas un code postal valide',
      },
      availableCountries: {
        POL: 'Pologne',
        NOR: 'Norvège',
        CHE: 'Suisse',
        DEU: 'Allemagne',
        DNK: 'Danemark',
        FRA: 'France',
        NLD: 'Pays-Bas',
        SWE: 'Suède',
        GBR: 'Royaume-Uni',
        USA: 'Etats Unis',
      },
      addressLine1: {
        name: 'Ligne d’adresse 1',
        placeholder: '*',
        fieldRequired: 'La ligne d’adresse 1 doit être remplie.',
        wrongFormat: '*',
      },
      addressLine2: {
        name: 'Ligne d’adresse 2',
        placeholder: '*',
        fieldRequired: '*',
        wrongFormat: '*',
      },
      recipient: {
        name: 'Destinataire',
        placeholder: '*',
        fieldRequired: 'Le nom du destinataire est obligatoire.',
        wrongFormat: '*',
      },
    },
    contactForm: {
      contactDetails: 'Détail du contact',
      email: {
        name: 'E-mail',
        placeholder: 'Entrer l’e-mail',
        fieldRequired: 'Le-mail est requis',
        wrongFormat: 'La valeur entrée n’est pas une adresse mail ',
      },
      phone: {
        name: 'Téléphone',
        placeholder: 'Entrer le numéro de téléphone',
        fieldRequired: 'Le numéro de téléphone est requis',
        wrongFormat: 'La valeur saisie n’est pas un numéro de téléphone valide.',
      },
      phonePrefix: {
        name: 'Prefix',
        placeholder: 'Entrer le préfixe',
        fieldRequired: 'Le préfixe est obligatoire',
        wrongFormat: '*Entered value is not a valid phone prefix',
      },
      firstName: {
        name: 'Prénom',
        placeholder: 'Entrer le prénom',
        fieldRequired: 'Le prénom est requis',
      },
      lastName: {
        name: 'Nom',
        placeholder: 'Nom d’usage',
        fieldRequired: 'Le nom est requis',
      },
      companyName: {
        name: 'Nom de la société (optionnel)',
        placeholder: '**',
      },
      taxId: {
        name: 'Numéro d’identité fiscale de l’entreprise',
        placeholder: '',
        fieldRequired: 'Le numéro d’identité fiscale de l’entreprise est obligatoire.',
      },
    },
    shippingMethodSection: {
      subtitle: 'Méthode de livraison',
      shippingMethod: {
        name: 'Shipping Method',
        fieldRequired: 'Ce champ est obligatoire.',
      },
    },
    deliveryAddressSection: {
      subtitle: 'Adresse de livraison',
      billingAndDeliveryAreTheSameText: 'L’adresse de facturation est identique à l’adresse de livraison.',
      deliveryInfoText: 'Livraison à domicile gratuite sous 10-15 jours',
    },
    paymentMethods: {
      supportedCreditCardsMessage: {
        acceptedCreditCardsText: 'Nous acceptons les cartes de paiement suivantes',
      },
    },
    readyToOrderSection: {
      subtitle: 'Prêt à passer commande ?',
      termsAndConditions: {
        termsAndConditionLinkName: 'Conditions générales',
        name: 'J’accepte les conditions générales',
        placeholder: '*',
        fieldRequired: 'Ce champ est obligatoire.',
      },
      subscription: 'J’aimerais recevoir plus de...',
    },
    readyToOrderPaymentSection: {
      subtitle: 'Prêt à passer commande ?',
    },
    errors: {
      creatingOrderFailed:
        'Impossible de passer la commande. Veuillez réessayer ultérieurement ou contacter le service d’assistance.',
      initializingPaymentFailed:
        'Échec du paiement. Veuillez réessayer ultérieurement ou contacter le service d’assistance.',
      authorizingPaymentFailed:
        'Autorisation refusée. Veuillez réessayer ultérieurement ou contacter le service d’assistance.',
      finalizingPaymentFailed:
        'Échec du paiement. Veuillez réessayer ultérieurement ou contacter le service d’assistance.',
    },
    discountCodeForm: {
      applyButton: 'Appliquer',
      discountCodeIsAppliedMessage: 'Le code de réduction "{code}" a été appliqué.',
      discountCodeIsInvalidOrExpiredError: 'Le code saisi est invalide ou a expiré.',
      doYouHaveDiscountCodeText: 'Avez-vous un code promotionnel ?',
      enterCodeText: 'Saisissez votre code ici',
      label: 'Code de réduction',
    },
  },
  checkoutShortcutPage: {
    pageTitleFormat:
      'Checkout shortcut',
    pageDescriptionFormat:
      '',
    errors: {
      addingProductToCartFailed:
        'Unable to purchase product configuration with code {code}. Please contact customer support.',
      gettingProductConfigurationFailed:
        'Unable to retrieve information about product configuration with the code {code}. Please refresh page or contact customer support.',
    }
  },
  configurationPage: {
    pageDescriptionFormat: '{productName} {options} {accessories}',
    pageTitleFormat: '{productName} - Configurateur',
    productDetails: {
      documentsSection: 'Documents',
      moreInformationSection: 'Plus d’informations',
      specificationSection: 'Informations produit',
    },
    showFiltersText: '*Show filters',
    hideFiltersText: '*Hide filters',
    productModels: {
      chooseModelText: 'Choisissez votre modèle',
      modelSelectorWithLoader: {
        noOtherModelsAvailable: 'Aucun autre modèle n’est disponible.',
      },
    },
    options: {
      title: 'Configurer le siège',
      priceGroup: 'Groupe de prix',
      textSelected: 'Selectionner',
      fabricFilters: {
        noFabricsAvailable: 'Il n’existe pas de revêtements correspondant à votre demande.',
        clearButton: 'Effacer',
        defaultFilterName: 'Tout sélectionner',
        collectionFilterLabel: 'Collection'
      },
    },
    accessories: {
      title: 'Accessoires',
      accessory: {
        removeButton: 'Suppprimer',
        addButton: 'Ajouter',
        textSelected: 'Sélectionner',
      },
    },
    summary: {
      title: 'Résumé de votre commande',
      accessoriesSubtitle: 'Accessoires',
      requestQuoteButton: 'Demander un devis',
      emailConfirmationButton: 'Envoyer ma configuration par e-mail',
      limitedChairQuantity: 'Pour commander plus de {max} sièges, veuillez nous contacter.',
      checkoutButton: 'Payer',
      editConfigurationLink: 'Editer',
      expectedShipment: {
        expectedShipment: 'Expédition sous',
        days: 'jours ouvrés',
      },
      quantityInput: {
        outOfRangeWarning: 'La valeur doit se situer entre {min} et {max}',
      },
      saveYourConfigurationHereButton: 'Sauvegarder votre configuration',
      backButton: 'Retour',
      shareSection: {
        title: 'Enregistrez votre configuration',
        yourConfigurationUrl: 'Votre URL de configuration',
        downloadAsPdf: 'Télécharger en version PDF',
        downloadVisualizations: 'Télécharger le document',
        codeShare: {
          codeTitle: 'Votre code de configuration',
          copyCode: {
            label: 'Copier le code',
            textCopiedLabel: 'Copié',
          },
        },
        copyLink: {
          label: 'Copier le lien',
          textCopiedLabel: 'Copié',
        },
        closeButton: 'Fermer',
      },
      findLocalDealerSection: {
        title: 'Où trouver Flokk?',
        button: 'Trouver un revendeur',
      },
    },
    restrictions: {
      accessorySelection: {
        header: {
          userSelectionNotRequiredFormat: 'Etes-vous sur de vouloir ajouter l’accessoire {itemName}?',
          userSelectionRequiredFormat: 'Si vous souhaitez ajouter {itemName} vous devez changer vos options',
        },
        automaticDeselectionOfAccessoriesFormat:
          'L’accessoire sélectionné n’est pas disponible avec {itemsToDeselect}. Si vous choisissez cet accessoire, les accessoires ci-dessous seront supprimés de votre commande.',
      },
      optionSelection: {
        header: {
          userSelectionNotRequiredFormat: `Etes-vous sur de vouloir changer l’option {itemName}?`,
          userSelectionRequiredFormat: `Si vous souhaitez changer l’option {itemName}, vous devez sélectionner une autre option`,
        },
        automaticDeselectionOfAccessoriesFormat:
          'L’option sélectionnés n’est pas disponible avec {itemsToDeselect}. Si vous choisissez cette option, les accessoires ci-dessous seront supprimés de votre commande.',
      },
      itemNotAvailableWithFormat: 'L’option {itemToSelect} n’est pas disponible avec l’option {itemsToDeselect}. Vous trouverez les options compatibles ci-dessous.',
      itemsSeparator: ' et ',
      selectMatchingOption: ' Vous trouverez les options compatibles ci-dessous.',
      cancelButton: 'Annuler',
      applyButton: 'Accepter et modifier',
      closeButton: 'Fermer',
      selectedText: 'Sélecionner',
    },
    tabs: {
      fabrics: {
        name: 'Revêtements',
      },
      options: {
        name: 'Options',
      },
      accessories: {
        name: 'Accessoires',
      },
      summary: {
        name: 'Résumé',
      },
    },
    productNotAvailableInCurrentStoreWarning: {
      messageFormat:
        'Nous sommes désolés, mais nous ne pouvons pas afficher ce produit pour le moment. Si vous avez des questions, n’hésitez pas à contacter notre service client à l’adresse info @flokk.com.',
    },
    productInactiveWarning: {
      discontinued: '*This is a discontinued product',
      generic: '*Product is not available',
      genericWithReason: '*Product is not available: {reason}'
    },
    obsoleteCodeModal: {
      notAvailable: {
        title: 'La configuration sur ce siège n’est pas disponible ',
        intro: 'La configuration demandée n’est pas disponible.',
        introWithCodePart1: 'La configuration demandée avec ce code',
        introWithCodePart2: 'n’est pas disponible.',
        explanation: 'Les pièces ou matériaux demandés ne peuvent plus être utilisés:',
      },
      restricted: {
        title: 'La configuration sur ce siège n’est pas valide',
        intro: 'La configuration demandée n’est pas valide.',
        introWithCodePart1: 'La configuration demandée avec ce code',
        introWithCodePart2: 'n’est pas valide.',
        explanation: 'Cela peut être dû à des changements dans la configuration du siège.',
      },
      footer: 'Veuillez reconfigurer votre chaise.',
      closeButton: 'Fermer',
    },
    optionDescriptionModal,
    metaTitleFormat: 'Mon {productName}',
    metaDescriptionFormat: '{productName}: {options}',
    priceTaxText: 'TTC',
  },
  modularConfiguratorPage: {
    pageDescriptionFormat: '{productName} {options} {accessories}',
    pageTitleFormat: '{productName} - Configurateur',
  },
  storeAndLanguageSelector: {
    title: 'Choisir mon pays et la langue',
    storeSelectorLabel: 'Pays',
    languageSelectorLabel: 'Langue',
  },
  initialStoreSelectorModal: {
    title: 'Bonjour',
    description: "Choisissez votre pays pour voir le contenu pertinent à l'endroit où vous vous trouvez",
    buttonContinue: 'Continuer',
    inputPlaceholder: 'Choisissez votre pays',
    languageInputPlaceholder: 'Choisir la langue',
    linkLabelInternationalStore: 'Aller à la page internationale',
    noOptionMessage: 'Ce pays est introuvable',
    noLanguageMessage: 'Cette langue est introuvable',
  },
  noConnectionModal: {
    title: 'Oups!',
    content: 'Aucune connexion internet trouvée.',
    continueLink: 'Continuer',
    buttonSeparator: 'ou',
    refreshButton: 'Actualiser la page',
    closeButton: 'Fermer',
  },
  emptyCartPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Panier vide',
    basketEmptyLine1: 'Votre panier est vide',
    basketEmptyLine2: 'mais il ne doit pas l’être.',
    continueButton: 'Continuer vos achats',
  },
  pageNotFound: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Page non trouvée',
    content: 'Nous ne trouvons pas la page que vous recherchez.',
    title: 'Oh, non!',
    buttonLink: 'Retour à l’accueil',
  },
  generalErrorPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Erreur générale',
    title: 'Erreur interne du serveur!',
    content: 'Veuillez réessayer plus tard',
    buttonLink: 'Réessayer',
  },
  invalidCodePage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Code de la page invalide',
    title: 'Code de configuration invalide',
    content: 'Veuillez fournir un code valide',
    buttonLink: 'Revenir à l’accueil Flokk',
  },
  productSpecificationPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Informations produit',
    accessoriesSection: 'Accessoires',
    linkTitle: 'Votre lien de configuration',
    codeTitle: 'Votre code de configuration',
    pricesSection: {
      unitPrice: 'Prix unitaire',
      quantity: 'Qté',
      subtotalPrice: 'Total',
    },
    dataSection: {
      title: 'Données',
      brand: 'Marque',
      code: 'Ref. art',
    },
  },
  storeLocatorPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Nous trouver',
    heading: 'Trouver un contact Flokk',
    storeSearch: {
      noDealersText: 'Désolé, nous ne sommes pas présents dans cette région.',
      noDealersWithFiltersText: 'Désolé nous ne sommes pas presents dans la région sélectionnée par filtre. ',
      filterResultText: '{storesNumber} Contact trouvé',
      storeLocatorInput: {
        label: 'Trouver un contact',
        placeholder: 'Ville, département ou code postal',
        orText: 'ou',
        currentLocationText: 'utiliser l’emplacement actuel',
        disabledGeolocationText: 'La géolocalisation est désactivée.',
        unavailableGeolocationText: 'La géolocalisation est indisponibles.',
        tryAgainText: 'Réessayer.',
      },
      storeLocatorFilters: {
        title: 'Filtres',
        placesLabel: 'Type de lieux',
        brandLabel: 'Marques',
      },
    },
    storeTypes: {
      Showroom: 'Showroom',
      Shop: 'Boutique',
    },
    details: {
      webSite: 'Website',
      address: 'Addresse',
      directionLink: 'Instructions',
      email: 'E-mail',
      openHours: 'Opening Hours Heures d’ouverture ',
      phone: 'Téléphone',
      closeButton: 'Fermer',
      backButton: 'Revnir à la liste',
    },
  },
  cart: {
    summary: {
      deliveryFee: 'Frais de livraison',
      subTotal: 'Price before VAT',
      totalTaxFormat: 'Vat ({taxTotal}%)',
      subTotalWithTax: 'Total price including VAT',
      totalToPay: 'Total à payer',
      moreInformationText: "Plus d'informations",
      lessInformationText: "Moins d'informations",
      quantityText: 'Quantité',
      shippingTimeText: {
        deliveredWithin: 'Livraison sous',
        day: 'jour',
        days: 'jours',
        week: 'semaine',
        weeks: 'semaines',
      },
      configurationCodeLabel: 'Référence',
      deliveryCostUnavailable: 'Sélectionner la méthode de livraison',
      freeDeliveryText: 'Livraison à domicile gratuite',
      taxText: '+ TVA',
      discount: 'Réduction',
    },
  },
  redirectionWarningModal: {
    closeButton: 'Fermer',
    title:
      'Vous allez être redirigé automatiquement dans quelques secondes. Interagissez avec l’écran pour annuler la redirection.',
  },
  scrollToTop: {
    button: 'Haut de la page',
  },
  productFamilyPage: {
    pageDescriptionFormat: 'description',
    pageTitleFormat: 'Page produit',
    overviewSection: {
      name: "Vue d'ensemble",
    },
    designerSection: {
      name: 'Designers',
      designedBy: 'Conçu par',
      readMore: 'EN SAVOIR PLUS',
    },
    relatedProducts: {
      name: 'Produits associés',
      title: 'Ces produits peuvent aussi vous intéresser',
    },
    productUserStories: {
      name: 'Projets',
      readMore: 'EN SAVOIR PLUS',
      title: 'Projet',
    },
    modelsSection: {
      name: 'Modèles',
      productInfo: {
        priceSection: {
          taxText: 'TTC',
          fromText: 'à partir de',
        },
        popularDesigns: {
          title: 'Les configurations populaires',
        },
        actions: {
          configureChairButton: 'Crée le tien',
          askForPriceButton: 'Demander un devis',
          goToCheckoutButton: 'Ajouter au panier',
          whereToBuyButton: 'Où acheter nos produits',
        },
        whereToBuy: {
          text: 'Nous vendons nos produits par le biais d’un réseau de partenaires agréés.',
          designCustomChairText:
            'Toutefois, si vous souhaitez créer un siège sur mesure, vous pouvez concevoir votre propre modèle ci-dessus et nous vous enverrons un devis.',
        },
      },
      productModelSelector: {
        heading: 'Choisissez votre variante',
      },
      modelViewer: {
        hint: "Cliquez pour agrandir l'image",
      },
    },
    productModelDetailsSection: {
      assetsDownloader: {
        downloadAllButton: 'Télécharger tous les actifs',
        other: 'Autres',
        categories: {},
      },
      downloadsSectionTitle: 'Téléchargements',
      environmentalDataSection: {
        averagedValuesDisclaimerText:
          'Toutes les valeurs sont des moyennes pour ce modèle, pour certains produits les chiffres peuvent être différents. Les valeurs présentées sont basées sur les EPD disponibles par produit, soit la norme +A1:2013 ou +A2:2019, et seront continuellement mises à jour en fonction des nouvelles déclarations.',
        energyUsageMjText: 'Consommation d’énergie',
        co2EmissionKgText: 'Émissions de dioxyde de carbone',
        recyclableMaterialsText: 'matériaux recyclables',
        recycledAluminiumText: 'aluminium recyclé',
        recycledMaterialsTitle: 'Part de matériaux recyclés',
        recycledMaterialsText: 'de matériaux recyclés',
        recycledPlasticText: 'plastique recyclé',
        recyclableMaterialsTitle: 'Taux de recyclage',
      },
      environmentalDataSectionTitle: 'Données environnementales',
      name: 'Informations',
      specificationSectionTitle: 'Caractéristiques',
    },
    suggestedConfigurationsSection: {
      name: 'Variations',
      title: 'Vous avez une demande spécifique?',
      subtitle: 'Chosissez vos options : couleurs, tissus, matériaux, accessoires et autres.',
      buttonText: 'Crée le tien',
    },
    gallerySection: {
      name: 'Galerie',
    },
    userGuideVideoSection: {
      name: '',
      videoTitle: 'Manuel de l’utilisateur',
    },
  },
  viewerPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Visualiser',
  },
  zoomModal: {
    closeButton: 'Fermer',
  },
  common: {
    chairVisualization: {
      zoomHintText: 'Toucher pour zoomer',
      hintText: "Cliquez sur l'image pour ouvrir le mode zoom",
      renderingError: 'Désolé, il est impossible de charger la page de visualisation pour cette configuration.',
      webGLUnsupportedError:
        'Désolé, il est impossible de charger la page de visualisation car WebGL n’est pas pris en charge par votre navigateur.',
    },
    tags: {
      clearResults: 'Tout supprimer',
    },
  },
  catalogPage: {
    pageTitleFormat: 'Produits',
    pageDescriptionFormat: 'Trouvez votre siège ',
    filterBar: {
      name: 'Filters',
      results: 'Résultats: {results}',
    },
    configurationFinder: {
      codeWithIncorrectLengthFormat: 'La valeur devrait avoir des caractères exacts {expectedLength}.',
      codeInvalid: 'Le code fourni {invalidCode} est invalide! Assurez-vous qu’il soit entré correctement.',
      codeNotFound: 'Impossible de trouver la configuration du produit par code {notFoundCode}',
    },
    productFamily: {
      showMoreProducts: "Plus d'options disponibles",
    },
    searchForConfigurationText: 'Vous avez déjà défini un modèle ? Trouvez votre configuration',
    noResults: 'Aucun produit ne correspond à vos préférences',
    pagination: {
      title: 'Pagination',
    },
  },
  disclaimer: {
    messagePart1: "L'apparence de la couleur dépend de la configuration de votre appareil ou de votre écran. ",
    link: 'Contactez nous',
    messagePart2: " Si vous avez besoin d'aide pour choisir la bonne couleur et/ou le bon revêtement.",
  },
  requestQuoteLink: {
    link: 'Vous achetez pour votre entreprise? Demander un devis ici',
  },
  unauthorizedErrorPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Accès refusé',
    title: 'Accès refusé',
    content: "L'accès n'est autorisé que pour les utilisateurs enregistrés.",
    buttonLink: 'Se connecter',
  },
  forbiddenErrorPage: {
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Accès interdit',
    title: 'Oh, non !',
    content1: 'Désolé, vous n’avez pas accès à cette page ou cette ressource.',
    buttonLink: 'Revenir à la page d’accueil',
  },
  downloadsPage: {
    downloadList: {
      download: {
        downloadButton: 'Télécharger',
      },
      emptyScreen: {
        title: 'Aucun fichier ne correspond à vos critères',
        description: 'Désolé, nous ne semblons pas avoir ce que vous recherchez. Veuillez essayer d’autre filtres.',
      },
      results: 'Il existe {results} des fichiers correspondant à vos critères.',
      pagination: {
        title: 'Pagination',
      },
    },
    filters: {
      title: 'Filtres',
      brandsFilter: {
        name: 'Marques populaires',
      },
      categoryFilter: {
        name: 'Catégorie',
        fileTypeFilter: {
          name: 'Type de fichier',
        },
        resultsCounter: 'Tous les résultats',
      },
      downloadAllButton: 'Tout télécharger',
    },
    pageTitleFormat: 'Télécharger',
    pageDescriptionFormat:
      'Trouvez et téléchargez des ressources sur les produits, telles que des fiches techniques, des images, des fichiers CAD, etc.',
  },
  readyMadeProductPage: {
    overviewSection: {
      name: 'Vue d’ensemble',
    },
    variationsSection: {
      name: 'Variations',
    },
    specificationSection: {
      name: 'Caractéristiques techniques',
    },
    productDetailsSection: {
      specificationSectionTitle: 'Caractéristiques techniques',
      maintenanceSectionTitle: 'Entretien',
      downloadsSectionTitle: 'Téléchargements',
      assetsDownloader: {
        downloadAllButton: 'Télécharger tous les actifs',
        other: 'Autres',
        categories: {},
      },
      productCare: {
        downloadGuide: 'Télécharger le guide ici',
      },
    },
    productVariations: {
      addToCartButton: 'Ajouter au panier',
      buyButton: 'Acheter',
      deliveryTime: {
        deliveryTime: 'Délai de livraison',
        day: 'jour',
        days: 'jours*',
        week: 'semaine',
        weeks: 'semaines*',
      },
      priceIncludingTax: 'TTC, hors frais de livraison',
      deliveryTimeAnnotation: '* Le délai de livraison peut être plus long pour certaines configurations',
      modelViewer: {
        hint: "Cliquez pour agrandir l'image",
      },
      customizationSection: {
        textLine1: 'Envie d’originalité ?',
        textLine2: 'Vous pouvez',
        linkText: 'personnaliser le modèle de votre choix ici',
      },
      productOptions: {
        fabricInfo: {
          text: '*Fabric information:',
          optionDescriptionModal,
        },
        fabricDescriptionCannotBeLoadedError:
          '*Sorry, something went wrong. Cannot load the information about the fabric.',
      },
    },
    productCarousel: {
      title: 'Nos clients ont également consulté ces modèles',
    },
  },
};

export default translation;
