import { action, computed, makeObservable, override } from 'mobx';

import {
  ConfigurationSearchPageState,
  IConfigurationSearchPageMemento,
} from '../../../app/configurationSearch/ConfigurationSearchPageState';
import { BasePageState } from '../../../app/shared/BasePageState';
import { PageMetadata } from '../../../app/shared/common';
import { StoreState } from '../../../app/StoreState';
import { HomePageState, IHomePageMemento } from './HomePageState';

interface IHomePageWithFallbackMemento {
  homeMemento: IHomePageMemento;
  configurationSearchMemento: IConfigurationSearchPageMemento;
}

export class HomePageWithFallbackState extends BasePageState<IHomePageWithFallbackMemento> {
  constructor(public homeState: HomePageState, public configurationSearchState: ConfigurationSearchPageState) {
    super();
    makeObservable(this);
  }

  @computed
  get homePageContentDefined(): boolean {
    const { pageContent, products } = this.homeState;

    return (
      !!pageContent?.header ||
      !!pageContent?.articleList ||
      !!pageContent?.gallery ||
      !!pageContent?.productList ||
      products.any()
    );
  }

  @override
  get metadata(): PageMetadata {
    return this.homePageContentDefined ? this.homeState.metadata : this.configurationSearchState.metadata;
  }

  @action
  public async onLoad(store: StoreState) {
    await Promise.all([this.homeState.onLoad(store), this.configurationSearchState.onLoad(store)]);
  }

  public getMemento(): IHomePageWithFallbackMemento {
    return {
      homeMemento: this.homeState.getMemento(),
      configurationSearchMemento: this.configurationSearchState.getMemento(),
    };
  }

  public restoreMemento(memento: IHomePageWithFallbackMemento) {
    this.homeState.restoreMemento(memento.homeMemento);
    this.configurationSearchState.restoreMemento(memento.configurationSearchMemento);
  }
}
