import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';

import { RawConfiguratorSize } from '../RawConfiguratorSize';
import { SimplifiedFeatureSelectionState } from '../RawConfiguratorState';
import css from './ConfiguratorFeatures.css';
import FeatureSelection from './FeatureSelection/FeatureSelection';

interface ConfiguratorFeaturesProps {
  features: SimplifiedFeatureSelectionState[];
  size: RawConfiguratorSize;
  onSelectionChange: (featureSelection: SimplifiedFeatureSelectionState, selectedOptionCode: string) => void;
  className?: string;
}

const ConfiguratorFeatures = observer(({ className, features, size, onSelectionChange }: ConfiguratorFeaturesProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [features.length]);

  return (
    <div ref={ref} className={className}>
      {features.map((featureSelection) => {
        return (
          <FeatureSelection
            selection={featureSelection}
            containerClassName={classNames(css.feature, css[size])}
            className={css.select}
            labelClassName={css.label}
            label={featureSelection.feature.name}
            value={featureSelection.selectedOption?.code}
            onChange={(e) => onSelectionChange(featureSelection, e.target.value)}
            key={featureSelection.feature.code}
            size={size}
          />
        );
      })}
    </div>
  );
});

export default ConfiguratorFeatures;
