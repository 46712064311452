import { ShoppingContext } from '../../data/model';

export interface EventData {
  context: ShoppingContext;
}

export interface LinkClickEventData {
  name?: string;
  url?: string;
}

export interface ProductsEventData extends EventData {
  products: ProductData[];
}

export interface ProductEventData extends EventData {
  product: ProductData;
}

export interface ButtonClickEventData extends ProductsEventData {
  buttonName: string;
}

export interface CartEventData extends ProductsEventData {
  source?: CartEventSource;
}

export interface CheckoutEventData extends EventData {
  stepId: string;
  stepNumber: number;
  option?: string;
  products?: ProductData[];
}

export interface CheckoutOptionEventData extends EventData {
  stepId: string;
  stepNumber: number;
  option: string;
}

export interface PurchaseEventData extends ProductsEventData {
  orderNumber: string;
  revenue: number; // total, including tax and shipping
  tax: number;
  shipping: number;
  coupon?: string;
}

export interface ProductData {
  id: string;
  name: string;
  price?: number;
  brand?: string;
  category?: string;
  configurationCode?: string;
  list?: string;
  position?: number;
  quantity?: number;
}

export enum CartEventSource {
  Cart = 'Cart',
  Configurator = 'Configurator',
  OrderHistory = 'Order History',
  WebShop = 'Web Shop',
}

export interface ITrackingService {
  trackPageVisit(relativeUrl: string): void;

  trackButtonClick(data: ButtonClickEventData): void;

  trackAddToCart(data: ProductsEventData): void;

  trackRemoveFromCart(data: ProductsEventData): void;

  trackCheckoutStep(data: CheckoutEventData): void;

  trackCheckoutOption(data: CheckoutOptionEventData): void;

  trackCheckoutCompleted(data: PurchaseEventData): void;

  trackAskForPriceCompleted(data: ProductsEventData): void;

  trackDownload(data: LinkClickEventData): void;
}

export class ConsoleTrackingService implements ITrackingService {
  // tslint:disable:no-console
  trackPageVisit(relativeUrl: string): void {
    console.log('trackPageVisit', relativeUrl, window.document.title);
  }

  trackAddToCart(data: ProductsEventData): void {
    console.log('trackAddToCart', data);
  }

  trackRemoveFromCart(data: ProductsEventData): void {
    console.log('trackRemoveFromCart', data);
  }

  trackButtonClick(data: ButtonClickEventData): void {
    console.log('trackButtonClick', data);
  }

  trackDownload(data: LinkClickEventData): void {
    console.log('trackDownloadLinkClick', data);
  }

  trackCheckoutStep(data: CheckoutEventData): void {
    console.log('trackCheckoutStep', data);
  }

  trackCheckoutOption(data: CheckoutOptionEventData): void {
    console.log('trackCheckoutOption', data);
  }

  trackCheckoutCompleted(data: PurchaseEventData): void {
    console.log('trackCheckoutCompleted', data);
  }

  trackAskForPriceCompleted(data: ProductsEventData): void {
    console.log('trackAskForPriceCompleted', data);
  }

  // tslint:enable:no-console
}
