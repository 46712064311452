import { autorun } from 'mobx';
import { observer } from 'mobx-react';

import Map from '../../../../b2c/shared/components/Map/Map';
import { PhysicalStoreData } from '../../../data/model';
import StoreMapState from './StoreMapState';
import StoreMarkers from './StoreMarkers/StoreMarkers';

interface StoreMapProps {
  state: StoreMapState;
  onItemSelected: (x: PhysicalStoreData) => void;
}

const StoreMap = ({ state, onItemSelected }: StoreMapProps) => {
  const handleIdle = (map: google.maps.Map) => {
    state.setBounds(map.getBounds().toJSON());
    state.setCenter(map.getCenter().toJSON(), false);
    state.setZoom(map.getZoom());
    state.updateClusters();
  };

  const setInitialZoomAndCenter = (map: google.maps.Map) => {
    state.mapInitialized();

    autorun(() => {
      if (map && state.focusToBounds?.length > 0) {
        const bounds = new google.maps.LatLngBounds();
        state.focusToBounds.forEach((e) => bounds.extend(e));
        const center = bounds.getCenter();
        map.setCenter({ lat: center.lat(), lng: center.lng() });
        map.fitBounds(bounds);
      }
    });
  };

  return (
    <Map
      apiKey={state.gApiKey}
      language={state.language}
      zoom={state.zoom}
      center={state.center}
      onIdle={handleIdle}
      onDragEnd={state.enableSort}
      onLoad={setInitialZoomAndCenter}
    >
      <StoreMarkers state={state} onItemSelected={onItemSelected} />
    </Map>
  );
};

export default observer(StoreMap);
