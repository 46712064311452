import { observer } from 'mobx-react';
import { Component, default as React, Fragment } from 'react';

import { FinalVisualizationBuilder } from '../../configurator/VisualizationBuilder';
import { GetProductConfigurationByCodeQueryResponse, ProductConfigurationOrigin } from '../../data/model';
import { ShoppingCartSummaryTranslation } from '../../localization/SiteTranslation';
import ComponentList from '../components/ComponentList/ComponentList';
import { ExpandablePanel } from '../components/ExpandablePanel/ExpandablePanel';
import Icon from '../components/Icon/Icon';
import { LoadableImage } from '../components/LoadableImage';
import HeaderLevel4 from '../components/Typography/HeaderLevel4';
import { HubSpotFormWithProductState } from './HubSpotFormWithProductState';
import css from './ProductDetailsPreview.css';

interface OptionsAndAccessoriesProps {
  data: GetProductConfigurationByCodeQueryResponse;
  translation: ShoppingCartSummaryTranslation;
}

const OptionsAndAccessories = ({ data, translation }: OptionsAndAccessoriesProps) => {
  const renderPanelButton = (isExpanded: boolean) => (
    <div className={css.buttonContainer}>
      <button className={css.button}>
        {isExpanded ? (
          <Fragment>
            {translation.lessInformationText} <Icon name="minus" className={css.icon} />
          </Fragment>
        ) : (
          <Fragment>
            {translation.moreInformationText} <Icon name="plus" className={css.icon} />
          </Fragment>
        )}
      </button>
    </div>
  );

  return data ? (
    <ExpandablePanel renderTitle={renderPanelButton} expanded={true} titleClassName={css.expandableList}>
      <ComponentList accessories={data.accessories} options={data.options} />
    </ExpandablePanel>
  ) : null;
};

interface ProductDetailsPreviewProps {
  state: HubSpotFormWithProductState;
  translation: ShoppingCartSummaryTranslation;
}

@observer
export default class ProductDetailsPreview extends Component<ProductDetailsPreviewProps> {
  render() {
    const state = this.props.state;
    const code = state.getCode();
    const name = state.getName();

    const imageUrl =
      state.productConfigurationResponse.configuration.origin === ProductConfigurationOrigin.DefaultConfigurator
        ? FinalVisualizationBuilder.buildUrl(code)
        : undefined;

    return (
      <div>
        {imageUrl && (
          <div className={css.previewImageContainer}>
            <LoadableImage
              className={css.previewImage}
              classNameUnloaded={css.unloaded}
              src={imageUrl}
              width={380}
              height={570}
              sharpeningFactor={1.5}
            />
          </div>
        )}
        <div className={css.previewSummaryContainer}>
          <div className={css.previewSummaryContent}>
            <HeaderLevel4 className={css.previewItemName}>
              {name} ({code})
            </HeaderLevel4>
            <OptionsAndAccessories data={state.productConfigurationResponse} translation={this.props.translation} />
          </div>
        </div>
      </div>
    );
  }
}
