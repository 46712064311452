import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import CookieConsentResolver, {
  CookieCategory,
} from '../../../../b2c/shared/components/CookieConsentResolver/CookieConsentResolver';
import ExternalImage from '../ExternalImage/ExternalImage';
import IconPlay from '../Icon/CustomIcons/IconPlay';

// tslint:disable:ordered-imports
import 'glightbox/dist/css/glightbox.min.css';
import '../../../../styles/vendors/glightbox.css';
import css from './ModalVideoPlayer.css';

const VIDEO_LIBRARY_SETTINGS = {
  css: 'plyr.css',
  js: 'plyr.js',
  ratio: '16:9',
  config: {
    storage: {
      enabled: false,
    },
  },
};

interface ModalVideoPlayerProps {
  videoUrl: string;
  imageUrl: string;
  title: string;
  id: string;
  isTitleSmall?: boolean;
}

interface ModalVideoPlayerState {
  atLeastOneTimeOpen: boolean;
  isOpen: boolean;
}

@observer
export class ModalVideoPlayer extends Component<ModalVideoPlayerProps, ModalVideoPlayerState> {
  private lightBox: GLightboxObject;

  constructor(parameters: ModalVideoPlayerProps) {
    super(parameters);
  }

  componentDidMount() {
    const glightbox = require('glightbox');

    this.lightBox = glightbox({
      selector: `.${this.props.id}`,
      plyr: VIDEO_LIBRARY_SETTINGS,
    });
  }

  componentWillUnmount() {
    this.lightBox.destroy();
  }

  renderContent() {
    return (
      <>
        <div className={css.videoTitleContainer}>
          <div className={css.videoTitleWrapper}>
            <div className={css.playIconContainer}>
              <IconPlay />
            </div>
            <span className={classNames(css.videoTitle, { [css.small]: this.props.isTitleSmall })}>
              {this.props.title}
            </span>
          </div>
        </div>
        <ExternalImage className={css.videoPoster} src={this.props.imageUrl} />
      </>
    );
  }

  render() {
    return (
      <div className={css.ModalVideoPlayer}>
        <CookieConsentResolver
          requiredCookieCategory={CookieCategory.Marketing}
          placeholder={<div className={css.videoContainer}>{this.renderContent()}</div>}
        >
          <a className={classNames(this.props.id, css.videoContainer)} href={this.props.videoUrl}>
            {this.renderContent()}
          </a>
        </CookieConsentResolver>
      </div>
    );
  }
}
