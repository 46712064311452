import { action, IObservableArray, makeObservable, observable } from 'mobx';

import { AccessoryState } from '../configurator/components/AccessoriesConfigurator/AccessoryState';
import { IOptionState } from '../configurator/OptionState';
import { RestrictionData, RestrictionType } from '../data/model';
import { IFeature, IItem } from './common';
import Logger from './Logger';
import { ModalState } from './ModalState';

export interface RestrictionParty {
  feature: IFeature;
  item: IItem;
  canBeDeselected: boolean;
}

export interface Restriction {
  target: RestrictionParty;
  dependencies: Array<RestrictionParty>;
}

export class Restrictions {
  target: RestrictionParty;
  restrictions: Array<Restriction>;

  constructor(target: RestrictionParty, restrictions: Array<Restriction>) {
    this.target = target;
    this.restrictions = restrictions;
  }

  getRestrictingDependenciesForCurrentSelection() {
    return this.getRestrictingDependencies((x) => x.item.selected).filter((x) => x.feature.enabled);
  }

  getRestrictingDependenciesFor(items: Array<IItem>) {
    return this.getRestrictingDependencies((x) => items.contains(x.item));
  }

  private getRestrictingDependencies(predicate: (x: RestrictionParty) => boolean) {
    return this.restrictions
      .mapMany((restriction) => restriction.dependencies)
      .filter((dependency) => predicate(dependency));
  }
}

export interface IRestrictionsFactory {
  create(target: RestrictionParty, restrictionsData: Array<RestrictionData>): Restrictions;

  finalizeCreation(): void;
}

class RestrictionDependencyToInject {
  dependencyId: string;
  restriction: Restriction;

  constructor(dependencyId: string, restriction: Restriction) {
    this.dependencyId = dependencyId;
    this.restriction = restriction;
  }
}

export class NotRestrictedSelection {
  feature: IFeature;
  items: Array<IItem>;
  selectedItem: IItem;

  constructor(feature: IFeature, items: Array<IItem>) {
    makeObservable(this);
    this.feature = feature;
    this.items = items;
    this.selectedItem = feature.getPreselectionStrategy().suggestValidItem(items) ?? items[0];
  }

  @action setSelectedItem(item: IItem) {
    this.selectedItem = item;
  }
}

export interface IRestrictionsResolver {
  checkRestrictionsAndSelect(item: IItem): void;

  getRestrictingDependencies(item: IItem): Array<RestrictionParty>;

  isRestricted(item: IItem): boolean;

  getSuggestedSelectionsForDependentFeatures(item: IItem): Array<IItem>;
}

export interface IRestrictionsInitializer {
  registerRestrictions(party: RestrictionParty): IRestrictionsResolver;

  setEnablingItems(options: IOptionState[], accessories: Array<IFeature>): void;
}

interface RestrictionsCheckResult {
  target: RestrictionParty;
  restrictingDependencies: Array<RestrictionParty>;
}

export class RestrictionsEvaluator {
  public restrictionsMap: Map<string, Restrictions>;

  constructor(restrictionsMap: Map<string, Restrictions>) {
    this.restrictionsMap = restrictionsMap;
  }

  public checkRestrictionsForCurrentSelection(itemToBeSelected: IItem): RestrictionsCheckResult {
    const restrictions = this.restrictionsMap.get(itemToBeSelected.id);
    const restrictingDependencies = restrictions.getRestrictingDependenciesForCurrentSelection();

    return { target: restrictions.target, restrictingDependencies };
  }

  public checkRestrictionsFor(items: Array<IItem>): Array<RestrictionParty> {
    let restrictingDependencies = new Array<RestrictionParty>();

    items.forEach((item) => {
      const restrictions = this.restrictionsMap.get(item.id);
      const otherItems = items.exclude([item]);
      restrictingDependencies.push(...restrictions.getRestrictingDependenciesFor(otherItems));
    });

    return restrictingDependencies;
  }
}

export class RestrictionResolutionStep {
  private evaluator: RestrictionsEvaluator;
  public selectedItems: Array<IItem> = [];
  public selectedAlongTheWay: Array<IItem> = [];

  @observable.ref target: RestrictionParty;
  @observable.ref restricting: RestrictionParty;

  @observable.shallow selections: Array<NotRestrictedSelection> = [];
  @observable.shallow itemsToDeselect: Array<RestrictionParty> = [];
  @observable.shallow itemsToSelect: Array<RestrictionParty> = [];

  constructor(evaluator: RestrictionsEvaluator, selectedItems: Array<IItem>, selectedAlongTheWay: Array<IItem>) {
    makeObservable(this);
    this.evaluator = evaluator;
    this.selectedItems = selectedItems;
    this.selectedAlongTheWay = selectedAlongTheWay;

    this.populate();
  }

  public getRestrictingDependencies() {
    return this.evaluator
      .checkRestrictionsFor(this.selectedItems)
      .filter((x) => !this.selectedAlongTheWay.contains(x.item));
  }

  public get isRestricted() {
    return this.getRestrictingDependencies().any();
  }

  public createNext() {
    let selectedItems = this.selectedItems.exclude(this.itemsToDeselect.map((x) => x.item));

    let selectedAlongTheWay = new Array<IItem>();

    this.selections.forEach((x) => {
      selectedItems = selectedItems.exclude(x.feature.getAllItems());
      selectedItems.push(x.selectedItem);
      selectedAlongTheWay.push(x.selectedItem);
    });

    selectedItems.push(...this.itemsToSelect.map((x) => x.item));
    selectedAlongTheWay.push(...this.itemsToSelect.map((x) => x.item));

    return new RestrictionResolutionStep(this.evaluator, selectedItems, [
      ...this.selectedAlongTheWay,
      ...selectedAlongTheWay,
    ]);
  }

  private populate() {
    const restrictingDependencies = this.getRestrictingDependencies();

    if (!restrictingDependencies.any()) {
      return;
    }

    const limitedDependencies = [this.getFirstRelevantDependency(restrictingDependencies)];
    const notRestrictedSelection = this.getNotRestrictedSelections(limitedDependencies);
    const itemsToDeselect = this.getItemsToDeselect(limitedDependencies);
    const itemsToSelect = this.getItemsToSelect(itemsToDeselect);

    if (notRestrictedSelection.empty() && itemsToDeselect.empty()) {
      Logger.warn(`Unable to resolve restriction for ${limitedDependencies[0].item.id}`);
    }

    this.setSelections(notRestrictedSelection);
    this.setItemsToDeselect(itemsToDeselect);
    this.setItemsToSelect(itemsToSelect);

    this.target = this.evaluator.restrictionsMap.get(this.selectedAlongTheWay.last().id).target;
    this.restricting = limitedDependencies.first();
  }

  private getFirstRelevantDependency(restrictingDependencies: Array<RestrictionParty>): RestrictionParty {
    const notAutoresolvableRestriction = restrictingDependencies.find(
      (x) => x.feature.canBeAutoResolvedWhenRestricting() === false,
    );
    if (notAutoresolvableRestriction) {
      return notAutoresolvableRestriction;
    }
    return restrictingDependencies[0];
  }

  private getNotRestrictedSelections(restrictingDependencies: Array<RestrictionParty>): Array<NotRestrictedSelection> {
    let selections = new Array<NotRestrictedSelection>();

    restrictingDependencies
      .filter((x) => !x.canBeDeselected)
      .forEach((dependency) => {
        const items = dependency.feature.getAllItems();
        const itemsToCheck = items.exclude(this.selectedItems);
        const notRestrictedItems = itemsToCheck.filter((x) =>
          this.evaluator.checkRestrictionsFor([...this.selectedAlongTheWay, x]).empty(),
        );

        if (notRestrictedItems.any()) {
          const selection = new NotRestrictedSelection(dependency.feature, notRestrictedItems);
          selections.push(selection);
        }
      });

    return selections;
  }

  private getItemsToDeselect(restrictingDependencies: Array<RestrictionParty>): Array<RestrictionParty> {
    return restrictingDependencies.filter((x) => x.canBeDeselected);
  }

  private getItemsToSelect(itemsToDeselect: Array<RestrictionParty>): Array<RestrictionParty> {
    const itemsToSelect = new Array<RestrictionParty>();
    const rawItemsToDeselect = itemsToDeselect.map((x) => x.item);

    this.selectedAlongTheWay.forEach((x) => {
      const feature = this.evaluator.restrictionsMap.get(x.id).target.feature;

      if (
        feature.enablers.any() &&
        feature.enablers
          .intersection(this.selectedItems.exclude(this.selectedAlongTheWay))
          .exclude(rawItemsToDeselect)
          .empty()
      ) {
        const notRestrictedEnablers = feature.enablers
          .exclude(rawItemsToDeselect)
          .filter((enabler) =>
            this.evaluator.checkRestrictionsFor([...this.selectedItems.exclude(rawItemsToDeselect), enabler]).empty(),
          )
          .map((enabler) => this.evaluator.restrictionsMap.get(enabler.id).target)
          .filter((enabler) => enabler.canBeDeselected);

        if (notRestrictedEnablers.any()) {
          itemsToSelect.push(notRestrictedEnablers.first());
        }
      }
    });

    return itemsToSelect;
  }

  @action setSelections(selections: Array<NotRestrictedSelection>): void {
    (this.selections as IObservableArray<NotRestrictedSelection>).clear();
    this.selections.push(...selections);
  }

  @action setItemsToDeselect(items: Array<RestrictionParty>): void {
    (this.itemsToDeselect as IObservableArray<RestrictionParty>).clear();
    this.itemsToDeselect.push(...items);
  }

  @action setItemsToSelect(items: Array<RestrictionParty>): void {
    (this.itemsToSelect as IObservableArray<RestrictionParty>).clear();
    this.itemsToSelect.push(...items);
  }

  canBeAutoResolved() {
    return (
      (this.restricting && !this.restricting.feature.enabled) ||
      this.targetRestrictedByAccessoryRepresentingTheSameFeature() ||
      this.restricting?.feature.canBeAutoResolvedWhenRestricting()
    );
  }

  private targetRestrictedByAccessoryRepresentingTheSameFeature(): boolean {
    return this.restricting?.canBeDeselected && this.target?.feature.data.code === this.restricting?.feature.data.code;
  }
}

export class RestrictionResolutionSequence {
  private selectedItems: Array<IItem>;
  private steps: Array<RestrictionResolutionStep> = [];

  @observable.ref
  currentStep: RestrictionResolutionStep;

  constructor(evaluator: RestrictionsEvaluator, selectedItems: Array<IItem>, itemToSelect: IItem) {
    makeObservable(this);
    this.selectedItems = selectedItems;

    const feature = evaluator.restrictionsMap.get(itemToSelect.id).target.feature;

    this.currentStep = new RestrictionResolutionStep(
      evaluator,
      [...selectedItems.exclude(feature.getAllItems()), itemToSelect],
      [itemToSelect],
    );
    this.steps.push(this.currentStep);

    while (this.currentStep.canBeAutoResolved()) {
      this.createNextStep();
    }
  }

  public applyChanges() {
    this.currentStep.selectedItems.forEach((x) => x.select());

    this.selectedItems
      .exclude(this.currentStep.selectedItems) // TODO: do it better, maybe use IFeature or something
      .forEach((x) => x.deselect());
  }

  public continue() {
    this.createNextStep();

    while (this.currentStep.canBeAutoResolved()) {
      this.createNextStep();
    }
  }

  public back() {
    this.currentStep = this.steps.pop();
  }

  private createNextStep() {
    this.currentStep = this.currentStep.createNext();
    this.steps.push(this.currentStep);
  }
}

export class RestrictionsResolver implements IRestrictionsResolver, IRestrictionsInitializer {
  private factory: IRestrictionsFactory;
  private modal: IResolveRestrictionsModal;
  private restrictionsMap: Map<string, Restrictions>;
  private restrictions: Array<Restrictions>;
  public restrictionsEvaluator: RestrictionsEvaluator;

  constructor(factory: IRestrictionsFactory, modal: IResolveRestrictionsModal) {
    this.factory = factory;
    this.modal = modal;

    this.restrictionsMap = new Map<string, Restrictions>();
    this.restrictions = new Array<Restrictions>();
  }

  registerRestrictions(party: RestrictionParty) {
    const restrictions = this.factory.create(party, party.item.restrictions);

    this.restrictionsMap.set(party.item.id, restrictions);
    this.restrictions.push(restrictions);

    return this;
  }

  finalizeRegistration() {
    this.factory.finalizeCreation();
    this.restrictionsEvaluator = new RestrictionsEvaluator(this.restrictionsMap);
  }

  setEnablingItems(options: IOptionState[], accessories: Array<AccessoryState>): void {
    const features: Array<IFeature> = [...options, ...accessories];

    features.forEach((feature) => {
      const enablingItems = new Array<IItem>();

      feature.data.enabledBy.forEach((variationDescriptor) => {
        // TODO: change when options and accessories modes are unified
        const accessory = accessories.find((x) => x.id === variationDescriptor.variationId);
        if (accessory) {
          enablingItems.push(...accessory.getAllItems());
          accessory.dependentFeatures.push(feature);
          return;
        }

        const optionVariation = options
          .mapMany((x) => x.getAllItems())
          .find((x) => x.id === variationDescriptor.variationId);
        if (optionVariation) {
          enablingItems.push(optionVariation);
          optionVariation.dependentFeatures.push(feature);
          return;
        }
      });

      feature.setEnablingItems(enablingItems, feature.data.enabledByOperator);
    });
  }

  checkRestrictionsAndSelect(itemToBeSelected: IItem): void {
    const target = this.restrictionsMap.get(itemToBeSelected.id).target;

    const selectedItems = this.restrictions
      .filter((x) => x.target.feature.enabled)
      .map((x) => x.target.item)
      .filter((x) => x.selected);
    const dependentItems = this.tryPreselectDependentFeatures(selectedItems, itemToBeSelected);
    const itemsToCheck = selectedItems.concat(dependentItems);

    const sequence = new RestrictionResolutionSequence(this.restrictionsEvaluator, itemsToCheck, itemToBeSelected);

    if (sequence.currentStep.isRestricted) {
      this.showModal(target, sequence);
    } else {
      sequence.applyChanges();
    }
  }

  getRestrictingDependencies(itemToBeSelected: IItem): Array<RestrictionParty> {
    const result = this.restrictionsEvaluator.checkRestrictionsForCurrentSelection(itemToBeSelected); // TODO: check dependencies
    return result.restrictingDependencies;
  }

  isRestricted(itemToBeSelected: IItem): boolean {
    return this.getRestrictingDependencies(itemToBeSelected).any();
  }

  private showModal(toBeSelected: RestrictionParty, sequence: RestrictionResolutionSequence) {
    this.modal.setSequence(sequence);
    this.modal.open();
  }

  private tryPreselectDependentFeatures(selectedItems: IItem[], itemToBeSelected: IItem) {
    itemToBeSelected.dependentFeatures
      .filter((x) => !x.enabled)
      .forEach((dependentFeature) => {
        const validItems = dependentFeature.getAllItems().filter((x) => !this.isRestricted(x));
        if (validItems.any()) {
          dependentFeature
            .getPreselectionStrategy()
            .preselectDependentItem(itemToBeSelected, selectedItems, validItems);
        }
      });

    return itemToBeSelected.dependentFeatures.map((x) => x.getSelectedItem()).filter((x) => !!x);
  }

  getSuggestedSelectionsForDependentFeatures(item: IItem): Array<IItem> {
    return item.dependentFeatures
      .map((dependentFeature) => {
        const validItems = dependentFeature.getAllItems().filter((x) => !this.isRestricted(x));
        return dependentFeature.getPreselectionStrategy().suggestValidItem(validItems);
      })
      .filter((x) => !!x);
  }
}

export class RestrictionsFactory implements IRestrictionsFactory {
  private map: Map<string, RestrictionParty> = new Map();
  private dependenciesToInject: Array<RestrictionDependencyToInject> = new Array();

  create(target: RestrictionParty, restrictionsData: Array<RestrictionData>): Restrictions {
    this.map.set(target.item.id, target);
    let restrictions = new Array<Restriction>();

    restrictionsData.forEach((data) => {
      if (data.type !== RestrictionType.NotAvailableIf) {
        return; // Other restriction types are not supported
      }

      const restriction: Restriction = {
        target: target,
        dependencies: [],
      };

      restrictions.push(restriction);

      data.entitiesIds.forEach((entityId) => {
        this.dependenciesToInject.push(new RestrictionDependencyToInject(entityId, restriction));
      });
    });

    return new Restrictions(target, restrictions);
  }

  private injectDependencies() {
    this.dependenciesToInject.forEach((x) => {
      const dependency = this.map.get(x.dependencyId);
      if (dependency) {
        x.restriction.dependencies.push(dependency);
      }
    });
  }

  finalizeCreation() {
    this.injectDependencies();
  }
}

export interface IResolveRestrictionsModal {
  setSequence(sequence: RestrictionResolutionSequence): void;

  open(): void;

  close(): void;
}

export class ResolveRestrictionsModalState extends ModalState implements IResolveRestrictionsModal {
  @observable.ref sequence: RestrictionResolutionSequence;

  constructor() {
    super();

    makeObservable(this);
  }

  @action setSequence(sequence: RestrictionResolutionSequence) {
    this.sequence = sequence;
  }

  @action.bound continue() {
    this.sequence.continue();

    if (!this.sequence.currentStep.isRestricted) {
      this.sequence.applyChanges();
      this.sequence = null;
      this.close();
    }
  }

  @action.bound back() {
    this.sequence.back();
  }
}
