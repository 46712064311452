import classNames from 'classnames';

import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import { ButtonProps } from './Button';
import css from './TextButton.css';

type LoaderPosition = 'leftOut' | 'rightOut' | 'center' | 'leftIn' | 'rightIn';

interface TextButtonProps extends ButtonProps {
  hideName?: boolean;
  loaderPosition?: LoaderPosition;
  childrenClassName?: string;
  loaderClassName?: string;
  isHighlighted?: boolean;
}

const TextButton = ({
  className,
  children,
  isHighlighted,
  isLoading,
  hideName,
  loaderPosition,
  childrenClassName,
  loaderClassName,
  ...buttonProps
}: TextButtonProps) => {
  const classes = classNames(css.TextButton, isHighlighted && css.highlighted, className);

  const childrenClasses = classNames(hideName && isLoading && css.hide);
  const loaderClasses = classNames(loaderPosition ? css[loaderPosition] : css.rightOut, loaderClassName);

  return (
    <button {...buttonProps} className={classes}>
      <span className={classNames(childrenClasses, childrenClassName)}>{children}</span>
      {isLoading && <ThreeDotsLoader className={loaderClasses} size="small" />}
    </button>
  );
};

export default TextButton;
