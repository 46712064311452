import { action, makeObservable, observable, override, reaction } from 'mobx';

import { IApiClient } from '../../app/data/client';
import { GetDealerOrdersQuery, OrderAggregatedStatus, OrderData, ShoppingContext } from '../../app/data/model';
import { BasePageState } from '../../app/shared/BasePageState';
import { PageMetadata } from '../../app/shared/common';
import { PaginationState } from '../../app/shared/components/Pagination/PaginationState';
import { LoadingIndicator } from '../../app/shared/LoadingIndicator';
import { INavigationService } from '../../app/shared/NavigationService';
import { Tab } from '../../app/shared/Tabs/Tab';
import { Tabs } from '../../app/shared/Tabs/Tabs';
import { ICancellableTask, RunLastTasksScheduler } from '../../app/shared/TasksScheduler';
import { StoreState } from '../../app/StoreState';
import { OrdersPageTranslation } from '../localization/SiteTranslation';
import { OrderListFiltersState } from './OrderListFilters/OrderListFiltersState';
import { OrderQuery, OrderQueryParser } from './OrderQueryParser';

export class OrderListTab extends Tab {
  constructor(id: string, title: string, public status: OrderAggregatedStatus) {
    super(id, title);
  }
}

export class LoadOrdersTask implements ICancellableTask {
  public cancelled = false;
  public query: GetDealerOrdersQuery;

  constructor(query: GetDealerOrdersQuery) {
    this.query = query;
  }
}

export class OrderListState extends BasePageState<never> {
  @observable orderQuery: OrderQuery;
  public ordersTabs: Tabs;

  public ongoingOrdersTab: Tab;
  public pastOrdersTab: Tab;

  public shoppingContext: ShoppingContext;

  @observable public orders: OrderData[] = [];

  public loadingIndicator: LoadingIndicator;
  public orderListFilters: OrderListFiltersState;
  public pagination: PaginationState;

  private loadOrdersScheduler: RunLastTasksScheduler<LoadOrdersTask, void>;

  constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    public readonly translation: OrdersPageTranslation,
  ) {
    super();
    makeObservable(this);
    this.navigation = navigation;
    this.loadingIndicator = new LoadingIndicator();
    this.orderQuery = OrderQueryParser.toModel(this.navigation.currentUrl.query);

    this.initializeTabs();
    this.orderListFilters = new OrderListFiltersState(this.orderQuery, this.translation.ordersListFilter);
    this.pagination = new PaginationState(20);
    this.pagination.pageChanged.subscribe(() => this.loadOrders());

    this.loadOrdersScheduler = new RunLastTasksScheduler(this.runLoadOrdersTask);

    reaction(
      () => this.ordersTabs.activeTab,
      () => {
        this.pagination.resetSilently();
        this.loadOrders();
      },
    );
  }

  private initializeTabs = () => {
    this.ongoingOrdersTab = new OrderListTab(
      'ongoingOrders',
      this.translation.ongoingOrdersTitle,
      OrderAggregatedStatus.Ongoing,
    );
    this.pastOrdersTab = new OrderListTab('pastOrders', this.translation.pastOrdersTitle, OrderAggregatedStatus.Past);

    this.ordersTabs = new Tabs([this.ongoingOrdersTab, this.pastOrdersTab]);
    this.ordersTabs.setActiveTab(this.ongoingOrdersTab);
  };

  @override
  get metadata(): PageMetadata {
    return {
      title: 'Order history',
    };
  }

  async onLoad(store: StoreState) {
    this.shoppingContext = store.shoppingContext;
    this.loadOrders();
  }

  @action.bound
  private setOrders(orders: Array<OrderData>) {
    this.orders = orders;
  }

  private loadOrders = () => {
    const query = new GetDealerOrdersQuery({
      shoppingContext: this.shoppingContext,
      sortColumn: this.orderListFilters.selectedSortOrderItem.column,
      sortDirection: this.orderListFilters.selectedSortOrderItem.direction,
      searchTerm: this.orderListFilters.searchInput.value,
      onlyMine: this.orderListFilters.onlyMineInput.value,
      dateFrom: this.orderListFilters.dateFrom?.toISOString(),
      dateTo: this.orderListFilters.dateTo?.toISOString(),
      status: (this.ordersTabs.activeTab as OrderListTab).status,
      skip: this.pagination.startIndex,
      take: this.pagination.pageSize,
    });

    this.loadOrdersScheduler.run(new LoadOrdersTask(query));
  };

  private runLoadOrdersTask = async (task: LoadOrdersTask) => {
    this.loadingIndicator.start();

    const response = await this.client.send(task.query);

    if (task.cancelled) {
      return;
    }

    this.setOrders(response.orders);
    this.pagination.setTotalCount(response.totalCount);

    const query = OrderQueryParser.toQuery({ phrase: this.orderListFilters.searchInput.value });
    this.navigation.setQuery(query);

    this.loadingIndicator.reset();
  };

  public searchOrders = () => {
    this.pagination.resetSilently();
    this.loadOrders();
  };

  public resetFilters = () => {
    this.orderListFilters.clearFilters();
    this.pagination.resetSilently();
    this.loadOrders();
  };
}
