import { observer } from 'mobx-react';
import * as moment from 'moment';
import { Component } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import css from './DateRange.css';
import './DateRangePicker.css';

interface DateRangeProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  setDateRange: (startDate: moment.Moment, endDate: moment.Moment) => void;
  disabled?: boolean;
}

interface DateRangeState {
  focusedInput: FocusedInputShape | null;
}

@observer
class DateRange extends Component<DateRangeProps, DateRangeState> {
  constructor(props: DateRangeProps) {
    super(props);

    this.state = { focusedInput: null };
  }

  render() {
    return (
      <>
        <div className={css.dataRangeLabels}>
          <label className={css.dateRangeLabel}>Date from </label>
          <label className={css.dateRangeLabel}>Date to </label>
        </div>
        <DateRangePicker
          startDate={this.props.startDate}
          startDateId="startDate"
          startDatePlaceholderText="DD - MM - YYYY"
          endDatePlaceholderText="DD - MM - YYYY"
          endDate={this.props.endDate}
          endDateId="endDate"
          onDatesChange={({ startDate, endDate }) => {
            this.props.setDateRange(startDate, endDate);
          }}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          keepFocusOnInput={false}
          customArrowIcon={<div className={css.dateRangeSeparator} />}
          calendarInfoPosition="bottom"
          hideKeyboardShortcutsPanel
          displayFormat="DD - MM - YYYY"
          isOutsideRange={() => false}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

export default DateRange;
