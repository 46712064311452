import Notification from '../../app/shared/components/Notification/Notification';
import { RelativeUrl } from '../../app/shared/Url';
import { EmptyFavoritesListTranslation } from '../localization/SiteTranslation';

interface Props {
  continueLink: RelativeUrl;
  translation: EmptyFavoritesListTranslation;
}

const EmptyFavoritesList = ({ continueLink, translation }: Props) => {
  return (
    <Notification
      icon={{ name: 'favorites', colorGrey: true }}
      title={translation.titleText}
      subtitle={translation.subtitleText}
      links={[
        {
          to: continueLink,
          label: translation.labelText,
          displayAs: 'button',
        },
      ]}
    />
  );
};

export default EmptyFavoritesList;
