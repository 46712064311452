import { action, autorun, IObservableArray, makeObservable, observable } from 'mobx';

import { ObjectState } from './ObjectState';

export class GroupState extends ObjectState {
  @observable.shallow
  public objects: IObservableArray<ObjectState> = [] as IObservableArray<ObjectState>;

  constructor(name: string, objects: ObjectState[] = []) {
    super(name);
    makeObservable(this);
    this.objects.push(...objects);

    autorun(() => {
      this.objects.forEach((x) => x.setSelected(this.selected));
    });
  }

  @action
  addObject(object: ObjectState) {
    this.objects.push(object);
  }

  @action
  removeObject(object: ObjectState) {
    this.objects.remove(object);
  }
}
