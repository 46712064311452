import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useResizeDetector } from 'react-resize-detector';

import useFullscreen from '../../../../../../b2c/shared/hooks/useFullscreen';
import BabylonScene from '../../../../../3dEngine/babylon/BabylonScene';
import { MaterialPresenter } from '../../../../../3dEngine/babylon/presenters/MaterialPresenter';
import { OpacityPresenter } from '../../../../../3dEngine/babylon/presenters/OpacityPresenter';
import { PositionPresenter } from '../../../../../3dEngine/babylon/presenters/PositionPresenter';
import { RotationPresenter } from '../../../../../3dEngine/babylon/presenters/RotationPresenter';
import { SelectionPresenter } from '../../../../../3dEngine/babylon/presenters/SelectionPresenter';
import { CubeVisualFactory } from '../../../../../3dEngine/babylon/visuals/CubeVisualFactory';
import { GroupVisualFactory } from '../../../../../3dEngine/babylon/visuals/GroupVisualFactory';
import { LoadableVisualFactory } from '../../../../../3dEngine/babylon/visuals/LoadableVisualFactory';
import ThreeDotsLoader from '../../../../../shared/components/Loaders/ThreeDotsLoader';
import ModularConfiguratorCoordinatorState from '../../ModularConfiguratorCoordinatorState';
import css from './ModularConfiguratorPreview.css';
import ModularConfiguratorToolbar from './ModularConfiguratorToolbar/ModularConfiguratorToolbar';

const factories = [new CubeVisualFactory(), new LoadableVisualFactory(), new GroupVisualFactory()];
const presenters = [
  new OpacityPresenter(),
  new PositionPresenter(),
  new RotationPresenter(),
  new SelectionPresenter(),
  new MaterialPresenter(),
];

interface ModularConfiguratorPreviewProps {
  state: ModularConfiguratorCoordinatorState;
  className?: string;
  readonly?: boolean;
}

const ModularConfiguratorPreview = observer(
  ({ className, state, readonly = false }: ModularConfiguratorPreviewProps) => {
    const { width, height, ref } = useResizeDetector();
    const fullscreen = useFullscreen(ref);

    return (
      <div ref={ref} className={classNames(css.container, className)}>
        {!readonly && <ModularConfiguratorToolbar className={css.toolbar} fullscreen={fullscreen} state={state} />}
        <BabylonScene
          className={css.scene}
          size={{ width, height }}
          state={state.configurator.scene}
          factories={factories}
          presenters={presenters}
          onObjectClick={(o) => state.configurator.selectComponent(o)}
          onNoneObjectClick={() => state.configurator.unselectComponent()}
        />
        {!readonly && (
          <div className={css.hint}>
            {fullscreen.isActive ? 'Scroll to zoom, drag to pan' : 'Scroll to zoom, drag to rotate'}
          </div>
        )}

        {state.configurator.loadingIndicator.isLoading && (
          <div className={css.loader}>
            <ThreeDotsLoader className={css.inner} size="large" />
          </div>
        )}
      </div>
    );
  },
);

export default ModularConfiguratorPreview;
