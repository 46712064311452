import { action, computed, makeObservable, observable } from 'mobx';

import { TabData } from '../../../data/model';
import { ConfiguratorPageTranslation } from '../../../localization/SiteTranslation';
import { IOptionState } from '../../OptionState';
import { Tab } from '../../Tab';
import { AccessoryState } from '../AccessoriesConfigurator/AccessoryState';
import OptionsConfigurator from '../OptionsConfigurator';
import { DynamicTabState } from './DynamicTabState';
import { TabState } from './TabState';

export class TabsState {
  @observable tabs: Array<TabState | DynamicTabState>;
  defaultTab: TabState;

  constructor(
    tabs: Array<TabData>,
    activeTabCode: string,
    options: Array<IOptionState>,
    accessories: Array<AccessoryState>,
    translation: ConfiguratorPageTranslation,
    additionalTabs: TabState[] = [],
    private brandId: string,
  ) {
    makeObservable(this);
    this.tabs = this.initializeTabsStates(tabs, options, accessories, translation);

    this.tabs.push(...additionalTabs); // new TabState(Tab.Summary, translation.tabs));
    this.defaultTab = this.tabs[0];

    this.setInitActiveTab(activeTabCode);
  }

  private setInitActiveTab(tabCode: string) {
    return this.getTab(tabCode) ? this.setActiveTab(tabCode) : this.setActiveTab(this.defaultTab.code);
  }

  private initializeTabsStates(
    tabs: Array<TabData>,
    options: Array<IOptionState>,
    accessories: Array<AccessoryState>,
    translation: ConfiguratorPageTranslation,
  ) {
    return tabs
      .map((tab, index) => {
        const tabOptions = this.groupTabItems(tabs, options)
          .find((x) => x.code === tab.code)
          .items.map((x) => x as IOptionState);
        const tabAccessories = this.groupTabItems(tabs, accessories)
          .find((x) => x.code === tab.code)
          .items.map((x) => x as AccessoryState);
        const features = [...tabOptions, ...tabAccessories].sort((x, y) => x.index - y.index);

        return new DynamicTabState(
          tab.code,
          translation.tabs,
          OptionsConfigurator,
          {
            translation,
            options: tabOptions,
            accessories: tabAccessories,
            features: features,
            brandId: this.brandId,
          },
          index,
        );
      })
      .filter((x) => x.features.any());
  }

  private groupTabItems = (tabs: Array<TabData>, items: Array<IOptionState> | Array<AccessoryState>) => {
    return tabs.map((tab) => {
      return { code: tab.code, items: [...items].filter((x) => x.tabCode === tab.code) };
    });
  };

  @computed
  get activeTab() {
    return this.tabs.find((tab) => tab.isActive);
  }

  @computed
  get dynamicTabs() {
    return this.tabs.filter((tab) => tab.code !== Tab.Summary).map((x) => x as DynamicTabState);
  }

  @computed
  get inactiveDynamicsTabs() {
    return this.dynamicTabs.filter((tab) => !tab.isActive);
  }

  @computed
  get isSummaryTabActive() {
    return this.activeTab.code === Tab.Summary;
  }

  @computed
  get canMoveToPrevTab() {
    return this.tabs.indexOf(this.activeTab) >= 1;
  }

  @computed
  get prevTab() {
    if (this.canMoveToPrevTab) {
      return this.tabs[this.tabs.indexOf(this.activeTab) - 1];
    }
    throw new Error("Prev tab doesn't exist");
  }

  @action.bound
  changeTabTo(nextTab: TabState) {
    this.setActiveTab(nextTab.code);
  }

  @action
  setActiveTab(tabCode: string) {
    return this.tabs.forEach((tab) => tab.setActive(tab.code === tabCode));
  }

  getTab(tabCode: string) {
    return this.tabs.find((tab) => tab.code === tabCode);
  }

  trySelectOptionUsingId(id: string) {
    return this.dynamicTabs.any((tab) => tab.options.any((option) => option.trySelectUsingId(id)));
  }

  trySelectAccessoryUsingId(id: string) {
    return this.dynamicTabs.any((tab) =>
      tab.accessories.any((accessory) => {
        if (accessory.id === id) {
          accessory.select();
          return true;
        }
        return false;
      }),
    );
  }
}
