import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { FileTypeFilterTranslation } from '../../../../../localization/SiteTranslation';
import TextButton from '../../../../../shared/components/Button/TextButton';
import Icon from '../../../../../shared/components/Icon/Icon';
import { IFilterOptionState, ITypeFilterSelectionState } from '../../DownloadsFiltersState';
import FilterButton from '../../FilterButton/FilterButton';
import css from './FilterDropdownSubgroup.css';

interface FilterDropdownSubgroupProps extends Pick<React.AriaAttributes, 'aria-controls'> {
  selection: ITypeFilterSelectionState;
  items: IFilterOptionState[];
  translation: FileTypeFilterTranslation;
  filterName?: string;
  className?: string;
  isOpen?: boolean;
  onBackButtonClick?: () => void;
  onItemClick: () => void;
}

const FilterDropdownSubgroup: React.FC<FilterDropdownSubgroupProps> = ({
  className,
  filterName,
  isOpen,
  items,
  onBackButtonClick,
  onItemClick,
  selection,
  translation,
  ...aria
}) => {
  const renderItem = (item: IFilterOptionState) => {
    const { isActive, name, value } = item;
    const handleClick = () => {
      onItemClick();
      selection.select(item);
    };

    return (
      <FilterButton value={value} name="type" isActive={isActive(selection.value)} onSelect={handleClick} key={value}>
        {name}
      </FilterButton>
    );
  };

  return (
    <div className={classNames(css.FilterDropdownSubgroup, { [css.open]: isOpen }, className)}>
      <TextButton className={css.backButton} childrenClassName={css.backButtonInner} onClick={onBackButtonClick}>
        <>
          <Icon name="angleLeft" />
          <span className={css.backButtonText}>{filterName}</span>
        </>
      </TextButton>
      <div role="radiogroup" aria-label={translation.name} {...aria}>
        {items.map(renderItem)}
      </div>
    </div>
  );
};

export default observer(FilterDropdownSubgroup);
