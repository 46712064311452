import css from './ResultsNotFoundMessage.css';

export default function ResultsNotFoundMessage(props: { message: string }) {
  let { message } = props;
  return (
    <div className={css.ResultsNotFoundMessage}>
      <div className={css.message}>{message}</div>
    </div>
  );
}
