import { Component } from 'react';

import Footer from '../Footer/Footer';
import Topbar from '../Topbar';
import css from './ContentCenteredLayout.css';
import { LayoutProps } from './MainLayout';

interface ContentCenteredLayoutProps extends LayoutProps {}

export default class ContentCenteredLayout extends Component<ContentCenteredLayoutProps, {}> {
  render() {
    const {
      appState,
      children,
      embeddedQuery: { embed },
    } = this.props;
    const showFooter = !embed && !appState.store.isShowroom();

    return (
      <div className={css.ContentCenteredLayout}>
        {!embed && <Topbar appState={appState} translation={appState.translation.layout.topBar} />}
        <div className={css.content}>{children}</div>
        {showFooter && (
          <Footer translation={appState.translation.layout.footerPanel} data={appState.navigationState.footer} />
        )}
      </div>
    );
  }
}
