import classNames from 'classnames';
import * as React from 'react';

import { Subtitle2DarkGrey } from '../../../shared/components/Typography/Subtitle';
import css from './ResultsCounter.css';

interface ResultsCounterProps {
  translation: string;
  value: number;
  className?: string;
}

const ResultsCounter: React.FC<ResultsCounterProps> = ({ className, translation, value }) => (
  <Subtitle2DarkGrey className={classNames(css.ResultsCounter, className)}>
    <span role="region" aria-live="polite" aria-atomic={true}>
      {`${translation}: ${value}`}
    </span>
  </Subtitle2DarkGrey>
);

export default ResultsCounter;
