import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import Label from '../Label/Label';
import InputField, { InputFieldProps } from './InputField';

export interface InputFieldWithLabel extends InputFieldProps {
  label: ReactNode;
}

export const InputFieldWithLabel = observer(({ label, ...props }: InputFieldWithLabel) => {
  return (
    <>
      <Label>{label}</Label>
      <InputField {...props} />
    </>
  );
});
