import { action, makeObservable, observable, override, reaction } from 'mobx';

import { Tab } from '../configurator/Tab';
import { FinalVisualizationBuilder } from '../configurator/VisualizationBuilder';
import { IApiClient } from '../data/client';
import {
  BaseModelLiteData,
  GetProductFamiliesQuery,
  GetProductFamiliesQueryResponse,
  GetProductFamilyFiltersQuery,
  GetProductFamilyFiltersQueryResponse,
  ImageData,
  PredefinedConfigurationData,
  ProductFamilyBasicData,
  PropertyData,
  ShoppingContext,
} from '../data/model';
import { ConfiguratorUrlBuilder } from '../errorHandling/ParseConfigurationCodeAndRedirectState';
import { CatalogPageTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { AsyncCommand, PageMetadata } from '../shared/common';
import { PaginationState } from '../shared/components/Pagination/PaginationState';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { INavigationService } from '../shared/NavigationService';
import { StoreUrl } from '../shared/StoreUrl';
import { ICancellableTask, RunLastTasksScheduler } from '../shared/TasksScheduler';
import { RelativeUrl } from '../shared/Url';
import { VisualizationMode } from '../shared/visualization';
import { StoreState } from '../StoreState';
import { CatalogFiltersState } from './CatalogFiltersState';
import { CatalogPageQueryConverter } from './CatalogPageQueryConverter';
import { CatalogPageQueryParser, ICatalogPageQuery } from './CatalogPageQueryParser';

interface ICatalogMemento {
  shoppingContext: ShoppingContext;
  getProductFamilyFiltersQueryResponse: GetProductFamilyFiltersQueryResponse;
  getProductFamiliesQueryResponse: GetProductFamiliesQueryResponse;
}

export class BaseModelState {
  public imageUrl: string;
  public url: RelativeUrl;
  public id: string;
  public name: string;
  public predefinedConfiguration: PredefinedConfigurationData;
  public canBePurchased: boolean;
  public visualizationAspectRatio: string;

  public client: IApiClient;
  public navigation: INavigationService;

  public goToConfiguratorCommand: AsyncCommand;

  constructor(
    familyData: ProductFamilyBasicData,
    data: BaseModelLiteData,
    client: IApiClient,
    navigation: INavigationService,
  ) {
    this.client = client;
    this.navigation = navigation;

    this.imageUrl = BaseModelState.getImageUrl(data);
    this.url = StoreUrl.productPageUrl(
      familyData.id,
      data.id,
      data.predefinedConfiguration && data.predefinedConfiguration.code,
    );
    this.id = data.id;
    this.name = data.name;
    this.canBePurchased = data.canBePurchased;
    this.predefinedConfiguration = data.predefinedConfiguration;
    this.visualizationAspectRatio = data.visualizationAspectRatio;
    this.goToConfiguratorCommand = new AsyncCommand(() => this.goToConfigurator());
  }

  async goToConfigurator(tab: Tab = Tab.Fabrics): Promise<void> {
    const builder = new ConfiguratorUrlBuilder(this.client);
    const configuratorUrl = await builder.buildFromCode(this.predefinedConfiguration.code, undefined, tab);
    this.navigation.navigateTo(configuratorUrl);

    return Promise.resolve();
  }

  private static getImageUrl(data: BaseModelLiteData) {
    if (data.visualizationMode === VisualizationMode.Default || !data.image) {
      return FinalVisualizationBuilder.buildUrlForPredefinedConfiguration(data.predefinedConfiguration, data.image);
    }

    return data.image.url;
  }
}

export class ProductFamilyState {
  public brand: string;
  public name: string;
  public shortName: string;
  public url: RelativeUrl;
  public imageUrl: string;
  public models: Array<BaseModelState> = [];
  public properties: Array<PropertyData> = [];
  public listingIcon?: ImageData;

  constructor(data: ProductFamilyBasicData, client: IApiClient, navigation: INavigationService) {
    this.brand = data.brand;
    this.name = data.name;
    this.shortName = data.shortName;
    this.url = StoreUrl.productPageUrl(data.id);
    this.models = data.models.map((x) => new BaseModelState(data, x, client, navigation)).filter((x) => x.imageUrl);
    this.properties = data.properties;
    this.listingIcon = data.listingIcon;

    if (data.image.url) {
      this.imageUrl = data.image.url;
    } else if (this.models.any()) {
      const firstModel = this.models.first();
      this.imageUrl = firstModel.imageUrl;
      this.url = firstModel.url;
    }
  }
}

class LoadFamiliesTask implements ICancellableTask {
  public cancelled = false;
  public query: GetProductFamiliesQuery;

  public constructor(query: GetProductFamiliesQuery) {
    this.query = query;
  }
}

export class CatalogPageState extends BasePageState<ICatalogMemento> {
  @observable.ref
  public families: ProductFamilyState[];
  public filters: CatalogFiltersState;
  public loadingIndicator: LoadingIndicator;
  public pagination: PaginationState;
  public urlQuery: ICatalogPageQuery;

  private getProductFamilyFiltersQueryResponse: GetProductFamilyFiltersQueryResponse;
  private getProductFamiliesQueryResponse: GetProductFamiliesQueryResponse;
  private loadFamiliesScheduler: RunLastTasksScheduler<LoadFamiliesTask, void>;
  private shoppingContext: ShoppingContext;

  public constructor(
    public readonly translation: CatalogPageTranslation,
    private readonly navigation: INavigationService,
    private readonly client: IApiClient,
    pageSize: number = 12,
  ) {
    super(translation);
    makeObservable(this);
    this.loadFamiliesScheduler = new RunLastTasksScheduler(this.runLoadFamiliesTask);
    this.loadingIndicator = new LoadingIndicator();
    this.urlQuery = CatalogPageQueryParser.toModel(this.navigation.currentUrl.query);
    this.initializePagination(pageSize);
  }

  public get counter(): number {
    return this.pagination.totalCount;
  }

  public getMemento(): ICatalogMemento {
    return {
      shoppingContext: this.shoppingContext,
      getProductFamilyFiltersQueryResponse: this.getProductFamilyFiltersQueryResponse,
      getProductFamiliesQueryResponse: this.getProductFamiliesQueryResponse,
    };
  }

  @override
  public get metadata() {
    const title = this.translations.pageTitleFormat;
    const description = this.translations.pageDescriptionFormat;

    return <PageMetadata>{
      title: title,
      description: description,
    };
  }

  @action
  public async onLoad(store: StoreState) {
    const getProductFamilyFiltersQueryResponse = await this.client.send(
      new GetProductFamilyFiltersQuery({ shoppingContext: store.shoppingContext }),
    );
    const getProductFamiliesQueryResponse = await this.client.send(
      this.createInitialProductFamiliesQuery(store.shoppingContext),
    );
    this.initialize(store.shoppingContext, getProductFamilyFiltersQueryResponse, getProductFamiliesQueryResponse);
  }

  public restoreMemento(memento: ICatalogMemento) {
    this.initialize(
      memento.shoppingContext,
      memento.getProductFamilyFiltersQueryResponse,
      memento.getProductFamiliesQueryResponse,
    );
  }

  public searchFamilies = () => {
    this.pagination.setCurrentPageIndex(0, true);
    this.loadFamilies();
  };

  private createInitialProductFamiliesQuery(shoppingContext: ShoppingContext): GetProductFamiliesQuery {
    return new GetProductFamiliesQuery({
      shoppingContext,
      types: this.urlQuery.type ?? [],
      sectors: this.urlQuery.sector ?? [],
      brands: this.urlQuery.brand ?? [],
      skip: this.pagination.startIndex,
      take: this.pagination.pageSize,
    });
  }

  private initialize(
    context: ShoppingContext,
    getProductFamilyFiltersQueryResponse: GetProductFamilyFiltersQueryResponse,
    getProductFamiliesQueryResponse: GetProductFamiliesQueryResponse,
  ) {
    this.shoppingContext = context;
    this.getProductFamilyFiltersQueryResponse = getProductFamilyFiltersQueryResponse;
    this.getProductFamiliesQueryResponse = getProductFamiliesQueryResponse;
    this.filters = new CatalogFiltersState(getProductFamilyFiltersQueryResponse, this.urlQuery);

    const converter = new CatalogPageQueryConverter(this.filters, this.pagination, this.navigation);
    converter.initialize();

    this.setFamilies(getProductFamiliesQueryResponse);
    this.pagination.setTotalCount(getProductFamiliesQueryResponse.totalCount);

    reaction(
      () => this.filters.selection.properties.slice(),
      () => this.searchFamilies(),
    );
  }

  private initializePagination(pageSize: number) {
    this.pagination = new PaginationState(pageSize);

    const currentPageIndex = this.urlQuery.page - 1;
    if (currentPageIndex >= 0) {
      this.pagination.setCurrentPageIndex(this.urlQuery.page - 1);
    }

    this.pagination.pageChanged.subscribe(this.loadFamilies);
  }

  private loadFamilies = () => {
    const query = new GetProductFamiliesQuery({
      shoppingContext: this.shoppingContext,
      types: this.filters.selectedTypes,
      sectors: this.filters.selectedSectors,
      brands: this.filters.selectedBrands,
      skip: this.pagination.startIndex,
      take: this.pagination.pageSize,
    });

    this.loadFamiliesScheduler.run(new LoadFamiliesTask(query));
  };

  private runLoadFamiliesTask = async (task: LoadFamiliesTask) => {
    this.loadingIndicator.start();

    const response = await this.client.send(task.query);

    if (task.cancelled) {
      return;
    }

    this.setFamilies(response);
    this.pagination.setTotalCount(response.totalCount);

    this.loadingIndicator.reset();
  };

  @action.bound
  private setFamilies(response: GetProductFamiliesQueryResponse) {
    if (!response || !response.productFamilies || response.productFamilies.empty()) {
      this.families = [];
      return;
    }

    this.families = response.productFamilies.map((x) => new ProductFamilyState(x, this.client, this.navigation));
  }
}
