import classNames from 'classnames';
import { observer } from 'mobx-react';

import ConfigurationCodeShare from '../../../configurator/components/ConfigurationShare/ConfigurationCodeShare/ConfigurationCodeShare';
import css from './ConfigurationCode.css';
import { ConfigurationCodeState } from './ConfigurationCodeState';

interface ConfigurationCodeProps {
  state: ConfigurationCodeState;
}

const ConfigurationCode = observer(({ state }: ConfigurationCodeProps) => {
  if (!state.configurator.configurationCompleted) {
    return null;
  }

  return (
    <ConfigurationCodeShare
      classNames={{
        root: classNames(css.root, css[state.configurator.size]),
        title: css.title,
        code: css.code,
        button: css.button,
      }}
      code={state.configurationCode}
      isCodeGenerationError={state.isError}
      isCodeLoading={state.codeGenerationIndicator.isLoading}
      translation={{
        codeTitle: 'Configuration code',
        copyCode: {
          label: 'Copy code',
          textCopiedLabel: 'Copied',
        },
      }}
    />
  );
});

export default ConfigurationCode;
