import { ComponentProps } from 'react';

import IconMarkerPlace from '../../../../../images/map/place.svg';
import Marker from '../Marker/Marker';

type PlaceProps = Pick<ComponentProps<typeof Marker>, 'map' | 'position'>;

const Place = ({ ...markerProps }: PlaceProps) => {
  return (
    <Marker
      clickable={false}
      icon={{ url: IconMarkerPlace, scaledSize: new google.maps.Size(43, 43) }}
      {...markerProps}
    />
  );
};

export default Place;
