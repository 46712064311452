import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../app/data/client';
import { GetPagesQuery, GetPagesQueryResponse } from '../../../app/data/model';
import { ILoadingIndicator, LoadingIndicator } from '../../../app/shared/LoadingIndicator';
import { RelativeUrl } from '../../../app/shared/Url';
import { StoreState } from '../../../app/StoreState';
import { SideMenuTranslation } from '../../localization/SiteTranslation';
import { ISideMenuItemState, SideMenuItemState } from './SideMenuItem/SideMenuItemState';

export class SideMenuState {
  public homepageUrl: RelativeUrl;
  @observable.ref
  public items: ISideMenuItemState[] = [];
  public loadingIndicator: LoadingIndicator;

  public constructor(
    private readonly apiClient: IApiClient,
    private readonly pageLoadingIndicator: ILoadingIndicator,
    private readonly store: StoreState,
    public readonly translation: SideMenuTranslation,
  ) {
    makeObservable(this);
    this.loadingIndicator = new LoadingIndicator();
    this.setHomepageUrl();
    this.loadItems();
  }

  public get isLoading() {
    return this.loadingIndicator.isLoading;
  }

  @action.bound
  public setItemIsActive(url?: RelativeUrl | string) {
    this.items.forEach((item) => item.setIsActive(url));
  }

  private addItem = (result: ISideMenuItemState[], item: { name: string; path: string }): ISideMenuItemState[] => {
    if (item.name) {
      const url = this.buildItemUrl(item.path);
      result.push(new SideMenuItemState(item.name, url));
    }

    return result;
  };

  private buildItemUrl(purePath: string) {
    const storeId = this.store.storeResponse.storeContext.store.id;
    const languageCode = this.store.storeResponse.storeContext.language.code;

    return RelativeUrl.parse(`/${storeId}/${languageCode}/${purePath}`);
  }

  private setHomepageUrl() {
    this.homepageUrl = this.buildItemUrl('/');
  }

  private async loadItems() {
    this.pageLoadingIndicator.start();
    this.loadingIndicator.start();

    const query = new GetPagesQuery({
      path: 'flokk-hub',
      shoppingContext: this.store.shoppingContext,
    });

    const response = await this.apiClient.send(query);

    this.setItems(response);
    this.pageLoadingIndicator.stop();
    this.loadingIndicator.stop();
  }

  @action.bound
  private setItems(response: GetPagesQueryResponse) {
    let items: ISideMenuItemState[] = [
      new SideMenuItemState(this.translation.productsLabel, this.buildItemUrl('products')),
      new SideMenuItemState(this.translation.quickOrderLabel, this.buildItemUrl('quick-order')),
      new SideMenuItemState(this.translation.orderHistoryLabel, this.buildItemUrl('orders')),
      new SideMenuItemState(this.translation.favoritesLabel, this.buildItemUrl('favorites')),
    ];

    if (response.pages.any()) {
      items = [...items, ...response.pages.reduce(this.addItem, [])];
    }

    this.items = items;
  }
}
