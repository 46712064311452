import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../app/data/client';
import { AddEntireDealerOrderToCartCommand, OrderData, ShoppingContext } from '../../app/data/model';
import { Analytics } from '../../app/shared/analytics/Analytics';
import ProductDataConverter from '../../app/shared/analytics/ProductDataConverter';
import { CartEventSource } from '../../app/shared/analytics/TrackingService';
import { AsyncCommand } from '../../app/shared/common';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { ModalState } from '../../app/shared/ModalState';
import { ShoppingCartUpdatedEvent } from '../cart/events/ShoppingCartUpdatedEvent';
import { ReorderModalTranslation } from '../localization/SiteTranslation';

export class ReorderModalState extends ModalState {
  @observable.ref
  order?: OrderData;

  public reorderCommand: AsyncCommand;

  constructor(
    private readonly client: IApiClient,
    private readonly eventAggregator: IEventAggregator,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: ReorderModalTranslation,
  ) {
    super();
    makeObservable(this);
    this.reorderCommand = new AsyncCommand(this.addEntireOrderToCart);
  }

  @action.bound
  public setOrder(order: OrderData) {
    this.order = order;
  }

  private addEntireOrderToCart = async () => {
    if (!this.order) {
      return;
    }

    const response = await this.client.send(
      new AddEntireDealerOrderToCartCommand({
        orderNumber: this.order.number,
        shoppingContext: this.shoppingContext,
      }),
    );

    Analytics.trackAddToCart({
      context: this.shoppingContext,
      source: CartEventSource.OrderHistory,
      products: this.order.items.map(ProductDataConverter.fromOrderItemData),
    });

    this.eventAggregator.publish(new ShoppingCartUpdatedEvent(response.dealerCarts.cartsSummary));
    this.close();
  };
}
