import { observer } from 'mobx-react';
import { Component } from 'react';

import AddressLine1 from '../../../app/checkout/components/ShippingAddresses/Inputs/AddressLine1';
import AddressLine2 from '../../../app/checkout/components/ShippingAddresses/Inputs/AddressLine2';
import City from '../../../app/checkout/components/ShippingAddresses/Inputs/City';
import Recipient from '../../../app/checkout/components/ShippingAddresses/Inputs/Recipient';
import ZipCode from '../../../app/checkout/components/ShippingAddresses/Inputs/ZipCode';
import { AddButton, EditButton } from '../../../app/shared/components/Button/CustomButtons';
import ThreeDotsLoader from '../../../app/shared/components/Loaders/ThreeDotsLoader';
import { DeliveryAddressFormTranslation, DeliveryAddressTranslation } from '../../localization/SiteTranslation';
import { DeliveryAddressFormState } from '../DeliveryAddressFormState';
import css from './DeliveryAddress.css';

interface Props {
  deliveryAddress: DeliveryAddressFormState;
  className?: string;
  translation: DeliveryAddressTranslation;
}

@observer
export class DeliveryAddress extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { deliveryAddress, className, translation } = this.props;

    if (deliveryAddress.initializationIndicator.isLoading) {
      return (
        <div className={css.loaderWrapper}>
          <ThreeDotsLoader size="small" />
        </div>
      );
    }

    return (
      <div className={className}>
        {!deliveryAddress.showPreview && (
          <DeliveryAddressForm address={deliveryAddress} translation={translation.deliveryAddressForm} />
        )}

        {deliveryAddress.showPreview && (
          <>
            <DeliveryAddressPreview className={css.deliveryAddress} address={deliveryAddress} />
            <EditButton onClick={deliveryAddress.edit}>{translation.editButton}</EditButton>
            <AddButton className={css.buttonAdd} onClick={deliveryAddress.add}>
              {translation.addButton}
            </AddButton>
          </>
        )}
      </div>
    );
  }
}

export const DeliveryAddressForm = observer(
  ({ address, translation }: { address: DeliveryAddressFormState; translation: DeliveryAddressFormTranslation }) => {
    return (
      <div>
        <Recipient
          input={address.recipient}
          label={translation.recipient.name}
          placeholder={translation.recipient.placeholder}
          showValidationHints
        />
        <AddressLine1
          input={address.addressLine1}
          label={translation.addressLine1.name}
          placeholder={translation.addressLine1.placeholder}
          showValidationHints
        />
        <AddressLine2
          input={address.addressLine2}
          label={translation.addressLine2.name}
          placeholder={translation.addressLine2.placeholder}
          showValidationHints
        />
        <ZipCode
          input={address.zipCode}
          label={translation.zipCode.name}
          placeholder={translation.zipCode.placeholder}
          showValidationHints
        />
        <City
          input={address.city}
          isLoading={address.autocompleteCityBehavior?.loadingIndicator.isLoading}
          label={translation.city.name}
          placeholder={translation.city.placeholder}
          showValidationHints
        />
        <div>
          <label className={css.countryLabel}>{translation.country.name + ':'}</label>
          <span className={css.countryName}>{' ' + address.country.name}</span>
        </div>
      </div>
    );
  },
);

export const DeliveryAddressPreview = observer(
  ({ className, address }: { className?: string; address: DeliveryAddressFormState }) => {
    return (
      <address className={className}>
        {address.recipient.value}
        <br />
        {address.addressLine1.value}
        <br />
        {address.addressLine2.value && (
          <>
            {address.addressLine2.value}
            <br />
          </>
        )}
        {address.zipCode.value + ' ' + address.city.value}
        <br />
        {address.country.name}
      </address>
    );
  },
);
