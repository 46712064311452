import AppSettings from '../AppSettings';
import IConfigurationPageQuery from '../configurator/IConfigurationPageQuery';
import { ShoppingContext } from '../data/model';
import { StoreUrl } from './StoreUrl';

export interface IPdfUrlProvider {
  initialize(shoppingContext: ShoppingContext): Promise<void>;
  getPdfUrl(slug: string, configurationQuery: IConfigurationPageQuery): string;
}

export abstract class BasePdfOriginProvider implements IPdfUrlProvider {
  private storeId?: string;
  private languageCode?: string;

  protected constructor(protected readonly settings: AppSettings) {}

  async initialize(shoppingContext: ShoppingContext): Promise<void> {
    const { storeId, languageCode } = await this.getStoreAndLanguage(shoppingContext);
    this.storeId = storeId;
    this.languageCode = languageCode;
  }

  getPdfUrl(slug: string, configurationQuery: IConfigurationPageQuery): string {
    if (!this.storeId || !this.languageCode) {
      throw new Error(`This instance of PDF URL provider is not initialized`);
    }

    const relativeUrl = StoreUrl.productPdfPrintUrl(this.storeId, this.languageCode, slug, configurationQuery);
    return `${this.origin}${relativeUrl.toString()}`;
  }

  protected abstract getStoreAndLanguage(
    shoppingContext: ShoppingContext,
  ): Promise<{ storeId: string; languageCode: string }>;

  protected abstract get origin(): string;
}

export class DefaultPdfOriginProvider extends BasePdfOriginProvider {
  constructor(settings: AppSettings) {
    super(settings);
  }

  protected getStoreAndLanguage(shoppingContext: ShoppingContext): Promise<{ storeId: string; languageCode: string }> {
    return Promise.resolve({ storeId: shoppingContext.storeId, languageCode: shoppingContext.languageCode });
  }

  protected get origin(): string {
    return this.settings.baseUrl;
  }
}
