import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../app/data/client';
import { OrderData, ShoppingContext, UpdateDealerOrderNameCommand } from '../../app/data/model';
import { AsyncCommand } from '../../app/shared/common';
import Event from '../../app/shared/Event';
import { TextInput } from '../../app/shared/Form';
import { ModalState } from '../../app/shared/ModalState';
import { EditOrderModalTranslation } from '../localization/SiteTranslation';

export class EditOrderModalState extends ModalState {
  customNameInput: TextInput = new TextInput();

  @observable
  order?: OrderData;

  public updateOrderCommand: AsyncCommand;

  public orderUpdated: Event<OrderData> = new Event<OrderData>();

  constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: EditOrderModalTranslation,
  ) {
    super();
    makeObservable(this);
    this.updateOrderCommand = new AsyncCommand(this.updateOrder);
  }

  @action.bound
  public setOrder(order: OrderData) {
    this.order = order;
    this.customNameInput.setDefaultValue(order.customName);
  }

  private updateOrder = async () => {
    if (!this.order) {
      return;
    }

    const response = await this.client.send(
      new UpdateDealerOrderNameCommand({
        orderNumber: this.order.number,
        orderName: this.customNameInput.value,
        shoppingContext: this.shoppingContext,
      }),
    );

    this.setOrder(response.order);
    this.orderUpdated.raise(response.order);
    this.close();
  };
}
