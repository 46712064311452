import { IApiClient } from '../../../../data/client';
import CountryCode from '../../../../shared/CountryCode';
import { TextInput } from '../../../../shared/Form';
import AutocompleteCityBehavior from './AutocompleteCityBehavior';

class AutocompleteCityBehaviorFactory {
  private static readonly supportedCountries = [
    CountryCode.Denmark,
    CountryCode.Norway,
    CountryCode.Sweden,
    CountryCode.France,
    CountryCode.Austria,
    CountryCode.Germany,
    CountryCode.Ireland,
    CountryCode.Netherlands,
    CountryCode.Switzerland,
  ];

  public static create(client: IApiClient, countryCode: string, zipCodeInput: TextInput, cityInput: TextInput) {
    if (!AutocompleteCityBehaviorFactory.isAutocompleteCityBehaviorSupported(countryCode)) {
      return null;
    }
    return new AutocompleteCityBehavior(client, countryCode, zipCodeInput, cityInput);
  }

  private static isAutocompleteCityBehaviorSupported(countryCode: string) {
    return this.supportedCountries.includes(countryCode);
  }
}

export default AutocompleteCityBehaviorFactory;
