import { observer } from 'mobx-react';

import Icon from '../../../../../../../app/shared/components/Icon/Icon';
import SelectableRoundImage from '../../../../../../../app/shared/components/SelectableRoundImage/SelectableRoundImage';
import css from './ProductOptionVariation.css';
import ProductOptionVariationState from './ProductOptionVariationState';

interface ProductOptionVariationProps {
  state: ProductOptionVariationState;
  onClick: (item: ProductOptionVariationState) => void;
}

const ProductOptionVariation = ({ state, onClick }: ProductOptionVariationProps) => {
  return (
    <div className={css.container}>
      <SelectableRoundImage
        className={css.image}
        classNameInner={state.isNotSelectedAccessory ? css.iconContainer : undefined}
        customElement={state.isNotSelectedAccessory ? <Icon className={css.icon} name="ban" /> : undefined}
        image={state.thumbnail}
        onClick={() => onClick(state)}
        isSelected={state.isActive}
      />
      {state.name && <div className={css.name}>{state.name}</div>}
    </div>
  );
};

export default observer(ProductOptionVariation);
