import classNames from 'classnames';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import { ImageData } from '../../../data/model';
import SelectableRoundImage from '../../../shared/components/SelectableRoundImage/SelectableRoundImage';
import css from './OptionVariationThumbnail.css';

interface OptionVariationThumbnailProps extends React.DOMAttributes<{}> {
  isSelected?: boolean;
  image: ImageData;
  readonly?: boolean;
  className?: string;
  highlighted?: boolean;
  id?: string;
  removeHighlight?: () => void;
  onClick?: () => void;
}

const OptionVariationThumbnail = ({
  className,
  highlighted,
  removeHighlight,
  ...props
}: OptionVariationThumbnailProps) => {
  const finalClassName = classNames(highlighted && css.scrolled, className);

  return (
    <CSSTransition
      in={highlighted}
      timeout={500}
      onEntered={() => {
        removeHighlight();
      }}
      classNames={{}}
    >
      <SelectableRoundImage className={finalClassName} {...props} />
    </CSSTransition>
  );
};

export default OptionVariationThumbnail;
