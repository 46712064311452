import { observer } from 'mobx-react';
import React, { Component } from 'react';

import AppState from '../AppState';
import ExtendedPagination from '../shared/components/ExtendedPagination/ExtendedPagination';
import Link from '../shared/components/Link';
import List from '../shared/components/List/List';
import ThreeDotsLoader from '../shared/components/Loaders/ThreeDotsLoader';
import { StoreUrl } from '../shared/StoreUrl';
import css from './CatalogPage.css';
import { CatalogPageState } from './CatalogPageState';
import FilterBar from './FilterBar/FilterBar';
import ProductFamily from './ProductFamily';
import ResultsNotFoundMessage from './ResultsNotFoundMessage/ResultsNotFoundMessage';
import SelectedFilters from './SelectedFilters/SelectedFilters';

interface CatalogPageProps {
  appState: AppState;
  state: CatalogPageState;
}

@observer
class CatalogPage extends Component<CatalogPageProps, {}> {
  constructor(props: CatalogPageProps) {
    super(props);
  }

  get translation() {
    return this.props.appState.translation.catalogPage;
  }

  get commonTranslation() {
    return this.props.appState.translation.common;
  }

  render() {
    const { state } = this.props;
    const { selection } = this.props.state.filters;

    return (
      <div className={css.CatalogPage}>
        <FilterBar
          containerClassName={css.filterBarContainer}
          translation={this.translation.filterBar}
          filters={state.filters.filters}
          resultsNumber={state.pagination.totalCount}
        />
        <div className={css.specificityContainer}>
          {selection.properties.any() && (
            <SelectedFilters
              className={css.selectedFilters}
              selection={selection}
              translation={this.commonTranslation.tags}
            />
          )}
          <Link className={css.configurationSearchLink} to={StoreUrl.configurationSearchUrl()}>
            {this.translation.searchForConfigurationText}
          </Link>
        </div>
        <div className={css.familyListContainer}>
          <div className={css.content}>
            {state.loadingIndicator.isLoading && (
              <div className={css.loaderContainer}>
                <ThreeDotsLoader className={css.loader} size="large" />
              </div>
            )}

            <List
              className={css.familyList}
              itemClassName={css.familyItemContainer}
              items={state.families}
              itemRenderer={(item) => <ProductFamily item={item} translation={this.translation.productFamily} />}
              keyProperty="url"
            />
            {state.families.empty() && <ResultsNotFoundMessage message={this.translation.noResults} />}
          </div>

          <ExtendedPagination state={state.pagination} translation={this.translation.pagination} />
        </div>
      </div>
    );
  }
}

export default CatalogPage;
