import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { OrderItemData } from '../../../app/data/model';
import { LoadableImage } from '../../../app/shared/components/LoadableImage';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { CartItemDetails } from '../../cart/CartItem/CartItemDetails/CartItemDetails';
import { OrderItemTranslation } from '../../localization/SiteTranslation';
import css from './OrderItem.css';

export const OrderItem = (props: { item: OrderItemData; translation: OrderItemTranslation }) => {
  let { item, translation } = props;
  const imgWidth = 80;
  const imgHeight = 140;

  return (
    <Panel
      headerComponent={
        <div className={css.orderItemPanelHeader}>
          <strong className={css.orderItemPanelHeaderBrand}>{item.brandName}</strong> {item.shortName}
        </div>
      }
      bodyClassName={css.orderItemPanelContent}
    >
      <LoadableImage
        className={css.orderItemImage}
        src={FinalVisualizationBuilder.buildUrl(item.configurationCode, undefined, undefined, imgWidth, imgHeight)}
        width={imgWidth}
        height={imgHeight}
        sharpeningFactor={1.5}
      />
      <div className={css.orderItemDetails}>
        <CartItemDetails
          code={item.configurationCode}
          listPrice={item.totalPrice}
          salePrice={item.price}
          quantity={item.quantity}
          className={css.orderItemPropertiesContainer}
          propertyClassNames={{
            valueClassName: css.orderItemPropertyValue,
          }}
          translation={translation.cartItemDetails}
        />
      </div>
    </Panel>
  );
};
