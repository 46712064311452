import { Headers } from 'cross-fetch';

import { IAuthenticationService } from '../../auth/AuthenticationService';
import { IHttpRequestOptionsProvider } from '../client';
import { RequestOptions } from '../httpClient';
import * as Api from '../model';

export class RequestAuthOptionsProvider implements IHttpRequestOptionsProvider {
  constructor(private authentication: IAuthenticationService) {}

  async getForRequest<TResponse>(request: Api.Request<TResponse>): Promise<RequestOptions> {
    const headers = new Headers();
    const onErrorRecoverTokenWithLogin = !request.$allowAnonymous;
    const authorizationHeader = await this.getBearerAuthorizationHeader(onErrorRecoverTokenWithLogin);

    if (authorizationHeader) {
      headers.set('Authorization', authorizationHeader);
    }

    return { headers };
  }

  private async getBearerAuthorizationHeader(onErrorRecoverTokenWithLogin: boolean): Promise<string | null> {
    const accessToken = await this.authentication.getAccessToken(onErrorRecoverTokenWithLogin);

    if (accessToken) {
      return `Bearer ${accessToken}`;
    }

    return null;
  }
}
