import { Component } from 'react';

import { PropertyData } from '../../../data/model';
import { TagsTranslations } from '../../../localization/SiteTranslation';
import TextButton from '../Button/TextButton';
import Tag from './Tag/Tag';
import css from './Tags.css';

interface TagsProps {
  items: Array<PropertyData>;
  onItemClick?: (item: PropertyData) => void;
  clear?: () => void;
  disableClear?: boolean;
  translation: TagsTranslations;
}

export default class Tags extends Component<TagsProps, {}> {
  renderClearFiltersButton() {
    return (
      <TextButton className={css.clearButton} onClick={() => this.props.clear()}>
        {this.props.translation.clearResults}
      </TextButton>
    );
  }

  renderTagItem(item: PropertyData) {
    return (
      <li key={item.valueId} className={css.tagItem} onClick={() => this.props.onItemClick(item)}>
        <Tag>{item.value}</Tag>
      </li>
    );
  }

  render() {
    const { items, disableClear } = this.props;
    return (
      <ul className={css.Tags}>
        {items.map((item) => this.renderTagItem(item))}
        {!disableClear && <li className={css.clearTags}>{this.renderClearFiltersButton()}</li>}
      </ul>
    );
  }
}
