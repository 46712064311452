import List from '../../../../../../app/shared/components/List/List';
import { TextLabel } from '../../../../../../app/shared/components/Typography/TextLabels';
import ProductFabricInfo from './ProductFabricInfo/ProductFabricInfo';
import css from './ProductOptions.css';
import ProductOptionsState from './ProductOptionsState';
import ProductOptionVariation from './ProductOptionVariation/ProductOptionVariation';

interface ProductOptionProps {
  state: ProductOptionsState;
  className?: string;
}

const ProductOptions = ({ className, state }: ProductOptionProps) => {
  return state.items.any ? (
    <div className={className}>
      {state.items.map((option) => (
        <div className={css.item} key={option.id}>
          <TextLabel className={css.label}>{option.name}</TextLabel>
          <List
            className={css.variationsList}
            itemClassName={css.variation}
            items={option.variations}
            itemRenderer={(variation) => (
              <ProductOptionVariation state={variation} onClick={option.setActiveVariation} />
            )}
            keyProperty="id"
          />
        </div>
      ))}
      <ProductFabricInfo state={state} />
    </div>
  ) : null;
};

export default ProductOptions;
