import { ProductThumbnailTranslation } from '../../../../../../app/localization/SiteTranslation';
import Image from '../../../../../../app/shared/components/Image/Image';
import Link from '../../../../../../app/shared/components/Link';
import { RelativeUrl } from '../../../../../../app/shared/Url';
import css from './ProductThumbnail.css';
import { ProductVariationProps } from './ProductVariationList/ProductVariation/ProductVariation';
import ProductVariationList from './ProductVariationList/ProductVariationList';

export interface ProductThumbnailProps {
  imageSrc: string;
  linkUrl: RelativeUrl;
  name: string;
  price: string;
  translation: ProductThumbnailTranslation;
  variations: ProductVariationProps[];
}

const ProductThumbnail = ({ imageSrc, linkUrl, name, price, translation, variations }: ProductThumbnailProps) => {
  return (
    <article className={css.container}>
      <h3 className={css.name}>
        <Link to={linkUrl} className={css.link}>
          {name}
        </Link>
      </h3>
      <Image className={css.image} src={imageSrc} width={295} height={350} size="contain" />
      <span className={css.price}>{price}</span>
      <ProductVariationList
        className={css.variants}
        items={variations}
        translation={translation.productVariationList}
      />
    </article>
  );
};

export default ProductThumbnail;
