import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';

import { GroupState } from '../../../../3dEngine/core/GroupState';
import { LoadableMeshState } from '../../../../3dEngine/core/LoadableMeshState';
import { ObjectState } from '../../../../3dEngine/core/ObjectState';
import { SceneState } from '../../../../3dEngine/core/SceneState';
import { ConfiguratorCoreState } from '../../../../configurator/ConfiguratorCoreState';
import { IApiClient } from '../../../../data/client';
import { GetProductByIdQuery, ModularLayoutData, ShoppingContext } from '../../../../data/model';
import { SiteTranslation } from '../../../../localization/SiteTranslation';
import { IShareConfiguratorUrlProvider } from '../../../../shared/BaseShareConfiguratorUrlProvider';
import { LoadingIndicator } from '../../../../shared/LoadingIndicator';
import ModularComponentConfiguratorState from '../ModularConfigurator/ModularComponentConfigurator/ModularComponentConfiguratorState';
import ModularConfiguratorSummaryState from './ModularConfiguratorSummary/ModularConfiguratorSummaryState';

export enum ModularConfiguratorAction {
  Configure,
  Move,
  Rotate,
}

class ModularConfiguratorState {
  @observable
  public activeAction: ModularConfiguratorAction;

  @observable.shallow
  public readonly components = [] as IObservableArray<ModularComponentConfiguratorState>;

  @observable
  public readonly = false;

  public readonly loadingIndicator = new LoadingIndicator();
  public readonly scene = new SceneState();
  public readonly summary: ModularConfiguratorSummaryState;

  @computed
  public get isConfigureActionActive() {
    return this.activeAction === ModularConfiguratorAction.Configure;
  }

  @computed
  public get isMoveActionActive() {
    return this.activeAction === ModularConfiguratorAction.Move;
  }

  @computed
  public get isRotateActionActive() {
    return this.activeAction === ModularConfiguratorAction.Rotate;
  }

  @computed
  public get selectedComponent() {
    return this.components.find((x) => x.sceneObjectsGroup.selected);
  }

  public constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    private readonly translation: SiteTranslation,
    configurationUrlProvider: IShareConfiguratorUrlProvider,
  ) {
    makeObservable(this);

    this.summary = new ModularConfiguratorSummaryState(configurationUrlProvider);

    this.activateDefaultAction();
  }

  @action.bound
  public activateDefaultAction() {
    this.activateConfigureAction();
  }

  @action.bound
  public activateConfigureAction() {
    this.activateAction(ModularConfiguratorAction.Configure);
  }

  @action.bound
  public activateMoveAction() {
    this.activateAction(ModularConfiguratorAction.Move);
  }

  @action.bound
  public activateRotateAction() {
    this.activateAction(ModularConfiguratorAction.Rotate);
  }

  @action
  public addFromFile(): void {
    const loadable1 = new LoadableMeshState('P60', 'https://flokkrendersint.blob.core.windows.net/profim-revo/P60.glb');
    this.scene.addObject(loadable1);

    const loadable2 = new LoadableMeshState(
      'B180',
      'https://flokkrendersint.blob.core.windows.net/profim-revo/B180.glb',
    );

    const loadable3 = new LoadableMeshState(
      'SW180',
      'https://flokkrendersint.blob.core.windows.net/profim-revo/SW180.glb',
    );

    const group1 = new GroupState('group1', [loadable2, loadable3]);
    group1.position.x = 1;
    this.scene.addObject(group1);
  }

  @action
  public async loadLayout(layout: ModularLayoutData) {
    if (!layout) {
      return;
    }

    if (this.loadingIndicator.isLoading) {
      // TODO: just simple loading request synchronization, maybe we can do it better
      return;
    }

    this.loadingIndicator.start();

    this.scene.objects.clear();
    this.components.clear();

    const distinctProductsIds = layout.components.map((x) => x.productId).distinct();
    const productsData = await Promise.all(
      distinctProductsIds.map((x) =>
        this.client.send(new GetProductByIdQuery({ productId: x, shoppingContext: this.shoppingContext })),
      ),
    );

    runInAction(async () => {
      layout.components.forEach((component) => {
        const data = productsData.find((x) => x.baseModel.id === component.productId);
        const groupingSceneObject = new GroupState(data.baseModel.id, []);
        groupingSceneObject.position = component.position;
        groupingSceneObject.rotation = component.rotation;
        this.scene.addObject(groupingSceneObject);

        const coreState = new ConfiguratorCoreState(this.client, this.translation.configurationPage);
        coreState.initialize(data, '000000', '', [], this.shoppingContext);

        const componentState = new ModularComponentConfiguratorState(data, groupingSceneObject, coreState);
        this.components.push(componentState);
      });

      await Promise.all(this.components.map((x) => x.coreState.ensureCodeIsInSyncWithSelectedOptionsAndAccessories()));

      await this.scene.ensureResourcesLoaded();

      this.loadingIndicator.stop();
    });
  }

  @action
  public enableReadonlyMode() {
    this.prepareReadonlyMode();
    this.readonly = true;
  }

  @action
  public disableReadonlyMode() {
    this.readonly = false;
  }

  @action
  public selectComponent(object: ObjectState) {
    if (this.readonly) {
      return;
    }

    this.clearSelection();
    object.setSelected(true);
  }

  @action.bound
  public removeSelectedComponent() {
    if (this.readonly) {
      return;
    }

    this.removeComponent(this.selectedComponent);
  }

  @action
  public unselectComponent() {
    this.clearSelection();
  }

  @action
  public tryApplySimilarConfiguration(prototype: ModularComponentConfiguratorState) {
    prototype.coreState.selectedComponents.forEach((variationCode, featureCode) => {
      const variationId = `${featureCode}-${variationCode}`;

      this.components
        .filter((component) => component !== prototype)
        .map((component) => component.coreState)
        .forEach((configurator) => {
          configurator.tabs.trySelectOptionUsingId(variationId);
          configurator.tabs.trySelectAccessoryUsingId(variationId);
        });
    });
  }

  private activateAction(activeAction: ModularConfiguratorAction) {
    if (this.readonly) {
      return;
    }

    this.activeAction = activeAction;
  }

  private prepareReadonlyMode() {
    this.unselectComponent();
  }

  @action
  private clearSelection() {
    this.scene.objects.forEach((x) => x.setSelected(false));
  }

  @action
  private removeComponent(component: ModularComponentConfiguratorState) {
    if (!component) {
      return;
    }

    this.scene.removeObject(component.sceneObjectsGroup);
    this.components.remove(component);
  }
}

export default ModularConfiguratorState;
