import { action, makeObservable, observable } from 'mobx';

import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import { BasePageState } from '../../app/shared/BasePageState';
import { IMemento } from '../../app/shared/common';
import Logger from '../../app/shared/Logger';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import { RelativeUrl } from '../../app/shared/Url';
import RouteDefinitions from '../routing/RouteDefinitions';
import { AuthenticationFlowError } from './AuthenticationFlowError';
import { HttpErrorCode } from './HandleAuthCodeState';
import { IOAuthHandlerState } from './IOAuthHandlerState';

export class BaseOAuthHandlerState extends BasePageState<IMemento> implements IOAuthHandlerState {
  constructor(
    protected readonly navigation: INavigationService,
    protected readonly authentication: IAuthenticationService,
  ) {
    super();
    makeObservable(this);
  }

  @observable
  public error?: string;

  @observable
  public errorHeader?: string;

  @observable
  public httpErrorCode?: HttpErrorCode;

  @action.bound
  public setError(error?: string, errorCode?: HttpErrorCode, errorHeader?: string) {
    this.error = error;
    this.errorHeader = errorHeader;
    this.httpErrorCode = errorCode;
  }

  // tslint:disable-next-line:no-any
  public handleAuthFlowError(e: any) {
    if (e instanceof AuthenticationFlowError) {
      Logger.exception('Authentication Flow Error', e);
      this.setError(e.message, e.errorCode, e.errorHeader);
    } else {
      throw e;
    }
  }

  protected handleAuthorizationCodeRedirect = async (): Promise<RedirectLoginResult> => {
    let redirectResponse: RedirectLoginResult | undefined;
    try {
      redirectResponse = await this.authentication.handleRedirectCallback();
    } catch (e) {
      Logger.exception('Unable to handle login callback.', e);
      throw new AuthenticationFlowError(
        'Sorry. we are not able to process your request.',
        HttpErrorCode.InternalServerError,
      );
    } finally {
      this.clearUrlQueryParams();
    }
    return redirectResponse;
  };

  protected clearUrlQueryParams = () => {
    // Get rid of code and state parameter after handling them in Auth0 sdk - for security reasons
    this.navigation.currentUrl = new RelativeUrl(this.navigation.currentUrl.path);
  };

  protected navigateToHomePage = (storeId: string, languageCode: string) => {
    const homePath = RouteUrlBuilder.getUrlTo(RouteDefinitions.homeRoute);
    const storeUrl = StoreUrlBuilder.buildRawStoreUrl(storeId, languageCode, homePath);
    this.navigation.navigateTo(storeUrl);
  };
}
