import { action, makeObservable, observable } from 'mobx';

import { FinalVisualizationBuilder } from '../../../configurator/VisualizationBuilder';
import { PredefinedConfigurationData } from '../../../data/model';
import { ImageDataWithShot } from '../../../shared/components/ModelViewer/ModelViewerState';
import { IConfigurationCodeResolutionService } from '../../../shared/ConfigurationCodeResolutionService';
import { VisualizationAspectRatio } from '../../../shared/visualization';

export type SuggestedConfiguration = {
  image: ImageDataWithShot & { code: string; aspectRatio: string };
  code: string;
};

export default class SuggestedConfigurationsState {
  private codeResolver: IConfigurationCodeResolutionService;

  @observable.shallow
  public models: Array<SuggestedConfiguration>;

  @observable
  public hidden: boolean;

  constructor(codeResolver: IConfigurationCodeResolutionService) {
    makeObservable(this);
    this.codeResolver = codeResolver;

    this.hidden = true;
    this.models = [];
  }

  public onClick(configuration: SuggestedConfiguration) {
    this.codeResolver.resolveAndGoTo(configuration.code);
  }

  @action
  public initialize(suggestedConfigurations: PredefinedConfigurationData[]) {
    if (!suggestedConfigurations) {
      return;
    }

    let availableSlots = 5;
    const models: SuggestedConfiguration[] = [];

    suggestedConfigurations.some((x) => {
      if (!x.code) {
        return false;
      }

      const isWide = x.visualizationAspectRatio?.toLowerCase() === VisualizationAspectRatio.Wide.toLowerCase();
      const slotSize = isWide ? 1.5 : 1;

      if (slotSize > availableSlots) {
        return false;
      }

      models.push({
        image: {
          shot: x.shot,
          code: x.code,
          url: FinalVisualizationBuilder.buildUrl(x.code, x.shot, 'png'),
          name: x.image.name,
          size: { height: 365, width: isWide ? 306 : 208 },
          aspectRatio: x.visualizationAspectRatio,
        },
        code: x.code,
      });
      availableSlots -= slotSize;

      return availableSlots < 1;
    });

    this.models = models;
  }
}
