import classNames from 'classnames';

import Link from '../../../shared/components/Link';
import List from '../../../shared/components/List/List';
import { TabState } from '../ConfiguratorTabs/TabState';
import css from './ConfiguratorTabList.css';

interface ConfiguratorTabListProps {
  className?: string;
  items?: TabState[];
  onItemClick?: (nextTab: TabState) => void;
}

const ConfiguratorTabList = ({ className, onItemClick, items }: ConfiguratorTabListProps) => {
  const renderTabItem = (item: TabState) => {
    return (
      <Link onClick={() => onItemClick(item)} className={classNames(css.link, { [css.linkActive]: item.isActive })}>
        {item.name}
      </Link>
    );
  };

  return (
    <List
      items={items}
      itemRenderer={renderTabItem}
      className={classNames(css.items, className)}
      itemClassName={css.item}
    />
  );
};

export default ConfiguratorTabList;
