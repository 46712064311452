class DibsLanguageResolver {
  private static readonly defaultLanguage = 'en-GB';
  private static readonly languages = new Map([
    ['en-GB', 'en-GB'],
    ['da-DK', 'da-DK'],
    ['de-AT', 'de-DE'],
    ['de-CH', 'de-DE'],
    ['de-DE', 'de-DE'],
    ['en-GB', 'en-gb'],
    ['en-US', 'en-GB'],
    ['fr-BE', 'fr-FR'],
    ['fr-CH', 'fr-FR'],
    ['fr-FR', 'fr-FR'],
    ['nb-NO', 'nb-NO'],
    ['nl-BE', 'nl-NL'],
    ['nl-NL', 'nl-NL'],
    ['pl-PL', 'pl-PL'],
    ['sv-SE', 'sv-SE'],
  ]);

  public static resolve(languageCode: string) {
    return this.languages.get(languageCode) ?? this.defaultLanguage;
  }
}

export default DibsLanguageResolver;
