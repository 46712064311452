import { isBrowser } from '../../app/shared/common';
import Logger from '../../app/shared/Logger';

class Fullscreen {
  public static get isActive() {
    return Fullscreen.isAvailable && !!document.fullscreenElement;
  }

  private static get isAvailable() {
    if (!isBrowser()) {
      return false;
    }

    if (!document.fullscreenEnabled) {
      Logger.log(`The browser does not support or does not allow the fullscreen mode.`);
      return false;
    }

    return true;
  }

  public static open(element?: HTMLElement) {
    if (!Fullscreen.isAvailable || !element) {
      return;
    }

    return element.requestFullscreen();
  }

  public static close() {
    if (!Fullscreen.isAvailable || !Fullscreen.isActive) {
      return;
    }

    return document.exitFullscreen();
  }
}

export default Fullscreen;
