import classNames from 'classnames';
import { Component } from 'react';

import { MoneyData } from '../../../app/data/model';
import Price from '../../../app/shared/components/Price';
import { PropertyItem } from '../../../app/shared/components/PropertyList/PropertyItem';
import { PropertyList } from '../../../app/shared/components/PropertyList/PropertyList';
import { CartSummaryTranslation } from '../../localization/SiteTranslation';
import css from './CartSummary.css';

interface CartSummaryProps {
  className?: string;
  shoppingCart: {
    subTotal: MoneyData;
    subTotalTax: MoneyData;
    subTotalWithTax: MoneyData;
    totalWithTax: MoneyData;
    taxRatePercentage: number;
  };
  numberOfItems?: number;
  translation: CartSummaryTranslation;
}

export default class CartSummary extends Component<CartSummaryProps> {
  render() {
    const { shoppingCart, numberOfItems, className, translation } = this.props;

    const vatLabel = translation.vatLabelFormat.interpolate([['taxRate', shoppingCart.taxRatePercentage.toString()]]);

    const summaryCartPricesList = [
      { label: translation.priceBeforeVatLabel, value: <Price price={shoppingCart.subTotal} /> },
      { label: vatLabel, value: <Price price={shoppingCart.subTotalTax} /> },
      { label: translation.priceIncludingVatLabel, value: <Price price={shoppingCart.subTotalWithTax} /> },
    ];

    return (
      <div className={classNames(css.CartSummary, className)}>
        {numberOfItems && (
          <PropertyItem
            className={css.cartSummaryItemsNumberProp}
            labelOptions={{ colorGrey: true }}
            label={translation.noItemsLabel}
            value={numberOfItems}
          />
        )}

        <PropertyList
          className={css.cartSummaryPriceList}
          items={summaryCartPricesList}
          itemClassName={css.cartSummaryPricesItem}
        />

        <PropertyItem
          className={css.cartSummarySubtotal}
          labelClassName={css.label}
          label={translation.subtotalHeader}
          value={<Price className={css.price} price={shoppingCart.subTotalWithTax} />}
        />

        <PropertyItem
          className={css.cartSummaryDeliveryFee}
          label={translation.deliveryFeeLabel}
          valueClassName={css.value}
          value={translation.accordingToAgreementText}
        />

        <p className={css.cartSummaryAnnotation}>{translation.surchargesAnnotationText}</p>
      </div>
    );
  }
}
