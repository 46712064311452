import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { TextInput } from '../Form';
import css from './FormInput.css';
import { ErrorMessages, ErrorMessagesStylesProps } from './Input/ErrorMessages/ErrorMessages';
import InputField, { InputFieldProps } from './Input/InputField';
import InputWithLoader, { InputLoaderProps } from './Input/InputWithLoader';
import Label from './Label/Label';

export interface FormInputProps extends InputFieldProps, ErrorMessagesStylesProps, InputLoaderProps {
  label?: string;
  inputClassName?: string;
  labelClassName?: string;
  showValidationHints?: boolean;
}

@observer
class FormInput extends Component<FormInputProps, {}> {
  otherProps: FormInputProps;

  renderInput() {
    const { input, inputClassName, ...props } = this.otherProps;
    return <InputField input={input} invalid={!input.isValid} className={inputClassName} {...props} />;
  }

  renderInputWithLoader(isLoading: boolean, loaderClassName: string, loaderContainerClassName: string) {
    return (
      <InputWithLoader
        isLoading={isLoading}
        loaderClassName={loaderClassName}
        loaderContainerClassName={loaderContainerClassName}
      >
        {this.renderInput()}
      </InputWithLoader>
    );
  }

  render() {
    const {
      label,
      input,
      isLoading,
      errorContainerClassName,
      errorItemClassName,
      errorMessageClassName,
      labelClassName,
      loaderClassName,
      loaderContainerClassName,
      className,
      showValidationHints,
      ...otherProps
    } = this.props;
    this.otherProps = { input, ...otherProps };
    const validationHints = showValidationHints ? ` (${input.validationHints.join(', ')})` : '';

    return (
      <div className={classNames(css.FormInput, className)}>
        {label && (
          <Label htmlFor={this.props.id} className={labelClassName}>
            {label}
            {validationHints}
          </Label>
        )}
        {isLoading
          ? this.renderInputWithLoader(isLoading, loaderClassName, loaderContainerClassName)
          : this.renderInput()}
        <ErrorMessages
          errorContainerClassName={errorContainerClassName}
          errorItemClassName={errorItemClassName}
          errorMessageClassName={errorMessageClassName}
          errors={FormInput.getValidationErrorMessages(input)}
        />
      </div>
    );
  }

  static getValidationErrorMessages(input: TextInput) {
    if (!input.isValid) {
      return input.errorMessages;
    }
    return null;
  }
}

export default FormInput;
