import { Scene } from '@babylonjs/core';

import { GroupState } from '../../core/GroupState';
import { ObjectState } from '../../core/ObjectState';
import { AbstractVisual } from './AbstractVisual';
import { GroupVisual } from './GroupVisual';
import { IVisualFactory } from './VisualFactory';

export class GroupVisualFactory implements IVisualFactory<GroupState> {
  canCreate(object: ObjectState): boolean {
    return object instanceof GroupState;
  }

  createAsync(scene: Scene, object: GroupState): Promise<AbstractVisual> {
    const groupVisual = new GroupVisual(object, []);
    return Promise.resolve(groupVisual);
  }
}
