import { observer } from 'mobx-react';
import { Fragment } from 'react';

import { PhysicalStoreData } from '../../../data/model';
import { StoreLocatorDetailsTranslation, StoreTypeTranslation } from '../../../localization/SiteTranslation';
import NavButton from '../../../shared/components/Button/NavButton';
import Icon from '../../../shared/components/Icon/Icon';
import Link from '../../../shared/components/Link';
import css from './StoreDetails.css';

interface StoresDetailsProps {
  data: PhysicalStoreData;
  directionsUrl: string;
  translation: StoreLocatorDetailsTranslation;
  storeTypeTranslation: StoreTypeTranslation;
  close: () => void;
}

const StoresDetails = observer(
  ({ data, directionsUrl, translation, storeTypeTranslation, close }: StoresDetailsProps) => {
    const storeType = data.storeTypes.map((x) => storeTypeTranslation[x] || x);
    const renderHours = data.openingHours.map((x, i) => (
      <Fragment key={'opening' + i}>
        <br />
        {x.note == null ? `${x.days} : ${x.hours}` : x.note}
      </Fragment>
    ));

    return (
      <div className={css.StoreDetails}>
        <button className={css.close} onClick={close}>
          <Icon className={css.closeIcon} name="cross" />
          <span className="visuallyHidden">{translation.closeButton}</span>
        </button>

        <NavButton className={css.back} onClick={close} back>
          {translation.backButton}
        </NavButton>

        <h3 className={css.heading}>{data.name}</h3>
        <span className={css.type}>{storeType.join(', ')}</span>

        <div className={css.dataContainer}>
          <div className={css.dataColumn}>
            <span className={css.section}>
              {translation.address}
              <br />
              {data.address.line1} {data.address.line2}
              {data.address.city} <br />
              {data.address.zipCode}
            </span>
          </div>
          {data.openingHours.any() && (
            <div className={css.dataColumn}>
              {translation.openHours}
              {renderHours}
            </div>
          )}

          <div className={css.dataColumn}>
            <span className={css.section}>
              {translation.phone}
              <br />
              {data.phone}
            </span>
            <span className={css.section}>
              {translation.email}
              <br />
              <Link href={`mailto:${data.email}`}>{data.email}</Link>
            </span>
            {data.webSite && (
              <span className={css.section}>
                {translation.webSite}
                <br />
                <Link target={'_blank'} href={data.webSite}>
                  {data.webSite}
                </Link>
              </span>
            )}
          </div>

          <Link href={directionsUrl} target="_blank" className={css.link}>
            {translation.directionLink}
          </Link>
        </div>
      </div>
    );
  },
);

export default StoresDetails;
