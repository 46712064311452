import classNames from 'classnames';
import { Component } from 'react';

import InputSearch, { InputSearchProps } from '../InputSearch';
import css from './InputSearchExpanding.css';

interface InputSearchState {
  isExpanded: boolean;
  isFilled: boolean;
}

class InputSearchExpanding extends Component<InputSearchProps, InputSearchState> {
  inputSearchContainerRef: HTMLDivElement;

  constructor(props: InputSearchProps) {
    super(props);
    this.state = { isExpanded: false, isFilled: false };

    this.handleClickOutsideSearchInput = this.handleClickOutsideSearchInput.bind(this);
    this.handleMagnifyClick = this.handleMagnifyClick.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('mousedown', this.handleClickOutsideSearchInput);
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClickOutsideSearchInput);
  }

  // tslint:disable-next-line: no-any
  handleClickOutsideSearchInput(e: any) {
    if (this.state.isExpanded && !this.inputSearchContainerRef.contains(e.target)) {
      this.setState({ isExpanded: false });
    }
  }

  handleMagnifyClick() {
    this.setState({ isExpanded: true });

    if (this.props.onMagnifyClick) {
      this.props.onMagnifyClick();
    }
  }

  handleOnChange(isFilled: boolean) {
    this.setState({ isFilled });
  }

  render() {
    const { className, ...otherProps } = this.props;
    return (
      <InputSearch
        setRef={(node) => (this.inputSearchContainerRef = node)}
        className={classNames(
          css.InputSearchExpanding,
          this.state.isExpanded && css.isExpanded,
          this.state.isFilled && css.isFilled,
          className,
        )}
        classNameContainer={classNames(css.searchBox)}
        classNameButtonClose={classNames(css.buttonClose)}
        onMagnifyClick={() => this.handleMagnifyClick()}
        onChange={(isFilled) => this.handleOnChange(isFilled)}
        {...otherProps}
      />
    );
  }
}

export default InputSearchExpanding;
