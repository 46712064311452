import { action, IObservableArray, makeObservable, observable } from 'mobx';

import { CubeState } from '../3dEngine/core/CubeState';
import { GroupState } from '../3dEngine/core/GroupState';
import { LoadableMeshState } from '../3dEngine/core/LoadableMeshState';
import { ObjectState } from '../3dEngine/core/ObjectState';
import { SceneState } from '../3dEngine/core/SceneState';
import { Visualization3dBuilder } from '../configurator/Visualization3dBuilder';
import { IApiClient } from '../data/client';
import {
  GetModularLayoutQuery,
  GetProductByIdQuery,
  GetProductQueryResponse,
  ModularLayoutData,
  ShoppingContext,
  Visualization3dData,
} from '../data/model';
import { BasePageState } from '../shared/BasePageState';
import { IMemento } from '../shared/common';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { StoreState } from '../StoreState';

export interface IModularConfiguratorMemento extends IMemento {
  shoppingContext: ShoppingContext;
  layout?: ModularLayoutData;
}

export class ModularComponentState {
  constructor(public sceneObject: ObjectState, public productData?: GetProductQueryResponse) {}
}

export class ModularConfiguratorPageState extends BasePageState<IModularConfiguratorMemento> {
  private shoppingContext: ShoppingContext;
  private layout?: ModularLayoutData;

  public scene: SceneState = new SceneState();
  public assetsLoadingIndicator = new LoadingIndicator();

  @observable.shallow
  public components: IObservableArray<ModularComponentState> = [] as IObservableArray<ModularComponentState>;

  constructor(private apiClient: IApiClient) {
    super();
    makeObservable(this);
  }

  async onLoad(store: StoreState): Promise<void> {
    // TODO: get the layout code from query if provided
    const response = await this.apiClient.send(
      new GetModularLayoutQuery({ layoutId: 'ml-profim-revo-LC-18', shoppingContext: store.shoppingContext }),
    );

    this.initialize(store.shoppingContext, response.layout);
  }

  async onLoadAdditionalData() {
    if (!this.layout) {
      return;
    }

    this.assetsLoadingIndicator.start();

    const distinctProductsIds = this.layout.components.map((x) => x.productId).distinct();
    const productsData = await Promise.all(
      distinctProductsIds.map((x) =>
        this.apiClient.send(new GetProductByIdQuery({ productId: x, shoppingContext: this.shoppingContext })),
      ),
    );

    this.layout.components.forEach((component) => {
      const data = productsData.find((x) => x.baseModel.id === component.productId);
      // TODO: get it from the configurator state (rules check etc)
      const selectedProductComponents = new Map<string, string>(
        data.options.map((x) => [
          x.code,
          x.groups.first().variations[Math.floor(Math.random() * x.groups.first().variations.length)].code,
        ]),
      );
      const selected3dProductComponents = Visualization3dBuilder.buildComponents3d(
        data.visualization as Visualization3dData,
        selectedProductComponents,
      );

      const sceneObjects = selected3dProductComponents.map(
        (x) => new LoadableMeshState(x.mesh.name, x.mesh.url, x.material?.name, x.material?.url),
      );
      const groupingSceneObject = new GroupState(data.baseModel.id, sceneObjects);
      groupingSceneObject.position = component.position;
      groupingSceneObject.rotation = component.rotation;
      this.scene.addObject(groupingSceneObject);

      const componentState = new ModularComponentState(groupingSceneObject, data);
      this.components.push(componentState);
    });

    this.assetsLoadingIndicator.stop();
  }

  private initialize(shoppingContext: ShoppingContext, layout: ModularLayoutData) {
    this.shoppingContext = shoppingContext;
    this.layout = layout;
  }

  getMemento(): IModularConfiguratorMemento {
    return {
      shoppingContext: this.shoppingContext,
      layout: this.layout,
    };
  }

  restoreMemento(memento: IModularConfiguratorMemento) {
    this.initialize(memento.shoppingContext, memento.layout);
  }

  @action
  selectObject(object: ObjectState) {
    this.clearSelection();
    object.setSelected(true);
  }

  @action
  clearSelection() {
    this.scene.objects.forEach((x) => x.setSelected(false));
  }

  @action
  removeSelected() {
    this.scene.objects
      .filter((x) => x.selected)
      .forEach((x) => {
        this.scene.objects.remove(x);
      });
  }

  @action
  public addCube(): void {
    const cube = new CubeState(`Cube ${this.scene.objects.length + 1}`, 1);
    cube.position.y = 0.5;
    this.scene.addObject(cube);
  }

  @action
  public addFromFile(): void {
    const loadable1 = new LoadableMeshState('P60', 'https://flokkrendersint.blob.core.windows.net/profim-revo/P60.glb');
    this.scene.addObject(loadable1);

    const loadable2 = new LoadableMeshState(
      'B180',
      'https://flokkrendersint.blob.core.windows.net/profim-revo/B180.glb',
    );

    const loadable3 = new LoadableMeshState(
      'SW180',
      'https://flokkrendersint.blob.core.windows.net/profim-revo/SW180.glb',
    );

    const group1 = new GroupState('group1', [loadable2, loadable3]);
    group1.position.x = 1;
    this.scene.addObject(group1);
  }
}
