import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../AppState';
import { PriceProvider } from '../context/PriceContext';
import { ScrollToAnimation } from '../shared/animations';
import NavButton from '../shared/components/Button/NavButton';
import HeaderLevel1 from '../shared/components/Typography/HeaderLevel1';
import css from './CheckoutPage.css';
import { CheckoutState } from './CheckoutState';
import CheckoutForm from './components/CheckoutForm';
import CheckoutPayment from './components/CheckoutPayment';
import CheckoutProgressIndicator from './components/CheckoutProgressIndicator/CheckoutProgressIndicator';
import DiscountCodeForm from './components/DiscountCodeForm/DiscountCodeForm';
import EmptyCartInformation from './components/EmptyCartInformation';
import { ShoppingCartSummary } from './components/ShoppingCartSummary/ShoppingCartSummary';

@observer
export default class CheckoutPage extends Component<{ appState: AppState; state: CheckoutState }, {}> {
  componentDidMount() {
    reaction(
      () => this.props.state.steps.find((x) => x.isActive),
      (x) => x !== undefined && new ScrollToAnimation('div', true).start(),
    );

    reaction(
      () => this.props.state.payment.finalizeCommand.processing,
      (x) => {
        const indicator = this.props.appState.loadingPageIndicator;
        if (x) {
          indicator.start();
        } else {
          indicator.stop();
        }
      },
    );
  }

  renderForm() {
    const { state } = this.props;

    if (!state.form.isActive) {
      return null;
    }

    return (
      <CheckoutForm
        translation={this.props.appState.translation.checkoutPage}
        requestQuoteLinkTranslation={this.props.appState.translation.requestQuoteLink}
        isSubmitButtonDisabled={this.props.state.isFormSubmitButtonDisabled}
        isRequestQuoteLink={this.props.appState.store.canRequestQuote()}
        termsLink={this.props.state.termsAndConditions}
        legalAgreementsConsentHtml={this.props.state.legalAgreementsConsentHtml}
        form={state.form}
        command={state.createOrderCommand}
        configurationCode={state.shoppingCart.items.first().code}
      />
    );
  }

  renderPayment() {
    const { state } = this.props;

    if (!state.payment.isActive) {
      return null;
    }

    return (
      <CheckoutPayment
        settings={this.props.appState.settings}
        checkout={state}
        translation={this.props.appState.translation.checkoutPage}
      />
    );
  }

  renderSteps() {
    return (
      <div className={css.stepContainer}>
        {this.renderForm()}
        {this.renderPayment()}
      </div>
    );
  }

  get translation() {
    return this.props.appState.translation.checkoutPage;
  }

  renderDiscountCodeForm() {
    const { state } = this.props;

    if (!state.form.isActive) {
      return null;
    }

    return (
      <DiscountCodeForm
        className={css.discountCodeForm}
        activeDiscountCode={state.activeDiscountCode}
        state={state.discountCodeForm}
      />
    );
  }

  renderCheckoutSummary() {
    const { state } = this.props;

    return (
      <ShoppingCartSummary
        translation={this.props.appState.translation.cart.summary}
        shoppingCart={state.shoppingCart}
        shippingCostAvailable={state.shippingCostAvailable}
      />
    );
  }

  render() {
    const { state, appState } = this.props;

    if (state.shoppingCart.items.length === 0) {
      return (
        <EmptyCartInformation
          continueShoppingUrl={appState.navigationState.homeUrl}
          translation={this.props.appState.translation.emptyCartPage}
        />
      );
    }

    return (
      <PriceProvider enable={true} displayDecimalPart={appState.store.shouldDisplayDecimalPartOfPrices}>
        <NavButton onClick={state.backToPreviousPage} back>
          {this.translation.goBackLink}
        </NavButton>
        <HeaderLevel1 className={css.pageTitle}>{this.translation.title}</HeaderLevel1>
        <CheckoutProgressIndicator steps={this.props.state.steps} />
        <div className={css.content}>
          {this.renderSteps()}
          <div className={css.shoppingCartContainer}>
            {this.renderDiscountCodeForm()}
            {this.renderCheckoutSummary()}
          </div>
        </div>
      </PriceProvider>
    );
  }
}
