import { HTMLAttributes } from 'react';

const RawHtml = (props: { content: string } & HTMLAttributes<HTMLElement>) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.content,
      }}
      {...props}
    />
  );
};

export default RawHtml;
