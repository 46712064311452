import { action, makeObservable, observable } from 'mobx';

import { ShoppingCartItemData } from '../../app/data/model';
import { ModalState } from '../../app/shared/ModalState';
import { FavoriteItemDetailsModalTranslation } from '../localization/SiteTranslation';

export class FavoriteItemModalState extends ModalState {
  @observable
  public favoriteItem?: ShoppingCartItemData;

  @action
  public setFavoriteItem(item: ShoppingCartItemData) {
    this.favoriteItem = item;
  }

  constructor(public readonly translation: FavoriteItemDetailsModalTranslation) {
    super();
    makeObservable(this);
  }
}
