import { CardElement } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardElementChangeEvent,
  StripeElementLocale,
  StripeElements,
} from '@stripe/stripe-js';
import { action, makeObservable, observable, override } from 'mobx';

import { OrderData, PaymentData } from '../../../data/model';
import { AppError } from '../../../shared/AppError';
import { AuthorizationCompletedEventArgs, BasePaymentMethod } from '../IPaymentMethod';

export default class StripePaymentMethod extends BasePaymentMethod {
  public loadStripePromise: Promise<Stripe>;

  @observable.ref
  public stripe: Stripe;
  @observable.ref
  public elements: StripeElements;

  public publishableKey: string;
  public clientSecret: string;

  @observable
  public cardValidationError: string;

  @observable
  public cardDataComplete: boolean = false;

  @override get isValid(): boolean {
    return !this.cardValidationError && this.cardDataComplete && !!this.elements && !!this.stripe;
  }

  constructor(public readonly languageCode: string) {
    super('Stripe.Payments');
    makeObservable(this);
    this.showAuthorizeButton = true;
  }

  async initialize(order: OrderData, payment: PaymentData): Promise<void> {
    this.publishableKey = payment.parameters.find((x) => x.key === 'PublishableKey')?.value;
    this.clientSecret = payment.parameters.find((x) => x.key === 'ClientSecret')?.value;

    if (!this.publishableKey) {
      throw 'Publishable key not defined for Stripe payment method';
    }

    if (!this.clientSecret) {
      throw 'Client secret key not defined for Stripe payment method';
    }

    const locale = this.languageCode?.substr(0, 2) as StripeElementLocale;

    this.loadStripePromise = loadStripe(this.publishableKey, { locale: locale });
    this.stripe = await this.loadStripePromise;
  }

  async authorize(): Promise<void> {
    const payload = await this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.elements.getElement(CardElement),
      },
    });

    if (payload.error) {
      throw new AppError(payload.error.message, payload.error.message);
    }

    const args: AuthorizationCompletedEventArgs = {
      parameters: [{ key: 'paymentIntentId', value: payload.paymentIntent?.id }],
    };

    this.authorizationCompleted.raise(args);
  }

  public onWidgetRendered() {
    this.setIsInitialized();
  }

  @action
  public setIsInitialized() {
    this.isInitialized = true;
  }

  @action
  public setCardValidationError(error: string) {
    this.cardValidationError = error;
  }

  @action
  public setCardDataComplete(complete: boolean) {
    this.cardDataComplete = complete;
  }

  @action
  public setElements(elements: StripeElements) {
    this.elements = elements;
  }

  public onCardInputChange(event: StripeCardElementChangeEvent) {
    this.setCardValidationError(event.error?.message);
    this.setCardDataComplete(event.complete);
  }
}
