import { Component } from 'react';

import { DesignerData } from '../../../data/model';
import { DesignerInfoTranslation } from '../../../localization/SiteTranslation';
import ExternalImage from '../../../shared/components/ExternalImage/ExternalImage';
import RawHtml from '../../../shared/components/RawHtml';
import HeaderLevel1 from '../../../shared/components/Typography/HeaderLevel1';
import HeaderLevel3 from '../../../shared/components/Typography/HeaderLevel3';
import { UnderlineLink } from '../../../shared/components/UnderlineLink/UnderlineLink';
import css from './ProductDesignerInfo.css';

interface ProductDesignerInfoProps {
  designer: DesignerData;
  translation: DesignerInfoTranslation;
}

export default class ProductDesignerInfo extends Component<ProductDesignerInfoProps, {}> {
  render() {
    const { designer, translation } = this.props;
    if (!this.props.designer) {
      return null;
    }

    return (
      <div className={css.ProductDesignerInfo}>
        <div className={css.container}>
          <div className={css.imageContainer}>
            <ExternalImage className={css.image} src={designer.imageUrl} alt={designer.name} />
          </div>
          <div className={css.content}>
            <HeaderLevel3 className={css.subtitle}>{translation.designedBy}</HeaderLevel3>
            {designer.name && <HeaderLevel1 className={css.name}>{designer.name}</HeaderLevel1>}
            {designer.description && <RawHtml className={css.description} content={designer.description} />}
            {designer.url && <UnderlineLink href={designer.url}>{translation.readMore}</UnderlineLink>}
          </div>
        </div>
      </div>
    );
  }
}
