import { isBrowser, loadScriptAsync } from '../../shared/common';

export class AsyncScriptLoader {
  public static loaded: { [key: string]: boolean } = {};

  public static async ensureLoaded(uri: string, crossOrigin?: string): Promise<void> {
    if (AsyncScriptLoader.loaded[uri]) {
      return Promise.resolve();
    }

    if (isBrowser()) {
      await loadScriptAsync(uri, crossOrigin);
    }

    AsyncScriptLoader.loaded[uri] = true;
    return Promise.resolve();
  }
}
