import { IApiClient } from '../../../app/data/client';
import { GetDealerContactQuery, SendCartContentCommand, ShoppingContext } from '../../../app/data/model';
import { AsyncCommand } from '../../../app/shared/common';
import {
  EmailAddressSplitter,
  MultipleEmailAddressesRule,
  TextInput,
  ValueRequiredRule,
} from '../../../app/shared/Form';
import { LoadingIndicator } from '../../../app/shared/LoadingIndicator';
import { ModalState } from '../../../app/shared/ModalState';
import { SendCartToEmailTranslation } from '../../localization/SiteTranslation';

export class SendCartToEmailModalState extends ModalState {
  private selectedShoppingCartsIds: string[] = [];

  emailInput: TextInput;

  initializationIndicator = new LoadingIndicator();
  initialized = false;

  sendEmailCommand: AsyncCommand;

  constructor(
    private client: IApiClient,
    private shoppingContext: ShoppingContext,
    public translation: SendCartToEmailTranslation,
  ) {
    super();
    this.shoppingContext = shoppingContext;
    this.emailInput = new TextInput().withRule(new ValueRequiredRule()).withRule(new MultipleEmailAddressesRule());
    this.sendEmailCommand = new AsyncCommand(this.sendEmail, this.emailInput);
  }

  private async ensureInitialized() {
    if (this.initialized) {
      return;
    }

    this.initializationIndicator.start();
    const response = await this.client.send(new GetDealerContactQuery());
    this.initializationIndicator.stop();

    this.emailInput.setDefaultValue(response.contact.email || '');
    this.initialized = true;
  }

  public setSelectedShoppingCartsIds(ids: string[]) {
    this.selectedShoppingCartsIds = ids;
  }

  private sendEmail = async () => {
    const emails = EmailAddressSplitter.split(this.emailInput.value);

    await this.client.send(
      new SendCartContentCommand({
        shoppingContext: this.shoppingContext,
        recipients: emails,
        shoppingCartsIds: this.selectedShoppingCartsIds,
      }),
    );

    this.close();
    return Promise.resolve();
  };

  protected onAfterOpen() {
    this.ensureInitialized();
  }
}
