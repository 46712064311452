import { observer } from 'mobx-react';
import { Component } from 'react';

import { RenderWhenPriceEnabled } from '../../../context/PriceContext';
import { AsyncCommand } from '../../../shared/common';
import List from '../../../shared/components/List/List';
import ModelViewer from '../../../shared/components/ModelViewer/ModelViewer';
import Price from '../../../shared/components/Price';
import { StoreState } from '../../../StoreState';
import PredefinedConfigurations from '../PredefinedConfigurations/PredefinedConfigurations';
import ProductModelInfo from '../ProductModelInfo/ProductModelInfo';
import ProductModelSelector from '../ProductModelSelector/ProductModelSelector';
import css from './ProductDetails.css';
import ProductDetailsState from './ProductDetailsState';
import { ProductFeatureTogglesState } from './ProductFeatureTogglesState';
import { ProductModelActionButtons } from './ProductModelDetailsButtons';

interface ProductDetailsProps {
  storeState: StoreState;
  state: ProductDetailsState;
  familyName: string;
  canRequestQuote: boolean;
  goToConfiguratorCommand: AsyncCommand;
  goToCheckoutCommand: AsyncCommand;
  askForPriceCommand: AsyncCommand;
  showWhereToBuy: boolean;
  whereToBuyPageUrl?: string;
}

export interface ProductFeatureToggles {
  whereToBuyEnabled: boolean;
  goToCheckoutEnabled: boolean;
  askForPriceEnabled: boolean;
  configureChairEnabled: boolean;
  designerEnabled: boolean;
}

@observer
export default class ProductDetails extends Component<ProductDetailsProps> {
  render() {
    const { state, goToConfiguratorCommand, goToCheckoutCommand, askForPriceCommand, whereToBuyPageUrl } = this.props;
    const optionsVisibilityState = new ProductFeatureTogglesState(this.props.storeState, this.props.state);

    return (
      <div className={css.ProductDetails}>
        <div>
          {state.modelViewer.items.any() && (
            <ModelViewer className={css.modelViewer} imageClassName={css.image} state={state.modelViewer} />
          )}
        </div>
        <div className={css.modelInformation}>
          <ProductModelInfo model={state.modelSelector.active} />
          <List
            className={css.iconList}
            items={state.icons}
            itemRenderer={(item) => <img className={css.icon} src={item.url} alt={item.name} />}
          />
        </div>
        {state.modelSelector.items.any() && (
          <ProductModelSelector
            className={css.modelSelector}
            state={state.modelSelector}
            translation={state.translation.modelsSection.productModelSelector}
          />
        )}
        <div className={css.modelConfigurator}>
          {state.predefinedConfigurations.items.any() && (
            <PredefinedConfigurations
              configurations={state.predefinedConfigurations}
              translation={state.translation.modelsSection.productInfo}
              onConfigure={() => goToConfiguratorCommand.action(undefined)}
            />
          )}
          {optionsVisibilityState.purchaseEnabled && (
            <RenderWhenPriceEnabled>
              {state.modelSelector.active.data.price?.amount > 0 && (
                <div className={css.containerPrice}>
                  {state.translation.modelsSection.productInfo.priceSection.fromText}{' '}
                  <Price className={css.price} price={state.modelSelector.active.data.price} />
                  <span className={css.textTax}>
                    {state.translation.modelsSection.productInfo.priceSection.taxText}
                  </span>
                </div>
              )}
            </RenderWhenPriceEnabled>
          )}
          <ProductModelActionButtons
            optionsVisibility={optionsVisibilityState}
            goToConfiguratorCommand={goToConfiguratorCommand}
            goToCheckoutCommand={goToCheckoutCommand}
            askForPriceCommand={askForPriceCommand}
            whereToBuyPageUrl={whereToBuyPageUrl}
            translation={state.translation.modelsSection.productInfo}
          />
        </div>
      </div>
    );
  }
}
