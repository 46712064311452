import { ImageData } from '../../../../../../app/data/model';
import Image from '../../../../../../app/shared/components/Image/Image';
import Link from '../../../../../../app/shared/components/Link';
import { RelativeUrl } from '../../../../../../app/shared/Url';
import css from './ProductCarouselItem.css';

interface ProductCarouselItemProps {
  url: RelativeUrl;
  image: ImageData;
}

const ProductCarouselItem = ({ image, url }: ProductCarouselItemProps) => (
  <Link className={css.ProductCarouselItem} to={url}>
    <Image classNameUnloaded={css.unloadedImage} src={image.url} alt={image.name} />
  </Link>
);

export default ProductCarouselItem;
