import classNames from 'classnames';
import { CSSProperties } from 'react';

import BodyScroll from '../shared/components/BodyScroll/BodyScroll';
import css from './AnimatedTab.css';
import { AnimatedPanel, PanelAnimations } from './animations/AnimatedPanel/AnimatedPanel';

interface AnimatedTabProps {
  children?: JSX.Element;
  className?: string;
  containerClassName?: string;
  style?: CSSProperties;
  enableScroll?: boolean;
  disableScroll?: boolean;
  animations: PanelAnimations;
  handleTabClick?: () => void;
  isFixed?: boolean;
}

const AnimatedTab = (props: AnimatedTabProps) => {
  const {
    enableScroll,
    disableScroll,
    handleTabClick,
    children,
    animations,
    className,
    containerClassName,
    style,
    isFixed,
  } = props;

  return (
    <BodyScroll active={enableScroll} disable={disableScroll} isFixed={isFixed}>
      <div className={classNames(css.AnimatedTab, className)} style={style}>
        <AnimatedPanel
          animations={animations}
          className={classNames(css.container, containerClassName)}
          onClick={handleTabClick}
        >
          {children}
        </AnimatedPanel>
      </div>
    </BodyScroll>
  );
};

export default AnimatedTab;
