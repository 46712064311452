import { Component as ReactComponent, ComponentType } from 'react';

import { FormInputProps } from '../../../../shared/components/FormInput';

type WithPrefixProps = {
  idPrefix?: string;
};

export const withIdPrefix = <P extends FormInputProps & WithPrefixProps>(
  Component: ComponentType<Omit<P, keyof WithPrefixProps>>,
  name: string,
) => {
  return class extends ReactComponent<P> {
    render() {
      const { idPrefix, ...otherProps } = this.props;
      const id = idPrefix ? `${idPrefix}-${name}` : name;

      return <Component {...(otherProps as P)} id={id} name={name} />;
    }
  };
};
