import { observable } from 'mobx';

import { ObjectState } from './ObjectState';

export class CubeState extends ObjectState {
  @observable
  size: number;

  constructor(name: string, size: number) {
    super(name);
    this.size = size;
  }
}
