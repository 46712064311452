import { Wrapper } from '@googlemaps/react-wrapper';
import { ComponentProps } from 'react';

import MapInternal from './MapInternal/MapInternal';

interface MapProps extends ComponentProps<typeof MapInternal> {
  apiKey: string;
  language: string;
}

const Map = ({ apiKey, children, language, ...mapProps }: MapProps) => {
  return (
    <Wrapper apiKey={apiKey} language={language} libraries={['places']}>
      <MapInternal {...mapProps}>{children}</MapInternal>
    </Wrapper>
  );
};

export default Map;
