import { observer } from 'mobx-react';

import ActionButton from '../../app/shared/components/ActionButton';
import TertiaryButton from '../../app/shared/components/Button/TertiaryButton';
import Modal from '../../app/shared/components/Modal/Modal';
import { ShoppingCartItemBasicInfo } from '../shared/ShoppingCartItemBasicInfo/ShoppingCartItemBasicInfo';
import { cartItemToPanelItem, ShoppingCartItemPanel } from '../shared/ShoppingCartItemPanel/ShoppingCartItemPanel';
import { FavoritesModalHeader } from './FavoritesModalHeader';
import css from './RemovalConfirmationModal.css';
import { RemovalConfirmationModalState } from './RemovalConfirmationModalState';

export const RemovalConfirmationModal = observer(({ state }: { state: RemovalConfirmationModalState }) => {
  if (!state.favoriteItem) {
    return null;
  }
  const translation = state.removalConfirmationModaltranslation;

  return (
    <Modal modal={state} translation={{ closeButton: '' }}>
      <div className={css.removalConfirmationModal}>
        <FavoritesModalHeader heading={translation.header} description={translation.description} />

        <ShoppingCartItemPanel
          item={cartItemToPanelItem(state.favoriteItem)}
          translation={translation.shoppingCartItemPanel}
          verticalSpacing
        >
          <ShoppingCartItemBasicInfo item={state.favoriteItem} translation={translation.shoppingCartItemBasicInfo} />
        </ShoppingCartItemPanel>
        <ActionButton
          command={state.removalAcceptedCommand}
          className={css.confirmButton}
          buttonElement={TertiaryButton}
        >
          {translation.confirmButton}
        </ActionButton>
      </div>
    </Modal>
  );
});
