import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { ICheckoutStep } from '../../ICheckoutStep';
import css from './CheckoutProgressIndicator.css';
import CheckoutProgressIndicatorItem from './CheckoutProgressIndicatorItem';

interface CheckoutProgressIndicatorProps {
  className?: string;
  stepsClassName?: string;
  separatorClassName?: string;
  itemClasses?: {
    item?: string;
    number?: string;
    text?: string;
    label?: string;
    active?: string;
  };
  onStepClick?: (step: ICheckoutStep, isCompleted: boolean) => void;
  steps: Array<ICheckoutStep>;
}

@observer
class CheckoutProgressIndicator extends Component<CheckoutProgressIndicatorProps, {}> {
  render() {
    const { steps, className, stepsClassName, separatorClassName, itemClasses, onStepClick } = this.props;
    const firstActive = steps.find((x) => x.isActive);
    const firstActiveIndex = steps.indexOf(firstActive);

    const renderIndicatorItem = (step: ICheckoutStep, index: number) => {
      const isCompleted = index < firstActiveIndex;
      const isLastItem = index < steps.length - 1;
      return (
        <Fragment key={index}>
          <CheckoutProgressIndicatorItem
            classes={itemClasses}
            order={`${index + 1}`}
            label={step.title}
            active={step.isActive}
            completed={isCompleted}
            onClick={() => onStepClick(step, isCompleted)}
          />
          {isLastItem && (
            <div
              className={classNames(css.separator, separatorClassName && separatorClassName, isCompleted && css.active)}
            />
          )}
        </Fragment>
      );
    };

    return (
      <div className={classNames(css.CheckoutProgressIndicator, className)}>
        <div className={classNames(css.steps, stepsClassName)}>
          {steps.map((step, index) => renderIndicatorItem(step, index))}
        </div>
      </div>
    );
  }
}

export default CheckoutProgressIndicator;
