import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { ConfiguratorPageTranslation, RequestQuoteLinkTranslation } from '../../../localization/SiteTranslation';
import ActionButton from '../../../shared/components/ActionButton';
import IconButton from '../../../shared/components/Button/IconButton';
import TertiaryButton from '../../../shared/components/Button/TertiaryButton';
import Icon from '../../../shared/components/Icon/Icon';
import InputNumber from '../../../shared/components/InputNumber/InputNumber';
import List from '../../../shared/components/List/List';
import { LoadableImage } from '../../../shared/components/LoadableImage';
import Price from '../../../shared/components/Price';
import HeaderLevel3 from '../../../shared/components/Typography/HeaderLevel3';
import { TextLabel, TextLabelStrong } from '../../../shared/components/Typography/TextLabels';
import { Region } from '../../../shared/regions/Region';
import { StoreState } from '../../../StoreState';
import { SelectedFeature } from '../../ConfiguratorCoreState';
import { ConfiguratorState } from '../../ConfiguratorState';
import { FinalVisualizationBuilder } from '../../VisualizationBuilder';
import ConfigurationShare from '../ConfigurationShare/ConfigurationShare';
import { TabState } from '../ConfiguratorTabs/TabState';
import AccessoryItem from './AccessoryItem/AccessoryItem';
import css from './ConfigurationSummary.css';
import FindLocalDealer from './FindLocalDealer/FindLocalDealer';
import OptionItem from './OptionItem/OptionItem';

interface ConfigurationSummaryProps {
  configurator: ConfiguratorState;
  store: StoreState;
  className?: string;
  translation: ConfiguratorPageTranslation;
  requestQuoteLinkTranslation: RequestQuoteLinkTranslation;
  changeTab: (tab: TabState) => void;
  isMobilePortraitViewActive: boolean;
}

@observer
export default class ConfigurationSummary extends Component<ConfigurationSummaryProps> {
  constructor(props: ConfigurationSummaryProps) {
    super(props);

    this.changeTabToDefault = this.changeTabToDefault.bind(this);
  }

  changeTabToDefault() {
    return this.props.changeTab(this.props.configurator.coreState.tabs.defaultTab);
  }

  renderOptions() {
    const { configurator } = this.props;

    const optionsPrice = configurator.coreState.selectedAccessories.any() && (
      <div className={css.optionsPrice}>
        <TextLabelStrong className={css.optionsPriceLabel}>
          <Price price={configurator.coreState.baseModelAndOptionsUnitPriceV2} />
        </TextLabelStrong>
      </div>
    );

    if (configurator.coreState.selectedFeaturesVisuallyGrouped.options.any()) {
      return (
        <Fragment>
          <List
            items={configurator.coreState.selectedFeaturesVisuallyGrouped.options}
            itemRenderer={(item: SelectedFeature) => <OptionItem className={css.optionItem} selectedFeature={item} />}
          />
          {optionsPrice}
        </Fragment>
      );
    }
    return null;
  }

  renderAccessories() {
    const { configurator, translation } = this.props;
    if (configurator.coreState.selectedFeaturesVisuallyGrouped.accessories.any()) {
      return (
        <div className={css.accessoriesContainer}>
          <TextLabelStrong className={css.title}>{translation.summary.accessoriesSubtitle}</TextLabelStrong>
          <List
            items={configurator.coreState.selectedFeaturesVisuallyGrouped.accessories}
            itemRenderer={(item: SelectedFeature) => <AccessoryItem selectedFeature={item} />}
          />
        </div>
      );
    }
    return null;
  }

  renderShipmentAndQuantity() {
    const { configurator, store, translation } = this.props;
    const showExpectedShipment = store.canShowExpectedShipment(configurator);
    const canBePurchased = store.canBePurchased(configurator.baseModel);

    if (canBePurchased) {
      return (
        <div className={classNames(css.shipmentQuantityContainer, css.showExpectedShipment)}>
          {showExpectedShipment && (
            <div className={css.expectedShipment}>
              <span>{translation.summary.expectedShipment.expectedShipment} </span>
              <span className={css.shipmentDays}>
                {configurator.preparingTime.minDays} - {configurator.preparingTime.maxDays}
              </span>{' '}
              <span>{translation.summary.expectedShipment.days}</span>
            </div>
          )}
          <div className={css.quantityContainer}>
            <InputNumber
              className={css.inputNumber}
              onChange={configurator.updateQuantity}
              min={configurator.quantityMin}
              input={configurator.quantityInput}
            />
            <Price className={css.totalPrice} price={configurator.total} taxSuffix={translation.priceTaxText} />
          </div>
        </div>
      );
    }
  }

  renderSendEmailButton() {
    const { configurator, store, translation } = this.props;
    const { configurationCodeLoading } = configurator.coreState;

    return (
      store.canEmailMyConfiguration() && (
        <div className={css.emailButtonContainer}>
          <ActionButton
            className={css.emailButton}
            command={configurator.proceedToEmailConfirmationCommand}
            buttonElement={TertiaryButton}
            disabled={configurationCodeLoading}
          >
            {translation.summary.emailConfirmationButton}
          </ActionButton>
        </div>
      )
    );
  }

  renderFindLocalDealer() {
    const { configurator, translation } = this.props;

    return this.props.store.showFindLocalDealer() ? (
      <FindLocalDealer
        brand={configurator.coreState.brandName}
        translation={translation.summary.findLocalDealerSection}
      />
    ) : null;
  }

  renderConfigurationShare() {
    const { configurator, translation } = this.props;

    return this.props.store.showConfiguratorShare() ? (
      <ConfigurationShare
        state={configurator.shareState}
        isCodeLoading={configurator.coreState.configurationCodeLoading}
        isCodeError={configurator.coreState.configurationCodeError}
        translation={translation.summary.shareSection}
        className={css.configurationShare}
        showDownloads={true}
      />
    ) : null;
  }

  renderEditButton(className: string) {
    const { translation } = this.props;

    return (
      <IconButton
        className={classNames(css.edit, className)}
        icon={<Icon name="edit" />}
        onClick={this.changeTabToDefault}
        bold
      >
        {translation.summary.editConfigurationLink}
      </IconButton>
    );
  }

  renderBackButton() {
    const {
      configurator: {
        coreState: { tabs },
      },
      changeTab,
      translation,
    } = this.props;

    return (
      <IconButton
        className={css.backButton}
        nameClassName={css.name}
        icon={<Icon name="arrowBack" />}
        onClick={() => {
          changeTab(tabs.prevTab);
        }}
      >
        {translation.summary.backButton}
      </IconButton>
    );
  }

  renderActions() {
    return (
      <Region name="ConfiguratorSummary" data={{ configurator: this.props.configurator, store: this.props.store }} />
    );
  }

  render() {
    const { configurator, translation, className, isMobilePortraitViewActive } = this.props;
    const { configurationCodeLoading } = configurator.coreState;

    return (
      <div className={classNames(css.ConfigurationSummary, className)}>
        {configurator.coreState.tabs.canMoveToPrevTab && this.renderBackButton()}
        <div className={css.summaryContainer}>
          <div className={css.content}>
            <HeaderLevel3 className={css.header}>{translation.summary.title}</HeaderLevel3>
            <div className={css.selectedItems}>
              <div className={css.productNameRow}>
                <TextLabel className={css.label}>{configurator.baseModel.name}</TextLabel>
                {this.renderEditButton(css.editDesktop)}
              </div>
              {this.renderOptions()}
              {this.renderAccessories()}
            </div>
          </div>
          {isMobilePortraitViewActive && (
            <div className={css.imageThumbnailContainer}>
              <LoadableImage
                className={css.image}
                classNameUnloaded={css.unloaded}
                src={
                  configurationCodeLoading ? undefined : FinalVisualizationBuilder.buildUrl(configurator.coreState.code)
                }
                width={100}
                height={150}
                onClick={this.changeTabToDefault}
                sharpeningFactor={2}
              />
              {this.renderEditButton(css.editMobile)}
            </div>
          )}
        </div>
        {this.renderShipmentAndQuantity()}
        {this.renderActions()}
        {this.renderSendEmailButton()}
        {this.renderConfigurationShare()}
        {this.renderFindLocalDealer()}
      </div>
    );
  }
}
