import classNames from 'classnames';

import { Alignment, ColorData, ImageData, LinkData } from '../../../../../app/data/model';
import ExternalImage from '../../../../../app/shared/components/ExternalImage/ExternalImage';
import Icon from '../../../../../app/shared/components/Icon/Icon';
import Link from '../../../../../app/shared/components/Link';
import { ModalVideoPlayer } from '../../../../../app/shared/components/ModalVideoPlayer/ModalVideoPlayer';
import HeaderLevel2 from '../../../../../app/shared/components/Typography/HeaderLevel2';
import css from './Teaser.css';

interface TeaserProps {
  id: string;
  description: string;
  title: string;
  backgroundColor?: ColorData;
  image?: ImageData;
  link?: LinkData;
  mediaAlignment?: Alignment;
  textColor?: ColorData;
  video?: {
    src: string;
    posterSrc?: string;
    title?: string;
  };
}

const Teaser = ({
  backgroundColor,
  description,
  id,
  image,
  link,
  textColor,
  title,
  video,
  mediaAlignment = Alignment.Right,
}: TeaserProps) => {
  const hasLink = !!link?.url;
  const hasVideo = !!video?.src;
  const hasMedia = hasVideo || !!image?.url;
  const hasText = title || description;
  const hasContent = hasMedia || hasText;

  return hasContent ? (
    <article className={css.container}>
      <div
        className={classNames(css.inner, {
          [css.withVideo]: hasVideo,
          [css.withLink]: hasLink,
          [css.withMediaOnLeft]: mediaAlignment === Alignment.Left,
        })}
      >
        {hasText && (
          <div className={css.content} style={{ backgroundColor: backgroundColor?.rgba, color: textColor?.rgba }}>
            <div>
              {title && (
                <HeaderLevel2 className={css.title}>
                  {hasLink ? (
                    <Link
                      className={css.link}
                      href={link.url}
                      target={link.openInNewTab ? '_blank' : undefined}
                      rel={link.noFollow ? 'nofollow' : undefined}
                    >
                      {title}
                    </Link>
                  ) : (
                    title
                  )}
                </HeaderLevel2>
              )}
              {description && <p className={css.description}>{description}</p>}
            </div>
            {hasLink && <Icon className={css.icon} name="arrowNext" />}
          </div>
        )}
        {hasMedia && (
          <div className={css.media}>
            {hasVideo ? (
              <ModalVideoPlayer
                id={id}
                imageUrl={video.posterSrc}
                title={video.title}
                videoUrl={video.src}
                isTitleSmall
              />
            ) : (
              <ExternalImage className={css.image} src={image.url} alt={image.name} />
            )}
          </div>
        )}
      </div>
    </article>
  ) : null;
};

export default Teaser;
