import { action, makeObservable, observable } from 'mobx';

import { ModalState } from '../../../app/shared/ModalState';

export enum FamilyItemAvailability {
  AvailableOnlyForConfiguration,
  Unavailable,
}

export class FamilyItemAvailabilityModalState extends ModalState {
  @observable
  public status: FamilyItemAvailability;

  public constructor() {
    super();
    makeObservable(this);
  }

  @action.bound
  public openModal(status: FamilyItemAvailability) {
    this.status = status;
    this.open();
  }
}
