import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';

import useLoadingStatus from '../hooks/useLoadingStatus';
import { ImagePreloader } from '../ImagePreloader';
import { ImageUrlBuilder } from '../ImageUrlBuilder';
import DefaultImage from './DefaultImage/DefaultImage';
import css from './LoadableImage.css';
import ThreeDotsLoader, { ThreeDotsLoaderSize } from './Loaders/ThreeDotsLoader';

interface LoadableImageProps {
  src: string;
  height: number;
  width: number;
  alt?: string;
  className?: string;
  classNameUnloaded?: string;
  loaderSize?: ThreeDotsLoaderSize;
  onClick?: () => void;
  sharpeningFactor?: number;
}

export const LoadableImage = ({
  alt,
  classNameUnloaded,
  loaderSize,
  src,
  width,
  height,
  sharpeningFactor,
  className,
  onClick,
}: LoadableImageProps) => {
  const preloader = useRef(new ImagePreloader());

  const { isError, isLoading, setError, setInProgress, setSuccess } = useLoadingStatus();

  const finalSrc = useMemo(
    () => ImageUrlBuilder.build(src, width, height, sharpeningFactor),
    [src, width, height, sharpeningFactor],
  );

  useEffect(() => {
    if (!finalSrc) {
      setError();
      return;
    }

    setInProgress();

    preloader.current.load([finalSrc]).then(setSuccess).catch(setError);
  }, [finalSrc]);

  if (isLoading) {
    return (
      <div className={classNames(css.loader, className, classNameUnloaded)}>
        <ThreeDotsLoader className={css.loaderDots} size={loaderSize} />
      </div>
    );
  }

  if (isError) {
    return <DefaultImage className={classNames(className, classNameUnloaded)} />;
  }

  return <img alt={alt} onClick={onClick} className={className} src={finalSrc} />;
};
