import { computed, makeObservable } from 'mobx';

import { PageTranslation } from '../localization/SiteTranslation';
import { StoreState } from '../StoreState';
import { IMemento, IPageState, PageMetadata } from './common';

export abstract class BasePageState<TMemento extends IMemento> implements IPageState {
  public disableCache = false;

  translations: PageTranslation;

  constructor(translation: PageTranslation = { pageDescriptionFormat: null, pageTitleFormat: null }) {
    makeObservable(this);
    this.translations = translation;
  }

  @computed
  public get metadata() {
    return <PageMetadata>{
      title: this.translations.pageTitleFormat,
    };
  }

  async onLoad(store: StoreState): Promise<void> {
    return Promise.resolve();
  }

  onLoadAdditionalData(): void {
    // Do nothing here, this can be overridden in a subclass
  }

  getMemento(): TMemento {
    return <TMemento>{};
  }

  restoreMemento(memento: TMemento): void {
    // Do nothing here, this can be overridden in a subclass
  }

  unload(): void {
    // Do nothing here, this can be overridden in a subclass
  }
}
