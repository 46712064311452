import { isBrowser } from '../common';
import Event from '../Event';
import { INavigationService, PageLoadedEventArgs } from '../NavigationService';
import { RelativeUrl } from '../Url';
import { Monitoring } from './Monitoring';

export class PageViewTrackingProxy implements INavigationService {
  private navigation: INavigationService;

  public pageLoaded = new Event<PageLoadedEventArgs>();

  get referrer() {
    return this.navigation.referrer;
  }

  constructor(navigation: INavigationService) {
    this.navigation = navigation;
    Monitoring.startTrackPage();

    if (isBrowser()) {
      window.addEventListener('beforeunload', () => {
        Monitoring.stopTrackPage();
      });
    }

    this.pageLoaded.subscribe((x) => this.navigation.pageLoaded.raise(x));
  }

  navigateTo(url: RelativeUrl): void {
    Monitoring.stopTrackPage();
    this.navigation.navigateTo(url);
    Monitoring.startTrackPage();
  }

  redirect(url: string): void {
    Monitoring.stopTrackPage();
    this.navigation.redirect(url);
  }

  setQuery(query: Map<string, string>) {
    this.navigation.setQuery(query);
  }

  reload() {
    this.navigation.reload();
  }

  back() {
    this.navigation.back();
  }

  get currentUrl() {
    return this.navigation.currentUrl;
  }

  set currentUrl(url: RelativeUrl) {
    this.navigation.currentUrl = url;
  }
}
