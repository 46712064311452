import { makeObservable, observable } from 'mobx';

import { ConfiguratorPageTranslation, TabsTranslation } from '../../../localization/SiteTranslation';
import { IFeature } from '../../../shared/common';
import { IOptionState } from '../../OptionState';
import { AccessoryState } from '../AccessoriesConfigurator/AccessoryState';
import { TabState } from './TabState';

export interface DynamicTabProps {
  translation: ConfiguratorPageTranslation;
  accessories: Array<AccessoryState>;
  options: Array<IOptionState>;
  features: Array<IFeature>;
  brandId: string;
}

export class DynamicTabState extends TabState {
  @observable accessories: Array<AccessoryState>;
  @observable options: Array<IOptionState>;
  @observable features: Array<IFeature>;

  constructor(
    code: string,
    translation: TabsTranslation,
    // tslint:disable-next-line: no-any
    component: any,
    componentProps: DynamicTabProps,
    index: number,
  ) {
    super(code, translation, component, componentProps);
    makeObservable(this);
    this.index = index;
    this.options = componentProps.options;
    this.accessories = componentProps.accessories;
    this.features = componentProps.features;
  }
}
