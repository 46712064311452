import { useRef } from 'react';

import { useRegionContext } from './RegionContext';
import { RegionDataContext } from './RegionDataContext';

export const Region = <TRegionContext extends RegionDataContext>(props: Extract<RegionDataContext, TRegionContext>) => {
  const { regionManager } = useRegionContext();

  if (!regionManager) {
    return null;
  }

  // Intentionally initialized only once per component lifetime
  const viewsRef = useRef(regionManager.getViews<TRegionContext>(props.name, props.data));

  return (
    <>
      {viewsRef.current?.map((view) => (
        <view.viewType key={view.viewType.name} state={view.state} />
      ))}
    </>
  );
};
