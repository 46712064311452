import Error403Image from '../../images/errors/error403.jpg';
import { ForbiddenErrorPageTranslation } from '../localization/SiteTranslation';
import TextButton from '../shared/components/Button/TextButton';
import ErrorPage from './ErrorPage';
import css from './ForbiddenErrorPage.css';

interface ForbiddenErrorPageProps {
  translation: ForbiddenErrorPageTranslation;
  logout: () => Promise<void>;
  redirectUrl: string;
}

export const ForbiddenErrorPage = ({ translation, logout, redirectUrl }: ForbiddenErrorPageProps) => {
  return (
    <ErrorPage
      className={css.root}
      title={translation.title}
      imageSrc={Error403Image}
      link={{
        label: translation.buttonLink,
        href: redirectUrl,
      }}
    >
      {translation.content1}
      {translation.logout && (
        <TextButton type="button" className={css.logout} isHighlighted onClick={logout}>
          {translation.logout}
        </TextButton>
      )}
      {translation.content2}
    </ErrorPage>
  );
};
