import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { DownloadTranslation } from '../../../../localization/SiteTranslation';
import ActionButton from '../../../../shared/components/ActionButton';
import IconButton from '../../../../shared/components/Button/IconButton';
import Icon from '../../../../shared/components/Icon/Icon';
import Image from '../../../../shared/components/Image/Image';
import css from './Download.css';
import { IDownloadState } from './DownloadState';

interface DownloadProps extends Omit<IDownloadState, 'id'> {
  translation: DownloadTranslation;
}

const Download: React.FC<DownloadProps> = ({ downloadCommand, image, subtitle, title, translation }) => {
  return (
    <div className={classNames(css.Download, { [css.processing]: downloadCommand.processing })}>
      <Image
        className={css.image}
        classNameFallbackElement={css.fallbackImage}
        src={image.url}
        alt={image.name}
        width={172}
        height={248}
        sharpeningFactor={1.4}
      />

      <div className={css.textContent}>
        <div className={css.inner}>
          <h3 className={css.title}>{title}</h3>
          {subtitle && <span>{subtitle}</span>}
          <ActionButton
            command={downloadCommand}
            className={css.button}
            buttonElement={IconButton}
            externalButtonProps={{
              bold: true,
              icon: <Icon className={css.icon} name="downloadLight" />,
            }}
          >
            {translation.downloadButton}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default observer(Download);
