import { AppInsights } from 'applicationinsights-js';
import { Data, Envelope, ExceptionData, SeverityLevel } from 'applicationinsights/out/Declarations/Contracts';

import includeAdditionalProperties from '../includeAdditionalProperties';
import ICustomTelemetryHandler from './ICustomTelemetryHandler';

class TraceReactHydrationErrorAsWarningHandler implements ICustomTelemetryHandler {
  public tryHandle(envelope: Envelope) {
    if (envelope.data.baseType !== 'ExceptionData') {
      return false;
    }

    const { baseData } = envelope.data as Data<ExceptionData>;
    const hydrationErrorPattern = /^Minified React error #(418|423|425)/;
    const error = baseData.exceptions.find((e) => e.typeName === 'Error' && hydrationErrorPattern.test(e.message));

    if (!error) {
      return false;
    }

    AppInsights.trackTrace(error.message, includeAdditionalProperties(baseData.properties), SeverityLevel.Warning);

    return true;
  }
}

export default TraceReactHydrationErrorAsWarningHandler;
