import { autorun } from 'mobx';

import { GroupState } from '../../../../../3dEngine/core/GroupState';
import { LoadableMeshState } from '../../../../../3dEngine/core/LoadableMeshState';
import { ConfiguratorCoreState } from '../../../../../configurator/ConfiguratorCoreState';
import { Visualization3dBuilder } from '../../../../../configurator/Visualization3dBuilder';
import { GetProductQueryResponse, Visualization3dData } from '../../../../../data/model';

class ModularComponentConfiguratorState {
  public get id() {
    return this.data.baseModel.name;
  }

  public get image() {
    return this.data.baseModel.image;
  }

  public get name() {
    return this.data.baseModel.name;
  }

  public constructor(
    public readonly data: GetProductQueryResponse,
    public readonly sceneObjectsGroup: GroupState,
    public readonly coreState: ConfiguratorCoreState,
  ) {
    autorun(() => {
      const components = this.coreState.selectedComponents;
      const selected3dProductComponents = Visualization3dBuilder.buildComponents3d(
        this.data.visualization as Visualization3dData,
        components,
      );

      const inactiveObjects = [...this.sceneObjectsGroup.objects];

      selected3dProductComponents.forEach((component3d) => {
        let sceneObject = this.sceneObjectsGroup.objects.find(
          (x) => x instanceof LoadableMeshState && x.name === component3d.mesh.name,
        ) as LoadableMeshState;

        if (!sceneObject) {
          sceneObject = new LoadableMeshState(component3d.mesh.name, component3d.mesh.url);
          this.sceneObjectsGroup.addObject(sceneObject);
        } else {
          inactiveObjects.remove(sceneObject);
        }

        if (sceneObject && component3d.material?.name && component3d.material?.url) {
          const meshState = sceneObject;
          meshState.setMaterialName(component3d.material.name);
          meshState.setMaterialUrl(component3d.material?.url);
        }
      });

      inactiveObjects.forEach((x) => this.sceneObjectsGroup.removeObject(x));
    });
  }
}

export default ModularComponentConfiguratorState;
