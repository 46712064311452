import { observer } from 'mobx-react';
import { Component } from 'react';

import { BaseModelBasicData } from '../../../data/model';
import Markdown from '../../../shared/components/Markdown/Markdown';
import Heading, { HeadingSize } from '../../../shared/components/Typography/Heading/Heading';
import { SelectorItem } from '../../../shared/Selector';
import css from './ProductModelInfo.css';

interface ProductModelInfoProps {
  model: SelectorItem<BaseModelBasicData>;
}

@observer
class ProductModelInfo extends Component<ProductModelInfoProps, {}> {
  constructor(props: ProductModelInfoProps) {
    super(props);
  }

  render() {
    const {
      model: {
        data: { description, displayName },
      },
    } = this.props;

    return (
      <div className={css.root}>
        <Heading level={1} size={HeadingSize.ExtraLarge} className={css.name}>
          {displayName}
        </Heading>
        {description && <Markdown className={css.description} content={description} />}
      </div>
    );
  }
}

export default ProductModelInfo;
