import classNames from 'classnames';
import { ReactChild } from 'react';

import css from './BodyScroll.css';

interface BodyScrollProps {
  active?: boolean;
  children?: ReactChild | Array<ReactChild>;
  className?: string;
  disable?: boolean;
  isFixed?: boolean;
}

const BodyScroll = (props: BodyScrollProps) => {
  const { active, children, className, disable, isFixed } = props;

  const bodyScrollClasses = classNames(
    css.BodyScroll,
    {
      [css.active]: active && !disable,
      [css.pointerEvents]: active,
      [css.fixed]: isFixed,
    },
    className,
  );

  return <div className={bodyScrollClasses}>{children}</div>;
};

export default BodyScroll;
