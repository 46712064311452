import classNames from 'classnames';
import { observer } from 'mobx-react';

import { FullscreenInterface } from '../../../../../../../../b2c/shared/hooks/useFullscreen';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import css from './SceneActionButtons.css';

interface ModularConfiguratorSceneActionsProps {
  fullscreen: FullscreenInterface;
  is3dMode: boolean;
  onSceneModeChange: () => void;
  className?: string;
}

const SceneActionButtons = observer(
  ({ className, fullscreen, onSceneModeChange, is3dMode }: ModularConfiguratorSceneActionsProps) => {
    const toggleFullscreen = async () => {
      if (fullscreen.isActive) {
        await fullscreen.close();
      } else {
        await fullscreen.open();
      }
    };

    return (
      <div className={classNames(css.container, { [css.fullscreen]: fullscreen.isActive }, className)}>
        <ToolbarButton
          icon={is3dMode ? '2d' : '3d'}
          tooltip={{ id: 'modular-component-scene-mode-action', text: is3dMode ? '2D view' : '3D view' }}
          compact={!fullscreen.isActive}
          onClick={onSceneModeChange}
        >
          {is3dMode ? 'Show floorplan' : 'Show perspective view'}
        </ToolbarButton>
        <ToolbarButton
          labelClassName={css.dimensionsLabel}
          icon="dimensions"
          tooltip={{ id: 'modular-component-dimensions-action', text: 'Dimensions' }}
          compact={!fullscreen.isActive}
          onClick={() => {
            return;
          }}
        >
          Show dimensions
        </ToolbarButton>
        <ToolbarButton
          icon={fullscreen.isActive ? 'cross' : 'fullscreen'}
          tooltip={{ id: 'modular-component-fullscreen-action', text: 'Fullscreen' }}
          compact={!fullscreen.isActive}
          onClick={toggleFullscreen}
        >
          Close
        </ToolbarButton>
      </div>
    );
  },
);

export default SceneActionButtons;
