import { action, makeObservable, observable } from 'mobx';

import { TabData } from '../../data/model';
import { Storyboard } from '../../shared/animations';
import { TabState } from '../components/ConfiguratorTabs/TabState';
import { ConfiguratorStoryboards } from '../ConfiguratorPageStoryboards';
import { AnimatedElementsState } from './AnimatedElementsState';

export class AnimationsManager {
  private activeStoryboard: Storyboard;
  storyboards: ConfiguratorStoryboards;
  animatedElements: AnimatedElementsState;

  @observable isMobilePortrait: boolean;

  constructor(tabs: Array<TabData>) {
    makeObservable(this);
    this.animatedElements = new AnimatedElementsState(tabs);
    this.storyboards = new ConfiguratorStoryboards(this.animatedElements);
  }

  getPreInitStyle(activeTab: TabState, inactiveDynamicsTabs: Array<TabState>) {
    return this.storyboards.preInitStyle(inactiveDynamicsTabs, activeTab);
  }

  runInitAnimation(activeTab: TabState) {
    const animationsLandscapeDelays = {
      tabInitAnimationDelay: 400,
      priceDelay: 600,
      productModelsPanelDelay: 800,
      chairNavigationDelay: 800,
      summaryShowDelay: 1100,
      summaryChairDelay: 750,
    };

    const animationsMobilePortraitDelays = {
      tabInitAnimationDelay: 0,
      priceDelay: 0,
      productModelsPanelDelay: 0,
      chairNavigationDelay: 0,
      summaryShowDelay: 0,
      summaryChairDelay: 0,
    };

    this.activeStoryboard = this.storyboards.initTab(
      activeTab,
      this.isMobilePortrait ? animationsMobilePortraitDelays : animationsLandscapeDelays,
    );
    this.activeStoryboard.run();
  }

  @action.bound
  setMobilePortrait(isMobilePortrait: boolean) {
    this.isMobilePortrait = isMobilePortrait;
  }

  @action
  public runChangeTabAnimation(prevTab: TabState, nextTab: TabState, inactiveDynamicsTabs: Array<TabState>) {
    if (this.activeStoryboard) {
      this.activeStoryboard.cancel();
    }

    const animationsLandscapeDelays = {
      showSummary: {
        moveLeftChairDelay: 50,
        showSummaryDelay: 300,
      },
      hideSummary: {
        showTabContentDelay: 50,
        hideSummaryDelay: 50,
        lockSummaryDelay: 800,
      },
    };

    const animationsMobilePortraitDelays = {
      showSummary: {
        moveLeftChairDelay: 0,
        showSummaryDelay: 0,
      },
      hideSummary: {
        showTabContentDelay: 0,
        hideSummaryDelay: 0,
        lockSummaryDelay: 0,
      },
    };

    const switchTabProps = {
      prevTab,
      nextTab,
      inactiveDynamicsTabs,
      isMobilePortrait: this.isMobilePortrait,
      delays: this.isMobilePortrait ? animationsMobilePortraitDelays : animationsLandscapeDelays,
    };

    this.activeStoryboard = this.storyboards.switchTab(switchTabProps);
    this.activeStoryboard.run();
  }
}
