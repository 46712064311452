import { makeObservable, observable } from 'mobx';

import { MenuItem } from '../data/model';

export class MenuItemState {
  public data: MenuItem;
  public level: number;
  public index: number;

  @observable
  isOpen: boolean;

  @observable
  isActive: boolean;

  public children: Array<MenuItemState> = [];

  public get key() {
    return this.level * 100 + this.index;
  }

  public get hasGroupingChildren() {
    return this.children.any((x) => x.isGroupingItem);
  }

  public get isGroupingItem() {
    return this.data.children.any();
  }

  constructor(item: MenuItem, level: number = 0, index: number = 0) {
    makeObservable(this);
    this.data = item;
    this.level = level;
    this.index = index;
    this.isOpen = false;
    this.isActive = false;
    this.children = this.getChildren(item, level);
  }

  private getChildren(item: MenuItem, level: number = 0, index: number = 0): MenuItemState[] {
    let children: MenuItemState[] = [];
    let childIndex = index;

    if (item.children.empty()) {
      return children;
    }

    item.children.forEach((child) => {
      if (child.name) {
        children.push(new MenuItemState(child, level + 1, childIndex));
        childIndex++;
      } else {
        const nestedChildren = this.getChildren(child, level, childIndex);

        children = [...children, ...nestedChildren];
        childIndex += nestedChildren.length;
      }
    });

    return children;
  }
}
