import classNames from 'classnames';

import { PropertyData } from '../../../data/model';
import capitalizeFirstLetter from '../../../shared/capitalizeFirstLetter';
import List from '../../../shared/components/List/List';
import ProductProperty from '../ProductProperty/ProductProperty';
import css from './ProductPropertiesList.css';

interface ProductPropertiesListProps {
  className?: string;
  itemClassName?: string;
  itemLabelClassName?: string;
  itemValueClassName?: string;
  items: Array<PropertyData>;
}

const ProductPropertiesList = (props: ProductPropertiesListProps) => {
  const { className, itemClassName, itemLabelClassName, itemValueClassName, items } = props;

  const renderLabeledItem = ({ name, displayName, value, valueId }: PropertyData) => {
    return (
      <ProductProperty
        key={name}
        name={name}
        displayName={capitalizeFirstLetter(displayName)}
        value={value}
        valueId={valueId}
        itemLabelClassName={itemLabelClassName}
        itemValueClassName={itemValueClassName}
      />
    );
  };

  return (
    <List
      items={items}
      className={className}
      itemClassName={classNames(css.item, itemClassName)}
      itemRenderer={renderLabeledItem}
    />
  );
};

export default ProductPropertiesList;
