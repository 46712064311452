import { Scene } from '@babylonjs/core';
import { action, IArraySplice, IObservableArray, Lambda, makeObservable, observable, observe } from 'mobx';

import { GroupState } from '../../core/GroupState';
import { ObjectState } from '../../core/ObjectState';
import { AbstractVisual } from './AbstractVisual';
import { GroupVisual } from './GroupVisual';
import { IVisualFactory } from './VisualFactory';

export interface VisualListItem {
  visual: AbstractVisual;
  state: ObjectState;
  rootState: ObjectState;
}

export class VisualList {
  @observable.shallow
  items: IObservableArray<VisualListItem> = [] as IObservableArray<VisualListItem>;

  private disposers: Array<Lambda> = [];

  constructor(
    private readonly factories: IVisualFactory<ObjectState>[],
    private readonly scene: Scene,
    objects: IObservableArray<ObjectState>,
  ) {
    makeObservable(this);
    this.observeArray(objects);
  }

  private observeArray(
    objects: IObservableArray<ObjectState>,
    rootState: GroupState = null,
    parentVisual: GroupVisual = null,
  ) {
    objects.forEach((x) => this.addItem(x, rootState, parentVisual));

    const disposer = observe(objects, (change) => {
      const arrayChange = change as IArraySplice<ObjectState>;

      arrayChange.added?.forEach(async (x) => {
        this.addItem(x, rootState, parentVisual);
      });

      arrayChange.removed?.forEach((x) => {
        this.removeItem(x);
      });
    });

    this.disposers.push(disposer);
  }

  @action
  private async addItem(state: ObjectState, rootState: GroupState = null, parentVisual: GroupVisual = null) {
    const factory = this.factories.find((f) => f.canCreate(state));
    const visual = await factory.createAsync(this.scene, state);

    const item = { visual, state, rootState };
    this.items.push(item);

    if (parentVisual) {
      parentVisual.onChildAdded(visual);
    }

    if (state instanceof GroupState) {
      const groupState = state as GroupState;
      this.observeArray(groupState.objects, rootState ?? groupState, visual as GroupVisual);
    }

    return item;
  }

  @action
  private removeItem(state: ObjectState) {
    const itemsToRemove = this.items.filter((x) => x.state === state);
    itemsToRemove.forEach((x) => this.items.remove(x));

    if (state instanceof GroupState) {
      const groupState = state as GroupState;
      groupState.objects.forEach((x) => this.removeItem(x));
    }
  }

  public dispose() {
    this.items.clear();
    this.disposers.forEach((x) => x());
    this.disposers = [];
  }
}
