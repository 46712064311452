import classnames from 'classnames';
import { observer } from 'mobx-react';

import { ShippingRateData } from '../../data/model';
import { ErrorMessages } from '../../shared/components/Input/ErrorMessages/ErrorMessages';
import InputRadio from '../../shared/components/InputRadio/InputRadio';
import Price from '../../shared/components/Price';
import { Input } from '../../shared/Form';
import css from './ShippingSelector.css';

const buildRateId = (rate: ShippingRateData) => {
  if (!rate?.method) {
    return null;
  }

  return `${rate.method.code}--${rate.name}`;
};

interface ShippingSelectorProps {
  rates: ShippingRateData[];
  rateInput: Input<ShippingRateData>;
  onRateChange: (rate: ShippingRateData) => void;
}

export const ShippingSelector = observer(({ rates, rateInput }: ShippingSelectorProps) => {
  return (
    <div>
      <div>
        {rates.map((rate) => (
          <ShippingRate
            key={`${rate.method.code}-${rate.name}`}
            onChange={rateInput.onChange}
            rate={rate}
            checked={buildRateId(rate) === buildRateId(rateInput.value)}
            className={css.shippingRateItem}
          />
        ))}
      </div>
      <ErrorMessages errors={rateInput.errorMessages} />
    </div>
  );
});

interface ShippingRateProps {
  rate: ShippingRateData;
  checked?: boolean;
  onChange: (rate: ShippingRateData) => void;
  className?: string;
}

const ShippingRate = ({ rate, checked: isChecked = false, onChange, className }: ShippingRateProps) => {
  const rateId = buildRateId(rate);

  return (
    <InputRadio
      className={classnames([css.shippingRateInputRadio, isChecked && css.selected, className])}
      id={rateId}
      name={rateId}
      value={rateId}
      checked={isChecked}
      onChange={() => onChange(rate)}
    >
      <div>
        <div className={css.shippingRateDetails}>
          <span>{rate.name}</span>
          <Price price={rate.rateWithTax} />
        </div>
        {rate.description && <div className={css.shippingRateDescription}>{rate.description}</div>}
      </div>
    </InputRadio>
  );
};
