import { Mesh, Scene } from '@babylonjs/core';

import { CubeState } from '../../core/CubeState';
import { ObjectState } from '../../core/ObjectState';
import { AbstractVisual } from './AbstractVisual';
import { SimpleVisual } from './SimpleVisual';
import { IVisualFactory } from './VisualFactory';

export class CubeVisualFactory implements IVisualFactory<CubeState> {
  canCreate(object: ObjectState): boolean {
    return object instanceof CubeState;
  }

  createAsync(scene: Scene, object: CubeState): Promise<AbstractVisual> {
    const box = Mesh.CreateBox(object.name, object.size, scene, true);
    const visual = new SimpleVisual(object, box);

    return Promise.resolve(visual);
  }
}
