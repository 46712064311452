import { CatalogPageState as CatalogPageStateBase } from '../../app/catalog/CatalogPageState';
import { IApiClient } from '../../app/data/client';
import { CatalogPageTranslation } from '../../app/localization/SiteTranslation';
import { INavigationService } from '../../app/shared/NavigationService';
import { FamilyItemAvailabilityModalState } from './FamilyItemAvailabilityModal/FamilyItemAvailabilityModalState';

export class CatalogPageState extends CatalogPageStateBase {
  public familyItemAvailabilityModal: FamilyItemAvailabilityModalState;

  public constructor(translation: CatalogPageTranslation, navigation: INavigationService, client: IApiClient) {
    super(translation, navigation, client, 10);
    this.familyItemAvailabilityModal = new FamilyItemAvailabilityModalState();
  }
}
