import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import { TextInput } from '../../Form';
import Label from '../Label/Label';
import { ErrorMessages } from './ErrorMessages/ErrorMessages';
import css from './InlineInputs.css';

interface InlineInputsProps {
  label?: string;
  hideLabel?: boolean;
  inputs: Array<TextInput>;
  children: Array<ReactNode>;
  className?: string;
  inlineInputsContainerClassName?: string;
}

const InlineInputs = (props: InlineInputsProps) => {
  const getErrors = () => {
    return props.inputs.mapMany((input) => input.errorMessages);
  };

  return (
    props.inputs.any() && (
      <div className={classNames(css.InlineInputs, props.className)}>
        {props.label && <Label hideLabel={props.hideLabel}>{props.label}</Label>}
        <div className={classNames(css.inputsContainer, props.inlineInputsContainerClassName)}>{props.children}</div>
        <ErrorMessages errors={getErrors()} />
      </div>
    )
  );
};

export default observer(InlineInputs);
