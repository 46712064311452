import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import { AsyncCommand } from '../../../common';
import ActionButton from '../../ActionButton';
import { ButtonProps } from '../../Button/Button';
import SecondaryButton from '../../Button/SecondaryButton';
import TertiaryButton from '../../Button/TertiaryButton';
import HeaderLevel3 from '../../Typography/HeaderLevel3';
import css from './CustomModalDefaultElements.css';

interface ModalDefaultTileProps {
  title: ReactNode | Array<ReactNode>;
  className?: string;
}

export const ModalDefaultTile = observer(
  ({ defaultTitle: { title, className } }: { defaultTitle: ModalDefaultTileProps }) => (
    <HeaderLevel3 className={classNames(css.ModalDefaultTitle, className)}>
      <>{title}</>
    </HeaderLevel3>
  ),
);

interface ModalDefaultContentProps {
  content: ReactNode | Array<ReactNode>;
  className?: string;
}

export const ModalDefaultContent = observer(
  ({ defaultContent: { content, className } }: { defaultContent: ModalDefaultContentProps }) => (
    <div className={classNames(css.ModalDefaultContent, className)}>
      <>{content}</>
    </div>
  ),
);

export interface ModalDefaultApplyButtonProps extends ButtonProps {
  disabled?: boolean;
  command?: AsyncCommand;
  onClickApply?: () => void;
}

export interface ModalDefaultCancelButtonProps extends ButtonProps {
  disabled?: boolean;
  command?: AsyncCommand;
  onClickCancel?: () => void;
}

export interface ModalDefaultActionButtonsProps {
  applyButton: ModalDefaultApplyButtonProps;
  cancelButton?: ModalDefaultCancelButtonProps;

  onClose: () => void;
}

export const ModalDefaultActionButtons = observer(
  ({ applyButton, cancelButton, onClose }: ModalDefaultActionButtonsProps) => {
    const handleOnApply = () => {
      if (applyButton.onClickApply) {
        applyButton.onClickApply();
      }
    };
    const handleOnCancel = () => {
      if (cancelButton?.onClickCancel) {
        cancelButton.onClickCancel();
      }
      onClose();
    };

    return (
      <div className={css.ModalDefaultActionButtons}>
        {cancelButton?.command ? (
          <ModalDefaultCancelActionButton cancelButton={cancelButton} />
        ) : (
          <ModalDefaultCancelButton onClick={handleOnCancel} {...cancelButton} />
        )}
        {applyButton.command ? (
          <ModalDefaultApplyActionButton applyButton={applyButton} />
        ) : (
          <ModalDefaultApplyButton onClick={handleOnApply} {...applyButton} />
        )}
      </div>
    );
  },
);

const ModalDefaultCancelActionButton = observer(({ cancelButton }: { cancelButton: ModalDefaultCancelButtonProps }) => (
  <ActionButton
    command={cancelButton.command}
    buttonElement={ModalDefaultCancelButton}
    externalButtonProps={{ ...cancelButton }}
  />
));

const ModalDefaultApplyActionButton = observer(({ applyButton }: { applyButton: ModalDefaultApplyButtonProps }) => (
  <ActionButton
    type="submit"
    command={applyButton.command}
    buttonElement={ModalDefaultApplyButton}
    externalButtonProps={{ ...applyButton }}
  />
));

const ModalDefaultApplyButton = observer((applyButton: ModalDefaultApplyButtonProps) => (
  <SecondaryButton type="submit" className={css.applyButton} {...applyButton}>
    {applyButton.name ? applyButton.name : 'Apply'}
  </SecondaryButton>
));

const ModalDefaultCancelButton = observer((cancelButton: ModalDefaultCancelButtonProps) => {
  return (
    <TertiaryButton type="button" className={css.cancelButton} {...cancelButton}>
      {cancelButton.name ? cancelButton.name : 'Cancel'}
    </TertiaryButton>
  );
});
