import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../../app/AppState';
import SecondaryButton from '../../app/shared/components/Button/SecondaryButton';
import LogoFlokkHub from '../menu/LogoFlokkHub/LogoFlokkHub';
import css from './LoginPage.css';
import { LoginState } from './LoginState';

@observer
class LoginPage extends Component<{ appState: AppState; state: LoginState }, {}> {
  render() {
    const { appState, state } = this.props;

    if (appState.loadingPageIndicator.isLoading || state.disableContent) {
      return null;
    }

    return (
      <div className={css.LoginPage}>
        <LogoFlokkHub className={css.logo} size="large" />
        <p className={css.welcomeText}>{state.translation.welcomeText}</p>

        <div className={css.imageContainer}>
          <div className={css.image} />
        </div>

        <SecondaryButton onClick={state.login}>{state.translation.getStartedButton}</SecondaryButton>
      </div>
    );
  }
}

export default LoginPage;
