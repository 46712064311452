import List from '../../../../../app/shared/components/List/List';
import HeaderLevel2 from '../../../../../app/shared/components/Typography/HeaderLevel2';
import css from './InfoListBanner.css';
import InfoListBannerItem, { InfoListBannerItemProps } from './InfoListBannerItem/InfoListBannerItem';

interface InfoListBannerProps {
  items: InfoListBannerItemProps[];
  title?: string;
}

const InfoListBanner = ({ items, title }: InfoListBannerProps) => {
  const renderItem = (item: InfoListBannerItemProps) => {
    return <InfoListBannerItem description={item.description} icon={item.icon} title={item.title} />;
  };

  return items?.any() ? (
    <section className={css.container}>
      <div className={css.inner}>
        {title && <HeaderLevel2 className="visuallyHidden">{title}</HeaderLevel2>}
        <List items={items} itemRenderer={renderItem} className={css.items} itemClassName={css.item} />
      </div>
    </section>
  ) : null;
};

export default InfoListBanner;
