import classNames from 'classnames';
import { observer } from 'mobx-react';

import { FullscreenInterface } from '../../../../../../../b2c/shared/hooks/useFullscreen';
import ModularConfiguratorCoordinatorState from '../../../ModularConfiguratorCoordinatorState';
import ModularComponentActionButtons from './ModularComponentActionButtons/ModularComponentActionButtons';
import css from './ModularConfiguratorToolbar.css';
import SceneActionButtons from './SceneActionButtons/SceneActionButtons';

interface ModularConfiguratorPreviewToolbarProps {
  fullscreen: FullscreenInterface;
  state: ModularConfiguratorCoordinatorState;
  className?: string;
}

const ModularConfiguratorToolbar = observer(
  ({ className, fullscreen, state }: ModularConfiguratorPreviewToolbarProps) => {
    const shouldDisplayComponentActions = state.configurator.selectedComponent && !fullscreen.isActive;

    return (
      <div role="toolbar" className={classNames(css.container, className)}>
        {shouldDisplayComponentActions && (
          <ModularComponentActionButtons className={css.componentToolbar} state={state} />
        )}
        <SceneActionButtons
          className={css.sceneToolbar}
          fullscreen={fullscreen}
          is3dMode={state.configurator.scene.is3d}
          onSceneModeChange={state.configurator.scene.toggleMode}
        />
      </div>
    );
  },
);

export default ModularConfiguratorToolbar;
