import {
  CartItemActionsTranslation,
  CartItemDetailsTranslation,
  CartSummaryTranslation,
  ShoppingCartItemBasicInfoTranslation,
  ShoppingCartItemPanelTranslation,
  SiteTranslation,
} from './SiteTranslation';
import { ActionMenuTranslation } from '../../app/localization/SiteTranslation';
import baseTranslation from '../../app/localization/en-gb';

const actionMenuTranslation: ActionMenuTranslation = {
  title: 'Actions',
};

const cartItemActionsTranslation: CartItemActionsTranslation = {
  actionMenu: actionMenuTranslation,
};

const shoppingCartItemBasicInfoTranslation: ShoppingCartItemBasicInfoTranslation = {
  cartItemDetails: {
    configCodeLabel: 'Config. code',
    noItemsLabel: 'No. items',
    listPriceLabel: 'List price',
    netPriceLabel: 'Your net price',
  },
};

const shoppingCartItemPanelTranslation: ShoppingCartItemPanelTranslation = {
  cartItemActions: cartItemActionsTranslation,
};

const cartSummaryTranslation: CartSummaryTranslation = {
  priceBeforeVatLabel: 'Price before VAT',
  vatLabelFormat: 'Vat ({taxRate}%)',
  priceIncludingVatLabel: 'Price including VAT',
  deliveryFeeLabel: 'Delivery Fee',
  noItemsLabel: 'No. items',
  subtotalHeader: 'Subtotal',
  accordingToAgreementText: 'According to agreement',
  surchargesAnnotationText: 'Surcharges may apply to the order. Details will be specified in the invoice.',
};

const cartItemDetailsTranslation: CartItemDetailsTranslation = {
  configCodeLabel: 'Config. code',
  noItemsLabel: 'No. items',
  listPriceLabel: 'List price',
  netPriceLabel: 'Your net price',
};

const translation: SiteTranslation = {
  ...baseTranslation,
  ...{
    b2bHomePage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      onboarding: {
        headerText: 'Welcome',
        welcomeText: "We're excited to have you on board. Below you will find tips to help you get started.",
        onboardingCards: {
          configureChairTitle: 'Configure a chair',
          configureChairText: 'Configure a chair',
          placeAnOrderTitle: 'Place an order',
          placeAnOrderText: 'Place an order',
          manageAndTrackTitle: 'Manage and track',
          manageAndTrackText: 'Manage and track',
          selectAChairTextPart1: 'Select a chair in ',
          selectAChairTextPart2: 'Products',
          selectAChairTextPart3: ' and adjust it to your customer needs in configurator.',
          addConfigToFavoritesTextPart1: 'You can add your configuration to ',
          addConfigToFavoritesTextPart2: 'Favourites',
          placeChairToCartTextPart1: 'Once your chair is ready to order place it to your ',
          placeChairToCartTextPart2: 'Cart',
          manageOngoingOrdersTextPart1: 'You can easily manage ongoing orders in ',
          manageOngoingOrdersTextPart2: 'Order history',
          manageOngoingOrdersTextPart3: ', as well as view past orders.',
          receiveNotificationsFromTrackingText:
            'Through the tracking system you will receive a notification every time the order status is changed.',
        },
      },
    },
    profilePage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      personalTabTitle: 'Personal',
      usersTabTitle: 'Users',
      roles: {
        admin: 'Administrator',
        buyer: 'Buyer',
        flokkSales: 'Flokk Sales',
        flokkSupport: 'Flokk Support',
        reader: 'Reader',
      },
      personalTabContent: {
        emailLabel: 'Email',
        emailNotificationsHeader: 'Email notifications',
        emailNotifications: {
          orderCreated: 'Order created',
          orderStatusChanged: 'Order status changed',
          orderCancelled: 'Order cancelled',
          orderNotifyOnlyMyOrders: 'My orders only',
        },
        firstNameLabel: 'First name',
        lastNameLabel: 'Last name',
        phoneNumberLabel: 'Phone number',
        saveChangesButton: 'Save changes',
        basicInformationHeader: 'Basic information',
        languageSelectorLabel: 'Language',
        languages: {
          deDe: 'German',
          enGb: 'English',
          frFr: 'French',
        },
        passwordResetBlock: {
          passwordResetHeader: 'Password Reset',
          emailSentText: 'We have sent a password reset email. Please check your mailbox.',
          sendPasswordResetEmailAgainText: 'Send password reset email again',
          sendPasswordResetEmailText: 'Send password reset email',
          confirmPasswordResetHeader: 'Confirm password reset',
          areYouSureResetPasswordText: 'Are you sure you want to reset your password?',
          cancelButton: 'Cancel',
          yesButton: 'Yes',
        },
      },
      usersTab: {
        removeUserTitle: 'Remove user',
        inviteNewUserButton: 'Invite new user',
        editRolesLabel: 'Edit roles',
        removeLabel: 'Remove',
        removeContactFromOrganisationTextPart1: 'Are you sure you want to remove ',
        removeContactFromOrganisationTextPart2: ' from your organisation?',
        invitationsTable: {
          pendingInvitationsHeader: 'Pending invitations',
          userNameHeader: 'User name',
          emailHeader: 'Email',
          expiresAtHeader: 'Expires at',
          contextMenuHeader: '',
          removeLabel: 'Remove',
          actionMenu: actionMenuTranslation,
        },
        usersTable: {
          rolesHeader: 'Roles',
          userNameHeader: 'User name',
          contextMenuHeader: '',
        },
        inviteNewUsersModal: {
          inviteNewUserHeader: 'Invite new user',
          firstNameLabel: 'First name',
          lastNameLabel: 'Last Name',
          emailLabel: 'Email',
          repeatEmailLabel: 'Repeat Email',
          grantedRolesLabel: 'Granted roles',
          sendInvitationButton: 'Send invitation',
        },
        confirmInvitationRemovalModal: {
          areYouSureRemoveInvitationText: 'Are you sure you want to remove an invitation for ',
          title: 'Remove invitation',
        },
        changeUserRolesModal: {
          selectRolesText: 'Please select roles for',
          title: 'Edit roles',
        },
        actionMenu: actionMenuTranslation,
      },
    },
    invitationPage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      headerText: 'You have been invited to Flokk HUB!',
      loggedAsText: 'You are currently logged in to Flokk Hub as',
      useCurrentAccountOrCreateNewText:
        'Do you want to use the current account or create a new one to fulfill the invitation process?',
      useCurrentAccountButton: 'Use the current account',
      createNewAccountButton: 'Create a new account',
      fullfillInvitationText:
        'To fulfill the process of invitation on the next screen you will need to log in using existing account or sign up to create a new account.',
      signUpForFlokkHub: 'Sign up for Flokk Hub',
      haveAccountInformationText: 'If you have an account in Flokk Hub already you might want to use it',
      logInWithExistingAccountText: 'Log in with existing account',
      expiredInvitationInformationText:
        'Your invitation has expired. Ask Flokk Team members to send you a new invitation',
      invitationLinkNotValidText: 'Your invitation link is not valid',
      invitationLinkNotValidInformationText:
        'The invitation you are trying to use does not exists. Check if the link used is the one send by the Flokk.',
      ifProblemStillOccursContactFlokkText: 'If a problem still occurs contact Flokk Team to get a new invitation.',
      somethingWrongWithInvitationText: 'Something with your invitation went wrong',
      notAbleToProcessRequestText:
        'We are not able to process your request. Please try again later or contact Flokk Team.',
    },
    catalogPage: {
      ...baseTranslation.catalogPage,
      familyItemAvailabilityModal: {
        closeButton: 'Close',
        familyItemIsAvailableOnlyForConfiguration:
          'This product is currently not available for purchase through Flokk Hub. You can still configure and add it to your favourites.',
        familyItemIsUnavailable: 'This product is not yet available to configure or for purchase through Flokk Hub.',
      },
      filterBar: {
        ...baseTranslation.catalogPage.filterBar,
        results: "Product families: {results}"
      }
    },
    cartPage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      orderSummaryText: 'Order summary',
      goBackButton: 'Go back',
      deliveryAddressText: 'Delivery address',
      deliveryInstructionsLabel: 'Delivery instructions, visible on parcel label',
      deliveryInstructionsPlaceholder: 'E.g. second floor',
      placeOrderButton: 'Place order',
      cartList: {
        sendByEmailButton: 'Send by email',
        placeOrderButton: 'Place order',
        editProductLabel: 'Edit product',
        removeFromCardLabel: 'Remove from cart',
        cartOverviewTitle: 'Cart overview',
        cartSummaryTitle: 'Cart summary',
        downloadImagesLabel: 'Download images',
        noneOrdersSelectedLabel: 'None orders selected',
        continueWithOrdersLabelFormat: 'Continue with {ordersCount} orders',
        placeAllOrdersLabelFormat: 'Place all {ordersCount} orders',
        cartSummary: cartSummaryTranslation,
        cartHeader: {
          readyForShippingText: 'Ready for shipping within {minDays} - {maxDays} working days',
        },
        cartItemList: {
          cartItem: {
            cartItemActions: cartItemActionsTranslation,
            cartItemBodyDetails: {
              removeItemButton: 'Remove item',
              updateQuantityButton: 'Update quantity',
              revertButton: 'Revert',
              cartItemDetails: cartItemDetailsTranslation,
            },
          },
        },
      },
      orderSummary: {
        brandLabel: 'Brand',
        noItemsLabel: 'No. items',
        readyForShippingFromFactoryLabel: 'Ready for shipping from factory',
        readyForShippingFromFactoryValue: '{minDays} - {maxDays} working days',
        customReferenceLabel: 'Your reference',
        customReferencePlaceholder: 'E.g. your order number',
        customNameLabel: 'Order name',
        customNamePlaceholder: 'E.g. customer name',
        commentLabel: 'Comment',
        commentPlaceholder: '',
      },
      deliveryAddress: {
        addButton: 'Enter new address',
        editButton: 'Edit',
        deliveryAddressForm: {
          city: {
            name: 'City',
            fieldRequired: 'City is required',
          },
          country: {
            name: 'Country',
          },
          zipCode: {
            name: 'Zip Code',
            fieldRequired: 'Zip Code is required',
            wrongFormat: 'Entered value is not a valid zip code',
          },
          addressLine1: {
            name: 'Address Line 1',
            fieldRequired: 'Address Line 1 is required',
          },
          addressLine2: {
            name: 'Address Line 2',
          },
          recipient: {
            name: 'Recipient',
            fieldRequired: 'Recipient is required',
          }
        },
      },
      cartSummary: cartSummaryTranslation,
      orderCreationConfirmationModal: {
        title: 'Are you sure you want to proceed?',
        confirmationText: 'You are placing an order on behalf of',
        confirmButton: 'Place order',
        cancelButton: 'Cancel',
      },
      orderCreatedModal: {
        somethingWentWrongTitle: 'Something went wrong',
        notAllOrdersCreatedSuccessfullyTitle:
          'Something went wrong. Only {succedeedOrdersCount} out of ${totalOrdersCount} were created successfully',
        technicalIssuesSubtitle: "Due to technical issues we can't create order with the following configurations:",
        orderCreatinFailedSubtitle: 'Order creation failed. Please try again or contact Flokk Hub support team.',
        notAllOrdersCreatedSuccessfullySubtitle:
          'Successfully created orders: {succedeedOrders}.\nFailed to process: {failedOrders}.\nPlease try again or contact Flokk Hub support team.',
        OrderCreatedSuccessNotification: {
          ongoingOrdersLabel: 'See ongoing orders',
          orderDetailsLabel: 'See order details',
          orderReceivedTitle: 'Thanks! We have received your order.',
          orderReceivedSubtitle:
            'An order confirmation will be sent to your email when the order is sent to production.',
        },
      },
      sendCartToEmail: {
        headerText: 'Send selected items to email',
        sendButton: 'Send',
        emailLabel: 'Email',
      },
    },
    configurationPage: {
      ...baseTranslation.configurationPage,
      priceTaxText: '(EXCL. VAT)',
    },
    configuratorActions: {
      addToCartButton: 'Add to cart',
      favoriteButton: {
        addToFavoritesText: 'Add to favourites',
        addingToFavoritesText: 'Adding to favourites...',
        addedToFavoritesText: 'Added to favourites',
        addingToFavoritesFailed: 'Could not add the product to favourites. Make sure it is available in your country.',
      },
      updateCartItemButton: 'Update Cart Item',
      addedToCartModal: {
        updatedInCartHeader: 'Updated in cart',
        addedToCartHeader: 'Added to cart',
        goToCartButton: 'Go to cart',

        quantityInfo: {
          productAddedText: '1 product added',
          productsAddedText: '{quantityAdded} products added',
        },
        addedToCartItem: {
          cartItemPreview: {
            cartItemDetails: cartItemDetailsTranslation,
          },
          cartItemSubtotal: {
            exclValText: '(excl. VAT)',
            subtotalText: 'Subtotal',
          },
        },
      },
    },
  },
  favoritesPage: {
    title: 'My favourites',
    downloadImagesLabel: 'Download images',
    editNameLabel: 'Edit a name',
    removeLabel: 'Remove',
    favoriteItem: {
      addToCartButton: 'Add to cart',
      showConfigurationButton: 'Show configuration',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    editConfigurationNameModal: {
      header: 'Edit a name',
      description: 'Tag your configuration with customer name, date or other characteristic',
      configurationNameLabel: 'Configuration Name',
      saveButton: 'Save',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    favoriteItemDetailsModal: {
      configurationText: 'Configuration',
      accessoriesText: 'Accessories',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    removalConfirmationModal: {
      header: 'Remove from favourites',
      description: 'Are you sure you want to remove this product from favourites?',
      confirmButton: 'Yes, remove',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    emptyFavoritesList: {
      titleText: 'Your favourites list is empty',
      subtitleText: "but it doesn't have to be",
      labelText: 'Browse products',
    },
  },
  loginPage: {
    welcomeText: 'Welcome to Flokk Hub!',
    getStartedButton: 'Get started',
  },
  orderPage: {
    downloadImagesLabel: 'Download images',
    shippingWithinTextFormat: 'Shipping within {minDays} - {maxDays} working days',
    shippingSoonText: 'Shipping soon',
    weHaveDelayText: 'We have some delay',
    changeInOrderAlertText: 'There has been a change in your order, the information below might be incorrect.',
    orderActionButtons: {
      yourReferenceLabel: 'Your reference',
      orderNameLabel: 'Order name',
      setOrderNameText: 'Set order name',
      addEntireOrderToCartButton: 'Add entire order to cart',
    },
    trackingInformation: {
      trackOrderButton: 'Track order',
    },
    shippingDate: {
      shippingDateText: 'Shipping date',
      shippingTimeText: 'Shipping time',
      withinDaysTextFormat: 'within {minDays} - {maxDays} working days',
      soonText: 'Soon',
      delayText: 'We have some delay',
    },
    orderDetailsPanel: {
      deliveryAddressLabel: 'Delivery address',
      totalPriceBeforeVatLabel: 'Total price before VAT',
      totalPriceIncludingVatLabel: 'Total price including VAT',
      buyerLabel: 'Buyer',
      summaryLabel: 'Summary',
      summary: cartSummaryTranslation,
    },
    orderDetailsProduct: {
      reorderButton: 'Reorder',
      productsHeader: 'Products',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    orderShipmentsModal: {
      closeButton: 'Close',
      header: 'This delivery has several shipments',
      orderShipmentPanelHeader: {
        deliveryNumberLabel: 'Delivery nr. ',
        shippingDateLabel: 'Shipping date: ',
        trackOrderButton: 'Track order',
      },
      orderShipmentsList: {
        configurationCodeLabel: 'Config. code',
      },
    },
    editOrderModal: {
      orderTextFormat: 'Order {orderNumber}',
      saveButton: 'Save',
      nameLabel: 'Name',
    },
    reorderModal: {
      areYouSureText: 'Are you sure you want to add order content to cart?',
      confirmButton: 'Confirm',
      orderTextFormat: 'Order {orderNumber}',
    },
  },
  ordersPage: {
    ongoingOrdersTitle: 'Ongoing orders',
    pastOrdersTitle: 'Past orders',
    ordersListFilter: {
      searchLabel: 'Search for',
      searchPlaceholder: 'Order number or name',
      onlyMineLabel: 'Only mine',
      searchButton: 'Search',
      resetButton: 'Reset',
      sortByLabel: 'Sort by',
      sortByOrderNumberAscLabel: 'Order number (asc)',
      sortByOrderNumberDescLabel: 'Order number (desc)',
      sortByOrderDateAscLabel: 'Order date (asc)',
      sortByOrderDateDescLabel: 'Order date (desc)',
      sortByOrderTotalAscLabel: 'Order total (asc)',
      sortByOrderTotalDescLabel: 'Order total (desc)',
    },
    tabContent: {
      noOrdersMatchingCriteriaText: 'There are no orders matching your criteria.',
      pastOrdersTable: {
        orderNumberHeader: 'Order nr.',
        orderNameHeader: 'Order name',
        orderDateHeader: 'Order date',
        shippingDateHeader: 'Shipping date',
        productsHeader: 'Products',
        totalPriceHeader: 'Total price',
        buyerHeader: 'Buyer',
        cancelledText: 'Cancelled',
      },
      order: {
        changesInOrderAlertText: 'There has been a change in your order, the information below might be incorrect.',
        shippingDateFormat: 'Shipping date {date}',
        orderHeader: {
          orderNoLabel: 'Order No.',
          orderDateLabel: 'Order date',
          totalPriceLabel: 'Total price',
          buyerLabel: 'Buyer',
          orderNameLabel: 'Order Name',
          yourReferenceLabel: 'Your Reference',
        },
        orderItem: {
          cartItemDetails: cartItemDetailsTranslation,
        },
        shippingMessage: {
          shippingSoonText: 'Shipping soon',
          shippingWithinDaysFormat: 'Shipping within {minDays} - {maxDays} working days',
          weHaveDelayText: 'We have some delay',
        },
      },
    },
  },
  sideMenu: {
    productsLabel: 'Products',
    quickOrderLabel: 'Quick order',
    orderHistoryLabel: 'Order history',
    favoritesLabel: 'My favourites',
    signOutLabel: 'Sign out',
  },
  topMenu: {
    cartButton: 'Cart',
    userInfo: {
      storeSelector: {
        storeChangeFailedWarning: {
          message: 'Sorry, we are not able to change your current organization to the requested one. Please, try again or contact customer support.'
        }
      }
    }
  },
  appLoader: {
    loaderText: 'Initializing ...',
  },
};

export default translation;
