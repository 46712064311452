import { IHttpClient } from '../../data/httpClient';
import { DependencyTelemetryCollector } from './DependencyTelemetryCollector';
import { Monitoring } from './Monitoring';

export class DependencyTrackingProxy implements IHttpClient {
  private client: IHttpClient;

  constructor(client: IHttpClient) {
    this.client = client;
  }

  async get(url: string, ...otherArgs: []): Promise<Response> {
    const collector = new DependencyTelemetryCollector(url, 'GET').start();
    const response = await this.client.get(url, ...otherArgs);
    const dependency = collector.stop(response.status).getTelemetry();

    Monitoring.trackDependency(dependency);
    return response;
  }

  async head(url: string, ...otherArgs: []): Promise<Response> {
    const collector = new DependencyTelemetryCollector(url, 'HEAD').start();
    const response = await this.client.head(url, ...otherArgs);
    const dependency = collector.stop(response.status).getTelemetry();

    Monitoring.trackDependency(dependency);
    return response;
  }

  async post(url: string, body: string, ...otherArgs: []): Promise<Response> {
    const collector = new DependencyTelemetryCollector(url, 'POST').start();
    const response = await this.client.post(url, body, ...otherArgs);
    const dependency = collector.stop(response.status).getTelemetry();

    Monitoring.trackDependency(dependency);
    return response;
  }
}
