import classNames from 'classnames';
import { observer } from 'mobx-react';

import { PropertyData } from '../../../data/model';
import css from './ProductProperty.css';

interface ProductPropertyProps extends PropertyData {
  className?: string;
  itemLabelClassName?: string;
  itemValueClassName?: string;
}

const ProductProperty = observer((props: ProductPropertyProps) => {
  const { className, displayName, value, itemValueClassName, itemLabelClassName } = props;

  return (
    <div className={classNames(css.ProductProperty, className)}>
      <label className={classNames(css.label, itemLabelClassName)}>{displayName}</label>
      <div className={classNames(css.value, itemValueClassName)}>{value}</div>
    </div>
  );
});

export default ProductProperty;
