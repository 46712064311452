import {
  BaseVisualizationData,
  ComponentCodeToCustomName,
  ImageData,
  PredefinedConfigurationData,
  VisualizationData,
} from '../data/model';
import { AbsoluteUrl, RelativeUrl } from '../shared/Url';

const COMPONENT_CODE = '{componentCode}';
const VARIATION_CODE = '{variationCode}';
const COMPONENT_REFERENCE_PREFIX = '{component:';
const COMPONENT_REFERENCE_SUFFIX = '}';
const SEGMENT_REFERENCE_PREFIX = '{segment:';
const SEGMENT_REFERENCE_SUFFIX = '}';

export class ReferenceReplacer {
  public static replace(text: string, prefix: string, suffix: string, resolveValue: (referenceName: string) => string) {
    while (true) {
      const startIndex = text.indexOf(prefix, 0);
      if (startIndex < 0) {
        break;
      }

      const endIndex = text.indexOf(suffix, startIndex);
      if (endIndex < 0) {
        break;
      }

      const referenceName = text.substring(startIndex + prefix.length, endIndex);
      text = `${text.substring(0, startIndex)}${resolveValue(referenceName)}${text.substring(endIndex + 1)}`;
    }

    return text;
  }
}

export class ComponentNameFormatter {
  private codesToNames: Map<string, string>;
  private selectedItems: Map<string, string>;

  constructor(codesToNames: Array<ComponentCodeToCustomName>, selectedItems: Map<string, string>) {
    this.codesToNames = new Map<string, string>();
    codesToNames.forEach((x) => this.codesToNames.set(x.code, x.name));

    this.selectedItems = selectedItems;
  }

  public apply(format: string, componentCode: string, variationCode: string): string {
    let result = format
      .replace(COMPONENT_CODE, this.MapCodeToCustomName(componentCode))
      .replace(VARIATION_CODE, this.MapCodeToCustomName(variationCode));

    result = this.replaceReferencesToOtherComponents(result);

    return result;
  }

  private replaceReferencesToOtherComponents(format: string) {
    return ReferenceReplacer.replace(
      format,
      COMPONENT_REFERENCE_PREFIX,
      COMPONENT_REFERENCE_SUFFIX,
      (componentCode) => {
        const variationCode = this.selectedItems.has(componentCode)
          ? this.selectedItems.get(componentCode)
          : 'Undefined';
        const customName = this.MapCodeToCustomName(variationCode);
        return customName;
      },
    );
  }

  private MapCodeToCustomName(code: string) {
    const name = this.codesToNames.get(code);
    return !!name ? name : code;
  }
}

export class FuzzyEqualOperator {
  public static evaluate(x: string, y: string) {
    const yAsteriskIndex = y.indexOf('*');

    if (yAsteriskIndex >= 0) {
      let prefix = y.substring(0, yAsteriskIndex);
      let suffix = y.substring(1 + yAsteriskIndex);

      if (prefix && suffix) {
        return x.startsWith(prefix) && x.endsWith(suffix);
      }

      if (prefix) {
        return x.startsWith(prefix);
      }

      if (suffix) {
        return x.endsWith(suffix);
      }
    }

    return x === y;
  }
}

export class ContainsOperator {
  public static evaluate(x: string, y: string) {
    const array = ContainsOperator.parseArray(y);
    return array.any((i) => FuzzyEqualOperator.evaluate(x, i));
  }

  private static parseArray(input: string) {
    return input
      .replace('[', '')
      .replace(']', '')
      .split(',')
      .filter((item) => !!item)
      .map((item) => item.trim());
  }
}

export class ConditionEvaluator {
  private operators = new Map<string, (x: string, y: string) => boolean>();
  private nameFormatter: ComponentNameFormatter;

  constructor(selectedItems: Map<string, string>) {
    this.operators.set('!=', (x, y) => !FuzzyEqualOperator.evaluate(x, y));
    this.operators.set('=', (x, y) => FuzzyEqualOperator.evaluate(x, y));
    this.operators.set('!∈', (x, y) => !ContainsOperator.evaluate(x, y));
    this.operators.set('∈', (x, y) => ContainsOperator.evaluate(x, y));

    this.nameFormatter = new ComponentNameFormatter([], selectedItems);
  }

  public isSatisfied(condition: string, componentCode: string, variationCode: string): boolean {
    if (!condition) {
      return true;
    }

    let result = true;
    const conditions = condition.split('&');

    conditions.forEach((x) => {
      result = result && this.evaluate(x.trim(), componentCode, variationCode);
    });

    return result;
  }

  public evaluate(condition: string, componentCode: string, variationCode: string): boolean {
    if (!condition) {
      return true;
    }

    let result = false;
    let operatorMatched = false;

    this.operators.forEach((evaluator, operator) => {
      if (operatorMatched) {
        return;
      }

      if (condition.indexOf(operator) >= 0) {
        const args = condition.split(operator);

        if (args.length !== 2) {
          throw Error(`Expected two arguments but got ${args.length} when parsing condition ${condition}`);
        }

        const x = this.nameFormatter.apply(args[0], componentCode, variationCode).trim();
        const y = this.nameFormatter.apply(args[1], componentCode, variationCode).trim();

        result = evaluator(x, y);
        operatorMatched = true;
      }
    });

    return result;
  }
}

export class VisualizationComponents {
  protected data: BaseVisualizationData;

  constructor(data: BaseVisualizationData) {
    this.data = data;
  }

  public includeProductIntoSelectedItems(selectedItems: Map<string, string>) {
    const selectedItemsIncludingProductCode = new Map(selectedItems).set('product', this.data.productCode);
    return selectedItemsIncludingProductCode;
  }

  public getNames(selectedItems: Map<string, string>, shot: string) {
    const selectedItemsIncludingProductCode = this.includeProductIntoSelectedItems(selectedItems);
    return this.getNamesInternal(selectedItemsIncludingProductCode, shot);
  }

  private getNamesInternal(selectedItems: Map<string, string>, shot: string) {
    const componentsNames = [...this.data.mapping.staticComponents];
    const conditionEvaluator = new ConditionEvaluator(selectedItems);

    this.data.mapping.dynamicComponents.forEach((component) => {
      selectedItems.forEach((variationCode, componentCode) => {
        if (
          component.code === componentCode &&
          conditionEvaluator.isSatisfied(component.condition, componentCode, variationCode)
        ) {
          component.formats.forEach((format) => {
            const codesToCustomNames = [
              ...(component.codesToCustomNames ?? []),
              ...(this.data.mapping.globalCodesToCustomNames ?? []),
            ];
            const formatter = new ComponentNameFormatter(codesToCustomNames, selectedItems);
            let componentName = formatter.apply(format, componentCode, variationCode);

            // TODO: backward compatibility, later always convert to lowercase
            if (shot) {
              componentName = componentName.toLowerCase();
            }

            componentsNames.push(componentName);
          });
        }
      });
    });

    return this.replaceNamedSegments(selectedItems, componentsNames);
  }

  private replaceNamedSegments(selectedItems: Map<string, string>, componentsNames: string[]) {
    if (!this.data.mapping.segments) {
      return componentsNames;
    }

    const conditionEvaluator = new ConditionEvaluator(selectedItems);

    const segmentsMap = new Map(
      this.data.mapping.segments
        .filter((x) => conditionEvaluator.isSatisfied(x.condition, '', ''))
        .map((x) => [x.name, x.value]),
    );

    const finalComponentsNames = componentsNames.map((x) => {
      return ReferenceReplacer.replace(
        x,
        SEGMENT_REFERENCE_PREFIX,
        SEGMENT_REFERENCE_SUFFIX,
        (segmentName) => segmentsMap.get(segmentName) ?? 'Undefined',
      );
    });

    return finalComponentsNames;
  }
}

export class VisualizationBuilder {
  public static buildPartialImages(
    data: VisualizationData,
    selectedComponents: Map<string, string>,
    shot?: string,
    width?: number,
    height?: number,
  ) {
    const components = new VisualizationComponents(data);
    const componentsNames = components.getNames(selectedComponents, shot);
    const baseImagesUrl = shot ? `${data.basePartialImagesUrl}/${shot}` : data.basePartialImagesUrl;

    let queryString = `v=${data.mapping.versionOfImages}`;
    queryString = VisualizationBuilder.appendSize(queryString, width, height);

    const urls = componentsNames.map((x) => `${baseImagesUrl}/${x}.png?${queryString}`);
    return urls;
  }

  public static buildFlattenedImage(
    data: VisualizationData,
    selectedComponents: Map<string, string>,
    shot?: string,
    width?: number,
    height?: number,
    extension: string = '.jpg',
  ) {
    const components = new VisualizationComponents(data);
    const componentsNames = components.getNames(selectedComponents, shot);
    const mergedParts = componentsNames.join(',');
    const baseImagesUrl = shot ? `${data.baseFlattenedImagesUrl}/${shot}` : data.baseFlattenedImagesUrl;

    let queryString: string = `parts=${mergedParts}&sourceFormat=png&v=${data.mapping.versionOfImages}`;
    queryString = VisualizationBuilder.appendSize(queryString, width, height);

    const url = `${baseImagesUrl}${extension}?${queryString.toString()}`;
    return url;
  }

  private static appendSize(queryString: string, width?: number, height?: number) {
    let result = queryString;

    if (width) {
      result += `&width=${width}`;
    }

    if (height) {
      result += `&height=${height}`;
    }

    return result;
  }
}

export type SupportedExtension = 'jpeg' | 'jpg' | 'gif' | 'png';

export class FinalVisualizationBuilder {
  private static baseUrl: string;

  public static setBaseUrl(baseUrl: string) {
    FinalVisualizationBuilder.baseUrl = baseUrl;
  }

  public static buildUrl(
    configurationCode: string,
    shot?: string,
    extension: SupportedExtension = 'jpg',
    width?: number,
    height?: number,
  ) {
    const query = new Map<string, string>();

    if (shot) {
      query.set('shot', shot);
    }
    if (width) {
      query.set('width', width.toString());
    }
    if (height) {
      query.set('height', height.toString());
    }

    query.set('v', '6');
    query.set('cacheBuster', '1');

    return AbsoluteUrl.parse(FinalVisualizationBuilder.baseUrl)
      .append(new RelativeUrl(`${configurationCode}.${extension}`, query))
      .toString();
  }

  public static buildUrlForPredefinedConfiguration(
    configuration: PredefinedConfigurationData,
    fallbackImage: ImageData,
  ) {
    return configuration
      ? FinalVisualizationBuilder.buildUrl(configuration.code, configuration.shot)
      : fallbackImage
      ? fallbackImage.url
      : '';
  }
}
