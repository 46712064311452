import AuthSettings from './auth/AuthSettings';
import { isBrowser } from './shared/common';

export type Environment = 'prod' | 'int' | 'test';

class AppSettings {
  apiUrl: string;
  visualizationUrl: string;
  baseUrl: string;
  environment: Environment;
  instrumentationKey: string;
  googleMapApiKey: string;
  functionsUrl: string;
  authSettings: AuthSettings;

  constructor(
    apiUrl: string = 'https://flokk-storefront-api-int.azurewebsites.net/api/endpoint',
    visualizationUrl: string = 'https://flokk-cdn-visualization-int.azureedge.net',
    environment: Environment = 'int',
    googleMapApiKey: string = 'AIzaSyB1JM0lfpZngiLmjpGmfeO8tFq1IlmPM5U',
    baseUrl?: string,
    instrumentationKey?: string,
    functionsUrl: string = 'https://flokk-services-int.azurewebsites.net',
    authSettings: AuthSettings = {
      domain: 'flokk-int.eu.auth0.com',
      clientId: 'xoOCTD3UnzGf2dxNjRiJwpez5F87S8h8',
      redirectUri: 'http://localhost:3000/handle-auth-code',
      audience: 'https://flokk-storefront-api-int.azurewebsites.net',
    },
  ) {
    this.apiUrl = apiUrl;
    this.visualizationUrl = visualizationUrl;
    this.baseUrl = baseUrl || (isBrowser() ? location.origin : 'https://flokk-storefront-web-int.azurewebsites.net');
    this.environment = environment;
    this.instrumentationKey = instrumentationKey;
    this.googleMapApiKey = googleMapApiKey;
    this.functionsUrl = functionsUrl;
    this.authSettings = authSettings;
  }
}

export default AppSettings;
