import classNames from 'classnames';
import { Component } from 'react';

import { Breakpoint, Screen, ScreenObserver } from '../../layout/ScreenObserver';
import { FilterBarTranslation } from '../../localization/SiteTranslation';
import IconButton from '../../shared/components/Button/IconButton';
import Icon from '../../shared/components/Icon/Icon';
import List from '../../shared/components/List/List';
import { ScrollLock } from '../../shared/components/ScrollLock/ScrollLock';
import { Subtitle2DarkGrey } from '../../shared/components/Typography/Subtitle';
import { FilterState } from '../CatalogFiltersState';
import FilterGroup from '../FilterGroup';
import css from './FilterBar.css';

interface FilterBarProps {
  translation: FilterBarTranslation;
  filters: Array<FilterState>;
  resultsNumber: number;
  className?: string;
  containerClassName?: string;
}

export default class FilterBar extends Component<FilterBarProps, { isOpen: boolean; breakpoint: Breakpoint }> {
  filterBarRef: HTMLDivElement;

  constructor(props: FilterBarProps) {
    super(props);
    this.state = { isOpen: false, breakpoint: Breakpoint.Unknown };

    this.handleClick = this.handleClick.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onScreenChange = this.onScreenChange.bind(this);
    this.closeFiltersMobileMenu = this.closeFiltersMobileMenu.bind(this);
  }

  get translation() {
    return this.props.translation;
  }

  componentDidMount(): void {
    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
    window.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount(): void {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
    window.removeEventListener('mousedown', this.handleClick);
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  closeFiltersMobileMenu() {
    this.setState({ isOpen: false });
  }

  onScreenChange(screen: Screen) {
    this.setState({ breakpoint: screen.breakpoint });

    if (this.state.isOpen) {
      this.closeFiltersMobileMenu();
    }
  }

  handleClick(e: MouseEvent) {
    const target = e.target as Node;
    if (!this.filterBarRef.contains(target)) {
      this.closeFiltersMobileMenu();
    }
  }

  render() {
    const { filters, resultsNumber, className, containerClassName } = this.props;
    const { isOpen } = this.state;

    return (
      <ScrollLock isLocked={this.state.isOpen && this.state.breakpoint === Breakpoint.Phone}>
        <div
          className={classNames(css.FilterBar, isOpen && css.isOpen, className)}
          ref={(node) => (this.filterBarRef = node)}
        >
          <div className={classNames(css.container, containerClassName)}>
            <IconButton
              className={css.filtersMobileGroup}
              nameClassName={css.name}
              icon={<Icon name={isOpen ? 'angleUp' : 'angleDown'} />}
              after
              onClick={this.toggleOpen}
            >
              {this.translation.name}
            </IconButton>
            <div className={classNames(css.filterGroupsContainer, isOpen && css.filtersMobileOpen)}>
              <List
                className={classNames(css.filterGroups, isOpen && css.filtersMobileOpen)}
                items={filters}
                itemRenderer={(group) => <FilterGroup breakpoint={this.state.breakpoint} filterGroup={group} />}
                itemClassName={css.filterGroupItemContainer}
              />
            </div>
            <Subtitle2DarkGrey className={css.filteredFamiliesQuantity}>
              {this.translation.results.interpolate([['results', resultsNumber.toString()]])}
            </Subtitle2DarkGrey>
          </div>
        </div>
      </ScrollLock>
    );
  }
}
