import AppSettings from '../../app/AppSettings';
import { IApiClient } from '../../app/data/client';
import { GetDealerStoreQuery, ShoppingContext } from '../../app/data/model';
import RouteDefinitions from '../../app/RouteDefinitions';
import { BaseShareConfiguratorUrlProvider } from '../../app/shared/BaseShareConfiguratorUrlProvider';
import { getB2cOrigin } from '../../app/shared/getB2cOrigin';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';

export class B2bShareConfiguratorUrlProvider extends BaseShareConfiguratorUrlProvider {
  private storeId?: string;
  private languageCode?: string;

  constructor(settings: AppSettings, private apiClient: IApiClient) {
    super(settings);
  }

  async initialize(shoppingContext: ShoppingContext): Promise<void> {
    const response = await this.apiClient.send(new GetDealerStoreQuery({ shoppingContext }));
    this.storeId = response.store.id;
    this.languageCode = response.store.languages.any((x) => x.code === shoppingContext.languageCode)
      ? shoppingContext.languageCode
      : 'en-GB';
  }

  public getUrl(code: string, quantity: number = 1): string {
    if (!this.storeId || !this.languageCode) {
      throw new Error(`This instance of share link provider is not initialized`);
    }

    const b2cOrigin = getB2cOrigin(this.settings.baseUrl);
    const query = this.getQuantityQuery(quantity);
    const relativeUrl = StoreUrlBuilder.build(
      this.storeId,
      this.languageCode,
      RouteDefinitions.getUrlTo((x) => x.codeRoute, { code: code }),
      query,
    ).toString();

    return `${b2cOrigin}${relativeUrl}`;
  }
}
