import classNames from 'classnames';
import throttle from 'lodash.throttle';
import { Component, Fragment } from 'react';

import * as Api from '../../../data/model';
import { ScrollPosition } from '../../../layout/WindowScroll';
import WindowScrollObserver from '../../../layout/WindowScrollObserver';
import { ScrollToTopTranslation } from '../../../localization/SiteTranslation';
import { Analytics } from '../../analytics/Analytics';
import { ScrollToAnimation } from '../../animations';
import Icon from '../Icon/Icon';
import Button, { ButtonProps } from './Button';
import css from './ScrollToTopButton.css';

interface ScrollToTopButtonProps extends ButtonProps {
  translation: ScrollToTopTranslation;
  shoppingContext?: Api.ShoppingContext;
}

export default class ScrollToTopButton extends Component<ScrollToTopButtonProps, { isVisible: boolean }> {
  constructor(props: ScrollToTopButtonProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.onScrollChange = throttle(this.onScrollChange.bind(this), 50);

    this.state = { isVisible: false };
  }

  componentDidMount(): void {
    WindowScrollObserver.onScrollChange.subscribe(this.onScrollChange);
  }

  componentWillUnmount(): void {
    WindowScrollObserver.onScrollChange.unsubscribe(this.onScrollChange);
  }

  onScrollChange(position: ScrollPosition) {
    this.setState({ isVisible: position.scrollY > 100 });
  }

  handleClick() {
    new ScrollToAnimation('#root', true).start();
    if (this.props.shoppingContext) {
      Analytics.trackButtonClick('scrollToTopClick', this.props.shoppingContext);
    }
  }

  render() {
    const { translation, ...props } = this.props;
    return (
      <Button
        onClick={this.handleClick}
        {...props}
        className={classNames(
          css.ScrollToTopButton,
          !this.state.isVisible && 'visuallyHidden',
          this.state.isVisible && css.isVisible,
        )}
      >
        <Fragment>
          <div className={css.round}>
            <Icon className={css.icon} name="angleUp" />
          </div>
          <span>{translation.button}</span>
        </Fragment>
      </Button>
    );
  }
}
