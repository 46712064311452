export interface Telemetry {
  properties?: {
    [key: string]: string;
  };
}

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export interface TraceTelemetry extends Telemetry {
  message: string;
  severity?: SeverityLevel;
}

export interface PageViewTelemetry extends Telemetry {
  name: string;
  url: string;
  duration: number;
}

export interface DependencyTelemetry extends Telemetry {
  method: string;
  url: string;
  pathName: string;
  duration: number;
  resultCode: number;
  success: boolean;
}

export interface MetricTelemetry extends Telemetry {
  name: string;
  value: number;
  count?: number;
  min?: number;
  max?: number;
}

export interface ExceptionTelemetry extends Telemetry {
  exception: Error;
}

export interface EventTelemetry extends Telemetry {
  name: string;
}

export interface IMonitoringService {
  startTrackPage(): void;

  stopTrackPage(): void;

  trackPageView(pageView: PageViewTelemetry): void;

  trackDependency(request: DependencyTelemetry): void;

  trackEvent(event: EventTelemetry): void;

  trackException(exception: ExceptionTelemetry): void;

  trackMetric(metric: MetricTelemetry): void;

  trackTrace(trace: TraceTelemetry): void;
}

export class EmptyMonitoringService implements IMonitoringService {
  startTrackPage(): void {
    // Do nothing
  }

  stopTrackPage(): void {
    // Do nothing
  }

  trackPageView(pageView: PageViewTelemetry): void {
    // Do nothing
  }

  trackDependency(request: DependencyTelemetry): void {
    // Do nothing
  }

  trackEvent(event: EventTelemetry): void {
    // Do nothing
  }

  trackException(exception: ExceptionTelemetry): void {
    // Do nothing
  }

  trackMetric(metric: MetricTelemetry): void {
    // Do nothing
  }

  trackTrace(trace: TraceTelemetry): void {
    // Do nothing
  }
}
