import { PaymentMethodData } from '../../../data/model';
import PaymentMethodSelectorOption from './PaymentMethodSelectorOption/PaymentMethodSelectorOption';

interface PaymentMethodSelectorProps {
  options: PaymentMethodData[];
  value: string;
  onChange: (code: string) => void;
}

const PaymentMethodSelector = ({ onChange, options, value }: PaymentMethodSelectorProps) => {
  return options.any() ? (
    <>
      {options.map((option) => (
        <PaymentMethodSelectorOption
          data={option}
          isChecked={option.code === value}
          name="payment-method"
          onChange={onChange}
          key={option.code}
        />
      ))}
    </>
  ) : null;
};

export default PaymentMethodSelector;
