import { ConfiguratorSummaryContext } from '../app/shared/regions/RegionDataContext';
import { RegionManager } from '../app/shared/regions/RegionManager';
import { AppState } from './AppState';
import { ConfiguratorActions } from './configurator/ConfiguratorActions';
import { ConfiguratorActionsState } from './configurator/ConfiguratorActionsState';

export default function (regionManager: RegionManager) {
  regionManager.registerViewWithRegion(
    'ConfiguratorSummary',
    (data: ConfiguratorSummaryContext['data'], appState: AppState) => {
      return new ConfiguratorActionsState(
        data.configurator,
        data.store,
        appState.client,
        appState.navigation,
        appState.eventAggregator,
        appState.translation.configuratorActions,
      );
    },
    ConfiguratorActions,
  );
}
