import classNames from 'classnames';
import { observer } from 'mobx-react';

import Button from '../../../shared/components/Button/Button';
import Heading from '../../../shared/components/Typography/Heading/Heading';
import ConfiguratorFeatures from './ConfiguratorFeatures/ConfiguratorFeatures';
import ConfiguratorProgress from './ConfiguratorProgress/ConfiguratorProgress';
import css from './RawConfigurator.css';
import { RawConfiguratorState } from './RawConfiguratorState';

interface RawConfiguratorProps {
  state: RawConfiguratorState;
}

const RawConfigurator = observer(({ state }: RawConfiguratorProps) => {
  if (!state.structure) {
    return null;
  }

  return (
    <>
      <header>
        <Heading level={2} className={classNames(css.heading, css[state.size])}>
          Select features for {state.structure.productNumber}
        </Heading>
        <ConfiguratorProgress size={state.size} value={state.progressPercentage} />
      </header>
      <ConfiguratorFeatures
        className={classNames(css.features, css[state.size])}
        features={state.selectedFeatures}
        size={state.size}
        onSelectionChange={state.onFeatureOptionSelected}
      />
      {!state.configurationCompleted && (
        <Button
          className={classNames(css.button, css[state.size])}
          onClick={state.populateNextFeatureSelection}
          disabled={!state.canMoveNext}
        >
          Select
        </Button>
      )}
    </>
  );
});

export default RawConfigurator;
