import { reaction } from 'mobx';

import { KindOfFabricRestrictionData, RestrictionType } from '../data/model';
import { FabricOptionState, KindOfFabricFilter } from './FabricOptionState';
import { IOptionState, OptionVariationState } from './OptionState';

export class KindOfFabricRestrictionsCoordinator {
  private restrictionsByVariationId: { [key: string]: KindOfFabricRestrictionData[] } = {};
  private optionsByKindOfFabricId: { [key: string]: FabricOptionState[] } = {};
  private lastSelectedByOptionId: { [key: string]: OptionVariationState } = {};

  constructor(fabricOptions: FabricOptionState[], restrictions: KindOfFabricRestrictionData[]) {
    this.restrictionsByVariationId = restrictions.groupBy<KindOfFabricRestrictionData>((x) => x.variant.variationId);
    this.optionsByKindOfFabricId = fabricOptions.groupBy<FabricOptionState>((x) => x.data.kindOfFabricId);

    fabricOptions.forEach((option) => {
      this.rememberLastSelected(option, option.selected);
    });

    fabricOptions.forEach((option) => {
      this.checkRestrictionsAndLimitDependentOptions(option.selected);

      reaction(
        () => option.selected,
        (selected) => {
          this.rememberLastSelected(option, selected);
          this.checkRestrictionsAndLimitDependentOptions(selected);
        },
      );
    });
  }

  rememberLastSelected(option: FabricOptionState, selected: OptionVariationState) {
    this.lastSelectedByOptionId[option.id] = selected;
  }

  resetLastSelected(optionId: string) {
    this.lastSelectedByOptionId[optionId] = undefined;
  }

  checkRestrictionsAndLimitDependentOptions(selected: OptionVariationState) {
    const effectiveRestrictions = selected.data.kindOfFabricVariationIds?.mapMany(
      (x) => this.restrictionsByVariationId[x] ?? [],
    );

    if (!effectiveRestrictions || effectiveRestrictions.empty()) {
      return;
    }

    const effectiveRestrictionsByTargetKindOfFabricId = effectiveRestrictions.groupBy<KindOfFabricRestrictionData>(
      (x) => x.restrictedVariant.optionId,
    );

    Object.entries(effectiveRestrictionsByTargetKindOfFabricId).forEach(([restrictedKindOfFabricId, restrictions]) => {
      const includedKindOfFabricsVariationsIds = restrictions
        .filter((restriction) => restriction.type === RestrictionType.Requires)
        .map((restriction) => restriction.restrictedVariant.variationId);

      const excludedKindOfFabricsVariationsIds = restrictions
        .filter((restriction) => restriction.type === RestrictionType.NotAvailableIf)
        .map((restriction) => restriction.restrictedVariant.variationId);

      const restrictedOptions = this.optionsByKindOfFabricId[restrictedKindOfFabricId];

      if (!restrictedOptions) {
        return;
      }

      restrictedOptions
        .map((restrictedOption) => restrictedOption as FabricOptionState)
        .forEach((restrictedOption) => {
          restrictedOption.setIncludedKindOfFabrics(includedKindOfFabricsVariationsIds);
          restrictedOption.setExcludedKindOfFabrics(excludedKindOfFabricsVariationsIds);

          this.tryPreselectDependentVariation(
            restrictedOption,
            includedKindOfFabricsVariationsIds,
            excludedKindOfFabricsVariationsIds,
            selected.data.code,
          );
        });
    });
  }

  private tryPreselectDependentVariation(
    dependentOption: FabricOptionState,
    includedKindOfFabricVariationIds: string[],
    excludedKindOfFabricVariationIds: string[],
    newSelectedFabricCode: string,
  ) {
    const filter = new KindOfFabricFilter(
      includedKindOfFabricVariationIds,
      excludedKindOfFabricVariationIds,
      dependentOption.allFabricVariationsData,
    );
    const filteredVariations = dependentOption
      .getAllItems()
      .filter((x) => filter.isSatisfied(x.data) && x.restrictionsResolver.isRestricted(x) === false);

    if (filteredVariations.empty()) {
      return;
    }

    const lastSelection = this.lastSelectedByOptionId[dependentOption.id];
    if (lastSelection && filter.isSatisfied(lastSelection.data)) {
      return;
    }

    const variationToPreselect =
      filteredVariations.find((x) => x.data.code === newSelectedFabricCode) ?? filteredVariations.first();

    dependentOption.selectItem(variationToPreselect);

    // Reset, but do it in another mobx action after all reactions triggered by previous line are called. We can force it using setTimeout.
    setTimeout(() => this.resetLastSelected(dependentOption.id));
  }
}

export class KindOfFabricRestrictionsCoordinatorFactory {
  public static create(options: IOptionState[], restrictions: KindOfFabricRestrictionData[]) {
    const fabricOptions = options
      .filter((x) => !!x.data.kindOfFabricId)
      .filter((x) => x instanceof FabricOptionState)
      .map((x) => x as FabricOptionState);

    if (fabricOptions.any() && restrictions.any()) {
      // tslint:disable-next-line:no-unused-expression
      new KindOfFabricRestrictionsCoordinator(fabricOptions, restrictions);
    }
  }
}
