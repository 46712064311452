import {
  BaseModelData,
  OrderItemData,
  ProductConfigurationData,
  ShoppingCartItemData,
  WebShopProductDetailsData,
} from '../../data/model';
import { ProductData } from './TrackingService';

class ProductDataConverter {
  public static fromBaseModelData(
    baseModelData: BaseModelData,
    configurationCode: string,
    price: number,
    quantity: number,
  ): ProductData {
    return {
      id: baseModelData.id,
      name: baseModelData.name,
      brand: baseModelData.brandName,
      category: ProductDataConverter.formatCategory(baseModelData.productFamilyShortName, baseModelData.mainProductId),
      configurationCode,
      price,
      quantity,
    };
  }

  public static fromOrderItemData(orderItemData: OrderItemData): ProductData {
    const mainProductId = orderItemData.mainProductOriginId ?? orderItemData.mainProductId;

    return {
      id: orderItemData.productOriginId ?? orderItemData.baseModelId,
      name: orderItemData.name,
      brand: orderItemData.brandName,
      category: ProductDataConverter.formatCategory(orderItemData.familyShortName, mainProductId),
      configurationCode: orderItemData.configurationCode,
      price: orderItemData.salePrice.amount,
      quantity: orderItemData.quantity,
    };
  }

  public static fromProductConfigurationData(product: ProductConfigurationData): ProductData {
    return {
      id: product.baseModelId,
      name: product.baseModelId,
      quantity: product.quantity,
    };
  }

  public static fromShoppingCartItemData(shoppingCartItemData: ShoppingCartItemData, quantity?: number): ProductData {
    const { baseModel, code, salePriceWithTax } = shoppingCartItemData;
    const mainProductId = baseModel.mainProductOriginId ?? baseModel.mainProductId;

    return {
      id: baseModel.productOriginId ?? baseModel.id,
      name: baseModel.name,
      brand: baseModel.brandName,
      category: ProductDataConverter.formatCategory(baseModel.productFamilyShortName, mainProductId),
      configurationCode: code,
      price: salePriceWithTax.amount,
      quantity: quantity ? quantity : shoppingCartItemData.quantity,
    };
  }

  public static fromWebShopProductDetailsData(
    webShopProductDetailsData: WebShopProductDetailsData,
    configurationCode: string,
    price: number,
    quantity: number,
  ): ProductData {
    return {
      id: webShopProductDetailsData.baseModelId,
      name: webShopProductDetailsData.name,
      brand: webShopProductDetailsData.brandName,
      category: ProductDataConverter.formatCategory(
        webShopProductDetailsData.productFamily.shortName,
        webShopProductDetailsData.mainProductOriginId,
      ),
      configurationCode,
      price,
      quantity,
    };
  }

  private static formatCategory(productFamilyName: string, mainProductId: string): string {
    if (!productFamilyName || !mainProductId) {
      return;
    }

    return `${productFamilyName}/${mainProductId}`;
  }
}

export default ProductDataConverter;
