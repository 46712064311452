import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import InputElement, { InputElementProps } from '../../../../shared/components/Input/InputElement';
import InputWithLoader, { InputLoaderProps } from '../../../../shared/components/Input/InputWithLoader';
import Label from '../../../../shared/components/Label/Label';
import css from './SearchInput.css';

export interface SearchInputProps extends InputElementProps, InputLoaderProps {
  label?: string;
  labelClassName?: string;
  hideLabel?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  inputClassName?: string;
}

const SearchInput = (props: SearchInputProps) => {
  let {
    label,
    labelClassName,
    hideLabel,

    className,
    inputClassName,

    isLoading,
    loaderClassName,
    loaderContainerClassName,

    ...inputProps
  } = props;

  return (
    <div className={classNames(css.SearchInput, className)}>
      {label && (
        <Label className={labelClassName} hideLabel={hideLabel}>
          {label}
        </Label>
      )}
      <InputWithLoader
        isLoading={isLoading}
        loaderClassName={loaderClassName}
        loaderContainerClassName={loaderContainerClassName}
      >
        <InputElement {...inputProps} className={inputClassName} />
      </InputWithLoader>
    </div>
  );
};

export default observer(SearchInput);
