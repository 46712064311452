import { Mesh, Scene } from '@babylonjs/core';

import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from './MeshPresenter';

export class OpacityPresenter implements IMeshPresenter {
  update(scene: Scene, mesh: Mesh, object: ObjectState): void {
    mesh.visibility = object.opacity;
    mesh.isPickable = true;
  }
}
