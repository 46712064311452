import { useState } from 'react';

const enum LoadingStatus {
  Error,
  InProgress,
  Success,
}

function useLoadingStatus(): {
  isError: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  setError: () => void;
  setInProgress: () => void;
  setSuccess: () => void;
} {
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.InProgress);

  const isError = loadingStatus === LoadingStatus.Error;
  const isLoaded = loadingStatus === LoadingStatus.Success;
  const isLoading = loadingStatus === LoadingStatus.InProgress;

  const setError = () => {
    setLoadingStatus(LoadingStatus.Error);
  };

  const setInProgress = () => {
    setLoadingStatus(LoadingStatus.InProgress);
  };

  const setSuccess = () => {
    setLoadingStatus(LoadingStatus.Success);
  };

  return { isError, isLoaded, isLoading, setError, setInProgress, setSuccess };
}

export default useLoadingStatus;
