import { observer } from 'mobx-react';

import { ColorData } from '../../../../../app/data/model';
import { Gallery, GalleryBreakpoint } from '../../../../../app/shared/components/Gallery/Gallery';
import { GalleryState } from '../../../../../app/shared/components/Gallery/GalleryState';

interface ImageGalleryProps {
  breakpoints: GalleryBreakpoint[];
  maxRowCount: number;
  state: GalleryState;
  className?: string;
  withBackground?: boolean;
  backgroundColor?: ColorData;
}

const ImageGallery = ({
  breakpoints,
  className,
  maxRowCount,
  state,
  withBackground,
  backgroundColor,
}: ImageGalleryProps) => {
  return (
    <Gallery
      className={className}
      breakpoints={breakpoints}
      maxRowCount={maxRowCount}
      state={state}
      withBackground={withBackground}
      style={{ ...(backgroundColor && { backgroundColor: backgroundColor.rgba }) }}
    />
  );
};

export default observer(ImageGallery);
