import { Camera } from '@babylonjs/core';
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Scene } from '@babylonjs/core/scene';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Component, CSSProperties } from 'react';

import { SceneScreenshotMaker } from '../3d/SceneScreenshotMaker';
import AppState from '../AppState';
import ProductViewer3d from '../configurator/components/ProductViewer3d/ProductViewer3d';
import { VisualizationHybridState } from '../configurator/VisualizationHybridState';
import ThreeDotsLoader from '../shared/components/Loaders/ThreeDotsLoader';
import css from './ProductViewer3dPage.css';
import { originalImageHeight, originalImageWidth, ProductViewer3dPageState } from './ProductViewer3dPageState';

interface ProductViewer3dPageProps {
  appState: AppState;
  state: ProductViewer3dPageState;
}

@observer
class ProductViewer3dPage extends Component<ProductViewer3dPageProps, {}> {
  constructor(props: ProductViewer3dPageProps) {
    super(props);
  }

  onSceneUpdate = (scene: Scene) => {
    const { width, height, shot, setSceneReady } = this.props.state;
    const camera = scene.activeCamera as ArcRotateCamera;

    if (width && height) {
      const engine = scene.getEngine();
      engine.setSize(width, height);

      const originalAspectRatio = originalImageWidth / originalImageHeight;
      const currentAspectRatio = width / height;
      camera.fovMode =
        originalAspectRatio > currentAspectRatio ? Camera.FOVMODE_VERTICAL_FIXED : Camera.FOVMODE_HORIZONTAL_FIXED;

      engine.resize();
    }

    if (shot) {
      camera.alpha = SceneScreenshotMaker.getCameraRotationAngleByShot(shot);
    }

    setSceneReady(true);
  };

  render() {
    const { width, height, backgroundColor, isSceneReady } = this.props.state;
    const { visualization } = this.props.state;
    const sceneReadyClassName = 'sceneReady';
    const viewerClassName = isSceneReady
      ? classnames(css.viewer, css.visible, sceneReadyClassName)
      : classnames(css.viewer, css.hidden);

    const style: CSSProperties = {};

    if (width && height) {
      style.width = width;
      style.height = height;
    }

    return (
      <div className={css.ProductViewer3dPage}>
        {visualization instanceof VisualizationHybridState && (
          <ProductViewer3d
            state={visualization.visualization3dState}
            onSceneUpdateSuccess={this.onSceneUpdate}
            style={style}
            viewerClassName={viewerClassName}
            backgroundColor={backgroundColor}
          >
            {isSceneReady ? null : <ThreeDotsLoader className={css.loader} size="large" />}
          </ProductViewer3d>
        )}
      </div>
    );
  }
}

export default ProductViewer3dPage;
