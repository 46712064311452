import { observer } from 'mobx-react';
import { createElement } from 'react';

import { AppState } from '../AppState';
import { TopBarTranslation } from '../localization/SiteTranslation';
import GeneralHeader from './GeneralHeader/GeneralHeader';
import { WithShowroomTimer } from './WithShowroomTimer';

export interface TopbarProps {
  className?: string;
  logoLink?: string;
  modelName?: string;
  translation: TopBarTranslation;
  appState: AppState;
  isLogoSmall?: boolean;
}

const Topbar = observer((props: TopbarProps) => {
  const { appState, logoLink } = props;
  const isShowroom = appState.store?.isShowroom();

  if (!logoLink) {
    props = {
      ...props,
      logoLink: isShowroom
        ? appState.storeResponse.storeContext.showroomConfig.homePageUrl
        : appState.navigationState.homeUrl,
    };
  }

  return createElement(isShowroom ? WithShowroomTimer(GeneralHeader) : GeneralHeader, props);
});

export default Topbar;
