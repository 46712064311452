export interface Size {
  width: number;
  height: number;
}

export const baseScreenWidth = 1024;

export type WindowSize = { width: number; height: number };

export class ScreenViewport {
  public static get htmlTag() {
    return document.getElementsByTagName('html')[0];
  }

  public static isElementVisible(elementId: string, offsetTop: number = 0, offsetBottom: number = 0): boolean {
    const element = document.getElementById(elementId);

    if (!element) {
      return true;
    }

    const elementRectangle = element.getBoundingClientRect();

    const isVisible =
      elementRectangle.top - offsetTop >= 0 &&
      elementRectangle.top + elementRectangle.height + offsetBottom <= window.innerHeight;

    return isVisible;
  }

  public static isElementBottomVisible(elementId: string, offset?: number) {
    const element = document.getElementById(elementId);

    if (!element) {
      return true;
    }

    const elementRectangle = element.getBoundingClientRect();
    return elementRectangle.bottom >= offset;
  }

  public static distanceFromViewport(elementId: string, offsetTop: number = 0, offsetBottom: number = 0): number {
    const element = document.getElementById(elementId);

    if (!element) {
      return 0;
    }

    const elementRectangle = element.getBoundingClientRect();

    const topDistance = elementRectangle.top - offsetTop >= 0 ? 0 : Math.abs(elementRectangle.top - offsetTop);
    const bottomDistance =
      elementRectangle.top + elementRectangle.height + offsetBottom <= window.innerHeight
        ? 0
        : Math.abs(elementRectangle.top + elementRectangle.height + offsetBottom - window.innerHeight);

    return Math.max(topDistance, bottomDistance);
  }

  public static getWindowSize(): WindowSize {
    return { width: window.innerWidth, height: window.innerHeight };
  }
}
