import classNames from 'classnames';

import css from './AccordionPanelContent.css';

interface AccordionPanelContentProps {
  className?: string;
  children: string | JSX.Element;
  withoutSeparator: boolean;
}

const AccordionPanelContent = ({ className, children, withoutSeparator }: AccordionPanelContentProps) => {
  return (
    <div className={classNames(className, !withoutSeparator && css.separator)}>
      <div className={css.AccordionPanelContent}>{children}</div>
    </div>
  );
};

export default AccordionPanelContent;
