import { action, makeObservable, observable } from 'mobx';

import { ShoppingCartItemData } from '../../../app/data/model';
import { AsyncCommand } from '../../../app/shared/common';
import { NumberInput } from '../../../app/shared/Form';
import { CartItemTranslation } from '../../localization/SiteTranslation';

// TODO Move to some common location
export interface ILabeledAction<TItem> {
  label: string;
  action: (item: TItem) => Promise<void>;
}

export interface ICartItemContextAction extends ILabeledAction<ShoppingCartItemData> {
  // label: string;
  // action: (item: ShoppingCartItemData) => Promise<void>;
  separate?: boolean;
}

export interface ICartItemActions {
  contextActions: ICartItemContextAction[];
  quantityChangeApproved: (item: ShoppingCartItemData, newQuantity: number) => Promise<void>;
}

export interface CartItemContextCommand {
  label: string;
  command: AsyncCommand;
}

export class CartItemState {
  public updateQuantityCommand: AsyncCommand;
  public cartItemContextCommands: CartItemContextCommand[] = [];

  @observable
  public quantityUpdate: NumberInput;

  constructor(
    public item: ShoppingCartItemData,
    private actions: ICartItemActions,
    public translation: CartItemTranslation,
  ) {
    makeObservable(this);
    this.quantityUpdate = new NumberInput(item.quantity);

    this.updateQuantityCommand = new AsyncCommand(async () => {
      await this.actions.quantityChangeApproved(this.item, this.quantityUpdate.value);
    });

    this.cartItemContextCommands = actions.contextActions.map<CartItemContextCommand>((ca) => ({
      label: ca.label,
      command: new AsyncCommand(async () => await ca.action(this.item)),
    }));
  }

  @action.bound
  public setQuantityUpdate(quantity: NumberInput) {
    this.quantityUpdate = quantity;
  }
}
