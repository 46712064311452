import * as React from 'react';

import IconButton from '../../../../../shared/components/Button/IconButton';
import Icon from '../../../../../shared/components/Icon/Icon';
import css from './FilterDropdownToggle.css';

interface FilterDropdownToggleProps {
  isOpen: boolean;
  onClick: () => void;
  children: string;
}

const FilterDropdownToggle: React.FC<FilterDropdownToggleProps> = ({ children, isOpen, onClick }) => {
  return (
    <IconButton
      className={css.FilterDropdownToggle}
      icon={<Icon name={isOpen ? 'angleUp' : 'angleDown'} />}
      after
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

export default FilterDropdownToggle;
