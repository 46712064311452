import { action, computed, makeObservable } from 'mobx';

import { OptionVariationGroupData, PriceGroupData } from '../data/model';
import { IFeature } from '../shared/common';
import { IRestrictionsInitializer } from '../shared/restrictions';
import { OptionDescriptionModalState } from './components/OptionDescriptionModal/OptionDescriptionModalState';
import { FabricCollectionState } from './FabricCollectionState';
import { IFabricOptionVariationFilter } from './FabricOptionState';

export class FabricPriceGroupState {
  data: PriceGroupData;
  collections: Array<FabricCollectionState>;

  @computed get filteredCollections() {
    return this.collections.filter((x) => x.filteredVariations.length > 0);
  }

  constructor(
    priceGroup: PriceGroupData,
    collections: Array<OptionVariationGroupData>,
    restrictionsInitializer: IRestrictionsInitializer,
    feature: IFeature,
    descriptionModal: OptionDescriptionModalState,
  ) {
    makeObservable(this);
    this.data = priceGroup;
    this.collections = collections.map(
      (x) => new FabricCollectionState(x, restrictionsInitializer, feature, descriptionModal),
    );
  }

  @action applyFilters(filters: Array<IFabricOptionVariationFilter>) {
    this.collections.forEach((x) => x.applyFilters(filters));
  }

  containsVariationId(variationId: string): boolean {
    return this.collections
      .mapMany((x) => x.variations)
      .map((x) => x.id)
      .contains(variationId);
  }
}
