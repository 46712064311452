import { FooterPaymentOptionData } from '../../../data/model';
import ExternalImage from '../../../shared/components/ExternalImage/ExternalImage';
import List from '../../../shared/components/List/List';
import css from './PaymentOptionList.css';

interface PaymentOptionListProps {
  items: FooterPaymentOptionData[];
}

const PaymentOptionList = ({ items }: PaymentOptionListProps) => {
  const renderItem = (item: FooterPaymentOptionData) => {
    return <ExternalImage className={css.icon} src={item.logo.url} alt={item.logo.name} />;
  };

  return <List className={css.container} itemClassName={css.item} items={items} itemRenderer={renderItem} />;
};

export default PaymentOptionList;
