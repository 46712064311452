import * as React from 'react';

import { CarouselSlideData } from '../../../../../../app/data/model';
import ExternalImage from '../../../../../../app/shared/components/ExternalImage/ExternalImage';
import css from './HeaderSlide.css';

interface HeaderSlideProps extends CarouselSlideData {
  backgroundColor?: string;
  textColor?: string;
}

const HeaderSlide = ({ backgroundColor, textColor, image, title }: HeaderSlideProps) => {
  const captionStyle: React.CSSProperties = {
    backgroundColor,
    color: textColor,
    // IE11 fallback
    boxShadow: `3.2rem 0 0 ${backgroundColor}, -3.2rem 0 0 ${backgroundColor}`,
  };

  return (
    <figure className={css.container}>
      <ExternalImage className={css.image} src={image.url} alt={image.name} />

      {title && (
        <div className={css.textContent}>
          <div className={css.inner}>
            <figcaption className={css.title} style={captionStyle}>
              {title}
            </figcaption>
          </div>
        </div>
      )}
    </figure>
  );
};

export default HeaderSlide;
