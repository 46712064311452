import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { OptionVariationData } from '../../../data/model';
import { OptionDescriptionModalTranslation } from '../../../localization/SiteTranslation';
import List from '../../../shared/components/List/List';
import ThreeDotsLoader from '../../../shared/components/Loaders/ThreeDotsLoader';
import Markdown from '../../../shared/components/Markdown/Markdown';
import Modal from '../../../shared/components/Modal/Modal';
import PreloadedImage from '../../../shared/components/PreloadedImage';
import HeaderLevel3 from '../../../shared/components/Typography/HeaderLevel3';
import css from './OptionDescriptionModal.css';
import { OptionDescriptionModalState } from './OptionDescriptionModalState';

interface OptionDescriptionModalProps {
  modal: OptionDescriptionModalState;
  translation: OptionDescriptionModalTranslation;
}

@observer
class OptionDescriptionModal extends Component<OptionDescriptionModalProps, {}> {
  renderItem(item: OptionVariationData) {
    return (
      <Fragment>
        <PreloadedImage src={item.image.url} width={100} height={100} className={css.collectionImage} />
        <span className={css.text}>{item.name}</span>
        {item.name !== item.code && <span className={css.text}>{item.code}</span>}
      </Fragment>
    );
  }

  render() {
    const { modal, translation } = this.props;

    return (
      <Modal modal={modal} translation={translation}>
        {modal.isLoading ? (
          <div className={css.loaderWrapper}>
            <ThreeDotsLoader className={css.loader} size="medium" />
          </div>
        ) : (
          <div className={css.OptionDescriptionModal}>
            <HeaderLevel3 className={css.title}>{modal.title}</HeaderLevel3>
            <div className={css.descriptionContainer}>
              {modal.optionImage && (
                <PreloadedImage src={modal.optionImage.url} width={200} height={200} className={css.optionImage} />
              )}
              <Markdown linkTarget="_blank" content={modal.description} />
            </div>
            {modal.collection && (
              <List
                items={modal.collection}
                itemRenderer={this.renderItem}
                className={css.list}
                itemClassName={css.listItem}
              />
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export default OptionDescriptionModal;
