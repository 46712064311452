import { useEffect, useState } from 'react';

import { Breakpoint, Screen, ScreenObserver } from '../../layout/ScreenObserver';

function useMobileViewDetector() {
  const [isMobileView, setIsMobileView] = useState(true);

  useEffect(() => {
    handleScreenChange(ScreenObserver.current);

    ScreenObserver.onScreenChange.subscribe(handleScreenChange);

    return () => ScreenObserver.onScreenChange.unsubscribe(handleScreenChange);
  }, []);

  const handleScreenChange = (screen: Screen) => {
    const isMobile = screen.breakpoint === Breakpoint.Phone || screen.breakpoint === Breakpoint.Tablet;
    setIsMobileView(isMobile);
  };

  return isMobileView;
}

export default useMobileViewDetector;
