import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import AppState from '../AppState';
import CheckoutProgressIndicator from '../checkout/components/CheckoutProgressIndicator/CheckoutProgressIndicator';
import { DeliveryFee } from '../checkout/components/ShoppingCartSummary/DeliveryFee';
import { SummaryItem } from '../checkout/components/ShoppingCartSummary/SummaryItem';
import { TotalToPay } from '../checkout/components/ShoppingCartSummary/TotalToPay';
import { PriceProvider } from '../context/PriceContext';
import {
  AddressData,
  ContactAndBillingData,
  DeliveryAddressData,
  DesignerData,
  OrderItemData,
  UserStoryData,
} from '../data/model';
import TertiaryButton from '../shared/components/Button/TertiaryButton';
import ExternalImage from '../shared/components/ExternalImage/ExternalImage';
import Link from '../shared/components/Link';
import List from '../shared/components/List/List';
import Price from '../shared/components/Price';
import RawHtml from '../shared/components/RawHtml';
import HeaderLevel1 from '../shared/components/Typography/HeaderLevel1';
import HeaderLevel3 from '../shared/components/Typography/HeaderLevel3';
import css from './ConfirmationPage.css';
import { ConfirmationState } from './ConfirmationState';

@observer
class ConfirmationPage extends Component<{ orderNumber: string; appState: AppState; state: ConfirmationState }, {}> {
  get translation() {
    return this.props.appState.translation.confirmationPage;
  }

  renderSummaryItem(item: OrderItemData, key: number) {
    return (
      <SummaryItem
        key={`${key}_${SummaryItem.name}`}
        translation={this.props.appState.translation.cart.summary}
        item={{
          code: item.configurationCode,
          name: item.name,
          quantity: item.quantity.toString(),
        }}
        className={css.summaryItem}
      />
    );
  }

  // TODO move to separate component
  renderSummary() {
    const order = this.props.state.orderData;
    const orderItems = order.items;

    return (
      <section className={css.summarySection}>
        <HeaderLevel3 className={css.sectionTitle}>{this.translation.summarySection.title}</HeaderLevel3>
        <div className={css.container}>
          <div className={css.content}>
            <List
              className={css.summaryItemContainer}
              items={orderItems}
              itemRenderer={(item, i) => this.renderSummaryItem(item, i)}
            />
            <div className={css.summaryInfoContainer}>
              <div className={css.addressContainer}>
                {this.renderBillingAddress(this.props.state.orderData.contactAndBilling)}
                {this.renderDeliveryAddress(this.props.state.orderData.deliveryAddress)}
              </div>
              <DeliveryFee
                className={css.deliveryFeeContainer}
                deliveryTimeClassName={css.deliveryTime}
                shippingTotal={order.totalShippingWithTax}
                shippingCostAvailable={true}
                deliveryTime={order.deliveryTime}
                translation={this.props.appState.translation.cart.summary}
              />
              <div className={css.priceContainer}>
                <TotalToPay
                  className={css.priceWithTax}
                  price={order.totalWithTax}
                  translation={this.translation.summarySection.totalToPayText}
                />
                <div className={css.priceWithoutTax}>
                  <Price price={order.total} /> {this.translation.summarySection.taxText}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderAddress(address: AddressData) {
    return (
      <Fragment>
        {address.line1 && (
          <Fragment>
            {address.line1}
            <br />
          </Fragment>
        )}
        {address.line2 && (
          <Fragment>
            {address.line2}
            <br />
          </Fragment>
        )}
        {address.zipCode && (
          <Fragment>
            {' '}
            {address.zipCode}
            <br />
          </Fragment>
        )}
        {address.country && (
          <Fragment>
            {' '}
            {address.country.name}
            <br />
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderBillingAddress(contact: ContactAndBillingData) {
    return (
      <address className={css.summaryAddressContainer}>
        <strong className={css.name}>{this.translation.summarySection.contact.contactAndBilling}</strong>
        {contact.firstName && (
          <Fragment>
            {contact.firstName} {contact.lastName}
            <br />
          </Fragment>
        )}
        {contact.companyName && (
          <Fragment>
            {contact.companyName}
            <br />
          </Fragment>
        )}
        {contact.email && (
          <Fragment>
            {' '}
            {contact.email}
            <br />
          </Fragment>
        )}
        {contact.phoneData.prefix && <Fragment> {contact.phoneData.prefix}</Fragment>}
        {contact.phoneData.number && (
          <Fragment>
            {' '}
            {contact.phoneData.number}
            <br />
          </Fragment>
        )}
        {this.renderAddress(contact.address)}
      </address>
    );
  }

  renderDeliveryAddress(address: DeliveryAddressData) {
    return (
      <address className={css.summaryAddressContainer}>
        <strong className={css.name}>{this.translation.summarySection.contact.deliveryAddress}</strong>
        {address.recipient && (
          <Fragment>
            {address.recipient} <br />
          </Fragment>
        )}
        {this.renderAddress(address)}
      </address>
    );
  }

  renderContactUs() {
    return (
      <div className={css.contactUsContainer}>
        {this.translation.contactUsSection.text}{' '}
        <Link href={this.props.appState.navigationState.customerServiceUrl} target="_blank">
          {this.translation.contactUsSection.linkText}.
        </Link>
      </div>
    );
  }

  renderInformation(title: string, data: DesignerData | UserStoryData) {
    return (
      <div className={css.informationContainer}>
        <HeaderLevel3 className={css.title}>{title}</HeaderLevel3>
        <div className={css.content}>
          <div className={css.imageContainer}>
            <ExternalImage className={css.image} src={data.imageUrl} alt={data.name} />
          </div>
          <div className={css.descriptionContainer}>
            {data.description && <RawHtml className={css.description} content={data.description} />}
            <TertiaryButton
              className={css.button}
              itemElement={
                <Link href={data.url} target="_blank">
                  {this.translation.readMore}
                </Link>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      appState: {
        store: { shouldDisplayDecimalPartOfPrices },
      },
      state: { designerData, steps, userStoryData, orderData },
    } = this.props;

    const isPaymentApproved = orderData.payments.length > 0 && orderData.payments.any((x) => x.isApproved);
    return (
      <PriceProvider enable={true} displayDecimalPart={shouldDisplayDecimalPartOfPrices}>
        <section className={css.ConfirmationPage}>
          <div className={css.content}>
            <HeaderLevel1 className={css.pageTitle}>{this.translation.title}</HeaderLevel1>
            <CheckoutProgressIndicator steps={steps} />

            <div className={css.confirmationContainer}>
              <HeaderLevel3 className={classNames(css.orderMessages, !isPaymentApproved && css.errorText)}>
                {isPaymentApproved ? (
                  <>
                    <div className={css.paymentConfirmed}>{this.translation.paymentConfirmed}</div>
                    <div className={css.checkYourEmail}>{this.translation.checkYourEmail}</div>
                  </>
                ) : (
                  this.translation.errorContent
                )}
              </HeaderLevel3>
              <section className={css.orderNumberContainer}>
                <HeaderLevel3 className={css.sectionTitle}>
                  <Fragment>
                    {this.translation.orderSection.title}: <span className={css.orderNumber}>{orderData.number}</span>
                  </Fragment>
                </HeaderLevel3>
              </section>
              {this.renderSummary()}
              {this.renderContactUs()}
            </div>
          </div>
        </section>
        <section className={css.informationSection}>
          <div className={css.informationSectionContainer}>
            <div className={css.informationSectionContent}>
              {designerData && this.renderInformation(this.translation.designerSectionTitle, designerData)}
              {userStoryData && this.renderInformation(this.translation.customerStorySectionTitle, userStoryData)}
            </div>
          </div>
        </section>
      </PriceProvider>
    );
  }
}

export default ConfirmationPage;
