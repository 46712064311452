import { observer } from 'mobx-react';
import { Component } from 'react';

import { NoConnectionModalTranslation } from '../../../localization/SiteTranslation';
import TertiaryButton from '../Button/TertiaryButton';
import Link from '../Link';
import Modal from '../Modal/Modal';
import HeaderLevel3 from '../Typography/HeaderLevel3';
import css from './NoConnectionModal.css';
import { NoConnectionModalState } from './NoConnectionModalState';

interface ConnectionIssuesModalProps {
  modal: NoConnectionModalState;
  translation: NoConnectionModalTranslation;
}

@observer
class NoConnectionModal extends Component<ConnectionIssuesModalProps, {}> {
  render() {
    const { modal, translation } = this.props;

    if (!modal) {
      return null;
    }

    return (
      <Modal
        modal={modal}
        className={css.NoConnectionModal}
        translation={translation}
        contentClassName={css.content}
        overlayClassName={css.showNoConnectionModalAlwaysOnTop}
      >
        <div className={css.chairWrapper}>
          <div className={css.chair} />
        </div>
        <HeaderLevel3 className={css.header}>{translation.title}</HeaderLevel3>
        <div className={css.description}>{translation.content}</div>
        <TertiaryButton onClick={modal.refresh}>{translation.refreshButton}</TertiaryButton>
        <div className={css.or}>{translation.buttonSeparator}</div>
        <Link className={css.link} onClick={modal.close}>
          {translation.continueLink}
        </Link>
      </Modal>
    );
  }
}

export default NoConnectionModal;
