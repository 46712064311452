import Error404Image from '../../images/errors/error404.jpg';
import { GeneralErrorPageTranslation } from '../localization/SiteTranslation';
import ErrorPage from './ErrorPage';

interface PageNotFoundProps {
  translation: GeneralErrorPageTranslation;
  redirectUrl: string;
}

const PageNotFound = ({ translation, redirectUrl }: PageNotFoundProps) => {
  return (
    <ErrorPage
      title={translation.title}
      imageSrc={Error404Image}
      link={{
        label: translation.buttonLink,
        href: redirectUrl,
      }}
    >
      {translation.content}
    </ErrorPage>
  );
};

export default PageNotFound;
