import classNames from 'classnames';
import { observer } from 'mobx-react';

import FullPageLoader from '../shared/components/Loaders/FullPageLoader';
import Heading, { HeadingPosition, HeadingSize } from '../shared/components/Typography/Heading/Heading';
import ConfigurationCode from './components/ConfigurationCode/ConfigurationCode';
import RawConfigurator from './components/RawConfigurator/RawConfigurator';
import css from './EmbeddableRawConfiguratorPage.css';
import { EmbeddableRawConfiguratorPageState } from './EmbeddableRawConfiguratorPageState';

interface EmbeddableRawConfiguratorPageProps {
  state: EmbeddableRawConfiguratorPageState;
}

const EmbeddableRawConfiguratorPage = observer(({ state }: EmbeddableRawConfiguratorPageProps) => {
  if (state.isLoading) {
    return <FullPageLoader />;
  }

  return (
    <article
      className={classNames(css.root, {
        [css.embedded]: state.isEmbedded,
        [css.small]: state.configuratorState.size === 'small',
      })}
    >
      <Heading
        level={1}
        className={css.title}
        position={HeadingPosition.Center}
        size={HeadingSize.Large}
        visuallyHidden={state.isEmbedded}
      >
        Simplified configurator
      </Heading>
      <div className={css.content}>
        {!!state.errorMessage && <p className={css.error}>{state.errorMessage}</p>}
        {!!state.configuratorState.structure && (
          <>
            <RawConfigurator state={state.configuratorState} />
            <ConfigurationCode state={state.configurationCodeState} />
          </>
        )}
      </div>
    </article>
  );
});

export default EmbeddableRawConfiguratorPage;
