import { observer } from 'mobx-react';

import { OrderItemData, ShipmentData } from '../../../app/data/model';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import { TrackButtonLink } from '../../../app/shared/components/Button/CustomButtons';
import List from '../../../app/shared/components/List/List';
import Modal from '../../../app/shared/components/Modal/Modal';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { PropertyItem } from '../../../app/shared/components/PropertyList/PropertyItem';
import { PropertyItemVertical } from '../../../app/shared/components/PropertyList/PropertyItemVertical/PropertyItemVertical';
import HeaderLevel4 from '../../../app/shared/components/Typography/HeaderLevel4';
import { OrderShipmentPanelHeaderTranslation, OrderShipmentsListTranslation } from '../../localization/SiteTranslation';
import { OrderShipmentsModalState } from '../OrderState';
import css from './OrderShipmentsModal.css';

interface OrderShipmentsModalProps {
  state: OrderShipmentsModalState;
}

export const OrderShipmentsModal = ({ state }: OrderShipmentsModalProps) => {
  const translation = state.translation;
  return (
    <Modal modal={state} className={css.OrderShipmentsModal} translation={{ closeButton: translation.closeButton }}>
      <HeaderLevel4 className={css.orderShipmentsModalHeader}>{translation.header}</HeaderLevel4>
      <List
        items={state.shipments}
        itemClassName={css.orderShipmentsPanel}
        itemRenderer={(shipmentState) => (
          <Panel
            bodyClassName={css.orderShipmentsPanelContent}
            headerComponent={
              <OrderShipmentPanelHeader
                shipment={shipmentState.shipment}
                items={shipmentState.items}
                translation={translation.orderShipmentPanelHeader}
              />
            }
          >
            <OrderShipmentsList items={shipmentState.items} translation={translation.orderShipmentsList} />
          </Panel>
        )}
      />
    </Modal>
  );
};

export const OrderShipmentsList = observer(
  ({ items, translation }: { items: Array<OrderItemData>; translation: OrderShipmentsListTranslation }) => {
    return (
      <List
        items={items}
        itemClassName={css.orderShipmentsItem}
        itemRenderer={(item) => (
          <>
            <div className={css.productName}>
              <strong>{item.brandName}</strong> {item.shortName}
            </div>
            <PropertyItem
              label={translation.configurationCodeLabel}
              labelClassName={css.label}
              labelOptions={{ colorGrey: true }}
              value={item.configurationCode}
              valueClassName={css.value}
              valueOptions={{ alignLeft: true }}
            />
          </>
        )}
      />
    );
  },
);

export const OrderShipmentPanelHeader = observer(
  ({
    shipment,
    translation,
  }: {
    shipment: ShipmentData;
    items: OrderItemData[];
    translation: OrderShipmentPanelHeaderTranslation;
  }) => {
    return (
      <div className={css.OrderShipmentPanelHeader}>
        <div className={css.propertiesContainer}>
          <PropertyItemVertical label={translation.deliveryNumberLabel} value={shipment.number} />
          <PropertyItemVertical
            className={css.deliveryDate}
            label={translation.shippingDateLabel}
            value={formatISODateStringToDottedDate(shipment.deliveryDate) ?? 'Not defined'}
          />
        </div>
        <TrackButtonLink link={{ href: shipment.trackingUrl, target: 'blank_' }}>
          {translation.trackOrderButton}
        </TrackButtonLink>
      </div>
    );
  },
);
