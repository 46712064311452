import classnames from 'classnames';
import { useEffect, useRef } from 'react';
import Slider, { ResponsiveObject } from 'react-slick';

import Carousel, { CarouselProps } from '../../../../../shared/components/Carousel/Carousel';
import CarouselNavButton, {
  CarouselNavButtonProps,
  CarouselNavButtonType,
} from '../../../../../shared/components/Carousel/CarouselNavButton/CarouselNavButton';
import { ModelSelectorComponentProps } from '../../ModelSelector/ModelSelector';
import ModelSelectorItem from '../../ModelSelector/ModelSelectorItem/ModelSelectorItem';
import css from './ModelCarousel.css';

const CarouselArrow = ({ className, type, ...props }: CarouselNavButtonProps) => {
  return <CarouselNavButton className={classnames(css.navButton, className)} type={type} {...props} />;
};

const CAROUSEL_BREAKPOINTS = [
  { maxWidth: 1023, slidesToShow: 1, slidesToScroll: 1 },
  { maxWidth: 1199, slidesToShow: 3, slidesToScroll: 3 },
];

const CAROUSEL_SETTINGS: CarouselProps = {
  speed: 300,
  arrows: true,
  prevArrow: <CarouselArrow type={CarouselNavButtonType.Previous} />,
  nextArrow: <CarouselArrow type={CarouselNavButtonType.Next} />,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: CAROUSEL_BREAKPOINTS.map<ResponsiveObject>((x) => ({
    breakpoint: x.maxWidth,
    settings: {
      slidesToShow: x.slidesToShow,
      slidesToScroll: x.slidesToScroll,
    },
  })),
};

type ModelCarouselProps = ModelSelectorComponentProps;

const ModelCarousel = ({ onItemClick, state }: ModelCarouselProps) => {
  const ref = useRef<Slider>(null);

  useEffect(() => {
    if (ref?.current) {
      const indexOfActiveItem = state.items.findIndex((x) => x.isActive);

      if (indexOfActiveItem > 0) {
        ref.current.slickGoTo(indexOfActiveItem, true);
      }
    }
  }, []);

  return (
    <Carousel className={css.container} ref={ref} {...CAROUSEL_SETTINGS}>
      {state.items.map((item) => (
        <ModelSelectorItem item={item} onClick={onItemClick} key={item.data.id} />
      ))}
    </Carousel>
  );
};

export default ModelCarousel;
