import { nameOf } from './common';
import { QueryParser } from './QueryParser';

export class EmbeddedQuery {
  embed?: boolean = false;
}

export const EMBED = nameOf<EmbeddedQuery>('embed');

export class EmbeddedQueryParser extends QueryParser {
  static toModel(query: Map<string, string>) {
    const queryModel = new EmbeddedQuery();

    queryModel.embed = QueryParser.getValue(query, EMBED) === true.toString();

    return queryModel;
  }

  static toQuery(queryModel: EmbeddedQuery) {
    const query = new Map<string, string>();
    EmbeddedQueryParser.buildUpQuery(queryModel, query);

    return query;
  }

  static buildUpQuery(queryModel: EmbeddedQuery, query: Map<string, string>) {
    if (queryModel.embed) {
      QueryParser.setValue(query, EMBED, true.toString());
    }
  }
}
