import classNames from 'classnames';
import { observer } from 'mobx-react';

import css from './CloseButton.css';
import { CrossButton, CrossButtonProps } from './CustomButtons';

type CloseButtonProps = CrossButtonProps;

const CloseButton = ({ className, ...buttonProps }: CloseButtonProps) => (
  <CrossButton after className={classNames(css.CloseButton, className)} {...buttonProps} />
);

export default observer(CloseButton);
