import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { CssAnimation } from '../../../shared/animations';
import { AnimatedComponent, AnimatedComponentProps } from '../AnimatedComponent';
import css from './AnimatedChairSliderNavigation.css';

export class AnimatedChairSliderNavigationState {
  initAnimation: CssAnimation;
  initStyle: CssAnimation;

  constructor() {
    this.initAnimation = new CssAnimation();
    this.initStyle = new CssAnimation();
  }
}

interface AnimatedChairSliderNavigationProps extends AnimatedComponentProps {
  isSummaryTabActive: boolean;
}

@observer
export class AnimatedChairSliderNavigation extends Component<AnimatedChairSliderNavigationProps, {}> {
  constructor(props: AnimatedChairSliderNavigationProps) {
    super(props);
  }

  render() {
    return (
      <AnimatedComponent
        className={classNames(this.props.isSummaryTabActive && css.isSummaryTabActive)}
        {...this.props}
        css={css}
      />
    );
  }
}
