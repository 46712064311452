import RouteDefinitions from './RouteDefinitions';

const publicRoutes = [
  RouteDefinitions.homeRoute,
  RouteDefinitions.loginRoute,
  RouteDefinitions.invitationRoute,
  RouteDefinitions.handleInvitationCodeRoute,
  RouteDefinitions.handleAuthCodeRoute,
];

const routes = [
  ...publicRoutes,
  RouteDefinitions.dashboardRoute,
  RouteDefinitions.catalogRoute,
  RouteDefinitions.configuratorRoute,
  RouteDefinitions.cartRoute,
  RouteDefinitions.ordersRoute,
  RouteDefinitions.orderRoute,
  RouteDefinitions.quickOrderRoute,
  RouteDefinitions.favoritesRoute,
  RouteDefinitions.codeRoute,
  RouteDefinitions.profileRoute,
  RouteDefinitions.articleRoute,
  RouteDefinitions.configurationDetailsRoute,
];

export { publicRoutes, routes };
