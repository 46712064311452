import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import { GetConfigurationByCodeQuery } from '../data/model';
import { ConfiguratorUrlBuilder } from '../errorHandling/ParseConfigurationCodeAndRedirectState';
import { ConfigurationFinderTranslation } from '../localization/SiteTranslation';
import { AsyncCommand } from './common';
import { TextInput, ValueExactLength } from './Form';
import { INavigationService } from './NavigationService';
import { StatusCodes } from './StatusCodes';

class ConfigurationFinderState {
  @observable
  public errorMessage: string;

  public searchBar: TextInput;

  public searchCommand: AsyncCommand;

  public constructor(
    private readonly client: IApiClient,
    private readonly navigationService: INavigationService,
    private readonly translation: ConfigurationFinderTranslation,
  ) {
    makeObservable(this);
    this.searchBar = new TextInput().withRule(new ValueExactLength(6, translation.codeWithIncorrectLengthFormat));
    this.searchCommand = new AsyncCommand(this.goToConfigurator, this.searchBar);
  }

  @action.bound
  private async goToConfigurator() {
    const codeSent = this.searchBar.value;
    this.setErrorMessage('');

    try {
      const configuration = await this.client.send(new GetConfigurationByCodeQuery({ code: codeSent }));
      const url = ConfiguratorUrlBuilder.buildFromConfiguration(codeSent, configuration.productConfiguration);

      this.navigationService.navigateTo(url);
    } catch (error) {
      if (error.status) {
        if (error.status === StatusCodes.NotFound) {
          this.setErrorMessage(this.translation.codeNotFound.interpolate([['notFoundCode', codeSent]]));
          return;
        } else if (error.status === StatusCodes.BadRequest) {
          this.setErrorMessage(this.translation.codeInvalid.interpolate([['invalidCode', codeSent]]));
          return;
        }
      }

      throw error;
    }
  }

  @action
  private setErrorMessage(message: string) {
    this.errorMessage = message;
  }
}

export default ConfigurationFinderState;
