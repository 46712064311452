import { ImageData, LinkData } from '../../../../../../app/data/model';
import ExternalImage from '../../../../../../app/shared/components/ExternalImage/ExternalImage';
import { UnderlineLink } from '../../../../../../app/shared/components/UnderlineLink/UnderlineLink';
import css from './ArticleTeaser.css';

interface ArticleTeaserProps {
  readMoreTranslation: string;
  description: string;
  image: ImageData;
  link: LinkData;
  title: string;
}

const ArticleTeaser = ({ description, image, link, readMoreTranslation, title }: ArticleTeaserProps) => {
  return (
    <article className={css.container}>
      <h2 className={css.title}>{title}</h2>
      <div className={css.imageContainer}>
        {image && image.url && <ExternalImage className={css.image} src={image.url} alt={image.name} />}
      </div>
      {description && <p className={css.description}>{description}</p>}
      {link && (
        <UnderlineLink
          className={css.link}
          dark
          href={link.url}
          {...(link.openInNewTab && { target: '_blank' })}
          {...(link.noFollow && { rel: 'nofollow' })}
        >
          {readMoreTranslation}
        </UnderlineLink>
      )}
    </article>
  );
};

export default ArticleTeaser;
