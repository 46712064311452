import { observer } from 'mobx-react';
import { Component } from 'react';

import { CartItem } from '../CartItem/CartItem';
import { CartItemState } from '../CartItem/CartItemState';
import css from './CartItemList.css';

interface CartItemListProps {
  items: Array<CartItemState>;
}

@observer
export class CartItemList extends Component<CartItemListProps> {
  render() {
    const { items } = this.props;
    return (
      <ul>
        {items.map((cartItem) => (
          <li key={cartItem.item.id} className={css.cartItemContainer}>
            <CartItem state={cartItem} />
          </li>
        ))}
      </ul>
    );
  }
}
