import { action, makeObservable, observable } from 'mobx';

export interface ITab {
  id: string;
  title?: string;
  isActive: boolean;
}

export class Tab implements ITab {
  public id: string;
  public title: string;

  @observable
  public isActive: boolean;

  constructor(id: string, title: string = '', isActive: boolean = false) {
    makeObservable(this);
    this.id = id;
    this.isActive = isActive;
    this.title = title;
  }

  @action
  public setActive(id: boolean) {
    this.isActive = id;
  }
}
