import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import FormInput, { FormInputProps } from '../../../../shared/components/FormInput';
import { withIdPrefix } from './withIdPrefix';

type AddressLine2Props = FormInputProps;

@observer
class AddressLine2 extends Component<AddressLine2Props> {
  render() {
    const { className, label, inputClassName, ...otherProps } = this.props;

    return (
      <FormInput
        label={label || 'Address Line 2'}
        className={className}
        inputClassName={classNames(inputClassName)}
        {...otherProps}
      />
    );
  }
}

export default withIdPrefix(AddressLine2, 'addressLine2');
