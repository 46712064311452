import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { IApiClient } from '../data/client';
import {
  BaseModelData,
  GenerateConfigurationCodeCommand,
  GetProductQueryResponse,
  OptionType,
  PriceGroupData,
  ProductConfigurationData,
  ProductConfigurationOrigin,
  SelectedComponentData,
  ShoppingContext,
} from '../data/model';
import { ConfiguratorPageTranslation } from '../localization/SiteTranslation';
import { IFeature, IItem, parseFeatureCode } from '../shared/common';
import Logger from '../shared/Logger';
import { ResolveRestrictionsModalState, RestrictionsFactory, RestrictionsResolver } from '../shared/restrictions';
import { RunLastTasksScheduler } from '../shared/TasksScheduler';
import { AccessoryState } from './components/AccessoriesConfigurator/AccessoryState';
import { TabsState } from './components/ConfiguratorTabs/TabsState';
import { TabState } from './components/ConfiguratorTabs/TabState';
import { ObsoleteConfigurationModalState } from './components/ObsoleteConfigurationModal/ObsoleteConfigurationModalState';
import { OptionDescriptionModalState } from './components/OptionDescriptionModal/OptionDescriptionModalState';
import { FabricOptionState, PriceGroupDeterminantMode } from './FabricOptionState';
import { GenerateCodeTask } from './GenerateCodeTask';
import IConfigurationPageQuery from './IConfigurationPageQuery';
import { KindOfFabricCoordinatorFactory } from './KindOfFabricCoordinator';
import { KindOfFabricRestrictionsCoordinatorFactory } from './KindOfFabricRestrictionsCoordinator';
import { IOptionState, OptionState, SelectedOptionVariation } from './OptionState';
import { RestoringConsolidatedOptionStateStrategy } from './RestoringConsolidatedOptionStateStrategy';

export interface SelectedFeature {
  feature: IFeature;
  item: IItem;
  tabCode: string;
}

export interface SelectedFeatures {
  options: SelectedFeature[];
  accessories: SelectedFeature[];
  all: SelectedFeature[];
}

export class ConfiguratorCoreState {
  @observable brandName: string;
  @observable code: string;
  @observable tabs: TabsState;

  public accessories: AccessoryState[];
  public baseModel: BaseModelData;
  public options: IOptionState[];

  public obsoleteConfigurationModal = new ObsoleteConfigurationModalState(this.client);
  public optionDescriptionModal = new OptionDescriptionModalState();
  public restrictionsModal = new ResolveRestrictionsModalState();

  private generateCodeScheduler = new RunLastTasksScheduler((task: GenerateCodeTask) => this.generateCode(task));
  private productResponse: GetProductQueryResponse;
  private restrictionsResolver: RestrictionsResolver;
  private shoppingContext: ShoppingContext;

  @computed
  public get selectedAccessories() {
    const selected = Array<AccessoryState>();

    this.tabs.dynamicTabs.forEach((tab) => {
      const { accessories } = tab;

      accessories
        .filter((option) => option.enabled)
        .filter((x) => x.selected)
        .forEach((x) => {
          selected.push(x);
        });
    });

    return selected;
  }

  @action.bound
  public updateCode(value: string) {
    this.code = value;
  }

  @computed
  public get selectedFeaturesVisuallyGrouped(): SelectedFeatures {
    const selectedFeatures = this.tabs.dynamicTabs.mapMany((tab) =>
      tab.features
        .map((feature) => {
          return { tabCode: tab.code, item: feature.getSelectedItem(), feature: feature };
        })
        .filter((x) => !!x.item && x.feature.enabled),
    );

    const selectedAccessories = selectedFeatures.filter((x) => x.tabCode === 'accessories');
    const selectedOptions = selectedFeatures.exclude(selectedAccessories);

    return {
      all: selectedFeatures,
      options: selectedOptions,
      accessories: selectedAccessories,
    };
  }

  @computed
  public get unitPriceWithoutAccessories() {
    let total = { ...this.baseModel.price };

    const selectedPriceGroups = this.selectedPriceGroups;
    if (selectedPriceGroups.any()) {
      total.amount += this.selectedPriceGroups.sum((x) => x.price.amount);
    } else if (this.selectedPriceGroup) {
      total.amount += this.selectedPriceGroup.price.amount;
    }

    this.selectedFeaturesVisuallyGrouped.options
      .filter((x) => !!x.item.price)
      .forEach((x) => {
        total.amount += x.item.price.amount;
      });

    return total;
  }

  @computed
  public get unitPriceWithoutAccessoriesAndDependentFeatures() {
    let total = { ...this.baseModel.price };

    if (this.selectedOptionPriceGroupsNotDependentOnAccessories.any()) {
      total.amount += this.selectedOptionPriceGroupsNotDependentOnAccessories.sum((x) => x.price.amount);
    } else if (this.selectedPriceGroup) {
      total.amount += this.selectedPriceGroup.price.amount;
    }

    this.selectedFeaturesVisuallyGrouped.options
      .filter((x) => !!x.item.price)
      .forEach((x) => {
        total.amount += x.item.price.amount;
      });

    return total;
  }

  @computed
  public get baseModelAndOptionsUnitPriceV2() {
    const price = { ...this.baseModel.price };

    if (
      this.selectedPriceGroups.empty() && // Fallback to price groups V1
      this.selectedPriceGroup
    ) {
      price.amount += this.selectedPriceGroup.price.amount;
    }

    price.amount += this.selectedFeaturesVisuallyGrouped.options.sum((x) => x.feature.price.amount);

    return price;
  }

  @computed
  public get accessoriesUnitPrice() {
    return this.selectedFeaturesVisuallyGrouped.accessories
      .filter((x) => !!x.item.price)
      .sum((x) => x.item.price.amount);
  }

  @computed
  public get accessoriesUnitPriceV2() {
    const price = { amount: 0, currency: this.baseModel.price.currency };

    price.amount += this.selectedFeaturesVisuallyGrouped.accessories.sum((x) => x.feature.price.amount);

    return price;
  }

  @computed
  public get configurationCodeLoading() {
    return this.generateCodeScheduler.loading;
  }

  @computed
  public get configurationCodeError() {
    return this.generateCodeScheduler.error;
  }

  @computed
  public get unitPrice() {
    const total = { ...this.unitPriceWithoutAccessories };

    total.amount += this.accessoriesUnitPrice;

    return total;
  }

  @computed
  public get unitPriceV2() {
    const price = { ...this.baseModelAndOptionsUnitPriceV2 };

    price.amount += this.accessoriesUnitPriceV2.amount;

    return price;
  }

  @computed
  public get selectedPriceGroup(): PriceGroupData | undefined {
    const fabricOptions = this.tabs.dynamicTabs
      .mapMany((tab) => [...tab.options])
      .filter((x) => x.type === OptionType.Fabric && x.enabled)
      .map((x) => x as FabricOptionState);

    const priceGroupOptionDeterminant = fabricOptions.find((x) => x.data.determinesPriceGroup);
    if (priceGroupOptionDeterminant?.selectedGroup) {
      return priceGroupOptionDeterminant.selectedGroup.data;
    }

    return undefined;
  }

  @computed
  public get selectedPriceGroups() {
    return this.tabs.dynamicTabs
      .mapMany((tab) => [...tab.options])
      .filter((x) => x.enabled)
      .filter((x) => x.type === OptionType.Fabric)
      .map((x) => x as FabricOptionState)
      .filter((x) => x.data.priceGroups.any())
      .map((x) => x.selectedGroup.data);
  }

  @computed
  private get selectedOptionPriceGroupsNotDependentOnAccessories() {
    const { accessories } = this.selectedFeaturesVisuallyGrouped;

    return this.selectedPriceGroups.filter(
      (priceGroup) =>
        !accessories.some(
          (accessory) =>
            accessory.feature instanceof FabricOptionState &&
            accessory.feature.selectedGroup?.data.id === priceGroup.id,
        ),
    );
  }

  @computed
  public get selectedOptionVariations(): SelectedOptionVariation[] {
    const selectedOptionVariationsPerTab = this.tabs.dynamicTabs.map((tab) =>
      tab.options
        .filter((option) => option.enabled)
        .filter((option) => !!option.selected)
        .map((option) => {
          return {
            option: option,
            variation: option.selected,
          };
        }),
    );

    return [].concat(...selectedOptionVariationsPerTab);
  }

  public constructor(private readonly client: IApiClient, public readonly translation: ConfiguratorPageTranslation) {
    makeObservable(this);

    this.code = '000000';
  }

  @action
  public initialize(
    response: GetProductQueryResponse,
    code: string,
    activeTab: string,
    additionalTabs: TabState[] = [],
    shoppingContext: ShoppingContext,
  ) {
    const { baseModel } = response;
    this.productResponse = response;
    this.baseModel = baseModel;
    this.brandName = baseModel.brandName;
    this.code = code;
    this.shoppingContext = shoppingContext;

    this.initializeOptionsAndAccessories();

    this.tabs = new TabsState(
      response.tabs,
      activeTab,
      this.options,
      this.accessories,
      this.translation,
      additionalTabs,
      baseModel.brandId,
    );

    this.generateCodeOnConfigurationChange();
  }

  @action
  public initializeKindOfFabricCordinators(response: GetProductQueryResponse) {
    KindOfFabricCoordinatorFactory.createMany(this.options);
    KindOfFabricRestrictionsCoordinatorFactory.create(this.options, response.kindOfFabricRestrictions);
  }

  public getProductConfiguration(quantity: number = 1) {
    const options = new Array<SelectedComponentData>();
    const accessories = new Array<SelectedComponentData>();
    const priceGroups = new Array<SelectedComponentData>();

    this.selectedOptionVariations.forEach((x) => {
      options.push({ id: x.variation.data.id, componentCode: x.option.code, variationCode: x.variation.data.code });
      x.variation.data.origin.forEach((y) => {
        options.push({ id: y.variationId, componentCode: y.optionCode, variationCode: y.variationCode });
      });
      if (x.variation.priceGroup) {
        priceGroups.push({
          id: x.variation.priceGroup.id,
          componentCode: x.variation.priceGroup.code,
          variationCode: x.variation.priceGroup.variationCode,
        });
      }
    });

    this.productResponse.hidden.forEach((x) => {
      options.push({ id: x.variationId, componentCode: x.optionCode, variationCode: x.variationCode });
    });

    this.selectedAccessories.forEach((x) => {
      accessories.push({ id: x.data.id, componentCode: x.code, variationCode: x.data.variationCode });
    });

    const configuration: ProductConfigurationData = {
      baseModelId: this.baseModel.id,
      baseModelCode: this.baseModel.code,
      priceGroupId: null,
      options: options,
      accessories: accessories,
      quantity,
      priceGroups: priceGroups,
      origin: ProductConfigurationOrigin.DefaultConfigurator,
    };

    if (this.selectedPriceGroups.any()) {
      configuration.priceGroups = configuration.priceGroups.concat(
        this.selectedPriceGroups.map((x) => {
          return { id: x.id, componentCode: x.code, variationCode: x.variationCode };
        }),
      );
    } else if (this.selectedPriceGroup) {
      configuration.priceGroupId = this.selectedPriceGroup.id;
    }

    return configuration;
  }

  public ensureCodeIsInSyncWithSelectedOptionsAndAccessories(): Promise<void> {
    return this.executeGenerateCodeTask(this.getProductConfiguration());
  }

  public restoreState(code: string, pageQuery: IConfigurationPageQuery) {
    // TODO: make more generic? not related to query?
    const hiddenComponents = this.productResponse.hiddenConsolidated
      .map((x) => x.id)
      .concat(this.productResponse.hidden.map((x) => x.variationId));

    const visibleFeaturesCodes = this.tabs.dynamicTabs.mapMany((tab) => tab.features.map((feature) => feature.id));
    const visibleOptionsFromQuery = pageQuery.options.filter((x) => visibleFeaturesCodes.contains(parseFeatureCode(x)));
    const visibleAccessoriesFromQuery = pageQuery.accessories.filter((x) => !hiddenComponents.contains(x));
    const visibleComponentsFromQuery = visibleOptionsFromQuery.concat(visibleAccessoriesFromQuery);

    const items = this.tabs.dynamicTabs.mapMany((tab) =>
      tab.features.mapMany((feature) => feature.getAllItems().filter((x) => visibleComponentsFromQuery.contains(x.id))),
    );

    const itemsToCheck = items.concat(this.getDependentItemsWhichAreNotSpecified(items));

    const restrictionsViolations = this.restrictionsResolver.restrictionsEvaluator.checkRestrictionsFor(itemsToCheck);
    if (restrictionsViolations.any()) {
      this.displayInvalidConfigurationModal(code);
      return;
    }

    const notSelectedOptionsIds = visibleOptionsFromQuery.filter((id) => !this.tabs.trySelectOptionUsingId(id));
    const notSelectedAccessoriesIds = visibleAccessoriesFromQuery.filter(
      (id) => !this.tabs.trySelectAccessoryUsingId(id),
    );

    const strategy = new RestoringConsolidatedOptionStateStrategy(this.productResponse);
    const selectedConsolidatedOptionsIds = strategy.selectConsolidateOptionsNotSpecifiedInQuery(this.tabs, pageQuery);

    const notSelectedComponentsIds = notSelectedOptionsIds
      .exclude(selectedConsolidatedOptionsIds)
      .concat(notSelectedAccessoriesIds);

    if (notSelectedComponentsIds.any() && !pageQuery.switch) {
      this.displayObsoleteComponentsModal(code, notSelectedComponentsIds);
    }
  }

  public get selectedComponents() {
    const configuration = this.getProductConfiguration();
    const components = new Map<string, string>();

    configuration.options.forEach((x) => {
      components.set(x.componentCode, x.variationCode);
    });

    this.selectedAccessories.forEach((x) => {
      components.set(x.data.code, x.data.variationCode);
    });

    return components;
  }

  private executeGenerateCodeTask(configuration: ProductConfigurationData) {
    const task = new GenerateCodeTask(configuration);
    return this.generateCodeScheduler.run(task);
  }

  private async generateCode(task: GenerateCodeTask) {
    const command = new GenerateConfigurationCodeCommand({
      productConfiguration: task.configuration,
    });

    try {
      const response = await this.client.send(command);

      if (task.cancelled || !response) {
        return;
      }

      this.updateCode(response.code);
    } catch (error) {
      Logger.exception('Error occurred while generating configuration code', error);
      throw error;
    }
  }

  private generateCodeOnConfigurationChange() {
    reaction(
      () => this.getProductConfiguration(),
      (configuration) => this.executeGenerateCodeTask(configuration),
    );
  }

  private initializeOptionsAndAccessories() {
    this.restrictionsResolver = new RestrictionsResolver(new RestrictionsFactory(), this.restrictionsModal);

    const fabricOptions = this.productResponse.options.filter((option) => option.type === OptionType.Fabric);
    const determinantMode = fabricOptions.any((option) => option.priceGroups.any())
      ? PriceGroupDeterminantMode.PriceGroupsArray
      : PriceGroupDeterminantMode.DeterminantFlag;

    this.options = this.productResponse.options.map((option) => {
      if (option.type === OptionType.Fabric) {
        return new FabricOptionState(
          option,
          determinantMode,
          this.productResponse.priceGroups,
          this.restrictionsResolver,
          this.optionDescriptionModal,
          this.translation.options.fabricFilters,
          this.productResponse.fabricCollections,
        );
      }
      return new OptionState(option, this.restrictionsResolver, this.optionDescriptionModal);
    });

    this.accessories = this.productResponse.accessories.map((x) => new AccessoryState(x, this.restrictionsResolver));

    autorun(() => {
      const priceGroupNumber = this.selectedPriceGroup?.number;
      if (priceGroupNumber) {
        this.options
          .filter((x) => x instanceof OptionState)
          .forEach((x) => (x as OptionState).selectPriceGroup(priceGroupNumber)); // TODO: better filtering
        this.accessories.forEach((x) => x.selectPriceGroup(priceGroupNumber));
      }
    });

    this.restrictionsResolver.finalizeRegistration();
    this.restrictionsResolver.setEnablingItems(this.options, this.accessories);
  }

  private getDependentItemsWhichAreNotSpecified(items: IItem[]) {
    const definedFeaturesCodes = items.map((x) => parseFeatureCode(x.id));
    return items
      .mapMany((x) => x.dependentFeatures)
      .filter((x) => !definedFeaturesCodes.contains(x.id))
      .map((x) => x.getSelectedItem())
      .filter((x) => !!x);
  }

  private displayObsoleteComponentsModal(code: string, notSelectedComponentsIds: string[]) {
    this.obsoleteConfigurationModal.initialize(code, this.shoppingContext, notSelectedComponentsIds);
    this.obsoleteConfigurationModal.open();

    Logger.warn(
      `Configuration with code '${code}' could not be restored properly. Missing components were not found: '${notSelectedComponentsIds.join(
        ', ',
      )}'.`,
    );
  }

  private displayInvalidConfigurationModal(code: string) {
    this.obsoleteConfigurationModal.setCode(code);
    this.obsoleteConfigurationModal.setInvalidConfiguration(true);
    this.obsoleteConfigurationModal.open();
    Logger.warn(`Configuration with code '${code}' could not be restored properly dues to restrictions violation.`);
  }
}
