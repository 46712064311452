import '@babylonjs/core/Helpers/sceneHelpers';
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import '@babylonjs/core/Loading/loadingScreen';
import { Scene } from '@babylonjs/core/scene';
import SceneComponent from 'babylonjs-hook';
import classNames from 'classnames';
import * as React from 'react';

import css from './Viewer3d.css';

export interface Viewer3dProps {
  sceneConfiguration: (scene: Scene) => Promise<void>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  viewerClassName?: string;
}

const Viewer3d = ({ children, style, sceneConfiguration, viewerClassName }: Viewer3dProps) => {
  const onSceneReady = (scene: Scene) => {
    sceneConfiguration(scene);
    scene.onReadyObservable.addOnce(() => scene.getEngine().resize());
  };

  return (
    <div className={classNames(css.viewer3dContainer, viewerClassName)} style={style}>
      {children}
      <SceneComponent onSceneReady={onSceneReady} id={css.babylonJsCanvas} antialias observeCanvasResize={false} />
    </div>
  );
};

export default Viewer3d;
