import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { ScrollToAnimation } from '../../../app/shared/animations';
import ActionButton from '../../../app/shared/components/ActionButton';
import SecondaryButton from '../../../app/shared/components/Button/SecondaryButton';
import TertiaryButton from '../../../app/shared/components/Button/TertiaryButton';
import Checkbox from '../../../app/shared/components/Checkbox/Checkbox';
import FormInput from '../../../app/shared/components/FormInput';
import { InputElementInvalid } from '../../../app/shared/components/Input/InputElement';
import LanguageSelector from '../../../app/shared/components/LanguageSelector/LanguageSelector';
import Link from '../../../app/shared/components/Link';
import List from '../../../app/shared/components/List/List';
import Modal from '../../../app/shared/components/Modal/Modal';
import HeaderLevel3 from '../../../app/shared/components/Typography/HeaderLevel3';
import HeaderLevel4 from '../../../app/shared/components/Typography/HeaderLevel4';
import { CheckboxInput } from '../../../app/shared/Form';
import { EmailNotificationsTranslation, PasswordResetBlockTranslation } from '../../localization/SiteTranslation';
import { toEmailNotification } from '../EmailNotification';
import css from './PersonalTab.css';
import { NotificationSubscriptionState, PersonalTabState } from './PersonalTabState';

interface PersonalTabProps {
  state: PersonalTabState;
}

@observer
export class PersonalTabContent extends Component<PersonalTabProps> {
  private validationTriggered = false;

  constructor(props: PersonalTabProps) {
    super(props);
    reaction(
      (_) => props.state.basicInfoForm.isValid,
      (x) => !x && this.scrollToFirstInvalidInput(),
    );
  }

  // TODO create HOC - to reuse it?
  scrollToFirstInvalidInput() {
    if (!this.validationTriggered) {
      setTimeout(() => new ScrollToAnimation(InputElementInvalid, false).start(), 0);
    }
    this.validationTriggered = true;
  }

  render() {
    const {
      basicInfoForm: { firstNameInput, lastNameInput, emailInput, phoneNumberInput, languageSelector },
      subscriptions,
      saveChangesCommand,
      passwordResetEnabled,
      translation,
    } = this.props.state;

    return (
      <form>
        <div className={css.formContainer}>
          <HeaderLevel3 className={css.containerHeader}>{translation.basicInformationHeader}</HeaderLevel3>

          <div className={css.formInputs}>
            <FormInput input={firstNameInput} label={translation.firstNameLabel} />
            <FormInput input={lastNameInput} label={translation.lastNameLabel} />
            <FormInput input={emailInput} label={translation.emailLabel} disabled />
            <FormInput input={phoneNumberInput} label={translation.phoneNumberLabel} className={css.phoneNumberInput} />
            <LanguageSelector state={languageSelector} label={translation.languageSelectorLabel} />
          </div>
        </div>

        {passwordResetEnabled && (
          <PasswordResetBlock state={this.props.state} translation={translation.passwordResetBlock} />
        )}

        <div className={css.formContainer}>
          <HeaderLevel3 className={css.containerHeader}>{translation.emailNotificationsHeader}</HeaderLevel3>
          <EmailNotificationCheckboxes subscriptions={subscriptions} translation={translation.emailNotifications} />
        </div>

        <div className={css.actionButtonContainer}>
          <ActionButton command={saveChangesCommand} buttonElement={SecondaryButton} className={css.actionButton}>
            {translation.saveChangesButton}
          </ActionButton>
        </div>
      </form>
    );
  }
}

interface EmailNotificationCheckboxesProps {
  subscriptions: NotificationSubscriptionState[];
  translation: EmailNotificationsTranslation;
}

function localizeEmailNotification(emailNotificationStr: string, translation: EmailNotificationsTranslation) {
  const key = toEmailNotification(emailNotificationStr).uncapitalize() as keyof EmailNotificationsTranslation;
  return translation[key];
}

const EmailNotificationCheckboxes = ({ subscriptions, translation }: EmailNotificationCheckboxesProps) => {
  return (
    <>
      <List
        items={subscriptions}
        itemRenderer={(item) => (
          <EmailNotificationCheckbox
            label={localizeEmailNotification(item.subscription.notificationType, translation)}
            input={item.subscriptionEnabled}
          />
        )}
        className={css.emailNotificationList}
        itemClassName={css.emailNotificationItem}
      />
    </>
  );
};

const EmailNotificationCheckbox = ({
  className,
  input,
  label,
}: {
  className?: string;
  input: CheckboxInput;
  label: string;
}) => (
  <Checkbox input={input} size="large" className={className} labelClassName={css.checkboxLabel}>
    {label}
  </Checkbox>
);

const PasswordResetBlock = observer(
  ({ state, translation }: { state: PersonalTabState; translation: PasswordResetBlockTranslation }) => (
    <div className={css.formContainer}>
      <HeaderLevel3 className={css.containerHeader}>{translation.passwordResetHeader}</HeaderLevel3>

      {state.resetPasswordEmailSent && (
        <>
          <div>{translation.emailSentText}</div>
          <Link onClick={state.passwordResetConfirmationModal.open} className={css.passwordResetLink}>
            {translation.sendPasswordResetEmailAgainText}
          </Link>
        </>
      )}
      {!state.resetPasswordEmailSent && (
        <Link onClick={state.passwordResetConfirmationModal.open} className={css.passwordResetLink}>
          {translation.sendPasswordResetEmailText}
        </Link>
      )}

      <Modal modal={state.passwordResetConfirmationModal} contentClassName={css.modalContent}>
        <HeaderLevel4>{translation.confirmPasswordResetHeader}</HeaderLevel4>
        <p className={css.modalDescription}>{translation.areYouSureResetPasswordText}</p>

        <div className={css.modalButtonContainer}>
          <TertiaryButton onClick={state.passwordResetConfirmationModal.close}>
            {translation.cancelButton}
          </TertiaryButton>
          <ActionButton
            className={css.confirmationButton}
            buttonElement={SecondaryButton}
            command={state.passwordResetConfirmationModal.sendPasswordResetEmailCommand}
          >
            {translation.yesButton}
          </ActionButton>
        </div>
      </Modal>
    </div>
  ),
);
