import classNames from 'classnames';
import { Component } from 'react';

import { FinalVisualizationBuilder } from '../../../configurator/VisualizationBuilder';
import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import { LoadableImage } from '../../../shared/components/LoadableImage';
import HeaderLevel4 from '../../../shared/components/Typography/HeaderLevel4';
import { Subtitle2DarkGrey } from '../../../shared/components/Typography/Subtitle';
import css from './SummaryItem.css';

interface SummaryItemProps {
  className?: string;
  item: {
    imageUrlOverride?: string;
    quantity?: string;
    code: string;
    name: string;
  };
  translation: ShoppingCartSummaryTranslation;
}

export class SummaryItem extends Component<SummaryItemProps, {}> {
  renderQuantity() {
    const { item, translation } = this.props;
    if (item.quantity) {
      return (
        <div className={css.quantityContainer}>
          <span className={css.text}>{translation.quantityText}: </span>
          <span className={css.quantity}>{item.quantity.toString()}</span>
        </div>
      );
    }
  }

  render() {
    const { item, translation, className } = this.props;

    return (
      <div className={classNames(css.SummaryItem, className)}>
        <div className={css.imageWrapper}>
          <LoadableImage
            className={css.image}
            src={item.imageUrlOverride || FinalVisualizationBuilder.buildUrl(item.code)}
            width={100}
            height={150}
          />
        </div>
        <div className={css.textWrapper}>
          <HeaderLevel4 className={css.itemName}>{item.name}</HeaderLevel4>
          <Subtitle2DarkGrey>
            {translation.configurationCodeLabel}: {item.code}
          </Subtitle2DarkGrey>

          {this.renderQuantity()}
        </div>
      </div>
    );
  }
}
