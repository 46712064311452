import { BaseRequestTelemetryCollector } from './BaseRequestTelemetryCollector';
import { DependencyTelemetry } from './IMonitoringService';

export class DependencyTelemetryCollector extends BaseRequestTelemetryCollector {
  private url: string;
  private method: string;

  constructor(url: string, method: string) {
    super();
    this.url = url;
    this.method = method;
  }

  getTelemetry(): DependencyTelemetry {
    return {
      method: this.method,
      url: this.url,
      pathName: this.url, // TODO: get path name
      duration: this.duration,
      resultCode: this.resultCode,
      success: this.success,
    };
  }
}
