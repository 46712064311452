import classNames from 'classnames';
import { Fragment } from 'react';

import { MoneyData } from '../../../data/model';
import Price from '../../../shared/components/Price';
import HeaderLevel4 from '../../../shared/components/Typography/HeaderLevel4';
import css from './TotalToPay.css';

interface TotalToPayProps {
  price: MoneyData;
  translation: string;
  className?: string;
}

export const TotalToPay = ({ price, translation, className }: TotalToPayProps) => (
  <HeaderLevel4 className={classNames(css.priceTotalContainer, className)}>
    <Fragment>
      {translation}
      <Price className={css.priceTotal} price={price} />
    </Fragment>
  </HeaderLevel4>
);
