import { observer } from 'mobx-react';

import { TextInput } from '../../Form';
import InputElement, { InputElementProps } from './InputElement';

export interface InputFieldProps extends InputElementProps {
  input: TextInput;
}

const InputField = (props: InputFieldProps) => {
  const { input, ...otherProps } = props;
  return (
    <InputElement
      value={input.value}
      onChange={(e) => input.onChange(e.target.value)}
      type={props.type || 'text'}
      maxLength={input.maxLength}
      {...otherProps}
    />
  );
};

export default observer(InputField);
