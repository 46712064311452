declare global {
  interface Array<T> {
    any(predicate?: (x: T) => boolean): boolean;

    all(predicate?: (x: T) => boolean): boolean;

    empty(predicate?: (x: T) => boolean): boolean;

    contains(x: T): boolean;

    mapMany<V>(selector: (x: T) => Array<V>): Array<V>;

    sum(selector: (x: T) => number): number;

    distinct(): Array<T>;

    exclude(items: Array<T>): Array<T>;

    intersection(items: Array<T>): Array<T>;

    remove(x: T): void;

    first(): T;

    last(): T;

    take(n: number): Array<T>;

    skip(n: number): Array<T>;

    flat(): Array<T>;

    groupBy<T>(selector: (x: T) => string | number): { [key: string]: T[] };
  }
}

if (!Array.prototype.any) {
  Array.prototype.any = function <T>(predicate: (x: T) => boolean = (x) => true): boolean {
    for (let i = 0; i < this.length; i++) {
      const item = this[i];
      if (predicate(item)) {
        return true;
      }
    }
    return false;
  };
}

if (!Array.prototype.all) {
  Array.prototype.all = function <T>(predicate: (x: T) => boolean = (x) => true): boolean {
    for (let i = 0; i < this.length; i++) {
      const item = this[i];
      if (!predicate(item)) {
        return false;
      }
    }
    return true;
  };
}

if (!Array.prototype.empty) {
  Array.prototype.empty = function <T>(predicate: (x: T) => boolean = (x) => true): boolean {
    return this.filter((x: T) => predicate(x)).length === 0;
  };
}

if (!Array.prototype.contains) {
  Array.prototype.contains = function <T>(x: T): boolean {
    return this.indexOf(x) >= 0;
  };
}

if (!Array.prototype.mapMany) {
  Array.prototype.mapMany = function <T, V>(selector: (x: T) => Array<V>): Array<V> {
    return this.reduce((x: Array<V>, y: T) => x.concat(selector(y)), []);
  };
}

if (!Array.prototype.sum) {
  Array.prototype.sum = function <T>(selector: (x: T) => number): number {
    let result = 0;
    this.forEach((x: T) => {
      result += selector(x);
    });
    return result;
  };
}

if (!Array.prototype.distinct) {
  Array.prototype.distinct = function <T>(): Array<T> {
    return this.filter((x: T, index: number) => this.indexOf(x) === index);
  };
}

if (!Array.prototype.exclude) {
  Array.prototype.exclude = function <T>(items: Array<T>): Array<T> {
    return this.filter((x: T) => !items.contains(x));
  };
}

if (!Array.prototype.intersection) {
  Array.prototype.intersection = function <T>(items: Array<T>): Array<T> {
    return this.filter((x: T) => items.contains(x));
  };
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function <T>(x: T): void {
    const index = this.indexOf(x);
    if (index >= 0) {
      this.splice(index, 1);
    }
  };
}

if (!Array.prototype.first) {
  Array.prototype.first = function <T>(): T {
    return this.any() ? this[0] : null;
  };
}

if (!Array.prototype.last) {
  Array.prototype.last = function <T>(): T {
    return this.any() ? this[this.length - 1] : null;
  };
}

if (!Array.prototype.take) {
  Array.prototype.take = function <T>(n: number): Array<T> {
    if (this.length <= 0) {
      return [];
    }
    return this.slice(0, n);
  };
}

if (!Array.prototype.skip) {
  Array.prototype.skip = function <T>(n: number): Array<T> {
    if (this.length <= 0) {
      return [];
    }
    return this.slice(n, this.length);
  };
}

if (!Array.prototype.flat) {
  Array.prototype.flat = function <T>(): Array<T> {
    return [].concat(...this);
  };
}

if (!Array.prototype.groupBy) {
  Array.prototype.groupBy = function <T>(selector: (x: T) => string | number): { [key: string]: T[] } {
    return this.reduce((x: { [key: string]: T[] }, y: T) => {
      const el = selector(y);
      x[el] = !x[el] ? [y] : [...x[el], y];
      return x;
    }, {});
  };
}

export default {};
