import * as React from 'react';

import { GlobalShoppingContext } from '../../app/context/ShoppingContext';
import { IPolicy } from '../Policy';

type AuthoriseProps = React.PropsWithChildren<{
  policy: IPolicy;
}>;

export const Authorize = ({ policy, children }: AuthoriseProps) => {
  const shoppingContext = React.useContext(GlobalShoppingContext);

  if (!shoppingContext.customer || shoppingContext.customer.isRegistered === false) {
    return null;
  }

  if (policy.grantAccess(shoppingContext.customer)) {
    return <>{children}</>;
  }

  return null;
};
