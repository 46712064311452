import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { IApiClient } from '../../app/data/client';
import { AssociateUserIdentityCommand, AssociateUserIdentityCommandResponse } from '../../app/data/model';
import Logger from '../../app/shared/Logger';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreState } from '../../app/StoreState';
import { AuthenticationFlowError } from './AuthenticationFlowError';
import { BaseOAuthHandlerState } from './BaseOAuthHandlerState';
import { HttpErrorCode } from './HandleAuthCodeState';

export class HandleInvitationCodeState extends BaseOAuthHandlerState {
  constructor(
    private readonly client: IApiClient,
    navigation: INavigationService,
    authentication: IAuthenticationService,
  ) {
    super(navigation, authentication);
  }

  async onLoad(store: StoreState) {
    const query = this.navigation.currentUrl.query;

    const code = query.get('code');
    const state = query.get('state');

    try {
      if (code && state) {
        await this.processCodeStateStep();
      } else {
        Logger.error(`Url does not contain code or state parameter. Code: ${code} | State: ${state}`);
        this.setError('Invalid request', HttpErrorCode.BadRequest);
      }
    } catch (e) {
      this.handleAuthFlowError(e);
    }
  }

  private processCodeStateStep = async () => {
    const redirectResponse = await this.handleAuthorizationCodeRedirect();
    const user = await this.authentication.getUser();

    // There user should be always authenticated. If he is not then we cannot do anything resonable.
    if (!user) {
      Logger.error('Unauthenticated user accessed process code step in invitation flow.');
      throw new AuthenticationFlowError('User not authenticated', HttpErrorCode.InternalServerError);
    }

    const { token }: { token: string } = redirectResponse.appState;
    const { storeId, languageCode } = await this.assosiateUserIdentity(token, user.id);

    Logger.log(`User ${user.id} successfully associated. Navigating to home page.`);
    this.navigateToHomePage(storeId, languageCode);
  };

  private assosiateUserIdentity = async (
    token: string,
    userExternalId: string,
  ): Promise<AssociateUserIdentityCommandResponse> => {
    Logger.log(`Associating user identity for invitation token: ${token}`);

    try {
      const response = await this.client.send(
        new AssociateUserIdentityCommand({
          invitationToken: token,
          userExternalId: userExternalId,
        }),
      );

      return response;
    } catch (e) {
      Logger.exception(`Cannot associate invitation token ${token} and userExternalId ${userExternalId}`, e);
      throw new AuthenticationFlowError(
        'Unable to associate user identity with Flokk HUB invitation',
        HttpErrorCode.InternalServerError,
      );
    }
  };
}
