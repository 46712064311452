import classNames from 'classnames';
import { Component, Fragment } from 'react';

import { ProductFamilyTranslation } from '../localization/SiteTranslation';
import Icon from '../shared/components/Icon/Icon';
import Image from '../shared/components/Image/Image';
import Link from '../shared/components/Link';
import List from '../shared/components/List/List';
import { Subtitle2DarkGrey } from '../shared/components/Typography/Subtitle';
import { VisualizationAspectRatio } from '../shared/visualization';
import { ProductFamilyState } from './CatalogPageState';
import css from './ProductFamily.css';

interface ProductFamilyProps {
  item: ProductFamilyState;
  translation: ProductFamilyTranslation;
}

export default class ProductFamily extends Component<ProductFamilyProps, { showMore: boolean }> {
  constructor(props: ProductFamilyProps) {
    super(props);

    this.showFamilyInfo = this.showFamilyInfo.bind(this);
    this.hideFamilyInfo = this.hideFamilyInfo.bind(this);
    this.toggleFamilyInfo = this.toggleFamilyInfo.bind(this);
    this.state = { showMore: false };
  }

  get secondaryModels() {
    const { models, url } = this.props.item;

    if (models.first()?.url === url) {
      return models.slice(1);
    }

    return models;
  }

  showFamilyInfo() {
    this.setState({ showMore: true });
  }

  hideFamilyInfo() {
    this.setState({ showMore: false });
  }

  toggleFamilyInfo() {
    this.setState({ showMore: !this.state.showMore });
  }

  renderMoreInformation() {
    const { item, translation } = this.props;
    const secondaryModels = this.secondaryModels;
    const moreInfoClasses = classNames(
      css.productFamilyDetails,
      this.state.showMore && secondaryModels.length > 0 && css.productFamilyHover,
      secondaryModels.length <= 3 && css.hideShowMoreLink,
      secondaryModels.length === 0 && css.showInfoText,
    );

    return (
      <div onClick={this.toggleFamilyInfo} onMouseEnter={this.showFamilyInfo} onMouseLeave={this.hideFamilyInfo}>
        <div className={moreInfoClasses}>
          <div className={css.infoContainer}>
            {item.brand && <strong>{item.brand}</strong>}
            {item.shortName && <span>{item.shortName}</span>}
          </div>
          {secondaryModels.length > 0 && (
            <List
              items={secondaryModels.take(3)}
              itemRenderer={(model) => {
                const hasVisualizationWideAspectRatio =
                  model.visualizationAspectRatio === VisualizationAspectRatio.Wide;
                const imageWidth = hasVisualizationWideAspectRatio ? 60 : 40;
                return (
                  <Link to={model.url}>
                    <Image
                      className={classNames(css.image, { [css.wide]: hasVisualizationWideAspectRatio })}
                      src={model.imageUrl}
                      width={imageWidth}
                      height={70}
                      sharpeningFactor={2}
                    />
                  </Link>
                );
              }}
              className={css.modelList}
              itemClassName={css.modelContainer}
            />
          )}
          {secondaryModels.length > 3 && (
            <Link to={item.url}>
              <Subtitle2DarkGrey className={css.moreOptions}>
                <Fragment>
                  {translation.showMoreProducts} <Icon className={css.showMoreIcon} name="angleRight" />
                </Fragment>
              </Subtitle2DarkGrey>
            </Link>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { item } = this.props;

    return (
      <Fragment>
        <Link
          to={item.url}
          className={css.productFamilyImage}
          onMouseEnter={this.showFamilyInfo}
          onMouseLeave={this.hideFamilyInfo}
        >
          <div className={css.imageContainer}>
            <Image className={css.image} src={item.imageUrl} width={260} height={400} sharpeningFactor={1.4} />
            {item.listingIcon?.url && (
              <img className={css.icon} src={item.listingIcon.url} alt={item.listingIcon.name} />
            )}
          </div>
        </Link>
        {this.renderMoreInformation()}
      </Fragment>
    );
  }
}
