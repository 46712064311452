import { SimpleProductSummary } from '../../../checkout/components/ShoppingCartSummary/SimpleProductSummary';
import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import { HubSpotFormWithProductState } from '../HubSpotFormWithProductState';
import ProductDetailsPreview from '../ProductDetailsPreview';

interface ProductSummaryProps {
  state: HubSpotFormWithProductState;
  translation: ShoppingCartSummaryTranslation;
  productPreviewClassName?: string;
  shoppingCartClassName?: string;
}

const ProductSummary = ({
  productPreviewClassName,
  shoppingCartClassName,
  state,
  translation,
}: ProductSummaryProps) => {
  return state.hasConfigurationCode() ? (
    <div className={productPreviewClassName}>
      <ProductDetailsPreview state={state} translation={translation} />
    </div>
  ) : (
    <div className={shoppingCartClassName}>
      <SimpleProductSummary
        translation={translation}
        imageUrl={state.getStubImageUrl()}
        code={state.getCode()}
        name={state.getName()}
      />
    </div>
  );
};

export default ProductSummary;
