import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import fetch from 'cross-fetch';
import { action, computed, makeObservable, observable } from 'mobx';

import AuthSettings from './AuthSettings';

// Fix Auth0 types
declare module '@auth0/auth0-spa-js/dist/typings/Auth0Client' {
  export interface IdToken {
    [key: string]: string;
  }
}

export interface User {
  id: string;
  email: string;
  name: string;
  nickname: string;
  pictureUrl: string;
  dbConnection?: string;
}

export interface IAuthenticationService {
  isAuthenticated: () => Promise<boolean>;
  redirectInvitation: (token: string, email?: string, loginMode?: boolean) => Promise<void>;
  login: (returnTo?: string) => Promise<void>;
  logout: () => Promise<void>;
  getUser: () => Promise<User | undefined>;
  getAccessToken: (onErrorRecoverTokenWithLogin: boolean) => Promise<string>;
  handleRedirectCallback: () => Promise<RedirectLoginResult>;
  silentLogin: () => Promise<void>;
  sendResetPasswordEmail: () => Promise<void>;
  passwordResetEnabled: boolean;
  currentUser?: User;
}

export class AuthenticationService implements IAuthenticationService {
  private constructor(private readonly auth0Client: Auth0Client, private readonly auth0Settings: AuthSettings) {
    makeObservable(this);
  }

  @observable
  currentUser?: User;

  @computed
  public get passwordResetEnabled(): boolean {
    return this.currentUser && !!this.currentUser.dbConnection;
  }

  public isAuthenticated = () => {
    return this.auth0Client.isAuthenticated();
  };

  public redirectInvitation = (token: string, email?: string, loginMode: boolean = false): Promise<void> => {
    return this.auth0Client.loginWithRedirect({
      redirect_uri: `${window.location.origin}/handle-invitation-code`,
      appState: { token },
      prompt: 'login', // To force even logged in users to authenticate in Auth0
      mode: loginMode ? 'login' : 'signUp',
      login_hint: email,
      allowSignUp: true,
    });
  };

  public login = (returnTo?: string): Promise<void> => {
    return this.auth0Client.loginWithRedirect({
      allowSignUp: false,
      appState: returnTo ? { appState: { returnTo } } : undefined,
    });
  };

  public logout = async (): Promise<void> => {
    return this.auth0Client.logout({ returnTo: window.location.origin });
  };

  public getUser = async (): Promise<User | undefined> => {
    const auth0User = await this.auth0Client.getUser();
    const idTokenClaims = await this.auth0Client.getIdTokenClaims();

    if (!auth0User) {
      return undefined;
    }

    return {
      id: auth0User.sub,
      email: auth0User.email,
      name: auth0User.name,
      nickname: auth0User.nickname,
      pictureUrl: auth0User.picture,
      dbConnection: idTokenClaims['https://flokk.com/dbConnection'],
    };
  };

  // FIXME: These OAuth 2.0 constants are copied from auth0-spa-js 1.22.1, but are
  // available as early as 1.9.0 Remove them from here and use the original once we update auth0-spa-js.
  /**
   * A list of errors that can be issued by the authorization server which the
   * user can recover from by signing in interactively.
   * https://openid.net/specs/openid-connect-core-1_0.html#AuthError
   * @ignore
   */
  private static readonly RECOVERABLE_ERRORS = [
    'login_required',
    'consent_required',
    'interaction_required',
    'account_selection_required',
    // Strictly speaking the user can't recover from `access_denied` - but they
    // can get more information about their access being denied by logging in
    // interactively.
    'access_denied',
  ];

  public getAccessToken = async (onErrorRecoverTokenWithLogin: boolean): Promise<string | undefined> => {
    try {
      return await this.auth0Client.getTokenSilently();
    } catch (error) {
      if (onErrorRecoverTokenWithLogin && AuthenticationService.RECOVERABLE_ERRORS.contains(error.error)) {
        await this.auth0Client.loginWithRedirect({ appState: { returnTo: window.location.href } });
      } else {
        return undefined;
      }
    }
  };

  public handleRedirectCallback = async (): Promise<RedirectLoginResult> => {
    const redirectResult = await this.auth0Client.handleRedirectCallback();
    await this.initializeUser();
    return redirectResult;
  };

  public silentLogin = async (): Promise<void> => {
    await this.auth0Client.getTokenSilently();
    await this.initializeUser();
  };

  public sendResetPasswordEmail = async () => {
    if (!this.currentUser?.dbConnection) {
      return;
    }

    const resetPasswordUrl = `https://${this.auth0Settings.domain}/dbconnections/change_password`;
    await fetch(resetPasswordUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: this.auth0Settings.clientId,
        email: this.currentUser.email,
        connection: this.currentUser.dbConnection,
      }),
    });
  };

  private initializeUser = async () => {
    const user = await this.getUser();
    this.setCurrentUser(user);
  };

  @action
  private setCurrentUser = (user: User) => {
    this.currentUser = user;
  };

  public static async create(authSettings: AuthSettings): Promise<AuthenticationService> {
    const client = await createAuth0Client({
      domain: authSettings.domain,
      client_id: authSettings.clientId,
      redirect_uri: authSettings.redirectUri,
      audience: authSettings.audience,
    });

    const authService = new AuthenticationService(client, authSettings);
    await authService.initializeUser();

    return authService;
  }
}

export class FakeAuthenticationService implements IAuthenticationService {
  isAuthenticated = () => Promise.resolve(false);
  redirectInvitation = () => Promise.resolve();
  login = () => Promise.resolve();
  logout = () => Promise.resolve();
  getUser = () => Promise.resolve(undefined);
  getAccessToken = () => Promise.resolve('');
  handleRedirectCallback = () => Promise.resolve<RedirectLoginResult>({});
  silentLogin = () => Promise.resolve();
  sendResetPasswordEmail = () => Promise.resolve();
  passwordResetEnabled: false;
  currentUser?: User = null;
}
