import { IEvent } from '../../../app/shared/EventAggregator';

interface CustomerUpdatedEventCustomerData {
  username: string;
  languageCode: string;
  storeId?: string;
}

export class CustomerUpdatedEvent implements IEvent {
  constructor(public readonly customer: CustomerUpdatedEventCustomerData) {}
}
