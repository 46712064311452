import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import OptionVariationThumbnail from '../../../configurator/components/OptionVariationThumbnail/OptionVariationThumbnail';
import { PredefinedConfigurationData } from '../../../data/model';
import { ProductInfoTranslation } from '../../../localization/SiteTranslation';
import IconButton from '../../../shared/components/Button/IconButton';
import Icon from '../../../shared/components/Icon/Icon';
import List from '../../../shared/components/List/List';
import Heading from '../../../shared/components/Typography/Heading/Heading';
import { SelectorItem } from '../../../shared/Selector';
import css from './PredefinedConfigurations.css';
import PredefinedConfigurationsState from './PredefinedConfigurationsState';

interface PredefinedConfigurationsProps {
  className?: string;
  configurations: PredefinedConfigurationsState;
  translation: ProductInfoTranslation;
  onConfigure: () => void;
}

@observer
export default class PredefinedConfigurations extends Component<PredefinedConfigurationsProps, {}> {
  constructor(props: PredefinedConfigurationsProps) {
    super(props);

    this.renderConfigurationItem = this.renderConfigurationItem.bind(this);
  }

  renderConfigurationItem(item: SelectorItem<PredefinedConfigurationData>) {
    return (
      <div
        className={classNames(css.item, item.isActive && css.isSelected)}
        onClick={() => this.props.configurations.setActiveItem(item)}
      >
        <OptionVariationThumbnail image={item.data.image} isSelected={item.isActive} />
      </div>
    );
  }

  render() {
    const { configurations, className, onConfigure, translation } = this.props;
    return (
      <div className={classNames(css.PredefinedConfigurations, className)}>
        <Heading level={2} className={css.heading}>
          {translation.popularDesigns.title}
        </Heading>
        <div className={css.content}>
          <List className={css.list} items={configurations.items} itemRenderer={this.renderConfigurationItem} />
          <IconButton
            className={css.addConfigurationButton}
            nameClassName="visuallyHidden"
            icon={<Icon name="plus" />}
            onClick={onConfigure}
          >
            {translation.actions.configureChairButton}
          </IconButton>
        </div>
      </div>
    );
  }
}
