import { observer } from 'mobx-react';

import { OrderData } from '../../../app/data/model';
import { RouteUrlBuilder } from '../../../app/RouteDefinitions';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import Link from '../../../app/shared/components/Link';
import Price from '../../../app/shared/components/Price';
import { ITableHeaderCell, ITableRowData, Table } from '../../../app/shared/components/Table/Table';
import { StoreUrlBuilder } from '../../../app/shared/StoreUrlBuilder';
import { PastOrdersTableTranslation } from '../../localization/SiteTranslation';
import RouteDefinitions from '../../routing/RouteDefinitions';
import css from './PastOrdersTable.css';

const getHeaders = (translation: PastOrdersTableTranslation) => {
  return {
    ORDER_NUMBER: translation.orderNumberHeader,
    ORDER_NAME: translation.orderNameHeader,
    ORDER_DATE: translation.orderDateHeader,
    SHIPPING_DATE: translation.shippingDateHeader,
    PRODUCTS: translation.productsHeader,
    TOTAL_PRICE: translation.totalPriceHeader,
    BUYER: translation.buyerHeader,
  };
};

const getTableHeaders = (translation: PastOrdersTableTranslation) => {
  const headers = getHeaders(translation);

  const tableHeaders: ITableHeaderCell[] = new Array<ITableHeaderCell>();
  tableHeaders.push({ name: headers.ORDER_NUMBER });
  tableHeaders.push({ name: headers.ORDER_NAME, alignment: 'left' });
  tableHeaders.push({ name: headers.ORDER_DATE });
  tableHeaders.push({ name: headers.SHIPPING_DATE });
  tableHeaders.push({ name: headers.PRODUCTS, alignment: 'left' });
  tableHeaders.push({ name: headers.TOTAL_PRICE });
  tableHeaders.push({ name: headers.BUYER, alignment: 'left' });

  return tableHeaders;
};

const getTableData = (order: OrderData, translation: PastOrdersTableTranslation): Array<ITableRowData> => {
  const orderUrl = StoreUrlBuilder.withContext(
    RouteUrlBuilder.getUrlTo(RouteDefinitions.orderRoute, { orderNumber: order.number }),
  );
  const shippingDate =
    order.status === 'Cancelled' ? (
      <span className={css.cancelled}>{translation.cancelledText}</span>
    ) : (
      order.shippingDate && formatISODateStringToDottedDate(order.shippingDate)
    );
  const headers = getHeaders(translation);

  return [
    { headerName: headers.ORDER_NUMBER, value: <Link to={orderUrl}>{order.number}</Link> },
    { headerName: headers.ORDER_NAME, value: order.customName, alignment: 'left' },
    { headerName: headers.ORDER_DATE, value: formatISODateStringToDottedDate(order.createdDate) },
    { headerName: headers.SHIPPING_DATE, value: shippingDate },
    { headerName: headers.PRODUCTS, value: order.items.length, alignment: 'left' },
    { headerName: headers.TOTAL_PRICE, value: <Price price={order.total} /> },
    { headerName: headers.BUYER, value: order.customerName, alignment: 'left' },
  ];
};

export const PastOrdersTable = observer(
  ({ orders, translation }: { orders: Array<OrderData>; translation: PastOrdersTableTranslation }) => {
    const tableData = orders.map((order) => getTableData(order, translation));

    return (
      <Table
        className={css.PastOrdersTable}
        tableHeader={{ data: getTableHeaders(translation), thClassName: css.th }}
        tableBody={{ data: tableData, tdClassName: css.td }}
      />
    );
  },
);
