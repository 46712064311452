import { action, computed, makeObservable, observable } from 'mobx';

import Event from '../../Event';

export interface PageChangedEventArgs {
  pageIndex: number;
}

export class PaginationState {
  pageChanged: Event<PageChangedEventArgs> = new Event<PageChangedEventArgs>();

  @observable
  pageSize: number = 10;

  @observable
  currentPageIndex: number = 0;

  @observable
  totalCount: number;

  @computed
  get currentPageNumber() {
    return this.currentPageIndex + 1;
  }

  @computed
  get pagesCount() {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  @computed
  get canMoveLeft() {
    return this.currentPageIndex > 0;
  }

  get canMoveRight() {
    return this.currentPageNumber < this.pagesCount;
  }

  get startIndex() {
    return this.currentPageIndex * this.pageSize;
  }

  get lastIndex() {
    return Math.min(this.startIndex + this.pageSize, this.totalCount) - 1;
  }

  constructor(pageSize: number) {
    makeObservable(this);
    this.pageSize = pageSize;
  }

  @action setTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }

  @action
  moveLeft() {
    this.setCurrentPageIndex(this.currentPageIndex - 1);
  }

  @action
  moveRight() {
    this.setCurrentPageIndex(this.currentPageIndex + 1);
  }

  @action
  setCurrentPageIndex(pageIndex: number, silently: boolean = false) {
    this.currentPageIndex = pageIndex;

    if (silently) {
      return;
    }

    this.pageChanged.raise({ pageIndex: pageIndex });
  }

  @action resetSilently() {
    this.setCurrentPageIndex(0, true);
    this.setTotalCount(0);
  }
}
