import { action, makeObservable, observable } from 'mobx';

import { Navigation } from '../../../../app/shared/NavigationService';
import { RelativeUrl } from '../../../../app/shared/Url';

export interface ISideMenuItemState {
  isActive: boolean;
  name: string;
  url: RelativeUrl | string;
  setIsActive: (menuItemUrl: RelativeUrl | string) => void;
}

export class SideMenuItemState implements ISideMenuItemState {
  @observable
  public isActive: boolean;
  public name: string;
  public url: RelativeUrl | string;

  public constructor(name: string, url: RelativeUrl | string) {
    makeObservable(this);
    this.init(name, url);
  }

  @action
  public setIsActive(menuItemUrl: RelativeUrl | string) {
    this.isActive = menuItemUrl === this.url;
  }

  @action
  private init(name: string, url: RelativeUrl | string) {
    this.name = name;
    this.url = url;
    this.isActive = this.isUrlPartOfCurrentPath();
  }

  private isUrlPartOfCurrentPath() {
    const currentPath = RelativeUrl.parse(Navigation.instance.currentUrl.toString()).path;

    if (this.url instanceof RelativeUrl) {
      const path = this.url.path;
      return path === currentPath || (currentPath.startsWith(path) && this.url.pathSegments.length > 2);
    }

    return false;
  }
}
