import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { FileTypeFilterTranslation } from '../../../../../../localization/SiteTranslation';
import { IFilterOptionState, ITypeFilterSelectionState } from '../../../DownloadsFiltersState';
import FilterButton from '../../../FilterButton/FilterButton';
import css from './TabSubgroup.css';

interface TabSubgroupProps extends Pick<React.AriaAttributes, 'aria-controls'> {
  isOpen: boolean;
  items: IFilterOptionState[];
  onTabSelect: () => void;
  selection: ITypeFilterSelectionState;
  translation: FileTypeFilterTranslation;
  activeItemClassName?: string;
}

const TabSubgroup: React.FC<TabSubgroupProps> = ({ isOpen, items, onTabSelect, selection, translation, ...aria }) => {
  const renderTab = (item: IFilterOptionState) => {
    const { isActive, name, value } = item;
    const handleClick = () => {
      selection?.select(item);
      onTabSelect();
    };

    return (
      <FilterButton
        className={classNames({ [css.activeTab]: isActive(selection?.value) })}
        name="type"
        value={value}
        isActive={isActive(selection?.value)}
        onSelect={handleClick}
        key={value}
      >
        {name}
      </FilterButton>
    );
  };

  return (
    <div className={classNames(css.TabSubgroup, { [css.open]: isOpen })}>
      <div role="radiogroup" className={css.inner} aria-label={translation.name} {...aria}>
        {items.map(renderTab)}
      </div>
    </div>
  );
};

export default observer(TabSubgroup);
