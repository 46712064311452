import AppState from './AppState';
import { ForbiddenErrorPage } from './errorHandling/ForbiddenErrorPage';
import GeneralErrorPage from './errorHandling/GeneralErrorPage';
import { GeneralErrorState } from './errorHandling/GeneralErrorState';
import PageNotFound from './errorHandling/PageNotFound';
import { PageNotFoundState } from './errorHandling/PageNotFoundState';
import { ForbiddenErrorPageTranslation, GeneralErrorPageTranslation } from './localization/SiteTranslation';
import RouteDefinitions from './RouteDefinitions';
import { IPageState } from './shared/common';

/*
 * webpack does static analysis over `System.import` calls to split the app code
 * into chunks. We must include each import explicitly.
 */

export interface Page {
  state: IPageState;
  component: JSX.Element;
  useSplashScreen: boolean;
}

export interface Route<T> {
  /**
   *  The actual route name, containing what URLs this route matches.
   * For example for defining a route for /books - you'd pass `books'.
   * parameters can be passed with `:` express like syntax, for example `/books/:id/`
   */
  route: string;

  /**
   * Return page that will be mounted when route is matched
   */
  getPage: (appState?: AppState, params?: object, query?: Map<string, string>) => Promise<Page>;

  /**
   * Array of routes that should be redirected to this page
   */
  aliases?: Array<string>;
}

export const createGeneralErrorPage = (translation: GeneralErrorPageTranslation, redirectUrl: string) => {
  return {
    state: new GeneralErrorState(translation),
    component: <GeneralErrorPage translation={translation} redirectUrl={redirectUrl} />,
    useSplashScreen: false,
  };
};

export const createForbiddenPage = (
  translation: ForbiddenErrorPageTranslation,
  redirectUrl: string,
  logout: () => Promise<void>,
) => {
  return {
    state: new GeneralErrorState(translation),
    component: <ForbiddenErrorPage translation={translation} logout={logout} redirectUrl={redirectUrl} />,
    useSplashScreen: false,
  };
};

export const createPageNotFound = (translation: GeneralErrorPageTranslation, redirectUrl: string) => {
  return {
    state: new PageNotFoundState(translation),
    component: <PageNotFound translation={translation} redirectUrl={redirectUrl} />,
    useSplashScreen: false,
  };
};

const routes = [
  RouteDefinitions.productPageRoute,
  RouteDefinitions.homeRoute,
  RouteDefinitions.configurationSearchRoute,
  RouteDefinitions.configuratorProductRoute,
  RouteDefinitions.modularConfiguratorRoute,
  RouteDefinitions.checkoutRoute,
  RouteDefinitions.checkoutShortcutRoute,
  RouteDefinitions.confirmationRoute,
  RouteDefinitions.simulateErrorRoute,
  RouteDefinitions.requestQuoteRoute,
  RouteDefinitions.emailMyConfigurationRoute,
  RouteDefinitions.productSpecificationRoute,
  RouteDefinitions.productViewer3dPageRoute,
  RouteDefinitions.storeLocatorRoute,
  RouteDefinitions.viewerRoute,
  RouteDefinitions.codeRoute,
  RouteDefinitions.catalogPageRoute,
  RouteDefinitions.downloadsPageRoute,
  RouteDefinitions.webShopProductDetailsRoute,
  RouteDefinitions.modularConfiguratorPageRoute,
  RouteDefinitions.rawConfiguratorRoute,
  RouteDefinitions.configurationDetailsRoute,
];

export { routes as routes };
