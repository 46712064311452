import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { StoreSearchTranslation, StoreTypeTranslation } from '../../../localization/SiteTranslation';
import SecondaryButton from '../../../shared/components/Button/SecondaryButton';
import Icon from '../../../shared/components/Icon/Icon';
import StoreLocatorState from '../../StoreLocatorState';
import StoreFilters from '../StoreFilters/StoreFilters';
import StoreLocatorInput from '../StoreLocationInput/StoreLocatorInput';
import css from './StoreSearch.css';

interface StoreSearchProps {
  state: StoreLocatorState;
  translation: StoreSearchTranslation;
  storeTypeTranslation: StoreTypeTranslation;
}

@observer
class StoreSearch extends Component<StoreSearchProps, {}> {
  constructor(props: StoreSearchProps) {
    super(props);
  }

  renderNoDealersMessage() {
    const { translation, state } = this.props;

    if (state.listState.stores.empty()) {
      return (
        <p className={css.notFound}>
          {state.filterState.selectedFiltersNumber ? translation.noDealersWithFiltersText : translation.noDealersText}
        </p>
      );
    }
  }

  render() {
    const { translation, storeTypeTranslation, state } = this.props;

    const filterResultText = translation.filterResultText.interpolate([
      ['storesNumber', state.storesNumber.toString()],
    ]);

    return (
      <div className={css.StoreSearch}>
        <StoreLocatorInput state={state.storeLocatorInputState} translation={translation.storeLocatorInput} />
        <StoreFilters
          state={state.filterState}
          translation={translation.storeLocatorFilters}
          storeTypeTranslation={storeTypeTranslation}
        />
        <div className={css.container}>
          <p className={css.results}>{filterResultText}</p>

          <div className={css.buttonsGroup}>
            <SecondaryButton
              className={classNames(css.button, !state.listVisible && css.active)}
              onClick={() => {
                state.showMap();
              }}
            >
              <Icon name="location" className={css.icon} />
            </SecondaryButton>
            <SecondaryButton
              className={classNames(css.button, state.listVisible && css.active)}
              onClick={() => {
                state.openStoreList();
              }}
            >
              <Icon name="list" className={css.icon} />
            </SecondaryButton>
          </div>
        </div>
        {this.renderNoDealersMessage()}
      </div>
    );
  }
}

export default StoreSearch;
