import IconButton from '../../Button/IconButton';
import Icon from '../../Icon/Icon';

interface DownloadButtonProps {
  onClick: () => void;
  className?: string;
  iconClassName?: string;
}

const DownloadButton = ({ className, iconClassName, onClick }: DownloadButtonProps) => {
  return (
    <IconButton className={className} onClick={onClick} icon={<Icon name="download" className={iconClassName} />} />
  );
};

export default DownloadButton;
