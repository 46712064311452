import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ReactNode } from 'react';

import { FavoriteButtonTranslation } from '../../../../b2b/localization/SiteTranslation';
import { RelativeUrl } from '../../Url';
import Icon from '../Icon/Icon';
import Link, { LinkProps } from '../Link';
import { ButtonProps } from './Button';
import css from './CustomButtons.css';
import IconButton, { IconButtonProps } from './IconButton';

interface CartButtonProps extends ButtonProps {
  cartUrl: RelativeUrl;
  quantity: number;
}

export const CartButton = (props: CartButtonProps) => {
  const { cartUrl, children, quantity, ...otherProps } = props;
  return (
    <Link to={cartUrl}>
      <IconButton
        className={css.CartButton}
        nameClassName={css.cartButtonNameContainer}
        icon={<Icon name="basket" className={css.cartButtonIcon} />}
        {...otherProps}
      >
        <>
          {quantity > 0 && (
            <div className={css.cartButtonQuantityContainer}>
              <span className={css.cartButtonQuantity}>{quantity}</span>
            </div>
          )}
          <span className={css.cartButtonName}>{children}</span>
        </>
      </IconButton>
    </Link>
  );
};

export interface FavoriteButtonProps extends ButtonProps {
  translation: FavoriteButtonTranslation;
  added?: boolean;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = observer(
  ({ added, className, disabled, isLoading, translation, ...otherProps }: FavoriteButtonProps) => {
    const text = isLoading
      ? translation.addingToFavoritesText
      : added
      ? translation.addedToFavoritesText
      : translation.addToFavoritesText;

    return (
      <IconButton
        className={classNames(css.FavoriteButton, disabled && css.disabled, className)}
        icon={
          <>
            <Icon
              name="favorites"
              className={classNames(css.favoriteButtonIcon, !disabled && css.active, isLoading && css.isLoading)}
            />
            <Icon
              name="favoritesFull"
              className={classNames(css.favoriteButtonIcon, disabled && css.active, isLoading && css.isLoading)}
            />
          </>
        }
        disabled={disabled}
        {...otherProps}
      >
        {text}
      </IconButton>
    );
  },
);

export const EditButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  return (
    <IconButton
      className={css.EditButton}
      icon={<Icon name="edit" className={css.icon} />}
      uppercase
      after
      {...props}
    />
  );
};

export const AddButton: React.FC<IconButtonProps> = ({ className, ...props }: IconButtonProps) => {
  return (
    <IconButton
      className={classNames(css.AddButton, className)}
      icon={<Icon name="plus" className={css.icon} />}
      uppercase
      after
      {...props}
    />
  );
};

export interface CrossButtonProps extends IconButtonProps {
  iconClassName?: string;
}

export const CrossButton = (props: CrossButtonProps) => {
  const { className, iconClassName, nameClassName, ...otherProps } = props;
  return (
    <IconButton
      className={classNames(css.CrossButton, className)}
      nameClassName={classNames(css.crossButtonName, nameClassName)}
      icon={<Icon name="cross" className={classNames(css.crossButtonIcon, iconClassName)} />}
      {...otherProps}
    />
  );
};

export const NextButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  const { className, ...otherProps } = props;
  return <IconButton className={className} icon={<Icon name="angleRight" />} {...otherProps} />;
};

export const PrevButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  const { className, ...otherProps } = props;
  return <IconButton className={className} icon={<Icon name="angleLeft" />} {...otherProps} />;
};

export const TrackButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  const { className, ...otherProps } = props;
  return (
    <IconButton
      after
      className={classNames(css.TrackButton, className)}
      nameClassName={css.trackButtonName}
      icon={<Icon className={css.trackButtonIcon} name="track" />}
      {...otherProps}
    />
  );
};

interface TrackButtonLinkProps {
  className?: string;
  link: LinkProps;
  children?: ReactNode;
}

export const TrackButtonLink: React.FC<TrackButtonLinkProps> = (props: TrackButtonLinkProps) => {
  const { className, link, children } = props;
  return (
    <Link className={classNames(css.TrackButton, className)} {...link}>
      <>
        <span className={css.trackButtonLinkName}>{children}</span>
        <Icon className={css.trackButtonIcon} name="track" />
      </>
    </Link>
  );
};
