import classNames from 'classnames';
import { observer } from 'mobx-react';

import ToggleButton from '../../../../../../../shared/components/Button/ToggleButton/ToggleButton';
import ModularConfiguratorCoordinatorState from '../../../../ModularConfiguratorCoordinatorState';
import ToolbarButton from '../ToolbarButton/ToolbarButton';
import css from './ModularComponentActionButtons.css';

interface ModularComponentToolbarActionsProps {
  state: ModularConfiguratorCoordinatorState;
  className?: string;
}

const ModularComponentActionButtons = observer(({ className, state }: ModularComponentToolbarActionsProps) => {
  const handleMoveButtonClick = () => {
    if (state.configurator.isMoveActionActive) {
      state.configurator.activateDefaultAction();
    } else {
      state.configurator.activateMoveAction();
    }
  };

  const handleRotateButtonClick = () => {
    if (state.configurator.isRotateActionActive) {
      state.configurator.activateDefaultAction();
    } else {
      state.configurator.activateRotateAction();
    }
  };

  return (
    <div className={classNames(css.container, className)}>
      <ToolbarButton
        icon="trash"
        tooltip={{ id: 'modular-component-2d-action', text: 'Remove' }}
        onClick={state.configurator.removeSelectedComponent}
        compact
      />
      <ToggleButton
        id="modular-component-move-action"
        label="Move"
        icon="move"
        onClick={handleMoveButtonClick}
        isPressed={state.configurator.isMoveActionActive}
      />
      <ToggleButton
        id="modular-component-rotate-action"
        label="Rotate"
        icon="rotate"
        onClick={handleRotateButtonClick}
        isPressed={state.configurator.isRotateActionActive}
      />
    </div>
  );
});

export default ModularComponentActionButtons;
