import { action, computed, makeObservable, observable } from 'mobx';

import {
  ComponentData,
  EnabledByOperator,
  ImageData,
  MoneyData,
  OptionType,
  RestrictionData,
  SizeData,
} from '../data/model';
import { StoreState } from '../StoreState';
import { CssAnimation, IAnimation } from './animations';
import { AppError } from './AppError';
import Logger from './Logger';
import { IRestrictionsResolver } from './restrictions';

export const keysOf = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
export const nameOf = <T>(name: keyof T) => name.toString();

export type KeysOfType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];

export interface IValidatable {
  isValid: boolean;

  validate(): void;

  resetValidation(): void;
}

export interface IEnableable {
  isEnabled: boolean;

  enable(): void;

  disable(): void;
}

export interface ISelectable {
  selected: boolean;

  select(): void;

  deselect(): void;
}

export interface IItem extends ISelectable {
  id: string;
  code: string;
  displayCode: string;
  fullName: string;
  name: string;
  price: MoneyData;
  priceIncludingDependentFeatures: MoneyData;
  image: ImageData;
  restrictions: Array<RestrictionData>;

  select(): void;

  dependentFeatures: Array<IFeature>;
  feature: IFeature;
}

export interface IHighlightable {
  setHighlight(): void;

  removeHighlight(): void;

  highlighted: boolean;

  highlightAnimation: IAnimation;
}

export abstract class Item implements IItem, IHighlightable {
  id: string;
  code: string;
  displayCode: string;
  fullName: string;
  name: string;
  abstract get price(): MoneyData;
  image: ImageData;
  restrictions: Array<RestrictionData>;
  highlightAnimation: IAnimation = new CssAnimation();
  dependentFeatures: Array<IFeature> = [];
  feature: IFeature;
  restrictionsResolver: IRestrictionsResolver;

  @observable selected: boolean;
  @observable highlighted: boolean;

  protected constructor() {
    makeObservable(this);
  }

  @action select() {
    this.selected = true;
  }

  @action deselect() {
    this.selected = false;
  }

  @action.bound setHighlight(): void {
    this.highlighted = true;
  }

  @action.bound removeHighlight(): void {
    this.highlighted = false;
  }

  @computed get dependentFeaturesPrice(): MoneyData {
    let amount = this.restrictionsResolver
      .getSuggestedSelectionsForDependentFeatures(this)
      .map((x) => x.feature.getItemPrice(x))
      .sum((x) => x.amount);

    return {
      currency: this.price.currency,
      amount: amount,
    };
  }

  @computed get priceIncludingDependentFeatures(): MoneyData {
    return {
      currency: this.price.currency,
      amount: this.price.amount + this.dependentFeaturesPrice.amount,
    };
  }
}

export abstract class EnableableOption {
  @observable.shallow
  enablers: Array<IItem> = [];
  enabledByOperator: EnabledByOperator;

  protected constructor() {
    makeObservable(this);
  }

  @computed get enabled() {
    if (this.enablers.empty()) {
      return true;
    }

    if (this.enabledByOperator === EnabledByOperator.And) {
      const enablersGroupedByFeatures = this.enablers.groupBy<IItem>((x) => x.feature.data.code);
      return Object.entries(enablersGroupedByFeatures).all(([, variations]) =>
        variations.any((x) => x.selected && x.feature.enabled),
      );
    }

    return this.enablers.any((x) => x.selected && x.feature.enabled);
  }

  @action setEnablingItems(items: Array<IItem>, enabledByOperator: EnabledByOperator) {
    this.enabledByOperator = enabledByOperator;
    this.enablers.push(...items);
  }
}

export interface IDependentOptionPreselectionStrategy {
  preselectDependentItem(itemToBeSelected: IItem, selectedItems: IItem[], validItems: IItem[]): void;

  suggestValidItem(validItems: IItem[]): IItem | null;
}

export class DefaultDependentOptionPreselectionStrategy implements IDependentOptionPreselectionStrategy {
  constructor(protected feature: IFeature) {}

  preselectDependentItem(_itemToBeSelected: IItem, _selectedItems: IItem[], validItems: IItem[]): void {
    if (validItems.empty()) {
      return;
    }

    const selectedItem = this.feature.getSelectedItem();
    const suggestedValidItem = this.suggestValidItem(validItems);

    if (selectedItem === suggestedValidItem) {
      return;
    }

    this.feature.selectItem(suggestedValidItem);
  }

  suggestValidItem(validItems: IItem[]): IItem | null {
    const selectedItem = this.feature.getSelectedItem();
    return validItems.contains(selectedItem) ? selectedItem : validItems.first();
  }
}

export interface IFeature {
  id: string;
  name: string;
  type?: OptionType;
  enabled: boolean;
  index: number;
  enablers: Array<IItem>;
  enabledByOperator: EnabledByOperator;
  data: ComponentData;
  price: MoneyData;

  selectItem(item: IItem): void;

  deselectItem(item: IItem): void;

  getAllItems(): Array<IItem>;

  formatItemName(item: IItem): string;

  getItemPriceDifference(item: IItem): MoneyData;

  getItemPrice(item: IItem): MoneyData;

  getSelectedItem(): IItem;

  getPreselectionStrategy(): IDependentOptionPreselectionStrategy;

  setEnablingItems(items: Array<IItem>, enabledByOperator: EnabledByOperator): void;

  canBeAutoResolvedWhenRestricting(): boolean;
}

export interface IPriceGroupRelated {
  selectPriceGroup(priceGroupNumber: string): void;
}

export interface IMemento {}

export interface IRestorable {
  getMemento(): IMemento;

  restoreMemento(memento: IMemento): void;
}

export interface PageMetadata {
  title?: string;
  description?: string;
  imageUrl?: string;
  imageWidth?: string;
  imageHeight?: string;
  url?: string;
}

export interface IPageState extends IRestorable {
  onLoad(store: StoreState): Promise<void>;

  onLoadAdditionalData(): void;

  metadata: PageMetadata;
  disableCache?: boolean;

  unload(): void;
}

export interface IAppState extends IRestorable {
  pages: Array<IPageState>;
  currentPage: IPageState;
}

export class AlwaysValid implements IValidatable {
  @observable isValid: boolean;

  constructor() {
    makeObservable(this);
    this.isValid = true;
  }

  validate() {
    // left empty cause it is always valid
  }

  resetValidation() {
    // do nothing
  }
}

export class AsyncCommand<TActionArg = undefined> {
  private validatable: IValidatable;

  action: (arg: TActionArg) => Promise<void>;

  @observable errorMessage: string = '';
  @observable errorOccurred: boolean;
  @observable processing: boolean;

  @computed get disabled() {
    return this.processing || !this.validatable.isValid;
  }

  constructor(actionToInvoke: (arg: TActionArg) => Promise<void>, validatable: IValidatable = new AlwaysValid()) {
    makeObservable(this);
    this.action = actionToInvoke;
    this.validatable = validatable;
  }

  public invoke = async (arg?: TActionArg) => {
    this.start();

    try {
      this.validatable.validate();

      if (!this.validatable.isValid) {
        return;
      }

      await this.action(arg);
    } catch (error) {
      Logger.exception('Error occurred while processing async command', error);
      this.setError(error);
    } finally {
      this.stop();
    }
  };

  @action
  private start() {
    this.processing = true;
    this.clearError();
  }

  @action
  private setError(error: Error) {
    this.errorOccurred = true;

    const appError = error as AppError;
    if (appError.userMessage) {
      this.errorMessage = appError.userMessage;
    }
  }

  @action clearError() {
    this.errorOccurred = false;
    this.errorMessage = '';
  }

  @action
  private stop() {
    this.processing = false;
  }
}

export const loadScriptAsync = (uri: string, crossOrigin: string = undefined) => {
  return new Promise<void>((resolve, reject) => {
    let tag = document.createElement('script');
    tag.src = uri;
    tag.async = true;
    tag.crossOrigin = crossOrigin;
    tag.onload = () => {
      resolve();
    };
    tag.onerror = (e) => {
      reject(e);
    };
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  });
};

export const kebabToCamelCase = (text: string) => {
  if (!text) {
    return '';
  }
  return text.toLowerCase().replace(/-([a-z])/g, (x) => x[1].toUpperCase());
};

export interface CurrencyFormat {
  symbol: string;
  prefix: boolean;
}

export class CurrencyFormatter {
  private static currencySymbols = [
    { code: 'EUR', symbol: '€', prefix: false },
    { code: 'NOK', symbol: 'NOK', prefix: false },
    { code: 'USD', symbol: '$', prefix: true },
    { code: 'DKK', symbol: 'DKK', prefix: false },
    { code: 'SEK', symbol: 'kr', prefix: false },
  ];

  public static getFormat(code: string): CurrencyFormat {
    const currencySymbol = this.currencySymbols.find((x) => x.code === code);
    return currencySymbol
      ? { symbol: currencySymbol.symbol, prefix: currencySymbol.prefix }
      : { symbol: code, prefix: true };
  }

  public static getFormattedPrice(price: MoneyData): string {
    const currencyFormat = CurrencyFormatter.getFormat(price.currency.code);
    const amountFormatted = price.amount.toLocaleString();

    if (currencyFormat) {
      if (currencyFormat.prefix) {
        return `${currencyFormat.symbol} ${amountFormatted}`;
      } else {
        return `${amountFormatted} ${currencyFormat.symbol}`;
      }
    }
    return `${price.currency.code} ${amountFormatted}`;
  }
}

export class PriceFormatter {
  public static formatFromString(amount: string, preserveDecimalPart: boolean): string {
    let amountNumeric = Number(amount);

    if (Number.isNaN(amountNumeric)) {
      return amount;
    }

    return PriceFormatter.format(amountNumeric, preserveDecimalPart);
  }

  public static format(amount: number, preserveDecimalPart: boolean): string {
    let amountFormatted = PriceFormatter.round(Math.abs(amount), preserveDecimalPart);
    amountFormatted = PriceFormatter.addThousandSeparator(amountFormatted);

    return amountFormatted;
  }

  private static addThousandSeparator(amount: string): string {
    const separatorPattern = '$& ';

    if (amount.includes('.')) {
      return amount.replace(/\d(?=(\d{3})+\.)/g, separatorPattern);
    }

    return amount.replace(/\d(?=(\d{3})+$)/g, separatorPattern);
  }

  private static round(amount: number, preserveDecimalPart: boolean): string {
    const shouldRemoveDecimalPart = !preserveDecimalPart && Number.isSafeInteger(amount);

    if (shouldRemoveDecimalPart) {
      return amount.toFixed(0);
    }

    return amount.toFixed(2);
  }
}

export const getEmptyMoneyData = (): MoneyData => ({ amount: 0, currency: { code: '' } });

export const getPriceDifferenceText = (price: MoneyData) => {
  if (price.amount === 0) {
    return null;
  }

  const currencyFormat = CurrencyFormatter.getFormat(price.currency.code);
  const amount = Math.abs(price.amount).toFixed(2);
  const sign = price.amount > 0 ? '+' : '-';

  if (currencyFormat) {
    if (currencyFormat.prefix) {
      return `${sign} ${currencyFormat.symbol} ${amount}`;
    } else {
      return `${sign} ${amount} ${currencyFormat.symbol}`;
    }
  }
  return `${sign} ${price.currency.code} ${amount}`;
};

export function isBrowser() {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
}

export const isEmpty = <T>(obj: T) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const appendQuery = (url: string, entries: string[]) => {
  return url + (url.indexOf('?') === -1 ? '?' : '&') + entries.join('&');
};

export function scaleToFit(size: SizeData, bounds: SizeData): SizeData {
  if (size.width <= bounds.width && size.height <= bounds.height) {
    return size;
  }

  const aspectRatio = size.width / size.height;
  const targetAspectRatio = bounds.width / bounds.height;

  let width = size.width;
  let height = size.height;

  if (aspectRatio > targetAspectRatio) {
    width = bounds.width;
    height = width / aspectRatio;
  } else {
    height = bounds.height;
    width = height * aspectRatio;
  }

  return {
    height: Math.round(height),
    width: Math.round(width),
  };
}

export function parseFeatureCode(variationId: string) {
  const parts = variationId.split('-');
  return parts.length > 0 ? parts[0] : variationId;
}

export function parseVariationCode(variationId: string) {
  const parts = variationId.split('-');
  return parts.length > 0 ? parts[1] : variationId;
}

// tslint:disable-next-line:no-any
export function debounce<F extends (...args: any[]) => void>(func: F, waitMiliseconds: number) {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  const debounceFunc = function (...args: Parameters<F>) {
    const context = this;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func.apply(context, args), waitMiliseconds);
  };

  return debounceFunc;
}
