import { observer } from 'mobx-react';

import { StoreLocatorFiltersTranslation, StoreTypeTranslation } from '../../../localization/SiteTranslation';
import AccordionPanel from '../../../shared/components/AccordionPanel/AccordionPanel';
import CheckboxElement from '../../../shared/components/Checkbox/CheckboxElement';
import List from '../../../shared/components/List/List';
import { TextLabel } from '../../../shared/components/Typography/TextLabels';
import css from './StoreFilters.css';
import StoreFiltersState from './StoreFilterState';

interface IStoreFiltersProps {
  state: StoreFiltersState;
  translation: StoreLocatorFiltersTranslation;
  storeTypeTranslation: StoreTypeTranslation;
}

const StoreList = observer(({ state, translation, storeTypeTranslation }: IStoreFiltersProps) => {
  const showFilters = !state.storeTypes.empty() || !state.brands.empty();
  const title =
    state.selectedFiltersNumber > 0 ? `${translation.title} (${state.selectedFiltersNumber})` : translation.title;

  const renderBrandList = () => {
    return (
      <div className={css.filterGroup}>
        <TextLabel className={css.section}>{translation.brandLabel}</TextLabel>
        <List
          items={state.brands}
          itemRenderer={(brand) => (
            <CheckboxElement checked={brand.value} onChange={(value) => brand.toggle()}>
              {brand.name}
            </CheckboxElement>
          )}
          itemClassName={css.item}
          className={css.list}
        />
      </div>
    );
  };

  const renderStoreList = () => {
    return (
      <div className={css.filterGroup}>
        <TextLabel className={css.section}>{translation.placesLabel}</TextLabel>
        <List
          items={state.storeTypes}
          itemRenderer={(place) => (
            <CheckboxElement checked={place.value} onChange={(value) => place.toggle()}>
              {storeTypeTranslation[place.name] || place.name}
            </CheckboxElement>
          )}
          itemClassName={css.item}
          className={css.list}
        />
      </div>
    );
  };

  return (
    showFilters && (
      <AccordionPanel title={title} className={css.StoreFilters} panelTitleClassName={css.title}>
        <div className={css.filters}>
          {state.storeTypes.any() && renderStoreList()}
          {state.brands.any() && renderBrandList()}
        </div>
      </AccordionPanel>
    )
  );
});

export default StoreList;
