export interface IGeolocationService {
  getCurrentPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions,
  ): void;
}

export class GeolocationService implements IGeolocationService {
  getCurrentPosition(
    successCallback: PositionCallback,
    errorCallback?: PositionErrorCallback,
    options?: PositionOptions,
  ): void {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  }
}
