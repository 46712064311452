import { observer } from 'mobx-react';
import { Component } from 'react';

import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { ShoppingCartItemData } from '../../../app/data/model';
import { AsyncCommand } from '../../../app/shared/common';
import ActionButton from '../../../app/shared/components/ActionButton';
import SecondaryButton from '../../../app/shared/components/Button/SecondaryButton';
import TertiaryButton from '../../../app/shared/components/Button/TertiaryButton';
import InputNumber from '../../../app/shared/components/InputNumber/InputNumber';
import { LoadableImage } from '../../../app/shared/components/LoadableImage';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { NumberInput } from '../../../app/shared/Form';
import { Authorize } from '../../authentication/Authorize';
import { CartItemBodyDetailsTranslation } from '../../localization/SiteTranslation';
import { BuyerPolicy } from '../../Policy';
import CartItemActions from '../CartItemActions';
import css from './CartItem.css';
import { CartItemDetails } from './CartItemDetails/CartItemDetails';
import { CartItemContextCommand, CartItemState } from './CartItemState';

interface CartItemProps {
  state: CartItemState;
}

@observer
export class CartItem extends Component<CartItemProps> {
  render() {
    const { state } = this.props;

    return (
      <Panel
        className={css.cartItem}
        bodyClassName={css.cartItemBody}
        header={{
          heading: <CartItemHeading item={state.item} contextCommands={state.cartItemContextCommands} />,
          headingActionButtons: (
            <CartItemActions items={state.cartItemContextCommands} translation={state.translation.cartItemActions} />
          ),
          headerClassName: css.cartItemHeader,
        }}
      >
        <CartItemBodyDetails
          item={state.item}
          quantityUpdate={state.quantityUpdate}
          updateQuantityCommand={state.updateQuantityCommand}
          translation={state.translation.cartItemBodyDetails}
        />
      </Panel>
    );
  }
}

interface CartItemHeaderProps {
  item: ShoppingCartItemData;
  contextCommands: Array<CartItemContextCommand>;
}

const CartItemHeading = ({ item }: CartItemHeaderProps) => {
  return (
    <div>
      <span className={css.cartItemHeading}>
        <strong>{item.baseModel.brandName}</strong> {item.baseModel.shortName}
      </span>
    </div>
  );
};

interface CartItemBodyProps {
  item: ShoppingCartItemData;
  quantityUpdate: NumberInput;
  updateQuantityCommand: AsyncCommand;
  translation: CartItemBodyDetailsTranslation;
}

const CartItemBodyDetails = ({ item, quantityUpdate, updateQuantityCommand, translation }: CartItemBodyProps) => {
  const imgWidth = 80;
  const imgHeight = 140;

  return (
    <>
      <LoadableImage
        className={css.cartItemBodyImage}
        src={FinalVisualizationBuilder.buildUrl(item.code, undefined, undefined, imgWidth, imgHeight)}
        width={imgWidth}
        height={imgHeight}
        sharpeningFactor={1.5}
      />
      <div className={css.cartItemBodyDetails}>
        <CartItemDetails
          code={item.code}
          systemMessage={item.systemMessage}
          listPrice={item.listPrice}
          salePrice={item.salePrice}
          className={css.cartItemDetails}
          propertyClassNames={{
            propClassName: css.cartItemBodyDetailsProp,
            labelClassName: css.cartItemBodyDetailsLabel,
            valueClassName: css.cartItemBodyDetailsValue,
          }}
          translation={translation.cartItemDetails}
        />
        <Authorize policy={BuyerPolicy}>
          <ItemUpdateQuantityInput
            item={item}
            quantityUpdate={quantityUpdate}
            updateQuantityCommand={updateQuantityCommand}
            translation={translation}
          />
        </Authorize>
      </div>
    </>
  );
};

const ItemUpdateQuantityInput = observer(
  ({ item, quantityUpdate, updateQuantityCommand, translation }: CartItemBodyProps) => {
    const updateText = quantityUpdate.value > 0 ? translation.updateQuantityButton : translation.removeItemButton;

    return (
      <>
        <InputNumber
          className={css.cartItemQuantityInput}
          input={quantityUpdate}
          onChange={quantityUpdate.onChange}
          min={0}
        />
        {item.quantity !== quantityUpdate.value && (
          <div className={css.cartItemActionButtonsContainer}>
            <TertiaryButton className={css.cartItemActionButton} onClick={() => quantityUpdate.onChange(item.quantity)}>
              {translation.revertButton}
            </TertiaryButton>
            <ActionButton
              className={css.cartItemActionButton}
              buttonElement={SecondaryButton}
              command={updateQuantityCommand}
            >
              {updateText}
            </ActionButton>
          </div>
        )}
      </>
    );
  },
);
