import { CustomerData } from '../../app/data/model';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import Link from '../../app/shared/components/Link';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import configureImg from '../../images/onboarding/configure.svg';
import placeImg from '../../images/onboarding/place.svg';
import trackImg from '../../images/onboarding/track.svg';
import { Authorize } from '../authentication/Authorize';
import { OnboardingCardsTranslation } from '../localization/SiteTranslation';
import { BuyerPolicy } from '../Policy';
import RouteDefinitions from '../routing/RouteDefinitions';
import css from './Onboarding.css';

class OnboardingCardsBuilder {
  public static build(translation: OnboardingCardsTranslation, customer?: CustomerData) {
    const onboarding = [OnboardingCardsBuilder.buildCatalogCard(translation)];

    if (customer && BuyerPolicy.grantAccess(customer)) {
      onboarding.push(OnboardingCardsBuilder.buildOrderCart(translation));
    }

    onboarding.push(OnboardingCardsBuilder.buildManagingAndTrackingCard(translation));

    return onboarding;
  }

  private static buildCatalogCard(translation: OnboardingCardsTranslation) {
    const productsLink = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.catalogRoute));
    const favoritesLink = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.favoritesRoute));

    return {
      title: translation.configureChairTitle,
      img: { src: configureImg, alt: translation.configureChairText },
      description: (
        <>
          <p>
            {translation.selectAChairTextPart1}
            <Link to={productsLink}>{translation.selectAChairTextPart2}</Link>
            {translation.selectAChairTextPart3}
          </p>
          <Authorize policy={BuyerPolicy}>
            <p>
              {translation.addConfigToFavoritesTextPart1}{' '}
              <Link to={favoritesLink}>{translation.addConfigToFavoritesTextPart2}</Link>.
            </p>
          </Authorize>
        </>
      ),
    };
  }

  private static buildOrderCart(translation: OnboardingCardsTranslation) {
    const cartLink = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.cartRoute));

    return {
      title: translation.placeAnOrderTitle,
      img: { src: placeImg, alt: translation.placeAnOrderText },
      description: (
        <p>
          {translation.placeChairToCartTextPart1}
          <Link to={cartLink}>{translation.placeChairToCartTextPart2}</Link>.
        </p>
      ),
    };
  }

  private static buildManagingAndTrackingCard(translation: OnboardingCardsTranslation) {
    const orderHistoryLink = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.ordersRoute));

    return {
      title: translation.manageAndTrackTitle,
      img: { src: trackImg, alt: translation.manageAndTrackText, className: css.trackImage },
      description: (
        <>
          <p>
            {translation.manageOngoingOrdersTextPart1}
            <Link to={orderHistoryLink}>{translation.manageOngoingOrdersTextPart2}</Link>
            {translation.manageOngoingOrdersTextPart3}
          </p>
          <p>{translation.receiveNotificationsFromTrackingText}</p>
        </>
      ),
    };
  }
}

export default OnboardingCardsBuilder;
