import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import List from '../../../app/shared/components/List/List';
import { RelativeUrl } from '../../../app/shared/Url';
import LogoFlokkHub from '../LogoFlokkHub/LogoFlokkHub';
import css from './SideMenu.css';
import SideMenuItem, { SideMenuButton } from './SideMenuItem/SideMenuItem';
import { ISideMenuItemState } from './SideMenuItem/SideMenuItemState';
import { SideMenuState } from './SideMenuState';

export interface SideMenuProps {
  state: SideMenuState;
  className?: string;
  onSignOutClick?: () => void;
}

export interface MenuItem {
  name: string;
  url: RelativeUrl;
}

@observer
export default class SideMenu extends Component<SideMenuProps, {}> {
  constructor(props: SideMenuProps) {
    super(props);
  }

  handleLogoClick = () => {
    const {
      state: { setItemIsActive },
    } = this.props;
    setItemIsActive();
  };

  renderSideMenuItem = (item: ISideMenuItemState) => {
    const { setItemIsActive } = this.props.state;
    return <SideMenuItem url={item.url} name={item.name} isActive={item.isActive} onClick={setItemIsActive} />;
  };

  render() {
    const { className, state, onSignOutClick } = this.props;

    return state.isLoading ? null : (
      <aside className={classNames(css.SideMenu, className)}>
        <div className={css.container}>
          <div className={css.logoContainer}>
            <LogoFlokkHub
              linkProps={{ to: state.homepageUrl, onClick: this.handleLogoClick }}
              className={css.logo}
              color="light"
            />
          </div>
          <List items={state.items} itemRenderer={this.renderSideMenuItem} />

          {onSignOutClick && (
            <div className={css.SignOutContainer}>
              <SideMenuButton onClick={onSignOutClick}>{state.translation.signOutLabel}</SideMenuButton>
            </div>
          )}
        </div>
      </aside>
    );
  }
}
