import classNames from 'classnames';
import { observer } from 'mobx-react';

import ObsoleteConfigurationModal from '../../../../../configurator/components/ObsoleteConfigurationModal/ObsoleteConfigurationModal';
import OptionDescriptionModal from '../../../../../configurator/components/OptionDescriptionModal/OptionDescriptionModal';
import ResolveRestrictionsModal from '../../../../../configurator/components/ResolveRestrictionsModal/ResolveRestrictionsModal';
import NavButton from '../../../../../shared/components/Button/NavButton';
import Heading from '../../../../../shared/components/Typography/Heading/Heading';
import ModularComponentConfiguratorTabs from '../../ModularComponentConfigurator/ModularComponentConfiguratorTabs/ModularComponentConfiguratorTabs';
import ModularConfiguratorCoordinatorState from '../../ModularConfiguratorCoordinatorState';
import css from './ModularComponentConfigurator.css';

interface ModularComponentConfiguratorProps {
  state: ModularConfiguratorCoordinatorState;
}

const ModularComponentConfigurator = observer(({ state }: ModularComponentConfiguratorProps) => {
  return (
    <>
      <ObsoleteConfigurationModal
        modal={state.configurator.selectedComponent.coreState.obsoleteConfigurationModal}
        translation={state.configurator.selectedComponent.coreState.translation.obsoleteCodeModal}
      />
      <ResolveRestrictionsModal
        modal={state.configurator.selectedComponent.coreState.restrictionsModal}
        translation={state.configurator.selectedComponent.coreState.translation.restrictions}
      />
      <OptionDescriptionModal
        modal={state.configurator.selectedComponent.coreState.optionDescriptionModal}
        translation={state.configurator.selectedComponent.coreState.translation.optionDescriptionModal}
      />

      <section className={css.container}>
        <Heading level={2} visuallyHidden>
          {/* TODO: Replace with a translation */}
          Edit module
        </Heading>
        <NavButton className={classNames(css.backButton, css.inner)} onClick={state.setLayoutMode} back>
          {/* TODO: Replace with a translation */}
          All layouts
        </NavButton>
        <div className={css.content}>
          {state.configurator.isConfigureActionActive ? (
            <ModularComponentConfiguratorTabs state={state.configurator.selectedComponent.coreState.tabs} />
          ) : state.configurator.isMoveActionActive ? (
            <div className={css.inner}>
              position x ={' '}
              <input
                type="number"
                step="0.1"
                value={state.configurator.selectedComponent.sceneObjectsGroup.position.x}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.position.x = parseFloat(e.target.value))
                }
              />
              <br />
              position y ={' '}
              <input
                type="number"
                step="0.1"
                value={state.configurator.selectedComponent.sceneObjectsGroup.position.y}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.position.y = parseFloat(e.target.value))
                }
              />
              <br />
              position z ={' '}
              <input
                step="0.1"
                type="number"
                value={state.configurator.selectedComponent.sceneObjectsGroup.position.z}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.position.z = parseFloat(e.target.value))
                }
              />
              <br />
            </div>
          ) : (
            <div className={css.inner}>
              rotation x ={' '}
              <input
                type="number"
                step="0.1"
                value={state.configurator.selectedComponent.sceneObjectsGroup.rotation.x}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.rotation.x = parseFloat(e.target.value))
                }
              />
              <br />
              rotation y ={' '}
              <input
                type="number"
                step="0.1"
                value={state.configurator.selectedComponent.sceneObjectsGroup.rotation.y}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.rotation.y = parseFloat(e.target.value))
                }
              />
              <br />
              rotation z ={' '}
              <input
                type="number"
                step="0.1"
                value={state.configurator.selectedComponent.sceneObjectsGroup.rotation.z}
                onChange={(e) =>
                  (state.configurator.selectedComponent.sceneObjectsGroup.rotation.z = parseFloat(e.target.value))
                }
              />
              <br />
            </div>
          )}
        </div>
      </section>
    </>
  );
});

export default ModularComponentConfigurator;
