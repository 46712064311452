import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { CheckboxInput } from '../../Form';
import { ErrorMessages, ErrorMessagesStylesProps } from '../Input/ErrorMessages/ErrorMessages';
import css from './Checkbox.css';
import CheckboxElement, { CheckboxElementProps } from './CheckboxElement';

interface CheckboxProps extends CheckboxElementProps, ErrorMessagesStylesProps {
  input: CheckboxInput;
  labelClassName?: string;
  enabledErrorMessages?: boolean;
}

@observer
class Checkbox extends React.Component<CheckboxProps, {}> {
  constructor(props: CheckboxProps) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.input.onChange(e.target.checked);

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const {
      input,
      children,
      className,
      onChange,
      enabledErrorMessages,
      errorContainerClassName,
      errorItemClassName,
      errorMessageClassName,
      ...props
    } = this.props;

    const isCheckboxInvalid: boolean = input.errorMessages && input.errorMessages.any();

    return (
      <div className={classNames(css.Checkbox, className)}>
        <CheckboxElement checked={input.value} onChange={this.handleOnChange} invalid={isCheckboxInvalid} {...props}>
          {children}
        </CheckboxElement>

        {enabledErrorMessages && (
          <ErrorMessages
            errors={input.errorMessages}
            errorContainerClassName={errorContainerClassName}
            errorItemClassName={classNames(css.errorItem, errorItemClassName)}
            errorMessageClassName={errorMessageClassName}
          />
        )}
      </div>
    );
  }
}

export default Checkbox;
