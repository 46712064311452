import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../../app/AppState';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import List from '../../app/shared/components/List/List';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import RouteDefinitions from '../routing/RouteDefinitions';
import { EditConfigurationNameModal } from './EditConfigurationNameModal';
import EmptyFavoritesList from './EmptyFavoritesList';
import { FavoriteItem } from './FavoriteItem';
import { FavoriteItemDetailsModal } from './FavoriteItemDetailsModal';
import css from './FavoritesPage.css';
import { FavoritesState } from './FavoritesState';
import { RemovalConfirmationModal } from './RemovalConfirmationModal';

export interface Props {
  appState: AppState;
  state: FavoritesState;
}

@observer
export default class FavoritesPage extends Component<Props, never> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      favoriteItems,
      editConfigurationNameModal: editProductConfigurationModal,
      favoriteItemDetailsModal,
      removalConfirmationModal,
      translation,
    } = this.props.state;

    // TODO Where to store link like this one - (the same is created in cart page)?
    const catalogUrl = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.catalogRoute));

    return (
      <>
        <div className={css.favoritesPage}>
          <List
            className={css.favoritesList}
            items={favoriteItems}
            itemRenderer={(favoriteItem) => <FavoriteItem state={favoriteItem} />}
            itemClassName={css.favoritesListItem}
          />
        </div>
        <EditConfigurationNameModal state={editProductConfigurationModal} />
        <FavoriteItemDetailsModal state={favoriteItemDetailsModal} />
        <RemovalConfirmationModal state={removalConfirmationModal} />
        {favoriteItems.empty() && (
          <EmptyFavoritesList continueLink={catalogUrl} translation={translation.emptyFavoritesList} />
        )}
      </>
    );
  }
}
