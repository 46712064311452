import classNames from 'classnames';

import Link, { LinkProps } from '../Link';
import buttonCSS from './Button.css';
import css from './LinkButtons.css';
import secondaryButtonCSS from './SecondaryButton.css';
import tertiaryButtonCSS from './TertiaryButton.css';

export const ButtonLink = ({ className, ...linkProps }: LinkProps) => (
  <Link {...linkProps} className={classNames(css.LinkButton, buttonCSS.Button, className)} />
);

export const SecondaryButtonLink = ({ className, ...linkProps }: LinkProps) => (
  <ButtonLink {...linkProps} className={classNames(secondaryButtonCSS.SecondaryButton, className)} />
);

export const TertiaryButtonLink = ({ className, ...linkProps }: LinkProps) => (
  <ButtonLink {...linkProps} className={classNames(tertiaryButtonCSS.TertiaryButton, className)} />
);
