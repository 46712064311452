import { Data, Envelope, ExceptionData } from 'applicationinsights/out/Declarations/Contracts';

import ICustomTelemetryHandler from './ICustomTelemetryHandler';

class IgnoreExternalScriptErrorHandler implements ICustomTelemetryHandler {
  public tryHandle(envelope: Envelope) {
    if (envelope.data.baseType !== 'ExceptionData') {
      return false;
    }

    const { baseData } = envelope.data as Data<ExceptionData>;

    return baseData.exceptions.any((e) => e.typeName === 'Error' && e.message === 'Script error.');
  }
}

export default IgnoreExternalScriptErrorHandler;
