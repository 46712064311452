import classNames from 'classnames';
import { observer } from 'mobx-react';
import RcSwitch from 'rc-switch';
import 'rc-switch/assets/index.css';
import * as React from 'react';

import { SwitchInput } from '../../app/shared/Form';
import css from './Switch.css';

export type SwitchElementProps = React.ComponentProps<typeof RcSwitch> & {
  wrapperClassName?: string;
};

export const SwitchElement = ({ wrapperClassName: wrapperClassName, ...rest }: SwitchElementProps) => (
  <div className={classNames(css.switchWrapper, wrapperClassName)}>
    <RcSwitch {...rest} />
  </div>
);

export type SwitchProps = Omit<React.ComponentProps<typeof SwitchElement>, 'checked' | 'onChange' | 'disabled'> & {
  input: SwitchInput;
};

export const Switch = observer(({ input, ...rest }: SwitchProps) => (
  <SwitchElement checked={input.value} disabled={!input.isEnabled} onChange={input.onChange} {...rest} />
));
