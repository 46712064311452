import classNames from 'classnames';
import { Fragment } from 'react';

import Icon from '../Icon/Icon';
import Button, { ButtonProps } from './Button';
import css from './NavButton.css';

interface NavButton extends ButtonProps {
  back?: boolean;
}

const NavButton = ({ className, children, back, ...buttonProps }: NavButton) => {
  const finalClassName = classNames(css.NavButton, className);
  const nameClasses = classNames(back ? css.nameLeft : css.nameRight);

  const arrow = <Icon className={classNames(css.arrow, back && css.arrowLeft)} name="arrowRight" />;

  return (
    <Button {...buttonProps} className={finalClassName}>
      <Fragment>
        {back && arrow}
        <span className={nameClasses}>{children}</span>
        {!back && arrow}
      </Fragment>
    </Button>
  );
};

export default NavButton;
