import { action, makeObservable, observable } from 'mobx';

import { FilterData, OptionVariationData, PropertyData } from '../data/model';
import { FabricFiltersTranslation } from '../localization/SiteTranslation';
import { IFabricOptionVariationFilter } from './FabricOptionState';

export class PropertyBasedFabricFilterState implements IFabricOptionVariationFilter {
  data: FilterData;

  @observable.ref
  selected: PropertyData;

  @observable.shallow
  available: Array<PropertyData>;

  constructor(data: FilterData, translation: FabricFiltersTranslation) {
    makeObservable(this);
    this.data = data;

    this.available = [
      {
        name: translation.defaultFilterName,
        displayName: translation.defaultFilterName,
        value: translation.defaultFilterName,
        valueId: '',
      },
      ...data.availableProperties,
    ];

    this.selected = this.available[0];
  }

  @action.bound
  update(filterValue: PropertyData) {
    this.selected = filterValue;
  }

  @action.bound clear() {
    this.selected = this.available[0];
  }

  get isApplied() {
    if (!this.selected) {
      return false;
    }

    return this.available.indexOf(this.selected) > 0;
  }

  isSatisfied(variation: OptionVariationData): boolean {
    return variation.properties.findIndex((x) => x.valueId === this.selected.valueId) >= 0;
  }
}
