import classNames from 'classnames';
import { observer } from 'mobx-react';

import { PhysicalStoreData } from '../../../data/model';
import { StoreTypeTranslation } from '../../../localization/SiteTranslation';
import { TextLabelStrong } from '../../../shared/components/Typography/TextLabels';
import css from './Store.css';

interface StoreProps {
  data: PhysicalStoreData;
  translation: StoreTypeTranslation;
  className?: string;
}

const Store = observer(({ data, translation, className }: StoreProps) => {
  const storeType = data.storeTypes.map((x) => translation[x] || x);

  return (
    <button className={classNames(css.Store, className)}>
      <TextLabelStrong className={css.heading}>{data.name}</TextLabelStrong>
      <span className={css.address}>
        {data.address.line1} {data.address.line2}
      </span>
      <span>{data.address.city}</span>
      <span className={css.type}>{storeType.join(', ')}</span>
      <span className={css.type}>{data.brands.join(', ')}</span>
    </button>
  );
});

export default Store;
