import classnames from 'classnames';

import { BaseModelBasicData } from '../../../../data/model';
import { ProductModelsTranslation } from '../../../../localization/SiteTranslation';
import TextButton from '../../../../shared/components/Button/TextButton';
import Modal from '../../../../shared/components/Modal/Modal';
import HeaderLevel1 from '../../../../shared/components/Typography/HeaderLevel1';
import ModelSelector from '../ModelSelector/ModelSelector';
import { ProductModelsState } from '../ProductModelsState';
import ModelList from './ModelList/ModelList';
import css from './ProductModelsModal.css';

interface ProductModelsModalProps {
  state: Required<ProductModelsState>;
  translation: ProductModelsTranslation;
  className?: string;
}

const ProductModelsModal = ({ className, state, translation }: ProductModelsModalProps) => {
  const handleItemClick = (itemData: BaseModelBasicData) => {
    return state.navigateToModelConfigurator(itemData);
  };

  const handleTriggerClick = async () => {
    state.modal.open();

    if (state.selector.items.empty()) {
      return state.load();
    }
  };

  return (
    <div className={classnames(css.container, className)}>
      <TextButton className={css.trigger} onClick={handleTriggerClick}>
        {translation.chooseModelText}
      </TextButton>

      <Modal
        className={css.modal}
        headerWrapperClassName={css.header}
        contentClassName={css.content}
        modal={state.modal}
        headerComponent={<HeaderLevel1 className={css.title}>{translation.chooseModelText}</HeaderLevel1>}
        fullScreen
      >
        <ModelSelector
          component={ModelList}
          isLoading={state.isLoading}
          state={state.selector}
          translation={translation.modelSelectorWithLoader}
          onItemClick={handleItemClick}
        />
      </Modal>
    </div>
  );
};

export default ProductModelsModal;
