import { PaymentMethodData } from '../../../data/model';
import { PaymentMethodsTranslation } from '../../../localization/SiteTranslation';
import PaymentMethodSelector from '../PaymentMethodSelector/PaymentMethodSelector';
import SupportedCreditCardsMessage from './SupportedCreditCardsMessage/SupportedCreditCardsMessage';

interface PaymentMethodsProps {
  methods: PaymentMethodData[];
  selectedMethod: PaymentMethodData;
  translation: PaymentMethodsTranslation;
  onChange: (code: string) => void;
}

const PaymentMethods = ({ methods, onChange, selectedMethod, translation }: PaymentMethodsProps) => {
  const hasMultipleMethods = methods.length > 1;

  if (hasMultipleMethods) {
    return <PaymentMethodSelector options={methods} value={selectedMethod?.code} onChange={onChange} />;
  }

  if (selectedMethod.supportedCreditCardsImage) {
    return (
      <SupportedCreditCardsMessage
        image={selectedMethod.supportedCreditCardsImage}
        translation={translation.supportedCreditCardsMessage}
      />
    );
  }

  return null;
};

export default PaymentMethods;
