import IPreferredStoreProvider from './IPreferredStoreProvider';

export default class PreferredStoreProviderComposite implements IPreferredStoreProvider {
  private providers: Array<IPreferredStoreProvider>;

  constructor(implementations: IPreferredStoreProvider[]) {
    this.providers = implementations;
  }

  public getStoreId(): string {
    return this.getPreferred((x) => x.getStoreId);
  }

  public getLanguageCode(): string {
    return this.getPreferred((x) => x.getLanguageCode);
  }

  public getCountryCode(): string {
    return this.getPreferred((x) => x.getCountryCode);
  }

  public setStoreId(id: string): void {
    this.setPreferred((x) => x.setStoreId, id);
  }

  public setLanguageCode(code: string): void {
    this.setPreferred((x) => x.setLanguageCode, code);
  }

  public setCountryCode(code: string): void {
    this.setPreferred((x) => x.setCountryCode, code);
  }

  private getPreferred(selector: (impl: IPreferredStoreProvider) => () => string): string {
    const result = this.providers.map((x) => selector(x).bind(x)()).find((x) => x != null);
    if (result) {
      return result;
    }
    return null;
  }

  private setPreferred(selector: (impl: IPreferredStoreProvider) => (val: string) => void, value: string): void {
    this.providers.forEach((x) => {
      selector(x).bind(x)(value);
    });
  }
}
