import AppSettings from '../../app/AppSettings';
import { IApiClient } from '../../app/data/client';
import { GetDealerStoreQuery, ShoppingContext } from '../../app/data/model';
import { getB2cOrigin } from '../../app/shared/getB2cOrigin';
import { BasePdfOriginProvider } from '../../app/shared/PdfUrlProvider';

export class B2bPdfUrlProvider extends BasePdfOriginProvider {
  constructor(settings: AppSettings, private apiClient: IApiClient) {
    super(settings);
  }

  protected async getStoreAndLanguage(
    shoppingContext: ShoppingContext,
  ): Promise<{ storeId: string; languageCode: string }> {
    const response = await this.apiClient.send(new GetDealerStoreQuery({ shoppingContext }));
    const languageCode = response.store.languages.any((x) => x.code === shoppingContext.languageCode)
      ? shoppingContext.languageCode
      : 'en-GB';

    return { storeId: response.store.id, languageCode };
  }

  protected get origin(): string {
    return getB2cOrigin(this.settings.baseUrl);
  }
}
