import classNames from 'classnames';

import TertiaryButton from '../Button/TertiaryButton';
import Icon, { IconType } from '../Icon/Icon';
import Link, { LinkProps } from '../Link';
import List from '../List/List';
import HeaderLevel3 from '../Typography/HeaderLevel3';
import css from './Notification.css';

interface Props {
  className?: string;
  icon?: {
    name: IconType;
    colorGrey?: boolean;
    className?: string;
  };
  links?: Array<NotificationLinkProps>;
  title?: string;
  subtitle?: string;
}

const Notification = ({ className, icon, links, title, subtitle }: Props) => (
  <section className={classNames(css.Notification, className)}>
    {icon && (
      <Icon name={icon.name} className={classNames(css.icon, icon.colorGrey && css.colorGrey, icon.className)} />
    )}
    {title && <HeaderLevel3>{title}</HeaderLevel3>}
    {subtitle && <div className={css.subtitle}>{subtitle}</div>}

    {links && (
      <List className={css.linksContainer} items={links} itemRenderer={(link) => <NotificationLink {...link} />} />
    )}
  </section>
);

export interface NotificationLinkProps extends LinkProps {
  label: string;
  displayAs?: 'button' | 'link';
}

const NotificationLink = (props: NotificationLinkProps) => {
  const { displayAs, label, ...linkProps } = props;

  if (displayAs === 'button') {
    return <TertiaryButton className={css.button} itemElement={<Link {...linkProps}>{label}</Link>} />;
  }

  return (
    <Link {...linkProps} className={css.link}>
      {label}
    </Link>
  );
};

export default Notification;
