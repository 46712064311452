import { observer } from 'mobx-react';

import { SelectedOptionData } from '../../app/data/model';
import List from '../../app/shared/components/List/List';
import Modal from '../../app/shared/components/Modal/Modal';
import { ShoppingCartItemBasicInfo } from '../shared/ShoppingCartItemBasicInfo/ShoppingCartItemBasicInfo';
import { cartItemToPanelItem, ShoppingCartItemPanel } from '../shared/ShoppingCartItemPanel/ShoppingCartItemPanel';
import css from './FavoriteItemDetailsModal.css';
import { FavoriteItemModalState } from './FavoriteItemModalState';

export const FavoriteItemDetailsModal = observer(({ state }: { state: FavoriteItemModalState }) => {
  if (!state.favoriteItem) {
    return null;
  }

  return (
    <Modal modal={state} translation={{ closeButton: '' }}>
      <ShoppingCartItemPanel
        item={cartItemToPanelItem(state.favoriteItem)}
        translation={state.translation.shoppingCartItemPanel}
      >
        <ShoppingCartItemBasicInfo
          item={state.favoriteItem}
          translation={state.translation.shoppingCartItemBasicInfo}
        />

        <div>
          <span className={css.favoriteConfigurationTitle}>{state.translation.configurationText}</span>
          <List
            className={css.favoriteConfigurationOptions}
            itemClassName={css.favoriteConfigurationOptionsItem}
            items={state.favoriteItem?.options}
            itemRenderer={(item: SelectedOptionData) => (
              <>
                <span className={css.name}>{item.name}</span>
                <span className={css.variationName}>{item.variationName}</span>
                <span className={css.code}>{item.variationCode}</span>
                <br />
              </>
            )}
          />

          {state.favoriteItem?.accessories.any() && (
            <>
              <span className={css.favoriteConfigurationSubtitle}>{state.translation.accessoriesText}</span>
              <List
                className={css.favoriteConfigurationAccessories}
                itemClassName={css.favoriteConfigurationAccessoriesItem}
                items={state.favoriteItem?.accessories}
                itemRenderer={(item) => (
                  <>
                    <span className={css.name}>{item.name}</span>
                    <span className={css.code}>{item.code}</span>
                    <br />
                  </>
                )}
              />
            </>
          )}
        </div>
      </ShoppingCartItemPanel>
    </Modal>
  );
});
