import { action, makeObservable, observable } from 'mobx';

import ConfiguratorPageQueryParser from '../configurator/ConfiguratorPageQueryParser';
import { IConfiguratorMemento } from '../configurator/ConfiguratorState';
import { IImageBasedVisualizationState } from '../configurator/IVisualizationState';
import { VisualizationStateFactory } from '../configurator/VisualizationStateFactory';
import { IApiClient } from '../data/client';
import HttpClient from '../data/httpClient';
import { HttpError } from '../data/HttpError';
import {
  BaseModelData,
  BaseVisualizationData,
  GetProductByIdQuery,
  GetProductQueryResponse,
  GetStoreContextQueryResponse,
} from '../data/model';
import { BasePageState } from '../shared/BasePageState';
import { parseFeatureCode, parseVariationCode } from '../shared/common';
import { IImagePreloader } from '../shared/ImagePreloader';
import { INavigationService } from '../shared/NavigationService';
import { StoreState } from '../StoreState';
import { ProductViewer3dPageQueryParser } from './ProductViewer3dPageQueryParser';

interface IProductViewer3dMemento extends Pick<IConfiguratorMemento, 'storeContextResponse' | 'productResponse'> {}

export const originalImageWidth: number = 2000;
export const originalImageHeight: number = 2384;

export class ProductViewer3dPageState extends BasePageState<IProductViewer3dMemento> {
  width: number = originalImageWidth;
  height: number = originalImageHeight;
  shot: string = 'front';
  backgroundColor: string = '#ffffff';

  @observable
  isSceneReady: boolean;

  productResponse: GetProductQueryResponse;
  baseModel: BaseModelData;
  storeResponse: GetStoreContextQueryResponse;

  visualization: IImageBasedVisualizationState<BaseVisualizationData>;

  constructor(
    private readonly slug: string,
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly imagePreloader: IImagePreloader,
  ) {
    super();
    makeObservable(this);
    this.getDataFromQuery(navigation.currentUrl.query);
  }

  private getDataFromQuery(query: Map<string, string>) {
    const model = ProductViewer3dPageQueryParser.toModel(query);
    if (model.width) {
      this.setWidth(model.width);
    }
    if (model.height) {
      this.setHeight(model.height);
    }
    if (model.shot) {
      this.shot = model.shot;
    }
    if (model.background) {
      this.backgroundColor = `#${model.background}`;
    }
  }

  @action
  public async onLoad(store: StoreState) {
    const productResponse = await this.client.send(
      new GetProductByIdQuery({ productId: this.slug, shoppingContext: store.shoppingContext }),
    );

    this.initialize(store.storeResponse, productResponse);

    await this.ensureAllResourcesExist();
  }

  @action
  private initialize(storeResponse: GetStoreContextQueryResponse, response: GetProductQueryResponse) {
    const { baseModel } = response;
    this.storeResponse = storeResponse;
    this.productResponse = response;
    this.baseModel = baseModel;

    this.visualization = new VisualizationStateFactory(this.imagePreloader).create(response.visualization);

    const pageQuery = ConfiguratorPageQueryParser.toModel(this.navigation.currentUrl.query);

    this.visualization.initialize(
      response.visualization,
      new Map(
        [...pageQuery.options, ...pageQuery.accessories].map((x) => [parseFeatureCode(x), parseVariationCode(x)]),
      ),
    );
  }

  private async ensureAllResourcesExist() {
    const requiredResourcesUrls = this.visualization.getResourcesUrls();
    const httpClient = new HttpClient();

    let fetchResponses = [];

    try {
      fetchResponses = await Promise.all(requiredResourcesUrls.map((x) => httpClient.head(x)));
    } catch (error) {
      throw new HttpError(500, 'Unable to initialize 3d visualization');
    }

    if (fetchResponses.any((x) => x.status === 404)) {
      throw new HttpError(404, 'Visualization not found');
    }
  }

  getMemento() {
    const memento: IProductViewer3dMemento = {
      storeContextResponse: this.storeResponse,
      productResponse: this.productResponse,
    };

    return memento;
  }

  @action
  restoreMemento(memento: IProductViewer3dMemento) {
    this.initialize(memento.storeContextResponse, memento.productResponse);
  }

  @action
  private setWidth = (width: number) => {
    this.width = width;
  };

  @action
  private setHeight = (height: number) => {
    this.height = height;
  };

  @action
  setSceneReady = (isSceneReady: boolean) => {
    this.isSceneReady = isSceneReady;
  };
}
