import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, createElement } from 'react';
import Loadable from 'react-loadable';

import AppState from '../AppState';
import { Breakpoint, Orientation, Screen, ScreenObserver } from '../layout/ScreenObserver';
import { FabricWarning } from '../layout/Warnings/FabricWarning';
import { ProductInactiveWarning } from '../layout/Warnings/ProductInactiveWarning';
import { ProductNotAvailableInCurrentStoreWarning } from '../layout/Warnings/ProductNotAvailableInCurrentStoreWarning';
import { StackedWarnings } from '../layout/Warnings/StackedWarnings';
import BodyScroll from '../shared/components/BodyScroll/BodyScroll';
import IconButton from '../shared/components/Button/IconButton';
import CustomScrollbar from '../shared/components/CustomScrollbar/CustomScrollbar';
import Icon from '../shared/components/Icon/Icon';
import ImageZoomModal from '../shared/components/ImageZoomModal/ImageZoomModal';
import ThreeDotsLoader from '../shared/components/Loaders/ThreeDotsLoader';
import { AnimatedChair } from './animations/AnimatedChair/AnimatedChair';
import { AnimatedChairSliderNavigation } from './animations/AnimatedChairSliderNavigation/AnimatedChairSliderNavigation';
import { AnimatedProductModelsPanel } from './animations/AnimatedProductModelsPanel/AnimatedProductModelsPanel';
import { AnimatedSummaryTab } from './animations/AnimatedSummaryTab/AnimatedSummaryTab';
import { AnimationsManager } from './animations/AnimationsManager';
import ConfigurationNavigation from './components/ConfigurationNavigation/ConfigurationNavigation';
import ConfigurationShareModal from './components/ConfigurationShareModal/ConfigurationShareModal';
import ConfigurationSummary from './components/ConfigurationSummary/ConfigurationSummary';
import ChairNavigation from './components/ConfiguratorChair/ChairNavigation';
import ChairSliderNavigation from './components/ConfiguratorChair/ChairSliderNavigation';
import ConfiguratorPriceConsumer from './components/ConfiguratorPriceConsumer';
import TabContent from './components/ConfiguratorTabs/TabContent';
import { TabState } from './components/ConfiguratorTabs/TabState';
import ObsoleteConfigurationModal from './components/ObsoleteConfigurationModal/ObsoleteConfigurationModal';
import OptionDescriptionModal from './components/OptionDescriptionModal/OptionDescriptionModal';
import ProductModels from './components/ProductModels/ProductModels';
import { ProductViewer } from './components/ProductViewer/ProductViewer';
import ResolveRestrictionsModal from './components/ResolveRestrictionsModal/ResolveRestrictionsModal';
import css from './ConfiguratorPage.css';
import { ConfiguratorState } from './ConfiguratorState';
import { VisualizationHybridState } from './VisualizationHybridState';
import { VisualizationState } from './VisualizationState';

const ProductViewerHybrid = Loadable({
  loader: () => import('./components/ProductViewerHybrid/ProductViewerHybrid'),
  loading: () => <ThreeDotsLoader className={css.loadableComponentLoader} dotClassName={css.dot} size="medium" />,
});

export interface ConfiguratorPageProps {
  appState: AppState;
  state: ConfiguratorState;
  isFixed?: boolean;
}

interface ConfiguratorPageState {
  isMobilePortrait: boolean;
  isModelsAccordionExpanded: boolean;
}

@observer
class ConfiguratorPage extends Component<ConfiguratorPageProps, ConfiguratorPageState> {
  public static fabricWarningId = 'fabric-warning';

  private animationsManager: AnimationsManager;
  private initialAnimationStarted: boolean;

  constructor(props: ConfiguratorPageProps) {
    super(props);

    this.state = { isMobilePortrait: false, isModelsAccordionExpanded: false };
    this.handleOnChairVisualizationClick = this.handleOnChairVisualizationClick.bind(this);
    this.handleOnModelsAccordionClick = this.handleOnModelsAccordionClick.bind(this);
    this.collapseModelsAccordion = this.collapseModelsAccordion.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onScreenChange = this.onScreenChange.bind(this);

    this.animationsManager = new AnimationsManager(this.props.state.productResponse.tabs);
    this.initialAnimationStarted = false;
  }

  componentDidMount() {
    const { activeTab, inactiveDynamicsTabs } = this.props.state.coreState.tabs;

    this.animationsManager.getPreInitStyle(activeTab, inactiveDynamicsTabs).run();

    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
  }

  componentWillUnmount() {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
  }

  onScreenChange(screen: Screen) {
    const isMobile = screen.breakpoint === Breakpoint.Phone || screen.breakpoint === Breakpoint.Tablet;
    const isPortrait = screen.orientation === Orientation.Portrait;
    const isMobilePortrait = isMobile && isPortrait;

    this.setState({ isMobilePortrait });
    this.animationsManager.setMobilePortrait(isMobilePortrait);
  }

  componentDidUpdate() {
    const { activeTab } = this.props.state.coreState.tabs;
    if (!this.initialAnimationStarted && !this.props.appState.loadingImagesIndicator.isLoading) {
      this.animationsManager.runInitAnimation(activeTab);
      this.initialAnimationStarted = true;
    }
  }

  changeModelsAccordionState(value: boolean) {
    this.setState({ isModelsAccordionExpanded: value });
  }

  collapseModelsAccordion() {
    this.changeModelsAccordionState(false);
  }

  handleOnModelsAccordionClick(isExpanded: boolean) {
    const { load, selector } = this.props.state.productModelsState;
    const shouldLoadModels = isExpanded && selector.items.empty();

    this.changeModelsAccordionState(isExpanded);

    if (shouldLoadModels) {
      load();
    }
  }

  handleOnChairVisualizationClick() {
    if (!this.props.state.coreState.tabs.isSummaryTabActive) {
      this.collapseModelsAccordion();
      this.props.state.visualization.openZoom();
    }
  }

  changeTab(nextTab: TabState) {
    const { tabs } = this.props.state.coreState;
    const prevTab = tabs.activeTab;

    if (nextTab.code === prevTab.code) {
      return;
    }

    tabs.setActiveTab(nextTab.code);
    this.animationsManager.runChangeTabAnimation(prevTab, nextTab, tabs.inactiveDynamicsTabs);
  }

  renderSummary() {
    const { translation } = this.props.appState;
    const {
      isFixed,
      state: {
        coreState: { tabs },
      },
    } = this.props;
    const { isMobilePortrait } = this.state;

    return tabs.isSummaryTabActive ? (
      <BodyScroll active className={css.summaryContainer} isFixed={isFixed}>
        <AnimatedSummaryTab
          animations={this.animationsManager.animatedElements.summary.animations}
          className={classNames(css.animatedSummaryTab)}
        >
          <CustomScrollbar>
            <div className={css.summaryTabContainer}>
              <ConfigurationSummary
                configurator={this.props.state}
                store={this.props.appState.store}
                translation={translation.configurationPage}
                requestQuoteLinkTranslation={translation.requestQuoteLink}
                changeTab={(tab: TabState) => this.changeTab(tab)}
                className={css.summaryTab}
                isMobilePortraitViewActive={isMobilePortrait}
              />
            </div>
          </CustomScrollbar>
        </AnimatedSummaryTab>
      </BodyScroll>
    ) : null;
  }

  renderChair() {
    const {
      visualization,
      coreState: { tabs },
    } = this.props.state;
    const { translation } = this.props.appState;
    const { animatedElements } = this.animationsManager;

    const animatedChairClasses = classNames(
      css.animatedChairContainer,
      visualization.loadingImageIndicator.isLoading && css.isLoading,
      !tabs.isSummaryTabActive && css.chairActiveMode,
    );

    const arrowNavigation = visualization.shots.all.any() && (
      <AnimatedChairSliderNavigation
        animations={animatedElements.chairSliderNavigation}
        isSummaryTabActive={tabs.isSummaryTabActive}
      >
        <ChairSliderNavigation
          className={css.chairSliderNav}
          shots={visualization.shots}
          onClick={this.collapseModelsAccordion}
        />
      </AnimatedChairSliderNavigation>
    );

    const dotsNavigation = (
      <ChairNavigation
        className={css.chairNavigationInit}
        shots={visualization.shots}
        translation={translation.common.chairVisualization}
        displayHint
      />
    );

    const zoomInButton = <IconButton className={css.zoomInButton} icon={<Icon name="zoomIn" />} />;

    return (
      <AnimatedChair className={animatedChairClasses} animations={animatedElements.chair.animations}>
        <div className={css.chairContainer}>
          <ThreeDotsLoader className={css.partialImageLoader} dotClassName={css.dot} size="medium" />

          {arrowNavigation}

          <div className={css.chairPartsContainer} onClick={this.handleOnChairVisualizationClick}>
            {visualization instanceof VisualizationHybridState && (
              <ProductViewerHybrid
                state={visualization as VisualizationHybridState}
                imageClassName={css.partialImage}
                translation={translation.common.chairVisualization}
              />
            )}
            {visualization instanceof VisualizationState && (
              <ProductViewer imageUrls={visualization.imageUrls} imageClassName={css.partialImage} />
            )}
          </div>

          {dotsNavigation}
          {zoomInButton}
        </div>
      </AnimatedChair>
    );
  }

  renderProductModels() {
    const {
      state: { productModelsState, translation },
    } = this.props;
    const { isModelsAccordionExpanded } = this.state;

    return (
      <AnimatedProductModelsPanel
        className={css.productModelsContainer}
        animations={this.animationsManager.animatedElements.productModels.animations}
      >
        <ProductModels
          isAccordionExpanded={isModelsAccordionExpanded}
          state={productModelsState}
          translation={translation.productModels}
          onAccordionClick={this.handleOnModelsAccordionClick}
        />
      </AnimatedProductModelsPanel>
    );
  }

  renderTabs() {
    const {
      isFixed,
      state: {
        coreState: { tabs },
      },
    } = this.props;

    return tabs.tabs.map((tab, key) => {
      return (
        tab.component && (
          <TabContent
            key={key}
            tabState={tab}
            containerAnimations={this.animationsManager.animatedElements.tabContainer}
            onClick={this.changeTab}
            animationState={this.animationsManager.animatedElements.tabsContent[tab.code]}
            animatedTabClassName={css.animatedTab}
            animatedTabContainerClassName={css.animatedTabContainer}
            isFixed={isFixed}
          >
            {createElement(tab.component, { ...tab.componentProps })}
          </TabContent>
        )
      );
    });
  }

  render() {
    const { state, appState, isFixed } = this.props;
    const { translation } = this.props.appState;
    const { tabs } = this.props.state.coreState;

    // This is needed in order to call componentWillUpdate method when indicator state changes.
    // tslint:disable-next-line:no-unused-variable
    const isLoadingImages = this.props.appState.loadingImagesIndicator.isLoading;

    const configuratorPageStyle = {
      display: appState.loadingPageIndicator.isLoading ? 'none' : 'flex',
    };

    const canBePurchased = this.props.appState.store.canBePurchased(this.props.state.baseModel);
    const { isSummaryTabActive } = this.props.state.coreState.tabs;

    return (
      <div
        style={configuratorPageStyle}
        className={classNames(css.container, {
          [css.fixed]: isFixed,
        })}
      >
        {/* Modals */}
        <ImageZoomModal
          state={state.visualization.zoomModal}
          translation={translation.zoomModal}
          visualizationTranslation={translation.common.chairVisualization}
        />
        <StackedWarnings>
          <FabricWarning
            key={ConfiguratorPage.fabricWarningId}
            state={state.fabricColorsDisclaimerState}
            customerServiceLink={appState.navigationState.customerServiceUrl}
            translation={translation.disclaimer}
          />
          <ProductNotAvailableInCurrentStoreWarning
            state={state.productNotAvailableInCurrentStoreState}
            translation={translation.configurationPage.productNotAvailableInCurrentStoreWarning}
          />
          <ProductInactiveWarning
            state={state.productInactiveWarningState}
            translation={translation.configurationPage.productInactiveWarning}
          />
        </StackedWarnings>
        <ObsoleteConfigurationModal
          modal={state.coreState.obsoleteConfigurationModal}
          translation={translation.configurationPage.obsoleteCodeModal}
        />
        <ResolveRestrictionsModal
          modal={state.coreState.restrictionsModal}
          translation={translation.configurationPage.restrictions}
        />
        <OptionDescriptionModal
          modal={state.coreState.optionDescriptionModal}
          translation={translation.configurationPage.optionDescriptionModal}
        />
        <ConfigurationShareModal
          state={state.shareState}
          modal={state.configurationShareModal}
          translation={translation.configurationPage.summary.shareSection}
          isCodeLoading={state.coreState.configurationCodeLoading}
          isCodeError={state.coreState.configurationCodeError}
          showDownloads={true}
        />

        <header className={css.header}>
          <ConfigurationNavigation
            className={css.configurationNavigationDesktop}
            changeTab={this.changeTab}
            tabs={tabs.tabs}
            price={state.coreState.unitPrice}
            priceAnimations={this.animationsManager.animatedElements.price}
            canBePurchased={canBePurchased}
          />
        </header>

        <ConfiguratorPriceConsumer canBePurchased={canBePurchased}>
          <main className={classNames(css.main, isSummaryTabActive && css.isSummaryTabActive)}>
            <div className={css.tabContainer}>
              {this.renderTabs()}
              {this.renderSummary()}
            </div>

            <div className={css.previewContainer}>
              {this.renderChair()}
              {this.renderProductModels()}
            </div>

            {/*Duplicated ConfigurationNavigation for mobile */}
            <div className={css.configurationNavigationMobileContainer}>
              <div className={css.configurationNavigationMobileWrapper}>
                <ConfigurationNavigation
                  className={css.configurationNavigationMobile}
                  changeTab={this.changeTab}
                  tabs={tabs.tabs}
                  canBePurchased={canBePurchased}
                />
              </div>
            </div>
          </main>
        </ConfiguratorPriceConsumer>
      </div>
    );
  }
}

export default ConfiguratorPage;
