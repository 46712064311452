import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { DaysRangeData, OrderData } from '../../app/data/model';
import { Alert } from '../../app/shared/alert/Alert';
import { formatISODateStringToDottedDate } from '../../app/shared/commonDate';
import { EditButton, TrackButton, TrackButtonLink } from '../../app/shared/components/Button/CustomButtons';
import SecondaryButton from '../../app/shared/components/Button/SecondaryButton';
import { InputFieldWithLabel } from '../../app/shared/components/Input/InputFieldWithLabel';
import { ConfirmationModal } from '../../app/shared/components/Modal/ConfirmationModal/ConfirmationModal';
import { PropertyItemVertical } from '../../app/shared/components/PropertyList/PropertyItemVertical/PropertyItemVertical';
import { Authorize } from '../authentication/Authorize';
import {
  OrderActionButtonsTranslation,
  ShippingDateTranslation,
  TrackingInformationTranslation,
} from '../localization/SiteTranslation';
import { BuyerPolicy } from '../Policy';
import { EditOrderModalState } from './EditOrderModalState';
import { OrderDetailsPanel } from './OrderDetailsPanel/OrderDetailsPanel';
import { OrderDetailsProducts } from './OrderDetailsProducts/OrderDetailsProducts';
import css from './OrderPage.css';
import { OrderProgressIndicator } from './OrderProgressIndicator/OrderProgressIndicator';
import { OrderShipmentsModal } from './OrderShipmentsModal/OrderShipmentsModal';
import { ExpectedShippingStatus, getExpectedShippingStatus, OrderShipmentsModalState, OrderState } from './OrderState';
import { ReorderModalState } from './ReorderModalState';

interface Props {
  state: OrderState;
}

@observer
export default class OrderPage extends Component<Props> {
  render() {
    const {
      order,
      orderItems,
      openEditNameModal,
      editOrderModal,
      reorderModal,
      orderShipmentsModal,
      shippingDate,
      shippingAndTrackingEnabled,
      translation,
    } = this.props.state;

    if (!order) {
      return null;
    }

    return (
      <div className={css.OrderPage}>
        {order.erpChangesNotSynchronized && (
          <Alert className={css.alertDanger} size="large">
            {translation.changeInOrderAlertText}
          </Alert>
        )}

        <OrderActionButtons
          order={order}
          openEditNameModal={openEditNameModal}
          openReorderModal={reorderModal.open}
          translation={translation.orderActionButtons}
        />

        <div className={css.orderProgressContainer}>
          <OrderProgressIndicator className={css.progressIndicator} history={order.history} />
          <div className={css.trackOrderContainer}>
            {shippingAndTrackingEnabled && (
              <>
                <TrackingInformation
                  orderShipmentsModal={orderShipmentsModal}
                  translation={translation.trackingInformation}
                />
                <ShippingDate
                  className={orderShipmentsModal.shipments.empty() && css.deliveryDate}
                  realShippingDate={shippingDate}
                  expectedShipping={order.expectedShipping}
                  translation={translation.shippingDate}
                />
              </>
            )}
          </div>
        </div>

        <OrderDetailsPanel order={order} translation={translation.orderDetailsPanel} />
        <OrderDetailsProducts orderItems={orderItems} translation={translation.orderDetailsProduct} />

        <EditOrderModal state={editOrderModal} orderNumber={order.number} />
        <OrderShipmentsModal state={orderShipmentsModal} />
        <ReorderModal state={reorderModal} orderNumber={order.number} />
      </div>
    );
  }
}

interface OrderActionButtons {
  order: OrderData;
  openEditNameModal: () => void;
  openReorderModal: () => void;
  translation: OrderActionButtonsTranslation;
}

const OrderActionButtons = observer(
  ({ order, openEditNameModal, openReorderModal, translation }: OrderActionButtons) => {
    return (
      <div className={css.orderActionButtonsContainer}>
        <div className={css.orderActionButtons}>
          {order.customReference && (
            <PropertyItemVertical
              label={translation.yourReferenceLabel}
              labelClassName={css.label}
              value={order.customReference}
            />
          )}
          <PropertyItemVertical
            className={css.orderCustomNameContainer}
            label={translation.yourReferenceLabel}
            labelClassName={css.label}
            value={
              <div>
                {order.customName}
                <Authorize policy={BuyerPolicy}>
                  {!order.customName && translation.setOrderNameText}
                  <EditButton className={css.orderPageEditButton} onClick={openEditNameModal} />
                </Authorize>
              </div>
            }
          />
        </div>
        <Authorize policy={BuyerPolicy}>
          <SecondaryButton onClick={openReorderModal}>{translation.addEntireOrderToCartButton}</SecondaryButton>
        </Authorize>
      </div>
    );
  },
);

const TrackingInformation = ({
  orderShipmentsModal,
  translation,
}: {
  orderShipmentsModal: OrderShipmentsModalState;
  translation: TrackingInformationTranslation;
}) => {
  const shipments = orderShipmentsModal.shipments;

  if (shipments.empty()) {
    return <></>;
  }

  if (shipments.length === 1) {
    return (
      <TrackButtonLink className={css.trackButton} link={{ href: shipments[0].shipment.trackingUrl, target: '_blank' }}>
        {translation.trackOrderButton}
      </TrackButtonLink>
    );
  }

  return (
    <TrackButton className={css.trackButton} onClick={orderShipmentsModal.open}>
      {translation.trackOrderButton}
    </TrackButton>
  );
};

interface ShippingDateProps {
  className?: string;
  realShippingDate?: string;
  expectedShipping?: DaysRangeData;
  translation: ShippingDateTranslation;
}

const ShippingDate = ({ className, realShippingDate, expectedShipping, translation }: ShippingDateProps) => {
  const expectedShippingStatus = expectedShipping ? getExpectedShippingStatus(expectedShipping) : null;

  return (
    <div className={classNames(css.trackOrder, className)}>
      {realShippingDate && (
        <>
          <div className={css.estimatedDeliveryLabel}>{translation.shippingDateText}</div>
          <div className={css.estimatedDate}>{formatISODateStringToDottedDate(realShippingDate)}</div>
        </>
      )}

      {expectedShipping && (
        <>
          <div className={css.estimatedDeliveryLabel}>{translation.shippingTimeText}</div>
          {expectedShippingStatus === ExpectedShippingStatus.Range && (
            <div className={css.estimatedDate}>
              {translation.withinDaysTextFormat.interpolate([
                ['minDays', expectedShipping.minDays.toString()],
                ['maxDays', expectedShipping.maxDays.toString()],
              ])}
            </div>
          )}
          {expectedShippingStatus === ExpectedShippingStatus.Soon && (
            <div className={css.estimatedDate}>{translation.soonText}</div>
          )}
          {expectedShippingStatus === 'delayed' && <div className={css.estimatedDate}>{translation.delayText}</div>}
        </>
      )}
    </div>
  );
};

// TODO move modals to separate file
const ReorderModal = observer(({ state, orderNumber }: { state: ReorderModalState; orderNumber: string }) => (
  <ConfirmationModal
    modal={state}
    title={state.translation.orderTextFormat.interpolate([['orderNumber', orderNumber]])}
    applyButton={{ name: state.translation.confirmButton, command: state.reorderCommand }}
  >
    <p>{state.translation.areYouSureText}</p>
  </ConfirmationModal>
));

const EditOrderModal = observer(({ state, orderNumber }: { state: EditOrderModalState; orderNumber: string }) => (
  <ConfirmationModal
    modal={state}
    title={state.translation.orderTextFormat.interpolate([['orderNumber', orderNumber]])}
    applyButton={{ name: state.translation.saveButton, command: state.updateOrderCommand }}
  >
    <InputFieldWithLabel input={state.customNameInput} label={state.translation.nameLabel} autoFocus={true} />
  </ConfirmationModal>
));
