import { action, makeObservable, observable, override } from 'mobx';

import { ConfigurationShareState } from '../configurator/ConfigurationShareState';
import { IApiClient } from '../data/client';
import { HttpError } from '../data/HttpError';
import {
  GetProductConfigurationByCodeQuery,
  GetProductConfigurationByCodeQueryResponse,
  ProductConfigurationOrigin,
  ShoppingContext,
} from '../data/model';
import {
  ChairVisualizationTranslation,
  ConfigurationDetailsPageTranslation,
  ModelViewerTranslation,
  ZoomModalTranslation,
} from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { IShareConfiguratorUrlProvider } from '../shared/BaseShareConfiguratorUrlProvider';
import { PageMetadata } from '../shared/common';
import ModelViewerState, { modelViewerImagesBuilder } from '../shared/components/ModelViewer/ModelViewerState';
import { IImagePreloader } from '../shared/ImagePreloader';
import { INavigationService } from '../shared/NavigationService';
import { IPdfUrlProvider } from '../shared/PdfUrlProvider';
import { StoreState } from '../StoreState';

export interface IConfigurationDetailsPageMemento {
  shoppingContext: ShoppingContext;
  code: string;
  details: GetProductConfigurationByCodeQueryResponse;
}

export class ConfigurationDetailsPageState extends BasePageState<IConfigurationDetailsPageMemento> {
  @override
  get metadata(): PageMetadata {
    const title = this.translations.pageTitleFormat.interpolate([['code', this.code]]);
    const description = this.translations.pageDescriptionFormat.interpolate([
      ['productName', this.details.productName],
      ['options', this.details.options.map((x) => `${x.name} : ${x.variationName}`).join(', ')],
      ['accessories', this.details.options.map((x) => `${x.name} : ${x.variationName}`).join(', ')],
    ]);
    const imageUrl = this.modelViewer.items.any() ? this.modelViewer.items[0].data.url : undefined;

    return {
      title: title,
      description: description,
      imageUrl: imageUrl,
    };
  }

  @observable public shoppingContext: ShoppingContext;
  @observable public code: string;
  @observable.ref public details: GetProductConfigurationByCodeQueryResponse;

  public modelViewer: ModelViewerState;
  public shareState: ConfigurationShareState;

  constructor(
    translation: ConfigurationDetailsPageTranslation,
    readonly chairVisualizationTranslation: ChairVisualizationTranslation,
    readonly zoomModalTranslation: ZoomModalTranslation,
    private readonly modelViewerTranslation: ModelViewerTranslation,
    navigation: INavigationService,
    private readonly client: IApiClient,
    private readonly imagePreloader: IImagePreloader,
    pdfUrlProvider: IPdfUrlProvider,
    shareConfiguratorUrlProvider: IShareConfiguratorUrlProvider,
    code: string,
  ) {
    super(translation);
    makeObservable(this);
    this.code = code;
    this.modelViewer = new ModelViewerState(this.imagePreloader, [], this.modelViewerTranslation);
    this.shareState = new ConfigurationShareState(
      client,
      navigation,
      imagePreloader,
      pdfUrlProvider,
      shareConfiguratorUrlProvider,
      () => ({
        productNumber: this.details.productNumber,
        query: {
          code: this.code,
          options: this.details.options.map((x) => x.variationId),
          accessories: this.details.accessories.map((x) => x.variationId),
          quantity: 1,
          step: undefined,
        },
        visualizationMode: this.details.visualizationMode,
      }),
    );
  }

  @action
  async onLoad(store: StoreState) {
    if (!this.code) {
      throw new HttpError(400, 'Configuration code not defined');
    }

    const response = await this.client.send(
      new GetProductConfigurationByCodeQuery({ code: this.code, shoppingContext: store.shoppingContext }),
    );

    // TODO: sync vs async
    await this.initialize(store.shoppingContext, this.code, response);
  }

  private async initialize(
    context: ShoppingContext,
    code: string,
    details: GetProductConfigurationByCodeQueryResponse,
  ) {
    this.shoppingContext = context;
    this.code = code;
    this.details = details;

    await this.shareState.onLoad(context);
    this.shareState.setCode(code);

    if (details.configuration.origin === ProductConfigurationOrigin.DefaultConfigurator) {
      const images = modelViewerImagesBuilder(code);
      this.modelViewer.setItems(images);
    }
  }

  getMemento(): IConfigurationDetailsPageMemento {
    return {
      shoppingContext: this.shoppingContext,
      code: this.code,
      details: this.details,
    };
  }

  restoreMemento(memento: IConfigurationDetailsPageMemento) {
    this.initialize(memento.shoppingContext, memento.code, memento.details);
  }
}
