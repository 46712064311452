import { action, autorun, computed, makeObservable, observable } from 'mobx';

import AppSettings from '../AppSettings';
import { IApiClient } from '../data/client';
import {
  GeolocationData,
  GetPhysicalStoresQuery,
  GetPhysicalStoresResponse,
  PhysicalStoreData,
  ShoppingContext,
} from '../data/model';
import { StoreLocatorPageTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { IMemento } from '../shared/common';
import { EmbeddedQuery, EmbeddedQueryParser } from '../shared/EmbeddedQueryParser';
import { INavigationService } from '../shared/NavigationService';
import { StoreState } from '../StoreState';
import StoreFiltersState from './components/StoreFilters/StoreFilterState';
import StoresListState from './components/StoreList/StoreListState';
import StoreLocatorInputState from './components/StoreLocationInput/StoreLocatorInputState';
import StoreMapState from './components/StoreMap/StoreMapState';
import { StoreLocatorQuery, StoreLocatorQueryParser } from './StoreLocatorQuery';

export interface IStoreLocatorMemento extends IMemento {
  shoppingContext: ShoppingContext;
  storesResponse: GetPhysicalStoresResponse;
}

export default class StoreLocatorState extends BasePageState<IStoreLocatorMemento> {
  private navigation: INavigationService;
  private apiClient: IApiClient;
  private shoppingContext: ShoppingContext;
  private storesResponse: GetPhysicalStoresResponse;

  public translation: StoreLocatorPageTranslation;
  public mapState: StoreMapState;
  public listState: StoresListState;
  public filterState: StoreFiltersState;
  public storeLocatorInputState: StoreLocatorInputState;

  @observable.deep
  public queryObject: StoreLocatorQuery;
  public embeddedQuery: EmbeddedQuery;

  @observable
  public listVisible: boolean;

  @observable.shallow
  public physicalStores: Array<PhysicalStoreData> = [];

  @observable.ref
  public selectedItem: PhysicalStoreData;

  constructor(
    apiClient: IApiClient,
    settings: AppSettings,
    navigation: INavigationService,
    translation: StoreLocatorPageTranslation,
  ) {
    super(translation);
    makeObservable(this);
    this.translation = translation;
    this.apiClient = apiClient;
    this.navigation = navigation;

    this.queryObject = StoreLocatorQueryParser.toModel(this.navigation.currentUrl.query);
    this.embeddedQuery = EmbeddedQueryParser.toModel(this.navigation.currentUrl.query);

    this.mapState = new StoreMapState(null, settings.googleMapApiKey);
    this.listState = new StoresListState(this.translation);
    this.filterState = new StoreFiltersState();
    this.storeLocatorInputState = new StoreLocatorInputState();
    this.listState.onStoreSelected.subscribe((a) => this.openStoreDetails(a));

    this.storeLocatorInputState.onChange.subscribe((a) => {
      this.mapState.setCenter(a.location, true);
      this.mapState.setFocusToBounds(a.bounds);
      this.mapState.setLocationInput(a.location);
    });

    autorun(() => {
      if (this.mapState.isGoogleMapInitialized) {
        this.storeLocatorInputState.setGoogleMapApiInitialization();
        this.listState.setGoogleMapApiInitialization();
      }
    });
  }

  @action
  private updateQuery() {
    const model = StoreLocatorQueryParser.toModel(this.navigation.currentUrl.query);
    model.brands = this.filterState.selectedBrands;
    model.storeTypes = this.filterState.selectedStoreTypes;
    const query = StoreLocatorQueryParser.toQuery(model);
    this.navigation.setQuery(query);
  }

  private initialize(shoppingContext: ShoppingContext, response: GetPhysicalStoresResponse) {
    this.shoppingContext = shoppingContext;
    this.mapState.setLanguage(shoppingContext.languageCode);

    this.refreshStores(response, true);
    this.filterState.initialize(
      response.availableBrands,
      this.queryObject.brands,
      response.availableStoreTypes,
      this.queryObject.storeTypes,
    );

    this.filterState.onChange.subscribe(() => {
      this.updateQuery();
      this.updateStores().then(() => {
        this.mapState.updateClusters();
      });
    });
  }

  startTrackingMapCenter() {
    autorun(async () => {
      if (this.mapState.shouldSort) {
        this.updateStores();
        let response = await this.loadStores(
          this.shoppingContext,
          this.mapState.center,
          this.filterState.selectedBrands,
          this.filterState.selectedStoreTypes,
        );
        this.refreshStores(response, false);
      }
    });
  }

  @computed
  get storesNumber() {
    return this.listState.stores.length;
  }

  @action.bound
  openStoreDetails(store: PhysicalStoreData) {
    this.selectStore(store);
  }

  @action.bound
  closeStoreDetails() {
    this.openStoreList();
    this.deselectStore();
  }

  @action.bound
  openStoreList() {
    this.listVisible = true;
  }

  @action.bound
  closeStoreList() {
    this.listVisible = false;
  }

  @action.bound
  deselectStore() {
    this.selectedItem = null;
    this.mapState.deselectItem();
  }

  @action.bound
  showMap() {
    this.listVisible = false;
    this.mapState.refreshMap();
  }

  @action.bound
  selectStore(item: PhysicalStoreData) {
    this.selectedItem = item;
    this.openStoreList();
    this.mapState.setSelectedItem(item);
  }

  @action
  clearSelectedStore() {
    this.selectedItem = null;
    this.mapState.clearSelectedItem();
  }

  @action.bound
  async updateStores() {
    let result = await this.loadStores(
      this.shoppingContext,
      this.mapState.center,
      this.filterState.selectedBrands,
      this.filterState.selectedStoreTypes,
    );
    this.refreshStores(result, false);
  }

  @action
  async onLoad(store: StoreState) {
    const response = await this.loadStores(
      store.shoppingContext,
      null,
      this.queryObject.brands,
      this.queryObject.storeTypes,
    );
    this.initialize(store.shoppingContext, response);
    this.startTrackingMapCenter();
  }

  private async loadStores(
    shoppingContext: ShoppingContext,
    coords: GeolocationData,
    brands: string[],
    storeTypes: string[],
  ) {
    const query = new GetPhysicalStoresQuery({
      shoppingContext: shoppingContext,
      sortingCenter: coords,
      brands: brands,
      storeTypes: storeTypes,
    });
    const response = await this.apiClient.send(query);
    return response;
  }

  @action.bound
  public async centerForAutocomplete(latLng: google.maps.LatLngLiteral) {
    this.mapState.setCenter(latLng, true);
  }

  @action
  private refreshStores(response: GetPhysicalStoresResponse, shouldAdjustViewport: boolean) {
    this.storesResponse = response;
    this.physicalStores = response.physicalStores;

    if (this.selectedItem != null && !this.physicalStores.find((x) => x.id === this.selectedItem.id)) {
      this.clearSelectedStore();
    }

    this.listState.setStores(this.physicalStores);
    this.mapState.setStores(this.physicalStores, shouldAdjustViewport);
  }

  public getDirections(item: PhysicalStoreData) {
    return `https://maps.google.com/?daddr=${item.coordinates.lat},${item.coordinates.lng}&zoom=${this.mapState.zoom}`;
  }

  getMemento(): IStoreLocatorMemento {
    return {
      shoppingContext: this.shoppingContext,
      storesResponse: this.storesResponse,
    };
  }

  restoreMemento(memento: IStoreLocatorMemento) {
    this.initialize(memento.shoppingContext, memento.storesResponse);
    this.startTrackingMapCenter();
  }
}
