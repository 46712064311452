import { action, makeObservable, observable } from 'mobx';
import { Moment } from 'moment';

import { OrderSortColumn, OrderSortDirection } from '../../../app/data/model';
import { SwitchInput, TextInput } from '../../../app/shared/Form';
import { OrderListFiltersTranslation } from '../../localization/SiteTranslation';
import { OrderQuery } from '../OrderQueryParser';

interface ISortOrderItem {
  label: string;
  column: OrderSortColumn;
  direction: OrderSortDirection;
  key?: string;
}

export class OrderListFiltersState {
  public searchInput: TextInput = new TextInput();
  public onlyMineInput: SwitchInput = new SwitchInput(true);

  @observable public dateFrom: Moment;
  @observable public dateTo: Moment;

  sortOrderItems: Array<ISortOrderItem>;
  @observable selectedSortOrderItem: ISortOrderItem;

  constructor(query: OrderQuery, public readonly translation: OrderListFiltersTranslation) {
    makeObservable(this);
    this.sortOrderItems = [
      {
        label: translation.sortByOrderNumberAscLabel,
        column: OrderSortColumn.OrderNumber,
        direction: OrderSortDirection.Ascending,
      },
      {
        label: translation.sortByOrderNumberDescLabel,
        column: OrderSortColumn.OrderNumber,
        direction: OrderSortDirection.Descending,
      },
      {
        label: translation.sortByOrderDateAscLabel,
        column: OrderSortColumn.OrderDate,
        direction: OrderSortDirection.Ascending,
      },
      {
        label: translation.sortByOrderDateDescLabel,
        column: OrderSortColumn.OrderDate,
        direction: OrderSortDirection.Descending,
      },
      {
        label: translation.sortByOrderTotalAscLabel,
        column: OrderSortColumn.OrderTotal,
        direction: OrderSortDirection.Ascending,
      },
      {
        label: translation.sortByOrderTotalDescLabel,
        column: OrderSortColumn.OrderTotal,
        direction: OrderSortDirection.Descending,
      },
    ];

    this.sortOrderItems.forEach((x) => (x.key = x.column.toString() + x.direction.toString()));

    this.setSearchPhrase(query.phrase);
    this.setSelectedSortOrderItem(this.sortOrderItems[3]);
  }

  setSearchPhrase(phrase: string) {
    this.searchInput.value = phrase;
  }

  @action.bound
  setDateRange(dateFrom: Moment, dateTo: Moment) {
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  @action.bound
  setSelectedSortOrderItem(item: ISortOrderItem) {
    this.selectedSortOrderItem = item;
  }

  @action.bound
  public clearFilters() {
    this.dateFrom = null;
    this.dateTo = null;
    this.searchInput.value = '';
    this.onlyMineInput.onChange(true);
  }
}
