import { Storyboard } from '../shared/animations';
import { AnimatedElementsState } from './animations/AnimatedElementsState';
import { TabState } from './components/ConfiguratorTabs/TabState';
import { Tab } from './Tab';

export interface IInitTabDelaysProps {
  tabInitAnimationDelay?: number;
  priceDelay?: number;
  productModelsPanelDelay?: number;
  chairNavigationDelay?: number;
  summaryShowDelay?: number;
  summaryChairDelay?: number;
}

interface ISummaryShowDelays {
  moveLeftChairDelay?: number;
  showSummaryDelay?: number;
}

interface ISummaryHideDelays {
  showTabContentDelay?: number;
  hideSummaryDelay?: number;
  lockSummaryDelay?: number;
}

export interface ISummaryDelaysProps {
  showSummary: ISummaryShowDelays;
  hideSummary: ISummaryHideDelays;
}

interface ISummaryHideProps {
  nextTab: TabState;
  tabsToHideStoryboards: Array<Storyboard>;
  delays: ISummaryHideDelays;
}

interface ISwitchTabProps {
  prevTab: TabState;
  nextTab: TabState;
  inactiveDynamicsTabs: Array<TabState>;
  isMobilePortrait: boolean;
  delays?: ISummaryDelaysProps;
}

export class ConfiguratorStoryboards {
  initTab: (activeTab: TabState, delays: IInitTabDelaysProps) => Storyboard;
  preInitStyle: (inactiveDynamicsTabs: Array<TabState>, activeTab: TabState) => Storyboard;

  switchTab: (switchTabProps: ISwitchTabProps) => Storyboard;
  changeTab: (changeTabProps: ISwitchTabProps) => Storyboard;

  showSummary: (showSummaryProps: ISummaryShowDelays) => Storyboard;
  hideSummary: (hideSummaryProps: ISummaryHideProps) => Storyboard;

  constructor(componentList: AnimatedElementsState) {
    this.preInitStyle = (inactiveDynamicsTabs: Array<TabState>, activeTab: TabState) => {
      let storyboard = new Storyboard();

      let tabsInitStyle = inactiveDynamicsTabs.map((tab) => componentList.tabsContent[tab.code].animations.initStyle);
      let tabsToLock = inactiveDynamicsTabs.map((tab) => componentList.tabsContent[tab.code].animations.lock);

      if (activeTab.code !== Tab.Summary) {
        storyboard
          .appendStorybord(0, componentList.tabsContent[activeTab.code].slow())
          .appendMany(0, [componentList.tabsContent[activeTab.code].animations.initStyle])
          .appendMany(0, [componentList.summary.animations.disabled]);
      } else {
        storyboard.appendMany(0, [componentList.tabContainer.animations.moveOut]);
      }

      storyboard
        .append(0, componentList.chair.animations.initPosition)
        .appendStoryboards(0, [componentList.productModels.hide(), componentList.price.hide()])
        .appendMany(0, tabsInitStyle.concat(tabsToLock))
        .appendStorybord(0, componentList.summary.hide())
        .appendMany(0, [componentList.chairNavigation.initStyle, componentList.chairSliderNavigation.initStyle]);
      return storyboard;
    };

    this.initTab = (activeTab: TabState, delays: IInitTabDelaysProps) => {
      let storyboard = new Storyboard();

      storyboard.appendStorybord(0, componentList.chair.slow()).appendMany(0, [componentList.chair.animations.show]);

      if (activeTab.code !== Tab.Summary) {
        storyboard
          .appendMany(
            delays.tabInitAnimationDelay,
            [componentList.tabsContent[activeTab.code].animations.initAnimation],
            [componentList.chair.animations.initPosition],
          )
          .appendStorybord(delays.priceDelay, componentList.price.show())
          .appendStorybord(delays.productModelsPanelDelay, componentList.productModels.show())
          .appendMany(delays.chairNavigationDelay, [
            componentList.chairNavigation.initAnimation,
            componentList.chairSliderNavigation.initAnimation,
          ]);
      } else {
        storyboard
          .appendStorybord(delays.summaryShowDelay, componentList.summary.show())
          .appendMany(
            delays.summaryChairDelay,
            [
              componentList.chair.animations.moveLeft,
              componentList.chairNavigation.initAnimation,
              componentList.chairSliderNavigation.initAnimation,
            ],
            [componentList.chair.animations.initPosition],
          );
      }
      return storyboard;
    };

    this.switchTab = ({ prevTab, nextTab, inactiveDynamicsTabs, isMobilePortrait, delays }: ISwitchTabProps) => {
      if (prevTab.code === Tab.Summary) {
        const tabsToHideStoryboards = inactiveDynamicsTabs.map((tab) => {
          if (!isMobilePortrait) {
            return componentList.tabsContent[tab.code].hide();
          } else {
            return tab.index > nextTab.index
              ? componentList.tabsContent[tab.code].hideToRight()
              : componentList.tabsContent[tab.code].hideToLeft();
          }
        });

        return this.hideSummary({ nextTab, tabsToHideStoryboards, delays: delays.hideSummary });
      } else if (nextTab.code === Tab.Summary) {
        return this.showSummary(delays.showSummary);
      }
      return this.changeTab({ prevTab, nextTab, inactiveDynamicsTabs, isMobilePortrait });
    };

    this.changeTab = ({ prevTab, nextTab, inactiveDynamicsTabs, isMobilePortrait }: ISwitchTabProps): Storyboard => {
      if (prevTab.code === nextTab.code) {
        return new Storyboard();
      }
      const tabsToHide = inactiveDynamicsTabs.map((tab) => componentList.tabsContent[tab.code].animations.initStyle);
      const tabsToLock = inactiveDynamicsTabs.map((tab) => componentList.tabsContent[tab.code].animations.lock);

      const getDesktopStoryboard = () => {
        return new Storyboard()
          .appendMany(
            0,
            [],
            [componentList.tabsContent[nextTab.code].animations.lock, componentList.chair.animations.initPosition],
          )
          .appendMany(0, tabsToHide)
          .appendStorybord(0, componentList.tabsContent[prevTab.code].hide())
          .appendStorybord(400, componentList.tabsContent[nextTab.code].show())
          .appendMany(800, tabsToLock);
      };

      const getPortraitMobileStoryboard = () => {
        const slidePrevTab =
          prevTab.index > nextTab.index
            ? componentList.tabsContent[prevTab.code].hideToRight()
            : componentList.tabsContent[prevTab.code].hideToLeft();
        const slideNextTab =
          prevTab.index > nextTab.index
            ? componentList.tabsContent[nextTab.code].showFromLeft()
            : componentList.tabsContent[nextTab.code].showFromRight();

        return new Storyboard()
          .appendMany(
            0,
            [],
            [componentList.tabsContent[nextTab.code].animations.lock, componentList.chair.animations.initPosition],
          )
          .appendMany(0, tabsToHide)
          .appendStorybord(0, slidePrevTab)
          .appendStorybord(0, slideNextTab)
          .appendMany(0, tabsToLock);
      };

      return isMobilePortrait ? getPortraitMobileStoryboard() : getDesktopStoryboard();
    };

    this.showSummary = (delays: ISummaryShowDelays): Storyboard => {
      return new Storyboard()
        .appendMany(0, [], [componentList.summary.animations.disabled])
        .appendStoryboards(0, [
          componentList.price.hide(),
          componentList.productModels.hide(),
          componentList.tabContainer.medium(),
        ])
        .appendMany(0, [componentList.tabContainer.animations.moveOut])
        .appendStorybord(0, componentList.chair.medium())
        .appendMany(delays.moveLeftChairDelay, [componentList.chair.animations.moveLeft])
        .appendStorybord(delays.showSummaryDelay, componentList.summary.show());
    };

    this.hideSummary = (props: ISummaryHideProps): Storyboard => {
      const { nextTab, tabsToHideStoryboards, delays } = props;

      return new Storyboard()
        .appendMany(0, [], [componentList.chair.animations.initPosition])
        .appendStorybord(0, componentList.summary.hide())
        .appendStoryboards(0, tabsToHideStoryboards)
        .appendStorybord(0, componentList.price.show())
        .appendStorybord(0, componentList.productModels.show())
        .appendStorybord(0, componentList.tabContainer.slow())
        .appendStorybord(delays.showTabContentDelay, componentList.tabsContent[nextTab.code].show())
        .appendMany(
          delays.hideSummaryDelay,
          [],
          [
            componentList.tabContainer.animations.moveOut,
            componentList.tabsContent[nextTab.code].animations.initStyle,
            componentList.chair.animations.moveLeft,
          ],
        )
        .appendMany(
          delays.lockSummaryDelay,
          [componentList.summary.animations.disabled],
          [componentList.tabsContent[nextTab.code].animations.lock],
        );
    };
  }
}
