import * as React from 'react';

import css from './Tag.css';

interface TagProps {
  isActive: boolean;
  children: string;
  onSelect: () => void;
}

const Tag: React.FC<TagProps> = ({ children, isActive, onSelect }) => {
  return (
    <label className={css.Tag}>
      <input className={css.input} type="checkbox" checked={isActive} onChange={onSelect} />
      <span className={css.inner}>{children}</span>
    </label>
  );
};

export default Tag;
