import * as React from 'react';

import { CookieConsentService } from '../../CookieConsentService';
import css from './CookieConsentResolver.css';

const COOKIE_CONSENT_CLASS_BASE = 'cookieconsent-';
const COOKIE_CONSENT_OPT_IN_CLASS_BASE = `${COOKIE_CONSENT_CLASS_BASE}optin-`;
const COOKIE_CONSENT_OPT_OUT_CLASS_BASE = `${COOKIE_CONSENT_CLASS_BASE}optout-`;

export enum CookieCategory {
  Preferences = 'preferences',
  Statistics = 'statistics',
  Marketing = 'marketing',
}

interface CookieConsentResolver {
  children: React.ReactNode;
  requiredCookieCategory: CookieCategory;
  placeholder?: React.ReactNode;
}

const CookieConsentResolver = ({ requiredCookieCategory, children, placeholder }: CookieConsentResolver) => {
  const mainContentClasses = `${css.container} ${COOKIE_CONSENT_OPT_IN_CLASS_BASE}${requiredCookieCategory}`;
  const placeholderClasses = `${css.container} ${COOKIE_CONSENT_OPT_OUT_CLASS_BASE}${requiredCookieCategory}`;

  return (
    <>
      <div className={mainContentClasses}>{children}</div>
      <div className={placeholderClasses}>
        <div className={css.placeholder}>
          {placeholder}
          <div className={css.textContainer}>
            <div className={css.text}>
              Please&nbsp;
              <button className={css.button} type="button" onClick={CookieConsentService.renew}>
                {`accept ${requiredCookieCategory} cookies`}
              </button>
              &nbsp;to view this content.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsentResolver;
