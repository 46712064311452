import { ProductVariationListTranslation } from '../../../../../../../app/localization/SiteTranslation';
import List from '../../../../../../../app/shared/components/List/List';
import Heading from '../../../../../../../app/shared/components/Typography/Heading/Heading';
import ProductVariation, { ProductVariationProps } from './ProductVariation/ProductVariation';
import css from './ProductVariationList.css';

const MAX_NUMBER_OF_ITEMS_TO_DISPLAY = 4;

interface ProductVariationListProps {
  items: ProductVariationProps[];
  translation: ProductVariationListTranslation;
  className?: string;
}

const ProductVariationList = ({ className, items, translation }: ProductVariationListProps) => {
  const itemsToDisplay = items.slice(0, MAX_NUMBER_OF_ITEMS_TO_DISPLAY);
  const numberOfHiddenItems = Math.max(items.length - MAX_NUMBER_OF_ITEMS_TO_DISPLAY, 0);

  const renderItem = (item: ProductVariationProps) => {
    return <ProductVariation id={item.id} name={item.name} thumbnail={item.thumbnail} />;
  };

  return items.any() ? (
    <div className={className}>
      <Heading level={4} visuallyHidden>
        {translation.title}
      </Heading>
      <div className={css.wrapper}>
        <List
          className={css.items}
          itemClassName={css.item}
          items={itemsToDisplay}
          itemRenderer={renderItem}
          keyProperty="id"
        />
        {numberOfHiddenItems > 0 && <span>{`+${numberOfHiddenItems}`}</span>}
      </div>
    </div>
  ) : null;
};

export default ProductVariationList;
