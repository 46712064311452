import { action, IObservableArray, makeObservable, observable } from 'mobx';

import { OptionVariationGroupData } from '../data/model';
import { IFeature } from '../shared/common';
import { IRestrictionsInitializer } from '../shared/restrictions';
import { OptionDescriptionModalState } from './components/OptionDescriptionModal/OptionDescriptionModalState';
import { IFabricOptionVariationFilter } from './FabricOptionState';
import { OptionVariationState } from './OptionState';

export class FabricCollectionState {
  data: OptionVariationGroupData;
  variations: Array<OptionVariationState>;
  @observable.shallow filteredVariations: Array<OptionVariationState>;
  descriptionModal: OptionDescriptionModalState;

  constructor(
    data: OptionVariationGroupData,
    restrictionsInitializer: IRestrictionsInitializer,
    feature: IFeature,
    descriptionModal: OptionDescriptionModalState,
  ) {
    makeObservable(this);
    this.data = data;
    this.variations = new Array<OptionVariationState>(
      ...this.data.variations.map(
        (x) => new OptionVariationState(x, feature, restrictionsInitializer, this.descriptionModal),
      ),
    );
    this.filteredVariations = new Array<OptionVariationState>(...this.variations);
    this.descriptionModal = descriptionModal;
  }

  openDescriptionModal() {
    this.descriptionModal.setFabricCollectionData(this.data.name, this.data.description, this.data.variations);
    this.descriptionModal.open();
  }

  @action applyFilters(filters: Array<IFabricOptionVariationFilter>) {
    (this.filteredVariations as IObservableArray<OptionVariationState>).clear();

    if (filters.length === 0) {
      this.filteredVariations.push(...this.variations);
      return;
    }

    this.variations.forEach((variation) => {
      if (filters.all((filter) => filter.isSatisfied(variation.data))) {
        this.filteredVariations.push(variation);
      }
    });
  }
}
