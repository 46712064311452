import classNames from 'classnames';
import { observer } from 'mobx-react';
import { AriaRole, MouseEvent, ReactNode } from 'react';

import css from './List.css';

interface IKeyProperty {
  // tslint:disable-next-line: no-any
  [key: string]: any;
}

interface ListProps<T> {
  className?: string;
  itemClassName?: string;
  items: Array<T>;
  onItemClick?: (item: T, event: MouseEvent<HTMLLIElement>) => void;
  itemRenderer: (item: T, key: number) => ReactNode;
  hideItem?: (item: T) => boolean;
  id?: string;
  itemRole?: AriaRole;
  keyProperty?: string;
  role?: AriaRole;
}

const List = function <T>({
  className,
  hideItem,
  id,
  items,
  itemClassName,
  itemRenderer,
  itemRole,
  keyProperty,
  onItemClick,
  role,
}: ListProps<T>) {
  const isEmpty = items.empty();
  const classes = classNames(css.List, className);

  const itemsComponent = items.map((item, key) => {
    const itemKey = keyProperty ? keyProperty.split('.').reduce((prev: IKeyProperty, curr) => prev[curr], item) : key;

    return (
      <li
        role={itemRole}
        className={classNames(css.listItem, itemClassName, hideItem && hideItem(item) && css.hide)}
        key={itemKey}
        onClick={(mouseEvent) => onItemClick && onItemClick(item, mouseEvent)}
      >
        {itemRenderer(item, itemKey)}
      </li>
    );
  });

  return !isEmpty ? (
    <ul id={id} className={classes} role={role}>
      {itemsComponent}
    </ul>
  ) : null;
};

export default observer(List);
