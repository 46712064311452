import ThreeDotsLoader from '../../Loaders/ThreeDotsLoader';
import css from './SlideLoader.css';

const SlideLoader = () => {
  return (
    <div className={css.container}>
      <ThreeDotsLoader className={css.inner} size="large" color="white" />
    </div>
  );
};

export default SlideLoader;
