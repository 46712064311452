import * as $ from 'jquery';
import { Component } from 'react';

import AppState from '../../AppState';
import { loadScriptAsync } from '../common';
import HeaderLevel4 from '../components/Typography/HeaderLevel4';
import hubSpotCSS from './HubSpotForm.css';
import { HubSpotFormState } from './HubSpotFormState';
import HubSpotLocaleResolver from './HubSpotLocaleResolver';

export interface HubSpotPageProps<T> {
  appState: AppState;
  state: T;
}

type HubSpotMessage = { type: string; eventName: string };

export abstract class HubspotFormPage<T extends HubSpotFormState> extends Component<HubSpotPageProps<T>> {
  public hubSpotFormScriptUrl = '//js-eu1.hsforms.net/forms/embed/v2.js';
  public requestId = `hubSpotFormContainer`;

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.jQuery = $;
    }
    this.messageHandler = this.messageHandler.bind(this);
    this.props.appState.loadingPageIndicator.start();
    this.attachErrorHandler();
    this.embedHubSpotForm();
  }

  componentWillUnmount() {
    this.detachErrorHandler();
  }

  abstract onFormSubmit(formElement: Element): void;

  onFormError(error: Error): void {
    this.props.state.setError(error);
    this.props.appState.loadingPageIndicator.stop();
  }

  onFormSubmitted() {
    this.props.state.setFormSubmitted();
  }

  attachErrorHandler() {
    window.addEventListener('message', this.messageHandler);
  }

  detachErrorHandler() {
    window.removeEventListener('message', this.messageHandler);
  }

  // Using global form events, see more https://developers.hubspot.com/global-form-events
  messageHandler(err: MessageEvent) {
    if (err.data) {
      const dataObject = err.data as HubSpotMessage;
      if (dataObject.eventName === 'onFormDefinitionFetchError' && dataObject.type === 'hsFormCallback') {
        this.onFormError(new Error('Failed to fetch HubSpot form definition'));
      }
    }
  }

  embedHubSpotForm() {
    const { state } = this.props;
    const { portalId, formId } = state.hubSpotConfig;
    const languageCode = this.props.state.shoppingContext.languageCode;
    loadScriptAsync(this.hubSpotFormScriptUrl).then(() => {
      window.hbspt.forms.create({
        portalId: portalId,
        formId: formId,
        css: hubSpotCSS,
        locale: HubSpotLocaleResolver.resolve(languageCode),
        env: '',
        target: `#${this.requestId}`,
        onFormReady: () => {
          this.props.appState.loadingPageIndicator.stop();
        },
        onFormSubmit: () => {
          const formElement = document.querySelector(`#${this.requestId}`);
          this.onFormSubmit(formElement);
        },
        onFormSubmitted: () => {
          this.onFormSubmitted();
        },
      });
    });
  }

  render() {
    if (this.props.state.error != null) {
      return (
        <div>
          <HeaderLevel4>{this.props.state.translations.errorMessage}</HeaderLevel4>
        </div>
      );
    } else {
      return <div id={this.requestId} />;
    }
  }
}
