import classNames from 'classnames';
import * as React from 'react';

import css from './MenuSection.css';

export interface MenuSectionProps {
  id: string;
  children?: React.ReactNode;
  name?: string;
  hide?: boolean;
  sectionId?: string;
  className?: string;
  scrollAreaClassName?: string;
}

export default class MenuSection extends React.Component<MenuSectionProps> {
  render() {
    const { children, className, hide, id, scrollAreaClassName } = this.props;
    return (
      !hide && (
        <section className={classNames(css.MenuSection, className)}>
          <span id={id} className={classNames(scrollAreaClassName, css.scrollArea)} />
          {children}
        </section>
      )
    );
  }
}
