import { action, makeObservable, observable, override } from 'mobx';

import { IApiClient } from '../data/client';
import {
  DownloadProductFamilyAssetsQuery,
  GetAssetFiltersQuery,
  GetAssetFiltersQueryResponse,
  GetProductFamiliesAssetsQuery,
  GetProductFamiliesAssetsQueryResponse,
  ShoppingContext,
} from '../data/model';
import { DownloadsPageTranslation } from '../localization/SiteTranslation';
import { Analytics } from '../shared/analytics/Analytics';
import { BasePageState } from '../shared/BasePageState';
import { AsyncCommand, PageMetadata } from '../shared/common';
import { PaginationState } from '../shared/components/Pagination/PaginationState';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { INavigationService } from '../shared/NavigationService';
import { ICancellableTask, RunLastTasksScheduler } from '../shared/TasksScheduler';
import { StoreState } from '../StoreState';
import { DownloadState } from './components/DownloadList/Download/DownloadState';
import { DownloadsFiltersState } from './components/Filters/DownloadsFiltersState';
import { DownloadsPageQuery } from './DownloadsPageQuery';
import { DownloadsPageQueryParser } from './DownloadsPageQueryParser';

interface IDownloadsMemento {
  shoppingContext: ShoppingContext;
  getAssetFiltersQueryResponse: GetAssetFiltersQueryResponse;
  getProductFamiliesAssetsQueryResponse: GetProductFamiliesAssetsQueryResponse;
}

class LoadAssetsTask implements ICancellableTask {
  public cancelled = false;
  public query: GetProductFamiliesAssetsQuery;

  public constructor(query: GetProductFamiliesAssetsQuery) {
    this.query = query;
  }
}

export class DownloadsPageState extends BasePageState<IDownloadsMemento> {
  @observable.ref
  public downloads: DownloadState[];
  public downloadAllCommand: AsyncCommand<string>;
  public filters: DownloadsFiltersState;
  public loadingIndicator: LoadingIndicator;
  public pagination: PaginationState;
  public urlQuery: DownloadsPageQuery;

  private getAssetFiltersQueryResponse: GetAssetFiltersQueryResponse;
  private getProductFamiliesAssetsQueryResponse: GetProductFamiliesAssetsQueryResponse;
  private loadAssetsScheduler: RunLastTasksScheduler<LoadAssetsTask, void>;
  private shoppingContext: ShoppingContext;

  public constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    public readonly translation: DownloadsPageTranslation,
  ) {
    super(translation);
    makeObservable(this);
    this.downloadAllCommand = new AsyncCommand(this.downloadAssets);
    this.loadAssetsScheduler = new RunLastTasksScheduler(this.runLoadAssetsTask);
    this.loadingIndicator = new LoadingIndicator();
    this.urlQuery = DownloadsPageQueryParser.toModel(this.navigation.currentUrl.query);
    this.initializePagination();
  }

  public get counter(): number {
    return this.pagination.totalCount;
  }

  public get downloadAllEnabled(): boolean {
    return this.filters.downloadAllEnabled && this.counter > 0;
  }

  public getMemento(): IDownloadsMemento {
    return {
      shoppingContext: this.shoppingContext,
      getAssetFiltersQueryResponse: this.getAssetFiltersQueryResponse,
      getProductFamiliesAssetsQueryResponse: this.getProductFamiliesAssetsQueryResponse,
    };
  }

  @override
  public get metadata() {
    const title = this.translations.pageTitleFormat;
    const description = this.translations.pageDescriptionFormat;

    return <PageMetadata>{
      title: title,
      description: description,
    };
  }

  @action
  public async onLoad(store: StoreState) {
    const getAssetFiltersQueryResponse = await this.client.send(
      new GetAssetFiltersQuery({ shoppingContext: store.shoppingContext }),
    );
    const getProductFamiliesAssetsQueryResponse = await this.client.send(
      this.getInitialGetProductFamiliesAssetsQuery(store.shoppingContext, getAssetFiltersQueryResponse),
    );
    this.initialize(store.shoppingContext, getAssetFiltersQueryResponse, getProductFamiliesAssetsQueryResponse);
  }

  public restoreMemento(memento: IDownloadsMemento) {
    this.initialize(
      memento.shoppingContext,
      memento.getAssetFiltersQueryResponse,
      memento.getProductFamiliesAssetsQueryResponse,
    );
  }

  public searchAssets = () => {
    this.pagination.setCurrentPageIndex(0, true);
    this.loadAssets();
  };

  private downloadAssets = async (productFamily?: string) => {
    const command = new DownloadProductFamilyAssetsQuery({
      category: this.filters.categories.selection.value,
      fileType: this.filters.categories.selection.typeSelection.value,
      brands: productFamily ? [] : this.filters.brands.selection.values,
      productFamilies: productFamily ? [productFamily] : [],
      shoppingContext: this.shoppingContext,
    });
    const response = await this.client.send(command);

    window.location.href = response.zipUrl;
    Analytics.trackDownload({ url: response.zipUrl });
  };

  private getInitialGetProductFamiliesAssetsQuery(
    shoppingContext: ShoppingContext,
    getAssetFiltersQueryResponse: GetAssetFiltersQueryResponse,
  ): GetProductFamiliesAssetsQuery {
    const { category: categoryQuery, type: typeQuery } = this.urlQuery;

    let category = getAssetFiltersQueryResponse.categories.first();

    if (categoryQuery) {
      const categoryByQuery = getAssetFiltersQueryResponse.categories.find((item) => item.id === categoryQuery);

      if (categoryByQuery) {
        category = categoryByQuery;
      }
    }

    let fileType = category.fileTypes.first()?.id ?? '';

    if (typeQuery) {
      const typeByQuery = category.fileTypes.find((item) => item.id === typeQuery);

      if (typeByQuery) {
        fileType = typeByQuery.id;
      }
    }

    return new GetProductFamiliesAssetsQuery({
      shoppingContext,
      fileType,
      category: category.id,
      brands: this.urlQuery.brands ?? [],
      skip: this.pagination.startIndex,
      take: this.pagination.pageSize,
    });
  }

  private initialize(
    context: ShoppingContext,
    getAssetFiltersQueryResponse: GetAssetFiltersQueryResponse,
    getProductFamiliesAssetsQueryResponse: GetProductFamiliesAssetsQueryResponse,
  ) {
    this.shoppingContext = context;
    this.getAssetFiltersQueryResponse = getAssetFiltersQueryResponse;
    this.getProductFamiliesAssetsQueryResponse = getProductFamiliesAssetsQueryResponse;
    this.filters = new DownloadsFiltersState(getAssetFiltersQueryResponse, this.urlQuery);
    this.setAssets(getProductFamiliesAssetsQueryResponse);
    this.pagination.setTotalCount(getProductFamiliesAssetsQueryResponse.totalCount);
  }

  private initializePagination() {
    this.pagination = new PaginationState(15);

    const currentPageIndex = this.urlQuery.page - 1;
    if (currentPageIndex >= 0) {
      this.pagination.setCurrentPageIndex(this.urlQuery.page - 1);
    }

    this.pagination.pageChanged.subscribe(this.loadAssets);
  }

  private loadAssets = () => {
    const query = new GetProductFamiliesAssetsQuery({
      shoppingContext: this.shoppingContext,
      category: this.filters.categories.selection.value,
      brands: this.filters.brands.selection.values,
      fileType: this.filters.categories.selection.typeSelection.value,
      skip: this.pagination.startIndex,
      take: this.pagination.pageSize,
    });

    this.loadAssetsScheduler.run(new LoadAssetsTask(query));
  };

  private runLoadAssetsTask = async (task: LoadAssetsTask) => {
    this.loadingIndicator.start();

    const query = DownloadsPageQueryParser.toQuery({
      category: this.filters.categories?.selection?.value,
      type: this.filters.categories?.selection?.typeSelection.value,
      brands: this.filters.brands?.selection?.values.slice(),
      page: this.pagination.currentPageNumber,
    });
    this.navigation.setQuery(query);

    const response = await this.client.send(task.query);

    if (task.cancelled) {
      return;
    }

    this.setAssets(response);
    this.pagination.setTotalCount(response.totalCount);

    this.loadingIndicator.reset();
  };

  @action.bound
  private setAssets(response: GetProductFamiliesAssetsQueryResponse) {
    if (!response || !response.productFamilies || response.productFamilies.empty()) {
      this.downloads = [];
      return;
    }

    this.downloads = response.productFamilies.map((x) => new DownloadState(x, this.downloadAssets));
  }
}
