import classnames from 'classnames';
import * as React from 'react';

import { BaseModelBasicData } from '../../../../../data/model';
import Image from '../../../../../shared/components/Image/Image';
import { SelectorItem } from '../../../../../shared/Selector';
import css from './ModelSelectorItem.css';

interface ModelSelectorItemProps {
  item: SelectorItem<BaseModelBasicData>;
  onClick: (itemData: BaseModelBasicData) => void;
}

const ModelSelectorItem = ({ item, onClick }: ModelSelectorItemProps) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onClick(item.data);
  };

  return (
    <div className={css.container}>
      <div className={classnames(css.inner, { [css.active]: item.isActive })}>
        <div className={css.imageContainer}>
          <Image className={css.image} src={item.data.outline?.url} alt={item.data.displayName} />
          {(!item.data.outline || !item.data.outline.url) && (
            <span className={css.name} aria-hidden={true}>
              {item.data.displayName}
            </span>
          )}
        </div>
        <button className={css.button} type="button" onClick={handleClick} disabled={item.isActive}>
          {item.data.shortDescription ?? item.data.displayName}
        </button>
      </div>
    </div>
  );
};

export default ModelSelectorItem;
