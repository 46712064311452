import classNames from 'classnames';

import List from '../../List/List';
import css from './ErrorMessages.css';

export interface ErrorMessageProps {
  className?: string;
  error?: string;
}

export interface ErrorMessagesStylesProps {
  errorContainerClassName?: string;
  errorItemClassName?: string;
  errorMessageClassName?: string;
}

export interface ErrorMessagesProps extends ErrorMessagesStylesProps {
  errors?: Array<string>;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { className, error } = props;
  return <span className={classNames(css.errorMessage, className)}>{error}</span>;
};

export const ErrorMessages = (props: ErrorMessagesProps) => {
  const { errorContainerClassName, errorItemClassName, errorMessageClassName, errors } = props;
  return errors && errors.any() ? (
    <List
      items={errors}
      itemRenderer={(error) => <ErrorMessage error={error} className={errorMessageClassName} />}
      className={classNames(css.ErrorMessages, errorContainerClassName)}
      itemClassName={classNames(css.item, errorItemClassName)}
    />
  ) : (
    <div className={classNames(errorContainerClassName, css.errorPlaceholder)} />
  );
};
