import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../data/client';
import {
  FindFabricsOptionsQuery,
  RawEnablersGroupData,
  RawFeatureData,
  RawFeatureEnablersLogicalOperator,
  RawFeatureOptionData,
  RawFeatureSequenceEntryData,
  RawFeatureType,
  RawOptionReferenceData,
  RawOptionValueType,
  RawProductStructureData,
  RawRelationData,
  RawRelationType,
  ShoppingContext,
} from '../../../data/model';
import Event from '../../../shared/Event';
import { ILoadingIndicator, LoadingIndicator } from '../../../shared/LoadingIndicator';
import Logger from '../../../shared/Logger';
import { RawConfiguratorSize } from './RawConfiguratorSize';

export class RawConfiguratorState {
  shoppingContext: ShoppingContext;

  @observable.ref
  structure?: RawProductStructureData;

  @observable.shallow
  selectedFeatures: IObservableArray<SimplifiedFeatureSelectionState> = [] as IObservableArray<SimplifiedFeatureSelectionState>;

  @computed
  get selectedFeaturesCount() {
    return this.selectedFeatures.length;
  }

  @computed
  get allFeaturesCount() {
    return this.structure?.features.length ?? 0;
  }

  @computed
  get selectedOptions(): SelectedFeatureOption[] {
    return this.selectedFeatures
      .filter((x) => x.selectedOption)
      .map((x) => ({ feature: x.feature, option: x.selectedOption, sequenceEntry: x.sequenceEntry }));
  }

  @observable
  configurationCompleted: boolean = false;

  @observable
  configurationCode: string | undefined;

  @computed
  get progressPercentage() {
    return this.configurationCompleted
      ? 100
      : Math.round(((this.selectedFeaturesCount - 1) / this.allFeaturesCount) * 100);
  }

  @computed
  get canMoveNext() {
    return !this.configurationCompleted && this.selectedFeatures.all((x) => !!x.selectedOption);
  }

  constructor(
    public readonly productId: string,
    public readonly size: RawConfiguratorSize,
    private readonly client: IApiClient,
  ) {
    makeObservable(this);
  }

  public initialize(shoppingContext: ShoppingContext, structure: RawProductStructureData) {
    this.shoppingContext = shoppingContext;
    this.structure = structure;
    this.populateNextFeatureSelection();
  }

  @action.bound
  public onFeatureOptionSelected(featureSelection: SimplifiedFeatureSelectionState, selectedOptionCode: string) {
    this.configurationCompleted = false;

    const selectedOption = featureSelection.availableOptions.find((x) => x.code === selectedOptionCode);
    featureSelection.setSelectedOption(selectedOption);

    const featureSelectionIndex = this.selectedFeatures.indexOf(featureSelection);
    this.selectedFeatures.skip(featureSelectionIndex + 1).forEach((x) => {
      this.selectedFeatures.remove(x);
    });
  }

  @action.bound
  populateNextFeatureSelection() {
    const evaluator = new RawProductStructureEvaluator(this.structure);
    const nextFeature = evaluator.getNextFeature(this.selectedOptions);

    if (!nextFeature) {
      this.configurationCompleted = true;
      this.configurationCode = undefined;
      return;
    }

    let nextFeatureSelection: SimplifiedFeatureSelectionState;

    if (
      nextFeature.data.type === RawFeatureType.Fabric &&
      this.selectedFeatures.any((x) => x.feature.code.startsWith('7KIF'))
    ) {
      nextFeatureSelection = new LazyLoadedFeatureSelectionState(
        this.client,
        this.shoppingContext,
        this.productId,
        this.selectedOptions,
        this.structure,
        nextFeature.data,
        nextFeature.sequenceEntry,
        true,
      );
    } else if (nextFeature.data.type === RawFeatureType.Fabric) {
      const searchableFeatureSelection = new SearchableFeatureSelectionState(
        this.client,
        this.shoppingContext,
        this.productId,
        this.selectedOptions,
        this.structure,
        nextFeature.data,
        nextFeature.sequenceEntry,
      );
      searchableFeatureSelection.onSearchStart.subscribe(() =>
        this.onFeatureOptionSelected(searchableFeatureSelection, ''),
      );
      searchableFeatureSelection.onSearchEnd.subscribe((availableOptions) => {
        if (availableOptions.length === 1) {
          this.onFeatureOptionSelected(searchableFeatureSelection, availableOptions[0].code);
        }
      });
      nextFeatureSelection = searchableFeatureSelection;
    } else {
      nextFeatureSelection = new SimplifiedFeatureSelectionState(
        nextFeature.data,
        nextFeature.sequenceEntry,
        nextFeature.selectedOption,
        nextFeature.availableOptions,
        nextFeature.error,
      );
    }

    this.selectedFeatures.push(nextFeatureSelection);

    if (nextFeature.selectedOption && nextFeature.selectedOptionForcedByRestrictionType === RawRelationType.Mandatory) {
      this.populateNextFeatureSelection();
    }
  }
}

export class SimplifiedFeatureSelectionState {
  @observable.shallow
  availableOptions: RawFeatureOptionData[] = [];

  @observable.ref
  selectedOption?: RawFeatureOptionData = undefined;

  constructor(
    public readonly feature: RawFeatureData,
    public readonly sequenceEntry: RawFeatureSequenceEntryData,
    selectedOption: RawFeatureOptionData,
    availableOptions: RawFeatureOptionData[],
    public readonly error?: string,
  ) {
    makeObservable(this);
    this.selectedOption = selectedOption;
    this.availableOptions = availableOptions;
  }

  @action
  setSelectedOption(option: RawFeatureOptionData) {
    this.selectedOption = option;
  }
}

export class LazyLoadedFeatureSelectionState extends SimplifiedFeatureSelectionState {
  public loadingIndicator: ILoadingIndicator = new LoadingIndicator();

  constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    private readonly productId: string,
    private readonly alreadySelectedOptions: SelectedFeatureOption[],
    private readonly structure: RawProductStructureData,
    feature: RawFeatureData,
    sequenceEntry: RawFeatureSequenceEntryData,
    loadData: boolean = false,
  ) {
    super(feature, sequenceEntry, undefined, []);

    if (loadData) {
      this.loadAvailableOptions();
    }
  }

  @action
  setAvailableOptions(availableOptions: RawFeatureOptionData[]) {
    this.availableOptions = availableOptions;
  }

  private async loadAvailableOptions() {
    this.findAvailableOptions();
  }

  protected async findAvailableOptions(searchPhrase?: string) {
    this.loadingIndicator.start();

    const response = await this.client.send(
      new FindFabricsOptionsQuery({
        featureCode: this.feature.code,
        productId: this.productId,
        restrictingOptions: this.alreadySelectedOptions.map((x) => ({
          featureCode: x.feature.code,
          optionCode: x.option.code,
        })),
        shoppingContext: this.shoppingContext,
        take: 250,
        optionSearchPhrase: searchPhrase,
        validFeatureCodes: this.structure.features.map((x) => x.code),
      }),
    );

    this.setAvailableOptions(response.options);

    this.loadingIndicator.stop();
  }
}

export class SearchableFeatureSelectionState extends LazyLoadedFeatureSelectionState {
  public onSearchStart: Event<string> = new Event<string>();
  public onSearchEnd: Event<RawFeatureOptionData[]> = new Event<RawFeatureOptionData[]>();

  @observable searchPhrase?: string;
  @action setSearchPhrase(phrase: string) {
    this.searchPhrase = phrase;
  }

  @observable message?: string;
  @action setMessage(message: string) {
    this.message = message;
  }

  constructor(
    client: IApiClient,
    shoppingContext: ShoppingContext,
    productId: string,
    alreadySelectedOptions: SelectedFeatureOption[],
    structure: RawProductStructureData,
    feature: RawFeatureData,
    sequenceEntry: RawFeatureSequenceEntryData,
  ) {
    super(client, shoppingContext, productId, alreadySelectedOptions, structure, feature, sequenceEntry);
  }

  public async search() {
    this.onSearchStart.raise(this.searchPhrase);

    this.setMessage(undefined);
    await this.findAvailableOptions(this.searchPhrase);
    this.setMessage(
      this.availableOptions.any() ? undefined : `Unable to find options for phrase '${this.searchPhrase}'`,
    );

    this.onSearchEnd.raise(this.availableOptions);
  }
}

export class RawProductStructureEvaluator {
  private readonly validationRules: AdditionalValidationRule[] = [new MaxNumberOfPowerUnitsValidationRule()];

  constructor(private structure: RawProductStructureData) {}

  public getNextFeature(alreadySelectedOptions: SelectedFeatureOption[]): NextFeature | undefined {
    const alreadySelectedFeaturesCodes = alreadySelectedOptions.map((x) => x.feature.code);
    const lastSelectedOption = alreadySelectedOptions.last();
    const lastSelectedFeaturesSequenceIndex = alreadySelectedOptions.any()
      ? this.structure.featuresSequence.indexOf(lastSelectedOption.sequenceEntry)
      : -1;

    const nextFeaturesSequenceEntry = this.structure.featuresSequence
      .skip(lastSelectedFeaturesSequenceIndex + 1)
      .filter((x) => alreadySelectedFeaturesCodes.includes(x.code) === false)
      .filter((x) => RawProductEnablersEvaluator.isFeatureEnabled(x, alreadySelectedOptions))
      .first();

    if (!nextFeaturesSequenceEntry) {
      return undefined;
    }

    const nextFeatureToSelect = this.structure.features.find((x) => x.code === nextFeaturesSequenceEntry.code);

    if (!nextFeatureToSelect) {
      return undefined;
    }

    const restrictionsEvaluator = new RawProductRestrictionsEvaluator(
      this.structure,
      nextFeatureToSelect,
      alreadySelectedOptions,
    );

    this.validationRules.forEach((x) => x.initialize(this.structure, nextFeatureToSelect, alreadySelectedOptions));

    const result: NextFeature = {
      data: nextFeatureToSelect,
      sequenceEntry: nextFeaturesSequenceEntry,
      availableOptions: restrictionsEvaluator
        .getAvailableOptions()
        .filter((option) => this.validationRules.all((rule) => rule.isValid(option))),
    };

    const mandatoryOptionResult = restrictionsEvaluator.getMandatoryOption();
    switch (mandatoryOptionResult.status) {
      case 'Found': {
        result.selectedOption = mandatoryOptionResult.option;
        result.availableOptions = [mandatoryOptionResult.option];
        result.selectedOptionForcedByRestrictionType = RawRelationType.Mandatory;
        return result;
      }
      case 'FoundRelationButNotOption': {
        const target = mandatoryOptionResult.relation.target;
        result.error = `Unable to select mandatory option ${target.featureCode}-${target.optionCode}`;
        result.availableOptions = [];
        result.selectedOptionForcedByRestrictionType = RawRelationType.Mandatory;
        Logger.warn(`Raw configurator, product number: ${this.structure.productNumber}, error: ${result.error}`);
        return result;
      }
      case 'NotFound': {
        const isPriceGroupFeature = alreadySelectedOptions
          .filter((x) => x.feature.determinesPriceGroupFeatureCode === nextFeatureToSelect.code)
          .any();

        if (isPriceGroupFeature) {
          result.error = `Price group ${nextFeatureToSelect.code} is not specified by already selected options`;
          result.availableOptions = [];
          Logger.warn(`Raw configurator, product number: ${this.structure.productNumber}, error: ${result.error}`);
          return result;
        }
        break;
      }
      default: {
        break;
      }
    }

    const defaultOption = restrictionsEvaluator.getDefaultOption();
    if (defaultOption) {
      result.selectedOption = defaultOption;
      result.selectedOptionForcedByRestrictionType = RawRelationType.Default;
    }

    this.limitAvailableModelChoices(result, defaultOption);

    return result;
  }

  private limitAvailableModelChoices(feature: NextFeature, defaultOption?: RawFeatureOptionData) {
    const modelChoicesFeaturesCodes = ['13600', '23600', '63600'];
    if (defaultOption && modelChoicesFeaturesCodes.contains(feature.data.code)) {
      feature.availableOptions = feature.availableOptions.filter((x) => x.code === defaultOption.code);
    }
  }
}

export type RawFeatureOptionResolutionResult =
  | {
      status: 'NotFound';
    }
  | {
      status: 'FoundRelationButNotOption';
      relation: RawRelationData;
    }
  | {
      status: 'Found';
      option: RawFeatureOptionData;
      relation: RawRelationData;
    };

export class RawProductRestrictionsEvaluator {
  private readonly effectiveRelations: RawRelationData[] = [];

  constructor(
    private readonly structure: RawProductStructureData,
    private readonly nextFeature: RawFeatureData,
    alreadySelectedOptions: SelectedFeatureOption[],
  ) {
    this.effectiveRelations = alreadySelectedOptions
      .map((x) => x.option)
      .mapMany((x) => x.relations)
      .filter((x) => x.target.featureCode === nextFeature.code);
  }

  public getMandatoryOption(): RawFeatureOptionResolutionResult {
    const mandatoryRelation = this.effectiveRelations.find((x) => x.type === RawRelationType.Mandatory);
    if (!mandatoryRelation) {
      return {
        status: 'NotFound',
      };
    }

    const option = this.nextFeature.options.find((x) => x.code === mandatoryRelation.target.optionCode);
    return option
      ? {
          status: 'Found',
          relation: mandatoryRelation,
          option: option,
        }
      : {
          status: 'FoundRelationButNotOption',
          relation: mandatoryRelation,
        };
  }

  getAvailableOptions(): RawFeatureOptionData[] {
    const validOptionCodes = this.effectiveRelations
      .filter((x) => x.type === RawRelationType.Valid)
      .map((x) => x.target.optionCode);

    const invalidOptionCodes = this.effectiveRelations
      .filter((x) => x.type === RawRelationType.Invalid)
      .map((x) => x.target.optionCode);

    const availableOptions = validOptionCodes.any()
      ? this.nextFeature.options.filter((x) => validOptionCodes.contains(x.code))
      : this.nextFeature.options;

    return availableOptions.filter((x) => !invalidOptionCodes.contains(x.code));
  }

  public getDefaultOption(): RawFeatureOptionData | undefined {
    const defaultRestrictions = [
      ...this.structure.relations.filter(
        (x) => x.target.featureCode === this.nextFeature.code && x.type === RawRelationType.Default,
      ),
      ...this.effectiveRelations.filter((x) => x.type === RawRelationType.Default),
    ];

    const defaultRestriction = defaultRestrictions.reverse().first(); // TODO: referenced option might not be available
    if (!defaultRestriction) {
      return undefined;
    }

    return this.getAvailableOptions().find((x) => x.code === defaultRestriction.target.optionCode);
  }
}

export class RawProductEnablersEvaluator {
  public static isFeatureEnabled(
    nextFeature: { enablersGroups: RawEnablersGroupData[] },
    alreadySelectedOptions: SelectedFeatureOption[],
  ) {
    if (nextFeature.enablersGroups.empty()) {
      return true;
    }

    const isEnabled = nextFeature.enablersGroups
      .map((x) => {
        switch (x.operator) {
          case RawFeatureEnablersLogicalOperator.Or:
            return new EnablersGroupWithOrOperatorEvaluator(x);
          case RawFeatureEnablersLogicalOperator.And:
            return new EnablersGroupWithAndOperatorEvaluator(x);
          default:
            throw new Error(`Not supported operator detected: ${x.operator}`);
        }
      })
      .any((x) => x.isSatisfied(alreadySelectedOptions));

    return isEnabled;
  }
}

export interface IRawEnablersGroupEvaluator {
  isSatisfied(selectedOptions: SelectedFeatureOption[]): boolean;
}

export abstract class BaseEnablersGroupEvaluator implements IRawEnablersGroupEvaluator {
  constructor(protected enablersGroup: RawEnablersGroupData) {}

  isSatisfied(selectedOptions: SelectedFeatureOption[]): boolean {
    const selectedEnablers = this.enablersGroup.enablers.filter((enabler) =>
      selectedOptions.any(
        (selected) => selected.feature.code === enabler.featureCode && selected.option.code === enabler.optionCode,
      ),
    );

    return this.isSatisfiedInternal(selectedEnablers);
  }

  protected abstract isSatisfiedInternal(selectedEnablers: RawOptionReferenceData[]): boolean;
}

export class EnablersGroupWithAndOperatorEvaluator extends BaseEnablersGroupEvaluator {
  constructor(enablersGroup: RawEnablersGroupData) {
    super(enablersGroup);
  }

  protected isSatisfiedInternal(selectedEnablers: RawOptionReferenceData[]): boolean {
    const uniqueFeaturesCodes = this.enablersGroup.enablers.map((x) => x.featureCode).distinct();
    const uniqueSelectedFeaturesCodes = selectedEnablers.map((x) => x.featureCode).distinct();

    return uniqueFeaturesCodes.intersection(uniqueSelectedFeaturesCodes).length === uniqueFeaturesCodes.length;
  }
}

export class EnablersGroupWithOrOperatorEvaluator extends BaseEnablersGroupEvaluator {
  constructor(enablersGroup: RawEnablersGroupData) {
    super(enablersGroup);
  }

  protected isSatisfiedInternal(selectedEnablers: RawOptionReferenceData[]): boolean {
    return selectedEnablers.any();
  }
}

interface SelectedFeatureOption {
  feature: RawFeatureData;
  option: RawFeatureOptionData;
  sequenceEntry: RawFeatureSequenceEntryData;
}

interface NextFeature {
  data: RawFeatureData;
  sequenceEntry: RawFeatureSequenceEntryData;
  availableOptions: RawFeatureOptionData[];
  selectedOption?: RawFeatureOptionData;
  selectedOptionForcedByRestrictionType?: RawRelationType;
  error?: string;
}

interface AdditionalValidationRule {
  initialize(
    structure: RawProductStructureData,
    currentFeature: RawFeatureData,
    alreadySelectedOptions: SelectedFeatureOption[],
  ): void;
  isValid(option: RawFeatureOptionData): boolean;
}

export class MaxNumberOfPowerUnitsValidationRule implements AdditionalValidationRule {
  private isApplicable = false;
  private alreadySelectedValue = 0;
  private maximumValue = 0;

  initialize(
    structure: RawProductStructureData,
    currentFeature: RawFeatureData,
    alreadySelectedOptions: SelectedFeatureOption[],
  ): void {
    this.isApplicable = this.isNumberFeature(currentFeature);

    if (!this.isApplicable) {
      return;
    }

    const numberFeatures = structure.features.filter((x) => this.isNumberFeature(x));
    const numberFeaturesCodes = numberFeatures.map((x) => x.code);

    this.alreadySelectedValue = alreadySelectedOptions
      .filter((x) => numberFeaturesCodes.contains(x.feature.code))
      .map((x) => parseInt(x.option.code, 10))
      .sum((x) => x);

    const availableMaximumValues = structure.features
      .filter((x) => this.isNumberFeature(x))
      .mapMany((x) => x.options)
      .map((x) => parseInt(x.code, 10));

    this.maximumValue = availableMaximumValues.any() ? Math.max(...availableMaximumValues) : 0;
  }

  isValid(option: RawFeatureOptionData): boolean {
    if (!this.isApplicable) {
      return true;
    }

    return this.alreadySelectedValue + parseInt(option.code, 10) <= this.maximumValue;
  }

  private isNumberFeature = (feature: RawFeatureData) => feature.optionValueType === RawOptionValueType.Number;
}
