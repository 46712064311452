export enum EmailNotification {
  OrderCreated = 'CustomerOrderCreatedNotification',
  OrderStatusChanged = 'CustomerOrderStatusChangedNotification',
  OrderCancelled = 'CustomerOrderCancelledNotification',
  OrderNotifyOnlyMyOrders = 'CustomerOrderOnlyMyOrdersNotification',
}

export function toEmailNotification(notificationTypeStr: string) {
  const notificationDict = EmailNotification as { [key: string]: string };
  for (let notificationKey in notificationDict) {
    if (notificationDict[notificationKey] === notificationTypeStr) {
      return notificationKey as EmailNotification;
    }
  }
}
