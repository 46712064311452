import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../data/client';
import { HubSpotFormConfig, ShoppingContext } from '../../data/model';
import { HubSpotPageTranslation } from '../../localization/SiteTranslation';
import { StoreState } from '../../StoreState';
import { BasePageState } from '../BasePageState';
import Logger from '../Logger';
import { INavigationService } from '../NavigationService';

export class IHubSpotFormMemento<T extends HubSpotFormConfig = HubSpotFormConfig> {
  shoppingContext: ShoppingContext;
  hubSpotConfig: T;
}

export abstract class HubSpotFormState<
  THubSpotState extends HubSpotFormConfig = HubSpotFormConfig,
  THubSpotMemento extends IHubSpotFormMemento<THubSpotState> = IHubSpotFormMemento<THubSpotState>,
> extends BasePageState<IHubSpotFormMemento<THubSpotState>> {
  public declare translations: HubSpotPageTranslation;

  protected client: IApiClient;
  protected navigation: INavigationService;

  public shoppingContext: ShoppingContext;

  @observable public hubSpotConfig: THubSpotState;
  @observable public isFormSubmitted: boolean;
  @observable public error: {};

  constructor(client: IApiClient, navigation: INavigationService, pageTranslation: HubSpotPageTranslation) {
    super(pageTranslation);
    makeObservable(this);
    this.client = client;
    this.navigation = navigation;

    this.isFormSubmitted = false;
  }

  private initialize(context: ShoppingContext, hubSpotConfig: THubSpotState) {
    this.shoppingContext = context;
    this.hubSpotConfig = hubSpotConfig;
  }

  async onLoad(store: StoreState) {
    this.ensureFeatureIsEnabled(store);
    const config = this.hubSpotConfigSelector(store);
    this.initialize(store.shoppingContext, config);
  }

  protected abstract hubSpotConfigSelector(state: StoreState): THubSpotState;

  protected abstract ensureFeatureIsEnabled(store: StoreState): void;

  getMemento() {
    let memento: IHubSpotFormMemento<THubSpotState> = {
      shoppingContext: this.shoppingContext,
      hubSpotConfig: this.hubSpotConfig,
    };

    return memento;
  }

  restoreMemento(memento: IHubSpotFormMemento<THubSpotState>) {
    this.initialize(memento.shoppingContext, memento.hubSpotConfig);
  }

  @action setFormSubmitted() {
    this.isFormSubmitted = true;
  }

  @action setError(error: Error) {
    Logger.exception(`HubSpot form error, form id = ${this.hubSpotConfig.formId}`, error);
    this.error = error;
  }

  abstract backToSummary(): void;
}
