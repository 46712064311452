//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { RequestData } from './typeHelpers';

export interface EntityData
{
    id: string;
}
export class Request<TResponse>
{
    public $type?: string;
    public $allowAnonymous: boolean;
    private $response: TResponse;
}
export interface WebShopOptionVariationData
{
    id: string;
    name: string;
    question: string;
    answer: string;
    fabricFamilyName: string;
    thumbnail: ImageData;
    isNotSelectedAccessory: boolean;
}
export interface WebShopPageData
{
    header?: WebShopHeaderData;
    benefitList?: WebShopBenefitListData;
    productList?: WebShopProductsData;
    articleList?: WebShopArticleListData;
    gallery?: GalleryData;
    teaser?: WebShopTeaserData;
}
export interface WebShopHeaderData
{
    slides: CarouselSlideData[];
    backgroundColor?: ColorData;
    textColor?: ColorData;
}
export interface CarouselSlideData
{
    title: string;
    image?: ImageData;
}
export interface WebShopBenefitListData
{
    title?: string;
    items: WebShopBenefitItemData[];
}
export interface WebShopBenefitItemData
{
    title?: string;
    description?: string;
    icon?: ImageData;
}
export interface WebShopProductsData
{
    title: string;
}
export interface WebShopArticleListData
{
    articles: WebShopArticleData[];
}
export interface WebShopArticleData
{
    image?: ImageData;
    title: string;
    description: string;
    link?: LinkData;
}
export interface WebShopTeaserData
{
    title: string;
    description?: string;
    link?: LinkData;
    image?: ImageData;
    video?: VideoData;
    mediaAlignment: Alignment;
    backgroundColor?: ColorData;
    textColor?: ColorData;
}
export interface WebShopProductListData extends EntityData
{
    code: string;
    listingConfigurationCode: string;
    name: string;
    shortName: string;
    brandId: string;
    brandName: string;
    lowestPrice: MoneyData;
    listingOptionVariations: WebShopOptionVariationData[];
}
export class GetFabricDescriptionQuery extends Request<GetFabricDescriptionQueryResponse>
{
    public fabricCode: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetFabricDescriptionQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetFabricDescriptionQuery';
    }
}
export interface GetFabricDescriptionQueryResponse
{
    description: string;
}
export class GetWebShopCmsContentQuery extends Request<GetWebShopCmsContentQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetWebShopCmsContentQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetWebShopCmsContentQuery';
    }
}
export interface GetWebShopCmsContentQueryResponse
{
    pageContent?: WebShopPageData;
}
export class GetWebShopProductDetailsQuery extends Request<GetWebShopProductDetailsQueryResponse>
{
    public productId: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetWebShopProductDetailsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetWebShopProductDetailsQuery';
    }
}
export interface WebShopProductDetailsData extends EntityData
{
    name: string;
    displayName: string;
    brandId: string;
    brandName: string;
    productFamily: WebShopProductFamilyData;
    mainProductOriginId: string;
    baseModelId: string;
    description: string;
    variations: WebShopProductVariationData[];
    optionGroups: WebShopOptionGroupData[];
    assets: AssetsData;
    specification: string;
    relatedProducts: WebShopRelatedProductData[];
}
export interface WebShopProductFamilyData
{
    name: string;
    shortName: string;
    description: string;
    mainImage?: ImageData;
    userGuide?: ProductFamilyVideoBlockData;
}
export interface WebShopOptionGroupData
{
    name: string;
    optionId: string;
    isFabric: boolean;
    options: WebShopOptionVariationData[];
}
export interface WebShopProductVariationData
{
    configurationCode: string;
    price: MoneyData;
    optionsIds: string[];
    deliveryTime?: DaysRangeData;
}
export interface WebShopRelatedProductData
{
    id: string;
    code: string;
    name: string;
    productId: string;
}
export interface GetWebShopProductDetailsQueryResponse
{
    product: WebShopProductDetailsData;
}
export class GetWebShopProductsQuery extends Request<GetWebShopProductsQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetWebShopProductsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetWebShopProductsQuery';
    }
}
export interface GetWebShopProductsQueryResponse
{
    products: WebShopProductListData[];
}
export interface AddWebShopProductToCartCommandResponse extends BaseCartRequestResponse
{
}
export interface BaseVisualizationData
{
    chairCode: string;
    productCode: string;
    mode: string;
    mapping: VisualizationMapping;
}
export interface Visualization3dData extends BaseVisualizationData
{
    baseAssets3dUrl: string;
    baseMaterials3dUrl: string;
}
export interface VisualizationData extends BaseVisualizationData
{
    basePartialImagesUrl: string;
    baseFlattenedImagesUrl: string;
}
export interface VisualizationMapping
{
    versionOfImages: number;
    shots: string[];
    staticComponents: string[];
    dynamicComponents: DynamicComponent[];
    segments: Segment[];
    globalCodesToCustomNames: ComponentCodeToCustomName[];
    transformationGroups: TransformationGroup[];
    cameraSetupGroups: CameraSetupGroup[];
    shadowCasters: string[];
}
export interface DynamicComponent
{
    condition?: string;
    code: string;
    formats: string[];
    codesToCustomNames: ComponentCodeToCustomName[];
}
export interface ComponentCodeToCustomName
{
    code: string;
    name: string;
}
export interface Segment
{
    name: string;
    condition: string;
    value: string;
}
export interface TransformationGroup
{
    condition: string;
    parts: string[];
    translation: Vector3;
}
export interface Vector3
{
    x: number;
    y: number;
    z: number;
}
export interface CameraSetupGroup
{
    condition: string;
    distanceFactor: number;
}
export interface StoreContext
{
    store: StoreData;
    language: LanguageData;
    currency: CurrencyData;
    featureToggles: FeatureToggles;
    requestQuoteConfig: RequestQuoteFormConfig;
    emailMeConfigurationConfig: HubSpotFormConfig;
    whereToBuyConfig: WhereToBuyConfig;
    availableCountries: string[];
    availablePaymentMethods: PaymentMethodData[];
    showroomConfig: ShowRoomConfig;
}
export interface PaymentMethodData
{
    logo: string;
    code: string;
    name: string;
    websiteUrl: string;
    supportedCreditCardsImage?: ImageData;
    displayLogo: boolean;
}
export interface FeatureToggles
{
    displayDecimalPartOfPrices: boolean;
    enablePurchase: boolean;
    enablePurchaseForBrands: string[];
    enableQuoteRequest: boolean;
    enablePrices: boolean;
    enableEmailMyConfiguration: boolean;
    enableShowroom: boolean;
    enableFindLocalDealer: boolean;
    enableConfigurationShare: boolean;
    enableExpectedShipmentDate: boolean;
    enableWhereToBuy: boolean;
    enableWebShopProductCustomization: boolean;
    enableWebShopPurchase: boolean;
}
export interface HubSpotFormConfig
{
    portalId: string;
    formId: string;
}
export interface RequestQuoteFormConfig extends HubSpotFormConfig
{
    isProcessedByDealer: boolean;
}
export interface ShowRoomConfig
{
    redirectionDelay: number;
    homePageUrl: string;
}
export interface WhereToBuyConfig
{
    pageUrl: string;
}
export interface StoreData
{
    id: string;
    name: string;
    displayName: string;
    isEnabled: boolean;
    languages: LanguageData[];
    currencies: CurrencyData[];
    countryCode: string;
    termsAndCondition: string;
}
export class GetAvailableStoreNamesQuery extends Request<GetAvailableStoreNamesQueryResponse>
{
    constructor (data?: RequestData<GetAvailableStoreNamesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetAvailableStoreNamesQuery';
    }
}
export interface GetAvailableStoreNamesQueryResponse
{
    availableStoreNames: string[];
}
export abstract class BaseGetStoreContextQuery extends Request<GetStoreContextQueryResponse>
{
    public storeId?: string;
    public languageCode?: string;
    public customer?: CustomerData;
}
export class GetStoreContextQuery extends BaseGetStoreContextQuery
{
    public currencyCode?: string;
    public countryCode?: string;
    public ip?: string;
    public preserveLanguage?: boolean;
    constructor (data?: RequestData<GetStoreContextQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetStoreContextQuery';
    }
}
export interface GetStoreContextQueryResponse
{
    storeContext: StoreContext;
    shoppingContext: ShoppingContext;
    shoppingCartSummary: ShoppingCartSummaryData;
    favoritesSummary: FavoritesSummaryData;
    availableStores: StoreData[];
}
export interface GeolocationData
{
    lat: number;
    lng: number;
}
export interface PhysicalStoreData
{
    id: string;
    name: string;
    address: AddressData;
    phone: string;
    email: string;
    webSite: string;
    coordinates: GeolocationData;
    storeTypes: string[];
    brands: string[];
    openingHours: PhysicalStoreOpeningHoursData[];
}
export interface PhysicalStoreOpeningHoursData
{
    days: string;
    hours: string;
    note: string;
}
export class GetPhysicalStoresQuery extends Request<GetPhysicalStoresResponse>
{
    public shoppingContext: ShoppingContext;
    public sortingCenter: GeolocationData;
    public brands: string[];
    public storeTypes: string[];
    constructor (data?: RequestData<GetPhysicalStoresQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetPhysicalStoresQuery';
    }
}
export interface GetPhysicalStoresResponse
{
    physicalStores: PhysicalStoreData[];
    availableStoreTypes: string[];
    availableBrands: string[];
}
export abstract class BaseCartRequest<TResponse> extends Request<TResponse>
{
    public shoppingContext: ShoppingContext;
}
export interface BaseCartRequestResponse
{
    shoppingCart: ShoppingCartData;
}
export interface DiscountData
{
    promotionId: string;
    currency: string;
    amount: number;
    amountWithTax: number;
    coupon: string;
    description?: string;
}
export interface SelectedOptionData
{
    id: string;
    code: string;
    name: string;
    variationId: string;
    variationName: string;
    variationCode: string;
}
export interface ShipmentData extends EntityData
{
    shipmentMethodCode: string;
    shipmentMethodOption: string;
    price: MoneyData;
    priceWithTax: MoneyData;
    taxPercentRate: number;
}
export interface ShippingMethodData
{
    code: string;
    name: string;
    description: string;
}
export interface ShippingRateData
{
    name: string;
    description: string;
    rate: MoneyData;
    rateWithTax: MoneyData;
    method: ShippingMethodData;
}
export interface ShoppingCartData extends EntityData
{
    customName: string;
    brandId: string;
    brandName: string;
    appliedCoupons: string[];
    discountTotalWithTax: MoneyData;
    items: ShoppingCartItemData[];
    subTotal: MoneyData;
    subTotalWithTax: MoneyData;
    subTotalTax: MoneyData;
    shippingTotalWithTax: MoneyData;
    taxTotal: MoneyData;
    totalWithTax: MoneyData;
    taxRatePercentage: number;
    preparingTimeInWorkingDays?: DaysRangeData;
    deliveryTime?: DaysRangeData;
    shipments: ShipmentData[];
    discounts: DiscountData[];
    surcharges: SurchargeData[];
    factoryName?: string;
    factoryId?: string;
}
export interface ShoppingCartItemData extends EntityData
{
    baseModel: ShoppingCartBaseModelData;
    options: SelectedOptionData[];
    accessories: SelectedOptionData[];
    listPrice: MoneyData;
    salePrice: MoneyData;
    salePriceWithTax: MoneyData;
    totalPrice: MoneyData;
    totalPriceWithTax: MoneyData;
    taxTotal: MoneyData;
    quantity: number;
    code: string;
    invalidConfiguration: boolean;
    systemMessage: string;
    customName?: string;
}
export interface ShoppingCartBaseModelData
{
    id: string;
    name: string;
    shortName: string;
    code: string;
    brandName: string;
    productFamilyShortName: string;
    mainProductId: string;
    mainProductOriginId?: string;
    productOriginId?: string;
    price: MoneyData;
}
export interface ShoppingCartSummaryData extends EntityData
{
    numberOfItems: number;
    total: MoneyData;
}
export interface ShoppingContext
{
    storeId: string;
    storeType: string;
    languageCode: string;
    currencyCode: string;
    customer: CustomerData;
}
export class GetCartQuery extends Request<GetCartQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetCartQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetCartQuery';
    }
}
export interface GetCartQueryResponse
{
    shoppingCart: ShoppingCartData;
    shoppingCartSummary: ShoppingCartSummaryData;
}
export class GetShippingMethodsQuery extends Request<GetShippingMethodsQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetShippingMethodsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetShippingMethodsQuery';
    }
}
export interface GetShippingMethodsQueryResponse
{
    shippingRates: ShippingRateData[];
}
export class AddCouponToCartCommand extends BaseCartRequest<AddCouponToCartCommandResponse>
{
    public couponCode: string;
    constructor (data?: RequestData<AddCouponToCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddCouponToCartCommand';
    }
}
export interface AddCouponToCartCommandResponse extends BaseCartRequestResponse
{
    couponApplied: boolean;
}
export class AddProductToCartCommand extends BaseCartRequest<AddProductToCartCommandResponse>
{
    public productConfiguration: ProductConfigurationData;
    public ignorePriceGroupValidation?: boolean;
    constructor (data?: RequestData<AddProductToCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddProductToCartCommand';
    }
}
export interface AddProductToCartCommandResponse extends BaseCartRequestResponse
{
}
export class ClearCartCommand extends BaseCartRequest<ClearCartCommandResponse>
{
    constructor (data?: RequestData<ClearCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'ClearCartCommand';
    }
}
export interface ClearCartCommandResponse extends BaseCartRequestResponse
{
}
export class RemoveItemFromCartCommand extends BaseCartRequest<RemoveItemFromCartCommandResponse>
{
    public itemId: string;
    constructor (data?: RequestData<RemoveItemFromCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'RemoveItemFromCartCommand';
    }
}
export interface RemoveItemFromCartCommandResponse extends BaseCartRequestResponse
{
}
export class SetShippingMethodCommand extends Request<SetShippingMethodCommandResponse>
{
    public shipmentMethodCode: string;
    public shipmentMethodOption: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<SetShippingMethodCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'SetShippingMethodCommand';
    }
}
export interface SetShippingMethodCommandResponse extends BaseCartRequestResponse
{
}
export class AssociateUserIdentityCommand extends Request<AssociateUserIdentityCommandResponse>
{
    public invitationToken: string;
    public userExternalId: string;
    constructor (data?: RequestData<AssociateUserIdentityCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AssociateUserIdentityCommand';
    }
}
export interface AssociateUserIdentityCommandResponse
{
    storeId: string;
    languageCode: string;
}
export class VerifyInvitationCommand extends Request<VerifyInvitationCommandResponse>
{
    public token: string;
    public userEmail: string;
    constructor (data?: RequestData<VerifyInvitationCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'VerifyInvitationCommand';
        this.$allowAnonymous = true;
    }
}
export interface VerifyInvitationCommandResponse
{
    isActive: boolean;
    matchingContactExists: boolean;
}
export abstract class BaseSearchQuery<TResponse> extends Request<TResponse>
{
    public phrase: string;
    public store: string;
    public language: string;
    public skip: number;
    public take: number;
}
export class ProductSearchQuery extends BaseSearchQuery<ProductSearchQueryResponse>
{
    constructor (data?: RequestData<ProductSearchQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'ProductSearchQuery';
    }
}
export interface ProductSearchQueryResponse
{
    languageCode: string;
    storeId: string;
    total: number;
    products: BaseModelBasicData[];
}
export interface AccessoryData extends ComponentData
{
    description: string;
    variationCode: string;
    price: MoneyData;
    priceGroupSpecificPrices: PriceGroupSpecificPriceData[];
    image: ImageData;
    restrictions: RestrictionData[];
}
export interface AssetCategoryData
{
    categoryName: string;
    assets: AssetData[];
}
export interface AssetData
{
    name: string;
    url: string;
}
export interface AssetsData
{
    categories: AssetCategoryData[];
    otherAssets: AssetData[];
}
export interface BaseModelBasicData extends IBaseModel, EntityData
{
    familyId: string;
    name: string;
    displayName: string;
    code: string;
    description: string;
    shortDescription: string;
    specificationDescription: string;
    availableInCurrentStore: boolean;
    specification: PropertyData[];
    outline?: ImageData;
    images: ImageData[];
    predefinedConfigurations: PredefinedConfigurationData[];
    price: MoneyData;
    relatedProductsIds: string[];
    canBePurchased: boolean;
    brandId: string;
    brandName: string;
    environmentalData: EnvironmentalData;
    visualizationAspectRatio: string;
}
export interface BaseModelData extends IBaseModel, EntityData
{
    productFamilyId: string;
    productFamilyShortName: string;
    mainProductId: string;
    name: string;
    shortName: string;
    code: string;
    description: string;
    shortDescription: string;
    brandId: string;
    brandName: string;
    price: MoneyData;
    availableInCurrentStore: boolean;
    canBePurchased: boolean;
    isActive: boolean;
    purchaseQuantityLimit: number;
    reasonForInactivation: string;
    specification: BaseModelDetailsData;
    moreInformation: BaseModelDetailsData;
    image: ImageData;
}
export interface BaseModelDetailsData
{
    description?: string;
    properties: PropertyData[];
    documents: DocumentData[];
}
export interface ComponentData extends EntityData
{
    name: string;
    code: string;
    tabCode: string;
    index: number;
    enabledBy: OptionVariationDescriptorData[];
    enabledByOperator: EnabledByOperator;
}
export interface DesignerData
{
    name: string;
    description: string;
    imageUrl: string;
    videoUrl: string;
    url: string;
}
export interface EnvironmentalData
{
    co2EmissionKg?: number;
    energyUsageMj?: number;
    recycledMaterialsTotalPercentage?: number;
    recycledPlasticPercentage?: number;
    recycledAluminiumPercentage?: number;
    recyclableMaterialsPercentage?: number;
    certificates: ImageData[];
}
export interface FabricCollectionData
{
    id: string;
    name: string;
    includedFabricsCodes: string[];
}
export interface GetBasicProductByIdQueryResponse
{
    product: BaseModelBasicData;
}
export interface IBaseModel
{
    availableInCurrentStore: boolean;
    canBePurchased: boolean;
    brandId: string;
    brandName: string;
}
export interface KindOfFabricRestrictionData
{
    variant: OptionVariationDescriptorData;
    restrictedVariant: OptionVariationDescriptorData;
    type: RestrictionType;
}
export interface OptionData extends ComponentData
{
    defaultVariationId?: string;
    determinesPriceGroup?: boolean;
    type: OptionType;
    groups: OptionVariationGroupData[];
    filters: FilterData[];
    priceGroups: PriceGroupData[];
    kindOfFabricId?: string;
}
export interface OptionVariationData extends EntityData
{
    fullName: string;
    name: string;
    code: string;
    alternativeCode?: string;
    description?: string;
    price: MoneyData;
    image: ImageData;
    properties: PropertyData[];
    restrictions: RestrictionData[];
    origin: OptionVariationDescriptorData[];
    kindOfFabricVariationIds?: string[];
}
export interface OptionVariationDescriptorData
{
    optionId: string;
    optionCode: string;
    variationId: string;
    variationCode: string;
}
export interface OptionVariationGroupData
{
    name: string;
    description?: string;
    priceGroup?: string;
    variations: OptionVariationData[];
}
export interface PredefinedConfigurationData
{
    code: string;
    shot?: string;
    image: ImageData;
    visualizationAspectRatio: string;
}
export interface PriceGroupData extends EntityData
{
    number: string;
    name: string;
    price: MoneyData;
    code: string;
    variationCode: string;
}
export interface ProductConfigurationData
{
    baseModelId: string;
    baseModelCode: string;
    priceGroupId?: string;
    priceGroups: SelectedComponentData[];
    origin?: ProductConfigurationOrigin;
    options: SelectedComponentData[];
    accessories: SelectedComponentData[];
    quantity: number;
}
export interface ProductFamilyBasicData extends EntityData
{
    code: string;
    name: string;
    shortName: string;
    brand: string;
    image: ImageData;
    listingIcon?: ImageData;
    properties: PropertyData[];
    models: BaseModelLiteData[];
}
export interface ProductFamilyData extends EntityData
{
    name: string;
    shortName: string;
    code: string;
    brand: string;
    description: string;
    models: BaseModelBasicData[];
    designers: DesignerData[];
    mainImage: ImageData;
    listingIcons: ImageData[];
    listingIcon?: ImageData;
    assets: AssetsData;
    predefinedConfigurations: PredefinedConfigurationData[];
    gallery: ImageData[];
    userStories: UserStoryData[];
    relatedProducts: RelatedProductData[];
    heroVideoLink: string;
    userGuide: ProductFamilyVideoBlockData;
    salesPitch: ProductFamilyVideoBlockData;
}
export interface ProductFamilyVideoBlockData
{
    title: string;
    description: string;
    videoLink: string;
    videoOverlayText: string;
    videoPosterLink: string;
}
export interface RelatedProductData
{
    familyId: string;
    familyName: string;
    familyShortName: string;
    modelId: string;
    modelName: string;
    brand: string;
    image?: ImageData;
    predefinedConfiguration?: PredefinedConfigurationData;
}
export interface RestrictionData
{
    entitiesIds: string[];
    type: RestrictionType;
}
export interface SelectedComponentData
{
    componentCode: string;
    variationCode: string;
    id: string;
}
export interface UserStoryData
{
    imageUrl: string;
    videoUrl: string;
    description: string;
    url: string;
    name: string;
}
export interface BaseModelLiteData extends EntityData
{
    name: string;
    image?: ImageData;
    predefinedConfiguration?: PredefinedConfigurationData;
    canBePurchased: boolean;
    properties: PropertyData[];
    visualizationAspectRatio: string;
    visualizationMode: string;
}
export class GetBasicProductByIdQuery extends Request<GetBasicProductByIdQueryResponse>
{
    public productId: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetBasicProductByIdQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetBasicProductByIdQuery';
    }
}
export interface GetDisplayNameQueryResponse
{
    componentIdToName: { [key:string]: string };
}
export class GetDisplayNamesQuery extends Request<GetDisplayNameQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public ids: string[];
    constructor (data?: RequestData<GetDisplayNamesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDisplayNamesQuery';
    }
}
export class GetFeaturesVariationsNamesQuery extends Request<GetFeaturesVariationsNamesQueryResponse>
{
    public variationsIds: string[];
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetFeaturesVariationsNamesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetFeaturesVariationsNamesQuery';
    }
}
export interface GetFeaturesVariationsNamesQueryResponse
{
    featuresVariationDisplayNames: FeatureVariationDisplayName[];
}
export interface FeatureVariationDisplayName
{
    variationId: string;
    variationCode: string;
    featureName?: string;
    variationName?: string;
    fabricFamilyName?: string;
}
export class GetProductByIdQuery extends Request<GetProductQueryResponse>
{
    public productId: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductByIdQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductByIdQuery';
    }
}
export class GetProductConfigurationByCodeQuery extends Request<GetProductConfigurationByCodeQueryResponse>
{
    public code: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductConfigurationByCodeQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductConfigurationByCodeQuery';
    }
}
export interface GetProductConfigurationByCodeQueryResponse
{
    productNumber: string;
    productName: string;
    outline?: ImageData;
    visualizationMode: string;
    configuration: ProductConfigurationData;
    options: SelectedOptionData[];
    accessories: SelectedOptionData[];
}
export class GetProductFamiliesQuery extends Request<GetProductFamiliesQueryResponse>
{
    public skip: number;
    public take: number;
    public types?: string[];
    public sectors?: string[];
    public brands?: string[];
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductFamiliesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductFamiliesQuery';
    }
}
export interface GetProductFamiliesQueryResponse
{
    productFamilies: ProductFamilyBasicData[];
    totalCount: number;
}
export class GetProductFamilyFiltersQuery extends Request<GetProductFamilyFiltersQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductFamilyFiltersQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductFamilyFiltersQuery';
    }
}
export interface GetProductFamilyFiltersQueryResponse
{
    typeFilter?: FilterData;
    sectorFilter?: FilterData;
    brandFilter?: FilterData;
}
export class GetProductFamilyModelsQuery extends Request<GetProductFamilyModelsQueryResponse>
{
    public productFamilyId: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductFamilyModelsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductFamilyModelsQuery';
    }
}
export interface GetProductFamilyModelsQueryResponse
{
    models: BaseModelBasicData[];
}
export class GetProductFamilyQuery extends Request<GetProductFamilyQueryResponse>
{
    public familyId: string;
    public shoppingContext: ShoppingContext;
    public previewMode: boolean;
    constructor (data?: RequestData<GetProductFamilyQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductFamilyQuery';
    }
}
export interface GetProductFamilyQueryResponse
{
    productFamily: ProductFamilyData;
    redirectToFamilyId?: string;
}
export interface GetProductQueryResponse
{
    baseModel: BaseModelData;
    priceGroups: PriceGroupData[];
    options: OptionData[];
    accessories: AccessoryData[];
    hidden: OptionVariationDescriptorData[];
    hiddenConsolidated: HiddenConsolidatedOptionDescriptorData[];
    tabs: TabData[];
    visualization: BaseVisualizationData;
    redirectToProductId?: string;
    kindOfFabricRestrictions: KindOfFabricRestrictionData[];
    fabricCollections: FabricCollectionData[];
    fabricErrors: any;
}
export interface HiddenConsolidatedOptionDescriptorData
{
    id: string;
    code: string;
    enablers: OptionVariationDescriptorData[];
}
export interface TabData
{
    code: string;
}
export class GenerateConfigurationPdfCommand extends Request<GenerateConfigurationPdfCommandResponse>
{
    public configurationCode: string;
    public pdfGenerationUrl: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GenerateConfigurationPdfCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GenerateConfigurationPdfCommand';
    }
}
export interface GenerateConfigurationPdfCommandResponse
{
    fileUrl: string;
}
export class GenerateZipArchiveCommand extends Request<GenerateZipArchiveCommandResponse>
{
    public files: string[];
    public outputFile: string;
    constructor (data?: RequestData<GenerateZipArchiveCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GenerateZipArchiveCommand';
    }
}
export interface GenerateZipArchiveCommandResponse
{
    fileUrl: string;
}
export interface RawEnablersGroupData
{
    enablers: RawOptionReferenceData[];
    operator: RawFeatureEnablersLogicalOperator;
}
export interface RawFeatureData extends RawObjectData
{
    type: RawFeatureType;
    optionValueType: RawOptionValueType;
    enablersGroups: RawEnablersGroupData[];
    options: RawFeatureOptionData[];
    determinesPriceGroupFeatureCode: string;
    includeInConfigurationCode: boolean;
}
export interface RawFeatureOptionData extends RawObjectData
{
    relations: RawRelationData[];
}
export interface RawFeatureSequenceEntryData
{
    code: string;
    enablersGroups: RawEnablersGroupData[];
}
export interface RawObjectData
{
    code: string;
    name: string;
}
export interface RawOptionReferenceData
{
    featureCode: string;
    optionCode: string;
}
export interface RawProductStructureData
{
    productNumber: string;
    productName: string;
    features: RawFeatureData[];
    featuresSequence: RawFeatureSequenceEntryData[];
    relations: RawRelationData[];
}
export interface RawRelationData
{
    target: RawOptionReferenceData;
    type: RawRelationType;
}
export class FindFabricsOptionsQuery extends Request<FindFabricsOptionsQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public productId: string;
    public restrictingOptions: RawOptionReferenceData[];
    public featureCode: string;
    public optionSearchPhrase?: string;
    public validFeatureCodes: string[];
    public take: number;
    constructor (data?: RequestData<FindFabricsOptionsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'FindFabricsOptionsQuery';
    }
}
export interface FindFabricsOptionsQueryResponse
{
    options: RawFeatureOptionData[];
}
export class GetProductStructureByIdQuery extends Request<GetProductStructureByIdQueryResponse>
{
    public productId: string;
    public ignoreIrrelevantFeaturesAndOptions: boolean;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductStructureByIdQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductStructureByIdQuery';
    }
}
export interface GetProductStructureByIdQueryResponse
{
    structure: RawProductStructureData;
}
export interface OrderData
{
    id: string;
    number: string;
    createdDate?: string;
    customName?: string;
    customReference?: string;
    customerName?: string;
    contactAndBilling: ContactAndBillingData;
    deliveryAddress: DeliveryAddressData;
    subTotal: MoneyData;
    subTotalWithTax: MoneyData;
    subTotalTax: MoneyData;
    totalShippingWithTax: MoneyData;
    taxTotal: MoneyData;
    total: MoneyData;
    totalWithTax: MoneyData;
    taxRatePercentage: number;
    status: string;
    items: OrderItemData[];
    payments: PaymentData[];
    shipments: ShipmentData[];
    history: OrderStatusHistoryEntryData[];
    erpChangesNotSynchronized: boolean;
    preparingTimeInWorkingDays?: DaysRangeData;
    deliveryTime?: DaysRangeData;
    expectedShipping?: DaysRangeData;
    surcharges: SurchargeData[];
    shippingDate?: string;
}
export interface OrderItemData extends EntityData
{
    name: string;
    shortName: string;
    brandName: string;
    familyId: string;
    familyShortName: string;
    baseModelId: string;
    mainProductId: string;
    mainProductOriginId?: string;
    productOriginId?: string;
    configurationCode: string;
    listPrice: MoneyData;
    salePrice: MoneyData;
    price: MoneyData;
    totalPrice: MoneyData;
    totalTax: MoneyData;
    quantity: number;
}
export interface OrderStatusHistoryEntryData
{
    dateTime?: string;
    status: string;
    isActive: boolean;
}
export interface PaymentData
{
    code: string;
    isPaid: boolean;
    isApproved: boolean;
    message: string;
    parameters: PaymentParameterData[];
}
export interface PaymentOperationResultData
{
    isSuccess: boolean;
    redirectUrl?: string;
    sessionId: string;
    error?: string;
    code: string;
    paymentOption?: string;
    parameters: PaymentParameterData[];
}
export interface PaymentParameterData
{
    key: string;
    value: string;
}
export interface ShipmentData
{
    number: string;
    deliveryDate: string;
    shippingDate: string;
    trackingUrl: string;
    lineItemsIds: string[];
}
export class GetCityByZipCodeQuery extends Request<GetCityByZipCodeResponse>
{
    public countryCode: string;
    public zipCode: string;
    constructor (data?: RequestData<GetCityByZipCodeQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetCityByZipCodeQuery';
    }
}
export interface GetCityByZipCodeResponse
{
    city: string;
}
export class GetOrderQuery extends Request<GetOrderQueryResponse>
{
    public orderNumber: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetOrderQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetOrderQuery';
    }
}
export interface GetOrderQueryResponse
{
    order: OrderData;
}
export class GetOrderWithFamilyDataQuery extends Request<GetOrderWithFamilyDataQueryResponse>
{
    public orderNumber: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetOrderWithFamilyDataQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetOrderWithFamilyDataQuery';
    }
}
export interface GetOrderWithFamilyDataQueryResponse
{
    order: OrderData;
    designer: DesignerData;
    userStory: UserStoryData;
}
export class CreateOrderCommand extends Request<CreateOrderCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public contactAndBilling: ContactAndBillingData;
    public deliveryAddress: DeliveryAddressData;
    public paymentMethodCode: string;
    public parameters: PaymentParameterData[];
    constructor (data?: RequestData<CreateOrderCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'CreateOrderCommand';
    }
}
export interface CreateOrderCommandResponse
{
    order: OrderData;
    paymentOperationResult: PaymentOperationResultData;
}
export class FinalizePaymentCommand extends Request<FinalizePaymentCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public orderId: string;
    public gatewayCode: string;
    public parameters: PaymentParameterData[];
    constructor (data?: RequestData<FinalizePaymentCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'FinalizePaymentCommand';
    }
}
export interface FinalizePaymentCommandResponse
{
    operationResult: PaymentOperationResultData;
}
export interface ModularComponentData
{
    productId: string;
    position: Vector3;
    rotation: Vector3;
}
export interface ModularLayoutBasicData extends EntityData
{
    code: string;
    name: string;
    thumbnail: ImageData;
}
export interface ModularLayoutData extends EntityData
{
    code: string;
    components: ModularComponentData[];
}
export interface ModularProductFamilyData extends EntityData
{
    code: string;
    name: string;
    layouts: ModularLayoutBasicData[];
}
export class GetModularLayoutQuery extends Request<GetModularLayoutQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public layoutId: string;
    constructor (data?: RequestData<GetModularLayoutQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetModularLayoutQuery';
    }
}
export interface GetModularLayoutQueryResponse
{
    layout: ModularLayoutData;
}
export class GetModularProductFamilyQuery extends Request<GetModularProductFamilyQueryResponse>
{
    public productFamilyId: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetModularProductFamilyQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetModularProductFamilyQuery';
    }
}
export interface GetModularProductFamilyQueryResponse
{
    productFamily: ModularProductFamilyData;
}
export interface MenuItem
{
    code: string;
    order: number;
    name: string;
    url: string;
    target: string;
    children: MenuItem[];
}
export interface SimpleMenuItem
{
    name: string;
    target: string;
    url: string;
}
export interface TopBarData
{
    isEnabled: boolean;
    menu: SimpleMenuItem[];
    translation: TopBarTranslation;
    storeLinkUrl?: string;
}
export interface TopBarTranslation
{
    cartTranslation: CartTranslation;
    storeLinkLabel: string;
}
export interface CartTranslation
{
    continueShoppingText: string;
    emptyCartMainText: string;
    emptyCartSecondaryText: string;
    numberOfProductsText: string;
    shoppingCartText: string;
}
export interface FooterData
{
    displayNewFooter: boolean;
    address: string;
    copyright: FooterCopyrightData;
    emailAddress: FooterEmailAddressData;
    links: MenuItem[];
    phoneNumber: FooterPhoneNumberData;
    socialMedia: FooterSocialMediaData;
    paymentInformation: FooterPaymentInformationData;
    contact: string;
    copyrights: string;
}
export interface FooterCopyrightData
{
    copyrightNoticeText: string;
    allRightsReservedText: string;
}
export interface FooterEmailAddressData
{
    label: string;
    value: string;
}
export interface FooterPhoneNumberData
{
    label: string;
    formattedValue: string;
    value: string;
}
export interface FooterSocialMediaData
{
    followUsText: string;
    facebookLink: string;
    instagramLink: string;
    pinterestLink: string;
    linkedInLink: string;
    blogLink: string;
}
export interface FooterPaymentInformationData
{
    options: FooterPaymentOptionData[];
}
export interface FooterPaymentOptionData
{
    logo: ImageData;
}
export class GetNavigationQuery extends Request<GetNavigationQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetNavigationQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetNavigationQuery';
    }
}
export interface GetNavigationQueryResponse
{
    menuItems: MenuItem[];
    searchUrl: string;
    homeUrl: string;
    termsAndConditionsUrl: string;
    legalAgreementsConsentHtml: string;
    customerServiceUrl: string;
    footer: FooterData;
    initialStoreSelector: InitialStoreSelectorData;
    topBar: TopBarData;
}
export interface InitialStoreSelectorData
{
    backgroundImage?: ImageData;
    backgroundVideoUrl?: string;
}
export class GetPromotionForConfigurationCodeQuery extends Request<GetPromotionForConfigurationCodeQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public configurationCode: string;
    public campaignCode?: string;
    constructor (data?: RequestData<GetPromotionForConfigurationCodeQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetPromotionForConfigurationCodeQuery';
    }
}
export interface GetPromotionForConfigurationCodeQueryResponse
{
    isPromoted: boolean;
    promotionName: string;
    promotionDescription: string;
}
export class CalculatePreparingTimeForCodeCommand extends Request<CalculatePreparingTimeForCodeResponse>
{
    public storeId: string;
    public storeType: string;
    public configurationCode: string;
    constructor (data?: RequestData<CalculatePreparingTimeForCodeCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'CalculatePreparingTimeForCodeCommand';
    }
}
export interface CalculatePreparingTimeForCodeResponse
{
    preparingTimeInWorkingDays: DaysRangeData;
}
export interface ContactAndBillingData
{
    firstName: string;
    lastName: string;
    companyName: string;
    taxId: string;
    email: string;
    address: AddressData;
    phoneData: PhoneData;
}
export interface ContactData
{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    fullName: string;
    language: string;
    roles: string[];
}
export interface CustomerData extends EntityData
{
    userName: string;
    isRegistered: boolean;
    roles: string[];
}
export interface NotificationSubscriptionData
{
    notificationType: string;
    isActive: boolean;
}
export interface PhoneData
{
    prefix: string;
    number: string;
}
export class GetPageContentQuery extends Request<GetPageContentQueryResponse>
{
    public pagePath: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetPageContentQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetPageContentQuery';
    }
}
export interface GetPageContentQueryResponse
{
    content: string;
}
export class GetPagesQuery extends Request<GetPagesQueryResponse>
{
    public path: string;
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetPagesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetPagesQuery';
    }
}
export interface GetPagesQueryResponse
{
    pages: PageInfo[];
}
export interface PageInfo
{
    name: string;
    path: string;
}
export interface AddressData
{
    line1: string;
    line2: string;
    zipCode: string;
    city: string;
    country: CountryData;
}
export interface BrandData
{
    id: string;
    name: string;
}
export interface ColorData
{
    hex: string;
    rgba: string;
    opacity: number;
}
export interface CountryData
{
    code: string;
    name: string;
}
export interface CurrencyData
{
    code: string;
}
export interface DaysRangeData
{
    minDays: number;
    maxDays: number;
}
export interface DeliveryAddressData extends AddressData
{
    recipient: string;
}
export interface DocumentData
{
    name: string;
    url: string;
}
export interface FilterData
{
    name: string;
    displayName: string;
    availableProperties: PropertyData[];
}
export interface GalleryData
{
    backgroundColor?: ColorData;
    backgroundEnabled?: boolean;
    images: ImageData[];
}
export interface ImageData
{
    name: string;
    size?: SizeData;
    url?: string;
}
export interface LanguageData
{
    code: string;
}
export interface LinkData
{
    noFollow?: boolean;
    openInNewTab?: boolean;
    url: string;
    type: string;
}
export interface MoneyData
{
    amount: number;
    currency: CurrencyData;
}
export interface PriceGroupSpecificPriceData
{
    priceGroup: string;
    price: MoneyData;
}
export interface PropertyData
{
    name: string;
    displayName: string;
    value: string;
    valueId: string;
}
export interface SizeData
{
    width: number;
    height: number;
}
export interface SurchargeData
{
    code: string;
    price: MoneyData;
    priceWithTax: MoneyData;
    taxPercentage: number;
}
export interface VideoData
{
    title?: string;
    posterUrl: string;
    url: string;
}
export class GetConfigurationByCodeQuery extends Request<GetConfigurationByCodeQueryResponse>
{
    public code: string;
    constructor (data?: RequestData<GetConfigurationByCodeQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetConfigurationByCodeQuery';
    }
}
export interface GetConfigurationByCodeQueryResponse
{
    productConfiguration: ProductConfigurationData;
}
export class GenerateConfigurationCodeCommand extends Request<GenerateConfigurationCodeResponse>
{
    public productConfiguration: ProductConfigurationData;
    constructor (data?: RequestData<GenerateConfigurationCodeCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GenerateConfigurationCodeCommand';
    }
}
export interface GenerateConfigurationCodeResponse
{
    code: string;
}
export interface DealerDeliveryAddressData extends DeliveryAddressData
{
    erpNumber?: string;
}
export class CreateDealerOrderCommand extends Request<CreateDealerOrderCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public shoppingCarts: ShoppingCartDescriptor[];
    public comment?: string;
    public deliveryAddress: DealerDeliveryAddressData;
    public deliveryInstructions?: string;
    constructor (data?: RequestData<CreateDealerOrderCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'CreateDealerOrderCommand';
    }
}
export interface ShoppingCartDescriptor
{
    id: string;
    customName: string;
    customReference: string;
}
export interface CreateDealerOrderCommandResponse extends BaseDealerCartRequestResponse
{
    success: boolean;
    invalidConfigurationCodes: string[];
    failedShoppingCartIds: string[];
    orders: OrderData[];
}
export interface DealerCartsData
{
    carts: ShoppingCartData[];
    cartsSummary: ShoppingCartSummaryData;
}
export class SendCartContentCommand extends Request<SendCartContentCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public shoppingCartsIds: string[];
    public recipients: string[];
    constructor (data?: RequestData<SendCartContentCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'SendCartContentCommand';
    }
}
export interface SendCartContentCommandResponse
{
}
export class GetDealerCartQuery extends Request<GetDealerCartQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetDealerCartQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerCartQuery';
    }
}
export interface GetDealerCartQueryResponse
{
    dealerCarts: DealerCartsData;
}
export class GetDealerContextQuery extends BaseGetStoreContextQuery
{
    constructor (data?: RequestData<GetDealerContextQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerContextQuery';
    }
}
export interface GetDealerDefaultAddressQueryResponse
{
    defaultAddress: DealerDeliveryAddressData;
    defaultCountry: CountryData;
}
export class GetDealerStoreQuery extends Request<GetDealerStoreQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetDealerStoreQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerStoreQuery';
    }
}
export interface GetDealerStoreQueryResponse
{
    store: StoreData;
}
export class GetUserDefaultDealerOrganizationQuery extends Request<GetUserDefaultDealerOrganizationQueryResponse>
{
    constructor (data?: RequestData<GetUserDefaultDealerOrganizationQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetUserDefaultDealerOrganizationQuery';
    }
}
export interface GetUserDefaultDealerOrganizationQueryResponse
{
    storeId: string;
    languageCode: string;
}
export interface CreateInvitationData
{
    firstName: string;
    lastName: string;
    email: string;
    retypedEmail: string;
    grantedRoles: string[];
}
export interface InvitationData
{
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    createdAt?: string;
    expiresAt?: string;
}
export class GetAvailableContactRolesQuery extends Request<GetAvailableContactRolesQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetAvailableContactRolesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetAvailableContactRolesQuery';
    }
}
export interface GetAvailableContactRolesQueryResponse
{
    roles: string[];
}
export class GetDealerContactQuery extends Request<GetDealerContactQueryResponse>
{
    constructor (data?: RequestData<GetDealerContactQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerContactQuery';
    }
}
export interface GetDealerContactQueryResponse
{
    contact: ContactData;
    notificationSubscriptions: NotificationSubscriptionData[];
}
export class GetDealerInvitationsQuery extends Request<GetDealerInvitationsQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetDealerInvitationsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerInvitationsQuery';
    }
}
export interface GetDealerInvitationsQueryResponse
{
    invitations: InvitationData[];
}
export class GetDealerMembersQuery extends Request<GetDealerMembersQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetDealerMembersQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerMembersQuery';
    }
}
export interface GetDealerMembersQueryResponse
{
    members: ContactData[];
}
export class InviteDealerContactCommand extends Request<InviteDealerContactCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public invitation: CreateInvitationData;
    constructor (data?: RequestData<InviteDealerContactCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'InviteDealerContactCommand';
    }
}
export interface InviteDealerContactCommandResponse
{
}
export class RemoveContactFromDealerCommand extends Request<RemoveContactFromDealerCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public contactId: string;
    constructor (data?: RequestData<RemoveContactFromDealerCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'RemoveContactFromDealerCommand';
    }
}
export interface RemoveContactFromDealerCommandResponse
{
}
export class RemoveInvitationCommand extends Request<void>
{
    public shoppingContext: ShoppingContext;
    public invitationId: string;
    constructor (data?: RequestData<RemoveInvitationCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'RemoveInvitationCommand';
    }
}
export class SetContactLastDealerUsedCommand extends Request<SetContactLastDealerUsedCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public storeId: string;
    constructor (data?: RequestData<SetContactLastDealerUsedCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'SetContactLastDealerUsedCommand';
    }
}
export interface SetContactLastDealerUsedCommandResponse
{
    contact: ContactData;
}
export class SetDealerContactRolesCommand extends Request<SetDealerContactRolesCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public contactId: string;
    public roles: string[];
    constructor (data?: RequestData<SetDealerContactRolesCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'SetDealerContactRolesCommand';
    }
}
export interface SetDealerContactRolesCommandResponse
{
    contact: ContactData;
}
export class UpdateDealerContactCommand extends Request<UpdateDealerContactCommandResponse>
{
    public contact: ContactData;
    public contactSubscriptions: NotificationSubscriptionData[];
    constructor (data?: RequestData<UpdateDealerContactCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'UpdateDealerContactCommand';
    }
}
export interface UpdateDealerContactCommandResponse
{
    contact: ContactData;
    contactSubscriptions: NotificationSubscriptionData[];
}
export class GetDealerOrderDetailsQuery extends Request<GetDealerOrderDetailsQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public orderNumber: string;
    constructor (data?: RequestData<GetDealerOrderDetailsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerOrderDetailsQuery';
    }
}
export interface GetDealerOrderDetailsQueryResponse
{
    orderDetails: OrderData;
}
export class GetDealerOrdersQuery extends Request<GetDealerOrdersQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public searchTerm?: string;
    public dateFrom?: string;
    public dateTo?: string;
    public sortColumn: OrderSortColumn;
    public sortDirection: OrderSortDirection;
    public status: OrderAggregatedStatus;
    public onlyMine: boolean;
    public skip: number;
    public take: number;
    constructor (data?: RequestData<GetDealerOrdersQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerOrdersQuery';
    }
}
export interface GetDealerOrdersQueryResponse
{
    orders: OrderData[];
    totalCount: number;
}
export interface AddEntireDealerOrderToCartCommandResponse extends BaseDealerCartRequestResponse
{
}
export class UpdateDealerOrderNameCommand extends Request<UpdateDealerOrderNameCommandResponse>
{
    public shoppingContext: ShoppingContext;
    public orderNumber: string;
    public orderName: string;
    constructor (data?: RequestData<UpdateDealerOrderNameCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'UpdateDealerOrderNameCommand';
    }
}
export interface UpdateDealerOrderNameCommandResponse
{
    order: OrderData;
}
export interface BaseFavoritesCartRequestResponse
{
    favoritesCart: ShoppingCartData;
    favoritesSummary: FavoritesSummaryData;
}
export interface FavoritesSummaryData
{
    configurationCodes: string[];
}
export class GetDealerFavoritesQuery extends Request<GetDealerFavoritesQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetDealerFavoritesQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerFavoritesQuery';
    }
}
export interface GetDealerFavoritesQueryResponse extends BaseFavoritesCartRequestResponse
{
}
export class AddProductToFavoritesCommand extends BaseCartRequest<AddProductToFavoritesCommandResponse>
{
    public productConfiguration: ProductConfigurationData;
    constructor (data?: RequestData<AddProductToFavoritesCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddProductToFavoritesCommand';
    }
}
export interface AddProductToFavoritesCommandResponse extends BaseFavoritesCartRequestResponse
{
}
export class RemoveProductFromDealerFavoritesCommand extends BaseCartRequest<RemoveProductFromDealerFavoritesCommandResponse>
{
    public lineItemId: string;
    constructor (data?: RequestData<RemoveProductFromDealerFavoritesCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'RemoveProductFromDealerFavoritesCommand';
    }
}
export interface RemoveProductFromDealerFavoritesCommandResponse extends BaseFavoritesCartRequestResponse
{
}
export class UpdateFavoriteConfigurationLabelCommand extends BaseCartRequest<UpdateFavoriteConfigurationLabelCommandResponse>
{
    public lineItemId: string;
    public label: string;
    constructor (data?: RequestData<UpdateFavoriteConfigurationLabelCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'UpdateFavoriteConfigurationLabelCommand';
    }
}
export interface UpdateFavoriteConfigurationLabelCommandResponse extends BaseFavoritesCartRequestResponse
{
}
export class AddProductToDealerCartCommand extends BaseCartRequest<AddProductToDealerCartCommandResponse>
{
    public productConfiguration: ProductConfigurationData;
    constructor (data?: RequestData<AddProductToDealerCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddProductToDealerCartCommand';
    }
}
export interface AddProductToDealerCartCommandResponse extends BaseDealerCartRequestResponse
{
    recentItem: ShoppingCartItemData;
    quantityAdded: number;
}
export interface BaseDealerCartRequestResponse
{
    dealerCarts: DealerCartsData;
}
export class RemoveProductFromDealerCartCommand extends BaseCartRequest<RemoveProductFromDealerCartCommandResponse>
{
    public lineItemId: string;
    constructor (data?: RequestData<RemoveProductFromDealerCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'RemoveProductFromDealerCartCommand';
    }
}
export interface RemoveProductFromDealerCartCommandResponse extends BaseDealerCartRequestResponse
{
}
export class UpdateProductConfigurationInDealerCartCommand extends BaseCartRequest<UpdateProductConfigurationInDealerCartCommandResponse>
{
    public lineItemId: string;
    public productConfiguration: ProductConfigurationData;
    constructor (data?: RequestData<UpdateProductConfigurationInDealerCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'UpdateProductConfigurationInDealerCartCommand';
    }
}
export interface UpdateProductConfigurationInDealerCartCommandResponse extends BaseDealerCartRequestResponse
{
    updatedItem: ShoppingCartItemData;
}
export class UpdateProductQuantityInDealerCartCommand extends BaseCartRequest<UpdateProductQuantityInDealerCartCommandResponse>
{
    public lineItemId: string;
    public quantity: number;
    constructor (data?: RequestData<UpdateProductQuantityInDealerCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'UpdateProductQuantityInDealerCartCommand';
    }
}
export interface UpdateProductQuantityInDealerCartCommandResponse extends BaseDealerCartRequestResponse
{
}
export interface AssetCategoryWithTypesData
{
    id: string;
    name: string;
    fileTypes: AssetFileTypeData[];
    downloadAllEnabled: boolean;
}
export interface AssetFileTypeData
{
    id: string;
    name: string;
}
export interface ProductFamilyAssetsData extends EntityData
{
    code: string;
    name: string;
    shortName: string;
    brandId: string;
    brandName: string;
    modelCode: string;
    modelImage?: ImageData;
}
export class GetAssetFiltersQuery extends Request<GetAssetFiltersQueryResponse>
{
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetAssetFiltersQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetAssetFiltersQuery';
    }
}
export interface GetAssetFiltersQueryResponse
{
    categories: AssetCategoryWithTypesData[];
    brands: BrandData[];
}
export class GetProductFamiliesAssetsQuery extends Request<GetProductFamiliesAssetsQueryResponse>
{
    public category: string;
    public fileType: string;
    public skip: number;
    public take: number;
    public brands: string[];
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<GetProductFamiliesAssetsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetProductFamiliesAssetsQuery';
    }
}
export interface GetProductFamiliesAssetsQueryResponse
{
    productFamilies: ProductFamilyAssetsData[];
    totalCount: number;
}
export class DownloadProductFamilyAssetsQuery extends Request<DownloadProductFamilyAssetsQueryResponse>
{
    public productFamilies: string[];
    public category: string;
    public fileType: string;
    public brands: string[];
    public shoppingContext: ShoppingContext;
    constructor (data?: RequestData<DownloadProductFamilyAssetsQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'DownloadProductFamilyAssetsQuery';
    }
}
export interface DownloadProductFamilyAssetsQueryResponse
{
    zipUrl: string;
}
export class GetDealerDefaultAddressQuery extends Request<GetDealerDefaultAddressQueryResponse>
{
    public shoppingContext: ShoppingContext;
    public brandId: string;
    public factoryId: string;
    constructor (data?: RequestData<GetDealerDefaultAddressQuery>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'GetDealerDefaultAddressQuery';
    }
}
export class AddEntireDealerOrderToCartCommand extends BaseCartRequest<AddEntireDealerOrderToCartCommandResponse>
{
    public orderNumber: string;
    constructor (data?: RequestData<AddEntireDealerOrderToCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddEntireDealerOrderToCartCommand';
    }
}
export class AddWebShopProductToCartCommand extends BaseCartRequest<AddWebShopProductToCartCommandResponse>
{
    public productId: string;
    public variationCode: string;
    public quantity: number;
    constructor (data?: RequestData<AddWebShopProductToCartCommand>)
    {
        super();
        Object.assign(this, data);
        this.$type = 'AddWebShopProductToCartCommand';
    }
}
export enum Alignment {
    Left = 0,
    Right = 1
}
export enum PhysicalStoreType {
    Showroom = 0,
    Shop = 1
}
export enum EnabledByOperator {
    Or = 0,
    And = 1
}
export enum OptionType {
    NotAffectingPrice = 0,
    AffectingPrice = 1,
    Fabric = 2,
    Accessory = 3,
    PriceGroup = 4
}
export enum ProductConfigurationOrigin {
    DefaultConfigurator = 0,
    RawConfigurator = 1
}
export enum RestrictionType {
    NotAvailableIf = 0,
    Requires = 1
}
export enum RawOptionValueType {
    Alphanumeric = 0,
    Number = 1
}
export enum RawFeatureEnablersLogicalOperator {
    Or = 0,
    And = 1
}
export enum RawFeatureType {
    Generic = 0,
    Fabric = 1,
    PriceGroup = 2
}
export enum RawRelationType {
    Default = 0,
    Mandatory = 1,
    Valid = 2,
    Invalid = 3
}
export enum OrderAggregatedStatus {
    Ongoing = 0,
    Past = 1
}
export enum OrderSortColumn {
    OrderNumber = 0,
    OrderDate = 1,
    OrderTotal = 2
}
export enum OrderSortDirection {
    Ascending = 0,
    Descending = 1
}
