import { observer } from 'mobx-react';
import { Component } from 'react';

import ActionButton from '../../app/shared/components/ActionButton';
import { Authorize } from '../authentication/Authorize';
import { BuyerPolicy } from '../Policy';
import { AddedToCartModal } from './AddedToCartModal/AddedToCartModal';
import css from './ConfiguratorActions.css';
import { ConfiguratorActionsState } from './ConfiguratorActionsState';
import FavoriteActionButton from './FavoriteActionButton/FavoriteActionButton';

interface AddToShoppingCartActionProps {
  state: ConfiguratorActionsState;
}

@observer
export class ConfiguratorActions extends Component<AddToShoppingCartActionProps, {}> {
  constructor(props: { state: ConfiguratorActionsState }) {
    super(props);
  }

  private get priceDefined() {
    const {
      state: { configurator },
    } = this.props;
    return configurator.coreState.unitPriceWithoutAccessories.amount > 0 && configurator.total.amount > 0;
  }

  private get canBePurchased() {
    const {
      state: { configurator },
    } = this.props;
    return configurator.productResponse.baseModel.canBePurchased && this.priceDefined;
  }

  private get availableInCurrentStore() {
    const {
      state: { configurator },
    } = this.props;
    return configurator.productResponse.baseModel.availableInCurrentStore;
  }

  render() {
    const {
      state: {
        addToCartCommand,
        addToFavoritesCommand,
        cartActionType,
        configurator: {
          coreState: { configurationCodeLoading },
        },
        isProductInFavorites,
        recentlyAddedItemModal,
        translation,
        updateCartItemCommand,
      },
    } = this.props;

    return (
      <div className={css.container}>
        <Authorize policy={BuyerPolicy}>
          {this.availableInCurrentStore && (
            <FavoriteActionButton
              command={addToFavoritesCommand}
              translation={translation.favoriteButton}
              disabled={isProductInFavorites || configurationCodeLoading}
              clicked={this.props.state.isProductInFavorites}
            />
          )}
          {this.canBePurchased && cartActionType === 'Add' && (
            <ActionButton
              className={css.buttonAddToCart}
              command={addToCartCommand}
              disabled={configurationCodeLoading}
            >
              {translation.addToCartButton}
            </ActionButton>
          )}
          {this.canBePurchased && cartActionType === 'Update' && (
            <ActionButton
              className={css.buttonAddToCart}
              command={updateCartItemCommand}
              disabled={configurationCodeLoading}
            >
              {translation.updateCartItemButton}
            </ActionButton>
          )}
        </Authorize>

        <AddedToCartModal state={recentlyAddedItemModal} actionType={cartActionType} />
      </div>
    );
  }
}
