import { ReactNode } from 'react';
import { useSwipeable } from 'react-swipeable';

interface SwipeProps {
  children: ReactNode;
  className?: string;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
}

const Swipe = ({ children, className, onSwipeLeft, onSwipeRight }: SwipeProps) => {
  const handlers = useSwipeable({ onSwipedLeft: onSwipeLeft, onSwipedRight: onSwipeRight });
  return (
    <div className={className} {...handlers}>
      {children}
    </div>
  );
};

export default Swipe;
