import AppSettings from '../AppSettings';
import { QUANTITY } from '../configurator/ConfiguratorConsts';
import { ShoppingContext } from '../data/model';

export interface IShareConfiguratorUrlProvider {
  initialize(shoppingContext: ShoppingContext): Promise<void>;
  getUrl(code: string, quantity: number): string;
}

export abstract class BaseShareConfiguratorUrlProvider implements IShareConfiguratorUrlProvider {
  public abstract initialize(shoppingContext: ShoppingContext): Promise<void>;
  public abstract getUrl(code: string, quantity: number): string;

  public constructor(protected readonly settings: AppSettings) {}

  protected getQuantityQuery(quantity: number = 1): Map<string, string> {
    const query = new Map<string, string>();

    // Skip default quantity
    if (quantity && quantity !== 1) {
      query.set(QUANTITY, quantity.toString());
    }

    return query;
  }
}
