import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import AnimateHeight from 'react-animate-height';

import { FileTypeFilterTranslation } from '../../../../../localization/SiteTranslation';
import { ICategoryFilterOptionState, ICategoryFilterState } from '../../DownloadsFiltersState';
import css from './TabsSubgroups.css';
import TabSubgroup from './TabSubgroup/TabSubgroup';

interface TabsSubgroupsProps
  extends Omit<ICategoryFilterState, 'fullName' | 'isTypeSelected'>,
    Pick<React.AriaAttributes, 'aria-controls'> {
  onTabSelect: () => void;
  translation: FileTypeFilterTranslation;
}

const TabsSubgroups: React.FC<TabsSubgroupsProps> = ({ options, onTabSelect, selection, translation, ...aria }) => {
  const renderGroup = (item: ICategoryFilterOptionState) => {
    return item.types?.any() ? (
      <TabSubgroup
        isOpen={item.isActive(selection.value)}
        items={item.types}
        onTabSelect={onTabSelect}
        selection={selection.typeSelection}
        translation={translation}
        key={item.value}
        {...aria}
      />
    ) : null;
  };

  return (
    <div className={classNames(css.TabsSubgroups, { [css.open]: !!selection.typeSelection.value })}>
      <AnimateHeight
        contentClassName={css.inner}
        animateOpacity={true}
        duration={400}
        height={selection.typeSelection.value ? 'auto' : 0}
      >
        {options.map(renderGroup)}
      </AnimateHeight>
    </div>
  );
};

export default observer(TabsSubgroups);
