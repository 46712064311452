import fetch from 'cross-fetch';

export interface IHttpClient {
  get(url: string, requestOptions?: RequestOptions): Promise<Response>;
  head(url: string, requestOptions?: RequestOptions): Promise<Response>;

  post(url: string, body: string, requestOptions?: RequestOptions): Promise<Response>;
}

export type RequestOptions = Pick<RequestInit, 'headers'>;

class HttpClient implements IHttpClient {
  private readonly options: object;

  constructor(options: {} = {}) {
    this.options = options;

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
  }

  async get(url: string): Promise<Response> {
    const response = await fetch(url, {
      ...this.options,
      method: 'GET',
    });

    return response;
  }

  async head(url: string): Promise<Response> {
    const response = await fetch(url, {
      ...this.options,
      method: 'HEAD',
    });

    return response;
  }

  async post(url: string, body: string, requestOptions: RequestOptions = {}): Promise<Response> {
    const response = await fetch(url, {
      ...this.options,
      ...requestOptions,
      method: 'POST',
      body: body,
    });

    return response;
  }
}

export default HttpClient;
