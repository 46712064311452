import { IFormatter } from '../../../shared/Form';

/**
 * Applies Swedish zip code formatting rules, i.e. 000 00, to the passed-in string.
 */
class ZipCodeFormatterSE implements IFormatter {
  public format(value: string): string {
    const pattern = /^(\d{3})(\d*)$/;

    if (pattern.test(value)) {
      return value.replace(pattern, '$1 $2');
    }

    return value;
  }

  public formatOnChange(prevValue: string, value: string): string {
    const deletion = prevValue.length > value.length;

    if (value.length === 3 && deletion) {
      return value.slice(0, -1);
    }

    if (value.length === 5 && /\s$/.test(value)) {
      return prevValue;
    }

    return this.format(value);
  }
}

export default ZipCodeFormatterSE;
