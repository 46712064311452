export interface IIpAddressProvider {
  getIp(): string;
}

export class IpAddressProvider implements IIpAddressProvider {
  private ipAddress: string;

  constructor(ipAddress: string = null) {
    this.ipAddress = ipAddress;
  }

  getIp() {
    return this.ipAddress;
  }
}
