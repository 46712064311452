import { action, makeObservable, observable } from 'mobx';

import { ModalState } from '../../ModalState';
import { INavigationService } from '../../NavigationService';
import { StatusCodes } from '../../StatusCodes';

export default class RedirectionWarningModalState extends ModalState {
  private timer: number;
  private navigation: INavigationService;
  private redirectUrl: string;
  private initialTimeout: number;

  @observable
  public selectedLanguageCode: string;

  @observable
  public timeout: number;

  constructor(timeout: number, navigationService: INavigationService) {
    super();
    makeObservable(this);
    this.navigation = navigationService;
    this.timeout = this.initialTimeout = timeout;
    this.timer = null;
  }

  initialize(redirectUrl: string) {
    this.redirectUrl = redirectUrl;
  }

  @action
  onBeforeOpen() {
    this.timeout = this.initialTimeout;
    this.startCountdown();
  }

  @action.bound
  onTick() {
    if (this.timeout === 0) {
      this.close();
      this.navigation.redirect(this.redirectUrl, StatusCodes.MovedTemporary);
    } else {
      this.setTimeout(this.timeout - 1);
    }
  }

  @action
  setTimeout(timeout: number) {
    this.timeout = timeout;
  }

  startCountdown() {
    this.timer = setInterval(this.onTick.bind(this), 1000) as unknown as number;
  }

  onBeforeClose() {
    clearInterval(this.timer);
  }
}
