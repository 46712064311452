import classNames from 'classnames';
import { observer } from 'mobx-react';

import { ChairVisualizationTranslation } from '../../../localization/SiteTranslation';
import NavigationBullets from '../../../shared/components/NavigationBullets/NavigationBullets';
import { VisualizationShotsState } from '../../../shared/VisualizationShotsState';
import css from './ChairNavigation.css';

interface ChairNavigationProps {
  shots: VisualizationShotsState;
  translation: ChairVisualizationTranslation;
  className?: string;
  displayHint?: boolean;
  displayZoomHint?: boolean;
}

const ChairNavigation = (props: ChairNavigationProps) => {
  const { shots, className, translation, displayZoomHint, displayHint } = props;
  return (
    <div className={classNames(css.ChairNavigation, className)}>
      <NavigationBullets
        className={css.bullets}
        items={shots.all}
        handleOnBulletClick={shots.setActive}
        activeShot={shots.active}
      />
      {displayZoomHint && <span className={css.touchHint}>{translation.zoomHintText}</span>}
      {displayHint && <span className={css.hint}>{translation.hintText}</span>}
    </div>
  );
};

export default observer(ChairNavigation);
