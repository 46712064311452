import classNames from 'classnames';
import * as React from 'react';

import { HeaderProps } from './HeaderLevel1';
import css from './HeaderLevel2.css';

interface HeaderLevel2Props extends HeaderProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderLevel2 = ({ className, children, centered }: HeaderLevel2Props) => {
  const classes = classNames(css.HeaderLevel2, className, centered && css.centered);
  return <h2 className={classes}>{children}</h2>;
};

export default HeaderLevel2;
