import { OrderData, PaymentData } from '../../../data/model';
import { CheckboxInput } from '../../../shared/Form';
import { BasePaymentMethod } from '../IPaymentMethod';
import { PaymentParameterDataExtensions } from '../PaymentParameterDataExtensions';

export class ManualTestPaymentMethod extends BasePaymentMethod {
  paymentId: string;
  static readonly PAYMENT_ID = 'paymentId';
  input: CheckboxInput;
  static readonly IS_VALID = 'isValid';

  constructor() {
    super('ManualPaymentMethod');
    this.input = new CheckboxInput(false);
    this.isInitialized = true;
    this.showAuthorizeButton = true;
  }

  public initialize(order: OrderData, payment: PaymentData): Promise<void> {
    this.paymentId = PaymentParameterDataExtensions.GetRequired(payment.parameters, ManualTestPaymentMethod.PAYMENT_ID);
    return Promise.resolve();
  }

  public authorize(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.authorizationCompleted.raise({
        parameters: [
          {
            key: ManualTestPaymentMethod.PAYMENT_ID,
            value: this.paymentId,
          },
          {
            key: ManualTestPaymentMethod.IS_VALID,
            value: this.input.value.toString(),
          },
        ],
      });
      resolve();
    });
  }
}
