import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import AppState from '../AppState';
import Brand from '../configurator/components/Brand/Brand';
import AccessoryItem from '../configurator/components/ConfigurationSummary/AccessoryItem/AccessoryItem';
import OptionItem from '../configurator/components/ConfigurationSummary/OptionItem/OptionItem';
import { ConfiguratorState } from '../configurator/ConfiguratorState';
import { ProductSpecificationPageTranslation } from '../localization/SiteTranslation';
import capitalizeFirstLetter from '../shared/capitalizeFirstLetter';
import Link from '../shared/components/Link';
import List from '../shared/components/List/List';
import Markdown from '../shared/components/Markdown/Markdown';
import PreloadedImage from '../shared/components/PreloadedImage';
import Price from '../shared/components/Price';
import HeaderLevel3 from '../shared/components/Typography/HeaderLevel3';
import HeaderLevel4 from '../shared/components/Typography/HeaderLevel4';
import { TextLabelStrong } from '../shared/components/Typography/TextLabels';
import css from './ProductSpecificationPage.css';

interface ProductSpecificationPageProps {
  appState: AppState;
  state: ConfiguratorState;
  translation: ProductSpecificationPageTranslation;
}

@observer
class ProductSpecificationPage extends Component<ProductSpecificationPageProps, {}> {
  constructor(props: ProductSpecificationPageProps) {
    super(props);
  }

  get translation() {
    return this.props.appState.translation.productSpecificationPage;
  }

  renderHeader() {
    const { baseModel } = this.props.state;

    return (
      <header className={css.header}>
        <Brand className={css.brand} name={baseModel.brandName} />
        <HeaderLevel3 className={css.name}>{baseModel.name}</HeaderLevel3>
        {/*Prices are disabled temporary*/}
        {/*<Price className={css.totalPrice} price={total} />*/}
      </header>
    );
  }

  renderLeftPanel() {
    const {
      shareState: { imageUrlForPdf },
      baseModel,
    } = this.props.state;
    return (
      <div className={css.leftPanelContainer}>
        <PreloadedImage src={imageUrlForPdf} width={1000} height={1192} alt={baseModel.name} />
      </div>
    );
  }

  renderRightPanel() {
    const { baseModel } = this.props.state;
    // const { storeContext } = this.props.appState.storeResponse;

    return (
      <div className={css.rightPanelContainer}>
        <Markdown linkTarget="_blank" className={css.description} content={baseModel.description} />
        {this.renderOptions()}
        {!this.props.state.coreState.selectedAccessories.empty() && this.renderAccessories()}
        {/*Prices are disabled temporary*/}
        {/*{storeContext.featureToggles.enablePrices && this.renderPrices()}*/}
        {this.renderLink()}
        {this.renderCode()}
      </div>
    );
  }

  renderOptions() {
    return (
      <List
        className={css.options}
        items={this.props.state.coreState.selectedFeaturesVisuallyGrouped.options}
        itemRenderer={(item) => <OptionItem selectedFeature={item} nameClassName={css.optionName} />}
      />
    );
  }

  renderAccessories() {
    return (
      <div className={css.accessories}>
        <TextLabelStrong className={css.title}>{this.translation.accessoriesSection}</TextLabelStrong>
        <List
          items={this.props.state.coreState.selectedFeaturesVisuallyGrouped.accessories}
          itemRenderer={(item) => <AccessoryItem selectedFeature={item} hidePrice />}
        />
      </div>
    );
  }

  renderPrices() {
    const {
      coreState: { unitPrice },
      total,
      quantityInput,
    } = this.props.state;
    return (
      <div className={css.prices}>
        <div className={css.item}>
          <span>{this.translation.pricesSection.unitPrice}</span>
          <Price price={unitPrice} />
        </div>
        <div className={css.item}>
          <span>{this.translation.pricesSection.quantity}</span> <span>{quantityInput.value}</span>
        </div>
        <div className={css.item}>
          <span>{this.translation.pricesSection.subtotalPrice}</span>
          <Price price={total} className={css.subtotal} />
        </div>
      </div>
    );
  }

  renderCode() {
    const { code } = this.props.state.coreState;
    return (
      <div className={css.codeContainer}>
        <TextLabelStrong className={css.title}>{this.translation.codeTitle}</TextLabelStrong>
        <div className={css.code}>{code}</div>
      </div>
    );
  }

  renderLink() {
    const {
      shareState: { absoluteShareUrl },
    } = this.props.state;

    return (
      <div className={css.shareUrl}>
        <TextLabelStrong className={css.title}>{this.translation.linkTitle}</TextLabelStrong>
        <Link href={absoluteShareUrl} className={css.link}>
          {absoluteShareUrl}
        </Link>
      </div>
    );
  }

  renderData() {
    const { baseModel } = this.props.state;
    const brandName = { name: this.translation.dataSection.brand, value: baseModel.brandName };
    const code = { name: this.translation.dataSection.code, value: baseModel.code };
    const properties = [code, brandName, ...baseModel.specification.properties];

    return (
      <section className={css.data}>
        <HeaderLevel4 className={css.title}>{this.translation.dataSection.title}</HeaderLevel4>
        <List
          items={properties}
          itemRenderer={(item) => (
            <Fragment>
              <span>{capitalizeFirstLetter(item.name)}</span> <span>{item.value}</span>
            </Fragment>
          )}
          className={css.propertyList}
          itemClassName={css.property}
        />
      </section>
    );
  }

  renderFooter() {
    return (
      <footer className={css.footer}>
        {this.renderData()}
        <div className={css.logoContainer}>
          <div className={css.logo} />
        </div>
      </footer>
    );
  }

  render() {
    return (
      <div className={css.ProductSpecificationPage}>
        {this.renderHeader()}
        <main className={css.main}>
          {this.renderLeftPanel()}
          {this.renderRightPanel()}
        </main>
        {this.renderFooter()}
      </div>
    );
  }
}

export default ProductSpecificationPage;
