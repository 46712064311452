import classNames from 'classnames';

import Icon from '../../../shared/components/Icon/Icon';
import css from './CheckoutProgressIndicatorItem.css';

export interface CheckoutProgressIndicatorItemProps {
  order: string;
  label: string;
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
  classes?: {
    item?: string;
    active?: string;
    number?: string;
    text?: string;
    label?: string;
  };
}

const CheckoutProgressIndicatorItem = ({
  order,
  label,
  active,
  completed,
  onClick,
  classes,
}: CheckoutProgressIndicatorItemProps) => {
  const orderContent = completed ? <Icon name="check" className={css.icon} /> : order;
  const itemClasses = classNames(
    css.CheckoutProgressIndicatorItem,
    classes?.item && classes.item,
    (active || completed) && classNames(css.active, classes?.active),
  );

  return (
    <div className={itemClasses} onClick={onClick}>
      <div className={classNames(css.number, classes?.number && classes.number)}>
        <span className={classNames(css.text, classes?.text && classes.text)}>{orderContent}</span>
      </div>
      <div className={classNames(css.label, classes?.label && classes.label)}>{label}</div>
    </div>
  );
};

export default CheckoutProgressIndicatorItem;
