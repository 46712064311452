import { DaysRangeData } from '../../../app/data/model';
import { CheckboxInput } from '../../../app/shared/Form';
import { CartHeaderTranslation } from '../../localization/SiteTranslation';
import css from './CartHeader.css';

interface CartHeaderProps {
  isSelected: CheckboxInput;
  brandName: string;
  factoryName?: string;
  preparingTimeInWorkingDays?: DaysRangeData;
  translation: CartHeaderTranslation;
}

export const CartHeader = ({ brandName, preparingTimeInWorkingDays, translation }: CartHeaderProps) => {
  const readyForShippingText = translation.readyForShippingText.interpolate([
    ['minDays', preparingTimeInWorkingDays?.minDays?.toString()],
    ['maxDays', preparingTimeInWorkingDays?.maxDays?.toString()],
  ]);

  return (
    <div className={css.CartHeader}>
      <div className={css.cartHeaderLeft}>
        {/* TODO: Placing multiple orders disabled */}
        {/* <Checkbox
          className={css.cartHeaderCheckbox}
          input={isSelected}
          size="large"
          invertColors
        >
        </Checkbox> */}
        <div className={css.cartHeaderCheckbox}>
          <div>
            <strong className={css.cartHeaderBrandName}>{brandName}</strong>
            {/*
              * The factory name is hidden for now because currently there is no case where it applies.
              * TODO: Uncomment this part when the purchase of the HAG Celi is enabled.
              * {factoryName && (
                  <>
                    {' '}
                    <span className={css.cartHeaderFactoryName}>{factoryName}</span>
                  </>
              * )}
              */}
          </div>
        </div>
      </div>
      <div className={css.cartHeaderRight}>
        {preparingTimeInWorkingDays && <div className={css.cartHeaderEstimatedDelivery}>{readyForShippingText}</div>}
        {/*<ShowMoreButton className={css.cartHeaderOptionsButton} />*/}
      </div>
    </div>
  );
};
