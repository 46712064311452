import { ShoppingContext } from '../data/model';
import RouteDefinitions from '../RouteDefinitions';
import { BaseShareConfiguratorUrlProvider } from './BaseShareConfiguratorUrlProvider';
import { StoreUrlBuilder } from './StoreUrlBuilder';
import { AbsoluteUrl } from './Url';

export class DefaultShareConfiguratorUrlProvider extends BaseShareConfiguratorUrlProvider {
  public initialize(_shoppingContext: ShoppingContext): Promise<void> {
    return Promise.resolve();
  }

  public getUrl(code: string, quantity: number = 1): string {
    const query = this.getQuantityQuery(quantity);
    const relativeUrl = StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.codeRoute, { code: code }),
      query,
      true,
    );

    return AbsoluteUrl.parse(this.settings.baseUrl).append(relativeUrl).toString();
  }
}
