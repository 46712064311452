import { action, makeObservable, observable, override } from 'mobx';

import { IApiClient } from '../data/client';
import { ShoppingContext } from '../data/model';
import { ConfigurationSearchPageTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { PageMetadata } from '../shared/common';
import ConfigurationFinderState from '../shared/ConfigurationFinderState';
import { INavigationService } from '../shared/NavigationService';
import { StoreState } from '../StoreState';

export interface IConfigurationSearchPageMemento {
  shoppingContext: ShoppingContext;
  code: string;
}

export class ConfigurationSearchPageState extends BasePageState<IConfigurationSearchPageMemento> {
  public translation: ConfigurationSearchPageTranslation;
  public configurationFinder: ConfigurationFinderState;

  @override
  get metadata(): PageMetadata {
    return {
      title: this.translation.pageTitleFormat,
      description: this.translation.pageDescriptionFormat,
    };
  }

  @observable public shoppingContext: ShoppingContext;

  constructor(
    translation: ConfigurationSearchPageTranslation,
    navigationService: INavigationService,
    client: IApiClient,
  ) {
    super(translation);
    makeObservable(this);
    this.translation = translation;
    this.configurationFinder = new ConfigurationFinderState(client, navigationService, translation.configurationFinder);
  }

  @action
  async onLoad(store: StoreState) {
    this.initialize(store.shoppingContext, '');
    return Promise.resolve();
  }

  private initialize(context: ShoppingContext, code: string) {
    this.shoppingContext = context;
    this.configurationFinder.searchBar.value = code;
  }

  getMemento(): IConfigurationSearchPageMemento {
    return {
      shoppingContext: this.shoppingContext,
      code: this.configurationFinder.searchBar.value,
    };
  }

  restoreMemento(memento: IConfigurationSearchPageMemento) {
    this.initialize(memento.shoppingContext, memento.code);
  }
}
