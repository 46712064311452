import classNames from 'classnames';
import * as React from 'react';

import ErrorImage from '../../images/errors/error.jpg';
import TertiaryButton from '../shared/components/Button/TertiaryButton';
import Image from '../shared/components/Image/Image';
import Link, { LinkProps } from '../shared/components/Link';
import css from './ErrorPage.css';

interface ErrorLinkProps extends LinkProps {
  label: string;
}

interface ErrorPageProps {
  title: string;
  className?: string;
  children?: React.ReactNode;
  imageSrc?: string;
  link?: ErrorLinkProps;
}

const ErrorPage = ({ className, title, children, link, imageSrc = ErrorImage }: ErrorPageProps) => {
  return (
    <div className={classNames(css.ErrorPage, className)}>
      <div className={css.wrapper}>
        <div className={css.text}>
          <h1 className={css.title}>{title}</h1>
          {children && <p className={css.description}>{children}</p>}
          {link?.href && <TertiaryButton className={css.button} itemElement={<Link {...link}>{link.label}</Link>} />}
        </div>
        <Image classNameImageElement={css.image} src={imageSrc} alt="" />
      </div>
    </div>
  );
};

export default ErrorPage;
