import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ChangeEvent } from 'react';

import Label from '../Label/Label';
import css from './Select.css';

interface SelectProps<T> {
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  inlineLabel?: boolean;
  items?: Array<T>;
  children?: React.ReactNode;
  value?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  keySelector?: (x: T, id: number) => string;
  itemRenderer?: (item: T) => JSX.Element | string;
}

const Select = <T,>(props: SelectProps<T>) => {
  const { className, containerClassName, labelClassName, label, inlineLabel, items, itemRenderer, children, onChange } =
    props;

  const itemsComponent =
    items &&
    items.map((item, key) => {
      const value = props.keySelector ? props.keySelector(item, key) : key;
      return (
        <Option value={value} key={key}>
          {` ${itemRenderer ? itemRenderer(item) : item} `}
        </Option>
      );
    });

  return (
    <div className={classNames(inlineLabel && css.inlineLabelWrapper, containerClassName)}>
      {label && <Label className={classNames({ [css.inlineLabel]: inlineLabel }, labelClassName)}>{label}</Label>}
      <select
        disabled={props.disabled}
        value={props.value}
        className={classNames(css.Select, className)}
        onChange={onChange}
      >
        {children || itemsComponent}
      </select>
    </div>
  );
};

const Option = (props: React.ComponentPropsWithoutRef<'option'>) => {
  return <option {...props} />;
};

Select.Option = Option;

export default observer(Select);
