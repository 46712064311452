import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import FormInput, { FormInputProps } from '../../../../shared/components/FormInput';
import { withIdPrefix } from './withIdPrefix';

type AddressLine1Props = FormInputProps;

@observer
class AddressLine1 extends Component<AddressLine1Props> {
  render() {
    const { className, label, inputClassName, ...otherProps } = this.props;

    return (
      <FormInput
        label={label || 'Address Line 1'}
        className={className}
        inputClassName={classNames(inputClassName)}
        {...otherProps}
      />
    );
  }
}

export default withIdPrefix(AddressLine1, 'addressLine1');
