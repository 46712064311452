import classNames from 'classnames';
import * as React from 'react';

import ProductEnvironmentalDataEntryAdditionalInfo from '../ProductEnvironmentalDataEntryAdditionaInfo/ProductEnvironmentalDataEntryAdditionaInfo';
import css from './ProductEnvironmentalDataEntry.css';

interface ProductEnvironmentalDataEntryProps {
  imageSrc: string;
  title: string;
  value: string;
  additionalInfo?: { title: string; value: string }[];
  description?: React.ReactChild;
  descriptionClassName?: string;
}

const ProductEnvironmentalDataEntry = ({
  additionalInfo,
  description,
  descriptionClassName,
  imageSrc,
  title,
  value,
}: ProductEnvironmentalDataEntryProps) => {
  return (
    <div className={css.container}>
      <dt className={css.title}>
        <img className={css.image} src={imageSrc} alt={title} />
      </dt>
      <dd className={css.value}>
        {`${value} `}
        {description && <div className={classNames(css.description, descriptionClassName)}>{description}</div>}
        {additionalInfo?.any() && <ProductEnvironmentalDataEntryAdditionalInfo items={additionalInfo} />}
      </dd>
    </div>
  );
};

export default ProductEnvironmentalDataEntry;
