import Logger from './Logger';

export class UrlConstants {
  public static doubleSlash = '://';
  public static questionMark = '?';
  public static andMark = '&';
  public static slash = '/';
  public static hash = '#';
}

export class RelativeUrl {
  pathSegments: Array<string>;
  query: Map<string, string>;
  fragment: string;

  static decodeURISafe(url: string) {
    if (!url) {
      return url;
    }
    try {
      return decodeURI(url);
    } catch {
      Logger.warn(`Invalid uri ${url} was skipped.`);
    }

    return url;
  }

  constructor(pathName: string = '/', query: Map<string, string> = new Map<string, string>(), fragment: string = '') {
    this.path = pathName;
    this.query = query;
    this.fragment = fragment;
  }

  public get path() {
    return `/${this.pathSegments.join('/')}`;
  }

  public set path(value: string) {
    this.pathSegments = !!value ? value.split('/').filter((x) => !!x) : [];
  }

  public static parse(urlString: string): RelativeUrl {
    if (urlString) {
      const schemaSeparatorIndex = urlString.indexOf(UrlConstants.doubleSlash);

      if (schemaSeparatorIndex >= 0) {
        const firstSingleSlashIndex = urlString.indexOf(
          UrlConstants.slash,
          schemaSeparatorIndex + UrlConstants.doubleSlash.length,
        );
        urlString = firstSingleSlashIndex >= 0 ? urlString.substring(firstSingleSlashIndex) : UrlConstants.slash;
      }
    }

    const decodeUrlString = RelativeUrl.decodeURISafe(urlString);
    const url = new RelativeUrl();
    const questionMarkIndex = decodeUrlString.indexOf('?');

    if (questionMarkIndex < 0) {
      url.path = decodeUrlString;
    } else {
      url.path = decodeUrlString.substring(0, questionMarkIndex);
      url.query = this.parseQuery(decodeUrlString.substring(questionMarkIndex + 1), true);
    }

    return url;
  }

  public static parseQuery(queryString: string, decoded: boolean = false): Map<string, string> {
    const decodeUrlString = decoded ? queryString : RelativeUrl.decodeURISafe(queryString);

    const query = new Map<string, string>();
    const segments =
      decodeUrlString[0] === '?'
        ? decodeUrlString.slice(1, decodeUrlString.length).split('&')
        : decodeUrlString.split('&');

    segments.forEach((segment) => {
      const parts = segment.split('=');
      if (parts.length === 2) {
        query.set(parts[0], parts[1]);
      }
    });

    return query;
  }

  public toString(shouldEncode: boolean = true): string {
    let result = this.path;
    result += RelativeUrl.queryToString(this.query);

    return shouldEncode ? encodeURI(result) : result;
  }

  public static queryToString(query: Map<string, string>) {
    let result = '';
    if (query.size > 0) {
      result += '?';
      let segments = new Array<string>();
      query.forEach((value, key) => {
        segments.push(`${key}=${value}`);
      });
      result += segments.join('&');
    }
    return result;
  }

  public clone(): RelativeUrl {
    return RelativeUrl.parse(this.toString());
  }

  public setQueryParameter(name: string, value: number | string) {
    this.query.set(name, value.toString());
    return this;
  }

  public isEmpty() {
    return this.pathSegments.length === 0 && this.query.size === 0;
  }

  public append(relative: RelativeUrl) {
    this.pathSegments.push(...relative.pathSegments);
    relative.query.forEach((value, key) => this.query.set(key, value));

    return this;
  }
}

export class AbsoluteUrl {
  public schema: string;
  public hostname: string;
  public relative: RelativeUrl;

  constructor(schema: string, hostname: string, relative: RelativeUrl) {
    this.schema = schema;
    this.hostname = hostname;
    this.relative = relative;
  }

  public toString() {
    return `${this.schema}${UrlConstants.doubleSlash}${this.hostname}${
      this.relative.isEmpty() ? '' : this.relative.toString()
    }`;
  }

  public static parse(urlString: string) {
    if (!urlString) {
      throw Error(`Unable to parse absolute url, provided value is not defined, url = ${urlString}}`);
    }

    const doubleSlashIndex = urlString.indexOf(UrlConstants.doubleSlash);

    if (doubleSlashIndex < 0) {
      throw Error(`Unable to parse absolute url, provided value is invalid, url = ${urlString}`);
    }

    const firstSlashIndex = urlString.indexOf(UrlConstants.slash, doubleSlashIndex + UrlConstants.doubleSlash.length);

    const schema = urlString.substring(0, doubleSlashIndex);
    const hostname = urlString.substring(
      doubleSlashIndex + UrlConstants.doubleSlash.length,
      firstSlashIndex > 0 ? firstSlashIndex : undefined,
    );
    const relative = RelativeUrl.parse(urlString);

    return new AbsoluteUrl(schema, hostname, relative);
  }

  public append(relative: RelativeUrl) {
    this.relative.append(relative);
    return this;
  }
}
