import classNames from 'classnames';

import css from './BackgroundVideoPlayer.css';

interface BackgroundVideoPlayerProps {
  className?: string;
  posterUrl?: string;
  sourceUrl: string;
}

const BackgroundVideoPlayer = (props: BackgroundVideoPlayerProps) => {
  return (
    <video className={classNames(css.video, props.className)} poster={props.posterUrl} autoPlay muted loop>
      <source src={props.sourceUrl} type="video/mp4" />
      <source src={props.sourceUrl} type="video/ogg" />
      Your browser does not support video tag.
    </video>
  );
};

export default BackgroundVideoPlayer;
