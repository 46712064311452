import * as React from 'react';

import { BaseModelBasicData } from '../../../../data/model';
import { ModelSelectorWithLoaderTranslations } from '../../../../localization/SiteTranslation';
import ThreeDotsLoader from '../../../../shared/components/Loaders/ThreeDotsLoader';
import { ModelSelectorState } from './ModalSelectorState';
import css from './ModelSelector.css';

export interface ModelSelectorComponentProps {
  state: ModelSelectorState;
  onItemClick?: (itemData: BaseModelBasicData) => void;
}

interface ModelSelectorProps extends ModelSelectorComponentProps {
  component: React.ComponentType<ModelSelectorComponentProps>;
  isLoading: boolean;
  translation: ModelSelectorWithLoaderTranslations;
}

const ModelSelector = ({ component, isLoading, translation, ...props }: ModelSelectorProps) => {
  if (isLoading) {
    return <ThreeDotsLoader className={css.statusContainer} size="medium" />;
  }

  if (props.state.items.empty()) {
    return <div className={css.statusContainer}>{translation.noOtherModelsAvailable}</div>;
  }

  return React.createElement(component, props);
};

export default ModelSelector;
