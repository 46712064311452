import { action, computed, makeObservable, observable } from 'mobx';

import { FabricCollectionData, OptionVariationData } from '../data/model';
import { FabricFiltersTranslation } from '../localization/SiteTranslation';
import { IFabricOptionVariationFilter } from './FabricOptionState';

export class FabricCollectionBasedFilterState implements IFabricOptionVariationFilter {
  @observable.ref
  selected: FabricCollectionData;

  @observable.shallow
  available: Array<FabricCollectionData>;

  @computed get isVisible() {
    return this.available.length > 1;
  }

  constructor(availableCollections: Array<FabricCollectionData>, translation: FabricFiltersTranslation) {
    makeObservable(this);

    this.available = [
      {
        id: 'all',
        name: translation.defaultFilterName,
        includedFabricsCodes: [],
      },
      ...availableCollections,
    ];

    this.clear();
  }

  @action.bound
  update(fabricCollection: FabricCollectionData) {
    this.selected = fabricCollection;
  }

  @action.bound clear() {
    this.selected = this.available[0];
  }

  get isApplied() {
    if (!this.selected) {
      return false;
    }

    return this.available.indexOf(this.selected) > 0;
  }

  isSatisfied(variation: OptionVariationData): boolean {
    return this.selected.includedFabricsCodes.indexOf(variation.code) >= 0;
  }
}
