import BaseAppState from '../app/AppState';
import { GetDealerContextQuery, GetStoreContextQueryResponse } from '../app/data/model';
import { StoreUrlBuilder } from '../app/shared/StoreUrlBuilder';
import { CustomerUpdatedEvent } from './cart/events/CustomerUpdatedEvent';
import { FavoritesUpdatedEvent } from './cart/events/FavoritesUpdatedEvent';
import { ShoppingCartUpdatedEvent } from './cart/events/ShoppingCartUpdatedEvent';
import { SiteTranslation } from './localization/SiteTranslation';

export class AppState extends BaseAppState {
  public declare translation: SiteTranslation;

  public get languageCodes() {
    return this.translationResolver.languageCodes;
  }

  protected async getStore(storeId: string, countryCode: string, languageCode: string) {
    return await this.client.send(
      new GetDealerContextQuery({
        storeId: storeId,
        languageCode: languageCode,
      }),
    );
  }

  protected initializeStore(storeResponse: GetStoreContextQueryResponse) {
    super.initializeStore(storeResponse);

    this.eventAggregator.subscribe(ShoppingCartUpdatedEvent, (event) => {
      this.store.setShoppingCartSummary(event.shoppingCartSummary);
    });

    this.eventAggregator.subscribe(FavoritesUpdatedEvent, (event) => {
      this.store.setFavoriteConfigurationCodes(event.favoriteConfigurationCodes);
    });

    this.eventAggregator.subscribe(CustomerUpdatedEvent, (event) => {
      const { storeId, languageCode, username } = event.customer;
      this.updateCustomer(username, languageCode, storeId);
    });
  }

  private reloadPage(languageCode: string, storeId: string) {
    const nextUrl = StoreUrlBuilder.changeStoreAndLanguage(this.navigation.currentUrl, storeId, languageCode);
    this.navigation.redirect(nextUrl.toString());
  }

  private reloadPageInSpecificLanguage(languageCode: string) {
    const nextUrl = StoreUrlBuilder.changeLanguage(
      this.store.shoppingContext,
      this.navigation.currentUrl,
      languageCode,
    );
    this.navigation.redirect(nextUrl.toString());
  }

  private updateCustomer(username: string, languageCode: string, storeId?: string) {
    if (storeId && storeId !== this.store.storeResponse.storeContext.store.id) {
      this.reloadPage(languageCode, storeId);
      return;
    }

    if (languageCode !== this.store.shoppingContext.languageCode) {
      this.reloadPageInSpecificLanguage(languageCode);
      return;
    }

    this.store.setCustomerName(username);
  }
}
