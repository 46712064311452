import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../../app/AppState';
import { DashboardState } from './DashboardState';

@observer
class DashboardPage extends Component<{ appState: AppState; state: DashboardState }, {}> {
  render() {
    return <div>Dashboard Page</div>;
  }
}

export default DashboardPage;
