import classNames from 'classnames';
import * as React from 'react';

import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import InputElement from '../Input/InputElement';
import css from './InputSearch.css';

export interface InputSearchProps {
  className?: string;
  classNameContainer?: string;
  classNameButtonClose?: string;

  // tslint:disable-next-line: no-any
  setRef?: (node: any) => HTMLDivElement;

  onEnterKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onMagnifyClick?: (e?: React.MouseEvent<{}>) => void;
  onChange?: (isFilled: boolean) => void;

  placeholderTranslation: string;
}

interface InputSearchState {
  isFilled: boolean;
}

class InputSearch extends React.Component<InputSearchProps, InputSearchState> {
  inputSearchRef: React.RefObject<HTMLInputElement>;

  constructor(props: InputSearchProps) {
    super(props);

    this.handleMagnifyClick = this.handleMagnifyClick.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    this.handleOnCloseButtonClick = this.handleOnCloseButtonClick.bind(this);

    this.focusSearchInput = this.focusSearchInput.bind(this);

    this.inputSearchRef = React.createRef<HTMLInputElement>();
    this.state = { isFilled: false };
  }

  handleMagnifyClick() {
    this.focusSearchInput();

    if (this.props.onMagnifyClick) {
      this.props.onMagnifyClick();
    }
  }

  handleOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      this.props.onEnterKeyPress(e);
    }
  }

  handleOnChange() {
    this.setState({ isFilled: !!this.inputSearchRef.current.value }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.isFilled);
      }
    });
  }

  handleOnCloseButtonClick() {
    this.inputSearchRef.current.value = '';
    this.setState({ isFilled: false });
  }

  focusSearchInput() {
    this.inputSearchRef.current.focus();
  }

  render() {
    const { className, classNameContainer, classNameButtonClose, setRef, placeholderTranslation } = this.props;

    const InputSearchClasses = classNames(css.InputSearch, this.state.isFilled && css.isFilled, className);

    return (
      <div className={InputSearchClasses} ref={setRef}>
        <div className={classNames(css.searchBox, classNameContainer)}>
          <IconButton
            icon={<Icon name="search" className={css.iconSearch} />}
            className={css.buttonMagnifier}
            onClick={this.handleMagnifyClick}
          />
          <InputElement
            type="search"
            inputRef={this.inputSearchRef}
            placeholder={placeholderTranslation}
            className={css.input}
            onKeyPress={(e) => this.handleOnKeyPress(e)}
            onChange={() => this.handleOnChange()}
          />
          <IconButton
            className={classNames(css.buttonClose, classNameButtonClose)}
            icon={<Icon name="cross" className={css.iconCross} />}
            onClick={this.handleOnCloseButtonClick}
          />
        </div>
      </div>
    );
  }
}

export default InputSearch;
