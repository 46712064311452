import { observer } from 'mobx-react';

import Icon from '../../../app/shared/components/Icon/Icon';
import Link from '../../../app/shared/components/Link';
import StoreDisplayName from './StoreDisplayName/StoreDisplayName';
import StoreSelector from './StoreSelector/StoreSelector';
import css from './UserInfo.css';
import UserInfoState from './UserInfoState';

interface UserInfoProps {
  state: UserInfoState;
}

const UserInfo = ({ state }: UserInfoProps) => {
  return (
    <div className={css.UserInfo}>
      <Link className={css.user} to={state.profileUrl}>
        <Icon name="user" className={css.icon} />
        <span className={css.name}>{state.name}</span>
      </Link>
      {state.hasAccessToMultipleStores ? (
        <StoreSelector state={state.storeSelector} />
      ) : (
        <StoreDisplayName storeId={state.store.id} className={css.store} storeName={state.store.displayName} />
      )}
    </div>
  );
};

export default observer(UserInfo);
