import { action, computed, makeObservable, observable } from 'mobx';

import { LanguageData, StoreData } from '../data/model';
import IUserLanguageProvider from '../data/StoreProviders/IPreferredStoreProvider';
import { LanguageTranslation } from '../localization/SiteTranslation';
import { kebabToCamelCase } from '../shared/common';
import Event from '../shared/Event';
import { StoreState } from '../StoreState';

export interface LanguageItem extends LanguageData {
  displayName: string;
}

export interface StoreWithUniqueKey extends StoreData {
  key: string;
}

export default class StoreSelectionState {
  private languageProvider: IUserLanguageProvider;

  public storeChanged: Event<{ store: StoreData; languageCode: string }> = new Event<{
    store: StoreData;
    languageCode: string;
  }>();

  @observable
  private store: StoreState;

  @observable
  private translation: LanguageTranslation;

  @observable.shallow
  public stores: Array<StoreWithUniqueKey>;

  @computed
  public get canChangeStore() {
    return this.store && !this.store.isShowroom();
  }

  @computed
  get isCurrentStorePreferred() {
    const preferredStoreId = this.languageProvider.getStoreId();

    if (!preferredStoreId) {
      return true;
    }

    return this.currentStore?.id === preferredStoreId;
  }

  @computed
  get isVisible() {
    return this.initialized && (this.stores.length > 1 || this.currentStore.languages.length > 1);
  }

  @computed
  get initialized() {
    return !!this.stores && !!this.currentStore;
  }

  get preferredCountry() {
    return this.getStoreName(this.languageProvider.getCountryCode());
  }

  @observable.ref
  public currentStore: StoreWithUniqueKey;

  @observable.ref
  public currentLanguage: LanguageItem;

  constructor(languageProvider: IUserLanguageProvider, translation: LanguageTranslation) {
    makeObservable(this);
    this.stores = [];
    this.languageProvider = languageProvider;
    this.translation = translation;
  }

  changeCurrentStoreToPreferred = () => {
    const store = this.stores.find((x) => x.id === this.languageProvider.getStoreId());
    this.storeChanged.raise({ store: store, languageCode: this.languageProvider.getLanguageCode() });
  };

  @action
  setTranslation(translation: LanguageTranslation) {
    this.translation = translation;
  }

  @action
  setStoreResponse(
    store: StoreState,
    stores: Array<StoreData>,
    currentStore: StoreData,
    currentLanguage: LanguageData,
  ) {
    this.stores = stores
      .filter((x) => x.isEnabled || x.id === currentStore.id)
      .map((x) => this.createStoreWithUniqueKey(x));
    this.store = store;
    this.currentStore =
      this.stores.find((x) => x.id === currentStore.id && x.countryCode === currentStore.countryCode) ||
      this.stores.find((x) => x.id === currentStore.id);
    this.currentLanguage = this.getLanguages(this.currentStore.key).find((x) => x.code === currentLanguage.code);
  }

  getLanguages(selectedStoreKey: string) {
    if (this.currentStore && selectedStoreKey) {
      return this.stores
        .find((x) => x.key === selectedStoreKey)
        .languages.map(
          (x) =>
            <LanguageItem>{
              ...x,
              displayName: this.getDisplayName(x),
            },
        );
    }

    return [];
  }

  private getDisplayName(x: LanguageData): string {
    return this.translation[kebabToCamelCase(x.code)] || x.code;
  }

  private getStoreName(countryCode: string) {
    const store = this.stores.find((item) => item.countryCode === countryCode);

    if (!store) {
      return null;
    }

    return store.name;
  }

  @action
  selectLanguage(languageCode: string) {
    this.selectStore(this.currentStore.key, languageCode);
  }

  @action
  selectStore(storeKey: string, languageCode: string) {
    const store = this.stores.find((x) => x.key === storeKey);

    this.languageProvider.setLanguageCode(languageCode);
    this.languageProvider.setCountryCode(store.countryCode);
    this.languageProvider.setStoreId(store.id);

    this.storeChanged.raise({ store: store, languageCode: languageCode });
  }

  private createStoreWithUniqueKey(store: StoreData) {
    const clone = Object.assign({}, store) as StoreWithUniqueKey;
    clone.key = `${store.id}_${store.countryCode}`;
    return clone;
  }
}
