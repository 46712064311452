import classNames from 'classnames';

import { RawConfiguratorSize } from '../RawConfiguratorSize';
import css from './ConfiguratorProgress.css';

interface ConfiguratorProgressProps {
  size: RawConfiguratorSize;
  value: number;
}

const ConfiguratorProgress = ({ size, value }: ConfiguratorProgressProps) => {
  return (
    <div className={classNames(css.root, css[size])}>
      <progress aria-label="Progress" className={css.bar} max="100" value={value}>
        {value}%
      </progress>
      <span aria-hidden={true} className={css.value}>
        {value}%
      </span>
    </div>
  );
};

export default ConfiguratorProgress;
