import * as ASCIIFolder from 'fold-to-ascii';
import { reaction } from 'mobx';

import { PaginationState } from '../shared/components/Pagination/PaginationState';
import { INavigationService } from '../shared/NavigationService';
import { CatalogFiltersState } from './CatalogFiltersState';
import { CatalogPageQueryParser, ICatalogPageQuery } from './CatalogPageQueryParser';

export class CatalogPageQueryConverter {
  navigation: INavigationService;
  catalogFilters: CatalogFiltersState;
  pagination: PaginationState;

  constructor(catalogFilters: CatalogFiltersState, pagination: PaginationState, navigation: INavigationService) {
    this.catalogFilters = catalogFilters;
    this.pagination = pagination;
    this.navigation = navigation;
  }

  startObserving() {
    reaction(
      () => this.catalogFilters.selection.properties.slice(),
      () => this.updateQuery(),
    );
    reaction(
      () => this.pagination.currentPageIndex,
      () => this.updateQuery(),
    );
  }

  initialize() {
    this.clearFilters();
    this.setFromQuery();
    this.setFromReferrer();
    this.startObserving();
  }

  updateQuery() {
    const grouped = this.catalogFilters.selection.properties.reduce(
      (g, item) => {
        const name = item.name.toLowerCase();
        g[name] = g[name] || [];
        g[name].push(item.valueId);
        return g;
      },
      {} as {
        [key: string]: string[];
      },
    );
    const model = grouped as unknown as ICatalogPageQuery;

    if (this.pagination.currentPageNumber >= 2) {
      model.page = this.pagination.currentPageNumber;
    }

    const query = CatalogPageQueryParser.fromModel(model);
    this.navigation.setQuery(query);
  }

  clearFilters() {
    this.catalogFilters.selection.clear();
  }

  setFromReferrer() {
    const lastPart = this.navigation.referrer.pathSegments.last();
    const filtersProperties = this.catalogFilters.filters.mapMany((x) => x.availableProperties.slice());
    const matched = filtersProperties.filter((x) => x.data.valueId.equalsIgnoreCase(lastPart, true));
    matched.forEach((m) => !m.selected && m.toggle());
  }

  setFromQuery() {
    const model = CatalogPageQueryParser.toModel(this.navigation.currentUrl.query);
    const filtersProperties = this.catalogFilters.filters.mapMany((x) => x.availableProperties.slice());
    CatalogPageQueryParser.FILTER_KEYS.forEach((name) => {
      model[name].forEach((value) => {
        const property = filtersProperties.find(
          (x) =>
            x.data.name.equalsIgnoreCase(name) &&
            CatalogPageQueryConverter.equalsIgnoreCaseFoldToAscii(x.data.valueId, value),
        );
        if (property) {
          property.toggle();
        }
      });
    });
  }

  private static equalsIgnoreCaseFoldToAscii(str1: string, str2: string): boolean {
    const normalizedStr1 = ASCIIFolder.foldMaintaining(str1);
    const normalizedStr2 = ASCIIFolder.foldMaintaining(str2);

    return normalizedStr1.equalsIgnoreCase(normalizedStr2);
  }
}
