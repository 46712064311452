import { observer } from 'mobx-react';
import { Component } from 'react';

import { PaymentWidgetProps } from '../IPaymentMethod';
import DibsPaymentMethod from './DibsPaymentMethod';

@observer
export default class DibsPaymentWidget extends Component<PaymentWidgetProps<DibsPaymentMethod>, {}> {
  componentDidMount() {
    this.props.paymentMethod.onWidgetRendered();
  }

  render() {
    return <div id="dibs_container" />;
  }
}
