import classNames from 'classnames';
import { CSSProperties, MouseEvent } from 'react';

import css from './ExternalImage.css';

export interface ExternalImageProps {
  className?: string;
  alt?: string;
  height?: number;
  name?: string;
  src: string;
  srcset?: string;
  width?: number;
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const ExternalImage = (props: ExternalImageProps) => {
  const { alt, className, src, ...imageProps } = props;

  if (!src) {
    return null;
  }

  return <img className={classNames(css.ExternalImage, className)} src={src} alt={props.alt ?? ''} {...imageProps} />;
};

export default ExternalImage;
