import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import AppSettings from '../../AppSettings';
import { AddressData } from '../../data/model';
import { CheckoutPageTranslation } from '../../localization/SiteTranslation';
import ActionButton from '../../shared/components/ActionButton';
import TertiaryButton from '../../shared/components/Button/TertiaryButton';
import ThreeDotsLoader from '../../shared/components/Loaders/ThreeDotsLoader';
import HeaderLevel3 from '../../shared/components/Typography/HeaderLevel3';
import { CheckoutState } from '../CheckoutState';
import { PaymentMethodFactory } from '../payments/PaymentWidgetFactory';
import css from './CheckoutPayment.css';

interface CheckoutPaymentProps {
  settings: AppSettings;
  checkout: CheckoutState;
  translation: CheckoutPageTranslation;
}

@observer
class CheckoutPayment extends Component<CheckoutPaymentProps, {}> {
  renderPaymentMethodWidget() {
    const { settings, checkout, translation } = this.props;

    const factory = new PaymentMethodFactory(
      settings,
      checkout.termsAndConditions,
      checkout.currentLanguage,
      translation,
    );
    const widget = factory.createWidget(checkout.payment.method);

    return widget;
  }

  renderAddress = (address: AddressData) => {
    return (
      <Fragment>
        {address.line1}
        <br />
        {address.line2 && (
          <Fragment>
            {address.line2} <br />
          </Fragment>
        )}
        {address.zipCode + ' ' + address.city}
        <br />
        {address.country.name}
      </Fragment>
    );
  };

  renderContactAndBilling() {
    const {
      checkout: { form },
      translation,
    } = this.props;
    const billingAddress = form.billingAddress.getData();

    return (
      <section className={css.summarySection}>
        <HeaderLevel3 className={css.title}>{translation.contactForm.contactDetails}</HeaderLevel3>
        <address>
          {form.firstName.value + ' ' + form.lastName.value}
          <br />
          {form.companyName.value && (
            <div className={css.container}>
              <Fragment>
                {form.companyName.value} <br />
              </Fragment>
              <Fragment>
                {form.taxId.value} <br />
              </Fragment>
            </div>
          )}
          {form.email.value}
          <br />
          {form.phone.value}
          <br />
          {this.renderAddress(billingAddress)}
        </address>
      </section>
    );
  }

  renderDeliveryAddress() {
    const {
      checkout: { form },
      translation,
    } = this.props;
    const address = form.deliveryAddress.getData();
    const hideAddress = form.deliveryAddressSameAsBilling.value;
    return (
      <section className={css.summarySection}>
        <HeaderLevel3 className={css.title}>{translation.deliveryAddressSection.subtitle}</HeaderLevel3>
        {hideAddress && translation.deliveryAddressSection.billingAndDeliveryAreTheSameText}
        <address className={classNames(hideAddress && css.hide)}>
          {address.recipient}
          <br />
          {this.renderAddress(address)}
        </address>
      </section>
    );
  }

  render() {
    const { checkout, translation } = this.props;
    const payment = checkout.payment;

    return (
      <div className={css.CheckoutPayment}>
        <div className={css.summary}>
          <TertiaryButton className={css.editButton} children="Edit" onClick={this.props.checkout.editData} />
          {this.renderContactAndBilling()}
          {this.renderDeliveryAddress()}
          <section className={classNames(css.summarySection)}>
            <HeaderLevel3 className={css.title}>{translation.readyToOrderPaymentSection.subtitle}</HeaderLevel3>
            {!payment.method.isInitialized && <ThreeDotsLoader size="medium" className={css.loader} />}
            <div className={classNames(css.klarnaWidget, payment.method.isInitialized && css.show)}>
              {this.renderPaymentMethodWidget()}
            </div>
          </section>
          {payment.method.isInitialized && payment.method.showAuthorizeButton && (
            <ActionButton className={css.submitButton} command={payment.authorizeCommand}>
              {this.props.translation.orderAndPayButton}
            </ActionButton>
          )}
        </div>
      </div>
    );
  }
}

export default CheckoutPayment;
