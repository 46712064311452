import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { AddressFormTranslation } from '../../localization/SiteTranslation';
import css from './Address.css';
import AddressState from './AddressState';
import Recipient from './ShippingAddresses/Inputs/Recipient';

interface AddressProps {
  formElement: JSX.Element;
  translation: AddressFormTranslation;
  state: AddressState;
  showRecipient?: boolean;
  idPrefix?: string;
}

@observer
class Address extends Component<AddressProps, {}> {
  render() {
    const { translation, state, showRecipient, idPrefix, formElement } = this.props;

    return (
      <Fragment>
        {showRecipient && <Recipient input={state.recipient} label={translation.recipient.name} idPrefix={idPrefix} />}
        {formElement}
        <p className={css.countryContainer}>
          {translation.country.name}: <span className={css.countryName}>{state.storeCountry}</span>
        </p>
      </Fragment>
    );
  }
}

export default Address;
