import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { MoneyData } from '../../../data/model';
import Price from '../../../shared/components/Price';
import { AnimatedPrice } from '../../animations/AnimatedPrice/AnimatedPrice';
import { AnimatedPriceState } from '../../animations/AnimatedPrice/AnimatedPriceState';
import ConfiguratorPriceConsumer from '../ConfiguratorPriceConsumer';
import ConfiguratorTabList from '../ConfiguratorTabList/ConfiguratorTabList';
import { TabState } from '../ConfiguratorTabs/TabState';
import css from './ConfigurationNavigation.css';

interface ConfigurationNavigationProps {
  canBePurchased: boolean;
  tabs: TabState[];
  changeTab?: (nextTab: TabState) => void;
  className?: string;
  price?: MoneyData;
  priceAnimations?: AnimatedPriceState;
}

@observer
export class ConfigurationNavigation extends Component<ConfigurationNavigationProps, {}> {
  public renderPrice() {
    const { price, priceAnimations } = this.props;

    if (!price) {
      return;
    }

    const priceElement = <Price className={css.price} price={price} />;

    if (priceAnimations) {
      return (
        <AnimatedPrice className={css.priceContainer} animations={priceAnimations.animations}>
          {priceElement}
        </AnimatedPrice>
      );
    }

    return priceElement;
  }

  render() {
    const { changeTab, tabs, className, canBePurchased } = this.props;

    return (
      <div className={classNames(css.ConfigurationNavigation, className)}>
        <ConfiguratorTabList className={css.list} items={tabs} onItemClick={changeTab} />
        <ConfiguratorPriceConsumer canBePurchased={canBePurchased}>{this.renderPrice()}</ConfiguratorPriceConsumer>
      </div>
    );
  }
}

export default ConfigurationNavigation;
