import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment, ReactNode } from 'react';

import css from './Label.css';

interface LabelProps {
  className?: string;
  children?: ReactNode | Array<ReactNode>;
  hideLabel?: boolean;
  htmlFor?: string;
}

@observer
class Label extends Component<LabelProps, {}> {
  render() {
    const { className, hideLabel, children, ...props } = this.props;
    return (
      children && (
        <label {...props} className={classNames(css.Label, hideLabel && 'visuallyHidden', className)}>
          <Fragment>{children}</Fragment>
        </label>
      )
    );
  }
}

export default Label;
