import { StoreData } from '../../../../../../app/data/model';
import TextButton from '../../../../../../app/shared/components/Button/TextButton';
import StoreDisplayName from '../../../StoreDisplayName/StoreDisplayName';
import css from './StoreSelectorOption.css';

interface StoreSelectorOptionProps {
  data: StoreData;
  onClick: (store: StoreData) => void;
}

const StoreSelectorOption = ({ data, onClick }: StoreSelectorOptionProps) => {
  const handleClick = () => onClick(data);

  return (
    <TextButton role="menuitem" className={css.element} onClick={handleClick}>
      <StoreDisplayName storeId={data.id} storeName={data.name} />
    </TextButton>
  );
};

export default StoreSelectorOption;
