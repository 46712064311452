import ConfigurationSearchPage from '../../../app/configurationSearch/ConfigurationSearchPage';
import HomePage from './HomePage';
import { HomePageWithFallbackState } from './HomePageWithFallbackState';

interface HomePageWithFallbackProps {
  state: HomePageWithFallbackState;
}

const HomePageWithFallback = ({ state }: HomePageWithFallbackProps) => {
  const { homeState, configurationSearchState, homePageContentDefined } = state;

  return (
    <>
      {homePageContentDefined && <HomePage state={homeState} />}
      {!homePageContentDefined && <ConfigurationSearchPage state={configurationSearchState} />}
    </>
  );
};

export default HomePageWithFallback;
