import {
  ArcRotateCamera,
  Color3,
  Color4,
  CubeTexture,
  DirectionalLight,
  Engine,
  Mesh,
  Scene,
  ShadowGenerator,
  StandardMaterial,
  Vector3,
} from '@babylonjs/core';
import { autorun, IArraySplice, Lambda, observe } from 'mobx';
import { observer } from 'mobx-react';
import { Component, createRef, MouseEventHandler } from 'react';

import { ObjectState } from '../core/ObjectState';
import { SceneMode } from '../core/SceneMode';
import { SceneState } from '../core/SceneState';
import OrthographicCamera from './cameras/OrthographicCamera';
import { IMeshPresenter } from './presenters/MeshPresenter';
import { AbstractVisual } from './visuals/AbstractVisual';
import { IVisualFactory } from './visuals/VisualFactory';
import { VisualList, VisualListItem } from './visuals/VisualList';

export interface BabylonSceneProps {
  state: SceneState;
  factories: IVisualFactory<ObjectState>[];
  presenters: IMeshPresenter[];
  size: {
    width: number;
    height: number;
  };
  className?: string;
  onObjectClick?: (object: ObjectState) => void;
  onNoneObjectClick?: () => void;
}

type PointerPosition = {
  x: number;
  y: number;
};

type BabylonSceneState = {
  lastPinterDownPosition: PointerPosition;
};

@observer
export default class BabylonScene extends Component<BabylonSceneProps, BabylonSceneState> {
  private scene: Scene;
  private engine: Engine;
  private shadowGenerator: ShadowGenerator;

  private objectsToVisuals = new Map<ObjectState, AbstractVisual>();
  private meshToObjects = new Map<Mesh, ObjectState>();

  private canvasRef = createRef<HTMLCanvasElement>();

  private camera2d: OrthographicCamera;
  private camera3d: ArcRotateCamera;

  private visualList: VisualList;

  private disposers: Array<Lambda> = [];

  private readonly cameraMap = [
    { mode: SceneMode.Scene2d, activate: () => this.activateCamera2d() },
    { mode: SceneMode.Scene3d, activate: () => this.activateCamera3d() },
  ];

  public componentWillUnmount() {
    this.visualList.dispose();

    this.disposers.forEach((x) => x());

    this.scene.meshes.splice(0, this.scene.meshes.length);
    this.scene.dispose();
  }

  public componentDidMount() {
    const view = this.canvasRef.current;
    this.engine = new Engine(view, true);

    this.scene = new Scene(this.engine);
    this.scene.clearColor = new Color4(1, 1, 1, 1);
    this.scene.ambientColor = new Color3(0, 0, 0); // new Color3(0.1, 0.1, 0.1);
    this.scene.useRightHandedSystem = true;
    this.scene.onPointerDown = (e) => {
      this.setState({ lastPinterDownPosition: { x: e.pageX, y: e.pageY } });
    };

    this.initializeCameras();

    // TODO: move scene setup to a separate method, that can be passed in props

    const light = new DirectionalLight('shadowLight', new Vector3(0, -1, 0), this.scene);
    light.position = new Vector3(0, 50, 0);
    light.shadowMinZ = 1;
    light.shadowMaxZ = 100;
    light.shadowEnabled = true;
    light.intensity = 1.1;

    this.shadowGenerator = new ShadowGenerator(1024, light);
    this.shadowGenerator.useContactHardeningShadow = true;
    this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.1;
    this.shadowGenerator.usePoissonSampling = true;
    this.shadowGenerator.useBlurExponentialShadowMap = true;
    this.shadowGenerator.usePercentageCloserFiltering = true;
    this.shadowGenerator.setDarkness(0.5);

    const itemMaterial = new StandardMaterial('mat', this.scene);
    itemMaterial.diffuseColor = new Color3(1.0, 0, 0);
    itemMaterial.specularColor = new Color3(0.5, 0, 0);

    const ground = Mesh.CreateGround('ground', 60, 60, 1, this.scene, false);
    const groundMaterial = new StandardMaterial('ground', this.scene);
    groundMaterial.diffuseColor = new Color3(1, 1, 1);
    groundMaterial.specularColor = new Color3(0, 0, 0);
    groundMaterial.emissiveColor = new Color3(0.2, 0.2, 0.2);
    ground.receiveShadows = true;
    ground.material = groundMaterial;

    const environmentTexture = CubeTexture.CreateFromPrefilteredData(
      'https://flokkrendersint.blob.core.windows.net/3d-materials-library/env_test.env',
      this.scene,
    );
    this.scene.createDefaultSkybox(environmentTexture).visibility = 0;

    this.engine.runRenderLoop(() => {
      this.scene.render();
    });

    this.visualList = new VisualList(this.props.factories, this.scene, this.props.state.objects);
    this.visualList.items.forEach((x) => this.onItemAdded(x));

    const disposer = observe(this.visualList.items, (change) => {
      const arrayChange = change as IArraySplice<VisualListItem>;

      arrayChange.added?.forEach(async (x) => {
        await this.onItemAdded(x);
      });

      arrayChange.removed?.forEach((x) => {
        this.onItemRemoved(x);
      });

      this.disposers.push(disposer);
    });

    this.props.state.addResourceLoadingAwaiter(() => this.scene.whenReadyAsync());
  }

  private async onItemAdded(item: VisualListItem) {
    this.objectsToVisuals.set(item.state, item.visual);
    item.visual.getMeshes().forEach((m) => {
      this.meshToObjects.set(m, item.rootState);
      this.shadowGenerator.addShadowCaster(m);
    });

    const disposer = autorun(() => {
      item.visual.update(this.scene, this.props.presenters);
    });

    this.disposers.push(disposer);
  }

  private async onItemRemoved(item: VisualListItem) {
    const visual = this.objectsToVisuals.get(item.state);
    if (visual) {
      visual.getMeshes().forEach((m) => {
        this.scene.removeMesh(m);
        this.shadowGenerator.removeShadowCaster(m);
      });
    } // TODO: remove/dispose reaction
  }

  public handleClick: MouseEventHandler<HTMLCanvasElement> = (event) => {
    const deltaX = Math.abs(event.pageX - this.state?.lastPinterDownPosition?.x);
    const deltaY = Math.abs(event.pageY - this.state?.lastPinterDownPosition?.y);

    if (deltaX > 5 || deltaY > 5) {
      return; // drag or rotation of the scene
    }

    if (!this.props.onObjectClick) {
      return;
    }
    const pickResult = this.scene.pick(event.nativeEvent.offsetX, event.nativeEvent.offsetY);

    if (pickResult.hit && this.meshToObjects.has(pickResult.pickedMesh as Mesh)) {
      const object = this.meshToObjects.get(pickResult.pickedMesh as Mesh);
      this.props.onObjectClick(object);
    } else if (this.props.onObjectClick) {
      this.props.onNoneObjectClick();
    }
  };

  public render() {
    const { className, size: dimensions } = this.props;

    if (
      this.engine &&
      (this.engine.getRenderWidth() !== dimensions.width || this.engine.getRenderHeight() !== dimensions.height)
    ) {
      this.engine.setSize(dimensions.width, dimensions.height);
      this.camera2d.positionPlaneOnScene();
    }

    return (
      <canvas
        className={className}
        ref={this.canvasRef}
        id="scene"
        style={{ width: dimensions.width, height: dimensions.height }}
        onClick={(x) => this.handleClick(x)}
      />
    );
  }

  private initializeCameras() {
    this.setCamera2d();
    this.setCamera3d();

    autorun(() => this.changeActiveCamera());
  }

  private setCamera2d() {
    this.camera2d = new OrthographicCamera('camera2d', 3, this.scene);
  }

  private setCamera3d() {
    const camera = new ArcRotateCamera('camera3d', Math.PI / 2, Math.PI / 2.8, 4, Vector3.Zero(), this.scene);
    camera.wheelPrecision = 100;
    camera.minZ = 0.1;

    this.camera3d = camera;
  }

  private changeActiveCamera() {
    const camera = this.cameraMap.find((c) => this.props.state.mode === c.mode);

    if (!camera && !this.scene.activeCamera) {
      this.cameraMap.first()?.activate();
      return;
    }

    this.scene.activeCamera?.detachControl();
    camera?.activate();
  }

  private activateCamera2d() {
    const view = this.canvasRef.current;
    this.setActiveCamera(this.camera2d);
    // @ts-ignore
    this.scene.activeCamera.attachControl(view, true, false, 0);
  }

  private activateCamera3d() {
    const view = this.canvasRef.current;
    this.setActiveCamera(this.camera3d);
    this.scene.activeCamera.attachControl(view);
  }

  private setActiveCamera(camera: ArcRotateCamera) {
    this.scene.activeCamera = camera;
  }
}
