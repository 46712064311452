import css from './ProductInfo.css';

interface ProductInfoProps {
  title: string;
  description: string;
}

const ProductInfo = ({ title, description }: ProductInfoProps) => {
  return (
    <div className={css.ProductInfo}>
      <h2 className={css.label}>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default ProductInfo;
