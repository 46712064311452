import Event from './Event';
import { StatusCodes } from './StatusCodes';
import { RelativeUrl } from './Url';

export type PageLoadedEventArgs = {
  url: RelativeUrl;
};

export interface INavigationService {
  pageLoaded: Event<PageLoadedEventArgs>;
  referrer: RelativeUrl;
  currentUrl: RelativeUrl;

  navigateTo(url: RelativeUrl): void;

  redirect(url: string, code?: StatusCodes): void;

  setQuery(query: Map<string, string>): void;

  reload(): void;

  back(): void;
}

export class NavigationService implements INavigationService {
  private lastReferrer: RelativeUrl;

  public pageLoaded = new Event<PageLoadedEventArgs>();

  constructor(initialReferrer: string) {
    this.lastReferrer = RelativeUrl.parse(initialReferrer);
  }

  get referrer(): RelativeUrl {
    return this.lastReferrer;
  }

  get currentUrl(): RelativeUrl {
    let url = new RelativeUrl();
    url.path = window.location.pathname;
    url.query = RelativeUrl.parseQuery(window.location.search, false);
    return url;
  }

  set currentUrl(url: RelativeUrl) {
    history.replaceState(null, '', url.toString());
  }

  navigateTo(url: RelativeUrl): void {
    this.lastReferrer = this.currentUrl;
    history.pushState(null, '', url.toString());
  }

  redirect(url: string) {
    window.location.href = url;
  }

  setQuery(query: Map<string, string> = new Map<string, string>()) {
    const url = this.currentUrl;
    url.query = query;
    this.currentUrl = url;
  }

  reload() {
    window.location.reload();
  }

  back() {
    history.back();
  }
}

export class Navigation {
  public static instance: INavigationService;

  public static setService(service: INavigationService) {
    Navigation.instance = service;
  }
}
