import { action, makeObservable, observable } from 'mobx';

import { isBrowser } from './common';

export interface IAnimation {
  start(): void;

  stop(): void;

  active: boolean;
}

export class CssAnimation implements IAnimation {
  @observable active: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound start() {
    this.active = true;
  }

  @action.bound stop() {
    this.active = false;
  }
}

export interface ICancellable {
  cancel(): void;
}

export class AnimationTrigger implements ICancellable {
  startAfter: number = 0;
  animations: Array<IAnimation>;
  animationsToStop: Array<IAnimation>;
  isCancelled = false;

  constructor(startAfter: number = 0, animations: Array<IAnimation>, animationsToStop: Array<IAnimation> = []) {
    this.startAfter = startAfter;
    this.animations = animations;
    this.animationsToStop = animationsToStop;
  }

  append(animations: Array<IAnimation>, animationsToStop: Array<IAnimation> = []) {
    this.animations.push(...animations);
    this.animationsToStop.push(...animationsToStop);
    return this;
  }

  run() {
    if (this.startAfter === 0) {
      this.startAnimation();
      return;
    }

    this.scheduleStartAnimation();
  }

  private scheduleStartAnimation() {
    setTimeout(() => this.startAnimation(), this.startAfter);
  }

  private startAnimation() {
    if (this.isCancelled) {
      return;
    }

    this.animationsToStop.forEach((x) => x.stop());
    this.animations.forEach((x) => x.start());
  }

  cancel() {
    this.isCancelled = true;
  }
}

export class Storyboard implements ICancellable {
  triggers: Array<AnimationTrigger> = [];

  constructor() {
    makeObservable(this);
  }

  append(delay: number, animation: IAnimation, animationsToStop: Array<IAnimation> = []) {
    return this.appendMany(delay, [animation], animationsToStop);
  }

  appendMany(delay: number, animations: Array<IAnimation>, animationsToStop: Array<IAnimation> = []) {
    this.triggers.push(new AnimationTrigger(delay, animations, animationsToStop));
    return this;
  }

  appendStorybord(delay: number, storyboard: Storyboard) {
    storyboard.triggers.forEach((x) => {
      this.appendMany(delay + x.startAfter, x.animations, x.animationsToStop);
    });
    return this;
  }

  appendStoryboards(delay: number, storyboards: Array<Storyboard>) {
    storyboards.forEach((storyboard) => {
      this.appendStorybord(delay, storyboard);
    });
    return this;
  }

  getApproximateDuration() {
    const delays = this.triggers.map((x) => {
      return x.startAfter;
    });
    const maxDelay = Math.max(...delays);
    const averageAnimationDuration = 700;
    return maxDelay + averageAnimationDuration;
  }

  @action
  run(): void {
    this.triggers.forEach((x) => {
      x.run();
    });
  }

  cancel() {
    this.triggers.forEach((x) => x.cancel());
  }
}

export class ScrollToAnimation implements IAnimation {
  private elementSelector: string;

  active: boolean; // TODO: can we get rid of this from IAnimation?
  shouldScrollToTop: boolean;

  constructor(elementSelector: string, scrollToTop: boolean = false) {
    this.elementSelector = elementSelector;
    this.shouldScrollToTop = scrollToTop;
  }

  start(): void {
    if (isBrowser()) {
      const element = document.querySelector(this.elementSelector);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: this.shouldScrollToTop ? 'start' : 'center' });
      }
    }
  }

  stop(): void {
    // Unable to stop
  }
}
