import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { IAuthenticationService } from '../../../app/auth/AuthenticationService';
import { IApiClient } from '../../../app/data/client';
import {
  ContactData,
  GetDealerContactQuery,
  NotificationSubscriptionData,
  UpdateDealerContactCommand,
} from '../../../app/data/model';
import { LanguageItem } from '../../../app/layout/StoreSelectionState';
import { AsyncCommand, kebabToCamelCase } from '../../../app/shared/common';
import Event from '../../../app/shared/Event';
import { IEventAggregator } from '../../../app/shared/EventAggregator';
import { CheckboxInput } from '../../../app/shared/Form';
import { ModalState } from '../../../app/shared/ModalState';
import { CustomerUpdatedEvent } from '../../cart/events/CustomerUpdatedEvent';
import { LanguagesTranslation, PersonalTabContentTranslation } from '../../localization/SiteTranslation';
import { BasicInformationForm } from './BasicInformationForm';

export class PersonalTabState {
  @observable
  public contact?: ContactData;

  @observable.ref
  public subscriptionsData: NotificationSubscriptionData[] = [];

  @observable.ref
  public subscriptions: NotificationSubscriptionState[] = [];

  @observable
  public resetPasswordEmailSent: boolean = false;

  public readonly saveChangesCommand: AsyncCommand;
  public readonly passwordResetConfirmationModal = new ConfirmPasswordChangeModal(this.authentication);

  public basicInfoForm: BasicInformationForm;

  constructor(
    private readonly client: IApiClient,
    private readonly authentication: IAuthenticationService,
    private readonly eventAggregator: IEventAggregator,
    public readonly translation: PersonalTabContentTranslation,
    currentLanguageCode: string,
    languageCodes: string[],
  ) {
    makeObservable(this);
    const languages = this.getLanguages(languageCodes, translation.languages);

    this.basicInfoForm = new BasicInformationForm(languages, currentLanguageCode);
    this.saveChangesCommand = new AsyncCommand(this.saveChanges, this.basicInfoForm);

    this.passwordResetConfirmationModal.resetPasswordConfirmedEvent.subscribe(async () => {
      this.setResetEmailPasswordSent(true);
    });

    reaction(
      () => this.subscriptionsData,
      (subscriptionsData) => {
        this.subscriptions = subscriptionsData.map((data) => new NotificationSubscriptionState(data));
      },
    );
  }

  public load = async () => {
    const dealerContactResponse = await this.client.send(new GetDealerContactQuery({}));
    this.setUser(dealerContactResponse.contact);
    this.setUserSubscriptions(dealerContactResponse.notificationSubscriptions);
  };

  @computed
  public get passwordResetEnabled() {
    return this.authentication.passwordResetEnabled;
  }

  public resetForm = (contact: ContactData) => {
    this.basicInfoForm.firstNameInput.setDefaultValue(contact.firstName);
    this.basicInfoForm.lastNameInput.setDefaultValue(contact.lastName);
    this.basicInfoForm.emailInput.setDefaultValue(contact.email);
    this.basicInfoForm.phoneNumberInput.setDefaultValue(contact.phoneNumber || '');
    this.basicInfoForm.languageSelector.setDefaultLanguage(contact.language);
  };

  @action.bound
  public setUser(contact: ContactData) {
    this.contact = contact;
    this.resetForm(contact);
  }

  @action.bound
  public setUserSubscriptions(subscriptions: NotificationSubscriptionData[]) {
    this.subscriptionsData = subscriptions;
  }

  private getLanguages(languageCodes: string[], translation: LanguagesTranslation) {
    return languageCodes.map<LanguageItem>((code) => {
      const translationKey = kebabToCamelCase(code) as keyof LanguagesTranslation;
      const displayName = translation[translationKey] || code;

      return { code, displayName };
    });
  }

  @action.bound
  private setResetEmailPasswordSent(value: boolean) {
    this.resetPasswordEmailSent = value;
  }

  private saveChanges = async () => {
    const response = await this.client.send(
      new UpdateDealerContactCommand({
        contact: {
          ...this.contact,
          firstName: this.basicInfoForm.firstNameInput.value,
          lastName: this.basicInfoForm.lastNameInput.value,
          phoneNumber: this.basicInfoForm.phoneNumberInput.value,
          language: this.basicInfoForm.languageSelector.selection,
        },
        contactSubscriptions: this.subscriptions.map(({ subscription, subscriptionEnabled }) => ({
          notificationType: subscription.notificationType,
          isActive: subscriptionEnabled.value,
        })),
      }),
    );

    this.setUser(response.contact);
    this.setUserSubscriptions(response.contactSubscriptions);
    this.eventAggregator.publish(
      new CustomerUpdatedEvent({
        username: response.contact.fullName,
        languageCode: response.contact.language,
      }),
    );
  };
}

export class NotificationSubscriptionState {
  public subscriptionEnabled: CheckboxInput;

  constructor(public readonly subscription: NotificationSubscriptionData) {
    this.subscriptionEnabled = new CheckboxInput(subscription.isActive);
  }
}

class ConfirmPasswordChangeModal extends ModalState {
  public readonly resetPasswordConfirmedEvent = new Event();
  public readonly sendPasswordResetEmailCommand: AsyncCommand;

  constructor(private readonly authentication: IAuthenticationService) {
    super();

    this.sendPasswordResetEmailCommand = new AsyncCommand(async () => {
      await this.authentication.sendResetPasswordEmail();
      this.close();
      this.resetPasswordConfirmedEvent.raise(null);
    });
  }
}
