import { ComponentProps } from 'react';

import IconPin from '../../../../../images/map/pin.svg';
import IconPinSelected from '../../../../../images/map/pinSelected.svg';
import Marker from '../Marker/Marker';

interface PinProps extends Pick<ComponentProps<typeof Marker>, 'map' | 'position'> {
  onClick: () => void;
  selected?: boolean;
}

const Pin = ({ onClick, selected = false, ...markerProps }: PinProps) => {
  return (
    <Marker
      icon={{ url: selected ? IconPinSelected : IconPin, scaledSize: new google.maps.Size(28.8, 28.8) }}
      onClick={onClick}
      {...markerProps}
    />
  );
};

export default Pin;
