import { action, makeObservable, observable } from 'mobx';

import { ForceDownloadUrlBuilder } from '../../../configurator/ForceDownloadUrlBuilder';
import { ImageData } from '../../../data/model';
import { appendQuery, scaleToFit } from '../../common';

export type ImageModel = ImageData & { optimizedUrl: string };

export class GalleryState {
  private forceDownloadBuilder: ForceDownloadUrlBuilder;

  maxHeight: number;
  maxWidth: number;

  @observable
  public images: ImageModel[] = [];

  @observable
  public isLightboxOpen: boolean;

  @observable
  public initialImageIndex: number = 0;

  constructor(forceDownloadBuilder: ForceDownloadUrlBuilder, maxWidth: number, maxHeight: number) {
    makeObservable(this);
    this.maxWidth = maxWidth;
    this.maxHeight = maxHeight;
    this.forceDownloadBuilder = forceDownloadBuilder;
  }

  @action.bound
  openLightbox(index: number) {
    this.initialImageIndex = index;
    this.isLightboxOpen = true;
  }

  @action.bound
  closeLightbox() {
    this.isLightboxOpen = false;
  }

  @action.bound
  downloadImage(imageIndex: number) {
    const currentImage = this.images[imageIndex];
    window.location.href = this.forceDownloadBuilder.build(currentImage.url, currentImage.name);
  }

  @action
  setImages(images: ImageData[]) {
    if (!images) {
      return;
    }

    const imagesModel: ImageModel[] = [];
    images.forEach((image) => {
      if (image.size) {
        // Size is required here
        const fitResult = scaleToFit(image.size, { height: this.maxHeight, width: this.maxWidth });
        const optimizedUrl = appendQuery(image.url, [`width=${fitResult.width}`, `height=${fitResult.height}`]);
        imagesModel.push({
          ...image,
          optimizedUrl,
        });
      }
    });

    this.initialImageIndex = 0;
    this.images = imagesModel;
  }
}
