import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

export class ProductViewer3dPageQuery {
  width?: number = null;
  height?: number = null;
  shot?: string = null;
  background?: string = null;
}

export const WIDTH = nameOf<ProductViewer3dPageQuery>('width');
export const HEIGHT = nameOf<ProductViewer3dPageQuery>('height');
export const SHOT = nameOf<ProductViewer3dPageQuery>('shot');
export const BACKGROUND_COLOR = nameOf<ProductViewer3dPageQuery>('background');

export class ProductViewer3dPageQueryParser extends QueryParser {
  public static toModel(query: Map<string, string>) {
    const queryModel = new ProductViewer3dPageQuery();

    queryModel.width = Number.parseFloat(QueryParser.getValue(query, WIDTH));
    queryModel.height = Number.parseFloat(QueryParser.getValue(query, HEIGHT));
    queryModel.shot = QueryParser.getValue(query, SHOT);
    queryModel.background = QueryParser.getValue(query, BACKGROUND_COLOR);

    return queryModel;
  }

  public static toQuery(queryModel: ProductViewer3dPageQuery) {
    const query = new Map<string, string>();

    QueryParser.setValue(query, WIDTH, queryModel.width.toString());
    QueryParser.setValue(query, HEIGHT, queryModel.height.toString());

    return query;
  }
}
