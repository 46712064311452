declare global {
  interface String {
    replaceAll(from: string, to: string): string;

    interpolate(values: Array<[string, string]>): string;

    interpolate(values: Map<string, string>): string;

    equalsIgnoreCase(value: string, ignoreDiacritics?: boolean): boolean;

    startsWith(searchString: string, position: number): boolean;

    capitalize(): string;

    uncapitalize(): string;
  }
}

if (!String.prototype.interpolate) {
  String.prototype.interpolate = function (this: string, values: Array<[string, string]> | Map<string, string>) {
    let result = this;
    if (values instanceof Map) {
      values.forEach((value, key) => {
        result = result.replaceAll('{' + key + '}', value);
      });
    } else if (values instanceof Array) {
      values.forEach((x) => {
        const [key, value] = x;
        result = result.replaceAll('{' + key + '}', value);
      });
    }

    return result;
  };
}

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (this: string, from: string, to: string) {
    return this.split(from).reduce((a, b) => a + to + b);
  };
}

if (!String.prototype.equalsIgnoreCase) {
  String.prototype.equalsIgnoreCase = function (this: string, value: string, ignoreDiacritics: boolean = false) {
    if (this == null && value == null) {
      return true;
    }
    if (this == null || value == null) {
      return false;
    }
    return (
      this.localeCompare(value, undefined, {
        sensitivity: ignoreDiacritics ? 'base' : 'accent',
      }) === 0
    );
  };
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString: string, position: number) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

if (!String.prototype.capitalize) {
  String.prototype.capitalize = function (this: string) {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
}

if (!String.prototype.uncapitalize) {
  String.prototype.uncapitalize = function (this: string) {
    return this.charAt(0).toLowerCase() + this.slice(1);
  };
}

export default {};
