import { action, computed, makeObservable, observable, reaction } from 'mobx';

import Event from '../../../shared/Event';

export class FilterState {
  @observable value: boolean;
  public name: string;

  constructor(name: string) {
    makeObservable(this);
    this.name = name;
    this.value = false;
  }

  public isEqual(s: string) {
    return this.name.equalsIgnoreCase(s, true);
  }

  @action
  public set(value: boolean) {
    this.value = value;
  }

  @action
  public toggle() {
    this.value = !this.value;
  }
}

export default class StoreFiltersState {
  public onChange: Event<void>;

  @observable.shallow brands: FilterState[];
  @observable.shallow storeTypes: FilterState[];

  constructor() {
    makeObservable(this);
    this.brands = [];
    this.storeTypes = [];
    this.onChange = new Event<void>();
  }

  @computed
  get selectedBrands() {
    return this.brands.filter((b) => b.value).map((b) => b.name);
  }

  @computed
  get selectedStoreTypes() {
    return this.storeTypes.filter((st) => st.value).map((b) => b.name);
  }

  @computed
  get selectedFilters() {
    return [...this.selectedBrands, ...this.selectedStoreTypes];
  }

  @computed
  get selectedFiltersNumber() {
    return this.selectedFilters.length;
  }

  @action
  public initialize(brands: string[], initialBrands: string[], storeTypes: string[], initialStoreTypes: string[]) {
    this.brands = brands.map((x) => {
      let input = new FilterState(x);
      if (initialBrands.find((type) => input.isEqual(type))) {
        input.set(true);
      }
      input.name = x;
      return input;
    });
    this.storeTypes = storeTypes.map((x) => {
      let input = new FilterState(x);
      if (initialStoreTypes.find((type) => input.isEqual(type))) {
        input.set(true);
      }
      input.name = x;
      return input;
    });

    reaction(
      () => this.brands.map((x) => x.value),
      () => this.onChange.raise(null),
    );
    reaction(
      () => this.storeTypes.map((x) => x.value),
      () => this.onChange.raise(null),
    );
  }
}
