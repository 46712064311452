import classNames from 'classnames';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Component, createElement } from 'react';

import { CheckoutPageTranslation, RequestQuoteLinkTranslation } from '../../localization/SiteTranslation';
import RequestQuoteLink from '../../requestQuote/RequestQuoteLinkButton/RequestQuoteLink';
import { ScrollToAnimation } from '../../shared/animations';
import { AsyncCommand } from '../../shared/common';
import ActionButton from '../../shared/components/ActionButton';
import Checkbox from '../../shared/components/Checkbox/Checkbox';
import FormInput from '../../shared/components/FormInput';
import InlineInputs from '../../shared/components/Input/InlineInputs';
import { InputElementInvalid } from '../../shared/components/Input/InputElement';
import InputField from '../../shared/components/Input/InputField';
import Link from '../../shared/components/Link';
import RawHtml from '../../shared/components/RawHtml';
import TextInfo from '../../shared/components/TextInfo/TextInfo';
import HeaderLevel3 from '../../shared/components/Typography/HeaderLevel3';
import { CheckoutFormState } from '../CheckoutFormState';
import Address from './Address';
import css from './CheckoutForm.css';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import { DynamicInputProps } from './ShippingAddresses/DynamicInputsFactory';
import { ShippingSelector } from './ShippingSelector';

interface CheckoutFormProps {
  form: CheckoutFormState;
  command: AsyncCommand;
  termsLink: string;
  legalAgreementsConsentHtml: string;
  translation: CheckoutPageTranslation;
  isSubmitButtonDisabled: boolean;
  isRequestQuoteLink?: boolean;
  requestQuoteLinkTranslation: RequestQuoteLinkTranslation;
  className?: string;
  configurationCode: string;
}

@observer
class CheckoutForm extends Component<CheckoutFormProps, {}> {
  private validationTriggered = false;

  constructor(props: CheckoutFormProps, context: {}) {
    super(props, context);
    reaction(
      (x) => props.form.isValid,
      (x) => !x && this.scrollToFirstInvalidInput(),
    );
  }

  scrollToFirstInvalidInput() {
    if (!this.validationTriggered) {
      setTimeout(() => new ScrollToAnimation(InputElementInvalid, false).start(), 0);
    }
    this.validationTriggered = true;
  }

  renderPaymentSolutionProviderLink() {
    const {
      form: { selectedPaymentMethod },
      translation,
    } = this.props;

    if (!selectedPaymentMethod.displayLogo) {
      return null;
    }

    const img = selectedPaymentMethod.logo ? (
      <img className={css.logo} alt={selectedPaymentMethod.name} src={selectedPaymentMethod.logo} />
    ) : (
      selectedPaymentMethod.name
    );

    return (
      <div className={css.paymentSolution}>
        <span className={css.text}>{translation.paymentPoweredByText}</span>
        {selectedPaymentMethod.websiteUrl ? (
          <Link href={selectedPaymentMethod.websiteUrl} target="_blank" className={css.link}>
            {img}
          </Link>
        ) : (
          img
        )}
      </div>
    );
  }

  render() {
    const {
      form,
      command,
      translation,
      className,
      termsLink,
      legalAgreementsConsentHtml,
      requestQuoteLinkTranslation,
      isSubmitButtonDisabled,
      isRequestQuoteLink,
    } = this.props;
    const contactTranslation = translation.contactForm;
    const addressTranslation = translation.addressForm;
    const billingDynamicInputProps: DynamicInputProps = {
      state: form.billingAddress.dynamicInputs,
      idPrefix: 'billing',
    };
    // Flokk decided to hide second address, but let's keep it here, just in case.
    // const shippingDynamicInputProps: DynamicInputProps = {
    //   state: form.deliveryAddress.dynamicInputs,
    //   idPrefix: 'shipping',
    // };

    return (
      <div className={classNames(css.CheckoutForm, className)}>
        {isRequestQuoteLink && (
          <RequestQuoteLink
            translation={requestQuoteLinkTranslation}
            className={css.requestQuoteLinkContainer}
            linkClassName={css.link}
            configurationCode={this.props.configurationCode}
          />
        )}
        <section className={css.formSection}>
          <HeaderLevel3 className={css.title}>{contactTranslation.contactDetails}</HeaderLevel3>
          <FormInput
            id="firstName"
            name="firstName"
            label={contactTranslation.firstName.name}
            placeholder={contactTranslation.firstName.placeholder}
            input={form.firstName}
          />
          <FormInput
            id="lastName"
            name="lastName"
            label={contactTranslation.lastName.name}
            placeholder={contactTranslation.lastName.placeholder}
            input={form.lastName}
          />
          {/*Company Name & TaxId field are hidden - for b2b there's request quote link */}
          {/*<FormInput*/}
          {/*id="companyName"*/}
          {/*name="companyName"*/}
          {/*label={contactTranslation.companyName.name}*/}
          {/*placeholder={contactTranslation.companyName.placeholder}*/}
          {/*input={form.companyName}*/}
          {/*/>*/}
          {/*<FormInput*/}
          {/*id="taxId"*/}
          {/*name="taxId"*/}
          {/*label={contactTranslation.taxId.name}*/}
          {/*placeholder={contactTranslation.taxId.placeholder}*/}
          {/*input={form.taxId}*/}
          {/*className={classNames(!form.companyName.value && css.hide)}*/}
          {/*/>*/}
          <FormInput
            id="email"
            type="email"
            name="email"
            label={contactTranslation.email.name}
            placeholder={contactTranslation.email.placeholder}
            input={form.email}
          />
          <InlineInputs inputs={[form.phonePrefix, form.phone]} label={contactTranslation.phone.name}>
            <InputField
              id="prefix"
              name="prefix"
              type="text"
              placeholder={contactTranslation.phonePrefix.placeholder}
              input={form.phonePrefix}
              className={classNames(css.inlineInput, css.prefixContainer)}
              maxLength={3}
            />
            <InputField
              id="phone"
              name="phone"
              type="tel"
              placeholder={contactTranslation.phone.placeholder}
              input={form.phone}
              className={css.inlineInput}
            />
          </InlineInputs>
          <Address
            translation={addressTranslation}
            state={form.billingAddress}
            formElement={createElement(form.billingAddress.dynamicInputsComponent, billingDynamicInputProps)}
            idPrefix={billingDynamicInputProps.idPrefix}
          />
        </section>
        <section className={css.formSection}>
          <HeaderLevel3 className={css.title}>{translation.shippingMethodSection.subtitle}</HeaderLevel3>
          {form.showShippingMethodSelection ? (
            <ShippingSelector
              rates={form.shippingRates}
              rateInput={form.selectedShippingRate}
              onRateChange={form.changeShippingRate}
            />
          ) : (
            <TextInfo>{translation.deliveryAddressSection.deliveryInfoText}</TextInfo>
          )}
        </section>
        {/* Flokk decided to hide second address, but let's keep it here, just in case. */}
        {/* <section className={css.formSection}>
          <HeaderLevel3 className={css.title}>{translation.deliveryAddressSection.subtitle}</HeaderLevel3>
          <Checkbox input={form.deliveryAddressSameAsBilling} enabledErrorMessages>
            {translation.deliveryAddressSection.billingAndDeliveryAreTheSameText}
          </Checkbox>
          <div className={classNames(form.deliveryAddressSameAsBilling.value && css.hide)}>
            <Address
              translation={addressTranslation}
              state={form.deliveryAddress}
              showRecipient
              formElement={React.createElement(form.deliveryAddress.dynamicInputsComponent, shippingDynamicInputProps)}
              idPrefix={shippingDynamicInputProps.idPrefix}
            />
          </div>
        </section> */}
        <section className={css.formSection}>
          <HeaderLevel3 className={css.title}>
            {form.paymentMethods.length > 1 ? translation.paymentMethodLabel : translation.readyToOrderSection.subtitle}
          </HeaderLevel3>

          <PaymentMethods
            methods={form.paymentMethods}
            selectedMethod={form.selectedPaymentMethod}
            onChange={form.changePaymentMethod}
            translation={translation.paymentMethods}
          />

          <Checkbox input={form.termsAndConditions} className={css.checkbox} enabledErrorMessages>
            {legalAgreementsConsentHtml ? (
              <RawHtml content={legalAgreementsConsentHtml} />
            ) : (
              <>
                {translation.readyToOrderSection.termsAndConditions.name}{' '}
                {termsLink ? (
                  <Link href={termsLink} target="_blank" className={css.termsLink}>
                    {translation.readyToOrderSection.termsAndConditions.termsAndConditionLinkName}
                  </Link>
                ) : (
                  translation.readyToOrderSection.termsAndConditions.termsAndConditionLinkName
                )}
              </>
            )}
          </Checkbox>
          {/*Temporary hide subscription checkbox */}
          {/*<Checkbox input={form.subscription} className={css.checkbox}>{translation.readyToOrderSection.subscription}</Checkbox>*/}

          <div className={css.confirmPaymentContainer}>
            <ActionButton
              disabled={isSubmitButtonDisabled}
              command={command}
              className={css.button}
              errorMessage={translation.errors.creatingOrderFailed}
            >
              {translation.goToPaymentButton}
            </ActionButton>

            {this.renderPaymentSolutionProviderLink()}
          </div>
        </section>
      </div>
    );
  }
}

export default CheckoutForm;
