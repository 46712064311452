import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { SelectedFeatures } from '../../../configurator/ConfiguratorCoreState';
import { IApiClient } from '../../../data/client';
import { ImageData, ModularLayoutData, ModularProductFamilyData, ShoppingContext } from '../../../data/model';
import { SiteTranslation } from '../../../localization/SiteTranslation';
import { IShareConfiguratorUrlProvider } from '../../../shared/BaseShareConfiguratorUrlProvider';
import LayoutPickerState from './ModularConfigurator/LayoutPicker/LayoutPickerItem/LayoutPickerState';
import ModularComponentConfiguratorState from './ModularConfigurator/ModularComponentConfigurator/ModularComponentConfiguratorState';
import ModularConfiguratorState from './ModularConfigurator/ModularConfiguratorState';

export enum ModularConfiguratorMode {
  Component,
  Layout,
  Summary,
}

export interface UsedModularComponentListItem {
  id: string;
  image: ImageData;
  name: string;
  features: SelectedFeatures;
  counter: number;
}

export interface UsedModularComponentConfigurationListItem {
  code: string;
  name: string;
  features: SelectedFeatures;
  counter: number;
}

class ModularConfiguratorCoordinatorState {
  @observable
  public mode: ModularConfiguratorMode = ModularConfiguratorMode.Layout;

  public readonly configurator: ModularConfiguratorState;
  public readonly layoutPicker: LayoutPickerState;

  @computed
  public get isComponentModeActive() {
    return this.mode === ModularConfiguratorMode.Component;
  }

  @computed
  public get isSummary() {
    return this.mode === ModularConfiguratorMode.Summary;
  }

  public get productFamilyName() {
    return this.productFamily.name;
  }

  @computed
  public get usedComponents(): UsedModularComponentListItem[] {
    return this.configurator.components.reduce<UsedModularComponentListItem[]>((accumulator, component) => {
      const indexOfComponentGroup = accumulator.findIndex((x) => x.id === component.id);

      if (indexOfComponentGroup === -1) {
        accumulator.push({
          id: component.id,
          image: component.image,
          name: component.name,
          features: component.coreState.selectedFeaturesVisuallyGrouped,
          counter: 1,
        });
      } else {
        accumulator[indexOfComponentGroup].counter++;
      }

      return accumulator;
    }, []);
  }

  @computed
  public get usedComponentConfigurations(): UsedModularComponentConfigurationListItem[] {
    return this.configurator.components.reduce<UsedModularComponentConfigurationListItem[]>(
      (accumulator, component) => {
        const indexOfComponentGroup = accumulator.findIndex((x) => x.code === component.coreState.code);

        if (indexOfComponentGroup === -1) {
          accumulator.push({
            code: component.coreState.code,
            name: component.name,
            features: component.coreState.selectedFeaturesVisuallyGrouped,
            counter: 1,
          });
        } else {
          accumulator[indexOfComponentGroup].counter++;
        }

        return accumulator;
      },
      [],
    );
  }

  public constructor(
    client: IApiClient,
    configurationUrlProvider: IShareConfiguratorUrlProvider,
    private readonly productFamily: ModularProductFamilyData,
    shoppingContext: ShoppingContext,
    public readonly translation: SiteTranslation,
    initialLayoutId: string,
  ) {
    makeObservable(this);

    this.configurator = new ModularConfiguratorState(client, shoppingContext, translation, configurationUrlProvider);
    this.layoutPicker = new LayoutPickerState(productFamily.layouts);
    this.layoutPicker.setActiveItemByPredicate((x) => x.id === initialLayoutId);

    reaction(() => this.configurator.selectedComponent, this.handleComponentSelectionChange);
  }

  public async loadLayoutIntoConfigurator(layout: ModularLayoutData) {
    await this.configurator.loadLayout(layout);
  }

  @action.bound
  public goToSummary() {
    this.configurator.enableReadonlyMode();
    this.setMode(ModularConfiguratorMode.Summary);
  }

  @action.bound
  public setComponentMode() {
    this.setMode(ModularConfiguratorMode.Component);
    this.configurator.disableReadonlyMode();
  }

  @action.bound
  public setLayoutMode() {
    this.setMode(ModularConfiguratorMode.Layout);
    this.configurator.disableReadonlyMode();
    this.configurator.unselectComponent();
  }

  @action.bound
  private handleComponentSelectionChange(component: ModularComponentConfiguratorState) {
    if (this.configurator.readonly) {
      return;
    }

    if (!component) {
      this.setLayoutMode();
      return;
    }

    if (this.mode === ModularConfiguratorMode.Layout) {
      this.setComponentMode();
    }
  }

  private setMode(mode: ModularConfiguratorMode) {
    this.mode = mode;
  }
}

export default ModularConfiguratorCoordinatorState;
