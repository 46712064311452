import classNames from 'classnames';
import { Component } from 'react';

import Link from '../Link';
import css from './Logo.css';

interface LogoProps {
  link?: string;
  className?: string;
  logoClassName?: string;
  colorLight?: boolean;
  isSmall?: boolean;
}

export class Logo extends Component<LogoProps, {}> {
  render() {
    const { className, logoClassName, link, colorLight, isSmall } = this.props;
    return (
      <div className={classNames(css.Logo, colorLight ? css.light : css.dark, { [css.small]: isSmall }, className)}>
        {link && <Link className={logoClassName} href={link} />}
      </div>
    );
  }
}

export default Logo;
