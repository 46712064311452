// TODO: Remove after footer update in all stores

import { Component, Fragment } from 'react';

import { FooterData } from '../../data/model';
import { FooterPanelTranslation } from '../../localization/SiteTranslation';
import Icon from '../../shared/components/Icon/Icon';
import Link from '../../shared/components/Link';
import RawHtml from '../../shared/components/RawHtml';
import ExternalLinks from '../../shared/externalLinks';
import { ContactSection } from './ContactSection';
import css from './DeprecatedFooter.css';

interface GeneralFooterPanelProps {
  translation: FooterPanelTranslation;
  data: FooterData;
}

export default class DeprecatedFooter extends Component<GeneralFooterPanelProps> {
  renderAddress() {
    const { translation, data } = this.props;
    const content =
      data && data.address ? (
        <RawHtml content={data.address} />
      ) : (
        <Fragment>
          <div>Flokk</div>
          <div>Fridtjof Nansens vei 12,</div>
          <div>P.o Box 5055 Majorstuen-0301 Oslo, Norway</div>
          <Link href={ExternalLinks('flokkContactUs')}>{translation.countactUsLink}</Link>
        </Fragment>
      );

    return <address className={css.address}>{content}</address>;
  }

  renderContact() {
    const { translation, data } = this.props;

    return (
      <div className={css.contact}>
        <ContactSection translation={translation} footerData={data} />
      </div>
    );
  }

  renderSocial() {
    const { translation, data } = this.props;
    const content =
      data && data.socialMedia ? (
        <RawHtml content={data.socialMedia.followUsText} />
      ) : (
        <Fragment>
          <span>{translation.followUsLabel}</span>
        </Fragment>
      );

    return (
      <div className={css.social}>
        {content}
        <div className={css.icons}>
          <Link className={css.link} href={ExternalLinks('flokkInstagram')}>
            <Icon className={css.icon} name="socialInstagram" />
          </Link>
          <Link className={css.link} href={ExternalLinks('flokkFacebook')}>
            <Icon className={css.icon} name="socialFb" />
          </Link>
          <Link className={css.link} href={ExternalLinks('flokkPinterest')}>
            <Icon className={css.icon} name="socialPinterest" />
          </Link>
          <Link className={css.link} href={ExternalLinks('flokkLinkedin')}>
            <Icon className={css.icon} name="socialLinkedin" />
          </Link>
          <Link className={css.link} href={ExternalLinks('flokkBlog')}>
            <Icon className={css.icon} name="socialFocus" />
          </Link>
        </div>
      </div>
    );
  }

  renderCopyright() {
    const { translation, data } = this.props;

    const content =
      data && data.copyrights ? data.copyrights : <Fragment>Flokk {translation.allRightReserved}</Fragment>;
    return (
      <div className={css.copyright}>
        {translation.copyright} © {new Date().getFullYear()} {content}
      </div>
    );
  }

  render() {
    return (
      <div className={css.DeprecatedFooter}>
        <div className={css.content}>
          {this.renderAddress()}
          {this.renderContact()}
          {this.renderSocial()}
        </div>
        {this.renderCopyright()}
      </div>
    );
  }
}
