import { action, computed, makeObservable, observable, override, reaction } from 'mobx';

import { CheckoutStep, ICheckoutStep } from '../../app/checkout/ICheckoutStep';
import { ConfiguratorUrlBuilder } from '../../app/configurator/ConfiguratorUrlBuilder';
import { IApiClient } from '../../app/data/client';
import {
  CreateDealerOrderCommand,
  CreateDealerOrderCommandResponse,
  DealerCartsData,
  GetDealerCartQuery,
  MoneyData,
  OrderData,
  RemoveProductFromDealerCartCommand,
  ShoppingCartData,
  ShoppingCartItemData,
  ShoppingCartSummaryData,
  SurchargeData,
  UpdateProductQuantityInDealerCartCommand,
} from '../../app/data/model';
import { Analytics } from '../../app/shared/analytics/Analytics';
import ProductDataConverter from '../../app/shared/analytics/ProductDataConverter';
import PurchaseEventDataConverter from '../../app/shared/analytics/PurchaseEventDataConverter';
import {
  CartEventData,
  CartEventSource,
  CheckoutEventData,
  PurchaseEventData,
} from '../../app/shared/analytics/TrackingService';
import { BasePageState } from '../../app/shared/BasePageState';
import { AsyncCommand, PageMetadata } from '../../app/shared/common';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { CheckboxInput, Form, TextInput, ValueMaxLength } from '../../app/shared/Form';
import { ModalState } from '../../app/shared/ModalState';
import { INavigationService } from '../../app/shared/NavigationService';
import { ZipBuilder } from '../../app/shared/ZipBuilder';
import { StoreState } from '../../app/StoreState';
import { CartPageTranslation, OrderCreatedModalTranslation } from '../localization/SiteTranslation';
import { BuyerPolicy } from '../Policy';
import { ICartItemContextAction } from './CartItem/CartItemState';
import { CartState } from './CartState';
import { DeliveryAddressFormState } from './DeliveryAddressFormState';
import { ShoppingCartUpdatedEvent } from './events/ShoppingCartUpdatedEvent';
import OrderCreationConfirmationModalState from './OrderCreationConfirmationModal/OrderCreationConfirmationModalState';
import { SendCartToEmailModalState } from './SendCartToEmail/SendCartToEmailModalState';

export class OrderCreationResultModal extends ModalState {
  @observable.ref
  result: CreateDealerOrderCommandResponse;

  @computed
  get failedCarts() {
    return this.result.dealerCarts.carts.filter((x) => this.result.failedShoppingCartIds.contains(x.id));
  }

  @action
  setResponse(response: CreateDealerOrderCommandResponse) {
    this.result = response;
  }

  constructor(public translation: OrderCreatedModalTranslation) {
    super();
    makeObservable(this);
  }
}

export class CartListState extends BasePageState<null> {
  @override
  get metadata(): PageMetadata {
    return {
      title: 'Cart',
    };
  }

  cartItemContextActions: ICartItemContextAction[] = [];

  @observable.ref
  private cartsData: ShoppingCartData[] = [];

  @observable
  public carts: CartState[] = [];

  @observable.ref
  public allCartsSummary?: ShoppingCartSummaryData;

  public steps: Array<ICheckoutStep> = [];
  public cartOverviewStep: CheckoutStep;
  public cartSummaryStep: CheckoutStep;

  public selectAllCheckbox: CheckboxInput = new CheckboxInput(false);
  public orderCreationConfirmationModal = new OrderCreationConfirmationModalState(
    this.store.storeResponse.storeContext.store,
    this.translation.orderCreationConfirmationModal,
  );
  public orderCreationResultModal = new OrderCreationResultModal(this.translation.orderCreatedModal);
  public sendOrderToEmailModal: SendCartToEmailModalState;

  public deliveryAddress: DeliveryAddressFormState;
  public placeOrderForm: Form;
  public commentInput: TextInput;
  public deliveryInstructions: TextInput;

  private zipBuilder: ZipBuilder;

  public readonly goToCartSummaryCommand: AsyncCommand;
  public readonly placeOrderCommand: AsyncCommand;

  public get hasUserAccessToMultipleStores() {
    return this.store.areOtherStoresAvailable;
  }

  constructor(
    private client: IApiClient,
    private eventAggregator: IEventAggregator,
    private store: StoreState,
    private navigation: INavigationService,
    public translation: CartPageTranslation,
  ) {
    super();
    makeObservable(this);
    this.zipBuilder = new ZipBuilder(this.client, this.navigation);

    this.cartOverviewStep = new CheckoutStep('cart-overview', this.translation.cartList.cartOverviewTitle);
    this.cartSummaryStep = new CheckoutStep('cart-summary', this.translation.cartList.cartSummaryTitle);

    this.steps = [this.cartOverviewStep, this.cartSummaryStep];
    this.setStep(this.cartOverviewStep);

    const buyerContextActions = [
      {
        label: this.translation.cartList.editProductLabel,
        action: this.redirectToItemConfiguration,
      },
      {
        label: this.translation.cartList.removeFromCardLabel,
        action: this.removeProductFromCart,
        separate: true,
      },
    ];

    const memberContextActions = [
      {
        label: this.translation.cartList.downloadImagesLabel,
        action: this.generateZipVisualizationImages,
      },
    ];

    this.cartItemContextActions = BuyerPolicy.grantAccess(this.store.shoppingContext.customer)
      ? [...buyerContextActions, ...memberContextActions]
      : memberContextActions;

    this.goToCartSummaryCommand = new AsyncCommand(() => {
      this.setStep(this.cartSummaryStep);
      return Promise.resolve();
    });

    this.commentInput = new TextInput().withRule(new ValueMaxLength(60));
    this.deliveryInstructions = new TextInput().withRule(new ValueMaxLength(50));
    this.deliveryAddress = new DeliveryAddressFormState(
      store.shoppingContext,
      client,
      translation.deliveryAddress.deliveryAddressForm,
    );
    this.placeOrderForm = new Form();
    this.placeOrderForm.inputsToValidate.push(this.commentInput);
    this.placeOrderForm.inputsToValidate.push(this.deliveryInstructions);
    this.placeOrderCommand = new AsyncCommand(this.placeOrder, this.placeOrderForm);
    this.sendOrderToEmailModal = new SendCartToEmailModalState(
      client,
      store.shoppingContext,
      this.translation.sendCartToEmail,
    );

    reaction(
      () => this.cartsData,
      (cartsData) => {
        this.carts = cartsData.map(
          (cartData) =>
            new CartState(
              cartData,
              this.cartItemContextActions,
              this.quantityChangeApproved,
              this.translation.cartList.cartItemList,
            ),
        );
      },
    );

    this.setupCheckboxEvents();
  }

  async onLoad(store: StoreState): Promise<void> {
    const cartResponse = await this.client.send(new GetDealerCartQuery({ shoppingContext: store.shoppingContext }));

    this.setDealerCarts(cartResponse.dealerCarts);
    this.selectAllCarts();
  }

  @computed
  public get selectedCarts() {
    return this.carts.filter((bc) => bc.selected.value === true);
  }

  @computed
  public get selectedCartsCount() {
    return this.selectedCarts.length;
  }

  @computed
  public get selectedCartsItemsCount() {
    return this.selectedCarts.empty() ? 0 : this.selectedCarts.sum((x) => x.numberOfItems);
  }

  @computed get footerButtonAction(): { label: string; command: AsyncCommand } | undefined {
    let noSelectedOrders = this.selectedCartsCount === 0;

    if (this.cartOverviewStep.isActive) {
      return {
        label: noSelectedOrders
          ? this.translation.cartList.noneOrdersSelectedLabel
          : this.translation.cartList.continueWithOrdersLabelFormat.interpolate([
              ['ordersCount', this.selectedCartsCount.toString()],
            ]),
        command: this.goToCartSummaryCommand,
      };
    }
    if (this.cartSummaryStep.isActive) {
      return {
        label: this.translation.cartList.placeAllOrdersLabelFormat.interpolate([
          ['ordersCount', this.selectedCartsCount.toString()],
        ]),
        command: this.placeOrderCommand,
      };
    }
  }

  private sumPrices(prices: MoneyData[]): MoneyData {
    if (prices.empty()) {
      throw new Error('Unable to sum empty price list');
    }
    return { amount: prices.sum((x) => x.amount), currency: prices[0].currency };
  }

  @computed
  public get summaryShoppingCart() {
    if (this.selectedCarts.empty()) {
      const emptyPrice: MoneyData = { amount: 0, currency: { code: '' } };
      const emptySurcharges: SurchargeData[] = [];
      return {
        subTotal: emptyPrice,
        subTotalTax: emptyPrice,
        subTotalWithTax: emptyPrice,
        totalWithTax: emptyPrice,
        taxRatePercentage: 0,
        surcharges: emptySurcharges,
      };
    }

    return {
      subTotal: this.sumPrices(this.selectedCarts.map((x) => x.shoppingCart.subTotal)),
      subTotalTax: this.sumPrices(this.selectedCarts.map((x) => x.shoppingCart.subTotalTax)),
      subTotalWithTax: this.sumPrices(this.selectedCarts.map((x) => x.shoppingCart.subTotalWithTax)),
      totalWithTax: this.sumPrices(this.selectedCarts.map((x) => x.shoppingCart.totalWithTax)),
      taxRatePercentage: this.carts[0].shoppingCart.taxRatePercentage,
      surcharges: this.carts.mapMany((x) => x.shoppingCart.surcharges),
    };
  }

  @action.bound
  public setDealerCarts(dealerCarts: DealerCartsData) {
    this.cartsData = dealerCarts.carts;
    this.allCartsSummary = dealerCarts.cartsSummary;
    this.eventAggregator.publish(new ShoppingCartUpdatedEvent(dealerCarts.cartsSummary));
  }

  private trackCheckoutStep(step: ICheckoutStep, stepNumber: number, cart?: CartState) {
    const eventData: CheckoutEventData = {
      context: this.store.shoppingContext,
      stepId: step.id,
      stepNumber: stepNumber,
      products: cart?.cartItems.map((x) => ProductDataConverter.fromShoppingCartItemData(x.item)),
    };

    Analytics.trackCheckoutStep(eventData);
  }

  @action.bound setStep(step: ICheckoutStep) {
    this.steps.forEach((x) => (x.isActive = x === step));

    if (step === this.cartSummaryStep && this.selectedCarts.any()) {
      const cartState = this.selectedCarts.first(); // Selection disabled, so take the first one

      this.placeOrderForm.clearChildForms();
      this.placeOrderForm.childForms.push(cartState);
      this.placeOrderForm.childForms.push(this.deliveryAddress);
      this.placeOrderForm.resetValidation();

      this.deliveryAddress.initialize(cartState.shoppingCart);
    }
  }

  public placeOrder = async () => {
    const command = new CreateDealerOrderCommand({
      shoppingCarts: this.selectedCarts.map((x) => {
        return { id: x.cartId, customName: x.customName.value, customReference: x.customReference.value };
      }),
      comment: this.commentInput.value,
      deliveryAddress: this.deliveryAddress.getAddress(),
      deliveryInstructions: this.deliveryInstructions.value,
      shoppingContext: this.store.shoppingContext,
    });
    const response = await this.client.send(command);

    response.orders.forEach((order) => {
      this.trackPurchase(order);
    });

    this.orderCreationConfirmationModal.close();

    this.orderCreationResultModal.setResponse(response);
    await this.orderCreationResultModal.open();

    this.setDealerCarts(response.dealerCarts);

    if (response.success || response.invalidConfigurationCodes.any()) {
      this.setStep(this.cartOverviewStep);
    }
  };

  public openOrderConfirmation = async () => {
    this.placeOrderForm.validate();

    if (this.placeOrderForm.isValid) {
      await this.orderCreationConfirmationModal.open();
    }
  };

  private setupCheckboxEvents() {
    reaction(
      () => this.carts.all((bc) => bc.selected.value === true),
      (allSelected) => {
        this.selectAllCheckbox.value = allSelected;
      },
    );

    this.selectAllCheckbox.valueChanged.subscribe((value) => {
      this.carts.forEach((bc) => bc.selected.onChange(value));
    });
  }

  private selectAllCarts() {
    this.selectAllCheckbox.onChange(true);
  }

  private trackPurchase(order: OrderData) {
    const eventData: PurchaseEventData = PurchaseEventDataConverter.convert(this.store.shoppingContext, order);

    Analytics.trackCheckoutCompleted(eventData);
  }

  private quantityChangeApproved = async (item: ShoppingCartItemData, newQuantity: number) => {
    const response = await this.client.send(
      new UpdateProductQuantityInDealerCartCommand({
        quantity: newQuantity,
        lineItemId: item.id,
        shoppingContext: this.store.shoppingContext,
      }),
    );

    this.trackItemQuantityChange(item, newQuantity);

    this.setDealerCarts(response.dealerCarts);
  };

  private redirectToItemConfiguration = async (item: ShoppingCartItemData) => {
    const url = ConfiguratorUrlBuilder.buildFromCartItem(item);
    this.navigation.navigateTo(url);
  };

  private removeProductFromCart = async (item: ShoppingCartItemData) => {
    const response = await this.client.send(
      new RemoveProductFromDealerCartCommand({
        lineItemId: item.id,
        shoppingContext: this.store.shoppingContext,
      }),
    );

    this.trackRemoveFromCartAction(item);

    this.setDealerCarts(response.dealerCarts);
  };

  private generateZipVisualizationImages = async (item: ShoppingCartItemData) => {
    const zipUrl = await this.zipBuilder.downloadZipConfigurationImages(item.code);
    Analytics.trackDownload({ url: zipUrl });
  };

  @action.bound
  public goToCartSummary(cart: CartState) {
    this.selectSpecificCart(cart);
    this.setStep(this.cartSummaryStep);
    // The products array should only be sent with the first step. Sending it with any other step has no effect.
    this.trackCheckoutStep(this.cartSummaryStep, 1, cart);
  }

  @action.bound
  public openSendConfigurationToEmailModal = (cart: CartState) => {
    this.sendOrderToEmailModal.setSelectedShoppingCartsIds([cart.shoppingCart.id]);
    this.sendOrderToEmailModal.open();
  };

  private selectSpecificCart(cart: CartState) {
    this.carts.forEach((x) => x.selected.setDefaultValue(false));
    cart.selected.setDefaultValue(true);
  }

  private buildCartUpdateEventData(item: ShoppingCartItemData, quantity?: number): CartEventData {
    return {
      context: this.store.shoppingContext,
      source: CartEventSource.Cart,
      products: [ProductDataConverter.fromShoppingCartItemData(item, quantity)],
    };
  }

  private trackRemoveFromCartAction(item: ShoppingCartItemData, quantity?: number) {
    const eventData = this.buildCartUpdateEventData(item, quantity);
    Analytics.trackRemoveFromCart(eventData);
  }

  private trackAddToCartAction(item: ShoppingCartItemData, quantity: number) {
    const eventData = this.buildCartUpdateEventData(item, quantity);
    Analytics.trackAddToCart(eventData);
  }

  private trackItemQuantityChange(item: ShoppingCartItemData, newQuantity: number) {
    if (item.quantity > newQuantity) {
      this.trackRemoveFromCartAction(item, item.quantity - newQuantity);
    }

    if (item.quantity < newQuantity) {
      this.trackAddToCartAction(item, newQuantity - item.quantity);
    }
  }
}
