import { action, configure, makeObservable, observable } from 'mobx';
import { ReactElement } from 'react';
import { Router } from 'routes';

import AppSettings from './AppSettings';
import AppState, { IAppMemento } from './AppState';
import { IAuthenticationService } from './auth/AuthenticationService';
import { FinalVisualizationBuilder } from './configurator/VisualizationBuilder';
import { MetadataContext } from './context/MetadataProvider';
import { RequestAuthOptionsProvider } from './data/auth/RequestAuthOptionsProvider';
import ApiClient, { IHttpRequestOptionsProvider } from './data/client';
import HttpClient, { IHttpClient } from './data/httpClient';
import { HttpError } from './data/HttpError';
import { IIpAddressProvider } from './data/IpAddressProvider';
import { ICustomerContextLocalStorage } from './data/localStorage';
import IPreferredStoreProvider from './data/StoreProviders/IPreferredStoreProvider';
import { IAppStateFactory } from './IAppStateFactory';
import { IStoreInitializationStrategy } from './IStoreInitializationStrategy';
import { LayoutResolver } from './layout/Layouts/LayoutResolver';
import NavigationState from './layout/NavigationState';
import { IWarningLocalStorage } from './layout/Warnings/WarningLocalStorage';
import WindowScroll, { ScrollPosition } from './layout/WindowScroll';
import WindowScrollObserver from './layout/WindowScrollObserver';
import WindowScrollPositionHistory from './layout/WindowScrollPositionHistory';
import RouterFactory from './RouterFactory';
import { createForbiddenPage, createGeneralErrorPage, createPageNotFound, Page, Route } from './routes';
import { Analytics } from './shared/analytics/Analytics';
import { debounce, IPageState, isBrowser } from './shared/common';
import { EventAggregator } from './shared/EventAggregator';
import { IImagePreloader, ImagePreloadingContext } from './shared/ImagePreloader';
import Logger from './shared/Logger';
import { DependencyTrackingProxy } from './shared/monitoring/DependencyTrackingProxy';
import { INavigationService } from './shared/NavigationService';
import { RegionManager } from './shared/regions/RegionManager';
import { isStatusCodeSuccessful, StatusCodes } from './shared/StatusCodes';
import {
  DefaultTasksScheduler,
  IScheduledTask,
  ITasksScheduler,
  TasksSchedulingStrategy,
} from './shared/TasksScheduler';
import { RelativeUrl } from './shared/Url';

configure({ enforceActions: 'observed' });

export class UpdateLocationTask implements IScheduledTask {
  public pathname: string;
  public search: string;
  public scrollPosition: ScrollPosition | undefined;

  constructor(
    pathname: string = isBrowser() ? location.pathname : '/',
    search: string = isBrowser() ? location.search : '',
    scrollPosition: ScrollPosition | undefined = undefined,
  ) {
    this.pathname = pathname;
    this.search = search;
    this.scrollPosition = scrollPosition;
  }
}

export interface IPageLoadPipeline {
  onLoadStart(appState: AppState): void;

  initializePageState(appState: AppState, page: Page): Promise<void>;

  onViewSet(appState: AppState, page: Page): Promise<void>;

  loadImages(appState: AppState, page: Page): Promise<void>;

  onLoadFinished(appState: AppState, page: Page): void;
}

export class DefaultPageLoadPipeline implements IPageLoadPipeline {
  onLoadStart(appState: AppState): void {
    appState.loadingPageIndicator.start();
    appState.loadingImagesIndicator.start();
  }

  initializePageState(appState: AppState, page: Page): Promise<void> {
    return appState.loadPage(page.state);
  }

  onViewSet(appState: AppState, page: Page): Promise<void> {
    return Promise.resolve();
  }

  loadImages(appState: AppState, page: Page): Promise<void> {
    const imageUrls = ImagePreloadingContext.queue.dequeueMany();
    return appState.imagePreloader.load(imageUrls);
  }

  onLoadFinished(appState: AppState, page: Page): void {
    appState.loadingImagesIndicator.stop();
    appState.loadingPageIndicator.stop();

    if (page && page.state) {
      page.state.onLoadAdditionalData();
    }
  }
}

export class RestorePageLoadPipeline extends DefaultPageLoadPipeline {
  private appMemento: IAppMemento;
  private initializeView: (callback?: () => void) => void;

  constructor(appMemento: IAppMemento, initializeView: (callback?: () => void) => void) {
    super();
    this.appMemento = appMemento;
    this.initializeView = initializeView;
  }

  onLoadStart(appState: AppState): void {
    appState.restoreStoreMemento(this.appMemento);
  }

  initializePageState(appState: AppState, page: Page): Promise<void> {
    if (this.isError()) {
      throw new HttpError(
        this.appMemento.statusCode,
        `Restored error from server with status code ${this.appMemento.statusCode}`,
      );
    }

    appState.restorePageMemento(page.state, this.appMemento);
    return Promise.resolve();
  }

  // TODO: load image or not

  onViewSet(appState: AppState, page: Page): Promise<void> {
    if (page?.useSplashScreen) {
      appState.loadingPageIndicator.start();
      appState.loadingImagesIndicator.start();
    }

    return new Promise((resolve) => {
      this.initializeView(resolve);
    });
  }

  onLoadFinished(appState: AppState, page: Page) {
    if (appState.loadingImagesIndicator.isLoading) {
      appState.loadingImagesIndicator.stop();
    }

    if (appState.loadingPageIndicator.isLoading) {
      appState.loadingPageIndicator.stop();
    }

    if (page && page.state) {
      page.state.onLoadAdditionalData();
    }
  }

  private isError() {
    return !isStatusCodeSuccessful(this.appMemento.statusCode);
  }
}

class App {
  @observable.ref view: ReactElement<Object> = null;
  @observable.ref layout: ReactElement<Object> = null;
  @observable appState: AppState;
  router: Router<Route<{}>>;
  lastStatusCode: number = 200;

  // tslint:disable-next-line
  pushState: any;
  // tslint:disable-next-line
  replaceState: any;
  // tslint:disable-next-line
  onpopstate: any;

  regionManager: RegionManager;
  authenticationService: IAuthenticationService;
  imagePreloader: IImagePreloader;
  preferredStoreProvider: IPreferredStoreProvider;

  updateLocationScheduler: ITasksScheduler<UpdateLocationTask>;

  storeInitializationStrategy: IStoreInitializationStrategy;

  metadataContext: MetadataContext;

  constructor(
    appSettings: AppSettings,
    customerContextLocalStorage: ICustomerContextLocalStorage,
    warningLocalStorage: IWarningLocalStorage,
    navigation: INavigationService,
    imagePreloader: IImagePreloader,
    preferredStoreProvider: IPreferredStoreProvider,
    ipProvider: IIpAddressProvider,
    httpClientOptions: {},
    routes: Route<{}>[],
    authenticationService: IAuthenticationService,
    appStateFactory: IAppStateFactory,
    storeInitializationStrategy: IStoreInitializationStrategy,
    metadataContext: MetadataContext = {},
  ) {
    makeObservable(this);
    let httpClient: IHttpClient = new HttpClient(httpClientOptions);
    httpClient = new DependencyTrackingProxy(httpClient);

    let authRequestOptionsProvider: IHttpRequestOptionsProvider;
    if (authenticationService) {
      authRequestOptionsProvider = new RequestAuthOptionsProvider(authenticationService);
    }
    const client = new ApiClient(appSettings.apiUrl, httpClient, authRequestOptionsProvider);
    const eventAggregator = new EventAggregator();

    const menuState = new NavigationState(client, navigation);
    this.appState = appStateFactory.create(
      appSettings,
      client,
      menuState,
      navigation,
      customerContextLocalStorage,
      warningLocalStorage,
      imagePreloader,
      preferredStoreProvider,
      ipProvider,
      eventAggregator,
      authenticationService,
    );

    this.router = RouterFactory(routes);
    this.regionManager = new RegionManager(this.appState);

    this.authenticationService = authenticationService;
    this.imagePreloader = imagePreloader;
    this.preferredStoreProvider = preferredStoreProvider;
    this.storeInitializationStrategy = storeInitializationStrategy;
    this.metadataContext = metadataContext;

    this.updateLocationScheduler = new DefaultTasksScheduler(async (task) => {
      await this.updateLocation(task.pathname, task.search);

      if (task.scrollPosition) {
        setTimeout(() => WindowScroll.setScrollY(task.scrollPosition.scrollY));
      }
    }, TasksSchedulingStrategy.replace);

    FinalVisualizationBuilder.setBaseUrl(appSettings.visualizationUrl);

    this.hookHistory();
    this.keepTrackingScrollPosition();
  }

  @action resetView = () => {
    this.view = null;
    this.layout = null;
  };

  @action setView = (component: ReactElement<{}>, page: IPageState) => {
    this.view = component;
    this.layout = LayoutResolver.resolveLayout(component, this.appState, page);
  };

  async restore(
    appMemento: IAppMemento,
    initializeView: (callback: () => void) => void,
    pathname: string = isBrowser() ? location.pathname : '/',
    search: string = isBrowser() ? location.search : '',
  ) {
    await this.updateLocationUsingPipeline(pathname, search, new RestorePageLoadPipeline(appMemento, initializeView));
  }

  async updateLocation(
    pathname: string = isBrowser() ? location.pathname : '/',
    search: string = isBrowser() ? location.search : '',
  ) {
    await this.updateLocationUsingPipeline(pathname, search, new DefaultPageLoadPipeline());
  }

  public async updateLocationUsingPipeline(path: string, search: string, pipeline: IPageLoadPipeline) {
    let page: Page = null;

    try {
      pipeline.onLoadStart(this.appState);

      this.resetView();

      const storeInitializationResult = await this.storeInitializationStrategy.tryInitialize(
        path,
        search,
        this.appState,
        this.preferredStoreProvider,
      );
      if (storeInitializationResult.redirected) {
        return;
      }

      page = await this.matchPage(storeInitializationResult.purePath, search);

      await pipeline.initializePageState(this.appState, page);

      this.setView(page.component, page.state);

      await pipeline.onViewSet(this.appState, page);
      await pipeline.loadImages(this.appState, page);

      this.lastStatusCode = StatusCodes.Ok;
    } catch (error) {
      this.lastStatusCode = error.status !== undefined ? error.status : 500;

      const homeUrl = this.appState.navigationState.homeUrl;
      if (this.lastStatusCode === StatusCodes.NoConnection) {
        // Stay on the same page, do nothing
      } else if (this.lastStatusCode === StatusCodes.NotFound) {
        Logger.log(`Page not found for path name '${path}'`);
        page = this.setErrorPage(createPageNotFound(this.appState.translation.pageNotFound, homeUrl));
      } else if (this.lastStatusCode === StatusCodes.BadRequest) {
        Logger.warn(`Bad request for path '${path}'`);
        page = this.setErrorPage(createGeneralErrorPage(this.appState.translation.invalidCodePage, homeUrl));
      } else if (this.lastStatusCode === StatusCodes.Unauthorized) {
        Logger.warn(`Unauthorized request`);
        const redirectUrl = this.appState.navigation.currentUrl;
        const url = `/login?direct=true&returnTo=${redirectUrl}`;
        page = this.setErrorPage(createGeneralErrorPage(this.appState.translation.unauthorizedErrorPage, url));
      } else if (this.lastStatusCode === StatusCodes.Forbidden) {
        const { translation, authenticationService } = this.appState;
        Logger.warn(`Forbidden request for path '${path}'`);
        page = this.setErrorPage(
          createForbiddenPage(translation.forbiddenErrorPage, '/', authenticationService.logout),
        );
      } else {
        Logger.exception(`Error occurred while loading page for path name '${path}'`, error);
        page = this.setErrorPage(createGeneralErrorPage(this.appState.translation.generalErrorPage, homeUrl));
      }
      await pipeline.onViewSet(this.appState, page);
    } finally {
      pipeline.onLoadFinished(this.appState, page);

      if (isBrowser) {
        Analytics.trackPageVisit(this.appState.navigation.currentUrl); // TODO: do it make navigation proxy as it was done before
      }

      this.appState.navigation.pageLoaded.raise({ url: this.appState.navigation.currentUrl });
    }
  }

  private matchPage(pathname: string, search: string): Promise<Page> {
    const match = this.router.match(pathname);

    if (!match) {
      throw new HttpError(404, `Cannot find route for path ${pathname}`);
    }

    const params = match.params;
    const route = match.fn;
    const query = search ? RelativeUrl.parseQuery(search) : new Map<string, string>();

    return route.getPage(this.appState, params, query);
  }

  private setErrorPage(page: Page) {
    this.appState.setCurrentPage(page.state);
    this.setView(page.component, page.state);
    return page;
  }

  hookHistory() {
    if (isBrowser()) {
      this.pushState = history.pushState;
      // tslint:disable-next-line
      history.pushState = (...args: Array<any>) => {
        if (this.pushState) {
          this.pushState.apply(history, args);
        }

        this.updateLocationScheduler.run(new UpdateLocationTask());
      };

      this.replaceState = history.replaceState;

      this.onpopstate = window.onpopstate;
      window.onpopstate = (e: PopStateEvent) => {
        if (this.onpopstate) {
          this.onpopstate.apply(window, e);
        }

        const updateLocationTask = new UpdateLocationTask();
        updateLocationTask.scrollPosition = WindowScrollPositionHistory.extractPosition(e);

        this.updateLocationScheduler.run(updateLocationTask);
      };
    }
  }

  keepTrackingScrollPosition() {
    if (isBrowser()) {
      WindowScrollObserver.onScrollChange.subscribe(
        debounce((x) => WindowScrollPositionHistory.rememberPosition(x), 100),
      );
    }
  }

  unload() {
    window.onpopstate = this.onpopstate;
    history.pushState = this.pushState;
    history.replaceState = this.replaceState;

    this.appState.unload();
  }

  getMemento(): IAppMemento {
    return this.appState.getMemento(this.lastStatusCode);
  }
}

export default App;
