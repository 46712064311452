import { observer } from 'mobx-react';

import NavButton from '../shared/components/Button/NavButton';
import HeaderLevel1 from '../shared/components/Typography/HeaderLevel1';
import { HubSpotFormConfirmation } from '../shared/hubSpot/Confirmation/HubSpotFormConfirmation';
import { HubspotFormPage } from '../shared/hubSpot/HubspotFormPage';
import ProductSummary from '../shared/hubSpot/ProductSummary/ProductSummary';
import css from './EmailMyConfigurationPage.css';
import { EmailMyConfigurationState } from './EmailMyConfigurationState';

@observer
export default class EmailMyConfigurationPage extends HubspotFormPage<EmailMyConfigurationState> {
  componentDidMount() {
    if (this.props.appState.store.canEmailMyConfiguration()) {
      super.componentDidMount();
    }
  }

  onFormSubmit(formElement: Element) {
    const code = this.props.state.getCode();
    const requestConfigurationReceipt: HTMLInputElement = formElement.querySelector(
      'input[name="request_configuration_receipt"]',
    );
    requestConfigurationReceipt.value = code;
  }

  render() {
    const { appState, state } = this.props;
    const { translation } = appState;
    if (state.isFormSubmitted) {
      return (
        <HubSpotFormConfirmation
          buttonLink={
            appState.storeResponse.storeContext.showroomConfig.homePageUrl || appState.navigationState.homeUrl
          }
          translation={translation.emailMyConfigurationPage.confirmation}
        />
      );
    }
    return (
      <div className={css.page}>
        <NavButton className={css.navigation} onClick={state.backToSummary} back>
          {translation.emailMyConfigurationPage.goBackLink}
        </NavButton>
        <HeaderLevel1 className={css.title} centered>
          {translation.emailMyConfigurationPage.title}
        </HeaderLevel1>
        <div className={css.container} id="hsEmailMyConfigurationForm">
          <div className={css.formContainer}>{super.render()}</div>
          <ProductSummary
            shoppingCartClassName={css.shoppingCartContainer}
            productPreviewClassName={css.productDetailsPreviewContainer}
            state={state}
            translation={translation.cart.summary}
          />
        </div>
      </div>
    );
  }
}
