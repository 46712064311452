import { isBrowser } from '../shared/common';
import Logger from '../shared/Logger';
import { ScrollPosition } from './WindowScroll';

export interface HistoryStateWithPosition {
  scrollPosition: ScrollPosition;
}

// tslint:disable-next-line:no-any
function isHistoryStateWithPosition(state: any): state is HistoryStateWithPosition {
  return !!state && !!state.scrollPosition && state.scrollPosition.scrollY;
}

export default class WindowScrollPositionHistory {
  public static rememberPosition(scrollPosition: ScrollPosition) {
    if (!isBrowser() || !scrollPosition) {
      return;
    }

    try {
      history.replaceState({ scrollPosition: scrollPosition }, window.document.title);
    } catch (error) {
      Logger.warn(`Error when remembering scroll position in history state, error = ${error}`);
    }
  }

  public static extractPosition(e: PopStateEvent): ScrollPosition {
    return isHistoryStateWithPosition(e?.state) ? e.state.scrollPosition : { scrollX: 0, scrollY: 0 };
  }
}
