import { Component } from 'react';

import FullPageLoader from '../../../app/shared/components/Loaders/FullPageLoader';
import css from './PageLoader.css';

export default class PageLoader extends Component<{}, {}> {
  render() {
    return (
      <div className={css.PageLoader}>
        <FullPageLoader className={css.loader} />
      </div>
    );
  }
}
