import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../../app/data/client';
import {
  GetAvailableContactRolesQuery,
  InviteDealerContactCommand,
  InviteDealerContactCommandResponse,
  ShoppingContext,
} from '../../../../app/data/model';
import { AsyncCommand } from '../../../../app/shared/common';
import Event from '../../../../app/shared/Event';
import {
  ArrayMinLengthRule,
  CheckboxGroupInput,
  EmailAddressRule,
  Form,
  TextInput,
  ValueRequiredRule,
} from '../../../../app/shared/Form';
import { ModalState } from '../../../../app/shared/ModalState';
import { InviteNewUsersModalTranslation } from '../../../localization/SiteTranslation';

class InviteNewUserForm extends Form {
  public firstNameInput: TextInput;
  public lastNameInput: TextInput;
  public emailInput: TextInput;
  public retypedEmailInput: TextInput;
  public rolesInput: CheckboxGroupInput;

  constructor(availableRoles: string[] = []) {
    super();

    this.firstNameInput = new TextInput().withRule(new ValueRequiredRule());
    this.lastNameInput = new TextInput().withRule(new ValueRequiredRule());
    this.emailInput = new TextInput().withRule(new ValueRequiredRule()).withRule(new EmailAddressRule());
    this.retypedEmailInput = new TextInput()
      .withRule(new ValueRequiredRule())
      .withRule(new EmailAddressRule())
      .withRule({
        errorMessage: 'Email adresses do not match.',
        validate: (retypedEmail) => retypedEmail === this.emailInput.value,
      });

    this.rolesInput = new CheckboxGroupInput(availableRoles).withRule(
      new ArrayMinLengthRule(1, 'At least one role is required'),
    );

    this.inputsToValidate.push(
      this.firstNameInput,
      this.lastNameInput,
      this.emailInput,
      this.retypedEmailInput,
      this.rolesInput,
    );
  }

  public reset() {
    this.resetValidation();
    this.firstNameInput.onChange('');
    this.lastNameInput.onChange('');
    this.emailInput.onChange('');
    this.retypedEmailInput.onChange('');
    this.rolesInput.onChange([]);
  }
}

export class InviteNewUserModalState extends ModalState {
  @observable
  public readonly inviteNewUserCommand: AsyncCommand;

  public readonly invitationSentEvent = new Event<InviteDealerContactCommandResponse>();

  @observable
  public invitationForm = new InviteNewUserForm();

  constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: InviteNewUsersModalTranslation,
  ) {
    super();
    makeObservable(this);
    this.inviteNewUserCommand = new AsyncCommand(this.inviteNewUser, this.invitationForm);
  }

  public load = async () => {
    const response = await this.client.send(
      new GetAvailableContactRolesQuery({ shoppingContext: this.shoppingContext }),
    );
    this.invitationForm.rolesInput.setAvailableValues(response.roles);
  };

  private inviteNewUser = async () => {
    const { firstNameInput, lastNameInput, emailInput, retypedEmailInput, rolesInput } = this.invitationForm;

    const response = await this.client.send(
      new InviteDealerContactCommand({
        shoppingContext: this.shoppingContext,
        invitation: {
          firstName: firstNameInput.value,
          lastName: lastNameInput.value,
          email: emailInput.value,
          retypedEmail: retypedEmailInput.value,
          grantedRoles: rolesInput.value,
        },
      }),
    );

    this.close();
    this.invitationSentEvent.raise(response);
  };

  onBeforeClose() {
    this.resetForm();
  }

  @action.bound
  private resetForm() {
    this.invitationForm.reset();
  }
}
