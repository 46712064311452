declare global {
  interface Number {
    mod(n: number): number;
  }
}

if (!Number.prototype.mod) {
  Number.prototype.mod = function (this: number, n: number) {
    return ((this % n) + n) % n;
  };
}

export default {};
