import { observer } from 'mobx-react';

import { CheckboxGroupInput } from '../../Form';
import { ErrorMessages } from '../Input/ErrorMessages/ErrorMessages';
import Label from '../Label/Label';
import CheckboxElement from './CheckboxElement';

type CheckboxGroupProps = {
  label?: string;
  input: CheckboxGroupInput;
};

const CheckboxGroup = observer(({ label, input }: CheckboxGroupProps) => {
  return (
    <div>
      {label && <Label>Granted roles</Label>}
      {input.availableValues.map((checkboxKey) => (
        <CheckboxElement
          key={checkboxKey}
          onChange={() => input.handleCheckboxChange(checkboxKey)}
          checked={input.isChecked(checkboxKey)}
          size="large"
        >
          {checkboxKey}
        </CheckboxElement>
      ))}
      <ErrorMessages errors={input.validationErrorMessages} />
    </div>
  );
});

export default CheckboxGroup;
