import Topbar from '../../Topbar';
import { LayoutProps } from '../MainLayout';
import css from './ModularConfiguratorLayout.css';

type ModularConfiguratorLayoutProps = LayoutProps;

const ModularConfiguratorLayout = ({ appState, children, embeddedQuery }: ModularConfiguratorLayoutProps) => {
  if (embeddedQuery.embed) {
    return children;
  }

  return (
    <div className={css.container}>
      <Topbar appState={appState} translation={appState.translation.layout.topBar} isLogoSmall />
      {children}
    </div>
  );
};

export default ModularConfiguratorLayout;
