import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../data/client';
import { AssetCategoryData, AssetsData, GenerateZipArchiveCommand } from '../../../data/model';
import { DownloadsSectionTranslation } from '../../../localization/SiteTranslation';
import { Analytics } from '../../analytics/Analytics';
import { AsyncCommand } from '../../common';
import { INavigationService } from '../../NavigationService';

export class AssetsDownloaderState {
  @observable.shallow
  public categories: Array<AssetCategoryData> = [];
  public downloadAllCommand: AsyncCommand;

  private allAssetsArchiveName: string;

  public constructor(
    public readonly client: IApiClient,
    private readonly navigationService: INavigationService,
    public readonly translations: DownloadsSectionTranslation,
  ) {
    makeObservable(this);
    this.getCategoryName = this.getCategoryName.bind(this);
  }

  @action
  public async downloadAll() {
    const urls = this.categories.mapMany((x) => x.assets).map((x) => x.url);
    const command = new GenerateZipArchiveCommand({
      files: urls,
      outputFile: this.allAssetsArchiveName,
    });
    const response = await this.client.send(command);

    this.navigationService.redirect(response.fileUrl);
    this.trackDownload(response.fileUrl);
  }

  public getCategoryName(x: AssetCategoryData) {
    return this.translations.categories[x.categoryName] || x.categoryName;
  }

  @action
  public setAssets(allAssetsArchiveName: string, data: AssetsData) {
    this.allAssetsArchiveName = allAssetsArchiveName;
    const categories = data.categories
      .map((x) => x)
      .sort((a, b) => {
        // Warning: sort function does not return new array
        return this.getCategoryName(a).localeCompare(this.getCategoryName(b));
      });

    if (data.otherAssets.any()) {
      categories.push({
        categoryName: this.translations.other,
        assets: data.otherAssets,
      });
    }

    this.categories = categories;
    this.downloadAllCommand = new AsyncCommand(() => this.downloadAll());
  }

  public trackDownload(url: string) {
    Analytics.trackDownload({ url });
  }
}
