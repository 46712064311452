// tslint:disable:no-any no-console
export interface ILogAppender {
  log(message?: string): void;

  warn(message?: string): void;

  error(message?: string): void;

  exception(message?: string, exception?: Error): void;
}

class ConsoleAppender implements ILogAppender {
  public log(message?: string) {
    console.log(message);
  }

  public warn(message?: string) {
    console.warn(message);
  }

  public error(message?: string) {
    console.error(message);
  }

  public exception(message?: string, exception?: any) {
    console.error(message, exception);
  }
}

class Logger {
  static appenders: Array<ILogAppender> = [new ConsoleAppender()];

  static addAppender(appender: ILogAppender) {
    Logger.appenders.push(appender);
  }

  public static log(message?: string) {
    Logger.appenders.forEach((x) => x.log(message));
  }

  public static warn(message?: string) {
    Logger.appenders.forEach((x) => x.warn(message));
  }

  public static error(message?: string) {
    Logger.appenders.forEach((x) => x.error(message));
  }

  public static exception(message?: string, exception?: any) {
    Logger.appenders.forEach((x) => x.exception(message, exception));
  }
}

export default Logger;
// tslint:enable
