import { observer } from 'mobx-react';

import { PhysicalStoreData } from '../../../data/model';
import { StoreTypeTranslation } from '../../../localization/SiteTranslation';
import List from '../../../shared/components/List/List';
import Store from '../Store/Store';
import css from './StoreList.css';
import StoreListState from './StoreListState';

interface StoreListProps {
  state: StoreListState;
  storeTypeTranslation: StoreTypeTranslation;
}

const StoreList = observer(({ state, storeTypeTranslation }: StoreListProps) => {
  const renderStore = (item: PhysicalStoreData) => {
    return <Store translation={storeTypeTranslation} data={item} />;
  };

  return (
    <List
      items={state.stores}
      itemRenderer={renderStore}
      onItemClick={(store) => state.onStoreSelected.raise(store)}
      className={css.list}
      itemClassName={css.listItem}
    />
  );
});

export default StoreList;
