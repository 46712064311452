import { Mesh, Scene, SceneLoader } from '@babylonjs/core';
import { GLTFFileLoader } from '@babylonjs/loaders';

import { LoadableMeshState } from '../../core/LoadableMeshState';
import { ObjectState } from '../../core/ObjectState';
import { AbstractVisual } from './AbstractVisual';
import { SimpleVisual } from './SimpleVisual';
import { IVisualFactory } from './VisualFactory';

export class LoadableVisualFactory implements IVisualFactory<LoadableMeshState> {
  constructor() {
    SceneLoader.RegisterPlugin(new GLTFFileLoader());
    SceneLoader.ShowLoadingScreen = false;
  }

  canCreate(object: ObjectState): boolean {
    return object instanceof LoadableMeshState;
  }

  async createAsync(scene: Scene, object: LoadableMeshState): Promise<AbstractVisual> {
    const importMeshResult = await SceneLoader.ImportMeshAsync(object.name, object.url, null, scene);
    const mesh = importMeshResult.meshes.find((x) => x.name.equalsIgnoreCase(object.name));
    mesh.isPickable = true;
    mesh.receiveShadows = true;

    if (object.materialName && object.materialUrl) {
      if (scene.materials.empty((x) => x.name === object.materialName)) {
        await SceneLoader.AppendAsync(object.materialUrl, null, scene); // TODO: check if there is another way of doing it
        scene.meshes.filter((x) => x.name.startsWith('prof')).forEach((x) => (x.visibility = 0)); // TODO: hide the plane unnecessarily imported form the material file
      }
    }

    const visual = new SimpleVisual(object, mesh as Mesh);
    return visual;
  }
}
