import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../app/data/client';
import { ContactData, RemoveContactFromDealerCommand, ShoppingContext } from '../../../app/data/model';
import { AsyncCommand } from '../../../app/shared/common';
import Event from '../../../app/shared/Event';
import { ModalState } from '../../../app/shared/ModalState';

export class ConfirmContactRemovalModalState extends ModalState {
  @observable
  public contact: ContactData | undefined = undefined;

  public readonly removeContactCommand: AsyncCommand;

  public readonly contactRemovedEvent = new Event();

  constructor(private readonly client: IApiClient, private readonly shoppingContext: ShoppingContext) {
    super();

    makeObservable(this);

    this.removeContactCommand = new AsyncCommand(async () => {
      if (!this.contact) {
        return;
      }

      await this.client.send(
        new RemoveContactFromDealerCommand({ shoppingContext: this.shoppingContext, contactId: this.contact.id }),
      );
      this.close();
      this.contactRemovedEvent.raise({});
    });
  }

  @action.bound
  public setContact(contact: ContactData) {
    this.contact = contact;
  }

  onBeforeClose() {
    this.contact = undefined;
  }
}
