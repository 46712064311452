import * as classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

import css from './PropertyItem.css';

export interface PropertyItemOptionProps {
  colorGrey?: boolean;
  fontBold?: boolean;
  alignLeft?: boolean;
  uppercase?: boolean;
}

export interface PropertyItemClassNamesProps {
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  propClassName?: string;
}

export interface PropertyProps extends PropertyItemClassNamesProps {
  label: ReactNode;
  value: ReactNode;
  labelOptions?: PropertyItemOptionProps;
  valueOptions?: PropertyItemOptionProps;
}

export const PropertyItem: React.FunctionComponent<PropertyProps> = (props: PropertyProps) => {
  const { label, value, className, labelClassName, valueClassName, propClassName, labelOptions, valueOptions } = props;

  const labelClasses = classNames(
    css.propertyItemLabel,
    propClassName,
    labelOptions?.colorGrey && css.propertyItemGrey,
    labelOptions?.fontBold && css.propertyItemBold,
    labelOptions?.alignLeft && css.propertyAlignLeft,
    labelOptions?.uppercase && css.propertyUppercase,
    labelClassName,
  );

  const valueClasses = classNames(
    css.propertyItemValue,
    propClassName,
    valueOptions?.colorGrey && css.propertyItemGrey,
    valueOptions?.fontBold && css.propertyItemBold,
    valueOptions?.alignLeft && css.propertyAlignLeft,
    valueOptions?.uppercase && css.propertyUppercase,
    valueClassName,
  );

  return label && value ? (
    <div className={classNames(css.PropertyItem, className)}>
      <div className={labelClasses}>{label}</div>
      <div className={valueClasses}>{value}</div>
    </div>
  ) : null;
};
