import { useEffect } from 'react';

function useOnKeyDown(targetKey: string, handler: () => void): void {
  useEffect(() => {
    const listener = (event: KeyboardEvent): void => {
      if (event.key !== targetKey) {
        return;
      }

      handler();
    };

    addEventListener('keydown', listener);

    return (): void => removeEventListener('keydown', listener);
  });
}

export default useOnKeyDown;
