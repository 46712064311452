import { Router } from 'routes';

import RouteDefinitions from './RouteDefinitions';
import { Route } from './routes';

export default function RouterFactory(routes: Route<{}>[]): Router<Route<Object>> {
  const router = Router<Route<Object>>();

  routes.forEach((r) => {
    router.addRoute(r.route, r);
    if (r.aliases != null) {
      const redirectRoute = RouteDefinitions.redirectRouteFactoryMethod(r.route);
      r.aliases.map((a) => {
        router.addRoute(a, redirectRoute);
      });
    }
  });

  return router;
}
