import classNames from 'classnames';

import Button, { ButtonProps } from './Button';
import css from './TertiaryButton.css';

const TertiaryButton = ({ className, ...buttonProps }: ButtonProps) => {
  const finalClassName = classNames(
    css.TertiaryButton,
    buttonProps.disabled && !buttonProps.isLoading && css.disabled,
    buttonProps.error && css.error,
    className,
  );
  return <Button {...buttonProps} className={finalClassName} loaderDefaultColor />;
};

export default TertiaryButton;
