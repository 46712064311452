import classNames from 'classnames';

import css from './ThreeDotsLoader.css';

export type ThreeDotsLoaderColor = 'default' | 'white';
export type ThreeDotsLoaderSize = 'small' | 'medium' | 'large';

interface ThreeDotsLoader {
  className?: string;
  dotClassName?: string;
  size?: ThreeDotsLoaderSize;
  color?: ThreeDotsLoaderColor;
}

const ThreeDotsLoader = ({ className, dotClassName, size, color }: ThreeDotsLoader) => {
  const dots = ['bounce1', 'bounce2', 'bounce3'] as const;

  return (
    <div className={classNames(className ? className : css.dots, css.spinner)}>
      {dots.map((animation) => {
        return (
          <div
            key={animation}
            className={classNames(
              css.dot,
              css[animation],
              size ? css[size] : css.small,
              color ? css[`${color}Color` as `${ThreeDotsLoaderColor}Color`] : css.defaultColor,
              dotClassName,
            )}
          />
        );
      })}
    </div>
  );
};

export default ThreeDotsLoader;
