import * as React from 'react';

import { BrandsFilterTranslation } from '../../../../localization/SiteTranslation';
import List from '../../../../shared/components/List/List';
import { IBrandFilterOptionState, IBrandFilterState } from '../DownloadsFiltersState';
import Tag from './Tag/Tag';
import css from './Tags.css';

interface TagsProps extends IBrandFilterState {
  onSelect: () => void;
  translation: BrandsFilterTranslation;
}

const Tags: React.FC<TagsProps> = ({ options, selection, translation, onSelect }) => {
  const renderItem = (item: IBrandFilterOptionState) => {
    const { isActive, name } = item;
    const handleSelect = () => {
      selection.toggle(item);
      onSelect();
    };

    return (
      <Tag isActive={isActive(selection.values)} onSelect={handleSelect}>
        {name}
      </Tag>
    );
  };

  return (
    <div className={css.Tags}>
      <h2 className={css.title}>{translation.name}:</h2>
      <List className={css.items} itemClassName={css.item} items={options} itemRenderer={renderItem} />
    </div>
  );
};

export default Tags;
