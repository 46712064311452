import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';
import ReactModal, { Classes } from 'react-modal';

import { ModalTranslation } from '../../../localization/SiteTranslation';
import { ModalState } from '../../ModalState';
import CloseButton from '../Button/CloseButton';
import SecondaryButton from '../Button/SecondaryButton';
import TertiaryButton from '../Button/TertiaryButton';
import { ScrollLock } from '../ScrollLock/ScrollLock';
import css from './Modal.css';

interface ModalProps {
  className?: string;
  classes?: Classes;
  contentClassName?: string;
  modal: ModalState;
  children: ReactNode | Array<ReactNode>;
  onClose?: () => void;
  onApply?: () => void;
  cancelButtonName?: string;
  applyButtonName?: string;
  applyButtonDisabled?: boolean;
  hideCloseButton?: boolean;
  headerComponent?: ReactNode;
  headerWrapperClassName?: string;
  closeButtonClassName?: string;
  overlayClassName?: string;
  translation?: ModalTranslation;
  fullScreen?: boolean;
  shouldNotCloseOnEsc?: boolean;
}

// TODO refactor modal component - add extra component with mobx modal state
class Modal extends Component<ModalProps> {
  render() {
    const {
      children,
      className,
      classes,
      contentClassName,
      overlayClassName,
      modal,
      onApply,
      cancelButtonName,
      applyButtonName,
      applyButtonDisabled,
      hideCloseButton,
      headerComponent,
      headerWrapperClassName,
      closeButtonClassName,
      translation,
      fullScreen,
      shouldNotCloseOnEsc,
      ...modalProps
    } = this.props;

    const headerClasses = classNames(css.header, headerWrapperClassName);
    const cancelButton = cancelButtonName && (
      <TertiaryButton className={css.cancelButton} onClick={modal.close}>
        {cancelButtonName}
      </TertiaryButton>
    );
    const applyButton = applyButtonName && (
      <SecondaryButton onClick={onApply} disabled={applyButtonDisabled}>
        {applyButtonName}
      </SecondaryButton>
    );
    const closeButton = !hideCloseButton && (
      <CloseButton className={classNames(css.close, closeButtonClassName)} onClick={modal.close}>
        {translation?.closeButton && translation.closeButton}
      </CloseButton>
    );

    const baseClass = classNames(css.overlay, overlayClassName);

    const overlayClasses = {
      base: baseClass,
      afterOpen: css.afterOpen,
      beforeClose: css.beforeClose,
    };

    const modalClasses = {
      base: classNames(css.Modal, fullScreen && css.fullscreen, className && className, classes?.base || ''),
      afterOpen: classes?.afterOpen || '',
      beforeClose: classes?.beforeClose || '',
    };

    const showButtons = applyButtonName || cancelButtonName;
    return (
      <ScrollLock isLocked={modal.visible}>
        <ReactModal
          className={modalClasses}
          overlayClassName={overlayClasses}
          shouldFocusAfterRender={true}
          ariaHideApp={false}
          isOpen={modal.visible}
          onRequestClose={modal.close}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc={!shouldNotCloseOnEsc}
          closeTimeoutMS={300}
          {...modalProps}
        >
          <div className={headerClasses}>
            {headerComponent}
            {closeButton}
          </div>
          <div className={classNames(css.content, contentClassName)}>{children}</div>
          {showButtons && (
            <div className={css.buttonsGroup}>
              {cancelButton}
              {applyButton}
            </div>
          )}
        </ReactModal>
      </ScrollLock>
    );
  }
}

export default observer(Modal);
