import classNames from 'classnames';
import { Component, Fragment } from 'react';

import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import css from './NavigationArrows.css';

interface NavigationArrowsProps {
  className?: string;
  prevIconClassName?: string;
  nextIconClassName?: string;
  handleOnClickPrev?: () => void;
  handleOnClickNext?: () => void;
}

class NavigationArrows extends Component<NavigationArrowsProps, {}> {
  render() {
    const { prevIconClassName, className, nextIconClassName, handleOnClickNext, handleOnClickPrev } = this.props;

    return (
      <Fragment>
        <IconButton
          className={classNames(css.prevIcon, className, prevIconClassName)}
          icon={<Icon name="angleLeft" className={css.angleIcon} />}
          onClick={handleOnClickPrev}
        />
        <IconButton
          className={classNames(css.nextIcon, className, nextIconClassName)}
          icon={<Icon name="angleRight" className={css.angleIcon} />}
          onClick={handleOnClickNext}
        />
      </Fragment>
    );
  }
}

export default NavigationArrows;
