import classNames from 'classnames';

import TextButton from '../../../../shared/components/Button/TextButton';
import { ExpandablePanel } from '../../../../shared/components/ExpandablePanel/ExpandablePanel';
import Icon from '../../../../shared/components/Icon/Icon';
import List from '../../../../shared/components/List/List';
import StoreAndLanguageSelectorState from '../../../StoreAndLanguageSelector/StoreAndLanguageSelectorState';
import { LanguageItem, StoreWithUniqueKey } from '../../../StoreSelectionState';
import css from './StoreAndLanguageSubmenu.css';

interface StoreAndLanguageSubmenuProps {
  state: StoreAndLanguageSelectorState;
  className?: string;
}

const StoreAndLanguageSubmenu = ({ className, state }: StoreAndLanguageSubmenuProps) => {
  const renderLanguage = (language: LanguageItem, store: StoreWithUniqueKey) => {
    return (
      <TextButton
        className={classNames(css.menuItemLink, {
          [css.isActive]: state.isLanguageCurrent(language),
        })}
        onClick={() => state.select(store, language)}
      >
        {language.displayName}
      </TextButton>
    );
  };

  const renderStoreName = (item: StoreWithUniqueKey, isExpanded: boolean) => {
    return (
      <>
        <span className={classNames(css.menuItemLink, { [css.isActive]: state.isStoreCurrent(item) })}>
          {item.name}
        </span>
        <Icon
          className={classNames(css.moreItemsIcon, css.showMoreItemsIcon)}
          name={isExpanded ? 'angleUp' : 'angleDown'}
        />
      </>
    );
  };

  const renderItem = (item: StoreWithUniqueKey) => {
    const languages = state.getLanguagesByStore(item);

    return (
      <div className={css.menuItem}>
        {languages.length > 1 ? (
          <ExpandablePanel
            titleClassName={css.expandableContent}
            renderTitle={(isExpanded) => renderStoreName(item, isExpanded)}
            expanded={false}
            animationDuration={400}
          >
            <List items={languages} itemRenderer={(language) => renderLanguage(language, item)} />
          </ExpandablePanel>
        ) : (
          <TextButton
            className={classNames(css.menuItemLink, {
              [css.isActive]: state.isStoreCurrent(item),
            })}
            onClick={() => state.selectStore(item)}
          >
            {item.name}
          </TextButton>
        )}
      </div>
    );
  };

  return (
    <List
      className={classNames(css.menuItemList, className)}
      itemClassName={css.menuItemContainer}
      items={state.stores}
      itemRenderer={renderItem}
    />
  );
};

export default StoreAndLanguageSubmenu;
