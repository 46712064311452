import { observer } from 'mobx-react';

import CheckboxGroup from '../../../../app/shared/components/Checkbox/CheckboxGroup';
import { ConfirmationModal } from '../../../../app/shared/components/Modal/ConfirmationModal/ConfirmationModal';
import css from './ChangeUserRolesModal.css';
import { ChangeUserRolesModalState } from './ChangeUserRolesModalState';

export const ChangeUserRolesModal = observer(({ state }: { state: ChangeUserRolesModalState }) => {
  if (!state.contact) {
    return null;
  }

  return (
    <ConfirmationModal
      modal={state}
      title={state.translation.title}
      applyButton={{ command: state.setUserRolesCommand }}
    >
      <div className={css.userContainer}>
        {state.translation.selectRolesText} <strong>{state.contact.fullName}</strong>
      </div>
      <CheckboxGroup input={state.rolesInput} />
    </ConfirmationModal>
  );
});
