import { LanguageFormatValidator } from '../localization/LanguageFormatValidator';
import { RelativeUrl } from './Url';

export interface ParsedUrlPath {
  languageCode: string;
  hasValidLanguage: boolean;
  hasValidStore: boolean;
  storeId: string;
  pure: string;
}

export class StoreUrlParser {
  public static parse(path: string, validStorePredicate: (storeCandidate: string) => boolean): ParsedUrlPath {
    const url = new RelativeUrl(path);

    let result: ParsedUrlPath = {
      languageCode: null,
      hasValidLanguage: false,
      hasValidStore: false,
      pure: null,
      storeId: null,
    };

    const clone = url.clone();

    const isCorrectLanguageOnSecondPosition =
      clone.pathSegments.length >= 2 && LanguageFormatValidator.isValidLanguage(clone.pathSegments[1]);
    // When second segment is language and it's known
    if (isCorrectLanguageOnSecondPosition) {
      result.hasValidLanguage = true;
      result.languageCode = clone.pathSegments[1].toLocaleLowerCase();
      const storeCandidate = clone.pathSegments[0].toLocaleLowerCase();
      const matchingStore = validStorePredicate(storeCandidate) ? storeCandidate : null;
      result.storeId = (matchingStore || storeCandidate).toLocaleLowerCase();
      result.hasValidStore = matchingStore != null;
      clone.pathSegments = clone.pathSegments.slice(2);
    } else {
      const storeCandidate = clone.pathSegments.length > 0 && clone.pathSegments[0].toLocaleLowerCase();
      const matchingStore = validStorePredicate(storeCandidate) ? storeCandidate : null;
      result.hasValidStore = matchingStore != null;
      if (matchingStore != null) {
        result.storeId = clone.pathSegments.shift().toLocaleLowerCase();
      } else {
        const isLanguageValid = LanguageFormatValidator.isValidLanguage(clone.pathSegments[0]);
        if (isLanguageValid) {
          result.hasValidLanguage = true;
          result.languageCode = clone.pathSegments.shift().toLocaleLowerCase();
        }
      }
    }

    result.pure = clone.path;
    return result;
  }
}
