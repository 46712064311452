import classNames from 'classnames';
import { Component } from 'react';

import { ImagePreloadingContext } from '../ImagePreloader';
import { ImageUrlBuilder } from '../ImageUrlBuilder';
import DefaultImage from './DefaultImage/DefaultImage';

interface PreloadedImageProps {
  className?: string;
  fallbackClass?: string;
  alt?: string;
  onClick?: () => void;
  src: string;
  width?: number;
  height?: number;
  rounded?: boolean;
  sharpeningFactor?: number;
}

class PreloadedImage extends Component<PreloadedImageProps> {
  constructor(props: PreloadedImageProps) {
    super(props);

    if (this.props.src) {
      ImagePreloadingContext.queue.enqueue(this.buildUrlWithSize());
    }
  }

  private buildUrlWithSize() {
    const { height, sharpeningFactor, src, width } = this.props;
    return ImageUrlBuilder.build(src, width, height, sharpeningFactor);
  }

  render() {
    const { className, fallbackClass, rounded, onClick, alt = '' } = this.props;
    const url = this.buildUrlWithSize();

    return url ? (
      <img className={className} src={url} alt={alt} onClick={onClick} />
    ) : (
      <DefaultImage className={classNames(className, fallbackClass)} rounded={rounded} />
    );
  }
}

export default PreloadedImage;
