import classNames from 'classnames';

import IconButton, { IconButtonProps } from '../../../../../../../shared/components/Button/IconButton';
import Icon, { IconType } from '../../../../../../../shared/components/Icon/Icon';
import css from './ToolbarButton.css';

interface ToolbarButtonProps extends Pick<IconButtonProps, 'tooltip'> {
  icon: IconType;
  onClick: () => void;
  className?: string;
  children?: string;
  iconClassName?: string;
  labelClassName?: string;
  compact?: boolean;
}

const ToolbarButton = ({
  children,
  className,
  iconClassName,
  labelClassName,
  icon,
  onClick,
  tooltip,
  compact = false,
}: ToolbarButtonProps) => {
  return (
    <IconButton
      className={classNames(css.element, { [css.withText]: !compact }, className)}
      nameClassName={labelClassName}
      icon={<Icon className={classNames(css.icon, iconClassName)} name={icon} />}
      onClick={onClick}
      after
      tooltip={compact && tooltip}
    >
      {!compact && children}
    </IconButton>
  );
};

export default ToolbarButton;
