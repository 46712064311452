import * as React from 'react';

import css from './ScrollLock.css';

interface ScrollLockProps {
  children: React.ReactNode;
  isLocked: boolean;
}

interface ScrollLockState {
  scrollPosition?: number;
}

export class ScrollLock extends React.Component<ScrollLockProps, ScrollLockState> {
  constructor(props: ScrollLockProps) {
    super(props);
    this.state = {
      scrollPosition: undefined,
    };
  }

  componentDidUpdate(prevProps: ScrollLockProps): void {
    const isLockedChanged = prevProps.isLocked !== this.props.isLocked;
    if (isLockedChanged) {
      const scrollY = window.scrollY || window.pageYOffset;
      if (this.props.isLocked) {
        const body = document.body;
        body.classList.add(css.bodyHidden);
        body.style.top = `-${scrollY}px`;
      } else if (this.state.scrollPosition !== undefined) {
        this.unlock();
      }
      this.setState({ scrollPosition: this.props.isLocked ? scrollY : undefined });
    }
  }

  componentWillUnmount() {
    this.unlock();
  }

  unlock() {
    const body = document.body;

    body.classList.remove(css.bodyHidden);
    body.style.top = '';

    window.scrollTo({
      top: this.state.scrollPosition,
      behavior: 'auto',
    });
  }

  render() {
    return <React.Fragment> {this.props.children} </React.Fragment>;
  }
}
