import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { Breakpoint, Screen, ScreenObserver } from '../../layout/ScreenObserver';
import { SelectOptionsStepTranslation } from '../../localization/SiteTranslation';
import IconButton from '../../shared/components/Button/IconButton';
import Icon from '../../shared/components/Icon/Icon';
import PriceDifference from '../../shared/components/PriceDifference';
import TextInfo from '../../shared/components/TextInfo/TextInfo';
import { FabricCollectionState } from '../FabricCollectionState';
import { FabricOptionState } from '../FabricOptionState';
import { FabricPriceGroupState } from '../FabricPriceGroupState';
import { OptionVariationState } from '../OptionState';
import FabricFilters from './FabricFilters/FabricFilters';
import css from './Fabrics.css';
import OptionGroupTitle from './OptionGroupTitle/OptionGroupTitle';
import OptionVariationItem from './OptionVariationItem';

interface FabricsProps {
  option: FabricOptionState;
  translation: SelectOptionsStepTranslation;
  shouldDisplayFilters: boolean;
  brandId: string;
}

@observer
class Fabrics extends Component<FabricsProps, { hidePopup: boolean }> {
  constructor(props: FabricsProps) {
    super(props);
    this.state = { hidePopup: true };

    this.onScreenChange = this.onScreenChange.bind(this);
  }

  componentDidMount(): void {
    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
  }

  componentWillUnmount(): void {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
  }

  onScreenChange(screen: Screen) {
    const isMobile = screen.breakpoint === Breakpoint.Phone || screen.breakpoint === Breakpoint.Tablet;
    this.setState({ hidePopup: isMobile });
  }

  render() {
    const { option, shouldDisplayFilters, translation } = this.props;
    const noResults = !option.filteredPriceGroups.length && !option.collections.length;
    const clearButton: JSX.Element | null = option.anyFilterApplied ? (
      <IconButton
        icon={<Icon name="cross" className={css.clearIcon} />}
        onClick={() => {
          option.clearFilters();
        }}
        className={css.clear}
        bold
      >
        <span className={css.clearName}>{translation.fabricFilters.clearButton}</span>
      </IconButton>
    ) : null;

    return (
      <Fragment>
        <FabricFilters
          showFilters={option.anyFilterDefined}
          className={classNames({ [css.hiddenFiltersOnMobile]: !shouldDisplayFilters })}
          collectionsFilter={option.collectionsBasedFilter}
          filters={option.filters}
          noResults={noResults}
          clearButton={clearButton}
          translation={translation.fabricFilters}
        />
        {option.isPriceGroupDeterminant
          ? this.renderPriceGroupDeterminant(option.filteredPriceGroups)
          : this.renderFabricsCollections(option.collectionsWithVariations)}
      </Fragment>
    );
  }

  renderPriceGroupDeterminant(priceGroups: FabricPriceGroupState[]) {
    return priceGroups.length === 1
      ? this.renderFabricsCollections(priceGroups[0].filteredCollections) // Just to look better if only one price group available
      : priceGroups.map((group) => this.renderPriceGroup(group));
  }

  renderFabricsCollections(collections: FabricCollectionState[]) {
    return (
      <div className={css.PriceGroup}>
        <div className={css.collections}>{collections.map((collection) => this.renderCollection(collection))}</div>
      </div>
    );
  }

  renderPriceGroup(priceGroup: FabricPriceGroupState) {
    const { option, translation } = this.props;
    const priceComponent =
      option.enablers.empty() || option.priceGroups.any() ? (
        <PriceDifference price={option.getPriceDifference(priceGroup)} />
      ) : (
        <></>
      );
    const isPriceGroupSelected = option.selectedGroup === priceGroup;

    return (
      <div className={css.PriceGroup} key={priceGroup.data.id}>
        <OptionGroupTitle
          className={css.optionGroupTitle}
          title={`${translation.priceGroup} ${priceGroup.data.name}`}
          priceComponent={priceComponent}
          isSelected={isPriceGroupSelected}
          translation={translation}
        />
        <div className={css.collections}>
          {priceGroup.filteredCollections.map((collection) => this.renderCollection(collection))}
        </div>
      </div>
    );
  }

  renderCollection(collection: FabricCollectionState) {
    return (
      <div key={collection.data.name} className={css.collection}>
        <TextInfo
          className={css.collectionName}
          buttonClassName={css.infoButton}
          onClick={() => collection.openDescriptionModal()}
          showInfo={!!collection.data.description}
        >
          {collection.data.name}
        </TextInfo>
        <div className={css.fabricItems}>
          {collection.filteredVariations.map((fabric) => this.renderFabric(fabric))}
        </div>
      </div>
    );
  }

  renderFabric(variation: OptionVariationState) {
    const { option, brandId } = this.props;
    const selected = variation.selected;
    return (
      <OptionVariationItem
        key={variation.data.id}
        variation={variation}
        className={css.item}
        thumbnailClassName={classNames(css.thumbnail, selected && css.isSelected)}
        onSelected={() => {
          option.checkRestrictionsAndSelect(variation);
        }}
        isSelected={selected}
        hidePopup={this.state.hidePopup}
        brandId={brandId}
      />
    );
  }
}

export default Fabrics;
