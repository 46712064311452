import classNames from 'classnames';
import { Component, createRef, RefObject } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import IconButton from '../Button/IconButton';
import HeightAnimation from '../HeightAnimation';
import Icon from '../Icon/Icon';
import HeaderLevel2 from '../Typography/HeaderLevel2';
import css from './ExpandableTextBox.css';

interface ExpandableTextBoxProps {
  className?: string;
  headingContainerClassName?: string;
  headingClassName?: string;
  descriptionContainerClassName?: string;
  descriptionClassName?: string;
  buttonContainerClassName?: string;
  buttonClassName?: string;
  initClassName?: string;

  heading: JSX.Element | string;
  description: JSX.Element | string;
  maxHeight: number;
}

interface ExpandableTextBoxState {
  maxDescriptionHeight: number;
  descriptionHeight: 'auto' | number;
  descriptionScrollHeight: number;
  isInitHeight: boolean;
}

class ExpandableTextBox extends Component<ExpandableTextBoxProps, ExpandableTextBoxState> {
  descriptionRef: RefObject<HTMLParagraphElement>;

  constructor(props: ExpandableTextBoxProps) {
    super(props);

    this.state = {
      descriptionHeight: this.props.maxHeight,
      descriptionScrollHeight: this.props.maxHeight,
      maxDescriptionHeight: this.props.maxHeight,
      isInitHeight: true,
    };

    this.descriptionRef = createRef<HTMLParagraphElement>();
    this.toggleDescription = this.toggleDescription.bind(this);
    this.updateDescriptionScrollHeight = this.updateDescriptionScrollHeight.bind(this);
  }

  componentDidMount(): void {
    this.updateDescriptionScrollHeight();
  }

  componentDidUpdate(prevProps: ExpandableTextBoxProps, prevState: ExpandableTextBoxState) {
    if (prevState.maxDescriptionHeight !== this.props.maxHeight) {
      this.setState({ maxDescriptionHeight: this.props.maxHeight, descriptionHeight: this.props.maxHeight });
    }
  }

  updateDescriptionScrollHeight() {
    this.setState({ descriptionScrollHeight: this.descriptionRef.current.scrollHeight });
  }

  toggleDescription() {
    const newState: Pick<ExpandableTextBoxState, 'descriptionHeight'> = {
      descriptionHeight:
        this.state.descriptionHeight >= this.state.maxDescriptionHeight ? 'auto' : this.state.maxDescriptionHeight,
    };
    return this.state.isInitHeight ? this.setState({ ...newState, isInitHeight: false }) : this.setState(newState);
  }

  render() {
    const {
      className,
      headingContainerClassName,
      headingClassName,
      descriptionContainerClassName,
      descriptionClassName,
      initClassName,
      buttonContainerClassName,
      buttonClassName,
      heading,
      description,
    } = this.props;

    return (
      <div className={classNames(css.ExpandableTextBox, className)}>
        <div className={classNames(css.headingContainer, headingContainerClassName)}>
          <HeaderLevel2 className={classNames(css.heading, headingClassName)}>{heading}</HeaderLevel2>
        </div>
        <HeightAnimation
          duration={500}
          height={this.state.descriptionHeight}
          className={classNames(css.descriptionContainer, descriptionContainerClassName)}
        >
          <ReactResizeDetector handleWidth handleHeight onResize={this.updateDescriptionScrollHeight}>
            <p
              ref={this.descriptionRef}
              className={classNames(css.description, descriptionClassName, this.state.isInitHeight && initClassName)}
            >
              {description}
            </p>
          </ReactResizeDetector>
        </HeightAnimation>
        <div className={classNames(css.buttonContainer, buttonContainerClassName)}>
          {this.state.descriptionScrollHeight > this.state.maxDescriptionHeight && (
            <IconButton
              className={classNames(css.button, buttonClassName)}
              icon={<Icon name={this.state.descriptionHeight === 'auto' ? 'angleUp' : 'angleDown'} />}
              onClick={this.toggleDescription}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ExpandableTextBox;
