import { BasePageState } from '../shared/BasePageState';
import { INavigationService } from '../shared/NavigationService';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreUrlBuilder } from '../shared/StoreUrlBuilder';
import { RelativeUrl } from '../shared/Url';
import { StoreState } from '../StoreState';

export class RedirectState extends BasePageState<null> {
  private navigation: INavigationService;
  public pureUrl: string;

  constructor(navigation: INavigationService, pureUrl: string) {
    super();
    this.navigation = navigation;
    this.pureUrl = pureUrl;
  }

  async onLoad(store: StoreState): Promise<void> {
    const relative = RelativeUrl.parse(this.pureUrl);
    const url = StoreUrlBuilder.fromContext(store.shoppingContext, relative.path, relative.query);
    this.navigation.redirect(url.toString(), StatusCodes.MovedPermanently);
  }
}
