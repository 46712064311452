import { Scene } from '@babylonjs/core/scene';
import { action, makeObservable, observable } from 'mobx';

import { SceneScreenshotMaker } from '../3d/SceneScreenshotMaker';
import { Visualization3dData } from '../data/model';
import ImageZoomModalState from '../shared/components/ImageZoomModal/ImageZoomModalState';
import { IImagePreloader } from '../shared/ImagePreloader';
import { VisualizationShotsState } from '../shared/VisualizationShotsState';
import { IImageBasedVisualizationState } from './IVisualizationState';
import { Visualization3dState } from './Visualization3dState';
import { BaseVisualizationState } from './VisualizationState';

export class VisualizationHybridState
  extends BaseVisualizationState
  implements IImageBasedVisualizationState<Visualization3dData>
{
  public visualization3dState: Visualization3dState;
  private scene: Scene;
  private sceneUpdated: boolean;
  private createScreenshotHighRes = this.createScreenshotWithResolution(2000, 2384);

  @observable
  public hasError: boolean = false;

  constructor(visualization3dState: Visualization3dState, imagePreloader: IImagePreloader) {
    super();
    makeObservable(this);
    this.visualization3dState = visualization3dState;
    this.zoomModal = new ImageZoomModalState(imagePreloader, (shot: string) => this.getImageUrl(shot));
    this.shots = new VisualizationShotsState();
  }

  getResourcesUrls(): string[] {
    return this.visualization3dState.getResourcesUrls();
  }

  initialize(data: Visualization3dData, components: Map<string, string>) {
    this.shots.setShots(data.mapping.shots);

    this.shots.activeShotChanged.subscribe(() => this.updateImages());
    this.zoomModal.shots.activeShotChanged.subscribe((shot: string) => this.shots.setActive(shot));

    this.setComponents(components);
  }

  setComponents(components: Map<string, string>) {
    this.visualization3dState.setComponents(components);
  }

  private getImageUrls() {
    if (this.scene) {
      return Promise.all([this.createScreenshotHighRes(this.scene, this.shots.active, this.sceneUpdated)]);
    }

    return Promise.resolve([]);
  }

  private getImageUrl(shot: string) {
    if (this.scene) {
      return this.createScreenshotHighRes(this.scene, shot, this.sceneUpdated);
    }

    return Promise.reject();
  }

  private async updateImages() {
    const urls = await this.getImageUrls();
    this.setImageUrls(urls);

    this.sceneUpdated = false;
  }

  @action setImageUrls(urls: Array<string>) {
    this.imageUrls = urls;
  }

  @action openZoom() {
    this.zoomModal.setData(this.shots.all, this.shots.active);
    this.zoomModal.open();
  }

  @action setHasError(hasError: boolean) {
    this.hasError = hasError;
  }

  setScene(scene: Scene) {
    this.sceneUpdated = true;
    this.scene = scene;
  }

  handleSceneUpdateSuccess = async (scene: Scene) => {
    this.setScene(scene);
    this.updateImages().then(() => this.setHasError(false));
  };

  handleSceneUpdateError = async (error: string) => {
    this.setHasError(true);
  };

  handleLoadingStart = async () => {
    this.loadingImageIndicator.start();
  };

  handleLoadingEnd = async () => {
    this.loadingImageIndicator.reset();
  };

  private createScreenshotWithResolution(width: number, height: number) {
    let cache: Map<string, string> = new Map();

    return async (scene: Scene, shot: string, clearCache: boolean) => {
      if (clearCache) {
        cache.clear();
      }

      if (!cache.has(shot)) {
        const src = await SceneScreenshotMaker.getScreenshot(scene, shot, width, height);
        cache.set(shot, src);
      }

      return cache.get(shot);
    };
  }
}
