import { action, makeObservable, observable } from 'mobx';

export class WarningStateBase<TArgs = never> {
  @observable args: TArgs;
  @observable visible: boolean;

  constructor() {
    makeObservable(this);
  }

  @action open(args?: TArgs) {
    this.args = args;
    this.visible = true;
  }

  @action close() {
    this.visible = false;
  }
}
