export class KeyboardOnlyFocusListener {
  public static initialize = () => {
    if (typeof document !== 'undefined') {
      document.body.classList.add('noFocus');
      document.body.addEventListener('keydown', KeyboardOnlyFocusListener.handleFirstTab);
    }
  };

  private static handleFirstTab = (e: KeyboardEvent): void => {
    if (e.key === 'Tab') {
      document.body.classList.remove('noFocus');

      document.body.removeEventListener('keydown', KeyboardOnlyFocusListener.handleFirstTab);
      document.body.addEventListener('mousedown', KeyboardOnlyFocusListener.handleMouseClick);
    }
  };

  private static handleMouseClick = (): void => {
    document.body.classList.add('noFocus');

    document.body.removeEventListener('mousedown', KeyboardOnlyFocusListener.handleMouseClick);
    document.body.addEventListener('keydown', KeyboardOnlyFocusListener.handleFirstTab);
  };
}
