import {
  DependencyTelemetry,
  EventTelemetry,
  ExceptionTelemetry,
  IMonitoringService,
  MetricTelemetry,
  PageViewTelemetry,
  TraceTelemetry,
} from './IMonitoringService';

export class Monitoring {
  static services: Array<IMonitoringService> = [];

  static addService(service: IMonitoringService) {
    Monitoring.services.push(service);
  }

  static startTrackPage(): void {
    Monitoring.services.forEach((x) => x.startTrackPage());
  }

  static stopTrackPage(): void {
    Monitoring.services.forEach((x) => x.stopTrackPage());
  }

  static trackPageView(pageView: PageViewTelemetry): void {
    Monitoring.services.forEach((x) => x.trackPageView(pageView));
  }

  static trackDependency(request: DependencyTelemetry): void {
    Monitoring.services.forEach((x) => x.trackDependency(request));
  }

  static trackEvent(event: EventTelemetry): void {
    Monitoring.services.forEach((x) => x.trackEvent(event));
  }

  static trackException(exception: ExceptionTelemetry): void {
    Monitoring.services.forEach((x) => x.trackException(exception));
  }

  static trackMetric(metric: MetricTelemetry): void {
    Monitoring.services.forEach((x) => x.trackMetric(metric));
  }

  static trackTrace(trace: TraceTelemetry): void {
    Monitoring.services.forEach((x) => x.trackTrace(trace));
  }
}
