import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

import capitalizeFirstLetter from '../../capitalizeFirstLetter';
import css from './Table.css';

type tableCellAlignment = 'left' | 'center' | 'right';

export interface ITableRowData {
  headerName: ReactNode;
  headerComponent?: ReactNode;
  value: ReactNode;
  className?: string;
  alignment?: tableCellAlignment;
}

export interface ITableHeaderCell {
  name: string;
  component?: ReactNode;
  className?: string;
  alignment?: tableCellAlignment;
}

export interface ITableHeaderProps {
  theadClassName?: string;
  thClassName?: string;
  data: Array<ITableHeaderCell>;
}

export interface ITableBodyProps {
  tbodyClassName?: string;
  trClassName?: string;
  tdClassName?: string;
  data: Array<Array<ITableRowData>>;
}

interface TableProps {
  className?: string;
  tableHeader: ITableHeaderProps;
  tableBody: ITableBodyProps;
}

export const Table: React.FunctionComponent<TableProps> = (props: TableProps) => {
  const { className, tableHeader, tableBody } = props;

  return (
    <table className={classNames(css.Table, className)}>
      <TableHeader tableHeader={tableHeader} />
      <TableBody tableBody={tableBody} />
    </table>
  );
};

const TableHeader = ({ tableHeader }: { tableHeader: ITableHeaderProps }) => (
  <thead className={classNames(tableHeader.theadClassName)}>
    <tr>
      {tableHeader.data.map((header, key) => {
        const alignmentClass =
          header.alignment && css[`alignment${capitalizeFirstLetter(header.alignment)}` as keyof typeof css];
        const thClasses = classNames(css.th, tableHeader.thClassName, alignmentClass, header.className);

        return (
          <th key={key} className={thClasses}>
            {header.component ? header.component : header.name}
          </th>
        );
      })}
    </tr>
  </thead>
);

const TableBody = ({ tableBody }: { tableBody: ITableBodyProps }) => (
  <tbody className={classNames(tableBody.tbodyClassName)}>
    {tableBody.data.map((row, key) => (
      <tr key={key} className={classNames(css.tr, tableBody.trClassName)}>
        {row.map((td, rowKey) => {
          const alignmentClass =
            td.alignment && css[`alignment${capitalizeFirstLetter(td.alignment)}` as keyof typeof css];
          const tdClasses = classNames(css.td, tableBody.tdClassName, alignmentClass, td.className);

          return (
            <td key={rowKey} className={tdClasses} data-label={td.headerName}>
              {td.value}
            </td>
          );
        })}
      </tr>
    ))}
  </tbody>
);
