import { AppInsights } from 'applicationinsights-js';

import { ILogAppender } from '../Logger';
import includeAdditionalProperties from './includeAdditionalProperties';

// For some reason enum defined in imported module is not defined
enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}

export class AppInsightsLogAppender implements ILogAppender {
  log(message?: string): void {
    AppInsights.trackTrace(message, includeAdditionalProperties({}), SeverityLevel.Verbose);
  }

  warn(message?: string): void {
    AppInsights.trackTrace(message, includeAdditionalProperties({}), SeverityLevel.Warning);
  }

  error(message?: string): void {
    AppInsights.trackTrace(message, includeAdditionalProperties({}), SeverityLevel.Error);
  }

  exception(message?: string, exception?: Error): void {
    AppInsights.trackException(exception, null, includeAdditionalProperties({ message: message }));
  }
}
