import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import css from './InputWithLoader.css';

export interface InputLoaderProps {
  isLoading?: boolean;
  loaderClassName?: string;
  loaderContainerClassName?: string;
}

export interface InputWithLoaderProps extends InputLoaderProps {
  children: ReactNode;
}

const InputWithLoader = (props: InputWithLoaderProps) => {
  const { isLoading, loaderClassName, loaderContainerClassName, children } = props;
  return (
    <div className={classNames(css.InputWithLoader, loaderContainerClassName)}>
      {children}
      {isLoading && <ThreeDotsLoader className={classNames(css.loader, loaderClassName)} size={'small'} />}
    </div>
  );
};

export default observer(InputWithLoader);
