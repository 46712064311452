import { MutableRefObject } from 'react';

import Fullscreen from '../Fullscreen';

export interface FullscreenInterface {
  close: () => Promise<void>;
  isActive: boolean;
  open: () => Promise<void>;
}

function useFullscreen<T extends HTMLElement>(element: MutableRefObject<T>): FullscreenInterface {
  const isActive = Fullscreen.isActive;

  const open = () => {
    if (!element.current) {
      return;
    }

    return Fullscreen.open(element.current);
  };

  const close = () => {
    return Fullscreen.close();
  };

  return { isActive, open, close };
}

export default useFullscreen;
