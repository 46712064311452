import { observer } from 'mobx-react';

import ActionMenu from '../../app/shared/components/ActionMenu/ActionMenu';
import { CartItemActionsTranslation } from '../localization/SiteTranslation';
import { CartItemContextCommand } from './CartItem/CartItemState';

interface CartItemActionsProps {
  items: Array<CartItemContextCommand>;
  translation: CartItemActionsTranslation;
}

const CartItemActions = ({ items, translation }: CartItemActionsProps) => {
  const isLoading = items.any((x) => x.command.processing);
  const actions = items.map((x) => ({ label: x.label, action: () => x.command.invoke() }));

  return <ActionMenu isLoading={isLoading} items={actions} translation={translation.actionMenu} />;
};

export default observer(CartItemActions);
