import { computed, makeObservable, observable } from 'mobx';

import { OrderData, PaymentData, PaymentParameterData, ShoppingContext, StoreContext } from '../../data/model';
import { CheckoutPageTranslation } from '../../localization/SiteTranslation';
import { IValidatable } from '../../shared/common';
import Event from '../../shared/Event';

export interface PaymentWidgetProps<T extends IPaymentMethod = IPaymentMethod> {
  paymentMethod: T;
  translation: CheckoutPageTranslation;
}

export interface IPaymentMethod extends IValidatable {
  showAuthorizeButton: boolean;
  code: string;
  isInitialized: boolean;

  getInitialParameters(shoppingContext: ShoppingContext, storeContext: StoreContext): PaymentParameterData[];

  initialize(order: OrderData, payment: PaymentData): Promise<void>;

  authorize(): Promise<void>;

  authorizationCompleted: Event<AuthorizationCompletedEventArgs>;

  dispose(): void;
}

export abstract class BasePaymentMethod implements IPaymentMethod {
  public code: string;
  public showAuthorizeButton: boolean = false;

  public authorizationCompleted: Event<AuthorizationCompletedEventArgs>;

  @observable
  public isInitialized: boolean = false;

  constructor(code: string) {
    makeObservable(this);
    this.code = code;
    this.authorizationCompleted = new Event<AuthorizationCompletedEventArgs>();
  }

  getInitialParameters(_: ShoppingContext): PaymentParameterData[] {
    return [];
  }

  abstract initialize(order: OrderData, payment: PaymentData): Promise<void>;

  abstract authorize(): Promise<void>;

  public dispose() {
    // Nothing here
  }

  @computed get isValid(): boolean {
    return true;
  }

  validate(): void {
    // Nothing here
  }

  resetValidation(): void {
    // Nothing here
  }
}

export interface AuthorizationCompletedEventArgs {
  parameters: Array<PaymentParameterData>;
}

export interface PaymentCompletedEventArgs {
  paymentOption?: string;
  redirectUrl?: string;
}
