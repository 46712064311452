import { ImageData } from '../../../../data/model';
import { SupportedCreditCardsMessageTranslation } from '../../../../localization/SiteTranslation';
import css from './SupportedCreditCardsMessage.css';

interface SupportedCreditCardsMessageProps {
  image: ImageData;
  translation: SupportedCreditCardsMessageTranslation;
}

const SupportedCreditCardsMessage = ({ image, translation }: SupportedCreditCardsMessageProps) => {
  return image.url ? (
    <p className={css.container}>
      <span className={css.text}>{translation.acceptedCreditCardsText}</span>
      <img className={css.image} src={image.url} alt={image.name} />
    </p>
  ) : null;
};

export default SupportedCreditCardsMessage;
