import AppState from '../../AppState';
import { RegionDataContext } from './RegionDataContext';

export interface RegionDefinition {
  name: string;
  registeredViews: ViewRegistration[];
}

export interface ViewRegistration {
  stateProvider: (context: unknown, appState: AppState) => unknown;
  viewType: React.ComponentType<{ state: unknown }>;
}

export class RegionManager {
  constructor(private appState: AppState) {}

  private regions = new Map<string, RegionDefinition>();

  public registerViewWithRegion<
    TRegionContext extends RegionDataContext,
    TState,
    TView extends React.ComponentType<{ state: TState }>,
  >(
    regionName: TRegionContext['name'],
    stateProvider: (context: TRegionContext['data'], appState: AppState) => TState,
    viewType: TView,
  ) {
    let region = this.regions.get(regionName);

    if (!region) {
      region = {
        name: regionName,
        registeredViews: [],
      };

      this.regions.set(region.name, region);
    }

    region.registeredViews.push({ stateProvider: stateProvider, viewType: viewType });
  }

  public getViews<TRegionContext extends RegionDataContext>(
    regionName: RegionDataContext['name'],
    dataContext: TRegionContext['data'],
  ): Array<{ state: unknown; viewType: React.ComponentType<{ state: unknown }> }> {
    const region = this.regions.get(regionName);
    if (!region) {
      return [];
    }

    return region.registeredViews.map((x) => {
      return {
        state: x.stateProvider(dataContext, this.appState),
        viewType: x.viewType,
      };
    });
  }
}
