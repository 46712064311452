import { action, computed, makeObservable } from 'mobx';

import { OptionDescriptionModalState } from '../../../../../../app/configurator/components/OptionDescriptionModal/OptionDescriptionModalState';
import { IApiClient } from '../../../../../../app/data/client';
import { GetFabricDescriptionQuery, ShoppingContext, WebShopOptionGroupData } from '../../../../../../app/data/model';
import { ProductOptionsTranslation } from '../../../../../../app/localization/SiteTranslation';
import Logger from '../../../../../../app/shared/Logger';
import ProductOptionState from './ProductOptionState';

class ProductOptionsState {
  public readonly descriptionModal = new OptionDescriptionModalState();

  public readonly items: ProductOptionState[];

  public get count() {
    return this.items.length;
  }

  @computed
  public get selectedFabric() {
    return this.items.find((x) => x.isFabric === true)?.activeVariation;
  }

  public constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: ProductOptionsTranslation,
    options: WebShopOptionGroupData[],
  ) {
    makeObservable(this);

    this.items = options.map((option) => new ProductOptionState(option));
  }

  @action.bound
  public async openDescriptionModal() {
    const fabric = this.selectedFabric;

    if (!fabric) {
      return;
    }

    try {
      this.descriptionModal.startLoading();
      this.descriptionModal.open();

      const query = new GetFabricDescriptionQuery({
        fabricCode: fabric.code,
        shoppingContext: this.shoppingContext,
      });
      const response = await this.client.send(query);

      this.descriptionModal.setFabricCollectionData(fabric.familyName, response.description);
    } catch (error) {
      Logger.exception(`An error occurred while fetching information for the fabric = ${fabric.familyName}`, error);
      this.descriptionModal.setFabricCollectionData(
        fabric.familyName,
        this.translation.fabricDescriptionCannotBeLoadedError,
      );
    } finally {
      this.descriptionModal.stopLoading();
    }
  }
}

export default ProductOptionsState;
