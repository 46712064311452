import classNames from 'classnames';

import { OrderStatusHistoryEntryData } from '../../../app/data/model';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import css from './OrderProgressIndicator.css';

export const OrderProgressIndicator = ({
  history,
  className,
}: {
  history: OrderStatusHistoryEntryData[];
  className?: string;
}) => {
  const renderOrderProcessItem = (entry: OrderStatusHistoryEntryData) => (
    <OrderProgressItem
      key={entry.status}
      name={entry.status}
      isActive={entry.isActive}
      isCompleted={!!entry.dateTime}
      isCancelled={entry.status === 'Cancelled'}
      date={entry.dateTime ? formatISODateStringToDottedDate(entry.dateTime) : entry.dateTime}
    />
  );

  return (
    <div className={classNames(css.OrderProgressIndicator, className)}>
      {history.map((entry: OrderStatusHistoryEntryData) => renderOrderProcessItem(entry))}
    </div>
  );
};

interface OrderProgressItemProps {
  name: string;
  date?: string;
  isActive: boolean;
  isCompleted: boolean;
  isCancelled?: boolean;
}

const OrderProgressItem = ({ name, date, isActive, isCompleted, isCancelled }: OrderProgressItemProps) => {
  const classes = classNames(
    css.OrderProgressIndicatorItem,
    isCompleted && css.completed,
    isActive && css.active,
    isCancelled && css.cancelled,
  );
  return (
    <div className={classes}>
      <div>{name}</div>
      {date && <div className={css.orderProgressItemDate}>{date}</div>}
    </div>
  );
};
