import { useMemo } from 'react';

import { ProductDetailsSectionTranslation } from '../../../../../app/localization/SiteTranslation';
import AssetsDownloader from '../../../../../app/shared/components/AssetsDownloader/AssetsDownloader';
import { AssetsDownloaderState } from '../../../../../app/shared/components/AssetsDownloader/AssetsDownloaderState';
import ProductSpecification from '../../../../shared/components/ProductSpecification/ProductSpecification';
import { TabbedInterfaceItem } from '../../../../shared/components/TabbedInterface/TabbedInterface';
import Tabs from '../../../../shared/components/Tabs/Tabs';
import useComponentInstanceCreator from '../../../../shared/hooks/useComponentInstanceCreator';
import { ProductCareSectionProps } from './ProductCare/ProductCareSection/ProductCareSection';
import css from './ProductDetailsSection.css';

interface ProductDetailsSection {
  assetsDownloaderState: AssetsDownloaderState;
  guideUrl: string;
  id: string;
  maintenance: ProductCareSectionProps[];
  specification: string;
  translation: ProductDetailsSectionTranslation;
}

const ProductDetailsSection = ({
  assetsDownloaderState,
  guideUrl,
  id,
  maintenance,
  specification,
  translation,
}: ProductDetailsSection) => {
  /*
   * The AssetsDownloader component is recreated when the assetsDownloaderInstanceKey changes in order to close all expanded Accordion components inside it.
   * An accordion component uses a fully uncontrolled ExpandablePanel component so it's not possible to change its internal state outside a component.
   * */
  const [assetsDownloaderInstanceKey, resetAssetsDownloaderInstanceKey] = useComponentInstanceCreator();

  const tabs = useMemo<TabbedInterfaceItem[]>(() => {
    const result = [];

    if (specification) {
      result.push({
        name: translation.specificationSectionTitle,
        content: <ProductSpecification description={specification} />,
      });
    }

    // TODO: we don't have content yet. Maybe this section can be unified with product specification?
    // result.push(
    //   {
    //     name: translation.maintenanceSectionTitle,
    //     content: <ProductCare guideUrl={guideUrl} sections={maintenance} translation={translation.productCare} />,
    //   },
    // );

    if (assetsDownloaderState.categories.any()) {
      result.push({
        name: translation.downloadsSectionTitle,
        content: (
          <AssetsDownloader
            state={assetsDownloaderState}
            translation={translation.assetsDownloader}
            key={assetsDownloaderInstanceKey}
          />
        ),
        onClose: resetAssetsDownloaderInstanceKey,
      });
    }

    return result;
  }, [
    assetsDownloaderInstanceKey,
    assetsDownloaderState,
    guideUrl,
    maintenance,
    resetAssetsDownloaderInstanceKey,
    specification,
    translation,
  ]);

  return (
    <div className={css.container}>
      <Tabs id={`product-details-${id}`} tabs={tabs} />
    </div>
  );
};

export default ProductDetailsSection;
