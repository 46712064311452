import css from './ProductEnvironmentalDataEntryAdditionaInfo.css';

interface ProductEnvironmentalDataEntryAdditionalInfoProps {
  items: { title: string; value: string }[];
}

const ProductEnvironmentalDataEntryAdditionalInfo = ({ items }: ProductEnvironmentalDataEntryAdditionalInfoProps) => {
  return (
    <dl className={css.container}>
      {items.map(({ title, value }) => (
        <div key={title}>
          <dt className={css.title}>{title}</dt>
          <dd className={css.value}>{value}</dd>
        </div>
      ))}
    </dl>
  );
};

export default ProductEnvironmentalDataEntryAdditionalInfo;
