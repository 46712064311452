import classNames from 'classnames';
import { observer } from 'mobx-react';

import NavButton from '../shared/components/Button/NavButton';
import HeaderLevel1 from '../shared/components/Typography/HeaderLevel1';
import { HubspotFormPage } from '../shared/hubSpot/HubspotFormPage';
import ProductSummary from '../shared/hubSpot/ProductSummary/ProductSummary';
import ProductPromotionInfo from './ProductPromotionInfo/ProductPromotionInfo';
import { RequestQuoteConfirmation } from './RequestQuoteConfirmation';
import css from './RequestQuotePage.css';
import { RequestQuoteState } from './RequestQuoteState';
import RequestQuoteSteps from './RequestQuoteSteps/RequestQuoteSteps';

@observer
class RequestQuotePage extends HubspotFormPage<RequestQuoteState> {
  componentDidMount() {
    if (this.props.appState.store.canRequestQuote()) {
      super.componentDidMount();
    }
  }

  onFormSubmit(formElement: Element) {
    // noinspection JSIgnoredPromiseFromCall
    this.onFormSubmitAsync(formElement);
  }

  async onFormSubmitAsync(formElement: Element) {
    const setInputValue = (name: string, value: string) => {
      const field: HTMLInputElement = formElement.querySelector(`input[name="${name}"]`);
      field.value = value;
    };

    if (this.props.state.hasConfigurationCode()) {
      const code = this.props.state.getCode();
      const url = this.props.state.getShopUrl();

      const promotionInfo = this.props.state.promotionResponse?.isPromoted
        ? `(${this.props.state.promotionResponse.promotionName})`
        : '';

      setInputValue('latest_configuration_id', `${code} ${promotionInfo}`);
      setInputValue('latest_configuration_url', url);
    } else {
      setInputValue('latest_configuration_id', 'na');
      setInputValue('latest_configuration_url', 'na');
    }

    setInputValue('latest_configuration_product_id', this.props.state.product.id);
    setInputValue('latest_configuration_brand', this.props.state.product.brandId);
    setInputValue('price_quote_request', true.toString());
  }

  getTranslation() {
    const {
      state,
      appState: {
        translation: { requestQuotePage },
      },
    } = this.props;

    if (state.isRequestProcessedByDealer) {
      return requestQuotePage.contentWhenProcessedByDealer;
    }

    return requestQuotePage.defaultContent;
  }

  renderMainContent() {
    const {
      appState: { translation },
      state,
    } = this.props;

    return (
      <div className={css.container}>
        <div className={css.formContainer} id="hsRequestQuoteForm">
          {super.render()}
        </div>
        <ProductSummary
          shoppingCartClassName={css.shoppingCartContainer}
          productPreviewClassName={css.productDetailsPreviewContainer}
          state={state}
          translation={translation.cart.summary}
        />
      </div>
    );
  }

  renderSteps() {
    const translation = this.getTranslation();
    return <RequestQuoteSteps className={css.steps} translation={translation} />;
  }

  render() {
    const {
      appState: { navigationState },
      state,
    } = this.props;
    const translation = this.getTranslation();

    if (state.isFormSubmitted) {
      return (
        <RequestQuoteConfirmation
          isEmbedded={state.isEmbedded}
          navigation={navigationState}
          translation={translation.confirmation}
        />
      );
    }

    return (
      <div className={css.page}>
        {!state.isEmbedded && (
          <NavButton className={css.navigation} onClick={state.backToSummary} back>
            {translation.goBackLink}
          </NavButton>
        )}
        <HeaderLevel1 className={classNames('visuallyHidden', css.title)} centered>
          {translation.title}
        </HeaderLevel1>
        <ProductPromotionInfo promotionDescription={state.promotionDescription} />

        {state.isRequestProcessedByDealer ? (
          <>
            {this.renderSteps()}
            {this.renderMainContent()}
          </>
        ) : (
          <>
            {this.renderMainContent()}
            {this.renderSteps()}
          </>
        )}
      </div>
    );
  }
}

export default RequestQuotePage;
