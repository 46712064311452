import { action, makeObservable, observable } from 'mobx';

import { IImagePreloader } from '../../ImagePreloader';
import { LoadingIndicator } from '../../LoadingIndicator';
import { ModalState } from '../../ModalState';
import { RunLastTasksScheduler } from '../../TasksScheduler';
import { UpdateVisualizationImagesTask } from '../../UpdateVisualizationImagesTask';
import { VisualizationShotsState } from '../../VisualizationShotsState';

export interface Size {
  // TODO: get rid of this interface when changes with windows size are merged
  width: number;
  height: number;
}

export default class ImageZoomModalState extends ModalState {
  private imagePreloader: IImagePreloader;
  private imageUrlResolver: (shot: string, width: number, height: number) => Promise<string>;

  loadingImageIndicator: LoadingIndicator = new LoadingIndicator();
  shots: VisualizationShotsState;

  @observable
  smallImageUrl: string;

  @observable
  largeImageUrl: string;

  protected resolutions: Array<Size> = [
    { width: 965, height: 1192 },
    { width: 2000, height: 2384 },
  ];

  private updateVisualizationScheduler = new RunLastTasksScheduler((task: UpdateVisualizationImagesTask) =>
    this.preloadAndUpdateZoomImages(task),
  );

  constructor(
    imagePreloader: IImagePreloader,
    imageUrlResolver: (shot: string, width: number, height: number) => Promise<string>,
  ) {
    super();

    makeObservable(this);

    this.imageUrlResolver = imageUrlResolver;
    this.imagePreloader = imagePreloader;
    this.shots = new VisualizationShotsState();
    this.shots.activeShotChanged.subscribe(() => this.onActiveShotChanged());
  }

  onAfterOpen() {
    this.updateImages();
  }

  private onActiveShotChanged() {
    if (this.visible) {
      this.updateImages();
    }
  }

  @action
  setSmallImageUrl(url: string) {
    this.smallImageUrl = url;
  }

  @action
  setLargeImageUrl(url: string) {
    this.largeImageUrl = url;
  }

  private async preloadAndUpdateZoomImages(task: UpdateVisualizationImagesTask): Promise<void> {
    if (task.urls.length !== 2) {
      throw Error('There should be exactly two images, small and large.');
    }

    this.loadingImageIndicator.start();
    // Preload sequentially to not do the same job twice
    await this.imagePreloader.load([task.urls[0]]);
    await this.imagePreloader.load([task.urls[1]]);
    if (task.cancelled) {
      return;
    }
    this.setSmallImageUrl(task.urls[0]);
    this.setLargeImageUrl(task.urls[1]);
    this.loadingImageIndicator.reset();
  }

  @action
  async setData(shots: string[], activeShot: string) {
    this.shots.setShots(shots);
    this.shots.setActive(activeShot);
  }

  getImagesUrls(shot: string) {
    return Promise.all(this.resolutions.map((x) => this.imageUrlResolver(shot, x.width, x.height)));
  }

  @action
  private async updateImages() {
    const urls = await this.getImagesUrls(this.shots.active);
    await this.updateVisualizationScheduler.run(new UpdateVisualizationImagesTask(urls));
  }
}
