import { action, makeObservable, observable } from 'mobx';

import { PhysicalStoreData } from '../../../data/model';
import { StoreLocatorPageTranslation } from '../../../localization/SiteTranslation';
import Event from '../../../shared/Event';

export enum LocationButtonState {
  Clickable = 'Clickable',
  Loading = 'Loading',
  Disabled = 'Disabled',
  Unavailable = 'Unavailable',
}

export enum PositionError {
  PermissionDenied = 1,
  PositionUnavailable,
  Timeout,
}

export default class StoresListState {
  translation: StoreLocatorPageTranslation;
  public onStoreSelected: Event<PhysicalStoreData>;

  @observable
  isGoogleMapApiInitialized: boolean;

  @observable
  stores: Array<PhysicalStoreData>;

  constructor(translation: StoreLocatorPageTranslation) {
    makeObservable(this);
    this.onStoreSelected = new Event<PhysicalStoreData>();
    this.translation = translation;
    this.stores = [];
    this.isGoogleMapApiInitialized = false;
  }

  @action
  setGoogleMapApiInitialization() {
    this.isGoogleMapApiInitialized = true;
  }

  @action
  setStores(items: Array<PhysicalStoreData>) {
    this.stores = items;
  }
}
