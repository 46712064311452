import {
  CartEventData,
  CheckoutEventData,
  CheckoutOptionEventData,
  ProductData,
  ProductEventData,
  PurchaseEventData,
} from '../TrackingService';
import {
  GtmEcommerceAction,
  GtmEcommerceActionName,
  GtmEnhancedCommerceEvent,
  GtmImpression,
  GtmProduct,
} from './types/Gtm';
import GtmEcommerceEventName from './types/GtmEcommerceEventName';

type GtmEcommerceActionData =
  | { type: GtmEcommerceActionName.Add; data: CartEventData }
  | { type: GtmEcommerceActionName.Checkout; data: CheckoutEventData }
  | { type: GtmEcommerceActionName.CheckoutOption; data: CheckoutOptionEventData }
  | { type: GtmEcommerceActionName.Click; data: ProductEventData }
  | { type: GtmEcommerceActionName.Detail; data: ProductEventData }
  | { type: GtmEcommerceActionName.Purchase; data: PurchaseEventData }
  | { type: GtmEcommerceActionName.Remove; data: CartEventData };

export class GtmEcommerceEventBuilder {
  private action?: GtmEcommerceAction;
  private currencyCode?: string;
  private impressions?: GtmImpression[];

  public build(eventName: GtmEcommerceEventName): GtmEnhancedCommerceEvent {
    return {
      event: eventName,
      ecommerce: {
        currencyCode: this.currencyCode,
        ...this.action,
        impressions: this.impressions,
      },
    };
  }

  public withImpressions(products: ProductData[]) {
    const impressions = products.map(GtmEcommerceEventBuilder.buildGtmImpression);

    if (this.impressions) {
      this.impressions = [...this.impressions, ...impressions];
    } else {
      this.impressions = impressions;
    }

    return this;
  }

  public setAction(action: GtmEcommerceActionData) {
    switch (action.type) {
      case GtmEcommerceActionName.Add: {
        this.action = {
          [action.type]: {
            actionField: {
              list: action.data.source,
            },
            products: action.data.products.map(GtmEcommerceEventBuilder.buildGtmProduct),
          },
        };
        break;
      }
      case GtmEcommerceActionName.Click:
        this.action = {
          [action.type]: {
            products: [GtmEcommerceEventBuilder.buildGtmProduct(action.data.product)],
          },
        };
        break;
      case GtmEcommerceActionName.Checkout:
        this.action = {
          [action.type]: {
            actionField: {
              step: action.data.stepNumber,
              option: action.data.option,
            },
            products: action.data.products?.map(GtmEcommerceEventBuilder.buildGtmProduct),
          },
        };
        break;
      case GtmEcommerceActionName.CheckoutOption:
        this.action = {
          [action.type]: {
            actionField: {
              step: action.data.stepNumber,
              option: action.data.option,
            },
          },
        };
        break;
      case GtmEcommerceActionName.Detail:
        this.action = {
          [action.type]: {
            products: [GtmEcommerceEventBuilder.buildGtmProduct(action.data.product)],
          },
        };
        break;
      case GtmEcommerceActionName.Purchase:
        this.action = {
          [action.type]: {
            actionField: {
              id: action.data.orderNumber,
              coupon: action.data.coupon,
              revenue: action.data.revenue,
              shipping: action.data.shipping,
              tax: action.data.tax,
            },
            products: action.data.products.map(GtmEcommerceEventBuilder.buildGtmProduct),
          },
        };
        break;
      case GtmEcommerceActionName.Remove:
        this.action = {
          [action.type]: {
            actionField: {
              list: action.data.source,
            },
            products: action.data.products.map(GtmEcommerceEventBuilder.buildGtmProduct),
          },
        };
        break;
      default:
        this.action = undefined;
    }

    return this;
  }

  public setCurrencyCode(code: string) {
    this.currencyCode = code;
    return this;
  }

  private static buildGtmProduct(product: ProductData): GtmProduct {
    return {
      id: product.id,
      name: product.name,
      brand: product.brand,
      category: product.category,
      price: product.price,
      position: product.position,
      variant: product.configurationCode,
      quantity: product.quantity,
    };
  }

  private static buildGtmImpression(product: ProductData): GtmImpression {
    return {
      id: product.id,
      name: product.name,
      brand: product.brand,
      category: product.category,
      list: product.list,
      position: product.position,
      price: product.price,
      variant: product.configurationCode,
    };
  }
}
