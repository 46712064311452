import classNames from 'classnames';

import { SimpleMenuItem } from '../../../data/model';
import Link from '../../../shared/components/Link';
import List from '../../../shared/components/List/List';
import StoreAndLanguageSelector from '../../StoreAndLanguageSelector/StoreAndLanguageSelector';
import StoreAndLanguageSelectorState from '../../StoreAndLanguageSelector/StoreAndLanguageSelectorState';
import css from './GeneralHeaderTopBar.css';

interface GeneralHeaderProps {
  menuItems: SimpleMenuItem[];
  storeAndLanguageSelectorState: StoreAndLanguageSelectorState;
}

const GeneralHeaderTopBar = ({ menuItems, storeAndLanguageSelectorState }: GeneralHeaderProps) => {
  const renderMenuItem = (item: SimpleMenuItem) => {
    return (
      <Link className={classNames(css.link, css.navLink)} href={item.url} target={item.target}>
        {item.name}
      </Link>
    );
  };

  return (
    <nav className={css.container}>
      <List className={css.navItemList} itemClassName={css.navItem} items={menuItems} itemRenderer={renderMenuItem} />

      <div className={css.secondaryNavItems}>
        <StoreAndLanguageSelector state={storeAndLanguageSelectorState} />

        {/*
         * TODO: Uncomment when the shopping cart is ready
         * <Cart className={css.cart} translation={translation.cartTranslation} />
         */}
      </div>
    </nav>
  );
};

export default GeneralHeaderTopBar;
