import throttle from 'lodash.throttle';
import * as React from 'react';
import { Fragment } from 'react';

import WindowScrollObserver from '../../../layout/WindowScrollObserver';
import { ScrollToAnimation } from '../../animations';
import { ScreenViewport } from '../../ScreenViewport';
import MenuSection, { MenuSectionProps } from './MenuSection';

interface IStickyMenu {
  menuComponent?: JSX.Element;
  children: React.ReactComponentElement<typeof MenuSection>[];
}

export default class StickyMenu extends React.Component<IStickyMenu, { activeItemId: string }> {
  items: Array<MenuSectionProps>;
  sections: Array<Array<MenuSectionProps>>;

  constructor(props: IStickyMenu) {
    super(props);

    this.scrollToSection = this.scrollToSection.bind(this);
    this.getMenuItems = this.getMenuItems.bind(this);
    this.onScrollChange = throttle(this.onScrollChange.bind(this), 50);

    this.items = this.getMenuItems();
    this.state = { activeItemId: this.items[0] ? this.items[0].id : '' };
  }

  componentDidMount(): void {
    WindowScrollObserver.onScrollChange.subscribe(this.onScrollChange);
  }

  componentWillUnmount(): void {
    WindowScrollObserver.onScrollChange.unsubscribe(this.onScrollChange);
  }

  getMenuItems(): Array<MenuSectionProps> {
    const children = this.props.children
      .filter((section) => section && !section.props.hide)
      .map((section) => ({
        name: section.props.name,
        id: section.props.id,
        sectionId: section.props.name ? section.props.id : null,
      }));

    this.assignItemsWithoutNameToPreviousItem(children);
    return children;
  }

  private assignItemsWithoutNameToPreviousItem(children: { sectionId: string }[]) {
    children.forEach((x, i, array) => {
      if (x.sectionId === null && i > 0) {
        x.sectionId = array[i - 1].sectionId;
      }
    });
  }

  onScrollChange() {
    const toHighlight = this.items.find((item) => ScreenViewport.isElementBottomVisible(item.id, 80));

    if (toHighlight) {
      this.setState({ activeItemId: toHighlight.sectionId });
    }
  }

  scrollToSection(activeItem: MenuSectionProps) {
    return new ScrollToAnimation(`[id='${activeItem.id}']`, true).start();
  }

  render() {
    const namedItems = this.items.filter((x) => x.name);

    return (
      <Fragment>
        {React.cloneElement(this.props.menuComponent, {
          items: namedItems,
          activeItemId: this.state.activeItemId,
          handleOnClick: this.scrollToSection,
        })}
        {this.props.children}
      </Fragment>
    );
  }
}
