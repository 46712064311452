import { action, computed, makeObservable } from 'mobx';

import {
  EnabledByOperator,
  MoneyData,
  OptionData,
  OptionType,
  OptionVariationData,
  PriceGroupData,
} from '../data/model';
import {
  DefaultDependentOptionPreselectionStrategy,
  EnableableOption,
  getEmptyMoneyData,
  IDependentOptionPreselectionStrategy,
  IFeature,
  IItem,
  IPriceGroupRelated,
  Item,
} from '../shared/common';
import { IRestrictionsInitializer } from '../shared/restrictions';
import { OptionDescriptionModalState } from './components/OptionDescriptionModal/OptionDescriptionModalState';

export interface IOptionState extends IFeature {
  data: OptionData;

  name: string;
  code: string;
  type: OptionType;
  selected: OptionVariationState;
  tabCode: string;

  trySelectUsingId(id: string): boolean;

  enabled: boolean;

  setEnablingItems(items: Array<IItem>, enabledByOperator: EnabledByOperator): void;
}

export class OptionVariationState extends Item {
  constructor(
    public readonly data: OptionVariationData,
    public readonly feature: IFeature,
    restrictionsInitializer: IRestrictionsInitializer,
    public readonly descriptionModal: OptionDescriptionModalState,
    public readonly priceGroup: PriceGroupData = null,
  ) {
    super();

    makeObservable(this);

    this.id = data.id;
    this.code = data.code;
    this.displayCode = data.code;
    this.fullName = data.fullName;
    this.name = data.name;
    this.image = data.image;
    this.restrictions = data.restrictions;

    this.highlighted = false;
    this.selected = false;

    this.restrictionsResolver = restrictionsInitializer.registerRestrictions({
      feature: feature,
      item: this,
      canBeDeselected: false,
    });
  }

  @computed get price(): MoneyData {
    if (this.priceGroup) {
      return {
        amount: this.data.price.amount + this.priceGroup.price.amount,
        currency: this.data.price.currency,
      };
    }
    return this.data.price;
  }

  openDescriptionModal() {
    this.descriptionModal.setOptionData(this.data.name, this.data.description, this.data.image);
    this.descriptionModal.open();
  }
}

export interface SelectedOptionVariation {
  option: IOptionState;
  variation: OptionVariationState;
}

export class OptionState extends EnableableOption implements IOptionState, IFeature, IPriceGroupRelated {
  data: OptionData;
  id: string;
  name: string;
  code: string;
  type: OptionType;
  tabCode: string;
  index: number;

  variations: Array<OptionVariationState>;

  @computed get price() {
    /*
     * TODO: in the future option might also be connected to price group feature and determines it.
     *  This will have to be handled here as well. See FabricOptionState.
     * */
    return this.selected?.price ?? getEmptyMoneyData();
  }

  @computed get selected() {
    return this.variations.find((x) => x.selected);
  }

  constructor(
    option: OptionData,
    restrictionInitializer: IRestrictionsInitializer,
    descriptionModal: OptionDescriptionModalState,
  ) {
    super();
    makeObservable(this);
    this.data = option;
    this.id = option.id;
    this.name = option.name;
    this.code = option.code;
    this.type = option.type;
    this.type = option.type;
    this.tabCode = option.tabCode;
    this.index = option.index;
    this.variations = option.groups.mapMany((group) =>
      group.variations.map(
        (x) =>
          new OptionVariationState(
            x,
            this,
            restrictionInitializer,
            descriptionModal,
            option.determinesPriceGroup
              ? option.priceGroups.find((priceGroup) => priceGroup.number === group.priceGroup)
              : null,
          ),
      ),
    );
    this.trySelectUsingId(option.defaultVariationId);
  }

  trySelectUsingId(id: string): boolean {
    let variation = this.variations.find((x) => x.data.id === id);
    if (variation) {
      this.select(variation);
    }

    return !!variation;
  }

  getPriceDifference(variation: OptionVariationState): MoneyData {
    return {
      amount: variation.priceIncludingDependentFeatures.amount - this.selected.priceIncludingDependentFeatures.amount,
      currency: this.selected.price.currency,
    };
  }

  getItemPrice(item: IItem): MoneyData {
    return item.price;
  }

  @action select(variation: OptionVariationState) {
    this.variations.forEach((x) => x.deselect());
    variation.select();
  }

  @action checkRestrictionsAndSelect(variation: OptionVariationState) {
    variation.restrictionsResolver.checkRestrictionsAndSelect(variation);
  }

  // IFeature interface implementation

  getItemPriceDifference(variation: OptionVariationState): MoneyData {
    return this.getPriceDifference(variation);
  }

  @action selectItem(variation: OptionVariationState) {
    this.select(variation);
  }

  @action deselectItem(variation: OptionVariationState) {
    throw Error('Not supported');
  }

  formatItemName(variation: OptionVariationState): string {
    return variation.name.startsWith(this.name) ? variation.name : `${this.name} ${variation.name}`;
  }

  getAllItems() {
    return this.variations;
  }

  getSelectedItem(): IItem {
    return this.selected;
  }

  selectPriceGroup(_priceGroupNumber: string) {
    // Not supported yet, no price group specific options appeared so far
  }

  getPreselectionStrategy(): IDependentOptionPreselectionStrategy {
    return new DefaultDependentOptionPreselectionStrategy(this);
  }

  canBeAutoResolvedWhenRestricting(): boolean {
    return false;
  }
}
