import { Component } from 'react';

import { UserGuideVideoSectionTranslation } from '../../../localization/SiteTranslation';
import { ModalVideoPlayer } from '../../../shared/components/ModalVideoPlayer/ModalVideoPlayer';
import HeaderLevel2 from '../../../shared/components/Typography/HeaderLevel2';
import css from './UserGuideVideo.css';

type UserGuideVideoProps = {
  videoUrl: string;
  videoTitle: string;
  imageUrl?: string;
  id: string;
  translation: UserGuideVideoSectionTranslation;
};

export class UserGuideVideo extends Component<UserGuideVideoProps> {
  render() {
    const text = this.props.videoTitle || this.props.translation.videoTitle;

    return (
      <div className={css.UserGuideVideo}>
        <HeaderLevel2 className={css.title}>{text}</HeaderLevel2>
        <ModalVideoPlayer
          videoUrl={this.props.videoUrl}
          imageUrl={this.props.imageUrl}
          id={this.props.id}
          title={text}
        />
      </div>
    );
  }
}
