import { action, autorun, computed, makeObservable, observable } from 'mobx';

import {
  FabricCollectionData,
  MoneyData,
  OptionData,
  OptionType,
  OptionVariationData,
  PriceGroupData,
} from '../data/model';
import { FabricFiltersTranslation } from '../localization/SiteTranslation';
import {
  DefaultDependentOptionPreselectionStrategy,
  EnableableOption,
  getEmptyMoneyData,
  IDependentOptionPreselectionStrategy,
  IFeature,
  IItem,
} from '../shared/common';
import { IRestrictionsInitializer } from '../shared/restrictions';
import { OptionDescriptionModalState } from './components/OptionDescriptionModal/OptionDescriptionModalState';
import { FabricCollectionBasedFilterState } from './FabricCollectionBasedFilterState';
import { FabricCollectionState } from './FabricCollectionState';
import { FabricPriceGroupState } from './FabricPriceGroupState';
import { IOptionState, OptionVariationState } from './OptionState';
import { PropertyBasedFabricFilterState } from './PropertyBasedFabricFilterState';

export interface IFabricOptionVariationFilter {
  isSatisfied(variation: OptionVariationData): boolean;
}

export class KindOfFabricFilter implements IFabricOptionVariationFilter {
  private alwaysSatisfied = false;

  constructor(
    private includedKindOfFabricVariationsIds: Array<string>,
    private excludedKindOfFabricVariationsIds: Array<string>,
    availableVariations: Array<OptionVariationData>,
  ) {
    this.alwaysSatisfied = !this.anyEffectiveRestrictionDefined(
      includedKindOfFabricVariationsIds,
      excludedKindOfFabricVariationsIds,
      availableVariations,
    );
  }

  isSatisfied(variation: OptionVariationData): boolean {
    if (this.alwaysSatisfied) {
      return true;
    }

    if (
      this.includedKindOfFabricVariationsIds.any() &&
      variation.kindOfFabricVariationIds?.intersection(this.includedKindOfFabricVariationsIds)?.empty()
    ) {
      return false;
    }

    if (
      this.excludedKindOfFabricVariationsIds.any() &&
      variation.kindOfFabricVariationIds?.intersection(this.excludedKindOfFabricVariationsIds)?.any()
    ) {
      return false;
    }

    return true;
  }

  anyEffectiveRestrictionDefined(
    includedKindOfFabricVariationsIds: Array<string>,
    excludedKindOfFabricVariationsIds: Array<string>,
    availableVariations: Array<OptionVariationData>,
  ) {
    if (includedKindOfFabricVariationsIds.empty() && excludedKindOfFabricVariationsIds.empty()) {
      return false;
    }

    const allKindsOfFabricVariationsIds = [...includedKindOfFabricVariationsIds, ...excludedKindOfFabricVariationsIds];
    return availableVariations.any((x) =>
      x.kindOfFabricVariationIds?.intersection(allKindsOfFabricVariationsIds)?.any(),
    );
  }
}

export class DependentFabricOptionPreselectionStrategy extends DefaultDependentOptionPreselectionStrategy {
  constructor(feature: IFeature) {
    super(feature);
  }

  preselectDependentItem(itemToBeSelected: IItem, selectedItems: IItem[], validItems: IItem[]): void {
    const selectedVariationsCodes = selectedItems.map((x) => x.code);
    const filteredVariations = this.filterByKindOfFabric(validItems);
    const preferedVariation = filteredVariations
      .map((x) => x as OptionVariationState)
      .filter((x) => selectedVariationsCodes.contains(x.code))
      .first();

    if (preferedVariation) {
      this.feature.selectItem(preferedVariation);
      return;
    }

    super.preselectDependentItem(itemToBeSelected, selectedItems, filteredVariations);
  }

  suggestValidItem(validItems: IItem[]): IItem | null {
    const filteredVariations = this.filterByKindOfFabric(validItems);
    return super.suggestValidItem(filteredVariations);
  }

  private filterByKindOfFabric(validItems: IItem[]): IItem[] {
    const fabricOption = this.feature as FabricOptionState;
    const fitler = new KindOfFabricFilter(
      fabricOption.includedKindOfFabricVariationsIds,
      fabricOption.excludedKindOfFabricVariationsIds,
      validItems.map((x) => (x as OptionVariationState).data),
    );

    const filteredVariations = validItems
      .map((x) => x as OptionVariationState)
      .filter((x) => fitler.isSatisfied(x.data));

    return filteredVariations;
  }
}

export enum PriceGroupDeterminantMode {
  DeterminantFlag = 'DeterminantFlag',
  PriceGroupsArray = 'PriceGroupsArray',
}

export interface IKindOfFabricFilteredFeature {
  setIncludedKindOfFabrics(kindOfFabricsVariationsIds: string[]): void;

  setExcludedKindOfFabrics(kindOfFabricsVariationsIds: string[]): void;

  selected: OptionVariationState;
}

export class FabricOptionState
  extends EnableableOption
  implements IOptionState, IFeature, IKindOfFabricFilteredFeature
{
  data: OptionData;
  id: string;
  name: string;
  code: string;
  type: OptionType;
  tabCode: string;
  index: number;
  priceGroupDeterminantMode: PriceGroupDeterminantMode;
  supportsPriceGroups: boolean;

  priceGroups: Array<FabricPriceGroupState>;

  collectionsBasedFilter: FabricCollectionBasedFilterState;
  filters: Array<PropertyBasedFabricFilterState>;

  collections: Array<FabricCollectionState> = [];

  @observable
  includedKindOfFabricVariationsIds: Array<string> = [];

  @observable
  excludedKindOfFabricVariationsIds: Array<string> = [];

  @computed get anyFilterDefined() {
    return this.filters.any() || this.collectionsBasedFilter.isVisible;
  }

  @computed get selectedGroup(): FabricPriceGroupState | undefined {
    return this.priceGroups.find((group) => group.collections.mapMany((x) => x.variations).any((x) => x.selected));
  }

  @computed get selected() {
    return this.allFabricVariations.find((x) => x.selected);
  }

  @computed get filteredPriceGroups() {
    return this.priceGroups.filter((x) => x.filteredCollections.length > 0);
  }

  @computed get collectionsWithVariations() {
    return this.collections.filter((x) => x.filteredVariations.length > 0);
  }

  @computed get anyFilterApplied() {
    return this.filters.filter((x) => !!x.selected).length > 0;
  }

  @computed get isPriceGroupDeterminant() {
    return this.priceGroupDeterminantMode === PriceGroupDeterminantMode.PriceGroupsArray
      ? this.data.priceGroups.any()
      : this.data.determinesPriceGroup;
  }

  @computed get allFabricVariationsData() {
    return this.allFabricVariations.map((x) => x.data);
  }

  @computed get price() {
    return this.data.priceGroups.any() && this.selectedGroup ? this.selectedGroup.data.price : getEmptyMoneyData();
  }

  @computed
  private get allFabricVariations() {
    return this.priceGroups
      .mapMany((x) => x.collections)
      .mapMany((x) => x.variations)
      .concat(this.collections.mapMany((x) => x.variations));
  }

  constructor(
    option: OptionData,
    priceGroupDeterminantMode: PriceGroupDeterminantMode,
    priceGroups: Array<PriceGroupData>,
    restrictionsInitializer: IRestrictionsInitializer,
    optionDescriptionModal: OptionDescriptionModalState,
    translation: FabricFiltersTranslation,
    collections: Array<FabricCollectionData> = [],
  ) {
    super();

    makeObservable(this);

    this.data = option;
    this.priceGroupDeterminantMode = priceGroupDeterminantMode;
    this.priceGroups = new Array<FabricPriceGroupState>();

    this.id = option.id;
    this.name = option.name;
    this.code = option.code;
    this.type = option.type;
    this.tabCode = option.tabCode;
    this.index = option.index;

    this.populateFilters(option, collections, translation);

    // What to do when some groups have price group while others do not?
    // For now we assume that only price group determinants may have price group assigned
    if (this.isPriceGroupDeterminant) {
      this.populatePriceGroups(
        option,
        option.priceGroups.any() ? option.priceGroups : priceGroups,
        restrictionsInitializer,
        optionDescriptionModal,
      );
    } else {
      this.populateCollections(option, restrictionsInitializer, optionDescriptionModal);
    }

    this.trySelectUsingId(option.defaultVariationId);

    autorun(() => {
      this.trackSelectedFilters();
    });
  }

  private populateFilters(
    option: OptionData,
    collections: FabricCollectionData[],
    translation: FabricFiltersTranslation,
  ) {
    if (option.determinesPriceGroup) {
      // Filtering by collection only for price group determinants
      const availableFabricCodes = this.data.groups.mapMany((x) => x.variations).map((x) => x.code);
      this.collectionsBasedFilter = new FabricCollectionBasedFilterState(
        collections.filter((collection) => availableFabricCodes.intersection(collection.includedFabricsCodes).any()),
        translation,
      );
    } else {
      this.collectionsBasedFilter = new FabricCollectionBasedFilterState([], translation);
    }

    this.filters = option.filters.map((filter) => new PropertyBasedFabricFilterState(filter, translation));
  }

  private trackSelectedFilters() {
    const appliedFilters = [...this.filters, this.collectionsBasedFilter].filter((x) => x.isApplied);

    const kindOfFabricFilter = new KindOfFabricFilter(
      this.includedKindOfFabricVariationsIds,
      this.excludedKindOfFabricVariationsIds,
      this.allFabricVariationsData,
    );

    const filters = [kindOfFabricFilter, ...appliedFilters];

    this.priceGroups.forEach((x) => x.applyFilters(filters));
    this.collections.forEach((x) => x.applyFilters(filters));
  }

  private populatePriceGroups(
    option: OptionData,
    priceGroups: Array<PriceGroupData>,
    restrictionsInitializer: IRestrictionsInitializer,
    descriptionModal: OptionDescriptionModalState,
  ) {
    const distinctNumber = Array.from(new Set(option.groups.filter((x) => x.priceGroup).map((x) => x.priceGroup)));
    const orderedNumbers = distinctNumber.sort((x, y) => x.localeCompare(y));

    orderedNumbers.forEach((priceGroupNumber) => {
      let priceGroup = priceGroups.filter((x) => x.number === priceGroupNumber)[0];
      let fabricPriceGroup = new FabricPriceGroupState(
        priceGroup,
        option.groups.filter((x) => x.priceGroup === priceGroupNumber),
        restrictionsInitializer,
        this,
        descriptionModal,
      );
      this.priceGroups.push(fabricPriceGroup);
    });
  }

  private populateCollections(
    option: OptionData,
    restrictionsInitializer: IRestrictionsInitializer,
    descriptionModal: OptionDescriptionModalState,
  ) {
    this.collections = option.groups
      .filter((group) => !group.priceGroup)
      .map((group) => new FabricCollectionState(group, restrictionsInitializer, this, descriptionModal));
  }

  @action select(fabric: OptionVariationState) {
    this.allFabricVariations.forEach((variation) => variation.deselect());
    fabric.select();
  }

  @action checkRestrictionsAndSelect(variation: OptionVariationState) {
    variation.restrictionsResolver.checkRestrictionsAndSelect(variation);
  }

  @action clearFilters() {
    this.filters.forEach((filter) => filter.clear());
  }

  getPriceDifference(group: FabricPriceGroupState): MoneyData {
    if (!group || !this.selectedGroup) {
      return getEmptyMoneyData();
    }

    return {
      amount: group.data.price.amount - this.selectedGroup.data.price.amount,
      currency: this.selectedGroup.data.price.currency,
    };
  }

  @action trySelectUsingId(id: string): boolean {
    const variation = this.allFabricVariations.find((x) => x.data.id === id);

    if (variation) {
      this.select(variation);
      return true;
    }
    return false;
  }

  // IFeature interface implementation

  selectItem(variation: OptionVariationState): void {
    this.select(variation);
  }

  getSelectedItem(): IItem {
    return this.selected;
  }

  @action deselectItem(variation: OptionVariationState) {
    throw Error('Not supported');
  }

  formatItemName(variation: OptionVariationState): string {
    return `Fabric ${variation.name}`;
  }

  getItemPriceDifference(variation: OptionVariationState): MoneyData {
    const owningGroup = this.priceGroups.find((group) =>
      group.collections.mapMany((collection) => collection.variations).contains(variation),
    );

    if (!owningGroup) {
      return getEmptyMoneyData();
    }

    return this.getPriceDifference(owningGroup);
  }

  getItemPrice(item: IItem): MoneyData {
    const owningGroup = this.priceGroups.find((group) => group.containsVariationId(item.id));

    if (!owningGroup) {
      return getEmptyMoneyData();
    }

    return owningGroup.data.price;
  }

  getAllItems() {
    return this.allFabricVariations;
  }

  getPreselectionStrategy(): IDependentOptionPreselectionStrategy {
    return new DependentFabricOptionPreselectionStrategy(this);
  }

  canBeAutoResolvedWhenRestricting(): boolean {
    return this.includedKindOfFabricVariationsIds.any() || this.excludedKindOfFabricVariationsIds.any();
  }

  @action
  setIncludedKindOfFabrics(kindOfFabricsVariationsIds: string[]): void {
    this.includedKindOfFabricVariationsIds = kindOfFabricsVariationsIds;
  }

  @action
  setExcludedKindOfFabrics(kindOfFabricsVariationsIds: string[]): void {
    this.excludedKindOfFabricVariationsIds = kindOfFabricsVariationsIds;
  }
}
