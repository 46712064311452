import { action, computed, makeObservable } from 'mobx';

import { IApiClient } from '../../../data/client';
import { BaseModelBasicData, BaseModelData, GetProductFamilyModelsQuery, ShoppingContext } from '../../../data/model';
import { ILoadingIndicator, LoadingIndicator } from '../../../shared/LoadingIndicator';
import { ModalState } from '../../../shared/ModalState';
import { INavigationService } from '../../../shared/NavigationService';
import { StoreUrl } from '../../../shared/StoreUrl';
import IConfigurationPageQuery from '../../IConfigurationPageQuery';
import { ModelSelectorState } from './ModelSelector/ModalSelectorState';

export class ProductModelsState {
  public readonly modal: ModalState;
  public readonly selector: ModelSelectorState;

  private readonly loadingIndicator: ILoadingIndicator;

  public constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly shoppingContext: ShoppingContext,
    private readonly activeModel: BaseModelData,
    private readonly getCurrentConfigurationPageQuery: () => IConfigurationPageQuery,
  ) {
    makeObservable(this);
    this.loadingIndicator = new LoadingIndicator();
    this.modal = new ModalState();
    this.selector = new ModelSelectorState();
  }

  public get isLoading() {
    return this.loadingIndicator.isLoading;
  }

  @computed
  public get activeModelName() {
    return this.activeModel.name;
  }

  @computed
  public get activeModelDescription() {
    return this.activeModel.shortDescription;
  }

  @action.bound
  public async load() {
    this.loadingIndicator.start();

    const models = await this.fetchModels();
    this.selector.setItems(models, this.activeModel.id);

    this.loadingIndicator.stop();
  }

  @action.bound
  public async navigateToModelConfigurator(model: BaseModelBasicData) {
    const queryModel = this.getCurrentConfigurationPageQuery();
    queryModel.switch = true;

    const url = StoreUrl.configuratorUrl(model.code, queryModel);
    this.navigation.navigateTo(url);
  }

  private async fetchModels() {
    const response = await this.client.send(
      new GetProductFamilyModelsQuery({
        productFamilyId: this.activeModel.productFamilyId,
        shoppingContext: this.shoppingContext,
      }),
    );

    if (response?.models) {
      return response.models.filter((x) => x.predefinedConfigurations?.any());
    }

    return [];
  }
}
