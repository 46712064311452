import { Component } from 'react';

import { EmbeddedQuery } from '../../shared/EmbeddedQueryParser';
import Footer from '../Footer/Footer';
import Topbar from '../Topbar';
import { LayoutProps } from './MainLayout';
import css from './StoreLocatorPageLayout.css';

interface StoreLocatorPageLayoutProps extends LayoutProps {
  embeddedQuery?: EmbeddedQuery;
}

export default class StoreLocatorPageLayout extends Component<StoreLocatorPageLayoutProps, {}> {
  render() {
    const {
      appState,
      children,
      embeddedQuery: { embed },
    } = this.props;
    const showFooter = !appState.store.isShowroom();

    if (embed) {
      return <div className={css.StoreLocatorPageLayout}>{children}</div>;
    }

    return (
      <div className={css.StoreLocatorPageLayout}>
        <Topbar appState={appState} translation={appState.translation.layout.topBar} />
        <div className={css.content}>{children}</div>
        {showFooter && (
          <Footer translation={appState.translation.layout.footerPanel} data={appState.navigationState.footer} />
        )}
      </div>
    );
  }
}
