import { ICancellableTask } from '../shared/TasksScheduler';

export class PreloadFlattenedImageTask implements ICancellableTask {
  url: string;
  cancelled = false;

  constructor(url: string) {
    this.url = url;
  }
}
