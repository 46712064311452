import classNames from 'classnames';

import CloseButton from '../../Button/CloseButton';
import DownloadButton from './DownloadButton';
import css from './LightboxToolbar.css';

interface LightboxToolbarProps {
  onClose: () => void;
  className?: string;
  onDownload?: () => void;
}

const LightboxToolbar = ({ className, onClose, onDownload }: LightboxToolbarProps) => {
  return (
    <div className={classNames(css.container, className)}>
      <CloseButton className={css.button} iconClassName={css.icon} onClick={onClose} />
      {onDownload && (
        <DownloadButton
          className={css.button}
          iconClassName={classNames(css.icon, css.download)}
          onClick={onDownload}
        />
      )}
    </div>
  );
};

export default LightboxToolbar;
