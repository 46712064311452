import classNames from 'classnames';
import { observer } from 'mobx-react';
import { CustomArrowProps } from 'react-slick';

import { ProductCarouselTranslation } from '../../../../../app/localization/SiteTranslation';
import Carousel, { CarouselProps } from '../../../../../app/shared/components/Carousel/Carousel';
import {
  SliderNextArrow,
  SliderPrevArrow,
} from '../../../../../app/shared/components/SliderNavigationArrows/SliderNavigationArrows';
import { ProductOverview } from '../../ProductPageState';
import css from './ProductCarousel.css';
import ProductCarouselItem from './ProductCarouselItem/ProductCarouselItem';

const CAROUSEL_BREAKPOINTS = {
  mobile: { maxWidth: 767, slidesToShow: 1, slidesToScroll: 1 },
  tablet: { maxWidth: 1199, slidesToShow: 2, slidesToScroll: 2 },
  desktop: { slidesToShow: 3, slidesToScroll: 3 },
};

interface ProductCarouselProps {
  items: Array<ProductOverview>;
  translation: ProductCarouselTranslation;
}

const ProductCarousel = observer(({ items, translation }: ProductCarouselProps) => {
  const NextArrow = ({ className, onClick }: CustomArrowProps) => (
    <SliderNextArrow className={classNames(className, css.arrow)} iconClassName={css.arrowIcon} onClick={onClick} />
  );

  const PrevArrow = ({ className, onClick }: CustomArrowProps) => (
    <SliderPrevArrow className={classNames(className, css.arrow)} iconClassName={css.arrowIcon} onClick={onClick} />
  );

  const settings: CarouselProps = {
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    slidesToShow: CAROUSEL_BREAKPOINTS.desktop.slidesToShow,
    slidesToScroll: CAROUSEL_BREAKPOINTS.desktop.slidesToScroll,
    responsive: [
      {
        breakpoint: CAROUSEL_BREAKPOINTS.tablet.maxWidth,
        settings: {
          slidesToShow: CAROUSEL_BREAKPOINTS.tablet.slidesToShow,
          slidesToScroll: CAROUSEL_BREAKPOINTS.tablet.slidesToScroll,
        },
      },
      {
        breakpoint: CAROUSEL_BREAKPOINTS.mobile.maxWidth,
        settings: {
          slidesToShow: CAROUSEL_BREAKPOINTS.mobile.slidesToShow,
          slidesToScroll: CAROUSEL_BREAKPOINTS.mobile.slidesToScroll,
        },
      },
    ],
  };

  return (
    <section className={css.ProductCarousel}>
      <div className={css.carouselContainer}>
        <h2 className={css.title}>{translation.title}</h2>
        <Carousel className={css.carousel} centered {...settings}>
          {items.map((x) => (
            <ProductCarouselItem key={x.id} image={x.image} url={x.url} />
          ))}
        </Carousel>
      </div>
    </section>
  );
});

export default ProductCarousel;
