import { action, makeObservable, observable } from 'mobx';

import { FinalVisualizationBuilder } from '../../../configurator/VisualizationBuilder';
import { RelatedProductData } from '../../../data/model';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { StoreUrl } from '../../../shared/StoreUrl';
import { RelativeUrl } from '../../../shared/Url';

export interface RelatedProduct {
  brand: string;
  familyName: string;
  modelName: string;
  image: string;
  link: RelativeUrl;
}

export class RelatedProductsState {
  @observable.shallow
  public products: Array<RelatedProduct> = [];

  public loadingIndicator: LoadingIndicator;

  constructor() {
    makeObservable(this);
    this.loadingIndicator = new LoadingIndicator();
  }

  public initialize(products: Array<RelatedProductData>, amount: number = 4) {
    if (products === null) {
      return;
    }

    this.updateRelatedProducts(
      products.take(amount).map((x) => ({
        brand: x.brand,
        familyName: x.familyShortName,
        modelName: x.modelName,
        link: StoreUrl.productPageUrl(
          x.familyId,
          x.modelId,
          x.predefinedConfiguration && x.predefinedConfiguration.code,
        ),
        image: FinalVisualizationBuilder.buildUrlForPredefinedConfiguration(x.predefinedConfiguration, x.image),
      })),
    );
  }

  @action
  public updateRelatedProducts(products: Array<RelatedProduct>) {
    this.products = products;
  }
}
