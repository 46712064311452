import { action, makeObservable } from 'mobx';

import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { IApiClient } from '../../app/data/client';
import { GetUserDefaultDealerOrganizationQuery } from '../../app/data/model';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import { BasePageState } from '../../app/shared/BasePageState';
import { IMemento } from '../../app/shared/common';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import { RelativeUrl } from '../../app/shared/Url';
import { StoreState } from '../../app/StoreState';
import { HomePageTranslation } from '../localization/SiteTranslation';
import RouteDefinitions from '../routing/RouteDefinitions';

export class HomeState extends BasePageState<IMemento> {
  public constructor(
    private readonly client: IApiClient,
    private readonly authentication: IAuthenticationService,
    private readonly navigation: INavigationService,
    public readonly translations: HomePageTranslation,
  ) {
    super(translations);
    makeObservable(this);
  }

  @action
  public async onLoad(store: StoreState) {
    const isAuthenticated = await this.authentication.isAuthenticated();

    if (isAuthenticated === false) {
      this.navigation.navigateTo(RelativeUrl.parse(RouteUrlBuilder.getUrlTo(RouteDefinitions.loginRoute)));
    } else if (!StoreUrlBuilder.context) {
      const { storeId, languageCode } = await this.client.send(new GetUserDefaultDealerOrganizationQuery());
      const dealerHomeUrl = StoreUrlBuilder.buildRawStoreUrl(
        storeId,
        languageCode,
        RouteUrlBuilder.getUrlTo(RouteDefinitions.homeRoute),
      );
      this.navigation.navigateTo(dealerHomeUrl);
    }

    return;
  }
}
