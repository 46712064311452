import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { NumberInput } from '../../Form';
import Icon, { IconType } from '../Icon/Icon';
import InputElement from '../Input/InputElement';
import css from './InputNumber.css';

interface InputControlProps {
  icon: IconType;
  onClick: () => void;
  isActive?: boolean;
}

const InputControl = ({ icon, onClick, isActive }: InputControlProps) => {
  const inputControlClasses = classNames(css.InputControl, !isActive && css.disabled);
  return (
    <div onClick={() => (isActive ? onClick() : null)} className={inputControlClasses}>
      <Icon className={css.iconControl} name={icon} />
    </div>
  );
};

export interface InputNumberProps {
  className?: string;
  onChange?: (value: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyPress?: () => void;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  input: NumberInput;
  showWarning?: boolean;
}

@observer
class InputNumber extends React.Component<InputNumberProps, { previousValue: number }> {
  min?: number;
  max?: number;

  constructor(props: InputNumberProps) {
    super(props);
    const { min, max } = this.props;

    this.min = min;
    this.max = max;
    this.state = { previousValue: null };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnKeyPress = this.handleOnKeyPress.bind(this);
    this.handleOnInputControlClick = this.handleOnInputControlClick.bind(this);
  }

  updateInput(value: number) {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }
    onChange(value);
  }

  handleOnInputControlClick(diff: number) {
    const { input } = this.props;
    const newValue = input.value + diff;
    this.updateInput(newValue);
  }

  handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e.currentTarget.value, 10);
    this.updateInput(newValue);
  }

  handleOnBlur() {
    const {
      onChange,
      input: { value },
      max,
      min,
    } = this.props;

    if (isNaN(value) && !isNaN(min)) {
      onChange(min);
    }

    if (!isNaN(value) && !isNaN(min) && value < min) {
      onChange(min);
    }

    if (!isNaN(value) && !isNaN(max) && value > max) {
      onChange(max);
    }
  }

  handleOnFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.currentTarget.select();
  }

  handleOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    const plusCode = 43;
    const minusCode = 45;
    if (e.charCode === plusCode || e.charCode === minusCode) {
      e.preventDefault();
    }
  }

  componentDidMount() {
    this.updateInput(this.props.input.value);
  }

  renderWarning() {
    const { input } = this.props;
    if (!input.isValid) {
      return <div className={css.tooltip}> {input.errorMessages[0]}</div>;
    }
    return null;
  }

  render() {
    const { input, step, showWarning, className } = this.props;
    const isDecreaseControlActive = isNaN(this.min) || input.value > this.min;
    const isIncreaseControlActive = isNaN(this.max) || input.value < this.max;

    return (
      <div className={classNames(css.InputNumber, className)}>
        {showWarning && this.renderWarning()}
        <InputControl
          icon="minus"
          onClick={() => {
            this.handleOnInputControlClick(-step || -1);
          }}
          isActive={isDecreaseControlActive}
        />
        <InputElement
          type="number"
          className={css.inputValue}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          onKeyPress={this.handleOnKeyPress}
          value={(typeof input.value === 'number' && input.value.toString()) || ''}
          min={this.min}
          max={this.max}
        />
        <InputControl
          icon="plus"
          onClick={() => {
            this.handleOnInputControlClick(step || 1);
          }}
          isActive={isIncreaseControlActive}
        />
      </div>
    );
  }
}

export default InputNumber;
