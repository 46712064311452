import { observer } from 'mobx-react';

import { ProductNotAvailableInCurrentStoreWarningTranslation } from '../../localization/SiteTranslation';
import { WarningComponentWithReason } from './WarningComponentWithReason';

@observer
export class ProductNotAvailableInCurrentStoreWarning extends WarningComponentWithReason<
  string,
  ProductNotAvailableInCurrentStoreWarningTranslation
> {
  getMessage(): string {
    const { state, translation } = this.props;
    const message = translation.messageFormat.interpolate([['currentStore', state.args]]);
    return message;
  }
}
