import classNames from 'classnames';
import { useId, useRef, useState } from 'react';

import { ActionMenuTranslation } from '../../../localization/SiteTranslation';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useOnKeyDown from '../../hooks/useOnKeyDown';
import { ShowMoreButton } from '../Button/ShowMoreButton';
import TextButton from '../Button/TextButton';
import List from '../List/List';
import css from './ActionMenu.css';

interface ActionMenuItem {
  label: string;
  action: () => void;
}

interface ActionMenuProps {
  items: ActionMenuItem[];
  translation: ActionMenuTranslation;
  isLoading?: boolean;
}

const ActionMenu = ({ items, translation, isLoading = false }: ActionMenuProps) => {
  const menuId = useId();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen((state) => !state);

  useOnClickOutside(ref, close);
  useOnKeyDown('Escape', close);

  const renderItem = (item: ActionMenuItem) => {
    const handleClick = () => {
      item.action();
      close();
    };

    return (
      <TextButton type="button" role="menuitem" className={css.item} onClick={handleClick} isHighlighted>
        {item.label}
      </TextButton>
    );
  };

  return (
    <div className={css.container} ref={ref}>
      <ShowMoreButton
        type="button"
        nameClassName="visuallyHidden"
        aria-controls={menuId}
        aria-expanded={isOpen}
        aria-haspopup="menu"
        isLoading={isLoading}
        onClick={toggle}
      >
        {translation.title}
      </ShowMoreButton>
      <List
        role="menu"
        id={menuId}
        className={classNames(css.menu, { [css.open]: isOpen })}
        itemRenderer={renderItem}
        itemRole="presentation"
        items={items}
      />
    </div>
  );
};

export default ActionMenu;
