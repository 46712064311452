import classNames from 'classnames';
import { ReactNode } from 'react';

import css from './PropertyItemVertical.css';

interface PropertyItemVerticalProps {
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  label: ReactNode;
  value: ReactNode;
}

export const PropertyItemVertical = ({
  className,
  labelClassName,
  valueClassName,
  label,
  value,
}: PropertyItemVerticalProps) => {
  return (
    <div className={classNames(css.PropertyItemVertical, className)}>
      <div className={classNames(css.label, labelClassName)}>{label}</div>
      <div className={classNames(css.value, valueClassName)}>{value}</div>
    </div>
  );
};
