import { QueryParser } from '../shared/QueryParser';
import { ACCESSORIES, CODE, OPTIONS, QUANTITY, SHOPPING_CART_ITEM_ID, STEP, SWITCH } from './ConfiguratorConsts';
import IConfigurationPageQuery from './IConfigurationPageQuery';

export default class ConfiguratorPageQueryParser extends QueryParser {
  static toModel(query: Map<string, string>): IConfigurationPageQuery {
    const options = QueryParser.getValues(query, OPTIONS);
    const accessories = QueryParser.getValues(query, ACCESSORIES);
    const step = QueryParser.getValue(query, STEP);
    const quantity = parseInt(QueryParser.getValue(query, QUANTITY), 10);
    const code = QueryParser.getValue(query, CODE);
    const shoppingCartItemId = QueryParser.getValue(query, SHOPPING_CART_ITEM_ID);
    const modelSwitch = query.has(SWITCH);

    return {
      options: options,
      accessories: accessories,
      step: step,
      quantity: quantity ? quantity : 1,
      code: code,
      shoppingCartItemId,
      switch: modelSwitch,
    };
  }

  static toQuery(queryModel: IConfigurationPageQuery) {
    const query = new Map<string, string>();

    QueryParser.setValue(query, CODE, queryModel.code);
    QueryParser.setValue(query, STEP, queryModel.step);
    QueryParser.setValues(query, OPTIONS, queryModel.options);
    QueryParser.setValues(query, ACCESSORIES, queryModel.accessories);
    QueryParser.setValue(query, SHOPPING_CART_ITEM_ID, queryModel.shoppingCartItemId);

    if (queryModel.quantity && queryModel.quantity > 1) {
      query.set(QUANTITY, queryModel.quantity.toString());
    }

    if (queryModel.switch) {
      query.set(SWITCH, true.toString());
    }

    return query;
  }
}
