export default class Event<TArgs> {
  private handlers: Array<(args: TArgs) => void> = [];

  public subscribe(handler: (args: TArgs) => void): void {
    this.handlers.push(handler);
  }

  public unsubscribe(handler: (args: TArgs) => void): void {
    const index = this.handlers.indexOf(handler);
    if (index >= 0) {
      this.handlers.splice(index, 1);
    }
  }

  public raise(args: TArgs) {
    this.handlers.forEach((x) => {
      x(args);
    });
  }
}
