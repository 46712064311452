import { observer } from 'mobx-react';
import { Component, CSSProperties, ReactNode } from 'react';

import CustomScrollbar from '../../../shared/components/CustomScrollbar/CustomScrollbar';
import AnimatedTab from '../../AnimatedTab';
import { AnimatedPanel } from '../../animations/AnimatedPanel/AnimatedPanel';
import { AnimatedPanelState } from '../../animations/AnimatedPanel/AnimatedPanelState';
import css from './TabContent.css';
import { TabState } from './TabState';

interface TabContentProps {
  className?: string;
  animatedTabClassName?: string;
  animatedTabContainerClassName?: string;
  style?: CSSProperties;
  tabState: TabState;
  children?: ReactNode | Array<ReactNode>;
  containerAnimations?: AnimatedPanelState;
  onClick?: (tab: TabState) => void;
  animationState: AnimatedPanelState;
  isFixed?: boolean;
}

@observer
class TabContent extends Component<TabContentProps, {}> {
  render() {
    const {
      tabState,
      children,
      containerAnimations,
      animationState,
      style,
      animatedTabClassName,
      animatedTabContainerClassName,
      isFixed,
    } = this.props;

    return (
      <AnimatedTab
        enableScroll={tabState.isActive}
        animations={containerAnimations.animations}
        style={style}
        className={animatedTabClassName}
        containerClassName={animatedTabContainerClassName}
        isFixed={isFixed}
      >
        <AnimatedPanel
          animations={animationState.animations}
          className={css.container}
          classes={{ initAnimation: css.initPanelAnimation }}
        >
          <CustomScrollbar trackClassName={css.trackY} viewClassName={css.scrollContent}>
            {children}
          </CustomScrollbar>
        </AnimatedPanel>
      </AnimatedTab>
    );
  }
}

export default TabContent;
