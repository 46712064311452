import { action, computed, makeObservable, observable } from 'mobx';

import AppSettings from '../AppSettings';
import { IApiClient } from '../data/client';
import {
  CreateOrderCommand,
  GetCartQuery,
  GetCartQueryResponse,
  GetOrderQuery,
  GetShippingMethodsQuery,
  OrderData,
  ShippingRateData,
  ShoppingCartData,
  ShoppingCartItemData,
  ShoppingContext,
  StoreContext,
} from '../data/model';
import { CheckoutPageTranslation } from '../localization/SiteTranslation';
import { Analytics } from '../shared/analytics/Analytics';
import ProductDataConverter from '../shared/analytics/ProductDataConverter';
import PurchaseEventDataConverter from '../shared/analytics/PurchaseEventDataConverter';
import { CheckoutEventData, CheckoutOptionEventData, PurchaseEventData } from '../shared/analytics/TrackingService';
import { AppError } from '../shared/AppError';
import { BasePageState } from '../shared/BasePageState';
import { AsyncCommand } from '../shared/common';
import { INavigationService } from '../shared/NavigationService';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreUrl } from '../shared/StoreUrl';
import { StoreState } from '../StoreState';
import { CheckoutFormState } from './CheckoutFormState';
import CheckoutPageQueryParser from './CheckoutPageQueryParser';
import { CheckoutPaymentState } from './CheckoutPaymentState';
import { PaymentSteps } from './CheckoutSteps';
import { DiscountCodeFormState } from './components/DiscountCodeForm/DiscountCodeFormState';
import { ICheckoutStep } from './ICheckoutStep';
import { PaymentMethodFactory } from './payments/PaymentWidgetFactory';

export class ICheckoutMemento {
  shoppingContext: ShoppingContext;
  storeContext: StoreContext;
  cartResponse: GetCartQueryResponse;
  shippingRates: ShippingRateData[];
  order?: OrderData;
  activeStep?: string;
}

export class CheckoutState extends BasePageState<ICheckoutMemento> {
  public disableCache = true;

  public termsAndConditions: string;
  public legalAgreementsConsentHtml: string;
  private client: IApiClient;
  private navigation: INavigationService;

  public shoppingContext: ShoppingContext;
  public storeContext: StoreContext;
  public cartResponse: GetCartQueryResponse;

  @observable.ref
  public shoppingCart: ShoppingCartData;
  public order: OrderData;

  @observable.ref
  public shippingRates: ShippingRateData[] = [];

  public translation: CheckoutPageTranslation;

  public form: CheckoutFormState;
  public readonly discountCodeForm: DiscountCodeFormState;
  public payment: CheckoutPaymentState;

  public steps: Array<ICheckoutStep> = [];

  public createOrderCommand: AsyncCommand;
  public finalizePaymentCommand: AsyncCommand;
  public currentLanguage: string;

  constructor(
    settings: AppSettings,
    client: IApiClient,
    navigation: INavigationService,
    translation: CheckoutPageTranslation,
    termsAndConditions: string,
    legalAgreementsConsentHtml: string,
    currentLanguage: string,
  ) {
    super(translation);

    makeObservable(this);

    this.client = client;
    this.navigation = navigation;
    this.translation = translation;
    this.termsAndConditions = termsAndConditions;
    this.legalAgreementsConsentHtml = legalAgreementsConsentHtml;

    this.discountCodeForm = new DiscountCodeFormState(this.translation.discountCodeForm, this.client);
    this.discountCodeForm.discountCodeAppliedEvent.subscribe(this.setCart);

    this.form = new CheckoutFormState(this.translation, [], client);
    this.form.shippingRateChangedEvent.subscribe(this.setCart);

    this.payment = new CheckoutPaymentState(
      this.client,
      new PaymentMethodFactory(settings, termsAndConditions, currentLanguage, this.translation),
      this.translation,
    );
    this.payment.completed.subscribe((args) => {
      this.onComplete(args.redirectUrl, args.paymentOption);
    });

    this.steps = [this.form, this.payment, PaymentSteps.CreateConfirmationStep(this.translation)];
    this.setStep(this.form);

    this.createOrderCommand = new AsyncCommand(() => this.createOrder(), this.form);
  }

  @action
  async onLoad(store: StoreState) {
    const cartResponse = await this.client.send(new GetCartQuery({ shoppingContext: store.shoppingContext }));
    const shippingRatesResponse = await this.client.send(
      new GetShippingMethodsQuery({ shoppingContext: store.shoppingContext }),
    );

    let order: OrderData = null;
    const query = CheckoutPageQueryParser.toModel(this.navigation.currentUrl.query);

    if (query.orderNumber) {
      order = (
        await this.client.send(
          new GetOrderQuery({ orderNumber: query.orderNumber, shoppingContext: store.shoppingContext }),
        )
      ).order;
    }

    this.initialize(
      store.shoppingContext,
      store.storeResponse.storeContext,
      cartResponse,
      shippingRatesResponse.shippingRates,
      order,
      query.step,
    );
  }

  unload() {
    this.payment.disposeCurrentMethod();
  }

  @action
  private initialize(
    shoppingContext: ShoppingContext,
    storeContext: StoreContext,
    response: GetCartQueryResponse,
    shippingRates: ShippingRateData[],
    order?: OrderData,
    activeStepId?: string,
  ) {
    this.shoppingContext = shoppingContext;
    this.storeContext = storeContext;
    this.cartResponse = response;
    this.shippingRates = shippingRates;
    this.order = order;

    this.setCart(response.shoppingCart);

    this.form.initialize(storeContext, shoppingContext, shippingRates, response.shoppingCart.shipments[0]);
    this.payment.initialize(shoppingContext, storeContext);
    this.discountCodeForm.initialize(shoppingContext);

    if (order && activeStepId) {
      const activeStep = this.steps.find((x) => x.id === activeStepId);

      if (activeStep) {
        this.steps.forEach((step) => {
          step.restoreOrder(order);

          if (step === activeStep) {
            this.setStep(step);
            return;
          }
        });
      }
    }

    this.trackCheckoutInitialization();
  }

  get activeDiscountCode() {
    return this.shoppingCart.appliedCoupons.first();
  }

  get isFormSubmitButtonDisabled() {
    return this.form.recalculating || this.discountCodeForm.isProcessing;
  }

  @computed
  get shippingCostAvailable() {
    return this.shoppingCart.shipments.length > 0 || this.shippingRates.length === 0;
  }

  getMemento() {
    const activeStep = this.steps.find((x) => x.isActive);

    let memento: ICheckoutMemento = {
      shoppingContext: this.shoppingContext,
      storeContext: this.storeContext,
      cartResponse: this.cartResponse,
      shippingRates: this.shippingRates,
      order: this.order,
      activeStep: activeStep ? activeStep.id : null,
    };

    return memento;
  }

  restoreMemento(memento: ICheckoutMemento) {
    this.initialize(
      memento.shoppingContext,
      memento.storeContext,
      memento.cartResponse,
      memento.shippingRates,
      memento.order,
      memento.activeStep,
    );
  }

  @action
  async createOrder() {
    const paymentMethodCode = this.form.selectedPaymentMethod.code;
    this.payment.setMethod(paymentMethodCode);

    const command = new CreateOrderCommand({
      shoppingContext: this.shoppingContext,
      contactAndBilling: this.form.getContactAndBilling(),
      deliveryAddress: this.form.getDeliveryAddress(),
      paymentMethodCode: paymentMethodCode,
      parameters: this.payment.getInitialParameters(),
      // TODO: Pass a discount code on order creation
      // discountCode: this.discountCode.value,
    });

    const response = await this.client.send(command);

    if (response.paymentOperationResult.isSuccess) {
      this.order = response.order;
      await this.payment.processOrder(response.order, response.paymentOperationResult.parameters);
      this.setStep(this.payment);
      this.trackActiveStep();
    } else {
      throw new AppError(
        this.translation.errors.initializingPaymentFailed,
        `Payment initialization failed, order id = ${response.order.id}, error = ${response.paymentOperationResult.error}}`,
      );
    }
  }

  @action.bound
  backToPreviousPage() {
    this.navigation.back();
  }

  @action.bound
  editData() {
    this.setStep(this.form);
    this.trackActiveStep();
  }

  @action.bound setStep(step: ICheckoutStep) {
    this.steps.forEach((x) => (x.isActive = x === step));
  }

  private get activeStepIndex(): number {
    return this.steps.findIndex((x) => x.isActive);
  }

  @action.bound
  private setCart(cart: ShoppingCartData) {
    this.shoppingCart = cart;
  }

  private onComplete(redirectUrl?: string, selectedPaymentOption?: string) {
    if (selectedPaymentOption) {
      this.trackCheckoutOption(selectedPaymentOption);
    }

    this.trackPurchase();

    if (redirectUrl) {
      this.navigation.redirect(redirectUrl, StatusCodes.MovedTemporary);
    } else {
      this.navigation.navigateTo(StoreUrl.confirmationUrl(this.order.number));
    }
  }

  private trackActiveStep(lineItems?: ShoppingCartItemData[]) {
    if (!this.shoppingContext) {
      return;
    }

    const activeStepIndex = this.activeStepIndex;
    if (activeStepIndex === -1) {
      return;
    }

    const activeStep = this.steps[activeStepIndex];
    if (!activeStep) {
      return;
    }

    const products = lineItems?.map(ProductDataConverter.fromShoppingCartItemData);
    const checkoutEventData: CheckoutEventData = {
      stepId: activeStep.id,
      stepNumber: activeStepIndex + 1,
      context: this.shoppingContext,
      products,
    };

    Analytics.trackCheckoutStep(checkoutEventData);
  }

  private trackCheckoutInitialization() {
    // The products array should only be sent with the first step. Sending it with any other step has no effect.
    this.trackActiveStep(this.shoppingCart.items);
  }

  private trackCheckoutOption(option: string) {
    const activeStepIndex = this.activeStepIndex;
    if (activeStepIndex === -1) {
      return;
    }

    const activeStep = this.steps[activeStepIndex];
    if (!activeStep) {
      return;
    }

    const eventData: CheckoutOptionEventData = {
      context: this.shoppingContext,
      stepId: activeStep.id,
      stepNumber: activeStepIndex + 1,
      option,
    };

    Analytics.trackCheckoutOption(eventData);
  }

  private trackPurchase() {
    const eventData: PurchaseEventData = PurchaseEventDataConverter.convert(
      this.shoppingContext,
      this.order,
      this.activeDiscountCode,
    );

    Analytics.trackCheckoutCompleted(eventData);
  }
}
