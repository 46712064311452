import { observer } from 'mobx-react';
import { Component, MouseEvent } from 'react';

import ViewerState from './ViewerState';

export interface ViewerPageProps {
  state: ViewerState;
}

@observer
class ViewerPage extends Component<ViewerPageProps, {}> {
  private isDragging = false;
  private pageX = 0;
  private position = 0;

  constructor(props: ViewerPageProps) {
    super(props);
  }

  private renderImageItem(image: string, active: boolean) {
    return (
      <li key={image} style={{ display: active ? 'block' : 'none' }}>
        <img src={image} />
      </li>
    );
  }

  private onDragStart(event: MouseEvent<{}>) {
    this.pageX = event.pageX;
    this.position = this.props.state.position;
    this.isDragging = true;
  }

  private onDragEnd() {
    this.isDragging = false;
  }

  private onMouseMove(event: MouseEvent<{}>) {
    if (this.isDragging) {
      this.props.state.changePosition(Math.round(this.position + (event.pageX - this.pageX) * 0.1));
    }
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          height: '95vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ul>{this.props.state.images.map((x) => this.renderImageItem(x, x === this.props.state.activeImage))}</ul>
        <div
          style={{ width: '100%', height: '100%', float: 'left', position: 'absolute', top: 0, cursor: 'pointer' }}
          onMouseDown={(e) => this.onDragStart(e)}
          onMouseMove={(e) => this.onMouseMove(e)}
          onMouseUp={() => this.onDragEnd()}
          onMouseLeave={() => this.onDragEnd()}
        />
      </div>
    );
  }
}

export default ViewerPage;
