import { ModularLayoutBasicData } from '../../../../../data/model';
import CustomScrollbar from '../../../../../shared/components/CustomScrollbar/CustomScrollbar';
import List from '../../../../../shared/components/List/List';
import Heading, { HeadingSize } from '../../../../../shared/components/Typography/Heading/Heading';
import { SelectorItem } from '../../../../../shared/Selector';
import css from './LayoutPicker.css';
import LayoutPickerItem from './LayoutPickerItem/LayoutPickerItem';
import LayoutPickerState from './LayoutPickerItem/LayoutPickerState';

interface LayoutPickerProps {
  state: LayoutPickerState;
  disabled?: boolean;
}

const LayoutPicker = ({ state, disabled = false }: LayoutPickerProps) => {
  const renderItem = (item: SelectorItem<ModularLayoutBasicData>) => {
    return <LayoutPickerItem state={item} onClick={() => state.setActiveItem(item)} disabled={disabled} />;
  };

  return (
    <section className={css.container}>
      <Heading className={css.heading} level={2} size={HeadingSize.Medium}>
        Pick layout
      </Heading>
      <CustomScrollbar>
        <List
          className={css.items}
          itemClassName={css.item}
          items={state.items}
          itemRenderer={renderItem}
          keyProperty="data.id"
        />
      </CustomScrollbar>
    </section>
  );
};

export default LayoutPicker;
