export class QueryParser {
  static getValue(query: Map<string, string>, key: string, fallback?: string): string {
    return query.has(key) ? query.get(key) : fallback;
  }

  static getValues(query: Map<string, string>, key: string, fallback: Array<string> = []): Array<string> {
    return query.has(key) ? query.get(key).split(',') : fallback;
  }

  static setValues(query: Map<string, string>, key: string, values?: Array<string>) {
    if (values && values.length > 0) {
      query.set(key, values.join(','));
    }
  }

  static setValue(query: Map<string, string>, key: string, value?: string) {
    if (value) {
      query.set(key, value);
    }
  }
}
