import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';

import { ModalState } from '../../../ModalState';
import { ModalBasic, ModalBasicProps } from '../ModalBasic/ModalBasic';
import {
  ModalDefaultActionButtons,
  ModalDefaultApplyButtonProps,
  ModalDefaultCancelButtonProps,
  ModalDefaultContent,
  ModalDefaultTile,
} from './CustomModalDefaultElements';

type ReactModalProps = Omit<ModalBasicProps, 'isOpen' | 'onClose'>;

interface ModalProps extends ReactModalProps {
  modal: ModalState;

  customTitle?: ReactNode;
  defaultTitle?: {
    title: ReactNode | Array<ReactNode>;
    className?: string;
  };

  customContent?: ReactNode;
  defaultContent?: {
    content: ReactNode | Array<ReactNode>;
    className?: string;
  };

  customActionButtons?: ReactNode;
  defaultActionButtons?: {
    applyButton: ModalDefaultApplyButtonProps;
    cancelButton?: ModalDefaultCancelButtonProps;
  };
}

@observer
export class CustomModal extends Component<ModalProps> {
  render() {
    const {
      modal,
      customTitle,
      defaultTitle,
      customContent,
      defaultContent,
      customActionButtons,
      defaultActionButtons,
      ...otherProps
    } = this.props;

    const content = (
      <>
        {customTitle ? customTitle : defaultTitle ? <ModalDefaultTile defaultTitle={defaultTitle} /> : null}
        {customContent ? (
          customContent
        ) : defaultContent ? (
          <ModalDefaultContent defaultContent={defaultContent} />
        ) : null}
        {customActionButtons ? (
          customActionButtons
        ) : defaultActionButtons ? (
          <ModalDefaultActionButtons
            applyButton={defaultActionButtons.applyButton}
            cancelButton={defaultActionButtons.cancelButton}
            onClose={modal.close}
          />
        ) : null}
      </>
    );
    return (
      <ModalBasic isOpen={modal.visible} onClose={modal.close} {...otherProps}>
        {defaultActionButtons ? <form>{content} </form> : content}
      </ModalBasic>
    );
  }
}
