import * as React from 'react';

import Icon from '../../Icon/Icon';
import Link from '../../Link';
import css from './DownloadAssetLink.css';

interface DownloadAssetLinkProps {
  name: string;
  onClick: (url: string) => void;
  url: string;
}

const DownloadAssetLink = ({ name, onClick, url }: DownloadAssetLinkProps) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = () => {
    onClick(url);
  };

  return (
    <Link href={url} target="_blank" className={css.DownloadAssetLink} onClick={handleClick} preventDefault={false}>
      {name}
      <Icon name="download" className={css.icon} />
    </Link>
  );
};

export default DownloadAssetLink;
