import { action, makeObservable, observable } from 'mobx';

import { ViewerPageTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { ImagePreloadingContext } from '../shared/ImagePreloader';

const mod = function (x: number, n: number) {
  return ((x % n) + n) % n;
};

export default class ViewerState extends BasePageState<{}> {
  @observable
  public images: Array<string> = [];

  @observable
  public activeImage: string;

  public position: number = 0;

  constructor(translation: ViewerPageTranslation) {
    super(translation);
    makeObservable(this);
  }

  public onLoad(): Promise<void> {
    this.initialize();
    return Promise.resolve();
  }

  @action
  public changePosition(position: number) {
    this.position = mod(position, this.images.length);
    this.activeImage = this.images[this.position];
  }

  public restoreMemento() {
    this.initialize();
  }

  private initialize() {
    const baseUrl = 'https://flokkrenderdev.blob.core.windows.net/viewer-360/';

    for (let i = 0; i < 36; i++) {
      const url = `${baseUrl}${i}.png`;
      this.images.push(url);
      ImagePreloadingContext.queue.enqueue(url);
    }

    this.changePosition(0);
  }
}
