import { observer } from 'mobx-react';
import * as React from 'react';

import { Breakpoint, Screen, ScreenObserver } from '../../../layout/ScreenObserver';
import { DownloadsPageFiltersTranslation } from '../../../localization/SiteTranslation';
import { AsyncCommand } from '../../../shared/common';
import ActionButton from '../../../shared/components/ActionButton';
import SecondaryButton from '../../../shared/components/Button/SecondaryButton';
import Icon from '../../../shared/components/Icon/Icon';
import { IDownloadsFiltersState } from './DownloadsFiltersState';
import FilterDropdown from './FilterDropdown/FilterDropdown';
import css from './Filters.css';
import Tabs from './Tabs/Tabs';
import Tags from './Tags/Tags';

interface FiltersProps extends IDownloadsFiltersState, Pick<React.AriaAttributes, 'aria-controls'> {
  downloadAllCommand: AsyncCommand;
  onFilter: () => void;
  resultsCounter: number;
  translation: DownloadsPageFiltersTranslation;
}

interface FiltersState {
  breakpoint: Breakpoint;
}

@observer
class Filters extends React.Component<FiltersProps, FiltersState> {
  constructor(props: FiltersProps) {
    super(props);
    this.state = { breakpoint: Breakpoint.Unknown };
  }

  componentDidMount(): void {
    this.handleScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.handleScreenChange);
  }

  componentWillUnmount(): void {
    ScreenObserver.onScreenChange.unsubscribe(this.handleScreenChange);
  }

  handleScreenChange = (screen: Screen) => {
    this.setState({ breakpoint: screen.breakpoint });
  };

  render() {
    const {
      brands,
      categories,
      downloadAllCommand,
      downloadAllEnabled,
      onFilter,
      resultsCounter,
      translation,
      ...aria
    } = this.props;

    return (
      <div className={css.Filters}>
        <h2 className="visuallyHidden">{translation.title}</h2>

        {categories?.options?.any() && (
          <div className={css.bar}>
            {this.state.breakpoint === Breakpoint.Desktop ? (
              <Tabs
                isTypeSelected={categories.isTypeSelected}
                options={categories.options}
                resultsCounter={resultsCounter}
                selection={categories.selection}
                translation={translation.categoryFilter}
                onTabSelect={onFilter}
                {...aria}
              />
            ) : (
              <FilterDropdown
                fullName={categories.fullName}
                options={categories.options}
                resultsCounter={resultsCounter}
                selection={categories.selection}
                translation={translation.categoryFilter}
                onSelect={onFilter}
                {...aria}
              />
            )}
          </div>
        )}

        {(brands?.options?.any() || downloadAllEnabled) && (
          <div className={css.inner}>
            {brands?.options?.any() && (
              <Tags
                options={brands.options}
                selection={brands.selection}
                translation={translation.brandsFilter}
                onSelect={onFilter}
              />
            )}
            {downloadAllEnabled && (
              <ActionButton className={css.downloadButton} command={downloadAllCommand} buttonElement={SecondaryButton}>
                <>
                  <Icon className={css.icon} name="downloadLight" />
                  {translation.downloadAllButton}
                </>
              </ActionButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Filters;
