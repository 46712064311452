import { makeObservable, override } from 'mobx';

import { IWarningLocalStorage } from './WarningLocalStorage';
import { WarningStateBase } from './WarningStateBase';

export class FabricWarningState extends WarningStateBase {
  constructor(private readonly localStorage: IWarningLocalStorage) {
    super();
    makeObservable(this);
    const disclaimerData = this.localStorage.getDisclaimerAcceptance();
    this.visible = disclaimerData ? !disclaimerData.isAccepted : true;
  }

  @override
  open() {
    super.open();
    this.localStorage.setDisclaimerAcceptance({ isAccepted: false });
  }

  @override
  close() {
    super.close();
    this.localStorage.setDisclaimerAcceptance({ isAccepted: true });
  }
}
