import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import InputElement, { InputElementProps } from '../Input/InputElement';
import css from './CheckboxElement.css';

export interface CheckboxElementProps extends InputElementProps {
  labelClassName?: string;
  invertColors?: boolean;
  checked?: boolean;
  children?: JSX.Element | string;
  size?: 'large';
}

@observer
export default class CheckboxElement extends React.Component<CheckboxElementProps, {}> {
  constructor(props: CheckboxElementProps) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (this.props.readOnly) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    const { className, labelClassName, invalid, children, checked, invertColors, size, disabled, ...inputProps } =
      this.props;
    const labelClasses = classNames(
      css.label,
      checked && checked && css.checked,
      invalid && css.invalid,
      invertColors && css.invertColors,
      disabled && css.disabled,
      size && css[size],
      labelClassName,
    );

    return (
      <div className={classNames(css.CheckboxElement, className)}>
        <label className={labelClasses}>
          <InputElement
            type="checkbox"
            onChange={this.handleOnChange}
            className={css.checkbox}
            checked={checked}
            disabled={disabled}
            {...inputProps}
          />
          <span className={css.text}>{children}</span>
        </label>
      </div>
    );
  }
}
