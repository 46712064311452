import classNames from 'classnames';
import { useId, useRef, useState } from 'react';

import CustomScrollbar from '../../../shared/components/CustomScrollbar/CustomScrollbar';
import Icon from '../../../shared/components/Icon/Icon';
import List from '../../../shared/components/List/List';
import useOnClickOutside from '../../../shared/hooks/useOnClickOutside';
import useOnKeyDown from '../../../shared/hooks/useOnKeyDown';
import css from './DropdownMenu.css';

interface DropdownMenuProps<T> {
  selectedItem: T;
  items: T[];
  keyProperty: Extract<keyof T, string>;
  label: string;
  namePropertyExtractor: (item: T) => string;
  onSelect: (item: T) => void;
  className?: string;
}

const DropdownMenu = <T,>({
  selectedItem,
  className,
  items,
  keyProperty,
  label,
  namePropertyExtractor,
  onSelect,
}: DropdownMenuProps<T>) => {
  const menuId = useId();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getItemName = (item: T) => namePropertyExtractor(item);

  const close = () => setIsOpen(false);

  const toggle = () => setIsOpen((state) => !state);

  useOnClickOutside(ref, close);
  useOnKeyDown('Escape', close);

  const renderItem = (item: T) => {
    const handleSelect = () => onSelect(item);
    const isSelected = item[keyProperty] === selectedItem[keyProperty];

    return (
      <button role="menuitem" className={css.button} onClick={handleSelect} disabled={isSelected}>
        {getItemName(item)}
      </button>
    );
  };

  return (
    <div className={classNames(css.container, className)} ref={ref}>
      <button
        type="button"
        className={css.button}
        onClick={toggle}
        aria-haspopup="menu"
        aria-controls={menuId}
        aria-expanded={isOpen}
        aria-label={`${label}: ${getItemName(selectedItem)}`}
      >
        {getItemName(selectedItem)}
        <Icon className={css.icon} name={isOpen ? 'angleUp' : 'angleDown'} />
      </button>
      <div className={classNames(css.menuContainer, { [css.open]: isOpen })}>
        <CustomScrollbar className={css.scrollbar} autoHeight autoHeightMax={500}>
          <List
            role="menu"
            id={menuId}
            className={css.menu}
            itemRenderer={renderItem}
            itemRole="presentation"
            items={items}
            keyProperty={keyProperty}
          />
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default DropdownMenu;
