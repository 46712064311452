import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';

import ModularConfiguratorCoordinatorState from '../ModularConfiguratorCoordinatorState';
import css from './ModularConfigurator.css';
import ModularConfiguratorNavigation from './ModularConfiguratorNavigation/ModularConfiguratorNavigation';
import ModularConfiguratorSection from './ModularConfiguratorSection/ModularConfiguratorSection';

interface ModularConfiguratorProps {
  state: ModularConfiguratorCoordinatorState;
  view: ReactElement;
  preview: ReactElement;
  summary: ReactElement;
  className?: string;
}

const ModularConfigurator = observer(({ className, view, preview, state, summary }: ModularConfiguratorProps) => {
  return (
    <div className={classNames(css.container, className)}>
      <ModularConfiguratorSection className={classNames(css.element, css.view, { [css.active]: !state.isSummary })}>
        {view}
      </ModularConfiguratorSection>
      <ModularConfiguratorSection
        className={classNames(css.element, css.preview, { [css.withSummary]: state.isSummary })}
      >
        {preview}
      </ModularConfiguratorSection>
      <ModularConfiguratorSection
        className={classNames(css.element, css.summary, { [css.active]: state.isSummary })}
        scrollable
      >
        {summary}
      </ModularConfiguratorSection>
      <ModularConfiguratorNavigation
        className={classNames(css.navigation, { [css.hidden]: state.isSummary })}
        state={state}
      />
    </div>
  );
});

export default ModularConfigurator;
