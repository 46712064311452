import { makeObservable, observable } from 'mobx';

import { PaymentSteps } from '../checkout/CheckoutSteps';
import { ICheckoutStep } from '../checkout/ICheckoutStep';
import { IApiClient } from '../data/client';
import { GetOrderWithFamilyDataQuery, OrderData } from '../data/model';
import { BasePageState } from '../shared/BasePageState';
import { StoreState } from '../StoreState';
import { DesignerData, UserStoryData } from './../data/model';
import { SiteTranslation } from './../localization/SiteTranslation';

interface IConfirmationMemento {
  order: OrderData;
  designer: DesignerData;
  userStory: UserStoryData;
}

export class ConfirmationState extends BasePageState<IConfirmationMemento> {
  disableCache = true;

  private client: IApiClient;

  @observable.shallow steps: Array<ICheckoutStep> = [];

  @observable.shallow orderData: OrderData;
  @observable orderNumber: string;
  @observable.shallow designerData: DesignerData;
  @observable.shallow userStoryData: UserStoryData;

  constructor(orderNumber: string, client: IApiClient, pageTranslation: SiteTranslation) {
    super(pageTranslation.confirmationPage);

    makeObservable(this);

    this.steps = [
      PaymentSteps.CreateContactAndBillingStep,
      PaymentSteps.CreatePaymentStep,
      PaymentSteps.CreateConfirmationStep,
    ].map((x) => x(pageTranslation.checkoutPage));

    this.steps.last().isActive = true;

    this.client = client;
    this.orderNumber = orderNumber;
  }

  async onLoad(store: StoreState) {
    const response = await this.client.send(
      new GetOrderWithFamilyDataQuery({
        orderNumber: this.orderNumber,
        shoppingContext: store.shoppingContext,
      }),
    );

    this.initialize(response.order, response.designer, response.userStory);
  }

  getMemento() {
    let memento: IConfirmationMemento = {
      order: this.orderData,
      designer: this.designerData,
      userStory: this.userStoryData,
    };

    return memento;
  }

  restoreMemento(memento: IConfirmationMemento) {
    this.initialize(memento.order, memento.designer, memento.userStory);
  }

  initialize(orderData: OrderData, designer: DesignerData, userStory: UserStoryData) {
    this.orderData = orderData;
    this.designerData = designer;
    this.userStoryData = userStory;
  }
}
