import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';

import Logger from './Logger';
import { Queue } from './Queue';

export interface IScheduledTask {}

export interface ICancellableTask extends IScheduledTask {
  cancelled: boolean;
}

export class TasksSchedulingStrategy {
  public static append<T extends IScheduledTask>(task: T, queue: Queue<T>): void {
    queue.enqueue(task);
  }

  public static replace<T extends IScheduledTask>(task: T, queue: Queue<T>): void {
    queue.dequeueMany();
    queue.enqueue(task);
  }
}

export class Timer {
  private startTime: Date;

  constructor() {
    this.startTime = new Date();
  }

  public get elapsed() {
    return new Date().getTime() - this.startTime.getTime();
  }
}

export class Awaiter {
  private predicate: () => boolean;
  private timeout: number;

  constructor(predicate: () => boolean, timeout: number) {
    this.predicate = predicate;
    this.timeout = timeout;
  }

  public async wait(): Promise<void> {
    const timer = new Timer();

    return new Promise<void>(async (resolve) => {
      while (this.predicate() === false && timer.elapsed < this.timeout) {
        await this.waitFor(5);
      }
      resolve();
    });
  }

  private async waitFor(delay: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, delay));
  }
}

export interface ITasksScheduler<T extends IScheduledTask> {
  run(task: T): void;
}

export class DefaultTasksScheduler<T extends IScheduledTask> implements ITasksScheduler<T> {
  private queue = new Queue<T>();
  private isExecuting = false;

  private executeTask: (task: T) => Promise<void>;
  private scheduleTask: (task: T, queue: Queue<T>) => void;

  constructor(
    executeTask: (task: T) => Promise<void>,
    scheduleTask: (task: T, queue: Queue<T>) => void = TasksSchedulingStrategy.append,
  ) {
    this.executeTask = executeTask;
    this.scheduleTask = scheduleTask;

    this.onTaskEnqueued = this.onTaskEnqueued.bind(this);
    this.queue.itemEnqueued.subscribe(this.onTaskEnqueued);
  }

  private onTaskEnqueued(task: T): void {
    if (this.isExecuting) {
      return;
    }

    this.executeNextTask();
  }

  private async executeNextTask() {
    this.isExecuting = true;

    if (this.queue.empty) {
      this.isExecuting = false;
      return;
    }

    const task = this.queue.dequeue();
    await this.executeTask(task);

    this.executeNextTask();
  }

  public run(task: T) {
    this.scheduleTask(task, this.queue);
  }
}

export class RunLastTasksScheduler<T extends ICancellableTask, U> implements ITasksScheduler<T> {
  private currentTask: T | null = null;

  @observable
  private currentTaskPromise: IPromiseBasedObservable<U> | null = null;

  private executeTask: (task: T) => Promise<U>;

  constructor(executeTask: (task: T) => Promise<U>) {
    makeObservable(this);
    this.executeTask = executeTask;

    reaction(
      () => this.currentTaskPromise?.state === 'fulfilled',
      (isFulfilled) => {
        if (!isFulfilled) {
          return;
        }

        this.setCurrentTask(null);
        this.setCurrentTaskPromise(null);
      },
    );
  }

  public async run(task: T) {
    if (this.currentTask != null) {
      this.currentTask.cancelled = true;
    }

    try {
      const taskPromise = this.executeTask(task);
      this.setCurrentTask(task);
      this.setCurrentTaskPromise(taskPromise);

      await taskPromise;
    } catch (error) {
      Logger.error(`Unable to process the task. Error: ${JSON.stringify(error, undefined, 4)}`);
    }
  }

  @computed
  public get loading() {
    return this.currentTaskPromise?.state === 'pending';
  }

  @computed
  public get error() {
    return this.currentTaskPromise?.state === 'rejected';
  }

  @action.bound
  private setCurrentTask(task?: T) {
    this.currentTask = task;
  }

  @action.bound
  private setCurrentTaskPromise(promise?: Promise<U>) {
    this.currentTaskPromise = promise ? fromPromise(promise) : null;
  }
}
