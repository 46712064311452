import classNames from 'classnames';
import { Component } from 'react';

import { DaysRangeData, MoneyData } from '../../../data/model';
import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import Price from '../../../shared/components/Price';
import TextInfo from '../../../shared/components/TextInfo/TextInfo';
import { createDeliveredWithinText } from './createDeliveryTimeText';
import css from './DeliveryFee.css';

interface DeliveryFeeProps {
  className?: string;
  deliveryTimeClassName?: string;
  translation: ShoppingCartSummaryTranslation;
  shippingTotal: MoneyData;
  shippingCostAvailable: boolean;
  deliveryTime?: DaysRangeData;
}

export class DeliveryFee extends Component<DeliveryFeeProps, {}> {
  render() {
    const { translation, shippingTotal, className, deliveryTimeClassName, shippingCostAvailable, deliveryTime } =
      this.props;
    const freeDelivery = shippingTotal.amount === 0;
    const deliveredWithinText = createDeliveredWithinText(deliveryTime, translation.shippingTimeText);

    return (
      <div className={classNames(css.DeliveryFee, className)}>
        <p className={classNames(css.content)}>
          <span className={css.text}>{translation.deliveryFee}</span>
          <span className={css.fee}>
            {shippingCostAvailable ? (
              freeDelivery ? (
                translation.freeDeliveryText
              ) : (
                <Price price={shippingTotal} />
              )
            ) : (
              translation.deliveryCostUnavailable
            )}
          </span>
        </p>
        {deliveredWithinText && (
          <TextInfo className={classNames(css.deliveryTime, deliveryTimeClassName)}>{deliveredWithinText}</TextInfo>
        )}
      </div>
    );
  }
}
