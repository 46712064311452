import * as React from 'react';

import { EmptyScreenTranslation } from '../../../../localization/SiteTranslation';
import css from './EmptyScreen.css';

interface EmptyScreenProps {
  translation: EmptyScreenTranslation;
}

const EmptyScreen: React.FC<EmptyScreenProps> = ({ translation }) => {
  return (
    <div className={css.EmptyScreen}>
      <div className={css.image} />
      <h2 className={css.title}>{translation.title}</h2>
      <p className={css.description}>{translation.description}</p>
    </div>
  );
};

export default EmptyScreen;
