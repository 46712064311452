import { GalleryBreakpoint } from '../../../app/shared/components/Gallery/Gallery';
import ArticleList from './components/ArticleList/ArticleList';
import Header from './components/Header/Header';
import ImageGallery from './components/ImageGallery/ImageGallery';
import InfoListBanner from './components/InfoListBanner/InfoListBanner';
import ProductList from './components/ProductList/ProductList';
import { HomePageState } from './HomePageState';

const GALLERY_BREAKPOINTS: GalleryBreakpoint[] = [
  { minWidth: 694, itemsPerRow: 3 },
  { minWidth: 0, itemsPerRow: 2 },
];

const GALLERY_MAX_ROW_COUNT = 2;

interface HomePageProps {
  state: HomePageState;
}

const HomePage = ({ state }: HomePageProps) => {
  const { pageContent } = state;

  return (
    <article>
      <h1 className="visuallyHidden">{state.translation.pageTitleFormat}</h1>
      {pageContent?.header && (
        <Header
          backgroundColor={pageContent.header.backgroundColor}
          textColor={pageContent.header.textColor}
          slides={pageContent.header.slides}
        />
      )}
      {pageContent?.benefitList && (
        <InfoListBanner items={pageContent.benefitList.items} title={pageContent.benefitList.title} />
      )}
      {state.productThumbnails.length > 0 && (
        <ProductList
          items={state.productThumbnails}
          title={pageContent?.productList?.title}
          translation={state.translation.productList}
        />
      )}
      {pageContent?.gallery && (
        <ImageGallery
          breakpoints={GALLERY_BREAKPOINTS}
          maxRowCount={GALLERY_MAX_ROW_COUNT}
          state={state.gallery}
          withBackground={pageContent.gallery.backgroundEnabled}
          backgroundColor={pageContent.gallery.backgroundColor}
        />
      )}
      {pageContent?.articleList && (
        <ArticleList items={pageContent.articleList.articles} translation={state.translation.articleList} />
      )}
    </article>
  );
};

export default HomePage;
