import { action, computed, makeObservable, observable } from 'mobx';

export interface ILoadingIndicator {
  isLoading: boolean;

  start(): void;

  stop(): void;

  reset(): void;
}

export class LoadingIndicator implements ILoadingIndicator {
  @observable activeAsyncTasks: number = 0;

  constructor() {
    makeObservable(this);
  }

  @computed get isLoading() {
    return this.activeAsyncTasks > 0;
  }

  @action start() {
    this.activeAsyncTasks++;
  }

  @action stop() {
    this.activeAsyncTasks--;
  }

  @action reset() {
    this.activeAsyncTasks = 0;
  }
}
