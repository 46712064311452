import Heading from '../shared/components/Typography/Heading/Heading';
import useMobileViewDetector from '../shared/hooks/useMobileViewDetector';
import MobileConfigurator from './components/MobileConfigurator/MobileConfigurator';
import ModularConfiguratorCoordinator from './components/ModularConfiguratorCoordinator/ModularConfiguratorCoordinator';
import css from './ModularConfiguratorPage.css';
import ModularConfiguratorPageState from './ModularConfiguratorPageState';

interface ModularConfiguratorPageProps {
  state: ModularConfiguratorPageState;
}

const ModularConfiguratorPage = ({ state }: ModularConfiguratorPageProps) => {
  const shouldUseMobileView = useMobileViewDetector();

  return (
    <article className={css.container}>
      <Heading level={1} visuallyHidden>
        {/* TODO: Replace with a translation */}
        Modular Configurator
      </Heading>
      {shouldUseMobileView ? (
        <MobileConfigurator className={css.configurator} />
      ) : (
        <ModularConfiguratorCoordinator className={css.configurator} state={state.configuratorCoordinator} />
      )}
    </article>
  );
};

export default ModularConfiguratorPage;
