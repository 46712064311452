import { action, makeObservable, observable } from 'mobx';

import { ConfiguratorState } from './configurator/ConfiguratorState';
import * as Api from './data/model';
import { EmbeddedQueryParser } from './shared/EmbeddedQueryParser';
import { IEventAggregator } from './shared/EventAggregator';
import { Navigation } from './shared/NavigationService';

export class StoreState {
  storeResponse: Api.GetStoreContextQueryResponse;

  @observable
  shoppingContext: Api.ShoppingContext;

  @observable
  shoppingCartSummary: Api.ShoppingCartSummaryData;

  @observable.ref
  favoriteConfigurationCodes: string[] = [];

  constructor(response: Api.GetStoreContextQueryResponse, eventAggregator: IEventAggregator) {
    makeObservable(this);
    this.storeResponse = response;
    this.shoppingContext = response.shoppingContext;
    this.shoppingCartSummary = response.shoppingCartSummary;
    this.favoriteConfigurationCodes = response.favoritesSummary.configurationCodes;
  }

  public get areOtherStoresAvailable() {
    return this.storeResponse.availableStores.length > 1;
  }

  public get shouldDisplayDecimalPartOfPrices() {
    return this.storeResponse.storeContext.featureToggles.displayDecimalPartOfPrices;
  }

  public canBePurchased(baseModel: Api.IBaseModel) {
    const { enablePurchase, enablePurchaseForBrands } = this.storeResponse.storeContext.featureToggles;
    const isPurchaseEnabledForModelBrand =
      enablePurchaseForBrands.empty() || enablePurchaseForBrands.includes(baseModel.brandId);

    return (
      enablePurchase &&
      isPurchaseEnabledForModelBrand &&
      baseModel.availableInCurrentStore &&
      baseModel.canBePurchased &&
      this.canShowPrices()
    );
  }

  public isShowroom() {
    const context = this.storeResponse.storeContext;
    return context.featureToggles.enableShowroom;
  }

  public isIdleRedirectionEnabled() {
    const context = this.storeResponse.storeContext;
    return (
      context.featureToggles.enableShowroom &&
      !!context.showroomConfig.homePageUrl &&
      !!context.showroomConfig.redirectionDelay
    );
  }

  public showConfiguratorShare() {
    return this.storeResponse.storeContext.featureToggles.enableConfigurationShare;
  }

  public showWhereToBuy() {
    return (
      this.storeResponse.storeContext.featureToggles.enableWhereToBuy &&
      !!this.storeResponse.storeContext.whereToBuyConfig.pageUrl
    );
  }

  public showFindLocalDealer() {
    return (
      this.storeResponse.storeContext.featureToggles.enableFindLocalDealer &&
      !EmbeddedQueryParser.toModel(Navigation.instance.currentUrl.query).embed
    );
  }

  public canEmailMyConfiguration() {
    const context = this.storeResponse.storeContext;
    return (
      context.featureToggles.enableEmailMyConfiguration &&
      !!context.emailMeConfigurationConfig.portalId &&
      !!context.emailMeConfigurationConfig.formId
    );
  }

  public canRequestQuote() {
    const context = this.storeResponse.storeContext;

    return (
      context.featureToggles.enableQuoteRequest &&
      !!context.requestQuoteConfig.formId &&
      !!context.requestQuoteConfig.portalId
    );
  }

  public canShowPrices() {
    return this.storeResponse.storeContext.featureToggles.enablePrices;
  }

  public availableLanguages() {
    return this.storeResponse.storeContext.store.languages;
  }

  public currentLanguageCode() {
    return this.storeResponse.storeContext.language.code;
  }

  public canShowExpectedShipment(configurator: ConfiguratorState) {
    return configurator.preparingTime && this.storeResponse.storeContext.featureToggles.enableExpectedShipmentDate;
  }

  public canCustomiseWebShopProduct() {
    return this.storeResponse.storeContext.featureToggles.enableWebShopProductCustomization;
  }

  public canPurchaseWebShopProduct() {
    return this.storeResponse.storeContext.featureToggles.enableWebShopPurchase;
  }

  @action.bound
  public setShoppingCartSummary(summary: Api.ShoppingCartSummaryData) {
    this.shoppingCartSummary = summary;
  }

  @action.bound
  public setCustomerName(username: string) {
    this.shoppingContext.customer.userName = username;
  }

  @action.bound
  public setFavoriteConfigurationCodes(codes: string[]) {
    this.favoriteConfigurationCodes = codes;
  }
}
