import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import FormInput, { FormInputProps } from '../../../../shared/components/FormInput';
import { withIdPrefix } from './withIdPrefix';

type RecipientProps = FormInputProps;

@observer
class Recipient extends Component<RecipientProps> {
  render() {
    const { className, label, inputClassName, ...otherProps } = this.props;

    return (
      <FormInput
        label={label || 'Recipient'}
        className={className}
        inputClassName={classNames(inputClassName)}
        {...otherProps}
      />
    );
  }
}

export default withIdPrefix(Recipient, 'recipient');
