import { observer } from 'mobx-react';
import { Component } from 'react';

import { RelatedProductsTranslation } from '../../../localization/SiteTranslation';
import Image from '../../../shared/components/Image/Image';
import Link from '../../../shared/components/Link';
import HeaderLevel1 from '../../../shared/components/Typography/HeaderLevel1';
import css from './RelatedProducts.css';
import { RelatedProduct, RelatedProductsState } from './RelatedProductsState';

interface RelatedProductsProps {
  state: RelatedProductsState;
  translation: RelatedProductsTranslation;
}

@observer
export default class RelatedProducts extends Component<RelatedProductsProps, {}> {
  constructor(props: RelatedProductsProps) {
    super(props);
  }

  renderItem(product: RelatedProduct) {
    return (
      <div className={css.product} key={product.modelName}>
        <Link to={product.link} key={product.familyName} className={css.linkImage}>
          <Image src={product.image} width={200} height={360} className={css.image} size="contain" />
          <div className={css.infoContainer}>
            <span className={css.brand}>{product.brand}</span>
            <span>{product.familyName}</span>
          </div>
        </Link>
      </div>
    );
  }

  render() {
    const { state } = this.props;

    if (state.loadingIndicator.isLoading) {
      return <div>Loading ...</div>;
    }

    return (
      <div className={css.RelatedProducts}>
        <HeaderLevel1 className={css.title}>{this.props.translation.title}</HeaderLevel1>
        <div className={css.products}>{state.products.map((x) => this.renderItem(x))}</div>
      </div>
    );
  }
}
