import * as React from 'react';
import { Fragment } from 'react';

import { ShoppingCartItemData } from '../../../data/model';
import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import ComponentList from '../../../shared/components/ComponentList/ComponentList';
import { ExpandablePanel } from '../../../shared/components/ExpandablePanel/ExpandablePanel';
import Icon from '../../../shared/components/Icon/Icon';
import css from './ShoppingCartSummaryCartItem.css';
import { SummaryItem } from './SummaryItem';

interface BasicShoppingCartSummaryCartItemProps {
  code: string;
  name: string;
  quantity: string;
  imageUrl?: string;
  translation: ShoppingCartSummaryTranslation;
  children?: React.ReactNode;
}

interface ShoppingCartSummaryCartItemProps {
  item: ShoppingCartItemData;
  translation: ShoppingCartSummaryTranslation;
  expanded: boolean;
}

export class ProductSummaryItem extends React.Component<BasicShoppingCartSummaryCartItemProps> {
  render() {
    const { children, translation, code, name, quantity, imageUrl } = this.props;
    return (
      <div className={css.ShoppingCartSummaryCartItem}>
        <SummaryItem
          translation={translation}
          item={{
            imageUrlOverride: imageUrl,
            code: code,
            name: name,
            quantity: quantity,
          }}
        />
        {children}
      </div>
    );
  }
}

export default class ShoppingCartSummaryCartItem extends React.Component<ShoppingCartSummaryCartItemProps> {
  constructor(props: ShoppingCartSummaryCartItemProps) {
    super(props);
  }

  render() {
    const { item, translation, expanded } = this.props;
    const enableShowMore = item.options.length > 0 || item.options.length > 0;

    const renderPanelButton = (isExpanded: boolean) => (
      <div className={css.buttonContainer}>
        <button className={css.button}>
          {isExpanded ? (
            <Fragment>
              {translation.lessInformationText} <Icon name="minus" className={css.icon} />
            </Fragment>
          ) : (
            <Fragment>
              {translation.moreInformationText} <Icon name="plus" className={css.icon} />
            </Fragment>
          )}
        </button>
      </div>
    );

    return (
      <ProductSummaryItem
        translation={translation}
        code={item.code}
        name={item.baseModel.name}
        quantity={item.quantity.toString()}
      >
        {enableShowMore && (
          <ExpandablePanel
            className={css.moreInformationSection}
            contentClassName={css.items}
            renderTitle={renderPanelButton}
            expanded={expanded}
          >
            <ComponentList accessories={item.accessories} options={item.options} />
          </ExpandablePanel>
        )}
        {!enableShowMore && <div className={css.moreInformationPlaceholder} />}
      </ProductSummaryItem>
    );
  }
}
