import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useId, useRef } from 'react';

import { StackedWarnings } from '../../../../app/layout/Warnings/StackedWarnings';
import TextButton from '../../../../app/shared/components/Button/TextButton';
import Icon from '../../../../app/shared/components/Icon/Icon';
import useOnClickOutside from '../../../../app/shared/hooks/useOnClickOutside';
import useOnKeyDown from '../../../../app/shared/hooks/useOnKeyDown';
import StoreDisplayName from '../StoreDisplayName/StoreDisplayName';
import StoreChangeFailedWarning from './StoreCannotBeChangedWarning/StoreChangeFailedWarning';
import css from './StoreSelector.css';
import StoreSelectorOptions from './StoreSelectorOptions/StoreSelectorOptions';
import StoreSelectorState from './StoreSelectorState';

interface OrganizationSelectorProps {
  state: StoreSelectorState;
  className?: string;
}

const StoreSelector = ({ className, state }: OrganizationSelectorProps) => {
  const menuId = useId();
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, state.close);
  useOnKeyDown('Escape', state.close);

  return (
    <div className={classNames(css.element, className)} ref={ref}>
      <TextButton
        className={css.button}
        loaderClassName={css.loader}
        type="button"
        aria-controls={menuId}
        aria-expanded={state.isOpen}
        aria-haspopup="menu"
        onClick={state.toggle}
        isLoading={state.changeStoreCommand.processing}
        disabled={state.changeStoreCommand.disabled || state.changeStoreCommand.processing}
      >
        <span className={css.inner}>
          <StoreDisplayName storeId={state.currentStore.id} storeName={state.currentStore.displayName} />
          <Icon className={css.icon} name={state.isOpen ? 'angleUp' : 'angleDown'} />
        </span>
      </TextButton>
      <div role="menu" id={menuId} className={classNames(css.options, { [css.expanded]: state.isOpen })}>
        <StoreSelectorOptions options={state.stores} onClick={state.select} />
      </div>

      <StackedWarnings>
        <StoreChangeFailedWarning
          state={state.storeChangeFailedWarning}
          translation={state.translation.storeChangeFailedWarning}
        />
      </StackedWarnings>
    </div>
  );
};

export default observer(StoreSelector);
