import { action, makeObservable, observable } from 'mobx';

import AutocompleteCityBehavior from '../../app/checkout/components/ShippingAddresses/DynamicInputs/AutocompleteCityBehavior';
import AutocompleteCityBehaviorFactory from '../../app/checkout/components/ShippingAddresses/DynamicInputs/AutocompleteCityBehaviourFactory';
import ZipCodeValidationFactory from '../../app/checkout/components/ShippingAddresses/Validation/ZipCodeValidationFactory';
import ZipCodeFormatterFactory from '../../app/checkout/components/ShippingAddresses/ZipCodeFormatterFactory';
import { IApiClient } from '../../app/data/client';
import {
  CountryData,
  DealerDeliveryAddressData,
  GetDealerDefaultAddressQuery,
  ShoppingCartData,
  ShoppingContext,
} from '../../app/data/model';
import { Form, TextInput, ValueMaxLength, ValueRequiredRule } from '../../app/shared/Form';
import { LoadingIndicator } from '../../app/shared/LoadingIndicator';
import { DeliveryAddressFormTranslation } from '../localization/SiteTranslation';

export class DeliveryAddressFormState extends Form {
  private defaultAddress?: DealerDeliveryAddressData;

  @observable
  showPreview = false;

  initializationIndicator = new LoadingIndicator();

  erpAddressNumber?: string;
  recipient: TextInput;
  addressLine1: TextInput;
  addressLine2: TextInput;
  zipCode: TextInput;
  city: TextInput;
  autocompleteCityBehavior?: AutocompleteCityBehavior;

  @observable
  country: CountryData;

  constructor(
    private readonly context: ShoppingContext,
    private readonly client: IApiClient,
    private readonly translation: DeliveryAddressFormTranslation,
  ) {
    super();

    makeObservable(this);

    this.recipient = new TextInput()
      .withRule(new ValueRequiredRule(translation.recipient.fieldRequired))
      .withRule(new ValueMaxLength(36));
    this.addressLine1 = new TextInput()
      .withRule(new ValueRequiredRule(translation.addressLine1.fieldRequired))
      .withRule(new ValueMaxLength(36));
    this.addressLine2 = new TextInput().withRule(new ValueMaxLength(36));
    this.zipCode = new TextInput().withRule(new ValueRequiredRule(translation.zipCode.fieldRequired));
    this.city = new TextInput()
      .withRule(new ValueRequiredRule(translation.city.fieldRequired))
      .withRule(new ValueMaxLength(20));
    this.country = { code: '', name: '' };
  }

  public getAddress(): DealerDeliveryAddressData {
    const deliveryAddress: DealerDeliveryAddressData = {
      recipient: this.recipient.value,
      line1: this.addressLine1.value,
      line2: this.addressLine2.value,
      city: this.city.value,
      zipCode: this.zipCode.value,
      country: this.country,
    };

    const isTheSameAsDefault =
      !!this.defaultAddress &&
      deliveryAddress.recipient === this.defaultAddress.recipient &&
      deliveryAddress.line1 === this.defaultAddress.line1 &&
      deliveryAddress.line2 === this.defaultAddress.line2 &&
      deliveryAddress.city === this.defaultAddress.city &&
      deliveryAddress.zipCode === this.defaultAddress.zipCode;

    deliveryAddress.erpNumber = isTheSameAsDefault ? this.defaultAddress.erpNumber : null;

    return deliveryAddress;
  }

  public async initialize(cart: ShoppingCartData) {
    this.initializationIndicator.start();
    const response = await this.client.send(
      new GetDealerDefaultAddressQuery({
        shoppingContext: this.context,
        brandId: cart.brandId,
        factoryId: cart.factoryId,
      }),
    );
    this.initializationIndicator.stop();

    if (response.defaultAddress) {
      this.defaultAddress = response.defaultAddress;

      this.erpAddressNumber = this.defaultAddress.erpNumber;
      this.recipient.setDefaultValue(this.defaultAddress.recipient);
      this.addressLine1.setDefaultValue(this.defaultAddress.line1);
      this.addressLine2.setDefaultValue(this.defaultAddress.line2);
      this.city.setDefaultValue(this.defaultAddress.city);
      this.zipCode.setDefaultValue(this.defaultAddress.zipCode);
      this.setCountry(this.defaultAddress.country);

      if (this.isValid) {
        this.setShowPreview(true);
      } else {
        this.showForm();
      }
    } else {
      this.setCountry(response.defaultCountry);
      this.showForm();
    }

    const zipCodeValidation = ZipCodeValidationFactory.create(this.country.code, this.translation.zipCode.wrongFormat);

    if (zipCodeValidation) {
      this.zipCode.withRule(zipCodeValidation);
    }

    this.zipCode.withFormatter(ZipCodeFormatterFactory.create(this.country.code));

    const autocompleteCityBehavior = AutocompleteCityBehaviorFactory.create(
      this.client,
      this.country.code,
      this.zipCode,
      this.city,
    );

    if (autocompleteCityBehavior) {
      this.autocompleteCityBehavior = autocompleteCityBehavior;
    }
  }

  showForm() {
    this.setShowPreview(false);
    this.inputsToValidate.push(this.recipient, this.addressLine1, this.addressLine2, this.zipCode, this.city);
  }

  @action
  setShowPreview(showPreview: boolean) {
    this.showPreview = showPreview;
  }

  @action
  setCountry(country: CountryData) {
    this.country = country;
  }

  @action.bound
  add() {
    this.erpAddressNumber = null;
    this.recipient.setDefaultValue('');
    this.addressLine1.setDefaultValue('');
    this.addressLine2.setDefaultValue('');
    this.city.setDefaultValue('');
    this.zipCode.setDefaultValue('');
    this.showForm();
  }

  @action.bound
  edit() {
    this.erpAddressNumber = null;
    this.showForm();
  }
}
