import { useEffect, useState } from 'react';

interface MarkerProps extends Pick<google.maps.MarkerOptions, 'clickable' | 'icon' | 'map'> {
  position: google.maps.LatLngLiteral;
  label?: google.maps.MarkerLabel;
  onClick?: () => void;
}

const Marker = ({ clickable, icon, label, map, onClick, position }: MarkerProps): null => {
  const [marker, setMarker] = useState<google.maps.Marker>(null);

  useEffect(() => {
    if (!marker) {
      setMarker(
        new google.maps.Marker({
          clickable,
          icon,
          label,
          map,
          position,
        }),
      );
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({ icon, label, position });
    }
  }, [(icon as google.maps.Icon)?.url, label?.text, marker, position.lat, position.lng]);

  useEffect(() => {
    if (marker) {
      if (onClick) {
        marker.addListener('click', onClick);
      }
    }
  }, [marker, onClick]);

  return null;
};

export default Marker;
