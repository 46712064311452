import Cookies from 'universal-cookie';
import { CustomerData } from './model';
import { ICookieOptionsProvider } from './StoreProviders/CookieOptionsProvider';

export interface ILocalStorage {
  get<T>(key: string): T;

  set<T>(key: string, value: T): void;
}

export class CookieBasedStorage implements ILocalStorage {
  private cookies: Cookies;
  private maxAgeInSeconds: number; // TODO: can be refactored into ICookieLifetime time with different strategies
  private cookieOptionsProvider?: ICookieOptionsProvider;

  constructor(cookies: Cookies, maxAgeInSeconds: number, cookieOptionsProvider?: ICookieOptionsProvider) {
    this.cookies = cookies;
    this.maxAgeInSeconds = maxAgeInSeconds;
    this.cookieOptionsProvider = cookieOptionsProvider;
  }

  get<T>(key: string): T {
    return this.cookies.get<T>(key);
  }

  set<T>(key: string, value: T): void {
    const cookieOptions = this.cookieOptionsProvider?.getOptions(key) ?? {};
    this.cookies.set(key, value, { maxAge: this.maxAgeInSeconds, path: '/', ...cookieOptions });
  }
}

export interface ICustomerContextLocalStorage {
  getAnonymousCustomer(): CustomerData;

  setAnonymousCustomer(data: CustomerData): void;
}

export class CustomerContextLocalStorage implements ICustomerContextLocalStorage {
  private storageKey: string;
  private storage: ILocalStorage;

  constructor(storageKey: string, storage: ILocalStorage) {
    this.storageKey = storageKey;
    this.storage = storage;
  }

  getAnonymousCustomer(): CustomerData {
    return this.storage.get<CustomerData>(this.storageKey);
  }

  setAnonymousCustomer(data: CustomerData): void {
    this.storage.set<CustomerData>(this.storageKey, data);
  }
}

export class MaxAge {
  public static inDays(days: number) {
    return days * 86400;
  }
}
