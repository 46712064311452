import classNames from 'classnames';

import { FooterEmailAddressData, FooterPhoneNumberData } from '../../../data/model';
import css from './ContactSection.css';

interface ContactSectionProps {
  address: string;
  emailAddress: FooterEmailAddressData;
  phoneNumber: FooterPhoneNumberData;
  className?: string;
}

const ContactSection = ({ className, address, emailAddress, phoneNumber }: ContactSectionProps) => {
  const shouldRender = Boolean(address || emailAddress.value || phoneNumber.value);

  return shouldRender ? (
    <address className={classNames(css.container, className)}>
      {address && <span className={css.info}>{address}</span>}

      {emailAddress.value && (
        <span className={css.info}>
          {emailAddress.label && `${emailAddress.label}: `}
          <a href={`mailto:${emailAddress.value}`}>{emailAddress.value}</a>
        </span>
      )}

      {phoneNumber.value && (
        <span className={css.info}>
          {phoneNumber.label && `${phoneNumber.label}: `}
          <a href={`tel:${phoneNumber.value}`}>{phoneNumber.formattedValue ?? phoneNumber.value}</a>
        </span>
      )}
    </address>
  ) : null;
};

export default ContactSection;
