import { action, makeObservable, observable } from 'mobx';

import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { BasePageState } from '../../app/shared/BasePageState';
import { IMemento } from '../../app/shared/common';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreState } from '../../app/StoreState';
import { LoginPageTranslation } from '../localization/SiteTranslation';

export class LoginState extends BasePageState<IMemento> {
  @observable
  public disableContent: boolean = false;

  constructor(
    private readonly authentication: IAuthenticationService,
    private readonly navitation: INavigationService,
    public readonly translation: LoginPageTranslation,
  ) {
    super({ pageTitleFormat: 'Login', pageDescriptionFormat: null });
    makeObservable(this);
  }

  @action
  async onLoad(store: StoreState) {
    const direct = !!this.navitation.currentUrl.query.get('direct');
    this.disableContent = direct;

    if (direct) {
      this.login();
    }
  }

  public login = async () => {
    const returnTo = this.navitation.currentUrl.query.get('returnTo');
    await this.authentication.login(returnTo);
  };
}
