import { TabData } from '../../data/model';
import { AnimatedChairState } from './AnimatedChair/AnimatedChairState';
import { AnimatedChairNavigationState } from './AnimatedChairNavigation/AnimatedChairNavigation';
import { AnimatedChairSliderNavigationState } from './AnimatedChairSliderNavigation/AnimatedChairSliderNavigation';
import { AnimatedPanelState } from './AnimatedPanel/AnimatedPanelState';
import { AnimatedPriceState } from './AnimatedPrice/AnimatedPriceState';
import { AnimatedProductModelsPanelState } from './AnimatedProductModelsPanel/AnimatedProductModelsPanelState';
import { AnimatedSummaryTabState } from './AnimatedSummaryTab/AnimatedSummaryTabState';

interface IAnimatedTabContent {
  [key: string]: AnimatedPanelState;
}

export class AnimatedElementsState {
  public tabContainer: AnimatedPanelState = new AnimatedPanelState();
  public summary: AnimatedSummaryTabState = new AnimatedSummaryTabState();
  public chair: AnimatedChairState = new AnimatedChairState();
  public productModels: AnimatedProductModelsPanelState = new AnimatedProductModelsPanelState();
  public tabsContent: IAnimatedTabContent;
  public chairNavigation: AnimatedChairNavigationState = new AnimatedChairNavigationState();
  public chairSliderNavigation: AnimatedChairSliderNavigationState = new AnimatedChairSliderNavigationState();
  public price: AnimatedPriceState = new AnimatedPriceState();

  constructor(tabs: Array<TabData>) {
    this.tabsContent = Object.assign(
      {},
      ...tabs.map((tab) => {
        return { [tab.code]: new AnimatedPanelState() };
      }),
    );
  }
}
