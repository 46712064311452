import { OrderData, ShoppingContext } from '../../data/model';
import ProductDataConverter from './ProductDataConverter';
import { PurchaseEventData } from './TrackingService';

class PurchaseEventDataConverter {
  public static convert(context: ShoppingContext, order: OrderData, coupon?: string): PurchaseEventData {
    return {
      context: context,
      orderNumber: order.number,
      revenue: order.totalWithTax.amount,
      shipping: order.totalShippingWithTax.amount,
      tax: order.taxTotal.amount,
      coupon: coupon,
      products: order.items.map(ProductDataConverter.fromOrderItemData),
    };
  }
}

export default PurchaseEventDataConverter;
