import { Component, Fragment } from 'react';

import { FooterData } from '../../data/model';
import Link from '../../shared/components/Link';
import RawHtml from '../../shared/components/RawHtml';
import ExternalLinks from '../../shared/externalLinks';

export class ContactSection extends Component<{
  translation: {
    emailLabel: string;
    phoneLabel: string;
  };
  footerData?: FooterData;
}> {
  render() {
    const { footerData, translation } = this.props;
    if (footerData && footerData.contact) {
      return <RawHtml content={footerData.contact} />;
    } else {
      return (
        <Fragment>
          <div>
            {translation.emailLabel}
            <Link href="mailto:info-no@flokk.com">info-no@flokk.com</Link>
          </div>
          <div>
            {translation.phoneLabel}
            <Link href="tel:+4722595900">+47 22 59 59 00</Link>
          </div>
          <Link href={ExternalLinks('flokk')}>www.flokk.com</Link>
        </Fragment>
      );
    }
  }
}
