import { RefObject, useEffect } from 'react';

import isChildOf from '../isChildOf';

function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: () => void,
  exceptionRefs?: RefObject<HTMLElement>[],
): void {
  useEffect(() => {
    const listener: EventListenerOrEventListenerObject = (event) => {
      const target = event.target as HTMLElement;

      if (isChildOf(target, ref.current) || exceptionRefs?.some((item) => isChildOf(target, item.current))) {
        return;
      }

      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, exceptionRefs]);
}

export default useOnClickOutside;
