import classNames from 'classnames';
import * as React from 'react';
import { Fragment, MouseEvent } from 'react';

import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import css from './Button.css';

export interface ButtonProps<T extends HTMLButtonElement = HTMLButtonElement> extends React.ButtonHTMLAttributes<T> {
  onClick?: (e?: React.MouseEvent<{}>) => void;
  className?: string;
  disabled?: boolean;
  itemElement?: string | React.ComponentType<ButtonProps> | React.ReactElement<ButtonProps>;
  children?: React.ReactNode;
  isLoading?: boolean;
  loaderDefaultColor?: boolean;
  error?: boolean;
  key?: string;
}

class Button extends React.Component<ButtonProps> {
  constructor(props: ButtonProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e: MouseEvent<EventTarget>) {
    const { onClick } = this.props;

    if (onClick) {
      if (e) {
        e.preventDefault();
      }
      onClick(e);
    }
  }

  renderChildren(children: React.ReactNode) {
    const { loaderDefaultColor, isLoading } = this.props;
    return isLoading ? (
      <Fragment>
        <ThreeDotsLoader className={css.loader} color={loaderDefaultColor ? 'default' : 'white'} size="small" />
        <div className={css.hide}>{children}</div>
      </Fragment>
    ) : (
      children
    );
  }

  render() {
    const { children, className, disabled, type, key, itemElement, isLoading, error, onClick } = this.props;

    const buttonProps = {
      type,
      id: key,
      disabled: !!disabled || !!isLoading,
      className: classNames(css.Button, disabled && !isLoading && css.disabled, error && css.error, className),
      'aria-controls': this.props['aria-controls'],
      'aria-expanded': this.props['aria-expanded'],
      'aria-haspopup': this.props['aria-haspopup'],
      'aria-labelledby': this.props['aria-labelledby'],
      'aria-pressed': this.props['aria-pressed'],
    };

    if (React.isValidElement(itemElement)) {
      return React.cloneElement(
        itemElement,
        { ...buttonProps, onClick: onClick },
        this.renderChildren(itemElement.props.children),
      );
    } else {
      return React.createElement(
        itemElement || 'button',
        { ...buttonProps, onClick: this.handleClick },
        this.renderChildren(children),
      );
    }
  }
}

export default Button;
