import { observer } from 'mobx-react';
import React, { Component } from 'react';

import { ProductFamilyState } from '../../app/catalog/CatalogPageState';
import FilterBar from '../../app/catalog/FilterBar/FilterBar';
import ResultsNotFoundMessage from '../../app/catalog/ResultsNotFoundMessage/ResultsNotFoundMessage';
import SelectedFilters from '../../app/catalog/SelectedFilters/SelectedFilters';
import ScrollToTopButton from '../../app/shared/components/Button/ScrollToTopButton';
import ExtendedPagination from '../../app/shared/components/ExtendedPagination/ExtendedPagination';
import List from '../../app/shared/components/List/List';
import ThreeDotsLoader from '../../app/shared/components/Loaders/ThreeDotsLoader';
import { AppState } from '../AppState';
import css from './CatalogPage.css';
import { CatalogPageState } from './CatalogPageState';
import FamilyItem from './FamilyItem/FamilyItem';
import FamilyItemAvailabilityModal from './FamilyItemAvailabilityModal/FamilyItemAvailabilityModal';

export interface CatalogPageProps {
  appState: AppState;
  state: CatalogPageState;
}

@observer
class CatalogPage extends Component<CatalogPageProps, {}> {
  constructor(props: CatalogPageProps) {
    super(props);
    this.renderFamilyGroup = this.renderFamilyGroup.bind(this);
  }

  get translation() {
    return this.props.appState.translation.catalogPage;
  }

  get commonTranslation() {
    return this.props.appState.translation.common;
  }

  renderFamilyGroup(family: ProductFamilyState) {
    const {
      state: { familyItemAvailabilityModal },
    } = this.props;

    return (
      family.models.any() && (
        <section className={css.familyGroup}>
          <header className={css.groupName}>
            <strong>{family.brand}</strong> {family.shortName}
          </header>
          <List
            className={css.familyList}
            itemClassName={css.familyItemContainer}
            items={family.models}
            itemRenderer={(x) => <FamilyItem item={x} openAvailabilityModal={familyItemAvailabilityModal.openModal} />}
            keyProperty="url"
          />
        </section>
      )
    );
  }

  renderFamilies() {
    const { state } = this.props;
    return (
      <List items={state.families} itemRenderer={this.renderFamilyGroup} itemClassName={css.familyGroupContainer} />
    );
  }

  render() {
    const { state } = this.props;
    const { selection } = this.props.state.filters;

    return (
      <>
        <main className={css.CatalogPage}>
          <FilterBar
            containerClassName={css.filtersBarContainer}
            translation={this.translation.filterBar}
            filters={state.filters.filters}
            resultsNumber={state.pagination.totalCount}
          />
          {selection.properties.any() && (
            <SelectedFilters
              className={css.selectedFilters}
              selection={selection}
              translation={this.commonTranslation.tags}
            />
          )}
          <div className={css.content}>
            {state.loadingIndicator.isLoading && (
              <div className={css.loaderContainer}>
                <ThreeDotsLoader className={css.loader} size="large" />
              </div>
            )}
            {this.renderFamilies()}
            {state.families.empty() && <ResultsNotFoundMessage message={this.translation.noResults} />}
            <ScrollToTopButton translation={{ button: 'Scroll to the top' }} />
          </div>

          <ExtendedPagination state={state.pagination} translation={this.translation.pagination} />
        </main>

        <FamilyItemAvailabilityModal
          modal={state.familyItemAvailabilityModal}
          translation={this.translation.familyItemAvailabilityModal}
        />
      </>
    );
  }
}

export default CatalogPage;
