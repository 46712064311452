import React, { ComponentProps } from 'react';

import Marker from '../Marker/Marker';
import css from './Cluster.css';

const ICON: google.maps.Symbol = {
  path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
  fillColor: '#1F6E5E',
  strokeWeight: 0,
};

interface ClusterProps extends Pick<ComponentProps<typeof Marker>, 'map' | 'position'> {
  scale: number;
  label?: string;
  onClick: () => void;
  opacity?: number;
}

const Cluster = ({ label, onClick, scale, opacity = 1, ...markerProps }: ClusterProps) => {
  return (
    <Marker
      icon={{ ...ICON, fillOpacity: opacity, scale }}
      label={{ className: css.label, text: label }}
      onClick={onClick}
      {...markerProps}
    />
  );
};

export default Cluster;
