import * as React from 'react';

import { ExpandablePanel } from '../../../../app/shared/components/ExpandablePanel/ExpandablePanel';

interface AccordionInterfaceProps {
  renderName: (isExpanded: boolean, name: string) => JSX.Element | string;
  sections: {
    name: string;
    content: React.ReactChild;
  }[];
  contentClassName?: string;
  sectionClassName?: string;
}

const AccordionInterface = ({ contentClassName, sectionClassName, renderName, sections }: AccordionInterfaceProps) => {
  return (
    <>
      {sections.map((item) => (
        <ExpandablePanel
          className={sectionClassName}
          contentClassName={contentClassName}
          renderTitle={(isExpanded) => renderName(isExpanded, item.name)}
          expanded={false}
          key={item.name}
        >
          {item.content}
        </ExpandablePanel>
      ))}
    </>
  );
};

export default AccordionInterface;
