import { Mesh, Scene } from '@babylonjs/core';

import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from '../presenters/MeshPresenter';
import { AbstractVisual } from './AbstractVisual';

export class SimpleVisual implements AbstractVisual {
  constructor(private object: ObjectState, private mesh: Mesh) {}

  getMeshes(): Mesh[] {
    return [this.mesh];
  }

  update(scene: Scene, presenters: IMeshPresenter[]): void {
    presenters.forEach((x) => x.update(scene, this.mesh, this.object));
  }
}
