import { observer } from 'mobx-react';

import AddressLine1 from '../Inputs/AddressLine1';
import AddressLine2 from '../Inputs/AddressLine2';
import City from '../Inputs/City';
import ZipCode from '../Inputs/ZipCode';
import { DefaultDynamicInputsProps } from './DefaultDynamicInputs';
import css from './DefaultDynamicInputs.css';

type DynamicInputsDEProps = DefaultDynamicInputsProps;

const DynamicInputsDE = ({ state: { autocompleteCity, translation, inputs }, idPrefix }: DynamicInputsDEProps) => {
  return (
    <>
      <AddressLine1
        placeholder={translation.addressLine1.placeholder}
        label={translation.addressLine1.name}
        input={inputs.addressLine1}
        idPrefix={idPrefix}
      />
      <AddressLine2
        placeholder={translation.addressLine2.placeholder}
        label={translation.addressLine2.name}
        input={inputs.addressLine2}
        idPrefix={idPrefix}
      />
      <ZipCode
        label={translation.zipCode.name}
        placeholder="0000"
        input={inputs.zipCode}
        inputClassName={css.zipCode}
        idPrefix={idPrefix}
      />
      <City
        placeholder={translation.city.placeholder}
        label={translation.city.name}
        isLoading={autocompleteCity?.loadingIndicator.isLoading}
        input={inputs.city}
        idPrefix={idPrefix}
      />
    </>
  );
};

export default observer(DynamicInputsDE);
