import { Mesh, Scene, TransformNode, Vector3 } from '@babylonjs/core';

import '../../../shared/arrayExtensions';
import { GroupState } from '../../core/GroupState';
import { IMeshPresenter } from '../presenters/MeshPresenter';
import { AbstractVisual } from './AbstractVisual';

export class GroupVisual implements AbstractVisual {
  public transformation: TransformNode;

  constructor(private group: GroupState, private visuals: AbstractVisual[]) {
    this.transformation = new TransformNode(group.name);
    visuals.forEach((visual) => visual.getMeshes().forEach((mesh) => (mesh.parent = this.transformation)));
  }

  getMeshes(): Mesh[] {
    return this.visuals.mapMany((x) => x.getMeshes());
  }

  update(scene: Scene, presenters: IMeshPresenter[]): void {
    this.transformation.position = new Vector3(this.group.position.x, this.group.position.y, this.group.position.z); // TODO reuse position presenter
    this.transformation.rotation = new Vector3(this.group.rotation.x, this.group.rotation.y, this.group.rotation.z); // TODO reuse rotation presenter

    return this.visuals.forEach((x) => {
      x.update(scene, presenters);
    });
  }

  public onChildAdded(child: AbstractVisual) {
    this.visuals.push(child);
    child.getMeshes().forEach((mesh) => (mesh.parent = this.transformation));
  }
}
