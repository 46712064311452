import { ShoppingCartItemData } from '../data/model';
import { StoreUrl } from '../shared/StoreUrl';
import IConfigurationPageQuery from './IConfigurationPageQuery';
import { Tab } from './Tab';

export class ConfiguratorUrlBuilder {
  public static convertCartItemToSelection(item: ShoppingCartItemData): IConfigurationPageQuery {
    const { accessories, options, quantity, id } = item;

    const queryModel: IConfigurationPageQuery = {
      accessories: accessories.map((accessory) => accessory.id),
      options: options.map((option) => option.variationId),
      quantity,
      step: Tab.Summary,
      shoppingCartItemId: id,
    };

    return queryModel;
  }

  public static buildFromCartItem(item: ShoppingCartItemData) {
    const queryModel = this.convertCartItemToSelection(item);
    const url = StoreUrl.configuratorUrl(item.baseModel.code, queryModel);
    return url;
  }
}
