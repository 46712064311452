import { AppState } from '../../AppState';
import { TopBarTranslation } from '../../localization/SiteTranslation';
import GeneralHeaderMainBar from './GeneralHeaderMainBar/GeneralHeaderMainBar';
import GeneralHeaderTopBar from './GeneralHeaderTopBar/GeneralHeaderTopBar';

interface GeneralHeaderProps {
  appState: AppState;
  translation: TopBarTranslation;
  className?: string;
  isLogoSmall?: boolean;
  logoLink?: string;
  modelName?: string;
}

const GeneralHeader = ({ appState, translation, className, isLogoSmall, logoLink, modelName }: GeneralHeaderProps) => {
  return (
    <header>
      {/* TODO: Remove condition when menu is updated in all stores */}
      {appState.navigationState.topBar.isEnabled && (
        <GeneralHeaderTopBar
          storeAndLanguageSelectorState={appState.storeAndLanguageSelector}
          menuItems={appState.navigationState.topBar.menu}
        />
      )}
      <GeneralHeaderMainBar
        className={className}
        appState={appState}
        logoLink={logoLink}
        modelName={modelName}
        translation={translation}
        isLogoSmall={isLogoSmall}
      />
    </header>
  );
};

export default GeneralHeader;
