import { action, makeObservable, observable } from 'mobx';
import { FC } from 'react';

import { IApiClient } from '../../data/client';
import { CountryData, DeliveryAddressData, StoreData } from '../../data/model';
import { AddressFormTranslation } from '../../localization/SiteTranslation';
import { IEnableable, keysOf } from '../../shared/common';
import { Form, SelectInput, TextInput, ValueMaxLength, ValueRequiredRule } from '../../shared/Form';
import DefaultDynamicInputsState from './ShippingAddresses/DynamicInputs/DefaultDynamicInputsState';
import { DynamicInputsState } from './ShippingAddresses/DynamicInputs/IDynamicInputsState';
import dynamicInputsFactory, { DynamicInputProps } from './ShippingAddresses/DynamicInputsFactory';

export class AddressState extends Form implements IEnableable {
  private client: IApiClient;
  country: SelectInput<CountryData>;
  recipient: TextInput;
  storeCountry: string;

  @observable
  isEnabled: boolean;

  @observable.ref
  dynamicInputs: DynamicInputsState;

  @observable.ref
  dynamicInputsComponent: FC<DynamicInputProps>;

  countries: Array<CountryData>;
  translation: AddressFormTranslation;

  private validationTriggered = false;

  constructor(translation: AddressFormTranslation, availableCountries: Array<string>, client: IApiClient) {
    super();
    makeObservable(this);
    this.client = client;
    this.dynamicInputs = new DefaultDynamicInputsState(translation, client);

    this.translation = translation;
    this.country = new SelectInput({ name: '', code: '' }).withRule(new ValueRequiredRule());
    this.recipient = new TextInput()
      .withRule(new ValueRequiredRule(translation.recipient.fieldRequired))
      .withRule(new ValueMaxLength(36));
    this.isEnabled = true;

    this.inputsToValidate.push(this.country, this.recipient);
    this.setAvailableCountries(availableCountries);
  }

  enable() {
    this.isEnabled = true;
    this.recipient.enable();
    this.dynamicInputs.enable();
  }

  disable() {
    this.isEnabled = false;
    this.recipient.disable();
    this.dynamicInputs.disable();
  }

  @action.bound
  selectCountry(countryCode: string) {
    let foundCountryData = this.countries.find((country) => country.code === countryCode);
    if (!foundCountryData) {
      foundCountryData = this.countries.find((x) => true);
    }
    this.country.value = foundCountryData;
    this.setDynamicShippingAddress(foundCountryData.code);
  }

  @action.bound
  setDynamicShippingAddress(countryCode: string) {
    const address = this.getData();

    keysOf(this.dynamicInputs.inputs).forEach((x) => {
      const currentInput = this.dynamicInputs.inputs[x];
      this.inputsToValidate.remove(currentInput);
    });
    const definition = dynamicInputsFactory(countryCode, this.translation, this.client);

    this.dynamicInputs = definition.state;
    this.dynamicInputsComponent = definition.component;
    this.dynamicInputs.setInputs(address);

    keysOf(this.dynamicInputs.inputs).forEach((x) => {
      const currentInput = this.dynamicInputs.inputs[x];
      this.inputsToValidate.push(currentInput);
    });

    if (this.isEnabled) {
      this.dynamicInputs.enable();
    } else {
      this.dynamicInputs.disable();
    }

    if (this.validationTriggered) {
      this.validate();
    }
  }

  validate() {
    this.validationTriggered = true;
    super.validate();
  }

  set(deliveryAddress: DeliveryAddressData) {
    this.recipient.value = deliveryAddress.recipient;
    this.country.value = deliveryAddress.country;

    this.dynamicInputs.setInputs(deliveryAddress);
  }

  getData() {
    let address: DeliveryAddressData = {
      recipient: this.recipient.value,
      line1: '',
      line2: '',
      zipCode: '',
      city: '',
      country: this.country.value,
    };

    if (!this.dynamicInputs) {
      return address;
    }

    this.dynamicInputs.buildUp(address);
    this.dynamicInputs.setInputs(address);
    return address;
  }

  @action
  setAvailableCountries(availableCountries: Array<string>) {
    this.countries = availableCountries.map((code: string) => {
      return { code: code, name: this.resolveCountryName(code) };
    });

    if (!this.countries.empty()) {
      this.selectCountry(this.countries[0].name);
    }
  }

  @action
  setStoreCountry(store: StoreData) {
    this.storeCountry = store.name;
  }

  private resolveCountryName(code: string) {
    return this.translation.availableCountries[code] || code;
  }
}

export default AddressState;
