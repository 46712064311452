import { GtmPageVisitEvent } from './types/Gtm';

export class PageVisitEventBuilder {
  static build(relativeUrl: string): GtmPageVisitEvent {
    return {
      event: 'sf.pageVisit',
      data: {
        relativeUrl: relativeUrl,
      },
    };
  }
}
