import classNames from 'classnames';

import { BaseModelBasicData } from '../../../../data/model';
import { ProductModelsTranslation } from '../../../../localization/SiteTranslation';
import Accordion from '../../../../shared/components/Accordion/Accordion';
import { ModelSelectorState } from '../ModelSelector/ModalSelectorState';
import ModelSelector from '../ModelSelector/ModelSelector';
import ProductModelPanelHeader from '../ProductModelPanelHeader/ProductModelPanelHeader';
import ModelCarousel from './ModelCarousel/ModelCarousel';
import css from './ProductModelsAccordion.css';

interface ProductModelsAccordionProps {
  description: string;
  isExpanded: boolean;
  isLoading: boolean;
  selectorState: ModelSelectorState;
  title: string;
  translation: ProductModelsTranslation;
  onIsExpandedChange: (isExpanded: boolean) => void;
  className?: string;
  onModelClick?: (itemData: BaseModelBasicData) => void;
}

const ProductModelsAccordion = ({
  className,
  description,
  isExpanded,
  isLoading,
  onIsExpandedChange,
  onModelClick,
  selectorState,
  title,
  translation,
}: ProductModelsAccordionProps) => {
  return (
    <div className={classNames(css.container, className)}>
      <Accordion
        className={css.inner}
        contentClassName={css.content}
        isExpanded={isExpanded}
        titleClassName={css.title}
        title={<ProductModelPanelHeader title={title} description={description} />}
        onIsExpandedChange={onIsExpandedChange}
      >
        <>
          <span className="visuallyHidden">{translation.chooseModelText}</span>
          <ModelSelector
            component={ModelCarousel}
            isLoading={isLoading}
            state={selectorState}
            translation={translation.modelSelectorWithLoader}
            onItemClick={onModelClick}
          />
        </>
      </Accordion>
    </div>
  );
};

export default ProductModelsAccordion;
