import classNames from 'classnames';

import CarouselNavButton, {
  CarouselNavButtonProps,
  CarouselNavButtonType,
} from '../../Carousel/CarouselNavButton/CarouselNavButton';
import css from './ModalCarouselNavButton.css';

type ModalCarouselNavButtonProps = CarouselNavButtonProps;

const ModalCarouselNavButton = ({
  className,
  classNameDisabled,
  iconClassName,
  ...props
}: ModalCarouselNavButtonProps) => (
  <CarouselNavButton
    className={classNames(className, css.element, {
      [css.next]: props.type === CarouselNavButtonType.Next,
      [css.prev]: props.type === CarouselNavButtonType.Previous,
    })}
    classNameDisabled={classNames(css.disabled, classNameDisabled)}
    iconClassName={classNames(css.icon, iconClassName)}
    {...props}
  />
);

export default ModalCarouselNavButton;
