import { observer } from 'mobx-react';
import * as React from 'react';

import App from '../app/App';
import { PriceProvider } from '../app/context/PriceContext';
import { ShoppingProvider } from '../app/context/ShoppingContext';
import NoConnectionModal from '../app/shared/components/NoConnectionModal/NoConnectionModal';
import { RegionContext } from '../app/shared/regions/RegionContext';
import { AppState } from './AppState';
import AppLoader from './layout/AppLoader/AppLoader';
import Layout from './layout/Layout';
import { EmptyPageState } from './routing/EmptyPageState';

interface ShellProps {
  app: App;
}

@observer
class Shell extends React.Component<ShellProps, {}> {
  renderWithLayout(appState: AppState, view: React.ReactElement<Object>) {
    return <Layout appState={appState}>{view}</Layout>;
  }

  render() {
    const { view, regionManager } = this.props.app;
    const appState = this.props.app.appState as AppState;
    const { store } = appState;

    const isPricesEnable = store?.canShowPrices();
    const shoppingContext = store?.shoppingContext;
    const shouldDisplayDecimalPartOfPrices = store?.shouldDisplayDecimalPartOfPrices ?? true;

    return (
      <ShoppingProvider shoppingContext={shoppingContext}>
        <RegionContext.Provider value={{ regionManager }}>
          <PriceProvider enable={isPricesEnable} displayDecimalPart={shouldDisplayDecimalPartOfPrices}>
            <div id="root">
              {appState.store ? this.renderWithLayout(appState, view) : view}
              {!appState.store && appState.currentPage instanceof EmptyPageState && (
                <AppLoader translation={appState.translation.appLoader} />
              )}
            </div>
            <NoConnectionModal
              modal={appState.noConnectionModal}
              translation={appState.translation.noConnectionModal}
            />
          </PriceProvider>
        </RegionContext.Provider>
      </ShoppingProvider>
    );
  }
}

export default Shell;
