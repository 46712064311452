export enum Role {
  Admin = 'Admin',
  Buyer = 'Buyer',
  FlokkSales = 'FlokkSales',
  FlokkSupport = 'FlokkSupport',
  Reader = 'Reader',
}

export function isFlokkRole(role: Role) {
  return [Role.FlokkSales, Role.FlokkSupport].contains(role);
}

export function toRole(roleStr: string) {
  const roleDict = Role as { [key: string]: string };
  for (let roleKey in roleDict) {
    if (roleDict[roleKey] === roleStr) {
      return roleKey as Role;
    }
  }
}
