import { observer } from 'mobx-react';

import { AsyncCommand } from '../../../app/shared/common';
import { ConfirmationModal } from '../../../app/shared/components/Modal/ConfirmationModal/ConfirmationModal';
import StoreDisplayName from '../../menu/UserInfo/StoreDisplayName/StoreDisplayName';
import css from './OrderCreationConfirmationModal.css';
import OrderCreationConfirmationModalState from './OrderCreationConfirmationModalState';

interface OrderCreationConfirmationModalProps {
  state: OrderCreationConfirmationModalState;
  applyCommand: AsyncCommand;
}

const OrderCreationConfirmationModal = ({ state, applyCommand }: OrderCreationConfirmationModalProps) => {
  return (
    <ConfirmationModal
      modal={state}
      title={state.translation.title}
      applyButton={{ name: state.translation.confirmButton, command: applyCommand }}
      cancelButton={{ name: state.translation.cancelButton }}
    >
      <p>
        {state.translation.confirmationText}{' '}
        <StoreDisplayName
          className={css.store}
          storeId={state.currentStore.id}
          storeName={state.currentStore.displayName}
        />
        .
      </p>
    </ConfirmationModal>
  );
};

export default observer(OrderCreationConfirmationModal);
