import { nameOf } from '../shared/common';
import { EmbeddedQuery, EmbeddedQueryParser } from '../shared/EmbeddedQueryParser';
import { QueryParser } from '../shared/QueryParser';
import { isRawConfiguratorSize, RawConfiguratorSize } from './components/RawConfigurator/RawConfiguratorSize';

const SIZE = nameOf<EmbeddableRawConfiguratorPageQuery>('size');

interface EmbeddableRawConfiguratorPageQuery extends EmbeddedQuery {
  size?: RawConfiguratorSize;
}

export default class EmbeddableRawConfiguratorPageQueryParser extends QueryParser {
  public static toModel(query: Map<string, string>): EmbeddableRawConfiguratorPageQuery {
    const model: EmbeddableRawConfiguratorPageQuery = { embed: EmbeddedQueryParser.toModel(query).embed };
    const size = QueryParser.getValue(query, SIZE);

    if (isRawConfiguratorSize(size)) {
      model.size = size;
    }

    return model;
  }

  public static toQuery(queryModel: EmbeddableRawConfiguratorPageQuery): Map<string, string> {
    const query = EmbeddedQueryParser.toQuery(queryModel);

    if (isRawConfiguratorSize(queryModel.size)) {
      QueryParser.setValue(query, SIZE, queryModel.size);
    }

    return query;
  }
}
