import { computed, makeObservable } from 'mobx';

import { IBaseModel } from '../../../data/model';
import { ProductFeatureToggles } from './ProductDetails';
import ProductDetailsState from './ProductDetailsState';

export interface IProductPurchaseAvailabilityProvider {
  canBePurchased: (baseModel: IBaseModel) => boolean;
}

export interface IFeatureAvailabilityProvider {
  canRequestQuote: () => boolean;
  showWhereToBuy: () => boolean;
}

export class ProductFeatureTogglesState {
  constructor(
    private readonly visibilityProvider: IProductPurchaseAvailabilityProvider & IFeatureAvailabilityProvider,
    private readonly modelState: ProductDetailsState,
  ) {
    makeObservable(this);
  }

  @computed
  get purchaseEnabled(): boolean {
    return (
      this.visibilityProvider.canBePurchased(this.modelState.modelSelector.active.data) &&
      this.hasPredefinedConfigurations
    );
  }

  @computed
  get hasPredefinedConfigurations(): boolean {
    return this.modelState.predefinedConfigurations.items.any();
  }

  public getProductFeatureToggles = (): ProductFeatureToggles => {
    const reuqestQuoteEnabled = this.visibilityProvider.canRequestQuote();
    const goToCheckoutEnabled = this.hasPredefinedConfigurations && this.purchaseEnabled;
    const whereToBuyEnabled = !this.purchaseEnabled && this.visibilityProvider.showWhereToBuy();

    return {
      askForPriceEnabled: reuqestQuoteEnabled && !whereToBuyEnabled && !goToCheckoutEnabled,
      configureChairEnabled: this.hasPredefinedConfigurations,
      goToCheckoutEnabled: goToCheckoutEnabled,
      whereToBuyEnabled: whereToBuyEnabled,
      designerEnabled: this.hasPredefinedConfigurations,
    };
  };
}
