import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';
import ModularConfiguratorPageQuery from './ModularConfiguratorPageQuery';

class ModularConfiguratorPageQueryParser extends QueryParser {
  public static toModel(query: Map<string, string>): ModularConfiguratorPageQuery {
    for (const [key, value] of query) {
      query.set(key, decodeURIComponent(value));
    }

    const layoutCode = QueryParser.getValue(query, nameOf<ModularConfiguratorPageQuery>('layoutCode'), '');

    return new ModularConfiguratorPageQuery(layoutCode);
  }

  public static toQuery(queryModel: ModularConfiguratorPageQuery): Map<string, string> {
    const query = new Map<string, string>();

    Object.entries(queryModel).forEach(([name, value]) => {
      QueryParser.setValue(query, name, encodeURIComponent(value));
    });

    return query;
  }
}

export default ModularConfiguratorPageQueryParser;
