interface GtmEvent {
  event: string;
}

// Page Visit Event

export interface GtmPageVisitEvent extends GtmEvent {
  event: string;
  data: GtmPageVisitDataField;
}

export interface GtmPageVisitDataField {
  relativeUrl: string;
}

// Custom Event

export interface GtmCustomEvent extends GtmEvent {
  ['gtm.element']?: HTMLElement;
  ['gtm.elementClasses']?: string;
  ['gtm.elementId']?: string;
  ['gtm.elementUrl']?: string;
  ['gtm.originalEvent']?: Event;
}

// Enhanced Ecommerce Event

export interface GtmEnhancedCommerceEvent extends GtmEvent {
  ecommerce: GtmEcommerce;
}

export interface GtmEcommerce {
  action?: GtmEcommerceAction;
  currencyCode?: string;
  impressions?: GtmImpression[];
}

export enum GtmEcommerceActionName {
  Add = 'add',
  Checkout = 'checkout',
  CheckoutOption = 'checkout_option',
  Click = 'click',
  Detail = 'detail',
  Purchase = 'purchase',
  Remove = 'remove',
}

export type GtmEcommerceAction =
  | GtmAddAction
  | GtmCheckoutAction
  | GtmCheckoutOptionAction
  | GtmClickAction
  | GtmDetailAction
  | GtmPurchaseAction
  | GtmRemoveAction;

export interface GtmAddAction {
  [GtmEcommerceActionName.Add]: {
    actionField: Pick<GtmActionField, 'list'>;
    products: GtmProduct[];
  };
}

export interface GtmCheckoutAction {
  [GtmEcommerceActionName.Checkout]: {
    actionField: Pick<GtmActionField, 'step' | 'option'>;
    products: GtmProduct[];
  };
}

export interface GtmCheckoutOptionAction {
  [GtmEcommerceActionName.CheckoutOption]: {
    actionField: Pick<GtmActionField, 'step' | 'option'>;
  };
}

export interface GtmClickAction {
  [GtmEcommerceActionName.Click]: {
    products: GtmProduct[];
  };
}

export interface GtmDetailAction {
  [GtmEcommerceActionName.Detail]: {
    actionField?: Pick<GtmActionField, 'list'>;
    products: GtmProduct[];
  };
}

export interface GtmPurchaseAction {
  [GtmEcommerceActionName.Purchase]: {
    actionField: Pick<GtmActionField, 'id' | 'affiliation' | 'coupon' | 'revenue' | 'tax' | 'shipping'>;
    products: GtmProduct[];
  };
}

export interface GtmRemoveAction {
  [GtmEcommerceActionName.Remove]: {
    actionField: Pick<GtmActionField, 'list'>;
    products: GtmProduct[];
  };
}

export interface GtmActionField {
  id: string;
  affiliation?: string;
  coupon?: string;
  list?: string;
  option?: string;
  revenue?: number; // total, including tax and shipping
  shipping?: number;
  step?: number;
  tax?: number;
}

export interface GtmImpression extends GtmEcommerceEntity {
  list?: string;
}

export interface GtmProduct extends GtmEcommerceEntity {
  coupon?: string;
  quantity?: number;
}

interface GtmEcommerceEntity {
  id: string;
  name: string;
  brand?: string;
  category?: string;
  position?: number;
  price?: number;
  variant?: string;
}
