import { ProductListTranslation } from '../../../../../app/localization/SiteTranslation';
import List from '../../../../../app/shared/components/List/List';
import HeaderLevel2 from '../../../../../app/shared/components/Typography/HeaderLevel2';
import css from './ProductList.css';
import ProductThumbnail, { ProductThumbnailProps } from './ProductThumbnail/ProductThumbnail';

interface ProductListProps {
  items: Omit<ProductThumbnailProps, 'translation'>[];
  title?: string;
  translation: ProductListTranslation;
}

const ProductList = ({ items, title, translation }: ProductListProps) => {
  const renderItem = (item: ProductThumbnailProps) => {
    return (
      <ProductThumbnail
        imageSrc={item.imageSrc}
        linkUrl={item.linkUrl}
        name={item.name}
        price={item.price}
        variations={item.variations}
        translation={translation.productThumbnail}
      />
    );
  };

  return (
    <section className={css.container}>
      {title && (
        <HeaderLevel2 className={css.title} centered>
          {title}
        </HeaderLevel2>
      )}
      <List className={css.list} itemClassName={css.item} items={items} itemRenderer={renderItem} />
    </section>
  );
};

export default ProductList;
