import { observer } from 'mobx-react';
import * as React from 'react';

import { PriceConsumer, PriceProvider } from '../../context/PriceContext';

const ConfiguratorPriceConsumer = (props: { canBePurchased: boolean; children: React.ReactChild }) => {
  const { canBePurchased, children } = props;

  return (
    <PriceConsumer>
      {({ displayDecimalPart }) => (
        <PriceProvider enable={canBePurchased} displayDecimalPart={displayDecimalPart}>
          {children}
        </PriceProvider>
      )}
    </PriceConsumer>
  );
};

export default observer(ConfiguratorPriceConsumer);
