import { useRef, useState } from 'react';
import Slider from 'react-slick';

import { ImageData } from '../../../data/model';
import Carousel from '../Carousel/Carousel';
import { CarouselNavButtonType } from '../Carousel/CarouselNavButton/CarouselNavButton';
import Image from '../Image/Image';
import Lightbox from '../Lightbox/Lightbox';
import css from './ModalCarousel.css';
import ModalCarouselNavButton from './ModalCarouselNavButton/ModalCarouselNavButton';
import SlideLoader from './SlideLoader/SlideLoader';

interface ModalCarouselProps {
  images: ImageData[];
  isOpen: boolean;
  onClose: () => void;
  initialSlide?: number;
  onDownload?: (imageIndex: number) => void;
}

const ModalCarousel = ({ images, isOpen, onClose, onDownload, initialSlide = 0 }: ModalCarouselProps) => {
  const carouselRef = useRef<Slider>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(initialSlide);
  const shouldDisplayCounter = images.length > 1;

  const goToInitialSlider = () => {
    carouselRef.current?.slickGoTo(initialSlide, true);
  };

  const handleDownload = () => {
    if (onDownload) {
      onDownload(currentSlideIndex);
    }
  };

  const renderCounter = () =>
    shouldDisplayCounter && (
      <div>
        {currentSlideIndex + 1}/{images.length}
      </div>
    );

  return (
    <Lightbox
      className={css.container}
      isOpen={isOpen}
      onAfterOpen={goToInitialSlider}
      onClose={onClose}
      onDownload={handleDownload}
    >
      <Carousel
        ref={carouselRef}
        className={css.carousel}
        adaptiveHeight
        appendDots={renderCounter}
        arrows
        dots={shouldDisplayCounter}
        dotsClass={css.counter}
        initialSlide={initialSlide}
        lazyLoad="progressive"
        prevArrow={<ModalCarouselNavButton type={CarouselNavButtonType.Previous} />}
        nextArrow={<ModalCarouselNavButton type={CarouselNavButtonType.Next} />}
        useCSS={false}
        useTransform={false}
        beforeChange={(_, index) => setCurrentSlideIndex(index)}
      >
        {images.map((image, index) => (
          <Image
            className={css.imageWrapper}
            classNameImageElement={css.image}
            classNameUnloaded={css.unloaded}
            placeholder={<SlideLoader />}
            src={image.url}
            key={index}
          />
        ))}
      </Carousel>
    </Lightbox>
  );
};

export default ModalCarousel;
