import { HomePageState } from '../b2c/webshop/home/HomePageState';
import HomePageWithFallback from '../b2c/webshop/home/HomePageWithFallback';
import { HomePageWithFallbackState } from '../b2c/webshop/home/HomePageWithFallbackState';
import ProductPage from '../b2c/webshop/product/ProductPage';
import { ProductPageState } from '../b2c/webshop/product/ProductPageState';
import AppState from './AppState';
import { CatalogPageState } from './catalog/CatalogPageState';
import { CheckoutState } from './checkout/CheckoutState';
import { CheckoutShortcutState } from './checkoutShortcut/CheckoutShortcutState';
import { ConfigurationDetailsPageState } from './configurationDetails/ConfigurationDetailsPageState';
import ConfigurationSearchPage from './configurationSearch/ConfigurationSearchPage';
import { ConfigurationSearchPageState } from './configurationSearch/ConfigurationSearchPageState';
import { ConfiguratorState } from './configurator/ConfiguratorState';
import { ConfirmationState } from './confirmation/ConfirmationState';
import DownloadsPage from './downloads/DownloadsPage';
import { DownloadsPageState } from './downloads/DownloadsPageState';
import { EmailMyConfigurationState } from './emailMyConfiguration/EmailMyConfigurationState';
import ParseConfigurationCodeAndRedirectState from './errorHandling/ParseConfigurationCodeAndRedirectState';
import { RedirectState } from './errorHandling/RedirectState';
import ModularConfiguratorPageState from './modularConfigurator/ModularConfiguratorPageState';
import { ModularConfiguratorPageState as ModularConfiguratorPageStateDraft } from './modularConfiguratorDraft/ModularConfiguratorPageState';
import ProductFamilyPageState from './products/ProductFamilyPageState';
import { ProductViewer3dPageState } from './productViewer3d/ProductViewer3dPageState';
import { EmbeddableRawConfiguratorPageState } from './rawConfigurator/EmbeddableRawConfiguratorPageState';
import { RequestQuoteState } from './requestQuote/RequestQuoteState';
import { Page, Route } from './routes';
import { keysOf, nameOf } from './shared/common';
import { DefaultShareConfiguratorUrlProvider } from './shared/DefaultShareConfiguratorUrlProvider';
import { Navigation } from './shared/NavigationService';
import { DefaultPdfOriginProvider } from './shared/PdfUrlProvider';
import { RelativeUrl } from './shared/Url';
import StoreLocatorState from './storeLocator/StoreLocatorState';
import ViewerState from './viewer/ViewerState';

export interface ConfiguratorRouteParams {
  slug: string;
}

export interface ConfirmationRouteParams {
  orderNumber: string;
}

export interface ProductSpecificationParams {
  slug: string;
}

export interface ProductViewer3dParams {
  slug: string;
}

export interface CodeRouteParams {
  code: string;
}

export interface ProductRouteParams {
  slug: string;
}

export interface WebShopProductRouteParams {
  productId: string;
}

export interface ModularConfiguratorRouteParams {
  productFamilyId: string;
}

export class RouteUrlBuilder {
  public static getUrlTo<T>(route: Route<T>, params: T = null, query: Map<string, string> = null) {
    let destination = route.route;
    if (params) {
      keysOf(params).forEach((key) => {
        const val = params[key];
        destination = RouteUrlBuilder.replaceRouteParams(destination, key, val.toString());
      });
    }
    const relative = RelativeUrl.parse(destination);
    if (query) {
      query.forEach((val, key) => {
        relative.setQueryParameter(key, val);
      });
    }
    // Todo: Make this method to return RelativeUrl instance?
    return relative.toString(false);
  }

  public static replaceRouteParams(source: string, key: string, value: string) {
    return source.replaceAll(`?:${key}`, value).replaceAll(`:${key}`, value);
  }
}

export class RouteDefinitions {
  static instance = new RouteDefinitions();

  public redirectRouteFactoryMethod(route: string) {
    const self = this;
    const result: Route<{}> = {
      route: route,
      async getPage(appState: AppState, routeValues: {}, query: Map<string, string>) {
        let destination = self.getUrlTo(() => this, routeValues, query);
        keysOf(routeValues).forEach((key) => {
          const val = routeValues[key];
          destination = RouteUrlBuilder.replaceRouteParams(destination, key, val);
        });
        const state = new RedirectState(appState.navigation, destination);
        const component = <div />;
        const useSplashScreen = true;
        return {
          state,
          component,
          useSplashScreen,
        };
      },
    };
    return result;
  }

  public getUrlTo<T>(
    selector: (rd: RouteDefinitions) => Route<T>,
    params: T = null,
    query: Map<string, string> = null,
  ) {
    const route = selector(RouteDefinitions.instance);
    return RouteUrlBuilder.getUrlTo(route, params, query);
  }

  public configuratorProductRoute: Route<ConfiguratorRouteParams> = {
    route: `/products/:${nameOf<ConfiguratorRouteParams>('slug')}/configure`,
    aliases: [`/configurator/:${nameOf<ConfiguratorRouteParams>('slug')}`],
    async getPage(appState: AppState, params: ConfiguratorRouteParams) {
      const ConfiguratorPage = require('./configurator/ConfiguratorPage').default;
      const state = new ConfiguratorState(
        appState.settings,
        appState.client,
        appState.navigation,
        appState.imagePreloader,
        new DefaultPdfOriginProvider(appState.settings),
        new DefaultShareConfiguratorUrlProvider(appState.settings),
        params.slug,
        appState.translation.configurationPage,
        appState.warningLocalStorage,
      );
      const component = <ConfiguratorPage appState={appState} state={state} isFixed />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public homeRoute: Route<never> = {
    route: '/',
    async getPage(appState: AppState) {
      const useSplashScreen = false;
      const state = new HomePageWithFallbackState(
        new HomePageState(appState.settings, appState.client, appState.translation.homePage),
        new ConfigurationSearchPageState(
          appState.translation.configurationSearchPage,
          appState.navigation,
          appState.client,
        ),
      );
      const component = <HomePageWithFallback state={state} />;
      return { state, component, useSplashScreen };
    },
  };

  public configurationSearchRoute: Route<never> = {
    route: '/configuration-search',
    async getPage(appState: AppState) {
      const useSplashScreen = false;
      const state = new ConfigurationSearchPageState(
        appState.translation.configurationSearchPage,
        Navigation.instance,
        appState.client,
      );
      const component = <ConfigurationSearchPage state={state} />;
      return { state, component, useSplashScreen };
    },
  };

  public checkoutRoute: Route<never> = {
    route: '/checkout',
    async getPage(appState: AppState) {
      const CheckoutPage = require('./checkout/CheckoutPage').default;
      const state = new CheckoutState(
        appState.settings,
        appState.client,
        appState.navigation,
        appState.translation.checkoutPage,
        appState.navigationState.termsAndConditionsUrl,
        appState.navigationState.legalAgreementsConsentHtml,
        appState.storeAndLanguageSelector.currentLanguage.code,
      );
      const component = <CheckoutPage appState={appState} state={state} />;
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public checkoutShortcutRoute: Route<never> = {
    route: `/checkout-shortcut/:${nameOf<CodeRouteParams>('code')}`,
    async getPage(appState: AppState, params: CodeRouteParams) {
      const useSplashScreen = false;
      const CheckoutShortcutPage = require('./checkoutShortcut/CheckoutShortcutPage').default;
      const state = new CheckoutShortcutState(
        params.code,
        appState.translation.checkoutShortcutPage,
        appState.translation.configurationSearchPage.configurationFinder,
        Navigation.instance,
        appState.client,
        appState.loadingPageIndicator,
      );
      const component = <CheckoutShortcutPage appState={appState} state={state} />;
      return { state, component, useSplashScreen };
    },
  };

  public confirmationRoute: Route<ConfirmationRouteParams> = {
    route: `/confirmation/:${nameOf<ConfirmationRouteParams>('orderNumber')}`,
    async getPage(appState: AppState, params: ConfirmationRouteParams) {
      const ConfirmationPage = require('./confirmation/ConfirmationPage').default;
      const state = new ConfirmationState(params.orderNumber, appState.client, appState.translation);
      const component = <ConfirmationPage appState={appState} state={state} />;
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public simulateErrorRoute: Route<never> = {
    route: '/simulate-error',
    async getPage(): Promise<Page> {
      throw new Error('Simulated error when loading page ...');
    },
  };

  public requestQuoteRoute: Route<never> = {
    route: '/request-quote',
    async getPage(appState: AppState) {
      const RequestQuote = require('./requestQuote/RequestQuotePage').default;
      const state = new RequestQuoteState(
        appState.client,
        appState.settings,
        appState.navigation,
        appState.translation.requestQuotePage,
      );
      const component = <RequestQuote appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public emailMyConfigurationRoute: Route<never> = {
    route: '/email-my-configuration',
    async getPage(appState: AppState) {
      const EmailMyConfiguration = require('./emailMyConfiguration/EmailMyConfigurationPage').default;
      const state = new EmailMyConfigurationState(
        appState.client,
        appState.settings,
        appState.navigation,
        appState.translation.emailMyConfigurationPage,
      );
      const component = <EmailMyConfiguration appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public productSpecificationRoute: Route<ProductSpecificationParams> = {
    route: `/product-specification/:${nameOf<ProductSpecificationParams>('slug')}`,
    async getPage(appState: AppState, params: ProductSpecificationParams) {
      const ProductSpecificationPage = require('./productSpecification/ProductSpecificationPage.tsx').default;
      const state = new ConfiguratorState(
        appState.settings,
        appState.client,
        appState.navigation,
        appState.imagePreloader,
        new DefaultPdfOriginProvider(appState.settings),
        new DefaultShareConfiguratorUrlProvider(appState.settings),
        params.slug,
        appState.translation.configurationPage,
        appState.warningLocalStorage,
      );
      const component = (
        <ProductSpecificationPage
          appState={appState}
          state={state}
          translation={appState.translation.productSpecificationPage}
        />
      );
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public productViewer3dPageRoute: Route<ProductViewer3dParams> = {
    route: `/product-viewer-3d/:${nameOf<ProductViewer3dParams>('slug')}`,
    async getPage(appState: AppState, params: ProductViewer3dParams) {
      const state = new ProductViewer3dPageState(
        params.slug,
        appState.client,
        appState.navigation,
        appState.imagePreloader,
      );

      const ProductViewer3dPage = require('./productViewer3d/ProductViewer3dPage').default;

      const component = <ProductViewer3dPage appState={appState} state={state} />;
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public storeLocatorRoute: Route<never> = {
    route: '/store-locator',
    async getPage(appState: AppState) {
      const StoreLocatorPage = require('./storeLocator/StoreLocatorPage.tsx').default;
      const state = new StoreLocatorState(
        appState.client,
        appState.settings,
        appState.navigation,
        appState.translation.storeLocatorPage,
      );
      const component = <StoreLocatorPage appState={appState} state={state} />;
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public codeRoute: Route<CodeRouteParams> = {
    route: `/code/:${nameOf<CodeRouteParams>('code')}`,
    aliases: [`/configure/:${nameOf<CodeRouteParams>('code')}`, `/shop/:${nameOf<CodeRouteParams>('code')}`],
    async getPage(appState: AppState, params: CodeRouteParams, query: Map<string, string>) {
      const state = new ParseConfigurationCodeAndRedirectState(
        appState.settings,
        appState.client,
        appState.navigation,
        params.code,
        query,
      );
      const component = <div />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public viewerRoute: Route<never> = {
    route: '/viewer-360',
    async getPage(appState: AppState) {
      const ViewerPage = require('./viewer/ViewerPage.tsx').default;
      const state = new ViewerState(appState.translation.viewerPage);
      const component = <ViewerPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public productPageRoute: Route<ProductRouteParams> = {
    route: `/products/:${nameOf<ProductRouteParams>('slug')}`,
    async getPage(appState: AppState, params: ProductRouteParams) {
      const state = new ProductFamilyPageState(
        appState.store,
        appState.settings,
        params.slug,
        appState.client,
        appState.navigation,
        appState.imagePreloader,
        appState.translation.productFamilyPage,
        appState.codeResolver,
      );
      const ProductFamilyPage = require('./products/ProductFamilyPage.tsx').default;
      const component = <ProductFamilyPage state={state} appState={appState} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public catalogPageRoute: Route<{}> = {
    route: `/products`,
    async getPage(appState: AppState) {
      const state = new CatalogPageState(appState.translation.catalogPage, appState.navigation, appState.client);
      const CatalogPage = require('./catalog/CatalogPage.tsx').default;
      const component = <CatalogPage state={state} appState={appState} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public downloadsPageRoute: Route<{}> = {
    route: `/downloads`,
    async getPage(appState: AppState) {
      const state = new DownloadsPageState(appState.client, appState.navigation, appState.translation.downloadsPage);
      const component = <DownloadsPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public webShopProductDetailsRoute: Route<WebShopProductRouteParams> = {
    route: `/webshop/products/:${nameOf<WebShopProductRouteParams>('productId')}`,
    async getPage(appState: AppState, params: WebShopProductRouteParams) {
      const state = new ProductPageState(
        appState.settings,
        params.productId,
        appState.client,
        appState.navigation,
        appState.translation.readyMadeProductPage,
        appState.imagePreloader,
      );
      const component = <ProductPage state={state} appState={appState} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public modularConfiguratorRoute: Route<ModularConfiguratorRouteParams> = {
    route: `/modular-configurator/:${nameOf<ModularConfiguratorRouteParams>('productFamilyId')}`,
    async getPage(appState: AppState, params: ModularConfiguratorRouteParams) {
      const state = new ModularConfiguratorPageState(
        appState.client,
        new DefaultShareConfiguratorUrlProvider(appState.settings),
        appState.navigation,
        appState.translation,
        params.productFamilyId,
      );
      const ModularConfiguratorPage = require('./modularConfigurator/ModularConfiguratorPage').default;
      const component = <ModularConfiguratorPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public modularConfiguratorPageRoute: Route<{}> = {
    route: `/modular-configurator-stub`,
    async getPage(appState: AppState, params: ProductViewer3dParams) {
      const state = new ModularConfiguratorPageStateDraft(appState.client);
      const ModularConfiguratorPageDraft = require('./modularConfiguratorDraft/ModularConfiguratorPage').default;
      const component = <ModularConfiguratorPageDraft state={state} />;
      const useSplashScreen = false;
      return { state, component, useSplashScreen };
    },
  };

  public rawConfiguratorRoute: Route<never> = {
    route: `/products/:${nameOf<ConfiguratorRouteParams>('slug')}/configure-raw`,
    async getPage(appState: AppState, params: ConfiguratorRouteParams, query: Map<string, string>) {
      const state = new EmbeddableRawConfiguratorPageState(
        appState.client,
        appState.loadingPageIndicator,
        params.slug,
        query,
      );
      const EmbeddableRawConfiguratorPage = require('./rawConfigurator/EmbeddableRawConfiguratorPage').default;
      const component = <EmbeddableRawConfiguratorPage appState={appState} state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public configurationDetailsRoute: Route<CodeRouteParams> = {
    route: `/configurations/:${nameOf<CodeRouteParams>('code')}`,
    async getPage(appState: AppState, params: CodeRouteParams) {
      const state = new ConfigurationDetailsPageState(
        appState.translation.configurationDetailsPage,
        appState.translation.common.chairVisualization,
        appState.translation.zoomModal,
        appState.translation.readyMadeProductPage.productVariations.modelViewer,
        appState.navigation,
        appState.client,
        appState.imagePreloader,
        new DefaultPdfOriginProvider(appState.settings),
        new DefaultShareConfiguratorUrlProvider(appState.settings),
        params.code,
      );
      const ConfigurationDetailsPage = require('./configurationDetails/ConfigurationDetailsPage').default;
      const component = (
        <ConfigurationDetailsPage
          appState={appState}
          store={appState.store}
          state={state}
          translation={appState.translation.configurationDetailsPage}
          shareTranslation={appState.translation.configurationPage.summary.shareSection}
        />
      );
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };
}

export default RouteDefinitions.instance;
