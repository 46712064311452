import Event from './Event';

export class Queue<T> {
  private items: Array<T> = [];

  public itemEnqueued: Event<T> = new Event<T>();
  public itemDequeued: Event<T> = new Event<T>();

  public enqueue(item: T): void {
    this.items.push(item);
    this.itemEnqueued.raise(item);
  }

  public enqueueMany(items: Array<T>): void {
    items.forEach((item) => {
      this.enqueue(item);
    });
  }

  public dequeue(): T {
    if (this.empty) {
      return null;
    }

    const item = this.items.shift();
    this.itemDequeued.raise(item);

    return item;
  }

  public dequeueMany(): Array<T> {
    const items = new Array<T>();

    while (!this.empty) {
      items.push(this.dequeue());
    }

    return items;
  }

  public get length(): number {
    return this.items.length;
  }

  public get empty(): boolean {
    return this.length === 0;
  }
}
