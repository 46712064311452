import AppSettings from '../app/AppSettings';
import { IAuthenticationService } from '../app/auth/AuthenticationService';
import { IApiClient } from '../app/data/client';
import { IIpAddressProvider } from '../app/data/IpAddressProvider';
import { ICustomerContextLocalStorage } from '../app/data/localStorage';
import IPreferredStoreProvider from '../app/data/StoreProviders/IPreferredStoreProvider';
import { IAppStateFactory } from '../app/IAppStateFactory';
import NavigationState from '../app/layout/NavigationState';
import { IWarningLocalStorage } from '../app/layout/Warnings/WarningLocalStorage';
import { IEventAggregator } from '../app/shared/EventAggregator';
import { IImagePreloader } from '../app/shared/ImagePreloader';
import { INavigationService } from '../app/shared/NavigationService';
import { AppState } from './AppState';
import TranslationResolverFactory from './localization/TranslationResolverFactory';

export class AppStateFactory implements IAppStateFactory {
  create(
    settings: AppSettings,
    client: IApiClient,
    navigationState: NavigationState,
    navigation: INavigationService,
    customerContextLocalStorage: ICustomerContextLocalStorage,
    warningLocalStorage: IWarningLocalStorage,
    imagePreloader: IImagePreloader,
    preferredStoreProvider: IPreferredStoreProvider,
    ipProvider: IIpAddressProvider,
    eventAggregator: IEventAggregator,
    authenticationService: IAuthenticationService,
  ): AppState {
    const translationResolver = TranslationResolverFactory.create();

    return new AppState(
      settings,
      client,
      navigationState,
      navigation,
      customerContextLocalStorage,
      warningLocalStorage,
      imagePreloader,
      preferredStoreProvider,
      ipProvider,
      eventAggregator,
      authenticationService,
      translationResolver,
    );
  }
}
