import { HttpErrorCode } from './HandleAuthCodeState';

export class AuthenticationFlowError extends Error {
  public readonly errorCode?: HttpErrorCode;
  public readonly errorHeader?: string;

  constructor(userFriendlyErrorMessage?: string, errorCode?: HttpErrorCode, errorHeader?: string) {
    super();

    // According to https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AuthenticationFlowError.prototype);

    this.message = userFriendlyErrorMessage;
    this.errorCode = errorCode;
    this.errorHeader = errorHeader;
  }
}
