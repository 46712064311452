import { ImageData } from '../../../../../../../../app/data/model';
import Image from '../../../../../../../../app/shared/components/Image/Image';
import css from './ProductVariation.css';

export interface ProductVariationProps {
  id: string;
  thumbnail: ImageData;
  name: string;
}

export const ProductVariation = ({ thumbnail, name }: ProductVariationProps) => {
  return <Image className={css.image} src={thumbnail?.url} alt={name} width={32} height={32} size="contain" />;
};

export default ProductVariation;
