import { action, computed, makeObservable, observable, override } from 'mobx';

import { Tab } from '../../app/configurator/Tab';
import { IApiClient } from '../../app/data/client';
import {
  DaysRangeData,
  GetDealerOrderDetailsQuery,
  OrderData,
  OrderItemData,
  ShipmentData,
  ShoppingContext,
} from '../../app/data/model';
import { ConfiguratorUrlBuilder } from '../../app/errorHandling/ParseConfigurationCodeAndRedirectState';
import { Analytics } from '../../app/shared/analytics/Analytics';
import { BasePageState } from '../../app/shared/BasePageState';
import { PageMetadata } from '../../app/shared/common';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { ModalState } from '../../app/shared/ModalState';
import { INavigationService } from '../../app/shared/NavigationService';
import { ZipBuilder } from '../../app/shared/ZipBuilder';
import { StoreState } from '../../app/StoreState';
import {
  OrderPageTranslation,
  OrderShipmentsModalTranslation,
  OrderShippingMessageTranslation,
} from '../localization/SiteTranslation';
import { EditOrderModalState } from './EditOrderModalState';
import { IOrderItemContextAction, OrderItemState } from './OrderItemState';
import { ReorderModalState } from './ReorderModalState';

export class OrderShipmentState {
  @observable.ref
  shipment: ShipmentData;

  @observable.ref
  items: OrderItemData[] = [];

  constructor(shipment: ShipmentData, items: OrderItemData[]) {
    makeObservable(this);
    this.shipment = shipment;
    this.items = items;
  }
}

export class OrderShipmentsModalState extends ModalState {
  @observable
  shipments: Array<OrderShipmentState> = [];

  @action setOrder(order: OrderData) {
    this.shipments = order.shipments
      .filter((x) => !!x.trackingUrl)
      .map((shipment) => {
        return new OrderShipmentState(
          shipment,
          order.items.filter((item) => shipment.lineItemsIds.includes(item.id)),
        );
      });
  }

  constructor(public translation: OrderShipmentsModalTranslation) {
    super();
    makeObservable(this);
  }
}

export class OrderState extends BasePageState<never> {
  @observable.ref
  public order?: OrderData;
  public editOrderModal: EditOrderModalState;
  public reorderModal: ReorderModalState;
  public orderShipmentsModal: OrderShipmentsModalState;

  private readonly configurationUrlBuilder: ConfiguratorUrlBuilder;
  private readonly zipBuilder: ZipBuilder;
  private readonly contextActions: IOrderItemContextAction[];

  constructor(
    private readonly orderNumber: string,
    private readonly shoppingContext: ShoppingContext,
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly eventAggregator: IEventAggregator,
    public readonly translation: OrderPageTranslation,
  ) {
    super();

    makeObservable(this);

    this.editOrderModal = new EditOrderModalState(this.client, this.shoppingContext, this.translation.editOrderModal);
    this.reorderModal = new ReorderModalState(
      this.client,
      this.eventAggregator,
      this.shoppingContext,
      this.translation.reorderModal,
    );
    this.orderShipmentsModal = new OrderShipmentsModalState(this.translation.orderShipmentsModal);

    this.configurationUrlBuilder = new ConfiguratorUrlBuilder(this.client);
    this.zipBuilder = new ZipBuilder(this.client, this.navigation);

    this.contextActions = [
      {
        label: this.translation.downloadImagesLabel,
        action: this.generateZipVisualizationImages,
      },
    ];

    this.editOrderModal.orderUpdated.subscribe(this.setOrder);
  }

  @override
  get metadata(): PageMetadata {
    return {
      title: this.order ? `Order ${this.order.number}` : null,
    };
  }

  @computed
  get orderItems(): OrderItemState[] {
    if (!this.order?.items) {
      return [];
    }

    return this.order.items.map((item) => new OrderItemState(item, this.reorderItem, this.contextActions));
  }

  @computed
  get shippingAndTrackingEnabled(): boolean {
    return this.order?.status !== 'Cancelled';
  }

  @computed
  get shippingDate(): string {
    return this.order?.shippingDate;
  }

  async onLoad(store: StoreState) {
    const response = await this.client.send(
      new GetDealerOrderDetailsQuery({
        shoppingContext: store.shoppingContext,
        orderNumber: this.orderNumber,
      }),
    );

    this.setOrder(response.orderDetails);
    this.orderShipmentsModal.setOrder(response.orderDetails);
    this.editOrderModal.setOrder(response.orderDetails);
    this.reorderModal.setOrder(response.orderDetails);
  }

  public openEditNameModal = () => {
    this.editOrderModal.setOrder(this.order);
    this.editOrderModal.open();
  };

  @action.bound
  private setOrder(order: OrderData) {
    this.order = order;
  }

  private reorderItem = async (item: OrderItemData) => {
    const shopUrl = await this.configurationUrlBuilder.buildFromCode(item.configurationCode, undefined, Tab.Summary);
    this.navigation.navigateTo(shopUrl);
  };

  private generateZipVisualizationImages = async (item: OrderItemData) => {
    const zipUrl = await this.zipBuilder.downloadZipConfigurationImages(item.configurationCode);
    Analytics.trackDownload({ url: zipUrl });
  };
}

export enum ExpectedShippingStatus {
  Range = 'range',
  Soon = 'soon',
  Delayed = 'delayed',
}

export const getExpectedShippingStatus = (expectedShipping: DaysRangeData): ExpectedShippingStatus => {
  const { minDays, maxDays } = expectedShipping;
  if (minDays > 0) {
    return ExpectedShippingStatus.Range;
  } else if (maxDays > 0) {
    return ExpectedShippingStatus.Soon;
  }

  return ExpectedShippingStatus.Delayed;
};

export const formatExpectedShippingMessage = (
  expectedShipping: DaysRangeData,
  translation: OrderShippingMessageTranslation,
) => {
  const { minDays, maxDays } = expectedShipping;
  const status = getExpectedShippingStatus(expectedShipping);

  switch (status) {
    case 'range':
      return translation.shippingWithinDaysFormat.interpolate([
        ['minDays', minDays.toString()],
        ['maxDays', maxDays.toString()],
      ]);
    case 'soon':
      return translation.shippingSoonText;
    case 'delayed':
      return translation.weHaveDelayText;
    default:
      return '';
  }
};
