import classNames from 'classnames';
import { Fragment } from 'react';

import { RequestQuoteContentTranslation } from '../../localization/SiteTranslation';
import HeaderLevel4 from '../../shared/components/Typography/HeaderLevel4';
import RequestQuoteStep from './RequestQuoteStep/RequestQuoteStep';
import css from './RequestQuoteSteps.css';

interface RequestQuoteStepsProps {
  translation: RequestQuoteContentTranslation;
  className?: string;
}

const RequestQuoteSteps = ({ className, translation }: RequestQuoteStepsProps) => {
  const steps = [
    { className: classNames(css.icon, css.icon1), text: translation.steps[0] },
    { className: classNames(css.icon, css.icon2), text: translation.steps[1] },
    { className: classNames(css.icon, css.icon3), text: translation.steps[2] },
  ];

  return (
    <div className={classNames(css.RequestQuoteSteps, className)}>
      <HeaderLevel4 className={css.heading}>{translation.stepsTitle}</HeaderLevel4>
      <div className={css.container}>
        {steps.map((step, key) => {
          return (
            <Fragment key={key}>
              <RequestQuoteStep className={step.className} text={step.text} />

              {/* Do not display arrow after last element */}
              {key !== steps.length - 1 && <div className={css.arrow} />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RequestQuoteSteps;
