import { ConfirmationPageTranslation } from '../../../localization/SiteTranslation';
import TertiaryButton from '../../components/Button/TertiaryButton';
import Icon from '../../components/Icon/Icon';
import Link from '../../components/Link';
import HeaderLevel3 from '../../components/Typography/HeaderLevel3';
import css from './HubSpotFormConfirmation.css';

export interface HubSpotFormConfirmationProps {
  buttonLink: string;
  translation: ConfirmationPageTranslation;
  isEmbedded?: boolean;
}

export const HubSpotFormConfirmation = ({ buttonLink, isEmbedded, translation }: HubSpotFormConfirmationProps) => {
  return (
    <div className={css.HubSpotFormConfirmation}>
      <Icon className={css.icon} name="sent" />
      <HeaderLevel3>{translation.title}</HeaderLevel3>
      <div className={css.description}>{translation.content}</div>
      {!isEmbedded && (
        <TertiaryButton className={css.button} itemElement={<Link href={buttonLink}>{translation.buttonLink}</Link>} />
      )}
    </div>
  );
};
