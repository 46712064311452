import { action, makeObservable, observable } from 'mobx';

import { DaysRangeData, ShoppingCartData, ShoppingCartItemData } from '../../app/data/model';
import { CheckboxInput, Form, TextInput, ValueMaxLength, ValueRequiredRule } from '../../app/shared/Form';
import { CartItemListTranslation } from '../localization/SiteTranslation';
import { CartItemState, ICartItemContextAction } from './CartItem/CartItemState';

export class CartState extends Form {
  @observable
  cartItems: CartItemState[] = [];

  selected: CheckboxInput = new CheckboxInput(true);

  customReference: TextInput = new TextInput().withRule(new ValueRequiredRule()).withRule(new ValueMaxLength(20));
  customName: TextInput = new TextInput().withRule(new ValueMaxLength(30));

  constructor(
    private readonly cart: ShoppingCartData,
    private readonly cartItemContextActions: ICartItemContextAction[],
    private readonly quantityChangeApproved: (item: ShoppingCartItemData, newQuantity: number) => Promise<void>,
    private readonly translation: CartItemListTranslation,
  ) {
    super();
    makeObservable(this);
    this.setCartItems(this.cart.items);

    this.inputsToValidate.push(this.customReference);
    this.inputsToValidate.push(this.customName);
  }

  public get cartId() {
    return this.cart.id;
  }

  public get shoppingCart() {
    return this.cart;
  }

  public get preparingTimeInWorkingDays(): DaysRangeData | undefined {
    return this.cart.preparingTimeInWorkingDays;
  }

  public get numberOfItems() {
    return this.cart.items.sum((item) => item.quantity);
  }

  @action.bound
  private setCartItems(cartItems: ShoppingCartItemData[]) {
    const newCartItems = cartItems.map((item) => {
      const oldItemState = this.cartItems.find((c) => c.item.id === item.id);
      const newItemState = new CartItemState(
        item,
        {
          contextActions: this.cartItemContextActions,
          quantityChangeApproved: this.quantityChangeApproved,
        },
        this.translation.cartItem,
      );
      if (oldItemState) {
        newItemState.setQuantityUpdate(oldItemState.quantityUpdate);
      }
      return newItemState;
    });
    this.cartItems = newCartItems;
  }
}
