import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../../app/AppState';
import EmptyCartInformation from '../../app/checkout/components/EmptyCartInformation';
import { RouteUrlBuilder } from '../../app/RouteDefinitions';
import ActionButton from '../../app/shared/components/ActionButton';
import NavButton from '../../app/shared/components/Button/NavButton';
import SecondaryButton from '../../app/shared/components/Button/SecondaryButton';
import { TextareaFieldWithLabel } from '../../app/shared/components/Textarea/Textarea';
import HeaderLevel3 from '../../app/shared/components/Typography/HeaderLevel3';
import { StoreUrlBuilder } from '../../app/shared/StoreUrlBuilder';
import { Authorize } from '../authentication/Authorize';
import { BuyerPolicy } from '../Policy';
import RouteDefinitions from '../routing/RouteDefinitions';
import { CartList } from './CartList/CartList';
import { CartListState } from './CartListState';
import css from './CartPage.css';
import CartSummary from './CartSummary/CartSummary';
import { DeliveryAddress } from './DeliveryAddress/DeliveryAddress';
import { OrderCreatedModal } from './OrderCreatedModal/OrderCreatedModal';
import OrderCreationConfirmationModal from './OrderCreationConfirmationModal/OrderCreationConfirmationModal';
import { OrderSummary } from './OrderSummary/OrderSummary';
import { SendCartToEmail } from './SendCartToEmail/SendCartToEmail';

interface Props {
  appState: AppState;
  state: CartListState;
}

interface State {}

@observer
export class CartPage extends Component<Props, State> {
  renderCartOverviewStep() {
    const { state } = this.props;

    if (!state.cartOverviewStep.isActive) {
      return null;
    }

    return (
      state?.carts?.any() && (
        <>
          {/* TODO: placing multiple order disabled */}
          {/* <Checkbox
          input={state.selectAllCheckbox}
          className={css.cartSelectAllCheckbox}
          size="large"
          invertColors
        >
          <strong className={css.cartSelectAllTextLabel}>Select all</strong>
        </Checkbox> */}
          <CartList state={state} />
        </>
      )
    );
  }

  renderCartSummaryStep() {
    const { state } = this.props;
    const translation = this.props.state.translation;

    if (!state.cartSummaryStep.isActive) {
      return null;
    }
    return (
      <div>
        <NavButton className={css.navButton} onClick={() => state.setStep(state.cartOverviewStep)} back>
          {translation.goBackButton}
        </NavButton>
        <div className={css.cartSummaryStep}>
          <div className={css.cartSelectedListContainer}>
            <div className={css.orderSummaryContainer}>
              <HeaderLevel3 className={css.title}>{translation.orderSummaryText}</HeaderLevel3>
              <div className={css.orderSummary}>
                <OrderSummary
                  cartList={state.selectedCarts}
                  commentInput={state.commentInput}
                  translation={translation.orderSummary}
                />
              </div>
              <HeaderLevel3 className={css.title}>{translation.deliveryAddressText}</HeaderLevel3>
              <DeliveryAddress
                deliveryAddress={state.deliveryAddress}
                className={css.deliveryAddress}
                translation={translation.deliveryAddress}
              />
              <TextareaFieldWithLabel
                className={css.deliveryInstructionsTextarea}
                label={translation.deliveryInstructionsLabel}
                placeholder={translation.deliveryInstructionsPlaceholder}
                input={state.deliveryInstructions}
                showValidationHints
              />
            </div>
          </div>
          <div className={css.cartSummaryContainer}>
            <div className={css.container}>
              <CartSummary
                className={css.cartSummaryBox}
                shoppingCart={state.summaryShoppingCart}
                translation={translation.cartSummary}
              />
              <Authorize policy={BuyerPolicy}>
                <div className={css.actionButtonContainer}>
                  {state.hasUserAccessToMultipleStores ? (
                    <>
                      <SecondaryButton onClick={state.openOrderConfirmation}>
                        {translation.placeOrderButton}
                      </SecondaryButton>
                      <OrderCreationConfirmationModal
                        state={state.orderCreationConfirmationModal}
                        applyCommand={state.placeOrderCommand}
                      />
                    </>
                  ) : (
                    <ActionButton buttonElement={SecondaryButton} command={state.placeOrderCommand}>
                      {translation.placeOrderButton}
                    </ActionButton>
                  )}
                </div>
              </Authorize>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSteps() {
    return (
      <div className={css.cartStepsContainer}>
        {this.renderCartOverviewStep()}
        {this.renderCartSummaryStep()}
      </div>
    );
  }

  render() {
    const { appState, state } = this.props;
    const catalogUrl = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.catalogRoute)).toString();

    // const handleOnStepClick = (step: ICheckoutStep) => this.props.state.setStep(step);

    return (
      <>
        <div className={css.CartPage}>
          {state?.carts?.any() && (
            <>
              {/* <CartProgressIndicator steps={state.steps} onStepClick={handleOnStepClick} /> */}
              {this.renderSteps()}
            </>
          )}
          {state?.carts?.empty() && (
            <EmptyCartInformation continueShoppingUrl={catalogUrl} translation={appState.translation.emptyCartPage} />
          )}
        </div>
        {/* TODO: placing multiple orders disabled  */}
        {/* {state?.carts.any() && (
          <CartListFooter
            numberOfItems={state.selectedCartsItemsCount}
            numberOfSelectedCarts={state.selectedCartsCount}
            total={state.summaryShoppingCart.totalWithTax}
            buttonAction={state.footerButtonAction}
            openSendConfigurationToEmailModal={state.openSendConfigurationToEmailModal}
          />
        )} */}
        <OrderCreatedModal state={state.orderCreationResultModal} />
        <SendCartToEmail state={state.sendOrderToEmailModal} />
      </>
    );
  }
}
