import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';

import InputSearch from '../../../../../shared/components/InputSearch/InputSearch';
import Label from '../../../../../shared/components/Label/Label';
import ThreeDotsLoader from '../../../../../shared/components/Loaders/ThreeDotsLoader';
import { RawConfiguratorSize } from '../../RawConfiguratorSize';
import {
  LazyLoadedFeatureSelectionState,
  SearchableFeatureSelectionState,
  SimplifiedFeatureSelectionState,
} from '../../RawConfiguratorState';
import css from './FeatureSelection.css';

interface FeatureSelectionProps<T> {
  disabled?: boolean;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  label?: string;
  inlineLabel?: boolean;
  value?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  keySelector?: (x: T, id: number) => string;
  selection: SimplifiedFeatureSelectionState;
  size: RawConfiguratorSize;
}

const FeatureSelection = <T,>(props: FeatureSelectionProps<T>) => {
  const { className, selection, containerClassName, labelClassName, label, inlineLabel, onChange, size } = props;
  const showLoader = selection instanceof LazyLoadedFeatureSelectionState && selection.loadingIndicator.isLoading;

  return (
    <div className={classNames(inlineLabel && css.inlineLabelWrapper, containerClassName)}>
      {label && <Label className={classNames({ [css.inlineLabel]: inlineLabel }, labelClassName)}>{label}</Label>}
      {selection instanceof SearchableFeatureSelectionState && (
        <>
          <SearchBar
            onSearch={(searchPhrase) => {
              selection.setSearchPhrase(searchPhrase);
              selection.search();
            }}
            size={size}
          />
          {selection.message && selection.searchPhrase && <div className={css.message}>{selection.message}</div>}
        </>
      )}
      {showLoader && (
        <div className={css.loader}>
          <ThreeDotsLoader size="medium" />
        </div>
      )}
      {!showLoader && selection.availableOptions.any() && (
        <select
          disabled={props.disabled}
          value={props.value}
          className={classNames(css.Select, className)}
          onChange={onChange}
        >
          <Option value="">Select an option</Option>
          {selection.availableOptions.map((item) => (
            <Option value={item.code} key={item.code}>
              {item.name} ({item.code})
            </Option>
          ))}
        </select>
      )}
      {selection.error && (
        <div className={classNames(css.message, css.error)}>
          Oops! We detected some issues with the current configuration ({selection.error}). Please try other options or
          contact the support team.
        </div>
      )}
    </div>
  );
};

const Option = (props: React.ComponentPropsWithoutRef<'option'>) => {
  return <option {...props} />;
};

interface SearchBarProps {
  className?: string;
  onSearch: (searchPhrase: string) => void;
  size: RawConfiguratorSize;
}

const SearchBar = ({ className, onSearch, size }: SearchBarProps) => {
  return (
    <InputSearch
      className={className}
      placeholderTranslation={'Enter option code or name'}
      onEnterKeyPress={(event) => onSearch(event.target.value)}
      classNameButtonClose={size === 'small' && css.smallSearchCloseButton}
      classNameContainer={classNames(css.searchContainer, size === 'small' && css.smallSearchContainer)}
    />
  );
};

export default observer(FeatureSelection);
