import * as React from 'react';

import Tab, { ITab } from './Tab/Tab';
import css from './TabList.css';

interface TabListProps {
  tabs: ITab[];
  onKeyDown: React.KeyboardEventHandler;
  onTabClick: (name: string) => void;
  refs: React.MutableRefObject<HTMLElement[]>;
  selectedTabClassName?: string;
  tabClassName?: string;
}

const TabList = ({ onKeyDown, onTabClick, refs, selectedTabClassName, tabClassName, tabs }: TabListProps) => {
  return tabs.length > 0 ? (
    <ul role="tablist" className={css.list} onKeyDown={onKeyDown}>
      {tabs.map((item) => (
        <li role="presentation" className={css.tab} key={item.id}>
          <Tab
            ref={(ref) => refs.current.push(ref)}
            id={item.id}
            className={tabClassName}
            selectedTabClassName={selectedTabClassName}
            isSelected={item.isSelected}
            name={item.name}
            panelId={item.panelId}
            onClick={onTabClick}
          >
            {item.name}
          </Tab>
        </li>
      ))}
    </ul>
  ) : null;
};

export default TabList;
