import classNames from 'classnames';
import { observer } from 'mobx-react';

import SecondaryButton from '../../../../../shared/components/Button/SecondaryButton';
import DefaultImage from '../../../../../shared/components/DefaultImage/DefaultImage';
import Image from '../../../../../shared/components/Image/Image';
import List from '../../../../../shared/components/List/List';
import ModularConfiguratorCoordinatorState, {
  UsedModularComponentListItem,
} from '../../ModularConfiguratorCoordinatorState';
import css from './ModularConfiguratorNavigation.css';

interface ModularConfiguratorNavigationProps {
  state: ModularConfiguratorCoordinatorState;
  className?: string;
}

const ModularConfiguratorNavigation = observer(({ className, state }: ModularConfiguratorNavigationProps) => {
  const renderItem = (item: UsedModularComponentListItem) => {
    return (
      <div className={css.component}>
        {item.image.url ? (
          <Image
            className={css.imageContainer}
            classNameImageElement={css.image}
            src={item.image.url}
            alt={item.name}
          />
        ) : (
          <DefaultImage className={css.imageContainer} />
        )}
        {`${item.name} (${item.counter})`}
      </div>
    );
  };

  return (
    <footer className={classNames(css.container, className)}>
      <div className={css.summary}>
        <span className={css.title}>{state.productFamilyName}</span>
        <List className={css.componentList} items={state.usedComponents} itemRenderer={renderItem} keyProperty={'id'} />
        <strong style={{ marginLeft: 26 }}>{state.configurator.selectedComponent?.coreState.code}</strong>
      </div>
      <SecondaryButton onClick={state.goToSummary}>Summary</SecondaryButton>
    </footer>
  );
});

export default ModularConfiguratorNavigation;
