import * as React from 'react';

import { DownloadListTranslation } from '../../../localization/SiteTranslation';
import ExtendedPagination from '../../../shared/components/ExtendedPagination/ExtendedPagination';
import List from '../../../shared/components/List/List';
import ThreeDotsLoader from '../../../shared/components/Loaders/ThreeDotsLoader';
import { PaginationState } from '../../../shared/components/Pagination/PaginationState';
import Download from './Download/Download';
import { IDownloadState } from './Download/DownloadState';
import css from './DownloadList.css';
import EmptyScreen from './EmptyScreen/EmptyScreen';

interface DownloadListProps {
  id: string;
  isLoading: boolean;
  items: IDownloadState[];
  pagination: PaginationState;
  resultsCounter: number;
  translation: DownloadListTranslation;
}

const DownloadList: React.FC<DownloadListProps> = ({
  id,
  isLoading,
  items,
  pagination,
  resultsCounter,
  translation,
}) => {
  const renderItem = (item: IDownloadState) => {
    return (
      <Download
        image={item.image}
        title={item.title}
        subtitle={item.subtitle}
        translation={translation.download}
        downloadCommand={item.downloadCommand}
      />
    );
  };

  return (
    <div id={id} className={css.DownloadList} role="region" aria-live="polite">
      <div className={css.content}>
        {isLoading && (
          <div className={css.loaderContainer}>
            <ThreeDotsLoader className={css.loader} size="large" />
          </div>
        )}

        {items.empty() ? (
          <EmptyScreen translation={translation.emptyScreen} />
        ) : (
          <>
            <h2 className="visuallyHidden">
              {translation.results.interpolate([['results', resultsCounter.toString()]])}
            </h2>
            <List
              className={css.items}
              itemClassName={css.item}
              items={items}
              itemRenderer={renderItem}
              keyProperty="id"
            />
          </>
        )}
      </div>

      <ExtendedPagination state={pagination} translation={translation.pagination} />
    </div>
  );
};

export default DownloadList;
