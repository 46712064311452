import { appendQuery } from '../shared/common';

export class ForceDownloadUrlBuilder {
  functionsUrl: string;

  constructor(functionsUrl: string) {
    this.functionsUrl = functionsUrl;
  }

  public build(url: string, name: string) {
    const base = `${this.functionsUrl}/force-download`;
    return appendQuery(base, [`file=${encodeURIComponent(url)}`, `name=${encodeURIComponent(name)}`]);
  }
}
