import { action, makeObservable } from 'mobx';

import { SiteTranslation } from './SiteTranslation';

export interface ITranslationResolver {
  default: SiteTranslation;
  resolve: (languageCode: string) => SiteTranslation;
  languageCodes: string[];
}

export class TranslationResolver<T extends SiteTranslation> implements ITranslationResolver {
  public constructor(private readonly translations: Map<string, T>, private readonly defaultLanguageCode: string) {
    makeObservable(this);
  }

  public get default(): T {
    return this.translations.get(this.defaultLanguageCode);
  }

  public get languageCodes(): string[] {
    return [...this.translations.keys()];
  }

  @action
  public resolve(languageCode: string): T {
    if (languageCode) {
      const texts = this.translations.get(languageCode.toLowerCase());

      if (texts) {
        return texts;
      }
    }

    return this.default;
  }
}
