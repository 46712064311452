import { action, computed, makeObservable, observable } from 'mobx';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import Event from '../../../shared/Event';
import { GeolocationService, IGeolocationService } from '../../../shared/GeolocationService';
import { LocationButtonState, PositionError } from '../StoreList/StoreListState';

export interface LocationChangedEventArgs {
  location: google.maps.LatLngLiteral;
  bounds: google.maps.LatLngLiteral[];
}

export default class StoreLocatorInputState {
  private geolocationService: IGeolocationService;
  public onChange: Event<LocationChangedEventArgs>;

  @observable
  public isGoogleApiInitialized: boolean;

  @observable
  geolocationButtonState: LocationButtonState;

  @observable
  locationSearch: string;

  constructor() {
    makeObservable(this);
    this.onChange = new Event<LocationChangedEventArgs>();
    this.geolocationService = new GeolocationService();
    this.geolocationButtonState = LocationButtonState.Clickable;
    this.locationSearch = '';
  }

  @action
  setGoogleMapApiInitialization() {
    this.isGoogleApiInitialized = true;
  }

  @action
  autocompleteSelect(result: string) {
    geocodeByAddress(result).then((g) => {
      if (g.any()) {
        const geocodeResult = g.find((x) => true);
        getLatLng(geocodeResult).then((latLng) => {
          this.setSearchString(geocodeResult.formatted_address);
          let viewportParts = [
            geocodeResult.geometry.viewport.getSouthWest(),
            geocodeResult.geometry.viewport.getNorthEast(),
          ].map((x) => x.toJSON());
          this.onChange.raise({
            location: latLng,
            bounds: viewportParts,
          });
        });
      }
    });
  }

  @action
  changeButtonState(state: LocationButtonState) {
    this.geolocationButtonState = state;
  }

  @computed
  get isGeolocationInactive() {
    return (
      this.geolocationButtonState === LocationButtonState.Disabled ||
      this.geolocationButtonState === LocationButtonState.Unavailable
    );
  }

  @computed
  get isGeolocationLoading() {
    return this.geolocationButtonState === LocationButtonState.Loading;
  }

  @computed
  get isGeolocationClickable() {
    return this.geolocationButtonState === LocationButtonState.Clickable;
  }

  @computed
  get isGeolocationUnavailable() {
    return this.geolocationButtonState === LocationButtonState.Unavailable;
  }

  @action
  getToCurrentLocation() {
    this.changeButtonState(LocationButtonState.Loading);
    this.geolocationService.getCurrentPosition(
      (success) => {
        const location = { lat: success.coords.latitude, lng: success.coords.longitude };
        new google.maps.Geocoder().geocode({ location: location }, (p) => {
          if (p.length > 0) {
            this.changeButtonState(LocationButtonState.Clickable);
            const firstPlace = p.find((x) => true);
            const boundaries = [
              firstPlace.geometry.viewport.getNorthEast(),
              firstPlace.geometry.viewport.getSouthWest(),
            ].map((x) => x.toJSON());
            this.onChange.raise({
              location: location,
              bounds: boundaries,
            });
            this.setSearchString(firstPlace.formatted_address);
          } else {
            this.changeButtonState(LocationButtonState.Disabled);
          }
        });
      },
      (error) => {
        const buttonState = error.code === PositionError.PermissionDenied;
        this.changeButtonState(buttonState ? LocationButtonState.Disabled : LocationButtonState.Unavailable);
      },
      {
        enableHighAccuracy: true,
      },
    );
  }

  @action.bound
  setSearchString(searchString: string) {
    this.locationSearch = searchString;
  }
}
