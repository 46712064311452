import { EmptyCartPageTranslation } from '../../localization/SiteTranslation';
import TertiaryButton from '../../shared/components/Button/TertiaryButton';
import Icon from '../../shared/components/Icon/Icon';
import Link from '../../shared/components/Link';
import HeaderLevel3 from '../../shared/components/Typography/HeaderLevel3';
import css from './EmptyCartInformation.css';

interface EmptyCartInformationProps {
  continueShoppingUrl: string;
  translation: EmptyCartPageTranslation;
}

const EmptyCartInformation = ({ translation, continueShoppingUrl }: EmptyCartInformationProps) => {
  return (
    <div className={css.EmptyCartInformation}>
      <Icon name="basket" className={css.cartIcon} />
      <HeaderLevel3>{translation.basketEmptyLine1}</HeaderLevel3>
      <div className={css.smallText}>{translation.basketEmptyLine2}</div>
      <TertiaryButton
        className={css.button}
        itemElement={
          <Link href={continueShoppingUrl} className={css.link}>
            {translation.continueButton}
          </Link>
        }
      />
    </div>
  );
};

export default EmptyCartInformation;
