import { AppInsights } from 'applicationinsights-js';

import Logger from '../Logger';
import { AppInsightsLogAppender } from './AppInsightsLogAppender';
import ICustomTelemetryHandler from './handlers/ICustomTelemetryHandler';
import IgnoreExternalScriptErrorHandler from './handlers/IgnoreExternalScriptErrorHandler';
import TraceReactHydrationErrorAsWarningHandler from './handlers/TraceReactHydrationErrorAsWarningHandler';
import {
  DependencyTelemetry,
  EventTelemetry,
  ExceptionTelemetry,
  IMonitoringService,
  MetricTelemetry,
  PageViewTelemetry,
  TraceTelemetry,
} from './IMonitoringService';
import includeAdditionalProperties from './includeAdditionalProperties';

export class MonitoringService implements IMonitoringService {
  constructor(instrumentationKey: string) {
    AppInsights.downloadAndSetup({
      instrumentationKey: instrumentationKey,
      verboseLogging: false,
      isCookieUseDisabled: true,
    });

    const customHandlers: ICustomTelemetryHandler[] = [
      new IgnoreExternalScriptErrorHandler(),
      new TraceReactHydrationErrorAsWarningHandler(),
    ];

    AppInsights.queue.push(() => {
      AppInsights.context.addTelemetryInitializer((envelope) => {
        let shouldSendTelemetry = true;

        customHandlers.forEach((handler) => {
          if (handler.tryHandle(envelope)) {
            shouldSendTelemetry = false;
          }
        });

        return shouldSendTelemetry;
      });
    });

    Logger.log(`Application insights initialized with instrumentation key ${instrumentationKey}`);
    Logger.addAppender(new AppInsightsLogAppender());
  }

  startTrackPage(): void {
    if (AppInsights.context) {
      AppInsights.context.operation.name = window.location.pathname;
      AppInsights.context.operation.id = this.getOperationId();
    }

    AppInsights.startTrackPage(window.location.pathname);
  }

  stopTrackPage(): void {
    AppInsights.stopTrackPage(window.location.pathname);
    AppInsights.flush();
  }

  trackDependency(dependency: DependencyTelemetry): void {
    AppInsights.trackDependency(
      this.getOperationId(),
      dependency.method,
      dependency.url,
      dependency.pathName,
      dependency.duration,
      dependency.success,
      dependency.resultCode,
    );
  }

  trackPageView(pageView: PageViewTelemetry): void {
    AppInsights.trackPageView(
      pageView.name,
      pageView.url,
      includeAdditionalProperties(pageView.properties),
      {},
      pageView.duration,
    );
  }

  trackEvent(event: EventTelemetry): void {
    AppInsights.trackEvent(event.name, includeAdditionalProperties(event.properties));
  }

  trackException(exception: ExceptionTelemetry): void {
    AppInsights.trackException(exception.exception, '', includeAdditionalProperties(exception.properties));
  }

  trackMetric(metric: MetricTelemetry): void {
    AppInsights.trackMetric(
      metric.name,
      metric.value,
      metric.count,
      metric.min,
      metric.max,
      includeAdditionalProperties(metric.properties),
    );
  }

  trackTrace(trace: TraceTelemetry): void {
    AppInsights.trackTrace(trace.message, includeAdditionalProperties(trace.properties), trace.severity);
  }

  private getOperationId() {
    const base64chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    let result = '';
    let random = Math.random() * 1073741824; // 5 symbols in base64, almost maxint

    while (random > 0) {
      const char = base64chars.charAt(random % 64);
      result += char;
      random = Math.floor(random / 64);
    }

    return result;
  }
}
