import { observer } from 'mobx-react';
import { Component } from 'react';

import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import { PaymentWidgetProps } from '../IPaymentMethod';
import { ManualTestPaymentMethod } from './ManualTestPaymentMethod';

@observer
export default class ManualTestPaymentWidget extends Component<PaymentWidgetProps<ManualTestPaymentMethod>, {}> {
  render() {
    return (
      <div>
        <Checkbox input={this.props.paymentMethod.input} enabledErrorMessages>
          Check the checkbox if payment should pass.
        </Checkbox>
      </div>
    );
  }
}
