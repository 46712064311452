import classNames from 'classnames';
import * as React from 'react';

import WindowSizeObserver from '../../../../app/layout/WindowSizeObserver';
import TabList from './TabList/TabList';
import TabPanel from './TabPanel/TabPanel';

const removeWhitespaceCharacters = (value: string) => value.replace(/\s/g, '-');

export type TabbedInterfaceItem = {
  name: string;
  content: React.ReactChild;
  innerClassName?: string;
  onClose?: () => void;
};

interface TabbedInterfaceProps {
  id: string;
  tabs: TabbedInterfaceItem[];
  hiddenPanelClassName?: string;
  panelClassName?: string;
  panelInnerClassName?: string;
  selectedTabClassName?: string;
  tabClassName?: string;
}

const TabbedInterface = ({
  hiddenPanelClassName,
  id,
  panelClassName,
  panelInnerClassName,
  selectedTabClassName,
  tabClassName,
  tabs,
}: TabbedInterfaceProps) => {
  const [panelHeight, setPanelHeight] = React.useState<number>(0);
  const [selectedTab, setSelectedTab] = React.useState<string>('');

  const panelRefs = React.useRef<React.RefObject<HTMLDivElement>[]>([]);
  const tabRefs = React.useRef<HTMLElement[]>([]);

  const getBiggestPanelHeight = () => {
    return tabPanels.reduce((maxHeight, item, index) => {
      const itemHeight = panelRefs.current[index]?.current.scrollHeight;

      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }

      return maxHeight;
    }, 0);
  };

  const getIndexOfSelectedTab = () => {
    return tabs.findIndex((item) => item.name === selectedTab);
  };

  const getPanelId = (tabName: string) => `${id}-${removeWhitespaceCharacters(tabName)}-panel`;

  const getTabId = (tabName: string) => `${id}-${removeWhitespaceCharacters(tabName)}-tab`;

  const isTabSelected = React.useCallback((tabName: string) => tabName === selectedTab, [selectedTab]);

  const focusActivePanel = () => {
    const indexOfSelectedTab = getIndexOfSelectedTab();

    if (indexOfSelectedTab === -1) {
      return;
    }

    panelRefs.current[indexOfSelectedTab].current.parentElement?.focus();
  };

  const selectTab = (tabName: string) => {
    const currentTab = tabs.find((tab) => tab.name === selectedTab);

    if (currentTab?.onClose) {
      currentTab.onClose();
    }

    setSelectedTab(tabName);
  };

  const selectPreviousTab = () => {
    const indexOfSelectedTab = getIndexOfSelectedTab();

    if (indexOfSelectedTab === -1) {
      return;
    }

    const indexOfPreviousTab = indexOfSelectedTab - 1;

    if (indexOfPreviousTab === -1) {
      return;
    }

    const previousTab = tabList[indexOfPreviousTab];

    selectTab(previousTab.name);
    tabRefs.current[indexOfPreviousTab].focus();
  };

  const selectNextTab = () => {
    const indexOfSelectedTab = getIndexOfSelectedTab();

    if (indexOfSelectedTab === -1) {
      return;
    }

    const indexOfNextTab = indexOfSelectedTab + 1;

    if (indexOfNextTab === tabs.length) {
      return;
    }

    const nextTab = tabList[indexOfNextTab];

    selectTab(nextTab.name);
    tabRefs.current[indexOfNextTab].focus();
  };

  const handleKeyPress: React.KeyboardEventHandler = (event) => {
    if (event.key === 'ArrowLeft') {
      selectPreviousTab();
    }

    if (event.key === 'ArrowRight') {
      selectNextTab();
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      focusActivePanel();
    }
  };

  const handleWindowSizeChange = () => {
    const maxPanelHeight = getBiggestPanelHeight();
    setPanelHeight(maxPanelHeight);
  };

  const tabList = React.useMemo(() => {
    return tabs.map((item) => ({
      name: item.name,
      id: getTabId(item.name),
      isSelected: isTabSelected(item.name),
      panelId: getPanelId(item.name),
    }));
  }, [isTabSelected, tabs]);

  const tabPanels = React.useMemo(() => {
    return tabs.map((item) => ({
      id: getPanelId(item.name),
      isVisible: isTabSelected(item.name),
      tabId: getTabId(item.name),
      title: item.name,
      content: item.content,
      innerClassName: item.innerClassName,
    }));
  }, [isTabSelected, tabs]);

  React.useEffect(() => {
    WindowSizeObserver.onSizeChange.subscribe(handleWindowSizeChange);

    return () => WindowSizeObserver.onSizeChange.unsubscribe(handleWindowSizeChange);
  }, []);

  React.useEffect(() => {
    const isValidTabSelected = tabs.some((tab) => tab.name === selectedTab);

    if (!isValidTabSelected) {
      const firstTab = tabs.first();

      if (firstTab) {
        selectTab(firstTab.name);
      }
    }
  }, [tabs]);

  React.useEffect(() => {
    handleWindowSizeChange();
  }, [tabPanels]);

  if (panelRefs.current.length !== tabPanels.length) {
    panelRefs.current = tabPanels.map((_, index) => panelRefs.current[index] ?? React.createRef<HTMLDivElement>());
  }

  return (
    <>
      <TabList
        refs={tabRefs}
        tabClassName={tabClassName}
        selectedTabClassName={selectedTabClassName}
        tabs={tabList}
        onTabClick={selectTab}
        onKeyDown={handleKeyPress}
      />
      {tabPanels.map(({ content, innerClassName, ...item }, index) => (
        <TabPanel
          ref={panelRefs.current[index]}
          className={panelClassName}
          hiddenPanelClassName={hiddenPanelClassName}
          innerClassName={classNames(panelInnerClassName, innerClassName)}
          minHeight={panelHeight}
          {...item}
          key={item.title}
        >
          {content}
        </TabPanel>
      ))}
    </>
  );
};

export default TabbedInterface;
