import { action, makeObservable, observable } from 'mobx';

import { Component3d } from '../3d/Asset3dReference';
import { TransformationGroup, Visualization3dData } from '../data/model';
import { IVisualizationState } from './IVisualizationState';
import { Visualization3dBuilder } from './Visualization3dBuilder';

export interface Visualization3d {
  components3d: Array<Component3d>;
  transformations: Array<TransformationGroup>;
  shadowCasters: Array<string>;
  cameraDistanceFactor: number;
  materialLibraryBaseUrl?: string;
}

export class Visualization3dState implements IVisualizationState<Visualization3dData> {
  private data: Visualization3dData;

  @observable
  visualization3d: Visualization3d = {
    components3d: [],
    transformations: [],
    shadowCasters: [],
    cameraDistanceFactor: 1,
  };

  constructor(data: Visualization3dData) {
    makeObservable(this);
    this.data = data;
  }

  getResourcesUrls(): string[] {
    return this.visualization3d.components3d
      .map((x) => x.mesh.url)
      .concat(this.visualization3d.components3d.filter((x) => x.material).map((x) => x.material!.url));
  }

  @action
  setVisualization3d(visualization: Visualization3d) {
    this.visualization3d = visualization;
  }

  initialize(data: Visualization3dData, selectedComponent: Map<string, string>) {
    this.data = data;
    this.setComponents(selectedComponent);
  }

  setComponents(components: Map<string, string>): void {
    const components3d = Visualization3dBuilder.buildComponents3d(this.data, components);
    const transformations = Visualization3dBuilder.getActiveTransformations(this.data, components);
    const cameraDistanceFactor = Visualization3dBuilder.getActiveCameraDistanceFactor(this.data, components);
    const shadowCasters = this.data.mapping.shadowCasters;
    const materialLibraryBaseUrl = this.data.baseMaterials3dUrl;
    const visualization: Visualization3d = {
      components3d,
      transformations,
      cameraDistanceFactor,
      shadowCasters,
      materialLibraryBaseUrl,
    };

    this.setVisualization3d(visualization);
  }
}
