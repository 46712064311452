import { ProductConfigurationQueryParser } from '../shared/hubSpot/ProductConfigurationQueryParser';
import { QueryParser } from '../shared/QueryParser';
import { RequestQuoteQuery } from './RequestQuoteQuery';

export class RequestQuoteQueryParser extends ProductConfigurationQueryParser {
  public static CAMPAIGN_CODE = 'campaign';

  static toQuery(model: RequestQuoteQuery) {
    const result = super.toQuery(model);

    if (model.campaignCode) {
      RequestQuoteQueryParser.setValue(result, RequestQuoteQueryParser.CAMPAIGN_CODE, model.campaignCode);
    }

    return result;
  }

  static toModel(query: Map<string, string>): RequestQuoteQuery {
    const model: RequestQuoteQuery = {};

    if (query.has(RequestQuoteQueryParser.CAMPAIGN_CODE)) {
      model.campaignCode = QueryParser.getValue(query, RequestQuoteQueryParser.CAMPAIGN_CODE);
    }

    const productConfigurationQueryModel = ProductConfigurationQueryParser.toModel(query);

    return { ...productConfigurationQueryModel, ...model };
  }
}
