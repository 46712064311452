import classnames from 'classnames';
import * as React from 'react';

import { PaymentMethodData } from '../../../../data/model';
import InputRadio from '../../../../shared/components/InputRadio/InputRadio';
import css from './PaymentMethodSelectorOption.css';

interface PaymentMethodSelectorOptionProps {
  data: PaymentMethodData;
  name: string;
  isChecked: boolean;
  onChange: (code: string) => void;
}

const PaymentMethodSelectorOption = ({ data, isChecked, name, onChange }: PaymentMethodSelectorOptionProps) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value);
  };

  return (
    <InputRadio
      id={data.code}
      className={classnames(css.input, { [css.checked]: isChecked })}
      classNameLabel={css.label}
      name={name}
      value={data.code}
      checked={isChecked}
      onChange={handleChange}
      key={data.code}
    >
      <span className={css.text}>{data.name}</span>
      {data.supportedCreditCardsImage?.url && (
        <img className={css.image} src={data.supportedCreditCardsImage.url} alt={data.supportedCreditCardsImage.name} />
      )}
    </InputRadio>
  );
};

export default PaymentMethodSelectorOption;
