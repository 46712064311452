import css from './RequestQuoteStep.css';

interface RequestQuoteStepProps {
  text: string;
  className?: string;
}

const RequestQuoteStep = ({ className, text }: RequestQuoteStepProps) => {
  return (
    <div className={css.requestQuoteStep}>
      <div className={css.roundWrapper}>
        <div className={css.iconWrapper}>
          <div className={className} />
        </div>
      </div>
      <div className={css.text}>{text}</div>
    </div>
  );
};

export default RequestQuoteStep;
