import { ShoppingContext } from '../../data/model';
import Logger from '../Logger';
import { RelativeUrl } from '../Url';
import {
  ButtonClickEventData,
  CartEventData,
  CheckoutEventData,
  CheckoutOptionEventData,
  ITrackingService,
  LinkClickEventData,
  ProductData,
  ProductsEventData,
  PurchaseEventData,
} from './TrackingService';

export class Analytics {
  private static services: Array<ITrackingService> = [];

  private static allowedQueryParams = ['step', 'code', 'model'];

  static safeInvoke(func: (service: ITrackingService) => void) {
    Analytics.services.forEach((s) => {
      try {
        func(s);
      } catch (e) {
        Logger.exception(`For ${Object.getPrototypeOf(s).name} there was an error`, e);
      }
    });
  }

  static addService(service: ITrackingService) {
    Analytics.services.push(service);
  }

  static trackConfigureClick(context: ShoppingContext): void {
    Analytics.trackButtonClick('configureClick', context);
  }

  static trackCheckoutClick(context: ShoppingContext): void {
    Analytics.trackButtonClick('checkoutClick', context);
  }

  static trackEmailMyConfigurationClick(context: ShoppingContext): void {
    Analytics.trackButtonClick('trackMyConfigurationClick', context);
  }

  static trackAskForPriceClick(context: ShoppingContext): void {
    Analytics.trackButtonClick('askForPriceClick', context);
  }

  static trackAskForPriceFormSubmitClick(context: ProductsEventData): void {
    Analytics.trackButtonClick('askForPriceFormSubmitClick', context.context, context.products);
  }

  static trackPageVisit(relativeUrl: RelativeUrl): void {
    const cleanedUrl = relativeUrl.clone();
    cleanedUrl.query.clear();

    relativeUrl.query.forEach((value: string, key: string) => {
      if (Analytics.allowedQueryParams.contains(key)) {
        cleanedUrl.query.set(key, value);
      }
    });

    Analytics.safeInvoke((x) => x.trackPageVisit(cleanedUrl.toString()));
  }

  static trackButtonClick(buttonName: string, context: ShoppingContext, products?: ProductData[]): void {
    const data: ButtonClickEventData = { buttonName: buttonName, context: context, products: products };
    Analytics.safeInvoke((x) => x.trackButtonClick(data));
  }

  static trackDownload(data: LinkClickEventData): void {
    Analytics.safeInvoke((x) => x.trackDownload(data));
  }

  static trackAskForPriceCompleted(data: ProductsEventData): void {
    Analytics.safeInvoke((x) => x.trackAskForPriceCompleted(data));
  }

  static trackAddToCart(data: CartEventData): void {
    Analytics.safeInvoke((x) => x.trackAddToCart(data));
  }

  static trackRemoveFromCart(data: CartEventData): void {
    Analytics.safeInvoke((x) => x.trackRemoveFromCart(data));
  }

  static trackCheckoutStep(data: CheckoutEventData): void {
    Analytics.safeInvoke((x) => x.trackCheckoutStep(data));
  }

  static trackCheckoutOption(data: CheckoutOptionEventData): void {
    Analytics.safeInvoke((x) => x.trackCheckoutOption(data));
  }

  static trackCheckoutCompleted(data: PurchaseEventData): void {
    Analytics.safeInvoke((x) => x.trackCheckoutCompleted(data));
  }
}
