import classNames from 'classnames';

import Button, { ButtonProps } from './Button';
import css from './SecondaryButton.css';

const SecondaryButton = ({ className, ...buttonProps }: ButtonProps) => {
  return <Button {...buttonProps} className={classNames(css.SecondaryButton, className)} />;
};

export default SecondaryButton;
