import classNames from 'classnames';
import { CustomArrowProps } from 'react-slick';

import { SliderNextArrow, SliderPrevArrow } from '../../SliderNavigationArrows/SliderNavigationArrows';
import css from './CarouselNavButton.css';

const REACT_SLICK_ARROW_DISABLED_CLASSNAME = 'slick-disabled';

export enum CarouselNavButtonType {
  Next,
  Previous,
}

export interface CarouselNavButtonProps extends CustomArrowProps {
  type: CarouselNavButtonType;
  classNameDisabled?: string;
  iconClassName?: string;
}

const CarouselNavButton = ({ className, classNameDisabled, type, ...props }: CarouselNavButtonProps) => {
  const isDisabled = className?.includes(REACT_SLICK_ARROW_DISABLED_CLASSNAME);

  const elementClass = classNames(className, css.button, {
    [css.previous]: type === CarouselNavButtonType.Previous,
    [css.next]: type === CarouselNavButtonType.Next,
    [classNameDisabled]: isDisabled,
  });

  if (type === CarouselNavButtonType.Previous) {
    return <SliderPrevArrow {...props} className={elementClass} disabled={isDisabled} />;
  }

  return <SliderNextArrow {...props} className={elementClass} disabled={isDisabled} />;
};

export default CarouselNavButton;
