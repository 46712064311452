import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { PropertyData } from '../../../data/model';
import { FabricFiltersTranslation } from '../../../localization/SiteTranslation';
import Select from '../../../shared/components/Select/Select';
import { FabricCollectionBasedFilterState } from '../../FabricCollectionBasedFilterState';
import { PropertyBasedFabricFilterState } from '../../PropertyBasedFabricFilterState';
import css from './FabricFilters.css';

interface FabricFilterProps {
  showFilters: boolean;
  collectionsFilter: FabricCollectionBasedFilterState;
  filters: Array<PropertyBasedFabricFilterState>;
  noResults: boolean;
  translation: FabricFiltersTranslation;
  className?: string;
  clearButton?: JSX.Element | null;
}

@observer
class FabricFilter extends Component<FabricFilterProps, {}> {
  renderCollectionsSelect(
    collectionsFilterState: FabricCollectionBasedFilterState,
    translation: FabricFiltersTranslation,
  ) {
    return (
      <Select
        value={collectionsFilterState.selected.id}
        className={css.select}
        label={translation.collectionFilterLabel}
        items={collectionsFilterState.available}
        keySelector={(item) => item.id}
        itemRenderer={(item) => item.name}
        onChange={(e) => {
          collectionsFilterState.update(collectionsFilterState.available.find((x) => x.id === e.target.value));
        }}
      />
    );
  }

  renderSelect(filters: Array<PropertyData>, filterState: PropertyBasedFabricFilterState, key: number) {
    return (
      <Select
        value={filterState.selected.valueId}
        key={key}
        className={css.select}
        label={filterState.data.displayName}
        items={filters}
        keySelector={(item) => item.valueId}
        itemRenderer={(item) => item.value}
        onChange={(e) => {
          filterState.update(filters.find((x) => x.valueId === e.target.value));
        }}
      />
    );
  }

  render() {
    const { showFilters, className, collectionsFilter, filters, noResults, translation } = this.props;

    return (
      showFilters && (
        <div className={classNames(css.filters, className)}>
          <div className={css.filterGroups}>
            {collectionsFilter.isVisible && this.renderCollectionsSelect(collectionsFilter, translation)}
            {filters.map((filterGroup, key) => {
              return this.renderSelect(filterGroup.available, filterGroup, key);
            })}
          </div>
          <div className={classNames(noResults ? css.message : css.hideMessage)}>{translation.noFabricsAvailable}</div>
        </div>
      )
    );
  }
}

export default FabricFilter;
