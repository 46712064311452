import { INavigationService } from '../shared/NavigationService';
import ProductDetailsState from './components/ProductDetails/ProductDetailsState';
import { ProductFamilyPageQuery, ProductFamilyQueryParser } from './ProductFamilyPageQuery';

export class ProductFamilyPageQueryConverter {
  private navigation: INavigationService;
  private modelDetails: ProductDetailsState;

  constructor(navigation: INavigationService, modelDetails: ProductDetailsState) {
    this.navigation = navigation;
    this.modelDetails = modelDetails;
  }

  public initialize() {
    this.restoreSelectionFromQuery();
    this.modelDetails.modelSelector.changed.subscribe(() => this.updateQuery());
    this.modelDetails.predefinedConfigurations.changed.subscribe(() => this.updateQuery());
  }

  private restoreSelectionFromQuery() {
    const query = ProductFamilyQueryParser.toModel(this.navigation.currentUrl.query);

    if (query && query.model) {
      this.modelDetails.modelSelector.setActiveItemByPredicate((x) => x.id.equalsIgnoreCase(query.model));
    }
    if (query && query.code) {
      this.modelDetails.predefinedConfigurations.setActiveItemByPredicate((x) => x.code.equalsIgnoreCase(query.code));
    }
  }

  private updateQuery() {
    const selectedModel = this.modelDetails.modelSelector.active;
    const selectedConfiguration = this.modelDetails.predefinedConfigurations.active;
    const queryModel: ProductFamilyPageQuery = {
      model: selectedModel ? selectedModel.data.id : undefined,
      code: selectedConfiguration ? selectedConfiguration.data.code : undefined,
    };

    this.navigation.setQuery(ProductFamilyQueryParser.toQuery(queryModel));
  }
}
