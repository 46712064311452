import { action, makeObservable, observable, reaction } from 'mobx';

import { IApiClient } from '../../../data/client';
import { FeatureVariationDisplayName, GetFeaturesVariationsNamesQuery, ShoppingContext } from '../../../data/model';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import Logger from '../../../shared/Logger';
import { ModalState } from '../../../shared/ModalState';

export class ObsoleteConfigurationModalState extends ModalState {
  public readonly loadingIndicator = new LoadingIndicator();

  @observable
  public missingComponentsIds: Array<string> = [];

  @observable
  public invalidConfiguration: boolean = false;

  @observable
  public code: string = undefined;

  @observable
  public featuresVariationsDisplayNames: FeatureVariationDisplayName[] = [];

  @observable
  public shoppingContext: ShoppingContext;

  @action
  setInvalidConfiguration(invalid: boolean) {
    this.invalidConfiguration = invalid;
  }

  @action
  setCode(code: string) {
    this.code = code;
  }

  @action
  public async getFeaturesVariationsNames(variationsIds: string[]) {
    try {
      this.loadingIndicator.start();

      const query = new GetFeaturesVariationsNamesQuery({
        variationsIds: variationsIds,
        shoppingContext: this.shoppingContext,
      });

      const response = await this.client.send(query);
      this.featuresVariationsDisplayNames = response.featuresVariationDisplayNames;
    } catch (error) {
      Logger.exception(
        `An error occurred while fetching information for the variations display names = ${variationsIds}`,
        error,
      );
      this.featuresVariationsDisplayNames = [];
    } finally {
      this.loadingIndicator.stop();
    }
  }

  @action
  public initialize(code: string, shoppingContext: ShoppingContext, componentsIds: Array<string>) {
    this.setCode(code);
    this.shoppingContext = shoppingContext;
    this.missingComponentsIds = componentsIds;
  }

  constructor(private readonly client: IApiClient) {
    super();
    makeObservable(this);
    reaction(
      () => this.missingComponentsIds,
      (components) => this.getFeaturesVariationsNames(components),
    );
  }
}
