import { BaseModelBasicData } from '../../../../../data/model';
import List from '../../../../../shared/components/List/List';
import { SelectorItem } from '../../../../../shared/Selector';
import { ModelSelectorComponentProps } from '../../ModelSelector/ModelSelector';
import ModelSelectorItem from '../../ModelSelector/ModelSelectorItem/ModelSelectorItem';
import css from './ModelList.css';

type ModelSelectorListProps = ModelSelectorComponentProps;

const ModelSelectorList = ({ onItemClick, state }: ModelSelectorListProps) => {
  const renderItem = (item: SelectorItem<BaseModelBasicData>) => {
    return <ModelSelectorItem item={item} onClick={onItemClick} key={item.data.id} />;
  };

  return <List className={css.items} itemClassName={css.item} items={state.items} itemRenderer={renderItem} />;
};

export default ModelSelectorList;
