import { action, makeObservable, observable } from 'mobx';

import { BaseModelBasicData } from '../../../../data/model';
import { SelectorItem } from '../../../../shared/Selector';

export class ModelSelectorState {
  @observable.ref
  public items: SelectorItem<BaseModelBasicData>[];

  public constructor() {
    makeObservable(this);
    this.items = [];
  }

  @action
  public setItems(models: BaseModelBasicData[], activeItemId: string) {
    this.items = models.map((item) => new SelectorItem<BaseModelBasicData>(item, item.id === activeItemId));
  }
}
