export enum StatusCodes {
  NoConnection = 0,
  Ok = 200,
  MovedPermanently = 301,
  MovedTemporary = 307,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

export function isStatusCodeSuccessful(statusCode: StatusCodes): boolean {
  return statusCode < 400;
}
