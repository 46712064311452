import { observer } from 'mobx-react';
import React, { Component } from 'react';

import ConfigurationShare from '../configurator/components/ConfigurationShare/ConfigurationShare';
import { ProductConfigurationOrigin } from '../data/model';
import { Breakpoint, Orientation, Screen, ScreenObserver } from '../layout/ScreenObserver';
import {
  ConfigurationDetailsPageTranslation,
  ShareConfigurationSectionTranslation,
} from '../localization/SiteTranslation';
import DefaultImage from '../shared/components/DefaultImage/DefaultImage';
import ImageZoomModal from '../shared/components/ImageZoomModal/ImageZoomModal';
import ModelViewer from '../shared/components/ModelViewer/ModelViewer';
import Heading, { HeadingSize } from '../shared/components/Typography/Heading/Heading';
import * as TextLabels from '../shared/components/Typography/TextLabels';
import { TextLabelStrong } from '../shared/components/Typography/TextLabels';
import { Region } from '../shared/regions/Region';
import { StoreState } from '../StoreState';
import css from './ConfigurationDetailsPage.css';
import { ConfigurationDetailsPageState } from './ConfigurationDetailsPageState';

export interface ConfigurationDetailsPageProps {
  store: StoreState;
  state: ConfigurationDetailsPageState;
  translation: ConfigurationDetailsPageTranslation;
  shareTranslation: ShareConfigurationSectionTranslation;
}

@observer
class ConfigurationDetailsPage extends Component<ConfigurationDetailsPageProps, { isMobilePortrait: boolean }> {
  constructor(props: ConfigurationDetailsPageProps) {
    super(props);

    this.state = { isMobilePortrait: false };
    this.onScreenChange = this.onScreenChange.bind(this);
  }

  componentDidMount() {
    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
  }

  componentWillUnmount() {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
  }

  onScreenChange(screen: Screen) {
    const isMobile = screen.breakpoint === Breakpoint.Phone || screen.breakpoint === Breakpoint.Tablet;
    const isPortrait = screen.orientation === Orientation.Portrait;
    const isMobilePortrait = isMobile && isPortrait;

    this.setState({ isMobilePortrait });
  }

  renderActions() {
    const {
      state: { details, code },
    } = this.props;
    return <Region name="ConfigurationDetails" data={{ code, details }} />;
  }

  renderConfigurationShare() {
    const { state, shareTranslation } = this.props;

    return this.props.store.showConfiguratorShare() ? (
      <ConfigurationShare
        className={css.share}
        state={state.shareState}
        translation={shareTranslation}
        showDownloads={state.details.configuration.origin === ProductConfigurationOrigin.DefaultConfigurator}
      />
    ) : null;
  }

  render() {
    const { state, translation } = this.props;

    return (
      <article className={css.root}>
        <div className={css.imageContainer}>
          {state.details.configuration.origin === ProductConfigurationOrigin.DefaultConfigurator &&
            state.modelViewer.items.any() && (
              <>
                <ModelViewer imageClassName={css.image} state={state.modelViewer} />
                <ImageZoomModal
                  state={state.modelViewer.zoomModal}
                  translation={state.zoomModalTranslation}
                  visualizationTranslation={state.chairVisualizationTranslation}
                />
              </>
            )}
          {state.details.configuration.origin === ProductConfigurationOrigin.RawConfigurator && (
            <div className={css.imageFallbackContainer}>
              <DefaultImage className={css.imageFallback} />
              <span className={css.imageNotAvailableText}>{translation.visualizationNotAvailableText}</span>
            </div>
          )}
        </div>
        <div className={css.summary}>
          <Heading level={1} className={css.heading} size={HeadingSize.Small}>
            {translation.title}
          </Heading>
          <div className={css.box}>
            <TextLabels.TextLabel className={css.productName}>{state.details.productName}</TextLabels.TextLabel>
            <ul>
              {state.details.options.map((option) => (
                <li className={css.option} key={option.code + option.variationCode}>
                  <TextLabelStrong className={css.optionName}>{option.name}</TextLabelStrong> {option.variationName}{' '}
                  <span className={css.optionCode}>{option.variationCode}</span>
                </li>
              ))}
            </ul>
          </div>
          {this.renderActions()}
          {this.renderConfigurationShare()}
        </div>
      </article>
    );
  }
}

export default ConfigurationDetailsPage;
