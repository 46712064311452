import { OptionDescriptionModalTranslation, RequestQuoteContentTranslation, SiteTranslation } from './SiteTranslation';

// tslint:disable:max-line-length

const requestQuoteTranslation: RequestQuoteContentTranslation = {
  confirmation: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Bestätigungsseite',
    buttonLink: 'Einkauf fortsetzen',
    title: 'Danke! Das gewünschte Angebot ist unterwegs.',
    content: 'Unser Händler in Ihrer Nähe wird sich in Kürze bei Ihnen melden.',
  },
  goBackLink: 'Zurück',
  title: 'Angebot anfordern',
  stepsTitle: 'Wie geht es nach meiner Anfrage weiter?',
  steps: [
    'Ihre Anfrage wird an das Flokk Sales Team geschickt',
    'Unser Flokk Sales Team vor Ort wird ein Angebot für Sie erstellen',
    'Innerhalb des nächsten Arbeitstages wird sich ein Flokk Vertriebsmitarbeiter mit Ihnen in Verbindung setzen.',
  ],
};

const requestQuoteProcessedByDealerTranslation: RequestQuoteContentTranslation = {
  ...requestQuoteTranslation,
  confirmation: {
    ...requestQuoteTranslation.confirmation,
    content: 'Unser Händler in Ihrer Nähe wird sich in Kürze bei Ihnen melden.',
  },
  steps: [
    'Das lokale Verkaufsteam erstellt eine persönliche Offerte für Sie.',
    'Unser freundliches Verkaufsteam gibt Ihre Anfrage einem zertifizierten Flokk Partner weiter, der die Abwicklung für uns übernimmt und alle Ihre Fragen professionell beantwortet.',
    'Der zertifizierte Partner wird sich innert 5 Arbeitstagen mit einer Offerte und Informationen zur Lieferung und Zahlung bei Ihnen melden.The certified dealer will be in touch as soon as they can to finalise your order, arrange payment and delivery details.',
  ],
};

const optionDescriptionModal: OptionDescriptionModalTranslation = {
  closeButton:
    'Schließen',
};

const translation: SiteTranslation = {
  layout: {
    topBar: {
      title: 'Flokk',
      chooseChairLink: 'Stühle auswählen',
      aboutUsLink: 'Über uns',
      inputSearchPlaceholder: 'Suche',
      languages: {
        chDe: 'Deutsch',
        chFr: 'Französisch',
        deDe: 'Deutsch',
        dkDk: 'Dänisch',
        enUs: 'Englisch (US)',
        frFr: 'Französisch',
        nlNl: 'Niederländisch',
        nbNo: 'Norwegisch',
        enGB: 'Englisch (GB)',
        svSe: 'Schwedisch',
        plPl: 'Polnisch',
      },
    },
    footerPanel: {
      emailLabel: 'E-Mail: ',
      phoneLabel: 'Telefon: ',
      countactUsLink: 'Kontaktieren Sie unser Büro in Ihrer Nähe',
      followUsLabel: 'Folgen Sie uns',
      copyright: 'Copyright',
      allRightReserved: 'All rights reserved',
    },
    titleFormat: '{title} - Flokk – Stellen Sie Ihr individuelles Stuhlmodell zusammen',
  },
  requestQuotePage: {
    errorMessage: 'Error occurred during loading hubspot form. Please contact support.',
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Angebot anfordern',
    defaultContent: requestQuoteTranslation,
    contentWhenProcessedByDealer: requestQuoteProcessedByDealerTranslation,
  },
  emailMyConfigurationPage: {
    errorMessage: 'Error occurred during loading hubspot form. Please contact support.',
    pageDescriptionFormat: 'Description',
    pageTitleFormat: 'Meine Konfiguration per E-Mail senden',
    confirmation: {
      pageDescriptionFormat: 'Description',
      pageTitleFormat: 'Meine Konfiguration per E-Mail senden',
      buttonLink: 'Zurück zur Startseite',
      title: 'Vielen Dank! Ihre E-Mail wurde an Sie versandt.',
      content: 'Bitte überprüfen Sie Ihr E-Mail Postfach.',
    },
    goBackLink: 'ZURÜCK ZUR ÜBERSICHT',
    title: 'Meine Konfiguration per E-Mail senden',
  },
  homePage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Startseite',
    articleList: {
      readMore: 'Mehr erfahren',
    },
    productList: {
      fromPriceLabel: 'Ab {price} inkl. MwSt',
      productThumbnail: {
        productVariationList: {
          title:
            'Variationen'
        }
      }
    },
  },
  configurationSearchPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Konfiguration',
    title: 'Bitte geben Sie Ihren Konfigurationscode ein,',
    description: 'damit Sie zum Konfigurator gelangen.',
    codeInputPlaceholder: 'Hier Code eingeben und auf Absenden klicken',
    configurationFinder: {
      codeWithIncorrectLengthFormat: 'Bitte geben Sie einen Code mit {expectedLength} Zeichen an.',
      codeInvalid: 'Der angegebene Code {invalidCode} ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
      codeNotFound: 'Die Produktkonfiguration konnte anhand dieses Codes nicht gefunden werden.{notFoundCode}',
    },
  },
  configurationDetailsPage: {
    pageTitleFormat:
      '*{code} - Configuration details',
    pageDescriptionFormat:
      '*{productName} {options} {accessories}',
    title:
      '*Your selected configuration',
    visualizationNotAvailableText:
      '*Visualization not available',
  },
  confirmationPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Bestätigung Ihrer Bestellung',
    title: 'Vielen Dank!',
    errorTitle: 'Ein Fehler ist aufgetreten',
    paymentConfirmed: 'Ihre Zahlung wurde bestätigt, wir hoffen, dass Ihnen der Einkauf bei uns gefallen hat.',
    checkYourEmail: 'Einzelheiten zu der Bestellung und Lieferung erhalten Sie per E-Mail',
    errorContent: 'Es ist ein Fehler aufgetreten, bitte wenden Sie sich an den Kundensupport.',
    orderSection: {
      title: 'Ihre Bestellung',
      orderNumber: 'Bestellnummer',
    },
    summarySection: {
      title: 'Zusammenfassung',
      taxText: 'inkl. MwSt',
      totalToPayText: 'Summe',
      contact: {
        contactAndBilling: 'Kontakt & Rechnung',
        deliveryAddress: 'Lieferadresse',
      },
    },
    designerSectionTitle: 'Wissen Sie wer Ihren Stuhl designed hat?',
    customerStorySectionTitle: 'Wissen Sie wie Sie darauf sitzen?',
    readMore: 'Mehr erfahren',
    contactUsSection: {
      text: 'Sollten Sie Fragen zu Ihrer Bestellung haben, wenden Sie sich bitte an unseren',
      linkText: 'Kundenservice',
    },
  },
  checkoutPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Bestellvorgang',
    title: 'Bestellvorgang',
    goBackLink: 'Zurück',
    progress: {
      title: 'Checkout',
      deliveryStep: 'Lieferung',
      paymentStep: 'Zahlung',
      confirmationStep: 'Zusammenfassung',
    },
    goToPaymentButton: 'Zur Zahlung',
    paymentMethodLabel: 'Wählen Sie Ihre Zahlungsmethode',
    orderAndPayButton: 'Zahlungspflichtig bestellen',
    submitButton: 'Absenden',
    paymentPoweredByText: 'Wir verwenden eine Zahlungslösung von',
    addressForm: {
      city: {
        name: 'Ort',
        placeholder: 'Ort angeben',
        fieldRequired: 'Die Ortsangabe ist notwendig.',
      },
      country: {
        name: 'Land',
        placeholder: 'Land angeben',
        fieldRequired: 'Die Angabe des Landes ist notwendig.',
      },
      street: {
        name: 'Straße',
        placeholder: 'Straße angeben',
        fieldRequired: ' Die Angabe der Straße ist notwendig.',
      },
      streetNumber: {
        name: 'Hausnummer',
        placeholder: 'Hausnummer angeben',
        fieldRequired: 'Die Angabe der Hausnummer ist notwendig.',
      },
      zipCode: {
        name: 'Postleitzahl',
        fieldRequired: 'Die Angabe der Postleitzahl ist notwendig.',
        wrongFormat: 'Bitte geben Sie eine gültige Postleitzahl ein.',
      },
      availableCountries: {
        POL: 'Polen',
        NOR: 'Norwegen',
        CHE: 'Schweiz',
        DEU: 'Deutschland',
        DNK: 'Dänemark',
        FRA: 'Frankreich',
        NLD: 'Niederlande',
        SWE: 'Schweden',
        GBR: 'Großbritannien',
        USA: 'Vereinigte Staaten',
      },
      addressLine1: {
        name: 'Adresszeile 1',
        placeholder: '',
        fieldRequired: 'Adresszeile 1 ist notwendig',
        wrongFormat: '',
      },
      addressLine2: {
        name: 'Adresszeile 2',
        placeholder: '',
        fieldRequired: '',
        wrongFormat: '',
      },
      recipient: {
        name: 'Empfänger',
        placeholder: '',
        fieldRequired: 'Empfänger ist notwendig',
        wrongFormat: '',
      },
    },
    contactForm: {
      contactDetails: 'Kontaktdaten',
      email: {
        name: 'E-Mail',
        placeholder: 'E-Mail-Adresse angeben',
        fieldRequired: 'Die Angabe der E-Mail-Adresse ist notwendig.',
        wrongFormat: 'Bitte überprüfen Sie die eingegebene Email-Adresse.',
      },
      phone: {
        name: 'Telefonnummer',
        placeholder: 'Telefonnummer angeben',
        fieldRequired: 'Die Angabe der Telefonnummer ist notwendig.',
        wrongFormat: 'Die eingegebene Nummer ist nicht gültig',
      },
      firstName: {
        name: 'Vorname',
        placeholder: 'Vorname angeben',
        fieldRequired: 'Die Angabe des Vornamens ist notwendig.',
      },
      phonePrefix: {
        name: 'Vorwahl',
        placeholder: '+ _ _',
        fieldRequired: 'Eine Vorwahl wird benötigt',
        wrongFormat: 'Der eingegebene Wert ist keine gültige Vorwahl',
      },
      lastName: {
        name: 'Nachname',
        placeholder: 'Nachname angeben',
        fieldRequired: 'Die Angabe des Nachnamens ist notwendig.',
      },
      companyName: {
        name: 'Firmennamen (optional)',
        placeholder: '',
      },
      taxId: {
        name: 'Company Tax Id',
        placeholder: '',
        fieldRequired: 'Company Tax Id is required',
      },
    },
    shippingMethodSection: {
      subtitle: 'Versandart',
      shippingMethod: {
        name: 'Versandart',
        fieldRequired: 'Dieses Feld ist erforderlich',
      },
    },
    deliveryAddressSection: {
      subtitle: 'Lieferadresse',
      billingAndDeliveryAreTheSameText: 'Rechnungs- und Lieferadresse sind identisch',
      deliveryInfoText: 'Frei Haus Lieferung innerhalb von 10-15 Tagen',
    },
    paymentMethods: {
      supportedCreditCardsMessage: {
        acceptedCreditCardsText: 'Wir akzeptieren folgende Kreditkarten',
      },
    },
    readyToOrderSection: {
      subtitle: 'Bereit für die Bestellung?',
      termsAndConditions: {
        termsAndConditionLinkName: 'Allgemeinen Geschäftsbedingungen',
        name: 'Ich akzeptiere die',
        placeholder: '',
        fieldRequired: 'Dieses Feld ist erforderlich',
      },
      subscription: 'I"d like to receive additional…',
    },
    readyToOrderPaymentSection: {
      subtitle: 'Bereit für die Bestellung?',
    },
    errors: {
      creatingOrderFailed: 'Die Bestellung kann nicht ausgeführt werden. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
      initializingPaymentFailed: 'Die Zahlungsverarbeitung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
      authorizingPaymentFailed: 'Autorisierung fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
      finalizingPaymentFailed: 'Die Zahlungsverarbeitung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundenservice.',
    },
    discountCodeForm: {
      applyButton: 'Übernehmen',
      discountCodeIsAppliedMessage: 'Der "{code}" Rabattcode wird angewendet.',
      discountCodeIsInvalidOrExpiredError: 'Der von Ihnen eingegebene Code ist ungültig oder abgelaufen.',
      doYouHaveDiscountCodeText: 'Haben Sie einen Gutschein- oder Kampagnencode?',
      enterCodeText: 'Code eingeben',
      label: 'Gutscheincode',
    },
  },
  checkoutShortcutPage: {
    pageTitleFormat:
      'Checkout shortcut',
    pageDescriptionFormat:
      '',
    errors: {
      addingProductToCartFailed:
        'Unable to purchase product configuration with code {code}. Please contact customer support.',
      gettingProductConfigurationFailed:
        'Unable to retrieve information about product configuration with the code {code}. Please refresh page or contact customer support.',
    }
  },
  configurationPage: {
    pageDescriptionFormat: '{productName} {options} {accessories}',
    pageTitleFormat: '{productName} - Konfigurator',
    productDetails: {
      documentsSection: 'Dokumente',
      moreInformationSection: 'Weitere Informationen',
      specificationSection: 'Spezifikationen',
    },
    showFiltersText: '*Show filters',
    hideFiltersText: '*Hide filters',
    productModels: {
      chooseModelText: 'Modell wählen',
      modelSelectorWithLoader: {
        noOtherModelsAvailable: 'Es sind keine anderen Modelle verfügbar.',
      },
    },
    options: {
      title: 'Stellen Sie Ihr Stuhlmodell zusammen',
      priceGroup: 'Preisgruppe',
      textSelected: 'Ausgewählt',
      fabricFilters: {
        noFabricsAvailable: 'Leider gibt es keine passenden Stoffe zu Ihrer Auswahl.',
        clearButton: 'Löschen',
        defaultFilterName: 'Alles',
        collectionFilterLabel: 'Kollektion'
      },
    },
    accessories: {
      title: 'Zubehör',
      accessory: {
        removeButton: 'Entfernen',
        addButton: 'Hinzufügen',
        textSelected: 'Ausgewählt',
      },
    },
    summary: {
      title: 'Übersicht Ihrer Bestellung',
      accessoriesSubtitle: 'Zubehör',
      requestQuoteButton: 'Angebot anfordern',
      emailConfirmationButton: 'Meine Konfiguration per E-Mail senden',
      limitedChairQuantity: 'Um mehr als {max} Stühle zu bestellen, kontaktieren Sie uns bitte',
      checkoutButton: 'Zur Kasse gehen',
      editConfigurationLink: 'Bearbeiten',
      expectedShipment: {
        expectedShipment: 'Versandfertig innerhalb',
        days: 'Werktage',
      },
      quantityInput: {
        outOfRangeWarning: 'Bitte geben Sie eine Menge zwischen {min} und {max} an.',
      },
      saveYourConfigurationHereButton: 'Hier können Sie Ihre Konfiguration speichern.',
      backButton: 'Zurück',
      shareSection: {
        title: 'Konfiguration speichern',
        yourConfigurationUrl: 'Link zu Ihrer Konfiguration',
        downloadAsPdf: 'Als PDF herunterladen',
        downloadVisualizations: 'Bilder herunterladen',
        codeShare: {
          codeTitle: 'Ihr Konfigurationscode',
          copyCode: {
            label: 'Code kopieren',
            textCopiedLabel: 'Kopiert',
          },
        },
        copyLink: {
          label: 'Link kopieren',
          textCopiedLabel: 'Kopiert',
        },
        closeButton: 'Schließen',
      },
      findLocalDealerSection: {
        title: 'Flokk in Ihrer Umgebung',
        button: 'Händler in Ihrer Nähe finden',
      },
    },
    restrictions: {
      accessorySelection: {
        header: {
          userSelectionNotRequiredFormat: 'Sind Sie  sicher, dass Sie das Zubehörteil {itemName} hinzufügen möchten?',
          userSelectionRequiredFormat:
            'Wenn Sie {itemName} hinzufügen möchten, müssen Sie ihre vorherige Auswahl anpassen.',
        },
        automaticDeselectionOfAccessoriesFormat:
          'Das ausgewählte Zubehörteil steht in Kombination mit {itemsToDeselect}. leider nicht zur Verfügung. Wenn Sie dieses Zubehörteil auswählen, werden die unten aufgeführten Zubehörteile aus Ihrer Bestellung entfernt.',
      },
      optionSelection: {
        header: {
          userSelectionNotRequiredFormat: 'Sind Sie sicher, dass Sie die Option {itemName} ändern möchten?',
          userSelectionRequiredFormat:
            'Wenn Sie die Option {itemName} ändern möchten, ist eine Anpassung Ihrer vorherigen Auswahl notwendig.',
        },
        automaticDeselectionOfAccessoriesFormat:
          'Die ausgewählte Option steht in Kombination mit {itemsToDeselect}. leider nicht zur Verfügung. Wenn Sie sich trotzdem für diese Option entscheiden, werden die unten aufgeführten Zubehörteile aus Ihrer Bestellung entfernt.',
      },
      itemNotAvailableWithFormat: '{itemToSelect} steht nicht zur Verfügung mit {itemsToDeselect}.',
      itemsSeparator: ' and ',
      selectMatchingOption: 'Alle passenden Optionen finden Sie unten aufgeführt.',
      cancelButton: 'Abbrechen',
      applyButton: 'Änderungen zustimmen',
      closeButton: 'Schließen',
      selectedText: 'Ausgewählt',
    },
    tabs: {
      fabrics: {
        name: 'Stoffe',
      },
      options: {
        name: 'Optionen',
      },
      accessories: {
        name: 'Zubehör',
      },
      summary: {
        name: 'Übersicht',
      },
    },
    productNotAvailableInCurrentStoreWarning: {
      messageFormat:
        'Es tut uns leid, wir können dieses Produkt im Moment nicht anzeigen. Wenn Sie Fragen haben, zögern Sie bitte nicht, unseren Kundenservice unter Ihrer zu kontaktieren info@flokk.com.',
    },
    productInactiveWarning: {
      discontinued: '*This is a discontinued product',
      generic: '*Product is not available',
      genericWithReason: '*Product is not available: {reason}'
    },
    obsoleteCodeModal: {
      notAvailable: {
        title: 'Konfiguration ist nicht erhältlich',
        intro: 'Gewählte Konfiguration ist nicht erhältlich',
        introWithCodePart1: 'Die Konfiguration gemäss dem eingegebenen Code',
        introWithCodePart2: 'ist nicht mehr erhältlich.',
        explanation: 'Folgende Bestandteile werden nicht mehr angeboten:',
      },
      restricted: {
        title: 'Konfiguration ist ungültig',
        intro: 'Gewählte Konfiguration ist ungültig',
        introWithCodePart1: 'Konfiguration gemäss dem eingegebenen Code',
        introWithCodePart2: 'ist nicht mehr erhältlich.',
        explanation: 'Dies kann durch Änderungen in der Angebotspalette verursacht werden.',
      },
      footer: 'Bitte geben Sie die Konfiguration nochmals ein.',
      closeButton: 'Schließen',
    },
    optionDescriptionModal,
    metaTitleFormat: 'Mein {productName}',
    metaDescriptionFormat: '{productName}: {options}',
    priceTaxText: 'inkl. MwSt',
  },
  modularConfiguratorPage: {
    pageDescriptionFormat: '{productName} {options} {accessories}',
    pageTitleFormat: '{productName} - Konfigurator',
  },
  storeAndLanguageSelector: {
    title: 'Wählen Sie Ihr Land und Ihre Sprache aus',
    storeSelectorLabel: 'Land',
    languageSelectorLabel: 'Sprache',
  },
  initialStoreSelectorModal: {
    title: 'Hallo',
    description: 'Wählen Sie Ihr Land aus, um relevante Inhalte für Ihr Land zu sehen',
    buttonContinue: 'Weiter',
    inputPlaceholder: 'Wählen Sie Ihr Land',
    languageInputPlaceholder: 'Sprache auswählen',
    linkLabelInternationalStore: 'Gehen Sie zur internationalen Seite',
    noOptionMessage: 'Dieses Land wurde nicht gefunden',
    noLanguageMessage: 'Diese Sprache wurde nicht gefunden',
  },
  noConnectionModal: {
    title: 'Hoppla!',
    content: 'Keine Internetverbindung gefunden.',
    continueLink: 'Weiter',
    buttonSeparator: 'oder',
    refreshButton: 'Seite neu laden',
    closeButton: 'Schließen',
  },
  emptyCartPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Warenkorb leeren',
    basketEmptyLine1: 'Ihr Warenkorb ist leer',
    basketEmptyLine2: 'aber dass muss nicht so bleiben.',
    continueButton: 'Einkauf fortsetzen',
  },
  pageNotFound: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Seite nicht gefunden',
    content: 'Page Wir können die Seite, nach der Sie suchen, leider nicht finden.',
    title: 'Oh nein!',
    buttonLink: ' Zurück zur Startseite',
  },
  generalErrorPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Allgemeiner Fehler',
    title: 'Interner Server-Fehler!',
    content: 'Bitte versuchen Sie es später noch einmal.',
    buttonLink: 'Erneut versuchen',
  },
  invalidCodePage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Ungültiger Konfigurationscode',
    title: 'Ungültiger Konfigurationscode',
    content: 'Bitte geben Sie einen gültigen Code an.',
    buttonLink: 'Zurück zur Startseite ',
  },
  productSpecificationPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Produktbeschreibung',
    accessoriesSection: 'Zubehör',
    linkTitle: 'Link zu Ihrer Konfiguration',
    codeTitle: 'Ihr Konfigurationscode',
    pricesSection: {
      unitPrice: 'Stückpreis',
      quantity: 'Menge',
      subtotalPrice: 'Zwischensumme',
    },
    dataSection: {
      title: 'Daten',
      brand: 'Marke',
      code: 'Artikelnummer',
    },
  },
  storeLocatorPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Händler, Showrooms und Flokk Standorte finden',
    heading: 'Finden Sie Ihren Händler',
    storeSearch: {
      noDealersText: 'Verzeihung, wir haben leider keine Händler oder Standorte in dieser Region.',
      noDealersWithFiltersText:
        'Verzeihung, wir haben keine Händler oder Standorte in dieser Region, die zu den Filterangaben passen. ',
      filterResultText: '{storesNumber} Standort gefunden',
      storeLocatorInput: {
        label: 'Finden Sie Ihren Händler',
        placeholder: 'Ort oder Postleitzahl',
        orText: 'oder',
        currentLocationText: 'aktuellen Standort nutzen',
        disabledGeolocationText: 'Ortungsdienste sind deaktiviert.',
        unavailableGeolocationText: 'Die Ortungsdienste sind nicht verfügbar.',
        tryAgainText: 'Bitte versuchen Sie es noch einmal.',
      },
      storeLocatorFilters: {
        title: 'Filter',
        placesLabel: 'Standorttyp',
        brandLabel: 'Marke',
      },
    },
    storeTypes: {
      Showroom: 'Ausstellungsraum',
      Office: 'Büro',
      Dealer: 'Händler',
      Shop: 'Geschäft',
    },
    details: {
      webSite: 'Webseite',
      address: 'Adresse',
      directionLink: 'Anfahrtsbeschreibung',
      email: 'E-Mail',
      openHours: 'Öffnungszeiten',
      phone: 'Telefon',
      closeButton: 'Schließen',
      backButton: 'Zurück zur Liste',
    },
  },
  cart: {
    summary: {
      deliveryFee: 'Versandkosten',
      subTotal: 'Nettopreis',
      totalTaxFormat: 'MwSt. ({taxTotal}%)',
      subTotalWithTax: 'Bruttopreis',
      totalToPay: 'Zu zahlender Gesamtbetrag',
      moreInformationText: 'Weitere Informationen',
      lessInformationText: 'Weniger Informationen',
      quantityText: 'Anzahl',
      shippingTimeText: {
        deliveredWithin: 'Geliefert innerhalb von',
        day: 'Tag',
        days: 'Tage',
        week: 'Woche',
        weeks: 'Wochen',
      },
      configurationCodeLabel: 'ID',
      deliveryCostUnavailable: 'Versandart auswählen',
      freeDeliveryText: 'Kostenfreie Lieferung',
      taxText: '+ MwSt',
      discount: 'Rabatt',
    },
  },
  redirectionWarningModal: {
    closeButton: 'Schließen',
    title:
      'In ein paar Sekunden werden Sie automatisch weitergeleitet. Um die Weiterleitung rückgängig zu machen, müssen Sie mit dem Bildschirm interagieren.',
  },
  scrollToTop: {
    button: 'Nach oben',
  },
  productFamilyPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Produktseite',
    overviewSection: {
      name: 'Übersicht',
    },
    relatedProducts: {
      name: 'Ähnliche Produkte',
      title: 'Dies könnte Ihnen auch gefallen',
    },
    designerSection: {
      name: 'Designer',
      designedBy: 'Designed von',
      readMore: 'Mehr lesen',
    },
    productUserStories: {
      name: 'Projekte',
      readMore: 'Mehr lesen',
      title: 'Projekt',
    },
    modelsSection: {
      name: 'Modelle',
      productInfo: {
        priceSection: {
          taxText: 'inkl. MwSt',
          fromText: 'von',
        },
        popularDesigns: {
          title: 'Beliebte Designs',
        },
        actions: {
          configureChairButton: 'Konfigurieren sie ihr modell',
          askForPriceButton: 'Preis anfragen',
          goToCheckoutButton: 'Zum Warenkorb hinzufügen',
          whereToBuyButton: 'Erhältlich bei',
        },
        whereToBuy: {
          text: 'Wir verkaufen unsere Produkte über ein Netzwerk autorisierter Partner.',
          designCustomChairText:
            'Hier können Sie einen Online Shop wählen.',
        },
      },
      productModelSelector: {
        heading: 'Wählen Sie Ihr Basismodell',
      },
      modelViewer: {
        hint: 'Klicken Sie auf das Bild um den Zoommodus zu aktivieren',
      },
    },
    productModelDetailsSection: {
      assetsDownloader: {
        downloadAllButton: 'Alles downloaden',
        other: 'Anderes',
        categories: {},
      },
      downloadsSectionTitle: 'Downloads',
      environmentalDataSection: {
        averagedValuesDisclaimerText:
          'Alle Angaben sind Durchschnittswerte für dieses Modell, bei einigen Produkten können die Zahlen abweichen. Die dargestellten Angaben basieren auf den verfügbaren EPDs der einzelnen Produkte, entweder nach dem Standard +A1:2013 oder +A2:2019, und werden kontinuierlich gemäß neuer Deklarationen aktualisiert.',
        energyUsageMjText: 'Energieverbrauch',
        co2EmissionKgText: 'Kohlendioxid-Emissionen',
        recyclableMaterialsText: 'recycelbare Materialien',
        recycledAluminiumText: 'recyceltes Aluminium',
        recycledMaterialsTitle: 'Anteil der recycelten Materialien',
        recycledMaterialsText: 'Recycelte Materialien',
        recycledPlasticText: 'recycelter Kunststoff',
        recyclableMaterialsTitle: 'Recyclingquote',
      },
      environmentalDataSectionTitle: 'Umweltdaten',
      name: 'Einzelheiten',
      specificationSectionTitle: 'Spezifikation',
    },
    suggestedConfigurationsSection: {
      name: 'Varianten',
      title: 'Suchen Sie etwas Bestimmtes?',
      subtitle: 'Wählen Sie aus verschiedenen Möglichkeiten bezüglich Farben, Stoffen, Materialien und Optionen.',
      buttonText: 'Konfigurieren sie ihr modell',
    },
    gallerySection: {
      name: 'Gallerie',
    },
    userGuideVideoSection: {
      name: '',
      videoTitle: 'Benutzerhandbuch',
    },
  },
  viewerPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Ansicht',
  },
  zoomModal: {
    closeButton: 'Schließen',
  },
  common: {
    chairVisualization: {
      zoomHintText: 'Zum Vergrößern/Verkleinern berühren',
      hintText: 'Zur Vergrößerung auf das Bild klicken',
      renderingError: 'Leider können wir die Visualisierung für diese Konfiguration nicht laden.',
      webGLUnsupportedError:
        'Leider können wir die Visualisierung nicht laden, da WebGL in Ihrem Browser nicht unterstützt wird.',
    },
    tags: {
      clearResults: 'Alle entfernen',
    },
  },
  catalogPage: {
    pageTitleFormat: 'Produkte',
    pageDescriptionFormat: 'Finden Sie Ihren Stuhl',
    filterBar: {
      name: 'Filters',
      results: 'Ergebnisse: {results}',
    },
    configurationFinder: {
      codeWithIncorrectLengthFormat: 'Bitte geben Sie einen Code mit {expectedLength} Zeichen an.',
      codeInvalid: 'Der angegebene Code {invalidCode} ist ungültig. Bitte überprüfen Sie Ihre Eingabe.',
      codeNotFound: 'Die Produktkonfiguration konnte anhand dieses Codes nicht gefunden werden.{notFoundCode}',
    },
    productFamily: {
      showMoreProducts: 'Weitere Varianten verfügbar',
    },
    searchForConfigurationText: 'Sie haben bereits ein Design konfiguriert? Hier geht es zu Ihrer Konfiguration',
    noResults: 'Kein Produkt passt zu Ihren Eingaben.',
    pagination: {
      title: 'Seitennummerierung',
    },
  },
  disclaimer: {
    messagePart1: 'Das Aussehen der Farbe hängt von Ihrem Gerät oder Ihrer Bildschirmeinstellung ab.',
    link: 'Kontaktieren Sie uns',
    messagePart2: ' wenn Sie Hilfe bei der Auswahl der richtigen Farbe und/oder Textur benötigen',
  },
  requestQuoteLink: {
    link: 'Sie kaufen im Auftrag einer Firma? Fordern Sie hier ein Angebot an.',
  },
  unauthorizedErrorPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Nicht autorisiert',
    title: 'Nicht autorisiert',
    content: 'Der Zugang ist nur für registrierte Benutzer möglich.',
    buttonLink: 'Login',
  },
  forbiddenErrorPage: {
    pageDescriptionFormat: 'Beschreibung',
    pageTitleFormat: 'Nicht erlaubt',
    title: 'Oh, no!',
    content1: 'Es tut uns leid, aber Sie haben keinen Zugang zu dieser Seite.',
    buttonLink: 'Bring mich zurück',
  },
  downloadsPage: {
    downloadList: {
      download: {
        downloadButton: 'Herunterladen',
      },
      emptyScreen: {
        title: 'Es gibt keine Dateien, die Ihren Suchkriterien entsprechen.',
        description: 'Wir scheinen nicht das zu haben, wonach Sie suchen. Bitte versuchen Sie andere Filter-Optionen.',
      },
      results: 'Es gibt {results} Dateien, die Ihren Kriterien entsprechen. ',
      pagination: {
        title: 'Seitennummerierung',
      },
    },
    filters: {
      title: 'Filter',
      brandsFilter: {
        name: 'Marken',
      },
      categoryFilter: {
        name: 'Kategorie',
        fileTypeFilter: {
          name: 'Dateityp',
        },
        resultsCounter: 'Alle Ergebnisse',
      },
      downloadAllButton: 'Alle herunterladen',
    },
    pageTitleFormat: 'Herunterladen',
    pageDescriptionFormat: 'Finden und Herunterladen von Produktressourcen, wie z. B. Datenblätter, Bilder, CADs usw.',
  },
  readyMadeProductPage: {
    overviewSection: {
      name: 'Überblick',
    },
    variationsSection: {
      name: 'Variationen',
    },
    specificationSection: {
      name: 'Spezifikation',
    },
    productDetailsSection: {
      specificationSectionTitle: 'Spezifikation',
      maintenanceSectionTitle: 'Instandhaltung',
      downloadsSectionTitle: 'Downloads',
      assetsDownloader: {
        downloadAllButton: 'Alles downloaden',
        other: 'Anderes',
        categories: {},
      },
      productCare: {
        downloadGuide: 'Leitfaden hier herunterladen',
      },
    },
    productVariations: {
      addToCartButton: 'In den Warenkorb legen',
      buyButton: 'Kaufen',
      deliveryTime: {
        deliveryTime: 'Lieferzeit',
        day: 'Tag',
        days: 'Tage*',
        week: 'Woche',
        weeks: 'Wochen*',
      },
      priceIncludingTax: 'Inkl. MwSt.',
      deliveryTimeAnnotation: '* Bei einzelnen Konfigurationen kann es zu längeren Lieferzeiten kommen',
      modelViewer: {
        hint: 'Klicken Sie auf das Bild um den Zoommodus zu aktivieren',
      },
      customizationSection: {
        textLine1: 'Sie wollen diesen Stuhl komplett individualisieren?',
        textLine2: 'Dann nutzen Sie den',
        textLine2End: 'für Ihr persönliches Design.',
        linkText: 'Flokk Konfigurator',
      },
      productOptions: {
        fabricInfo: {
          text: '*Fabric information:',
          optionDescriptionModal,
        },
        fabricDescriptionCannotBeLoadedError:
          '*Sorry, something went wrong. Cannot load the information about the fabric.',
      },
    },
    productCarousel: {
      title: 'Andere haben sich auch diese Stühle angesehen.',
    },
  },
};

export default translation;
