import { action, autorun, computed, IObservableArray, makeObservable, observable, override } from 'mobx';

import { IEnableable, IValidatable, keysOf } from './common';
import Event from './Event';

export class Form implements IValidatable {
  @observable inputsToValidate: Array<IValidatable>;
  @observable childForms: Array<Form>;

  @computed get isValid(): boolean {
    return this.inputsToValidate.filter((x) => !x.isValid).empty() && this.childForms.filter((x) => !x.isValid).empty();
  }

  constructor() {
    makeObservable(this);
    this.inputsToValidate = new Array<IValidatable>();
    this.childForms = new Array<Form>();
  }

  validate() {
    this.inputsToValidate.forEach((x) => x.validate());
    this.childForms.forEach((x) => x.validate());
  }

  public clearChildForms() {
    (this.childForms as IObservableArray<Form>).clear();
  }

  public resetValidation() {
    this.inputsToValidate.forEach((x) => x.resetValidation());
    this.childForms.forEach((x) => x.resetValidation());
  }
}

export class Input<TValue> implements IValidatable, IEnableable {
  protected rules: Array<IValidationRule>;
  private validationTriggered: boolean;

  public valueChanged: Event<TValue>;

  @observable value: TValue;
  @observable brokenRules: Array<IValidationRule>;
  @observable isEnabled: boolean = true;

  @computed get errorMessages() {
    const result = this.brokenRules.map((rule) => {
      const propertiesWithValues = keysOf(rule)
        .filter((k) => rule[k])
        .map((k) => [k as string, rule[k].toString()] as [string, string]);
      return rule.errorMessage.interpolate(propertiesWithValues);
    });
    return result;
  }

  @computed
  get validationErrorMessages() {
    if (!this.isValid) {
      return this.errorMessages;
    }
    return null;
  }

  @computed get isValid() {
    return this.isEnabled ? this.brokenRules.length === 0 : true;
  }

  constructor() {
    makeObservable(this);
    this.validationTriggered = false;
    this.valueChanged = new Event();
    this.rules = new Array<IValidationRule>();
    this.brokenRules = new Array<IValidationRule>();
  }

  @action.bound
  onChange(value: TValue) {
    this.value = value;
    this.valueChanged.raise(value);

    if (this.validationTriggered) {
      this.validate();
    }
  }

  @action.bound
  validate() {
    this.validationTriggered = true;
    this.brokenRules = this.rules.filter((x) => !x.validate(this.value));
  }

  public validateValue() {
    return this.rules.every((x) => x.validate(this.value));
  }

  conditional(condition: () => boolean) {
    autorun(() => {
      if (condition()) {
        this.enable();
      } else {
        this.disable();
      }
    });
    return this;
  }

  withRule(rule: IValidationRule) {
    this.rules.push(rule);
    return this;
  }

  triggerValidationImmediately() {
    this.validationTriggered = true;
    return this;
  }

  @action.bound
  enable() {
    this.isEnabled = true;
  }

  @action.bound
  disable() {
    this.isEnabled = false;
  }

  @action.bound
  setDefaultValue(value: TValue) {
    this.value = value;
  }

  resetValidation() {
    this.validationTriggered = false;
    this.brokenRules = [];
  }

  get validationHints() {
    return getValidationHints(this.rules);
  }
}

export class TextInput extends Input<string> {
  protected formatter: IFormatter = new NoOpFormatter();

  constructor() {
    super();
    this.value = '';
  }

  get maxLength() {
    const maxLengthRule = this.rules.find((x) => x instanceof ValueMaxLength) as ValueMaxLength;
    return maxLengthRule ? maxLengthRule.maxLength : undefined;
  }

  @override
  setDefaultValue(value: string) {
    super.setDefaultValue(this.formatter.format(value));
  }

  @override
  onChange(value: string) {
    super.onChange(this.formatter.formatOnChange(this.value, value));
  }

  withFormatter(formatter: IFormatter) {
    if (formatter) {
      this.formatter = formatter;
    }

    return this;
  }
}

export class NumberInput extends Input<number> {
  constructor(value: number = 1) {
    super();
    this.value = value;
  }
}

export class CheckboxInput extends Input<boolean> {
  name: string;

  constructor(value: boolean = false) {
    super();
    this.value = value;
  }
}

export class CheckboxGroupInput extends Input<string[]> {
  @observable
  public availableValues: string[] = [];

  constructor(avaiableValues: string[] = [], values: string[] = []) {
    super();

    makeObservable(this);

    this.value = values;
    this.setAvailableValues(avaiableValues);
  }

  handleCheckboxChange = (checkboxKey: string) => {
    let newValue = this.value;

    if (this.value.includes(checkboxKey)) {
      newValue = this.value.filter((v) => v !== checkboxKey);
    } else {
      newValue = [...this.value, checkboxKey];
    }

    this.onChange(newValue);
  };

  isChecked = (checkboxKey: string) => {
    return this.value.includes(checkboxKey);
  };

  @action.bound
  setAvailableValues(availableValues: string[]) {
    this.availableValues = availableValues;
  }
}

export class SwitchInput extends Input<boolean> {
  constructor(value: boolean = false) {
    super();
    this.value = value;
  }
}

export class SelectInput<TValue> extends Input<TValue> {
  constructor(value: TValue) {
    super();
    this.value = value;
  }
}

export class ValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export interface IFormatter {
  format(value: string): string;

  formatOnChange(prevValue: string, value: string): string;
}

export class NoOpFormatter implements IFormatter {
  public format(value: string): string {
    return value;
  }

  public formatOnChange(prevValue: string, value: string): string {
    return value;
  }
}

export interface IValidationRule {
  errorMessage: string;

  validate(value: {}): boolean;
}

export interface IValidationRuleWithHint extends IValidationRule {
  hint: string;
}

export class ValueMaxLength implements IValidationRuleWithHint {
  errorMessage: string;
  maxLength: number;
  hint: string;

  constructor(
    maxLength: number = 0,
    errorMessage: string = 'Value cannot be longer than {maxLength} chars.',
    hint: string = 'max {maxLength} characters',
  ) {
    this.maxLength = maxLength;
    this.errorMessage = errorMessage;
    this.hint = hint;
  }

  validate(value: string) {
    return !value || value.length <= this.maxLength;
  }
}

export class ValueExactLength implements IValidationRule {
  errorMessage: string;
  expectedLength: number;

  constructor(expectedLength: number = 0, errorMessage: string = 'Value should have exact {expectedLength} chars.') {
    this.expectedLength = expectedLength;
    this.errorMessage = errorMessage;
  }

  validate(value: string) {
    return value != null && value.length === this.expectedLength;
  }
}

export class ValueRequiredRule implements IValidationRuleWithHint {
  errorMessage: string;
  hint: string;

  constructor(errorMessage: string = 'Field is required', hint: string = 'required') {
    this.errorMessage = errorMessage;
    this.hint = hint;
  }

  validate(value: {}) {
    return !!value;
  }
}

export class RangeRule implements IValidationRule {
  errorMessage: string;
  min: number;
  max: number;

  constructor(min: number, max: number, errorMessage: string = 'The value is out of range') {
    this.errorMessage = errorMessage;
    this.max = max;
    this.min = min;
  }

  validate(value: number) {
    return this.min <= value && this.max >= value;
  }
}

export class MatchRegexRule implements IValidationRule {
  regex: RegExp;
  errorMessage: string;

  constructor(regex: RegExp, errorMessage: string = 'Entered value has wrong format') {
    this.regex = regex;
    this.errorMessage = errorMessage;
  }

  validate(value: string) {
    return value ? this.regex.test(value) : true;
  }
}

export class EmailAddressRule extends MatchRegexRule {
  constructor(errorMessage: string = 'Entered value is not a valid email address') {
    super(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, errorMessage);
  }
}

export class EmailAddressSplitter {
  public static split(input: string): string[] {
    return input
      ? input
          .split(/,|;/)
          .filter((x) => !!x)
          .map((x) => x.trim())
      : [input];
  }
}

export class MultipleEmailAddressesRule implements IValidationRule {
  public errorMessage: string;

  constructor(errorMessage: string = 'Entered value is not a valid email address') {
    this.errorMessage = errorMessage;
  }

  public validate(value: string): boolean {
    const addresses = EmailAddressSplitter.split(value);
    const addressRule = new EmailAddressRule();
    return addresses.any() ? addresses.all((x) => addressRule.validate(x)) : true;
  }
}

export class ArrayMinLengthRule implements IValidationRule {
  constructor(private readonly minElementsCount: number, private readonly customErrorMessage?: string) {}

  public get errorMessage() {
    return this.customErrorMessage ?? `The number of required elements is ${this.minElementsCount}`;
  }

  public validate(value: Array<unknown>) {
    if (value.length === undefined) {
      return false;
    }

    return value.length >= this.minElementsCount;
  }
}

export class PhoneRule extends MatchRegexRule {
  constructor(errorMessage: string = 'Entered value is not a valid phone number') {
    super(/^([0-9 ]+$)/i, errorMessage);
  }
}

export class PhonePrefixRule extends MatchRegexRule {
  constructor(errorMessage: string = 'Entered value is not a valid phone prefix') {
    super(/^[+]\d{1,2}$/i, errorMessage);
  }
}

export class FullPhoneNumberRule extends MatchRegexRule {
  constructor(errorMessage: string = 'Entered value is not a valid phone number') {
    super(/^([+\-()0-9 ]+)$/i, errorMessage);
  }
}

export class ZipCodeValidation extends MatchRegexRule {
  constructor(regexp: RegExp, errorMessage: string = 'Entered value is not a valid zip code') {
    super(regexp, errorMessage);
  }
}

export function isValidationRuleWithHint(rule: IValidationRule): rule is IValidationRuleWithHint {
  return (rule as IValidationRuleWithHint).hint !== undefined;
}

export function getValidationHints(rules: IValidationRule[]): string[] {
  const validationHints = rules.filter(isValidationRuleWithHint).map((rule) => {
    const propertiesWithValues = keysOf(rule)
      .filter((k) => rule[k])
      .map((k) => [k as string, rule[k].toString()] as [string, string]);
    return rule.hint.interpolate(propertiesWithValues);
  });
  return validationHints;
}
