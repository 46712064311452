import { action, makeObservable } from 'mobx';

import { BaseModelBasicData } from '../../../data/model';
import Selector from '../../../shared/Selector';

export default class ProductModelSelectorState extends Selector<BaseModelBasicData> {
  constructor(models: Array<BaseModelBasicData>) {
    super(models);
    makeObservable(this);
  }

  findItemById(id: string) {
    return this.items.find((item) => item.data.id === id);
  }

  @action.bound
  selectItem(id: string) {
    const item = this.findItemById(id);

    if (!item) {
      return;
    }

    this.setActiveItem(item);
  }
}
