import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import PhotoGallery, { ImageComponentProps, PhotoProps } from 'react-photo-gallery';

import ExternalImage from '../../../shared/components/ExternalImage/ExternalImage';
import ModalCarousel from '../ModalCarousel/ModalCarousel';
import css from './Gallery.css';
import { GalleryState, ImageModel } from './GalleryState';

export type GalleryBreakpoint = {
  minWidth: number;
  itemsPerRow: number;
};

export type GalleryProps = {
  breakpoints: GalleryBreakpoint[];
  maxRowCount: number;
  state: GalleryState;
  className?: string;
  style?: React.CSSProperties;
  withBackground?: boolean;
};

@observer
export class Gallery extends React.Component<GalleryProps> {
  private imagesToRenderCount = 0;

  constructor(props: GalleryProps) {
    super(props);

    this.renderImage = this.renderImage.bind(this);
    this.calculateColumns = this.calculateColumns.bind(this);
  }

  renderImage(props: ImageComponentProps) {
    if (props.index < this.imagesToRenderCount) {
      const imgWithClick = { cursor: 'pointer' };
      const imgStyle = { margin: props.margin };
      return (
        <ExternalImage
          src={props.photo.src}
          height={props.photo.height}
          width={props.photo.width}
          alt={props.photo.alt}
          style={props.onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
          onClick={(e) => props.onClick(e, props)}
        />
      );
    }
    return null;
  }

  calculateColumns(width: number) {
    const { breakpoints } = this.props;
    const foundSetting = breakpoints.filter((x) => x.minWidth < width).first();
    const imagesCount = this.props.state.images.length;
    const rows = Math.min(this.props.maxRowCount, Math.floor(imagesCount / foundSetting.itemsPerRow));
    if (rows === 0) {
      this.imagesToRenderCount = imagesCount;
      return imagesCount;
    } else {
      this.imagesToRenderCount = Math.min(rows, this.props.maxRowCount) * foundSetting.itemsPerRow;
      return foundSetting.itemsPerRow;
    }
  }

  get photos(): Array<PhotoProps> {
    return this.props.state.images.map((image: ImageModel, key: number) => ({
      height: image.size.height,
      width: image.size.width,
      src: image.optimizedUrl,
      key: key.toString(),
      alt: image.name,
    }));
  }

  get hasBackground() {
    return this.props.withBackground || !!this.props.style?.background || !!this.props.style?.backgroundColor;
  }

  render() {
    const { className, state, style } = this.props;

    return (
      <>
        <div className={className} style={style}>
          <div className={classNames(css.inner, { [css.withBackground]: this.hasBackground })}>
            <div className={css.galleryWrapper}>
              <PhotoGallery
                direction="row"
                columns={this.calculateColumns}
                onClick={(_, p) => state.openLightbox(p.index)}
                ImageComponent={this.renderImage}
                photos={this.photos}
                margin={4}
              />
            </div>
          </div>
        </div>

        <ModalCarousel
          initialSlide={state.initialImageIndex}
          images={state.images}
          isOpen={state.isLightboxOpen}
          onClose={state.closeLightbox}
          onDownload={state.downloadImage}
        />
      </>
    );
  }
}
