import { action, makeObservable } from 'mobx';

import { ModalState } from '../../ModalState';
import { INavigationService } from '../../NavigationService';

export class NoConnectionModalState extends ModalState {
  private navigation: INavigationService;

  constructor(navigation: INavigationService) {
    super();
    makeObservable(this);
    this.navigation = navigation;
  }

  @action.bound
  refresh() {
    this.navigation.reload();
  }
}
