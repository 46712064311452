import classNames from 'classnames';

import css from './HeaderLevel1.css';

export interface HeaderProps {
  centered?: boolean;
}

interface HeaderLevel1Props extends HeaderProps {
  className?: string;
  children: string | JSX.Element;
}

const HeaderLevel1 = ({ className, children, centered }: HeaderLevel1Props) => {
  const classes = classNames(css.HeaderLevel1, className, centered && css.centered);
  return <h1 className={classes}>{children}</h1>;
};

export default HeaderLevel1;
