import { observer } from 'mobx-react';
import * as React from 'react';

import Modal from '../../../app/shared/components/Modal/Modal';
import { FamilyItemAvailabilityModalTranslation } from '../../localization/SiteTranslation';
import { FamilyItemAvailability, FamilyItemAvailabilityModalState } from './FamilyItemAvailabilityModalState';

interface FamilyItemAvailabilityModalProps {
  modal: FamilyItemAvailabilityModalState;
  translation: FamilyItemAvailabilityModalTranslation;
}

const FamilyItemAvailabilityModal: React.FC<FamilyItemAvailabilityModalProps> = ({ modal, translation }) => {
  const renderText = () => {
    if (modal.status === FamilyItemAvailability.AvailableOnlyForConfiguration) {
      return translation.familyItemIsAvailableOnlyForConfiguration;
    }
    return translation.familyItemIsUnavailable;
  };

  return (
    <Modal modal={modal} cancelButtonName={translation.closeButton}>
      <p>{renderText()}</p>
    </Modal>
  );
};

export default observer(FamilyItemAvailabilityModal);
