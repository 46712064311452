import { action, makeObservable, observable, reaction } from 'mobx';

import { IApiClient } from '../../../../app/data/client';
import {
  ContactData,
  GetAvailableContactRolesQuery,
  SetDealerContactRolesCommand,
  ShoppingContext,
} from '../../../../app/data/model';
import { AsyncCommand } from '../../../../app/shared/common';
import Event from '../../../../app/shared/Event';
import { CheckboxGroupInput, CheckboxInput } from '../../../../app/shared/Form';
import { ModalState } from '../../../../app/shared/ModalState';
import { ChangeUserRolesModalTranslation } from '../../../localization/SiteTranslation';

export type RoleCheckbox = {
  role: string;
  checkbox: CheckboxInput;
};

export class ChangeUserRolesModalState extends ModalState {
  public setUserRolesCommand: AsyncCommand;

  @observable
  public contact: ContactData | undefined = undefined;

  public rolesInput: CheckboxGroupInput = new CheckboxGroupInput();

  public readonly rolesUpdatedEvent = new Event();

  constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: ChangeUserRolesModalTranslation,
  ) {
    super();

    makeObservable(this);

    this.setUserRolesCommand = new AsyncCommand(async () => {
      await this.saveUserRoles();
      this.close();
      this.rolesUpdatedEvent.raise({});
    });

    reaction(
      () => ({ contact: this.contact }),
      () => {
        if (!this.contact) {
          this.rolesInput.setDefaultValue([]);
          return;
        }

        this.rolesInput.setDefaultValue(this.contact.roles);
      },
    );
  }

  public load = async () => {
    const response = await this.client.send(
      new GetAvailableContactRolesQuery({ shoppingContext: this.shoppingContext }),
    );

    this.rolesInput.setAvailableValues(response.roles);
  };

  @action.bound
  public setContact(contact: ContactData) {
    this.contact = contact;
  }

  onBeforeClose() {
    this.contact = undefined;
  }

  private saveUserRoles = async () => {
    if (!this.contact) {
      return;
    }

    await this.client.send(
      new SetDealerContactRolesCommand({
        shoppingContext: this.shoppingContext,
        contactId: this.contact.id,
        roles: this.rolesInput.value,
      }),
    );
  };
}
