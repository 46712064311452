export interface ProductViewerProps {
  imageUrls: Array<string>;
  imageClassName: string;
}

export const ProductViewer = ({ imageUrls, imageClassName }: ProductViewerProps) => {
  return (
    <>
      {imageUrls.map((x) => (
        <img className={imageClassName} key={x} src={x} />
      ))}
    </>
  );
};
