import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { IPriceContext, PriceConsumer } from '../../../context/PriceContext';
import { OptionType } from '../../../data/model';
import { RestrictionResolutionTranslation, RestrictionsModalTranslation } from '../../../localization/SiteTranslation';
import { getPriceDifferenceText, IFeature, IItem, PriceFormatter } from '../../../shared/common';
import Modal from '../../../shared/components/Modal/Modal';
import PreloadedImage from '../../../shared/components/PreloadedImage';
import Select from '../../../shared/components/Select/Select';
import HeaderLevel4 from '../../../shared/components/Typography/HeaderLevel4';
import { TextLabelSmall, TextLabelSmallMediumGrey } from '../../../shared/components/Typography/TextLabels';
import { NotRestrictedSelection, ResolveRestrictionsModalState } from '../../../shared/restrictions';
import { OptionVariationState } from '../../OptionState';
import { AccessoryState } from '../AccessoriesConfigurator/AccessoryState';
import css from './ResolveRestrictionsModal.css';

interface ResolveRestrictionsModalProps {
  modal: ResolveRestrictionsModalState;
  translation: RestrictionsModalTranslation;
}

@observer
class ResolveRestrictionsModal extends Component<ResolveRestrictionsModalProps, {}> {
  get translation() {
    return this.props.translation;
  }

  renderInstructions() {
    const target = this.props.modal.sequence.currentStep.target;
    const item = target.item;
    const anyItemToSelect = !this.props.modal.sequence.currentStep.selections.empty();
    const itemName = target.feature.formatItemName(target.item);
    const description = this.buildDescription();

    let itemImageClass = '';
    let modalTranslation: RestrictionResolutionTranslation;

    if (target.item instanceof AccessoryState) {
      modalTranslation = this.translation.accessorySelection;
      itemImageClass = css.accessoryImage;
    }

    if (target.item instanceof OptionVariationState) {
      modalTranslation = this.translation.optionSelection;
      itemImageClass = css.optionImage;
    }

    const toFormat = anyItemToSelect
      ? modalTranslation.header.userSelectionRequiredFormat
      : modalTranslation.header.userSelectionNotRequiredFormat;

    const modalTitle = toFormat.interpolate([['itemName', itemName]]);

    return (
      <div className={css.itemContainer}>
        {anyItemToSelect && (
          <PreloadedImage className={classNames(itemImageClass)} src={item.image.url} width={100} height={100} />
        )}
        <div className={css.descriptionContainer}>
          <HeaderLevel4 className={css.title}>{modalTitle}</HeaderLevel4>
          {anyItemToSelect && <div className={css.description}>{description}</div>}
        </div>
      </div>
    );
  }

  renderOptions() {
    return this.props.modal.sequence.currentStep.selections.any() ? (
      <div className={css.features}>
        {this.props.modal.sequence.currentStep.selections.map((x) => this.renderSelection(x))}
      </div>
    ) : null;
  }

  private buildDescription() {
    const modal = this.props.modal;
    const target = modal.sequence.currentStep.target;
    const restricting = modal.sequence.currentStep.restricting;
    const itemToSelect = target.feature.formatItemName(target.item);
    const itemsToDeselect = restricting.feature.formatItemName(restricting.item);
    let description = this.translation.itemNotAvailableWithFormat.interpolate([
      ['itemToSelect', itemToSelect],
      ['itemsToDeselect', itemsToDeselect],
    ]);
    if (modal.sequence.currentStep.selections.any()) {
      description += this.translation.selectMatchingOption;
    }
    return description;
  }

  renderSelection(selection: NotRestrictedSelection) {
    return (
      <PriceConsumer key={selection.feature.id}>
        {(priceContext) => {
          return (
            <Select
              label={selection.feature.name}
              inlineLabel
              containerClassName={css.selectContainer}
              className={css.select}
              onChange={(event) => selection.setSelectedItem(selection.items.find((x) => x.id === event.target.value))}
            >
              {selection.items.map((x) => this.renderSelectionOption(x, selection.feature, priceContext))}
            </Select>
          );
        }}
      </PriceConsumer>
    );
  }

  renderSelectionOption(item: IItem, feature: IFeature, priceContext: IPriceContext) {
    const price =
      feature.type !== OptionType.NotAffectingPrice
        ? getPriceDifferenceText(feature.getItemPriceDifference(item))
        : null;
    const shouldDisplayPrice = priceContext.enable && !!price;
    let itemCode = item.code;
    if (item instanceof OptionVariationState && item.data.alternativeCode) {
      itemCode = item.data.alternativeCode;
    }
    const itemName =
      feature.type === OptionType.Fabric
        ? itemCode === item.name
          ? `${item.fullName}`
          : `${itemCode} ${item.fullName}`
        : item.name;

    return (
      <option key={item.id} value={item.id}>
        {itemName}{' '}
        {shouldDisplayPrice && `(${PriceFormatter.formatFromString(price, priceContext.displayDecimalPart)})`}
      </option>
    );
  }

  renderItemsToDeselect() {
    const itemsToDeselect = this.props.modal.sequence.currentStep.itemsToDeselect
      .map((x) => x.feature.formatItemName(x.item))
      .join(' and ');
    const target = this.props.modal.sequence.currentStep.target;

    let modalTranslation: RestrictionResolutionTranslation;
    if (target.item instanceof AccessoryState) {
      modalTranslation = this.translation.accessorySelection;
    } else if (target.item instanceof OptionVariationState) {
      modalTranslation = this.translation.optionSelection;
    }
    const description = modalTranslation.automaticDeselectionOfAccessoriesFormat.interpolate([
      ['itemsToDeselect', itemsToDeselect],
    ]);

    return (
      !this.props.modal.sequence.currentStep.itemsToDeselect.empty() && (
        <Fragment>
          <div className={css.description}> {description} </div>
          {this.props.modal.sequence.currentStep.itemsToDeselect.map((x) => this.renderItemToDeselect(x.item))}
        </Fragment>
      )
    );
  }

  renderItemToDeselect(item: IItem) {
    return (
      <div key={item.id} className={css.itemToDeselect}>
        <PreloadedImage className={css.smallImg} src={item.image.url} width={100} height={100} />
        <div className={css.itemToDeselectWrapper}>
          <TextLabelSmall className={css.accessoryName}>{item.name}</TextLabelSmall>
          <TextLabelSmallMediumGrey>{this.props.translation.selectedText}</TextLabelSmallMediumGrey>
        </div>
      </div>
    );
  }

  render() {
    const { modal, translation } = this.props;
    if (!modal.sequence) {
      return null;
    }

    return (
      <Modal
        modal={modal}
        contentClassName={css.content}
        cancelButtonName={translation.cancelButton}
        applyButtonName={translation.applyButton}
        onApply={modal.continue}
        translation={translation}
      >
        {this.renderInstructions()}
        {this.renderOptions()}
        {this.renderItemsToDeselect()}
      </Modal>
    );
  }
}

export default ResolveRestrictionsModal;
