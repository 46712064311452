import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { AccessorySelectorTranslation } from '../../../../localization/SiteTranslation';
import TertiaryButton from '../../../../shared/components/Button/TertiaryButton';
import Icon from '../../../../shared/components/Icon/Icon';
import Markdown from '../../../../shared/components/Markdown/Markdown';
import PreloadedImage from '../../../../shared/components/PreloadedImage';
import PriceDifference from '../../../../shared/components/PriceDifference';
import { TextLabelSmall } from '../../../../shared/components/Typography/TextLabels';
import { AccessoryState } from '../AccessoryState';
import css from './Accessory.css';

interface AccessoryProps extends React.Attributes {
  accessory: AccessoryState;
  translation: AccessorySelectorTranslation;
  className?: string;
}

const Accessory = ({ accessory, translation, className }: AccessoryProps) => {
  const isSelected = accessory.selected;
  const { priceIncludingDependentFeatures } = accessory;
  const { image, name } = accessory.data;

  const renderName = (nameClassName: string) => {
    return <TextLabelSmall className={classNames(css.name, nameClassName)}>{name}</TextLabelSmall>;
  };

  const label = (
    <div className={css.labelContainer}>
      {!isSelected ? (
        <PriceDifference price={priceIncludingDependentFeatures} className={css.price} />
      ) : (
        <TextLabelSmall className={css.label}>{translation.textSelected}</TextLabelSmall>
      )}
    </div>
  );

  return (
    <div className={classNames(css.Accessory, className)}>
      {renderName(css.nameMobile)}
      <div className={css.container}>
        <div className={classNames(css.imageContainer, isSelected && css.selected)} id={accessory.id}>
          <PreloadedImage className={css.image} src={image.url} width={300} height={300} />
          {isSelected && <Icon name="check" className={css.checkMark} />}
        </div>
        <div className={css.content}>
          {renderName(css.nameDesktop)}
          <div className={css.benefitsContainer}>
            {accessory.benefits ? (
              <Markdown linkTarget="_blank" className={css.benefits} content={accessory.benefits} />
            ) : null}

            <div className={css.selectAccessoryContainer}>
              <div className={css.buttons}>
                <TertiaryButton
                  onClick={() => accessory.toggleSelection()}
                  className={classNames({ [css.removeButton]: isSelected })}
                >
                  {isSelected ? (
                    translation.removeButton
                  ) : (
                    <div className={css.addButton}>
                      <Icon className={css.plus} name="plus" /> {translation.addButton}
                    </div>
                  )}
                </TertiaryButton>
              </div>
              {label}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Accessory);
