import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode, TextareaHTMLAttributes } from 'react';

import { TextInput } from '../../Form';
import Label from '../Label/Label';
import css from './Textarea.css';

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
}

export const Textarea = observer(({ className, ...props }: TextareaProps) => {
  return <textarea className={classNames(css.Textarea, className)} {...props} />;
});

export interface TextareaElementProps extends TextareaProps {
  input: TextInput;
  showValidationHints?: boolean;
}

export const TextareaField = observer(({ input, ...props }: TextareaElementProps) => {
  return (
    <Textarea
      value={input.value}
      maxLength={input.maxLength}
      onChange={(e) => input.onChange(e.target.value)}
      {...props}
    />
  );
});

export interface TextareaFieldWithLabelProps extends TextareaElementProps {
  label: ReactNode;
}

export const TextareaFieldWithLabel = observer(
  ({ label, showValidationHints, ...props }: TextareaFieldWithLabelProps) => {
    const { input } = props;
    const validationHints = showValidationHints ? ` (${input.validationHints.join(', ')})` : '';

    return (
      <>
        <Label>
          {label}
          {validationHints}
        </Label>
        <TextareaField {...props} />
      </>
    );
  },
);
