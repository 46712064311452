import { DaysRangeData } from '../../../data/model';
import { DeliveredWithinTranslation, DeliveryTimeTranslation } from '../../../localization/SiteTranslation';

export const createDeliveryTimeText = function (
  deliveryTime: DaysRangeData,
  translation: DeliveryTimeTranslation,
): string {
  if (deliveryTime && deliveryTime.minDays && deliveryTime.maxDays) {
    const timeRangeWithUnit = getTimeRangeWithUnit(deliveryTime.minDays, deliveryTime.maxDays, translation);
    return `${translation.deliveryTime}: ${timeRangeWithUnit}`;
  }
  return '';
};

export const createDeliveredWithinText = function (
  deliveryTime: DaysRangeData,
  translation: DeliveredWithinTranslation,
): string {
  if (deliveryTime && deliveryTime.minDays && deliveryTime.maxDays) {
    const timeRangeWithUnit = getTimeRangeWithUnit(deliveryTime.minDays, deliveryTime.maxDays, translation);
    return `${translation.deliveredWithin} ${timeRangeWithUnit}`;
  }
  return '';
};

function getTimeRangeWithUnit(
  minDays: number,
  maxDays: number,
  translation: { day: string; days: string; week: string; weeks: string },
): string {
  let min: number;
  let max: number;
  let unit: 'day' | 'days' | 'week' | 'weeks' = 'weeks';

  if (minDays % 7 === 0 && maxDays % 7 === 0) {
    min = minDays / 7;
    max = maxDays / 7;
    unit = areOnes(min, max) ? 'week' : 'weeks';
  } else {
    min = minDays;
    max = maxDays;
    unit = areOnes(min, max) ? 'day' : 'days';
  }

  const unitTranslated = translation[unit];

  return min === max ? `${min} ${unitTranslated}` : `${min}-${max} ${unitTranslated}`;
}

function areOnes(...numbers: number[]): boolean {
  return numbers.every((n) => n === 1);
}
