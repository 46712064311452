import { observer } from 'mobx-react';

import OptionItem from '../../../../../configurator/components/ConfigurationSummary/OptionItem/OptionItem';
import Accordion from '../../../../../shared/components/Accordion/Accordion';
import IconButton from '../../../../../shared/components/Button/IconButton';
import Icon from '../../../../../shared/components/Icon/Icon';
import List from '../../../../../shared/components/List/List';
import Heading, { HeadingSize } from '../../../../../shared/components/Typography/Heading/Heading';
import { Region } from '../../../../../shared/regions/Region';
import ModularConfiguratorCoordinatorState from '../../ModularConfiguratorCoordinatorState';
import ModularConfigurationShare from './ModularConfigurationShare/ModularConfigurationShare';
import css from './ModularConfiguratorSummary.css';

interface ModularConfiguratorSummaryProps {
  state: ModularConfiguratorCoordinatorState;
}

const ModularConfiguratorSummary = observer(({ state }: ModularConfiguratorSummaryProps) => {
  return (
    <section className={css.container}>
      <div className={css.header}>
        <Heading level={2} size={HeadingSize.Small}>
          Your order summary
        </Heading>
        <IconButton
          className={css.buttonEdit}
          icon={<Icon className={css.icon} name="edit" />}
          onClick={state.setLayoutMode}
          bold
        >
          Edit
        </IconButton>
      </div>

      <div className={css.productName}>{state.productFamilyName}</div>

      {state.usedComponentConfigurations.map((c) => (
        <Accordion
          className={css.component}
          titleClassName={css.title}
          titleExpandedClassName={css.expanded}
          contentClassName={css.details}
          title={`${c.name} (${c.counter})`}
          key={c.code}
        >
          <List
            items={[...c.features.options, ...c.features.accessories]}
            itemClassName={css.feature}
            itemRenderer={(item) => <OptionItem selectedFeature={item} key={item.feature.id} />}
          />
        </Accordion>
      ))}

      <Region
        name="ModularConfiguratorSummary"
        data={{
          configuratorSummaryState: state.configurator.summary,
          translation: state.translation.configurationPage.summary,
          requestQuoteLinkTranslation: state.translation.requestQuoteLink,
        }}
      />

      <ModularConfigurationShare
        className={css.share}
        state={state.configurator.summary}
        translation={state.translation.configurationPage.summary.shareSection}
      />
    </section>
  );
});

export default ModularConfiguratorSummary;
