export interface IEvent {}

export interface IEventSubscription {
  dispose(): void;
}

export interface IEventAggregator {
  subscribe<TEvent extends IEvent>(
    eventClass: new (...args: unknown[]) => TEvent,
    handler: (event: TEvent) => void,
  ): IEventSubscription;

  publish<TEvent extends IEvent>(event: TEvent): void;
}

export class EventAggregator implements IEventAggregator {
  private subscriptions = new Map<NewableFunction, Array<(event: IEvent) => void>>();

  subscribe<TEvent extends IEvent>(
    eventClass: new (...args: unknown[]) => TEvent,
    handler: (event: TEvent) => void,
  ): IEventSubscription {
    let handlers = this.subscriptions.get(eventClass);

    if (!handlers) {
      handlers = [];
      this.subscriptions.set(eventClass, handlers);
    }

    handlers.push(handler);

    return {
      dispose: () => {
        handlers.remove(handler);
      },
    };
  }

  publish<TEvent extends IEvent>(event: TEvent): void {
    let handlers = this.subscriptions.get(event.constructor);

    if (!handlers) {
      return;
    }

    handlers.forEach((x) => x(event));
  }
}
