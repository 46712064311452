import classNames from 'classnames';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Component, createRef, RefObject } from 'react';
import Slider from 'react-slick';

import Carousel, { CarouselProps } from '../Carousel/Carousel';
import Image from '../Image/Image';
import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import { SliderNextArrow, SliderPrevArrow } from '../SliderNavigationArrows/SliderNavigationArrows';
import css from './ModelViewer.css';
import ModelViewerState from './ModelViewerState';

const CAROUSEL_BREAKPOINTS = {
  mobile: { maxWidth: 767, swipe: true },
  tablet: { maxWidth: 1199, swipe: true },
  desktop: { swipe: false },
};

interface ModelViewerProps {
  className?: string;
  state: ModelViewerState;
  imageClassName?: string;
  fallBackImageClassName?: string;
}

@observer
class ModelViewer extends Component<ModelViewerProps> {
  public sliderRef: RefObject<Slider>;

  private reactionDisposer: IReactionDisposer;

  public constructor(props: ModelViewerProps) {
    super(props);
    this.sliderRef = createRef();
  }

  public componentDidMount() {
    this.reactionDisposer = reaction(
      () => this.props.state.activeIndex,
      (activeIndex) => {
        if (this.sliderRef.current) {
          this.sliderRef.current.slickGoTo(activeIndex);
        }
      },
    );
  }

  public componentWillUnmount() {
    this.reactionDisposer();
  }

  public renderModelImages() {
    const { fallBackImageClassName, imageClassName, state } = this.props;

    const sliderSettings: CarouselProps = {
      autoplay: false,
      arrows: false,
      dots: true,
      dotsClass: css.navigation,
      appendDots: (dots) => (
        <div>
          <SliderPrevArrow className={css.arrow} iconClassName={css.icon} onClick={this.sliderRef.current?.slickPrev} />
          <ul className={css.dots}>{dots}</ul>
          <SliderNextArrow className={css.arrow} iconClassName={css.icon} onClick={this.sliderRef.current?.slickNext} />
        </div>
      ),
      infinite: true,
      useCSS: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: state.activeIndex,
      swipe: CAROUSEL_BREAKPOINTS.desktop.swipe,
      beforeChange: (_, next) => state.setActiveItemByIndex(next),
      responsive: [
        {
          breakpoint: CAROUSEL_BREAKPOINTS.tablet.maxWidth,
          settings: {
            swipe: CAROUSEL_BREAKPOINTS.tablet.swipe,
          },
        },
      ],
    };

    return (
      <Carousel ref={this.sliderRef} {...sliderSettings}>
        {state.items.map((item) => (
          <div key={item.data.url} onClick={() => state.openZoomModal()}>
            <Image
              src={item.data.url}
              alt={item.data.name}
              className={classNames(css.image, imageClassName)}
              classNameImageElement={css.imageElement}
              classNameFallbackElement={classNames(css.imageElement, fallBackImageClassName)}
              width={545}
              height={650}
              placeholder={null}
            />
          </div>
        ))}
      </Carousel>
    );
  }

  public render() {
    const { className, state } = this.props;

    return (
      <div className={classNames(css.ModelViewer, className)}>
        <div
          className={classNames(css.containerImages, {
            [css.isLoading]: state.loadingIndicator.isLoading,
          })}
        >
          {this.renderModelImages()}
        </div>
        {state.loadingIndicator.isLoading ? (
          <ThreeDotsLoader className={css.imageLoader} size="medium" />
        ) : (
          state.items.any() && <span className={css.hint}>{state.translation.hint}</span>
        )}
      </div>
    );
  }
}

export default ModelViewer;
