import { GeneralErrorPageTranslation } from '../localization/SiteTranslation';
import ErrorPage from './ErrorPage';

interface GeneralErrorPageProps {
  translation: GeneralErrorPageTranslation;
  redirectUrl: string;
}

const GeneralErrorPage = ({ translation, redirectUrl }: GeneralErrorPageProps) => {
  return (
    <ErrorPage
      title={translation.title}
      link={{
        label: translation.buttonLink,
        href: redirectUrl,
      }}
    >
      {translation.content}
    </ErrorPage>
  );
};

export default GeneralErrorPage;
