import { GtmCustomEvent } from './types/Gtm';

export class GtmCustomEventBuilder {
  private elementUrl?: string;

  public withLink(url: string) {
    this.elementUrl = url;
    return this;
  }

  public build(eventName: string): GtmCustomEvent {
    return {
      event: eventName,
      ['gtm.elementUrl']: this.elementUrl,
    };
  }
}
