import { observer } from 'mobx-react';
import * as React from 'react';

import IconButton from '../../shared/components/Button/IconButton';
import Icon from '../../shared/components/Icon/Icon';
import css from './Warning.css';
import { WarningStateBase } from './WarningStateBase';

interface StackedWarningProps {
  children: React.ReactElement<WarningBaseProps>[] | React.ReactElement<WarningBaseProps>;
}

export interface WarningBaseProps<T = never> {
  state: WarningStateBase<T>;
}

@observer
export class StackedWarnings extends React.Component<StackedWarningProps> {
  render() {
    const { children } = this.props;

    let firstVisibleWarning: React.ReactElement<WarningBaseProps>;

    if (Array.isArray(children)) {
      firstVisibleWarning = children.find((x) => x.props.state.visible) as React.ReactElement<WarningBaseProps>;
    } else {
      firstVisibleWarning = children.props.state.visible ? children : null;
    }

    return firstVisibleWarning ? (
      <div className={css.Warning}>
        <div className={css.content}>
          {firstVisibleWarning}
          <IconButton
            onClick={() => firstVisibleWarning.props.state.close()}
            className={css.button}
            icon={<Icon className={css.icon} name="cross" />}
          />
        </div>
      </div>
    ) : (
      <React.Fragment />
    );
  }
}
