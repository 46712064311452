import classNames from 'classnames';
import { observer } from 'mobx-react';

import Price from '../../../../shared/components/Price';
import { SelectedFeature } from '../../../ConfiguratorCoreState';
import css from './AccessoryItem.css';

interface AccessoryItemProps {
  selectedFeature: SelectedFeature;
  className?: string;
  hidePrice?: boolean;
}

const AccessoryItem = ({ selectedFeature, className, hidePrice }: AccessoryItemProps) => {
  const price = selectedFeature.feature.price;

  return (
    <div className={classNames(className, css.AccessoryItem)}>
      <span>
        <strong className={css.name}>{selectedFeature.feature.name}</strong>{' '}
        <span>
          <span>{selectedFeature.item.fullName}</span>{' '}
          <span className={css.code}>{selectedFeature.item.displayCode}</span>
        </span>
      </span>
      {!hidePrice && price.amount !== 0 && <Price price={price} className={css.price} />}
    </div>
  );
};

export default observer(AccessoryItem);
