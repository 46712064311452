import { observer } from 'mobx-react';

import ActionButton from '../../../app/shared/components/ActionButton';
import FormInput from '../../../app/shared/components/FormInput';
import ThreeDotsLoader from '../../../app/shared/components/Loaders/ThreeDotsLoader';
import Modal from '../../../app/shared/components/Modal/Modal';
import HeaderLevel3 from '../../../app/shared/components/Typography/HeaderLevel3';
import css from './SendCartToEmail.css';
import { SendCartToEmailModalState } from './SendCartToEmailModalState';

interface Props {
  state: SendCartToEmailModalState;
}

export const SendCartToEmail = observer((props: Props) => {
  const translation = props.state.translation;
  const isEmailLoading = props.state.initializationIndicator.isLoading;

  return (
    <Modal modal={props.state} contentClassName={css.SendCartToEmail}>
      <HeaderLevel3 className={css.title}>{translation.headerText}</HeaderLevel3>
      {isEmailLoading ? (
        <ThreeDotsLoader size="small" />
      ) : (
        <form>
          <FormInput input={props.state.emailInput} label={translation.emailLabel} autoFocus={true} />
          <ActionButton type="submit" className={css.sendButton} command={props.state.sendEmailCommand}>
            {translation.sendButton}
          </ActionButton>
        </form>
      )}
    </Modal>
  );
});
