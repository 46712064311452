import { CustomerData } from '../app/data/model';
import { isFlokkRole, Role, toRole } from './Role';

export interface IPolicy {
  grantAccess: (user: CustomerData) => boolean;
}

export const AdminPolicy: IPolicy = {
  grantAccess: (user) =>
    user.roles.map((rs) => toRole(rs)).some((userRole) => userRole === Role.Admin || isFlokkRole(userRole)),
};

export const BuyerPolicy: IPolicy = {
  grantAccess: (user) =>
    user.roles
      .map((rs) => toRole(rs))
      .some((userRole) => [Role.Admin, Role.Buyer].contains(userRole) || isFlokkRole(userRole)),
};
