import { AsyncCommand } from '../../../app/shared/common';
import ActionButton from '../../../app/shared/components/ActionButton';
import { FavoriteButton } from '../../../app/shared/components/Button/CustomButtons';
import { FavoriteButtonTranslation } from '../../localization/SiteTranslation';
import css from './FavoriteActionButton.css';

interface FavoriteActionButtonProps {
  command: AsyncCommand;
  translation: FavoriteButtonTranslation;
  clicked?: boolean;
  disabled?: boolean;
}

const FavoriteActionButton = ({
  command,
  translation,
  clicked = false,
  disabled = false,
}: FavoriteActionButtonProps) => {
  return (
    <ActionButton
      className={css.element}
      command={command}
      disabled={disabled}
      buttonElement={FavoriteButton}
      externalButtonProps={{ translation, added: clicked }}
      errorMessage={translation.addingToFavoritesFailed}
      errorClassName={css.error}
    />
  );
};

export default FavoriteActionButton;
