import { GetProductQueryResponse, OptionVariationData, OptionVariationDescriptorData } from '../data/model';
import { TabsState } from './components/ConfiguratorTabs/TabsState';
import IConfigurationPageQuery from './IConfigurationPageQuery';

export class RestoringConsolidatedOptionStateStrategy {
  private response: GetProductQueryResponse;

  constructor(response: GetProductQueryResponse) {
    this.response = response;
  }

  public selectConsolidateOptionsNotSpecifiedInQuery(tabs: TabsState, pageQuery: IConfigurationPageQuery) {
    const consolidatedOptions = this.getConsolidatedOptionsNotSpecifiedInQuery(pageQuery);
    const selectedIds: string[] = [];

    consolidatedOptions.forEach((consolidatedOption) => {
      const matchingVariation = this.findBestMatchingVariation(consolidatedOption.allVariations, pageQuery);
      if (matchingVariation && tabs.trySelectOptionUsingId(matchingVariation.id)) {
        selectedIds.push(matchingVariation.id);
      }
    });

    return selectedIds;
  }

  private getConsolidatedOptionsNotSpecifiedInQuery(pageQuery: IConfigurationPageQuery) {
    return this.getConsolidatedOptions().filter(
      (consolidated) => !consolidated.allVariations.any((variation) => pageQuery.options.contains(variation.id)),
    );
  }

  private getConsolidatedOptions() {
    return this.response.options
      .filter((option) => option.groups.any((group) => group.variations.any((variation) => variation.origin.any())))
      .map((option) => {
        return { option: option, allVariations: option.groups.mapMany((group) => group.variations) };
      });
  }

  private findBestMatchingVariation(allVariations: OptionVariationData[], pageQuery: IConfigurationPageQuery) {
    const variationToSelect = allVariations.find((variation) =>
      variation.origin
        .filter((descriptor) => this.isOptionEnabled(descriptor, pageQuery))
        .all((descriptor) => pageQuery.options.contains(descriptor.variationId)),
    );

    return variationToSelect;
  }

  private isOptionEnabled(descriptor: OptionVariationDescriptorData, pageQuery: IConfigurationPageQuery) {
    const option = this.response.hiddenConsolidated.find((x) => x.id === descriptor.optionId);

    if (option && option.enablers.any()) {
      return option.enablers.all(
        (enabler) =>
          pageQuery.options.contains(enabler.variationId) || pageQuery.accessories.contains(enabler.variationId),
      );
    }

    return true;
  }
}
