import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { IApiClient } from '../../app/data/client';
import { ShoppingContext } from '../../app/data/model';
import { BasePageState } from '../../app/shared/BasePageState';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { Tab } from '../../app/shared/Tabs/Tab';
import { Tabs } from '../../app/shared/Tabs/Tabs';
import { StoreState } from '../../app/StoreState';
import { ProfilePageTranslation } from '../localization/SiteTranslation';
import { AdminPolicy } from '../Policy';
import { PersonalTabState } from './PersonalTab/PersonalTabState';
import { UsersTabState } from './UsersTab/UsersTabState';

export class ProfileState extends BasePageState<never> {
  public profileTabs: Tabs;

  // dealerTab is disabled for now
  // public dealerTab: Tab;

  public personalTab: Tab;
  public usersTab: Tab;

  public personalTabState: PersonalTabState;
  public usersTabState?: UsersTabState;

  constructor(
    client: IApiClient,
    authentication: IAuthenticationService,
    private readonly shoppingContext: ShoppingContext,
    eventAggregator: IEventAggregator,
    public readonly translation: ProfilePageTranslation,
    languagesCodes: string[],
  ) {
    super(translation);
    this.initializeTabs(client, authentication, eventAggregator, languagesCodes);
  }

  async onLoad(store: StoreState): Promise<void> {
    await Promise.all([this.personalTabState.load(), this.usersTabState?.load()]);
  }

  private initializeTabs = (
    client: IApiClient,
    authentication: IAuthenticationService,
    eventAggregator: IEventAggregator,
    languageCodes: string[],
  ) => {
    const tabs = [];
    // dealerTab is disabled for now
    // this.dealerTab = new Tab('dealer', 'Dealer (Forte Digital)');

    this.personalTab = new Tab('personal', this.translation.personalTabTitle);
    this.personalTabState = new PersonalTabState(
      client,
      authentication,
      eventAggregator,
      this.translation.personalTabContent,
      this.shoppingContext.languageCode,
      languageCodes,
    );
    tabs.push(this.personalTab);

    if (AdminPolicy.grantAccess(this.shoppingContext.customer)) {
      this.usersTab = new Tab('users', this.translation.usersTabTitle);
      this.usersTabState = new UsersTabState(client, this.shoppingContext, eventAggregator, this.translation.usersTab);
      tabs.push(this.usersTab);
    }

    this.profileTabs = new Tabs(tabs);
    this.profileTabs.setActiveTab(this.personalTab);
  };
}
