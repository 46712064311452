import { IApiClient } from '../data/client';
import { ConfiguratorUrlBuilder } from '../errorHandling/ParseConfigurationCodeAndRedirectState';
import { ILoadingIndicator } from './LoadingIndicator';
import Logger from './Logger';
import { INavigationService } from './NavigationService';

export interface IConfigurationCodeResolutionService {
  resolveAndGoTo(code: string): Promise<void>;
}

export class ConfigurationCodeResolutionService implements IConfigurationCodeResolutionService {
  private builder: ConfiguratorUrlBuilder;
  private navigation: INavigationService;
  private indicator: ILoadingIndicator;

  constructor(client: IApiClient, navigation: INavigationService, indicator: ILoadingIndicator) {
    this.builder = new ConfiguratorUrlBuilder(client);
    this.navigation = navigation;
    this.indicator = indicator;
  }

  public async resolveAndGoTo(code: string): Promise<void> {
    this.indicator.start();

    try {
      const url = await this.builder.buildFromCode(code);
      this.navigation.navigateTo(url);
    } catch (err) {
      Logger.exception(`An error occurred when resolving code ${code}`, err);
    } finally {
      this.indicator.stop();
    }
  }
}
