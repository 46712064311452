import StatusMessage, { Status } from './StatusMessage/StatusMessage';

export interface ActionButtonErrorProps {
  children: string;
  className?: string;
}

const ActionButtonError = ({ children, className }: ActionButtonErrorProps) => {
  return (
    <StatusMessage className={className} type={Status.Error}>
      {children}
    </StatusMessage>
  );
};

export default ActionButtonError;
