import { ProductConfigurationData } from '../data/model';
import { ICancellableTask } from '../shared/TasksScheduler';

export class GenerateCodeTask implements ICancellableTask {
  configuration: ProductConfigurationData;
  cancelled = false;

  constructor(configuration: ProductConfigurationData) {
    this.configuration = configuration;
  }
}
