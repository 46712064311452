import classNames from 'classnames';
import { Component } from 'react';

import { UserStoryData } from '../../../data/model';
import { Breakpoint, Screen, ScreenObserver } from '../../../layout/ScreenObserver';
import { UserStoriesTranslation } from '../../../localization/SiteTranslation';
import ExternalImage from '../../../shared/components/ExternalImage/ExternalImage';
import Heading, { HeadingPosition, HeadingSize } from '../../../shared/components/Typography/Heading/Heading';
import { UnderlineLink } from '../../../shared/components/UnderlineLink/UnderlineLink';
import css from './ProductUserStories.css';

interface ProductUserStoriesProps {
  stories: UserStoryData[];
  translation: UserStoriesTranslation;
}

export default class ProductUserStories extends Component<ProductUserStoriesProps, { titleLine: number }> {
  constructor(props: ProductUserStoriesProps) {
    super(props);

    this.state = { titleLine: 3 };
    this.onScreenChange = this.onScreenChange.bind(this);
  }

  componentDidMount(): void {
    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
  }

  componentWillUnmount(): void {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
  }

  onScreenChange(screen: Screen) {
    const isMobile = screen.breakpoint === Breakpoint.Phone || screen.breakpoint === Breakpoint.Tablet;
    this.setState({ titleLine: isMobile ? 2 : 3 });
  }

  renderStory(story: UserStoryData) {
    const { translation } = this.props;

    const classes = classNames(
      css.story,
      this.props.stories.length === 1 && css.oneStory,
      this.props.stories.length === 3 && css.threeStories,
    );

    return (
      <div key={story.name} className={classes}>
        <ExternalImage className={css.image} src={story.imageUrl} alt={story.name} />
        <div className={css.content}>
          <Heading className={css.title} level={2} position={HeadingPosition.Center} size={HeadingSize.Small}>
            {translation.title}
          </Heading>
          {story.name && (
            <Heading
              className={css.name}
              style={{ WebkitLineClamp: this.state.titleLine }}
              position={HeadingPosition.Center}
              level={3}
              size={HeadingSize.Large}
            >
              {story.name}
            </Heading>
          )}
          {story.url && (
            <div>
              <UnderlineLink href={story.url} dark>
                {translation.readMore}
              </UnderlineLink>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { stories } = this.props;
    return <div className={css.ProductUserStories}>{stories.map((story) => this.renderStory(story))}</div>;
  }
}
