import IPreferredStoreProvider from './IPreferredStoreProvider';

export default class BrowserPreferredStoreProvider implements IPreferredStoreProvider {
  getStoreId(): string {
    return null;
  }

  getCountryCode(): string {
    return null;
  }

  getLanguageCode(): string {
    return window.navigator.language;
  }

  setStoreId(id: string): void {
    //
  }

  setCountryCode(id: string): void {
    //
  }

  setLanguageCode(code: string): void {
    //
  }
}
