import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import {
  ContactAndBillingData,
  DeliveryAddressData,
  OrderData,
  PaymentMethodData,
  PaymentParameterData,
  ShipmentData,
  ShippingRateData,
  ShoppingContext,
  StoreContext,
} from '../data/model';
import { CheckoutPageTranslation } from '../localization/SiteTranslation';
import CountryCode from '../shared/CountryCode';
import {
  CheckboxInput,
  EmailAddressRule,
  Form,
  PhonePrefixRule,
  PhoneRule,
  TextInput,
  ValueMaxLength,
  ValueRequiredRule,
} from '../shared/Form';
import Logger from '../shared/Logger';
import { AddressState } from './components/AddressState';
import { ICheckoutStep } from './ICheckoutStep';
import { ShippingState } from './ShippingState';

export interface PaymentMethodSelected {
  paymentMethodCode: string;
  parameters: PaymentParameterData[];
}

export class CheckoutFormState extends Form implements ICheckoutStep {
  public id: string = 'contact-and-billing';
  public title: string;

  @observable
  public isActive: boolean;

  public firstName: TextInput;
  public lastName: TextInput;
  public companyName: TextInput;
  public taxId: TextInput;
  public email: TextInput;
  public phonePrefix: TextInput;
  public phone: TextInput;

  public deliveryAddress: AddressState;
  public billingAddress: AddressState;

  public deliveryAddressSameAsBilling: CheckboxInput;
  public termsAndConditions: CheckboxInput;
  public subscription: CheckboxInput;

  @observable.ref
  public selectedPaymentMethod: PaymentMethodData;

  @observable.shallow
  public paymentMethods: Array<PaymentMethodData> = [];

  private readonly shippingState: ShippingState;

  @computed
  public get shippingRates() {
    return this.shippingState.shippingRates;
  }

  @computed
  public get showShippingMethodSelection() {
    return this.shippingRates.length > 0;
  }

  @computed
  public get selectedShippingRate() {
    return this.shippingState.shippingRate;
  }

  @computed
  get shippingRateChangedEvent() {
    return this.shippingState.shippingRateChangedEvent;
  }

  @computed
  get recalculating() {
    return this.shippingState.recalculating;
  }

  constructor(translation: CheckoutPageTranslation, availableCountries: Array<string>, client: IApiClient) {
    super();

    makeObservable(this);

    this.firstName = new TextInput()
      .withRule(new ValueRequiredRule(translation.contactForm.firstName.fieldRequired))
      .withRule(new ValueMaxLength(18));
    this.lastName = new TextInput()
      .withRule(new ValueRequiredRule(translation.contactForm.lastName.fieldRequired))
      .withRule(new ValueMaxLength(18));

    this.companyName = new TextInput();
    this.taxId = new TextInput().conditional(() => !!this.companyName.value);
    // Disable TaxId field validation rule - we're hidden taxID field for b2b there's request quote link
    // .withRule(new ValueRequiredRule(translation.contactForm.taxId.fieldRequired));

    this.email = new TextInput()
      .withRule(new ValueRequiredRule(translation.contactForm.email.fieldRequired))
      .withRule(new EmailAddressRule(translation.contactForm.email.wrongFormat))
      .withRule(new ValueMaxLength(60));

    this.phonePrefix = new TextInput()
      .withRule(new ValueRequiredRule(translation.contactForm.phonePrefix.fieldRequired))
      .withRule(new PhonePrefixRule(translation.contactForm.phonePrefix.wrongFormat));

    this.phone = new TextInput()
      .withRule(new PhoneRule(translation.contactForm.phone.wrongFormat))
      .withRule(new ValueRequiredRule(translation.contactForm.phone.fieldRequired))
      .withRule(new ValueMaxLength(12));

    this.billingAddress = new AddressState(translation.addressForm, availableCountries, client);
    this.deliveryAddress = new AddressState(translation.addressForm, availableCountries, client);
    this.deliveryAddress.disable();

    this.deliveryAddressSameAsBilling = new CheckboxInput(true);

    this.shippingState = new ShippingState(client, translation.shippingMethodSection);

    this.termsAndConditions = new CheckboxInput().withRule(
      new ValueRequiredRule(translation.readyToOrderSection.termsAndConditions.fieldRequired),
    );
    this.subscription = new CheckboxInput();

    this.setInputsToValidate();

    this.title = translation.progress.deliveryStep;

    this.setDeliveryAddressValidation();
  }

  private setDeliveryAddressValidation() {
    autorun(() => {
      if (this.deliveryAddressSameAsBilling.value) {
        this.deliveryAddress.disable();
      } else {
        this.deliveryAddress.enable();
      }
    });
  }

  @action.bound
  changePaymentMethod(code: string): void {
    this.selectedPaymentMethod = this.paymentMethods.find((x) => x.code === code);
  }

  public changeShippingRate = async (shippingRate: ShippingRateData) => {
    await this.shippingState.changeShippingRateCommand.invoke(shippingRate);
  };

  @action
  initialize(
    storeContext: StoreContext,
    shoppingContext: ShoppingContext,
    shippingRates: ShippingRateData[],
    selectedShipment?: ShipmentData,
  ) {
    if (storeContext.availablePaymentMethods.empty()) {
      throw new Error(`No payment method is configured for store: ${storeContext.store.id}`);
    }

    this.paymentMethods = storeContext.availablePaymentMethods;
    this.selectedPaymentMethod = this.paymentMethods.first();

    this.deliveryAddress.setAvailableCountries(storeContext.availableCountries);
    this.deliveryAddress.setStoreCountry(storeContext.store);

    this.billingAddress.setAvailableCountries(storeContext.availableCountries);
    this.billingAddress.setStoreCountry(storeContext.store);

    this.shippingState.initialize(shoppingContext, shippingRates, selectedShipment);

    this.phonePrefix.value = this.getPhonePrefix(storeContext.store.countryCode);
  }

  @action
  public restoreOrder(order: OrderData): void {
    const contact = order.contactAndBilling;
    const delivery = order.deliveryAddress;

    this.companyName.value = contact.companyName;
    this.email.value = contact.email;
    this.firstName.value = contact.firstName;
    this.lastName.value = contact.lastName;
    this.phonePrefix.value = contact.phoneData.prefix;
    this.phone.value = contact.phoneData.number;
    this.taxId.value = contact.taxId || '';

    this.billingAddress.set(delivery);
    this.deliveryAddress.set(delivery);

    this.restoreSelectedPaymentMethod(order);
    // There is the assumption that a user had to accept the terms and conditions if an order is being restored.
    this.termsAndConditions.value = true;
  }

  public restoreSelectedPaymentMethod(order: OrderData) {
    const paymentMethodCode = order.payments.first()?.code;
    const paymentMethod = this.paymentMethods.find((x) => x.code === paymentMethodCode);

    if (paymentMethod) {
      this.changePaymentMethod(paymentMethodCode);
    } else {
      Logger.warn(
        `Cannot restore a payment method for the ${paymentMethodCode} code. The payment method in unavailable.`,
      );
    }
  }

  getPhonePrefix(countryCode: string) {
    const phonePrefixes = new Map([
      [CountryCode.France, '+33'],
      [CountryCode.Switzerland, '+41'],
      [CountryCode.UnitedKingdom, '+44'],
      [CountryCode.Denmark, '+45'],
      [CountryCode.Sweden, '+46'],
      [CountryCode.Norway, '+47'],
      [CountryCode.Poland, '+48'],
      [CountryCode.Germany, '+49'],
    ]);
    const defaultPhonePrefix = phonePrefixes.get(countryCode);
    return defaultPhonePrefix ? defaultPhonePrefix : '+';
  }

  getContactAndBilling() {
    let contactAndBilling: ContactAndBillingData = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      phoneData: {
        number: this.phone.value,
        prefix: this.phonePrefix.value,
      },
      email: this.email.value,
      companyName: this.companyName.value,
      taxId: this.taxId.value,
      address: this.billingAddress.getData(),
    };

    return contactAndBilling;
  }

  getDeliveryAddress(): DeliveryAddressData {
    if (this.deliveryAddressSameAsBilling.value) {
      const address = this.billingAddress.getData();
      address.recipient = `${this.companyName.value} ${this.firstName.value} ${this.lastName.value}`.trim();
      return address;
    }

    return this.deliveryAddress.getData();
  }

  setInputsToValidate() {
    this.billingAddress.recipient.disable();

    this.inputsToValidate.push(
      this.firstName,
      this.lastName,
      this.email,
      this.phonePrefix,
      this.phone,
      this.termsAndConditions,
      this.subscription,
      this.companyName,
      this.taxId,
    );

    this.childForms.push(this.billingAddress);
    this.childForms.push(this.deliveryAddress);
    this.childForms.push(this.shippingState);
  }
}

export default CheckoutFormState;
