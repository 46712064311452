import classNames from 'classnames';

import css from './Brand.css';
import brandClassNameResolver from './brandCssClassResolver';

interface BrandProps {
  className?: string;
  name: string;
}

const Brand = ({ className, name }: BrandProps) => {
  const brandClassName = brandClassNameResolver(css, name);

  return (
    brandClassName && (
      <div className={classNames(css.Brand, className)}>
        <div className={brandClassName} />
      </div>
    )
  );
};

export default Brand;
