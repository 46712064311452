import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { ErrorMessages } from '../../../shared/components/Input/ErrorMessages/ErrorMessages';
import { PaymentWidgetProps } from '../IPaymentMethod';
import StripePaymentMethod from './StripePaymentMethod';

const cardStyle = {
  style: {
    base: {
      color: '#000',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#000',
      },
    },
    invalid: {
      color: '#b84a59',
      iconColor: '#b84a59',
    },
  },
};

@observer
export default class StripePaymentWidget extends Component<PaymentWidgetProps<StripePaymentMethod>, {}> {
  componentDidMount() {
    this.props.paymentMethod.onWidgetRendered();
  }

  render() {
    const { paymentMethod } = this.props;
    const validationErrors = paymentMethod.cardValidationError ? [paymentMethod.cardValidationError] : undefined;

    return (
      <Elements stripe={paymentMethod.loadStripePromise}>
        <ElementsConsumer>
          {({ elements }) => {
            paymentMethod.setElements(elements);
            return (
              <>
                <div style={{ marginTop: 10 }}>Please enter your credit card details</div>
                <div style={{ marginTop: 10, padding: 10, border: 1, borderStyle: 'solid', borderColor: '#e9e9e9' }}>
                  <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={(x) => paymentMethod.onCardInputChange(x)}
                  />
                </div>
                <div>
                  <ErrorMessages errors={validationErrors} />
                </div>
              </>
            );
          }}
        </ElementsConsumer>
      </Elements>
    );
  }
}
