import { OptionDescriptionModalTranslation, RequestQuoteContentTranslation, SiteTranslation } from './SiteTranslation';

// tslint:disable:max-line-length

const requestQuoteTranslation: RequestQuoteContentTranslation = {
  'confirmation': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Confirmation Page',
    'buttonLink':
      'Continue Shopping',
    'title':
      'Thanks! Requested quote is on its way.',
    'content':
      'You will hear from us shortly',
  },
  'goBackLink':
    'Back',
  'title':
    'Contact me regarding price for this product',
  'stepsTitle':
    'What will happen to my request?',
  'steps': [
    'Your request will be sent to Flokk sales',
    'Our friendly sales team will handle your request',
    'Depending on location, you might be contacted by either a local Flokk representative or one of our certified dealers'
  ],
};

const requestQuoteServedByDealerTranslation: RequestQuoteContentTranslation = {
  ...requestQuoteTranslation,
  'confirmation': {
    ...requestQuoteTranslation.confirmation,
    'content':
      'You will hear from us shortly',
  },
  'steps': [
    'Your request will be sent to Flokk sales',
    'Our friendly sales team will handle your request',
    'Depending on location, you might be contacted by either a local Flokk representative or one of our certified dealers'
  ],
};

const optionDescriptionModal: OptionDescriptionModalTranslation = {
  'closeButton':
    'Close',
};

const translation: SiteTranslation = {
  'layout': {
    'topBar': {
      'title':
        'Flokk',
      'chooseChairLink':
        'Choose chairs',
      'aboutUsLink':
        'About us',
      'inputSearchPlaceholder':
        'Search',
      'languages': {
        'chDe':
          'German',
        'chFr':
          'French',
        'deDe':
          'German',
        'dkDk':
          'Danish',
        'enUs':
          'U.S. English',
        'frFr':
          'French',
        'nlNl':
          'Dutch',
        'nbNo':
          'Norwegian',
        'enGB':
          'English',
        'svSe':
          'Swedish',
        'plPl':
          'Polish'
      }
    },
    'footerPanel': {
      'emailLabel':
        'e-mail: ',
      'phoneLabel':
        'tel: ',
      'countactUsLink':
        'Contact your local office',
      'followUsLabel':
        'Follow Us',
      'copyright':
        'Copyright',
      'allRightReserved':
        'All rights reserved'
    },
    'titleFormat':
      '{title} - Flokk - Create your own'
  },
  'requestQuotePage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Request Quote',
    'errorMessage':
      'Error occurred while initializing form. Please contact customer support.',
    'defaultContent':
    requestQuoteTranslation,
    'contentWhenProcessedByDealer':
    requestQuoteServedByDealerTranslation,
  },
  'emailMyConfigurationPage': {
    'errorMessage':
      'Error occurred while initializing form. Please contact customer support.',
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Send my configuration by email',
    'confirmation': {
      'pageDescriptionFormat':
        'Description',
      'pageTitleFormat':
        'Send my configuration by email',
      'buttonLink':
        'Go back to home page',
      'title':
        'Thanks! Your mail is on the way',
      'content':
        'Check your inbox.'
    },
    'goBackLink':
      'Go back to summary',
    'title':
      'Send my configuration by email',
  },
  'homePage': {
    'pageDescriptionFormat':
      'A few, carefully selected chairs, perfect for your home office or in a small business, ready to order directly from Flokk',
    'pageTitleFormat':
      'Order your Flokk chair here',
    'articleList': {
      'readMore':
        'Read more',
    },
    'productList': {
      'fromPriceLabel':
        'From {price} incl. VAT',
      'productThumbnail': {
        'productVariationList': {
          'title':
            'Variations'
        }
      }
    }
  },
  'configurationSearchPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Search for configuration',
    'title':
      'Enter your configuration code',
    'description':
      'and we will take you to the configurator',
    'codeInputPlaceholder':
      'Type code here and hit enter',
    'configurationFinder': {
      'codeWithIncorrectLengthFormat':
        'Value should have exact {expectedLength} chars.',
      'codeInvalid':
        'Provided code {invalidCode} is invalid! Ensure that it was entered correctly.',
      'codeNotFound':
        'Unable to find product configuration by code {notFoundCode}',
    },
  },
  'configurationDetailsPage': {
    'pageTitleFormat':
      '{code} - Configuration details',
    'pageDescriptionFormat':
      '{productName} {options} {accessories}',
    'title':
      'Your selected configuration',
    'visualizationNotAvailableText':
      'Visualization not available',
  },
  'confirmationPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Order confirmation',
    'title':
      'Checkout',
    'errorTitle':
      'Error has occurred',
    'paymentConfirmed':
      'Your payment is confirmed, thank you for shopping at Flokk.',
    'checkYourEmail':
      'For order & delivery details, check your e-mail inbox.',
    'errorContent':
      'There was an error, please contact customer support.',
    'orderSection': {
      'title':
        'Your order',
      'orderNumber':
        'Order number',
    },
    'summarySection': {
      'title':
        'Summary',
      'taxText':
        '+ VAT',
      'totalToPayText':
        'total',
      'contact': {
        'contactAndBilling':
          'Contact & Billing',
        'deliveryAddress':
          'Delivery address',
      },
    },
    'designerSectionTitle':
      'Do you know who designed your chair?',
    'customerStorySectionTitle':
      'Improve your sitting position',
    'readMore':
      'Read more',
    'contactUsSection': {
      'text':
        'If you have any question contact',
      'linkText':
        'customer service'
    },
  },
  'checkoutPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Checkout',
    'title':
      'Checkout',
    'goBackLink':
      'Back',
    'progress': {
      'title':
        'Checkout',
      'deliveryStep':
        'Contact & Delivery',
      'paymentStep':
        'Payment',
      'confirmationStep':
        'Confirmation'
    },
    'goToPaymentButton':
      'Confirm & pay',
    'paymentMethodLabel':
      'How would you like to pay?',
    'orderAndPayButton':
      'Order and pay',
    'submitButton':
      'Submit',
    'paymentPoweredByText':
      'We use a payment solution from',
    'addressForm': {
      'city': {
        'name':
          'City',
        'placeholder':
          'Enter city',
        'fieldRequired':
          'City is required'
      },
      'country': {
        'name':
          'Country',
        'placeholder':
          'Enter country',
        'fieldRequired':
          'Country is required'
      },
      'street': {
        'name':
          'Street',
        'placeholder':
          'Enter street',
        'fieldRequired':
          'Street is required'
      },
      'streetNumber': {
        'name':
          'Street Number',
        'placeholder':
          'Enter street number',
        'fieldRequired':
          'Street Number is required'
      },
      'zipCode': {
        'name':
          'Zip Code',
        'fieldRequired':
          'Zip Code is required',
        'wrongFormat':
          'Entered value is not a valid zip code'
      },
      'availableCountries': {
        'POL':
          'Poland',
        'NOR':
          'Norway',
        'CHE':
          'Switzerland',
        'DEU':
          'Germany',
        'DNK':
          'Denmark',
        'FRA':
          'France,',
        'NLD':
          'Netherlands',
        'SWE':
          'Sweden',
        'GBR':
          'United Kingdom',
        'USA':
          'United States'
      },
      'addressLine1': {
        'name':
          'Address Line 1',
        'placeholder':
          '',
        'fieldRequired':
          'Address Line 1 is required',
        'wrongFormat':
          ''
      },
      'addressLine2': {
        'name':
          'Address Line 2',
        'placeholder':
          '',
        'fieldRequired':
          '',
        'wrongFormat':
          ''
      },
      'recipient': {
        'name':
          'Recipient',
        'placeholder':
          '',
        'fieldRequired':
          'Recipient is required',
        'wrongFormat':
          ''
      }
    },
    'contactForm': {
      'contactDetails':
        'Contact & Billing ',
      'email': {
        'name':
          'Email address',
        'placeholder':
          '',
        'fieldRequired':
          'Email is required',
        'wrongFormat':
          'Entered value is not a valid email address'
      },
      'phone': {
        'name':
          'Phone number',
        'placeholder':
          '',
        'fieldRequired':
          'Phone is required',
        'wrongFormat':
          'Entered value is not a valid phone number'
      },
      'phonePrefix': {
        'name':
          'Prefix',
        'placeholder':
          '+ _ _',
        'fieldRequired':
          'Prefix is required',
        'wrongFormat':
          'Entered value is not a valid phone prefix'
      },
      'firstName': {
        'name':
          'First name',
        'placeholder':
          '',
        'fieldRequired':
          'First Name is required'
      },
      'lastName': {
        'name':
          'Last name',
        'placeholder':
          '',
        'fieldRequired':
          'Last Name is required'
      },
      'companyName': {
        'name':
          'Company name (optional)',
        'placeholder':
          ''
      },
      'taxId': {
        'name':
          'Company Tax Id',
        'placeholder':
          '',
        'fieldRequired':
          'Company Tax Id is required'
      }
    },
    'shippingMethodSection': {
      'subtitle': 'Delivery method',
      'shippingMethod': {
        'name': 'Shipping Method',
        'fieldRequired': 'This field is required'
      }
    },
    'deliveryAddressSection': {
      'subtitle':
        'Delivery address',
      'billingAndDeliveryAreTheSameText':
        'Billing & Delivery address are the same',
      'deliveryInfoText':
        'Free home delivery in 10-15 days'
    },
    'paymentMethods': {
      'supportedCreditCardsMessage': {
        'acceptedCreditCardsText':
          'We accept the following payment cards'
      },
    },
    'readyToOrderSection': {
      'subtitle':
        'Ready to order?',
      'termsAndConditions': {
        'termsAndConditionLinkName':
          'Terms & Conditions',
        'name':
          'I accept the',
        'placeholder':
          '',
        'fieldRequired':
          'This field is required'
      },
      'subscription':
        'I\'d like to receive additional…'
    },
    'readyToOrderPaymentSection': {
      'subtitle':
        'Ready to order?',
    },
    'errors': {
      'creatingOrderFailed':
        'Unable to place order. Please try again or contact customer support.',
      'initializingPaymentFailed':
        'Payment processing failed. Please try again or contact customer support.',
      'authorizingPaymentFailed':
        'Authorization failed. Please try again or contact customer support.',
      'finalizingPaymentFailed':
        'Payment processing failed. Please try again or contact customer support.'
    },
    'discountCodeForm': {
      'applyButton': 'Apply',
      'discountCodeIsAppliedMessage': 'The "{code}" discount code is applied.',
      'discountCodeIsInvalidOrExpiredError': 'The code you entered is invalid or has expired.',
      'doYouHaveDiscountCodeText': 'Do you have a promotion or campaign code?',
      'enterCodeText': 'Insert code here',
      'label': 'Promo code',
    },
  },
  'checkoutShortcutPage': {
    'pageTitleFormat':
      'Checkout shortcut',
    'pageDescriptionFormat':
      '',
    'errors': {
      'addingProductToCartFailed':
        'Unable to purchase product configuration with code {code}. Please contact customer support.',
      'gettingProductConfigurationFailed':
        'Unable to retrieve information about product configuration with the code {code}. Please refresh page or contact customer support.',
    }
  },
  'configurationPage': {
    'pageDescriptionFormat':
      '{productName} {options} {accessories}',
    'pageTitleFormat':
      '{productName} - Configurator',
    'productDetails': {
      'documentsSection':
        'Documents',
      'moreInformationSection':
        'More Information',
      'specificationSection':
        'Specification'
    },
    'showFiltersText':
      'Show filters',
    'hideFiltersText':
      'Hide filters',
    'productModels': {
      'chooseModelText': 'Choose your model',
      'modelSelectorWithLoader': {
        'noOtherModelsAvailable': 'There are no other models available.'
      },
    },
    'options': {
      'title':
        'Customize your chair',
      'priceGroup':
        'Price Group',
      'textSelected':
        'Selected',
      'fabricFilters': {
        'noFabricsAvailable':
          'There are no fabrics matching to your preferences.',
        'clearButton':
          'Clear',
        'defaultFilterName':
          'All',
        'collectionFilterLabel':
          'Collection'
      },
    },
    'accessories': {
      'title':
        'Accessories',
      'accessory': {
        'removeButton':
          'Remove',
        'addButton':
          'Add',
        'textSelected':
          'Selected'
      }
    },
    'summary': {
      'title':
        'Your order summary',
      'accessoriesSubtitle':
        'Accessories',
      'requestQuoteButton':
        'Ask for price',
      'emailConfirmationButton':
        'Send my configuration by email',
      'limitedChairQuantity':
        'To order more than {max} chairs please contact us',
      'checkoutButton':
        'Checkout',
      'editConfigurationLink':
        'Edit',
      'expectedShipment': {
        'expectedShipment': 'Ready for shipping within',
        'days': 'working days'
      },
      'quantityInput': {
        'outOfRangeWarning':
          'The value must be between {min} and {max}',
      },
      'saveYourConfigurationHereButton':
        'Save your configuration here.',
      'backButton':
        'Back',
      'shareSection': {
        'title':
          'Save your configuration',
        'yourConfigurationUrl':
          'Your configuration URL',
        'downloadAsPdf':
          'Download as PDF',
        'downloadVisualizations':
          'Download images',

        'copyLink': {
          'label':
            'Copy Link',
          'textCopiedLabel':
            'Copied'
        },
        'codeShare': {
          'codeTitle':
            'Your configuration code',
          'copyCode': {
            'label':
              'Copy Code',
            'textCopiedLabel':
              'Copied'
          },
        },
        'closeButton':
          'Close',
      },
      'findLocalDealerSection': {
        'title':
          'Where is Flokk?',
        'button':
          'Find local dealer'
      }
    },
    'restrictions': {
      'accessorySelection': {
        'header': {
          'userSelectionNotRequiredFormat':
            'Are you sure you want to add {itemName} accessory?',
          'userSelectionRequiredFormat':
            'If you want to add {itemName} you need to change preferences',
        },
        'automaticDeselectionOfAccessoriesFormat':
          'Chosen accessory is not available with {itemsToDeselect}. If you choose this accessory the accessories below will be removed from your order.',
      },
      'optionSelection': {
        'header': {
          'userSelectionNotRequiredFormat': `Are you sure you want to change {itemName} option?`,
          'userSelectionRequiredFormat': `If you want to change {itemName} you need to change preferences`,
        },
        'automaticDeselectionOfAccessoriesFormat':
          'Chosen option is not available with {itemsToDeselect}. If you choose this option the accessories below will be removed from your order.',
      },
      'itemNotAvailableWithFormat':
        '{itemToSelect} is not available with {itemsToDeselect}.',
      'itemsSeparator':
        ' and ',
      'selectMatchingOption':
        ' Below you can find options that match.',
      'cancelButton':
        'Cancel',
      'applyButton':
        'Agree, change',
      'closeButton':
        'Close',
      'selectedText':
        'Selected'
    },
    'tabs': {
      'fabrics': {
        'name':
          'Fabrics',
      },
      'options': {
        'name':
          'Options',
      },
      'accessories': {
        'name':
          'Accessories',
      },
      'summary': {
        'name':
          'Summary'
      }
    },
    'productNotAvailableInCurrentStoreWarning': {
      'messageFormat': 'This product is not available in your country ({currentStore}). If you like it please contact customer support.'
    },
    'productInactiveWarning': {
      'discontinued': 'This is a discontinued product',
      'generic': 'Product is not available',
      'genericWithReason': 'Product is not available: {reason}'
    },
    'obsoleteCodeModal': {
      'notAvailable': {
        'title':
          'Chair configuration is not available',
        'intro':
          'Given product configuration is not available.',
        'introWithCodePart1':
          'Given product configuration with the code',
        'introWithCodePart2':
          'is not available.',
        'explanation':
          'The following parts or materials are not yet available in this configurator or no longer in use:'
      },
      'restricted': {
        'title':
          'Chair configuration is not valid',
        'intro':
          'Given product configuration is not valid.',
        'introWithCodePart1':
          'Given product configuration with the code',
        'introWithCodePart2':
          'is not valid.',
        'explanation':
          'This might be caused by changes in the product set up.'
      },
      'footer':
        'Please configure your chair once again.',
      'closeButton':
        'Close',
    },
    optionDescriptionModal,
    'metaTitleFormat':
      'My {productName}',
    'metaDescriptionFormat':
      '{productName}: {options}',
    'priceTaxText':
      'Incl. VAT',
  },
  'modularConfiguratorPage': {
    'pageDescriptionFormat': '{productName} {options} {accessories}',
    'pageTitleFormat': '{productName} - Configurator',
  },
  'storeAndLanguageSelector': {
    'title':
      'Choose your country or language',
    'storeSelectorLabel':
      'Country',
    'languageSelectorLabel':
      'Language',
  },
  'initialStoreSelectorModal': {
    'title': 'Hi there',
    'description': 'Find your country or go to our international page',
    'buttonContinue': 'Continue',
    'inputPlaceholder': 'Select your country',
    'languageInputPlaceholder': 'Select language',
    'linkLabelInternationalStore': 'Go to international page',
    'noOptionMessage': 'This country is not found',
    'noLanguageMessage': 'This language is not found'
  },
  'noConnectionModal': {
    'title':
      'Whooops!',
    'content':
      'No internet connection found.',
    'continueLink':
      'Continue',
    'buttonSeparator':
      'or',
    'refreshButton':
      'Refresh Page',
    'closeButton':
      'Close'
  },
  'emptyCartPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Empty Cart',
    'basketEmptyLine1':
      'Your basket is empty',
    'basketEmptyLine2':
      'but it doesn\'t have to be.',
    'continueButton':
      'Continue Shopping'
  },
  'pageNotFound': {
    'pageDescriptionFormat':
      'We can\'t seem to find the page you\'re looking for.',
    'pageTitleFormat':
      'Page Not Found',
    'content':
      'We can\'t seem to find the page you\'re looking for. Are you sure you have typed the correct address? Or, maybe we have messed up our links. Please try to go back or start again at our front page.',
    'title':
      'Oops!',
    'buttonLink':
      'Take me back home'
  },
  'generalErrorPage': {
    'pageDescriptionFormat':
      'Sorry, we are down for maintenance at the moment. Hopefully we will be back soon.',
    'pageTitleFormat':
      'General error',
    'title':
      'Down for maintenance',
    'content':
      'Sorry, we are down for maintenance at the moment. Hopefully we will be back soon.',
    'buttonLink':
      'Take me back home'
  },
  invalidCodePage: {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Invalid code page',
    'title':
      'Invalid configuration code',
    'content':
      'Please provide valid one',
    'buttonLink':
      'Back to Flokk'
  },
  'productSpecificationPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Product specification',
    'accessoriesSection':
      'Accessories',
    'linkTitle':
      'Your configuration link',
    'codeTitle':
      'Your configuration code',
    'pricesSection': {
      'unitPrice':
        'Price per unit',
      'quantity':
        'Qty',
      'subtotalPrice':
        'Subtotal'
    },
    'dataSection': {
      'title':
        'Data',
      'brand':
        'Brand',
      'code':
        'Art. no'
    }
  },
  'storeLocatorPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Find stores, showroom and Flokk offices',
    'heading':
      'Find your store',
    'storeSearch': {
      'noDealersText':
        'Sorry, we have no stores or offices in this area.',
      'noDealersWithFiltersText':
        'Sorry, we have no stores or offices in this area that matched active filters. ',
      'filterResultText':
        '{storesNumber} location found',
      'storeLocatorInput': {
        'label':
          'Find your store',
        'placeholder':
          'City, state or zip-code',
        'orText':
          'or',
        'currentLocationText':
          'use current location',
        'disabledGeolocationText':
          'Geolocation is disabled.',
        'unavailableGeolocationText':
          'Geolocation is unavailable.',
        'tryAgainText':
          'Try again.'
      },
      'storeLocatorFilters': {
        'title':
          'Filters',
        'placesLabel':
          'Type of places',
        'brandLabel':
          'Brand'
      },
    },
    'storeTypes': {
      'Showroom':
        'Showroom',
      'Shop':
        'Shop'
    },
    'details': {
      'webSite':
        'Website',
      'address':
        'Address',
      'directionLink':
        'Directions',
      'email':
        'Email',
      'openHours':
        'Opening Hours',
      'phone':
        'Phone',
      'closeButton':
        'Close',
      'backButton':
        'Back to list'
    }
  },
  'cart': {
    'summary': {
      'deliveryFee':
        'Delivery Fee',
      'subTotal':
        'Price before VAT',
      'totalTaxFormat':
        'Vat ({taxTotal}%)',
      'subTotalWithTax':
        'Total price including VAT',
      'totalToPay':
        'Total To Pay',
      'moreInformationText':
        'More information',
      'lessInformationText':
        'Less information',
      'quantityText':
        'Qty',
      'shippingTimeText': {
        'deliveredWithin': 'Delivered within',
        'day': 'day',
        'days': 'days',
        'week': 'week',
        'weeks': 'weeks'
      },
      'configurationCodeLabel':
        'ID',
      'deliveryCostUnavailable': 'Select shipping method',
      'freeDeliveryText':
        'Free home delivery',
      'taxText':
        '+ VAT',
      'discount':
        'Discount',
    },
  },
  'redirectionWarningModal': {
    'closeButton':
      'Close',
    'title':
      'You\'ll be automatically redirected in a few seconds. Interact with the screen to cancel redirection.'
  },
  'scrollToTop': {
    'button': 'Go to top'
  },
  'productFamilyPage': {
    'pageDescriptionFormat':
      '',
    'pageTitleFormat':
      '',
    'overviewSection': {
      'name':
        'Overview'
    },
    'relatedProducts': {
      'name':
        'Related products',
      'title':
        'You may also like'
    },
    'designerSection': {
      'name':
        'Designer',
      'designedBy':
        'Designed by',
      'readMore':
        'Read more'
    },
    'productUserStories': {
      'name':
        'Projects',
      'readMore':
        'Read more',
      'title':
        'Project'
    },
    'modelsSection': {
      'name':
        'Models',
      'productInfo': {
        'priceSection': {
          'taxText':
            'Incl. VAT',
          'fromText':
            'from'
        },
        'popularDesigns': {
          'title':
            'Select your design'
        },
        'actions': {
          'configureChairButton':
            'Create your own',
          'askForPriceButton':
            'Ask for price',
          'goToCheckoutButton':
            'Add to cart',
          'whereToBuyButton':
            'Where to buy'
        },
        'whereToBuy': {
          'text': 'We sell our products through a network of authorised partners.',
          'designCustomChairText': 'However, if you´d like to create a bespoke chair please design your own by clicking above and  we´ll come back to you with a price'
        }
      },
      'productModelSelector': {
        'heading': 'Choose your model'
      },
      'modelViewer': {
        'hint':
          'Click on image to open zoom mode'
      },
    },
    'productModelDetailsSection': {
      'assetsDownloader': {
        'downloadAllButton':
          'Download all',
        'other':
          'Other',
        'categories':
          {},
      },
      'downloadsSectionTitle':
        'Downloads',
      'environmentalDataSection': {
        'averagedValuesDisclaimerText':
          'All values are average for this model, for some products the numbers might differ. The values presented are from product stage (A1-A3) and are based on available EPDs per product, either the +A1:2013 or +A2:2019 standard, and will continuously be updated according to new declarations.',
        'energyUsageMjText':
          'Energy usage',
        'co2EmissionKgText':
          'Carbon dioxide emission',
        'recyclableMaterialsText':
          'recyclable materials',
        'recycledAluminiumText':
          'recycled aluminium',
        'recycledMaterialsTitle':
          'Ratio of recycled materials',
        'recycledMaterialsText':
          'recycled materials',
        'recycledPlasticText':
          'recycled plastic',
        'recyclableMaterialsTitle':
          'Recycling rate',
      },
      'environmentalDataSectionTitle':
        'Environmental data',
      'name':
        'Details',
      'specificationSectionTitle':
        'Specification',
    },
    'suggestedConfigurationsSection': {
      'name':
        'Suggested configurations',
      'title':
        'Prefer to design your own?',
      'subtitle':
        'Customise your perfect chair, choosing your own colours, fabrics, materials, and accessories.',
      'buttonText':
        'Create your own'
    },
    'gallerySection': {
      'name':
        'Gallery'
    },
    'userGuideVideoSection': {
      'name': '',
      'videoTitle': 'User demo'
    }
  },
  'viewerPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Viewer'
  },
  'zoomModal': {
    'closeButton':
      'Close'
  },
  'common': {
    'chairVisualization': {
      'zoomHintText':
        'Tap to zoom in/zoom out',
      'hintText':
        'Click on image to open zoom mode',
      renderingError:
        'Sorry, we are not able to load visualisation for this configuration.',
      webGLUnsupportedError:
        'Sorry, we are not able to load visualisation, because WebGL is not supported in your browser.',
    },
    'tags': {
      'clearResults':
        'Remove all'
    }
  },
  'catalogPage': {
    'pageTitleFormat':
      'Products',
    'pageDescriptionFormat':
      'Find your product ',
    'filterBar': {
      'name':
        'Filters',
      'results':
        'All results: {results}',
    },
    'configurationFinder': {
      'codeWithIncorrectLengthFormat':
        'Value should have exact {expectedLength} chars.',
      'codeInvalid':
        'Provided code {invalidCode} is invalid! Ensure that it was entered correctly.',
      'codeNotFound':
        'Unable to find product configuration by code {notFoundCode}',
    },
    'productFamily': {
      'showMoreProducts':
        'More options available'
    },
    'searchForConfigurationText': 'Already made a design? Find your configuration',
    'noResults':
      'There are no products matching your preferences.',
    'pagination': {
      'title': 'Pagination'
    },
  },
  'disclaimer': {
    'messagePart1': 'The appearance of the color depends on your device or screen setup. ',
    'link': 'Contact us',
    'messagePart2': ' if you need help choosing the right colour and/or texture',
  },
  'requestQuoteLink': {
    'link': 'Buying for a company? Request a quote here.'
  },
  'unauthorizedErrorPage': {
    'pageDescriptionFormat':
      'Description',
    'pageTitleFormat':
      'Unauthorized',
    'title':
      'Unauthorized',
    'content':
      'Access is allowed only for registered users.',
    'buttonLink':
      'Login'
  },
  'forbiddenErrorPage': {
    'pageDescriptionFormat':
      'You do not have necessary permissions to access this page.',
    'pageTitleFormat':
      'Forbidden',
    'title':
      'Oh, no!',
    'content1':
      'You do not have the necessary permissions to access this page. You can ',
    'logout':
      'log out',
    'content2':
      ' and try another account or contact Flokk support.',
    'buttonLink':
      'Take me home'
  },
  'downloadsPage': {
    'downloadList': {
      'download': {
        'downloadButton': 'Download'
      },
      'emptyScreen': {
        'title': 'There are no files matching your criteria.',
        'description': 'Sorry, we don’t seem to have what you are looking for. Please try different filters.',
      },
      'results': 'There are {results} files matching you criteria.',
      'pagination': {
        'title': 'Pagination'
      }
    },
    'filters': {
      'title': 'Filters',
      'brandsFilter': {
        'name': 'Popular brands'
      },
      'categoryFilter': {
        'name': 'Category',
        'fileTypeFilter': {
          'name': 'File type'
        },
        'resultsCounter': 'All results'
      },
      'downloadAllButton': 'Download all',
    },
    'pageTitleFormat': 'Downloads',
    'pageDescriptionFormat': 'Find and download product resources, such as fact sheets, images, CADs etc.',
  },
  'readyMadeProductPage': {
    'overviewSection': {
      'name': 'Overview'
    },
    'variationsSection': {
      'name': 'Variations'
    },
    'specificationSection': {
      'name': 'Details'
    },
    'productDetailsSection': {
      'specificationSectionTitle':
        'Specification',
      'maintenanceSectionTitle':
        'Maintenance',
      'downloadsSectionTitle':
        'Downloads',
      'assetsDownloader': {
        'downloadAllButton':
          'Download all',
        'other':
          'Other',
        'categories':
          {},
      },
      'productCare': {
        'downloadGuide':
          'Download guide here',
      },
    },
    'productVariations': {
      'addToCartButton':
        'Add to cart',
      'buyButton':
        'Buy',
      'deliveryTime': {
        'deliveryTime': 'Expected delivery',
        'day': 'day',
        'days': 'days*',
        'week': 'week',
        'weeks': 'weeks*'
      },
      'priceIncludingTax':
        'Incl. Tax',
      'deliveryTimeAnnotation':
        '* Delivery time may be longer for some configurations',
      'modelViewer': {
        'hint':
          'Click on image to open zoom mode'
      },
      'customizationSection': {
        'textLine1':
          'Looking for something different?',
        'textLine2':
          'You can',
        'linkText':
          'customise your choice here',
      },
      'productOptions': {
        'fabricInfo': {
          'text': 'Fabric information:',
          optionDescriptionModal,
        },
        'fabricDescriptionCannotBeLoadedError':
          'Sorry, something went wrong. Cannot load the information about the fabric.',
      },
    },
    'productCarousel': {
      'title':
        'Others have also looked at these chairs'
    },
  },
};

export default translation;
