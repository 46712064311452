import classNames from 'classnames';

import { RequestQuoteLinkTranslation } from '../../localization/SiteTranslation';
import Link, { LinkProps } from '../../shared/components/Link';
import { StoreUrl } from '../../shared/StoreUrl';
import css from './RequestQuoteLink.css';

interface RequestQuoteLinkProps extends LinkProps {
  className?: string;
  linkClassName?: string;
  translation: RequestQuoteLinkTranslation;
  configurationCode: string;
  disabled?: boolean;
}

const RequestQuoteLink = (props: RequestQuoteLinkProps) => {
  const { className, linkClassName, translation, configurationCode, disabled, ...otherProps } = props;

  const link = StoreUrl.requestQuoteUrl({ configurationCode: configurationCode });

  return (
    <div className={classNames(css.RequestQuoteLink, className)}>
      <Link to={link} className={classNames(css.link, linkClassName, disabled && css.disabled)} {...otherProps}>
        {' '}
        {translation.link}
      </Link>
    </div>
  );
};

export default RequestQuoteLink;
