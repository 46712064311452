import { action, makeObservable, observable, override } from 'mobx';

import AppSettings from '../../../app/AppSettings';
import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { IApiClient } from '../../../app/data/client';
import {
  DaysRangeData,
  GetWebShopCmsContentQuery,
  GetWebShopProductDetailsQuery,
  ImageData,
  MoneyData,
  ShoppingContext,
  WebShopOptionGroupData,
  WebShopPageData,
  WebShopProductDetailsData,
  WebShopProductFamilyData,
} from '../../../app/data/model';
import { ReadyMadeProductPageTranslation } from '../../../app/localization/SiteTranslation';
import { BasePageState } from '../../../app/shared/BasePageState';
import { PageMetadata } from '../../../app/shared/common';
import { AssetsDownloaderState } from '../../../app/shared/components/AssetsDownloader/AssetsDownloaderState';
import { IImagePreloader } from '../../../app/shared/ImagePreloader';
import { INavigationService } from '../../../app/shared/NavigationService';
import { StoreUrl } from '../../../app/shared/StoreUrl';
import { AbsoluteUrl, RelativeUrl } from '../../../app/shared/Url';
import { StoreState } from '../../../app/StoreState';
import ProductVariationsState from './components/ProductVariations/ProductVariationsState';

type WebShopProductPageData = Pick<WebShopPageData, 'teaser'>;

interface IProductPageMemento {
  shoppingContext: ShoppingContext;
  pageContent: WebShopProductPageData;
  product: WebShopProductDetailsData;
}

export interface ProductAvailability {
  quantityAvailable: number;
  price: MoneyData;
  delivery?: DaysRangeData;
}

export interface AvailableConfiguration {
  code: string;
  options: string[];
  productAvailability: ProductAvailability;
}

export interface ProductOverview {
  id: string;
  name: string;
  url: RelativeUrl;
  image: ImageData;
}

export class ProductPageState extends BasePageState<IProductPageMemento> {
  public pageContent?: WebShopProductPageData;
  public product: WebShopProductDetailsData;
  public options: WebShopOptionGroupData[];
  public configurations: AvailableConfiguration[];
  public configuratorState: ProductVariationsState;

  @observable.ref
  public shoppingContext: ShoppingContext;

  @observable.ref
  public productFamily: WebShopProductFamilyData;

  @observable.ref
  public assetsDownloaderState: AssetsDownloaderState;

  @observable
  public id: string;

  @observable
  public guideUrl: string;

  @observable.ref
  public maintenance: { title: string; description: string }[];

  @observable
  public specification: string;

  @observable
  public relatedProducts: ProductOverview[];

  public constructor(
    private readonly settings: AppSettings,
    private readonly productId: string,
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    public readonly translation: ReadyMadeProductPageTranslation,
    public imagePreloader: IImagePreloader,
  ) {
    super();

    makeObservable(this);

    this.assetsDownloaderState = new AssetsDownloaderState(
      client,
      navigation,
      translation.productDetailsSection.assetsDownloader,
    );
    this.guideUrl = '';
    this.id = productId;
    this.maintenance = [];
    this.specification = '';

    this.options = [];
    this.configurations = [];
    this.relatedProducts = [];
  }

  @override
  get metadata(): PageMetadata {
    const title = this.product.name;
    const description = this.product.description;
    const url = AbsoluteUrl.parse(this.settings.baseUrl)
      .append(
        StoreUrl.webShopProductPageUrl(this.productId, { code: this.configuratorState.selectedConfigurationCode }),
      )
      .toString();

    const imageUrl = this.configuratorState.modelViewer.active?.data.url;

    return { title, description, url, imageUrl };
  }

  @action
  public async onLoad(store: StoreState) {
    const webShopCmsContentResponse = await this.client.send(
      new GetWebShopCmsContentQuery({ shoppingContext: store.shoppingContext }),
    );

    const webShopProductDetailResponse = await this.client.send(
      new GetWebShopProductDetailsQuery({
        productId: this.productId,
        shoppingContext: store.shoppingContext,
      }),
    );

    this.initialize(webShopCmsContentResponse.pageContent, webShopProductDetailResponse.product, store.shoppingContext);
  }

  public async onLoadAdditionalData() {
    await this.configuratorState.modelViewer.finishPreloadingVisualizationImages();
  }

  public getMemento(): IProductPageMemento {
    return {
      shoppingContext: this.shoppingContext,
      pageContent: this.pageContent,
      product: this.product,
    };
  }

  public restoreMemento(memento: IProductPageMemento) {
    this.initialize(memento.pageContent, memento.product, memento.shoppingContext);
  }

  private initialize(content: WebShopPageData, product: WebShopProductDetailsData, shoppingContext: ShoppingContext) {
    this.pageContent = content;
    this.product = product;

    this.productFamily = product.productFamily;

    this.shoppingContext = shoppingContext;

    this.assetsDownloaderState.setAssets(product.name, product.assets);

    this.relatedProducts = product.relatedProducts.map<ProductOverview>((x) => ({
      id: x.id,
      image: {
        name: x.name,
        url: FinalVisualizationBuilder.buildUrl(x.code, 'front', 'png', 290, 350),
      },
      name: x.name,
      url: StoreUrl.webShopProductPageUrl(x.productId, { code: x.code }),
    }));

    this.options = product.optionGroups;

    this.configurations = product.variations.map<AvailableConfiguration>((variation) => ({
      code: variation.configurationCode,
      options: variation.optionsIds,
      productAvailability: { price: variation.price, delivery: variation.deliveryTime, quantityAvailable: 5 }, // TODO: get from backend in case we track inventory
    }));

    this.configuratorState = new ProductVariationsState(
      this.client,
      this.navigation,
      this.shoppingContext,
      this.translation.productVariations,
      this.configurations,
      this.product,
      this.options,
      this.imagePreloader,
    );

    this.specification = product.specification;
  }
}
