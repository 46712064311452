import objectFitImages from 'object-fit-images';
import * as React from 'react';
import 'react-dates/initialize';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import Cookies from 'universal-cookie';

import './../polyfils';

import App from '../app/App';
import AppSettings from '../app/AppSettings';
import { IAppMemento } from '../app/AppState';
import { AuthenticationService, FakeAuthenticationService } from '../app/auth/AuthenticationService';
import { IpAddressProvider } from '../app/data/IpAddressProvider';
import BrowserUserLanguageProvider from '../app/data/StoreProviders/BrowserPreferredStoreProvider';
import CookiePreferredStoreProvider from '../app/data/StoreProviders/CookiePreferredStoreProvider';
import PreferredStoreProviderComposite from '../app/data/StoreProviders/PreferredStoreProviderComposite';
import { WarningLocalStorage } from '../app/layout/Warnings/WarningLocalStorage';
import { RootProps } from '../app/root';
import { Analytics } from '../app/shared/analytics/Analytics';
import { GoogleTagManager } from '../app/shared/analytics/GoogleTagManager/GoogleTagManager';
import { GoogleTrackingService } from '../app/shared/analytics/GoogleTagManager/GoogleTrackingService';
import { isBrowser } from '../app/shared/common';
import { ImagePreloader } from '../app/shared/ImagePreloader';
import { KeyboardOnlyFocusListener } from '../app/shared/KeyboardOnlyFocusListener';
import { EmptyMonitoringService } from '../app/shared/monitoring/IMonitoringService';
import { Monitoring } from '../app/shared/monitoring/Monitoring';
import { MonitoringService } from '../app/shared/monitoring/MonitoringService';
import { PageViewTrackingProxy } from '../app/shared/monitoring/PageViewTrackingProxy';
import { Navigation, NavigationService } from '../app/shared/NavigationService';
import { AppStateFactory } from './AppStateFactory';
import { CustomerContextLocalStorageFactory } from './CustomerContextLocalStorageFactory';
import registerComponentsInRegions from './registerComponentsInRegions';
import { routes } from './routing/routes';
import Shell from './Shell';
import { StoreInitializationStrategy } from './StoreInitializationStrategy';

// tslint:disable:ordered-imports
import '../styles/index.css';

smoothscroll.polyfill();
objectFitImages();
KeyboardOnlyFocusListener.initialize();

const render = async (
  rootElement: React.ComponentClass<RootProps>,
  appProps: RootProps,
  container = document.getElementById('App'),
) => {
  const root = createRoot(container);
  root.render(React.createElement(rootElement, appProps));
  await appProps.app.updateLocation();
};

(async () => {
  let appMemento: IAppMemento = null;

  if (isBrowser()) {
    appMemento = (window as unknown as { __PRELOADED_STATE__: IAppMemento }).__PRELOADED_STATE__;
  }

  let appSettings = appMemento ? appMemento.settings : new AppSettings();

  const googleTrackingService = new GoogleTrackingService(new GoogleTagManager());
  Analytics.addService(googleTrackingService);

  const authenticationService = appSettings.authSettings
    ? await AuthenticationService.create(appSettings.authSettings)
    : new FakeAuthenticationService();
  const monitoringService = appSettings.instrumentationKey
    ? new MonitoringService(appSettings.instrumentationKey)
    : new EmptyMonitoringService();
  Monitoring.addService(monitoringService);

  const navigationService = new PageViewTrackingProxy(new NavigationService(document.referrer));
  Navigation.setService(navigationService);

  const imagePreloader = new ImagePreloader();
  const cookies = new Cookies();
  const preferredStore = new PreferredStoreProviderComposite([
    new CookiePreferredStoreProvider(cookies),
    new BrowserUserLanguageProvider(),
  ]);
  const ipAddressProvider = new IpAddressProvider(null);

  let props = {
    app: new App(
      appSettings,
      CustomerContextLocalStorageFactory.createForCookies(),
      WarningLocalStorage.inCookies(),
      navigationService,
      imagePreloader,
      preferredStore,
      ipAddressProvider,
      {},
      routes,
      authenticationService,
      new AppStateFactory(),
      new StoreInitializationStrategy(),
    ),
  };

  registerComponentsInRegions(props.app.regionManager);

  render(Shell, props);

  if (module.hot) {
    module.hot.accept();
  }
})();
