import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';
import { DownloadsPageQuery } from './DownloadsPageQuery';

const SEPARATOR = ',';

export class DownloadsPageQueryParser extends QueryParser {
  static toModel(query: Map<string, string>) {
    const queryModel = new DownloadsPageQuery();

    for (const [key, value] of query) {
      query.set(key, decodeURIComponent(value));
    }

    queryModel.brands = QueryParser.getValues(query, nameOf<DownloadsPageQuery>('brands'), []);
    queryModel.category = QueryParser.getValue(query, nameOf<DownloadsPageQuery>('category'), '');
    queryModel.type = QueryParser.getValue(query, nameOf<DownloadsPageQuery>('type'), '');

    const page = parseInt(QueryParser.getValue(query, nameOf<DownloadsPageQuery>('page'), '1'), 10);

    if (!isNaN(page)) {
      queryModel.page = page;
    }

    return queryModel;
  }

  static toQuery(queryModel: DownloadsPageQuery) {
    const query = new Map<string, string>();

    Object.entries(queryModel).forEach(([name, value]) => {
      if (Array.isArray(value)) {
        if (value.any()) {
          QueryParser.setValue(query, name, encodeURIComponent(value.join(SEPARATOR)));
        }
      } else if (name !== nameOf<DownloadsPageQuery>('page') || value > 1) {
        QueryParser.setValue(query, name, encodeURIComponent(value));
      }
    });

    return query;
  }
}
