import { EmbeddedQueryParser } from '../EmbeddedQueryParser';
import { QueryParser } from '../QueryParser';
import { ProductConfigurationQuery } from './ProductConfigurationQuery';

export class ProductConfigurationQueryParser extends QueryParser {
  public static CONFIGURATION_CODE = 'code';
  public static PRODUCT_ID = 'model';

  static toQuery(model: ProductConfigurationQuery) {
    const result = new Map<string, string>();
    if (model.configurationCode) {
      ProductConfigurationQueryParser.setValue(
        result,
        ProductConfigurationQueryParser.CONFIGURATION_CODE,
        model.configurationCode,
      );
    } else if (model.productId) {
      ProductConfigurationQueryParser.setValue(result, ProductConfigurationQueryParser.PRODUCT_ID, model.productId);
    }
    return result;
  }

  static toModel(query: Map<string, string>): ProductConfigurationQuery {
    const model: ProductConfigurationQuery = {};

    if (query.has(ProductConfigurationQueryParser.CONFIGURATION_CODE)) {
      model.configurationCode = QueryParser.getValue(query, ProductConfigurationQueryParser.CONFIGURATION_CODE);
    } else if (query.has(ProductConfigurationQueryParser.PRODUCT_ID)) {
      model.productId = QueryParser.getValue(query, ProductConfigurationQueryParser.PRODUCT_ID);
    }

    const embeddedQueryModel = EmbeddedQueryParser.toModel(query);

    return { ...embeddedQueryModel, ...model };
  }
}
