import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { SuggestedConfigurationsTranslation } from '../../../localization/SiteTranslation';
import Image from '../../../shared/components/Image/Image';
import Link from '../../../shared/components/Link';
import HeaderLevel2 from '../../../shared/components/Typography/HeaderLevel2';
import { AbsoluteUrl } from '../../../shared/Url';
import { VisualizationAspectRatio } from '../../../shared/visualization';
import css from './SuggestedConfigurations.css';
import SuggestedConfigurationsState, { SuggestedConfiguration } from './SuggestedConfigurationsState';

interface SuggestedConfigurationsProps {
  state: SuggestedConfigurationsState;
  translation: SuggestedConfigurationsTranslation;
}

@observer
export default class SuggestedConfigurations extends Component<SuggestedConfigurationsProps, {}> {
  constructor(props: SuggestedConfigurationsProps) {
    super(props);

    this.renderImage = this.renderImage.bind(this);
  }

  renderImage(model: SuggestedConfiguration, key: number) {
    const imageUrlWithBackground = AbsoluteUrl.parse(model.image.url);
    imageUrlWithBackground.relative.setQueryParameter('background', 'f8f7f6');

    return (
      <Link
        onClick={() => this.props.state.onClick(model)}
        key={model.image.code + model.image.shot}
        className={css.link}
      >
        <Image
          className={classNames(css.image, {
            [css.wide]: model.image.aspectRatio === VisualizationAspectRatio.Wide,
          })}
          classNameUnloaded={css.unloaded}
          alt={model.image.name}
          src={imageUrlWithBackground.toString()}
          width={model.image.size.width}
          height={model.image.size.height}
        />
      </Link>
    );
  }

  render() {
    const {
      state: { models },
      translation,
    } = this.props;

    if (!models.any()) {
      return null;
    }

    return (
      <div className={css.SuggestedConfigurations}>
        <div className={css.container}>
          <HeaderLevel2 className={css.title}>{translation.title}</HeaderLevel2>
          <p className={css.description}>{translation.subtitle}</p>
        </div>
        <div className={css.list}>{models.map(this.renderImage)}</div>

        {/* Hide "Design your chair" button - for now click on the picture of chair */}
        {/*<Button>{translation.buttonText}</Button>*/}
      </div>
    );
  }
}
