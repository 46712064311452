import NavigationState from '../layout/NavigationState';
import { ConfirmationPageTranslation } from '../localization/SiteTranslation';
import { HubSpotFormConfirmation } from '../shared/hubSpot/Confirmation/HubSpotFormConfirmation';

interface RequestQuoteConfirmationProps {
  isEmbedded: boolean;
  navigation: NavigationState;
  translation: ConfirmationPageTranslation;
}

export const RequestQuoteConfirmation = ({ isEmbedded, navigation, translation }: RequestQuoteConfirmationProps) =>
  HubSpotFormConfirmation({ translation: translation, buttonLink: navigation.homeUrl, isEmbedded });
