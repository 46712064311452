import * as React from 'react';

import { ShoppingContext } from '../data/model';

export const GlobalShoppingContext = React.createContext<ShoppingContext | null>(null);

interface ShoppingProviderProps {
  children: React.ReactNode;
  shoppingContext: ShoppingContext;
}

export class ShoppingProvider extends React.Component<ShoppingProviderProps> {
  constructor(props: ShoppingProviderProps) {
    super(props);
  }

  render() {
    return (
      <GlobalShoppingContext.Provider value={this.props.shoppingContext}>
        {this.props.children}
      </GlobalShoppingContext.Provider>
    );
  }
}
