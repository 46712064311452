import { WebShopOptionGroupData } from '../../../../../../app/data/model';
import Event from '../../../../../../app/shared/Event';
import ProductOptionVariationState from './ProductOptionVariation/ProductOptionVariationState';

class ProductOptionState {
  public id: string;
  public name: string;
  public isFabric: boolean;
  public onActiveVariationChange: Event<ProductOptionVariationState>;
  public variations: ProductOptionVariationState[];

  public constructor(optionsGroup: WebShopOptionGroupData) {
    this.id = optionsGroup.optionId;
    this.name = optionsGroup.name;
    this.isFabric = optionsGroup.isFabric;
    this.onActiveVariationChange = new Event<ProductOptionVariationState>();
    this.variations = this.getVariations(optionsGroup);
    this.setInitialActiveVariation();
  }

  public get activeVariation() {
    return this.variations.find((variation) => variation.isActive);
  }

  public setActiveVariation = (variationToActivate: ProductOptionVariationState, shouldRaiseEvent: boolean = true) => {
    this.variations.forEach((variation) =>
      variation === variationToActivate ? variation.activate() : variation.deactivate(),
    );

    if (shouldRaiseEvent) {
      this.onActiveVariationChange.raise(variationToActivate);
    }
  };

  private getVariations(optionsGroupData: WebShopOptionGroupData) {
    return optionsGroupData.options.map((option) => new ProductOptionVariationState(option));
  }

  private setInitialActiveVariation() {
    this.setActiveVariation(this.variations.first());
  }
}

export default ProductOptionState;
