import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { OptionType } from '../../data/model';
import { ConfiguratorPageTranslation } from '../../localization/SiteTranslation';
import { IFeature } from '../../shared/common';
import TextButton from '../../shared/components/Button/TextButton';
import { TextLabelStrong } from '../../shared/components/Typography/TextLabels';
import { FabricOptionState } from '../FabricOptionState';
import { OptionState } from '../OptionState';
import Accessory from './AccessoriesConfigurator/Accessory/Accessory';
import { AccessoryState } from './AccessoriesConfigurator/AccessoryState';
import Fabrics from './Fabrics';
import OptionAffectingPrice from './OptionAffectingPrice';
import OptionNotAffectingPrice from './OptionNotAffectingPrice';
import css from './OptionsConfigurator.css';

interface OptionsConfiguratorProps {
  translation: ConfiguratorPageTranslation;
  features: Array<IFeature>;
  brandId: string;
}

interface OptionsConfiguratorState {
  shouldDisplayFabricFilters: boolean;
}

@observer
class OptionsConfigurator extends Component<OptionsConfiguratorProps, OptionsConfiguratorState> {
  public constructor(props: OptionsConfiguratorProps) {
    super(props);

    this.state = {
      shouldDisplayFabricFilters: false,
    };
  }

  public toggleFiltersVisibility = () => {
    this.setState((state) => ({ shouldDisplayFabricFilters: !state.shouldDisplayFabricFilters }));
  };

  public renderOption(feature: IFeature) {
    const { type } = feature;
    const { translation, brandId } = this.props;

    if (feature.type === OptionType.Accessory) {
      return (
        <Accessory
          key={feature.id}
          accessory={feature as AccessoryState}
          translation={translation.accessories.accessory}
          className={!feature.enabled && css.hide}
        />
      );
    }

    return (
      <div key={feature.name} className={classNames(css.OptionsConfigurator, !feature.enabled && css.hide)}>
        <div className={css.header}>
          <TextLabelStrong className={css.name}>{feature.name}</TextLabelStrong>
          {type === OptionType.Fabric && (feature as FabricOptionState).anyFilterDefined && (
            <TextButton className={css.buttonFilters} onClick={this.toggleFiltersVisibility}>
              {this.state.shouldDisplayFabricFilters ? translation.hideFiltersText : translation.showFiltersText}
            </TextButton>
          )}
        </div>
        {type === OptionType.NotAffectingPrice && <OptionNotAffectingPrice option={feature as OptionState} />}
        {type === OptionType.AffectingPrice && (
          <OptionAffectingPrice translation={translation.options} option={feature as OptionState} />
        )}
        {type === OptionType.Fabric && (
          <Fabrics
            translation={translation.options}
            option={feature as FabricOptionState}
            shouldDisplayFilters={this.state.shouldDisplayFabricFilters}
            brandId={brandId}
          />
        )}
      </div>
    );
  }

  public render() {
    const { features } = this.props;
    return <Fragment>{!features.empty() && features.map((x) => this.renderOption(x))}</Fragment>;
  }
}

export default OptionsConfigurator;
