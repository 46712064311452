import classNames from 'classnames';
import { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import css from './CustomScrollbar.css';

interface CustomScrollbarProps {
  children: ReactNode;
  autoHeight?: boolean;
  autoHeightMax?: number;
  className?: string;
  trackClassName?: string;
  viewClassName?: string;
}

const CustomScrollbar = ({
  children,
  className,
  trackClassName,
  viewClassName,
  autoHeightMax,
  autoHeight = false,
}: CustomScrollbarProps) => {
  return (
    <Scrollbars
      className={classNames(css.container, className)}
      autoHeight={autoHeight}
      autoHeightMax={autoHeightMax}
      renderTrackVertical={(props) => <div {...props} className={classNames(css.trackY, trackClassName)} />}
      renderThumbVertical={(props) => <div {...props} className={css.thumbY} />}
      renderView={(props) => <div {...props} className={viewClassName} />}
      universal
    >
      {children}
    </Scrollbars>
  );
};

export default CustomScrollbar;
