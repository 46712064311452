import { observer } from 'mobx-react';
import React from 'react';
import { ReactElement } from 'react';

import { ObsoleteCodeModalTranslation, ObsoleteConfigurationTranslation } from '../../../localization/SiteTranslation';
import ThreeDotsLoader from '../../../shared/components/Loaders/ThreeDotsLoader';
import Modal from '../../../shared/components/Modal/Modal';
import HeaderLevel3 from '../../../shared/components/Typography/HeaderLevel3';
import { ILoadingIndicator } from '../../../shared/LoadingIndicator';
import ObsoleteComponentsMap from './ObsoleteComponentsMap';
import css from './ObsoleteConfigurationModal.css';
import { ObsoleteConfigurationModalState } from './ObsoleteConfigurationModalState';

export const ObsoleteConfigurationModalContent = (props: {
  code?: string;
  translation: ObsoleteConfigurationTranslation;
  body?: ReactElement;
  bodyLoadingIndicator?: ILoadingIndicator;
}) => {
  const { code, translation, body, bodyLoadingIndicator } = props;
  const isLoadingBody = bodyLoadingIndicator?.isLoading;

  return (
    <>
      <HeaderLevel3>{translation.title}</HeaderLevel3>
      <br />
      {isLoadingBody ? (
        <div className={css.loaderWrapper}>
          <ThreeDotsLoader size={'medium'} />
        </div>
      ) : (
        <div>
          {code && (
            <>
              {translation.introWithCodePart1} <strong>{code}</strong> {translation.introWithCodePart2}
            </>
          )}
          {!code && <>{translation.intro}</>} {translation.explanation}
          {body}
        </div>
      )}
    </>
  );
};

interface ObsoleteConfigurationModalProps {
  modal: ObsoleteConfigurationModalState;
  translation: ObsoleteCodeModalTranslation;
}

export const ObsoleteConfigurationModal = observer((props: ObsoleteConfigurationModalProps) => {
  const { modal, translation } = props;

  return (
    <Modal modal={modal} cancelButtonName={translation.closeButton}>
      {modal.missingComponentsIds.any() && (
        <ObsoleteConfigurationModalContent
          code={modal.code}
          translation={translation.notAvailable}
          body={
            <>
              <ObsoleteComponentsMap obsoleteComponents={modal.featuresVariationsDisplayNames} /> {translation.footer}
            </>
          }
          bodyLoadingIndicator={modal.loadingIndicator}
        />
      )}
      {modal.invalidConfiguration && (
        <ObsoleteConfigurationModalContent
          code={modal.code}
          translation={translation.restricted}
          body={<> {translation.footer}</>}
        />
      )}
    </Modal>
  );
});

export default ObsoleteConfigurationModal;
