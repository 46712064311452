import classNames from 'classnames';
import * as React from 'react';

export interface ITab {
  name: string;
  id: string;
  isSelected: boolean;
  panelId: string;
}

interface TabProps extends ITab {
  children: React.ReactNode;
  onClick: (name: string) => void;
  className?: string;
  selectedTabClassName?: string;
}

const Tab = React.forwardRef<HTMLAnchorElement, TabProps>(
  ({ children, className, id, isSelected, name, onClick, panelId, selectedTabClassName }, ref) => {
    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      event.preventDefault();
      onClick(name);
    };

    return (
      <a
        ref={ref}
        role="tab"
        id={id}
        className={classNames(className, { [selectedTabClassName]: isSelected })}
        href={`#${panelId}`}
        aria-selected={isSelected}
        onClick={handleClick}
        tabIndex={isSelected ? 0 : -1}
      >
        {children}
      </a>
    );
  },
);

export default Tab;
