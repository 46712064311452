export abstract class BaseRequestTelemetryCollector {
  protected startTime: Date;
  protected endTime: Date;
  protected resultCode: number;

  protected get duration() {
    return this.endTime.getTime() - this.startTime.getTime();
  }

  protected get success() {
    return this.resultCode < 400;
  }

  start() {
    this.startTime = new Date();
    return this;
  }

  stop(resultCode: number) {
    this.endTime = new Date();
    this.resultCode = resultCode;
    return this;
  }

  constructor() {
    this.startTime = new Date();
    this.endTime = new Date();
    this.resultCode = 200;
  }
}
