import { Storyboard } from '../../../shared/animations';
import { PriceAnimations } from './AnimatedPrice';

export class AnimatedPriceState {
  animations: PriceAnimations = new PriceAnimations();

  show: () => Storyboard;
  hide: () => Storyboard;

  constructor() {
    const { show, hide } = this.animations;
    this.show = () => new Storyboard().append(0, show, [hide]);
    this.hide = () => new Storyboard().append(0, hide, [show]);
  }
}
