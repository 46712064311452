import { isBrowser } from '../shared/common';

export interface ScrollPosition {
  scrollX: number;
  scrollY: number;
}

export default class WindowScroll {
  public static getPosition(): ScrollPosition {
    return isBrowser() ? { scrollX: window.pageXOffset, scrollY: window.pageYOffset } : { scrollX: 0, scrollY: 0 };
  }

  public static setScrollY(scrollY: number) {
    if (isBrowser()) {
      window.scrollBy(0, scrollY);
    }
  }
}
