import classNames from 'classnames';
import * as React from 'react';

import { CategoryFilterTranslation } from '../../../../localization/SiteTranslation';
import Icon from '../../../../shared/components/Icon/Icon';
import { ScrollLock } from '../../../../shared/components/ScrollLock/ScrollLock';
import ResultsCounter from '../../ResultsCounter/ResultsCounter';
import { ICategoryFilterOptionState, ICategoryFilterState } from '../DownloadsFiltersState';
import FilterButton from '../FilterButton/FilterButton';
import css from './FilterDropdown.css';
import FilterDropdownSubgroup from './FilterDropdownSubgroup/FilterDropdownSubgroup';
import FilterDropdownToggle from './FilterDropdownToggle/FilterDropdownToggle';

interface FilterDropdownProps
  extends Omit<ICategoryFilterState, 'isTypeSelected'>,
    Pick<React.AriaAttributes, 'aria-controls'> {
  onSelect: () => void;
  resultsCounter: number;
  translation: CategoryFilterTranslation;
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
  fullName,
  onSelect,
  options,
  resultsCounter,
  selection,
  translation,
  ...aria
}) => {
  const [openSubgroupId, setOpenSubgroupId] = React.useState<string | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const toggle = () => setIsOpen((state) => !state);

  const openSubgroup = (id: string) => setOpenSubgroupId(id);
  const closeSubgroup = () => setOpenSubgroupId(null);

  const renderItem = (item: ICategoryFilterOptionState) => {
    const handleClick = (): void => {
      if (item.types) {
        openSubgroup(item.value);
      } else {
        closeSubgroup();
      }
    };

    const handleSelect = (): void => {
      if (!item.types || item.types.empty()) {
        toggle();
        selection.select(item);
        onSelect();
      }
    };

    return (
      <React.Fragment key={item.value}>
        <FilterButton
          value={item.value}
          name="category"
          isActive={item.isActive(selection.value)}
          onClick={handleClick}
          onSelect={handleSelect}
          disabled={openSubgroupId !== null}
        >
          {item.name}
          {item.types?.any() && <Icon className={css.icon} name="angleRight" />}
        </FilterButton>

        {item.types?.any() && (
          <FilterDropdownSubgroup
            selection={selection.typeSelection}
            filterName={item.name}
            isOpen={openSubgroupId === item.value}
            items={item.types}
            translation={translation.fileTypeFilter}
            onBackButtonClick={closeSubgroup}
            onItemClick={() => {
              selection.select(item);
              onSelect();
              toggle();
            }}
            {...aria}
          />
        )}
      </React.Fragment>
    );
  };

  return (
    <ScrollLock isLocked={isOpen}>
      <div className={classNames(css.FilterDropdown, { [css.open]: isOpen })}>
        <div className={css.bar}>
          <FilterDropdownToggle isOpen={isOpen} onClick={toggle}>
            {fullName}
          </FilterDropdownToggle>
          <ResultsCounter translation={translation.resultsCounter} value={resultsCounter} />
        </div>

        <div
          className={classNames(css.items, { [css.subgroupOpen]: openSubgroupId !== null })}
          role="radiogroup"
          aria-label={translation.name}
          {...aria}
        >
          {options.map(renderItem)}
        </div>
      </div>
    </ScrollLock>
  );
};

export default FilterDropdown;
