import classNames from 'classnames';
import * as React from 'react';

import css from './Heading.css';

export enum HeadingPosition {
  Left = 'onLeft',
  Center = 'centered',
  Right = 'onRight',
}

export enum HeadingSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

interface HeadingProps {
  children: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
  position?: HeadingPosition;
  size?: HeadingSize;
  style?: React.CSSProperties;
  visuallyHidden?: boolean;
}

const Heading = ({
  children,
  className,
  level,
  size,
  style,
  visuallyHidden = false,
  position = HeadingPosition.Left,
}: HeadingProps) => {
  const classes = classNames(
    css.element,
    {
      [css[position]]: !!position,
      [css[size]]: !!size,
      visuallyHidden,
    },
    className,
  );

  return React.createElement(`h${level}`, { children, className: classes, style });
};

export default Heading;
