import classNames from 'classnames';
import { observer } from 'mobx-react';
import { cloneElement, ReactElement } from 'react';

import CustomScrollbar from '../../../../../shared/components/CustomScrollbar/CustomScrollbar';
import css from './ModularConfiguratorSection.css';

interface ModularConfiguratorSectionProps {
  children: ReactElement;
  className?: string;
  scrollable?: boolean;
}

const ModularConfiguratorSection = observer(
  ({ children, className, scrollable = false }: ModularConfiguratorSectionProps) => {
    const inner = <div className={css.inner}>{cloneElement(children, { className: css.content })}</div>;

    return (
      <div className={classNames(css.container, className, { [css.scrollable]: scrollable })}>
        {scrollable ? <CustomScrollbar>{inner}</CustomScrollbar> : inner}
      </div>
    );
  },
);

export default ModularConfiguratorSection;
