import { observer } from 'mobx-react';

import ActionButton from '../../app/shared/components/ActionButton';
import InputField from '../../app/shared/components/Input/InputField';
import Label from '../../app/shared/components/Label/Label';
import Modal from '../../app/shared/components/Modal/Modal';
import { ShoppingCartItemBasicInfo } from '../shared/ShoppingCartItemBasicInfo/ShoppingCartItemBasicInfo';
import { cartItemToPanelItem, ShoppingCartItemPanel } from '../shared/ShoppingCartItemPanel/ShoppingCartItemPanel';
import css from './EditConfigurationNameModal.css';
import { EditConfigurationNameModalState } from './EditConfigurationNameModalState';
import { FavoritesModalHeader } from './FavoritesModalHeader';

export const EditConfigurationNameModal = observer(({ state }: { state: EditConfigurationNameModalState }) => {
  if (!state.favoriteItem) {
    return null;
  }
  const translation = state.editConfigurationNameTranslation;

  return (
    <Modal modal={state} translation={{ closeButton: '' }}>
      <FavoritesModalHeader heading={translation.header} description={translation.description} />

      <ShoppingCartItemPanel
        item={cartItemToPanelItem(state.favoriteItem)}
        translation={translation.shoppingCartItemPanel}
        verticalSpacing
      >
        <ShoppingCartItemBasicInfo item={state.favoriteItem} translation={translation.shoppingCartItemBasicInfo} />
      </ShoppingCartItemPanel>

      <Label>{translation.configurationNameLabel}</Label>
      <div className={css.editConfigInputRow}>
        <InputField input={state.configurationCustomName} />
        <ActionButton command={state.updateLabelCommand}>{translation.saveButton}</ActionButton>
      </div>
    </Modal>
  );
});
