import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../app/data/client';
import { GetPageContentQuery, GetPageContentQueryResponse } from '../../app/data/model';
import { BasePageState } from '../../app/shared/BasePageState';
import { StoreState } from '../../app/StoreState';

export class ArticlePageState extends BasePageState<null> {
  @observable
  public content: string;

  public constructor(private readonly client: IApiClient, private readonly pagePath: string) {
    super();
    makeObservable(this);
  }

  public async onLoad(store: StoreState) {
    const query = new GetPageContentQuery({
      pagePath: this.pagePath,
      shoppingContext: store.shoppingContext,
    });
    const response = await this.client.send(query);
    this.setData(response);
  }

  @action.bound
  protected setData(response: GetPageContentQueryResponse) {
    this.content = response.content;
  }
}
