import { Component, ReactNode } from 'react';
import { IIdleTimer, withIdleTimer } from 'react-idle-timer';

interface IdleTimerProps extends IIdleTimer {
  children: ReactNode;
}

class IdleTimer extends Component<IdleTimerProps> {
  public render() {
    return this.props.children;
  }
}

export default withIdleTimer(IdleTimer);
