import classNames from 'classnames';

import { SelectedOptionData } from '../../../data/model';
import { Subtitle1 } from '../Typography/Subtitle';
import css from './ComponentList.css';

interface ConfigurationSummaryProps {
  accessories: SelectedOptionData[];
  options: SelectedOptionData[];
}

const ComponentList = ({ accessories, options }: ConfigurationSummaryProps) => {
  const renderItem = ({ id, name, variationCode, variationName }: SelectedOptionData, isAccessory: boolean) => {
    return (
      <div className={css.item} key={id}>
        <dt className={classNames(css.innerElement, { visuallyHidden: isAccessory })}>
          <Subtitle1>{name}</Subtitle1>
        </dt>
        <dd className={css.innerElement}>{` ${variationName} ${variationCode}`}</dd>
      </div>
    );
  };

  return (
    <dl className={css.items}>
      {options.map((x) => renderItem(x, false))}
      {accessories.map((x) => renderItem(x, true))}
    </dl>
  );
};

export default ComponentList;
