import classNames from 'classnames';
import { ReactNode } from 'react';

import Link, { LinkProps } from '../Link';
import css from './UnderlineLink.css';

interface UnderlineLinkProps extends LinkProps {
  children: ReactNode;
  dark?: boolean;
  className?: string;
}

export const UnderlineLink = ({ dark, children, className, ...props }: UnderlineLinkProps) => {
  const classes = classNames(css.UnderlineLink, dark ? css.dark : css.light, className);

  return (
    <Link className={classes} {...props}>
      {children}
    </Link>
  );
};
