import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { Breakpoint } from '../layout/ScreenObserver';
import TextButton from '../shared/components/Button/TextButton';
import CheckboxElement from '../shared/components/Checkbox/CheckboxElement';
import Icon from '../shared/components/Icon/Icon';
import List from '../shared/components/List/List';
import { FilterPropertyState, FilterState } from './CatalogFiltersState';
import css from './FilterGroup.css';

interface FilterGroupProps {
  filterGroup: FilterState;
  breakpoint: Breakpoint;
}

@observer
export default class FilterGroup extends Component<FilterGroupProps, { isOpen: boolean }> {
  filterGroupButtonRef: HTMLDivElement;

  constructor(props: FilterGroupProps) {
    super(props);
    this.state = { isOpen: false };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('mousedown', this.handleClick);
  }

  componentDidUpdate(prevProps: FilterGroupProps): void {
    if (prevProps.breakpoint !== this.props.breakpoint) {
      this.setState({ isOpen: false });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('mousedown', this.handleClick);
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  // tslint:disable-next-line: no-any
  handleClick(e: any) {
    if (this.props.breakpoint !== Breakpoint.Phone && !this.filterGroupButtonRef.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  }

  renderFilter(property: FilterPropertyState) {
    return (
      <CheckboxElement checked={property.selected} onChange={() => property.toggle()}>
        {property.data.value}
      </CheckboxElement>
    );
  }

  render() {
    const { filterGroup } = this.props;
    const { isOpen } = this.state;

    return (
      <div key={filterGroup.data.name} className={css.FilterGroup} ref={(node) => (this.filterGroupButtonRef = node)}>
        <TextButton
          className={classNames(css.filterGroupButton, isOpen && css.open)}
          childrenClassName={css.name}
          onClick={() => this.toggleOpen()}
        >
          <Fragment>
            <span>
              {filterGroup.data.displayName}
              <span className={classNames(css.selectedCount, filterGroup.selectedCount === 0 && css.hide)}>
                ({filterGroup.selectedCount})
              </span>
            </span>
            <Icon name={isOpen ? 'angleUp' : 'angleDown'} className={css.iconAngleDown} />
          </Fragment>
        </TextButton>
        {this.state.isOpen && (
          <List
            className={css.filters}
            itemClassName={css.filter}
            items={filterGroup.availableProperties}
            itemRenderer={(property) => this.renderFilter(property)}
          />
        )}
      </div>
    );
  }
}
