import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

import { ObjectState } from './ObjectState';
import { SceneMode } from './SceneMode';

type ResourceLoadingAwaiter = () => Promise<void>;

export class SceneState {
  @observable
  public mode: SceneMode = SceneMode.Scene3d;

  @observable.shallow
  public readonly objects: IObservableArray<ObjectState> = [] as IObservableArray<ObjectState>;

  private readonly resourceLoadingAwaiters: ResourceLoadingAwaiter[] = [];

  @computed
  public get selectedObjects() {
    return this.objects.filter((x) => x.selected) ?? [];
  }

  @computed
  public get is2d() {
    return this.mode === SceneMode.Scene2d;
  }

  @computed
  public get is3d() {
    return this.mode === SceneMode.Scene3d;
  }

  public constructor() {
    makeObservable(this);
  }

  @action
  public addObject(object: ObjectState) {
    this.objects.push(object);
  }

  @action
  public removeMultipleObjects(objects: ObjectState[]) {
    objects.forEach(this.removeObject);
  }

  @action
  public removeObject(object: ObjectState) {
    this.objects.remove(object);
  }

  @action.bound
  public toggleMode() {
    if (this.is2d) {
      this.set3dMode();
    } else {
      this.set2dMode();
    }
  }

  public addResourceLoadingAwaiter(awaiter: ResourceLoadingAwaiter) {
    this.resourceLoadingAwaiters.push(awaiter);
  }

  public ensureResourcesLoaded() {
    return Promise.all(this.resourceLoadingAwaiters.map((x) => x()));
  }

  private set2dMode() {
    this.setMode(SceneMode.Scene2d);
  }

  private set3dMode() {
    this.setMode(SceneMode.Scene3d);
  }

  private setMode(mode: SceneMode) {
    this.mode = mode;
  }
}
