import cn from 'classnames';

import { ConfigurationCodeShareTranslation } from '../../../../localization/SiteTranslation';
import ThreeDotsLoader from '../../../../shared/components/Loaders/ThreeDotsLoader';
import CopyButton from '../CopyButton';
import css from './ConfigurationCodeShare.css';

interface ConfigurationCodeShareProps {
  code: string;
  // TODO: Replace isCodeGenerationError and isCodeLoading with status
  isCodeGenerationError: boolean;
  isCodeLoading: boolean;
  translation: ConfigurationCodeShareTranslation;
  classNames?: {
    root?: string;
    title?: string;
    code?: string;
    button?: string;
  };
}

const ConfigurationCodeShare = ({
  classNames,
  code,
  isCodeGenerationError,
  isCodeLoading,
  translation,
}: ConfigurationCodeShareProps) => {
  return (
    <div className={classNames?.root}>
      <p className={classNames?.title}>{translation.codeTitle}</p>
      <div className={css.container}>
        <div className={cn(css.code, { [css.error]: isCodeGenerationError }, classNames?.code)}>
          {isCodeLoading ? <ThreeDotsLoader size="small" /> : code}
        </div>
        <CopyButton
          className={cn(css.buttonCopy, classNames?.button)}
          translation={translation.copyCode}
          valueToCopy={code}
          disabled={isCodeLoading}
        />
      </div>
    </div>
  );
};

export default ConfigurationCodeShare;
