import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

export const COUPON_CODE: string = nameOf<ICheckoutShortcutPageQuery>('couponCode');
export const QUANTITY: string = 'qty';

export interface ICheckoutShortcutPageQuery {
  couponCode?: string;
  quantity?: number;
}

export default class CheckoutShortcutPageQueryParser extends QueryParser {
  static toModel(query: Map<string, string>): ICheckoutShortcutPageQuery {
    const couponCode = QueryParser.getValue(query, COUPON_CODE);
    const quantity = Number.parseInt(QueryParser.getValue(query, QUANTITY), 10);

    return {
      couponCode: couponCode,
      quantity: quantity,
    };
  }
}
