import CountryCode from '../../../../shared/CountryCode';
import { ZipCodeValidation } from '../../../../shared/Form';
import { ZipCodeValidationAU } from './ValidationAU';
import { ZipCodeValidationCH } from './ValidationCH';
import { ZipCodeValidationDE } from './ValidationDE';
import { ZipCodeValidationDK } from './ValidationDK';
import { ZipCodeValidationFR } from './ValidationFR';
import { ZipCodeValidationIR } from './ValidationIR';
import { ZipCodeValidationNL } from './ValidationNL';
import { ZipCodeValidationNO } from './ValidationNO';
import { ZipCodeValidationSE } from './ValidationSE';

class ZipCodeValidationFactory {
  public static create(countryCode: string, errorMessage: string): ZipCodeValidation {
    switch (countryCode) {
      case CountryCode.Denmark:
        return new ZipCodeValidationDK(errorMessage);
      case CountryCode.Norway:
        return new ZipCodeValidationNO(errorMessage);
      case CountryCode.Sweden:
        return new ZipCodeValidationSE(errorMessage);
      case CountryCode.France:
        return new ZipCodeValidationFR(errorMessage);
      case CountryCode.Austria:
        return new ZipCodeValidationAU(errorMessage);
      case CountryCode.Germany:
        return new ZipCodeValidationDE(errorMessage);
      case CountryCode.Ireland:
        return new ZipCodeValidationIR(errorMessage);
      case CountryCode.Netherlands:
        return new ZipCodeValidationNL(errorMessage);
      case CountryCode.Switzerland:
        return new ZipCodeValidationCH(errorMessage);
      default:
        return null;
    }
  }
}

export default ZipCodeValidationFactory;
