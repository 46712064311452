import { observer } from 'mobx-react';

import ActionButton from '../../app/shared/components/ActionButton';
import TertiaryButton from '../../app/shared/components/Button/TertiaryButton';
import { Authorize } from '../authentication/Authorize';
import { BuyerPolicy } from '../Policy';
import { ShoppingCartItemBasicInfo } from '../shared/ShoppingCartItemBasicInfo/ShoppingCartItemBasicInfo';
import { cartItemToPanelItem, ShoppingCartItemPanel } from '../shared/ShoppingCartItemPanel/ShoppingCartItemPanel';
import css from './FavoriteItem.css';
import { FavoriteItemState } from './FavoriteItemState';

export const FavoriteItem = observer(({ state }: { state: FavoriteItemState }) => {
  const { item, contextCommands, addToCartCommand } = state;

  return (
    <ShoppingCartItemPanel
      item={cartItemToPanelItem(item)}
      contextCommands={contextCommands}
      translation={state.translation.shoppingCartItemPanel}
    >
      <ShoppingCartItemBasicInfo item={item} translation={state.translation.shoppingCartItemBasicInfo} />
      <div className={css.favoriteItemBodyActions}>
        <Authorize policy={BuyerPolicy}>
          <ActionButton command={addToCartCommand} className={css.addToCartButton} buttonElement={TertiaryButton}>
            {state.translation.addToCartButton}
          </ActionButton>
        </Authorize>
        {state.showItemDetails && (
          <div onClick={state.showItemDetails} className={css.favoriteItemDetailsLink}>
            {state.translation.showConfigurationButton}
          </div>
        )}
      </div>
    </ShoppingCartItemPanel>
  );
});
