import { makeObservable, observable, override } from 'mobx';

import { ShoppingCartItemData } from '../../app/data/model';
import { AsyncCommand } from '../../app/shared/common';
import { TextInput } from '../../app/shared/Form';
import {
  EditConfigurationNameModalTranslation,
  FavoriteItemDetailsModalTranslation,
} from '../localization/SiteTranslation';
import { FavoriteItemModalState } from './FavoriteItemModalState';

export class EditConfigurationNameModalState extends FavoriteItemModalState {
  public readonly updateLabelCommand: AsyncCommand;

  constructor(
    private readonly updateFavoriteConfigurationLabel: (
      favoriteItem: ShoppingCartItemData,
      newLabel: string,
    ) => Promise<void>,
    public editConfigurationNameTranslation: EditConfigurationNameModalTranslation,
    public favoriteItemModalTranslation: FavoriteItemDetailsModalTranslation,
  ) {
    super(favoriteItemModalTranslation);

    makeObservable(this);

    this.updateLabelCommand = new AsyncCommand(async () => {
      await this.updateFavoriteConfigurationLabel(this.favoriteItem, this.configurationCustomName.value);
      this.close();
    });
  }

  @observable
  public configurationCustomName = new TextInput();

  @override
  public setFavoriteItem(favoriteItem: ShoppingCartItemData) {
    super.setFavoriteItem(favoriteItem);
    this.configurationCustomName.setDefaultValue(this.favoriteItem.customName ?? '');
  }
}
