import { action, autorun, computed, makeObservable, observable, override, reaction } from 'mobx';

import AppSettings from '../AppSettings';
import { IApiClient } from '../data/client';
import {
  AddProductToCartCommand,
  BaseModelData,
  BaseVisualizationData,
  CalculatePreparingTimeForCodeCommand,
  DaysRangeData,
  GetProductByIdQuery,
  GetProductQueryResponse,
  GetStoreContextQueryResponse,
} from '../data/model';
import { FabricWarningState } from '../layout/Warnings/FabricWarningState';
import { IWarningLocalStorage } from '../layout/Warnings/WarningLocalStorage';
import { WarningStateBase } from '../layout/Warnings/WarningStateBase';
import { ConfiguratorPageTranslation } from '../localization/SiteTranslation';
import { Analytics } from '../shared/analytics/Analytics';
import ProductDataConverter from '../shared/analytics/ProductDataConverter';
import { CartEventData, CartEventSource } from '../shared/analytics/TrackingService';
import { BasePageState } from '../shared/BasePageState';
import { IShareConfiguratorUrlProvider } from '../shared/BaseShareConfiguratorUrlProvider';
import { AsyncCommand, IMemento, PageMetadata } from '../shared/common';
import { EmbeddedQuery, EmbeddedQueryParser } from '../shared/EmbeddedQueryParser';
import { NumberInput, RangeRule } from '../shared/Form';
import { IImagePreloader } from '../shared/ImagePreloader';
import Logger from '../shared/Logger';
import { ModalState } from '../shared/ModalState';
import { INavigationService } from '../shared/NavigationService';
import { IPdfUrlProvider } from '../shared/PdfUrlProvider';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreUrl } from '../shared/StoreUrl';
import { RunLastTasksScheduler } from '../shared/TasksScheduler';
import { AbsoluteUrl } from '../shared/Url';
import { StoreState } from '../StoreState';
import { CalculatePreparingTimeTask } from './CalculatePreparingTimeTask';
import { TabState } from './components/ConfiguratorTabs/TabState';
import { ProductModelsState } from './components/ProductModels/ProductModelsState';
import { RequestQuoteActionState } from './components/RequestQuoteAction/RequestQuoteActionState';
import { ConfigurationShareState } from './ConfigurationShareState';
import ConfigurationUnitPriceMismatchMigrationMonitor from './ConfigurationUnitPriceMismatchMigrationMonitor';
import { ConfiguratorCoreState } from './ConfiguratorCoreState';
import ConfiguratorPageQueryParser from './ConfiguratorPageQueryParser';
import IConfigurationPageQuery from './IConfigurationPageQuery';
import { IImageBasedVisualizationState } from './IVisualizationState';
import { Tab } from './Tab';
import { VisualizationStateFactory } from './VisualizationStateFactory';

export interface IConfiguratorMemento extends IMemento {
  storeContextResponse: GetStoreContextQueryResponse;
  productResponse: GetProductQueryResponse;
  code: string;
}

export class ConfiguratorState extends BasePageState<IConfiguratorMemento> {
  private selectionStateSetFirstTime = false;
  private settings: AppSettings;
  private client: IApiClient;
  private navigation: INavigationService;
  private imagePreloader: IImagePreloader;
  shareConfiguratorUrlProvider: IShareConfiguratorUrlProvider;
  private slug: string;

  public isRelativeToTheViewport = true;

  public embeddedQuery: EmbeddedQuery;

  public productModelsState: ProductModelsState;

  productResponse: GetProductQueryResponse;

  proceedToCheckoutCommand: AsyncCommand;
  proceedToEmailConfirmationCommand: AsyncCommand;

  baseModel: BaseModelData;

  @observable quantityInput: NumberInput;
  @observable shoppingCartItemId?: string;

  configurationShareModal: ModalState;

  fabricColorsDisclaimerState: FabricWarningState;
  productNotAvailableInCurrentStoreState: WarningStateBase<string>;
  productInactiveWarningState: WarningStateBase<string>;

  visualization: IImageBasedVisualizationState<BaseVisualizationData>;

  quantityMin: number;
  @observable
  quantityMax: number;

  translation: ConfiguratorPageTranslation;

  model: IConfigurationPageQuery;
  storeContextResponse: GetStoreContextQueryResponse;

  @observable private internalMetadata: PageMetadata;

  public coreState: ConfiguratorCoreState;

  public shareState: ConfigurationShareState;

  public requestQuoteState: RequestQuoteActionState;

  @observable preparingTime: DaysRangeData;

  private calculatePreparingTimeScheduler = new RunLastTasksScheduler((task: CalculatePreparingTimeTask) =>
    this.calculatePreparingTime(task),
  );

  constructor(
    settings: AppSettings,
    client: IApiClient,
    navigation: INavigationService,
    imagePreloader: IImagePreloader,
    pdfOriginProvider: IPdfUrlProvider,
    shareConfiguratorUrlProvider: IShareConfiguratorUrlProvider,
    slug: string,
    translations: ConfiguratorPageTranslation,
    warningLocalStorage: IWarningLocalStorage,
  ) {
    super(translations);
    makeObservable(this);
    this.settings = settings;
    this.client = client;
    this.navigation = navigation;
    this.imagePreloader = imagePreloader;
    this.slug = slug;
    this.internalMetadata = {};
    this.translation = translations;

    this.configurationShareModal = new ModalState();
    this.productNotAvailableInCurrentStoreState = new WarningStateBase<string>();
    this.productInactiveWarningState = new WarningStateBase();
    this.fabricColorsDisclaimerState = new FabricWarningState(warningLocalStorage);

    this.quantityMin = 1;
    this.quantityMax = 50;

    this.quantityInput = new NumberInput();

    this.coreState = new ConfiguratorCoreState(client, translations);
    this.shareState = new ConfigurationShareState(
      client,
      navigation,
      imagePreloader,
      pdfOriginProvider,
      shareConfiguratorUrlProvider,
      () => ({
        productNumber: this.slug,
        query: this.getQueryState(),
        visualizationMode: this.productResponse.visualization.mode,
      }),
    );
    this.requestQuoteState = new RequestQuoteActionState(navigation, this.coreState.code);

    this.populateDataFromQuery();
  }

  @override
  get metadata(): PageMetadata {
    return this.internalMetadata;
  }

  @action
  private populateDataFromQuery() {
    const query = this.navigation.currentUrl.query;
    this.model = ConfiguratorPageQueryParser.toModel(query);
    this.embeddedQuery = EmbeddedQueryParser.toModel(query);
    this.quantityInput.value = this.model.quantity || 1;
    this.shoppingCartItemId = this.model.shoppingCartItemId;
  }

  @action
  private initialize(
    storeContextResponse: GetStoreContextQueryResponse,
    response: GetProductQueryResponse,
    code: string,
  ) {
    const { baseModel } = response;
    this.storeContextResponse = storeContextResponse;
    this.productResponse = response;
    this.baseModel = baseModel;
    this.quantityMax = this.baseModel.purchaseQuantityLimit;

    this.setQuantityInput();

    const additionalTabs = [new TabState(Tab.Summary, this.translation.tabs)];
    this.coreState.initialize(
      response,
      code,
      this.model.step,
      additionalTabs,
      this.storeContextResponse.shoppingContext,
    ); // TODO: Check if this is a proper place

    this.shareState.setCode(code);

    this.requestQuoteState.initialize(this.storeContextResponse.shoppingContext);

    this.startTrackingConfigurationChanges();
    this.restoreStateFromQuery();
    this.coreState.initializeKindOfFabricCordinators(response);

    this.visualization = new VisualizationStateFactory(this.imagePreloader).create(response.visualization);
    this.visualization.initialize(response.visualization, this.coreState.selectedComponents);

    this.productModelsState = new ProductModelsState(
      this.client,
      this.navigation,
      this.storeContextResponse.shoppingContext,
      this.baseModel,
      () => this.getQueryState(),
    );

    this.proceedToCheckoutCommand = new AsyncCommand(() => this.proceedToCheckout());
    this.proceedToEmailConfirmationCommand = new AsyncCommand(() => this.proceedToEmailConfirmation());

    this.checkAvailabilityInCurrentStore(response);
    this.checkIfDeactivated(response);

    this.setMetadata();

    this.calculatePreparingTimeOnConfigurationCodeChange();

    reaction(
      () => this.coreState.code,
      () =>
        ConfigurationUnitPriceMismatchMigrationMonitor.monitor(
          this.coreState.unitPriceV2,
          this.coreState.unitPrice,
          this.coreState.code,
        ),
    );

    reaction(
      // TODO: verify if this is the best place
      () => this.coreState.code,
      () => {
        this.shareState.setCode(this.coreState.code);
        this.requestQuoteState.setCode(this.coreState.code);
      },
    );
  }

  private checkAvailabilityInCurrentStore(response: GetProductQueryResponse) {
    if (!response.baseModel.availableInCurrentStore) {
      const store = this.storeContextResponse.storeContext.store;
      const storeName = store.displayName || store.name;
      this.productNotAvailableInCurrentStoreState.open(storeName);
    }
  }

  private checkIfDeactivated(response: GetProductQueryResponse) {
    if (!response.baseModel.isActive) {
      const reasonForInactivation = response.baseModel.reasonForInactivation;
      this.productInactiveWarningState.open(reasonForInactivation);
    }
  }

  @action
  public async onLoad(store: StoreState) {
    let productResponse = await this.client.send(
      new GetProductByIdQuery({ productId: this.slug, shoppingContext: store.shoppingContext }),
    );

    if (productResponse.redirectToProductId) {
      this.navigation.redirect(
        StoreUrl.configuratorUrl(productResponse.redirectToProductId).toString(),
        StatusCodes.MovedTemporary,
      );
    }

    this.initialize(store.storeResponse, productResponse, '000000');
    await this.coreState.ensureCodeIsInSyncWithSelectedOptionsAndAccessories();
    await this.shareState.onLoad(store.shoppingContext);
  }

  onLoadAdditionalData() {
    this.startUpdatingVisualizationImages();
  }

  @action
  private setMetadata() {
    const title = this.translations.pageTitleFormat.interpolate([['productName', this.productResponse.baseModel.name]]);
    const description = this.translations.pageDescriptionFormat.interpolate([
      ['productName', this.productResponse.baseModel.name],
      [
        'options',
        this.coreState.selectedOptionVariations.map((x) => `${x.option.name} : ${x.variation.name}`).join(', '),
      ],
      [
        'accessories',
        this.coreState.accessories
          .filter((x) => x.selected)
          .map((x) => `${x.name}`)
          .join(', '),
      ],
    ]);
    const url = AbsoluteUrl.parse(this.settings.baseUrl)
      .append(StoreUrl.configuratorUrl(this.slug, this.getQueryState()))
      .toString();
    const imageUrl = this.shareState.imageUrlForPdf;

    this.internalMetadata = {
      url: url,
      title: title,
      description: description,
      imageUrl: imageUrl,
    };
  }

  @action
  restoreMemento(memento: IConfiguratorMemento) {
    this.initialize(memento.storeContextResponse, memento.productResponse, memento.code);
  }

  @action
  async proceedToEmailConfirmation() {
    this.navigation.navigateTo(StoreUrl.emailConfirmationUrl({ configurationCode: this.coreState.code }));
  }

  @action
  async proceedToCheckout() {
    await this.addProductToCart();
    Analytics.trackAddToCart(this.buildAddToCartEvent(this.coreState.unitPrice.amount));
    Analytics.trackCheckoutClick(this.storeContextResponse.shoppingContext);
    this.navigation.navigateTo(StoreUrl.checkoutUrl());
  }

  private async addProductToCart() {
    let configuration = this.getProductConfiguration();
    let command = new AddProductToCartCommand({
      shoppingContext: this.storeContextResponse.shoppingContext,
      productConfiguration: configuration,
    });

    await this.client.send(command);
  }

  @action.bound
  updateQuantity(value: number) {
    this.quantityInput.onChange(value);
  }

  @computed get total() {
    const total = { ...this.coreState.unitPrice };
    total.amount = this.quantityInput.value ? this.quantityInput.value * total.amount : total.amount;
    return total;
  }

  getProductConfiguration(ignoreQuantity: boolean = false) {
    return this.coreState.getProductConfiguration(ignoreQuantity ? 1 : this.quantityInput.value);
  }

  getMemento() {
    const memento: IConfiguratorMemento = {
      storeContextResponse: this.storeContextResponse,
      productResponse: this.productResponse,
      code: this.coreState.code,
    };

    return memento;
  }

  public getQueryState() {
    const configuration = this.getProductConfiguration();
    const pageQuery: IConfigurationPageQuery = {
      options: configuration.options.map((x) => x.id),
      accessories: configuration.accessories.map((x) => x.id),
      step: this.coreState.tabs.activeTab.code,
      quantity: configuration.quantity,
      shoppingCartItemId: this.shoppingCartItemId,
    };

    return pageQuery;
  }

  private startTrackingConfigurationChanges() {
    autorun(() => {
      const pageQuery = this.getQueryState();
      if (!this.selectionStateSetFirstTime) {
        this.selectionStateSetFirstTime = true;
        return;
      }
      const query = ConfiguratorPageQueryParser.toQuery(pageQuery);
      this.navigation.setQuery(query);
    });
  }

  private restoreStateFromQuery() {
    const pageQuery = ConfiguratorPageQueryParser.toModel(this.navigation.currentUrl.query);
    this.coreState.restoreState(this.model.code, pageQuery);
  }

  public buildAddToCartEvent(price: number): CartEventData {
    return {
      context: this.storeContextResponse.shoppingContext,
      source: CartEventSource.Configurator,
      products: [
        ProductDataConverter.fromBaseModelData(this.baseModel, this.coreState.code, price, this.quantityInput.value),
      ],
    };
  }

  private startUpdatingVisualizationImages() {
    autorun(async () => {
      const components = this.coreState.selectedComponents;
      this.visualization.setComponents(components);
    });
  }

  private setQuantityInput() {
    const outOfRangeWarning = this.translation.summary.quantityInput.outOfRangeWarning.interpolate([
      ['min', this.quantityMin.toString()],
      ['max', this.quantityMax.toString()],
    ]);

    this.quantityInput
      .withRule(new RangeRule(this.quantityMin, this.quantityMax, outOfRangeWarning))
      .triggerValidationImmediately();
  }

  private calculatePreparingTimeOnConfigurationCodeChange() {
    const { storeId, storeType } = this.storeContextResponse.shoppingContext;
    reaction(
      () => this.coreState.code,
      (code) => this.executeCalculatePreparingTimeTask(storeId, storeType, code),
    );
  }

  private executeCalculatePreparingTimeTask(storeId: string, storeType: string, configurationCode: string) {
    const task = new CalculatePreparingTimeTask(storeId, storeType, configurationCode);
    return this.calculatePreparingTimeScheduler.run(task);
  }

  @action
  private async calculatePreparingTime(task: CalculatePreparingTimeTask) {
    const command = new CalculatePreparingTimeForCodeCommand({
      storeId: task.storeId,
      storeType: task.storeType,
      configurationCode: task.configurationCode,
    });

    try {
      const response = await this.client.send(command);

      if (task.cancelled) {
        return;
      }

      this.updatePreparingTime(response.preparingTimeInWorkingDays);
    } catch (error) {
      Logger.exception('Error occurred while calculating preparing time', error);
      throw error;
    }
  }

  @action
  private updatePreparingTime(value: DaysRangeData) {
    this.preparingTime = value;
  }
}
