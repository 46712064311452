import classNames from 'classnames';
import { MouseEventHandler } from 'react';

import Icon, { IconType } from '../../Icon/Icon';
import IconButton from '../IconButton';
import css from './ToggleButton.css';

interface ToggleButtonProps {
  icon: IconType;
  id: string;
  isPressed: boolean;
  label: string;
  onClick: MouseEventHandler;
  className?: string;
}

const ToggleButton = ({ className, icon, id, isPressed, label, onClick }: ToggleButtonProps) => {
  return (
    <IconButton
      className={classNames(css.element, className)}
      type="button"
      icon={<Icon className={css.icon} name={icon} />}
      aria-pressed={isPressed}
      onClick={onClick}
      tooltip={{ id, text: label }}
    />
  );
};

export default ToggleButton;
