import classNames from 'classnames';
import { observer } from 'mobx-react';
import { cloneElement, Component, MouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { OptionVariationState } from '../../../configurator/OptionState';
import css from './Popup.css';

interface PopupProps {
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
  className?: string;
  children: JSX.Element;
  popper?: JSX.Element;
  variation: OptionVariationState;
}

interface PopupState {
  isActive: boolean;
  isVisible: boolean;
  isInitialized: boolean;
}

class Popup extends Component<PopupProps, PopupState> {
  constructor(props: PopupProps) {
    super(props);
    this.state = {
      isActive: false,
      isVisible: false,
      isInitialized: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleClick(e: MouseEvent<EventTarget>) {
    e.preventDefault();
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  }

  handleMouseEnter(e: MouseEvent<EventTarget>) {
    e.preventDefault();
    const { onHover } = this.props;

    if (onHover) {
      onHover();
    }

    this.setState({ isActive: true, isInitialized: true });
  }

  handleMouseLeave(e: MouseEvent<EventTarget>) {
    e.preventDefault();
    const { onLeave } = this.props;

    if (onLeave) {
      onLeave();
    }
    this.setState({ isActive: false });
  }

  render() {
    const { popper, children, className, variation } = this.props;
    const { isActive, isVisible, isInitialized } = this.state;
    const finalClassName = classNames(className, css.Popup, variation.highlightAnimation.active && css.scrolled);

    const popperClassName = classNames(css.popperInit, isActive && isVisible && css.popperEntered);

    if (isVisible !== isActive) {
      setTimeout(() => {
        this.setState({ isVisible: isActive });
      }, 1);
    }

    return (
      <CSSTransition
        in={variation.highlightAnimation.active}
        timeout={500}
        onEntered={() => {
          variation.highlightAnimation.stop();
        }}
        classNames={{}}
      >
        <div className={finalClassName} id={this.props.variation.id}>
          {popper && isInitialized && (
            <CSSTransition in={isActive} timeout={500} classNames={{}}>
              {cloneElement(popper, { className: popperClassName })}
            </CSSTransition>
          )}
          <div
            className={css.trigger}
            onClick={this.handleClick}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {children}
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default observer(Popup);
