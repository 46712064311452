import ImageFactory from '../../../../../images/factory.svg';
import ImageGlobe from '../../../../../images/globe.svg';
import ImageLifecycle from '../../../../../images/lifecycle.svg';
import ImageRecycling from '../../../../../images/recycling.svg';
import { ImageData } from '../../../../data/model';
import { ProductEnvironmentalDataTranslation } from '../../../../localization/SiteTranslation';
import ExternalImage from '../../../../shared/components/ExternalImage/ExternalImage';
import List from '../../../../shared/components/List/List';
import css from './ProductEnvironmentalData.css';
import ProductEnvironmentalDataEntry from './ProductEnvironmentalDataEntry/ProductEnvironmentalDataEntry';

const ENERGY_USAGE_UNIT = 'MJ';
const CO2_EMISSION_UNIT = (
  <>
    kgCO<sub>2</sub>e
  </>
);

export interface ProductModelEnvironmentalData {
  certificates: ImageData[];
  co2EmissionKg: string;
  energyUsageMj: string;
  recyclableMaterialsPercentage: string;
  recycledAluminiumPercentage: string;
  recycledPlasticPercentage: string;
  recycledMaterialsTotalPercentage: string;
}

interface ProductEnvironmentalDataProps extends ProductModelEnvironmentalData {
  translation: ProductEnvironmentalDataTranslation;
}

const ProductEnvironmentalData = ({
  certificates,
  co2EmissionKg,
  energyUsageMj,
  recyclableMaterialsPercentage,
  recycledAluminiumPercentage,
  recycledMaterialsTotalPercentage,
  recycledPlasticPercentage,
  translation,
}: ProductEnvironmentalDataProps) => {
  const getRecycledMaterialsDetails = () => {
    const details = [];

    if (recycledPlasticPercentage) {
      details.push({
        title: `${translation.recycledPlasticText}:`,
        value: `${recycledPlasticPercentage}%`,
      });
    }

    if (recycledAluminiumPercentage) {
      details.push({
        title: `${translation.recycledAluminiumText}:`,
        value: `${recycledAluminiumPercentage}%`,
      });
    }

    return details;
  };

  return (
    <div>
      <dl className={css.datalist}>
        {co2EmissionKg && (
          <ProductEnvironmentalDataEntry
            descriptionClassName={css.inline}
            description={CO2_EMISSION_UNIT}
            imageSrc={ImageGlobe}
            title={translation.co2EmissionKgText}
            value={co2EmissionKg}
          />
        )}
        {recycledMaterialsTotalPercentage && (
          <ProductEnvironmentalDataEntry
            description={translation.recycledMaterialsText}
            imageSrc={ImageRecycling}
            title={translation.recycledMaterialsTitle}
            value={`${recycledMaterialsTotalPercentage}%`}
            additionalInfo={getRecycledMaterialsDetails()}
          />
        )}
        {recyclableMaterialsPercentage && (
          <ProductEnvironmentalDataEntry
            description={translation.recyclableMaterialsText}
            imageSrc={ImageLifecycle}
            title={translation.recyclableMaterialsTitle}
            value={`${recyclableMaterialsPercentage}%`}
          />
        )}
        {energyUsageMj && (
          <ProductEnvironmentalDataEntry
            descriptionClassName={css.inline}
            description={ENERGY_USAGE_UNIT}
            imageSrc={ImageFactory}
            title={translation.energyUsageMjText}
            value={energyUsageMj}
          />
        )}
      </dl>

      <List
        className={css.certificatesList}
        itemClassName={css.certificate}
        items={certificates}
        itemRenderer={(item) => <ExternalImage className={css.image} src={item.url} alt={item.name} />}
      />

      <small className={css.disclaimer}>{translation.averagedValuesDisclaimerText}</small>
    </div>
  );
};

export default ProductEnvironmentalData;
