import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import List from '../../components/List/List';
import { Tab } from '../../Tabs/Tab';
import css from './TabList.css';

interface TabListProps {
  className?: string;
  tabItemClassName?: string;
  tabItemContainerClassName?: string;

  tabs: Array<Tab>;
  setActiveTab: (tab: Tab) => void;
  tabRenderer?: (tab: Tab) => ReactNode;
}

export const TabList = observer((props: TabListProps) => {
  const { tabs, setActiveTab, tabRenderer, className, tabItemContainerClassName, tabItemClassName } = props;
  return (
    <List
      className={classNames(css.TabList, className)}
      onItemClick={setActiveTab}
      items={tabs}
      itemRenderer={(tab) => <TabItem tab={tab} tabRenderer={tabRenderer} className={tabItemClassName} />}
      itemClassName={tabItemContainerClassName}
    />
  );
});

export const TabItem = observer(
  ({ tab, tabRenderer, className }: { tab: Tab; tabRenderer: (tab: Tab) => ReactNode; className?: string }) => (
    <button className={classNames(css.tabItem, tab.isActive && css.active, className)}>
      {tabRenderer ? tabRenderer(tab) : tab.title}
    </button>
  ),
);
