import { observer } from 'mobx-react';
import { Component } from 'react';

import ErrorPage from '../errorHandling/ErrorPage';
import { CheckoutShortcutState } from './CheckoutShortcutState';

@observer
class CheckoutShortcutPage extends Component<{ state: CheckoutShortcutState }, {}> {
  render() {
    const { state } = this.props;

    if (state.errorMessage) {
      return <ErrorPage title={state.errorMessage} />;
    }
  }
}

export default CheckoutShortcutPage;
