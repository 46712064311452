import classNames from 'classnames';
import { observer } from 'mobx-react';

import { TextLabelStrong } from '../../../../shared/components/Typography/TextLabels';
import { SelectedFeature } from '../../../ConfiguratorCoreState';
import { OptionVariationState } from '../../../OptionState';
import css from './OptionItem.css';

interface OptionItemProps {
  selectedFeature: SelectedFeature;
  className?: string;
  nameClassName?: string;
}

const OptionItem = (props: OptionItemProps) => {
  const { selectedFeature, className, nameClassName } = props;

  let code = selectedFeature.item.code;
  const optionVariationState = selectedFeature.item as OptionVariationState;

  if (optionVariationState.data?.alternativeCode) {
    code = optionVariationState.data.alternativeCode;
  }

  return (
    <div className={classNames(className, css.OptionItem)}>
      <TextLabelStrong className={classNames(css.name, nameClassName)}>{selectedFeature.feature.name}</TextLabelStrong>
      <div>
        <span>{selectedFeature.item.fullName}</span> <span className={css.code}>{code}</span>
      </div>
    </div>
  );
};

export default observer(OptionItem);
