import { observer } from 'mobx-react';
import { Component } from 'react';

import { CssAnimation } from '../../../shared/animations';
import { AnimatedComponent, AnimatedComponentProps } from '../AnimatedComponent';
import css from './AnimatedChairNavigation.css';

export class AnimatedChairNavigationState {
  initAnimation: CssAnimation;
  initStyle: CssAnimation;

  constructor() {
    this.initAnimation = new CssAnimation();
    this.initStyle = new CssAnimation();
  }
}

@observer
export class AnimatedChairNavigation extends Component<AnimatedComponentProps, {}> {
  constructor(props: AnimatedComponentProps) {
    super(props);
  }

  render() {
    return <AnimatedComponent {...this.props} css={css} />;
  }
}
