import { IApiClient } from '../../../app/data/client';
import { ShoppingContext, StoreData } from '../../../app/data/model';
import { RouteUrlBuilder } from '../../../app/RouteDefinitions';
import { IEventAggregator } from '../../../app/shared/EventAggregator';
import { StoreUrlBuilder } from '../../../app/shared/StoreUrlBuilder';
import { UserInfoTranslation } from '../../localization/SiteTranslation';
import RouteDefinitions from '../../routing/RouteDefinitions';
import StoreSelectorState from './StoreSelector/StoreSelectorState';

class UserInfoState {
  public readonly storeSelector: StoreSelectorState;

  public readonly profileUrl = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.profileRoute));

  public get hasAccessToMultipleStores() {
    return this.storeSelector.stores.any();
  }

  public get store() {
    return this.storeSelector.currentStore;
  }

  public get name() {
    return this.shoppingContext.customer.userName;
  }

  public constructor(
    client: IApiClient,
    eventAggregator: IEventAggregator,
    private readonly shoppingContext: ShoppingContext,
    currentDealer: StoreData,
    availableDealers: StoreData[],
    translation: UserInfoTranslation,
  ) {
    this.storeSelector = new StoreSelectorState(
      client,
      eventAggregator,
      shoppingContext,
      currentDealer,
      availableDealers,
      translation.storeSelector,
    );
  }
}

export default UserInfoState;
