import { ShareConfigurationSectionTranslation } from '../../../../localization/SiteTranslation';
import Icon from '../../../../shared/components/Icon/Icon';
import InputElement from '../../../../shared/components/Input/InputElement';
import CopyButton from '../CopyButton';
import css from './ConfigurationUrlShare.css';

interface ConfigurationUrlShareProps {
  isCodeLoading: boolean;
  // TODO: Extract new interface
  translation: ShareConfigurationSectionTranslation;
  url: string;
  className?: string;
}

const ConfigurationUrlShare = ({ className, isCodeLoading, translation, url }: ConfigurationUrlShareProps) => {
  return (
    <div className={className}>
      <p>{translation.yourConfigurationUrl}</p>
      <div className={css.inner}>
        <div className={css.inputContainer}>
          <InputElement type="text" className={css.input} value={url} readOnly />
          <Icon name="link" className={css.icon} />
        </div>
        <CopyButton
          className={css.buttonCopy}
          translation={translation.copyLink}
          valueToCopy={url}
          disabled={isCodeLoading}
        />
      </div>
    </div>
  );
};

export default ConfigurationUrlShare;
