import { HubSpotFormWithProductState } from '../shared/hubSpot/HubSpotFormWithProductState';
import { StoreUrl } from '../shared/StoreUrl';
import { StoreState } from '../StoreState';

export class EmailMyConfigurationState extends HubSpotFormWithProductState {
  onFormSubmit(): void {
    // Do nothing
  }

  protected hubSpotConfigSelector(store: StoreState) {
    return store.storeResponse.storeContext.emailMeConfigurationConfig;
  }

  protected ensureFeatureIsEnabled(store: StoreState) {
    if (!store.canEmailMyConfiguration()) {
      this.navigation.navigateTo(StoreUrl.homeUrl());
    }
  }
}
