import { action, computed, makeObservable, observable } from 'mobx';

import { ImageData, OptionVariationData } from '../../../data/model';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { ModalState } from '../../../shared/ModalState';

interface IOptionDescriptionModalState {
  setOptionData(title: string, description: string, optionImage: ImageData): void;

  setFabricCollectionData(title: string, description: string, collection: Array<OptionVariationData>): void;

  open(): void;

  close(): void;
}

export class OptionDescriptionModalState extends ModalState implements IOptionDescriptionModalState {
  @observable title: string;
  @observable description: string;
  @observable collection: Array<OptionVariationData>;
  @observable optionImage: ImageData;

  private readonly loadingIndicator = new LoadingIndicator();

  constructor() {
    super();
    makeObservable(this);
  }

  @computed
  get isLoading() {
    return this.loadingIndicator.isLoading;
  }

  @action
  setOptionData(title: string, description: string, optionImage: ImageData): void {
    this.title = title;
    this.description = description;
    this.optionImage = optionImage;
    this.collection = null;
  }

  @action
  setFabricCollectionData(title: string, description: string, collection: Array<OptionVariationData> = []): void {
    this.title = title;
    this.description = description;
    this.collection = collection;
    this.optionImage = null;
  }

  @action
  startLoading() {
    this.loadingIndicator.start();
  }

  @action
  stopLoading() {
    this.loadingIndicator.stop();
  }
}
