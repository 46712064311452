import * as React from 'react';

import { MenuItem } from '../../../../data/model';
import { ExpandablePanel } from '../../../../shared/components/ExpandablePanel/ExpandablePanel';
import css from './LinkListAccordion.css';
import LinkListAccordionHeader from './LinkListAccordionHeader/LinkListAccordionHeader';

interface LinkListAccordionProps {
  items: MenuItem[];
  renderItem: (list: MenuItem) => React.ReactElement;
  className?: string;
}

const LinkListAccordion = ({ className, items, renderItem }: LinkListAccordionProps) => {
  const renderTitle = (isExpanded: boolean, list: MenuItem) => {
    return <LinkListAccordionHeader isExpanded={isExpanded}>{list.name}</LinkListAccordionHeader>;
  };

  return items.any() ? (
    <div className={className}>
      {items.map((item, index) => (
        <ExpandablePanel
          contentClassName={css.content}
          renderTitle={(isExpanded) => renderTitle(isExpanded, item)}
          expanded={false}
          key={index}
        >
          {renderItem(item)}
        </ExpandablePanel>
      ))}
    </div>
  ) : null;
};

export default LinkListAccordion;
