import { isBrowser } from '../shared/common';
import Event from '../shared/Event';
import { ScreenViewport, Size } from '../shared/ScreenViewport';

export default class WindowSizeObserver {
  public static onSizeChange: Event<Size> = new Event<Size>();
}

if (isBrowser()) {
  window.addEventListener('resize', () => {
    WindowSizeObserver.onSizeChange.raise(ScreenViewport.getWindowSize());
  });
}
