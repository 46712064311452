import { action, makeObservable, observable } from 'mobx';

import { Vector } from './Vector';

export class ObjectState {
  @observable
  name: string;

  @observable
  position: Vector = { x: 0, y: 0, z: 0 };

  @observable
  rotation: Vector = { x: 0, y: 0, z: 0 };

  @observable
  selected: boolean = false;

  @observable
  opacity: number = 1;

  constructor(name: string) {
    makeObservable(this);
    this.name = name;
  }

  @action
  setSelected(selected: boolean) {
    this.selected = selected;
  }

  @action
  toggleSelected() {
    this.setSelected(!this.selected);
  }

  @action
  setOpacity(opacity: number) {
    this.opacity = opacity;
  }
}
