import classNames from 'classnames';
import * as React from 'react';

import css from './FilterButton.css';

interface FilterButtonProps {
  children: React.ReactNode;
  isActive: boolean;
  name: string;
  onSelect: () => void;
  value: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  children,
  className,
  disabled,
  isActive,
  name,
  onClick,
  onSelect,
  value,
}) => {
  return (
    <label className={css.FilterButton}>
      <input
        className={css.input}
        type="radio"
        name={name}
        value={value}
        checked={isActive}
        onClick={onClick}
        onChange={onSelect}
        disabled={disabled}
      />
      <span className={classNames(css.inner, className)}>{children}</span>
    </label>
  );
};

export default FilterButton;
