import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';

import HeightAnimation from '../HeightAnimation';
import css from './ExpandablePanel.css';

export interface ExpandablePanelProps {
  renderTitle: (isExpanded: boolean) => JSX.Element | string;
  onExpandChanged?: (value: boolean) => void;
  children: ReactNode;
  animationDuration?: number;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  expanded: boolean;
}

export interface ExpandablePanelState {
  isExpanded: boolean;
}

@observer
export class ExpandablePanel extends Component<ExpandablePanelProps, ExpandablePanelState> {
  constructor(props: ExpandablePanelProps) {
    super(props);

    this.state = {
      isExpanded: this.props.expanded,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick() {
    this.setExpanded(!this.state.isExpanded);
  }

  setExpanded(value: boolean) {
    if (value !== this.state.isExpanded) {
      this.setState({
        isExpanded: value,
      });

      if (this.props.onExpandChanged) {
        this.props.onExpandChanged(value);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<ExpandablePanelProps>) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setExpanded(this.props.expanded);
    }
  }

  render() {
    const { renderTitle, animationDuration, children, className, titleClassName, contentClassName } = this.props;
    return (
      <div className={classNames(css.ExpandablePanel, className)}>
        <div onClick={this.handleOnClick} className={classNames(css.title, titleClassName)}>
          {renderTitle(this.state.isExpanded)}
        </div>
        <div className={css.content}>
          <HeightAnimation
            animateOpacity={true}
            duration={animationDuration ?? 750}
            height={this.state.isExpanded ? 'auto' : 0}
          >
            <div className={contentClassName}>{children}</div>
          </HeightAnimation>
        </div>
      </div>
    );
  }
}
