import { makeObservable, observable } from 'mobx';

import { OrderData } from '../data/model';

export interface ICheckoutStep {
  id: string;
  isActive: boolean;
  title: string;

  // initialize(shoppingContext: ShoppingContext, storeContext: StoreContext): void;
  restoreOrder(order: OrderData): void;
}

export class CheckoutStep implements ICheckoutStep {
  public id: string;
  public title: string;

  @observable
  public isActive: boolean;

  constructor(id: string, title: string, isActive: boolean = false) {
    makeObservable(this);
    this.id = id;
    this.isActive = isActive;
    this.title = title;
  }

  public restoreOrder(_: OrderData) {
    // Nothing here
  }
}
