import classNames from 'classnames';
import { ReactNode } from 'react';

import Icon from '../../../shared/components/Icon/Icon';
import css from './StatusMessage.css';

export enum Status {
  Error,
  Success,
}

interface StatusMessageProps {
  children: ReactNode;
  type: Status;
  className?: string;
}

const StatusMessage = ({ children, className, type }: StatusMessageProps) => {
  const isError = type === Status.Error;

  const iconName = isError ? 'danger' : 'check';

  return (
    <div
      className={classNames(className, css.container, { [css.error]: isError, [css.success]: !isError })}
      role="alert"
    >
      <div className={css.iconWrapper}>
        <Icon name={iconName} className={css.icon} />
      </div>
      {children}
    </div>
  );
};

export default StatusMessage;
