import { observer } from 'mobx-react';

import ActionButton from '../../../../app/shared/components/ActionButton';
import SecondaryButton from '../../../../app/shared/components/Button/SecondaryButton';
import CheckboxGroup from '../../../../app/shared/components/Checkbox/CheckboxGroup';
import FormInput from '../../../../app/shared/components/FormInput';
import Modal from '../../../../app/shared/components/Modal/Modal';
import HeaderLevel3 from '../../../../app/shared/components/Typography/HeaderLevel3';
import css from './InviteNewUserModal.css';
import { InviteNewUserModalState } from './InviteNewUserModalState';

export const InviteNewUserModal = observer(({ state }: { state: InviteNewUserModalState }) => {
  const { firstNameInput, lastNameInput, emailInput, retypedEmailInput, rolesInput } = state.invitationForm;
  const translation = state.translation;

  return (
    <Modal className={css.InviteNewUserModal} modal={state}>
      <form>
        <HeaderLevel3 className={css.title}>{translation.inviteNewUserHeader}</HeaderLevel3>

        <div>
          <FormInput input={firstNameInput} label={translation.firstNameLabel} />
          <FormInput input={lastNameInput} label={translation.lastNameLabel} />
          <FormInput input={emailInput} label={translation.emailLabel} />
          <FormInput input={retypedEmailInput} label={translation.repeatEmailLabel} />
          <CheckboxGroup input={rolesInput} label={translation.grantedRolesLabel} />
        </div>

        <div className={css.actionButtonContainer}>
          <ActionButton
            command={state.inviteNewUserCommand}
            buttonElement={SecondaryButton}
            className={css.actionButton}
          >
            {translation.sendInvitationButton}
          </ActionButton>
        </div>
      </form>
    </Modal>
  );
});
