import classNames from 'classnames';
import { AnchorHTMLAttributes } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Link from '../Link';
import css from './Markdown.css';

interface MarkdownProps {
  className?: string;
  linkTarget?: '_blank';
  content: string;
}

const LinkRenderer = (props: AnchorHTMLAttributes<HTMLLinkElement>) => {
  return (
    <Link href={props.href} target={props.target} {...props}>
      {props.children}
    </Link>
  );
};

const Markdown = ({ className, content, ...props }: MarkdownProps) => {
  return (
    <ReactMarkdown
      className={classNames(css.Markdown, className)}
      components={{ link: LinkRenderer }}
      remarkPlugins={[remarkGfm]}
      {...props}
    >
      {content}
    </ReactMarkdown>
  );
};

export default Markdown;
