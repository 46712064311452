import classNames from 'classnames';
import { ReactNode } from 'react';

import { CustomerData } from '../../app/data/model';
import List from '../../app/shared/components/List/List';
import HeaderLevel2 from '../../app/shared/components/Typography/HeaderLevel2';
import HeaderLevel4 from '../../app/shared/components/Typography/HeaderLevel4';
import { OnboardingCardsTranslation, OnboardingTranslation } from '../localization/SiteTranslation';
import css from './Onboarding.css';
import OnboardingCardsBuilder from './OnboardingCardsBuilder';

interface OnboardingProps {
  translation: OnboardingTranslation;
  customer?: CustomerData;
  className?: string;
  contentClassName?: string;
}

export const Onboarding = ({ customer, className, contentClassName, translation }: OnboardingProps) => {
  return (
    <div className={classNames(css.Onboarding, className)}>
      <div className={css.heroContainer}>
        <div className={css.hero}>
          <div className={classNames(css.heroWelcomeTextContainer, contentClassName)}>
            <div className={css.heroWelcomeText}>
              <HeaderLevel2 className={css.title}>
                {translation.headerText} {customer?.userName}
              </HeaderLevel2>
              <p className={css.text}>{translation.welcomeText}</p>
            </div>
          </div>
        </div>
      </div>
      <OnboardingCards
        contentClassName={contentClassName}
        customer={customer}
        translation={translation.onboardingCards}
      />
    </div>
  );
};

interface OnboardingCardsProps {
  customer?: CustomerData;
  contentClassName?: string;
  translation: OnboardingCardsTranslation;
}

const OnboardingCards = ({ contentClassName, customer, translation }: OnboardingCardsProps) => {
  const cards = OnboardingCardsBuilder.build(translation, customer);

  return (
    <List
      className={classNames(css.OnboardingCards, contentClassName)}
      itemClassName={css.cardContainer}
      items={cards}
      itemRenderer={(item) => <OnboardingCard {...item} />}
    />
  );
};

interface OnboardingCardProps {
  title: string;
  img: { src: string; alt?: string; className?: string };
  description: ReactNode;
}

const OnboardingCard = ({ title, img, description }: OnboardingCardProps) => {
  return (
    <div className={css.OnboardingCard}>
      <div className={css.imageContainer}>
        <img src={img.src} alt={img.alt} width={150} height={100} className={img.className} />
      </div>
      <HeaderLevel4 className={css.title}>{title}</HeaderLevel4>
      <div className={css.text}>{description}</div>
    </div>
  );
};
