import { Storyboard } from '../../../shared/animations';
import { PanelAnimations } from './AnimatedPanel';

export class AnimatedPanelState {
  animations: PanelAnimations = new PanelAnimations();

  show: () => Storyboard;
  showFromLeft: () => Storyboard;
  showFromRight: () => Storyboard;
  hide: () => Storyboard;
  hideToLeft: () => Storyboard;
  hideToRight: () => Storyboard;
  fast: () => Storyboard;
  medium: () => Storyboard;
  slow: () => Storyboard;

  constructor() {
    const {
      show,
      hide,
      lock,
      fast,
      medium,
      slow,
      initAnimation,
      slideToLeft,
      slideToRight,
      slideLeftInit,
      slideRightInit,
    } = this.animations;
    this.show = () =>
      new Storyboard().append(0, show, [hide, lock, slideToLeft, slideToRight, slideLeftInit, slideRightInit]);
    this.showFromLeft = () =>
      new Storyboard().append(0, slideLeftInit, [slideToLeft, slideToRight, hide, lock, slideRightInit, slideLeftInit]);
    this.showFromRight = () =>
      new Storyboard().append(0, slideRightInit, [
        slideToLeft,
        slideToRight,
        hide,
        lock,
        slideLeftInit,
        slideRightInit,
      ]);
    this.hideToLeft = () =>
      new Storyboard().append(0, slideToLeft, [slideLeftInit, slideRightInit, slideToRight, hide, lock]);
    this.hideToRight = () =>
      new Storyboard().append(0, slideToRight, [slideToLeft, slideLeftInit, slideRightInit, hide, lock]);
    this.hide = () =>
      new Storyboard().append(0, hide, [show, initAnimation, slideToLeft, slideToRight, slideLeftInit, slideRightInit]);
    this.fast = () => new Storyboard().append(0, fast, [slow, medium]);
    this.medium = () => new Storyboard().append(0, medium, [fast, slow]);
    this.slow = () => new Storyboard().append(0, slow, [fast, medium]);
  }
}
