import { FinalVisualizationBuilder, SupportedExtension } from '../configurator/VisualizationBuilder';
import { IApiClient } from '../data/client';
import { GenerateZipArchiveCommand } from '../data/model';
import { INavigationService } from './NavigationService';
import { availableShots } from './visualization';

interface ZipImagesConfig {
  extensions?: SupportedExtension[];
  shots?: string[];
  outputFilename?: string;
}

export class ZipBuilder {
  public static modesSupportingTransparency: string[] = ['default'];

  constructor(private client: IApiClient, private navigation: INavigationService) {}

  public downloadZipConfigurationImages = async (
    configurationCode: string,
    config: ZipImagesConfig = {},
    visualizationMode: string = '',
  ): Promise<string> => {
    const command = ZipBuilder.buildZipConfigurationImagesCommand(configurationCode, config, visualizationMode);

    const response = await this.client.send(command);
    this.navigation.redirect(response.fileUrl);

    return response.fileUrl;
  };

  public static buildZipConfigurationImagesCommand = (
    configurationCode: string,
    config: ZipImagesConfig = {},
    visualizationMode: string,
  ) => {
    const defaultConfig: Required<ZipImagesConfig> = {
      extensions: ZipBuilder.getImageExtensions(visualizationMode),
      shots: availableShots,
      outputFilename: `Flokk-chair-${configurationCode}.zip`,
    };

    const finalConfig = { ...defaultConfig, ...config };

    const urls = finalConfig.extensions.mapMany((extension) =>
      finalConfig.shots.map((shot) =>
        FinalVisualizationBuilder.buildUrl(configurationCode, shot, extension, 2000, 2382),
      ),
    );

    return new GenerateZipArchiveCommand({
      files: urls,
      outputFile: finalConfig.outputFilename,
    });
  };

  public static isTransparencySupported(visualizationMode: string): boolean {
    return (
      ZipBuilder.modesSupportingTransparency.find(
        (x) => x.toLocaleLowerCase() === visualizationMode.toLocaleLowerCase(),
      ) !== undefined
    );
  }

  public static getImageExtensions(visualizationMode: string): SupportedExtension[] {
    if (ZipBuilder.isTransparencySupported(visualizationMode)) {
      return ['png', 'jpg'];
    } else {
      return ['jpg'];
    }
  }
}
