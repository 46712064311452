import { WarningBaseProps } from '../../../../../app/layout/Warnings/StackedWarnings';
import { StoreChangeFailedWarningTranslation } from '../../../../localization/SiteTranslation';

interface StoreChangeFailedWarningProps extends WarningBaseProps {
  translation: StoreChangeFailedWarningTranslation;
}

const StoreChangeFailedWarning = ({ translation }: StoreChangeFailedWarningProps) => {
  return <>{translation.message}</>;
};

export default StoreChangeFailedWarning;
