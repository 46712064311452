import { IShareConfiguratorUrlProvider } from '../../../../../shared/BaseShareConfiguratorUrlProvider';
import { AsyncCommand } from '../../../../../shared/common';
import { RunLastTasksScheduler } from '../../../../../shared/TasksScheduler';

class ModularConfiguratorSummaryState {
  public configurationCode = 'M0000';

  public isPurchaseEnabled = false;

  public readonly proceedToCheckoutCommand = new AsyncCommand(async () => {
    // TODO: Implement
  });

  public readonly proceedToRequestQuoteCommand = new AsyncCommand(async () => {
    // TODO: Implement
  });

  public readonly downloadAsPdfCommand = new AsyncCommand(async () => {
    // TODO: Implement
  });

  public readonly downloadZipCommand = new AsyncCommand(async () => {
    // TODO: Implement
  });

  private readonly generateCodeScheduler = new RunLastTasksScheduler(async () => {
    // TODO: Implement
  });

  public get isCodeGenerationInProgress() {
    return this.generateCodeScheduler.loading;
  }

  public get isCodeGenerationError() {
    return this.generateCodeScheduler.error;
  }

  public get shareUrl() {
    return this.shareConfiguratorUrlProvider.getUrl(this.configurationCode, 1);
  }

  public get pdfName() {
    // TODO: Implement
    return `Profim-Revo-${this.configurationCode}.pdf`;
  }

  public get zipName() {
    // TODO: Implement
    return `Profim-Revo-${this.configurationCode}.zip`;
  }

  public constructor(private readonly shareConfiguratorUrlProvider: IShareConfiguratorUrlProvider) {}
}

export default ModularConfiguratorSummaryState;
