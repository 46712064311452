import { computed, reaction } from 'mobx';

import { FinalVisualizationBuilder } from '../../../configurator/VisualizationBuilder';
import { ImageData } from '../../../data/model';
import { ModelViewerTranslation } from '../../../localization/SiteTranslation';
import Selector from '../../../shared/Selector';
import { appendQuery } from '../../common';
import { IImagePreloader } from '../../ImagePreloader';
import { LoadingIndicator } from '../../LoadingIndicator';
import { RunLastTasksScheduler } from '../../TasksScheduler';
import { UpdateVisualizationImagesTask } from '../../UpdateVisualizationImagesTask';
import ImageZoomModalState from '../ImageZoomModal/ImageZoomModalState';

export function modelViewerImagesBuilder(code: string) {
  const shots = ['front', 'left', 'back']; // TODO: get list of available shots form the server

  return shots.map((shot) => {
    const image: ImageDataWithShot = {
      name: `${code}`,
      url: FinalVisualizationBuilder.buildUrl(code, shot),
      shot: shot,
    };

    return image;
  });
}

export interface ImageDataWithShot extends ImageData {
  shot: string;
}

export default class ModelViewerState extends Selector<ImageDataWithShot> {
  public loadingIndicator: LoadingIndicator;
  public zoomModal: ImageZoomModalState;

  private preloadingVisualizationImagesOperation: Promise<void>;
  private updateVisualizationScheduler: RunLastTasksScheduler<UpdateVisualizationImagesTask, void>;

  @computed
  private get visualizationImageUrls() {
    return this.items.map((x) => x.data.url);
  }

  public constructor(
    private readonly imagePreloader: IImagePreloader,
    images: Array<ImageDataWithShot>,
    public readonly translation: ModelViewerTranslation,
  ) {
    super(images);

    this.loadingIndicator = new LoadingIndicator();

    this.zoomModal = new ImageZoomModalState(imagePreloader, (shot, width, height) => {
      const item = this.items.find((i) => i.data.shot === shot);
      if (item) {
        return Promise.resolve(appendQuery(item.data.url, [`width=${width}`, `height=${height}`]));
      }
    });

    this.zoomModal.shots.activeShotChanged.subscribe((shot) => {
      const item = this.items.find((x) => x.data.shot === shot);
      if (item) {
        this.setActiveItem(item);
      }
    });

    this.updateVisualizationScheduler = new RunLastTasksScheduler((task: UpdateVisualizationImagesTask) =>
      this.preloadAndUpdateVisualizationImages(task),
    );

    this.startPreloadingVisualizationImages(this.visualizationImageUrls);

    reaction(
      () => this.items,
      (items) => this.executeUpdateVisualizationImagesTask(items),
    );
  }

  public openZoomModal() {
    const shots = this.items.map((x) => x.data.shot);
    this.zoomModal.setData(shots, this.active.data.shot);
    this.zoomModal.open();
  }

  public setActiveItemByIndex = (index: number) => {
    const item = this.items[index];

    if (!item) {
      return;
    }

    this.setActiveItem(item);
  };

  public async finishPreloadingVisualizationImages() {
    await this.preloadingVisualizationImagesOperation;
    this.loadingIndicator.reset();
  }

  private executeUpdateVisualizationImagesTask(imagesItems: ModelViewerState['items']) {
    const task = new UpdateVisualizationImagesTask(imagesItems.map((x) => x.data.url));
    return this.updateVisualizationScheduler.run(task);
  }

  private startPreloadingVisualizationImages(urls: string[]) {
    this.loadingIndicator.start();
    this.preloadingVisualizationImagesOperation = this.loadVisualizationImages(urls);
  }

  private async preloadAndUpdateVisualizationImages(task: UpdateVisualizationImagesTask): Promise<void> {
    this.loadingIndicator.start();

    await this.loadVisualizationImages(task.urls);

    if (task.cancelled) {
      return;
    }

    this.loadingIndicator.reset();
  }

  private loadVisualizationImages(urls: string[]) {
    return this.imagePreloader.load(urls);
  }
}
