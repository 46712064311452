import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { IApiClient } from '../data/client';
import { HttpError } from '../data/HttpError';
import { GetProductStructureByIdQuery, ShoppingContext } from '../data/model';
import { BasePageState } from '../shared/BasePageState';
import { IMemento } from '../shared/common';
import { ILoadingIndicator, LoadingIndicator } from '../shared/LoadingIndicator';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreState } from '../StoreState';
import { ConfigurationCodeState } from './components/ConfigurationCode/ConfigurationCodeState';
import { RawConfiguratorState } from './components/RawConfigurator/RawConfiguratorState';
import EmbeddableRawConfiguratorPageQueryParser from './EmbeddableRawConfiguratorPageQueryParser';

export interface IEmbeddableRawConfiguratorPage extends IMemento {
  shoppingContext: ShoppingContext;
}

export class EmbeddableRawConfiguratorPageState extends BasePageState<IMemento> {
  public dataLoadingIndicator: ILoadingIndicator;
  public configuratorState: RawConfiguratorState;
  public configurationCodeState: ConfigurationCodeState;

  @observable
  public errorMessage: string = '';

  public isEmbedded: boolean;

  @computed
  public get isLoading(): boolean {
    return this.loadingIndicator.isLoading || this.dataLoadingIndicator.isLoading;
  }

  private shoppingContext: ShoppingContext;

  public constructor(
    private readonly client: IApiClient,
    private readonly loadingIndicator: ILoadingIndicator,
    private readonly productId: string,
    query: Map<string, string>,
  ) {
    super({ pageTitleFormat: 'Raw configurator', pageDescriptionFormat: '' });

    makeObservable(this);

    const { embed, size } = this.parseQuery(query);

    this.isEmbedded = embed;
    this.dataLoadingIndicator = new LoadingIndicator();
    this.configuratorState = new RawConfiguratorState(productId, size, this.client);
    this.configurationCodeState = new ConfigurationCodeState(this.configuratorState, client);

    this.notifyParentWhenEmbeddedInIFrame();
  }

  private notifyParentWhenEmbeddedInIFrame() {
    reaction(
      () => this.configurationCodeState.configurationCode,
      (configurationCode) => {
        if (configurationCode && window?.parent) {
          window.parent.postMessage({ type: 'configurationCode', configurationCode }, '*');
        }
      },
    );
  }

  public async onLoad(store: StoreState) {
    this.initialize(store.shoppingContext);
  }

  private initialize(shoppingContext: ShoppingContext) {
    this.shoppingContext = shoppingContext;
  }

  @action
  public async onLoadAdditionalData() {
    try {
      this.dataLoadingIndicator.start();

      const structureResponse = await this.client.send(
        new GetProductStructureByIdQuery({
          productId: this.productId,
          ignoreIrrelevantFeaturesAndOptions: true,
          shoppingContext: this.shoppingContext,
        }),
      );

      this.configuratorState.initialize(this.shoppingContext, structureResponse.structure);
    } catch (error) {
      if ((error as HttpError).status === StatusCodes.NotFound) {
        this.errorMessage = `Product structure not found for product ${this.productId}.
        Make sure it's properly configured in the Product Structure Extractor.`;
        return;
      }

      this.errorMessage = `Unable to load product structure for product ${this.productId}`;
    } finally {
      this.dataLoadingIndicator.stop();
    }
  }

  getMemento(): IEmbeddableRawConfiguratorPage {
    return {
      shoppingContext: this.shoppingContext,
    };
  }

  restoreMemento(memento: IEmbeddableRawConfiguratorPage) {
    this.initialize(memento.shoppingContext);
  }

  private parseQuery(query: Map<string, string>) {
    const { embed, size } = EmbeddableRawConfiguratorPageQueryParser.toModel(query);
    return { embed, size: size ?? 'medium' };
  }
}
