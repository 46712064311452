import { observer } from 'mobx-react';
import { Component } from 'react';

import Markdown from '../../app/shared/components/Markdown/Markdown';
import css from './ArticlePage.css';
import { ArticlePageState } from './ArticlePageState';

interface ArticlePageProps {
  state: ArticlePageState;
}

@observer
class ArticlePage extends Component<ArticlePageProps> {
  public constructor(props: ArticlePageProps) {
    super(props);
  }

  public render() {
    const { content } = this.props.state;

    return (
      <article className={css.ArticlePage}>
        <Markdown content={content} />
      </article>
    );
  }
}

export default ArticlePage;
