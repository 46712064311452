import { ReactNode } from 'react';
import AnimateHeight, { Height } from 'react-animate-height';

interface HeightAnimationProps {
  children: ReactNode;
  height: Height;
  animateOpacity?: boolean;
  className?: string;
  contentClassName?: string;
  duration?: number;
}

const HeightAnimation = ({
  animateOpacity,
  children,
  className,
  contentClassName,
  duration,
  height,
}: HeightAnimationProps) => {
  return (
    <AnimateHeight
      className={className}
      contentClassName={contentClassName}
      animateOpacity={animateOpacity}
      duration={duration}
      height={height}
    >
      {children}
    </AnimateHeight>
  );
};

export default HeightAnimation;
