declare global {
  interface Window {
    dataLayer: Array<{}>;
    google: google.maps.Map;
  }
}

export class GoogleTagManager {
  private dataLayer: Array<{}>;

  constructor() {
    this.dataLayer = window.dataLayer = window.dataLayer || [];
  }

  send(data: {}) {
    this.push(data);
    return this;
  }

  private push(data: {}) {
    this.dataLayer.push(data);
  }
}
