import classNames from 'classnames';

import capitalizeFirstLetter from '../../../app/shared/capitalizeFirstLetter';
import Link, { LinkProps } from '../../../app/shared/components/Link';
import css from './LogoFlokkHub.css';

export type LogoSize = 'medium' | 'large' | 'extraLarge';
export type LogoColor = 'dark' | 'light';

interface Props {
  className?: string;

  color?: LogoColor;
  size?: LogoSize;

  linkProps?: LinkProps;
}

const LogoFlokkHub = ({ linkProps, className, color = 'dark', size = 'medium' }: Props) => {
  const colorClassName = `color${capitalizeFirstLetter(color)}` as keyof typeof css;
  const sizeClassName = `size${capitalizeFirstLetter(size)}` as keyof typeof css;
  return (
    <Link className={classNames(css.LogoFlokkHub, className)} {...linkProps}>
      <div className={classNames(css.logo, css[colorClassName], css[sizeClassName])} />
    </Link>
  );
};

export default LogoFlokkHub;
