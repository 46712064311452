import { nameOf } from '../shared/common';
import IConfigurationPageQuery from './IConfigurationPageQuery';

export const OPTIONS: string = nameOf<IConfigurationPageQuery>('options');
export const ACCESSORIES = nameOf<IConfigurationPageQuery>('accessories');
export const STEP = nameOf<IConfigurationPageQuery>('step');
export const QUANTITY = nameOf<IConfigurationPageQuery>('quantity');
export const CODE: string = nameOf<IConfigurationPageQuery>('code');
export const SHOPPING_CART_ITEM_ID = nameOf<IConfigurationPageQuery>('shoppingCartItemId');
export const SWITCH = nameOf<IConfigurationPageQuery>('switch');
