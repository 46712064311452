import { observer } from 'mobx-react';

import { LazyImage } from './LazyImage';
import css from './OptionVariationPopup.css';

interface OptionVariationItemPopup {
  className?: string;
  imageUrl?: string;
  code: string;
}

const appendSizeQueryParams = (url: string, width: number, height: number) => {
  const sizeParams = `width=${width}&height=${height}`;
  const separator = url.indexOf('?') < 0 ? '?' : '&';
  return `${url}${separator}${sizeParams}`;
};

const OptionVariationItemPopup = ({ className, imageUrl, code }: OptionVariationItemPopup) => {
  return (
    <div className={className}>
      <div className={css.OptionVariationItemPopup}>
        <div className={css.wrapper}>
          <div className={css.imageWrapper}>
            {imageUrl && (
              <LazyImage
                placeholderUrl={appendSizeQueryParams(imageUrl, 100, 100)}
                lazyImageUrl={appendSizeQueryParams(imageUrl, 300, 300)}
              />
            )}
          </div>
          <div className={css.details}>
            <div className={css.code}>{code}</div>
          </div>
        </div>
        <div className={css.triangle} />
      </div>
    </div>
  );
};

export default observer(OptionVariationItemPopup);
