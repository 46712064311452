import { action, computed, makeObservable, observable, override } from 'mobx';

import AppSettings from '../../../app/AppSettings';
import { ForceDownloadUrlBuilder } from '../../../app/configurator/ForceDownloadUrlBuilder';
import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { IApiClient } from '../../../app/data/client';
import { GetWebShopProductsQuery, WebShopProductListData } from '../../../app/data/model';
import { GetWebShopCmsContentQuery, WebShopPageData } from '../../../app/data/model';
import { HomePageTranslation } from '../../../app/localization/SiteTranslation';
import { BasePageState } from '../../../app/shared/BasePageState';
import { CurrencyFormatter, PageMetadata } from '../../../app/shared/common';
import { GalleryState } from '../../../app/shared/components/Gallery/GalleryState';
import { StoreUrl } from '../../../app/shared/StoreUrl';
import { StoreState } from '../../../app/StoreState';
import logoImage from '../../../images/logoWithBackground.jpg';
import { ProductThumbnailProps } from './components/ProductList/ProductThumbnail/ProductThumbnail';
import { ProductVariationProps } from './components/ProductList/ProductThumbnail/ProductVariationList/ProductVariation/ProductVariation';

export interface IHomePageMemento {
  pageContent: WebShopPageData;
  products: WebShopProductListData[];
}

export class HomePageState extends BasePageState<IHomePageMemento> {
  public readonly gallery: GalleryState;
  public pageContent?: WebShopPageData;

  @observable.ref
  public products: WebShopProductListData[] = [];

  public constructor(
    settings: AppSettings,
    private readonly client: IApiClient,
    public readonly translation: HomePageTranslation,
  ) {
    super();

    makeObservable(this);

    const forceDownloadBuilder = new ForceDownloadUrlBuilder(settings.functionsUrl);
    this.gallery = new GalleryState(forceDownloadBuilder, 800, 600);
  }

  @override
  get metadata(): PageMetadata {
    return {
      title: this.translation.pageTitleFormat,
      description: this.translation.pageDescriptionFormat,
      imageUrl: logoImage,
    };
  }

  @computed
  get productThumbnails(): Omit<ProductThumbnailProps, 'translation'>[] {
    return this.products.map<Omit<ProductThumbnailProps, 'translation'>>((product) => ({
      name: product.name,
      linkUrl: StoreUrl.webShopProductPageUrl(product.id, { code: product.listingConfigurationCode }),
      imageSrc: FinalVisualizationBuilder.buildUrl(product.listingConfigurationCode),
      price: this.getFormattedLowestPrice(product),
      variations: product.listingOptionVariations.map<ProductVariationProps>((variation) => ({
        id: variation.id,
        name: variation.name,
        thumbnail: variation.thumbnail,
      })),
    }));
  }

  @action
  public async onLoad(store: StoreState) {
    const webShopCmsContentResponse = await this.client.send(
      new GetWebShopCmsContentQuery({ shoppingContext: store.shoppingContext }),
    );

    const webShopProductsResponse = await this.client.send(
      new GetWebShopProductsQuery({ shoppingContext: store.shoppingContext }),
    );

    this.initialize(webShopCmsContentResponse.pageContent, webShopProductsResponse.products);
  }

  public getMemento(): IHomePageMemento {
    return {
      pageContent: this.pageContent,
      products: this.products,
    };
  }

  public restoreMemento(memento: IHomePageMemento) {
    this.initialize(memento.pageContent, memento.products);
  }

  @action.bound
  private setProducts(products: WebShopProductListData[]) {
    this.products = products;
  }

  private getFormattedLowestPrice(product: WebShopProductListData) {
    return product.lowestPrice
      ? this.translation.productList.fromPriceLabel.interpolate([
          ['price', CurrencyFormatter.getFormattedPrice(product.lowestPrice)],
        ])
      : '';
  }

  private initialize(content: WebShopPageData, products: WebShopProductListData[]) {
    this.pageContent = content;

    if (content?.gallery?.images) {
      this.gallery.setImages(content.gallery.images);
    }

    this.setProducts(products);
  }
}
