import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import css from './Magnifier.css';

interface MagnifierProps {
  src: string;
  srcZoom: string;
}

const Magnifier = ({ src, srcZoom }: MagnifierProps) => {
  return (
    <InnerImageZoom
      className={css.element}
      fadeDuration={300}
      hideCloseButton
      hideHint
      imgAttributes={{ className: css.image }}
      src={src}
      zoomPreload
      zoomSrc={srcZoom}
      zoomType="hover"
    />
  );
};

export default Magnifier;
