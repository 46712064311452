import { action, makeObservable, observable } from 'mobx';

import AppSettings from '../../AppSettings';
import { IApiClient } from '../../data/client';
import {
  BaseModelBasicData,
  GetBasicProductByIdQuery,
  GetProductConfigurationByCodeQuery,
  GetProductConfigurationByCodeQueryResponse,
  HubSpotFormConfig,
} from '../../data/model';
import { HubSpotPageTranslation } from '../../localization/SiteTranslation';
import { StoreState } from '../../StoreState';
import { DefaultShareConfiguratorUrlProvider } from '../DefaultShareConfiguratorUrlProvider';
import { INavigationService } from '../NavigationService';
import { HubSpotFormState, IHubSpotFormMemento } from './HubSpotFormState';
import { ProductConfigurationQuery } from './ProductConfigurationQuery';
import { ProductConfigurationQueryParser } from './ProductConfigurationQueryParser';

export interface IHubSpotFormWithProductMemento<T extends HubSpotFormConfig = HubSpotFormConfig>
  extends IHubSpotFormMemento<T> {
  product: BaseModelBasicData;
  productConfigurationResponse?: GetProductConfigurationByCodeQueryResponse;
}

export abstract class HubSpotFormWithProductState<
  THubSpotState extends HubSpotFormConfig = HubSpotFormConfig,
  THubSpotMemento extends IHubSpotFormWithProductMemento<THubSpotState> = IHubSpotFormWithProductMemento<THubSpotState>,
  TQuery extends ProductConfigurationQuery = ProductConfigurationQuery,
> extends HubSpotFormState<THubSpotState, THubSpotMemento> {
  private readonly settings: AppSettings;

  @observable.shallow
  product: BaseModelBasicData;

  @observable.shallow
  productConfigurationResponse?: GetProductConfigurationByCodeQueryResponse;

  public query: TQuery;

  @action.bound
  backToSummary(): void {
    this.navigation.back();
  }

  public constructor(
    client: IApiClient,
    settings: AppSettings,
    navigation: INavigationService,
    pageTranslation: HubSpotPageTranslation,
  ) {
    super(client, navigation, pageTranslation);

    makeObservable(this);

    this.settings = settings;
    this.query = this.parseQuery(navigation.currentUrl.query);
  }

  protected parseQuery(rawQuery: Map<string, string>): TQuery {
    return ProductConfigurationQueryParser.toModel(rawQuery) as TQuery;
  }

  get isEmbedded() {
    return this.query.embed;
  }

  getName() {
    return this.product.displayName;
  }

  hasConfigurationCode() {
    return this.productConfigurationResponse != null;
  }

  getCode(): string | null {
    return this.query.configurationCode ?? null;
  }

  getShopUrl() {
    const code = this.getCode();
    const shareConfigurationUrlProvider = new DefaultShareConfiguratorUrlProvider(this.settings);
    return shareConfigurationUrlProvider.getUrl(code);
  }

  getStubImageUrl() {
    if (this.product) {
      const image = this.product.images.first();
      const code = this.getCode();

      if (image) {
        return image.url;
      }

      if (!code) {
        return this.product?.outline?.url;
      }
    }

    return null;
  }

  async onLoad(store: StoreState) {
    await super.onLoad(store);

    let productId = this.query.productId;
    let productConfigurationResponse: GetProductConfigurationByCodeQueryResponse = null;

    if (this.query.configurationCode) {
      productConfigurationResponse = await this.client.send(
        new GetProductConfigurationByCodeQuery({
          code: this.query.configurationCode,
          shoppingContext: this.shoppingContext,
        }),
      );

      productId = productConfigurationResponse.configuration.baseModelId;
    }

    const response = await this.client.send(
      new GetBasicProductByIdQuery({ productId: productId, shoppingContext: this.shoppingContext }),
    );
    this.productConfigurationResponse = productConfigurationResponse;
    this.product = response.product;
  }

  getMemento(): THubSpotMemento {
    const memento = super.getMemento() as THubSpotMemento;
    memento.product = this.product;
    memento.productConfigurationResponse = this.productConfigurationResponse;
    return memento;
  }

  restoreMemento(memento: THubSpotMemento) {
    this.product = memento.product;
    this.productConfigurationResponse = memento.productConfigurationResponse;
    super.restoreMemento(memento);
  }
}
