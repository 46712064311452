import React from 'react';

import { FeatureVariationDisplayName } from '../../../data/model';

const ObsoleteComponentsMap = ({ obsoleteComponents }: { obsoleteComponents: FeatureVariationDisplayName[] }) => {
  return obsoleteComponents.any() ? (
    <span>
      {obsoleteComponents.map((item, index) => (
        <React.Fragment key={item.variationId}>
          {hasNamesDefined(item) ? (
            <>
              <strong>{` ${item.featureName ?? ''}: ${item.fabricFamilyName ?? ''} ${
                item.variationName ?? ''
              }`}</strong>{' '}
              ({item.variationCode})
            </>
          ) : (
            <strong>{item.variationId}</strong>
          )}
          {setCommaOrDot(index, obsoleteComponents.length)}
        </React.Fragment>
      ))}
    </span>
  ) : null;
};

function hasNamesDefined(names: FeatureVariationDisplayName) {
  return names.featureName && names.variationName;
}

function setCommaOrDot(index: number, obsoleteComponentsLength: number) {
  return index === obsoleteComponentsLength - 1 ? '.' : ',';
}

export default ObsoleteComponentsMap;
