import classNames from 'classnames';

import SecondaryButton from '../../../shared/components/Button/SecondaryButton';
import Heading, { HeadingPosition } from '../../../shared/components/Typography/Heading/Heading';
import css from './MobileConfigurator.css';

interface MobileConfiguratorProps {
  className?: string;
}

const MobileConfigurator = ({ className }: MobileConfiguratorProps) => {
  return (
    <div className={classNames(css.container, className)}>
      <div className={css.inner}>
        <Heading level={2} position={HeadingPosition.Center}>
          {/* TODO: Replace with a translation */}
          Unable to load configurator
        </Heading>
        {/* TODO: Replace with a translation */}
        <p>This configurator is not available on mobile devices. Please switch to your computer.</p>
        <SecondaryButton>Go back</SecondaryButton>
      </div>
    </div>
  );
};

export default MobileConfigurator;
