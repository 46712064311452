import { observer } from 'mobx-react';
import * as React from 'react';
import ReactPaginate from 'react-paginate';

import { PaginationTranslation } from '../../../localization/SiteTranslation';
import Icon from '../Icon/Icon';
import { PaginationState } from '../Pagination/PaginationState';
import css from './ExtendedPagination.css';

interface ExtendedPaginationProps {
  state: PaginationState;
  translation: PaginationTranslation;
}

const ExtendedPagination: React.FC<ExtendedPaginationProps> = ({ state, translation }) => {
  const handlePageChange = (selectedItem: { selected: number }) => {
    window.scroll({ top: 0, behavior: 'smooth' });
    state.setCurrentPageIndex(selectedItem.selected);
  };

  return state.pagesCount > 1 ? (
    <nav className={css.ExtendedPagination}>
      <h2 className="visuallyHidden">{translation.title}</h2>
      <ReactPaginate
        disableInitialCallback={true}
        pageCount={state.pagesCount}
        forcePage={state.currentPageIndex}
        nextLabel={<Icon name="angleRight" />}
        previousLabel={<Icon name="angleLeft" />}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName={css.inner}
        disabledClassName={css.disabled}
        activeLinkClassName={css.active}
        breakClassName={css.item}
        breakLinkClassName={css.link}
        pageClassName={css.item}
        pageLinkClassName={css.link}
        previousClassName={css.item}
        previousLinkClassName={css.navLink}
        nextClassName={css.item}
        nextLinkClassName={css.navLink}
      />
    </nav>
  ) : null;
};

export default observer(ExtendedPagination);
