import css from './ProductPromotionInfo.css';

interface ProductPromotionInfoProps {
  promotionDescription: string;
}

const ProductPromotionInfo = ({ promotionDescription }: ProductPromotionInfoProps) => {
  return promotionDescription ? (
    <div className={css.container}>
      <div className={css.text}>{promotionDescription}</div>
    </div>
  ) : null;
};

export default ProductPromotionInfo;
