import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';
import stickybits from 'stickybits';

import IconButton from '../../../shared/components/Button/IconButton';
import Icon from '../../../shared/components/Icon/Icon';
import Link from '../../../shared/components/Link';
import List from '../../../shared/components/List/List';
import { MenuSectionProps } from '../StickyMenu/MenuSection';
import css from './NavigationBar.css';

export interface NavigationBarProps {
  id: string;
  heading: JSX.Element | string;
  containerClassName?: string;
  items?: Array<MenuSectionProps>;
  activeItemId?: string;
  handleOnClick?: (item: MenuSectionProps) => void;
}

@observer
export default class NavigationBar extends Component<NavigationBarProps, { open: boolean }> {
  constructor(props: NavigationBarProps) {
    super(props);

    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleOnMenuItemClick = this.handleOnMenuItemClick.bind(this);

    this.state = { open: false };
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  closeMenu() {
    this.setState({ open: false });
  }

  renderMenuItem(item: MenuSectionProps) {
    const classes = classNames(
      css.link,
      this.props.activeItemId && item.id === this.props.activeItemId && css.isActive,
    );
    return <Link className={classes}>{item.name}</Link>;
  }

  handleOnMenuItemClick(item: MenuSectionProps) {
    this.props.handleOnClick(item);
    this.closeMenu();
  }

  componentDidMount(): void {
    stickybits(`#${this.props.id}`);
  }

  render() {
    const { items = [], heading, containerClassName, activeItemId } = this.props;
    const activeItem = items.find((x) => x.id === activeItemId);

    return (
      <div className={classNames(css.NavigationBar, this.state.open && css.open)} id={this.props.id}>
        <div className={classNames(css.container, containerClassName)}>
          <div>
            <span className={css.heading}>{heading}</span>
            {activeItem && <span className={css.activeItem}>{activeItem?.name}</span>}
          </div>
          <IconButton
            className={css.button}
            icon={<Icon className={css.angleDown} name={this.state.open ? 'angleUp' : 'angleDown'} />}
            onClick={this.toggleMenu}
          />
          <List
            className={css.list}
            itemClassName={css.item}
            items={items}
            itemRenderer={this.renderMenuItem}
            onItemClick={this.handleOnMenuItemClick}
          />
        </div>
      </div>
    );
  }
}
