import classNames from 'classnames';
import { observer } from 'mobx-react';

import { NextButton, PrevButton } from '../Button/CustomButtons';
import css from './Pagination.css';
import { PaginationState } from './PaginationState';

interface PaginationProps {
  state: PaginationState;
}

const Pagination = (props: PaginationProps) => {
  const { state } = props;

  const paginationInfo = (
    <div className={css.paginationInfo}>
      Showing {state.startIndex + 1} to {state.lastIndex + 1} out of {state.totalCount} items
    </div>
  );

  const showPaginationControls = state.totalCount >= state.pageSize;

  return (
    <div className={css.Pagination}>
      <div className={classNames(css.container, !showPaginationControls && css.controlsHidden)}>
        {showPaginationControls && (
          <div className={css.paginationMainContainer}>
            <div className={css.paginationMain}>
              <PrevButton
                className={classNames(css.paginationButton, !state.canMoveLeft && css.paginationButtonHidden)}
                onClick={() => state.moveLeft()}
              />
              <span className={css.pageNumber}>{state.currentPageNumber}</span>
              <NextButton
                className={classNames(css.paginationButton, !state.canMoveRight && css.paginationButtonHidden)}
                onClick={() => state.moveRight()}
              />
            </div>
          </div>
        )}
        {paginationInfo}
      </div>
    </div>
  );
};

export default observer(Pagination);
