import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';
import ReactModal from 'react-modal';

import CloseButton from '../../Button/CloseButton';
import { ScrollLock } from '../../ScrollLock/ScrollLock';
import css from './ModalBasic.css';

export interface ModalBasicProps extends ReactModal.Props {
  onClose: () => void;
  children?: ReactNode;
  fullscreen?: boolean;
  closeButtonTranslation?: string;
}

@observer
export class ModalBasic extends Component<ModalBasicProps> {
  render() {
    const { children, onClose, fullscreen, className, overlayClassName, closeButtonTranslation, ...modalProps } =
      this.props;

    const overlayClasses =
      typeof overlayClassName === 'string'
        ? classNames(css.overlay, overlayClassName)
        : {
            base: classNames(css.overlay, overlayClassName?.base),
            afterOpen: classNames(css.afterOpen, overlayClassName?.afterOpen),
            beforeClose: classNames(css.beforeClose, overlayClassName?.beforeClose),
          };

    const modalClasses =
      typeof className === 'string'
        ? classNames(css.modal, className, fullscreen && css.fullscreen)
        : {
            base: classNames(css.modal, fullscreen && css.fullscreen, className?.base),
            afterOpen: className?.afterOpen || '',
            beforeClose: className?.beforeClose || '',
          };

    return (
      <ScrollLock isLocked={modalProps.isOpen}>
        <ReactModal
          className={modalClasses}
          onRequestClose={onClose}
          overlayClassName={overlayClasses}
          shouldFocusAfterRender={true}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          closeTimeoutMS={300}
          ariaHideApp={false}
          {...modalProps}
        >
          <ModalCloseButton closeButtonTranslation={closeButtonTranslation} onClose={onClose} />
          {children}
        </ReactModal>
      </ScrollLock>
    );
  }
}

interface ModalCloseButtonProps {
  closeButtonTranslation?: string;
  onClose: () => void;
}

const ModalCloseButton = observer(({ closeButtonTranslation, onClose }: ModalCloseButtonProps) => {
  return (
    <CloseButton className={css.ModalCloseButton} onClick={onClose}>
      {closeButtonTranslation ? closeButtonTranslation : 'Close'}
    </CloseButton>
  );
});
