import { observer } from 'mobx-react';
import { Component, ComponentType, createElement } from 'react';

import { AsyncCommand } from '../common';
import ActionButtonError from './ActionButtonError';
import Button, { ButtonProps } from './Button/Button';

interface ActionButtonProps<T extends ButtonProps, TCommandArg> extends ButtonProps {
  command: AsyncCommand<TCommandArg>;
  arg?: TCommandArg;
  className?: string;
  buttonElement?: ComponentType<T>;
  externalButtonProps?: T;
  errorMessage?: string;
  errorClassName?: string;
}

@observer
class ActionButton<T extends ButtonProps = ButtonProps, TCommandArg = undefined> extends Component<
  ActionButtonProps<T, TCommandArg>
> {
  render() {
    const {
      arg,
      command,
      buttonElement,
      children,
      errorMessage,
      errorClassName,
      externalButtonProps,
      disabled,
      type,
      ...buttonProps
    } = this.props;
    const actualErrorMessage = command.errorMessage || errorMessage;

    const options = {
      ...externalButtonProps,
      type: type ?? 'button',
      onClick: () => command.invoke(arg),
      disabled: disabled || command.disabled,
      isLoading: !!command.processing,
      error: command.errorOccurred,
      ...buttonProps,
    };
    return (
      <>
        {buttonElement ? createElement(buttonElement, options, children) : <Button {...options}>{children}</Button>}
        {command.errorOccurred && actualErrorMessage && (
          <ActionButtonError className={errorClassName}>{actualErrorMessage}</ActionButtonError>
        )}
      </>
    );
  }
}

export default ActionButton;
