import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../app/data/client';
import {
  ContactData,
  GetDealerInvitationsQuery,
  GetDealerMembersQuery,
  InvitationData,
  ShoppingContext,
} from '../../../app/data/model';
import { IEventAggregator } from '../../../app/shared/EventAggregator';
import { Form } from '../../../app/shared/Form';
import { LoadingIndicator } from '../../../app/shared/LoadingIndicator';
import { CustomerUpdatedEvent } from '../../cart/events/CustomerUpdatedEvent';
import { UsersTabTranslation } from '../../localization/SiteTranslation';
import { ChangeUserRolesModalState } from './ChangeUserRolesModal/ChangeUserRolesModalState';
import { ConfirmContactRemovalModalState } from './ConfirmContactRemovalModalState';
import { ConfirmInvitationRemovalModalState } from './ConfirmInvitationRemovalModalState';
import { InviteNewUserModalState } from './InviteNewUserModal/InviteNewUserModalState';

export class UsersTabState extends Form {
  public readonly inviteNewUserModal: InviteNewUserModalState;
  public readonly confirmUserRemovalModal: ConfirmContactRemovalModalState;
  public readonly confirmInvitationRemovalModal: ConfirmInvitationRemovalModalState;
  public readonly changeUserRolesModal: ChangeUserRolesModalState;

  @observable.ref
  public contacts: ContactData[] = [];

  @observable.ref
  public invitations: InvitationData[] = [];

  public readonly loadingIndicator = new LoadingIndicator();

  constructor(
    private readonly client: IApiClient,
    private readonly shoppingContext: ShoppingContext,
    private readonly eventAggregator: IEventAggregator,
    public readonly translation: UsersTabTranslation,
  ) {
    super();

    makeObservable(this);

    this.inviteNewUserModal = new InviteNewUserModalState(
      client,
      shoppingContext,
      this.translation.inviteNewUsersModal,
    );
    this.confirmUserRemovalModal = new ConfirmContactRemovalModalState(client, shoppingContext);
    this.confirmInvitationRemovalModal = new ConfirmInvitationRemovalModalState(
      client,
      shoppingContext,
      this.translation.confirmInvitationRemovalModal,
    );
    this.changeUserRolesModal = new ChangeUserRolesModalState(
      client,
      shoppingContext,
      this.translation.changeUserRolesModal,
    );

    this.inviteNewUserModal.invitationSentEvent.subscribe(this.load);
    this.confirmUserRemovalModal.contactRemovedEvent.subscribe(this.load);
    this.confirmInvitationRemovalModal.invitationRemovedEvent.subscribe(this.load);
    this.changeUserRolesModal.rolesUpdatedEvent.subscribe(this.load);

    this.eventAggregator.subscribe(CustomerUpdatedEvent, this.load);
  }

  public load = async () => {
    this.loadingIndicator.start();

    const [usersResponse, invitationsResponse] = await Promise.all([
      this.client.send(new GetDealerMembersQuery({ shoppingContext: this.shoppingContext })),
      this.client.send(new GetDealerInvitationsQuery({ shoppingContext: this.shoppingContext })),
      this.inviteNewUserModal.load(),
      this.changeUserRolesModal.load(),
    ]);

    this.setContacts(usersResponse.members);
    this.setInvitations(invitationsResponse.invitations);

    this.loadingIndicator.stop();
  };

  @action.bound
  public setContacts(users: ContactData[]) {
    this.contacts = users;
  }

  @action.bound
  public setInvitations(invitations: InvitationData[]) {
    this.invitations = invitations;
  }

  public openContactRemovalConfirmation = (contact: ContactData) => {
    this.confirmUserRemovalModal.setContact(contact);
    this.confirmUserRemovalModal.open();
  };

  public openInvitationRemovalConfirmation = (invitation: InvitationData) => {
    this.confirmInvitationRemovalModal.setInvitation(invitation);
    this.confirmInvitationRemovalModal.open();
  };

  public openChangeUserRoles = (contact: ContactData) => {
    this.changeUserRolesModal.setContact(contact);
    this.changeUserRolesModal.open();
  };
}
