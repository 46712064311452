import { observer } from 'mobx-react';
import { createElement } from 'react';

import ConfiguratorTabList from '../../../../../configurator/components/ConfiguratorTabList/ConfiguratorTabList';
import { TabsState } from '../../../../../configurator/components/ConfiguratorTabs/TabsState';
import { TabState } from '../../../../../configurator/components/ConfiguratorTabs/TabState';
import CustomScrollbar from '../../../../../shared/components/CustomScrollbar/CustomScrollbar';
import css from './ModularComponentConfiguratorTabs.css';

interface ModularComponentConfiguratorTabsProps {
  state: TabsState;
}

const ModularComponentConfiguratorTabs = observer(({ state }: ModularComponentConfiguratorTabsProps) => {
  const handleTabClick = (nextTab: TabState) => {
    const prevTab = state.activeTab;

    if (nextTab.code === prevTab.code) {
      return;
    }

    state.setActiveTab(nextTab.code);
  };

  return (
    <div className={css.container}>
      <ConfiguratorTabList className={css.tabs} items={state.dynamicTabs} onItemClick={handleTabClick} />
      <div className={css.panels}>
        {state.dynamicTabs.map((tab) => (
          <div className={css.panel} hidden={!tab.isActive} key={tab.code}>
            <CustomScrollbar viewClassName={css.inner}>
              {createElement(tab.component, { ...tab.componentProps })}
            </CustomScrollbar>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ModularComponentConfiguratorTabs;
