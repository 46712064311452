import { action, makeObservable, observable } from 'mobx';

import { EmptyPageState } from '../b2b/routing/EmptyPageState';
import AppSettings from './AppSettings';
import { IAuthenticationService } from './auth/AuthenticationService';
import { IApiClient } from './data/client';
import { IIpAddressProvider } from './data/IpAddressProvider';
import { ICustomerContextLocalStorage } from './data/localStorage';
import {
  GetAvailableStoreNamesQuery,
  GetStoreContextQuery,
  GetStoreContextQueryResponse,
  StoreData,
} from './data/model';
import IPreferredStoreProvider from './data/StoreProviders/IPreferredStoreProvider';
import { InitialStoreSelectorModalState } from './layout/InitialStoreSelectorModal/InitialStoreSelectorModalState';
import NavigationState, { INavigationMemento } from './layout/NavigationState';
import StoreAndLanguageSelectorState from './layout/StoreAndLanguageSelector/StoreAndLanguageSelectorState';
import StoreSelectionState from './layout/StoreSelectionState';
import { IWarningLocalStorage } from './layout/Warnings/WarningLocalStorage';
import { SiteTranslation } from './localization/SiteTranslation';
import { ITranslationResolver } from './localization/TranslationResolver';
import { IMemento, IPageState } from './shared/common';
import { NoConnectionModalState } from './shared/components/NoConnectionModal/NoConnectionModalState';
import RedirectionWarningModalState from './shared/components/RedirectionWarningModal/RedirectionWarningModalState';
import {
  ConfigurationCodeResolutionService,
  IConfigurationCodeResolutionService,
} from './shared/ConfigurationCodeResolutionService';
import { IEventAggregator } from './shared/EventAggregator';
import { ExternalLinks } from './shared/externalLinks';
import { IImagePreloader } from './shared/ImagePreloader';
import { ILoadingIndicator, LoadingIndicator } from './shared/LoadingIndicator';
import { INavigationService } from './shared/NavigationService';
import { StoreUrlBuilder } from './shared/StoreUrlBuilder';
import { StoreState } from './StoreState';

export interface IAppMemento extends IMemento {
  statusCode: number;
  settings: AppSettings;
  navigationMemento: INavigationMemento;
  storeResponse: GetStoreContextQueryResponse;
  currentPageMemento: IMemento;
}

export interface IAppState {
  pages: Array<IPageState>;
  currentPage: IPageState;
}

export class AppState implements IAppState {
  settings: AppSettings;
  navigationState: NavigationState;
  client: IApiClient;
  navigation: INavigationService;
  customerContextLocalStorage: ICustomerContextLocalStorage;
  warningLocalStorage: IWarningLocalStorage;
  imagePreloader: IImagePreloader;
  codeResolver: IConfigurationCodeResolutionService;
  eventAggregator: IEventAggregator;
  authenticationService: IAuthenticationService;

  storeAndLanguageSelector: StoreAndLanguageSelectorState;
  storeSelectionState: StoreSelectionState;
  initialStoreSelectionModal: InitialStoreSelectorModalState;

  noConnectionModal: NoConnectionModalState;

  storeResponse: GetStoreContextQueryResponse;
  store: StoreState;

  @observable.shallow pages: Array<IPageState>; // TODO: change to map, key is an url, value is a page
  @observable.ref currentPage: IPageState;
  @observable.ref translation: SiteTranslation;
  @observable availableStores: string[];

  loadingPageIndicator: ILoadingIndicator;
  loadingImagesIndicator: ILoadingIndicator;
  preferredStoreProvider: IPreferredStoreProvider;
  ipProvider: IIpAddressProvider;
  redirectionWarningModalState: RedirectionWarningModalState;

  protected readonly translationResolver: ITranslationResolver;

  constructor(
    settings: AppSettings,
    client: IApiClient,
    navigationState: NavigationState,
    navigation: INavigationService,
    customerContextLocalStorage: ICustomerContextLocalStorage,
    warningLocalStorage: IWarningLocalStorage,
    imagePreloader: IImagePreloader,
    preferredStoreProvider: IPreferredStoreProvider,
    ipProvider: IIpAddressProvider,
    eventAggregator: IEventAggregator,
    authenticationService: IAuthenticationService,
    translationResolver: ITranslationResolver,
  ) {
    makeObservable(this);
    this.translationResolver = translationResolver;
    this.translation = this.translationResolver.default;
    this.navigationState = navigationState;
    this.settings = settings;
    this.client = client;
    this.navigation = navigation;
    this.customerContextLocalStorage = customerContextLocalStorage;
    this.warningLocalStorage = warningLocalStorage;
    this.imagePreloader = imagePreloader;
    this.preferredStoreProvider = preferredStoreProvider;
    this.ipProvider = ipProvider;
    this.eventAggregator = eventAggregator;
    this.authenticationService = authenticationService;

    this.pages = new Array<IPageState>();
    this.currentPage = new EmptyPageState(this.client);

    this.loadingPageIndicator = new LoadingIndicator();
    this.loadingImagesIndicator = new LoadingIndicator();
    this.noConnectionModal = new NoConnectionModalState(navigation);
    this.storeSelectionState = new StoreSelectionState(
      preferredStoreProvider,
      this.translation.layout.topBar.languages,
    );
    this.initialStoreSelectionModal = new InitialStoreSelectorModalState(this.storeSelectionState);
    this.redirectionWarningModalState = new RedirectionWarningModalState(5, this.navigation);

    this.onConnectionError = this.onConnectionError.bind(this);
    this.client.connectionError.subscribe(this.onConnectionError);

    this.codeResolver = new ConfigurationCodeResolutionService(client, navigation, this.loadingPageIndicator);

    this.onStoreChange = this.onStoreChange.bind(this);
    this.storeSelectionState.storeChanged.subscribe(this.onStoreChange);
  }

  unload() {
    this.client.connectionError.unsubscribe(this.onConnectionError);
  }

  @action
  setCurrentPage(page: IPageState) {
    this.currentPage = page;
  }

  @action
  async loadStores(): Promise<string[]> {
    // Load only once
    if (!this.availableStores) {
      const availableStoresResponse = await this.client.send(new GetAvailableStoreNamesQuery());
      this.availableStores = availableStoresResponse.availableStoreNames;
    }
    return this.availableStores;
  }

  protected async getStore(storeId: string, countryCode: string, languageCode: string, preserveLanguage?: boolean) {
    return await this.client.send(
      new GetStoreContextQuery({
        ip: this.ipProvider.getIp(),
        storeId: storeId,
        countryCode: countryCode,
        customer: this.customerContextLocalStorage.getAnonymousCustomer(),
        languageCode: languageCode,
        preserveLanguage: preserveLanguage,
      }),
    );
  }

  @action
  async loadStore(
    storeId: string,
    countryCode: string,
    languageCode: string,
    preserveLanguage?: boolean,
  ): Promise<StoreState> {
    if (!this.storeResponse) {
      // load only once
      this.setTranslation(languageCode); // Use deduced language before store is loaded
      const storeResponse = await this.getStore(storeId, countryCode, languageCode, preserveLanguage);
      this.initializeStore(storeResponse);
      await this.navigationState.load(storeResponse.shoppingContext); // TODO: is this the best place?
    }

    return this.store;
  }

  restoreStoreMemento(memento: IAppMemento) {
    this.settings = memento.settings;
    this.navigationState.restoreMemento(memento.navigationMemento);
    this.initializeStore(memento.storeResponse);
  }

  protected initializeStore(storeResponse: GetStoreContextQueryResponse) {
    StoreUrlBuilder.setContext(storeResponse.shoppingContext);

    this.availableStores = storeResponse.availableStores.map((x) => x.id.toLowerCase());
    this.storeResponse = storeResponse;
    this.store = new StoreState(this.storeResponse, this.eventAggregator);

    this.setTranslation(this.store.shoppingContext.languageCode);
    this.storeSelectionState.setTranslation(this.translation.layout.topBar.languages);
    this.storeSelectionState.setStoreResponse(
      this.store,
      storeResponse.availableStores,
      storeResponse.storeContext.store,
      storeResponse.storeContext.language,
    );
    this.initialStoreSelectionModal.initialize();
    this.storeAndLanguageSelector = new StoreAndLanguageSelectorState(
      this.storeSelectionState,
      this.translation.storeAndLanguageSelector,
    );
    this.redirectionWarningModalState.initialize(this.store.storeResponse.storeContext.showroomConfig.homePageUrl);

    this.customerContextLocalStorage.setAnonymousCustomer(this.storeResponse.shoppingContext.customer);

    ExternalLinks.initialize(storeResponse.storeContext.store.countryCode, storeResponse.storeContext.language.code);
  }

  @action setTranslation(languageCode: string) {
    this.translation = this.translationResolver.resolve(languageCode);
  }

  @action
  async loadPage(page: IPageState) {
    if (this.currentPage) {
      this.currentPage.unload();
    }

    await page.onLoad(this.store);
    this.initializePage(page);
  }

  getMemento(statusCode: number) {
    let memento: IAppMemento = {
      statusCode: statusCode,
      settings: this.settings,
      navigationMemento: this.navigationState.getMemento(),
      storeResponse: this.storeResponse,
      currentPageMemento: this.currentPage ? this.currentPage.getMemento() : null,
    };

    return memento;
  }

  @action
  restorePageMemento(page: IPageState, memento: IAppMemento) {
    page.restoreMemento(memento.currentPageMemento);
    this.initializePage(page);
  }

  @action
  initializePage(page: IPageState) {
    this.currentPage = page;
  }

  private onConnectionError() {
    this.noConnectionModal.open();
  }

  private onStoreChange({ store, languageCode }: { store: StoreData; languageCode: string }) {
    const nextUrl = StoreUrlBuilder.changeStoreAndLanguage(this.navigation.currentUrl, store.id, languageCode);
    this.navigation.redirect(nextUrl.toString());
  }
}

export default AppState;
