import { observer } from 'mobx-react';
import { Component } from 'react';

import Button from '../../app/shared/components/Button/Button';
import HeaderLevel3 from '../../app/shared/components/Typography/HeaderLevel3';
import LogoFlokkHub from '../menu/LogoFlokkHub/LogoFlokkHub';
import css from './InvitationPage.css';
import { InvitationState } from './InvitationState';

interface Props {
  state: InvitationState;
}

@observer
export class InvitationPage extends Component<Props> {
  render() {
    const { state } = this.props;
    const translation = state.translation;

    return (
      <div className={css.InvitationPage}>
        <div className={css.wrapper}>
          <LogoFlokkHub size="extraLarge" className={css.logo} />
          {state.invitationStep === 'VERIFIED' && (
            <>
              <HeaderLevel3>{translation.headerText}</HeaderLevel3>
              {state.isAuthenticated && (
                <>
                  <div>
                    {translation.loggedAsText} <strong>{state.currentUser?.email}</strong>
                  </div>
                  <div>{translation.useCurrentAccountOrCreateNewText}</div>
                  <div className={css.selectAuthButtonsWrapper}>
                    <Button onClick={state.loginAsCurrentUser}>{translation.useCurrentAccountButton}</Button>
                    <Button onClick={state.signUpAsNewUser}>{translation.createNewAccountButton}</Button>
                  </div>
                </>
              )}
              {!state.isAuthenticated && (
                <>
                  <div>{translation.fullfillInvitationText}</div>
                  <Button onClick={() => state.proceedAnonymousUser(false)}>{translation.signUpForFlokkHub}</Button>
                  {state.invitationRequest.matchingContactExists && (
                    <>
                      <div>{translation.haveAccountInformationText}</div>
                      <Button onClick={() => state.proceedAnonymousUser(true)}>
                        {translation.logInWithExistingAccountText}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {state.invitationStep === 'EXPIRED' && (
            <div>
              <HeaderLevel3>{translation.expiredInvitationInformationText}</HeaderLevel3>
            </div>
          )}
          {state.invitationStep === 'INVALID' && (
            <>
              <HeaderLevel3>{translation.invitationLinkNotValidText}</HeaderLevel3>
              <div>{translation.invitationLinkNotValidInformationText}</div>
              <div>{translation.ifProblemStillOccursContactFlokkText}</div>
            </>
          )}
          {state.invitationStep === 'ERROR' && (
            <>
              <HeaderLevel3>{translation.somethingWrongWithInvitationText}</HeaderLevel3>
              <div>{translation.notAbleToProcessRequestText}</div>
            </>
          )}
        </div>
      </div>
    );
  }
}
