import { observer } from 'mobx-react';

import { ProductInactiveWarningTranslation } from '../../localization/SiteTranslation';
import { WarningComponentWithReason } from './WarningComponentWithReason';

@observer
export class ProductInactiveWarning extends WarningComponentWithReason<string, ProductInactiveWarningTranslation> {
  getMessage(): string {
    const {
      state: { args: reasonForInactivation },
      translation,
    } = this.props;

    let message: string;

    switch (reasonForInactivation) {
      case 'Discontinued':
        message = translation.discontinued;
        break;

      default:
        if (reasonForInactivation) {
          message = translation.genericWithReason.interpolate([['reason', reasonForInactivation]]);
        } else {
          message = translation.generic;
        }

        break;
    }

    return message;
  }
}
