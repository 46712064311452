import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import ChairNavigation from '../../../configurator/components/ConfiguratorChair/ChairNavigation';
import { ChairVisualizationTranslation, ZoomModalTranslation } from '../../../localization/SiteTranslation';
import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import Magnifier from '../Magnifier/Magnifier';
import Modal from '../Modal/Modal';
import NavigationArrows from '../NavigationArrows/NavigationArrows';
import Swipe from '../Swipe';
import css from './ImageZoomModal.css';
import ImageZoomModalState from './ImageZoomModalState';

interface ImageZoomModalProps {
  state: ImageZoomModalState;
  translation: ZoomModalTranslation;
  visualizationTranslation: ChairVisualizationTranslation;
}

@observer
export default class ImageZoomModal extends Component<ImageZoomModalProps, {}> {
  render() {
    const { translation, visualizationTranslation } = this.props;
    const { largeImageUrl, smallImageUrl, loadingImageIndicator, shots } = this.props.state;
    const loaderClasses = loadingImageIndicator.isLoading && css.loaderFadeIn;

    const render = loadingImageIndicator.isLoading ? (
      <ThreeDotsLoader className={classNames(css.partialImageLoader, loaderClasses)} size="medium" />
    ) : (
      largeImageUrl &&
      smallImageUrl && (
        <Swipe onSwipeRight={shots.nextSlide} onSwipeLeft={shots.prevSlide} className={css.container}>
          <Magnifier src={smallImageUrl} srcZoom={largeImageUrl} />
        </Swipe>
      )
    );

    return (
      <Modal
        modal={this.props.state}
        headerWrapperClassName={css.modalHeader}
        contentClassName={css.modalContent}
        translation={translation}
        fullScreen
      >
        <div className={css.ImageZoomModal}>
          <NavigationArrows
            className={css.navigationArrow}
            handleOnClickNext={shots.nextSlide}
            handleOnClickPrev={shots.prevSlide}
          />
          {render}
          <ChairNavigation
            shots={this.props.state.shots}
            className={css.chairNavigation}
            translation={visualizationTranslation}
            displayZoomHint
          />
        </div>
      </Modal>
    );
  }
}
