import classNames from 'classnames';
import { Fragment } from 'react';

import { SelectOptionsStepTranslation } from '../../../localization/SiteTranslation';
import { TextLabelSmall } from '../../../shared/components/Typography/TextLabels';
import css from './OptionGroupTitle.css';

interface OptionGroupTitleProps {
  title: string;
  isSelected?: boolean;
  priceComponent?: JSX.Element;
  className?: string;
  translation: SelectOptionsStepTranslation;
}

const OptionGroupTitle = ({ title, isSelected, priceComponent, className, translation }: OptionGroupTitleProps) => {
  const groupTitlePostfix = priceComponent && (
    <Fragment>
      {isSelected && <TextLabelSmall className={css.selected}>{translation.textSelected}</TextLabelSmall>}
      {!isSelected && <div className={css.priceDiff}>{priceComponent}</div>}
    </Fragment>
  );
  const classes = classNames(css.OptionGroupTitle, className);
  return (
    <div className={classes}>
      <div className={css.groupName}>{title}</div>
      <div className={css.line} />
      {groupTitlePostfix}
    </div>
  );
};

export default OptionGroupTitle;
