import {
  CartItemActionsTranslation,
  CartItemDetailsTranslation,
  CartSummaryTranslation,
  ShoppingCartItemBasicInfoTranslation,
  ShoppingCartItemPanelTranslation,
  SiteTranslation
} from "./SiteTranslation";
import { ActionMenuTranslation } from "../../app/localization/SiteTranslation";
import baseTranslation from "../../app/localization/de-de";

const actionMenuTranslation: ActionMenuTranslation = {
  title: "Aktionen",
};

const cartItemActionsTranslation: CartItemActionsTranslation = {
  actionMenu: actionMenuTranslation,
};

const shoppingCartItemBasicInfoTranslation: ShoppingCartItemBasicInfoTranslation = {
  cartItemDetails: {
    configCodeLabel: "Konfig. Code",
    noItemsLabel: "Anzahl Artikel",
    listPriceLabel: "Listenpreis",
    netPriceLabel: "Ihr Nettopreis"
  }
};

const shoppingCartItemPanelTranslation: ShoppingCartItemPanelTranslation = {
  cartItemActions: cartItemActionsTranslation,
};

const cartSummaryTranslation: CartSummaryTranslation = {
  priceBeforeVatLabel: "Preis ohne MwSt.",
  vatLabelFormat: "MwSt. ({taxRate}%)",
  priceIncludingVatLabel: " Preis inklusive MwSt.",
  deliveryFeeLabel: "Liefergebühr",
  noItemsLabel: "Anzahl Artikel",
  subtotalHeader: "Zwischensumme",
  accordingToAgreementText: "Gemäß Vereinbarung",
  surchargesAnnotationText: '*Surcharges may apply to the order. Details will be specified in the invoice.',
};

const cartItemDetailsTranslation: CartItemDetailsTranslation = {
  configCodeLabel: "Konfig. Code",
  noItemsLabel: "Anzahl Artikel",
  listPriceLabel: "Listenpreis",
  netPriceLabel: "Ihr Nettopreis"
};

const translation: SiteTranslation = {
  ...baseTranslation,
  ...{
    b2bHomePage: {
      pageTitleFormat: "",
      pageDescriptionFormat: "",
      onboarding: {
        headerText: "Willkommen",
        welcomeText:
          "Wir freuen uns, Sie begrüßen zu dürfen. Im Folgenden finden Sie Tipps, die Ihnen den Einstieg erleichtern.",
        onboardingCards: {
          configureChairTitle: "Stuhl konfigurieren",
          configureChairText: "Stuhl konfigurieren",
          placeAnOrderTitle: "Bestellung aufgeben",
          placeAnOrderText: "Bestellung aufgeben",
          manageAndTrackTitle: "Verwalten und verfolgen",
          manageAndTrackText: "Verwalten und verfolgen",
          selectAChairTextPart1: "Wählen Sie einen Stuhl unter",
          selectAChairTextPart2: "Produkte",
          selectAChairTextPart3: "und passen Sie ihn im Konfigurator an die Bedürfnisse Ihres Kunden an.",
          addConfigToFavoritesTextPart1: "Sie können Ihre Konfiguration abspeichern unter",
          addConfigToFavoritesTextPart2: "Favoriten",
          placeChairToCartTextPart1: "Sobald Ihr Stuhl zur Bestellung bereit ist, legen Sie ihn in Ihren ",
          placeChairToCartTextPart2: "Warenkorb",
          manageOngoingOrdersTextPart1: "Sie können laufende Bestellungen einfach verwalten unter ",
          manageOngoingOrdersTextPart2: "Bestellübersicht",
          manageOngoingOrdersTextPart3: ", und sich außerdem vergangene Bestellungen anzeigen lassen.",
          receiveNotificationsFromTrackingText:
            "Über das Tracking-System erhalten Sie jedes Mal eine Benachrichtigung, wenn sich der Status Ihrer Bestellung verändert."
        }
      }
    },
    profilePage: {
      pageTitleFormat: "",
      pageDescriptionFormat: "",
      personalTabTitle: "Persönliche Daten",
      usersTabTitle: "Benutzer",
      roles: {
        admin: "Administrator",
        buyer: "Käufer",
        flokkSales: "Flokk-Verkauf",
        flokkSupport: "Flokk-Unterstützung",
        reader: "Leser"
      },
      personalTabContent: {
        emailLabel: "E-Mail",
        emailNotificationsHeader: "E-Mail Benachrichtigungen",
        emailNotifications: {
          orderCreated: "Bestellung erstellt",
          orderStatusChanged: "Bestellstatus geändert",
          orderCancelled: "Bestellung storniert",
          orderNotifyOnlyMyOrders: "Nur meine Bestellungen"
        },
        firstNameLabel: "Vorname",
        lastNameLabel: "Nachname",
        phoneNumberLabel: "Telefonnummer",
        saveChangesButton: "Änderungen speichern",
        basicInformationHeader: "Allgemeine Informationen",
        languageSelectorLabel: "Sprache",
        languages: {
          deDe: "Deutsch",
          enGb: "Englisch",
          frFr: "Französisch"
        },
        passwordResetBlock: {
          passwordResetHeader: "Passwort zurücksetzen",
          emailSentText:
            "Wir haben Ihnen eine E-Mail zum Zurücksetzen des Passworts gesendet. Bitte überprüfen Sie Ihr Postfach.",
          sendPasswordResetEmailAgainText: "E-Mail zum Zurücksetzen des Passworts erneut senden",
          sendPasswordResetEmailText: "E-Mail zum Zurücksetzen des Passworts senden",
          confirmPasswordResetHeader: "Zurücksetzen des Passworts bestätigen",
          areYouSureResetPasswordText: "Sind Sie sicher, dass Sie Ihr Passwort zurücksetzen wollen?",
          cancelButton: "Abbrechen",
          yesButton: "Ja"
        }
      },
      usersTab: {
        removeUserTitle: "Benutzer entfernen",
        inviteNewUserButton: "Neuen Benutzer einladen",
        editRolesLabel: "Funktionen verwalten",
        removeLabel: "Entfernen",
        removeContactFromOrganisationTextPart1: "Sind Sie sich sicher mit der Entfernung von ",
        removeContactFromOrganisationTextPart2: "aus Ihrer Organisation?",
        invitationsTable: {
          pendingInvitationsHeader: "Ausstehende Einladungen",
          userNameHeader: "Benutzername",
          emailHeader: "E-Mail",
          expiresAtHeader: "Läuft ab am",
          contextMenuHeader: "",
          removeLabel: 'Entfernen',
          actionMenu: actionMenuTranslation,
        },
        usersTable: {
          rolesHeader: "Funktionen",
          userNameHeader: "Benutzername",
          contextMenuHeader: ""
        },
        inviteNewUsersModal: {
          inviteNewUserHeader: "Neuen Benutzer einladen",
          firstNameLabel: "Vorname",
          lastNameLabel: "Nachname",
          emailLabel: "E-Mail",
          repeatEmailLabel: "E-Mail wiederholen",
          grantedRolesLabel: "Erteilte Funktionen",
          sendInvitationButton: "Einladung senden"
        },
        confirmInvitationRemovalModal: {
          areYouSureRemoveInvitationText: "Sind Sie sich sicher mit dem Zurücknehmen der Einladung für ",
          title: "Einladung zurücknehmen"
        },
        changeUserRolesModal: {
          selectRolesText: "Bitte wählen Sie die Funktionen für ",
          title: "Funktionen bearbeiten"
        },
        actionMenu: actionMenuTranslation,
      }
    },
    invitationPage: {
      pageTitleFormat: "",
      pageDescriptionFormat: "",
      headerText: "Sie wurden zu Flokk HUB eingeladen!",
      loggedAsText: "Sie sind derzeit bei Flokk Hub eingeloggt als",
      useCurrentAccountOrCreateNewText:
        "Möchten Sie das aktuelle Konto verwenden oder ein neues Konto erstellen, um den Einladungsprozess durchzuführen?",
      useCurrentAccountButton: "Das aktuelle Konto verwenden",
      createNewAccountButton: "Ein neues Konto erstellen",
      fullfillInvitationText:
        "Um den Einladungsprozess im nächsten Schritt durchzuführen, müssen Sie sich mit einem bestehenden Konto anmelden oder ein neues Konto erstellen.",
      signUpForFlokkHub: "Für Flokk Hub registrieren",
      haveAccountInformationText:
        " Wenn Sie bereits ein Konto bei Flokk Hub haben, nutzen Sie dieses um sich anzumelden",
      logInWithExistingAccountText: "Mit bestehendem Konto anmelden",
      expiredInvitationInformationText:
        "Ihre Einladung ist abgelaufen. Bitte wenden Sie sich an das Flokk-Team, um eine neue Einladung zu erhalten",
      invitationLinkNotValidText: "Ihr Einladungslink ist ungültig",
      invitationLinkNotValidInformationText:
        "Die Einladung, die Sie verwenden möchten, existiert nicht. Bitte überprüfen Sie, ob der verwendete Link von Flokk gesendet wurde.",
      ifProblemStillOccursContactFlokkText:
        "Wenn das Problem weiterhin besteht, kontaktieren Sie bitte das Flokk-Team, um eine neue Einladung zu erhalten.",
      somethingWrongWithInvitationText: "Bei Ihrer Einladung ist etwas schiefgelaufen",
      notAbleToProcessRequestText:
        "Wir können Ihre Anfrage nicht verarbeiten. Bitte versuchen Sie es später erneut oder kontaktieren Sie das Flokk-Team."
    },
    catalogPage: {
      ...baseTranslation.catalogPage,
      familyItemAvailabilityModal: {
        closeButton: "Schließen",
        familyItemIsAvailableOnlyForConfiguration:
          "Dieses Produkt ist noch nicht über Flokk Hub erhältlich. Sie können es immer noch konfigurieren und zu Ihren Favoriten hinzufügen.",
        familyItemIsUnavailable: "Dieses Produkt kann noch nicht über Flokk Hub konfiguriert oder gekauft werden."
      },
      filterBar: {
        ...baseTranslation.catalogPage.filterBar,
        results: "*Product families: {results}"
      }
    },
    cartPage: {
      pageTitleFormat: "",
      pageDescriptionFormat: "",
      orderSummaryText: "Bestellübersicht",
      goBackButton: "Zurück",
      deliveryAddressText: "Lieferadresse",
      deliveryInstructionsLabel: "Lieferanweisungen, sichtbar auf dem Paketschein",
      deliveryInstructionsPlaceholder: "z.B. zweiter Stock",
      placeOrderButton: "Bestellung aufgeben",
      cartList: {
        sendByEmailButton: "Per E-Mail versenden",
        placeOrderButton: "Bestellung aufgeben",
        editProductLabel: "Produkt bearbeiten",
        removeFromCardLabel: "Aus dem Warenkorb entfernen",
        cartOverviewTitle: "Warenkorbübersicht",
        cartSummaryTitle: "Warenkorb-Zusammenfassung",
        downloadImagesLabel: "Bilder herunterladen",
        noneOrdersSelectedLabel: "Keine Bestellungen ausgewählt",
        continueWithOrdersLabelFormat: "Weiter mit {ordersCount} Bestellungen",
        placeAllOrdersLabelFormat: "Alle {ordersCount} Bestellungen aufgeben",
        cartSummary: cartSummaryTranslation,
        cartHeader: {
          readyForShippingText: "Versandfertig innerhalb von {minDays} - {maxDays} Werktagen"
        },
        cartItemList: {
          cartItem: {
            cartItemActions: cartItemActionsTranslation,
            cartItemBodyDetails: {
              removeItemButton: "Artikel entfernen",
              updateQuantityButton: "Menge aktualisieren",
              revertButton: "Rückgängig machen",
              cartItemDetails: cartItemDetailsTranslation
            }
          }
        }
      },
      orderSummary: {
        brandLabel: "Marke",
        noItemsLabel: "Anzahl Artikel",
        readyForShippingFromFactoryLabel: "Bereit zum Versand ab Werk",
        readyForShippingFromFactoryValue: "{minDays} - {maxDays} Werktage",
        customReferenceLabel: "Ihre Referenz",
        customReferencePlaceholder: "z.B. Ihre Bestellnummer",
        customNameLabel: "Bestellbezeichnung",
        customNamePlaceholder: "z.B Kundenname",
        commentLabel: " Anmerkungen",
        commentPlaceholder: ""
      },
      deliveryAddress: {
        addButton: 'Neue Adresse eingeben',
        editButton: "Bearbeiten",
        deliveryAddressForm: {
          city: {
            name: 'Ort',
            fieldRequired: 'Die Ortsangabe ist notwendig.',
          },
          country: {
            name: 'Land',
          },
          zipCode: {
            name: 'Postleitzahl',
            fieldRequired: 'Die Angabe der Postleitzahl ist notwendig.',
            wrongFormat: 'Bitte geben Sie eine gültige Postleitzahl ein.',
          },
          addressLine1: {
            name: 'Address Line 1',
            fieldRequired: 'Address Line 1 is required',
          },
          addressLine2: {
            name: 'Address Line 2',
          },
          recipient: {
            name: 'Recipient',
            fieldRequired: 'Recipient is required',
          },
        }
      },
      cartSummary: cartSummaryTranslation,
      orderCreationConfirmationModal: {
        title: '*Are you sure you want to proceed?',
        confirmationText: '*You are placing an order on behalf of',
        confirmButton: 'Bestellung aufgeben',
        cancelButton: 'Abbrechen',
      },
      orderCreatedModal: {
        somethingWentWrongTitle: "Etwas ist schiefgelaufen",
        notAllOrdersCreatedSuccessfullyTitle:
          "Etwas ist schief gelaufen. Nur {succedeedOrdersCount} von ${totalOrdersCount} [Bestellungen] wurde(n) erfolgreich erstellt",
        technicalIssuesSubtitle:
          "Aus technischen Gründen können wir keine Bestellung mit den folgenden Konfigurationen erfassen:",
        orderCreatinFailedSubtitle:
          "Bestellung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie das Flokk Hub-Supportteam.",
        notAllOrdersCreatedSuccessfullySubtitle:
          "Erfolgreich erstellte Bestellungen: {succedeedOrders}.\nVerarbeitung fehlgeschlagen: {failedOrders}.\nBitte versuchen Sie es erneut oder kontaktieren Sie das Flokk Hub-Supportteam.",
        OrderCreatedSuccessNotification: {
          ongoingOrdersLabel: "Laufende Bestellungen anzeigen",
          orderDetailsLabel: "Bestellinformationen ansehen",
          orderReceivedTitle: "Vielen Dank! Wir haben Ihre Bestellung erhalten.",
          orderReceivedSubtitle:
            "Eine Auftragsbestätigung wird Ihnen per E-Mail zugesandt, sobald die Bestellung an die Produktion übermittelt wurde."
        }
      },
      sendCartToEmail: {
        headerText: "Ausgewählte Artikel an E-Mail senden",
        sendButton: "Senden",
        emailLabel: "E-Mail"
      }
    },
    configurationPage: {
      ...baseTranslation.configurationPage,
      priceTaxText: "(OHNE MWST.)",
    },
    configuratorActions: {
      addToCartButton: "Zum Warenkorb hinzufügen",
      favoriteButton: {
        addToFavoritesText: "Zu Favoriten hinzufügen",
        addingToFavoritesText: '*Adding to favourites...',
        addedToFavoritesText: "Zu Favoriten hinzugefügt",
        addingToFavoritesFailed: '*Could not add the product to favourites. Make sure it is available in your country.',
      },
      updateCartItemButton: "Warenkorbartikel aktualisieren",
      addedToCartModal: {
        updatedInCartHeader: "Im Warenkorb aktualisiert",
        addedToCartHeader: "Zum Warenkorb hinzugefügt",
        goToCartButton: "Zum Warenkorb",

        quantityInfo: {
          productAddedText: "1 Produkt hinzugefügt",
          productsAddedText: "{quantityAdded} Produkte hinzugefügt"
        },
        addedToCartItem: {
          cartItemPreview: {
            cartItemDetails: cartItemDetailsTranslation
          },
          cartItemSubtotal: {
            exclValText: "(ohne MwSt.)",
            subtotalText: "Zwischensumme"
          }
        }
      }
    }
  },
  favoritesPage: {
    title: "Meine Favoriten",
    downloadImagesLabel: "Bilder herunterladen",
    editNameLabel: "Namen bearbeiten",
    removeLabel: "Entfernen",
    favoriteItem: {
      addToCartButton: "Zum Warenkorb hinzufügen",
      showConfigurationButton: "Konfiguration anzeigen",
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation
    },
    editConfigurationNameModal: {
      header: "Namen bearbeiten",
      description: "Kennzeichnen Sie Ihre Konfiguration mit Kundenname, Datum oder anderen Merkmalen",
      configurationNameLabel: "Konfigurationsname",
      saveButton: "Speichern",
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation
    },
    favoriteItemDetailsModal: {
      configurationText: "Konfiguration",
      accessoriesText: "Zubehör",
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation
    },
    removalConfirmationModal: {
      header: "Aus Favoriten entfernen",
      description: "Sind Sie sicher, dass Sie dieses Produkt aus Ihren Favoriten entfernen möchten?",
      confirmButton: "Ja, entfernen",
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation
    },
    emptyFavoritesList: {
      titleText: "Ihre Favoritenliste ist leer",
      subtitleText: "aber das muss nicht so bleiben",
      labelText: "Produkte entdecken"
    }
  },
  loginPage: {
    welcomeText: "Willkommen bei Flokk Hub!",
    getStartedButton: "Los geht‘s"
  },
  orderPage: {
    downloadImagesLabel: "Bilder herunterladen",
    shippingWithinTextFormat: "Versand erfolgt in {minDays} - {maxDays} Werktagen",
    shippingSoonText: "Versand erfolgt in Kürze",
    weHaveDelayText: "Es gibt eine Verzögerung",
    changeInOrderAlertText:
      "Es gab eine Änderung in Ihrer Bestellung, die unten aufgeführten Informationen könnten daher nicht mehr aktuell sein.",
    orderActionButtons: {
      yourReferenceLabel: "Ihre Referenz",
      orderNameLabel: "Bestellbezeichnung",
      setOrderNameText: "Bestellung benennen",
      addEntireOrderToCartButton: "Gesamte Bestellung in den Warenkorb legen"
    },
    trackingInformation: {
      trackOrderButton: "Bestellung verfolgen"
    },
    shippingDate: {
      shippingDateText: "Versanddatum",
      shippingTimeText: "Versanddauer",
      withinDaysTextFormat: "innerhalb von {minDays} - {maxDays} Werktagen",
      soonText: "In Kürze",
      delayText: "Es gibt eine Verzögerung"
    },
    orderDetailsPanel: {
      deliveryAddressLabel: "Lieferadresse",
      totalPriceBeforeVatLabel: "Gesamtpreis ohne MwSt.",
      totalPriceIncludingVatLabel: "Gesamtpreis inklusive MwSt.",
      buyerLabel: "Käufer",
      summaryLabel: "Übersicht",
      summary: cartSummaryTranslation,
    },
    orderDetailsProduct: {
      reorderButton: "Erneut bestellen",
      productsHeader: "Produkte",
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation
    },
    orderShipmentsModal: {
      closeButton: "Schließen",
      header: "Diese Lieferung umfasst mehrere Sendungen",
      orderShipmentPanelHeader: {
        deliveryNumberLabel: "Liefernummer",
        shippingDateLabel: "Versanddatum: ",
        trackOrderButton: "Bestellung verfolgen"
      },
      orderShipmentsList: {
        configurationCodeLabel: "Konfig. Code"
      }
    },
    editOrderModal: {
      orderTextFormat: "Bestellung {orderNumber}",
      saveButton: "Speichern",
      nameLabel: "Name"
    },
    reorderModal: {
      areYouSureText: "Sind Sie sicher, dass Sie den Inhalt der Bestellung in den Warenkorb legen möchten?",
      confirmButton: "Bestätigen",
      orderTextFormat: "Bestellung {orderNumber}"
    }
  },
  ordersPage: {
    ongoingOrdersTitle: "Laufende Bestellungen",
    pastOrdersTitle: "Letzte Bestellungen",
    ordersListFilter: {
      searchLabel: "Suchen nach",
      searchPlaceholder: "Bestellnummer oder Name",
      onlyMineLabel: "Nur meine",
      searchButton: "Suchen",
      resetButton: "Zurücksetzen",
      sortByLabel: "Sortieren nach",
      sortByOrderNumberAscLabel: "Bestellnummer (asc)",
      sortByOrderNumberDescLabel: "Bestellnummer (desc)",
      sortByOrderDateAscLabel: "Datum bestellen (asc)",
      sortByOrderDateDescLabel: "Datum bestellen (desc)",
      sortByOrderTotalAscLabel: "Auftragssumme (asc)",
      sortByOrderTotalDescLabel: "Auftragssumme (desc)"
    },
    tabContent: {
      noOrdersMatchingCriteriaText: "Es gibt keine Bestellungen, die mit Ihren Kriterien übereinstimmen.",
      pastOrdersTable: {
        orderNumberHeader: "Bestellnummer",
        orderNameHeader: "Bestellbezeichnung",
        orderDateHeader: "Bestelldatum",
        shippingDateHeader: " Versanddatum",
        productsHeader: "Produkte",
        totalPriceHeader: "Gesamtpreis",
        buyerHeader: "Käufer",
        cancelledText: "Storniert"
      },
      order: {
        changesInOrderAlertText:
          "Es gab eine Änderung in Ihrer Bestellung, die unten aufgeführten Informationen könnten daher nicht mehr aktuell sein.",
        shippingDateFormat: "Versanddatum {date}",
        orderHeader: {
          orderNoLabel: "Bestellnummer",
          orderDateLabel: "Bestelldatum",
          totalPriceLabel: "Gesamtpreis",
          buyerLabel: "Käufer",
          orderNameLabel: "Bestellbezeichnung",
          yourReferenceLabel: "Ihre Referenz"
        },
        orderItem: {
          cartItemDetails: cartItemDetailsTranslation
        },
        shippingMessage: {
          shippingSoonText: "Versand erfolgt in Kürze",
          shippingWithinDaysFormat: "Versand erfolgt in {minDays} - {maxDays} Werktagen",
          weHaveDelayText: "Es gibt eine Verzögerung"
        }
      }
    }
  },
  sideMenu: {
    productsLabel: "Produkte",
    quickOrderLabel: "Sofort-Bestellung",
    orderHistoryLabel: "Bestellübersicht",
    favoritesLabel: "Meine Favoriten",
    signOutLabel: "Abmelden"
  },
  topMenu: {
    cartButton: "Warenkorb",
    userInfo: {
      storeSelector: {
        storeChangeFailedWarning: {
          message: '*Sorry, we are not able to change your current organization to the requested one. Please, try again or contact customer support.'
        }
      }
    }
  },
  appLoader: {
    loaderText: "Initialisieren ..."
  }
};

export default translation;
