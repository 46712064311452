import { Component, ComponentElement, createElement } from 'react';

import AppSettings from '../../AppSettings';
import { CheckoutPageTranslation } from '../../localization/SiteTranslation';
import DibsPaymentMethod from './Dibs/DibsPaymentMethod';
import DibsPaymentWidget from './Dibs/DibsPaymentWidget';
import { IPaymentMethod, PaymentWidgetProps } from './IPaymentMethod';
import { KlarnaPaymentMethod } from './Klarna/KlarnaPaymentMethod';
import KlarnaPaymentWidget from './Klarna/KlarnaPaymentWidget';
import { ManualTestPaymentMethod } from './ManualTest/ManualTestPaymentMethod';
import ManualTestPaymentWidget from './ManualTest/ManualTestPaymentWidget';
import StripePaymentMethod from './Stripe/StripePaymentMethod';
import StripePaymentWidget from './Stripe/StripePaymentWidget';

export interface IPaymentMethodFactory {
  create(code: string): IPaymentMethod;
}

export interface IPaymentMethodWidgetFactory {
  createWidget(method: IPaymentMethod): ComponentElement<PaymentWidgetProps, Component<PaymentWidgetProps>>;
}

export class PaymentMethodFactory implements IPaymentMethodFactory, IPaymentMethodWidgetFactory {
  private innerFactories = new Map<
    string,
    {
      create: () => IPaymentMethod;
      createWidget: (method: IPaymentMethod) => ComponentElement<PaymentWidgetProps, Component<PaymentWidgetProps>>;
    }
  >();

  constructor(
    settings: AppSettings,
    termsAndConditionsUrl: string,
    currentLanguage: string,
    translation: CheckoutPageTranslation,
  ) {
    this.innerFactories.set('ManualPaymentMethod', {
      create: () => new ManualTestPaymentMethod(),
      createWidget: (method) =>
        createElement(ManualTestPaymentWidget, {
          paymentMethod: method as ManualTestPaymentMethod,
          translation: translation,
        }),
    });
    this.innerFactories.set('NetsEasy', {
      create: () => new DibsPaymentMethod(termsAndConditionsUrl, currentLanguage),
      createWidget: (method) =>
        createElement(DibsPaymentWidget, {
          paymentMethod: method as DibsPaymentMethod,
          translation: translation,
        }),
    });
    this.innerFactories.set('KlarnaPayments', {
      create: () => new KlarnaPaymentMethod(settings, translation),
      createWidget: (method) =>
        createElement(KlarnaPaymentWidget, {
          paymentMethod: method as KlarnaPaymentMethod,
          translation: translation,
        }),
    });
    this.innerFactories.set('Stripe.Payments', {
      create: () => new StripePaymentMethod(currentLanguage),
      createWidget: (method) =>
        createElement(StripePaymentWidget, {
          paymentMethod: method as StripePaymentMethod,
          translation: translation,
        }),
    });
  }

  private resolveFactory(code: string) {
    const factory = this.innerFactories.get(code);
    if (factory) {
      return factory;
    }

    throw new Error(`Not supported payment method detected: ${code}`);
  }

  public create(code: string) {
    return this.resolveFactory(code).create();
  }

  public createWidget(method: IPaymentMethod) {
    return this.resolveFactory(method.code).createWidget(method);
  }
}
