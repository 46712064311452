import { ShoppingContext } from '../data/model';
import { RelativeUrl } from './Url';

export class StoreUrlBuilder {
  public static context: ShoppingContext;

  public static setContext(context: ShoppingContext) {
    StoreUrlBuilder.context = context;
  }

  public static changeLanguage(context: ShoppingContext, url: RelativeUrl, languageCode: string) {
    const storeId = context.storeId;
    return StoreUrlBuilder.build(storeId, languageCode, StoreUrlBuilder.getRawPath(url), url.query);
  }

  public static changeStoreAndLanguage(url: RelativeUrl, storeId: string, languageCode: string) {
    return StoreUrlBuilder.build(storeId, languageCode, StoreUrlBuilder.getRawPath(url), url.query);
  }

  public static buildRawStoreUrl(storeId: string, languageCode: string, path: string) {
    return StoreUrlBuilder.build(storeId, languageCode, path);
  }

  public static fromContext(
    context: ShoppingContext,
    path: string,
    query: Map<string, string> = new Map<string, string>(),
    ignoreLanguage: boolean = false,
  ) {
    const storeId = context.storeId;
    const languageCode = ignoreLanguage ? null : context.languageCode;
    return StoreUrlBuilder.build(storeId, languageCode, path, query);
  }

  public static withContext(
    path: string,
    query: Map<string, string> = new Map<string, string>(),
    ignoreLanguage: boolean = false,
  ) {
    return StoreUrlBuilder.fromContext(StoreUrlBuilder.context, path, query, ignoreLanguage);
  }

  public static build(storeId: string, languageCode: string, rawPath: string, query?: Map<string, string>) {
    const url = new RelativeUrl(rawPath, query);

    if (languageCode) {
      url.pathSegments.unshift(languageCode.toLowerCase());
    }

    url.pathSegments.unshift(storeId.toLowerCase());
    return url;
  }

  private static getRawPath(url: RelativeUrl) {
    const clone = url.clone();

    clone.pathSegments.shift(); // Remove store id
    clone.pathSegments.shift(); // Remove language code

    return clone.path;
  }
}
