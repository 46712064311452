import classNames from 'classnames';
import * as React from 'react';

import { HeaderProps } from './HeaderLevel1';
import css from './HeaderLevel3.css';

interface HeaderLevel3Props extends HeaderProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderLevel3 = ({ className, children, centered }: HeaderLevel3Props) => {
  const classes = classNames(css.HeaderLevel3, className, centered && css.centered);
  return <h3 className={classes}>{children}</h3>;
};

export default HeaderLevel3;
