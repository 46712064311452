import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../app/data/client';
import { InvitationData, RemoveInvitationCommand, ShoppingContext } from '../../../app/data/model';
import { AsyncCommand } from '../../../app/shared/common';
import Event from '../../../app/shared/Event';
import { ModalState } from '../../../app/shared/ModalState';
import { ConfirmInvitationRemovalModalTranslation } from '../../localization/SiteTranslation';

export class ConfirmInvitationRemovalModalState extends ModalState {
  @observable
  public invitation: InvitationData | undefined = undefined;

  public readonly removeInvitationCommand: AsyncCommand;

  public readonly invitationRemovedEvent = new Event();

  constructor(
    private readonly client: IApiClient,
    shoppingContext: ShoppingContext,
    public readonly translation: ConfirmInvitationRemovalModalTranslation,
  ) {
    super();

    makeObservable(this);

    this.removeInvitationCommand = new AsyncCommand(async () => {
      if (!this.invitation) {
        return;
      }

      await this.client.send(new RemoveInvitationCommand({ shoppingContext, invitationId: this.invitation.id }));

      this.close();
      this.invitationRemovedEvent.raise({});
    });
  }

  @action.bound
  public setInvitation(invitation: InvitationData) {
    this.invitation = invitation;
  }

  onBeforeClose() {
    this.invitation = undefined;
  }
}
