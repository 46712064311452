import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

type CheckoutSteps = 'address' | 'payment';

export default class CheckoutPageQueryParser extends QueryParser {
  static ORDERNUMBER = nameOf<ICheckoutPageQuery>('orderNumber');
  static STEP = nameOf<ICheckoutPageQuery>('step');

  static toModel(query: Map<string, string>): ICheckoutPageQuery {
    const result = {} as ICheckoutPageQuery;

    result.orderNumber = this.getValue(query, CheckoutPageQueryParser.ORDERNUMBER);
    result.step = this.getValue(query, CheckoutPageQueryParser.STEP) as CheckoutSteps;

    return result;
  }
}

export interface ICheckoutPageQuery {
  orderNumber: string;
  step: CheckoutSteps;
}
