import { action, makeObservable, observable } from 'mobx';

import { IAuthenticationService, User } from '../../app/auth/AuthenticationService';
import { IApiClient } from '../../app/data/client';
import { HttpError } from '../../app/data/HttpError';
import { VerifyInvitationCommand, VerifyInvitationCommandResponse } from '../../app/data/model';
import Logger from '../../app/shared/Logger';
import { INavigationService } from '../../app/shared/NavigationService';
import { StoreState } from '../../app/StoreState';
import { InvitationPageTranslation } from '../localization/SiteTranslation';
import { BaseOAuthHandlerState } from './BaseOAuthHandlerState';

type InvitationStep = 'OPEN' | 'VERIFIED' | 'EXPIRED' | 'INVALID' | 'ERROR';
type InvitationParameters = {
  token: string;
  email: string;
};

export class InvitationState extends BaseOAuthHandlerState {
  constructor(
    private readonly client: IApiClient,
    navigation: INavigationService,
    authentication: IAuthenticationService,
    public readonly translation: InvitationPageTranslation,
  ) {
    super(navigation, authentication);
    makeObservable(this);
  }

  @observable
  public isAuthenticated: boolean = false;

  @observable
  public currentUser: User | undefined = undefined;

  @observable
  public invitationStep: InvitationStep = 'OPEN';

  @observable
  public invitationParameters: InvitationParameters | undefined = undefined;

  @observable invitationRequest: VerifyInvitationCommandResponse | undefined = undefined;

  @action.bound
  private setAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  @action.bound
  private setCurrentUser(user: User | undefined) {
    this.currentUser = user;
  }

  @action.bound
  public setInvitationStep(step: InvitationStep) {
    this.invitationStep = step;
  }

  @action.bound
  public setInvitationParameters(parameters: InvitationParameters) {
    this.invitationParameters = parameters;
  }

  @action.bound
  public setInvitationRequest(invitationRequest: VerifyInvitationCommandResponse) {
    this.invitationRequest = invitationRequest;
  }

  async onLoad(store: StoreState) {
    const query = this.navigation.currentUrl.query;

    const token = query.get('token');
    const email = query.has('email') ? decodeURIComponent(query.get('email')) : undefined;

    try {
      if (token && email) {
        await this.loadInvitationDetails(token, email);
      } else {
        Logger.error(`Requested invitation without valid token or email. Token = ${token}. Email = ${email}`);
        this.setInvitationStep('INVALID');
      }
    } catch (e) {
      Logger.error(`Verification of an invitation failed. Error: ${JSON.stringify(e)}`);
      if (e instanceof HttpError && e.status === 404) {
        this.setInvitationStep('INVALID');
      } else {
        this.setInvitationStep('ERROR');
      }
    }
  }

  private loadInvitationDetails = async (token: string, email: string) => {
    this.setInvitationParameters({ token, email });
    const { isActive, matchingContactExists } = await this.verifyInvitationToken(token, email);

    isActive === true ? this.setInvitationStep('VERIFIED') : this.setInvitationStep('EXPIRED');
    this.setInvitationRequest({ isActive, matchingContactExists });

    const isAuthenticated = await this.authentication.isAuthenticated();
    this.setAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      const currentUser = await this.authentication.getUser();
      this.setCurrentUser(currentUser);
    }
  };

  public loginAsCurrentUser = async () => {
    if (!this.currentUser) {
      throw new Error('Cannot login as current user when user is not authenticated!');
    }

    const { token } = this.invitationParameters;

    await this.authentication.redirectInvitation(token, this.currentUser.email, true);
  };

  public signUpAsNewUser = async () => {
    const { token, email } = this.invitationParameters;
    await this.authentication.redirectInvitation(token, email, false);
  };

  public proceedAnonymousUser = async (goToLogin: boolean) => {
    const { token, email } = this.invitationParameters;

    await this.authentication.redirectInvitation(token, email, goToLogin);
  };

  private verifyInvitationToken = async (token: string, email: string) => {
    Logger.log(`Verifying invitation token for token ${token} and email ${email}.`);

    const response = await this.client.send(
      new VerifyInvitationCommand({
        token: token,
        userEmail: email,
      }),
    );

    if (!response.isActive) {
      Logger.warn(`A user tried to use an expired invitation token ${token}`);
    }

    return response;
  };
}
