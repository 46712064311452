import { ICancellableTask } from './TasksScheduler';

export class UpdateVisualizationImagesTask implements ICancellableTask {
  urls: Array<string>;
  cancelled = false;

  constructor(urls: Array<string>) {
    this.urls = urls;
  }
}
