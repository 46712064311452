import { observer } from 'mobx-react';
import { Component } from 'react';

import NavigationBar from '../../../shared/components/NavigationBar/NavigationBar';
import { MenuSectionProps } from '../../../shared/components/StickyMenu/MenuSection';

interface ProductFamilyMenuProps {
  familyName: JSX.Element | string;
  containerClassName?: string;
  items: Array<MenuSectionProps>;
  activeItemId?: string;
  handleOnClick?: (item: MenuSectionProps) => void;
}

@observer
export default class ProductFamilyMenu extends Component<ProductFamilyMenuProps, {}> {
  constructor(props: ProductFamilyMenuProps) {
    super(props);
  }

  render() {
    const { familyName, ...props } = this.props;
    return <NavigationBar id="navigationBar" heading={familyName} {...props} />;
  }
}
