import { action, computed, makeObservable, observable } from 'mobx';

import Event from '../shared/Event';

export class SelectorItem<T> {
  data: T;
  @observable isActive: boolean;

  constructor(data: T, isActive: boolean = false) {
    makeObservable(this);
    this.data = data;
    this.isActive = isActive;
  }
}

export default class Selector<T> {
  @observable.shallow items: Array<SelectorItem<T>> = [];

  public changed: Event<T>;

  constructor(items: Array<T>, selected?: T) {
    makeObservable(this);
    this.changed = new Event();
    this.setItems(items, selected);
  }

  @computed
  get count() {
    return this.items.length;
  }

  @computed
  get active() {
    return this.items.find((item) => item.isActive);
  }

  @computed
  get activeIndex() {
    return this.items.findIndex((item) => item.isActive);
  }

  findItem(x: T) {
    return this.items.find((item) => item.data === x);
  }

  @action.bound
  setActiveItem(itemToSelect: SelectorItem<T>) {
    this.items.forEach((item) => (item.isActive = item === itemToSelect));
    this.changed.raise(itemToSelect.data);
  }

  @action.bound
  setActiveItemByPredicate(predicate: (data: T) => boolean) {
    const item = this.items.find((x) => predicate(x.data));
    if (!item) {
      return;
    }

    this.setActiveItem(item);
  }

  @action.bound
  async setItems(items: Array<T>, selected?: T) {
    this.items = items.map((item) => new SelectorItem(item, false));

    if (selected) {
      this.setActiveItem(this.items.find((x) => x.data === selected));
    } else if (items.any()) {
      this.setActiveItem(this.items[0]);
    }
  }

  shift(step: number) {
    return (this.activeIndex + step + this.count) % this.count;
  }

  @action.bound next() {
    this.setActiveItem(this.items[this.shift(1)]);
  }

  @action.bound prev() {
    this.setActiveItem(this.items[this.shift(-1)]);
  }
}
