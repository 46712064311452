import { makeObservable, observable } from 'mobx';

import { AddressData } from '../../../../data/model';
import { AddressFormTranslation } from '../../../../localization/SiteTranslation';
import { IEnableable } from '../../../../shared/common';

export abstract class DynamicInputsState<TInput = {}> implements IEnableable {
  @observable inputs: TInput;
  translation: AddressFormTranslation;
  @observable isEnabled: boolean;

  protected constructor(translation: AddressFormTranslation) {
    makeObservable(this);
    this.translation = translation;
    this.inputs = {} as TInput;
  }

  abstract buildUp(address: AddressData): void;

  abstract setInputs(address: AddressData): void;

  abstract enable(): void;

  abstract disable(): void;
}
