import '../../app/shared/stringExtensions';
import { Component3d, Material3dReference, Mesh3dReference } from '../3d/Asset3dReference';
import { Visualization3dData } from '../data/model';
import { ConditionEvaluator, VisualizationComponents } from './VisualizationBuilder';

export class Visualization3dBuilder {
  public static buildComponents3d(data: Visualization3dData, selectedComponents: Map<string, string>) {
    const components = new VisualizationComponents(data);
    const componentsNames = components.getNames(selectedComponents, null);

    const component3d: Array<Component3d> = [];

    componentsNames.forEach((x) => {
      const [meshName, materialName] = x.split('|');

      const mesh: Mesh3dReference = {
        name: meshName,
        url: Visualization3dBuilder.buildAssets3dUrl(data.baseAssets3dUrl, meshName),
      };

      const material: Material3dReference = !materialName
        ? undefined
        : {
            name: materialName,
            url: Visualization3dBuilder.buildAssets3dUrl(data.baseMaterials3dUrl, materialName),
          };

      component3d.push({ mesh, material });
    });

    return component3d;
  }

  public static getActiveTransformations(data: Visualization3dData, selectedComponents: Map<string, string>) {
    return Visualization3dBuilder.getItemsByCondition(data.mapping.transformationGroups, data, selectedComponents);
  }

  public static getActiveCameraDistanceFactor(data: Visualization3dData, selectedComponents: Map<string, string>) {
    const cameraSetups = Visualization3dBuilder.getItemsByCondition(
      data.mapping.cameraSetupGroups,
      data,
      selectedComponents,
    );
    return cameraSetups.any() ? cameraSetups[0].distanceFactor : 1;
  }

  private static getItemsByCondition<TItem extends { condition: string }>(
    items: TItem[],
    data: Visualization3dData,
    selectedComponents: Map<string, string>,
  ): TItem[] {
    const components = new VisualizationComponents(data);
    const conditionEvaluator = new ConditionEvaluator(components.includeProductIntoSelectedItems(selectedComponents));
    return items.filter((x) => conditionEvaluator.isSatisfied(x.condition, '', ''));
  }

  private static buildAssets3dUrl = (baseUrl: string, fileName: string) => {
    return `${baseUrl}/${fileName.replaceAll(' ', '_')}.glb`;
  };
}
