import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import css from './InputElement.css';

export interface InputElementProps {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  checked?: boolean;
  invalid?: boolean;
  maxLength?: number;
  minLength?: number;
  min?: number;
  max?: number;
  autocomplete?: string;
  pattern?: string;
  required?: boolean;
}

export const InputElementInvalidKey = 'input-invalid';
export const InputElementInvalid = `[${InputElementInvalidKey}="true"]`;

const setInputElementInvalidAttribute = (isInvalid: boolean) => {
  const inputElementInvalidAttribute: IKeyProperty = {};
  inputElementInvalidAttribute[InputElementInvalidKey] = isInvalid ? 'true' : 'false';

  return inputElementInvalidAttribute;
};

class InputElement extends React.Component<InputElementProps, {}> {
  constructor(props: InputElementProps) {
    super(props);
  }

  render() {
    const { className, invalid, inputRef, disabled, ...inputProps } = this.props;
    const invalidAttribute = setInputElementInvalidAttribute(invalid);
    const classes = classNames(css.Input, invalid && css.invalid, disabled && css.disabled, className);
    return <input ref={inputRef} className={classes} disabled={disabled} {...invalidAttribute} {...inputProps} />;
  }
}

export default observer(InputElement);
