import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

import { FilterData, GetProductFamilyFiltersQueryResponse, PropertyData } from '../data/model';
import { ICatalogPageQuery } from './CatalogPageQueryParser';

export class FilterPropertyState {
  private selection: FiltersSelectionState;
  public data: PropertyData;

  constructor(propertyData: PropertyData, selection: FiltersSelectionState) {
    makeObservable(this);
    this.data = propertyData;
    this.selection = selection;
  }

  @computed
  get selected() {
    return this.selection.properties.contains(this.data);
  }

  @action
  toggle() {
    this.selection.toggle(this.data);
  }
}

export class FilterState {
  public data: FilterData;

  @observable.shallow
  availableProperties: Array<FilterPropertyState>;

  @computed
  public get selectedCount() {
    return this.availableProperties.filter((x) => x.selected).length;
  }

  constructor(data: FilterData, selection: FiltersSelectionState) {
    makeObservable(this);
    this.data = data;
    this.availableProperties = data.availableProperties.map((x) => new FilterPropertyState(x, selection));
  }
}

export class FiltersSelectionState {
  @observable.shallow
  public properties: Array<PropertyData> = [];

  constructor() {
    makeObservable(this);
  }

  public isSelected(property: PropertyData) {
    this.properties.contains(property);
  }

  @action
  public clear() {
    (this.properties as IObservableArray<PropertyData>).clear();
  }

  @action
  public toggle(property: PropertyData) {
    if (this.properties.contains(property)) {
      this.properties.remove(property);
    } else {
      this.properties.push(property);
    }
  }

  @action
  public deselect(property: PropertyData) {
    this.properties.remove(property);
  }
}

export class CatalogFiltersState {
  @observable.shallow
  public filters: Array<FilterState>;

  public selection: FiltersSelectionState;

  constructor(filtersQueryResponse: GetProductFamilyFiltersQueryResponse, query?: ICatalogPageQuery) {
    makeObservable(this);
    this.selection = new FiltersSelectionState();
    this.filters = [
      filtersQueryResponse.typeFilter,
      filtersQueryResponse.sectorFilter,
      filtersQueryResponse.brandFilter,
    ]
      .filter(Boolean) // Filter out falsy entries
      .map((x) => new FilterState(x, this.selection));

    if (query) {
      this.initializeFromQuery(filtersQueryResponse, query);
    }
  }

  public get selectedTypes(): string[] {
    return this.getSelectedPropertyValues('type');
  }

  public get selectedSectors(): string[] {
    return this.getSelectedPropertyValues('sector');
  }

  public get selectedBrands(): string[] {
    return this.getSelectedPropertyValues('brand');
  }

  private getSelectedPropertyValues(name: string): string[] {
    return this.selection.properties.filter((x) => x.name.toLowerCase() === name).map((x) => x.valueId);
  }

  private initializeFromQuery(filtersData: GetProductFamilyFiltersQueryResponse, query: ICatalogPageQuery) {
    if (filtersData.typeFilter && query.type) {
      this.initializeFilter(filtersData.typeFilter, query.type);
    }
    if (filtersData.sectorFilter && query.sector) {
      this.initializeFilter(filtersData.sectorFilter, query.sector);
    }
    if (filtersData.brandFilter && query.brand) {
      this.initializeFilter(filtersData.brandFilter, query.brand);
    }
  }

  private initializeFilter(filterData: FilterData, selectedValues: string[]) {
    for (let selectedValue of selectedValues) {
      const prop = filterData.availableProperties.find((x) => x.valueId.toLowerCase() === selectedValue);
      this.selection.toggle(prop);
    }
  }
}
