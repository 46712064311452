import { observer } from 'mobx-react';

import { CartButton } from '../../../app/shared/components/Button/CustomButtons';
import { Authorize } from '../../authentication/Authorize';
import { BuyerPolicy } from '../../Policy';
import UserInfo from './../UserInfo/UserInfo';
import css from './TopMenu.css';
import TopMenuState from './TopMenuState';

interface TopMenuProps {
  state: TopMenuState;
}

const TopMenu = ({ state }: TopMenuProps) => {
  return (
    <div className={css.TopMenu}>
      <UserInfo state={state.userInfo} />
      <Authorize policy={BuyerPolicy}>
        <CartButton cartUrl={state.shoppingCartUrl} quantity={state.shoppingCartCount}>
          {state.translation.cartButton}
        </CartButton>
      </Authorize>
    </div>
  );
};

export default observer(TopMenu);
