import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

export class ProductFamilyPageQuery {
  model?: string = null;
  code?: string = null;
}

export const MODEL = nameOf<ProductFamilyPageQuery>('model');
export const CONFIGURATION_CODE = nameOf<ProductFamilyPageQuery>('code');

export class ProductFamilyQueryParser extends QueryParser {
  public static toModel(query: Map<string, string>) {
    const queryModel = new ProductFamilyPageQuery();

    queryModel.model = QueryParser.getValue(query, MODEL);
    queryModel.code = QueryParser.getValue(query, CONFIGURATION_CODE);

    return queryModel;
  }

  public static toQuery(queryModel: ProductFamilyPageQuery) {
    const query = new Map<string, string>();

    QueryParser.setValue(query, MODEL, queryModel.model);
    QueryParser.setValue(query, CONFIGURATION_CODE, queryModel.code);

    return query;
  }
}
