import { computed, makeObservable } from 'mobx';

import { BaseModelBasicData, ImageData, PredefinedConfigurationData } from '../../../data/model';
import { ProductFamilyPageTranslation } from '../../../localization/SiteTranslation';
import ModelViewerState, { modelViewerImagesBuilder } from '../../../shared/components/ModelViewer/ModelViewerState';
import { ImagePreloader } from '../../../shared/ImagePreloader';
import PredefinedConfigurationsState from '../PredefinedConfigurations/PredefinedConfigurationsState';
import ProductModelSelectorState from '../ProductModelSelector/ProductModelSelectorState';

export default class ProductDetailsState {
  modelViewer: ModelViewerState;
  modelSelector: ProductModelSelectorState;
  predefinedConfigurations: PredefinedConfigurationsState;
  translation: ProductFamilyPageTranslation;
  icons: ImageData[];

  @computed
  get activeModelDetails() {
    const { specification, specificationDescription, environmentalData } = this.modelSelector.active.data;

    return {
      specification,
      specificationDescription,
      environmentalData: {
        certificates: environmentalData.certificates,
        co2EmissionKg: ProductDetailsState.getEnvironmentalDataString(environmentalData.co2EmissionKg),
        energyUsageMj: ProductDetailsState.getEnvironmentalDataString(environmentalData.energyUsageMj),
        recyclableMaterialsPercentage: ProductDetailsState.getEnvironmentalDataString(
          environmentalData.recyclableMaterialsPercentage,
        ),
        recycledAluminiumPercentage: ProductDetailsState.getEnvironmentalDataString(
          environmentalData.recycledAluminiumPercentage,
        ),
        recycledPlasticPercentage: ProductDetailsState.getEnvironmentalDataString(
          environmentalData.recycledPlasticPercentage,
        ),
        recycledMaterialsTotalPercentage: ProductDetailsState.getEnvironmentalDataString(
          environmentalData.recycledMaterialsTotalPercentage,
        ),
      },
    };
  }

  @computed
  private get selectedConfiguration() {
    return this.modelSelector.active?.data;
  }

  @computed
  private get selectedConfigurationImages() {
    return this.selectedConfiguration?.images.map((x, i) => ({ ...x, shot: i.toString() })) ?? [];
  }

  @computed
  private get selectedPredefinedConfigurationImages() {
    const configuration = this.predefinedConfigurations.active.data;
    return modelViewerImagesBuilder(configuration.code);
  }

  constructor(
    models: Array<BaseModelBasicData>,
    imagePreloader: ImagePreloader,
    translation: ProductFamilyPageTranslation,
    icons: ImageData[],
  ) {
    makeObservable(this);

    this.translation = translation;
    this.icons = icons;

    this.initializeModelSelector(models);
    this.initializePredefinedConfigurations();
    this.initializeModelViewer(imagePreloader);
  }

  private static getEnvironmentalDataString(data: number) {
    return typeof data === 'number' ? data.toString() : '';
  }

  private initializeModelSelector(models: BaseModelBasicData[]) {
    this.modelSelector = new ProductModelSelectorState(models);
    this.modelSelector.changed.subscribe(() => this.onModelChanged());
  }

  private initializeModelViewer(imagePreloader: ImagePreloader) {
    const images = this.predefinedConfigurations.items.empty()
      ? this.selectedConfigurationImages
      : this.selectedPredefinedConfigurationImages;

    this.modelViewer = new ModelViewerState(imagePreloader, images, this.translation.modelsSection.modelViewer);
  }

  private initializePredefinedConfigurations() {
    const predefinedConfigurationsOfActiveModel = this.getPredefinedConfigurations(
      this.selectedConfiguration?.predefinedConfigurations,
    );
    this.predefinedConfigurations = new PredefinedConfigurationsState(predefinedConfigurationsOfActiveModel);
    this.predefinedConfigurations.changed.subscribe(() => this.onPredefinedConfigurationChanged());
  }

  private onModelChanged() {
    const model = this.modelSelector.active.data;

    this.predefinedConfigurations.setItems(this.getPredefinedConfigurations(model.predefinedConfigurations));

    if (model.predefinedConfigurations.empty()) {
      this.modelViewer.setItems(this.selectedConfigurationImages);
    }
  }

  private onPredefinedConfigurationChanged() {
    this.modelViewer.setItems(this.selectedPredefinedConfigurationImages);
  }

  private getPredefinedConfigurations(predefinedConfigurations?: PredefinedConfigurationData[]) {
    const numberOfConfigurationsToDisplay = 4;
    return predefinedConfigurations?.slice(0, numberOfConfigurationsToDisplay) ?? [];
  }
}
