import classNames from 'classnames';
import { observer } from 'mobx-react';

import ActionButton from '../../../shared/components/ActionButton';
import SecondaryButton from '../../../shared/components/Button/SecondaryButton';
import TextButton from '../../../shared/components/Button/TextButton';
import FormInput from '../../../shared/components/FormInput';
import StatusMessage, { Status } from '../../../shared/components/StatusMessage/StatusMessage';
import css from './DiscountCodeForm.css';
import { DiscountCodeFormState } from './DiscountCodeFormState';

interface DiscountCodeFormProps {
  state: DiscountCodeFormState;
  activeDiscountCode: string;
  className?: string;
}

const DiscountCodeForm = ({ activeDiscountCode, className, state }: DiscountCodeFormProps) => {
  if (activeDiscountCode) {
    return (
      <StatusMessage className={classNames(className, css.successMessage)} type={Status.Success}>
        {state.translation.discountCodeIsAppliedMessage.interpolate([['code', activeDiscountCode]])}
      </StatusMessage>
    );
  }

  if (!state.isVisible) {
    return (
      <TextButton className={classNames(className, css.toggleButton)} onClick={state.toggle} isHighlighted>
        {state.translation.doYouHaveDiscountCodeText}
      </TextButton>
    );
  }

  return (
    <form className={classNames(className, css.form)}>
      <FormInput
        id="discountCode"
        name="discountCode"
        className={css.input}
        placeholder={state.translation.enterCodeText}
        errorContainerClassName={css.error}
        labelClassName="visuallyHidden"
        label={state.translation.label}
        input={state.input}
        disabled={state.isInputDisabled}
        invalid={state.applyCommand.errorOccurred}
        autoFocus
      />
      <ActionButton
        type="submit"
        buttonElement={SecondaryButton}
        errorClassName={css.error}
        command={state.applyCommand}
      >
        {state.translation.applyButton}
      </ActionButton>
    </form>
  );
};

export default observer(DiscountCodeForm);
