import classNames from 'classnames';
import * as React from 'react';

import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import css from './SliderNavigationArrows.css';

interface SliderArrowProps {
  onClick?: (event?: React.MouseEvent) => void;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
}

export const SliderPrevArrow = ({ disabled, iconClassName, className, onClick }: SliderArrowProps) => {
  return (
    <IconButton
      icon={<Icon className={iconClassName} name="angleLeft" />}
      onClick={onClick}
      className={classNames(css.SliderIconPrev, className)}
      disabled={disabled}
    />
  );
};

export const SliderNextArrow = ({ disabled, iconClassName, className, onClick }: SliderArrowProps) => {
  return (
    <IconButton
      icon={<Icon className={iconClassName} name="angleRight" />}
      onClick={onClick}
      className={classNames(css.SliderIconNext, className)}
      disabled={disabled}
    />
  );
};
