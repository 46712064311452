import { action, computed, makeObservable, override } from 'mobx';

import AppSettings from '../AppSettings';
import { ForceDownloadUrlBuilder } from '../configurator/ForceDownloadUrlBuilder';
import { Tab } from '../configurator/Tab';
import { IApiClient } from '../data/client';
import {
  AddProductToCartCommand,
  GetConfigurationByCodeQuery,
  GetProductFamilyQuery,
  GetStoreContextQueryResponse,
  ProductFamilyData,
  ShoppingContext,
} from '../data/model';
import { ConfiguratorUrlBuilder } from '../errorHandling/ParseConfigurationCodeAndRedirectState';
import { WarningStateBase } from '../layout/Warnings/WarningStateBase';
import { ProductFamilyPageTranslation } from '../localization/SiteTranslation';
import { Analytics } from '../shared/analytics/Analytics';
import { BasePageState } from '../shared/BasePageState';
import { AsyncCommand, IMemento, PageMetadata } from '../shared/common';
import { AssetsDownloaderState } from '../shared/components/AssetsDownloader/AssetsDownloaderState';
import { GalleryState } from '../shared/components/Gallery/GalleryState';
import { IConfigurationCodeResolutionService } from '../shared/ConfigurationCodeResolutionService';
import { ProductConfigurationQuery } from '../shared/hubSpot/ProductConfigurationQuery';
import { IImagePreloader } from '../shared/ImagePreloader';
import { INavigationService } from '../shared/NavigationService';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreUrl } from '../shared/StoreUrl';
import { StoreState } from '../StoreState';
import ProductDetailsState from './components/ProductDetails/ProductDetailsState';
import { RelatedProductsState } from './components/RelatedProducts/RelatedProductsState';
import SuggestedConfigurationsState from './components/SuggestedConfigurations/SuggestedConfigurationsState';
import { ProductFamilyPageQueryConverter } from './ProductFamilyPageQueryConverter';

export interface IProductFamilyPageMemento extends IMemento {
  shoppingContext: ShoppingContext;
  storeResponse: GetStoreContextQueryResponse;
  data: ProductFamilyData;
}

export default class ProductFamilyPageState extends BasePageState<IProductFamilyPageMemento> {
  private imagePreloader: IImagePreloader;

  private client: IApiClient;
  private navigation: INavigationService;
  private shoppingContext: ShoppingContext;
  private storeResponse: GetStoreContextQueryResponse;

  public data: ProductFamilyData;
  public translation: ProductFamilyPageTranslation;
  public imageGallery: GalleryState;
  public relatedProducts: RelatedProductsState;
  public productsAssets: AssetsDownloaderState;

  familyId: string;
  suggestedConfigurations: SuggestedConfigurationsState;
  modelDetails: ProductDetailsState;
  goToConfiguratorCommand: AsyncCommand;
  goToCheckoutCommand: AsyncCommand;
  askForPriceCommand: AsyncCommand;
  storeState: StoreState;
  productNotAvailableInCurrentStoreState: WarningStateBase<string>;

  constructor(
    storeState: StoreState,
    settings: AppSettings,
    familyId: string,
    client: IApiClient,
    navigation: INavigationService,
    imagePreloader: IImagePreloader,
    translation: ProductFamilyPageTranslation,
    codeResolver: IConfigurationCodeResolutionService,
  ) {
    super(translation);

    makeObservable(this);

    this.storeState = storeState;
    this.familyId = familyId;
    this.imagePreloader = imagePreloader;

    this.translation = translation;
    this.client = client;
    this.navigation = navigation;

    this.suggestedConfigurations = new SuggestedConfigurationsState(codeResolver);
    const forceDownload = new ForceDownloadUrlBuilder(settings.functionsUrl);
    this.imageGallery = new GalleryState(forceDownload, 800, 600);
    this.relatedProducts = new RelatedProductsState();
    this.productNotAvailableInCurrentStoreState = new WarningStateBase<string>();

    this.productsAssets = new AssetsDownloaderState(
      client,
      navigation,
      translation.productModelDetailsSection.assetsDownloader,
    );

    this.goToConfiguratorCommand = new AsyncCommand(() => this.goToConfigurator());
    this.goToCheckoutCommand = new AsyncCommand(() => this.goToCheckout());
    this.askForPriceCommand = new AsyncCommand(() => this.askForPrice());
  }

  private initialize(
    shoppingContext: ShoppingContext,
    storeResponse: GetStoreContextQueryResponse,
    data: ProductFamilyData,
  ) {
    this.shoppingContext = shoppingContext;
    this.storeResponse = storeResponse;
    this.data = data;

    if (!this.hasModels) {
      this.showProductNotAvailableError();
      return;
    }

    this.modelDetails = new ProductDetailsState(
      this.data.models,
      this.imagePreloader,
      this.translation,
      this.data.listingIcons,
    );
    this.suggestedConfigurations.initialize(data.predefinedConfigurations);
    this.imageGallery.setImages(data.gallery);

    this.productsAssets.setAssets(data.name, data.assets);
    this.relatedProducts.initialize(data.relatedProducts, 4);

    const queryConverter = new ProductFamilyPageQueryConverter(this.navigation, this.modelDetails);
    queryConverter.initialize();
  }

  @action
  async onLoad(store: StoreState) {
    const previewMode = this.navigation.currentUrl.query.has('preview');
    const query = new GetProductFamilyQuery({
      familyId: this.familyId,
      shoppingContext: store.shoppingContext,
      previewMode: previewMode,
    });
    const response = await this.client.send(query);

    if (response.redirectToFamilyId) {
      this.navigation.redirect(
        StoreUrl.productPageUrl(response.redirectToFamilyId).toString(),
        StatusCodes.MovedPermanently,
      );
    }

    this.initialize(store.shoppingContext, store.storeResponse, response.productFamily);
  }

  async onLoadAdditionalData() {
    if (this.modelDetails) {
      await this.modelDetails.modelViewer.finishPreloadingVisualizationImages();
    }
  }

  getMemento(): IProductFamilyPageMemento {
    return {
      shoppingContext: this.shoppingContext,
      storeResponse: this.storeResponse,
      data: this.data,
    };
  }

  restoreMemento(memento: IProductFamilyPageMemento) {
    this.initialize(memento.shoppingContext, memento.storeResponse, memento.data);
  }

  async goToConfigurator(tab?: Tab): Promise<void> {
    Analytics.trackConfigureClick(this.shoppingContext);

    const selectedConfiguration = this.modelDetails.predefinedConfigurations.active;
    if (!selectedConfiguration) {
      return Promise.resolve();
    }

    const builder = new ConfiguratorUrlBuilder(this.client);
    const configuratorUrl = await builder.buildFromCode(selectedConfiguration.data.code, undefined, tab);

    this.navigation.navigateTo(configuratorUrl);
    return Promise.resolve();
  }

  async askForPrice(): Promise<void> {
    Analytics.trackAskForPriceClick(this.shoppingContext);

    const selectedConfiguration = this.modelDetails.predefinedConfigurations.active;
    let query: ProductConfigurationQuery;
    if (!selectedConfiguration) {
      query = {
        productId: this.modelDetails.modelSelector.active.data.code,
      };
    } else {
      query = {
        configurationCode: selectedConfiguration.data.code,
      };
    }
    this.navigation.navigateTo(StoreUrl.requestQuoteUrl(query));
    return Promise.resolve();
  }

  async showProductNotAvailableError(): Promise<void> {
    const store = this.storeResponse.storeContext.store;
    const storeName = store.displayName || store.name;
    this.productNotAvailableInCurrentStoreState.open(storeName);
  }

  async addToCart(): Promise<void> {
    const selectedConfiguration = this.modelDetails.predefinedConfigurations.active;
    const configurationCode = selectedConfiguration.data.code;
    const getConfigurationResponse = await this.client.send(
      new GetConfigurationByCodeQuery({ code: configurationCode }),
    );
    const configuration = getConfigurationResponse.productConfiguration;

    const addToCartCommand = new AddProductToCartCommand({
      shoppingContext: this.shoppingContext,
      productConfiguration: configuration,
    });

    await this.client.send(addToCartCommand);
  }

  async goToCheckout(): Promise<void> {
    Analytics.trackCheckoutClick(this.shoppingContext);

    this.goToConfigurator(Tab.Summary);
    // Todo: Bug with prices
    // await this.addToCart();

    // this.navigation.navigateTo(StoreUrl.checkoutUrl());
    // return Promise.resolve();
  }

  @override
  public get metadata() {
    const title = this.data.name;
    const description = this.data.description;
    const imageUrl = !!this.data.mainImage ? this.data.mainImage.url : null;

    return <PageMetadata>{
      title: title,
      description: description,
      imageUrl: imageUrl,
    };
  }

  @computed
  public get hasModels() {
    return this.data.models && this.data.models.any();
  }
}
