import { action, makeObservable, observable } from 'mobx';

import { WebShopOptionVariationData } from '../../../../../../../app/data/model';

class ProductOptionVariationState {
  @observable
  public isActive: boolean;

  public constructor(private readonly data: WebShopOptionVariationData) {
    makeObservable(this);
    this.isActive = false;
  }

  public get code() {
    return this.data.answer;
  }

  public get familyName() {
    return this.data.fabricFamilyName;
  }

  public get id() {
    return this.data.id;
  }

  public get isNotSelectedAccessory() {
    return this.data.isNotSelectedAccessory;
  }

  public get name() {
    return this.data.name;
  }

  public get thumbnail() {
    return this.data.thumbnail;
  }

  @action
  public activate() {
    this.isActive = true;
  }

  @action
  public deactivate() {
    this.isActive = false;
  }
}

export default ProductOptionVariationState;
