import { action, computed, makeObservable, observable } from 'mobx';

import StoreSelectionState from '../../layout/StoreSelectionState';
import { ModalState } from '../../shared/ModalState';

export interface ISelectItem {
  label: string;
  value: string;
}

export class InitialStoreSelectorModalState extends ModalState {
  @observable
  public selectedStoreKey: string;

  @computed
  public get canApply() {
    return this.storeSelection.initialized && this.selectedStoreKey !== this.storeSelection.currentStore.key;
  }

  @computed
  public get storesWithoutGlobal() {
    return this.storeSelection.stores.filter((x) => x.countryCode !== 'GLOBAL');
  }

  public constructor(public readonly storeSelection: StoreSelectionState) {
    super();
    makeObservable(this);
  }

  @action.bound
  public apply() {
    const selectedStore = this.storeSelection.stores.find((x) => x.key === this.selectedStoreKey);
    const language = selectedStore?.languages.first().code ?? '';

    this.storeSelection.selectStore(this.selectedStoreKey, language);
  }

  @action.bound
  public selectStore(storeKey: string) {
    this.selectedStoreKey = storeKey;
  }

  @action.bound
  public initialize() {
    this.selectedStoreKey = this.storeSelection.currentStore.key;
  }
}
