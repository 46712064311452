import { action, makeObservable } from 'mobx';

import { IApiClient } from '../../../../data/client';
import { AddressData } from '../../../../data/model';
import { AddressFormTranslation } from '../../../../localization/SiteTranslation';
import { TextInput, ValueMaxLength, ValueRequiredRule } from '../../../../shared/Form';
import ZipCodeValidationFactory from '../Validation/ZipCodeValidationFactory';
import ZipCodeFormatterFactory from '../ZipCodeFormatterFactory';
import AutocompleteCityBehavior from './AutocompleteCityBehavior';
import AutocompleteCityBehaviorFactory from './AutocompleteCityBehaviourFactory';
import { DynamicInputsState } from './IDynamicInputsState';

interface Inputs {
  addressLine1: TextInput;
  addressLine2: TextInput;
  zipCode: TextInput;
  city: TextInput;
}

export default class DefaultDynamicInputsState extends DynamicInputsState<Inputs> {
  readonly autocompleteCity?: AutocompleteCityBehavior;

  constructor(public translation: AddressFormTranslation, public client: IApiClient, countryCode?: string) {
    super(translation);

    makeObservable(this);

    this.inputs.addressLine1 = new TextInput()
      .withRule(new ValueRequiredRule(translation.addressLine1.fieldRequired))
      .withRule(new ValueMaxLength(36));
    this.inputs.addressLine2 = new TextInput().withRule(new ValueMaxLength(36));
    this.inputs.zipCode = new TextInput().withRule(new ValueRequiredRule(translation.zipCode.fieldRequired));
    this.inputs.city = new TextInput()
      .withRule(new ValueRequiredRule(translation.city.fieldRequired))
      .withRule(new ValueMaxLength(20));

    if (countryCode) {
      const zipCodeValidation = ZipCodeValidationFactory.create(countryCode, this.translation.zipCode.wrongFormat);

      if (zipCodeValidation) {
        this.inputs.zipCode.withRule(zipCodeValidation);
      }

      this.inputs.zipCode.withFormatter(ZipCodeFormatterFactory.create(countryCode));

      const autocompleteCityBehavior = AutocompleteCityBehaviorFactory.create(
        this.client,
        countryCode,
        this.inputs.zipCode,
        this.inputs.city,
      );

      if (autocompleteCityBehavior) {
        this.autocompleteCity = autocompleteCityBehavior;
      }
    }
  }

  enable() {
    this.isEnabled = true;

    this.inputs.addressLine1.enable();
    this.inputs.addressLine2.enable();
    this.inputs.zipCode.enable();
    this.inputs.city.enable();
  }

  disable(): void {
    this.isEnabled = false;

    this.inputs.addressLine1.disable();
    this.inputs.addressLine2.disable();
    this.inputs.zipCode.disable();
    this.inputs.city.disable();
  }

  buildUp(address: AddressData) {
    address.line1 = this.inputs.addressLine1.value;
    address.line2 = this.inputs.addressLine2.value;
    address.zipCode = this.inputs.zipCode.value;
    address.city = this.inputs.city.value;
  }

  @action
  setInputs(address: AddressData) {
    this.inputs.addressLine1.value = address.line1;
    this.inputs.addressLine2.value = address.line2;
    this.inputs.zipCode.value = address.zipCode;
    this.inputs.city.value = address.city;
  }
}
