import { observer } from 'mobx-react';

import AppState from '../../../app/AppState';
import { PriceProvider } from '../../../app/context/PriceContext';
import Hero from '../../../app/shared/components/Hero/Hero';
import ImageZoomModal from '../../../app/shared/components/ImageZoomModal/ImageZoomModal';
import NavigationBar from '../../../app/shared/components/NavigationBar/NavigationBar';
import MenuSection from '../../../app/shared/components/StickyMenu/MenuSection';
import StickyMenu from '../../../app/shared/components/StickyMenu/StickyMenu';
import ProductCarousel from './components/ProductCarousel/ProductCarousel';
import ProductDetailsSection from './components/ProductDetailsSection/ProductDetailsSection';
import ProductVariations from './components/ProductVariations/ProductVariations';
import Teaser from './components/Teaser/Teaser';
import css from './ProductPage.css';
import { ProductPageState } from './ProductPageState';

const USER_GUIDE_COMPONENT_ID = 'user-guide';
const CONFIGURATOR_TEASER_COMPONENT_ID = 'configurator-teaser';

interface ProductPageProps {
  state: ProductPageState;
  appState: AppState;
}

const ProductPage = ({ state, appState }: ProductPageProps) => {
  const { store } = appState;

  return (
    <PriceProvider enable={true} displayDecimalPart={store.shouldDisplayDecimalPartOfPrices}>
      <ImageZoomModal
        state={state.configuratorState.modelViewer.zoomModal}
        translation={appState.translation.zoomModal}
        visualizationTranslation={appState.translation.common.chairVisualization}
      />
      <article className={css.ProductPage}>
        <StickyMenu
          menuComponent={
            <NavigationBar
              id={'navigationBar'}
              heading={state.productFamily.name}
              containerClassName={css.menuContainer}
            />
          }
        >
          {state.productFamily.mainImage && (
            <MenuSection name={state.translation.overviewSection.name} id="overview">
              <Hero
                className={css.sectionHero}
                image={state.productFamily.mainImage}
                description={state.productFamily.description}
                title={state.productFamily.name}
              />
            </MenuSection>
          )}
          <MenuSection name={state.translation.variationsSection.name} id="variations">
            <ProductVariations
              state={state.configuratorState}
              enableCustomisation={store.canCustomiseWebShopProduct()}
              enablePurchase={store.canPurchaseWebShopProduct()}
            />
          </MenuSection>
          <MenuSection name={state.translation.specificationSection.name} id="specification">
            <ProductDetailsSection
              assetsDownloaderState={state.assetsDownloaderState}
              guideUrl={state.guideUrl}
              id={state.id}
              maintenance={state.maintenance}
              specification={state.specification}
              translation={state.translation.productDetailsSection}
            />
          </MenuSection>
          {state.productFamily.userGuide && (
            <Teaser
              id={USER_GUIDE_COMPONENT_ID}
              description={state.productFamily.userGuide.description}
              title={state.productFamily.userGuide.title}
              video={{
                src: state.productFamily.userGuide.videoLink,
                posterSrc: state.productFamily.userGuide.videoPosterLink,
                title: state.productFamily.userGuide.videoOverlayText,
              }}
            />
          )}

          {state.relatedProducts.any() && (
            <ProductCarousel items={state.relatedProducts} translation={state.translation.productCarousel} />
          )}

          {state.pageContent?.teaser && (
            <Teaser
              id={CONFIGURATOR_TEASER_COMPONENT_ID}
              description={state.pageContent.teaser.description}
              link={state.pageContent.teaser.link}
              title={state.pageContent.teaser.title}
              image={state.pageContent.teaser.image}
              video={{
                src: state.pageContent.teaser.video?.url,
                posterSrc: state.pageContent.teaser.video?.posterUrl,
                title: state.pageContent.teaser.video?.title,
              }}
              mediaAlignment={state.pageContent.teaser.mediaAlignment}
              backgroundColor={state.pageContent.teaser.backgroundColor}
              textColor={state.pageContent.teaser.textColor}
            />
          )}
        </StickyMenu>
      </article>
    </PriceProvider>
  );
};

export default observer(ProductPage);
