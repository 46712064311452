import { Queue } from './Queue';

export interface IImagePreloader {
  load(urls: Array<string>): Promise<void>;
}

export class ImagePreloader implements IImagePreloader {
  public load(urls: Array<string>): Promise<void> {
    if (urls.length === 0) {
      return Promise.resolve();
    }

    const promises = urls.map(
      (x) =>
        new Promise((resolve) => {
          const image = new Image();
          image.onload = resolve;
          image.onerror = resolve;
          image.src = x;
        }),
    );

    const aggregatedPromise = Promise.all(promises);

    return new Promise((resolve, reject) => {
      aggregatedPromise.then(() => resolve()).catch(reject);
    });
  }
}

export class ImagePreloadingContext {
  public static queue = new Queue<string>();
}
