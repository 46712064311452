import Cookies from 'universal-cookie';

import { CookieBasedStorage, ILocalStorage } from '../../data/localStorage';

export interface IWarningData {
  isAccepted: boolean;
}

export interface IWarningLocalStorage {
  getDisclaimerAcceptance(): IWarningData;

  setDisclaimerAcceptance(data: IWarningData): void;
}

export class WarningLocalStorage implements IWarningLocalStorage {
  private disclaimerStorageKey: string = 'flokk-disclaimer-v1';
  private storage: ILocalStorage;

  constructor(storage: ILocalStorage) {
    this.storage = storage;
  }

  getDisclaimerAcceptance(): IWarningData {
    return this.storage.get<IWarningData>(this.disclaimerStorageKey);
  }

  setDisclaimerAcceptance(data: IWarningData): void {
    this.storage.set<IWarningData>(this.disclaimerStorageKey, data);
  }

  static inCookies(cookies: Cookies = new Cookies()): WarningLocalStorage {
    const maxAge = 30 * 86400; // 30 days
    return new WarningLocalStorage(new CookieBasedStorage(cookies, maxAge));
  }
}
