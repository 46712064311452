import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { ShareConfigurationSectionTranslation } from '../../../localization/SiteTranslation';
import HeaderLevel4 from '../../../shared/components/Typography/HeaderLevel4';
import { ConfigurationShareState } from '../../ConfigurationShareState';
import ConfigurationCodeShare from './ConfigurationCodeShare/ConfigurationCodeShare';
import ConfigurationDownload from './ConfigurationDownload/ConfigurationDownload';
import css from './ConfigurationShare.css';
import ConfigurationUrlShare from './ConfigurationUrlShare/ConfigurationUrlShare';

interface ConfigurationShareProps {
  state: ConfigurationShareState;
  isCodeLoading?: boolean;
  isCodeError?: boolean;
  className?: string;
  translation: ShareConfigurationSectionTranslation;
  showDownloads: boolean;
}

@observer
class ConfigurationShare extends Component<ConfigurationShareProps> {
  public static id = 'configuration-share';

  render() {
    const {
      className,
      translation,
      isCodeLoading,
      isCodeError,
      showDownloads,
      state: { absoluteShareUrl, code, downloadAsPdfCommand, downloadZipCommand, pdfName, zipName },
    } = this.props;

    return (
      <div id={ConfigurationShare.id} className={classNames(css.ConfigurationShare, className)}>
        <HeaderLevel4 className={css.header}>{translation.title}</HeaderLevel4>
        <ConfigurationCodeShare
          code={code}
          isCodeLoading={isCodeLoading}
          isCodeGenerationError={isCodeError}
          translation={translation.codeShare}
        />
        <ConfigurationUrlShare
          className={css.configurationLink}
          isCodeLoading={isCodeLoading}
          translation={translation}
          url={absoluteShareUrl}
        />
        {showDownloads && (
          <>
            <ConfigurationDownload
              className={css.downloadButtonContainer}
              downloadCommand={downloadAsPdfCommand}
              filename={pdfName}
              icon="pdf"
              label={translation.downloadAsPdf}
              disabled={isCodeLoading}
            />
            <ConfigurationDownload
              className={classNames(css.downloadButtonContainer, css.downloadZipContainer)}
              downloadCommand={downloadZipCommand}
              filename={zipName}
              icon="zip"
              label={translation.downloadVisualizations}
              disabled={isCodeLoading}
            />
          </>
        )}
      </div>
    );
  }
}

export default ConfigurationShare;
