import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';

import { OrderData } from '../../app/data/model';
import List from '../../app/shared/components/List/List';
import ThreeDotsLoader from '../../app/shared/components/Loaders/ThreeDotsLoader';
import Pagination from '../../app/shared/components/Pagination/Pagination';
import { PaginationState } from '../../app/shared/components/Pagination/PaginationState';
import { TabList } from '../../app/shared/components/TabList/TabList';
import HeaderLevel3 from '../../app/shared/components/Typography/HeaderLevel3';
import { TabContentTranslation } from '../localization/SiteTranslation';
import { Order } from './Order/Order';
import { OrderListFilters } from './OrderListFilters/OrderListFilters';
import { OrderListState } from './OrderListState';
import css from './OrdersPage.css';
import { PastOrdersTable } from './PastOrdersTable/PastOrdersTable';

interface Props {
  state: OrderListState;
}

@observer
export class OrdersPage extends Component<Props> {
  renderOngoingOrders = () => {
    const { state } = this.props;

    if (!state.ongoingOrdersTab.isActive) {
      return null;
    }

    return (
      <>
        <OrderListFilters
          state={state.orderListFilters}
          resetFilters={state.resetFilters}
          searchOrders={state.searchOrders}
        />
        <TabContent
          orders={state.orders}
          itemRenderer={(order, key) => (
            <Order order={order} key={key} translation={state.translation.tabContent.order} />
          )}
          isLoading={state.loadingIndicator.isLoading}
          pagination={state.pagination}
          translation={state.translation.tabContent}
        />
      </>
    );
  };

  renderPastOrders = () => {
    const { state } = this.props;

    if (!state.pastOrdersTab.isActive) {
      return null;
    }

    return (
      <>
        <OrderListFilters
          state={state.orderListFilters}
          resetFilters={state.resetFilters}
          searchOrders={state.searchOrders}
        />
        <TabContent
          orders={state.orders}
          itemRenderer={(order, key) => (
            <Order order={order} key={key} translation={state.translation.tabContent.order} />
          )}
          isLoading={state.loadingIndicator.isLoading}
          componentType="table"
          pagination={state.pagination}
          translation={state.translation.tabContent}
        />
      </>
    );
  };

  render() {
    const { state } = this.props;

    return (
      <section className={css.OrdersPage}>
        <TabList setActiveTab={state.ordersTabs.setActiveTab} tabs={state.ordersTabs.tabs} />
        {this.renderOngoingOrders()}
        {this.renderPastOrders()}
      </section>
    );
  }
}

export type TabContentComponentType = 'default' | 'table';

interface TabContentProps {
  pagination: PaginationState;
  orders: Array<OrderData>;
  itemRenderer: (order: OrderData, key: number) => ReactNode;
  isLoading: boolean;
  componentType?: TabContentComponentType;
  translation: TabContentTranslation;
}

const TabContent = observer(
  ({ orders, itemRenderer, isLoading, componentType, pagination, translation }: TabContentProps) => {
    if (isLoading) {
      return <ThreeDotsLoader className={css.tabLoader} size="large" />;
    }

    if (orders.empty()) {
      return (
        <HeaderLevel3 className={css.emptyOrdersContainer}>{translation.noOrdersMatchingCriteriaText}</HeaderLevel3>
      );
    }

    return componentType === 'table' ? (
      <>
        <PastOrdersTable orders={orders} translation={translation.pastOrdersTable} />
        <Pagination state={pagination} />
      </>
    ) : (
      <>
        <List itemClassName={css.Order} items={orders} itemRenderer={itemRenderer} />
        <Pagination state={pagination} />
      </>
    );
  },
);
