import { INavigationService } from '../shared/NavigationService';
import { AbsoluteUrl, RelativeUrl } from '../shared/Url';

export class SearchInputState {
  private navigationService: INavigationService;
  private searchUrl: string;

  constructor(navigationService: INavigationService) {
    this.navigationService = navigationService;
  }

  public initialize(searchUrl: string) {
    this.searchUrl = searchUrl;
  }

  private buildSearchUrl(phrase: string) {
    const relativeUrl = new RelativeUrl('/');

    if (phrase) {
      relativeUrl.setQueryParameter('phrase', phrase);
    }

    return AbsoluteUrl.parse(this.searchUrl).append(relativeUrl).toString();
  }

  submit(phrase: string) {
    this.navigationService.redirect(this.buildSearchUrl(phrase));
  }
}
