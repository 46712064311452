import { ConfigurationDetailsPageState } from '../../app/configurationDetails/ConfigurationDetailsPageState';
import ConfigurationSearchPage from '../../app/configurationSearch/ConfigurationSearchPage';
import { ConfigurationSearchPageState as QuickOrderState } from '../../app/configurationSearch/ConfigurationSearchPageState';
import ConfiguratorPage from '../../app/configurator/ConfiguratorPage';
import { ConfiguratorState } from '../../app/configurator/ConfiguratorState';
import ParseConfigurationCodeAndRedirectState from '../../app/errorHandling/ParseConfigurationCodeAndRedirectState';
import { CodeRouteParams, ConfiguratorRouteParams, ProductRouteParams } from '../../app/RouteDefinitions';
import { Route } from '../../app/routes';
import { nameOf } from '../../app/shared/common';
import { Navigation } from '../../app/shared/NavigationService';
import { AppState } from '../AppState';
import ArticlePage from '../article/ArticlePage';
import { ArticlePageState } from '../article/ArticlePageState';
import { HandleAuthCodeState } from '../authentication/HandleAuthCodeState';
import { HandleInvitationCodeState } from '../authentication/HandleInvitationCodeState';
import { InvitationPage } from '../authentication/InvitationPage';
import { InvitationState } from '../authentication/InvitationState';
import { OAuthHandlerPage } from '../authentication/OAuthHandlerPage';
import { CartListState } from '../cart/CartListState';
import { CartPage } from '../cart/CartPage';
import CatalogPage from '../catalog/CatalogPage';
import { CatalogPageState } from '../catalog/CatalogPageState';
import DashboardPage from '../dashboard/DashboardPage';
import { DashboardState } from '../dashboard/DashboardState';
import FavoritesPage from '../favorites/FavoritesPage';
import { FavoritesState } from '../favorites/FavoritesState';
import HomePage from '../home/HomePage';
import { HomeState } from '../home/HomeState';
import LoginPage from '../login/LoginPage';
import { LoginState } from '../login/LoginState';
import { OrderListState } from '../orders/OrderListState';
import OrderPage from '../orders/OrderPage';
import { OrdersPage } from '../orders/OrdersPage';
import { OrderState } from '../orders/OrderState';
import ProfilePage from '../profile/ProfilePage';
import { ProfileState } from '../profile/ProfileState';
import { B2bPdfUrlProvider } from '../shared/B2bPdfUrlProvider';
import { B2bShareConfiguratorUrlProvider } from '../shared/B2bShareConfiguratorUrlProvider';

export interface OrderRouteParams {
  orderNumber: string;
}

export interface ArticleRouteParams {
  slug: string;
}

export class RouteDefinitions {
  static instance = new RouteDefinitions();

  public homeRoute: Route<never> = {
    route: '/',
    async getPage(appState: AppState) {
      const state = new HomeState(
        appState.client,
        appState.authenticationService,
        appState.navigation,
        appState.translation.b2bHomePage,
      );
      const component = <HomePage appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public loginRoute: Route<never> = {
    route: '/login',
    async getPage(appState: AppState) {
      const state = new LoginState(appState.authenticationService, appState.navigation, appState.translation.loginPage);
      const component = <LoginPage appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public invitationRoute: Route<never> = {
    route: '/invitation',
    async getPage(appState: AppState) {
      const state = new InvitationState(
        appState.client,
        appState.navigation,
        appState.authenticationService,
        appState.translation.invitationPage,
      );
      const component = <InvitationPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public handleInvitationCodeRoute: Route<never> = {
    route: '/handle-invitation-code',
    async getPage(appState: AppState) {
      const state = new HandleInvitationCodeState(appState.client, appState.navigation, appState.authenticationService);
      const component = <OAuthHandlerPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public handleAuthCodeRoute: Route<never> = {
    route: '/handle-auth-code',
    async getPage(appState: AppState) {
      const state = new HandleAuthCodeState(appState.client, appState.navigation, appState.authenticationService);
      const component = <OAuthHandlerPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public dashboardRoute: Route<never> = {
    route: '/dashboard',
    async getPage(appState: AppState) {
      const state = new DashboardState(appState.client);
      const component = <DashboardPage appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public catalogRoute: Route<{}> = {
    route: `/products`,
    async getPage(appState: AppState, params: ProductRouteParams) {
      const state = new CatalogPageState(appState.translation.catalogPage, appState.navigation, appState.client);
      const component = <CatalogPage state={state} appState={appState} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public configuratorRoute: Route<ConfiguratorRouteParams> = {
    route: `/products/:${nameOf<ConfiguratorRouteParams>('slug')}/configure`,
    async getPage(appState: AppState, params: ConfiguratorRouteParams, query: Map<string, string>) {
      const state = new ConfiguratorState(
        appState.settings,
        appState.client,
        appState.navigation,
        appState.imagePreloader,
        new B2bPdfUrlProvider(appState.settings, appState.client),
        new B2bShareConfiguratorUrlProvider(appState.settings, appState.client),
        params.slug,
        appState.translation.configurationPage,
        appState.warningLocalStorage,
      );

      const component = <ConfiguratorPage appState={appState} state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public quickOrderRoute: Route<never> = {
    route: '/quick-order',
    async getPage(appState: AppState) {
      appState.translation.configurationSearchPage.pageTitleFormat = 'Quick Order';
      const state = new QuickOrderState(
        appState.translation.configurationSearchPage,
        Navigation.instance,
        appState.client,
      );
      const component = <ConfigurationSearchPage state={state} />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public codeRoute: Route<CodeRouteParams> = {
    route: `/code/:${nameOf<CodeRouteParams>('code')}`,
    aliases: [`/configure/:${nameOf<CodeRouteParams>('code')}`, `/shop/:${nameOf<CodeRouteParams>('code')}`],
    async getPage(appState: AppState, params: CodeRouteParams, query: Map<string, string>) {
      const state = new ParseConfigurationCodeAndRedirectState(
        appState.settings,
        appState.client,
        appState.navigation,
        params.code,
        query,
      );
      const component = <div />;
      const useSplashScreen = true;
      return { state, component, useSplashScreen };
    },
  };

  public cartRoute: Route<never> = {
    route: '/cart',
    async getPage(appState: AppState, params: {}) {
      const state = new CartListState(
        appState.client,
        appState.eventAggregator,
        appState.store,
        appState.navigation,
        appState.translation.cartPage,
      );
      const component = <CartPage appState={appState} state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public ordersRoute: Route<never> = {
    route: '/orders',
    async getPage(appState: AppState) {
      const state = new OrderListState(appState.client, appState.navigation, appState.translation.ordersPage);
      const component = <OrdersPage state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public orderRoute: Route<OrderRouteParams> = {
    route: `/orders/:${nameOf<OrderRouteParams>('orderNumber')}`,
    async getPage(appState: AppState, params: OrderRouteParams) {
      const state = new OrderState(
        params.orderNumber,
        appState.store.shoppingContext,
        appState.client,
        appState.navigation,
        appState.eventAggregator,
        appState.translation.orderPage,
      );
      const component = <OrderPage state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public favoritesRoute: Route<never> = {
    route: '/favorites',
    async getPage(appState: AppState, params: {}) {
      const state = new FavoritesState(
        appState.client,
        appState.navigation,
        appState.eventAggregator,
        appState.translation.favoritesPage,
      );
      const component = <FavoritesPage appState={appState} state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public profileRoute: Route<never> = {
    route: '/profile',
    async getPage(appState: AppState, params: {}) {
      const state = new ProfileState(
        appState.client,
        appState.authenticationService,
        appState.store.shoppingContext,
        appState.eventAggregator,
        appState.translation.profilePage,
        appState.languageCodes,
      );
      const translation = appState.translation.profilePage;
      const component = <ProfilePage state={state} translation={translation} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public articleRoute: Route<ArticleRouteParams> = {
    route: `/:${nameOf<ArticleRouteParams>('slug')}`,
    async getPage(appState: AppState, params: ArticleRouteParams) {
      const state = new ArticlePageState(appState.client, `flokk-hub/${params.slug}`);
      const component = <ArticlePage state={state} />;
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };

  public configurationDetailsRoute: Route<CodeRouteParams> = {
    route: `/configurations/:${nameOf<CodeRouteParams>('code')}`,
    async getPage(appState: AppState, params: CodeRouteParams) {
      const state = new ConfigurationDetailsPageState(
        appState.translation.configurationDetailsPage,
        appState.translation.common.chairVisualization,
        appState.translation.zoomModal,
        appState.translation.readyMadeProductPage.productVariations.modelViewer,
        appState.navigation,
        appState.client,
        appState.imagePreloader,
        new B2bPdfUrlProvider(appState.settings, appState.client),
        new B2bShareConfiguratorUrlProvider(appState.settings, appState.client),
        params.code,
      );
      const ConfigurationDetailsPage = require('../../app/configurationDetails/ConfigurationDetailsPage').default;
      const component = (
        <ConfigurationDetailsPage
          appState={appState}
          store={appState.store}
          state={state}
          translation={appState.translation.configurationDetailsPage}
          shareTranslation={appState.translation.configurationPage.summary.shareSection}
        />
      );
      const useSplashScreen = true;

      return { state, component, useSplashScreen };
    },
  };
}

export default RouteDefinitions.instance;
