import { action, makeObservable, observable, override } from 'mobx';

import AppSettings from '../AppSettings';
import { IApiClient } from '../data/client';
import {
  GetPromotionForConfigurationCodeQuery,
  GetPromotionForConfigurationCodeQueryResponse,
  RequestQuoteFormConfig,
} from '../data/model';
import { HubSpotPageTranslation } from '../localization/SiteTranslation';
import { Analytics } from '../shared/analytics/Analytics';
import ProductDataConverter from '../shared/analytics/ProductDataConverter';
import {
  HubSpotFormWithProductState,
  IHubSpotFormWithProductMemento,
} from '../shared/hubSpot/HubSpotFormWithProductState';
import { INavigationService } from '../shared/NavigationService';
import { StoreUrl } from '../shared/StoreUrl';
import { StoreState } from '../StoreState';
import { RequestQuoteQuery } from './RequestQuoteQuery';
import { RequestQuoteQueryParser } from './RequestQuoteQueryParser';

export interface IRequestQuoteMemento extends IHubSpotFormWithProductMemento<RequestQuoteFormConfig> {
  promotionResponse?: GetPromotionForConfigurationCodeQueryResponse;
}

export class RequestQuoteState extends HubSpotFormWithProductState<
  RequestQuoteFormConfig,
  IRequestQuoteMemento,
  RequestQuoteQuery
> {
  @observable.shallow
  promotionResponse?: GetPromotionForConfigurationCodeQueryResponse;

  constructor(
    client: IApiClient,
    settings: AppSettings,
    navigation: INavigationService,
    pageTranslation: HubSpotPageTranslation,
  ) {
    super(client, settings, navigation, pageTranslation);

    makeObservable(this);
  }

  protected parseQuery(rawQuery: Map<string, string>): RequestQuoteQuery {
    return RequestQuoteQueryParser.toModel(rawQuery);
  }

  private buildRequestQuoteEvent() {
    return {
      context: this.shoppingContext,
      products: [ProductDataConverter.fromProductConfigurationData(this.productConfigurationResponse?.configuration)],
    };
  }

  @override setFormSubmitted() {
    const event = this.buildRequestQuoteEvent();
    Analytics.trackAskForPriceCompleted(event);

    super.setFormSubmitted();
  }

  get isRequestProcessedByDealer() {
    return this.hubSpotConfig.isProcessedByDealer;
  }

  @action onFormSubmit() {
    const event = this.buildRequestQuoteEvent();
    Analytics.trackAskForPriceFormSubmitClick(event);
  }

  async onLoad(store: StoreState): Promise<void> {
    await super.onLoad(store);

    let promotionResponse: GetPromotionForConfigurationCodeQueryResponse = null;

    if (this.query.configurationCode) {
      promotionResponse = await this.client.send(
        new GetPromotionForConfigurationCodeQuery({
          configurationCode: this.query.configurationCode,
          campaignCode: this.query.campaignCode,
          shoppingContext: this.shoppingContext,
        }),
      );
    }

    this.initializeChild(promotionResponse);
  }

  protected initializeChild(promotionResponse: GetPromotionForConfigurationCodeQueryResponse) {
    this.promotionResponse = promotionResponse;
    // TODO: Enable it: Analytics.pushEvent(this.buildRequestQuoteEvent());
  }

  protected hubSpotConfigSelector(store: StoreState) {
    return store.storeResponse.storeContext.requestQuoteConfig;
  }

  protected ensureFeatureIsEnabled(store: StoreState) {
    if (!store.canRequestQuote()) {
      this.navigation.navigateTo(StoreUrl.homeUrl());
    }
  }

  getMemento(): IRequestQuoteMemento {
    const memento = super.getMemento();
    memento.promotionResponse = this.promotionResponse;
    return memento;
  }

  restoreMemento(memento: IRequestQuoteMemento) {
    this.promotionResponse = memento.promotionResponse;
    super.restoreMemento(memento);
  }

  get promotionDescription() {
    return this.promotionResponse?.promotionDescription;
  }
}
