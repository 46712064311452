import * as React from 'react';
import { ReactNode } from 'react';

import List from '../List/List';
import { PropertyItem, PropertyItemClassNamesProps, PropertyItemOptionProps } from './PropertyItem';

interface IProperty {
  label: ReactNode;
  value: ReactNode;
}

interface PropertyListProps {
  className?: string;
  itemClassName?: string;
  items: Array<IProperty>;
  propertyClassNames?: PropertyItemClassNamesProps;
  labelOptions?: PropertyItemOptionProps;
  valueOptions?: PropertyItemOptionProps;
}

export const PropertyList: React.FunctionComponent<PropertyListProps> = (props: PropertyListProps) => {
  const { className, itemClassName, propertyClassNames, ...otherProps } = props;
  return (
    <List
      className={className}
      itemClassName={itemClassName}
      items={props.items}
      itemRenderer={(item) => (
        <PropertyItem label={item.label} value={item.value} {...propertyClassNames} {...otherProps} />
      )}
    />
  );
};
