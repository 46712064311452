import { TranslationResolver } from '../../app/localization/TranslationResolver';
import DeDe from './de-de';
import EnGb from './en-gb';
import FrFr from './fr-fr';
import { SiteTranslation } from './SiteTranslation';

class TranslationResolverFactory {
  public static create(): TranslationResolver<SiteTranslation> {
    const translations = new Map<string, SiteTranslation>([
      ['en-gb', EnGb],
      ['fr-fr', FrFr],
      ['de-de', DeDe],
    ]);
    const defaultLanguageCode = 'en-gb';

    return new TranslationResolver<SiteTranslation>(translations, defaultLanguageCode);
  }
}

export default TranslationResolverFactory;
