import { observer } from 'mobx-react';

import { ContactData } from '../../../app/data/model';
import ActionMenu from '../../../app/shared/components/ActionMenu/ActionMenu';
import SecondaryButton from '../../../app/shared/components/Button/SecondaryButton';
import List from '../../../app/shared/components/List/List';
import ThreeDotsLoader from '../../../app/shared/components/Loaders/ThreeDotsLoader';
import { ConfirmationModal } from '../../../app/shared/components/Modal/ConfirmationModal/ConfirmationModal';
import { ITableHeaderCell, ITableRowData, Table } from '../../../app/shared/components/Table/Table';
import { UsersTableTranslation } from '../../localization/SiteTranslation';
import { ChangeUserRolesModal } from './ChangeUserRolesModal/ChangeUserRolesModal';
import { ConfirmInvitationRemovalModalState } from './ConfirmInvitationRemovalModalState';
import { InvitationsTable } from './InvitationsTable';
import { InviteNewUserModal } from './InviteNewUserModal/InviteNewUserModal';
import css from './UsersTab.css';
import { UsersTabState } from './UsersTabState';

const getHeaders = (translation: UsersTableTranslation) => {
  return {
    USER_NAME: translation.userNameHeader,
    ROLES: translation.rolesHeader,
    CONTEXT_MENU: translation.contextMenuHeader,
  };
};

const getTableHeaders = (translation: UsersTableTranslation) => {
  const headers = getHeaders(translation);

  const tableHeaders: ITableHeaderCell[] = new Array<ITableHeaderCell>();
  tableHeaders.push({ name: headers.USER_NAME, alignment: 'left' });
  tableHeaders.push({ name: headers.ROLES, alignment: 'left' });
  tableHeaders.push({ name: headers.CONTEXT_MENU, className: css.actionsColumn });

  return tableHeaders;
};

const getTableData = (contact: ContactData, state: UsersTabState): Array<ITableRowData> => {
  const renderRoleList = () => {
    return (
      <List
        className={css.rolesCell}
        itemClassName={css.role}
        items={contact.roles || []}
        itemRenderer={(role) => role}
      />
    );
  };

  const UserActions = () => (
    <ActionMenu
      items={[
        { label: state.translation.removeLabel, action: () => state.openContactRemovalConfirmation(contact) },
        { label: state.translation.editRolesLabel, action: () => state.openChangeUserRoles(contact) },
      ]}
      translation={state.translation.actionMenu}
    />
  );
  const headers = getHeaders(state.translation.usersTable);

  return [
    { headerName: headers.USER_NAME, value: contact.fullName, alignment: 'left' },
    { headerName: headers.ROLES, value: renderRoleList(), alignment: 'left' },
    { headerName: headers.CONTEXT_MENU, value: <UserActions />, alignment: 'right' },
  ];
};

const ConfirmInvitationRemovalModal = observer(({ state }: { state: ConfirmInvitationRemovalModalState }) => {
  if (!state.invitation) {
    return null;
  }

  return (
    <ConfirmationModal
      modal={state}
      title={state.translation.title}
      applyButton={{ command: state.removeInvitationCommand }}
    >
      <div>
        {state.translation.areYouSureRemoveInvitationText}
        <strong>
          {state.invitation.firstName} {state.invitation.lastName}
        </strong>
      </div>
    </ConfirmationModal>
  );
});

export const UsersTab = observer(({ state }: { state: UsersTabState }) => {
  const tableData = state.contacts.map((contact) => getTableData(contact, state));

  if (state.loadingIndicator.isLoading) {
    return <ThreeDotsLoader size="large" className={css.tabLoader} />;
  }

  return (
    <>
      {state.invitations.length > 0 && (
        <InvitationsTable
          invitations={state.invitations}
          removeInvitation={state.openInvitationRemovalConfirmation}
          className={css.invitationsTable}
          translation={state.translation.invitationsTable}
        />
      )}

      <div className={css.actionButtonContainer}>
        <SecondaryButton className={css.actionButton} onClick={() => state.inviteNewUserModal.open()}>
          {state.translation.inviteNewUserButton}
        </SecondaryButton>
      </div>

      {state.contacts.length > 0 && (
        <Table tableHeader={{ data: getTableHeaders(state.translation.usersTable) }} tableBody={{ data: tableData }} />
      )}

      <InviteNewUserModal state={state.inviteNewUserModal} />
      <ConfirmationModal
        modal={state.confirmUserRemovalModal}
        title={state.translation.removeUserTitle}
        applyButton={{ command: state.confirmUserRemovalModal.removeContactCommand }}
      >
        <div>
          {state.translation.removeContactFromOrganisationTextPart1}
          <strong>{state.confirmUserRemovalModal.contact?.fullName}</strong>
          {state.translation.removeContactFromOrganisationTextPart2}
        </div>
      </ConfirmationModal>
      <ConfirmInvitationRemovalModal state={state.confirmInvitationRemovalModal} />
      <ChangeUserRolesModal state={state.changeUserRolesModal} />
    </>
  );
});
