import { action, computed, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import {
  FooterData,
  GetNavigationQuery,
  GetNavigationQueryResponse,
  InitialStoreSelectorData,
  ShoppingContext,
  TopBarData,
} from '../data/model';
import { IMemento, IRestorable } from '../shared/common';
import ExternalLinks from '../shared/externalLinks';
import { INavigationService } from '../shared/NavigationService';
import { RelativeUrl } from '../shared/Url';
import { MenuItemState } from './MenuItemState';
import { SearchInputState } from './SearchInputState';

export interface INavigationMemento extends IMemento {
  response: GetNavigationQueryResponse;
}

export default class NavigationState implements IRestorable {
  private apiClient: IApiClient;
  private response: GetNavigationQueryResponse;
  private navigationService: INavigationService;

  @observable.ref
  footer: FooterData;

  search: SearchInputState;

  @observable.shallow
  menuItems: MenuItemState[] = [];

  @observable
  searchUrl: string;

  @observable
  homeUrl: string;

  @observable
  customerServiceUrl: string;

  @observable
  termsAndConditionsUrl: string;

  @observable
  legalAgreementsConsentHtml: string;

  @observable.ref
  initialStoreSelector?: InitialStoreSelectorData;

  @observable.ref
  topBar: TopBarData;

  @observable
  isStoreSelectorOpen = false;

  @computed
  get selectedMenuItems() {
    let level = this.menuItems;
    let selected = new Array<MenuItemState>();

    while (level.any()) {
      const item = level.find((x) => x.isOpen);
      if (item) {
        selected.push(item);
        level = item.children;
      } else {
        break;
      }
    }

    return selected;
  }

  @computed
  get isSearchEnabled() {
    return !!this.searchUrl;
  }

  constructor(apiClient: IApiClient, navigationService: INavigationService) {
    makeObservable(this);
    this.apiClient = apiClient;
    this.search = new SearchInputState(navigationService);
    this.navigationService = navigationService;
  }

  public async load(context: ShoppingContext): Promise<void> {
    const response = await this.apiClient.send(
      new GetNavigationQuery({
        shoppingContext: context,
      }),
    );

    this.initialize(response);
  }

  @action
  private initialize(response: GetNavigationQueryResponse) {
    this.response = response;
    this.footer = response.footer;

    this.searchUrl = response.searchUrl || null;
    this.homeUrl = response.homeUrl || ExternalLinks('flokk');
    this.customerServiceUrl = response.customerServiceUrl || ExternalLinks('flokkContactUs');
    this.termsAndConditionsUrl = response.termsAndConditionsUrl || ExternalLinks('flokkContactUs');
    this.legalAgreementsConsentHtml = response.legalAgreementsConsentHtml;
    this.initialStoreSelector = response.initialStoreSelector;
    this.topBar = response.topBar;

    this.search.initialize(this.searchUrl);

    if (response.menuItems) {
      response.menuItems.forEach((item, index) => {
        if (item.name) {
          this.menuItems.push(new MenuItemState(item, 0, index));
        }
      });
    }

    this.markActiveItems();
    this.navigationService.pageLoaded.subscribe(() => this.markActiveItems());
  }

  getMemento(): INavigationMemento {
    return {
      response: this.response,
    };
  }

  restoreMemento(memento: INavigationMemento): void {
    this.initialize(memento.response);
  }

  @action.bound
  public onItemClick(item: MenuItemState): void {
    const alreadySelected = this.selectedMenuItems.contains(item);

    const itemsToDeselect = this.selectedMenuItems.filter((x) => x.level >= item.level);
    itemsToDeselect.forEach((x) => {
      x.isOpen = false;
    });

    item.isOpen = alreadySelected ? false : true;
  }

  @action.bound
  private markActiveItems() {
    const currentUrlPath = this.navigationService.currentUrl.toString(true);
    MenuItemActivator.markActiveItems(currentUrlPath, this.menuItems);
  }

  @action.bound
  public goBack(): void {
    if (this.selectedMenuItems.any()) {
      this.selectedMenuItems.last().isOpen = false;
    }
    this.closeStoreSelector();
  }

  @action.bound
  public clearSelectedItems(): void {
    this.selectedMenuItems.forEach((x) => {
      x.isOpen = false;
    });
  }

  @action.bound
  public closeStoreSelector() {
    this.isStoreSelectorOpen = false;
  }

  @action.bound
  public openStoreSelector() {
    this.isStoreSelectorOpen = true;
  }
}

export class MenuItemActivator {
  public static markActiveItems(urlToMatch: string, menuItems: MenuItemState[]) {
    const bestMatch = this.getMenuItemBestMatchingCurrentUrl(urlToMatch, menuItems);

    if (bestMatch) {
      this.markSelectedItemBranchRecursively(bestMatch, menuItems);
    }
  }

  private static getMenuItemBestMatchingCurrentUrl(urlToMatch: string, menuItems: MenuItemState[]) {
    const { pathSegments, query } = RelativeUrl.parse(urlToMatch);

    const matchingMenuItems = this.getMenuItemsMatchingUrl(urlToMatch, menuItems);

    const evaluatedMenuItems = matchingMenuItems.map((menuItem) => {
      const itemUrl = RelativeUrl.parse(menuItem.data.url);

      let matchingSegments = 0;
      for (let segmentIndex = 0; segmentIndex < pathSegments.length; segmentIndex++) {
        if (pathSegments[segmentIndex] === itemUrl.pathSegments[segmentIndex]) {
          ++matchingSegments;
        } else {
          break;
        }
      }

      let matchingQueryParams = 0;
      query.forEach((value, key) => {
        if (itemUrl.query.get(key) === value) {
          ++matchingQueryParams;
        }
      });

      return { menuItem, menuItemQueryParamsCount: itemUrl.query.size, matchingSegments, matchingQueryParams };
    });

    const bestMatch = evaluatedMenuItems.sort((a, b) => {
      const segmentDifference = b.matchingSegments - a.matchingSegments;
      if (segmentDifference !== 0) {
        return segmentDifference;
      }

      const queryDifference = b.matchingQueryParams - a.matchingQueryParams;
      if (queryDifference !== 0) {
        return queryDifference;
      }

      // Prefer items without params if there are no matching query params
      if (a.matchingQueryParams === 0 && b.matchingQueryParams === 0) {
        return a.menuItemQueryParamsCount === 0 ? -1 : b.menuItemQueryParamsCount === 0 ? -1 : 0;
      }

      // Prefer items without params when current url does not have query part
      if (query.size === 0) {
        return a.menuItemQueryParamsCount === 0 ? -1 : b.menuItemQueryParamsCount === 0 ? -1 : 0;
      }

      return 0;
    })[0]?.menuItem;

    return bestMatch;
  }

  private static getMenuItemsMatchingUrl(urlToMatch: string, menuItems: MenuItemState[]) {
    const getFlatMenuItems = (items: MenuItemState[]): MenuItemState[] => {
      return items.reduce(
        (allItems, currentChild) => [...allItems, currentChild, ...getFlatMenuItems(currentChild.children)],
        [],
      );
    };

    const allFlatMenuItems = getFlatMenuItems(menuItems);
    const matchingMenuItems: MenuItemState[] = [];

    const currentUrl = RelativeUrl.parse(urlToMatch);

    allFlatMenuItems.forEach((item) => {
      if (item.data.url) {
        const itemUrl = RelativeUrl.parse(item.data.url);

        if (itemUrl.pathSegments.any()) {
          const itemUrlPath = itemUrl.path.toLowerCase();

          const urlMatches = urlToMatch.startsWith(itemUrlPath);
          const isQueryEmpty = itemUrl.query.size === 0;
          const queryMatches =
            Array.from(currentUrl.query.entries()).every(([key, value]) => itemUrl.query.get(key) === value) &&
            currentUrl.query.size > 0 &&
            itemUrl.query.size > 0;

          if (urlMatches && (isQueryEmpty || queryMatches)) {
            matchingMenuItems.push(item);
          }
        }
      }
    });

    return matchingMenuItems;
  }

  private static markSelectedItemBranchRecursively(selectedItem: MenuItemState, items: MenuItemState[]) {
    items.forEach((item) => {
      item.isActive = false;

      this.markSelectedItemBranchRecursively(selectedItem, item.children);

      if (item === selectedItem || item.children.any((x) => x.isActive)) {
        item.isActive = true;
      }
    });
  }
}
