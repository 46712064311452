import { Mesh, Scene, Vector3 } from '@babylonjs/core';

import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from './MeshPresenter';

export class RotationPresenter implements IMeshPresenter {
  update(scene: Scene, mesh: Mesh, object: ObjectState): void {
    mesh.rotation = new Vector3(object.rotation.x, object.rotation.y, object.rotation.z);
  }
}
