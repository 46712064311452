import { action, makeObservable, observable } from 'mobx';

import Event from './Event';

export class VisualizationShotsState {
  activeShotChanged: Event<string>;

  @observable active: string;
  @observable all: Array<string> = [];

  constructor() {
    makeObservable(this);
    this.activeShotChanged = new Event<string>();
  }

  @action.bound setActive(shot: string) {
    if (this.active === shot) {
      return;
    }

    this.active = shot;
    this.activeShotChanged.raise(shot);
  }

  @action setShots(shots: Array<string>) {
    this.all = shots;
    this.active = shots.any() ? shots[0] : null;
  }

  private findActiveShotIndex(): number {
    return this.all.findIndex((x) => this.active === x);
  }

  @action.bound nextSlide() {
    const index = this.findActiveShotIndex();
    if (index !== this.all.length - 1) {
      const nextIndex = index + 1;
      this.setActive(this.all[nextIndex]);
    } else {
      this.setActive(this.all[0]);
    }
  }

  @action.bound prevSlide() {
    const index = this.findActiveShotIndex();
    if (index !== 0) {
      const prevIndex = index - 1;
      this.setActive(this.all[prevIndex]);
    } else {
      const lastIndex = this.all.length - 1;
      this.setActive(this.all[lastIndex]);
    }
  }
}
