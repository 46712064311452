import { observer } from 'mobx-react';

import SecondaryButton from '../../../app/shared/components/Button/SecondaryButton';
import TertiaryButton from '../../../app/shared/components/Button/TertiaryButton';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { Authorize } from '../../authentication/Authorize';
import { BuyerPolicy } from '../../Policy';
import { CartHeader } from '../CartHeader/CartHeader';
import { CartItemList } from '../CartItemList/CartItemList';
import { CartListState } from '../CartListState';
import CartSummary from '../CartSummary/CartSummary';
import css from './CartList.css';

export const CartList = observer(({ state }: { state: CartListState }) => {
  const translation = state.translation.cartList;
  return (
    <>
      {state.carts.map((cart) => (
        <Panel
          className={css.CartList}
          bodyClassName={css.cart}
          key={cart.cartId}
          headerComponent={
            <CartHeader
              isSelected={cart.selected}
              brandName={cart.cartItems[0].item.baseModel.brandName}
              /*
               * The factory name is hidden for now because currently there is no case where it applies.
               * TODO: Uncomment this part when the purchase of the HAG Celi is enabled.
               * factoryName={cart.shoppingCart.factoryName}
               */
              preparingTimeInWorkingDays={cart.preparingTimeInWorkingDays}
              translation={translation.cartHeader}
            />
          }
        >
          <div className={css.cartListLayout}>
            <div className={css.cartListContainer}>
              <CartItemList items={cart.cartItems} />
            </div>
            <div className={css.cartSummaryContainer}>
              <CartSummary
                shoppingCart={cart.shoppingCart}
                numberOfItems={cart.numberOfItems}
                translation={translation.cartSummary}
              />
              <div className={css.actionButtonsContainer}>
                <TertiaryButton onClick={() => state.openSendConfigurationToEmailModal(cart)}>
                  <>{translation.sendByEmailButton}</>
                </TertiaryButton>
                <Authorize policy={BuyerPolicy}>
                  <SecondaryButton className={css.placeOrderButton} onClick={() => state.goToCartSummary(cart)}>
                    <>{translation.placeOrderButton}</>
                  </SecondaryButton>
                </Authorize>
              </div>
            </div>
          </div>
        </Panel>
      ))}
    </>
  );
});
