import { PaymentParameterData } from '../../data/model';

export class PaymentParameterDataExtensions {
  public static GetRequired(source: PaymentParameterData[], key: string): string {
    const found = source.find((x) => x.key === key);
    if (found == null) {
      throw `There is no parameter that matches ${key} key.`;
    }
    return found.value;
  }
}
