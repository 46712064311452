export class HttpError extends Error {
  public status: number;

  constructor(status: number, message: string) {
    super(`HTTP error with status = ${status}, ${message}`);

    // According to https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, HttpError.prototype);

    this.status = status;
  }

  public static fromError(status: number, error: Error): HttpError {
    var httpError = new HttpError(status, error.message);
    httpError.stack = error.stack;

    return httpError;
  }
}
