import { keysOf, KeysOfType, nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

export interface ICatalogPageQuery {
  type: string[];
  sector: string[];
  brand: string[];
  page?: number;
}

type FilterKey = KeysOfType<ICatalogPageQuery, string[]>;
type CatalogPageFilterQuery = Pick<ICatalogPageQuery, FilterKey>;

export class CatalogPageQueryParser extends QueryParser {
  static readonly PAGE_KEY = nameOf<ICatalogPageQuery>('page') as 'page';
  static readonly FILTER_KEYS = keysOf({
    type: undefined,
    sector: undefined,
    brand: undefined,
  } as CatalogPageFilterQuery);

  static fromModel(model: ICatalogPageQuery): Map<string, string> {
    const map = new Map<string, string>();

    this.FILTER_KEYS.forEach((k) => {
      super.setValues(map, k, model[k]);
    });

    super.setValue(map, this.PAGE_KEY, model.page?.toString());

    return map;
  }

  static toModel(query: Map<string, string>): ICatalogPageQuery {
    const result = {} as ICatalogPageQuery;

    this.FILTER_KEYS.forEach((k) => {
      result[k] = QueryParser.getValues(query, k, []);
    });

    const page = parseInt(QueryParser.getValue(query, this.PAGE_KEY, '1'), 10);
    result.page = page ?? null;

    return result;
  }
}
