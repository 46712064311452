import { observer } from 'mobx-react';
import * as React from 'react';

import { CategoryFilterTranslation } from '../../../../localization/SiteTranslation';
import { ICategoryFilterState } from '../DownloadsFiltersState';
import TabsMainGroup from './TabsMainGroup/TabsMainGroup';
import TabsSubgroups from './TabsSubgroups/TabsSubgroups';

interface TabsProps extends Omit<ICategoryFilterState, 'fullName'>, Pick<React.AriaAttributes, 'aria-controls'> {
  onTabSelect: () => void;
  resultsCounter: number;
  translation: CategoryFilterTranslation;
}

const Tabs: React.FC<TabsProps> = ({
  isTypeSelected,
  options,
  onTabSelect,
  resultsCounter,
  selection,
  translation,
  ...aria
}) => {
  return (
    <>
      <TabsMainGroup
        isTypeSelected={isTypeSelected}
        options={options}
        selection={selection}
        resultsCounter={resultsCounter}
        translation={{ name: translation.name, resultsCounter: translation.resultsCounter }}
        onTabSelect={onTabSelect}
        {...aria}
      />
      <TabsSubgroups
        options={options}
        selection={selection}
        translation={translation.fileTypeFilter}
        onTabSelect={onTabSelect}
        {...aria}
      />
    </>
  );
};

export default observer(Tabs);
