import AppSettings from '../AppSettings';
import ConfiguratorPageQueryParser from '../configurator/ConfiguratorPageQueryParser';
import { Tab } from '../configurator/Tab';
import { IApiClient } from '../data/client';
import { GetConfigurationByCodeQuery, ProductConfigurationData, ProductConfigurationOrigin } from '../data/model';
import { BasePageState } from '../shared/BasePageState';
import { INavigationService } from '../shared/NavigationService';
import { StoreUrl } from '../shared/StoreUrl';
import { AbsoluteUrl, RelativeUrl } from '../shared/Url';

export class ConfiguratorUrlBuilder {
  private client: IApiClient;

  constructor(client: IApiClient) {
    this.client = client;
  }

  public async buildFromCode(
    code: string,
    query: Map<string, string> = new Map<string, string>(),
    step: Tab = null,
  ): Promise<RelativeUrl> {
    const response = await this.client.send(new GetConfigurationByCodeQuery({ code: code }));
    const configuration = response.productConfiguration;

    return ConfiguratorUrlBuilder.buildFromConfiguration(code, configuration, query, step);
  }

  public static buildFromConfiguration(
    code: string,
    configuration: ProductConfigurationData,
    query: Map<string, string> = new Map<string, string>(),
    step: Tab = null,
  ): RelativeUrl {
    if (configuration.origin === ProductConfigurationOrigin.DefaultConfigurator) {
      const queryModel = ConfiguratorPageQueryParser.toModel(query);
      queryModel.options = configuration.options.map((x) => x.id);
      queryModel.step = step;
      queryModel.accessories = configuration.accessories.map((x) => x.id);
      queryModel.code = code;

      const redirectUrl = StoreUrl.configuratorUrl(configuration.baseModelCode, queryModel);
      return redirectUrl;
    } else {
      return StoreUrl.configurationDetailsUrl(code);
    }
  }
}

export default class ParseConfigurationCodeAndRedirectState extends BasePageState<null> {
  private setting: AppSettings;
  private client: IApiClient;
  private navigation: INavigationService;
  private code: string;
  private query: Map<string, string>;

  constructor(
    setting: AppSettings,
    client: IApiClient,
    navigation: INavigationService,
    code: string,
    query: Map<string, string>,
  ) {
    super();
    this.setting = setting;
    this.client = client;
    this.navigation = navigation;
    this.code = code;
    this.query = query;
  }

  async onLoad(): Promise<void> {
    const builder = new ConfiguratorUrlBuilder(this.client);
    const configuratorUrl = await builder.buildFromCode(this.code, this.query, Tab.Summary);
    const absoluteUrl = AbsoluteUrl.parse(this.setting.baseUrl).append(configuratorUrl).toString();

    this.navigation.redirect(absoluteUrl);
  }
}
