import classNames from 'classnames';
import { ReactNode } from 'react';

import css from './Subtitle.css';

interface SubtitleProps {
  className?: string;
  children: ReactNode;

  // font-size
  small?: boolean;

  // font-weight
  mediumBold?: boolean;
  bold?: boolean;

  // font-color
  colorGrey?: boolean;
  colorGreen?: boolean;
}

const Subtitle = ({ className, small, bold, colorGrey, colorGreen, children }: SubtitleProps) => {
  const classes = classNames(
    css.Subtitle,
    small && css.small,
    bold && css.bold,
    colorGrey && css.colorGrey,
    colorGreen && css.colorGreen,
    className,
  );
  return <span className={classes}>{children}</span>;
};

export const Subtitle1 = (props: SubtitleProps) => {
  return <Subtitle {...props} />;
};

export const Subtitle1DarkGrey = (props: SubtitleProps) => {
  return <Subtitle colorGrey {...props} />;
};

export const Subtitle2 = (props: SubtitleProps) => {
  return <Subtitle small {...props} />;
};

export const Subtitle2Bold = (props: SubtitleProps) => {
  return <Subtitle2 bold {...props} />;
};

export const Subtitle2DarkGrey = (props: SubtitleProps) => {
  return <Subtitle2 colorGrey {...props} />;
};

export const Subtitle2MediumGreen = (props: SubtitleProps) => {
  return <Subtitle2Bold colorGreen {...props} />;
};
