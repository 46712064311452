import { observer } from 'mobx-react';
import { Component, memo } from 'react';

import { TabList } from '../../app/shared/components/TabList/TabList';
import HeaderLevel2 from '../../app/shared/components/Typography/HeaderLevel2';
import { ProfilePageTranslation, RolesTranslation } from '../localization/SiteTranslation';
import { toRole } from '../Role';
import { PersonalTabContent } from './PersonalTab/PersonalTab';
import css from './ProfilePage.css';
import { ProfileState } from './ProfileState';
import { UsersTab } from './UsersTab/UsersTab';

interface ProfilePageProps {
  state: ProfileState;
  translation: ProfilePageTranslation;
}

function localizeRole(roleStr: string, translation: RolesTranslation) {
  const key = toRole(roleStr).uncapitalize() as keyof RolesTranslation;
  return translation[key];
}

const UserRoles = memo(({ roles, translation }: { roles: string[]; translation: RolesTranslation }) => {
  const formattedRoles = roles.map((roleStr) => localizeRole(roleStr, translation)).join(', ');
  return <div className={css.customerRole}> {formattedRoles}</div>;
});

@observer
export default class ProfilePage extends Component<ProfilePageProps> {
  constructor(props: ProfilePageProps) {
    super(props);
  }

  renderPersonalTab = () => {
    const { state } = this.props;

    if (!state.personalTab.isActive) {
      return null;
    }

    return <PersonalTabContent state={state.personalTabState} />;
  };

  renderUsersTab = () => {
    const { state } = this.props;

    if (!state.usersTabState || !state.usersTab?.isActive) {
      return null;
    }

    return <UsersTab state={state.usersTabState} />;
  };

  render() {
    const {
      personalTabState: { contact },
      profileTabs,
    } = this.props.state;

    return (
      <div className={css.ProfilePage}>
        <section className={css.profilePageContainer}>
          <header className={css.profilePageHeader}>
            <HeaderLevel2>
              <>
                {contact.firstName} {contact.lastName}
              </>
            </HeaderLevel2>
            {contact.roles.any() && <UserRoles roles={contact.roles} translation={this.props.translation.roles} />}
          </header>

          <TabList setActiveTab={profileTabs.setActiveTab} tabs={profileTabs.tabs} tabItemClassName={css.tabItem} />

          {this.renderPersonalTab()}
          {this.renderUsersTab()}
        </section>
      </div>
    );
  }
}
