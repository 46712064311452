import CountryCode from '../../../shared/CountryCode';
import { IFormatter, NoOpFormatter } from '../../../shared/Form';
import ZipCodeFormatterSE from './ZipCodeFormatterSE';

class ZipCodeFormatterFactory {
  public static create(countryCode: string): IFormatter {
    switch (countryCode) {
      case CountryCode.Sweden:
        return new ZipCodeFormatterSE();
      default:
        return new NoOpFormatter();
    }
  }
}

export default ZipCodeFormatterFactory;
