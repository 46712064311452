import { makeObservable } from 'mobx';

import { StoreData } from '../../../app/data/model';
import { ModalState } from '../../../app/shared/ModalState';
import { OrderCreationConfirmationModalTranslation } from '../../localization/SiteTranslation';

class OrderCreationConfirmationModalState extends ModalState {
  public constructor(
    public readonly currentStore: StoreData,
    public readonly translation: OrderCreationConfirmationModalTranslation,
  ) {
    super();
    makeObservable(this);
  }
}

export default OrderCreationConfirmationModalState;
