import { observer } from 'mobx-react';

import { ProductModelsTranslation } from '../../../localization/SiteTranslation';
import css from './ProductModels.css';
import ProductModelsAccordion from './ProductModelsAccordion/ProductModelsAccordion';
import ProductModelsModal from './ProductModelsModal/ProductModelsModal';
import { ProductModelsState } from './ProductModelsState';

interface ProductModelsProps {
  state: Required<ProductModelsState>;
  translation: ProductModelsTranslation;
  isAccordionExpanded: boolean;
  onAccordionClick: (isExpanded: boolean) => void;
}

const ProductModels = ({ isAccordionExpanded, onAccordionClick, state, translation }: ProductModelsProps) => {
  return (
    <>
      <ProductModelsModal className={css.modal} state={state} translation={translation} />
      <ProductModelsAccordion
        className={css.accordion}
        description={state.activeModelDescription}
        title={state.activeModelName}
        isExpanded={isAccordionExpanded}
        isLoading={state.isLoading}
        selectorState={state.selector}
        translation={translation}
        onIsExpandedChange={onAccordionClick}
        onModelClick={state.navigateToModelConfigurator}
      />
    </>
  );
};

export default observer(ProductModels);
