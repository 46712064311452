import { observer } from 'mobx-react';
import { Component } from 'react';

import { CssAnimation } from '../../../shared/animations';
import { AnimatedComponent, AnimatedComponentProps } from '../AnimatedComponent';
import css from './AnimatedChair.css';

export class ChairAnimation {
  initPosition: CssAnimation;
  show: CssAnimation;
  moveLeft: CssAnimation;
  moveRight: CssAnimation;
  fast: CssAnimation;
  medium: CssAnimation;
  slow: CssAnimation;

  constructor() {
    this.initPosition = new CssAnimation();
    this.show = new CssAnimation();
    this.moveLeft = new CssAnimation();
    this.moveRight = new CssAnimation();
    this.fast = new CssAnimation();
    this.medium = new CssAnimation();
    this.slow = new CssAnimation();
  }
}

@observer
export class AnimatedChair extends Component<AnimatedComponentProps, {}> {
  constructor(props: AnimatedComponentProps) {
    super(props);
  }

  render() {
    return <AnimatedComponent {...this.props} css={css} />;
  }
}
