import { observer } from 'mobx-react';
import { Component } from 'react';

import DownloadList from './components/DownloadList/DownloadList';
import Filters from './components/Filters/Filters';
import css from './DownloadsPage.css';
import { DownloadsPageState } from './DownloadsPageState';

const DOWNLOADS_LIST_ID = 'downloadsList';

interface DownloadsPageProps {
  state: DownloadsPageState;
}

@observer
class DownloadsPage extends Component<DownloadsPageProps, {}> {
  render() {
    const {
      state: {
        counter,
        downloadAllCommand,
        downloadAllEnabled,
        downloads,
        filters,
        loadingIndicator,
        pagination,
        searchAssets,
        translation,
      },
    } = this.props;

    return (
      <article className={css.DownloadsPage}>
        <h1 className="visuallyHidden">{translation.pageTitleFormat}</h1>
        <Filters
          downloadAllCommand={downloadAllCommand}
          brands={filters.brands}
          categories={filters.categories}
          downloadAllEnabled={downloadAllEnabled}
          onFilter={searchAssets}
          resultsCounter={counter}
          translation={translation.filters}
          aria-controls={DOWNLOADS_LIST_ID}
        />
        <DownloadList
          id={DOWNLOADS_LIST_ID}
          isLoading={loadingIndicator.isLoading}
          items={downloads}
          pagination={pagination}
          resultsCounter={counter}
          translation={translation.downloadList}
        />
      </article>
    );
  }
}

export default DownloadsPage;
