import classNames from 'classnames';

import css from './DefaultImage.css';

interface DefaultImageProps {
  className?: string;
  rounded?: boolean;
}

const DefaultImage = (props: DefaultImageProps) => {
  const { className, rounded } = props;
  const defaultImageClasses = classNames(className, css.stripesBackground, rounded && css.rounded);

  return <div className={defaultImageClasses} />;
};

export default DefaultImage;
