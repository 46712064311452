import Cookies from 'universal-cookie';

import { CookieBasedStorage, CustomerContextLocalStorage, MaxAge } from '../app/data/localStorage';

export class CustomerContextLocalStorageFactory {
  public static createForCookies(cookies: Cookies = new Cookies()): CustomerContextLocalStorage {
    return new CustomerContextLocalStorage(
      'flokk-dealer-customer-v1',
      new CookieBasedStorage(cookies, MaxAge.inDays(30)),
    );
  }
}
