import { RenderWhenPriceEnabled } from '../../context/PriceContext';
import { MoneyData } from '../../data/model';
import Price from './Price';
import css from './PriceDifference.css';

interface PriceProps {
  price: MoneyData;
  className?: string;
}

const PriceDifference = ({ price, className }: PriceProps) => {
  const sign = price.amount > 0 ? '+' : '-';

  return (
    <RenderWhenPriceEnabled>
      {price.amount !== 0 && (
        <span className={css.PriceDifference}>
          {sign && <span className={css.differenceSign}>{sign}</span>}
          <Price price={price} className={className} />
        </span>
      )}
    </RenderWhenPriceEnabled>
  );
};

export default PriceDifference;
