import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';

import { Breakpoint, Screen, ScreenObserver } from '../../../layout/ScreenObserver';
import { ProductModelSelectorTranslation } from '../../../localization/SiteTranslation';
import Carousel, { CarouselProps } from '../../../shared/components/Carousel/Carousel';
import {
  SliderNextArrow,
  SliderPrevArrow,
} from '../../../shared/components/SliderNavigationArrows/SliderNavigationArrows';
import Heading from '../../../shared/components/Typography/Heading/Heading';
import { VisualizationAspectRatio } from '../../../shared/visualization';
import css from './ProductModelSelector.css';
import ProductModelSelectorItem from './ProductModelSelectorItem/ProductModelSelectorItem';
import ProductModelSelectorState from './ProductModelSelectorState';

interface ProductModelSelectorProps {
  className?: string;
  state: ProductModelSelectorState;
  translation: ProductModelSelectorTranslation;
}

const CustomPrevArrow = (props: CustomArrowProps) => {
  return (
    <SliderPrevArrow
      className={classNames(props.className, css.arrowPrev)}
      iconClassName={css.icon}
      onClick={props.onClick}
    />
  );
};

const CustomNextArrow = (props: CustomArrowProps) => {
  return (
    <SliderNextArrow
      className={classNames(props.className, css.arrowNext)}
      iconClassName={css.icon}
      onClick={props.onClick}
    />
  );
};

const getSelectorItemWidth = (visualizationAspectRatio: string, breakpoint: Breakpoint) => {
  /*
   * The value is determined by the size of the layout.
   * It is necessary because the carousel, with the truthy variableWidth property, requires setting a fixed size for each item.
   */
  const selectorWidth = breakpoint === Breakpoint.Desktop ? '594px' : '(100vw - 9.6rem)';
  const numberOfColumns = breakpoint === Breakpoint.Phone ? 2 : 4;
  const itemColumnSpan = visualizationAspectRatio === VisualizationAspectRatio.Wide ? 2 : 1;

  return `calc(${selectorWidth} / ${numberOfColumns} * ${itemColumnSpan})`;
};

const ProductModelSelector = observer(function ({ state, className, translation }: ProductModelSelectorProps) {
  const sliderSettings: CarouselProps = {
    speed: 300,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    variableWidth: true,
  };

  const sliderRef = useRef<Slider>();
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(Breakpoint.Phone);

  const handleClick = (id: string) => {
    state.selectItem(id);
    sliderRef.current.slickGoTo(state.activeIndex);
  };

  useEffect(() => {
    const handleScreenChange = (screen: Screen) => setBreakpoint(screen.breakpoint);

    ScreenObserver.onScreenChange.subscribe(handleScreenChange);
    handleScreenChange(ScreenObserver.current);

    return () => ScreenObserver.onScreenChange.unsubscribe(handleScreenChange);
  }, []);

  return (
    <div className={className}>
      <Heading level={2} className={css.heading}>
        {translation.heading}
      </Heading>
      <Carousel ref={sliderRef} className={css.carousel} {...sliderSettings} initialSlide={state.activeIndex}>
        {state.items.map((item) => (
          <ProductModelSelectorItem
            key={item.data.id}
            style={{ width: getSelectorItemWidth(item.data.visualizationAspectRatio, breakpoint) }}
            item={item}
            handleClick={() => handleClick(item.data.id)}
          />
        ))}
      </Carousel>
    </div>
  );
});

export default ProductModelSelector;
