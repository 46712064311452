import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, KeyboardEvent, MouseEvent } from 'react';

import { AppState } from '../../../AppState';
import { TopBarTranslation } from '../../../localization/SiteTranslation';
import TextButton from '../../../shared/components/Button/TextButton';
import Icon from '../../../shared/components/Icon/Icon';
import InputSearch from '../../../shared/components/InputSearch/InputSearch';
import InputSearchExpanding from '../../../shared/components/InputSearch/InputSearchExpanding/InputSearchExpanding';
import Link from '../../../shared/components/Link';
import List from '../../../shared/components/List/List';
import Logo from '../../../shared/components/Logo/Logo';
import { ScrollLock } from '../../../shared/components/ScrollLock/ScrollLock';
import { MenuItemState } from '../../MenuItemState';
import css from './GeneralHeaderMainBar.css';
import StoreAndLanguageSubmenu from './StoreAndLanguageSubmenu/StoreAndLanguageSubmenu';

interface GeneralHeaderMainBarProps {
  className?: string;
  isLogoSmall?: boolean;
  logoLink?: string;
  modelName?: string;
  translation: TopBarTranslation;
  appState: AppState;
}

interface GeneralHeaderMainBarState {
  hamburgerMenuOpen: boolean;
  lastItem: MenuItemState;
}

@observer
export default class GeneralHeaderMainBar extends Component<GeneralHeaderMainBarProps, GeneralHeaderMainBarState> {
  constructor(props: GeneralHeaderMainBarProps) {
    super(props);

    this.state = {
      hamburgerMenuOpen: false,
      lastItem: null,
    };

    this.toggleHamburgerMenu = this.toggleHamburgerMenu.bind(this);
    this.handleOnMenuItemClick = this.handleOnMenuItemClick.bind(this);
    this.handleOnSearchEnterPress = this.handleOnSearchEnterPress.bind(this);
    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.renderSubmenuCategoryDesktop = this.renderSubmenuCategoryDesktop.bind(this);
  }

  handleOnMenuItemClick(e: MouseEvent<HTMLElement>, item: MenuItemState) {
    this.props.appState.navigationState.onItemClick(item);
    e.stopPropagation();
  }

  handleOnSearchEnterPress(e: KeyboardEvent<HTMLInputElement>) {
    this.props.appState.navigationState.search.submit(e.currentTarget.value);
  }

  toggleHamburgerMenu() {
    const { navigationState } = this.props.appState;

    navigationState.clearSelectedItems();
    navigationState.closeStoreSelector();
    this.setState({ hamburgerMenuOpen: !this.state.hamburgerMenuOpen });
  }

  renderSubmenuLink(item: MenuItemState) {
    return (
      <Link
        className={classNames(css.subMenuItemLinkDesktop, item.isActive && css.isActive)}
        href={item.data.url}
        target={item.data.target}
      >
        {item.data.name}
      </Link>
    );
  }

  renderSubmenuCategoryDesktop(item: MenuItemState) {
    return item.isGroupingItem ? (
      <>
        <span className={css.subMenuItemCategoryName}>{item.data.name}</span>
        <List itemClassName={css.subMenuItem} items={item.children} itemRenderer={this.renderSubmenuLink} />
      </>
    ) : (
      this.renderSubmenuLink(item)
    );
  }

  renderSubmenuMobile(item: MenuItemState) {
    const { navigationState } = this.props.appState;

    const classes = classNames(
      css.menuItemsContainer,
      css.mobileSubmenu,
      navigationState.selectedMenuItems.any() &&
        item.level < navigationState.selectedMenuItems.last().level &&
        css.mobileSubmenuHide,
      navigationState.selectedMenuItems.any() &&
        item.isOpen &&
        item.level === navigationState.selectedMenuItems.last().level &&
        css.mobileSubmenuOpen,
    );

    return (
      item.isGroupingItem && (
        <div className={classes}>
          <div className={classNames(css.goBackContainer, item.isGroupingItem && css.show)}>
            <TextButton
              className={css.backButton}
              childrenClassName={css.inner}
              onClick={(e) => {
                navigationState.goBack();
                e.stopPropagation();
              }}
            >
              <>
                <Icon name="angleLeft" className={css.backIcon} />
                <span className={css.parentName}>{item.data.name}</span>
              </>
            </TextButton>
          </div>
          <List
            className={css.menuItemList}
            itemClassName={css.menuItemContainer}
            items={item.children}
            itemRenderer={this.renderMenuItem}
          />
        </div>
      )
    );
  }

  renderMenuItem = (item: MenuItemState) => {
    return (
      <div className={css.menuItem}>
        <Icon
          className={classNames(css.moreItemsIcon, item.isGroupingItem && css.showMoreItemsIcon)}
          name="angleRight"
        />

        {item.children.any() ? (
          <span
            className={classNames(
              css.menuItemLink,
              item.isOpen && css.isGroupingItemOpen,
              item.isActive && css.isActive,
            )}
            onClick={(e) => this.handleOnMenuItemClick(e, item)}
          >
            {item.data.name}
          </span>
        ) : (
          <Link
            href={item.data.url}
            className={classNames(css.menuItemLink, item.isActive && css.isActive)}
            target={item.data.target}
          >
            {item.data.name}
          </Link>
        )}

        {this.renderSubmenuMobile(item)}

        {/*Desktop submenu*/}
        {item.isGroupingItem && (
          <div
            className={classNames(
              css.desktopSubMenu,
              classNames({
                [css.show]: item.isOpen,
                [css.stretched]: item.hasGroupingChildren,
              }),
            )}
          >
            <List
              className={css.submenuCategoryContainerDesktop}
              itemClassName={css.submenuCategoryItemContainerDesktop}
              items={item.children}
              itemRenderer={this.renderSubmenuCategoryDesktop}
            />
          </div>
        )}
      </div>
    );
  };

  renderStoreAndLanguageSelector() {
    const { navigationState, storeAndLanguageSelector } = this.props.appState;

    return (
      <div className={css.menuItem}>
        <Icon className={classNames(css.moreItemsIcon, css.showMoreItemsIcon)} name="angleRight" />
        <TextButton className={css.menuItemLink} onClick={navigationState.openStoreSelector}>
          {storeAndLanguageSelector.currentStore.name}
        </TextButton>
        <div
          className={classNames(css.menuItemsContainer, css.mobileSubmenu, {
            [css.mobileSubmenuOpen]: navigationState.isStoreSelectorOpen,
          })}
        >
          <div className={classNames(css.goBackContainer, css.show)}>
            <TextButton
              className={css.backButton}
              childrenClassName={css.inner}
              onClick={(e) => {
                navigationState.goBack();
                e.stopPropagation();
              }}
            >
              <Icon name="angleLeft" className={css.backIcon} />
              <span className={css.parentName}>{storeAndLanguageSelector.translation.title}</span>
            </TextButton>
          </div>
          <StoreAndLanguageSubmenu state={storeAndLanguageSelector} />
        </div>
      </div>
    );
  }

  render() {
    const { className, appState: state, isLogoSmall, modelName, translation } = this.props;

    return (
      <ScrollLock isLocked={this.state.hamburgerMenuOpen}>
        <nav className={classNames(css.nav, this.state.hamburgerMenuOpen && css.hamburgerMenuOpen, className)}>
          <Logo
            className={classNames(css.logo, { [css.logoSmall]: isLogoSmall })}
            link={this.props.logoLink}
            logoClassName={css.logoLink}
            isSmall={isLogoSmall}
          />
          <div className={css.modelNameContainer}>
            <p className={css.modelName}>{modelName}</p>
          </div>
          <button className={css.hamburger} onClick={this.toggleHamburgerMenu}>
            <div className={css.hamburgerBox}>
              <div className={classNames(css.hamburgerInner, this.state.hamburgerMenuOpen && css.isOpen)} />
            </div>
          </button>
          <div className={css.mainMenu}>
            <div className={css.mainMenuContainer}>
              {state.navigationState && (
                <ul
                  className={classNames(css.menuItemsContainer, {
                    [css.menuFirstLevelMobileHide]:
                      !state.navigationState.selectedMenuItems.empty() || state.navigationState.isStoreSelectorOpen,
                  })}
                >
                  {state.navigationState.isSearchEnabled && (
                    <li className={classNames(css.menuItemContainer, css.iconMobile, css.inputSearchMobileContainer)}>
                      <InputSearch
                        className={css.inputSearchMobile}
                        onEnterKeyPress={this.handleOnSearchEnterPress}
                        placeholderTranslation={translation.inputSearchPlaceholder}
                      />
                    </li>
                  )}

                  {state.navigationState.menuItems.any() &&
                    state.navigationState.menuItems.map((item) => {
                      return (
                        <li key={item.key} className={css.menuItemContainer}>
                          {this.renderMenuItem(item)}
                        </li>
                      );
                    })}

                  <li className={classNames(css.menuItemContainer, css.mobileOnly)}>
                    {this.renderStoreAndLanguageSelector()}
                  </li>

                  {/* TODO: Remove condition when menu is updated in all stores */}
                  {state.navigationState.topBar.isEnabled && (
                    <>
                      {/*
                       * TODO: Uncomment when the shopping cart is ready and add onClick handler/href
                       * <li className={css.buttons}>
                       *   <TertiaryButton className={css.button}>
                       *     <span className={css.inner}>
                       *       <Icon className={css.icon} name="basket" />
                       *       {state.navigationState.topBar.translation.cartTranslation.shoppingCartText}
                       *     </span>
                       *   </TertiaryButton>
                       * </li>
                       */}

                      {state.navigationState.topBar.menu.any() &&
                        state.navigationState.topBar.menu.map((item, index) => (
                          <li className={css.secondaryMenuItem} key={index}>
                            <Link className={css.secondaryMenuItemLink} href={item.url} target={item.target}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
          {state.navigationState.isSearchEnabled && (
            <InputSearchExpanding
              className={css.inputSearchDesktop}
              onEnterKeyPress={this.handleOnSearchEnterPress}
              placeholderTranslation={translation.inputSearchPlaceholder}
            />
          )}
        </nav>
      </ScrollLock>
    );
  }
}
