import classNames from 'classnames';
import * as React from 'react';

import css from './TabPanel.css';

interface TabPanelProps {
  children: React.ReactChild;
  id: string;
  isVisible: boolean;
  minHeight: number;
  tabId: string;
  title: string;
  className?: string;
  innerClassName?: string;
  hiddenPanelClassName?: string;
}

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>(
  ({ children, className, id, innerClassName, isVisible, minHeight, tabId, title, hiddenPanelClassName }, ref) => {
    return (
      <section
        role="tabpanel"
        id={id}
        className={classNames(css.panel, { [css.hidden]: !isVisible, [hiddenPanelClassName]: !isVisible }, className)}
        aria-labelledby={tabId}
        aria-hidden={!isVisible}
        tabIndex={-1}
        style={{ minHeight: isVisible ? minHeight : 0 }}
      >
        <div ref={ref} className={innerClassName}>
          <h2 className="visuallyHidden">{title}</h2>
          {children}
        </div>
      </section>
    );
  },
);

export default TabPanel;
