import { reaction } from 'mobx';

import { FabricOptionState } from './FabricOptionState';
import { IOptionState, OptionVariationState } from './OptionState';

export class KindOfFabricCoordinator {
  private mainFabricOption: FabricOptionState;
  private dependentFabricOptions: FabricOptionState[];

  private lastKindOfFabricVariationId: string;
  private desynchronizedDependentOptionsIds: Set<string> = new Set<string>();

  constructor(fabricsOptions: FabricOptionState[]) {
    if (fabricsOptions.length <= 1) {
      throw Error(`Invalid operation, expected more than 1 fabric options, but got ${fabricsOptions.length}`);
    }

    this.mainFabricOption = fabricsOptions.find((x) => x.data.determinesPriceGroup) ?? fabricsOptions.first();
    this.lastKindOfFabricVariationId = this.mainFabricOption.selected.data.kindOfFabricVariationIds.first();

    this.dependentFabricOptions = fabricsOptions.filter((x) => x !== this.mainFabricOption);
    this.dependentFabricOptions.forEach((x) => {
      this.setIncludedKindOfFabricForDependentOption(
        x,
        this.lastKindOfFabricVariationId,
        this.mainFabricOption.data.code,
      );
      reaction(
        () => x.selected,
        (selected) => this.rememberWhenDependentOptionIsDesynchronized(x, selected),
        { fireImmediately: true },
      );
    });

    reaction(
      () => this.mainFabricOption.selected,
      (selected) => this.keepDependentOptionsInSyncWhenMainChanged(selected),
      { fireImmediately: true },
    );
  }

  private rememberWhenDependentOptionIsDesynchronized(
    dependentOption: FabricOptionState,
    selected: OptionVariationState,
  ) {
    if (selected.code !== this.mainFabricOption.selected.code) {
      this.desynchronizedDependentOptionsIds.add(dependentOption.id);
    }
  }

  private keepDependentOptionsInSyncWhenMainChanged(mainSelected: OptionVariationState) {
    const newKindOfFabricVariationId = mainSelected.data.kindOfFabricVariationIds.first();
    const newSelectedFabricCode = mainSelected.data.code;

    this.dependentFabricOptions.forEach((dependentOption) => {
      if (newKindOfFabricVariationId === this.lastKindOfFabricVariationId) {
        if (!this.desynchronizedDependentOptionsIds.has(dependentOption.id)) {
          this.preselectDependentVariation(dependentOption, newKindOfFabricVariationId, newSelectedFabricCode);
        }
      } else {
        this.setIncludedKindOfFabricForDependentOption(
          dependentOption,
          newKindOfFabricVariationId,
          newSelectedFabricCode,
        );
      }
    });

    this.lastKindOfFabricVariationId = newKindOfFabricVariationId;
  }

  private setIncludedKindOfFabricForDependentOption(
    dependentOption: FabricOptionState,
    newKindOfFabricVariationId: string,
    newSelectedFabricCode: string,
  ) {
    dependentOption.setIncludedKindOfFabrics([newKindOfFabricVariationId]);
    if (!dependentOption.selected.data.kindOfFabricVariationIds.contains(newKindOfFabricVariationId)) {
      this.preselectDependentVariation(dependentOption, newKindOfFabricVariationId, newSelectedFabricCode);
      this.desynchronizedDependentOptionsIds.delete(dependentOption.id);
    }
  }

  private preselectDependentVariation(
    dependentOption: FabricOptionState,
    newKindOfFabricVariationId: string,
    newSelectedFabricCode: string,
  ) {
    const filteredVariations = dependentOption
      .getAllItems()
      .filter((x) => x.data.kindOfFabricVariationIds.contains(newKindOfFabricVariationId));

    if (filteredVariations.empty()) {
      return;
    }

    const variationToPreselect =
      filteredVariations.find((x) => x.data.code === newSelectedFabricCode) ?? filteredVariations.first();
    dependentOption.selectItem(variationToPreselect);
  }
}

export class KindOfFabricCoordinatorFactory {
  public static createMany(options: IOptionState[]) {
    Object.values(
      options
        .filter((x) => !!x.data.kindOfFabricId)
        .filter((x) => x instanceof FabricOptionState)
        .groupBy<FabricOptionState>((x) => x.data.kindOfFabricId),
    )
      .filter((x) => x.length > 1)
      .forEach((kindOfFabricGroup) => {
        // tslint:disable-next-line:no-unused-expression
        new KindOfFabricCoordinator(kindOfFabricGroup);
      });
  }
}
