import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { MenuItem } from '../../../data/model';
import List from '../../../shared/components/List/List';
import { Breakpoint, Screen, ScreenObserver } from '../../ScreenObserver';
import FooterLink from './FooterLink/FooterLink';
import css from './LinkList.css';
import LinkListAccordion from './LinkListAccordion/LinkListAccordion';

interface LinkListSectionProps {
  items: MenuItem[];
  className?: string;
}

const LinkList = ({ className, items }: LinkListSectionProps) => {
  const [isMobileView, setIsMobileView] = useState<boolean>(true);

  const renderLink = (link: MenuItem) => {
    return <FooterLink url={link.url}>{link.name}</FooterLink>;
  };

  const renderList = (list: MenuItem) => {
    return (
      <>
        {!isMobileView && <h2 className={css.listTitle}>{list.name}</h2>}
        <List itemClassName={css.item} items={list.children} itemRenderer={renderLink} />
      </>
    );
  };

  useEffect(() => {
    const handleScreenChange = (screen: Screen) => {
      const isDesktop = screen.breakpoint === Breakpoint.Desktop;
      setIsMobileView(!isDesktop);
    };

    ScreenObserver.onScreenChange.subscribe(handleScreenChange);

    handleScreenChange(ScreenObserver.current);

    return () => ScreenObserver.onScreenChange.unsubscribe(handleScreenChange);
  }, []);

  return isMobileView ? (
    <LinkListAccordion className={className} items={items} renderItem={renderList} />
  ) : (
    <List
      className={classNames(css.container, className)}
      itemClassName={css.list}
      items={items}
      itemRenderer={renderList}
    />
  );
};

export default LinkList;
