import { CarouselSlideData, WebShopHeaderData } from '../../../../../app/data/model';
import Carousel from '../../../../../app/shared/components/Carousel/Carousel';
import HeaderSlide from './HeaderSlide/HeaderSlide';

type HeaderProps = WebShopHeaderData;

const Header = ({ backgroundColor, slides, textColor }: HeaderProps) => {
  const renderSlide = (item: CarouselSlideData, index: number) => (
    <HeaderSlide
      backgroundColor={backgroundColor?.rgba}
      textColor={textColor?.rgba}
      image={item.image}
      title={item.title}
      key={index}
    />
  );

  return (
    <header>
      <Carousel autoplay dots infinite>
        {slides.map(renderSlide)}
      </Carousel>
    </header>
  );
};

export default Header;
