import { observer } from 'mobx-react';
import * as React from 'react';

import { CrossButton } from '../../../app/shared/components/Button/CustomButtons';
import TertiaryButton from '../../../app/shared/components/Button/TertiaryButton';
import DateRange from '../../../app/shared/components/DateRange/DateRange';
import FormInput from '../../../app/shared/components/FormInput';
import Label from '../../../app/shared/components/Label/Label';
import Select from '../../../app/shared/components/Select/Select';
import { Switch } from '../../components/Switch';
import css from './OrderListFilters.css';
import { OrderListFiltersState } from './OrderListFiltersState';

interface OrdersFiltersProps {
  state: OrderListFiltersState;
  resetFilters: () => void;
  searchOrders: () => void;
}

export const OrderListFilters = observer((props: OrdersFiltersProps) => {
  const { state, resetFilters, searchOrders } = props;

  const handleOnSelectedSortOrderItemChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const item = state.sortOrderItems.find((x) => x.key === event.target.value);
    state.setSelectedSortOrderItem(item);
    searchOrders();
  };

  const translation = state.translation;

  return (
    <form className={css.OrderListFilters}>
      <FormInput
        className={css.orderListSearch}
        input={state.searchInput}
        type="search"
        label={translation.searchLabel}
        placeholder={translation.searchPlaceholder}
      />
      <div className={css.orderListDataRange}>
        <DateRange startDate={state.dateFrom} endDate={state.dateTo} setDateRange={state.setDateRange} />
      </div>
      <div className={css.orderListOnlyMine}>
        <Label className={css.orderListOnlyMineLabel}>{translation.onlyMineLabel}</Label>
        <Switch input={state.onlyMineInput} wrapperClassName={css.orderListSwitch} />
      </div>
      <TertiaryButton type={'submit'} className={css.orderListSearchButton} onClick={searchOrders}>
        {translation.searchButton}
      </TertiaryButton>
      <CrossButton className={css.orderListResetButton} nameClassName={css.label} onClick={resetFilters}>
        {translation.resetButton}
      </CrossButton>
      <div className={css.orderListSelect}>
        <Select
          label={translation.sortByLabel}
          containerClassName={css.orderListSortBySelect}
          className={css.orderListSortBySelect}
          onChange={handleOnSelectedSortOrderItemChanged}
          value={state.selectedSortOrderItem.key}
        >
          {state.sortOrderItems.map((item) => (
            <option key={item.key} value={item.key}>
              {item.label}
            </option>
          ))}
        </Select>
      </div>
    </form>
  );
});
