import { StoreWithUniqueKey } from '../../StoreSelectionState';
import DropdownMenu from '../DropdownMenu/DropdownMenu';

interface LanguageSelectorProps {
  activeItem: StoreWithUniqueKey;
  items: StoreWithUniqueKey[];
  label: string;
  onSelect: (item: StoreWithUniqueKey) => void;
  className?: string;
}

const StoreSelector = ({ activeItem, className, items, label, onSelect }: LanguageSelectorProps) => {
  return (
    <DropdownMenu
      className={className}
      selectedItem={activeItem}
      items={items}
      keyProperty="key"
      label={label}
      namePropertyExtractor={(x) => (x.displayName ? x.displayName : x.name)}
      onSelect={onSelect}
    />
  );
};

export default StoreSelector;
