import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FC, Fragment } from 'react';

import Tooltip from '../../../../b2c/shared/components/Tooltip/Tooltip';
import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import Button, { ButtonProps } from './Button';
import css from './IconButton.css';

export interface IconButtonProps extends ButtonProps {
  after?: boolean;
  uppercase?: boolean;
  bold?: boolean;
  icon?: JSX.Element;
  className?: string;
  loaderClassName?: string;
  nameClassName?: string;
  tooltip?: {
    id: string;
    text: string;
  };
}

const IconButton: FC<IconButtonProps> = observer(
  ({
    className,
    icon,
    children,
    after,
    bold,
    uppercase,
    isLoading,
    loaderClassName,
    nameClassName,
    tooltip,
    ...buttonProps
  }: IconButtonProps) => {
    const finalClassName = classNames(
      css.IconButton,
      uppercase && css.uppercase,
      buttonProps.disabled && css.disabled,
      bold && css.bold,
      className,
    );
    const loaderClassNames = classNames(css.loader, loaderClassName);
    const nameClasses = children ? classNames(!after ? css.spaceLeft : css.spaceRight, nameClassName) : null;
    const tooltipId = tooltip?.id ? `${tooltip.id}-tooltip` : undefined;

    const renderButton = (extraProps?: ButtonProps) => {
      return (
        <Button {...buttonProps} {...extraProps} className={finalClassName}>
          <Fragment>
            {!after && icon}
            {children && <span className={nameClasses}>{children}</span>}
            {after && icon}
            {isLoading && (
              <span className={loaderClassNames}>
                <ThreeDotsLoader className={css.loader} size="small" />
              </span>
            )}
          </Fragment>
        </Button>
      );
    };

    return tooltip ? (
      <div className={css.buttonWithTooltipContainer}>
        {renderButton({ 'aria-labelledby': tooltipId })}
        <Tooltip id={tooltipId}>{tooltip.text}</Tooltip>
      </div>
    ) : (
      renderButton()
    );
  },
);

export default IconButton;
