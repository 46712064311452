import classNames from 'classnames';
import { ReactNode } from 'react';

import css from './TextLabels.css';

interface TextLabelsProps {
  className?: string;
  children: ReactNode;
}

export const TextLabel = ({ className, children }: TextLabelsProps) => {
  const classes = classNames(css.TextLabel, className);
  return <span className={classes}>{children}</span>;
};

export const TextLabelSmall = ({ children, ...otherProps }: TextLabelsProps) => {
  return (
    <TextLabel {...otherProps}>
      <small>{children}</small>
    </TextLabel>
  );
};

export const TextLabelStrong = ({ children, className, ...otherProps }: TextLabelsProps) => {
  const classes = classNames(css.strong, className);
  return (
    <TextLabel className={classes} {...otherProps}>
      {children}
    </TextLabel>
  );
};

export const TextLabelSmallMediumGrey = ({ className, ...otherProps }: TextLabelsProps) => {
  return <TextLabelSmall className={classNames(css.mediumGrey, className)} {...otherProps} />;
};

interface TextLabelsColoredProps extends TextLabelsProps {
  color: 'mediumGrey' | 'darkGrey';
}

export const TextLabelColored = ({ className, color, ...otherProps }: TextLabelsColoredProps) => {
  return <TextLabelSmall className={classNames(css[color], className)} {...otherProps} />;
};
