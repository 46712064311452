import { LanguageItem } from '../../../app/layout/StoreSelectionState';
import LanguageSelectorState from '../../../app/shared/components/LanguageSelector/LanguageSelectorState';
import { Form, FullPhoneNumberRule, TextInput, ValueMaxLength, ValueRequiredRule } from '../../../app/shared/Form';

export class BasicInformationForm extends Form {
  public firstNameInput: TextInput;
  public lastNameInput: TextInput;
  public emailInput: TextInput;
  public phoneNumberInput: TextInput;
  public languageSelector: LanguageSelectorState;

  public constructor(languages: LanguageItem[], initialLanguageCode: string) {
    super();

    this.firstNameInput = new TextInput().withRule(new ValueRequiredRule());
    this.lastNameInput = new TextInput().withRule(new ValueRequiredRule());
    this.emailInput = new TextInput();
    this.phoneNumberInput = new TextInput().withRule(new FullPhoneNumberRule()).withRule(new ValueMaxLength(20));
    this.languageSelector = new LanguageSelectorState(languages, initialLanguageCode);

    this.inputsToValidate.push(this.firstNameInput);
    this.inputsToValidate.push(this.lastNameInput);
    this.inputsToValidate.push(this.phoneNumberInput);

    this.emailInput.disable();
  }
}
