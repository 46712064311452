import { observer } from 'mobx-react';
import { Component } from 'react';

import { CssAnimation } from '../../../shared/animations';
import { AnimatedComponent, AnimatedComponentProps } from '../AnimatedComponent';
import css from './AnimatedPanel.css';

export class PanelAnimations {
  hide: CssAnimation;
  show: CssAnimation;
  collapse: CssAnimation;
  moveOut: CssAnimation;
  initStyle: CssAnimation;
  initAnimation: CssAnimation;
  disabled: CssAnimation;
  lock: CssAnimation;
  fast: CssAnimation;
  medium: CssAnimation;
  slow: CssAnimation;
  slideToLeft: CssAnimation;
  slideToRight: CssAnimation;
  slideLeftInit: CssAnimation;
  slideRightInit: CssAnimation;

  constructor() {
    this.hide = new CssAnimation();
    this.show = new CssAnimation();
    this.collapse = new CssAnimation();
    this.moveOut = new CssAnimation();
    this.initStyle = new CssAnimation();
    this.initAnimation = new CssAnimation();
    this.disabled = new CssAnimation();
    this.lock = new CssAnimation();
    this.fast = new CssAnimation();
    this.medium = new CssAnimation();
    this.slow = new CssAnimation();
    this.slideToLeft = new CssAnimation();
    this.slideToRight = new CssAnimation();
    this.slideLeftInit = new CssAnimation();
    this.slideRightInit = new CssAnimation();
  }
}

@observer
export class AnimatedPanel extends Component<AnimatedComponentProps, {}> {
  constructor(props: AnimatedComponentProps) {
    super(props);
  }

  render() {
    return <AnimatedComponent {...this.props} css={css} />;
  }
}
