import { FooterSocialMediaData } from '../../../data/model';
import Icon, { IconType } from '../../../shared/components/Icon/Icon';
import Link from '../../../shared/components/Link';
import List from '../../../shared/components/List/List';
import css from './SocialMediaList.css';

type SocialMediaLink = {
  icon: IconType;
  title: string;
  url: string;
};

interface SocialMediaListProps extends FooterSocialMediaData {
  className?: string;
}

const SocialMediaList = ({
  className,
  followUsText,
  blogLink,
  facebookLink,
  instagramLink,
  linkedInLink,
  pinterestLink,
}: SocialMediaListProps) => {
  const items: SocialMediaLink[] = [
    {
      icon: 'socialInstagram',
      title: 'Instagram',
      url: instagramLink,
    },
    {
      icon: 'socialFb',
      title: 'Facebook',
      url: facebookLink,
    },
    {
      icon: 'socialPinterest',
      title: 'Pinterest',
      url: pinterestLink,
    },
    {
      icon: 'socialLinkedin',
      title: 'LinkedIn',
      url: linkedInLink,
    },
    {
      icon: 'socialFocus',
      title: 'Flokk Focus',
      url: blogLink,
    },
  ];

  const hasSocialMediaLink = Boolean(blogLink || facebookLink || instagramLink || linkedInLink || pinterestLink);

  const renderItem = (item: SocialMediaLink) => {
    return item.url ? (
      <Link className={css.link} href={item.url} target="_blank" aria-label={item.title}>
        <Icon className={css.icon} name={item.icon} />
      </Link>
    ) : null;
  };

  return hasSocialMediaLink ? (
    <section className={className}>
      {followUsText && <h2 className={css.title}>{followUsText}</h2>}
      <List className={css.list} itemClassName={css.item} items={items} itemRenderer={renderItem} />
    </section>
  ) : null;
};

export default SocialMediaList;
