import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import AppState from '../AppState';
import StoreDetails from './components/StoreDetails/StoreDetails';
import StoreList from './components/StoreList/StoreList';
import StoreMap from './components/StoreMap/StoreMap';
import StoreSearch from './components/StoreSearch/StoreSearch';
import css from './StoreLocatorPage.css';
import StoreLocatorState from './StoreLocatorState';

interface IStoreLocatorProps {
  appState: AppState;
  state: StoreLocatorState;
}

@observer
class StoreLocatorPage extends Component<IStoreLocatorProps, {}> {
  constructor(props: IStoreLocatorProps) {
    super(props);
  }

  render() {
    const { appState, state } = this.props;
    const { translation } = appState;

    const layoutClasses = classNames(
      css.StoreLocatorPage,
      state.embeddedQuery?.embed && css.embeded,
      state.listVisible && state.selectedItem && css.detailsVisible,
      state.listVisible && !state.selectedItem && css.listVisible,
    );

    const details = state.selectedItem ? (
      <StoreDetails
        directionsUrl={state.getDirections(state.selectedItem)}
        data={state.selectedItem}
        translation={translation.storeLocatorPage.details}
        storeTypeTranslation={translation.storeLocatorPage.storeTypes}
        close={state.closeStoreDetails}
      />
    ) : (
      <Fragment />
    );

    const pageContent = (
      <div className={layoutClasses}>
        <div className={css.layout}>
          <div className={css.listPanel}>
            {!state.queryObject.hideFilter && (
              <StoreSearch
                state={state}
                translation={translation.storeLocatorPage.storeSearch}
                storeTypeTranslation={translation.storeLocatorPage.storeTypes}
              />
            )}
            <div className={css.list}>
              <StoreList storeTypeTranslation={translation.storeLocatorPage.storeTypes} state={state.listState} />
            </div>
          </div>
          <div className={css.details}>{details}</div>
          <div className={css.map}>
            <div className={css.mapInner}>
              <StoreMap state={state.mapState} onItemSelected={state.openStoreDetails} />
            </div>
          </div>
        </div>
      </div>
    );

    if (state.embeddedQuery?.embed) {
      return pageContent;
    }

    return (
      <Fragment>
        <h1 className={css.heading}>{translation.storeLocatorPage.heading}</h1>
        {pageContent}
      </Fragment>
    );
  }
}

export default StoreLocatorPage;
