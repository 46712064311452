import { Component, Fragment } from 'react';

import { WarningBaseProps } from './StackedWarnings';

export abstract class WarningComponentWithReason<TReason, TTranslation> extends Component<
  WarningBaseProps<TReason> & {
    translation: TTranslation;
  }
> {
  abstract getMessage(): string;

  render() {
    const message = this.getMessage();
    return <Fragment>{message}</Fragment>;
  }
}
