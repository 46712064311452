import {
  ButtonClickEventData,
  CartEventData,
  CheckoutEventData,
  CheckoutOptionEventData,
  ITrackingService,
  LinkClickEventData,
  ProductsEventData,
  PurchaseEventData,
} from '../TrackingService';
import { GoogleTagManager } from './GoogleTagManager';
import { GtmCustomEventBuilder } from './GtmCustomEventBuilder';
import { GtmEcommerceEventBuilder } from './GtmEcommerceEventBuilder';
import { PageVisitEventBuilder } from './PageVisitEventBuilder';
import { GtmEcommerceActionName } from './types/Gtm';
import GtmCustomEventName from './types/GtmCustomEventName';
import GtmEcommerceEventName from './types/GtmEcommerceEventName';

export class GoogleTrackingService implements ITrackingService {
  private manager: GoogleTagManager;

  constructor(manager: GoogleTagManager) {
    this.manager = manager;
  }

  trackPageVisit(relativeUrl: string): void {
    const event = PageVisitEventBuilder.build(relativeUrl);
    this.manager.send(event);
  }

  trackDownload(data: LinkClickEventData): void {
    const event = new GtmCustomEventBuilder().withLink(data.url).build(GtmCustomEventName.Download);

    this.manager.send(event);
  }

  trackAddToCart(data: CartEventData): void {
    const event = new GtmEcommerceEventBuilder()
      .setCurrencyCode(data.context.currencyCode)
      .setAction({ type: GtmEcommerceActionName.Add, data })
      .build(GtmEcommerceEventName.AddToCart);

    this.manager.send(event);
  }

  trackRemoveFromCart(data: CartEventData): void {
    const event = new GtmEcommerceEventBuilder()
      .setCurrencyCode(data.context.currencyCode)
      .setAction({ type: GtmEcommerceActionName.Remove, data })
      .build(GtmEcommerceEventName.RemoveFromCart);

    this.manager.send(event);
  }

  trackCheckoutStep(data: CheckoutEventData): void {
    const event = new GtmEcommerceEventBuilder()
      .setCurrencyCode(data.context.currencyCode)
      .setAction({ type: GtmEcommerceActionName.Checkout, data })
      .build(GtmEcommerceEventName.Checkout);

    this.manager.send(event);
  }

  trackCheckoutOption(data: CheckoutOptionEventData): void {
    const event = new GtmEcommerceEventBuilder()
      .setAction({ type: GtmEcommerceActionName.CheckoutOption, data })
      .build(GtmEcommerceEventName.CheckoutOption);

    this.manager.send(event);
  }

  trackCheckoutCompleted(data: PurchaseEventData): void {
    const event = new GtmEcommerceEventBuilder()
      .setCurrencyCode(data.context.currencyCode)
      .setAction({ type: GtmEcommerceActionName.Purchase, data })
      .build(GtmEcommerceEventName.Purchase);

    this.manager.send(event);
  }

  trackAskForPriceCompleted(data: ProductsEventData): void {
    return;
  }

  trackButtonClick(data: ButtonClickEventData): void {
    return;
  }
}
