import { observer } from 'mobx-react';

import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { MoneyData, ShoppingCartItemData } from '../../../app/data/model';
import SecondaryButton from '../../../app/shared/components/Button/SecondaryButton';
import { LoadableImage } from '../../../app/shared/components/LoadableImage';
import Modal from '../../../app/shared/components/Modal/Modal';
import Price from '../../../app/shared/components/Price';
import HeaderLevel3 from '../../../app/shared/components/Typography/HeaderLevel3';
import { Subtitle2Bold } from '../../../app/shared/components/Typography/Subtitle';
import { TextLabelSmall } from '../../../app/shared/components/Typography/TextLabels';
import { CartItemDetails } from '../../cart/CartItem/CartItemDetails/CartItemDetails';
import {
  AddedToCartItemTranslation,
  CartItemPreviewTranslation,
  CartItemSubtotalTranslation,
  QuantityInfoTranslation,
} from '../../localization/SiteTranslation';
import { AddedToCartModalState, CartActionType } from '../ConfiguratorActionsState';
import css from './AddedToCartModal.css';

export const AddedToCartModal = observer(
  ({ state, actionType }: { state: AddedToCartModalState; actionType: CartActionType }) => {
    const translation = state.translation;

    const quantityUpdateOnly =
      state.recentlyAddedItem &&
      state.recentlyAddedQuantity &&
      state.recentlyAddedItem.quantity !== state.recentlyAddedQuantity;
    const headerText =
      quantityUpdateOnly || actionType === 'Update' ? translation.updatedInCartHeader : translation.addedToCartHeader;

    return (
      <Modal
        classes={{
          base: css.AddedToCartModal,
          afterOpen: css.modalAfterOpen,
          beforeClose: css.modalBeforeClose,
        }}
        contentClassName={css.modalContent}
        modal={state}
        translation={{ closeButton: '' }}
      >
        <HeaderLevel3 className={css.modalHeader}>
          <div>
            <>
              {headerText}{' '}
              {quantityUpdateOnly && (
                <QuantityInfo quantityUpdate={state.recentlyAddedQuantity} translation={translation.quantityInfo} />
              )}
            </>
          </div>
        </HeaderLevel3>

        <AddedToCartItem item={state.recentlyAddedItem} translation={translation.addedToCartItem} />
        <div className={css.shoppingCartDetailsGoToCartButtonContainer}>
          <SecondaryButton className={css.shoppingCartDetailsGoToCartButton} onClick={state.goToCart}>
            {translation.goToCartButton}
          </SecondaryButton>
        </div>
      </Modal>
    );
  },
);

const QuantityInfo = observer(
  ({
    quantityUpdate: quantityAdded,
    translation,
  }: {
    quantityUpdate: number;
    translation: QuantityInfoTranslation;
  }) => {
    const labelText =
      quantityAdded === 1
        ? translation.productAddedText
        : translation.productsAddedText.interpolate([['quantityAdded', quantityAdded.toString()]]);
    return (
      <TextLabelSmall className={css.quantityInfo}>
        <>{labelText}</>
      </TextLabelSmall>
    );
  },
);

const AddedToCartItem = ({
  item,
  translation,
}: {
  item: ShoppingCartItemData;
  translation: AddedToCartItemTranslation;
}) => {
  return (
    <div>
      <p className={css.shoppingCartProductName}>{item.baseModel.name}</p>
      <CartItemPreview item={item} translation={translation.cartItemPreview} />
      <CartItemSubtotal totalPrice={item.totalPrice} translation={translation.cartItemSubtotal} />
    </div>
  );
};

const CartItemPreview = ({
  item,
  translation,
}: {
  item: ShoppingCartItemData;
  translation: CartItemPreviewTranslation;
}) => {
  return (
    <div className={css.shoppingCartDetails}>
      <LoadableImage
        className={css.shoppingCartDetailsImage}
        src={FinalVisualizationBuilder.buildUrl(item.code)}
        width={100}
        height={150}
        sharpeningFactor={2}
      />

      <CartItemDetails
        code={item.code}
        quantity={item.quantity}
        listPrice={item.listPrice}
        salePrice={item.salePrice}
        className={css.shoppingCartDetailsItem}
        propertyClassNames={{
          labelClassName: css.shoppingCartDetailsConfigLabel,
          valueClassName: css.shoppingCartDetailsConfigValue,
        }}
        translation={translation.cartItemDetails}
      />
    </div>
  );
};

const CartItemSubtotal = (props: { totalPrice: MoneyData; translation: CartItemSubtotalTranslation }) => (
  <div className={css.shoppingCartDetailsSubtotal}>
    <Subtitle2Bold>
      <>
        {props.translation.subtotalText}{' '}
        <span className={css.shoppingCartDetailsVat}>{props.translation.exclValText}</span>
      </>
    </Subtitle2Bold>
    <Price className={css.shoppingCartDetailsTotalPrice} price={props.totalPrice} />
  </div>
);
