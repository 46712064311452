import { useState } from 'react';

function useComponentInstanceCreator(): [number, () => void] {
  const [instanceKey, setInstanceKey] = useState<number>(0);

  const create = () => setInstanceKey((state) => ++state);

  return [instanceKey, create];
}

export default useComponentInstanceCreator;
