import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../data/client';
import { AddCouponToCartCommand, ShoppingCartData, ShoppingContext } from '../../../data/model';
import { DiscountCodeFormTranslation } from '../../../localization/SiteTranslation';
import { AppError } from '../../../shared/AppError';
import { AsyncCommand } from '../../../shared/common';
import Event from '../../../shared/Event';
import { Form, TextInput } from '../../../shared/Form';

export class DiscountCodeFormState extends Form {
  private shoppingContext?: ShoppingContext;

  @observable
  public isVisible: boolean;
  public readonly input: TextInput;
  public readonly discountCodeAppliedEvent: Event<ShoppingCartData>;
  public readonly applyCommand: AsyncCommand;

  public constructor(public readonly translation: DiscountCodeFormTranslation, private readonly client: IApiClient) {
    super();

    makeObservable(this);

    this.applyCommand = new AsyncCommand<ShoppingCartData>(() => this.apply(), this);
    this.discountCodeAppliedEvent = new Event<ShoppingCartData>();
    this.isVisible = false;
    this.input = new TextInput();
    this.inputsToValidate.push(this.input);
  }

  @action.bound
  public initialize(shoppingContext: ShoppingContext) {
    this.shoppingContext = shoppingContext;
  }

  public get isInputDisabled() {
    return !this.input.isEnabled || this.applyCommand.processing;
  }

  public get isProcessing() {
    return this.applyCommand.processing;
  }

  @action.bound
  public toggle() {
    this.isVisible = !this.isVisible;
  }

  private async apply() {
    if (!this.shoppingContext) {
      return;
    }

    const command = new AddCouponToCartCommand({
      couponCode: this.input.value,
      shoppingContext: this.shoppingContext,
    });

    const response = await this.client.send(command);

    if (!response || !response.shoppingCart) {
      return;
    }

    if (!response.couponApplied) {
      throw new AppError(
        this.translation.discountCodeIsInvalidOrExpiredError,
        `The coupon code "${command.couponCode} is invalid!"`,
      );
    }

    this.discountCodeAppliedEvent.raise(response.shoppingCart);
  }
}
