import { action, makeObservable, observable } from 'mobx';

import {
  AssetCategoryWithTypesData,
  AssetFileTypeData,
  BrandData,
  GetAssetFiltersQueryResponse,
} from '../../../data/model';
import { DownloadsPageQuery } from '../../DownloadsPageQuery';

export interface IBrandFilterState {
  options: BrandFilterOptionState[];
  selection: BrandFilterSelectionState;
}

export interface IBrandFilterSelectionState {
  values: string[];
  toggle: (filter: IBrandFilterOptionState) => void;
}

export interface ICategoryFilterState {
  fullName: string;
  isTypeSelected: boolean;
  options: CategoryFilterOptionState[];
  selection: CategoryFilterSelectionState;
}

export interface IFilterOptionState {
  name: string;
  value: string;
  isActive: (selectedValue: string) => boolean;
}

export interface IBrandFilterOptionState {
  name: string;
  value: string;
  isActive: (selectedValue: string[]) => boolean;
}

export interface ICategoryFilterOptionState extends IFilterOptionState {
  types?: FilterOptionState[];
}

export interface ICategoryFilterSelectionState {
  value: string;
  typeSelection?: TypeFilterSelectionState;
  select: (option: ICategoryFilterOptionState) => void;
}

export interface IDownloadsFiltersState {
  categories: CategoryFilterState;
  brands: BrandFilterState;
  downloadAllEnabled: boolean;
}

export interface ITypeFilterSelectionState {
  value: string;
  select: (option: FilterOptionState) => void;
  unselect: () => void;
}

export class DownloadsFiltersState implements IDownloadsFiltersState {
  public categories: CategoryFilterState;
  public brands: BrandFilterState;

  public constructor(filtersData: GetAssetFiltersQueryResponse, query?: DownloadsPageQuery) {
    this.categories = new CategoryFilterState(filtersData?.categories, query);
    this.brands = new BrandFilterState(filtersData?.brands, query?.brands);
  }

  public get downloadAllEnabled() {
    return this.categories.selectedOption.downloadAllEnabled;
  }
}

class BrandFilterState implements IBrandFilterState {
  @observable
  public options: BrandFilterOptionState[];
  public selection: BrandFilterSelectionState;

  public constructor(brandsData: BrandData[], initialSelection?: string[]) {
    makeObservable(this);
    this.adapt(brandsData, initialSelection);
  }

  private adapt(brandsData: BrandData[], initialSelection?: string[]) {
    if (!brandsData || brandsData.empty()) {
      return;
    }

    this.options = brandsData.map((item) => new BrandFilterOptionState(item));
    this.selection = new BrandFilterSelectionState(initialSelection);
  }
}

class CategoryFilterState implements ICategoryFilterState {
  @observable
  public options: CategoryFilterOptionState[];
  public selection: CategoryFilterSelectionState;

  public constructor(categoriesData: AssetCategoryWithTypesData[], query?: DownloadsPageQuery) {
    makeObservable(this);
    this.adapt(categoriesData, query);
  }

  public get isTypeSelected(): boolean {
    return !!this.selection.typeSelection?.value;
  }

  public get fullName() {
    const type = this.selectedOption.types?.find((item) => item.isActive(this.selection.typeSelection.value));
    return `${this.selectedOption.name}${type?.name ? `(${type.name})` : ''}`;
  }

  public get selectedOption(): CategoryFilterOptionState {
    return this.options.find((item) => item.isActive(this.selection.value));
  }

  private adapt(categoriesData: AssetCategoryWithTypesData[], query?: DownloadsPageQuery) {
    if (!categoriesData || categoriesData.empty()) {
      return;
    }

    this.options = categoriesData.map((item) => new CategoryFilterOptionState(item));

    let initialSelection = this.options.first();

    if (query?.category) {
      const selectedOption = this.options.find((item) => item.value === query.category);

      if (selectedOption) {
        initialSelection = selectedOption;
      }
    }

    this.selection = new CategoryFilterSelectionState(initialSelection, query?.type);
  }
}

class FilterOptionState implements IFilterOptionState {
  public value: string;
  public name: string;

  public constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }

  public isActive = (selectedValue: string): boolean => {
    return this.value === selectedValue;
  };
}

class BrandFilterOptionState implements IBrandFilterOptionState {
  public value: string;
  public name: string;

  public constructor(brandData: BrandData) {
    this.name = brandData?.name;
    this.value = brandData?.id;
  }

  public isActive = (selectedValues: string[]): boolean => {
    return selectedValues.contains(this.value);
  };
}

class CategoryFilterOptionState extends FilterOptionState implements ICategoryFilterOptionState {
  public downloadAllEnabled: boolean;
  public types?: FilterOptionState[];

  public constructor(categoryData: AssetCategoryWithTypesData) {
    super(categoryData?.name, categoryData?.id);
    this.downloadAllEnabled = categoryData.downloadAllEnabled;
    this.setTypes(categoryData?.fileTypes);
  }

  private setTypes(typesData: AssetFileTypeData[]) {
    if (!typesData || typesData.empty()) {
      return;
    }
    this.types = typesData.map((item) => new FilterOptionState(item.name, item.id));
  }
}

class BrandFilterSelectionState implements IBrandFilterSelectionState {
  @observable
  public values: string[];

  public constructor(initialValues: string[] = []) {
    makeObservable(this);
    this.values = initialValues;
  }

  @action
  public toggle(option: IBrandFilterOptionState) {
    if (this.values.contains(option.value)) {
      this.values.remove(option.value);
      return;
    }

    this.values.push(option.value);
  }
}

class CategoryFilterSelectionState implements ICategoryFilterSelectionState {
  @observable
  public value: string;
  public typeSelection: TypeFilterSelectionState;

  public constructor(initialSelection: ICategoryFilterOptionState, initialTypeValue?: string) {
    makeObservable(this);
    this.setValue(initialSelection);
    this.initializeTypeSelection(initialSelection, initialTypeValue);
  }

  @action
  public select(option: ICategoryFilterOptionState) {
    this.setValue(option);

    const firstType = option.types?.first();

    if (firstType?.value) {
      this.typeSelection.select(firstType);
    } else {
      this.typeSelection.unselect();
    }
  }

  private initializeTypeSelection(option: ICategoryFilterOptionState, initialSelectionValue?: string) {
    if (initialSelectionValue) {
      const value = option?.types?.find((item) => item.value === initialSelectionValue)?.value;

      if (value) {
        this.typeSelection = new TypeFilterSelectionState(value);
        return;
      }
    }

    let selectionValue = option.types?.first()?.value;

    if (selectionValue) {
      this.typeSelection = new TypeFilterSelectionState(selectionValue);
      return;
    }

    this.typeSelection = new TypeFilterSelectionState();
  }

  private setValue(option: ICategoryFilterOptionState) {
    if (option?.value && option.value !== this.value) {
      this.value = option.value;
    }
  }
}

class TypeFilterSelectionState implements ITypeFilterSelectionState {
  @observable
  public value: string;

  public constructor(initialValue: string = '') {
    makeObservable(this);
    this.value = initialValue;
  }

  @action
  public select(option: IFilterOptionState) {
    if (option?.value && option.value !== this.value) {
      this.value = option.value;
    }
  }

  @action
  public unselect() {
    this.value = '';
  }
}
