import { IApiClient } from '../../../../data/client';
import { GetCityByZipCodeQuery } from '../../../../data/model';
import { TextInput } from '../../../../shared/Form';
import { LoadingIndicator } from '../../../../shared/LoadingIndicator';
import Logger from '../../../../shared/Logger';

export default class AutocompleteCityBehavior {
  client: IApiClient;
  countryCode: string;

  zipCodeInput: TextInput;
  cityInput: TextInput;

  loadingIndicator: LoadingIndicator;

  constructor(client: IApiClient, countryCode: string, zipCodeInput: TextInput, cityInput: TextInput) {
    this.client = client;
    this.countryCode = countryCode;

    this.zipCodeInput = zipCodeInput;
    this.zipCodeInput.valueChanged.subscribe(() => this.tryResolveCity());
    this.cityInput = cityInput;
    this.loadingIndicator = new LoadingIndicator();
  }

  private async tryResolveCity(): Promise<void> {
    if (this.cityInput.value) {
      return;
    }

    if (!this.zipCodeInput.validateValue()) {
      return;
    }

    const unformattedZipCode = this.zipCodeInput.value.replace(/\s+/g, '');

    try {
      this.loadingIndicator.start();

      const query = new GetCityByZipCodeQuery({ countryCode: this.countryCode, zipCode: unformattedZipCode });
      const response = await this.client.send(query);

      if (!this.cityInput.value && response.city) {
        this.cityInput.onChange(response.city);
      }
    } catch (error) {
      Logger.exception(`An error occurred while loading city for zip code = ${this.zipCodeInput.value}`, error);
    } finally {
      this.loadingIndicator.reset();
    }
  }
}
