import {
  CartItemActionsTranslation,
  CartItemDetailsTranslation,
  CartSummaryTranslation,
  ShoppingCartItemBasicInfoTranslation,
  ShoppingCartItemPanelTranslation,
  SiteTranslation,
} from './SiteTranslation';
import baseTranslation from '../../app/localization/fr-fr';
import { ActionMenuTranslation } from '../../app/localization/SiteTranslation';

const actionMenuTranslation: ActionMenuTranslation = {
  title: 'Actions',
};

const cartItemActionsTranslation: CartItemActionsTranslation = {
  actionMenu: actionMenuTranslation,
};

const shoppingCartItemBasicInfoTranslation: ShoppingCartItemBasicInfoTranslation = {
  cartItemDetails: {
    configCodeLabel: 'Code config.',
    noItemsLabel: 'Quantité',
    listPriceLabel: 'Prix catalogue',
    netPriceLabel: 'Votre prix net',
  },
};

const shoppingCartItemPanelTranslation: ShoppingCartItemPanelTranslation = {
  cartItemActions: cartItemActionsTranslation,
};

const cartSummaryTranslation: CartSummaryTranslation = {
  priceBeforeVatLabel: 'Prix hors TVA',
  vatLabelFormat: 'TVA ({taxRate} %)',
  priceIncludingVatLabel: 'Prix TVA incluse',
  deliveryFeeLabel: 'Frais de livraison',
  noItemsLabel: 'Quantité',
  subtotalHeader: 'Sous-total',
  accordingToAgreementText: 'À préciser',
  surchargesAnnotationText: 'Des frais supplémentaires sont susceptibles de s’appliquer à la commande. Les modalités seront précisées sur la facture.',

};

const cartItemDetailsTranslation: CartItemDetailsTranslation = {
  configCodeLabel: 'Code config',
  noItemsLabel: 'Quantité',
  listPriceLabel: 'Prix catalogue',
  netPriceLabel: 'Votre prix net',
};

const translation: SiteTranslation = {
  ...baseTranslation,
  ...{
    b2bHomePage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      onboarding: {
        headerText: 'Bienvenue',
        welcomeText: 'Nous sommes ravis de vous compter parmi nous. Les conseils ci-dessous vous guideront pas à pas.',
        onboardingCards: {
          configureChairTitle: 'Configurer un siège',
          configureChairText: 'Configurer un siège',
          placeAnOrderTitle: 'Passer une commande',
          placeAnOrderText: 'Passer une commande',
          manageAndTrackTitle: 'Gérer et suivre la commande',
          manageAndTrackText: 'Gérer et suivre la commande',
          selectAChairTextPart1: 'Sélectionnez un modèle dans ',
          selectAChairTextPart2: 'Produits',
          selectAChairTextPart3: ' et adaptez-le aux besoins de votre client dans le configurateur.',
          addConfigToFavoritesTextPart1: 'Vous pouvez ajouter votre siège personnalisé à vos ',
          addConfigToFavoritesTextPart2: 'Favoris',
          placeChairToCartTextPart1: 'Une fois la configuration terminée, ajoutez le siège à votre ',
          placeChairToCartTextPart2: 'Panier',
          manageOngoingOrdersTextPart1: 'Vous pouvez facilement gérer vos commandes en cours dans ',
          manageOngoingOrdersTextPart2: 'Historique des commandes',
          manageOngoingOrdersTextPart3: ', mais aussi consulter vos commandes passées.',
          receiveNotificationsFromTrackingText:
            'Grâce au système de suivi, vous recevrez une notification à chaque changement de statut de votre commande.',
        },
      },
    },
    profilePage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      personalTabTitle: 'Informations personnelles',
      usersTabTitle: 'Utilisateurs',
      roles: {
        admin: 'Gestionnaire',
        buyer: 'Acheteur',
        flokkSales: 'Flokk Ventes',
        flokkSupport: 'Assistance Flokk',
        reader: 'le lecteur',
      },
      personalTabContent: {
        emailLabel: 'Adresse e-mail',
        emailNotificationsHeader: 'Notifications par e-mail',
        emailNotifications: {
          orderCreated: 'Commande créée',
          orderStatusChanged: 'Le statut de la commande a changé',
          orderCancelled: 'Commande annulée',
          orderNotifyOnlyMyOrders: 'Mes commandes uniquement',
        },
        firstNameLabel: 'Prénom',
        lastNameLabel: 'Nom',
        phoneNumberLabel: 'Numéro de téléphone',
        saveChangesButton: 'Enregistrer les modifications',
        basicInformationHeader: 'Informations principales',
        languageSelectorLabel: 'Langue',
        languages: {
          deDe: 'Allemand',
          enGb: 'Anglais',
          frFr: 'Français',
        },
        passwordResetBlock: {
          passwordResetHeader: 'Réinitialisation du mot de passe',
          emailSentText:
            'Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Veuillez consulter votre boîte de réception.',
          sendPasswordResetEmailAgainText: 'Renvoyer un e-mail de réinitialisation du mot de passe',
          sendPasswordResetEmailText: 'Envoyer un e-mail de réinitialisation du mot de passe',
          confirmPasswordResetHeader: 'Confirmer la réinitialisation du mot de passe',
          areYouSureResetPasswordText: 'Voulez-vous vraiment réinitialiser votre mot de passe ?',
          cancelButton: 'Annuler',
          yesButton: 'Oui',
        },
      },
      usersTab: {
        removeUserTitle: 'Supprimer l’utilisateur',
        inviteNewUserButton: 'Inviter un nouvel utilisateur',
        editRolesLabel: 'Modifier les rôles',
        removeLabel: 'Supprimer',
        removeContactFromOrganisationTextPart1: 'Voulez-vous vraiment supprimer ',
        removeContactFromOrganisationTextPart2: ' de votre organisation ?',
        invitationsTable: {
          pendingInvitationsHeader: 'Invitations en attente',
          userNameHeader: 'Nom d’utilisateur',
          emailHeader: 'Adresse e-mail',
          expiresAtHeader: 'Expire à',
          contextMenuHeader: '',
          removeLabel: 'Supprimer',
          actionMenu: actionMenuTranslation,
        },
        usersTable: {
          rolesHeader: 'Rôles',
          userNameHeader: 'Nom d’utilisateur',
          contextMenuHeader: '',
        },
        inviteNewUsersModal: {
          inviteNewUserHeader: 'Inviter un nouvel utilisateur',
          firstNameLabel: 'Prénom',
          lastNameLabel: 'Nom',
          emailLabel: 'Adresse e-mail',
          repeatEmailLabel: 'Confirmation de l’adresse e-mail',
          grantedRolesLabel: 'Rôles attribués',
          sendInvitationButton: 'Envoyer l’invitation',
        },
        confirmInvitationRemovalModal: {
          areYouSureRemoveInvitationText: 'Voulez-vous vraiment supprimer l’invitation envoyée à ',
          title: 'Supprimer l’invitation',
        },
        changeUserRolesModal: {
          selectRolesText: 'Veuillez sélectionner les rôles attribués à',
          title: 'Modifier les rôles',
        },
        actionMenu: actionMenuTranslation,
      },
    },
    invitationPage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      headerText: 'Vous avez reçu une invitation pour accéder à Flokk Hub !',
      loggedAsText: 'Vous êtes actuellement connecté(e) à Flokk Hub en tant que',
      useCurrentAccountOrCreateNewText:
        "Souhaitez-vous utiliser le compte actuel ou créer un nouveau compte pour finaliser le processus d'invitation ?",
      useCurrentAccountButton: 'Utiliser le compte actuel',
      createNewAccountButton: 'Créer un nouveau compte',
      fullfillInvitationText:
        'Pour finaliser le processus d’invitation sur l’écran suivant, vous devrez vous connecter en utilisant un compte existant ou vous inscrire pour créer un nouveau compte.',
      signUpForFlokkHub: 'S’inscrire à Flokk Hub',
      haveAccountInformationText: 'Si vous possédez déjà un compte Flokk Hub, vous pouvez l’utiliser.',
      logInWithExistingAccountText: 'Se connecter avec un compte existant',
      expiredInvitationInformationText:
        'Votre invitation a expiré. Veuillez contacter l’équipe Flokk pour recevoir une nouvelle invitation.',
      invitationLinkNotValidText: 'Votre lien d’invitation n’est pas valide.',
      invitationLinkNotValidInformationText:
        'L’invitation que vous essayez d’utiliser n’existe pas. Veuillez vérifier qu’il s’agit bien du lien envoyé par l’équipe Flokk.',
      ifProblemStillOccursContactFlokkText:
        'Si le problème persiste, veuillez contacter l’équipe Flokk pour recevoir une nouvelle invitation.',
      somethingWrongWithInvitationText: 'Il y a eu un problème avec votre invitation.',
      notAbleToProcessRequestText:
        'Nous ne pouvons pas traiter votre demande. Veuillez réessayer ultérieurement ou contacter l’équipe Flokk.',
    },
    catalogPage: {
      ...baseTranslation.catalogPage,
      familyItemAvailabilityModal: {
        closeButton: 'Fermer',
        familyItemIsAvailableOnlyForConfiguration:
          'Ce produit n’est pas encore disponible à l’achat via Flokk Hub. Vous pouvez toujours le configurer et l’ajouter à vos favoris.',
        familyItemIsUnavailable: 'Ce produit n’est pas encore disponible pour être configuré ou acheté via Flokk Hub.',
      },
      filterBar: {
        ...baseTranslation.catalogPage.filterBar,
        results: "*Product families: {results}"
      }
    },
    cartPage: {
      pageTitleFormat: '',
      pageDescriptionFormat: '',
      orderSummaryText: 'Récapitulatif de commande',
      goBackButton: 'Retour',
      deliveryAddressText: 'Adresse de livraison',
      deliveryInstructionsLabel: 'Instructions de livraison, visibles sur l’étiquette du colis',
      deliveryInstructionsPlaceholder: 'Ex. : 2e étage',
      placeOrderButton: 'Commander',
      cartList: {
        sendByEmailButton: 'Envoyer par e-mail',
        placeOrderButton: 'Commander',
        editProductLabel: 'Modifier produit',
        removeFromCardLabel: 'Supprimer du panier',
        cartOverviewTitle: 'Aperçu du panier',
        cartSummaryTitle: 'Récapitulatif du panier',
        downloadImagesLabel: 'Télécharger les images',
        noneOrdersSelectedLabel: 'Aucune commande sélectionnée',
        continueWithOrdersLabelFormat: 'Poursuivre {ordersCount} commandes',
        placeAllOrdersLabelFormat: 'Passer toutes les {ordersCount} commandes',
        cartSummary: cartSummaryTranslation,
        cartHeader: {
          readyForShippingText: 'Expédition sous {minDays} - {maxDays} jours ouvrés',
        },
        cartItemList: {
          cartItem: {
            cartItemActions: cartItemActionsTranslation,
            cartItemBodyDetails: {
              removeItemButton: 'Supprimer le produit',
              updateQuantityButton: 'Mettre à jour la quantité',
              revertButton: 'Rétablir',
              cartItemDetails: cartItemDetailsTranslation,
            },
          },
        },
      },
      orderSummary: {
        brandLabel: 'Marque',
        noItemsLabel: 'Quantité',
        readyForShippingFromFactoryLabel: 'Expédition de l’usine',
        readyForShippingFromFactoryValue: '{minDays} - {maxDays} jours ouvrés',
        customReferenceLabel: 'Votre référence',
        customReferencePlaceholder: 'Ex. : votre numéro de commande',
        customNameLabel: 'Nom de commande',
        customNamePlaceholder: 'Ex. : le nom du client',
        commentLabel: 'Commentaire',
        commentPlaceholder: '',
      },
      deliveryAddress: {
        addButton: 'Entrez la nouvelle adresse',
        editButton: 'Modifier',
        deliveryAddressForm: {
          city: {
            name: 'Ville',
            fieldRequired: 'La ville est requise',
          },
          country: {
            name: 'Pays',
          },
          zipCode: {
            name: 'Code postale',
            fieldRequired: 'Le code postal est requis',
            wrongFormat: 'La valeur entrée n’est pas un code postal valide',
          },
          addressLine1: {
            name: 'Ligne d’adresse 1',
            fieldRequired: 'La ligne d’adresse 1 doit être remplie.',
          },
          addressLine2: {
            name: 'Ligne d’adresse 2',
          },
          recipient: {
            name: 'Destinataire',
            fieldRequired: 'Le nom du destinataire est obligatoire.',
          },
        },
      },
      cartSummary: cartSummaryTranslation,
      orderCreationConfirmationModal: {
        title: '*Are you sure you want to proceed?',
        confirmationText: '*You are placing an order on behalf of',
        confirmButton: 'Commander',
        cancelButton: 'Annuler',
      },
      orderCreatedModal: {
        somethingWentWrongTitle: 'Il y a eu un problème',
        notAllOrdersCreatedSuccessfullyTitle:
          'Il y a eu un problème. Nous n’avons pu valider que {succedeedOrdersCount} commande(s) sur ${totalOrdersCount}.',
        technicalIssuesSubtitle:
          'En raison de problèmes techniques, nous ne pouvons pas valider les commandes avec les configurations suivantes :',
        orderCreatinFailedSubtitle:
          'Échec de la validation de commande. Veuillez réessayer ou contacter l’assistance Flokk.',
        notAllOrdersCreatedSuccessfullySubtitle:
          'Commandes validées : {succedeedOrders}.\nCommandes non validées : {failedOrders}.\nVeuillez réessayer ou contacter l’assistance Flokk.',
        OrderCreatedSuccessNotification: {
          ongoingOrdersLabel: 'Voir les commandes en cours',
          orderDetailsLabel: 'Voir le détail des commandes',
          orderReceivedTitle: 'Merci ! Nous avons bien reçu votre commande.',
          orderReceivedSubtitle:
            'Vous recevrez un e-mail de confirmation de commande lorsque la commande sera envoyée en production.',
        },
      },
      sendCartToEmail: {
        headerText: 'Envoyer les éléments sélectionnés par e-mail',
        sendButton: 'Envoyer',
        emailLabel: 'Adresse e-mail',
      },
    },
    configurationPage: {
      ...baseTranslation.configurationPage,
      priceTaxText: '(HORS TVA)',
    },
    configuratorActions: {
      addToCartButton: 'Ajouter au panier',
      favoriteButton: {
        addToFavoritesText: 'Ajouter à mes favoris',
        addingToFavoritesText: '*Adding to favourites...',
        addedToFavoritesText: 'Ajouté à mes favoris',
        addingToFavoritesFailed: '*Could not add the product to favourites. Make sure it is available in your country.',
      },
      updateCartItemButton: 'Mettre à jour l’élément du panier',
      addedToCartModal: {
        updatedInCartHeader: 'Mis à jour dans le panier',
        addedToCartHeader: 'Ajouté au panier',
        goToCartButton: 'Voir mon panier',

        quantityInfo: {
          productAddedText: '1 produit ajouté',
          productsAddedText: '{quantityAdded} produits ajoutés',
        },
        addedToCartItem: {
          cartItemPreview: {
            cartItemDetails: cartItemDetailsTranslation,
          },
          cartItemSubtotal: {
            exclValText: '(hors TVA)',
            subtotalText: 'Sous-total',
          },
        },
      },
    },
  },
  favoritesPage: {
    title: 'Mes favoris',
    downloadImagesLabel: 'Télécharger les images',
    editNameLabel: 'Modifier un nom',
    removeLabel: 'Supprimer',
    favoriteItem: {
      addToCartButton: 'Ajouter au panier',
      showConfigurationButton: 'Voir la configuration',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    editConfigurationNameModal: {
      header: 'Modifier un nom',
      description: 'Ajouter des libellés à votre configuration (nom du client, date ou autre caractéristique)',
      configurationNameLabel: 'Nom de la configuration',
      saveButton: 'Enregistrer',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    favoriteItemDetailsModal: {
      configurationText: 'Configuration',
      accessoriesText: 'Accessoires',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    removalConfirmationModal: {
      header: 'Supprimer de mes favoris',
      description: 'Voulez-vous vraiment supprimer ce produit de vos favoris ?',
      confirmButton: 'Oui, supprimer',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    emptyFavoritesList: {
      titleText: 'Votre liste de favoris est vide',
      subtitleText: 'mais vous pouvez y remédier',
      labelText: 'Parcourir les produits',
    },
  },
  loginPage: {
    welcomeText: 'Bienvenue sur Flokk Hub !',
    getStartedButton: 'Démarrer',
  },
  orderPage: {
    downloadImagesLabel: 'Télécharger les images',
    shippingWithinTextFormat: 'Expédition sous {minDays} - {maxDays} jours ouvrés',
    shippingSoonText: 'Commande bientôt prête à être expédiée',
    weHaveDelayText: 'Nous rencontrons de légers retards',
    changeInOrderAlertText:
      'Il y a eu un changement dans votre commande, les informations ci-dessous sont peut-être erronées.',
    orderActionButtons: {
      yourReferenceLabel: 'Votre référence',
      orderNameLabel: 'Nom de commande',
      setOrderNameText: 'Définir le nom de commande',
      addEntireOrderToCartButton: 'Ajouter toute la commande au panier',
    },
    trackingInformation: {
      trackOrderButton: 'Suivre la commande',
    },
    shippingDate: {
      shippingDateText: 'Date d’expédition',
      shippingTimeText: 'Heure d’expédition',
      withinDaysTextFormat: 'sous {minDays} - {maxDays} jours ouvrés',
      soonText: 'Bientôt',
      delayText: 'Nous rencontrons de légers retards',
    },
    orderDetailsPanel: {
      deliveryAddressLabel: 'Adresse de livraison',
      totalPriceBeforeVatLabel: 'Prix total hors TVA',
      totalPriceIncludingVatLabel: 'Prix total TVA incluse',
      buyerLabel: 'Acheteur',
      summaryLabel: "Résumé",
      summary: cartSummaryTranslation,
    },
    orderDetailsProduct: {
      reorderButton: 'Commander à nouveau',
      productsHeader: 'Produits',
      shoppingCartItemPanel: shoppingCartItemPanelTranslation,
      shoppingCartItemBasicInfo: shoppingCartItemBasicInfoTranslation,
    },
    orderShipmentsModal: {
      closeButton: 'Fermer',
      header: 'Cette commande sera livrée en plusieurs fois',
      orderShipmentPanelHeader: {
        deliveryNumberLabel: 'N° livraison',
        shippingDateLabel: 'Date d’expédition : ',
        trackOrderButton: 'Suivre la commande',
      },
      orderShipmentsList: {
        configurationCodeLabel: 'Code config.',
      },
    },
    editOrderModal: {
      orderTextFormat: 'Commande {orderNumber}',
      saveButton: 'Enregistrer',
      nameLabel: 'Nom',
    },
    reorderModal: {
      areYouSureText: 'Voulez-vous vraiment ajouter le contenu de la commande à votre panier ?',
      confirmButton: 'Confirmer',
      orderTextFormat: 'Commande {orderNumber}',
    },
  },
  ordersPage: {
    ongoingOrdersTitle: 'Commandes en cours',
    pastOrdersTitle: 'Commandes passées',
    ordersListFilter: {
      searchLabel: 'Rechercher',
      searchPlaceholder: 'Numéro ou nom de commande',
      onlyMineLabel: 'Moi uniquement',
      searchButton: 'Rechercher',
      resetButton: 'Réinitialiser',
      sortByLabel: 'Trier par',
      sortByOrderNumberAscLabel: 'Numéro de commande (asc)',
      sortByOrderNumberDescLabel: 'Numéro de commande (desc)',
      sortByOrderDateAscLabel: 'Date de commande (asc)',
      sortByOrderDateDescLabel: 'Date de commande (desc)',
      sortByOrderTotalAscLabel: 'Total de la commande (asc)',
      sortByOrderTotalDescLabel: 'Total de la commande (desc)',
    },
    tabContent: {
      noOrdersMatchingCriteriaText: 'Aucune commande ne répond à vos critères.',
      pastOrdersTable: {
        orderNumberHeader: 'Numéro de commande',
        orderNameHeader: 'Nom de commande',
        orderDateHeader: 'Date de commande',
        shippingDateHeader: 'Date d’expédition',
        productsHeader: 'Produits',
        totalPriceHeader: 'Prix total',
        buyerHeader: 'Acheteur',
        cancelledText: 'Annulée',
      },
      order: {
        changesInOrderAlertText:
          'Il y a eu un changement dans votre commande, les informations ci-dessous sont peut-être erronées.',
        shippingDateFormat: 'Date d’expédition {date}',
        orderHeader: {
          orderNoLabel: 'Numéro de commande',
          orderDateLabel: 'Date de commande',
          totalPriceLabel: 'Prix total',
          buyerLabel: 'Acheteur',
          orderNameLabel: 'Nom de commande',
          yourReferenceLabel: 'Votre référence',
        },
        orderItem: {
          cartItemDetails: cartItemDetailsTranslation,
        },
        shippingMessage: {
          shippingSoonText: 'Commande bientôt prête à être expédiée',
          shippingWithinDaysFormat: 'Expédition sous {minDays} - {maxDays} jours ouvrés',
          weHaveDelayText: 'Nous rencontrons de légers retards',
        },
      },
    },
  },
  sideMenu: {
    productsLabel: 'Produits',
    quickOrderLabel: 'Commande rapide',
    orderHistoryLabel: 'Historique des commandes',
    favoritesLabel: 'Mes favoris',
    signOutLabel: 'Se déconnecter',
  },
  topMenu: {
    cartButton: 'Panier',
    userInfo: {
      storeSelector: {
        storeChangeFailedWarning: {
          message: '*Sorry, we are not able to change your current organization to the requested one. Please, try again or contact customer support.'
        }
      }
    }
  },
  appLoader: {
    loaderText: 'Initialisation ...',
  },
};

export default translation;
