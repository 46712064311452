import { AsyncCommand } from '../../../../shared/common';
import ActionButton from '../../../../shared/components/ActionButton';
import { ButtonProps } from '../../../../shared/components/Button/Button';
import { IconType } from '../../../../shared/components/Icon/Icon';
import css from './ConfigurationDownload.css';
import ConfigurationDownloadButton from './ConfigurationDownloadButton/ConfigurationDownloadButton';

interface ConfigurationDownloadProps {
  filename: string;
  downloadCommand: AsyncCommand;
  icon: IconType;
  label: string;
  className?: string;
  disabled?: boolean;
}

const ConfigurationDownload = ({
  className,
  disabled,
  downloadCommand,
  filename,
  icon,
  label,
}: ConfigurationDownloadProps) => {
  const renderButton = (props: ButtonProps) => (
    <ConfigurationDownloadButton disabled={disabled} icon={icon} {...props}>
      {filename}
    </ConfigurationDownloadButton>
  );

  return (
    <div className={className}>
      <p className={css.label}>{label}</p>
      <ActionButton command={downloadCommand} buttonElement={renderButton} />
    </div>
  );
};

export default ConfigurationDownload;
