import { AbsoluteUrl } from './Url';

export type ExternalLinkParams = {
  subdomainSegment: string;
  languageSegment: string;
  aboutUs: string;
  contactUs: string;
  default?: boolean;
};

export type ExternalLinksTemplates = {
  flokk: string;
  flokkContactUs: string;
  flokkAboutUs: string;
  flokkInstagram: string;
  flokkFacebook: string;
  flokkPinterest: string;
  flokkLinkedin: string;
  flokkBlog: string;
};

export type ExternalLinksParamsByLanguage = { [language: string]: ExternalLinkParams };

export type ExternalLinksParamsByCountry = { [countryCode: string]: ExternalLinksParamsByLanguage };

export const linksTemplates: ExternalLinksTemplates = {
  flokk: 'https://{subdomainSegment}.flokk.com/{languageSegment}',
  flokkContactUs: 'https://{subdomainSegment}.flokk.com/{languageSegment}/{contactUs}',
  flokkAboutUs: 'https://{subdomainSegment}.flokk.com/{languageSegment}/{aboutUs}',
  flokkInstagram: 'https://www.instagram.com/flokk_design/',
  flokkFacebook: 'https://www.facebook.com/WeAreFlokk/',
  flokkPinterest: 'https://www.pinterest.com/flokkdesign',
  flokkLinkedin: 'https://www.linkedin.com/company-beta/22346784/',
  flokkBlog: 'https://focus.flokk.com/',
};

const defaultLinkParams: ExternalLinkParams = {
  subdomainSegment: 'www',
  languageSegment: '',
  aboutUs: 'about-us/who-we-are',
  contactUs: 'about-us/contact-us',
};

const linkParamsMapping: ExternalLinksParamsByCountry = {
  che: {
    'fr-fr': {
      subdomainSegment: 'ch',
      languageSegment: 'fr',
      contactUs: 'a-propos/contacter-flokk',
      aboutUs: 'a-propos/qui-sommes-nous',
      default: true,
    },
    'de-ch': {
      subdomainSegment: 'ch',
      languageSegment: 'de',
      contactUs: 'uber-uns/kontaktieren-sie-uns',
      aboutUs: 'uber-uns/uber-uns',
    },
  },
  fra: {
    'fr-fr': {
      subdomainSegment: 'fr',
      languageSegment: '',
      contactUs: 'a-propos/contacter-flokk',
      aboutUs: 'a-propos/qui-sommes-nous',
    },
  },
  dnk: {
    'da-da': {
      subdomainSegment: 'dk',
      languageSegment: '',
      aboutUs: 'om-os/hvem-er-vi',
      contactUs: 'om-os/kontakt-os',
    },
  },
  deu: {
    'de-de': {
      subdomainSegment: 'de',
      languageSegment: '',
      contactUs: 'uber-uns/kontaktieren-sie-uns',
      aboutUs: 'uber-uns/uber-uns',
    },
  },
  nld: {
    'nl-nl': {
      subdomainSegment: 'nl',
      languageSegment: '',
      contactUs: 'over-ons/neem-contact-op-met-flokk',
      aboutUs: 'over-ons/wie-wij-zijn',
    },
  },
  nor: {
    'nb-no': {
      subdomainSegment: 'no',
      languageSegment: '',
      contactUs: 'om-oss/kontakt-oss',
      aboutUs: 'om-oss/hvem-vi-er',
    },
  },
  swe: {
    'sv-se': {
      subdomainSegment: 'se',
      languageSegment: '',
      contactUs: 'om-oss/kontakta-flokk',
      aboutUs: 'om-oss/vilka-vi-ar',
    },
  },
  gbr: {
    'en-uk': {
      subdomainSegment: 'uk',
      languageSegment: '',
      contactUs: 'about-us/contact-us',
      aboutUs: 'about-us/who-we-are',
    },
  },
  usa: {
    'en-us': {
      subdomainSegment: 'us',
      languageSegment: '',
      contactUs: 'about-us/contact-us',
      aboutUs: 'about-us/who-we-are',
    },
  },
  pol: {
    'pl-pl': {
      subdomainSegment: 'info',
      languageSegment: 'pl-pl/poland',
      contactUs: '',
      aboutUs: '',
    },
    'en-us': {
      subdomainSegment: 'info',
      languageSegment: 'en-us/poland',
      contactUs: '',
      aboutUs: '',
    },
  },
};

export class ExternalLinks {
  public static instance: ExternalLinks = new ExternalLinks(
    'NO',
    'nb-NO',
    linksTemplates,
    linkParamsMapping,
    defaultLinkParams,
  );

  private templates: ExternalLinksTemplates;
  private countryCode: string;
  private languageCode: string;
  private paramsMapping: ExternalLinksParamsByCountry;
  private defaultParams: ExternalLinkParams;

  public static initialize(countryCode: string, languageCode: string) {
    ExternalLinks.instance = new ExternalLinks(
      countryCode,
      languageCode,
      linksTemplates,
      linkParamsMapping,
      defaultLinkParams,
    );
  }

  constructor(
    countryCode: string,
    languageCode: string,
    templates: ExternalLinksTemplates,
    paramsMapping: ExternalLinksParamsByCountry,
    defaultParams: ExternalLinkParams,
  ) {
    this.templates = templates;
    this.countryCode = countryCode.toLowerCase();
    this.languageCode = languageCode.toLowerCase();
    this.paramsMapping = paramsMapping;
    this.defaultParams = defaultParams;
  }

  private tryFindParams(countryCode: string, languageCode: string) {
    const paramsByLanguage = this.paramsMapping[countryCode];

    if (paramsByLanguage) {
      const params =
        paramsByLanguage[languageCode] ||
        Object.keys(paramsByLanguage)
          .map((x) => paramsByLanguage[x])
          .find((x) => x.default);

      if (params) {
        return params;
      }
    }

    return this.defaultParams;
  }

  getLink(linkName: keyof ExternalLinksTemplates) {
    const template = this.templates[linkName];
    const params = this.tryFindParams(this.countryCode, this.languageCode);

    const interpolationMap = Object.keys(params)
      .map((x) => x as keyof ExternalLinkParams)
      .map((paramName) => {
        const paramValue = params[paramName];
        return [paramName, paramValue] as [keyof ExternalLinkParams, string];
      });

    let url = template.interpolate(interpolationMap);
    url = AbsoluteUrl.parse(url).toString();

    return url;
  }
}

export default (linkName: keyof ExternalLinksTemplates) => ExternalLinks.instance.getLink(linkName);
