import { action, observable, override } from 'mobx';

import { IApiClient } from '../data/client';
import {
  GetModularLayoutQuery,
  GetModularProductFamilyQuery,
  ModularLayoutBasicData,
  ModularLayoutData,
  ModularProductFamilyData,
  ShoppingContext,
} from '../data/model';
import { SiteTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { IShareConfiguratorUrlProvider } from '../shared/BaseShareConfiguratorUrlProvider';
import { IMemento } from '../shared/common';
import { INavigationService } from '../shared/NavigationService';
import { StoreState } from '../StoreState';
import ModularConfiguratorCoordinatorState from './components/ModularConfiguratorCoordinator/ModularConfiguratorCoordinatorState';
import ModularConfiguratorPageQuery from './ModularConfiguratorPageQuery';
import ModularConfiguratorPageQueryParser from './ModularConfiguratorPageQueryParser';

interface IModularConfiguratorPageMemento extends IMemento {
  layoutData: ModularLayoutData;
  productFamilyData: ModularProductFamilyData;
  shoppingContext: ShoppingContext;
}

class ModularConfiguratorPageState extends BasePageState<IModularConfiguratorPageMemento> {
  public configuratorCoordinator: ModularConfiguratorCoordinatorState;

  @observable
  private layoutData: ModularLayoutData;
  private productFamilyData: ModularProductFamilyData;
  private shoppingContext: ShoppingContext;

  @override
  public get metadata() {
    const title = 'Modular Configurator';
    return { title };
  }

  public constructor(
    private readonly client: IApiClient,
    private readonly configurationUrlProvider: IShareConfiguratorUrlProvider,
    private readonly navigation: INavigationService,
    // TODO: Replace with ModularConfiguratorPageTranslation
    private readonly translation: SiteTranslation,
    private readonly productFamilyId: string,
  ) {
    super();
  }

  public getMemento(): IModularConfiguratorPageMemento {
    return {
      layoutData: this.layoutData,
      productFamilyData: this.productFamilyData,
      shoppingContext: this.shoppingContext,
    };
  }

  public restoreMemento(memento: IModularConfiguratorPageMemento) {
    this.initialize(memento.productFamilyData, memento.layoutData, memento.shoppingContext);
  }

  public async onLoad(store: StoreState) {
    const productFamilyData = await this.loadProductFamilyData();
    const initialLayoutId = this.resolveInitialLayoutId(productFamilyData);
    const layoutData = await this.loadLayoutData(initialLayoutId);

    this.initialize(productFamilyData, layoutData, store.shoppingContext);

    await this.configurationUrlProvider.initialize(this.shoppingContext);
  }

  public async onLoadAdditionalData() {
    await this.loadLayoutIntoConfigurator(this.layoutData);
  }

  private initialize(
    productFamilyData: ModularProductFamilyData,
    layoutData: ModularLayoutData,
    shoppingContext: ShoppingContext,
  ) {
    this.configuratorCoordinator = new ModularConfiguratorCoordinatorState(
      this.client,
      this.configurationUrlProvider,
      productFamilyData,
      shoppingContext,
      this.translation,
      layoutData.id,
    );
    this.layoutData = layoutData;
    this.productFamilyData = productFamilyData;
    this.shoppingContext = shoppingContext;

    this.configuratorCoordinator.layoutPicker.changed.subscribe(this.handleLayoutChangeEvent);
  }

  @action.bound
  private async handleLayoutChangeEvent({ code, id }: ModularLayoutBasicData) {
    this.updateQueryString(new ModularConfiguratorPageQuery(code));
    await this.reloadLayout(id);
  }

  @action
  private async reloadLayout(layoutId: string) {
    this.layoutData = await this.loadLayoutData(layoutId);
    await this.loadLayoutIntoConfigurator(this.layoutData);
  }

  private async loadLayoutData(layoutId: string) {
    const query = new GetModularLayoutQuery({ layoutId, shoppingContext: this.shoppingContext });
    const response = await this.client.send(query);

    return response.layout;
  }

  private async loadLayoutIntoConfigurator(layoutData: ModularLayoutData) {
    return this.configuratorCoordinator.loadLayoutIntoConfigurator(layoutData);
  }

  private async loadProductFamilyData() {
    const query = new GetModularProductFamilyQuery({
      productFamilyId: this.productFamilyId,
      shoppingContext: this.shoppingContext,
    });
    const response = await this.client.send(query);

    return response.productFamily;
  }

  private resolveInitialLayoutId(productFamilyData: ModularProductFamilyData) {
    const layoutCode = this.getLayoutCodeFromQueryString();
    const layout = productFamilyData.layouts.find((x) => x.code === layoutCode);

    if (layout?.id) {
      return layout.id;
    }

    return productFamilyData.layouts.first()?.id ?? '';
  }

  private getLayoutCodeFromQueryString() {
    return ModularConfiguratorPageQueryParser.toModel(this.navigation.currentUrl.query).layoutCode;
  }

  private updateQueryString(queryModel: ModularConfiguratorPageQuery) {
    this.navigation.setQuery(ModularConfiguratorPageQueryParser.toQuery(queryModel));
  }
}

export default ModularConfiguratorPageState;
