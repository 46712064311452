import classNames from 'classnames';
import * as React from 'react';

import { InputElementProps } from '../Input/InputElement';
import css from './InputRadio.css';

interface InputRadioProps extends InputElementProps {
  children: React.ReactNode;
  classNameLabel?: string;
}

const InputRadio = ({ checked, children, className, classNameLabel, id, name, onChange, value }: InputRadioProps) => {
  return (
    <label className={classNames(css.InputRadio, className)}>
      <input
        type="radio"
        className={css.radio}
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className={classNames(css.label, classNameLabel)}>{children}</span>
    </label>
  );
};

export default InputRadio;
