import classNames from 'classnames';
import { Component } from 'react';

import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import css from './ShoppingCartSummary.css';
import { ProductSummaryItem } from './ShoppingCartSummaryCartItem';

interface SimpleProductSummaryProps {
  name: string;
  code?: string;
  quantity?: string;
  imageUrl?: string;
  translation: ShoppingCartSummaryTranslation;
  className?: string;
}

export class SimpleProductSummary extends Component<SimpleProductSummaryProps> {
  render() {
    const { className, translation, name, quantity, code, imageUrl } = this.props;

    if (!code && !imageUrl) {
      return <h1>{name}</h1>;
    }

    return (
      <div className={classNames(css.ShoppingCartSummary, className)}>
        <div className={css.content}>
          <ProductSummaryItem
            imageUrl={imageUrl}
            translation={translation}
            name={name}
            quantity={quantity}
            code={code ?? name}
          />
        </div>
      </div>
    );
  }
}
