import { action, makeObservable } from 'mobx';

import { OrderData, PaymentData, PaymentParameterData } from '../../../data/model';
import Logger from '../../../shared/Logger';
import { AsyncScriptLoader } from '../AsyncScriptLoader';
import { BasePaymentMethod } from '../IPaymentMethod';
import { PaymentParameterDataExtensions } from '../PaymentParameterDataExtensions';
import DibsLanguageResolver from './DibsLanguageResolver';

export default class DibsPaymentMethod extends BasePaymentMethod {
  private checkout: Dibs.Checkout;

  public checkoutOptions: {
    checkoutKey: string;
    paymentId: string;
    containerId: string;
    language: string;
  };

  private isProd: boolean;
  private currentLanguage: string;
  termsAndConditionsUrl: string;

  constructor(termsAndConditionsUrl: string, currentLanguage: string) {
    super('NetsEasy');
    makeObservable(this);
    this.currentLanguage = currentLanguage;
    this.termsAndConditionsUrl = termsAndConditionsUrl;
    this.onWindowMessageReceived = this.onWindowMessageReceived.bind(this);
    this.ensureScriptLoaded();
  }

  @action
  private setIsInitialized() {
    this.isInitialized = true;
  }

  private onWindowMessageReceived(event: MessageEvent) {
    if (this.checkout.checkMsgSafe(event)) {
      try {
        const message = JSON.parse(event.data);
        switch (message.eventType) {
          case 'checkoutInitialized':
            this.setIsInitialized();
            break;
          default:
            break;
        }
      } catch (err) {
        Logger.exception('Dibs - handling window message failed', err);
      }
    }
  }

  public async onWidgetRendered(): Promise<void> {
    await this.ensureScriptLoaded();
    try {
      this.checkout = new Dibs.Checkout(this.checkoutOptions);
      this.checkout.on('payment-completed', (response) => {
        this.authorizationCompleted.raise({
          parameters: [
            {
              key: 'session',
              value: response.toString(),
            },
            {
              key: 'paymentId',
              value: this.checkoutOptions.paymentId,
            },
          ],
        });
      });

      window.addEventListener('message', this.onWindowMessageReceived, false);
    } catch (err) {
      Logger.exception('Dibs - checkout initialization failed', err);
    }
  }

  public getInitialParameters(): Array<PaymentParameterData> {
    return [
      {
        key: 'url',
        value: location.href + '?step=payment&orderNumber={order.OrderNumber}',
      },
      {
        key: 'termsUrl',
        value: this.termsAndConditionsUrl,
      },
    ];
  }

  @action
  public async initialize(order: OrderData, payment: PaymentData): Promise<void> {
    const prod = PaymentParameterDataExtensions.GetRequired(payment.parameters, 'IsProd');
    const checkoutKey = PaymentParameterDataExtensions.GetRequired(payment.parameters, 'CheckoutKey');
    const paymentId = PaymentParameterDataExtensions.GetRequired(payment.parameters, 'PaymentId');

    this.isProd = prod.toLowerCase() === true.toString().toLocaleLowerCase();

    const language = DibsLanguageResolver.resolve(this.currentLanguage);

    this.checkoutOptions = {
      checkoutKey: checkoutKey,
      paymentId: paymentId,
      containerId: 'dibs_container',
      language: language,
    };
  }

  public authorize(): Promise<void> {
    throw new Error('Method not supported as the authorization is to by the widget itself.');
  }

  public dispose() {
    window.removeEventListener('message', this.onWindowMessageReceived);
  }

  private ensureScriptLoaded(): Promise<void> {
    const testScriptUrl = 'https://test.checkout.dibspayment.eu/v1/checkout.js?v=1';
    const productionScriptUrl = 'https://checkout.dibspayment.eu/v1/checkout.js?v=1';
    return AsyncScriptLoader.ensureLoaded(this.isProd ? productionScriptUrl : testScriptUrl);
  }
}
