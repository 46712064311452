import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { ImageData } from '../../../data/model';
import { Breakpoint, Screen, ScreenObserver } from '../../../layout/ScreenObserver';
import BackgroundVideoPlayer from '../../../shared/components/BackgroundVideoPlayer/BackgroundVideoPlayer';
import ExpandableTextBox from '../../../shared/components/ExpandableTextBox/ExpandableTextBox';
import PreloadedImage from '../PreloadedImage';
import css from './Hero.css';

interface HeroProps {
  className?: string;
  title: string;
  description: string;
  image: ImageData;
  videoUrl?: string;
}

@observer
class Hero extends Component<HeroProps, { breakpoint: Breakpoint }> {
  constructor(props: HeroProps) {
    super(props);

    this.onScreenChange = this.onScreenChange.bind(this);
    this.state = { breakpoint: Breakpoint.Unknown };
  }

  componentDidMount(): void {
    this.onScreenChange(ScreenObserver.current);
    ScreenObserver.onScreenChange.subscribe(this.onScreenChange);
  }

  componentWillUnmount(): void {
    ScreenObserver.onScreenChange.unsubscribe(this.onScreenChange);
  }

  onScreenChange(screen: Screen) {
    this.setState({ breakpoint: screen.breakpoint });
  }

  getDescriptionHeight() {
    switch (this.state.breakpoint) {
      case Breakpoint.Desktop:
        return 150;
      case Breakpoint.Tablet:
        return 72;
      case Breakpoint.Phone:
        return 48;
      default:
        return null;
    }
  }

  render() {
    const { className, title, description, image, videoUrl } = this.props;

    const showVideo = videoUrl && this.state.breakpoint === Breakpoint.Desktop;
    const showImage = image.url && !showVideo;

    const descriptionHeight = this.getDescriptionHeight();

    return (
      <div className={classNames(css.Hero, className)}>
        {showImage && <PreloadedImage className={css.image} src={image.url} alt={image.name} />}
        {showVideo && (
          <div className={css.videoContainer}>
            <BackgroundVideoPlayer sourceUrl={videoUrl} />
          </div>
        )}

        <div className={css.content}>
          <ExpandableTextBox
            heading={title}
            description={description}
            maxHeight={descriptionHeight}
            className={css.descriptionBox}
            initClassName={css.initHeight}
          />
        </div>
      </div>
    );
  }
}

export default Hero;
