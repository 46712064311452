import classNames from 'classnames';

import css from './StoreDisplayName.css';

interface StoreDisplayNameProps {
  storeId: string;
  storeName: string;
  className?: string;
}

const StoreDisplayName = ({ className, storeId, storeName }: StoreDisplayNameProps) => {
  return <span className={classNames(css.element, className)}>{`${storeName} (ID ${storeId})`}</span>;
};

export default StoreDisplayName;
