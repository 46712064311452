import { observer } from 'mobx-react';

import { OrderData } from '../../../app/data/model';
import { RouteUrlBuilder } from '../../../app/RouteDefinitions';
import Modal from '../../../app/shared/components/Modal/Modal';
import Notification, { NotificationLinkProps } from '../../../app/shared/components/Notification/Notification';
import { StoreUrlBuilder } from '../../../app/shared/StoreUrlBuilder';
import { OrderCreatedSuccessNotificationTranslation } from '../../localization/SiteTranslation';
import RouteDefinitions from '../../routing/RouteDefinitions';
import { OrderCreationResultModal } from '../CartListState';
import css from './OrderCreatedModal.css';

interface OrderCreatedModalProps {
  state: OrderCreationResultModal;
}

export const OrderCreatedModal = observer(({ state }: OrderCreatedModalProps) => {
  const result = state.result;
  const translation = state.translation;

  if (!result) {
    return <></>;
  }

  const titleTranslation = translation.notAllOrdersCreatedSuccessfullyTitle.interpolate([
    ['succedeedOrdersCount', result.orders.length.toString()],
    ['totalOrdersCount', result.orders.length + result.failedShoppingCartIds.length.toString()],
  ]);

  const subtitleTranslation = translation.notAllOrdersCreatedSuccessfullySubtitle.interpolate([
    ['succedeedOrders', result.orders.map((x) => `${x.number} (${x.customName})`).join(', ')],
    ['totalOrders', state.failedCarts.map((x) => x.customName).join(', ')],
  ]);

  return (
    <Modal modal={state} contentClassName={css.OrderCreatedModal}>
      {result.success && (
        <OrderCreatedSuccessNotification
          orders={result.orders}
          onClose={state.close}
          translation={translation.OrderCreatedSuccessNotification}
        />
      )}
      {!result.success && result.invalidConfigurationCodes.any() && (
        <Notification
          icon={{ name: 'info', colorGrey: true, className: css.orderCreatedModalIcon }}
          title={translation.somethingWentWrongTitle}
          subtitle={`${translation.technicalIssuesSubtitle} ${result.invalidConfigurationCodes.join(', ')}`}
        />
      )}
      {!result.success && result.orders.empty() && result.failedShoppingCartIds.any() && (
        <Notification
          icon={{ name: 'info', colorGrey: true, className: css.orderCreatedModalIcon }}
          title={translation.somethingWentWrongTitle}
          subtitle={translation.orderCreatinFailedSubtitle}
        />
      )}
      {!result.success && result.orders.any() && result.failedShoppingCartIds.any() && (
        <Notification
          icon={{ name: 'info', colorGrey: true, className: css.orderCreatedModalIcon }}
          title={titleTranslation}
          subtitle={subtitleTranslation}
        />
      )}
    </Modal>
  );
});

const getUrlOrder = (orderNumber: string) => {
  return StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.orderRoute, { orderNumber }));
};

const OrderCreatedSuccessNotification = ({
  orders,
  onClose,
  translation,
}: {
  orders: Array<OrderData>;
  onClose: () => void;
  translation: OrderCreatedSuccessNotificationTranslation;
}) => {
  let link: NotificationLinkProps;

  if (orders.length > 1) {
    const ongoingOrdersUrl = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.ordersRoute));
    link = { to: ongoingOrdersUrl, label: translation.ongoingOrdersLabel, onClick: onClose };
  } else {
    link = { to: getUrlOrder(orders[0].number), label: translation.orderDetailsLabel, onClick: onClose };
  }

  return (
    <Notification
      icon={{ name: 'sent', colorGrey: true, className: css.orderCreatedModalIcon }}
      title={translation.orderReceivedTitle}
      subtitle={translation.orderReceivedSubtitle}
      links={[link]}
    />
  );
};
