import classNames from 'classnames';
import { observer } from 'mobx-react';

import { RenderWhenPriceEnabled } from '../../../context/PriceContext';
import { ShoppingCartData } from '../../../data/model';
import { ShoppingCartSummaryTranslation } from '../../../localization/SiteTranslation';
import Price from '../../../shared/components/Price';
import { DeliveryFee } from './DeliveryFee';
import css from './ShoppingCartSummary.css';
import ShoppingCartSummaryCartItem from './ShoppingCartSummaryCartItem';
import { TotalToPay } from './TotalToPay';

interface ShoppingCartSummaryProps {
  shoppingCart: ShoppingCartData;
  translation: ShoppingCartSummaryTranslation;
  className?: string;
  showMoreInformation?: boolean;
  shippingCostAvailable: boolean;
}

export const ShoppingCartSummary = observer((props: ShoppingCartSummaryProps) => {
  const { shoppingCart, translation, className, showMoreInformation } = props;
  const vatTotalText = translation.totalTaxFormat.interpolate([
    ['taxTotal', shoppingCart.taxRatePercentage.toString()],
  ]);

  const feesAndPrice = () => (
    <div className={css.priceSummaryContainer}>
      <p className={css.subtotalContainer}>
        <span>{translation.subTotal}</span> <Price price={shoppingCart.subTotal} />
      </p>
      <p className={css.subtotalContainer}>
        <span>{vatTotalText}</span> <Price price={shoppingCart.subTotalTax} />
      </p>
      <p className={css.subtotalContainer}>
        <span>{translation.subTotalWithTax}</span> <Price price={shoppingCart.subTotalWithTax} />
      </p>
      {shoppingCart.discountTotalWithTax.amount > 0 && (
        <p className={classNames(css.subtotalContainer, css.discount)}>
          <span>{translation.discount}</span> <Price price={shoppingCart.discountTotalWithTax} />
        </p>
      )}
      <DeliveryFee
        shippingTotal={shoppingCart.shippingTotalWithTax}
        translation={translation}
        className={css.deliveryFee}
        shippingCostAvailable={props.shippingCostAvailable}
        deliveryTime={shoppingCart.deliveryTime}
      />
    </div>
  );

  return (
    <div className={classNames(css.ShoppingCartSummary, className)}>
      <div className={css.content}>
        {shoppingCart.items.map((item, i) => {
          return (
            <ShoppingCartSummaryCartItem key={i} item={item} translation={translation} expanded={showMoreInformation} />
          );
        })}
        <RenderWhenPriceEnabled>
          {feesAndPrice()}
          <div className={css.priceContainer}>
            <TotalToPay price={shoppingCart.totalWithTax} translation={translation.totalToPay} />
            {/* <div className={css.priceTotalWithoutTaxContainer}>
              <p className={css.price}>
                <Price price={shoppingCart.total}/> {translation.taxText}
              </p>
            </div>*/}
          </div>
        </RenderWhenPriceEnabled>
      </div>
    </div>
  );
});
