import { nameOf } from '../shared/common';
import { QueryParser } from '../shared/QueryParser';

export class StoreLocatorQuery {
  storeTypes?: Array<string> = [];
  brands?: Array<string> = [];
  hideFilter?: boolean;
}

export const STORE_TYPES = nameOf<StoreLocatorQuery>('storeTypes');
export const BRANDS = nameOf<StoreLocatorQuery>('brands');
export const HIDE_FILTER = nameOf<StoreLocatorQuery>('hideFilter');

export class StoreLocatorQueryParser extends QueryParser {
  static toModel(query: Map<string, string>) {
    const queryModel = new StoreLocatorQuery();

    queryModel.brands = QueryParser.getValues(query, BRANDS);
    queryModel.storeTypes = QueryParser.getValues(query, STORE_TYPES);
    queryModel.hideFilter = QueryParser.getValue(query, HIDE_FILTER) === true.toString();

    return queryModel;
  }

  static toQuery(queryModel: StoreLocatorQuery) {
    const query = new Map<string, string>();

    QueryParser.setValues(query, BRANDS, queryModel.brands);
    QueryParser.setValues(query, STORE_TYPES, queryModel.storeTypes);

    return query;
  }
}
