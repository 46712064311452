import { observer } from 'mobx-react';

import LayoutPicker from './ModularConfigurator/LayoutPicker/LayoutPicker';
import ModularComponentConfigurator from './ModularConfigurator/ModularComponentConfigurator/ModularComponentConfigurator';
import ModularConfigurator from './ModularConfigurator/ModularConfigurator';
import ModularConfiguratorPreview from './ModularConfigurator/ModularConfiguratorPreview/ModularConfiguratorPreview';
import ModularConfiguratorSummary from './ModularConfigurator/ModularConfiguratorSummary/ModularConfiguratorSummary';
import ModularConfiguratorCoordinatorState from './ModularConfiguratorCoordinatorState';

interface ModularConfiguratorCoordinatorProps {
  state: ModularConfiguratorCoordinatorState;
  className?: string;
}

const ModularConfiguratorCoordinator = observer(({ className, state }: ModularConfiguratorCoordinatorProps) => {
  const configuratorView = state.isComponentModeActive ? (
    <ModularComponentConfigurator state={state} />
  ) : (
    <LayoutPicker state={state.layoutPicker} disabled={state.configurator.loadingIndicator.isLoading} />
  );

  return (
    <ModularConfigurator
      className={className}
      state={state}
      view={configuratorView}
      preview={<ModularConfiguratorPreview state={state} readonly={state.isSummary} />}
      summary={<ModularConfiguratorSummary state={state} />}
    />
  );
});

export default ModularConfiguratorCoordinator;
