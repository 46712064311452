import { computed, makeObservable } from 'mobx';

import { ShoppingCartItemData } from '../../app/data/model';
import { AsyncCommand } from '../../app/shared/common';
import { CartItemContextCommand, ICartItemContextAction } from '../cart/CartItem/CartItemState';
import { FavoriteItemTranslation } from '../localization/SiteTranslation';

interface IFavoriteItemActions {
  addToCart: (item: ShoppingCartItemData) => Promise<void>;
  contextActions: ICartItemContextAction[];
  showDetails?: (item: ShoppingCartItemData) => void;
}

export class FavoriteItemState {
  public readonly addToCartCommand: AsyncCommand;
  public readonly contextCommands: CartItemContextCommand[] = [];
  public readonly showItemDetails?: () => void;

  constructor(
    public readonly item: ShoppingCartItemData,
    private readonly actions: IFavoriteItemActions,
    public readonly translation: FavoriteItemTranslation,
  ) {
    makeObservable(this);
    this.addToCartCommand = new AsyncCommand(async () => {
      await this.actions.addToCart(this.item);
    });

    this.contextCommands = actions.contextActions.map<CartItemContextCommand>((action) => ({
      label: action.label,
      command: new AsyncCommand(() => action.action(this.item)),
    }));

    if (this.actions.showDetails) {
      this.showItemDetails = () => this.actions.showDetails(this.item);
    }
  }

  @computed
  get isContextCommandProcessing(): boolean {
    return this.contextCommands.any((command) => command.command.processing);
  }
}
