import throttle from 'lodash.throttle';
import { action, computed, makeObservable, observable } from 'mobx';
import Supercluster, { AnyProps, ClusterFeature, PointFeature } from 'supercluster';

import { PhysicalStoreData } from '../../../data/model';

interface PointProperties {
  shop: PhysicalStoreData;
}

export default class StoreMapState {
  gApiKey: string;
  superCluster: Supercluster<PointProperties>;
  readonly maxZoomLevel: number;
  readonly clusterZoomRatio: number;
  readonly initialClusterSize: number;
  throttledCluster: () => void;

  @observable
  hasZoomChanged: boolean;

  @observable.shallow
  clusters: Array<ClusterFeature<AnyProps> | PointFeature<PointProperties>>;

  @observable
  isGoogleMapInitialized: boolean;

  @observable
  language: string;

  @observable
  public autocompleteMarker: google.maps.LatLngLiteral;

  @observable.shallow
  storesToShow: Array<PhysicalStoreData> = [];

  @observable
  center: google.maps.LatLngLiteral;

  @observable
  zoom: number;

  @observable
  bounds: google.maps.LatLngBoundsLiteral;

  @observable
  shouldSort: boolean;

  @observable.deep
  selectedItem: PhysicalStoreData;

  @observable.shallow
  focusToBounds: Array<google.maps.LatLngLiteral>;

  constructor(language: string, apiKey: string) {
    makeObservable(this);
    this.zoom = 10;
    this.center = { lat: 60, lng: 10.8 };
    this.language = language;
    this.gApiKey = apiKey;
    this.isGoogleMapInitialized = false;
    this.focusToBounds = observable.array([]);
    this.superCluster = new Supercluster();
    this.initialClusterSize = 40;
    this.maxZoomLevel = 15;
    this.clusterZoomRatio = 8;
    this.throttledCluster = throttle(this._cluster, 300);
  }

  @action
  public setBounds(bounds: google.maps.LatLngBoundsLiteral) {
    this.bounds = bounds;
  }

  @action.bound
  setFocusToBounds(latLngs: google.maps.LatLngLiteral[]) {
    this.focusToBounds = latLngs;
  }

  @action
  public mapInitialized() {
    this.isGoogleMapInitialized = true;
  }

  @action
  public setLocationInput(latLng: google.maps.LatLngLiteral) {
    this.autocompleteMarker = latLng;
  }

  @computed
  public get maxCluster() {
    const largestCluster = this.clusters
      .filter((x) => (<ClusterFeature<AnyProps>>x).properties.cluster === true)
      .sort(
        (p: ClusterFeature<AnyProps>, q: ClusterFeature<AnyProps>) =>
          q.properties.point_count - p.properties.point_count,
      )
      .find(() => true);
    return (<ClusterFeature<AnyProps>>largestCluster)?.properties?.point_count || 1;
  }

  @computed
  public get clusterRadius() {
    return this.initialClusterSize + (this.maxZoomLevel - this.zoom) * this.clusterZoomRatio;
  }

  @action
  public updateClusters() {
    this.throttledCluster();
  }

  @action
  public refreshMap() {
    this.setZoom(this.zoom + 0.001);
  }

  @action.bound
  private _cluster() {
    if (!this.hasZoomChanged) {
      return;
    }
    this.superCluster = new Supercluster({
      radius: this.clusterRadius,
      maxZoom: 13,
    });

    const map: PointFeature<PointProperties>[] = [...this.storesToShow]
      .sort((p, q) => p.id.localeCompare(q.id))
      .map((x) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [x.coordinates.lng, x.coordinates.lat],
          },
          properties: {
            shop: x,
          },
        };
      });

    this.superCluster.load(map);
    const zoom = Math.round(this.zoom);
    this.clusters = this.bounds
      ? this.superCluster.getClusters([this.bounds.west, this.bounds.south, this.bounds.east, this.bounds.north], zoom)
      : [];
  }

  @action
  public setLanguage(language: string) {
    this.language = language;
  }

  @action.bound
  public enableSort() {
    this.shouldSort = true;
  }

  @action
  public setZoom(zoom: number) {
    this.hasZoomChanged = true;
    this.zoom = zoom;
  }

  @action
  public setCenter(center: google.maps.LatLngLiteral, shouldSort: boolean) {
    this.shouldSort = shouldSort;
    this.center = center;
  }

  @action
  public setStores(data: Array<PhysicalStoreData>, shouldAdjustViewport: boolean) {
    this.storesToShow = data;
    if (shouldAdjustViewport) {
      this.setFocusToBounds(this.storesToShow.map((x) => x.coordinates));
    }
  }

  @action
  public clearSelectedItem() {
    this.selectedItem = null;
  }

  @action
  public deselectItem() {
    this.shouldSort = false;
    this.selectedItem = null;
    this.updateClusters();
  }

  @action
  public setSelectedItem(item: PhysicalStoreData) {
    this.shouldSort = false;
    this.selectedItem = item;
    this.center = {
      lat: item.coordinates.lat,
      lng: item.coordinates.lng,
    };
    const zoomThreshold = 10;
    if (this.zoom < zoomThreshold) {
      this.setZoom(zoomThreshold);
    }
    this.updateClusters();
  }
}
