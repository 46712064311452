import classNames from 'classnames';
import { observer } from 'mobx-react';

import { ProductInfoTranslation, WhereToBuyTranslation } from '../../../localization/SiteTranslation';
import { AsyncCommand } from '../../../shared/common';
import ActionButton from '../../../shared/components/ActionButton';
import { SecondaryButtonLink } from '../../../shared/components/Button/LinkButtons';
import SecondaryButton from '../../../shared/components/Button/SecondaryButton';
import TertiaryButton from '../../../shared/components/Button/TertiaryButton';
import Icon from '../../../shared/components/Icon/Icon';
import css from './ProductDetails.css';
import { ProductFeatureTogglesState } from './ProductFeatureTogglesState';

interface ProductModelDetailsButtonsProps {
  optionsVisibility: ProductFeatureTogglesState;
  goToConfiguratorCommand: AsyncCommand;
  goToCheckoutCommand: AsyncCommand;
  askForPriceCommand: AsyncCommand;
  whereToBuyPageUrl?: string;
  translation: ProductInfoTranslation;
}

export const ProductModelActionButtons = observer(
  ({
    optionsVisibility: state,
    goToConfiguratorCommand,
    goToCheckoutCommand,
    askForPriceCommand,
    whereToBuyPageUrl,
    translation,
  }: ProductModelDetailsButtonsProps) => {
    const { askForPriceEnabled, configureChairEnabled, goToCheckoutEnabled, whereToBuyEnabled, designerEnabled } =
      state.getProductFeatureToggles();

    return (
      <>
        <div className={css.containerButtons}>
          {whereToBuyEnabled && (
            <div className={css.button}>
              <SecondaryButtonLink className={css.whereToBuyButton} href={whereToBuyPageUrl} target={'_blank'}>
                <span className={css.whereToBuyButtonContent}>
                  <Icon name={'basket'} className={css.whereToBuyButtonIcon} />
                  {translation.actions.whereToBuyButton}
                </span>
              </SecondaryButtonLink>
              <WhereToBuyMessage
                className={css.whereToBuyMessageMobile}
                translation={translation.whereToBuy}
                designerEnabled={designerEnabled}
              />
            </div>
          )}

          {goToCheckoutEnabled && (
            <ActionButton className={css.button} command={goToCheckoutCommand} buttonElement={SecondaryButton}>
              {translation.actions.goToCheckoutButton}
            </ActionButton>
          )}

          {askForPriceEnabled && (
            <ActionButton className={css.button} command={askForPriceCommand} buttonElement={SecondaryButton}>
              {translation.actions.askForPriceButton}
            </ActionButton>
          )}

          {configureChairEnabled && (
            <ActionButton className={css.button} command={goToConfiguratorCommand} buttonElement={TertiaryButton}>
              {translation.actions.configureChairButton}
            </ActionButton>
          )}
        </div>
        {whereToBuyEnabled && (
          <WhereToBuyMessage
            className={css.whereToBuyMessageDesktop}
            translation={translation.whereToBuy}
            designerEnabled={designerEnabled}
          />
        )}
      </>
    );
  },
);

const WhereToBuyMessage = ({
  className,
  translation,
  designerEnabled,
}: {
  className?: string;
  translation: WhereToBuyTranslation;
  designerEnabled: boolean;
}) => (
  <p className={classNames(css.WhereToBuyMessage, className)}>
    {translation.text} {designerEnabled && translation.designCustomChairText}
  </p>
);
