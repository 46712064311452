import { AbsoluteUrl } from './Url';

export class ImageUrlBuilder {
  public static build(src: string, width?: number, height?: number, sharpeningFactor: number = 1): string {
    if (!src) {
      return '';
    }

    if (!width && !height) {
      return src;
    }

    const url = AbsoluteUrl.parse(src);

    if (width) {
      url.relative.setQueryParameter('width', Math.round(width * sharpeningFactor));
    }

    if (height) {
      url.relative.setQueryParameter('height', Math.round(height * sharpeningFactor));
    }

    return url.toString();
  }
}
