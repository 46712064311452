import { observer } from 'mobx-react';

import BabylonScene from '../3dEngine/babylon/BabylonScene';
import { OpacityPresenter } from '../3dEngine/babylon/presenters/OpacityPresenter';
import { PositionPresenter } from '../3dEngine/babylon/presenters/PositionPresenter';
import { RotationPresenter } from '../3dEngine/babylon/presenters/RotationPresenter';
import { SelectionPresenter } from '../3dEngine/babylon/presenters/SelectionPresenter';
import { CubeVisualFactory } from '../3dEngine/babylon/visuals/CubeVisualFactory';
import { GroupVisualFactory } from '../3dEngine/babylon/visuals/GroupVisualFactory';
import { LoadableVisualFactory } from '../3dEngine/babylon/visuals/LoadableVisualFactory';
import { ObjectState } from '../3dEngine/core/ObjectState';
import { ModularConfiguratorPageState } from './ModularConfiguratorPageState';

interface ProductViewer3dPageProps {
  state: ModularConfiguratorPageState;
}

const factories = [new CubeVisualFactory(), new LoadableVisualFactory(), new GroupVisualFactory()];
const presenters = [new OpacityPresenter(), new PositionPresenter(), new RotationPresenter(), new SelectionPresenter()];

const ModularConfiguratorPage = ({ state }: ProductViewer3dPageProps) => {
  return (
    <div style={{ padding: 20 }}>
      <BabylonScene
        size={{ width: 800, height: 600 }}
        state={state.scene}
        factories={factories}
        presenters={presenters}
        onObjectClick={(x) => state.selectObject(x)}
        onNoneObjectClick={() => state.clearSelection()}
      />
      {state.assetsLoadingIndicator.isLoading && <div>Loading assets ...</div>}
      {!state.assetsLoadingIndicator.isLoading && (
        <>
          <div>
            <button onClick={() => state.addCube()}>Add cube</button>
            <br />
            <button onClick={() => state.addFromFile()}>Add from file</button>
            <br />
            {state.scene.selectedObjects.any() && <button onClick={() => state.removeSelected()}>Remove</button>}
          </div>
          {state.scene.selectedObjects.length === 1 && (
            <SelectedObjectProperties selectedObject={state.scene.selectedObjects[0]} />
          )}
        </>
      )}
    </div>
  );
};

interface SelectedObjectPropertiesProps {
  selectedObject: ObjectState;
}

const SelectedObjectProperties = observer(({ selectedObject }: SelectedObjectPropertiesProps) => {
  return (
    <div>
      position x ={' '}
      <input
        type="number"
        value={selectedObject.position.x}
        onChange={(e) => (selectedObject.position.x = parseFloat(e.target.value))}
      />
      <br />
      position y ={' '}
      <input
        type="number"
        value={selectedObject.position.y}
        onChange={(e) => (selectedObject.position.y = parseFloat(e.target.value))}
      />
      <br />
      position z ={' '}
      <input
        type="number"
        value={selectedObject.position.z}
        onChange={(e) => (selectedObject.position.z = parseFloat(e.target.value))}
      />
      <br />
      rotation x ={' '}
      <input
        type="number"
        value={selectedObject.rotation.x}
        onChange={(e) => (selectedObject.rotation.x = parseFloat(e.target.value))}
      />
      <br />
      rotation y ={' '}
      <input
        type="number"
        value={selectedObject.rotation.y}
        onChange={(e) => (selectedObject.rotation.y = parseFloat(e.target.value))}
      />
      <br />
      rotation z ={' '}
      <input
        type="number"
        value={selectedObject.rotation.z}
        onChange={(e) => (selectedObject.rotation.z = parseFloat(e.target.value))}
      />
      <br />
    </div>
  );
});

export default observer(ModularConfiguratorPage);
