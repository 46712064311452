import { observer } from 'mobx-react';
import { Component } from 'react';

import ErrorPage from '../../app/errorHandling/ErrorPage';
import FullPageLoader from '../../app/shared/components/Loaders/FullPageLoader';
import { IOAuthHandlerState } from './IOAuthHandlerState';

interface Props {
  state: IOAuthHandlerState;
}

@observer
export class OAuthHandlerPage extends Component<Props> {
  render() {
    const { state } = this.props;

    return (
      <>
        {!state.error && <FullPageLoader />}
        {state.error && <ErrorPage title={state.errorHeader || 'Something went wrong'}>{state.error}</ErrorPage>}
      </>
    );
  }
}
