import { nameOf } from '../../app/shared/common';
import { QueryParser } from '../../app/shared/QueryParser';

export class OrderQuery {
  phrase?: string;
}

export const PHRASE = nameOf<OrderQuery>('phrase');

export class OrderQueryParser extends QueryParser {
  static toModel(query: Map<string, string>) {
    const queryModel = new OrderQuery();

    queryModel.phrase = QueryParser.getValue(query, PHRASE);

    return queryModel;
  }

  static toQuery(queryModel: OrderQuery) {
    const query = new Map<string, string>();

    QueryParser.setValue(query, PHRASE, queryModel.phrase);

    return query;
  }
}
