import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { FinalVisualizationBuilder } from '../../../../../app/configurator/VisualizationBuilder';
import { IApiClient } from '../../../../../app/data/client';
import {
  AddWebShopProductToCartCommand,
  ShoppingContext,
  WebShopOptionGroupData,
  WebShopProductDetailsData,
} from '../../../../../app/data/model';
import { ConfiguratorUrlBuilder } from '../../../../../app/errorHandling/ParseConfigurationCodeAndRedirectState';
import { ProductVariationsTranslation } from '../../../../../app/localization/SiteTranslation';
import { Analytics } from '../../../../../app/shared/analytics/Analytics';
import ProductDataConverter from '../../../../../app/shared/analytics/ProductDataConverter';
import { CartEventSource } from '../../../../../app/shared/analytics/TrackingService';
import { AsyncCommand } from '../../../../../app/shared/common';
import ModelViewerState, { ImageDataWithShot } from '../../../../../app/shared/components/ModelViewer/ModelViewerState';
import { IImagePreloader } from '../../../../../app/shared/ImagePreloader';
import { INavigationService } from '../../../../../app/shared/NavigationService';
import Selector from '../../../../../app/shared/Selector';
import { StoreUrl } from '../../../../../app/shared/StoreUrl';
import { AvailableConfiguration } from '../../ProductPageState';
import ProductOptionsState from './ProductOptions/ProductOptionsState';
import ProductOptionVariationState from './ProductOptions/ProductOptionVariation/ProductOptionVariationState';
import ProductVariationsQueryParser from './ProductVariationsQueryParser';

export default class ProductVariationsState {
  @observable
  public quantity: number = 1;

  public addProductToCartCommand: AsyncCommand;
  public modelSelect: Selector<AvailableConfiguration>;
  public modelViewer: ModelViewerState;
  public productOptionsState: ProductOptionsState;

  @computed
  public get codeUrl() {
    return StoreUrl.codeUrl(this.selectedConfigurationCode).toString();
  }

  @computed
  public get selectedConfiguration() {
    return this.modelSelect.active?.data;
  }

  @computed
  public get selectedConfigurationCode() {
    return this.modelSelect.active?.data.code;
  }

  @computed
  private get selectedConfigurationImages() {
    const shots = ['front', 'left', 'back'];
    const configuration = this.modelSelect.active.data;

    return shots.map((shot) => {
      const image: ImageDataWithShot = {
        name: `${configuration.code}`,
        url: FinalVisualizationBuilder.buildUrl(configuration.code, shot),
        shot: shot,
      };

      return image;
    });
  }

  public constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly shoppingContext: ShoppingContext,
    public readonly translation: ProductVariationsTranslation,
    private readonly configurations: AvailableConfiguration[],
    public readonly product: WebShopProductDetailsData,
    options: WebShopOptionGroupData[],
    imagePreloader: IImagePreloader,
  ) {
    makeObservable(this);
    this.addProductToCartCommand = new AsyncCommand(() => this.addProductToCart());

    this.productOptionsState = new ProductOptionsState(
      this.client,
      this.shoppingContext,
      translation.productOptions,
      options,
    );
    this.productOptionsState.items.forEach((option) =>
      option.onActiveVariationChange.subscribe(this.setActiveConfigurationOnOptionChange),
    );

    const initialConfiguration = this.findConfigurationByQuery(this.navigation.currentUrl.query);
    this.modelSelect = new Selector(configurations, initialConfiguration);
    this.modelSelect.changed.subscribe(() => this.onConfigurationChanged());
    this.setActiveOptionsVariations(this.modelSelect.active.data.options);
    this.modelViewer = new ModelViewerState(
      imagePreloader,
      this.selectedConfigurationImages,
      this.translation.modelViewer,
    );

    this.startTrackingConfigurationChanges();
  }

  public async goToConfigurator(): Promise<void> {
    const builder = new ConfiguratorUrlBuilder(this.client);
    const configuratorUrl = await builder.buildFromCode(this.selectedConfigurationCode);
    this.navigation.navigateTo(configuratorUrl);
  }

  @action
  public setQuantity(quantity: number) {
    this.quantity = quantity;
  }

  private async addProductToCart() {
    const command = new AddWebShopProductToCartCommand({
      shoppingContext: this.shoppingContext,
      productId: this.product.id,
      variationCode: this.selectedConfigurationCode,
      quantity: this.quantity,
    });

    await this.client.send(command);

    Analytics.trackAddToCart({
      context: this.shoppingContext,
      source: CartEventSource.WebShop,
      products: [
        ProductDataConverter.fromWebShopProductDetailsData(
          this.product,
          this.selectedConfigurationCode,
          this.selectedConfiguration.productAvailability.price.amount,
          this.quantity,
        ),
      ],
    });

    this.navigation.navigateTo(StoreUrl.checkoutUrl());
  }

  private findConfigurationByCode(code: string) {
    return this.configurations.find((x) => x.code === code);
  }

  private findConfigurationByQuery(query: Map<string, string>) {
    const { code } = ProductVariationsQueryParser.toModel(query);

    return this.findConfigurationByCode(code);
  }

  private findMostMatchingConfiguration(mustHaveOptionVariation?: ProductOptionVariationState) {
    const numberOfOptions = this.productOptionsState.count;
    let numberOfMatchedOptions = 0;
    let mostMatchingConfiguration = null;

    for (const configuration of this.configurations) {
      let numberOfMatchedOptionsInCurrentConfiguration = 0;

      if (mustHaveOptionVariation && !configuration.options.includes(mustHaveOptionVariation.id)) {
        continue;
      }

      this.productOptionsState.items.forEach((option) => {
        const isMatch = configuration.options.includes(option.activeVariation.id);

        if (isMatch) {
          numberOfMatchedOptionsInCurrentConfiguration++;
        }
      });

      if (numberOfMatchedOptionsInCurrentConfiguration <= numberOfMatchedOptions) {
        continue;
      }

      numberOfMatchedOptions = numberOfMatchedOptionsInCurrentConfiguration;
      mostMatchingConfiguration = configuration;

      if (numberOfMatchedOptionsInCurrentConfiguration === numberOfOptions) {
        break;
      }
    }

    return { isFullyMatched: numberOfMatchedOptions === numberOfOptions, mostMatchingConfiguration };
  }

  private onConfigurationChanged() {
    this.modelViewer.setItems(this.selectedConfigurationImages);
  }

  private setActiveConfiguration(configuration: AvailableConfiguration) {
    const toSelect = this.modelSelect.findItem(configuration);

    if (!toSelect) {
      return false;
    }

    this.modelSelect.setActiveItem(toSelect);
    return true;
  }

  @action.bound
  private setActiveConfigurationOnOptionChange(changedOption: ProductOptionVariationState) {
    const { isFullyMatched, mostMatchingConfiguration } = this.findMostMatchingConfiguration(changedOption);

    if (!mostMatchingConfiguration) {
      throw new Error(`Unable to find a fallback configuration for the "${changedOption.id}" option.`);
    }

    const isActiveConfigurationChanged = this.setActiveConfiguration(mostMatchingConfiguration);

    if (isActiveConfigurationChanged && !isFullyMatched) {
      this.setActiveOptionsVariations(mostMatchingConfiguration.options);
    }
  }

  private setActiveOptionsVariations(optionsVariationsIds: string[]) {
    this.productOptionsState.items.forEach((option) => {
      const variationToActivate = option.variations.find((variation) => optionsVariationsIds.includes(variation.id));

      if (variationToActivate) {
        option.setActiveVariation(variationToActivate, false);
        return;
      }

      const notSelectedAccessoryVariation = option.variations.find((x) => x.isNotSelectedAccessory);

      if (notSelectedAccessoryVariation) {
        option.setActiveVariation(notSelectedAccessoryVariation, false);
      }
    });
  }

  private startTrackingConfigurationChanges() {
    autorun(() => {
      const query = ProductVariationsQueryParser.toQuery({ code: this.selectedConfigurationCode });
      this.navigation.setQuery(query);
    });
  }
}
