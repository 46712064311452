import { action, makeObservable, observable } from 'mobx';

export class ModalState {
  @observable visible: boolean;

  private resolveIsOpenedPromise: () => void;

  constructor() {
    makeObservable(this);
    this.visible = false;
  }

  @action.bound open() {
    this.onBeforeOpen();
    this.visible = true;
    this.onAfterOpen();

    return new Promise<void>((resolve) => {
      this.resolveIsOpenedPromise = resolve;
    });
  }

  @action.bound close() {
    this.onBeforeClose();
    this.visible = false;
    this.onAfterClose();
  }

  @action.bound setVisible(isVisible: boolean) {
    this.visible = isVisible;
  }

  protected onBeforeOpen() {
    //
  }

  protected onAfterOpen() {
    //
  }

  protected onBeforeClose() {
    //
  }

  protected onAfterClose() {
    if (this.resolveIsOpenedPromise) {
      this.resolveIsOpenedPromise();
    }
  }
}
