import * as React from 'react';

import AppState from '../../AppState';
import { EmbeddedQuery } from '../../shared/EmbeddedQueryParser';
import Footer from '../Footer/Footer';
import Topbar from '../Topbar';
import css from './MainLayout.css';

export interface LayoutProps {
  appState: AppState;
  children: React.ReactElement;
  embeddedQuery?: EmbeddedQuery;
}

interface MainLayoutProps extends LayoutProps {}

export default class MainLayout extends React.Component<MainLayoutProps, {}> {
  render() {
    const {
      appState,
      children,
      embeddedQuery: { embed },
    } = this.props;
    const showFooter = !appState.store.isShowroom();

    if (embed) {
      return children;
    }

    return (
      <div className={css.MainLayout}>
        <Topbar appState={appState} translation={appState.translation.layout.topBar} />
        <div className={css.content}>{children}</div>
        {showFooter && (
          <Footer translation={appState.translation.layout.footerPanel} data={appState.navigationState.footer} />
        )}
      </div>
    );
  }
}
