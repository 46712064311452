import { BaseVisualizationData, Visualization3dData } from '../data/model';
import { IImagePreloader } from '../shared/ImagePreloader';
import { VisualizationMode } from '../shared/visualization';
import { IImageBasedVisualizationState } from './IVisualizationState';
import { Visualization3dState } from './Visualization3dState';
import { VisualizationHybridState } from './VisualizationHybridState';
import { VisualizationState } from './VisualizationState';

export class VisualizationStateFactory {
  constructor(private readonly imagePreloader: IImagePreloader) {}

  public create(visualization: BaseVisualizationData): IImageBasedVisualizationState<BaseVisualizationData> {
    if (visualization.mode === VisualizationMode.Hybrid) {
      return new VisualizationHybridState(
        new Visualization3dState(visualization as Visualization3dData),
        this.imagePreloader,
      );
    } else {
      return new VisualizationState(this.imagePreloader);
    }
  }
}
