import classNames from 'classnames';
import { Component, MouseEvent } from 'react';

import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import css from './TextInfo.css';

interface TextInfoProps {
  className?: string;
  buttonClassName?: string;
  children?: JSX.Element | string;
  onClick?: (e?: MouseEvent<{}>) => void;
  showInfo?: boolean;
}

class TextInfo extends Component<TextInfoProps, {}> {
  render() {
    const { className, buttonClassName, children, onClick, showInfo } = this.props;

    return (
      <p className={classNames(css.TextInfo, className)}>
        <span>{children}</span>
        {showInfo && (
          <span className={css.noWrap}>
            &nbsp;
            <IconButton
              className={classNames(css.button, buttonClassName)}
              onClick={onClick}
              icon={<Icon name="info" className={css.icon} />}
            />
          </span>
        )}
      </p>
    );
  }
}

export default TextInfo;
