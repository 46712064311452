import { Component } from 'react';

import { AppLoaderTranslation } from '../../localization/SiteTranslation';
import LogoFlokkHub from '../../menu/LogoFlokkHub/LogoFlokkHub';
import css from './AppLoader.css';

interface AppLoaderProps {
  translation: AppLoaderTranslation;
}

export default class AppLoader extends Component<AppLoaderProps, {}> {
  render() {
    return (
      <div className={css.AppLoader}>
        <div className={css.container}>
          <LogoFlokkHub size="extraLarge" />
          <div className={css.loaderText}>{this.props.translation.loaderText}</div>
        </div>
      </div>
    );
  }
}
