import classNames from 'classnames';
import { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { ScrollLock } from '../ScrollLock/ScrollLock';
import css from './Lightbox.css';
import LightboxToolbar from './LightboxToolbar/LightboxToolbar';

ReactModal.setAppElement('#App');

interface LightboxProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  onAfterOpen?: () => void;
  onDownload?: () => void;
}

const Lightbox = ({ children, className, isOpen, onAfterOpen, onClose, onDownload }: LightboxProps) => {
  return (
    <ScrollLock isLocked={isOpen}>
      <ReactModal
        className={classNames(css.inner, className)}
        overlayClassName={css.overlay}
        isOpen={isOpen}
        onRequestClose={onClose}
        onAfterOpen={onAfterOpen}
      >
        <LightboxToolbar className={css.toolbar} onClose={onClose} onDownload={onDownload} />
        <div className={css.content}>{children}</div>
      </ReactModal>
    </ScrollLock>
  );
};

export default Lightbox;
