import { AssetCategoryData, AssetData } from '../../../data/model';
import { DownloadsSectionTranslation } from '../../../localization/SiteTranslation';
import Accordion from '../Accordion/Accordion';
import ActionButton from '../ActionButton';
import IconButton from '../Button/IconButton';
import Icon from '../Icon/Icon';
import List from '../List/List';
import css from './AssetsDownloader.css';
import { AssetsDownloaderState } from './AssetsDownloaderState';
import DownloadAssetLink from './DownloadAssetLink/DownloadAssetLink';

interface AssetsDownloaderProps {
  state: AssetsDownloaderState;
  translation: DownloadsSectionTranslation;
}

const AssetsDownloader = ({ state, translation }: AssetsDownloaderProps) => {
  const renderAsset = (asset: AssetData) => (
    <DownloadAssetLink name={asset.name} url={asset.url} onClick={state.trackDownload} />
  );

  const renderCategory = (category: AssetCategoryData) => {
    const { getCategoryName } = state;

    const categoryName = category.categoryName === '' ? translation.other : getCategoryName(category);

    return (
      category.assets.any() && (
        <Accordion
          title={categoryName}
          titleExpandedClassName={css.accordionExpandedTitle}
          titleClassName={css.accordionTitle}
        >
          <List items={category.assets} itemRenderer={renderAsset} />
        </Accordion>
      )
    );
  };

  return (
    <div className={css.AssetsDownloader}>
      <List className={css.categories} items={state.categories} itemRenderer={renderCategory} />
      <div className={css.downloadAll}>
        <ActionButton
          className={css.button}
          command={state.downloadAllCommand}
          buttonElement={IconButton}
          externalButtonProps={{
            nameClassName: css.name,
            loaderClassName: css.loader,
            icon: <Icon name="download" className={css.icon} />,
          }}
        >
          {translation.downloadAllButton}
        </ActionButton>
      </div>
    </div>
  );
};

export default AssetsDownloader;
