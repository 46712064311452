import { observer } from 'mobx-react';
import { Component } from 'react';

import Link from '../../../../../app/shared/components/Link';
import ModelViewer from '../../../../../app/shared/components/ModelViewer/ModelViewer';
import ConfigurationSummary from './ConfigurationSummary/ConfigurationSummary';
import ProductInfo from './ProductInfo/ProductInfo';
import ProductOptions from './ProductOptions/ProductOptions';
import css from './ProductVariations.css';
import ProductVariationsState from './ProductVariationsState';

interface ProductVariationsProps {
  state: ProductVariationsState;
  enableCustomisation: boolean;
  enablePurchase: boolean;
}

@observer
export default class ProductVariations extends Component<ProductVariationsProps> {
  render() {
    const {
      enableCustomisation,
      enablePurchase,
      state: { modelViewer, productOptionsState, product, modelSelect, translation },
    } = this.props;
    const { customizationSection } = translation;

    return (
      <section className={css.ProductVariations}>
        {modelViewer.items.any() && (
          <div className={css.containerModelViewer}>
            <ModelViewer className={css.modelViewer} imageClassName={css.image} state={modelViewer} />
          </div>
        )}
        <div className={css.containerSelectModel}>
          <ProductInfo title={product.name} description={product.description} />
          <ProductOptions className={css.options} state={productOptionsState} />
          <ConfigurationSummary
            state={this.props.state}
            productAvailability={modelSelect.active.data.productAvailability}
            enablePurchase={enablePurchase}
            translation={translation}
          />
          {enableCustomisation && (
            <div className={css.customizationInfo}>
              <p>{customizationSection.textLine1}</p>
              <p>
                {customizationSection.textLine2}{' '}
                <Link href={this.props.state.codeUrl} onClick={() => this.props.state.goToConfigurator()}>
                  {customizationSection.linkText}
                </Link>
                {customizationSection.textLine2End && ` ${customizationSection.textLine2End}`}
              </p>
            </div>
          )}
        </div>
      </section>
    );
  }
}
