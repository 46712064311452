import { Color3, HighlightLayer, Mesh, Scene } from '@babylonjs/core';

import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from './MeshPresenter';

export class SelectionPresenter implements IMeshPresenter {
  private highlightLayer: HighlightLayer;
  private lastSceneUser: Scene; // TODO: simple workaround, we can remove it when presenters are initialized in the context of the scene

  update(scene: Scene, mesh: Mesh, object: ObjectState): void {
    if (this.highlightLayer == null || this.lastSceneUser !== scene) {
      this.highlightLayer = new HighlightLayer('HighlightSelected', scene);
      this.highlightLayer.innerGlow = false;
      this.lastSceneUser = scene;
    }

    if (object.selected) {
      this.highlightLayer.addMesh(mesh as Mesh, Color3.FromHexString('#32a98f'));
    } else {
      this.highlightLayer.removeMesh(mesh as Mesh);
    }
  }
}
