import classNames from 'classnames';

import Link from '../../../../shared/components/Link';
import css from './FooterLink.css';

interface FooterLinkProps {
  children: string;
  url: string;
}

const FooterLink = ({ children, url }: FooterLinkProps) => {
  return (
    <Link className={classNames(css.link)} href={url}>
      {children}
    </Link>
  );
};

export default FooterLink;
