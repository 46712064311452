import classNames from 'classnames';
import { ReactNode } from 'react';

import { DeliveryAddressData, OrderData } from '../../../app/data/model';
import { TextLabelColored } from '../../../app/shared/components/Typography/TextLabels';
import CartSummary from '../../cart/CartSummary/CartSummary';
import { OrderDetailsPanelTranslation } from '../../localization/SiteTranslation';
import css from './OrderDetailsPanel.css';

interface OrderDetailsPanelProps {
  order: OrderData;
  translation: OrderDetailsPanelTranslation;
}

export const OrderDetailsPanel = ({
  order: { deliveryAddress, subTotalTax, subTotal, subTotalWithTax, taxRatePercentage, totalWithTax, customerName },
  translation,
}: OrderDetailsPanelProps) => (
  <div className={css.container}>
    <div className={css.inner}>
      {deliveryAddress.recipient && (
        <PanelColumn label={translation.deliveryAddressLabel}>
          <AddressDetails address={deliveryAddress} />
        </PanelColumn>
      )}

      <PanelColumn label={translation.buyerLabel}>{customerName}</PanelColumn>

      <PanelColumn label={translation.summaryLabel} wide>
        <CartSummary
          shoppingCart={{
            subTotal,
            subTotalTax,
            subTotalWithTax,
            taxRatePercentage,
            totalWithTax,
          }}
          translation={translation.summary}
        />
      </PanelColumn>
    </div>
  </div>
);

const PanelColumn = ({ label, children, wide }: { label: string; children: ReactNode; wide?: boolean }) => (
  <div className={classNames(css.panelColumn, { [css.wide]: wide })}>
    <TextLabelColored color="darkGrey" className={css.panelColumnLabel}>
      {label}
    </TextLabelColored>
    {children}
  </div>
);

const AddressDetails = ({ address }: { address: DeliveryAddressData }) => (
  <>
    <div>{address.recipient}</div>
    <div>
      {address.line1} {address.line2}
    </div>
    <div>
      {address.zipCode} {address.city}
    </div>
    <div>{address.country?.name}</div>
  </>
);
