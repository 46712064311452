import { FinalVisualizationBuilder } from '../../../../configurator/VisualizationBuilder';
import { ImageData, ProductFamilyAssetsData } from '../../../../data/model';
import { AsyncCommand } from '../../../../shared/common';

export interface IDownloadState {
  downloadCommand: AsyncCommand;
  id: string;
  image: ImageData;
  title: string;
  subtitle: string;
}

export class DownloadState implements IDownloadState {
  public downloadCommand: AsyncCommand;
  public id: string;
  public image: ImageData;
  public subtitle: string;
  public title: string;

  private code: string;

  constructor(data: ProductFamilyAssetsData, actionCallback: (id: string) => Promise<void>) {
    this.setData(data);
    this.downloadCommand = new AsyncCommand(() => actionCallback(this.id));
  }

  private setData(data: ProductFamilyAssetsData) {
    this.code = data.modelCode;
    this.id = data.id;

    this.image = {
      name: data.name,
      url: this.code ? FinalVisualizationBuilder.buildUrl(this.code) : data.modelImage?.url,
    };

    this.subtitle = data.shortName;
    this.title = data.brandName;
  }
}
