export default function brandCssClassResolver<T extends object>(classes: T, brandName: string): T[keyof T] | undefined {
  if (!brandName) {
    return;
  }

  let brand = brandName.toLocaleLowerCase().trim();

  if (brand === 'håg') {
    brand = 'haag';
  }

  if (brand in classes) {
    return classes[brand as keyof T];
  }
}
