import { ModalVideoPlayer } from '../../../shared/components/ModalVideoPlayer/ModalVideoPlayer';
import HeaderLevel2 from '../../../shared/components/Typography/HeaderLevel2';
import css from './ProductPitch.css';

type ProductPitchProps = {
  description: string;
  id: string;
  posterUrl: string;
  title: string;
  videoTitle: string;
  videoUrl: string;
};

const ProductPitch = ({ description, id, posterUrl, title, videoTitle, videoUrl }: ProductPitchProps) => {
  return (
    <div className={css.ProductPitch}>
      <div className={css.inner}>
        {(title || description) && (
          <div className={css.text}>
            {title && <HeaderLevel2 className={css.title}>{title}</HeaderLevel2>}
            {description && <p className={css.description}>{description}</p>}
          </div>
        )}
        <div className={css.videoWrapper}>
          <ModalVideoPlayer id={id} title={videoTitle} imageUrl={posterUrl} videoUrl={videoUrl} />
        </div>
      </div>
    </div>
  );
};

export default ProductPitch;
