import { Mesh, Scene, Vector3 } from '@babylonjs/core';

import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from './MeshPresenter';

export class PositionPresenter implements IMeshPresenter {
  update(scene: Scene, mesh: Mesh, object: ObjectState): void {
    mesh.position = new Vector3(object.position.x, object.position.y, object.position.z);
  }
}
