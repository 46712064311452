import { StoreAndLanguageSelectorTranslation } from '../../localization/SiteTranslation';
import StoreSelectionState, { LanguageItem, StoreWithUniqueKey } from '../StoreSelectionState';

class StoreAndLanguageSelectorState {
  public readonly currentStoreLanguages: LanguageItem[];

  public get currentLanguage() {
    return this.storeSelection.currentLanguage;
  }

  public get currentStore() {
    return this.storeSelection.currentStore;
  }

  public get isCurrentStoreMultilingual() {
    return this.currentStoreLanguages.length > 1;
  }

  public get stores() {
    return this.storeSelection.stores;
  }

  public constructor(
    private readonly storeSelection: StoreSelectionState,
    public readonly translation: StoreAndLanguageSelectorTranslation,
  ) {
    this.currentStoreLanguages = this.getLanguagesByStore(this.currentStore);
  }

  public getLanguagesByStore(store: StoreWithUniqueKey) {
    return this.storeSelection.getLanguages(store.key);
  }

  public isLanguageCurrent(language: LanguageItem) {
    return language.code === this.currentLanguage.code;
  }

  public isStoreCurrent(store: StoreWithUniqueKey) {
    return store.key === this.currentStore.key;
  }

  public select = (store: StoreWithUniqueKey, language: LanguageItem) => {
    this.storeSelection.selectStore(store.key, language.code);
  };

  public selectLanguage = (language: LanguageItem) => {
    this.storeSelection.selectStore(this.storeSelection.currentStore.key, language.code);
  };

  public selectStore = (store: StoreWithUniqueKey) => {
    this.storeSelection.selectStore(store.key, store.languages.first().code);
  };
}

export default StoreAndLanguageSelectorState;
