import classNames from 'classnames';
import { Component } from 'react';

import { BaseModelState } from '../../../app/catalog/CatalogPageState';
import Icon from '../../../app/shared/components/Icon/Icon';
import Image from '../../../app/shared/components/Image/Image';
import { FamilyItemAvailability } from '../FamilyItemAvailabilityModal/FamilyItemAvailabilityModalState';
import css from './FamilyItem.css';

interface FamilyItemProps {
  item: BaseModelState;
  openAvailabilityModal: (availability: FamilyItemAvailability) => void;
}

export default class FamilyItem extends Component<FamilyItemProps, {}> {
  constructor(props: FamilyItemProps) {
    super(props);

    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  get isConfiguratorAvailable() {
    return this.props.item.predefinedConfiguration?.code;
  }

  handleInfoButtonClick() {
    const availability = this.isConfiguratorAvailable
      ? FamilyItemAvailability.AvailableOnlyForConfiguration
      : FamilyItemAvailability.Unavailable;

    this.props.openAvailabilityModal(availability);
  }

  handleOnClick() {
    this.props.item.goToConfiguratorCommand.invoke();
  }

  render() {
    const { item } = this.props;

    return (
      <article className={classNames(css.FamilyItem, !this.isConfiguratorAvailable && css.disabled)}>
        {!item.canBePurchased && (
          <button className={css.infoButton} type="button" onClick={this.handleInfoButtonClick}>
            <Icon name="info" className={css.icon} />
          </button>
        )}

        <h3 className={css.productDetails}>
          {this.isConfiguratorAvailable ? (
            <button className={css.actionButton} type="button" onClick={this.handleOnClick}>
              {item.name}
            </button>
          ) : (
            item.name
          )}
        </h3>

        <div className={css.imageContainer}>
          <Image
            className={css.image}
            classNameUnloaded={css.unloaded}
            src={item.imageUrl}
            width={160}
            height={200}
            sharpeningFactor={1.4}
            size="contain"
          />
        </div>
      </article>
    );
  }
}
