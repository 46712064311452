import ConfigurationCodeShare from '../../../../../../configurator/components/ConfigurationShare/ConfigurationCodeShare/ConfigurationCodeShare';
import ConfigurationDownload from '../../../../../../configurator/components/ConfigurationShare/ConfigurationDownload/ConfigurationDownload';
import ConfigurationUrlShare from '../../../../../../configurator/components/ConfigurationShare/ConfigurationUrlShare/ConfigurationUrlShare';
import { ShareConfigurationSectionTranslation } from '../../../../../../localization/SiteTranslation';
import Heading, { HeadingSize } from '../../../../../../shared/components/Typography/Heading/Heading';
import ModularConfiguratorSummaryState from '../ModularConfiguratorSummaryState';
import css from './ModularConfigurationShare.css';

interface ModularConfigurationShareProps {
  state: ModularConfiguratorSummaryState;
  // TODO: Extract to new interface
  translation: ShareConfigurationSectionTranslation;
  className?: string;
}

const ModularConfigurationShare = ({ className, state, translation }: ModularConfigurationShareProps) => {
  return (
    <section className={className}>
      <Heading className={css.title} level={3} size={HeadingSize.Small}>
        {translation.title}
      </Heading>
      <ConfigurationCodeShare
        classNames={{ root: css.section }}
        code={state.configurationCode}
        isCodeGenerationError={state.isCodeGenerationError}
        isCodeLoading={state.isCodeGenerationInProgress}
        translation={translation.codeShare}
      />
      <ConfigurationUrlShare
        className={css.section}
        isCodeLoading={state.isCodeGenerationInProgress}
        translation={translation}
        url={state.shareUrl}
      />
      <ConfigurationDownload
        className={css.section}
        filename={state.pdfName}
        downloadCommand={state.downloadAsPdfCommand}
        icon="pdf"
        label={translation.downloadAsPdf}
        disabled={state.isCodeGenerationInProgress}
      />
      <ConfigurationDownload
        className={css.section}
        filename={state.zipName}
        downloadCommand={state.downloadZipCommand}
        icon="zip"
        label={translation.downloadVisualizations}
        disabled={state.isCodeGenerationInProgress}
      />
    </section>
  );
};

export default ModularConfigurationShare;
