const CountryCode = {
  Austria: 'AUT',
  Denmark: 'DNK',
  France: 'FRA',
  Germany: 'DEU',
  Ireland: 'IRL',
  Netherlands: 'NLD',
  Norway: 'NOR',
  Poland: 'POL',
  Sweden: 'SWE',
  Switzerland: 'CHE',
  UnitedKingdom: 'GBR',
};

export default CountryCode;
