import { FavoritesSummaryData } from '../../../app/data/model';
import { IEvent } from '../../../app/shared/EventAggregator';

export class FavoritesUpdatedEvent implements IEvent {
  constructor(public readonly favoriteConfigurationCodes: string[]) {}

  public static fromFavoritesSummary(summary: FavoritesSummaryData): FavoritesUpdatedEvent {
    return new FavoritesUpdatedEvent(summary.configurationCodes);
  }
}
