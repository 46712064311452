import classNames from 'classnames';

import { PriceConsumer } from '../../context/PriceContext';
import { MoneyData } from '../../data/model';
import { CurrencyFormatter, PriceFormatter } from '../common';
import css from './Price.css';

interface PriceComponentProps {
  code: string;
  shouldDisplayDecimalPart: boolean;
  value: number;
  className?: string;
  taxSuffix?: string;
}

const PriceComponent = ({ code, shouldDisplayDecimalPart, value, className, taxSuffix }: PriceComponentProps) => {
  const currencyFormat = CurrencyFormatter.getFormat(code);
  const amount = PriceFormatter.format(value, shouldDisplayDecimalPart);

  return (
    <span className={classNames(css.container, className)}>
      <span className={css.value}>
        {currencyFormat.prefix && `${currencyFormat.symbol} `}
        {amount}
        {!currencyFormat.prefix && ` ${currencyFormat.symbol}`}
      </span>
      <wbr />
      {taxSuffix && <span className={css.taxSuffix}>{taxSuffix}</span>}
    </span>
  );
};

interface PriceProps {
  price: MoneyData;
  className?: string;
  taxSuffix?: string;
}

const Price = ({ price, className, taxSuffix }: PriceProps) => {
  return (
    <PriceConsumer>
      {({ enable, displayDecimalPart }) =>
        enable && (
          <PriceComponent
            code={price.currency.code}
            shouldDisplayDecimalPart={displayDecimalPart}
            value={price.amount}
            className={className}
            taxSuffix={taxSuffix}
          />
        )
      }
    </PriceConsumer>
  );
};

export default Price;
