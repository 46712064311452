import classNames from 'classnames';
import { ImgHTMLAttributes, ReactNode, useCallback, useEffect, useMemo } from 'react';

import useLoadingStatus from '../../hooks/useLoadingStatus';
import { ImageUrlBuilder } from '../../ImageUrlBuilder';
import DefaultImage from '../DefaultImage/DefaultImage';
import css from './Image.css';

interface ImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'placeholder' | 'onError' | 'onLoad'> {
  src: string;
  classNameError?: string;
  classNameFallbackElement?: string;
  classNameImageElement?: string;
  classNameUnloaded?: string;
  height?: number;
  placeholder?: ReactNode;
  sharpeningFactor?: number;
  width?: number;
  size?: 'auto' | 'contain' | 'cover';
}

const Image = ({
  className,
  classNameError,
  classNameFallbackElement,
  classNameImageElement,
  classNameUnloaded,
  height,
  onClick,
  placeholder,
  sharpeningFactor,
  src,
  width,
  alt = '',
  size = 'auto',
  ...props
}: ImageProps) => {
  const isCustomPlaceholder = placeholder !== undefined;

  const { isError, isLoaded, isLoading, setError, setInProgress, setSuccess } = useLoadingStatus();

  const url = useMemo<string>(
    () => ImageUrlBuilder.build(src, width, height, sharpeningFactor),
    [height, sharpeningFactor, src, width],
  );

  const handleImageLoadingStatus = useCallback((imageElement: HTMLImageElement) => {
    if (!isLoading || !imageElement) {
      return;
    }

    if (imageElement.complete) {
      if (imageElement.naturalHeight > 0) {
        setSuccess();
      } else {
        setError();
      }
    }

    imageElement.onload = setSuccess;
    imageElement.onerror = setError;
  }, []);

  useEffect(() => {
    if (url && isError) {
      setInProgress();
    }
  }, [url]);

  return (
    <div
      className={classNames(
        css.wrapper,
        {
          [css.withPlaceholder]: isLoading && !isCustomPlaceholder,
          [classNameUnloaded]: !isLoaded,
          [classNameError]: isError,
        },
        className,
      )}
    >
      {isError ? (
        <DefaultImage className={classNameFallbackElement} />
      ) : (
        <>
          <img
            ref={handleImageLoadingStatus}
            className={classNames(
              css.element,
              {
                [css.contain]: size === 'contain',
                [css.cover]: size === 'cover',
              },
              classNameImageElement,
            )}
            src={url}
            alt={alt}
            onClick={onClick}
            {...props}
          />
          {isLoading && placeholder}
        </>
      )}
    </div>
  );
};

export default Image;
