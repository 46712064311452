import classNames from 'classnames';
import { observer } from 'mobx-react';

import { ModularLayoutBasicData } from '../../../../../../data/model';
import Image from '../../../../../../shared/components/Image/Image';
import { SelectorItem } from '../../../../../../shared/Selector';
import css from './LayoutPickerItem.css';

interface LayoutPickerItemProps {
  state: SelectorItem<ModularLayoutBasicData>;
  onClick: () => void;
  disabled?: boolean;
}

const LayoutPickerItem = ({
  state: {
    data: { name, thumbnail },
    isActive,
  },
  disabled,
  onClick,
}: LayoutPickerItemProps) => {
  return (
    <div className={css.container}>
      <button
        type="button"
        className={classNames(css.button, {
          [css.active]: isActive,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <Image
          className={css.image}
          classNameError={css.imageError}
          src={thumbnail.url}
          alt={name}
          width={thumbnail.size?.width ?? 256}
          height={thumbnail.size?.height ?? 256}
          size="contain"
          loading="lazy"
        />
        <span className={css.title} aria-hidden={true}>
          {name}
        </span>
      </button>
    </div>
  );
};

export default observer(LayoutPickerItem);
