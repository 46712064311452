import { FooterData } from '../../data/model';
import { FooterPanelTranslation } from '../../localization/SiteTranslation';
import DeprecatedFooter from '../DeprecatedFooter/DeprecatedFooter';
import ContactSection from './ContactSection/ContactSection';
import Copyright from './Copyright/Copyright';
import css from './Footer.css';
import LinkList from './LinkList/LinkList';
import PaymentOptionList from './PaymentOptionList/PaymentOptionList';
import SocialMediaList from './SocialMediaList/SocialMediaList';

interface FooterProps {
  data: FooterData;
  // TODO: Remove after footer update in all stores
  translation: FooterPanelTranslation;
}

const Footer = ({ data, translation }: FooterProps) => {
  return data.displayNewFooter ? (
    <footer className={css.container}>
      <div className={css.inner}>
        <LinkList className={css.linkList} items={data.links} />
        <SocialMediaList
          className={css.socialMedia}
          blogLink={data.socialMedia.blogLink}
          facebookLink={data.socialMedia.facebookLink}
          instagramLink={data.socialMedia.instagramLink}
          linkedInLink={data.socialMedia.linkedInLink}
          pinterestLink={data.socialMedia.pinterestLink}
          followUsText={data.socialMedia.followUsText}
        />
        <ContactSection
          className={css.contact}
          address={data.address}
          emailAddress={data.emailAddress}
          phoneNumber={data.phoneNumber}
        />
        <div className={css.end}>
          <Copyright
            className={css.copyright}
            allRightsReservedText={data.copyright.allRightsReservedText}
            copyrightNoticeText={data.copyright.copyrightNoticeText}
          />
          {data.paymentInformation && <PaymentOptionList items={data.paymentInformation.options} />}
        </div>
      </div>
    </footer>
  ) : (
    // TODO: Remove after footer update in all stores
    <DeprecatedFooter translation={translation} data={data} />
  );
};

export default Footer;
