import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import {
  AddCouponToCartCommand,
  AddProductToCartCommand,
  GetConfigurationByCodeQuery,
  ProductConfigurationData,
  ShoppingContext,
} from '../data/model';
import { CheckoutShortcutPageTranslation, ConfigurationFinderTranslation } from '../localization/SiteTranslation';
import { BasePageState } from '../shared/BasePageState';
import { ILoadingIndicator } from '../shared/LoadingIndicator';
import Logger from '../shared/Logger';
import { INavigationService } from '../shared/NavigationService';
import { StatusCodes } from '../shared/StatusCodes';
import { StoreUrl } from '../shared/StoreUrl';
import { StoreState } from '../StoreState';
import CheckoutShortcutPageQueryParser from './ICheckoutShortcutPageQuery';
import { ICheckoutShortcutPageQuery } from './ICheckoutShortcutPageQuery';

export interface ICheckoutShortcutStateMemento {
  shoppingContext: ShoppingContext;
}

export class CheckoutShortcutState extends BasePageState<ICheckoutShortcutStateMemento> {
  @observable
  public statusCode: number = 200;

  @observable
  public errorMessage: string;

  private shoppingContext: ShoppingContext;

  constructor(
    private readonly code: string,
    private readonly translation: CheckoutShortcutPageTranslation,
    private readonly configurationFinderTranslation: ConfigurationFinderTranslation,
    private readonly navigation: INavigationService,
    private readonly client: IApiClient,
    private loadingIndicator: ILoadingIndicator,
  ) {
    super();
    makeObservable(this);
  }

  @action
  async onLoad(store: StoreState) {
    this.initialize(store.shoppingContext);
    return Promise.resolve();
  }

  @action
  async onLoadAdditionalData() {
    try {
      this.loadingIndicator.start();

      const productConfiguration = await this.getProductConfiguration();
      if (!productConfiguration) {
        return;
      }

      const query = CheckoutShortcutPageQueryParser.toModel(this.navigation.currentUrl.query);
      productConfiguration.quantity = this.getQuantity(query);

      const productAddedToCart = await this.addProductConfigurationToCart(productConfiguration);
      if (!productAddedToCart) {
        return;
      }

      await this.addCouponCodeWhenDefined(query);

      this.navigation.navigateTo(StoreUrl.checkoutUrl());
    } finally {
      this.loadingIndicator.stop();
    }
  }

  private async getProductConfiguration() {
    try {
      const configurationResponse = await this.client.send(new GetConfigurationByCodeQuery({ code: this.code }));
      return configurationResponse.productConfiguration;
    } catch (error) {
      if (error.status === StatusCodes.NotFound) {
        this.setErrorMessage(
          StatusCodes.NotFound,
          this.configurationFinderTranslation.codeNotFound.interpolate([['notFoundCode', this.code]]),
        );
      } else if (error.status === StatusCodes.BadRequest) {
        this.setErrorMessage(
          StatusCodes.BadRequest,
          this.configurationFinderTranslation.codeInvalid.interpolate([['invalidCode', this.code]]),
        );
      } else {
        Logger.exception(`Error when getting product configuration for code = '${this.code}'`, error);
        this.setErrorMessage(
          StatusCodes.InternalServerError,
          this.translation.errors.gettingProductConfigurationFailed.interpolate([['code', this.code]]),
        );
      }
    }

    return undefined;
  }

  private async addProductConfigurationToCart(productConfiguration: ProductConfigurationData) {
    try {
      await this.client.send(
        new AddProductToCartCommand({
          shoppingContext: this.shoppingContext,
          productConfiguration: productConfiguration,
          ignorePriceGroupValidation: true,
        }),
      );
      return true;
    } catch (error) {
      Logger.exception(`Error when adding product configuration to cart, configuration code = '${this.code}'`, error);
      this.setErrorMessage(
        error.status ?? StatusCodes.InternalServerError,
        this.translation.errors.addingProductToCartFailed.interpolate([['code', this.code]]),
      );
      return false;
    }
  }

  private async addCouponCodeWhenDefined(query: ICheckoutShortcutPageQuery) {
    if (query.couponCode) {
      try {
        const addCouponResponse = await this.client.send(
          new AddCouponToCartCommand({ shoppingContext: this.shoppingContext, couponCode: query.couponCode }),
        );

        if (!addCouponResponse.couponApplied) {
          Logger.exception(`Coupon code '${query.couponCode}' wasn't applied. It's might wrong or not valid anymore.`);
        }
      } catch (error) {
        Logger.exception(`Error when adding coupon code '${query.couponCode}'`, error);
      }
    }
  }

  private getQuantity(query: ICheckoutShortcutPageQuery): number {
    return query.quantity ? query.quantity : 1;
  }

  private initialize(context: ShoppingContext) {
    this.shoppingContext = context;
  }

  getMemento(): ICheckoutShortcutStateMemento {
    return {
      shoppingContext: this.shoppingContext,
    };
  }

  restoreMemento(memento: ICheckoutShortcutStateMemento) {
    this.initialize(memento.shoppingContext);
  }

  @action
  private setErrorMessage(statusCode: number, message: string) {
    this.statusCode = statusCode;
    this.errorMessage = message;
    this.loadingIndicator.stop();
  }
}
