import css from './ProductModelPanelHeader.css';

interface ProductModelPanelHeaderProps {
  description: string;
  title: string;
}

const ProductModelPanelHeader = ({ description, title }: ProductModelPanelHeaderProps) => {
  return (
    <div className={css.content}>
      {title}
      {description && <div className={css.secondary}>{description}</div>}
    </div>
  );
};

export default ProductModelPanelHeader;
