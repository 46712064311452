import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';

import { StoreLocatorInputTranslation } from '../../../localization/SiteTranslation';
import IconButton from '../../../shared/components/Button/IconButton';
import Icon from '../../../shared/components/Icon/Icon';
import List from '../../../shared/components/List/List';
import { CurrentLocationTextButton } from './CurrentLocationTextButton/CurrentLocationTextButton';
import SearchInput from './SearchInput/SearchInput';
import css from './StoreLocatorInput.css';
import StoreLocatorInputState from './StoreLocatorInputState';

interface StoreLocatorInputProps {
  state: StoreLocatorInputState;
  translation: StoreLocatorInputTranslation;
}

@observer
export default class StoreLocatorInput extends Component<StoreLocatorInputProps> {
  renderGeolocationHint() {
    const { state, translation } = this.props;
    const { isGeolocationInactive, isGeolocationLoading, isGeolocationUnavailable } = state;

    const geolocationErrorText = isGeolocationUnavailable
      ? translation.unavailableGeolocationText
      : translation.disabledGeolocationText;

    const geolocationInactiveHint = (
      <Fragment>
        <span>{geolocationErrorText} </span>
        {isGeolocationUnavailable && (
          <CurrentLocationTextButton
            onClick={() => state.getToCurrentLocation()}
            isLoading={isGeolocationLoading}
            className={css.tryAgain}
          >
            {translation.tryAgainText}
          </CurrentLocationTextButton>
        )}
      </Fragment>
    );

    const geolocationHint = (
      <Fragment>
        <span className={classNames(css.or, isGeolocationLoading && css.hide)}>{translation.orText} </span>
        <CurrentLocationTextButton onClick={() => state.getToCurrentLocation()} isLoading={isGeolocationLoading}>
          {translation.currentLocationText}
        </CurrentLocationTextButton>
      </Fragment>
    );

    return isGeolocationInactive ? geolocationInactiveHint : geolocationHint;
  }

  renderAutocompleteInput() {
    const { state, translation } = this.props;
    const { isGeolocationInactive, isGeolocationLoading, isGeolocationClickable } = state;

    return (
      <PlacesAutocomplete
        value={state.locationSearch}
        onChange={(s: string) => state.setSearchString(s)}
        onSelect={(x) => state.autocompleteSelect(x)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          const inputProps = { ...getInputProps({}) };

          const iconName = isGeolocationInactive ? 'gpsDisabled' : 'gpsEmpty';
          const iconClasses = classNames(css.icon, isGeolocationLoading && css.isLoading);
          const renderSuggestion = (suggestion: Suggestion) => {
            const className = classNames(css.suggestion, suggestion.active && css.active);
            return (
              <div {...getSuggestionItemProps(suggestion, { className })}>
                <span>{suggestion.description}</span>
              </div>
            );
          };

          return (
            <div className={css.StoreLocatorInput}>
              <div className={css.inputButton}>
                <SearchInput
                  type="text"
                  label={translation.label}
                  value={state.locationSearch}
                  placeholder={translation.placeholder}
                  isLoading={loading}
                  className={css.inputContainer}
                  inputClassName={css.input}
                  {...inputProps}
                />
                <List items={suggestions.concat()} itemRenderer={renderSuggestion} className={css.dropdown} />
                <div className={css.currentLocation}>
                  <IconButton
                    icon={<Icon name={iconName} className={iconClasses} />}
                    onClick={() => state.getToCurrentLocation()}
                    disabled={!isGeolocationClickable}
                    className={css.button}
                    isLoading={isGeolocationLoading}
                    loaderClassName={css.iconButtonLoader}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }

  render() {
    const { state, translation } = this.props;

    return (
      <Fragment>
        {state.isGoogleApiInitialized ? (
          this.renderAutocompleteInput()
        ) : (
          // We show fake input when autocomplete component is not initialized
          <SearchInput label={translation.label} type="text" placeholder={translation.placeholder} />
        )}
        <div className={css.currentLocationContainer}>{this.renderGeolocationHint()}</div>
      </Fragment>
    );
  }
}
