import classNames from 'classnames';
import { observer } from 'mobx-react';

import LanguageSelector from './LanguageSelector/LanguageSelector';
import css from './StoreAndLanguageSelector.css';
import StoreAndLanguageSelectorState from './StoreAndLanguageSelectorState';
import StoreSelector from './StoreSelector/StoreSelector';

interface StoreAndLanguageSelectorProps {
  state: StoreAndLanguageSelectorState;
  className?: string;
}

const StoreAndLanguageSelector = ({ className, state }: StoreAndLanguageSelectorProps) => {
  return (
    <div className={classNames(css.container, className)}>
      {state.isCurrentStoreMultilingual && (
        <LanguageSelector
          className={css.dropdown}
          activeItem={state.currentLanguage}
          items={state.currentStoreLanguages}
          label={state.translation.languageSelectorLabel}
          onSelect={state.selectLanguage}
        />
      )}
      <StoreSelector
        className={css.dropdown}
        items={state.stores}
        label={state.translation.storeSelectorLabel}
        activeItem={state.currentStore}
        onSelect={state.selectStore}
      />
    </div>
  );
};

export default observer(StoreAndLanguageSelector);
