import * as classNames from 'classnames';

import { MoneyData } from '../../../../app/data/model';
import Icon from '../../../../app/shared/components/Icon/Icon';
import Price from '../../../../app/shared/components/Price';
import { PropertyList } from '../../../../app/shared/components/PropertyList/PropertyList';
import { CartItemDetailsTranslation } from '../../../localization/SiteTranslation';
import css from './CartItemDetails.css';

interface CartItemDetailsProps {
  code?: string;
  invalidConfiguration?: boolean;
  systemMessage?: string;
  quantity?: number;
  listPrice?: MoneyData;
  salePrice?: MoneyData;
  className?: string;
  propertyClassNames?: {
    propClassName?: string;
    labelClassName?: string;
    valueClassName?: string;
  };
  translation: CartItemDetailsTranslation;
}

export const CartItemDetails = (props: CartItemDetailsProps) => {
  const { code, systemMessage, quantity, listPrice, salePrice, className, propertyClassNames, translation } = props;
  const cartItemDetails = [
    code && { label: translation.configCodeLabel, value: <CartItemCode code={code} systemMessage={systemMessage} /> },
    quantity && { label: translation.noItemsLabel, value: quantity },
    listPrice && { label: translation.listPriceLabel, value: <Price price={listPrice} /> },
    salePrice && { label: translation.netPriceLabel, value: <Price price={salePrice} /> },
  ].filter((item) => !!item);

  return (
    <PropertyList
      className={className}
      items={cartItemDetails}
      propertyClassNames={
        propertyClassNames && {
          valueClassName: classNames(css.cartItemDetailsValue, propertyClassNames.valueClassName),
          labelClassName: propertyClassNames.labelClassName,
          propClassName: propertyClassNames.propClassName,
        }
      }
      labelOptions={{ colorGrey: true }}
      valueOptions={{ alignLeft: true }}
    />
  );
};

const CartItemCode = ({
  code,
  systemMessage,
  invalidConfiguration = false,
}: {
  code: string;
  systemMessage: string;
  invalidConfiguration?: boolean;
}) => (
  <span className={classNames(css.CartItemCode, invalidConfiguration && css.invalidConfiguration)}>
    {code}
    {invalidConfiguration ? <Icon name="warning" title={systemMessage} className={css.cartItemCodeIcon} /> : null}
  </span>
);
