import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

import { ShoppingCartItemData } from '../../../app/data/model';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { CartItemContextCommand } from '../../cart/CartItem/CartItemState';
import CartItemActions from '../../cart/CartItemActions';
import { ShoppingCartItemPanelTranslation } from '../../localization/SiteTranslation';
import css from './ShoppingCartItemPanel.css';

export interface ShoppingProduct {
  id: string;
  brandName: string;
  shortName: string;
  customName?: string;
}

interface ShoppingCartItemPanelProps {
  item: ShoppingProduct;
  contextCommands?: CartItemContextCommand[];
  children: ReactNode;
  verticalSpacing?: boolean;
  classes?: {
    headerClassName?: string;
  };
  translation: ShoppingCartItemPanelTranslation;
}

export const ShoppingCartItemPanel = observer(
  ({
    item,
    contextCommands = [],
    verticalSpacing = false,
    children,
    classes,
    translation,
  }: ShoppingCartItemPanelProps) => {
    return (
      <Panel
        className={classNames(css.panel, verticalSpacing && css.verticalSpacing)}
        bodyClassName={css.panelBody}
        header={{
          heading: <ShoppingCartItemHeader item={item} />,
          headingActionButtons: contextCommands?.any() ? (
            <CartItemActions items={contextCommands} translation={translation.cartItemActions} />
          ) : null,
          headerClassName: classNames(css.panelHeader, classes?.headerClassName),
        }}
      >
        {children}
      </Panel>
    );
  },
);

interface ShoppingCartItemHeaderProps {
  item: ShoppingProduct;
  contextCommands?: CartItemContextCommand[];
}

export const ShoppingCartItemHeader = observer(({ item }: ShoppingCartItemHeaderProps) => {
  return (
    <div>
      <span className={css.panelHeading}>
        <strong className={css.itemBrandName}>{item.brandName}</strong> {item.shortName}
      </span>
      <div className={css.itemNameLabel}>{item.customName}</div>
    </div>
  );
});

export const cartItemToPanelItem = (cartItem: ShoppingCartItemData): ShoppingProduct => {
  return {
    id: cartItem.id,
    brandName: cartItem.baseModel.brandName,
    shortName: cartItem.baseModel.shortName,
    customName: cartItem.customName,
  };
};
