import { Children, cloneElement, isValidElement, ReactNode, useEffect, useRef, useState } from 'react';

import mapStyles from '../mapStyles';
import css from './MapInternal.css';

interface MapInternalProps extends Pick<google.maps.MapOptions, 'center' | 'zoom'> {
  children: ReactNode;
  onDragEnd?: () => void;
  onIdle?: (map: google.maps.Map) => void;
  onLoad?: (map: google.maps.Map) => void;
}

const MapInternal = ({ center, children, onDragEnd, onIdle, onLoad, zoom }: MapInternalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>(null);

  useEffect(() => {
    if (ref.current && !map) {
      const googleMap = new window.google.maps.Map(ref.current, { ...mapStyles, center, zoom });

      setMap(googleMap);

      if (onLoad) {
        onLoad(googleMap);
      }
    }
  }, [map, ref]);

  useEffect(() => {
    if (map) {
      map.setOptions({ center, zoom });
    }
  }, [center.lat, center.lng, map, zoom]);

  useEffect(() => {
    if (map) {
      ['dragend', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onDragEnd) {
        map.addListener('dragend', onDragEnd);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onDragEnd, onIdle]);

  return (
    <>
      <div ref={ref} className={css.element} />

      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default MapInternal;
