import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../../../../app/data/client';
import { SetContactLastDealerUsedCommand, ShoppingContext, StoreData } from '../../../../app/data/model';
import { WarningStateBase } from '../../../../app/layout/Warnings/WarningStateBase';
import { AsyncCommand } from '../../../../app/shared/common';
import { IEventAggregator } from '../../../../app/shared/EventAggregator';
import Logger from '../../../../app/shared/Logger';
import { CustomerUpdatedEvent } from '../../../cart/events/CustomerUpdatedEvent';
import { StoreSelectorTranslation } from '../../../localization/SiteTranslation';

class StoreSelectorState {
  @observable
  public isOpen: boolean = false;

  public readonly changeStoreCommand = new AsyncCommand<StoreData>((store) => this.changeStore(store));

  public readonly storeChangeFailedWarning = new WarningStateBase();

  public constructor(
    private readonly client: IApiClient,
    private readonly eventAggregator: IEventAggregator,
    private readonly shoppingContext: ShoppingContext,
    public readonly currentStore: StoreData,
    public readonly stores: StoreData[],
    public readonly translation: StoreSelectorTranslation,
  ) {
    makeObservable(this);

    this.currentStore = currentStore;
    this.stores = stores.filter((s) => s.id !== currentStore.id);
  }

  @action.bound
  public close() {
    this.isOpen = false;
  }

  @action.bound
  public async select(store: StoreData) {
    this.close();
    await this.changeStoreCommand.invoke(store);
  }

  @action.bound
  public toggle() {
    this.isOpen = !this.isOpen;
  }

  private async changeStore(store: StoreData) {
    try {
      await this.client.send(
        new SetContactLastDealerUsedCommand({
          storeId: store.id,
          shoppingContext: this.shoppingContext,
        }),
      );
    } catch (e) {
      Logger.error(
        `Unable to set ${store.id} as a default organization for the ${
          this.shoppingContext.customer.userName
        } user. Error: ${JSON.stringify(e)}`,
      );
      this.storeChangeFailedWarning.open();
      return;
    }

    this.eventAggregator.publish(
      new CustomerUpdatedEvent({
        username: this.shoppingContext.customer.userName,
        languageCode: this.shoppingContext.languageCode,
        storeId: store.id,
      }),
    );
  }
}

export default StoreSelectorState;
