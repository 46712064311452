import { action, makeObservable, observable } from 'mobx';

import { ObjectState } from './ObjectState';

export class LoadableMeshState extends ObjectState {
  @observable
  materialName: string;

  @observable
  materialUrl: string;

  constructor(name: string, public url: string, materialName?: string, materialUrl?: string) {
    super(name);
    this.materialName = materialName;
    this.materialUrl = materialUrl;
    makeObservable(this);
  }

  @action
  public setMaterialName(name: string) {
    this.materialName = name;
  }

  @action
  public setMaterialUrl(name: string) {
    this.materialUrl = name;
  }
}
