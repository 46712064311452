import { InvitationData } from '../../../app/data/model';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import ActionMenu from '../../../app/shared/components/ActionMenu/ActionMenu';
import { ITableHeaderCell, ITableRowData, Table } from '../../../app/shared/components/Table/Table';
import HeaderLevel3 from '../../../app/shared/components/Typography/HeaderLevel3';
import { InvitationsTableTranslation } from '../../localization/SiteTranslation';
import css from './InvitationsTable.css';

const getHeaders = (translation: InvitationsTableTranslation) => {
  return {
    USER_NAME: translation.userNameHeader,
    EMAIL: translation.emailHeader,
    EXPIRES_AT: translation.expiresAtHeader,
    CONTEXT_MENU: translation.contextMenuHeader,
  };
};

const getTableHeaders = (translation: InvitationsTableTranslation) => {
  const headers = getHeaders(translation);

  const tableHeaders: ITableHeaderCell[] = new Array<ITableHeaderCell>();
  tableHeaders.push({ name: headers.USER_NAME, alignment: 'left' });
  tableHeaders.push({ name: headers.EMAIL, alignment: 'left' });
  tableHeaders.push({ name: headers.EXPIRES_AT, alignment: 'left' });
  tableHeaders.push({ name: headers.CONTEXT_MENU, alignment: 'right' });

  return tableHeaders;
};

type InvitationAction = (invitation: InvitationData) => Promise<void> | void;

const mapInvitationToRow = (
  invitation: InvitationData,
  removeInvitation: InvitationAction,
  translation: InvitationsTableTranslation,
): ITableRowData[] => {
  const InvitationActions = () => (
    <ActionMenu
      items={[{ label: translation.removeLabel, action: () => removeInvitation(invitation) }]}
      translation={translation.actionMenu}
    />
  );
  const headers = getHeaders(translation);

  return [
    { headerName: headers.USER_NAME, value: `${invitation.firstName} ${invitation.lastName}`, alignment: 'left' },
    { headerName: headers.EMAIL, value: invitation.email, alignment: 'left' },
    { headerName: headers.EXPIRES_AT, value: formatISODateStringToDottedDate(invitation.expiresAt), alignment: 'left' },
    { headerName: headers.CONTEXT_MENU, value: <InvitationActions />, alignment: 'right' },
  ];
};

interface InvitationsTableProps {
  invitations: InvitationData[];
  removeInvitation: InvitationAction;
  className?: string;
  translation: InvitationsTableTranslation;
}

export const InvitationsTable = ({ invitations, removeInvitation, className, translation }: InvitationsTableProps) => {
  const tableData = invitations.map((invite) => mapInvitationToRow(invite, removeInvitation, translation));

  return (
    <>
      <HeaderLevel3 className={css.header}>{translation.pendingInvitationsHeader}</HeaderLevel3>
      <Table
        tableHeader={{ data: getTableHeaders(translation) }}
        tableBody={{ data: tableData }}
        className={className}
      />
    </>
  );
};
