import { action, makeObservable, observable } from 'mobx';

import { TabsTranslation } from '../../../localization/SiteTranslation';

export class TabState {
  /* tslint:disable: no-any */
  component: any;
  componentProps: any;
  /* tslint:enable: no-any */

  name: string;
  code: string;
  index: number;

  @observable isActive: boolean;

  // tslint:disable-next-line: no-any
  constructor(code: string, translation: TabsTranslation, component?: any, componentProps?: any) {
    makeObservable(this);
    this.code = code;
    this.name = translation[this.code].name;

    this.component = component;
    this.componentProps = componentProps;
  }

  @action
  public setActive(is: boolean) {
    this.isActive = is;
  }
}
