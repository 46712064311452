import { observer } from 'mobx-react';
import { Component } from 'react';

import { Gallery, GalleryProps } from '../../../shared/components/Gallery/Gallery';

export type ProductImageGalleryProps = GalleryProps;

@observer
export class ProductImageGallery extends Component<ProductImageGalleryProps> {
  constructor(props: ProductImageGalleryProps) {
    super(props);
  }

  render() {
    const { breakpoints, state, maxRowCount } = this.props;
    return <Gallery breakpoints={breakpoints} state={state} maxRowCount={maxRowCount} />;
  }
}
