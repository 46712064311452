import { Component, ReactNode } from 'react';

import IconButton from '../../Button/IconButton';
import Icon from '../../Icon/Icon';
import css from './Tag.css';

interface TagProps {
  children: ReactNode;
}

export default class Tag extends Component<TagProps, {}> {
  render() {
    return (
      <IconButton className={css.Tag} nameClassName={css.name} icon={<Icon name="cross" />}>
        {this.props.children}
      </IconButton>
    );
  }
}
