import { observer } from 'mobx-react';
import { Component } from 'react';

import Popup from '../../shared/components/Popup/Popup';
import { OptionVariationState } from '../OptionState';
import css from './OptionVariationItem.css';
import OptionVariationPopup from './OptionVariationPopup/OptionVariationPopup';
import OptionVariationThumbnail from './OptionVariationThumbnail/OptionVariationThumbnail';

interface OptionVariationItemProps {
  variation: OptionVariationState;
  className?: string;
  thumbnailClassName?: string;
  isSelected: boolean;
  onSelected: () => void;
  hideCaption?: boolean;
  hidePopup?: boolean;
  brandId?: string;
}

class OptionVariationItem extends Component<OptionVariationItemProps> {
  constructor(props: OptionVariationItemProps) {
    super(props);
  }

  renderCaption(name: string, code: string, alternativeCode: string, brandId: string) {
    const { hideCaption } = this.props;

    if (hideCaption) {
      return;
    }

    // Show code only when:
    // - brand is not Profim,
    // - brand is Profim and fabric is not a *LAMI (these tend to be too long)
    // - brand is Profim and alternative code is available
    // - the (alternative) code is different from the name
    // long and display poorly:
    let showCode = brandId !== 'profim' || !code.includes('LAMI') || !!alternativeCode;

    code = alternativeCode ?? code;

    if (code === name) {
      showCode = false;
    }

    return (
      <div className={css.caption}>
        {showCode && <span className={css.text}>{code}</span>}
        <span className={css.text}>{name}</span>
      </div>
    );
  }

  render() {
    const { variation, className, thumbnailClassName, onSelected, isSelected, brandId, hidePopup } = this.props;
    const { name, code, alternativeCode, image } = variation.data;

    const popper = !hidePopup && <OptionVariationPopup imageUrl={image.url} code={alternativeCode ?? code} />;

    return (
      <Popup className={className} popper={popper} variation={variation}>
        <div className={css.triggerComponent} onClick={() => onSelected()}>
          <OptionVariationThumbnail className={thumbnailClassName} isSelected={isSelected} image={image} />
          {this.renderCaption(name, code, alternativeCode, brandId)}
        </div>
      </Popup>
    );
  }
}

export default observer(OptionVariationItem);
