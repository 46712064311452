import { action, makeObservable } from 'mobx';

import AppSettings from '../../../AppSettings';
import { OrderData, PaymentData, PaymentParameterData, ShoppingContext } from '../../../data/model';
import { CheckoutPageTranslation } from '../../../localization/SiteTranslation';
import { AppError } from '../../../shared/AppError';
import Logger from '../../../shared/Logger';
import { StoreUrl } from '../../../shared/StoreUrl';
import { AbsoluteUrl } from '../../../shared/Url';
import { AsyncScriptLoader } from '../AsyncScriptLoader';
import { AuthorizationCompletedEventArgs, BasePaymentMethod } from '../IPaymentMethod';
import { PaymentParameterDataExtensions } from '../PaymentParameterDataExtensions';

export class KlarnaPaymentMethod extends BasePaymentMethod {
  private settings: AppSettings;
  private translation: CheckoutPageTranslation;
  private order: OrderData;
  private payment: PaymentData;

  constructor(setting: AppSettings, translation: CheckoutPageTranslation) {
    super('KlarnaPayments');
    makeObservable(this);
    this.settings = setting;
    this.translation = translation;
    this.showAuthorizeButton = true;

    this.ensureScriptLoaded();
  }

  public getInitialParameters(shoppingContext: ShoppingContext): Array<PaymentParameterData> {
    return [
      {
        key: 'Locale',
        value: shoppingContext.languageCode,
      },
    ];
  }

  private async ensureScriptLoaded(): Promise<void> {
    return AsyncScriptLoader.ensureLoaded('https://x.klarnacdn.net/kp/lib/v1/api.js', 'anonymous');
  }

  @action
  async initialize(order: OrderData, payment: PaymentData): Promise<void> {
    this.setInitialized(false);

    this.order = order;
    this.payment = payment;

    await this.ensureScriptLoaded();

    Klarna.Payments.init({ client_token: this.getClientToken() });
  }

  onWidgetRendered() {
    Klarna.Payments.load(
      { container: '#klarna_container', payment_method_category: this.getPaymentMethodCategory() },
      (response) => {
        this.setInitialized(true);
        if (response.error.any()) {
          Logger.exception('Klarna widget initialization failed', new Error(response.error.join(', ')));
        }
      },
    );
  }

  public authorize(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      Klarna.Payments.authorize(
        {
          payment_method_category: this.getPaymentMethodCategory(),
          auto_finalize: true,
        },
        null,
        (result) => {
          if (result.approved) {
            const args: AuthorizationCompletedEventArgs = {
              parameters: [
                { key: 'authorization_token', value: result.authorization_token },
                {
                  key: 'merchant_urls.confirmation',
                  value: AbsoluteUrl.parse(this.settings.baseUrl)
                    .append(StoreUrl.confirmationUrl(this.order.number))
                    .toString(),
                },
                { key: 'session_id', value: this.getSessionId() },
              ],
            };
            this.authorizationCompleted.raise(args);
            resolve();
          } else if (result.show_form) {
            resolve();
          } else {
            const error = new AppError(
              this.translation.errors.authorizingPaymentFailed,
              `Payment authorization failed, order id = ${this.order.id}, error = ${result.error}}`,
            );
            reject(error);
          }
        },
      );
    });
  }

  private getSessionId() {
    return PaymentParameterDataExtensions.GetRequired(this.payment.parameters, 'session_id');
  }

  private getClientToken() {
    return PaymentParameterDataExtensions.GetRequired(this.payment.parameters, 'client_token');
  }

  private getPaymentMethodCategory() {
    return PaymentParameterDataExtensions.GetRequired(this.payment.parameters, 'payment_method_categories');
  }

  @action
  private setInitialized(value: boolean) {
    this.isInitialized = value;
  }
}
