import { Component } from 'react';

import { ConfiguratorState } from '../../configurator/ConfiguratorState';
import Topbar from '../Topbar';
import css from './ConfiguratorLayout.css';
import { LayoutProps } from './MainLayout';

interface ConfiguratorLayoutProps extends LayoutProps {}

export default class ConfiguratorLayout extends Component<ConfiguratorLayoutProps, {}> {
  render() {
    const { appState, children, embeddedQuery } = this.props;
    const currentPage = this.props.appState.currentPage as ConfiguratorState;

    if (embeddedQuery.embed) {
      return children;
    }

    return (
      <div className={css.ConfiguratorLayout}>
        <Topbar
          appState={appState}
          translation={appState.translation.layout.topBar}
          modelName={currentPage.baseModel.name}
          isLogoSmall
        />
        {children}
      </div>
    );
  }
}
