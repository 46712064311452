import { FinalVisualizationBuilder } from '../../../app/configurator/VisualizationBuilder';
import { MoneyData } from '../../../app/data/model';
import { LoadableImage } from '../../../app/shared/components/LoadableImage';
import { CartItemDetails } from '../../cart/CartItem/CartItemDetails/CartItemDetails';
import { ShoppingCartItemBasicInfoTranslation } from '../../localization/SiteTranslation';
import css from './ShoppingCartItemBasicInfo.css';

interface Props {
  item: {
    code: string;
    quantity: number;
    listPrice: MoneyData;
    salePrice: MoneyData;
  };
  translation: ShoppingCartItemBasicInfoTranslation;
}

export const ShoppingCartItemBasicInfo = ({ item, translation }: Props) => (
  <div className={css.container}>
    <LoadableImage
      className={css.image}
      src={FinalVisualizationBuilder.buildUrl(item.code, undefined, undefined, 120, 140)}
      width={120}
      height={140}
      sharpeningFactor={1.5}
    />
    <div className={css.detailsContainer}>
      <CartItemDetails
        className={css.details}
        code={item.code}
        quantity={item.quantity}
        // Do not show 0 prices
        listPrice={item.listPrice.amount ? item.listPrice : undefined}
        salePrice={item.salePrice.amount ? item.salePrice : undefined}
        translation={translation.cartItemDetails}
      />
    </div>
  </div>
);
