import { action, makeObservable, observable } from 'mobx';

import { ShoppingContext } from '../../../data/model';
import { Analytics } from '../../../shared/analytics/Analytics';
import { AsyncCommand } from '../../../shared/common';
import { INavigationService } from '../../../shared/NavigationService';
import { StoreUrl } from '../../../shared/StoreUrl';

export class RequestQuoteActionState {
  private context: ShoppingContext;

  @observable public code: string;

  public proceedToRequestQuoteCommand: AsyncCommand;

  constructor(private readonly navigation: INavigationService, code: string) {
    makeObservable(this);
    this.code = code;
    this.proceedToRequestQuoteCommand = new AsyncCommand(() => this.proceedToRequestQuote());
  }

  initialize(context: ShoppingContext) {
    this.context = context;
  }

  @action
  async proceedToRequestQuote() {
    Analytics.trackAskForPriceClick(this.context);
    this.navigation.navigateTo(
      StoreUrl.requestQuoteUrl({
        configurationCode: this.code,
      }),
    );
  }

  @action setCode(code: string) {
    this.code = code;
  }
}
