import { Mesh, Scene, SceneLoader } from '@babylonjs/core';

import { LoadableMeshState } from '../../core/LoadableMeshState';
import { ObjectState } from '../../core/ObjectState';
import { IMeshPresenter } from './MeshPresenter';

export class MaterialPresenter implements IMeshPresenter {
  async update(scene: Scene, mesh: Mesh, object: ObjectState): Promise<void> {
    if (!(object instanceof LoadableMeshState)) {
      return;
    }

    const meshObjectState = object as LoadableMeshState;
    if (mesh.material?.name === meshObjectState.materialName) {
      return;
    }

    if (object.materialName && scene.materials.empty((x) => x.name === object.materialName)) {
      await SceneLoader.AppendAsync(object.materialUrl, null, scene); // TODO: check if there is another way of doing it
      scene.meshes.filter((x) => x.name.startsWith('prof')).forEach((x) => (x.visibility = 0)); // TODO: hide the plane unnecessarily imported form the material file
    }

    const material = scene.materials.find((x) => x.name.equalsIgnoreCase(object.materialName));
    if (material) {
      mesh.material = material;
    }
  }
}
