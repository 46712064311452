import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, MouseEvent } from 'react';

import { SelectOptionsStepTranslation } from '../../localization/SiteTranslation';
import PriceDifference from '../../shared/components/PriceDifference';
import TextInfo from '../../shared/components/TextInfo/TextInfo';
import { TextLabelSmall } from '../../shared/components/Typography/TextLabels';
import { OptionState, OptionVariationState } from '../OptionState';
import css from './OptionAffectingPrice.css';
import OptionVariationThumbnail from './OptionVariationThumbnail/OptionVariationThumbnail';

interface OptionAffectingPriceProps {
  option: OptionState;
  translation: SelectOptionsStepTranslation;
}

@observer
class OptionAffectingPrice extends Component<OptionAffectingPriceProps, {}> {
  constructor(props: OptionAffectingPriceProps) {
    super(props);
    this.handleInfoIconClick = this.handleInfoIconClick.bind(this);
  }

  handleInfoIconClick(event: MouseEvent<EventTarget>, variation: OptionVariationState) {
    event.stopPropagation();
    variation.openDescriptionModal();
  }

  render() {
    const { option } = this.props;

    return (
      <div className={css.threeColumnGridWrapper}>
        {option.variations.map((variation) => this.renderVariation(option, variation))}
      </div>
    );
  }

  renderVariation(option: OptionState, variation: OptionVariationState) {
    const { image, name } = variation.data;
    const selected = variation.selected;
    const priceLabel = selected ? (
      <TextLabelSmall>{this.props.translation.textSelected}</TextLabelSmall>
    ) : (
      <PriceDifference className={css.price} price={option.getPriceDifference(variation)} />
    );

    return (
      <div
        className={css.OptionAffectingPrice}
        key={variation.data.id}
        onClick={() => {
          option.checkRestrictionsAndSelect(variation);
        }}
      >
        <div className={css.optionContainer}>
          <div>
            <OptionVariationThumbnail
              image={image}
              isSelected={selected}
              className={classNames(css.thumbnail, selected && css.isSelected)}
              id={variation.data.id}
              highlighted={variation.highlightAnimation.active}
              removeHighlight={variation.highlightAnimation.stop}
            />
          </div>
          <div className={css.label}>
            {name && (
              <TextInfo
                className={css.nameContainer}
                onClick={(event: MouseEvent<EventTarget>) => {
                  this.handleInfoIconClick(event, variation);
                }}
                showInfo={!!variation.data.description}
              >
                {name}
              </TextInfo>
            )}
            {priceLabel}
          </div>
        </div>
      </div>
    );
  }
}

export default OptionAffectingPrice;
