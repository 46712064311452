import { observer } from 'mobx-react';
import { Component } from 'react';

import { ShareConfigurationSectionTranslation } from '../../../localization/SiteTranslation';
import Modal from '../../../shared/components/Modal/Modal';
import { ModalState } from '../../../shared/ModalState';
import { ConfigurationShareState } from '../../ConfigurationShareState';
import ConfigurationShare from '../ConfigurationShare/ConfigurationShare';
import css from './ConfigurationShareModal.css';

interface ConfigurationShareModalProps {
  state: ConfigurationShareState;
  modal: ModalState;
  translation: ShareConfigurationSectionTranslation;
  isCodeLoading?: boolean;
  isCodeError?: boolean;
  showDownloads: boolean;
}

@observer
class ConfigurationShareModal extends Component<ConfigurationShareModalProps, {}> {
  render() {
    const { modal, state, translation, isCodeLoading, isCodeError, showDownloads } = this.props;

    return (
      <Modal modal={modal} translation={translation} contentClassName={css.content}>
        <div>
          <ConfigurationShare
            state={state}
            translation={translation}
            isCodeLoading={isCodeLoading}
            isCodeError={isCodeError}
            showDownloads={showDownloads}
          />
        </div>
      </Modal>
    );
  }
}

export default ConfigurationShareModal;
