import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { Breakpoint, Screen, ScreenObserver } from '../../../../app/layout/ScreenObserver';
import Icon from '../../../../app/shared/components/Icon/Icon';
import AccordionInterface from '../AccordionInterface/AccordionInterface';
import TabbedInterface, { TabbedInterfaceItem } from '../TabbedInterface/TabbedInterface';
import css from './Tabs.css';

interface AccordionHeaderProps {
  isExpanded: boolean;
  title: string;
}

interface TabsProps {
  id: string;
  tabs: TabbedInterfaceItem[];
}

const AccordionHeader = ({ isExpanded, title }: AccordionHeaderProps) => {
  return (
    <span className={classNames(css.tab, { [css.selected]: isExpanded })}>
      {title}
      <Icon className={css.icon} name={isExpanded ? 'angleUp' : 'angleDown'} />
    </span>
  );
};

const Tabs = ({ id, tabs }: TabsProps) => {
  const [isMobileView, setIsMobileView] = useState<boolean>(true);

  const handleScreenChange = (screen: Screen) => {
    const hasScreenAtLeastTabletDimensions =
      screen.breakpoint === Breakpoint.Tablet || screen.breakpoint === Breakpoint.Desktop;
    setIsMobileView(!hasScreenAtLeastTabletDimensions);
  };

  useEffect(() => {
    ScreenObserver.onScreenChange.subscribe(handleScreenChange);

    handleScreenChange(ScreenObserver.current);

    return () => ScreenObserver.onScreenChange.unsubscribe(handleScreenChange);
  }, []);

  return isMobileView ? (
    <AccordionInterface
      contentClassName={css.panel}
      sectionClassName={css.accordion}
      renderName={(isExpanded, name) => <AccordionHeader isExpanded={isExpanded} title={name} />}
      sections={tabs}
    />
  ) : (
    <TabbedInterface
      id={id}
      hiddenPanelClassName={css.hidden}
      panelClassName={css.panel}
      panelInnerClassName={css.inner}
      tabClassName={css.tab}
      selectedTabClassName={css.selected}
      tabs={tabs}
    />
  );
};

export default Tabs;
