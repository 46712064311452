import classNames from 'classnames';
import React from 'react';

import { BaseModelBasicData } from '../../../../data/model';
import PreloadedImage from '../../../../shared/components/PreloadedImage';
import { SelectorItem } from '../../../../shared/Selector';
import css from './ProductModelSelectorItem.css';

interface ProductModelSelectorItemProps {
  item: SelectorItem<BaseModelBasicData>;
  handleClick: () => void;
  style?: React.CSSProperties;
}

const ProductModelSelectorItem = ({ item, handleClick, style }: ProductModelSelectorItemProps) => {
  const {
    data: { displayName, outline, shortDescription },
    isActive,
  } = item;

  return (
    <div
      className={classNames(css.ProductModelSelectorItem, isActive && css.isActive)}
      style={style}
      onClick={handleClick}
    >
      <div className={css.imageWrapper}>
        <PreloadedImage
          className={css.image}
          fallbackClass={css.fallback}
          src={outline?.url}
          alt={displayName}
          width={120}
          height={170}
        />
        {(!outline || !outline.url) && <span className={css.name}>{displayName}</span>}
      </div>
      {shortDescription && <p className={css.shortDescription}>{shortDescription}</p>}
    </div>
  );
};

export default ProductModelSelectorItem;
