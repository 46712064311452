import { observer } from 'mobx-react';

import { createDeliveryTimeText } from '../../../../../../app/checkout/components/ShoppingCartSummary/createDeliveryTimeText';
import { ProductVariationsTranslation } from '../../../../../../app/localization/SiteTranslation';
import ActionButton from '../../../../../../app/shared/components/ActionButton';
import Price from '../../../../../../app/shared/components/Price';
import Select from '../../../../../../app/shared/components/Select/Select';
import { ProductAvailability } from '../../../ProductPageState';
import ProductVariationsState from '../ProductVariationsState';
import css from './ConfigurationSummary.css';

interface ConfigurationSummaryProps {
  state: ProductVariationsState;
  productAvailability: ProductAvailability;
  enablePurchase: boolean;
  translation: ProductVariationsTranslation;
}

const ConfigurationSummary = observer(
  ({ state, productAvailability, enablePurchase, translation }: ConfigurationSummaryProps) => {
    const { delivery, price, quantityAvailable: quantityAvailable } = productAvailability;
    const { addProductToCartCommand } = state;
    const deliveryTimeText = createDeliveryTimeText(delivery, translation.deliveryTime);

    const checkoutButton = (
      <ActionButton className={css.addToCartButton} command={addProductToCartCommand}>
        {translation.buyButton}
      </ActionButton>
    );

    return (
      <div className={css.ConfigurationSummary}>
        {deliveryTimeText && (
          <div className={css.delivery}>
            <span>{deliveryTimeText}</span>
          </div>
        )}
        <div className={css.priceContainer}>
          <div className={css.description}>{translation.priceIncludingTax}</div>
          <Price className={css.price} price={price} />
        </div>
        {enablePurchase && (
          <div className={css.quantityCheckout}>
            <Select
              className={css.quantitySelect}
              value={state.quantity.toString()}
              items={[...Array(quantityAvailable).keys()].map((i) => (i + 1).toString())}
              keySelector={(x) => x}
              onChange={(x) => state.setQuantity(parseInt(x.target.value, undefined))}
            />
            {checkoutButton}
          </div>
        )}
        {deliveryTimeText && <div className={css.annotation}>{translation.deliveryTimeAnnotation}</div>}
      </div>
    );
  },
);

export default ConfigurationSummary;
