import { ICancellableTask } from '../shared/TasksScheduler';

export class CalculatePreparingTimeTask implements ICancellableTask {
  storeId: string;
  storeType: string;
  configurationCode: string;
  cancelled = false;

  constructor(storeId: string, storeType: string, configurationCode: string) {
    this.storeId = storeId;
    this.storeType = storeType;
    this.configurationCode = configurationCode;
  }
}
