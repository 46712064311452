import ActionButton from '../../../app/shared/components/ActionButton';
import TertiaryButton from '../../../app/shared/components/Button/TertiaryButton';
import List from '../../../app/shared/components/List/List';
import HeaderLevel3 from '../../../app/shared/components/Typography/HeaderLevel3';
import { Authorize } from '../../authentication/Authorize';
import { OrderDetailsProductTranslation } from '../../localization/SiteTranslation';
import { BuyerPolicy } from '../../Policy';
import { ShoppingCartItemBasicInfo } from '../../shared/ShoppingCartItemBasicInfo/ShoppingCartItemBasicInfo';
import { ShoppingCartItemPanel } from '../../shared/ShoppingCartItemPanel/ShoppingCartItemPanel';
import { OrderItemState } from '../OrderItemState';
import css from './OrderDetailsProducts.css';

interface Props {
  orderItems: Array<OrderItemState>;
  translation: OrderDetailsProductTranslation;
}

export const OrderDetailsProducts = ({ orderItems, translation }: Props) => {
  return (
    <div>
      <HeaderLevel3 className={css.OrderDetailsProductsTitle}>{translation.productsHeader}</HeaderLevel3>
      <div>
        <List
          items={orderItems}
          className={css.orderDetailsProductList}
          itemClassName={css.orderDetailsProductItem}
          itemRenderer={(itemState) => (
            <ShoppingCartItemPanel
              item={itemState.item}
              contextCommands={itemState.contextCommands}
              translation={translation.shoppingCartItemPanel}
            >
              <ShoppingCartItemBasicInfo
                item={{
                  code: itemState.item.configurationCode,
                  quantity: itemState.item.quantity,
                  listPrice: itemState.item.listPrice,
                  salePrice: itemState.item.salePrice,
                }}
                translation={translation.shoppingCartItemBasicInfo}
              />
              <Authorize policy={BuyerPolicy}>
                <ActionButton
                  command={itemState.reorderCommand}
                  className={css.reorderButton}
                  buttonElement={TertiaryButton}
                >
                  {translation.reorderButton}
                </ActionButton>
              </Authorize>
            </ShoppingCartItemPanel>
          )}
        />
      </div>
    </div>
  );
};
