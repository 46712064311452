import { action, makeObservable } from 'mobx';

import { IAuthenticationService } from '../../app/auth/AuthenticationService';
import { IApiClient } from '../../app/data/client';
import { GetUserDefaultDealerOrganizationQuery } from '../../app/data/model';
import Logger from '../../app/shared/Logger';
import { INavigationService } from '../../app/shared/NavigationService';
import { RelativeUrl } from '../../app/shared/Url';
import { StoreState } from '../../app/StoreState';
import { BaseOAuthHandlerState } from './BaseOAuthHandlerState';

export enum HttpErrorCode {
  BadRequest = 400,
  NotFound = 404,
  InternalServerError = 500,
}

export class HandleAuthCodeState extends BaseOAuthHandlerState {
  constructor(
    private readonly client: IApiClient,
    navigation: INavigationService,
    authentication: IAuthenticationService,
  ) {
    super(navigation, authentication);
    makeObservable(this);
  }

  @action
  async onLoad(store: StoreState) {
    const query = this.navigation.currentUrl.query;

    const hasCode = query.has('code');
    const hasState = query.has('state');

    if (!hasCode || !hasState) {
      Logger.error('Url does not contain code or state parameter');
      this.setError('Invalid request', HttpErrorCode.BadRequest);
      return;
    }

    try {
      await this.processCodeState();
    } catch (e) {
      this.handleAuthFlowError(e);
    }
  }

  private processCodeState = async () => {
    const redirectResult = await this.handleAuthorizationCodeRedirect();

    if (redirectResult.appState?.returnTo) {
      this.navigation.navigateTo(RelativeUrl.parse(redirectResult.appState.returnTo));
      return;
    }

    const { storeId, languageCode } = await this.client.send(new GetUserDefaultDealerOrganizationQuery());
    this.navigateToHomePage(storeId, languageCode);
  };
}
