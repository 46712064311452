import { action, computed, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import {
  SetShippingMethodCommand,
  ShipmentData,
  ShippingRateData,
  ShoppingCartData,
  ShoppingContext,
} from '../data/model';
import { ShippingMethodSectionTranslation } from '../localization/SiteTranslation';
import { AsyncCommand } from '../shared/common';
import Event from '../shared/Event';
import { Form, Input, ValueRequiredRule } from '../shared/Form';

export class ShippingState extends Form {
  private shoppingContext?: ShoppingContext;

  @observable
  public shippingRates: ShippingRateData[] = [];

  public readonly changeShippingRateCommand: AsyncCommand<ShippingRateData>;
  public readonly shippingRateChangedEvent = new Event<ShoppingCartData>();
  public readonly shippingRate: Input<ShippingRateData> = new Input<ShippingRateData>();

  constructor(private readonly client: IApiClient, public readonly translations: ShippingMethodSectionTranslation) {
    super();

    makeObservable(this);

    this.changeShippingRateCommand = new AsyncCommand<ShippingRateData>(this.handleShippingMethodChange);
    this.shippingRate.valueChanged.subscribe(this.changeShippingRateCommand.invoke);
    this.inputsToValidate.push(this.shippingRate);
  }

  @computed
  get recalculating() {
    return this.changeShippingRateCommand.processing;
  }

  @action.bound
  initialize = (
    shoppingContext: ShoppingContext,
    shippingRates: ShippingRateData[],
    selectedShipment?: ShipmentData,
  ) => {
    this.shoppingContext = shoppingContext;
    this.shippingRates = shippingRates;

    if (this.shippingRates.length > 0) {
      this.shippingRate.withRule(new ValueRequiredRule(this.translations.shippingMethod.fieldRequired));
    }

    if (selectedShipment) {
      const { shipmentMethodCode, shipmentMethodOption } = selectedShipment;
      this.shippingRate.value = shippingRates.find(
        (rate) => rate.method.code === shipmentMethodCode && rate.name === shipmentMethodOption,
      );
    }
  };

  private handleShippingMethodChange = async (shipping: ShippingRateData) => {
    const response = await this.client.send(
      new SetShippingMethodCommand({
        shipmentMethodCode: shipping.method.code,
        shipmentMethodOption: shipping.name,
        shoppingContext: this.shoppingContext,
      }),
    );

    this.shippingRateChangedEvent.raise(response.shoppingCart);
  };
}
