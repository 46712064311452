import { StoreData } from '../../../../../app/data/model';
import StoreSelectorOption from './StoreSelectorOption/StoreSelectorOption';

interface StoreSelectorOptionsProps {
  onClick: (store: StoreData) => void;
  options: StoreData[];
}

const StoreSelectorOptions = ({ onClick, options }: StoreSelectorOptionsProps) => {
  return (
    <>
      {options.map((store) => (
        <StoreSelectorOption data={store} onClick={onClick} key={store.id} />
      ))}
    </>
  );
};

export default StoreSelectorOptions;
