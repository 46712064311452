import { ReactNode } from 'react';

import css from './Tooltip.css';

interface TooltipProps {
  children: ReactNode;
  id: string;
}

const Tooltip = ({ children, id }: TooltipProps) => {
  return (
    <div role="tooltip" id={id} className={css.element}>
      {children}
    </div>
  );
};

export default Tooltip;
