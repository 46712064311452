import { FC } from 'react';

import { IApiClient } from '../../../data/client';
import { AddressFormTranslation } from '../../../localization/SiteTranslation';
import { DynamicInputsState } from './DynamicInputs/IDynamicInputsState';
import { defaultInputDefinition, dynamicInputsDefinitions } from './DynamicInputsDefinitions';

export interface DynamicInputProps {
  state: {};
  idPrefix?: string;
}

export interface DynamicInputsDefinition {
  state: DynamicInputsState;
  component: FC<DynamicInputProps>;
}

export default (code: string, translation: AddressFormTranslation, client: IApiClient) => {
  const factory = dynamicInputsDefinitions.get(code) || defaultInputDefinition;
  return factory(translation, client);
};
