import { OrderItemData } from '../../app/data/model';
import { AsyncCommand } from '../../app/shared/common';
import { CartItemContextCommand, ILabeledAction } from '../cart/CartItem/CartItemState';

export type IOrderItemContextAction = ILabeledAction<OrderItemData>;

export class OrderItemState {
  public readonly reorderCommand: AsyncCommand;
  public readonly contextCommands: CartItemContextCommand[];

  constructor(
    public readonly item: OrderItemData,
    private readonly reoder: (item: OrderItemData) => Promise<void>,
    private readonly contextActions: IOrderItemContextAction[] = [],
  ) {
    this.reorderCommand = new AsyncCommand(async () => {
      await this.reoder(this.item);
    });

    this.contextCommands = this.contextActions.map<CartItemContextCommand>((action) => ({
      label: action.label,
      command: new AsyncCommand(async () => await action.action(this.item)),
    }));
  }
}
