import classNames from 'classnames';
import { ReactNode } from 'react';

import { HeaderProps } from './HeaderLevel1';
import css from './HeaderLevel4.css';

interface HeaderLevel4Props extends HeaderProps {
  className?: string;
  children: ReactNode;
}

const HeaderLevel4 = ({ className, children, centered }: HeaderLevel4Props) => {
  const classes = classNames(css.HeaderLevel4, className, centered && css.centered);
  return <h4 className={classes}>{children}</h4>;
};

export default HeaderLevel4;
