import { ReactElement } from 'react';

import AppState from '../../AppState';
import { CheckoutState } from '../../checkout/CheckoutState';
import { ConfigurationSearchPageState } from '../../configurationSearch/ConfigurationSearchPageState';
import { ConfiguratorState } from '../../configurator/ConfiguratorState';
import { EmailMyConfigurationState } from '../../emailMyConfiguration/EmailMyConfigurationState';
import { GeneralErrorState } from '../../errorHandling/GeneralErrorState';
import ModularConfiguratorPageState from '../../modularConfigurator/ModularConfiguratorPageState';
import { RequestQuoteState } from '../../requestQuote/RequestQuoteState';
import { IPageState } from '../../shared/common';
import { EmbeddedQuery, EmbeddedQueryParser } from '../../shared/EmbeddedQueryParser';
import StoreLocatorState from '../../storeLocator/StoreLocatorState';
import ConfiguratorLayout from './ConfiguratorLayout';
import ContentCenteredLayout from './ContentCenteredLayout';
import MainLayout from './MainLayout';
import ModularConfiguratorLayout from './ModularConfiguratorLayout/ModularConfiguratorLayout';
import StoreLocatorPageLayout from './StoreLocatorPageLayout';

export class LayoutResolver {
  private static factories: Array<{
    pageTypes: Array<NewableFunction>;
    createLayout: (view: ReactElement<{}>, appState: AppState, embeddedQuery: EmbeddedQuery) => ReactElement<Object>;
  }> = [
    {
      pageTypes: [ConfiguratorState],
      createLayout: (view, appState, embeddedQuery) => (
        <ConfiguratorLayout appState={appState} children={view} embeddedQuery={embeddedQuery} />
      ),
    },
    {
      pageTypes: [ModularConfiguratorPageState],
      createLayout: (view, appState, embeddedQuery) => (
        <ModularConfiguratorLayout appState={appState} children={view} embeddedQuery={embeddedQuery} />
      ),
    },
    {
      pageTypes: [StoreLocatorState],
      createLayout: (view, appState, embeddedQuery) => (
        <StoreLocatorPageLayout appState={appState} children={view} embeddedQuery={embeddedQuery} />
      ),
    },
    {
      pageTypes: [
        GeneralErrorState,
        ConfigurationSearchPageState,
        CheckoutState,
        RequestQuoteState,
        EmailMyConfigurationState,
      ],
      createLayout: (view, appState, embeddedQuery) => (
        <ContentCenteredLayout appState={appState} children={view} embeddedQuery={embeddedQuery} />
      ),
    },
  ];

  public static resolveLayout(view: ReactElement<{}>, appState: AppState, pageState: IPageState): ReactElement<Object> {
    const embeddedQuery = EmbeddedQueryParser.toModel(appState.navigation.currentUrl.query);

    if (view) {
      const factory = LayoutResolver.factories.find((x) =>
        x.pageTypes.any((pageType) => pageState instanceof pageType),
      );
      if (factory) {
        return factory.createLayout(view, appState, embeddedQuery);
      }
    }

    return <MainLayout children={view} appState={appState} embeddedQuery={embeddedQuery} />;
  }
}
