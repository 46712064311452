import { action, makeObservable, observable, reaction } from 'mobx';

import { IApiClient } from '../../../data/client';
import {
  GenerateConfigurationCodeCommand,
  ProductConfigurationData,
  ProductConfigurationOrigin,
} from '../../../data/model';
import { ILoadingIndicator, LoadingIndicator } from '../../../shared/LoadingIndicator';
import Logger from '../../../shared/Logger';
import { RawConfiguratorState } from '../RawConfigurator/RawConfiguratorState';

export class ConfigurationCodeState {
  public codeGenerationIndicator: ILoadingIndicator;

  @observable
  public configurationCode: string | undefined;

  @observable
  public isError = false;

  constructor(public configurator: RawConfiguratorState, private readonly client: IApiClient) {
    makeObservable(this);

    this.codeGenerationIndicator = new LoadingIndicator();

    reaction(
      () => this.configurator.configurationCompleted,
      (completed) => {
        if (completed) {
          this.generateConfigurationCode();
        }
      },
    );
  }

  @action
  private async generateConfigurationCode() {
    try {
      this.codeGenerationIndicator.start();
      this.isError = false;

      const productConfiguration = this.getProductConfiguration();
      const generateCodeResponse = await this.client.send(
        new GenerateConfigurationCodeCommand({ productConfiguration }),
      );

      this.setConfigurationCode(generateCodeResponse.code);
    } catch (error) {
      Logger.exception('Error occurred while generating configuration code', error);
      this.isError = true;
    } finally {
      this.codeGenerationIndicator.stop();
    }
  }

  private getProductConfiguration(): ProductConfigurationData {
    const productConfiguration: ProductConfigurationData = {
      baseModelId: this.configurator.productId,
      baseModelCode: this.configurator.productId,
      options: this.configurator.selectedFeatures
        .filter((x) => x.feature.includeInConfigurationCode)
        .map((x) => {
          return {
            componentCode: x.feature.code,
            variationCode: x.selectedOption.code,
            id: `${x.feature.code}-${x.selectedOption.code}`,
          };
        }),
      priceGroups: [], // TODO: backward compatibility
      accessories: [], // TODO: backward compatibility
      quantity: 1,
      origin: ProductConfigurationOrigin.RawConfigurator,
    };

    return productConfiguration;
  }

  @action
  private setConfigurationCode(code: string) {
    this.configurationCode = code;
  }
}
