import { CheckoutPageTranslation } from '../localization/SiteTranslation';
import { CheckoutStep, ICheckoutStep } from './ICheckoutStep';

export class PaymentSteps {
  public static CreateContactAndBillingStep(translation: CheckoutPageTranslation): ICheckoutStep {
    return new CheckoutStep('contact-and-billing', translation.progress.deliveryStep);
  }

  public static CreatePaymentStep(translation: CheckoutPageTranslation) {
    return new CheckoutStep('payment', translation.progress.paymentStep);
  }

  public static CreateConfirmationStep(translation: CheckoutPageTranslation) {
    return new CheckoutStep('confirmation', translation.progress.confirmationStep);
  }
}
