import { action, makeObservable, observable } from 'mobx';

import { LanguageItem } from '../../../layout/StoreSelectionState';
import { SelectInput } from '../../Form';

class LanguageSelectorState {
  @observable.shallow
  public languages: LanguageItem[];

  private input: SelectInput<LanguageItem>;

  public constructor(languages: LanguageItem[] = [], initialLanguageCode?: string) {
    makeObservable(this);
    this.initialize(languages, initialLanguageCode);
  }

  public get selection(): string {
    return this.input.value.code;
  }

  public initialize(languages: LanguageItem[], initialLanguageCode?: string) {
    this.languages = languages;
    this.initializeInput(languages, initialLanguageCode);
  }

  @action.bound
  public select(languageCode: string) {
    const language = this.getLanguageByCode(languageCode);

    if (language) {
      this.input.onChange(language);
    }
  }

  public setDefaultLanguage(code: string) {
    const language = this.getLanguageByCode(code);

    if (language) {
      this.input.setDefaultValue(language);
    }
  }

  private getLanguageByCode(code: string) {
    return this.languages.find((x) => x.code === code);
  }

  private initializeInput(languages: LanguageItem[], initialLanguageCode?: string) {
    if (languages.empty()) {
      this.input = new SelectInput<LanguageItem>({ code: '', displayName: '' });
      return;
    }

    const language = languages.find((x) => x.code === initialLanguageCode);

    if (language) {
      this.input = new SelectInput<LanguageItem>(language);
      return;
    }

    this.input = new SelectInput<LanguageItem>(languages.first());
  }
}

export default LanguageSelectorState;
