import Cookies from 'universal-cookie';

import { CookieBasedStorage } from '../localStorage';
import { ICookieOptionsProvider } from './CookieOptionsProvider';
import IPreferredStoreProvider from './IPreferredStoreProvider';

type CookiePreferredLanguageData = {
  storeId: string;
  countryCode: string;
  languageCode: string;
};

export default class CookiePreferredStoreProvider implements IPreferredStoreProvider {
  private preferredLanguageKey = 'flokk-preferred-store-v1';

  private storage: CookieBasedStorage;

  constructor(cookies: Cookies, private cookieOptionsProvider?: ICookieOptionsProvider) {
    this.storage = new CookieBasedStorage(cookies, 365 * 86400, this.cookieOptionsProvider);
  }

  getStoreId(): string {
    return this.getPreferredLanguageCookieData().storeId;
  }

  getCountryCode(): string {
    return this.getPreferredLanguageCookieData().countryCode;
  }

  getLanguageCode(): string {
    return this.getPreferredLanguageCookieData().languageCode;
  }

  setStoreId(id: string): void {
    this.setPreferredLanguageCookieData({
      storeId: id,
    });
  }

  setCountryCode(code: string): void {
    this.setPreferredLanguageCookieData({
      countryCode: code,
    });
  }

  setLanguageCode(code: string): void {
    this.setPreferredLanguageCookieData({
      languageCode: code,
    });
  }

  private getPreferredLanguageCookieData() {
    const languagePreferredData = this.storage.get<CookiePreferredLanguageData>(this.preferredLanguageKey) || {
      languageCode: null,
      countryCode: null,
      storeId: null,
    };

    return languagePreferredData;
  }

  private setPreferredLanguageCookieData(cookieData: Partial<CookiePreferredLanguageData>) {
    const current = this.getPreferredLanguageCookieData();

    this.storage.set<CookiePreferredLanguageData>(this.preferredLanguageKey, {
      ...current,
      ...cookieData,
    });
  }
}
