import { useMemo } from 'react';

import ProductSpecification from '../../../../b2c/shared/components/ProductSpecification/ProductSpecification';
/*
 * A file in the app directory shouldn't have dependencies from the b2c directory.
 * TODO: Move the component and the ProductFamilyPage-related code to the b2c directory
 * https://dev.azure.com/fortedigital/Flokk/_workitems/edit/14056
 */
import { TabbedInterfaceItem } from '../../../../b2c/shared/components/TabbedInterface/TabbedInterface';
import Tabs from '../../../../b2c/shared/components/Tabs/Tabs';
import useComponentInstanceCreator from '../../../../b2c/shared/hooks/useComponentInstanceCreator';
import { PropertyData } from '../../../data/model';
import { ProductModelDetailsSectionTranslation } from '../../../localization/SiteTranslation';
import AssetsDownloader from '../../../shared/components/AssetsDownloader/AssetsDownloader';
import { AssetsDownloaderState } from '../../../shared/components/AssetsDownloader/AssetsDownloaderState';
import ProductEnvironmentalData, {
  ProductModelEnvironmentalData,
} from './ProductEnvironmentalData/ProductEnvironmentalData';
import css from './ProductModelDetails.css';

export interface ProductModelDetailsData {
  specification: PropertyData[];
  specificationDescription: string;
  environmentalData: ProductModelEnvironmentalData;
}

interface ProductModelDetailsProps {
  assetsDownloaderState: AssetsDownloaderState;
  model: ProductModelDetailsData;
  translation: ProductModelDetailsSectionTranslation;
}

const ID = 'product-model-details';

const ProductModelDetails = ({ assetsDownloaderState, model, translation }: ProductModelDetailsProps) => {
  /*
   * The AssetsDownloader component is recreated when the assetsDownloaderInstanceKey changes in order to close all expanded Accordion components inside it.
   * An accordion component uses a fully uncontrolled ExpandablePanel component so it's not possible to change its internal state outside a component.
   * */
  const [assetsDownloaderInstanceKey, resetAssetsDownloaderInstanceKey] = useComponentInstanceCreator();

  const tabs = useMemo<TabbedInterfaceItem[]>(() => {
    const { environmentalData, specification, specificationDescription } = model;
    const result = [];

    const doesEnvironmentalDataExist =
      environmentalData.co2EmissionKg ||
      environmentalData.energyUsageMj ||
      environmentalData.recyclableMaterialsPercentage ||
      environmentalData.recycledAluminiumPercentage ||
      environmentalData.recycledMaterialsTotalPercentage ||
      environmentalData.recycledPlasticPercentage;

    if (doesEnvironmentalDataExist) {
      result.push({
        name: translation.environmentalDataSectionTitle,
        content: (
          <ProductEnvironmentalData
            certificates={environmentalData.certificates}
            co2EmissionKg={environmentalData.co2EmissionKg}
            energyUsageMj={environmentalData.energyUsageMj}
            recyclableMaterialsPercentage={environmentalData.recyclableMaterialsPercentage}
            recycledAluminiumPercentage={environmentalData.recycledAluminiumPercentage}
            recycledMaterialsTotalPercentage={environmentalData.recycledMaterialsTotalPercentage}
            recycledPlasticPercentage={environmentalData.recycledPlasticPercentage}
            translation={translation.environmentalDataSection}
          />
        ),
        innerClassName: css.fullWidthTab,
      });
    }

    if (specificationDescription || specification.any()) {
      result.push({
        name: translation.specificationSectionTitle,
        content: <ProductSpecification description={specificationDescription} properties={specification} />,
      });
    }

    if (assetsDownloaderState.categories.any()) {
      result.push({
        name: translation.downloadsSectionTitle,
        content: (
          <AssetsDownloader
            state={assetsDownloaderState}
            translation={translation.assetsDownloader}
            key={assetsDownloaderInstanceKey}
          />
        ),
        onClose: resetAssetsDownloaderInstanceKey,
      });
    }

    return result;
  }, [assetsDownloaderInstanceKey, assetsDownloaderState, model, resetAssetsDownloaderInstanceKey, translation]);

  return (
    <div className={css.container}>
      <Tabs id={ID} tabs={tabs} />
    </div>
  );
};

export default ProductModelDetails;
