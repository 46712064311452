import { action, computed, makeObservable, observable } from 'mobx';

import { Tab } from './Tab';

export class Tabs {
  @observable tabs: Array<Tab>;
  defaultTab: Tab;

  constructor(tabs: Array<Tab>) {
    makeObservable(this);
    this.tabs = tabs;
    this.defaultTab = this.tabs[0];
  }

  @computed
  get activeTab() {
    return this.tabs.find((tab) => tab.isActive);
  }

  @action.bound
  changeTabTo(tab: Tab) {
    this.setActiveTab(tab);
  }

  @action.bound
  setActiveTab(newActiveTab: Tab) {
    return this.tabs.forEach((tab) => tab.setActive(tab.id === newActiveTab.id));
  }

  isActiveTab(id: string) {
    return this.activeTab.id === id;
  }

  getTab(tabId: string) {
    return this.tabs.find((tab) => tab.id === tabId);
  }
}
