import { ButtonProps } from '../../../../../shared/components/Button/Button';
import IconButton from '../../../../../shared/components/Button/IconButton';
import Icon, { IconType } from '../../../../../shared/components/Icon/Icon';
import css from './ConfigurationDownloadButton.css';

interface ConfigurationDownloadButtonProps extends ButtonProps {
  icon: IconType;
}

const ConfigurationDownloadButton = ({ children, icon, ...buttonProps }: ConfigurationDownloadButtonProps) => {
  return (
    <IconButton className={css.element} icon={<Icon name={icon} className={css.icon} />} {...buttonProps}>
      {children}
    </IconButton>
  );
};

export default ConfigurationDownloadButton;
