import classNames from 'classnames';
import * as React from 'react';
import Clipboard from 'react-clipboard.js';

import { CopyButtonTranslation } from '../../../localization/SiteTranslation';
import TertiaryButton from '../../../shared/components/Button/TertiaryButton';
import css from './CopyButton.css';

interface CopyButtonProps extends React.ComponentProps<typeof TertiaryButton> {
  className?: string;
  valueToCopy: string;
  vertical?: boolean;
  translation: CopyButtonTranslation;
}

interface CopyButtonState {
  lastCopyTime?: Date;
  copiedTextVisible: boolean;
}

const COPIED_TEXT_VISIBILITY_TIMEOUT = 1000;

class CopyButton extends React.Component<CopyButtonProps, CopyButtonState> {
  constructor(props: CopyButtonProps) {
    super(props);
    this.state = { lastCopyTime: null, copiedTextVisible: false };
  }

  showCopiedText() {
    this.setState({ lastCopyTime: new Date(), copiedTextVisible: true });
    setTimeout(() => this.hideCopiedText(), COPIED_TEXT_VISIBILITY_TIMEOUT);
  }

  hideCopiedText() {
    if (new Date().getTime() - this.state.lastCopyTime.getTime() >= COPIED_TEXT_VISIBILITY_TIMEOUT) {
      this.setState({ copiedTextVisible: false });
    }
  }

  render() {
    const { valueToCopy, vertical, translation, className, ...buttonProps } = this.props;
    const { copiedTextVisible } = this.state;
    const classes = classNames(className, vertical ? css.buttonVertical : css.button);

    return (
      <Clipboard
        component="div"
        className={classes}
        data-clipboard-text={valueToCopy}
        onSuccess={() => this.showCopiedText()}
      >
        <TertiaryButton className={css.CopyButton} {...buttonProps}>
          {copiedTextVisible ? translation.textCopiedLabel : translation.label}
        </TertiaryButton>
      </Clipboard>
    );
  }
}

export default CopyButton;
