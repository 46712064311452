import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, ReactNode } from 'react';

import { ExpandablePanel } from '../ExpandablePanel/ExpandablePanel';
import Icon from '../Icon/Icon';
import css from './Accordion.css';

interface AccordionProps {
  title: JSX.Element | string;
  className?: string;
  contentClassName?: string;
  titleExpandedClassName?: string;
  titleClassName?: string;
  children: ReactNode;
  isExpanded?: boolean;
  onIsExpandedChange?: (isExpanded: boolean) => void;
}

@observer
class Accordion extends Component<AccordionProps, never> {
  constructor(props: AccordionProps) {
    super(props);
  }

  renderTitle(isExpanded: boolean) {
    const classes = classNames(css.title, this.props.titleClassName, isExpanded && this.props.titleExpandedClassName);
    return (
      <div className={classes}>
        {this.props.title} <Icon name={isExpanded ? 'angleUp' : 'angleDown'} />
      </div>
    );
  }

  render() {
    return (
      <ExpandablePanel
        className={classNames(css.Accordion, this.props.className)}
        contentClassName={classNames(css.content, this.props.contentClassName)}
        renderTitle={(expanded) => this.renderTitle(expanded)}
        expanded={this.props.isExpanded}
        onExpandChanged={this.props.onIsExpandedChange}
      >
        {this.props.children}
      </ExpandablePanel>
    );
  }
}

export default Accordion;
