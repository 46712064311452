import classNames from 'classnames';
import { observer } from 'mobx-react';

import OptionDescriptionModal from '../../../../../../../app/configurator/components/OptionDescriptionModal/OptionDescriptionModal';
import TextInfo from '../../../../../../../app/shared/components/TextInfo/TextInfo';
import ProductOptionsState from '../ProductOptionsState';
import css from './ProductFabricInfo.css';

interface ProductFabricInfoProps {
  state: ProductOptionsState;
  className?: string;
}

const ProductFabricInfo = ({ className, state }: ProductFabricInfoProps) => {
  const fabric = state.selectedFabric;

  return fabric ? (
    <>
      <TextInfo
        className={classNames(css.text, className)}
        buttonClassName={css.button}
        onClick={state.openDescriptionModal}
        showInfo
      >
        <>
          {state.translation.fabricInfo.text} <strong>{state.selectedFabric.name}</strong>{' '}
          {state.selectedFabric.familyName}
        </>
      </TextInfo>

      <OptionDescriptionModal
        modal={state.descriptionModal}
        translation={state.translation.fabricInfo.optionDescriptionModal}
      />
    </>
  ) : null;
};

export default observer(ProductFabricInfo);
