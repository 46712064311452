import { observer } from 'mobx-react';
import { ChangeEventHandler } from 'react';

import Select from '../Select/Select';
import LanguageSelectorState from './LanguageSelectorState';

interface LanguageSelectorProps {
  state: LanguageSelectorState;
  label: string;
  className?: string;
  inlineLabel?: boolean;
}

const LanguageSelector = ({ className, inlineLabel, label, state }: LanguageSelectorProps) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    state.select(event.target.value);
  };

  return state.languages.length > 1 ? (
    <Select
      containerClassName={className}
      label={label}
      value={state.selection}
      items={state.languages}
      itemRenderer={(x) => x.displayName}
      keySelector={(x) => x.code}
      onChange={handleChange}
      inlineLabel={inlineLabel}
    />
  ) : null;
};

export default observer(LanguageSelector);
