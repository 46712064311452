import * as React from 'react';

import Icon from '../Icon/Icon';
import ThreeDotsLoader from '../Loaders/ThreeDotsLoader';
import IconButton, { IconButtonProps } from './IconButton';
import css from './ShowMoreButton.css';

interface ShowMoreButtonProps extends IconButtonProps {}

export const ShowMoreButton: React.FunctionComponent<ShowMoreButtonProps> = (props: ShowMoreButtonProps) => {
  const { isLoading, ...otherProps } = props;
  return (
    <IconButton
      icon={
        isLoading ? (
          <ThreeDotsLoader className={css.showMoreButtonIcon} size="small" />
        ) : (
          <Icon name="options" className={css.showMoreButtonIcon} />
        )
      }
      className={css.ShowMoreButton}
      disabled={isLoading}
      {...otherProps}
    />
  );
};
