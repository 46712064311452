import classNames from 'classnames';
import { ReactNode } from 'react';

import css from './Panel.css';

interface PanelHeader {
  heading?: ReactNode;
  headingActionButtons?: ReactNode;
  headingClassName?: string;
  headerClassName?: string;
}

interface PanelProps {
  headerComponent?: ReactNode;
  header?: PanelHeader;
  children?: ReactNode;
  className?: string;
  bodyClassName?: string;
}

export const Panel = (props: PanelProps) => {
  const { headerComponent, header, children, className, bodyClassName } = props;

  return (
    <div className={classNames(css.Panel, className)}>
      {headerComponent ? headerComponent : <DefaultPanelHeader {...header} />}
      {children && <div className={bodyClassName}>{children}</div>}
    </div>
  );
};

const DefaultPanelHeader = ({ heading, headingActionButtons, headerClassName, headingClassName }: PanelHeader) =>
  heading || headingActionButtons ? (
    <div className={classNames(css.panelHeader, headerClassName)}>
      {heading && <div className={classNames(css.panelHeading, headingClassName)}>{heading}</div>}
      {headingActionButtons}
    </div>
  ) : null;
