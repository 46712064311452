import ProductVariationsQueryParser, {
  IProductVariationsPageQuery,
} from '../../b2c/webshop/product/components/ProductVariations/ProductVariationsQueryParser';
import ConfiguratorPageQueryParser from '../configurator/ConfiguratorPageQueryParser';
import IConfigurationPageQuery from '../configurator/IConfigurationPageQuery';
import { ProductFamilyQueryParser } from '../products/ProductFamilyPageQuery';
import RouteDefinitions from '../RouteDefinitions';
import { StoreLocatorQuery, StoreLocatorQueryParser } from '../storeLocator/StoreLocatorQuery';
import { ProductConfigurationQuery } from './hubSpot/ProductConfigurationQuery';
import { ProductConfigurationQueryParser } from './hubSpot/ProductConfigurationQueryParser';
import { StoreUrlBuilder } from './StoreUrlBuilder';

export class StoreUrl {
  public static storeLocatorUrl(queryModel?: StoreLocatorQuery) {
    const query = queryModel ? StoreLocatorQueryParser.toQuery(queryModel) : new Map<string, string>();
    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.storeLocatorRoute),
      query,
    );
  }

  public static configuratorUrl(slug: string, queryModel?: IConfigurationPageQuery) {
    const query = queryModel ? ConfiguratorPageQueryParser.toQuery(queryModel) : new Map<string, string>();
    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.configuratorProductRoute, { slug: slug.toLocaleLowerCase() }),
      query,
    );
  }

  public static codeUrl(code: string) {
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.codeRoute, { code: code }));
  }

  public static emailConfirmationUrl(model: ProductConfigurationQuery) {
    const query = ProductConfigurationQueryParser.toQuery(model);
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.emailMyConfigurationRoute, undefined, query));
  }

  public static requestQuoteUrl(model: ProductConfigurationQuery) {
    const query = ProductConfigurationQueryParser.toQuery(model);
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.requestQuoteRoute, undefined, query));
  }

  public static checkoutUrl() {
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.checkoutRoute));
  }

  public static homeUrl() {
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.homeRoute));
  }

  public static confirmationUrl(orderNumber: string) {
    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.confirmationRoute, { orderNumber: orderNumber }),
    );
  }

  public static productPageUrl(familyName: string, model?: string, code?: string) {
    const query = ProductFamilyQueryParser.toQuery({
      code: code,
      model: model,
    });

    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.productPageRoute, { slug: familyName }, query),
    );
  }

  public static webShopProductPageUrl(productId: string, queryModel?: IProductVariationsPageQuery) {
    const query = queryModel ? ProductVariationsQueryParser.toQuery(queryModel) : new Map<string, string>();
    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.webShopProductDetailsRoute, { productId }, query),
    );
  }

  public static productPdfPrintUrl(
    storeId: string,
    languageCode: string,
    productSlug: string,
    queryModel: IConfigurationPageQuery,
  ) {
    const query = ConfiguratorPageQueryParser.toQuery(queryModel);
    query.set('embed', 'true');
    query.set('store', 'preserve');
    query.delete('step');
    const path = RouteDefinitions.getUrlTo((x) => x.productSpecificationRoute, { slug: productSlug }, query);

    return StoreUrlBuilder.build(storeId, languageCode, path);
  }

  public static configurationSearchUrl() {
    return StoreUrlBuilder.withContext(RouteDefinitions.getUrlTo((x) => x.configurationSearchRoute));
  }

  public static configurationDetailsUrl(code: string) {
    return StoreUrlBuilder.withContext(
      RouteDefinitions.getUrlTo((x) => x.configurationDetailsRoute, { code: code.toUpperCase() }),
    );
  }
}
