import HeaderLevel3 from '../../app/shared/components/Typography/HeaderLevel3';
import css from './FavoritesModalHeader.css';

interface Props {
  heading: string;
  description?: string;
}

export const FavoritesModalHeader = ({ heading, description }: Props) => {
  return (
    <div className={css.modalHeader}>
      <HeaderLevel3>{heading}</HeaderLevel3>
      <span>{description}</span>
    </div>
  );
};
