import classNames from 'classnames';
import { default as React, ReactNode } from 'react';

import { MoneyData } from '../../../app/data/model';
import { RouteUrlBuilder } from '../../../app/RouteDefinitions';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import Link from '../../../app/shared/components/Link';
import List from '../../../app/shared/components/List/List';
import Price from '../../../app/shared/components/Price';
import { StoreUrlBuilder } from '../../../app/shared/StoreUrlBuilder';
import { OrderHeaderTranslation } from '../../localization/SiteTranslation';
import RouteDefinitions from '../../routing/RouteDefinitions';
import css from './OrderHeader.css';

interface OrderHeaderProps {
  orderNo: string;
  date?: string;
  price: MoneyData;
  buyer?: string;
  name?: string;
  reference?: string;
  status?: string;
  expectedShippingMessage?: string;
  translation: OrderHeaderTranslation;
}

export const OrderHeader = ({
  orderNo,
  date,
  price,
  name,
  reference,
  buyer,
  status,
  expectedShippingMessage,
  translation,
}: OrderHeaderProps) => {
  const orderUrl = StoreUrlBuilder.withContext(
    RouteUrlBuilder.getUrlTo(RouteDefinitions.orderRoute, { orderNumber: orderNo }),
  );

  const orderItemHeaderProperties = [
    { label: translation.orderNoLabel, value: <Link to={orderUrl}>{orderNo}</Link> },
    { label: translation.orderDateLabel, value: formatISODateStringToDottedDate(date) },
    { label: translation.totalPriceLabel, value: <Price price={price} /> },
    { label: translation.buyerLabel, value: buyer, hide: !buyer },
    { label: translation.orderNameLabel, value: name, hide: !name },
    { label: translation.yourReferenceLabel, value: reference, hide: !reference },
  ];
  return (
    <div className={css.OrderHeader}>
      <List
        className={css.orderHeaderList}
        itemClassName={css.orderHeaderItemContainer}
        items={orderItemHeaderProperties}
        itemRenderer={(item) => <OrderHeaderItem label={item.label} value={item.value} />}
        hideItem={(item) => item.hide}
      />
      <OrderHeaderStatus status={status} expectedShippingMessage={expectedShippingMessage} />
    </div>
  );
};

interface OrderHeaderItemProps {
  className?: string;
  label: ReactNode;
  value: ReactNode;
}

const OrderHeaderItem = ({ className, label, value }: OrderHeaderItemProps) => {
  return (
    <div className={classNames(css.orderHeaderItem, className)}>
      <div className={css.label}>{label}</div>
      <div className={css.value}>{value}</div>
    </div>
  );
};

const OrderHeaderStatus = ({
  status,
  expectedShippingMessage,
}: {
  status: string;
  expectedShippingMessage?: string;
}) => {
  return (
    <div className={css.orderHeaderStatus}>
      {status}
      {expectedShippingMessage && <div className={css.orderDeliveryDate}>{expectedShippingMessage}</div>}
    </div>
  );
};
