import { Storyboard } from '../../../shared/animations';
import { ChairAnimation } from './AnimatedChair';

export class AnimatedChairState {
  animations: ChairAnimation = new ChairAnimation();

  fast: () => Storyboard;
  medium: () => Storyboard;
  slow: () => Storyboard;

  constructor() {
    const { fast, medium, slow } = this.animations;
    this.fast = () => new Storyboard().append(0, fast, [slow, medium]);
    this.medium = () => new Storyboard().append(0, medium, [fast, slow]);
    this.slow = () => new Storyboard().append(0, slow, [fast, medium]);
  }
}
