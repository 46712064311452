import { ShoppingCartItemData } from '../../app/data/model';
import { AsyncCommand } from '../../app/shared/common';
import {
  FavoriteItemDetailsModalTranslation,
  RemovalConfirmationModalTranslation,
} from '../localization/SiteTranslation';
import { FavoriteItemModalState } from './FavoriteItemModalState';

export class RemovalConfirmationModalState extends FavoriteItemModalState {
  constructor(
    private readonly removalAccepted: (item: ShoppingCartItemData) => Promise<void>,
    public readonly removalConfirmationModaltranslation: RemovalConfirmationModalTranslation,
    public readonly favoriteItemModalTranslation: FavoriteItemDetailsModalTranslation,
  ) {
    super(favoriteItemModalTranslation);

    this.removalAcceptedCommand = new AsyncCommand(async () => {
      await this.removalAccepted(this.favoriteItem);
      this.close();
    });
  }

  public readonly removalAcceptedCommand: AsyncCommand;
}
