import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { ImagePreloader, ImagePreloadingContext } from '../../../shared/ImagePreloader';
import css from './LazyImage.css';

interface LazyImageProps {
  placeholderUrl: string;
  lazyImageUrl: string;
  className?: string;
  imageClassName?: string;
}

@observer
export class LazyImage extends Component<LazyImageProps, { loaded: boolean }> {
  constructor(props: LazyImageProps) {
    super(props);
    this.state = { loaded: false };

    this.loadLargeImage = this.loadLargeImage.bind(this);

    if (this.props.placeholderUrl) {
      ImagePreloadingContext.queue.enqueue(this.props.placeholderUrl);
    }
  }

  componentDidMount(): void {
    this.loadLargeImage();
  }

  loadLargeImage() {
    if (this.state.loaded) {
      return;
    }

    new ImagePreloader().load([this.props.lazyImageUrl]).then(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    const { placeholderUrl, lazyImageUrl, className, imageClassName } = this.props;

    return (
      <div className={classNames(css.LazyImage, className)}>
        <img
          className={classNames(css.image, css.placeholder, imageClassName, this.state.loaded && css.hide)}
          src={placeholderUrl}
        />
        <img
          className={classNames(css.image, css.lazyImage, imageClassName, this.state.loaded && css.loaded)}
          src={this.state.loaded ? lazyImageUrl : placeholderUrl}
        />
      </div>
    );
  }
}
