import classNames from 'classnames';

import { ButtonProps } from '../../../../shared/components/Button/Button';
import TextButton from '../../../../shared/components/Button/TextButton';
import css from './CurrentLocationTextButton.css';

export const CurrentLocationTextButton = ({ children, className, ...buttonProps }: ButtonProps) => {
  return (
    <TextButton
      className={classNames(css.CurrentLocationTextButton, className)}
      hideName
      loaderPosition={'rightIn'}
      {...buttonProps}
    >
      {children}
    </TextButton>
  );
};
