import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { Camera } from '@babylonjs/core/Cameras/camera';
import { Engine } from '@babylonjs/core/Engines/engine';
import { ScreenshotTools } from '@babylonjs/core/Misc/screenshotTools';
import { Scene } from '@babylonjs/core/scene';

export class SceneScreenshotMaker {
  private static createScreenshot(engine: Engine, camera: Camera, width: number, height: number) {
    return ScreenshotTools.CreateScreenshotUsingRenderTargetAsync(
      engine,
      camera,
      { precision: 1, width, height },
      'image/jpeg',
      1,
      true,
    );
  }

  public static getCameraRotationAngleByShot(shot: string) {
    switch (shot) {
      case 'left':
        return 0;
      case 'back':
        return -Math.PI / 4;
      case 'front':
      default:
        return Math.PI / 2;
    }
  }

  public static getScreenshot(scene: Scene, shot: string, width: number, height: number) {
    const engine = scene.getEngine();
    const camera = scene.activeCamera as ArcRotateCamera;
    camera.alpha = SceneScreenshotMaker.getCameraRotationAngleByShot(shot);

    return SceneScreenshotMaker.createScreenshot(engine, camera, width, height);
  }
}
