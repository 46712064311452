import { observer } from 'mobx-react';
import { Component as ReactComponent, ComponentType } from 'react';

import AppState from '../AppState';
import { isBrowser } from '../shared/common';
import IdleTimer from './IdleTimer';

interface WithShowroomTimerProps {
  appState: AppState;
}

export const WithShowroomTimer = <P extends WithShowroomTimerProps>(Component: ComponentType<P>) =>
  observer(
    class ShowroomTimerDecorator extends ReactComponent<P> {
      public render() {
        const {
          appState: { loadingPageIndicator, redirectionWarningModalState, store },
        } = this.props;
        const showroomConfig = store.storeResponse.storeContext.showroomConfig;

        return store.isIdleRedirectionEnabled() && isBrowser() && !loadingPageIndicator.isLoading ? (
          <IdleTimer
            element={document}
            onIdle={redirectionWarningModalState.open}
            onAction={redirectionWarningModalState.visible ? redirectionWarningModalState.close : undefined}
            throttle={200}
            timeout={1000 * showroomConfig.redirectionDelay}
            startOnMount
          >
            <Component {...this.props} />
          </IdleTimer>
        ) : (
          <Component {...this.props} />
        );
      }
    },
  );
