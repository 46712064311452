import { action, computed, makeObservable, observable, override } from 'mobx';

import { Tab } from '../../app/configurator/Tab';
import { IApiClient } from '../../app/data/client';
import {
  FavoritesSummaryData,
  GetDealerFavoritesQuery,
  RemoveProductFromDealerFavoritesCommand,
  ShoppingCartData,
  ShoppingCartItemData,
  ShoppingContext,
  UpdateFavoriteConfigurationLabelCommand,
} from '../../app/data/model';
import { ConfiguratorUrlBuilder } from '../../app/errorHandling/ParseConfigurationCodeAndRedirectState';
import { Analytics } from '../../app/shared/analytics/Analytics';
import { BasePageState } from '../../app/shared/BasePageState';
import { PageMetadata } from '../../app/shared/common';
import { IEventAggregator } from '../../app/shared/EventAggregator';
import { INavigationService } from '../../app/shared/NavigationService';
import { ZipBuilder } from '../../app/shared/ZipBuilder';
import { StoreState } from '../../app/StoreState';
import { ICartItemContextAction } from '../cart/CartItem/CartItemState';
import { FavoritesUpdatedEvent } from '../cart/events/FavoritesUpdatedEvent';
import { FavoritesPageTranslation } from '../localization/SiteTranslation';
import { BuyerPolicy } from '../Policy';
import { EditConfigurationNameModalState } from './EditConfigurationNameModalState';
import { FavoriteItemModalState } from './FavoriteItemModalState';
import { FavoriteItemState } from './FavoriteItemState';
import { RemovalConfirmationModalState } from './RemovalConfirmationModalState';

export class FavoritesState extends BasePageState<null> {
  @observable.ref
  favoritesCart?: ShoppingCartData;

  @observable.ref
  shoppingContext?: ShoppingContext;

  @observable
  public readonly editConfigurationNameModal: EditConfigurationNameModalState;

  @observable
  public readonly favoriteItemDetailsModal: FavoriteItemModalState;

  @observable
  public readonly removalConfirmationModal: RemovalConfirmationModalState;

  public readonly favoriteItemContextActions: ICartItemContextAction[] = [];

  private readonly configurationUrlBuilder: ConfiguratorUrlBuilder;
  private readonly zipBuilder: ZipBuilder;

  constructor(
    private readonly client: IApiClient,
    private readonly navigation: INavigationService,
    private readonly eventAggregator: IEventAggregator,
    public readonly translation: FavoritesPageTranslation,
  ) {
    super();
    makeObservable(this);
    this.configurationUrlBuilder = new ConfiguratorUrlBuilder(this.client);
    this.zipBuilder = new ZipBuilder(this.client, this.navigation);

    this.editConfigurationNameModal = new EditConfigurationNameModalState(
      this.updatefavoriteConfigurationLabel,
      this.translation.editConfigurationNameModal,
      this.translation.favoriteItemDetailsModal,
    );
    this.favoriteItemDetailsModal = new FavoriteItemModalState(this.translation.favoriteItemDetailsModal);
    this.removalConfirmationModal = new RemovalConfirmationModalState(
      this.removeProductFromFavorites,
      this.translation.removalConfirmationModal,
      this.translation.favoriteItemDetailsModal,
    );
  }

  @computed
  get favoriteItems(): FavoriteItemState[] {
    if (!this.favoritesCart) {
      return [];
    }

    return this.favoritesCart.items.map(
      (item) =>
        new FavoriteItemState(
          item,
          {
            addToCart: this.addFavoriteToCart,
            contextActions: this.favoriteItemContextActions,
            showDetails: this.openFavoriteItemDetailsModal,
          },
          this.translation.favoriteItem,
        ),
    );
  }

  @override
  get metadata(): PageMetadata {
    return {
      title: this.translation.title ?? 'My favourites',
    };
  }

  async onLoad(store: StoreState) {
    const response = await this.client.send(
      new GetDealerFavoritesQuery({
        shoppingContext: store.shoppingContext,
      }),
    );

    this.setFavoritesCart(response);
    this.setItemContextActions(store.shoppingContext);
    this.setShoppingContext(store.shoppingContext);
  }

  @action.bound
  private setFavoritesCart({
    favoritesCart,
    favoritesSummary,
  }: {
    favoritesCart: ShoppingCartData;
    favoritesSummary: FavoritesSummaryData;
  }) {
    this.favoritesCart = favoritesCart;
    this.eventAggregator.publish(FavoritesUpdatedEvent.fromFavoritesSummary(favoritesSummary));
  }

  private setItemContextActions(shoppingContext: ShoppingContext) {
    this.favoriteItemContextActions.push({
      label: this.translation.downloadImagesLabel,
      action: this.generateZipVisualizationImages,
    });

    if (BuyerPolicy.grantAccess(shoppingContext.customer)) {
      this.favoriteItemContextActions.unshift({
        label: this.translation.editNameLabel,
        action: this.openProductConfigurationFormModal,
      });

      this.favoriteItemContextActions.push({
        label: this.translation.removeLabel,
        action: this.openRemovalConfirmationModal,
      });
    }
  }

  @action.bound
  private setShoppingContext(shoppingContext: ShoppingContext) {
    this.shoppingContext = shoppingContext;
  }

  private addFavoriteToCart = async (favoriteItem: ShoppingCartItemData) => {
    const shopUrl = await this.configurationUrlBuilder.buildFromCode(favoriteItem.code, undefined, Tab.Summary);
    this.navigation.navigateTo(shopUrl);
  };

  private removeProductFromFavorites = async (favoriteItem: ShoppingCartItemData) => {
    const response = await this.client.send(
      new RemoveProductFromDealerFavoritesCommand({
        lineItemId: favoriteItem.id,
        shoppingContext: this.shoppingContext,
      }),
    );

    this.setFavoritesCart(response);
  };

  private generateZipVisualizationImages = async (item: ShoppingCartItemData) => {
    const zipUrl = await this.zipBuilder.downloadZipConfigurationImages(item.code);
    Analytics.trackDownload({ url: zipUrl });
  };

  private openProductConfigurationFormModal = async (item: ShoppingCartItemData) => {
    this.editConfigurationNameModal.setFavoriteItem(item);
    this.editConfigurationNameModal.open();
  };

  private openFavoriteItemDetailsModal = async (item: ShoppingCartItemData) => {
    this.favoriteItemDetailsModal.setFavoriteItem(item);
    this.favoriteItemDetailsModal.open();
  };

  private openRemovalConfirmationModal = async (item: ShoppingCartItemData) => {
    this.removalConfirmationModal.setFavoriteItem(item);
    this.removalConfirmationModal.open();
  };

  private updatefavoriteConfigurationLabel = async (favoriteItem: ShoppingCartItemData, newLabel: string) => {
    const response = await this.client.send(
      new UpdateFavoriteConfigurationLabelCommand({
        lineItemId: favoriteItem.id,
        label: newLabel,
        shoppingContext: this.shoppingContext,
      }),
    );

    this.setFavoritesCart(response);
  };
}
