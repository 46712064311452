class HubSpotLocaleResolver {
  private static readonly defaultLocale = 'en';
  private static readonly locales = new Map([
    ['da-DK', 'da'],
    ['de-AT', 'de'],
    ['de-CH', 'de'],
    ['de-DE', 'de'],
    ['en-GB', 'en-gb'],
    ['en-US', 'en'],
    ['fr-BE', 'fr'],
    ['fr-CH', 'fr'],
    ['fr-FR', 'fr'],
    ['nb-NO', 'no-no'],
    ['nl-BE', 'nl'],
    ['nl-NL', 'nl'],
    ['pl-PL', 'pl'],
    ['sv-SE', 'sv'],
  ]);

  public static resolve(languageCode: string) {
    return this.locales.get(languageCode) ?? this.defaultLocale;
  }
}

export default HubSpotLocaleResolver;
