import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { CategoryFilterTranslation } from '../../../../../localization/SiteTranslation';
import ResultsCounter from '../../../ResultsCounter/ResultsCounter';
import { ICategoryFilterOptionState, ICategoryFilterState } from '../../DownloadsFiltersState';
import FilterButton from '../../FilterButton/FilterButton';
import css from './TabsMainGroup.css';

interface TabsMainGroupProps
  extends Omit<ICategoryFilterState, 'fullName'>,
    Pick<React.AriaAttributes, 'aria-controls'> {
  onTabSelect: () => void;
  resultsCounter: number;
  translation: Omit<CategoryFilterTranslation, 'fileTypeFilter'>;
}

const TabsMainGroup: React.FC<TabsMainGroupProps> = ({
  isTypeSelected,
  onTabSelect,
  options,
  resultsCounter,
  selection,
  translation,
  ...aria
}) => {
  const handleTabClick = (item: ICategoryFilterOptionState) => {
    selection.select(item);
    onTabSelect();
  };

  const renderTab = (tab: ICategoryFilterOptionState) => {
    const isActive = tab.isActive(selection.value);

    return (
      <FilterButton
        className={classNames(css.tab, { [css.active]: isActive, [css.withOpenSubgroup]: isTypeSelected })}
        name="category"
        value={tab.value}
        isActive={isActive}
        onSelect={() => handleTabClick(tab)}
        key={tab.value}
      >
        {tab.name}
      </FilterButton>
    );
  };

  return (
    <div className={css.TabsMainGroup}>
      <div className={css.inner} role="radiogroup" aria-label={translation.name} {...aria}>
        {options.map(renderTab)}
        <ResultsCounter className={css.counter} translation={translation.resultsCounter} value={resultsCounter} />
      </div>
    </div>
  );
};

export default observer(TabsMainGroup);
