import classNames from 'classnames';
import { Component } from 'react';

import css from './AccordionPanel.css';
import AccordionPanelContent from './AccordionPanelContent';
import AccordionPanelTitle from './AccordionPanelTitle';

interface AccordionPanelProps {
  className?: string;
  title: string;
  children: string | JSX.Element;
  active?: boolean;
  panelTitleClassName?: string;
  contentClassName?: string;
}

class AccordionPanel extends Component<AccordionPanelProps, { isActive: boolean }> {
  constructor(props: AccordionPanelProps) {
    super(props);
    this.state = {
      isActive: !!props.active,
    };
    this.handleAccordionPanelClick = this.handleAccordionPanelClick.bind(this);
  }

  handleAccordionPanelClick() {
    this.setState({ isActive: !this.state.isActive });
  }

  render() {
    const { className, title, children, panelTitleClassName, contentClassName } = this.props;
    const { isActive } = this.state;
    const finalTitleClassName = classNames(css.title, isActive && css.titleActive, panelTitleClassName);
    const finalContentClassName = classNames(css.content, isActive && css.contentExpanded, contentClassName);

    return (
      <div className={classNames(css.AccordionPanel, className)}>
        <AccordionPanelTitle
          onClick={this.handleAccordionPanelClick}
          isActive={isActive}
          className={finalTitleClassName}
        >
          {title}
        </AccordionPanelTitle>
        <AccordionPanelContent className={finalContentClassName} withoutSeparator>
          {children}
        </AccordionPanelContent>
      </div>
    );
  }
}

export default AccordionPanel;
