import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component, CSSProperties, ReactNode } from 'react';

import { CssAnimation } from '../../shared/animations';
import { keysOf } from '../../shared/common';

export interface IAnimationsList {
  hide?: CssAnimation;
  show?: CssAnimation;
  collapse?: CssAnimation;
  expand?: CssAnimation;
  moveOut?: CssAnimation;
  moveLeft?: CssAnimation;
  initPosition?: CssAnimation;
  initStyle?: CssAnimation;
  initAnimation?: CssAnimation;
  active?: CssAnimation;
  disabled?: CssAnimation;
  lock?: CssAnimation;
  fast?: CssAnimation;
  medium?: CssAnimation;
  slow?: CssAnimation;
}

type AnimationClasses = {
  [key in keyof IAnimationsList]: string;
};

export interface AnimatedComponentProps {
  animations: IAnimationsList;
  children?: ReactNode;
  css?: AnimationClasses;
  className?: string;
  classes?: AnimationClasses;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  style?: CSSProperties;
}

@observer
export class AnimatedComponent extends Component<AnimatedComponentProps> {
  constructor(props: AnimatedComponentProps) {
    super(props);
  }

  getClassNames() {
    const { animations, classes, css } = this.props;
    return keysOf(animations).map((name) =>
      animations[name].active ? (classes ? (classes[name] ? classes[name] : css[name]) : css[name]) : '',
    );
  }

  render() {
    const { children, className, onClick, onMouseEnter, onMouseLeave, style } = this.props;
    return (
      <div
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classNames(className, ...this.getClassNames())}
        style={style}
      >
        {children}
      </div>
    );
  }
}
