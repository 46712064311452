import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import AppState from '../AppState';
import { ProductNotAvailableInCurrentStoreWarning } from '../layout/Warnings/ProductNotAvailableInCurrentStoreWarning';
import { StackedWarnings } from '../layout/Warnings/StackedWarnings';
import { GalleryBreakpoint } from '../shared/components/Gallery/Gallery';
import ImageZoomModal from '../shared/components/ImageZoomModal/ImageZoomModal';
import MenuSection from '../shared/components/StickyMenu/MenuSection';
import StickyMenu from '../shared/components/StickyMenu/StickyMenu';
import ProductDesignerInfo from './components/ProductDesignerInfo/ProductDesignerInfo';
import ProductDetails from './components/ProductDetails/ProductDetails';
import ProductFamilyMenu from './components/ProductFamilyMenu/ProductFamilyMenu';
import { ProductImageGallery } from './components/ProductImageGallery/ProductImageGallery';
import ProductModelDetails from './components/ProductModelDetails/ProductModelDetails';
import ProductPitch from './components/ProductPitch/ProductPitch';
import ProductUserStories from './components/ProductUserStories/ProductUserStories';
import RelatedProducts from './components/RelatedProducts/RelatedProducts';
import SuggestedConfigurations from './components/SuggestedConfigurations/SuggestedConfigurations';
import { UserGuideVideo } from './components/UserGuideVideo/UserGuideVideo';
import css from './ProductFamilyPage.css';
import ProductFamilyPageState from './ProductFamilyPageState';

const PRODUCT_IMAGE_GALLERY_BREAKPOINTS: GalleryBreakpoint[] = [
  { minWidth: 950, itemsPerRow: 4 },
  { minWidth: 694, itemsPerRow: 3 },
  { minWidth: 0, itemsPerRow: 2 },
];

const PRODUCT_IMAGE_GALLERY_MAX_ROW_COUNT = 2;

export interface ProductFamilyPageProps {
  state: ProductFamilyPageState;
  appState: AppState;
}

@observer
export default class ProductFamilyPage extends Component<ProductFamilyPageProps, {}> {
  constructor(props: ProductFamilyPageProps) {
    super(props);

    this.renderDesigners = this.renderDesigners.bind(this);
  }

  renderDesigners() {
    const { state } = this.props;

    return (
      state.data.designers &&
      state.data.designers.map((designer, key) => {
        return (
          <div key={key} className={css.designerContainer}>
            <ProductDesignerInfo
              key={designer.name}
              translation={state.translation.designerSection}
              designer={designer}
            />
          </div>
        );
      })
    );
  }

  render() {
    const { state, appState } = this.props;
    const { translation } = appState;

    return (
      <Fragment>
        <StackedWarnings>
          <ProductNotAvailableInCurrentStoreWarning
            state={state.productNotAvailableInCurrentStoreState}
            translation={translation.configurationPage.productNotAvailableInCurrentStoreWarning}
          />
        </StackedWarnings>
        {state.hasModels && (
          <ImageZoomModal
            state={state.modelDetails.modelViewer.zoomModal}
            translation={translation.zoomModal}
            visualizationTranslation={translation.common.chairVisualization}
          />
        )}
        <div className={css.ProductFamilyPage}>
          <StickyMenu
            menuComponent={
              <ProductFamilyMenu items={[]} familyName={state.data.name} containerClassName={css.menuContainer} />
            }
          >
            <MenuSection name={state.translation.modelsSection.name} id="models" hide={!state.hasModels}>
              <ProductDetails
                storeState={state.storeState}
                state={state.modelDetails}
                canRequestQuote={appState.store.canRequestQuote()}
                familyName={state.data.name}
                goToConfiguratorCommand={state.goToConfiguratorCommand}
                goToCheckoutCommand={state.goToCheckoutCommand}
                askForPriceCommand={state.askForPriceCommand}
                showWhereToBuy={state.storeState.showWhereToBuy()}
                whereToBuyPageUrl={state.storeState.storeResponse.storeContext.whereToBuyConfig.pageUrl}
              />
            </MenuSection>
            <MenuSection
              id="specification"
              className={css.section}
              name={state.translation.productModelDetailsSection.name}
              hide={!state.hasModels}
            >
              {state.hasModels && (
                <ProductModelDetails
                  assetsDownloaderState={state.productsAssets}
                  model={state.modelDetails.activeModelDetails}
                  translation={state.translation.productModelDetailsSection}
                />
              )}
            </MenuSection>
            <MenuSection id="salesPresentationSection" className={css.section} hide={!state.data.salesPitch.videoLink}>
              <ProductPitch
                id="salesPresentation"
                description={state.data.salesPitch.description}
                posterUrl={state.data.salesPitch.videoPosterLink}
                title={state.data.salesPitch.title}
                videoTitle={undefined}
                videoUrl={state.data.salesPitch.videoLink}
              />
            </MenuSection>
            <MenuSection
              name={state.translation.gallerySection.name}
              id="gallery"
              className={css.section}
              hide={!state.imageGallery.images || state.imageGallery.images.empty()}
            >
              <ProductImageGallery
                breakpoints={PRODUCT_IMAGE_GALLERY_BREAKPOINTS}
                maxRowCount={PRODUCT_IMAGE_GALLERY_MAX_ROW_COUNT}
                state={state.imageGallery}
              />
            </MenuSection>
            <MenuSection
              id="variations"
              className={css.sectionVariations}
              hide={!state.suggestedConfigurations || state.suggestedConfigurations.models.empty()}
            >
              <SuggestedConfigurations
                state={state.suggestedConfigurations}
                translation={state.translation.suggestedConfigurationsSection}
              />
            </MenuSection>
            <MenuSection
              name={state.translation.designerSection.name}
              id="designer"
              className={css.section}
              hide={!state.data.designers || state.data.designers.empty()}
            >
              {this.renderDesigners()}
            </MenuSection>
            <MenuSection
              name={state.translation.productUserStories.name}
              id="userStories"
              className={css.userStoriesSection}
              hide={!state.data.userStories || state.data.userStories.empty()}
            >
              <ProductUserStories stories={state.data.userStories} translation={state.translation.productUserStories} />
            </MenuSection>
            <MenuSection
              id="userGuide"
              className={css.section}
              hide={!state.data.userGuide.videoLink || !state.data.userGuide.videoPosterLink}
            >
              <UserGuideVideo
                id="productUserCase"
                videoUrl={state.data.userGuide.videoLink}
                imageUrl={state.data.userGuide.videoPosterLink}
                videoTitle={state.data.userGuide.videoOverlayText}
                translation={state.translation.userGuideVideoSection}
              />
            </MenuSection>
            <MenuSection
              name={state.translation.relatedProducts.name}
              id="relatedProducts"
              className={css.relatedProductsSection}
              hide={!state.data.relatedProducts || state.data.relatedProducts.empty()}
            >
              <RelatedProducts state={state.relatedProducts} translation={state.translation.relatedProducts} />
            </MenuSection>
          </StickyMenu>
        </div>
      </Fragment>
    );
  }
}
