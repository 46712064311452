import { LanguageItem } from '../../StoreSelectionState';
import DropdownMenu from '../DropdownMenu/DropdownMenu';

interface LanguageSelectorProps {
  activeItem: LanguageItem;
  items: LanguageItem[];
  label: string;
  onSelect: (item: LanguageItem) => void;
  className?: string;
}

const LanguageSelector = ({ activeItem, className, items, label, onSelect }: LanguageSelectorProps) => {
  return (
    <DropdownMenu
      className={className}
      selectedItem={activeItem}
      items={items}
      keyProperty="code"
      label={label}
      namePropertyExtractor={(x) => x.displayName}
      onSelect={onSelect}
    />
  );
};

export default LanguageSelector;
