import { action, makeObservable, observable } from 'mobx';

import { IApiClient } from '../data/client';
import { FinalizePaymentCommand, OrderData, PaymentParameterData, ShoppingContext, StoreContext } from '../data/model';
import { CheckoutPageTranslation } from '../localization/SiteTranslation';
import { AsyncCommand } from '../shared/common';
import Event from '../shared/Event';
import Logger from '../shared/Logger';
import { CheckoutStep } from './ICheckoutStep';
import { AuthorizationCompletedEventArgs, IPaymentMethod, PaymentCompletedEventArgs } from './payments/IPaymentMethod';
import { ManualTestPaymentMethod } from './payments/ManualTest/ManualTestPaymentMethod';
import { IPaymentMethodFactory } from './payments/PaymentWidgetFactory';

export class CheckoutPaymentState extends CheckoutStep {
  private client: IApiClient;

  private shoppingContext: ShoppingContext;
  private storeContext: StoreContext;

  private order: OrderData;
  private finalizeParameters: Array<PaymentParameterData> = [];

  public authorizeCommand: AsyncCommand;
  public finalizeCommand: AsyncCommand;

  public completed: Event<PaymentCompletedEventArgs>;

  @observable.ref
  public method: IPaymentMethod = new ManualTestPaymentMethod();

  public factory: IPaymentMethodFactory;

  constructor(client: IApiClient, factory: IPaymentMethodFactory, translation: CheckoutPageTranslation) {
    super('payment', translation.progress.paymentStep);

    makeObservable(this);

    this.client = client;
    this.factory = factory;

    this.authorizeCommand = new AsyncCommand(() => this.method.authorize(), this.method);
    this.finalizeCommand = new AsyncCommand(() => this.finalize());

    this.completed = new Event<PaymentCompletedEventArgs>();

    this.onAuthorizationCompleted = this.onAuthorizationCompleted.bind(this);
  }

  public initialize(shoppingContext: ShoppingContext, storeContext: StoreContext) {
    this.shoppingContext = shoppingContext;
    this.storeContext = storeContext;
  }

  @action
  public setMethod(code: string) {
    this.disposeCurrentMethod();

    this.method = this.factory.create(code);
    this.method.authorizationCompleted.subscribe(this.onAuthorizationCompleted);
    this.authorizeCommand = new AsyncCommand(() => this.method.authorize(), this.method);
  }

  public getInitialParameters() {
    return this.method.getInitialParameters(this.shoppingContext, this.storeContext);
  }

  public async processOrder(order: OrderData, additionalParameter: Array<PaymentParameterData> = []): Promise<void> {
    await this.initializeMethod(order, additionalParameter);
  }

  public async restoreOrder(order: OrderData) {
    this.setMethod(this.getPaymentData(order).code);
    await this.initializeMethod(order);
  }

  private async initializeMethod(
    order: OrderData,
    additionalParameters: Array<PaymentParameterData> = [],
  ): Promise<void> {
    const payment = this.getPaymentData(order, additionalParameters);
    await this.method.initialize(order, payment);
    this.order = order;
  }

  private getPaymentData(order: OrderData, additionalParameters: Array<PaymentParameterData> = []) {
    if (order.payments.length !== 1) {
      throw new Error(`Provided order #${order.number} must have exactly one payment`);
    }

    const payment = order.payments[0];
    payment.parameters = payment.parameters.concat(additionalParameters); // Merge parameters to simply later usage
    return payment;
  }

  public disposeCurrentMethod() {
    if (this.method) {
      this.method.authorizationCompleted.unsubscribe(this.onAuthorizationCompleted);
      this.method.dispose();
    }

    this.authorizeCommand.clearError();
    this.finalizeCommand.clearError();
  }

  private onAuthorizationCompleted(args: AuthorizationCompletedEventArgs) {
    this.finalizeParameters = args.parameters;
    this.finalizeCommand.invoke();
  }

  @action
  public async finalize() {
    const command = new FinalizePaymentCommand({
      shoppingContext: this.shoppingContext,
      orderId: this.order.id,
      parameters: this.finalizeParameters,
      gatewayCode: this.method.code,
    });

    try {
      const response = await this.client.send(command);
      const args: PaymentCompletedEventArgs = {
        paymentOption: response.operationResult.paymentOption,
        redirectUrl: response.operationResult.redirectUrl,
      };

      this.completed.raise(args);
    } catch (error) {
      this.completed.raise({});
      Logger.exception('Calling FinalizePaymentCommand failed.', error);
    }
  }
}
