import { MoneyData } from '../data/model';
import { CurrencyFormatter } from '../shared/common';
import Logger from '../shared/Logger';

/*
 * It verifies if a unit price returned by ConfiguratorCoreState.unitPriceV2 is equal to the one returned by
 * ConfiguratorCoreState.unitPrice. If not it logs a warning.
 * TODO: Remove after the migration from ConfiguratorCoreState.unitPrice
 *  to ConfiguratorCoreState.unitPriceV2.
 */
class ConfigurationUnitPriceMismatchMigrationMonitor {
  public static monitor(actualPrice: MoneyData, expectedPrice: MoneyData, configurationCode: string) {
    if (actualPrice.amount !== expectedPrice.amount) {
      ConfigurationUnitPriceMismatchMigrationMonitor.log(actualPrice, expectedPrice, configurationCode);
    }
  }

  private static log(actualPrice: MoneyData, expectedPrice: MoneyData, configurationCode: string) {
    const actualPriceFormatted = CurrencyFormatter.getFormattedPrice(actualPrice);
    const expectedPriceFormatted = CurrencyFormatter.getFormattedPrice(expectedPrice);

    Logger.warn(
      `Unit prices mismatch. The actual price ${actualPriceFormatted} is not equal to the expected one ${expectedPriceFormatted} for ${configurationCode}.`,
    );
  }
}

export default ConfigurationUnitPriceMismatchMigrationMonitor;
