import { WebShopArticleData } from '../../../../../app/data/model';
import { ArticleListTranslation } from '../../../../../app/localization/SiteTranslation';
import List from '../../../../../app/shared/components/List/List';
import css from './ArticleList.css';
import ArticleTeaser from './ArticleTeaser/ArticleTeaser';

interface ArticleListProps {
  items: WebShopArticleData[];
  translation: ArticleListTranslation;
}

const ArticleList = ({ items, translation }: ArticleListProps) => {
  const renderItem = (item: WebShopArticleData) => {
    return (
      <ArticleTeaser
        description={item.description}
        image={item.image}
        link={item.link}
        readMoreTranslation={translation.readMore}
        title={item.title}
      />
    );
  };

  return (
    <div className={css.container}>
      <List className={css.list} itemClassName={css.item} items={items} itemRenderer={renderItem} />
    </div>
  );
};

export default ArticleList;
