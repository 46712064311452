import { observer } from 'mobx-react';
import { ComponentProps } from 'react';
import { AnyProps, ClusterFeature } from 'supercluster';

import Cluster from '../../../../../b2c/shared/components/Map/Cluster/Cluster';
import Marker from '../../../../../b2c/shared/components/Map/Marker/Marker';
import Pin from '../../../../../b2c/shared/components/Map/Pin/Pin';
import Place from '../../../../../b2c/shared/components/Map/Place/Place';
import { PhysicalStoreData } from '../../../../data/model';
import StoreMapState from '../StoreMapState';

interface StoreMarkersProps extends Pick<ComponentProps<typeof Marker>, 'map'> {
  state: StoreMapState;
  onItemSelected: (x: PhysicalStoreData) => void;
}

const StoreMarkers = ({ state, onItemSelected, ...markerProps }: StoreMarkersProps) => {
  const render = (x: StoreMapState['clusters'][number]) => {
    const shop = x.properties.shop as PhysicalStoreData;

    if (shop) {
      return (
        <Pin
          position={shop.coordinates}
          onClick={() => onItemSelected(shop)}
          selected={state.selectedItem?.id === shop.id}
          key={shop.id}
          {...markerProps}
        />
      );
    }

    const cluster = x as ClusterFeature<AnyProps>;
    const opacity = 0.1 + (cluster.properties.point_count / (state.maxCluster + 2)) * 0.5;
    const position = { lat: cluster.geometry.coordinates[1], lng: cluster.geometry.coordinates[0] };
    const scale = 0.4 + cluster.properties.point_count / state.maxCluster;

    return (
      <Cluster
        label={cluster.properties.point_count.toString()}
        opacity={opacity}
        position={position}
        scale={scale}
        onClick={() => {
          state.setCenter(position, true);
          state.setZoom(state.superCluster.getClusterExpansionZoom(cluster.id as number));
        }}
        key={cluster.id}
        {...markerProps}
      />
    );
  };

  return (
    <>
      {state.clusters?.map(render)}
      {state.autocompleteMarker && (
        <Place position={state.autocompleteMarker} key="autocompleteMarker" {...markerProps} />
      )}
    </>
  );
};

export default observer(StoreMarkers);
