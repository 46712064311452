import { observer } from 'mobx-react';
import { Component } from 'react';

import AppState from '../../app/AppState';
import { Onboarding } from '../onboarding/Onboarding';
import css from './HomePage.css';
import { HomeState } from './HomeState';

@observer
class HomePage extends Component<{ appState: AppState; state: HomeState }, {}> {
  render() {
    const { appState, state } = this.props;

    if (appState.loadingPageIndicator.isLoading) {
      return null;
    }

    return (
      <Onboarding
        contentClassName={css.onboardingContent}
        customer={appState.store?.shoppingContext?.customer}
        translation={state.translations.onboarding}
      />
    );
  }
}

export default HomePage;
