import { Router } from 'routes';

import { AppState } from '../app/AppState';
import { HttpError } from '../app/data/HttpError';
import IPreferredStoreProvider from '../app/data/StoreProviders/IPreferredStoreProvider';
import { IStoreInitializationStrategy } from '../app/IStoreInitializationStrategy';
import RouterFactory from '../app/RouterFactory';
import { Route } from '../app/routes';
import { StatusCodes } from '../app/shared/StatusCodes';
import { StoreUrlParser } from '../app/shared/StoreUrlParser';
import { publicRoutes } from './routing/routes';

export class StoreInitializationStrategy implements IStoreInitializationStrategy {
  private publicRouter: Router<Route<{}>>;

  constructor() {
    this.publicRouter = RouterFactory(publicRoutes);
  }

  async tryInitialize(
    path: string,
    search: string,
    appState: AppState,
    preferredStoreProvider: IPreferredStoreProvider,
  ) {
    if (this.publicRouter.match(path)) {
      return { redirected: false, purePath: path };
    }

    const parsedPath = StoreUrlParser.parse(path, (x) => !!x);
    const dealerId = parsedPath.storeId || preferredStoreProvider.getStoreId();
    const languageCode = parsedPath.hasValidLanguage
      ? parsedPath.languageCode
      : preferredStoreProvider.getLanguageCode();

    try {
      // TODO: redirect to test dealer, remove try catch block later on
      await appState.loadStore(dealerId, 'en-gb', languageCode);
    } catch (error) {
      const httpError = error as HttpError;

      if (httpError.status === StatusCodes.NotFound) {
        appState.navigation.redirect('/10100157/en-gb/dashboard');
        return { redirected: true };
      }

      throw error;
    }

    return {
      redirected: false,
      purePath: parsedPath.pure,
    };
  }
}
