import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { AccessoryData, EnabledByOperator, MoneyData, OptionType } from '../../../data/model';
import {
  IDependentOptionPreselectionStrategy,
  IFeature,
  IHighlightable,
  IItem,
  IPriceGroupRelated,
  Item,
} from '../../../shared/common';
import { IRestrictionsInitializer } from '../../../shared/restrictions';

export class AccessoryState extends Item implements IHighlightable, IFeature, IPriceGroupRelated {
  benefits: string;
  tabCode: string;
  index: number;
  data: AccessoryData;
  type: OptionType;

  @observable.shallow
  enablers: Array<IItem> = [];
  enabledByOperator: EnabledByOperator;

  @computed get enabled() {
    if (this.enablers.empty()) {
      return true;
    }

    if (this.enabledByOperator === EnabledByOperator.And) {
      const enablersGroupedByFeatures = this.enablers.groupBy<IItem>((x) => x.feature.data.code);
      return Object.entries(enablersGroupedByFeatures).all(([, variations]) =>
        variations.any((x) => x.selected && x.feature.enabled),
      );
    }

    return this.enablers.any((x) => x.selected);
  }

  @action setEnablingItems(items: Array<IItem>, enabledByOperator: EnabledByOperator) {
    this.enabledByOperator = enabledByOperator;
    this.enablers.push(...items);
  }

  @observable
  priceGroupNumber: string = undefined;

  constructor(data: AccessoryData, restrictionsInitializer: IRestrictionsInitializer) {
    super();

    makeObservable(this);

    this.id = data.id;
    this.name = data.name;
    this.code = data.code;
    this.displayCode = data.variationCode;
    this.image = data.image;
    this.benefits = data.description;
    this.restrictions = data.restrictions;
    this.tabCode = data.tabCode;
    this.index = data.index;
    this.data = data;
    this.type = OptionType.Accessory;
    this.feature = this;

    this.selected = false;

    this.restrictionsResolver = restrictionsInitializer.registerRestrictions({
      feature: this,
      item: this,
      canBeDeselected: true,
    });

    reaction(
      () => this.enabled,
      (enabled) => {
        if (!enabled) {
          this.deselect();
        }
      },
    );
  }

  @computed get isRestricted(): boolean {
    return this.restrictionsResolver
      .getRestrictingDependencies(this)
      .filter((x) => x.feature.data.code !== this.feature.data.code)
      .any();
  }

  @computed get price(): MoneyData {
    return this.priceGroupNumber
      ? {
          ...this.data.price,
          amount:
            this.data.price.amount +
            this.data.priceGroupSpecificPrices
              .filter((x) => x.priceGroup === this.priceGroupNumber)
              .sum((x) => x.price.amount),
        }
      : this.data.price;
  }

  @action toggleSelection() {
    if (this.selected) {
      this.deselect();
    } else {
      this.restrictionsResolver.checkRestrictionsAndSelect(this);
    }
  }

  // Feature interface implementation

  selectItem(item: AccessoryState): void {
    item.select();
  }

  deselectItem(item: AccessoryState): void {
    item.deselect();
  }

  getAllItems() {
    return [this];
  }

  formatItemName(item: AccessoryState): string {
    return item.name;
  }

  getItemPriceDifference(item: AccessoryState): MoneyData {
    return this.data.price;
  }

  getItemPrice(item: IItem): MoneyData {
    return item.price;
  }

  getSelectedItem(): IItem {
    return this.selected ? this : null;
  }

  @action
  selectPriceGroup(priceGroupNumber: string) {
    this.priceGroupNumber = priceGroupNumber;
  }

  getPreselectionStrategy(): IDependentOptionPreselectionStrategy {
    return new DependentAccessoryPreselectionStrategy(this);
  }

  canBeAutoResolvedWhenRestricting(): boolean {
    return false;
  }
}

export class DependentAccessoryPreselectionStrategy implements IDependentOptionPreselectionStrategy {
  constructor(private readonly accessory: AccessoryState) {}

  preselectDependentItem(_itemToBeSelected: IItem, _selectedItems: IItem[], _validItems: IItem[]): void {
    this.accessory.deselect();
  }

  suggestValidItem(_validItems: IItem[]): IItem {
    return null;
  }
}
