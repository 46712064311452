import classNames from 'classnames';

import capitalizeFirstLetter from '../../capitalizeFirstLetter';
import css from './Icon.css';

export type IconType =
  | 'calendar'
  | 'check'
  | 'creditCard'
  | 'doc'
  | 'edit'
  | 'equal'
  | 'link'
  | 'location'
  | 'minus'
  | 'plus'
  | 'save'
  | 'basket'
  | 'cross'
  | 'info'
  | 'sent'
  | 'user'
  | 'search'
  | 'pdf'
  | 'socialInstagram'
  | 'socialFb'
  | 'socialPinterest'
  | 'socialLinkedin'
  | 'socialBlog'
  | 'play'
  | 'angleDown'
  | 'angleUp'
  | 'angleLeft'
  | 'angleRight'
  | 'arrowRight'
  | 'enlarge'
  | 'earth'
  | 'gpsFull'
  | 'gpsDisabled'
  | 'gpsEmpty'
  | 'barcode'
  | 'list'
  | 'zip'
  | 'hamburger'
  | 'download'
  | 'zoomIn'
  | 'arrowNext'
  | 'arrowBack'
  | 'options'
  | 'favorites'
  | 'favoritesFull'
  | 'warning'
  | 'danger'
  | 'track'
  | 'socialFocus'
  | 'downloadLight'
  | 'ban'
  | '2d'
  | '3d'
  | 'dimensions'
  | 'fullscreen'
  | 'move'
  | 'rotate'
  | 'trash';

interface IconProps {
  className?: string;
  title?: string;
  name: IconType;
}

const Icon = ({ className, name, title }: IconProps) => {
  const iconName = `icon${capitalizeFirstLetter(name)}` as keyof typeof css;
  const classes = classNames(css[iconName], className);
  return <i className={classes} title={title} />;
};

export default Icon;
