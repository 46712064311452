import { ImageData } from '../../../../../../app/data/model';
import ExternalImage from '../../../../../../app/shared/components/ExternalImage/ExternalImage';
import HeaderLevel3 from '../../../../../../app/shared/components/Typography/HeaderLevel3';
import css from './InfoListBannerItem.css';

export interface InfoListBannerItemProps {
  description?: string;
  icon?: ImageData;
  title?: string;
}

const InfoListBannerItem = ({ description, icon, title }: InfoListBannerItemProps) => {
  return (
    <div className={css.container}>
      <div className={css.text}>
        {title && <HeaderLevel3 className={css.title}>{title}</HeaderLevel3>}
        {description}
      </div>
      {icon?.url && <ExternalImage className={css.image} src={icon.url} alt={icon.name} />}
    </div>
  );
};

export default InfoListBannerItem;
