import { action, makeObservable } from 'mobx';

import { IApiClient } from '../../app/data/client';
import { BasePageState } from '../../app/shared/BasePageState';
import { IMemento } from '../../app/shared/common';
import { StoreState } from '../../app/StoreState';

export class DashboardState extends BasePageState<IMemento> {
  constructor(client: IApiClient) {
    super({ pageTitleFormat: 'Dashboard', pageDescriptionFormat: null });
    makeObservable(this);
  }

  @action
  async onLoad(store: StoreState) {
    return Promise.resolve();
  }
}
