import { observer } from 'mobx-react';

import { FindLocalDealerSectionTranslation } from '../../../../localization/SiteTranslation';
import TertiaryButton from '../../../../shared/components/Button/TertiaryButton';
import Icon from '../../../../shared/components/Icon/Icon';
import Link from '../../../../shared/components/Link';
import HeaderLevel4 from '../../../../shared/components/Typography/HeaderLevel4';
import { StoreUrl } from '../../../../shared/StoreUrl';
import css from './FindLocalDealer.css';

interface FindLocalDealerProps {
  translation: FindLocalDealerSectionTranslation;
  brand: string;
}

const FindLocalDealer = (props: FindLocalDealerProps) => {
  const { translation, brand } = props;
  const storeNav = StoreUrl.storeLocatorUrl({
    brands: [brand],
  });
  const storeUrl = storeNav.toString(false);

  return (
    <div className={css.FindLocalDealer}>
      <HeaderLevel4 className={css.header}>{translation.title}</HeaderLevel4>
      <TertiaryButton
        className={css.link}
        itemElement={
          <Link href={storeUrl} target="_blank">
            <Icon name="location" className={css.icon} />
            {translation.button}
          </Link>
        }
      />
    </div>
  );
};

export default observer(FindLocalDealer);
