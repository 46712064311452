import Event from '../shared/Event';
import Logger from '../shared/Logger';
import { StatusCodes } from '../shared/StatusCodes';
import { IHttpClient, RequestOptions } from './httpClient';
import { HttpError } from './HttpError';
import * as Api from './model';

export interface IApiClient {
  send<TResponse>(request: Api.Request<TResponse>): Promise<TResponse>;

  connectionError: Event<{}>;
}

export default class ApiClient implements IApiClient {
  public connectionError: Event<{}> = new Event<{}>();

  constructor(
    private apiUrl: string,
    private client: IHttpClient,
    private readonly requestOptionsProvider?: IHttpRequestOptionsProvider,
  ) {
    this.send = this.send.bind(this);
    this.requestOptionsProvider ??= NullHttpRequestOptionsProvider;
  }

  public async send<TResponse>(request: Api.Request<TResponse>): Promise<TResponse> {
    const type = request.$type;
    const url = type ? `${this.apiUrl}/?type=${type}` : this.apiUrl;

    let response: Response;

    const options = await this.requestOptionsProvider.getForRequest(request);

    try {
      response = await this.client.post(url, JSON.stringify(request), options);
    } catch (error) {
      Logger.warn(`Connection error occurred while processing request ${url}. ${error}`);

      this.connectionError.raise({});
      throw new HttpError(StatusCodes.NoConnection, 'No internet connection');
    }

    return response.json().then(
      (json) => {
        if (response.ok) {
          return json;
        } else {
          return Promise.reject(new HttpError(response.status, json.message || json));
        }
      },
      (error) => {
        return Promise.reject(HttpError.fromError(response.status, error));
      },
    );
  }
}

export interface IHttpRequestOptionsProvider {
  getForRequest<TResponse>(request: Api.Request<TResponse>): Promise<RequestOptions>;
}

export const NullHttpRequestOptionsProvider: IHttpRequestOptionsProvider = {
  getForRequest: () => Promise.resolve({}),
};
