import { isBrowser } from '../shared/common';
import Event from '../shared/Event';
import WindowScroll, { ScrollPosition } from './WindowScroll';

export default class WindowScrollObserver {
  public static onScrollChange: Event<ScrollPosition> = new Event();
}

if (isBrowser()) {
  window.addEventListener('scroll', (e) => {
    WindowScrollObserver.onScrollChange.raise(WindowScroll.getPosition());
  });
}
