import { observer } from 'mobx-react';
import { Component } from 'react';

import HeaderLevel2 from '../../app/shared/components/Typography/HeaderLevel2';
import { IContainerContentStyle } from '../../app/Shell';
import { AppState } from '../AppState';
import SideMenu from '../menu/SideMenu/SideMenu';
import { SideMenuState } from '../menu/SideMenu/SideMenuState';
import TopMenu from '../menu/TopMenu/TopMenu';
import TopMenuState from '../menu/TopMenu/TopMenuState';
import PageLoader from './FullPageLoaderB2B/PageLoader';
import css from './Layout.css';

interface LayoutProps {
  children: Array<JSX.Element> | JSX.Element;
  appState: AppState;
}

@observer
export default class Layout extends Component<LayoutProps, {}> {
  public readonly sideMenuState: SideMenuState;
  public readonly topMenuState: TopMenuState;

  public constructor(props: LayoutProps) {
    super(props);

    const { client, eventAggregator, loadingPageIndicator, store, translation } = props.appState;
    this.sideMenuState = new SideMenuState(client, loadingPageIndicator, store, translation.sideMenu);
    this.topMenuState = new TopMenuState(client, eventAggregator, store, translation.topMenu);
  }

  public render() {
    const { children, appState } = this.props;
    const title = !appState.loadingPageIndicator.isLoading && appState?.currentPage?.metadata?.title;

    // TODO remove duplicated (b2c shell)
    const contentContainerStyle: IContainerContentStyle = {
      visibility: appState.loadingPageIndicator.isLoading ? 'hidden' : 'visible',
      position: appState.loadingPageIndicator.isLoading ? 'fixed' : 'static',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    };

    return (
      <div className={css.Layout}>
        <div className={css.sideMenu}>
          {appState.store && (
            <SideMenu
              state={this.sideMenuState}
              onSignOutClick={appState.authenticationService.currentUser && appState.authenticationService?.logout}
            />
          )}
        </div>

        <div className={css.sideContainer}>
          {appState.loadingPageIndicator.isLoading && <PageLoader />}
          {appState.store && <TopMenu state={this.topMenuState} />}

          <div style={contentContainerStyle}>
            <div className={css.content}>
              {title && <HeaderLevel2 className={css.title}>{title}</HeaderLevel2>}
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
