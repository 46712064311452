import ProductPropertiesList from '../../../../app/configurator/components/ProductPropertiesList/ProductPropertiesList';
import { PropertyData } from '../../../../app/data/model';
import Markdown from '../../../../app/shared/components/Markdown/Markdown';
import css from './ProductSpecification.css';

interface ProductSpecificationProps {
  description?: string;
  properties?: PropertyData[];
}

const ProductSpecification = ({ description, properties }: ProductSpecificationProps) => {
  return (
    <>
      {description && <Markdown className={css.description} content={description} />}
      {properties?.any() && (
        <ProductPropertiesList
          className={css.propertyList}
          itemClassName={css.property}
          itemLabelClassName={css.propertyLabel}
          itemValueClassName={css.propertyValue}
          items={properties}
        />
      )}
    </>
  );
};

export default ProductSpecification;
