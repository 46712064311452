import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Component } from 'react';

import { OptionState, OptionVariationState } from '../OptionState';
import css from './OptionNotAffectingPrice.css';
import OptionVariationItem from './OptionVariationItem';

interface OptionNotAffectingPriceProps {
  option: OptionState;
}

@observer
class OptionAffOptionNotAffectingPrice extends Component<OptionNotAffectingPriceProps, {}> {
  render() {
    const { option } = this.props;

    return (
      <div className={css.OptionNotAffectingPrice}>
        {option.variations.map((variation) => this.renderVariation(option, variation))}
      </div>
    );
  }

  renderVariation(option: OptionState, variation: OptionVariationState) {
    const selected = variation.selected;
    return (
      <OptionVariationItem
        key={variation.data.id}
        variation={variation}
        className={css.optionVariationItem}
        thumbnailClassName={classNames(css.thumbnail, selected && css.isSelected)}
        onSelected={() => {
          option.checkRestrictionsAndSelect(variation);
        }}
        isSelected={selected}
        hideCaption
        hidePopup
      />
    );
  }
}

export default OptionAffOptionNotAffectingPrice;
