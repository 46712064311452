import { action, makeObservable, observable } from 'mobx';

import { BaseVisualizationData, VisualizationData } from '../data/model';
import ImageZoomModalState from '../shared/components/ImageZoomModal/ImageZoomModalState';
import { IImagePreloader, ImagePreloadingContext } from '../shared/ImagePreloader';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { DefaultTasksScheduler, RunLastTasksScheduler } from '../shared/TasksScheduler';
import { UpdateVisualizationImagesTask } from '../shared/UpdateVisualizationImagesTask';
import { VisualizationShotsState } from '../shared/VisualizationShotsState';
import { IVisualizationState } from './IVisualizationState';
import { PreloadFlattenedImageTask } from './PreloadFlattenedImageTask';
import { VisualizationBuilder } from './VisualizationBuilder';

export class BaseVisualizationState {
  @observable imageUrls: Array<string> = [];

  zoomModal: ImageZoomModalState;
  shots: VisualizationShotsState;

  loadingImageIndicator: LoadingIndicator = new LoadingIndicator();

  constructor() {
    makeObservable(this);
  }
}

export class VisualizationState extends BaseVisualizationState implements IVisualizationState<BaseVisualizationData> {
  private imagePreloader: IImagePreloader;

  private data: VisualizationData;
  private selectedComponents: Map<string, string> = new Map<string, string>();

  constructor(imagePreloader: IImagePreloader) {
    super();
    makeObservable(this);
    this.imagePreloader = imagePreloader;
    this.zoomModal = new ImageZoomModalState(imagePreloader, (shot, width, height) =>
      Promise.resolve(this.buildImageUrl(shot, width, height)),
    );
    this.shots = new VisualizationShotsState();
  }

  getResourcesUrls(): string[] {
    return this.imageUrls;
  }

  private buildImageUrl(shot: string, width: number, height: number) {
    return VisualizationBuilder.buildFlattenedImage(this.data, this.selectedComponents, shot, width, height);
  }

  initialize(data: VisualizationData, components: Map<string, string>) {
    this.data = data;
    this.selectedComponents = components;
    this.shots.setShots(data.mapping.shots);

    const imageUrls = this.getImageUrls();
    this.setImageUrls(imageUrls);
    ImagePreloadingContext.queue.enqueueMany(imageUrls);

    this.shots.activeShotChanged.subscribe(() => this.updateImages());
    this.zoomModal.shots.activeShotChanged.subscribe((x) => this.shots.setActive(x));
  }

  setComponents(components: Map<string, string>) {
    this.selectedComponents = components;
    this.updateImages();
    this.triggerFlattenedImagePreloading();
  }

  private getImageUrls() {
    return VisualizationBuilder.buildPartialImages(this.data, this.selectedComponents, this.shots.active, 680, 1192);
  }

  private updateImages() {
    const urls = this.getImageUrls();
    this.updateVisualizationScheduler.run(new UpdateVisualizationImagesTask(urls));
  }

  private updateVisualizationScheduler = new RunLastTasksScheduler((task: UpdateVisualizationImagesTask) =>
    this.preloadAndUpdateVisualizationImages(task),
  );

  private async preloadAndUpdateVisualizationImages(task: UpdateVisualizationImagesTask): Promise<void> {
    this.loadingImageIndicator.start();

    await this.imagePreloader.load(task.urls);

    if (task.cancelled) {
      return;
    }

    this.setImageUrls(task.urls);

    this.loadingImageIndicator.reset();
  }

  @action setImageUrls(urls: Array<string>) {
    this.imageUrls = urls;
  }

  @action openZoom() {
    this.zoomModal.setData(this.shots.all, this.shots.active);
    this.zoomModal.open();
  }

  private triggerFlattenedImagePreloading() {
    const url = VisualizationBuilder.buildFlattenedImage(
      this.data,
      this.selectedComponents,
      this.shots.active,
      100,
      150,
    );
    this.preloadFlattenedImageScheduler.run(new PreloadFlattenedImageTask(url));
  }

  private preloadFlattenedImageScheduler = new DefaultTasksScheduler<PreloadFlattenedImageTask>((task) =>
    this.preloadFlattenedImage(task),
  );

  private async preloadFlattenedImage(task: PreloadFlattenedImageTask): Promise<void> {
    await this.imagePreloader.load([task.url]);
  }
}
