import { observer } from 'mobx-react';
import { Component, Fragment } from 'react';

import { DisclaimerTranslation } from '../../localization/SiteTranslation';
import Link from '../../shared/components/Link';
import { FabricWarningState } from './FabricWarningState';
import { WarningBaseProps } from './StackedWarnings';
import css from './Warning.css';

export interface FabricWarningProps extends WarningBaseProps {
  state: FabricWarningState;
  customerServiceLink: string;
  translation: DisclaimerTranslation;
}

@observer
export class FabricWarning extends Component<FabricWarningProps> {
  render() {
    const { customerServiceLink, translation } = this.props;
    return (
      <Fragment>
        {translation.messagePart1}
        <Link className={css.contactUsLink} href={customerServiceLink}>
          {translation.link}
        </Link>{' '}
        {translation.messagePart2}
      </Fragment>
    );
  }
}
