import { OrderData } from '../../../app/data/model';
import { Alert } from '../../../app/shared/alert/Alert';
import { formatISODateStringToDottedDate } from '../../../app/shared/commonDate';
import List from '../../../app/shared/components/List/List';
import { Panel } from '../../../app/shared/components/Panel/Panel';
import { OrderTranslation } from '../../localization/SiteTranslation';
import { OrderHeader } from '../OrderHeader/OrderHeader';
import { OrderItem } from '../OrderItem/OrderItem';
import { formatExpectedShippingMessage } from '../OrderState';
import css from './Order.css';

export const Order = ({ order, translation }: { order: OrderData; translation: OrderTranslation }) => {
  const shippingMessage = !!order.shippingDate
    ? order.shippingDate &&
      translation.shippingDateFormat.interpolate([['date', formatISODateStringToDottedDate(order.shippingDate)]])
    : order.expectedShipping && formatExpectedShippingMessage(order.expectedShipping, translation.shippingMessage);

  return (
    <>
      {order.erpChangesNotSynchronized && (
        <Alert className={css.alertDanger}>{translation.changesInOrderAlertText}</Alert>
      )}

      <Panel
        headerComponent={
          <OrderHeader
            orderNo={order.number}
            date={order.createdDate}
            price={order.total}
            name={order.customName}
            reference={order.customReference}
            buyer={order.customerName}
            status={order.status}
            expectedShippingMessage={shippingMessage}
            translation={translation.orderHeader}
          />
        }
        bodyClassName={css.Order}
      >
        <List
          className={css.orderList}
          itemClassName={css.orderItem}
          items={order.items}
          itemRenderer={(orderItem, key) => (
            <OrderItem item={orderItem} key={key} translation={translation.orderItem} />
          )}
        />
      </Panel>
    </>
  );
};
