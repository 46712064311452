import { IApiClient } from '../../../app/data/client';
import { RouteUrlBuilder } from '../../../app/RouteDefinitions';
import { IEventAggregator } from '../../../app/shared/EventAggregator';
import { StoreUrlBuilder } from '../../../app/shared/StoreUrlBuilder';
import { StoreState } from '../../../app/StoreState';
import { TopMenuTranslation } from '../../localization/SiteTranslation';
import RouteDefinitions from '../../routing/RouteDefinitions';
import UserInfoState from '../UserInfo/UserInfoState';

class TopMenuState {
  public readonly shoppingCartUrl = StoreUrlBuilder.withContext(RouteUrlBuilder.getUrlTo(RouteDefinitions.cartRoute));

  public readonly userInfo: UserInfoState;

  public get shoppingCartCount() {
    return this.store.shoppingCartSummary.numberOfItems;
  }

  public constructor(
    client: IApiClient,
    eventAggregator: IEventAggregator,
    private readonly store: StoreState,
    public readonly translation: TopMenuTranslation,
  ) {
    this.userInfo = new UserInfoState(
      client,
      eventAggregator,
      store.storeResponse.shoppingContext,
      store.storeResponse.storeContext.store,
      store.storeResponse.availableStores,
      translation.userInfo,
    );
  }
}

export default TopMenuState;
