import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { DirectionalLight } from '@babylonjs/core/Lights/directionalLight';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { CubeTexture } from '@babylonjs/core/Materials/Textures/cubeTexture';
import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Scene } from '@babylonjs/core/scene';
import { GLTFFileLoader } from '@babylonjs/loaders/glTF';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Asset3dReference, Component3d, Material3dReference, Mesh3dReference } from '../../../3d/Asset3dReference';
import Viewer3d from '../../../3d/components/Viewer3d';
import { TransformationGroup } from '../../../data/model';
import { Visualization3d, Visualization3dState } from '../../Visualization3dState';

export interface ProductViewer3dProps {
  state: Visualization3dState;
  handleLoadingState?: Function;
  onSceneUpdateSuccess?: (scene: Scene) => void;
  onSceneUpdateError?: (error: string) => void;
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  style?: React.CSSProperties;
  viewerClassName?: string;
  backgroundColor?: string;
  children?: React.ReactNode;
}

@observer
export default class ProductViewer3d extends React.Component<ProductViewer3dProps, { scene: Scene }> {
  private groundName = 'ground';
  private reactionDisposer: IReactionDisposer;

  constructor(props: ProductViewer3dProps) {
    super(props);
    this.initialSceneConfiguration = this.initialSceneConfiguration.bind(this);
    this.state = {
      scene: null,
    };
  }

  componentDidMount() {
    this.reactionDisposer = reaction(
      () => this.props.state.visualization3d,
      (x: Visualization3d) => this.loadComponents(this.state.scene, x),
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }

  private configCamera(scene: Scene, distanceFactor: number) {
    const radius = 3 * distanceFactor;

    const camera = new ArcRotateCamera(
      'camera',
      Math.PI / 2,
      Math.PI / 2.2,
      radius,
      new Vector3(0, 0.6, 0),
      scene,
      true,
    );
    camera.lowerBetaLimit = 0;
    camera.upperBetaLimit = Math.PI / 2;
    camera.fov = 0.5;
    camera.upperRadiusLimit = radius;

    return camera;
  }

  private configLight(scene: Scene, backgroundColor: Color3) {
    // const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
    // light.intensity = 0.1;

    const shadowLight = new DirectionalLight('shadowLight', new Vector3(0, -1, 0), scene);
    shadowLight.shadowFrustumSize = 2; // This helps to get rid of some artifacts when making screenshots
    shadowLight.position = new Vector3(0, 50, 0);
    shadowLight.shadowEnabled = true;
    shadowLight.shadowMinZ = 1;
    shadowLight.shadowMaxZ = 200;

    const shadowGenerator = new ShadowGenerator(256, shadowLight);
    shadowGenerator.useBlurExponentialShadowMap = true;
    shadowGenerator.blurScale = 0.5;
    shadowGenerator.useKernelBlur = true;
    shadowGenerator.enableSoftTransparentShadow = true;
    shadowGenerator.darkness = 0.6;

    // blurKernel was initially set to 60 but it was causing issues when the Azure Function hosted on Linux trying to render scene on headless Chrome
    // Need to investigate why 60 is not working, while 59 is fine
    shadowGenerator.blurKernel = 48;

    const ground = MeshBuilder.CreateGround(this.groundName, { width: 10, height: 10, updatable: false }, scene);
    const groundMaterial = new StandardMaterial(this.groundName, scene);

    groundMaterial.diffuseColor = backgroundColor;
    groundMaterial.specularColor = backgroundColor;
    ground.receiveShadows = true;
    ground.material = groundMaterial;
  }

  private configShadows(scene: Scene, shadowCasters: string[]) {
    const light = scene.getLightByName('shadowLight');

    if (!light) {
      return;
    }

    const shadowGenerator = light.getShadowGenerator() as ShadowGenerator;
    const shadowGeneratorNamePatterns = shadowCasters?.any() ? shadowCasters : ['BAZA', 'KOLKA', 'STOPKI'];

    scene.meshes.forEach((x) => {
      if (shadowGeneratorNamePatterns.any((pattern) => x.name.indexOf(pattern) >= 0)) {
        shadowGenerator.addShadowCaster(x);
      }
    });
  }

  private appendComponents(scene: Scene, assets: Array<Asset3dReference>, predicate: (a: Asset3dReference) => boolean) {
    const urls = assets
      .filter(predicate)
      .map((c) => c.url)
      .distinct();
    return urls.map((url) => SceneLoader.AppendAsync(url, null, scene));
  }

  private loadMeshes(scene: Scene, meshes: Array<Mesh3dReference>) {
    return this.appendComponents(scene, meshes, (mesh) =>
      scene.meshes.empty((m) => m.name.equalsIgnoreCase(mesh.name)),
    );
  }

  private loadMaterials(scene: Scene, materials: Array<Material3dReference>) {
    return this.appendComponents(scene, materials, (material) =>
      scene.materials.empty((m) => m.name.equalsIgnoreCase(material.name)),
    );
  }

  private displayOnlySelected(scene: Scene, components: Array<Component3d>) {
    const visibleMeshes = [...components.map((x) => x.mesh.name), this.groundName];
    const { hidden, visible } = scene.meshes.groupBy<AbstractMesh>((x) =>
      visibleMeshes.includes(x.name) ? 'visible' : 'hidden',
    );
    hidden.forEach((x: AbstractMesh) => (x.visibility = 0));
    visible.forEach((x: AbstractMesh) => (x.visibility = 1));
  }

  private transformSelected(scene: Scene, transformations: TransformationGroup[]) {
    transformations.forEach((transformationGroup) => {
      const meshes = scene.meshes.filter((x) => transformationGroup.parts.includes(x.name));
      meshes.forEach((mesh) => {
        mesh.position.x = transformationGroup.translation.x;
        mesh.position.y = transformationGroup.translation.y;
        mesh.position.z = transformationGroup.translation.z;
      });
    });
  }

  private mapComponents(scene: Scene, components: Array<Component3d>) {
    components.forEach((x) => {
      if (!x.material) {
        return;
      }

      const mesh = scene.meshes.find((m) => m.name.equalsIgnoreCase(x.mesh.name));
      const material = scene.materials.find((m) => m.name.equalsIgnoreCase(x.material.name));

      mesh.material = material;
    });
  }

  private visualization: Visualization3d = null;

  private async setupComponentsOnScene(scene: Scene, visualization: Visualization3d) {
    this.visualization = visualization;

    const components = visualization.components3d;
    const loadMeshes = this.loadMeshes(
      scene,
      components.map((x) => x.mesh),
    );
    const loadMaterials = this.loadMaterials(
      scene,
      components.map((x) => x.material).filter((x) => x),
    );

    await Promise.all([...loadMeshes, ...loadMaterials]);

    this.configShadows(scene, visualization.shadowCasters);

    if (this.visualization === visualization) {
      this.mapComponents(scene, components);
      this.displayOnlySelected(scene, components);
      this.transformSelected(scene, visualization.transformations);
    }

    return await scene.whenReadyAsync();
  }

  async loadComponents(scene: Scene, visualization: Visualization3d) {
    if (!scene) {
      return;
    }

    const { onSceneUpdateError, onSceneUpdateSuccess, onLoadingEnd, onLoadingStart } = this.props;

    try {
      onLoadingStart?.();
      await this.setupComponentsOnScene(scene, visualization);
      onSceneUpdateSuccess?.(scene);
    } catch (e) {
      onSceneUpdateError?.(e);
    } finally {
      onLoadingEnd?.();
    }
  }

  async initialSceneConfiguration(scene: Scene) {
    const { backgroundColor } = this.props;
    const { visualization3d } = this.props.state;

    SceneLoader.RegisterPlugin(new GLTFFileLoader());
    SceneLoader.ShowLoadingScreen = false;
    const engine = scene.getEngine();
    engine.renderEvenInBackground = false;

    const backgroundColor3 = backgroundColor ? Color3.FromHexString(backgroundColor) : new Color3(1, 1, 1);
    const backgroundColor4 = Color4.FromColor3(backgroundColor3, 1);

    scene.clearColor = backgroundColor4;
    scene.ambientColor = new Color3(0, 0, 0);

    const canvas = engine.getRenderingCanvas();
    const camera = this.configCamera(scene, visualization3d.cameraDistanceFactor);
    camera.attachControl(canvas, true);
    scene.addCamera(camera);

    if (visualization3d.materialLibraryBaseUrl) {
      const environmentTexture = CubeTexture.CreateFromPrefilteredData(
        `${visualization3d.materialLibraryBaseUrl}/env_test.env`,
        scene,
      );
      scene.createDefaultSkybox(environmentTexture);
    }

    this.configLight(scene, backgroundColor3);
    await this.loadComponents(scene, visualization3d);
    this.setState({ scene });
  }

  render() {
    return <Viewer3d sceneConfiguration={this.initialSceneConfiguration} {...this.props} />;
  }
}
