import classNames from 'classnames';

import { FooterCopyrightData } from '../../../data/model';
import css from './Copyright.css';

const currentYear = new Date().getFullYear();

interface CopyrightProps extends FooterCopyrightData {
  className?: string;
}

const Copyright = ({ allRightsReservedText, className, copyrightNoticeText }: CopyrightProps) => {
  if (!allRightsReservedText && !copyrightNoticeText) {
    return null;
  }

  return (
    <div className={classNames(css.container, className)}>
      {copyrightNoticeText && `${copyrightNoticeText} ${currentYear} Flokk `}
      {allRightsReservedText && <span className={css.allRightsReserved}>{allRightsReservedText}</span>}
    </div>
  );
};

export default Copyright;
