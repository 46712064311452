import { Component, createContext, ReactNode } from 'react';

export interface IPriceContext {
  enable: boolean;
  displayDecimalPart: boolean;
}

export const PriceContext = createContext<IPriceContext>({ enable: true, displayDecimalPart: true });
export const PriceConsumer = PriceContext.Consumer;

interface RenderWhenPriceEnabledProps {
  children?: ReactNode;
}

export const RenderWhenPriceEnabled = ({ children }: RenderWhenPriceEnabledProps) => (
  <PriceConsumer>{({ enable }) => (enable ? children : null)}</PriceConsumer>
);

interface PriceProviderProps extends IPriceContext {
  children: ReactNode;
}

export class PriceProvider extends Component<PriceProviderProps> {
  constructor(props: PriceProviderProps) {
    super(props);
  }

  render() {
    const { children, displayDecimalPart, enable } = this.props;
    return <PriceContext.Provider value={{ enable, displayDecimalPart }}>{children}</PriceContext.Provider>;
  }
}
